<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ EXPORT ==================================//
//======================================================//
export default {
    props: {
        tooltip: {
            default: true,
            type: Boolean
        },
        tooltipExpanded: {
            default: false,
            type: Boolean
        },
        tooltipDisabled: {
            default: false,
            type: Boolean
        },
        tooltipContent: {
            default: null,
            type: String
        },
        tooltipWhiteListInitialInit: {
            default: true,
            type: Boolean
        },

        tooltipWhiteList: {
            default: () => [],
            type: Array
        },
        tooltipOpenOnFocus: {
            default: 'off',
            type: String,
            validator: value => {
                return ['off', 'all', 'invalidOnly'].includes(value);
            }
        },
        tooltipCloseOnBlur: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            tooltipExpandedData: false,
            tooltipWhiteListInitial: []
        };
    },
    computed: {
        classObjectMixinTooltip() {
            return [
                {
                    'vue-has-tooltip': this.tooltipComputed,
                    'vue-has-tooltip-empty':
                        this.tooltipContent === '' || (this.tooltipContent === null && this.tooltipComputed),
                    'vue-has-tooltip-expanded': this.tooltipExpandedData
                }
            ];
        },
        tooltipComputed() {
            return !!(this.tooltip || this.tooltipContent);
        },
        tooltipHasContent() {
            return this.tooltipComputed && this.tooltipContent && this.tooltipContent !== '';
        },
        tooltipWhiteListComputed() {
            let whiteList = [];

            // initial internal white list setting
            if (this.tooltipWhiteListInitial.length > 0) {
                for (let i = 0; i < this.tooltipWhiteListInitial.length; i++) {
                    let element = this.tooltipWhiteListInitial[i];
                    whiteList.push(element);
                }
            }

            // add passed items via whiteList prop
            if (this.tooltipWhiteList.length > 0) {
                for (let i = 0; i < this.tooltipWhiteList.length; i++) {
                    let element = this.tooltipWhiteList[i];
                    whiteList.push(element);
                }
            }

            return whiteList;
        }
    },
    watch: {
        tooltipExpanded(value) {
            this.tooltipExpandedData = value;
        },
        tooltipExpandedData(value) {
            this.$emit('update:tooltipExpanded', value);
        }
    },
    methods: {
        tooltipToggle() {
            this.$refs.tooltip.toggle();
        },
        tooltipOpen() {
            this.$refs.tooltip.open();
        },
        tooltipClose() {
            this.$refs.tooltip.close();
        }
    }
};
</script>
