export default [
  {
    id: 0,
    title: 'Home',
    txtCol: 'text-esg-home',
    bgCol: 'bg-esg-home',
    wallPaper: 'prj-wallpaper-home',
    svg: {
      hash: `<svg width="100%" height="100%" viewBox="0 0 43 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-black">
            <path d="M14.1009 0.590907H8.76989L0.332386 31.9375H5.66335L14.1009 0.590907ZM28.5501 0.590907H23.2191L14.7816 31.9375H20.1126L28.5501 0.590907ZM42.9993 0.590907H37.6683L29.2308 31.9375H34.5618L42.9993 0.590907Z" fill="currentColor" />
            </svg>`,
    },
  },
  {
    id: 1,
    title: 'Planet',
    txtCol: 'text-esg-planet',
    bgCol: 'bg-esg-planet',
    wallPaper: 'prj-wallpaper-planet',
    svg: {
      hash: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 43 32" fill="none">
            <path d="M14.1009 0.590907H8.76989L0.332386 31.9375H5.66335L14.1009 0.590907Z" fill="#3FAD23"/>
            <path d="M28.5501 0.590907H23.2191L14.7816 31.9375H20.1126L28.5501 0.590907ZM42.9993 0.590907H37.6683L29.2308 31.9375H34.5618L42.9993 0.590907Z" fill="#D1D5DB"/>
            </svg>`,
      transparentHash: `<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.35511 2.86364H3.00426L0.191761 13.3125H1.54261L4.35511 2.86364Z" fill="white"/>
            <path d="M8.90199 2.86364H7.55114L4.73864 13.3125H6.08949L8.90199 2.86364ZM13.4489 2.86364H12.098L9.28551 13.3125H10.6364L13.4489 2.86364Z" fill="#6ECA27"/>
            </svg>`,
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path d="M5 21C5.5 16.5 7.5 13 12 11" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.99999 18C15.218 18 19.5 14.712 20 6V4H15.986C6.98599 4 3.99999 8 3.98599 13C3.98599 14 3.98599 16 5.98599 18H8.98599H8.99999Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
    },
  },
  {
    id: 2,
    title: 'People',
    txtCol: 'text-esg-people',
    bgCol: 'bg-esg-people',
    wallPaper: 'prj-wallpaper-people',
    svg: {
      hash: `<svg width="100%" height="100%" viewBox="0 0 43 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1009 0.590907H8.76989L0.332386 31.9375H5.66335L14.1009 0.590907ZM42.9993 0.590907H37.6683L29.2308 31.9375H34.5618L42.9993 0.590907Z" fill="#D1D5DB"/>
            <path d="M28.5501 0.590907H23.2191L14.7816 31.9375H20.1126L28.5501 0.590907Z" fill="#2DBCC6"/>
            </svg>`,
      transparentHash: `<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.35511 2.86364H3.00426L0.191761 13.3125H1.54261L4.35511 2.86364ZM13.4489 2.86364H12.098L9.28551 13.3125H10.6364L13.4489 2.86364Z" fill="#5ED7DF"/>
            <path d="M8.90199 2.86364H7.55114L4.73864 13.3125H6.08949L8.90199 2.86364Z" fill="white"/>
            </svg>`,
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>`,
    },
  },
  {
    id: 3,
    title: 'Profit',
    txtCol: 'text-esg-profit',
    bgCol: 'bg-esg-profit',
    wallPaper: 'prj-wallpaper-profit',
    svg: {
      hash: `<svg width="100%" height="100%" viewBox="0 0 43 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1009 0.590907H8.76989L0.332386 31.9375H5.66335L14.1009 0.590907ZM28.5501 0.590907H23.2191L14.7816 31.9375H20.1126L28.5501 0.590907Z" fill="#D1D5DB"/>
            <path d="M42.9993 0.590907H37.6683L29.2308 31.9375H34.5618L42.9993 0.590907Z" fill="#3D3C62"/>
            </svg>`,
      transparentHash: `<svg width="100%" height="100%" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.35511 2.86364H3.00426L0.191761 13.3125H1.54261L4.35511 2.86364ZM8.90199 2.86364H7.55114L4.73864 13.3125H6.08949L8.90199 2.86364Z" fill="#6E6D8A"/>
            <path d="M13.4489 2.86364H12.098L9.28551 13.3125H10.6364L13.4489 2.86364Z" fill="white"/>
            </svg>`,
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
            </svg>`,
    },
  },
]
