<template>
  <div
    class="vue-c-food-menu-control"
    :class="classObject"
  >
    <div class="vue-food-menu-buttons">
      <div
        v-if="mainCategory.length > 0"
        :class="buttonsClassObject"
      >
        <template v-for="(mainCategoryItem, index) in mainCategory">
          <template
            v-if="primaryCategoryType === 'string'"
          >
            <frm1006-button
              :key="mainCategoryItem + '-' + index"
              :active="activeMainCategory === mainCategoryItem"
              class="vue-food-menu-control-button"
              type="app-button"
              @buttonClickEvent="selectMainCategory(mainCategoryItem)"
            >
              {{ mainCategoryItem }}
            </frm1006-button>
          </template>

          <template
            v-if="primaryCategoryType === 'object'"
          >
            <frm1006-button
              :key="mainCategoryItem.name + '-' + index"
              :active="activeMainCategory === mainCategoryItem.id"
              class="vue-food-menu-control-button"
              type="app-button"
              @buttonClickEvent="selectMainCategory(mainCategoryItem.id)"
            >
              {{ mainCategoryItem.name }}
            </frm1006-button>
          </template>
        </template>
      </div>

      <div
        v-if="secondaryCategory.length > 0"
        class="vue-food-menu-control-subbuttons"
      >
        <frm1006-button
          v-if="showQuaternaryCategory"
          class="vue-food-menu-control-button-more"
          type="app-button"
          @buttonClickEvent="emitQuaternaryButtonEvent"
        />

        <frm1033-dropdown-button
          id="selected-building"
          class="vue-food-menu-control-button vue-menu-food-control-day-selector"
          type="app-button"
          :caption="secondaryCategoryCaption"
          :options="secondaryCategory"
          :optionPreselectedValue="secondaryCategoryValue"
          :tooltip="false"
          :contextContainerClickPosition="contextContainerPositions"
          contextContainerCustomCss="vue-is-simple vue-is-position-bottom"
          @contextContainerMenuItemSelectEvent="selectSecondaryCategory"
          @buttonDropdownToggleClickEvent="emitButtonToggleClickEvent"
        />

        <frm1006-button
          v-if="showTertiaryCategory"
          class="vue-food-menu-control-button"
          :class="classObject"
          type="app-button"
          @buttonClickEvent="emitTertiaryButtonEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

export default {
  name: 'Prj1006FoodMenuControl',
  mixins: [mxDetectDesktop],
  props: {
    mainCategory: {
      type: Array,
      required: true
    },
    activeMainCategory: {
      type: [String, Number],
      required: true
    },
    secondaryCategory: {
      type: Array,
      required: false,
      default: () => []
    },
    activeSecondaryCategory: {
      type: [String, Number],
      required: false,
      default: ''
    },
    showTertiaryCategory: {
      type: Boolean,
      required: false,
      default: false
    },
    showQuaternaryCategory: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      daySelectorVisibility: false,
      contextContainerPositions: false
    }
  },

  computed: {
    ...mapState('restaurants', [
      'selectedRestaurant',
      'selectedDay',
      'cafeMenuVisibility'
    ]),

    classObject() {
      return {
        'vue-is-active': this.daySelectorVisibility,
        'vue-menu-restaurants-control-cafe': !this.cafeMenuVisibility,
        'vue-menu-restaurants-control-restaurants': this.cafeMenuVisibility
      }
    },

    buttonsClassObject() {
      return {
        'vue-category-filter-scroll-wrapper': this.primaryCategoryType === 'object'
      }
    },

    secondaryCategoryCaption() {
      if (this.activeSecondaryCategory !== null) {
        return this.secondaryCategory[this.activeSecondaryCategory].caption.slice(0, 2);
      }

      return '';
    },

    secondaryCategoryValue() {
      if (this.activeSecondaryCategory !== null) {
        return this.secondaryCategory[this.activeSecondaryCategory].value;
      }

      return '';
    },

    primaryCategoryType() {
      return typeof this.mainCategory[0];
    }
  },

  methods: {
    selectMainCategory(activeMainCategory) {
      this.$emit('setActiveMainCategory', activeMainCategory);
    },

    selectSecondaryCategory(activeSecondaryCategory) {
      this.$emit('setActiveSecondaryCategory', activeSecondaryCategory);
    },

    emitTertiaryButtonEvent() {
      this.$emit('setActiveTertiaryCategory');
    },

    emitQuaternaryButtonEvent() {
      this.$emit('setActiveQuaternaryCategory');
    },

    emitButtonToggleClickEvent(event) {
      if (this.isDesktopLayout) {
        this.contextContainerPositions = {
          x: event.pageX,
          y: event.pageY,
          mode: 'bottom-right'
        }
      } else {
        this.contextContainerPositions = false
      }
    }
  }
}
</script>
