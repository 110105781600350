<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        @buttonCustomEvent="returnToComments"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-send-message">
        <div class="vue-b-form">
          <frm1035-form-group>
            <frm1001-input-field
              v-model="discussion.formData.name"
              :required="true"
              :state="formNameInvalidState()"
              :label="$i18n.t('articles.commentFullName')"
              :tooltip="false"
            />
          </frm1035-form-group>
          <frm1035-form-group>
            <frm1013-textarea
              v-model="discussion.formData.content"
              :required="true"
              :state="formContentInvalidState()"
              :label="$i18n.t('articles.commentContent')"
              :rowsAutoExpand="true"
              :rowsAutoExpandMinRows="1"
              :rowsAutoExpandMaxRows="8"
              :tooltip="false"
            />
          </frm1035-form-group>

          <gen1006-info-panel type="neutral">
            {{ $t('articles.commentsPrivacyDisclaimer') }}
          </gen1006-info-panel>
        </div>
        <div class="vue-b-form-button" />
      </div>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        type="primary"
        :disabled="discussionFormInvalid"
        @buttonClickEvent="formValidation"
      >
        {{ $t('articles.commentSend') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        :active.sync="sentFailed"
        :closeAfter="5000"
        :buttonClose="true"
        class="vue-is-info-panel"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('articles.commentSendFailed') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />
    </template>
  </page-layout>
</template>

<script>
import Prj1002Navbar from '../../components/prj1002-navbar/prj1002-navbar';

import PageLayout from '../../templates/partials/page-layout';

import dataLoader from '@/utils/data-loader';

import mxNavigationDefault from '../../mixins/mx-navigation-default';
import moduleStatistics from '../../mixins/mx-module-statistics';
import {mapState} from 'vuex';

import logger from '@/utils/logger';

export default {
  name: 'NewsDetailCommentAdd',

  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [mxNavigationDefault, moduleStatistics],

  data() {
    return {
      discussion: {
        formData: {
          name: '',
          email: '',
          content: '',
          parentId: 0
        },
      },
      validationErrors: [],
      sending: false,
      loading: false,
      sentFailed: false,
      showButtonLoadPostsMore: false
    }
  },

  computed: {
    ...mapState('articles', [
      'showFilteredArticles',
      'filteredArticles'
    ]),
    ...mapState('dataStore', [
      'contentData'
    ]),

    articleId() {
      return this.$route.params.id;
    },

    parentId() {
      return this.$route.query.parentId;
    },

    discussionFormInvalid() {
      return this.discussion.formData.name === '' || this.discussion.formData.content === '';
    },

    navbarCaption() {
      return this.parentId === 0 ? this.$i18n.t('articles.commentNew') : this.$i18n.t('articles.commentReply');
    }
  },

  mounted() {
    this.discussion.formData.parentId = this.parentId;
  },

  methods: {
    returnToComments(success) {
      if (success) {
        this.$router.push({ name: 'news-comments', params: { id: this.articleId, success: true }});
      } else {
        this.$router.push({ name: 'news-comments', params: { id: this.articleId }});
      }
    },

    sendArticleComment() {
      let values = this.discussion.formData;
      let deviceId = this.$store.state.user.deviceId;
      let topicId = this.articleId;

      this.sending = true;

      let postData = {
        ...values,
        deviceId,
        topicId,
      };

      dataLoader.sendForumPost(postData)
        .then(() => {
          this.sending = false;
          logger.info('Post application has been sent.');
        }).catch(error => {
          this.sending = false;
          this.sentFailed = true;
          logger.error('Sending post application has failed.', error);
        }).finally(() => {
          if (!this.sentFailed) {
            this.returnToComments(true);
          }
        });
    },

    formValidation(event) {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;

      let values = this.discussion.formData;

      this.validationErrors = [];

      if (!values.name) {
        this.validationErrors.push(this.$i18n.t('articles.commentFullNameInfo'));
      }

      if (!values.content) {
        this.validationErrors.push(this.$i18n.t('articles.commentContentInfo'));
      }

      if (this.validationErrors.length === 0) {
        this.sendArticleComment();
      }
    },

    formNameInvalidState() {
      if (!this.discussion.formData.name && this.validationErrors.length) {
        return 'invalid'
      } else {
        return 'info'
      }
    },

    formEmailInvalidState() {
      if (!this.discussion.formData.email && this.validationErrors.length) {
        return 'invalid'
      } else {
        return 'info'
      }
    },

    formContentInvalidState() {
      if (!this.discussion.formData.content && this.validationErrors.length) {
        return 'invalid'
      } else {
        return 'info'
      }
    },
  }
}
</script>
