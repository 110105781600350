<script>
// TODO: Temporary mixin for making strings from date string until we migrate to Vue3
import Vue from 'vue';
import utilsGeneral from '@/utils/utils-general';

export default {
  methods: {
    renderLessonString(lesson, mode) {
      // TODO: JNO - Try to make better solution for making this string
      const date = new Date(utilsGeneral.normalizeDateString(lesson.activityFrom));
      const endDate = new Date(utilsGeneral.normalizeDateString(lesson.activityTo));
      // FIXME MWr: Workaround for bad convert day name - CSOBZ-563
      const dateDay = (date.getDay() === 0 ? 6 : date.getDay() - 1);
      const endDateDay = (endDate.getDay() === 0 ? 6 : endDate.getDay() - 1);
      const weekDayName = this.$i18n.t('time.days.' + dateDay);
      const endWeekDayName = this.$i18n.t('time.days.' + endDateDay);
      const weekDayNumber = Vue.filter('convertDate')(lesson.activityFrom, 'day');
      const endWeekDayNumber = Vue.filter('convertDate')(lesson.activityTo, 'day');
      const monthNumber = Vue.filter('convertDate')(lesson.activityFrom, 'month');
      const endMonthNumber = Vue.filter('convertDate')(lesson.activityTo, 'month');
      const monthName = this.$i18n.t('time.months.' + monthNumber);
      const lessonMonth = this.$i18n.t('inflection.months.2.' + monthName);
      const lessonStartTime = Vue.filter('convertDate')(lesson.activityFrom, 'exactHours');
      const lessonEndTime = Vue.filter('convertDate')(lesson.activityTo, 'exactHours');

      if (mode === 'day') {
        return `${weekDayName} ${weekDayNumber}. ${monthNumber}.`;
      } else if (mode === 'time') {
        return `${lessonStartTime} - ${lessonEndTime}`;
      } else if (mode === 'endDay') {
        return `${endWeekDayName} ${endWeekDayNumber}. ${endMonthNumber}.`;
      } else if (mode === 'startTime') {
        return `${lessonStartTime}`;
      } else if (mode === 'endTime') {
        return `${lessonEndTime}`;
      }

      return `${weekDayName} ${weekDayNumber}. ${lessonMonth}, ${lessonStartTime} - ${lessonEndTime}`;
    },
  }
}
</script>
