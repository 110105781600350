<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <li class="vue-b-step" :class="classObject">
        <template v-if="mode === 'link'">
            <a v-if="passed" :href="link" class="vue-step">
                <span class="vue-step-caption-type">{{ captionDescription }}</span>
                <span class="vue-step-number">{{ index }}</span>
                <span class="vue-step-caption">{{ caption }}</span>
            </a>
            <span v-else class="vue-step" :tabindex="tabindex">
                <span class="vue-step-caption-type">{{ captionDescription }}</span>
                <span class="vue-step-number">{{ index }}</span>
                <span class="vue-step-caption">{{ caption }}</span>
            </span>
        </template>
        <template v-else-if="mode === 'button'">
            <span class="vue-step" :tabindex="tabindex" @click="stepClick" @keyup="stepKeyUp">
                <span class="vue-step-caption-type">{{ captionDescription }}</span>
                <span class="vue-step-number">{{ index }}</span>
                <span class="vue-step-caption">{{ caption }}</span>
            </span>
        </template>
    </li>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1021FormStep',
    props: {
        mode: String,
        index: Number,
        step: Object,
        stepCurrent: Number,
        caption: String,
        captionCurrent: String,
        captionPassed: String,
        captionFuture: String,
        link: String
    },
    computed: {
        classObject() {
            return [
                'vue-step-' + this.stepNumber,
                this.step.customCss,
                {
                    'vue-is-active': this.active,
                    'vue-is-passed': this.passed,
                    'vue-is-future': this.future,
                    'vue-is-available': this.available,
                    'vue-is-unavailable': this.unavailable
                }
            ];
        },
        stepNumber() {
            return this.index + 1;
        },
        active() {
            return this.stepNumber === this.stepCurrent;
        },
        passed() {
            return this.stepNumber < this.stepCurrent;
        },
        future() {
            return this.stepNumber > this.stepCurrent;
        },
        available() {
            return this.step.available === true;
        },
        unavailable() {
            return this.step.available === false;
        },
        captionDescription() {
            if (this.active) {
                return this.captionCurrent;
            } else if (this.passed) {
                return this.captionPassed;
            } else if (this.future) {
                return this.captionFuture;
            }

            return null;
        },
        tabindex() {
            if (this.mode === 'button' && this.passed) {
                return 0;
            }

            return null;
        }
    },
    methods: {
        stepClick() {
            if ((!this.unavailable && this.passed) || this.available) {
                this.$emit('stepActivateEvent', this.stepNumber, this.step);
            }
        },
        stepKeyUp(event) {
            if (event.key === 'Enter') {
                this.$emit('stepActivateEvent', this.stepNumber, this.step);
            }
        }
    }
};
</script>
