<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <button
    class="relative flex items-center justify-between mb-3 xs:mb-5 rounded-xl"
    :class="classObject"
    :disabled="disabled"
    @click="goToPage"
  >
    <!-- Icon -->
    <span
      v-if="hasIcon"
      class="prj-icon-default mr-1 text-black"
      :class="classIcon"
    >
      <slot name="icon" />
    </span>

    <!-- Text -->
    <span
      class="flex-grow ml-2 text-left"
      :class="classText"
    >
      <slot name="text" />
    </span>

    <!-- Arrow icon -->
    <span
      v-if="!typeIsLink && !hasCounter"
      class="relative z-10 ml-4"
      :class="classArrowIcon"
    >
      <template v-if="disabled">
        <!-- Lock closed -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
          />
        </svg>
      </template>

      <template v-else>
        <!-- Arrow -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </template>
    </span>

    <!-- Circle with number -->
    <span
      v-if="!typeIsLink && hasCounter"
      class="absolute inset-y-2/4 -mt-4.5 right-2.5 rounded-full h-9 w-9 flex items-center justify-center bg-sky-100"
      :class="classArrowIcon"
    >
      <span class="text-base leading-6 font-semibold">{{ counter }}</span>
    </span>

    <!-- Background -->
    <div
      v-if="typeIsMajor"
      class="absolute top-0 right-0 bottom-0 text-black opacity-60 mix-blend-overlay rounded-r-xl overflow-hidden"
    >
      <svg
        class="h-full w-auto"
        viewBox="0 0 102 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M102 90V0H63.5L42 35.7143L14.65 55.5L0.5 90H102Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </button>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
export const SECTION_BUTTON_TYPE_NORMAL = 'normal'
export const SECTION_BUTTON_TYPE_LINK = 'link'
export const SECTION_BUTTON_TYPE_MAJOR = 'major'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1001SectionButton',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: SECTION_BUTTON_TYPE_NORMAL,
    },
    routeName: {
      type: String,
      default: null,
    },
    routeParams: {
      type: Object,
      default: null,
    },
    counter: {
      type: Number,
      default: null,
    },
  },
  computed: {
    classObject() {
      return [
        {
          'px-4 py-4 bg-white': this.typeIsNormal,
          'px-0 py-2': this.typeIsLink,
          'px-4 py-4 w-full bg-fest-green': this.typeIsMajor,
          'filter brightness-90': this.disabled,
          'cursor-pointer': this.routeName,
        },
      ]
    },
    classIcon() {
      return [
        {
          'opacity-50': this.disabled,
        },
      ]
    },
    classText() {
      return [
        {
          'opacity-50': this.disabled,
          'text-base leading-6 font-semibold text-black': this.typeIsLink || this.typeIsNormal,
          'text-xl leading-7 font-bold text-white': this.typeIsMajor,
        },
      ]
    },
    classArrowIcon() {
      return [
        {
          'text-black': this.typeIsLink || this.typeIsNormal,
          'text-white': this.typeIsMajor,
        },
      ]
    },
    typeIsLink() {
      return this.type === SECTION_BUTTON_TYPE_LINK
    },
    typeIsNormal() {
      return this.type === SECTION_BUTTON_TYPE_NORMAL
    },
    typeIsMajor() {
      return this.type === SECTION_BUTTON_TYPE_MAJOR
    },
    hasIcon() {
      return !!this.$slots.icon
    },
    hasCounter() {
      return this.counter !== null
    },
  },
  methods: {
    goToPage() {
      if (this.routeName && !this.disabled)
        this.routeParams
          ? this.$router.push({ name: this.routeName, params: this.routeParams })
          : this.$router.push({ name: this.routeName })
    },
  },
}
</script>
