<script>

import { DESKTOP_BREAKPOINT_SIZE } from '@/constants/app-constants';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('general', [
      'viewportSize'
    ]),
    isDesktopLayout() {
      return this.viewportSize.width >= DESKTOP_BREAKPOINT_SIZE
    }
  }
}
</script>
