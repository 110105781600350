<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout
        :isBackNavEnabled="false"
        classNames="prj-wallpaper-festival"
      >
        <template v-slot:content>
          <div class="flex flex-col h-full max-h-px-768 pt-24 xs:pt-32 pb-5">
            <!--========== TARGETS ================================-->
            <!--===================================================-->
            <header class="mb-5">
              <router-link
                v-if="currentTheme"
                :to="{ name: ROUTE_THEME_DETAIL, params: { id: currentThemeId } }"
              >
                <span class="flex items-center justify-between">
                  <div class="flex flex-col">
                    <t-tag
                      tag-name="h1"
                      class="text-4xl font-extrabold mr-5 mb-0"
                    >
                      <span :class="categoryTxtColor">{{ currentTheme.title }}</span>
                    </t-tag>
                    <span class="text-fest-home pt-1 font-semibold">{{ currentTheme.subtitle }}</span>
                  </div>

                  <span
                    class="p-2 rounded-full text-white"
                    :class="categoryBgColor"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
              </router-link>
              <t-tag
                v-else
                tag-name="h1"
                class="text-4xl font-extrabold text-fest-home mr-5"
              >
                <span
                  class="inline-block mr-2 h-8"
                  v-html="currentThemeCategoryHash"
                ></span>
                <span>{{ $t('fest.dashboard.headline') }}</span>
              </t-tag>
            </header>

            <!--========== TILES ==================================-->
            <!--===================================================-->
            <div class="flex flex-col mb-1 xs:mb-3 md:mb-6">
              <prj1006-cards-wrapper>
                <div class="w-2/6 md:w-1/3 flex-shrink-0 md:flex-shrink first:pl-7 last:pr-7 box-content">
                  <prj1002-tile
                    class="w-full cursor-pointer"
                    :promo="true"
                    :url="newRegistrationUrl"
                    @click.native="pingRegistration('button')"
                  >
                    <template v-slot:icon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-8 w-8"
                        fill="none"
                        viewBox="0 0 28 22"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M18 1.08334V3.91667M18 9.58334V12.4167M18 18.0833V20.9167M3.83333 1.08334H23.6667C24.4181 1.08334 25.1388 1.38185 25.6701 1.9132C26.2015 2.44455 26.5 3.16522 26.5 3.91667V8.16667C25.7486 8.16667 25.0279 8.46518 24.4965 8.99653C23.9652 9.52789 23.6667 10.2486 23.6667 11C23.6667 11.7514 23.9652 12.4721 24.4965 13.0035C25.0279 13.5348 25.7486 13.8333 26.5 13.8333V18.0833C26.5 18.8348 26.2015 19.5555 25.6701 20.0868C25.1388 20.6182 24.4181 20.9167 23.6667 20.9167H3.83333C3.08189 20.9167 2.36122 20.6182 1.82986 20.0868C1.29851 19.5555 1 18.8348 1 18.0833V13.8333C1.75145 13.8333 2.47212 13.5348 3.00347 13.0035C3.53482 12.4721 3.83333 11.7514 3.83333 11C3.83333 10.2486 3.53482 9.52789 3.00347 8.99653C2.47212 8.46518 1.75145 8.16667 1 8.16667V3.91667C1 3.16522 1.29851 2.44455 1.82986 1.9132C2.36122 1.38185 3.08189 1.08334 3.83333 1.08334Z"
                        />
                      </svg>
                    </template>

                    <template v-slot:text>{{ $t('fest.dashboard.tileRegistration') }}</template>
                  </prj1002-tile>
                </div>
                <div class="w-2/6 md:w-1/3 flex-shrink-0 md:flex-shrink first:pl-7 last:pr-7 box-content">
                  <prj1002-tile
                    class="bg-white w-full"
                    :route-name="ROUTE_NEWS"
                  >
                    <template v-slot:icon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-8 w-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                          d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                        />
                      </svg>
                    </template>

                    <template v-slot:text>{{ $t('fest.dashboard.tileNews') }}</template>
                  </prj1002-tile>
                </div>
                <div class="w-2/6 md:w-1/3 flex-shrink-0 md:flex-shrink first:pl-7 last:pr-7 box-content">
                  <prj1002-tile
                    class="bg-white w-full cursor-pointer"
                    @click.native="goToProgram()"
                  >
                    <template v-slot:icon>
                      <svg
                        class="h-8 w-8"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.4167 4.25V9.91667M12.0833 4.25V9.91667M6.41666 15.5833H29.0833M9.25 7.08333H26.25C27.8148 7.08333 29.0833 8.35186 29.0833 9.91667V26.9167C29.0833 28.4815 27.8148 29.75 26.25 29.75H9.25C7.68519 29.75 6.41666 28.4815 6.41666 26.9167V9.91667C6.41666 8.35186 7.68519 7.08333 9.25 7.08333ZM12.0833 21.25H14.9167V24.0833H12.0833V21.25Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </template>

                    <template v-slot:text>{{ $t('fest.dashboard.tilePreparedProgram') }}</template>
                  </prj1002-tile>
                </div>
              </prj1006-cards-wrapper>

              <p>
                <span v-html="$t('fest.dashboard.registrationInfo1')"></span>
                <a
                  class="font-semibold"
                  style="text-decoration: underline"
                  target="_blank"
                  :href="newRegistrationUrl"
                  @click="pingRegistration('link')"
                >{{ $t('fest.dashboard.registrationInfoLink') }}</a>
                <span v-html="$t('fest.dashboard.registrationInfo2')"></span>
              </p>
            </div>
          </div>
        </template>
        <template v-slot:fixed>
          <!--========== MODALS =´===============================-->
          <!--===================================================-->
          <!-- Ticket -->
          <prj1024-emergency
            v-if="overlayEmergency"
            :requiredCheckbox="emergencyIsRequired"
            @closeButton="closeEmergency(emergencyIsRequired)"
          />

          <prj1009-modal :active="overlayTicket">
            <template v-slot:title>
              {{ $t('fest.qrCode.headline') }}
            </template>

            <template v-slot:content>
              <div
                class="mb-10"
              >
                <p>{{ $t('fest.qrCode.descriptionBeforeStart') }}</p>
              </div>
            </template>

            <template v-slot:controls>
              <t-button
                class="bg-fest-home"
                @click="overlayTicket = false"
              >
                {{ $t("fest.forms.buttons.buttonClose") }}
              </t-button>
            </template>
          </prj1009-modal>

          <!-- Identity -->
          <prj1009-modal
            :active="overlayIdentity"
            @overlayCloseEvent="toggleOverlayIdentity"
          >
            <template v-slot:title>
              {{ $t('fest.loginModal.identity.headline') }}
            </template>

            <template v-slot:content>
              <div
                class="mb-10"
              >
                <p>{{ $t('fest.loginModal.identity.description') }}</p>

                <p v-html="$t('fest.loginModal.registrationHelpContact')" />

                <frm1035-form-group>
                  <frm1003-checkbox
                    v-model="employee"
                    :tooltip="false"
                  >
                    {{ $t('fest.loginModal.identity.employeeConfirm') }}
                  </frm1003-checkbox>
                  <p class="text-xs">{{ $t('fest.loginModal.identity.employeeConfirmNote') }}</p>
                </frm1035-form-group>
              </div>
            </template>

            <template v-slot:controls>
              <t-button
                class="bg-fest-home flex items-center justify-center"
                :disabled="!employee || sending"
                @click="clickIdentityEvent()"
              >
                <template v-if="sending">
                  <svg
                    class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                </template>
                {{ $t("fest.loginModal.identity.buttonSubmit") }}
              </t-button>
            </template>
          </prj1009-modal>

          <!-- Sign In -->
          <prj1009-modal
            :active="overlaySignIn"
            @overlayCloseEvent="toggleOverlaySignIn"
          >
            <template v-slot:title>
              {{ $t('fest.loginModal.signIn.headline') }}
            </template>

            <template v-slot:content>
              <div
                class="mb-10"
              >
                <p>{{ $t('fest.loginModal.signIn.description') }}</p>

                <p v-html="$t('fest.loginModal.registrationHelpContact')" />

                <frm1035-form-group>
                  <frm1003-checkbox
                    v-model="employee"
                    :tooltip="false"
                  >
                    {{ $t('fest.loginModal.identity.employeeConfirm') }}
                  </frm1003-checkbox>
                  <p class="text-xs">{{ $t('fest.loginModal.identity.employeeConfirmNote') }}</p>
                </frm1035-form-group>
              </div>
            </template>

            <template v-slot:controls>
              <t-button
                class="bg-fest-home flex items-center justify-center"
                :disabled="!employee || sending"
                @click="clickSignInEvent()"
              >
                <template v-if="sending">
                  <svg
                    class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                </template>
                {{ $t("fest.loginModal.signIn.buttonSubmit") }}
              </t-button>
            </template>
          </prj1009-modal>

          <!-- Sign Out -->
          <prj1009-modal
            :active="overlaySignOut"
            @overlayCloseEvent="toggleOverlaySignOut"
          >
            <template v-slot:title>
              {{ $t('fest.loginModal.signOut.headline') }}
            </template>

            <template v-slot:content>
              <div
                class="mb-10"
              >
                <p>{{ $t('fest.loginModal.signOut.description') }}</p>
              </div>
            </template>

            <template v-slot:controls>
              <t-button
                class="bg-fest-home flex items-center justify-center"
                :disabled="sending"
                @click="clickSignOutEvent()"
              >
                <template v-if="sending">
                  <svg
                    class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                </template>
                {{ $t("fest.loginModal.signOut.buttonSubmit") }}
              </t-button>
            </template>
          </prj1009-modal>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import {mapGetters, mapState} from 'vuex';
import {FEST_THEMES, FEST_COMPETITIONS, FEST_CHALLENGES, FEST_USER} from '@/store/store-modules';
import {
  GET_THEMES_INFO,
  GET_COMPETITIONS_INFO,
  GET_CHALLENGES_INFO
} from '@/store/constants/fest/actions';

import ZapPageLayout from '@/templates/partials/page-layout';
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import {
  SET_USER_EMERGENCY_CONSENT, SET_USER_REGISTERED
} from '@/store/constants/fest/mutations';
import {
  ROUTE_THEME_DETAIL,
  ROUTE_NEWS,
  ROUTE_STORIES,
  ROUTE_THEMES,
  ROUTE_VOTE,
  ROUTE_TRANSPORT
} from '@/constants/fest/route-names';
import categoryConfig from '@/assets/fest/categories/config';
import apiCmsUser from '@/fest-api/cms-user';
import logger from '@/utils/logger';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import dataLoader from '@/utils/data-loader';

import PageLayout from '@/templates/fest/page-layout';
import Prj1002Tile from '@/components/fest/prj1002-tile/prj1002-tile';
import Prj1006CardsWrapper from '@/components/fest/prj1006-cards-wrapper/prj1006-cards-wrapper';
import Prj1009Modal from '@/components/fest/prj1009-modal/prj1009-modal';
import {FESTIVAL_KEY_PREFIX} from '@/constants/app-constants';
import zapUtilsGeneral from '@/utils/utils-general';
import Prj1024Emergency from '@/components/fest/prj1024-emergency/prj1024-emergency';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTDashboard',
  components: {
    Prj1024Emergency,
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1006CardsWrapper,
    Prj1002Tile,
    Prj1009Modal,
    PageLayout,
  },
  mixins: [
    mxNavigationDefault,
    mxDetectDesktop
  ],
  data() {
    return {
      ROUTE_THEME_DETAIL,
      ROUTE_NEWS,
      ROUTE_STORIES,
      ROUTE_THEMES,
      ROUTE_VOTE,
      ROUTE_TRANSPORT,
      categoryConfig,
      isSocialLinksActive: true, // TODO fetch from WP app config
      overlayTicket: false,
      overlayEmergency: false,
      emergencyIsRequired: false,
      overlayIdentity: false,
      overlaySignIn: false,
      overlaySignOut: false,
      sending: false,
      sendFailed: false,
      employee: false
    }
  },
  computed: {
    ...mapState('user', ['profile', 'deviceId']),
    ...mapState('festThemes', ['themesInfo', 'currentTheme']),
    ...mapState('festCompetitions', ['activeCompetitionsCount']),
    ...mapState('festChallenges', ['activeChallengesCount']),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),
    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY
    }),
    ...mapGetters('festUser', ['hasFestivalRegistered', 'hasEmergencyConsent']),
    paramAction() {
      if(typeof this.$route.params?.action === 'undefined') {
        return '';
      }
      return this.$route.params.action
    },
    festConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(FESTIVAL_KEY_PREFIX)) {
            const itemNameWithoutPrefix = item.key.replace(FESTIVAL_KEY_PREFIX, '');
            const itemName = zapUtilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },
    programPageId() {
      return (typeof this.festConfig?.programPageId !== 'undefined' ) ? this.festConfig.programPageId : 0;
    },
    currentThemeId() {
      return this.currentTheme?.id
    },
    currentThemeCategoryId() {
      return this.currentTheme?.categoryId || 0
    },
    currentThemeCategorySettings() {
      return this.categoryConfig?.find((item) => item.id === this.currentThemeCategoryId)
    },
    currentThemeCategoryHash() {
      return this.currentThemeCategorySettings?.svg.hash
    },
    categoryTxtColor() {
      return this.currentThemeCategorySettings?.txtCol
    },
    categoryBgColor() {
      return this.currentThemeCategorySettings?.bgCol
    },
    overlayActivated() {
      return this.overlayTicket || this.overlayIdentity || this.overlaySignIn || this.overlaySignOut || this.overlayEmergency
    },
    emergencyWasConfirmed() {
      return this.hasEmergencyConsent;
    },
    newRegistrationUrl() {
      return (typeof this.festConfig?.newRegistrationLink !== 'undefined' ) ? this.festConfig.newRegistrationLink : '';
    }
  },
  created() {
    this.$store.dispatch(`${FEST_THEMES}/${GET_THEMES_INFO}`)
    this.$store.dispatch(`${FEST_COMPETITIONS}/${GET_COMPETITIONS_INFO}`)
    this.$store.dispatch(`${FEST_CHALLENGES}/${GET_CHALLENGES_INFO}`)
    if (['clickSignInEvent', 'clickSignOutEvent'].includes(this.paramAction)) {
      this[this.paramAction]();
    }
  },
  mounted() {
    window.parent.postMessage('dashboard', '*')
  },
  methods: {
    toggleOverlayEmergency(required = false) {
      this.emergencyIsRequired = required;
      this.overlayEmergency = !this.overlayEmergency;
    },
    closeEmergency(required = false) {
      this.overlayEmergency = false;
      this.emergencyIsRequired = false;

      if (required) {
        this.toggleOverlayTicket();
        this.$store.commit(FEST_USER + '/' + SET_USER_EMERGENCY_CONSENT, true);
      }
    },
    toggleOverlayTicket() {
      this.overlayTicket = !this.overlayTicket;
    },
    toggleOverlaySwitch() {
      if (!this.hasIdentity) {
        this.toggleOverlayIdentity();
      } else {
        if (this.hasFestivalRegistered) {
          this.toggleOverlaySignOut();
        } else {
          this.toggleOverlaySignIn();
        }
      }
    },
    toggleOverlayIdentity() {
      this.overlayIdentity = !this.overlayIdentity;
    },
    toggleOverlaySignIn() {
      this.overlaySignIn = !this.overlaySignIn;
    },
    toggleOverlaySignOut() {
      this.overlaySignOut = !this.overlaySignOut;
    },
    clickIdentityEvent() {
      this.$router.push({ name: 'login', params: {
        authType: 'registration',
        authReferer: 'festival',
        authRefererAction: 'clickSignInEvent',
        forward: true,
        introDescription: this.$i18n.t('intro.message2'),
      }});
    },
    clickSignInEvent() {
      this.sending = true
      apiCmsUser
        .putRegistration()
        .then(() => {
          logger.info('Form has been sent.')
          this.sending = false
          this.$store.commit(FEST_USER + '/' + SET_USER_REGISTERED, true);
          this.overlaySignIn = false;
        })
        .catch((error) => {
          this.sending = false
          this.sendFailed = true
          logger.error('Sending form has failed.', error.response)
        });
    },
    clickSignOutEvent() {
      this.sending = true
      apiCmsUser
        .deleteRegistration()
        .then(() => {
          logger.info('Form has been sent.')
          this.sending = false
          this.$store.commit(FEST_USER + '/' + SET_USER_REGISTERED, false);
          this.overlaySignOut = false;
        })
        .catch((error) => {
          this.sending = false
          this.sendFailed = true
          logger.error('Sending form has failed.', error.response)
        });
    },
    goToProgram() {
      this.$router.push({ name: 'festival-page', params: {
        id: this.programPageId
      }});
    },
    pingRegistration(type) {
      dataLoader.logCustomInfo(this.deviceId, 'festival', type === 'button' ? 'debug1' : 'debug2', 'click new registration redirect to ' + this.newRegistrationUrl);
    },
  },
}
</script>
