<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-c-layout" :class="classObject">
        <slot></slot>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1026Layout',
    props: {
        type: String,
        id: {
            default: null,
            type: String
        }
    },
    computed: {
        classObject() {
            return {
                [`vue-is-${this.type}`]: this.type
            };
        }
    }
};
</script>
