<template>
  <div
    class="vue-c-day-time"
    :class="classObject"
  >
    <span class="vue-b-date">
      <span class="vue-day"><slot name="day">{{ day }}</slot></span>
      <span
        v-if="withDivider"
        class="vue-day-time-divider"
      >, </span>
    </span>
    <span class="vue-time"><slot name="time">{{ time }}</slot></span>
  </div>
</template>

<script>

export default {
  name: 'Prj1028DayTime',

  props: {
    withDivider: {
      type: Boolean,
      default: false
    },
    day: String,
    time: String
  },
  computed: {
    classObject() {
      return { 'vue-has-divider': this.withDivider}
    },
  },
}
</script>
