import { render, staticRenderFns } from "./tbl1007-table-responsive.vue?vue&type=template&id=51c1ec49&"
import script from "./tbl1007-table-responsive.vue?vue&type=script&itemType=application%2Fjavascript&lang=js&"
export * from "./tbl1007-table-responsive.vue?vue&type=script&itemType=application%2Fjavascript&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports