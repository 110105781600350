<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div ref="messageContainer" class="vue-message-container">
        <div :id="contentId" class="vue-message" role="tooltip">
            {{ content }}
        </div>
        <div class="vue-arrow"></div>
        <Frm1006Button
            v-if="buttonClose"
            type="internal"
            :title="buttonCloseCaption"
            class="vue-ci-button-close"
            :tabindex="-1"
            :preventLosingFocus="true"
            @buttonClickEvent="buttonCloseClick"
        >
            {{ buttonCloseCaption }}
        </Frm1006Button>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1010InformationTooltipMessage',
    components: {
        Frm1006Button
    },
    props: {
        expanded: {
            default: null,
            type: Boolean
        },
        content: {
            default: null,
            type: String
        },
        contentId: String,
        buttonClose: {
            default: true,
            type: Boolean
        },
        buttonCloseCaption: {
            default: null,
            type: String
        },
        boundComponentActive: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        //=== EVENTS
        buttonCloseClick() {
            this.$emit('closeEvent', 'button');
        }
    }
};
</script>
