import * as MUTATIONS_CONSTANTS from '../constants/mutations';
import * as ACTIONS_CONSTANTS from '../constants/actions';
import dataLoader from '../../utils/data-loader/index';

const state = {
  npsAreLoaded: false,
  npsFeedBackPages: {},
  npsGraphValues: {},
  npsFilterCategories: {
    years: []
  },
  npsSelectedFilters: {
    years: []
  },
  npsFiltersLoaded: false,
  npsActiveCategory: 0,
  npsAreFiltersActive: false
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_NPS_GRAPH_VALUES](state, graphValues) {
    state.npsGraphValues = graphValues;
  },

  [MUTATIONS_CONSTANTS.SET_NPS_IS_LOADED](state, npsAreLoaded) {
    state.npsAreLoaded = npsAreLoaded;
  },

  [MUTATIONS_CONSTANTS.SET_NPS_FILTER_CATEGORIES](state, npsFilters) {
    state.npsFilterCategories.years = npsFilters.years;
  },

  [MUTATIONS_CONSTANTS.SET_NPS_FILTERS_LOADED](state, npsFiltersAreLoaded) {
    state.npsFiltersLoaded = npsFiltersAreLoaded;
  },

  [MUTATIONS_CONSTANTS.SET_NPS_ACTIVE_CATEGORY](state, activeCategory) {
    state.npsActiveCategory = parseInt(activeCategory);
  },

  [MUTATIONS_CONSTANTS.SET_NPS_FILTER_ACTIVE](state, filterState) {
    state.npsAreFiltersActive = filterState;
  },

  [MUTATIONS_CONSTANTS.SET_NPS_SELECTED_FILTERS](state, selectedFilters) {
    state.npsSelectedFilters.years = selectedFilters.years;
  }
}

const actions = {
  [ACTIONS_CONSTANTS.FETCH_NPS_FILTER_DATA]({ commit }) {
    dataLoader.fetchNpsFiltersData()
      .then(filterData => {
        commit(MUTATIONS_CONSTANTS.SET_NPS_FILTER_CATEGORIES, filterData.data);
        commit(MUTATIONS_CONSTANTS.SET_NPS_FILTERS_LOADED, true);
      });
  },

  [ACTIONS_CONSTANTS.RESET_ALL_NPS_FILTER]({ commit }) {
    const resettedNpsObject = {
      years: []
    }

    commit(MUTATIONS_CONSTANTS.SET_NPS_SELECTED_FILTERS, resettedNpsObject);
    commit(MUTATIONS_CONSTANTS.SET_NPS_ACTIVE_CATEGORY, 0);
    commit(MUTATIONS_CONSTANTS.SET_NPS_FILTER_ACTIVE, false);
  },

  [ACTIONS_CONSTANTS.FETCH_NPS_DATA]({ commit }, options) {
    dataLoader.fetchNpsData(options)
      .then(npsData => {
        commit(MUTATIONS_CONSTANTS.SET_NPS_GRAPH_VALUES, npsData);
        commit(MUTATIONS_CONSTANTS.SET_NPS_IS_LOADED, true);
      })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
