<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar
            class="absolute top-0 left-7 z-10"
            :route-name="backRouteName"
            nav-button-variant="through"
          />
        </template>
        <template v-slot:content>
          <!--========== ARTICLE IMAGE ==========================-->
          <!--===================================================-->
          <prj1003-article-image
            v-if="topicImg"
            :article-image-url="topicImg"
            class="mb-8"
          />

          <!--========== MAIN TOPIC CONTENT =====================-->
          <!--===================================================-->
          <section
            v-if="topic"
            class="prj-article"
          >
            <header class="flex flex-col">
              <t-tag
                tag-name="h1"
                class="order-last"
                variant="blockTitle"
              >
                {{ topic.title }}
              </t-tag>
              <p class="text-2xl leading-7 font-bold my-0">
                <span
                  class="inline-block relative top-1 mr-2 h-6 w-7"
                  v-html="topicCategoryHash"
                ></span><span :class="categoryTxtColor">{{ topicThemeTitle }}</span>
              </p>
            </header>

            <div
              class="mb-8"
              v-html="topic.content"
            />
          </section>

          <!--========== RELATED TOPIC CONTENT ==================-->
          <!--===================================================-->
          <section
            v-if="relatedTopics.length"
            class="pb-4"
          >
            <t-tag
              variant="blockTitle"
              tag-name="h2"
              class="transition-opacity duration-300"
            >
              {{ $t('esg.topics.related') }}
            </t-tag>
            <prj1004-article-list
              :articles="relatedTopics"
              :itemAnimDuration="0.1"
              :pathName="ROUTE_TOPIC_DETAIL"
              classNames="py-2"
            />
          </section>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<script>
import apiCmsTopics from '@/esg-api/cms-topics'
import categoryConfig from '@/assets/esg/categories/config'
import '@/utils/dayjs'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import { mapState } from 'vuex'
import { ESG_THEMES } from '@/store/store-modules'
import { GET_THEMES_INFO } from '@/store/constants/esg/actions'
import { FILTER_THEME } from '@/constants/esg/string-values'
import { ROUTE_TOPIC_DETAIL } from '@/constants/esg/route-names'

import PageLayout from '@/templates/esg/page-layout'
import Prj1003ArticleImage from '@/components/esg/prj1003-article-image/prj1003-article-image'
import Prj1004ArticleList from '@/components/esg/prj1004-article-list/prj1004-article-list'
import Prj1005NavigationBar from '@/components/esg/prj1005-navigation-bar/prj1005-navigation-bar'

export default {
  name: 'ESGTopicDetail',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1003ArticleImage,
    Prj1004ArticleList,
    Prj1005NavigationBar,
    PageLayout,
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_TOPIC_DETAIL,
      topic: null,
      relatedTopics: [],
      categoryConfig,
    }
  },
  computed: {
    ...mapState('esgThemes', ['themesInfo']),
    backRouteName() {
      return this.$route.params.backRoute
    },
    topicId() {
      return parseInt(this.$route.params.id)
    },
    topicCategoryId() {
      return parseInt(this.topic?.categoryId)
    },
    topicThemeId() {
      return parseInt(this.topic?.themeId)
    },
    topicCategorySettings() {
      return this.categoryConfig?.find((item) => item.id === this.topicCategoryId)
    },
    topicCategoryHash() {
      return this.topicCategorySettings?.svg.hash
    },
    categoryTxtColor() {
      return this.topicCategorySettings?.txtCol
    },
    topicThemeTitle() {
      return this.themesInfo?.find((theme) => theme.id === this.topicThemeId)?.title
    },
    topicImg() {
      return this.topic?.image
    },
  },
  watch: {
    '$route' (to, from){
      if (from.name === ROUTE_TOPIC_DETAIL && to.name === ROUTE_TOPIC_DETAIL) {
        this.topic = null;
        this.relatedTopics = [];

        this.getTopicById();
      }
    }
  },
  async created() {
    if (!this.themesInfo.length) await this.$store.dispatch(`${ESG_THEMES}/${GET_THEMES_INFO}`)
    await this.getTopicById() // Must wait for themes fetch to execute getThemeTitle with data
  },
  methods: {
    async getTopicById() {
      const { data, status } = await apiCmsTopics.getTopicById(this.topicId)
      if (status === 200) {
        this.topic = data
        await this.getRelatedTopics(data.themeId)
      }
    },

    async getRelatedTopics(themeId) {
      const { data, status } = await apiCmsTopics.getTopicsByParam({
        perPage: 3,
        topicFilters: [{ filter: FILTER_THEME, id: themeId }],
      })
      if (status === 200)
        this.relatedTopics = data.reduce((acc, item) => {
          return item.id === this.topicId ? acc : [...acc, { ...item, themeTitle: this.getThemeTitle(item) }]
        }, [])
    },

    getThemeTitle(item) {
      return this.themesInfo.find((theme) => theme.id === item.themeId)?.title
    },
  },
}
</script>
