import Frm1015PhoneInput from './frm1015-phone-input';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1015PhoneInput);
    }
};

use(Plugin);

export default Plugin;

export { Frm1015PhoneInput };
