// ONBOARDING
export const USER_ROLE = Object.freeze({});
export const ONBOARDING_INTERVAL_DURATIONS = [];
export const ONBOARDING_APPLICANT_STATUSES = [];
export const ONBOARDING_APPLICANT_DOC_STATUSES = [];
export const ONBOARDING_APPLICANT_CONTRACT_TYPE_OPTIONS = Object.freeze({});

// CHATBOT HR
export const CHATBOT_HR_ABOUT_PAGE_ID = '';
export const CHATBOT_HR_WEB_URL = '';

// PARKING
export const PARKING_CODE_SHQ = '';
export const PARKING_CODE_NHQ = '';
export const PARKING_CODE_HQP = '';
export const PARKING_CODE_HHQ = '';
export const PARKING_CODE_RD3M = '';

export const PARKING_RESERVE_STATE_NEUTRAL = '';
export const PARKING_RESERVE_STATE_LOADING = '';
export const PARKING_RESERVE_STATE_SUCCESS = '';
export const PARKING_RESERVE_STATE_ERROR = '';

export const PARKING_DATE_URL_FORMAT = '';
export const PARKING_RESERVE_LIMIT = 0;

export const PARKING_SUCCESS_CODE = '';
export const PARKING_ERROR_CODE_ALREADY_EXIST = '';
export const PARKING_ERROR_CODE_USER_BLACKLISTED = '';
export const PARKING_ERROR_CODE_BUILDING_CAPACITY_OVERFLOW = '';
export const PARKING_ERROR_CODE_USER_CAPACITY_OVERFLOW = '';
export const PARKING_ERROR_CODE_USER_BUILDING_CAPACITY_OVERFLOW = '';
export const PARKING_ERROR_CODE_BAD_REQUEST = '';
export const PARKING_ERROR_CODE_UNKNOWN_ERROR = '';
