<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout
        :isBackNavEnabled="false"
        classNames="prj-wallpaper-home"
      >
        <template v-slot:content>
          <div class="flex flex-col h-full max-h-px-768 pt-20 xs:pt-24 pb-5">
            <!--========== TARGETS ================================-->
            <!--===================================================-->
            <header class="mb-5">
              <router-link
                v-if="currentTheme"
                :to="{ name: ROUTE_THEME_DETAIL, params: { id: currentThemeId } }"
              >
                <t-tag
                  class="text-lg font-bold mb-2"
                  variant="highlight"
                  tag-name="p"
                >
                  {{ $t('esg.dashboard.currentTheme') }}
                </t-tag>
                <span class="flex items-center justify-between">
                  <t-tag
                    tag-name="h1"
                    class="text-4xl font-extrabold mr-5"
                  >
                    <span
                      class="inline-block relative top-1.5 mr-2 h-10 w-11"
                      v-html="currentThemeCategoryHash"
                    ></span>
                    <span :class="categoryTxtColor">{{ currentTheme.title }}</span>
                  </t-tag>
                  <span
                    class="p-2 rounded-full text-white"
                    :class="categoryBgColor"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
              </router-link>
              <t-tag
                v-else
                tag-name="h1"
                class="text-4xl font-extrabold text-esg-home mr-5"
              >
                <span
                  class="inline-block mr-2 h-8"
                  v-html="currentThemeCategoryHash"
                ></span>
                <span>{{ $t('esg.dashboard.headline') }}</span>
              </t-tag>
            </header>

            <!--========== TILES ==================================-->
            <!--===================================================-->
            <div class="flex flex-col mt-auto mb-1 xs:mb-3 md:mb-6">
              <prj1006-cards-wrapper>
                <div class="w-2/6 md:w-1/3 flex-shrink-0 md:flex-shrink first:pl-7 last:pr-7 box-content">
                  <prj1002-tile
                    class="bg-white w-full"
                    :route-name="ROUTE_NEWS"
                  >
                    <template v-slot:icon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-8 w-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                          d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                        />
                      </svg>
                    </template>

                    <template v-slot:text>{{ $t('esg.dashboard.tileNews') }}</template>
                  </prj1002-tile>
                </div>
                <div class="w-2/6 md:w-1/3 flex-shrink-0 md:flex-shrink first:pl-7 last:pr-7 box-content">
                  <prj1002-tile
                    class="bg-white w-full"
                    :route-name="ROUTE_STORIES"
                  >
                    <template v-slot:icon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-8 w-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                          d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                        />
                      </svg>
                    </template>

                    <template v-slot:text>{{ $t('esg.dashboard.tileOurStories') }}</template>
                  </prj1002-tile>
                </div>
                <div class="w-2/6 md:w-1/3 flex-shrink-0 md:flex-shrink first:pl-7 last:pr-7 box-content">
                  <prj1002-tile
                    class="bg-white w-full"
                    :route-name="ROUTE_THEMES"
                  >
                    <template v-slot:icon>
                      <svg
                        class="h-8 w-8"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.35362 6H7.28125L4 18.1903H6.07362L9.35362 6Z" />
                        <path d="M14.6636 6H12.5859L9.30469 18.1903H11.3836L14.6636 6Z" />
                        <path d="M19.9636 6H17.8906L14.6094 18.1903H16.6836L19.9636 6Z" />
                      </svg>
                    </template>

                    <template v-slot:text>{{ $t('esg.dashboard.tileAreas') }}</template>
                  </prj1002-tile>
                </div>
              </prj1006-cards-wrapper>
            </div>

            <!--========== WHAT ARE WE DOING SECTION ==============-->
            <!--===================================================-->
            <div class="flex flex-col">
              <t-tag class="text-xl leading-7 font-semibold mb-3 xs:mb-5 md:mb-8">
                {{ $t('esg.dashboard.tileWhatAreWeDoing') }}
              </t-tag>

              <prj1001-section-button
                :route-name="ROUTE_CHALLENGES"
                :counter="activeChallengesCount"
                class="bg-white"
              >
                <template v-slot:icon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                    />
                  </svg>
                </template>

                <template v-slot:text>{{ $t('esg.dashboard.tileChallenges') }}</template>
              </prj1001-section-button>

              <prj1001-section-button
                class=""
                :route-name="ROUTE_COMPETITIONS"
                :counter="activeCompetitionsCount"
              >
                <template v-slot:icon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </template>

                <template v-slot:text>{{ $t('esg.dashboard.tileCompetitions') }}</template>
              </prj1001-section-button>

              <prj1001-section-button
                onclick="window.open('https://www.csob.cz/portal/csob/myslime-zelene/uhlikova-stopa', '_blank');"
              >
                <template v-slot:icon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      d="M9 7H15H9ZM15 17V14V17ZM12 17H12.01H12ZM9 17H9.01H9ZM9 14H9.01H9ZM12 14H12.01H12ZM15 11H15.01H15ZM12 11H12.01H12ZM9 11H9.01H9ZM7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21Z"
                      stroke=" currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </template>

                <template v-slot:text>{{ $t('esg.dashboard.tileCalculation') }}</template>
              </prj1001-section-button>

              <prj1001-section-button
                onclick="window.open('https://csob.nevyhazujto.cz/', '_blank');"
              >
                <template v-slot:icon>
                  <svg
                    class="h-6 w-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 3L21 21M4 7H7M11 7H20M10 11V17M14 14V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L18.077 18.077M18.384 14.373L19 7M9 5V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>

                <template v-slot:text>{{ $t('esg.dashboard.tileCleanGarbage') }}</template>
              </prj1001-section-button>
            </div>
          </div>
        </template>

        <template v-slot:footer>
          <div class="flex justify-between items-center mb-6">
            <prj1021-social-links v-if="isSocialLinksActive" />
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex'
import { ESG_THEMES, ESG_COMPETITIONS, ESG_CHALLENGES } from '@/store/store-modules'
import { GET_THEMES_INFO, GET_COMPETITIONS_INFO, GET_CHALLENGES_INFO } from '@/store/constants/esg/actions'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import {
  ROUTE_THEME_DETAIL,
  ROUTE_NEWS,
  ROUTE_STORIES,
  ROUTE_THEMES,
  ROUTE_CHALLENGES,
  ROUTE_COMPETITIONS,
  ROUTE_ABOUT
} from '@/constants/esg/route-names'
import categoryConfig from '@/assets/esg/categories/config'

import PageLayout from '@/templates/esg/page-layout'
import Prj1001SectionButton from '@/components/esg/prj1001-section-button/prj1001-section-button'
import Prj1002Tile from '@/components/esg/prj1002-tile/prj1002-tile'
import Prj1006CardsWrapper from '@/components/esg/prj1006-cards-wrapper/prj1006-cards-wrapper'
import Prj1021SocialLinks from '@/components/esg/prj1021-social-links/prj1021-social-links'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'ESGDashboard',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1006CardsWrapper,
    Prj1002Tile,
    Prj1001SectionButton,
    Prj1021SocialLinks,
    PageLayout,
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_THEME_DETAIL,
      ROUTE_NEWS,
      ROUTE_STORIES,
      ROUTE_THEMES,
      ROUTE_CHALLENGES,
      ROUTE_COMPETITIONS,
      categoryConfig,
      isSocialLinksActive: true // TODO fetch from WP app config
    }
  },
  computed: {
    ...mapState('esgIntro', ['introApp']),
    ...mapState('esgThemes', ['themesInfo', 'currentTheme']),
    ...mapState('esgCompetitions', ['activeCompetitionsCount']),
    ...mapState('esgChallenges', ['activeChallengesCount']),
    currentThemeId() {
      return this.currentTheme?.id
    },
    currentThemeCategoryId() {
      return this.currentTheme?.categoryId || 0
    },
    currentThemeCategorySettings() {
      return this.categoryConfig?.find((item) => item.id === this.currentThemeCategoryId)
    },
    currentThemeCategoryHash() {
      return this.currentThemeCategorySettings?.svg.hash
    },
    categoryTxtColor() {
      return this.currentThemeCategorySettings?.txtCol
    },
    categoryBgColor() {
      return this.currentThemeCategorySettings?.bgCol
    },
  },
  created() {
    // Temporarily disabled, about page article must be fetched dynamically
    this.toIntroPage()
    this.$store.dispatch(`${ESG_THEMES}/${GET_THEMES_INFO}`)
    this.$store.dispatch(`${ESG_COMPETITIONS}/${GET_COMPETITIONS_INFO}`)
    this.$store.dispatch(`${ESG_CHALLENGES}/${GET_CHALLENGES_INFO}`)
  },
  mounted() {
    window.parent.postMessage('dashboard', '*')
  },
  methods: {
    toIntroPage() {
      const introSeen = this.introApp.seen

      if (!introSeen) {
        this.$router.replace({ name: ROUTE_ABOUT })
      }
    },
  },
}
</script>
