import utilsGeneral from '../../../utils/utils-general';
import * as MUTATIONS_CONSTANTS from '../../constants/mutations';

export default {
  [MUTATIONS_CONSTANTS.SET_CONTENT_DATA_UPDATE](state, contentDataUpdate) {
    let date = new Date();
    let timestamp = Number(date);

    state.contentDataUpdate.inProgress = contentDataUpdate.inProgress;

    if (contentDataUpdate.stage) {
      state.contentDataUpdate.stage = contentDataUpdate.stage;
    }

    if (contentDataUpdate.dataPrefetchInfo) {
      state.contentDataUpdate.lastUpdate.dataPrefetchInfo = contentDataUpdate.dataPrefetchInfo;
    }

    state.contentDataUpdate.lastCheck.date = date;
    state.contentDataUpdate.lastCheck.timestamp = timestamp;
  },
  [MUTATIONS_CONSTANTS.UPDATE_ARTICLES](state, payload) {
    let categoryId = payload.type;
    let categoryIdApi = state.contentData[categoryId].slug;
    let itemsCurrent = state.contentData[categoryId].data;

    let itemTypes = utilsGeneral.createContentDataCategoryItemTypes(itemsCurrent, payload.items);
    let categoryData;

    if (categoryId === 'services' || categoryId === 'pages') {
      categoryData = utilsGeneral.updateContentDataCategory(itemTypes.itemsCurrent, itemTypes.itemsToUpdate, itemTypes.itemsToDelete, 0);
    } else {
      categoryData = utilsGeneral.updateContentDataCategory(itemTypes.itemsCurrent, itemTypes.itemsToUpdate, itemTypes.itemsToDelete, 20);
    }

    state.contentData[categoryId].data = categoryData.itemsToCommit;
    state.contentData[categoryId].lastUpdate.date = state.contentDataUpdate.lastUpdate.dataPrefetchInfo[categoryIdApi].modifiedDate;
    state.contentData[categoryId].lastUpdate.timestamp = state.contentDataUpdate.lastUpdate.dataPrefetchInfo[categoryIdApi].modifiedTimestamp;
  },
  [MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_CAFE](state, payload) {
    let categoryIdData = payload.restaurantId;
    state.contentData.restaurants.facilities[categoryIdData].data[payload.items.slug] = payload.items;
  },
  [MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS](state, payload) {
    let categoryIdData = payload.restaurantId;
    let categoryIdApi = state.contentData.restaurants.facilities[categoryIdData].slug;
    let itemsCurrent = state.contentData.restaurants.facilities[categoryIdData].data;

    let itemTypes = utilsGeneral.createContentDataCategoryItemTypes(itemsCurrent, payload.items);
    let categoryData = utilsGeneral.updateContentDataCategory(itemTypes.itemsCurrent, itemTypes.itemsToUpdate, itemTypes.itemsToDelete, 0);

    state.contentData.restaurants.facilities[categoryIdData].data = categoryData.itemsToCommit;
    state.contentData.restaurants.facilities[categoryIdData].lastUpdate.date = state.contentDataUpdate.lastUpdate.dataPrefetchInfo[categoryIdApi].modifiedDate;
    state.contentData.restaurants.facilities[categoryIdData].lastUpdate.timestamp = state.contentDataUpdate.lastUpdate.dataPrefetchInfo[categoryIdApi].modifiedTimestamp;
  },
  [MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_EXTERNAL](state, payload) {
    let categoryIdData = payload.restaurantId;

    if (!utilsGeneral.validateRestaurantDataForCurrentWeek(payload.items.itemsToUpdate)) {
      return false;
    }

    let modifiedDate = new Date();
    let modifiedTimestamp = Math.floor(Number(modifiedDate) / 1000);

    state.contentData.restaurants.facilities[categoryIdData].data = payload.items.itemsToUpdate;
    state.contentData.restaurants.facilities[categoryIdData].lastUpdate.date = modifiedDate;
    state.contentData.restaurants.facilities[categoryIdData].lastUpdate.timestamp = modifiedTimestamp;
  },
  [MUTATIONS_CONSTANTS.UPDATE_RECOMMENDED_POSTS](state, payload) {
    let modifiedDate = new Date();
    let modifiedTimestamp = Number(modifiedDate);

    state.contentData.recommendedNews.data = payload.items;
    state.contentData.recommendedNews.lastUpdate.date = modifiedDate;
    state.contentData.recommendedNews.lastUpdate.timestamp = modifiedTimestamp;
  }
}
