<template>
  <div class="vue-popup-event-filter">
    <!-- TODO MBU: use frm1025 option group -->
    <div
      v-for="(item, index) in eventsCategories"
      :key="item.catName + '-' + index"
      class="vue-popup-event-filter-item"
    >
      <frm1003-checkbox
        v-model="selectedCategories"
        :valueNative="item.catId"
        :tooltip="false"
      >
        {{ item.catName }}
      </frm1003-checkbox>
    </div>

    <frm1006-button
      class="vue-popup-event-filter-button"
      type="primary"
      @buttonClickEvent="filterArticles"
    >
      {{ $t('actions.filterActions') }}
    </frm1006-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'Prj1011PopupEventFilter',
  data() {
    return {
      selectedCategories: []
    }
  },

  computed: {
    ...mapState('events', [
      'eventsCategories',
      'eventsSelectedCategories'
    ])
  },

  mounted() {
    if (this.eventsSelectedCategories.length > 0) {
      this.selectedCategories = this.eventsSelectedCategories;
    }
  },

  methods: {
    filterArticles() {
      this.$store.commit(STORE_MODULES.EVENTS + '/' + MUTATIONS_CONSTANTS.SET_FILTERED_CATEGORIES, this.selectedCategories);
      this.$store.commit(STORE_MODULES.POPUP + '/' + MUTATIONS_CONSTANTS.TOGGLE_POPUP, false);
    }
  }
}
</script>
