<template>
  <div class="vue-workout-list-week">
    <h3 class="vue-headline">
      {{ caption }}
    </h3>

    <div
      id="vue-table-wrapper"
      class="vue-table-wrapper"
      :class="classObject"
      @scroll="handleScroll"
      @load="initScroll"
    >
      <table
        class="vue-table"
      >
        <thead>
          <tr>
            <th />
            <th
              v-for="(columnHead, indexHead) in columns"
              :key="indexHead"
              class="vue-column-caption"
            >
              {{ columnHead }}
            </th>
          </tr>
        </thead>
        <tbody
          class="vue-body"
        >
          <tr
            v-for="(row, indexRow) in rows"
            :key="indexRow"
            class="vue-row"
          >
            <td
              class="vue-row-caption"
            >
              <div
                class="vue-text-wrapper"
              >
                <div
                  class="vue-text"
                >
                  {{ row }}
                </div>
              </div>
            </td>
            <td
              v-for="(column, indexColumn) in columns"
              :key="indexColumn"
              class="vue-box"
            />
          </tr>
          <slot />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'Prj1018WorkoutListWeek',

  props: {
    rows: {
      type: Array,
      required: false
    },

    columns: {
      type: Array,
      required: false
    },

    caption: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      positionScrollLeft: 0,
      elementScrollWidth: 0,
      scrollableLeft: false,
      scrollableRight: false,
    }
  },

  computed: {
    ...mapState('workouts', [
      'workouts',
      'workoutsAreLoaded'
    ]),

    classObject() {
      return {
        'vue-is-scrollable-left': this.scrollableLeft,
        'vue-is-scrollable-right': this.scrollableRight
      }
    }
  },

  created () {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },

  mounted() {
    this.$nextTick().then(this.initScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },

  methods: {
    initScroll() {
      let tableElement = document.getElementById('vue-table-wrapper');

      this.elementScrollWidth = tableElement.scrollWidth - tableElement.offsetWidth;
      this.scrollableRight = tableElement.scrollWidth > tableElement.offsetWidth;
    },

    handleScroll(event) {
      this.positionScrollLeft = event.target.scrollLeft;
      this.elementScrollWidth = event.target.scrollWidth - event.target.offsetWidth;

      this.scrollableLeft = this.positionScrollLeft !== 0;
      this.scrollableRight = this.elementScrollWidth > 0 && this.positionScrollLeft < this.elementScrollWidth;
    }
  }
}
</script>
