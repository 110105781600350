<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="navigateBack()"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template v-if="initialized && !isLoading">
        <template v-if="formCompleted && formSent">
          <div class="vue-b-form-success">
            <gen1006-info-panel
              type="success"
            >
              {{ $i18n.t('marketplace.forms.insertSuccess') }}
            </gen1006-info-panel>
          </div>
        </template>

        <template v-else>
          <div :class="formClassObject">
            <frm1035-form-group v-if="!formCompleted || (formCompleted && formImage)">
              <div class="vue-photo-image-wrap">
                <label>{{ $i18n.t('marketplace.forms.labelImage') }}</label>

                <prj1010-image-upload
                  v-if="!formImage"
                  class="vue-ad-upload-image"
                  :cropper="true"
                  :acceptFiles="['image/jpeg']"
                  :cropper-aspect-ratio="0"
                  @uploadedPhoto="processedPhoto"
                />

                <div class="vue-camera-canvas">
                  <div
                    v-if="formImage || formImageOld"
                    class="vue-ad-uploaded-file"
                  >
                    <img
                      :src="formImage ?? formImageOld"
                      :style="styleObjectImg"
                    />

                    <frm1006-button
                      v-if="!formCompleted"
                      class="vue-button-remove-photo"
                      type="internal"
                      :disabled="!!formImageOld"
                      :iconLeft="true"
                      :captionHidden="true"
                      @buttonClickEvent="removeTakenPicture"
                    >
                      {{ $t('marketplace.forms.buttonRemovePhoto') }}
                    </frm1006-button>
                  </div>
                </div>
              </div>
            </frm1035-form-group>

            <frm1035-form-group v-if="!formCompleted || (formCompleted && formName)">
              <frm1001-input-field
                id="name"
                v-model="formName"
                :disabled="formCompleted"
                :tooltip="false"
                :label="$i18n.t('marketplace.forms.labelName')"
                @inputChangeEvent="validateForm"
              />
            </frm1035-form-group>

            <frm1035-form-group v-if="!formCompleted || (formCompleted && formDescription)">
              <frm1013-textarea
                id="description"
                v-model="formDescription"
                :disabled="formCompleted"
                :tooltip="false"
                :label="$i18n.t('marketplace.forms.labelDescription')"
                :rowsAutoExpand="true"
                :rows="1"
                :rowsAutoExpandMinRows="1"
                :rowsAutoExpandMaxRows="5"
                @textAreaUpdateEvent="validateForm"
              />
            </frm1035-form-group>

            <frm1035-form-group
              v-if="!formCompleted || (formCompleted && formPrice)"
              class="vue-form-group-price"
            >
              <frm1001-input-field
                id="price"
                v-model="formPrice"
                input-type="number"
                :disabled="formCompleted"
                :tooltip="false"
                :label="$i18n.t('marketplace.forms.labelPrice')"
                @inputChangeEvent="validateForm"
              />
              <span class="vue-price-currency">{{ $i18n.t('marketplace.forms.labelCurrency') }}</span>
            </frm1035-form-group>

            <frm1035-form-group v-if="!formCompleted || (formCompleted && formCategory)">
              <frm1028-advanced-combo
                id="category"
                v-model="formCategory"
                :disabled="formCompleted"
                :options="categoryList"
                :tooltip="false"
                :label="$i18n.t('marketplace.forms.labelCategory')"
                :placeholder="$i18n.t('marketplace.forms.placeholderCategory')"
                @contextContainerExpandedChangeEvent="validateForm"
              />
            </frm1035-form-group>

            <frm1035-form-group v-if="!formCompleted || (formCompleted && formCondition)">
              <frm1028-advanced-combo
                id="condition"
                v-model="formCondition"
                :disabled="formCompleted"
                :options="conditionList"
                :tooltip="false"
                :label="$i18n.t('marketplace.forms.labelCondition')"
                :placeholder="$i18n.t('marketplace.forms.placeholderCondition')"
                @contextContainerExpandedChangeEvent="validateForm"
              />
            </frm1035-form-group>

            <frm1035-form-group v-if="!formCompleted || (formCompleted && formLocation)">
              <frm1028-advanced-combo
                id="location"
                v-model="formLocation"
                :disabled="formCompleted"
                :options="locationList"
                :tooltip="false"
                :label="$i18n.t('marketplace.forms.labelLocality')"
                :placeholder="$i18n.t('marketplace.forms.placeholderLocality')"
                @contextContainerExpandedChangeEvent="validateForm"
              />
            </frm1035-form-group>

            <frm1035-form-group v-if="!formCompleted || (formCompleted && formDuration)">
              <frm1028-advanced-combo
                id="duration"
                v-model="formDuration"
                :disabled="formCompleted"
                :options="durationList"
                :tooltip="false"
                :label="$i18n.t('marketplace.forms.labelDuration')"
                :placeholder="$i18n.t('marketplace.forms.placeholderDuration')"
                @contextContainerExpandedChangeEvent="validateForm"
              />
            </frm1035-form-group>

            <div
              v-if="formCompleted"
              class="vue-b-contact-data"
            >
              <h2>{{ $i18n.t('marketplace.labelContact') }}</h2>

              <frm1035-form-group>
                <frm1001-input-field
                  id="email"
                  v-model="formEmail"
                  :disabled="true"
                  :tooltip="false"
                  :label="$i18n.t('marketplace.forms.labelEmail')"
                />
              </frm1035-form-group>

              <frm1035-form-group>
                <template v-if="formPhone.length > 2">
                  <frm1001-input-field
                    id="phone"
                    v-model="formPhone"
                    :disabled="true"
                    :tooltip="false"
                    :label="$i18n.t('marketplace.forms.labelPhoneNumber')"
                  />
                </template>
                <template v-else>
                  <frm1006-button
                    type="primary2"
                    :iconLeft="true"
                    :captionHidden="false"
                    class="vue-button-phone"
                    @buttonClickEvent="clickButtonPhone"
                  >
                    {{ $t('marketplace.forms.buttonAddPhone') }}
                  </frm1006-button>
                </template>
              </frm1035-form-group>
            </div>

            <frm1035-form-group v-if="formCompleted && !pageId">
              <frm1003-checkbox
                v-model="formConsent"
                :tooltip="false"
                @checkboxChangeEvent="validateForm"
              >
                {{ $t('marketplace.forms.consents.personalData') }}
                <a
                  class="prj-text-link cursor-pointer"
                  href="#"
                  @click="clickButtonRules()"
                >{{ $t("marketplace.forms.consents.personalDataLink") }}</a>
                {{ $t('marketplace.forms.consents.personalDataEnd') }}
              </frm1003-checkbox>
            </frm1035-form-group>
          </div>
        </template>
      </template>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <template
        v-if="formCompleted && formSent"
      >
        <frm1006-button
          type="primary"
          @buttonClickEvent="goToMyAds"
        >
          {{ $t('marketplace.forms.buttonClose') }}
        </frm1006-button>
      </template>

      <template v-else-if="formCompleted && !formSent">
        <frm1006-button
          v-if="(!pageId && !formExpired) || (pageId && formExpired)"
          type="primary"
          :disabled="!isFormValid || sending || formSent"
          @buttonClickEvent="submitForm"
        >
          {{ $t('marketplace.forms.buttonPublish') }}
        </frm1006-button>

        <frm1006-button
          v-if="pageId && !formExpired"
          type="primary"
          :disabled="!isFormValid || sending || formSent"
          @buttonClickEvent="submitForm"
        >
          {{ $t('marketplace.forms.buttonSave') }}
        </frm1006-button>
      </template>

      <template v-else>
        <frm1006-button
          :class="classObject"
          type="primary"
          :disabled="!isFormValid || sending || formSent"
          @buttonClickEvent="goToReview"
        >
          {{ $t('marketplace.forms.buttonNext') }}
        </frm1006-button>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import dataLoader from '@/utils/data-loader/index';
import logger from '@/utils/logger';
import {
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1010ImageUpload from '@/components/prj1010-image-upload/prj1010-image-upload';

import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import { MARKETPLACE } from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'MarketplaceForm',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1010ImageUpload,
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      hasError: false,
      formData: {
        buildings: [],
        categories: [],
      },
      refElements: {
        player: null,
        canvas: null,
        fileUpload: null
      },
      pictureOptions: {
        camera: false,
        fileUpload: true
      },
      sending: false,
      formSent: false,
      formFailed: false,
      isFormValid: false,
      isLoading: true,
      initialized: false
    }
  },

  computed: {
    ...mapState('user', ['profile']),
    ...mapState('marketplace', [
      'marketplaceForm',
      'marketplaceFilterCategories',
      'marketplaceSelectedCategories',
      'marketplaceActiveCategory',
      'marketplaceAreFiltersActive',
      'detailData'
    ]),

    pageId() {
      return this.$route.params.id;
    },

    paramPreload() {
      if(typeof this.$route.params?.preload === 'undefined') {
        return 'none';
      }
      return this.$route.params.preload
    },

    formId: {
      get () {
        return this.marketplaceForm.id;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_ID, value);
      }
    },

    formName: {
      get () {
        return this.marketplaceForm.name;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_NAME, value);
      }
    },

    formDescription: {
      get () {
        return this.marketplaceForm.description;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_DESCRIPTION, value);
      }
    },

    formPrice: {
      get () {
        return this.marketplaceForm.price;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_PRICE, value);
      }
    },

    formCategory: {
      get () {
        return this.marketplaceForm.category;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_CATEGORY, value);
      }
    },

    formCondition: {
      get () {
        return this.marketplaceForm.condition;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_CONDITION, value);
      }
    },

    formLocation: {
      get () {
        return this.marketplaceForm.location;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_LOCATION, value);
      }
    },

    formDuration: {
      get () {
        return this.marketplaceForm.duration;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_DURATION, value);
      }
    },

    formExpired: {
      get () {
        return this.marketplaceForm.expired;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_EXPIRED, value);
      }
    },

    formConsent: {
      get () {
        return this.marketplaceForm.consent;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_CONSENT, value);
      }
    },

    formImage: {
      get () {
        return this.marketplaceForm.image;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_IMAGE, value);
      }
    },

    formImageOld: {
      get () {
        return this.marketplaceForm.imageOldPath;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_IMAGE_OLD, value);
      }
    },

    formCompleted: {
      get () {
        return this.marketplaceForm.completed;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_COMPLETED, value);
      }
    },

    formPhone: {
      get () {
        return this.profile?.phone;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_PHONE, value);
      }
    },

    formEmail: {
      get () {
        return this.profile.workEmail;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_WORK_EMAIL, value);
      }
    },

    classObject() {
      return {
        'vue-is-active': this.isFormValid,
      }
    },

    styleObjectImg() {
      return {
        maxWidth: '200px',
        maxHeight: '200px',
      }
    },

    formClassObject() {
      return {
        'vue-b-form': !this.formCompleted && !this.formSent,
        'vue-b-form-review': this.formCompleted && !this.formSent,
      }
    },

    navbarCaption() {
      let retValue = this.$route.meta.navbarCaption;
      if (this.pageId) {
        retValue = this.$i18n.t('marketplace.forms.editAdCaption')
      }
      if (this.formCompleted && this.formSent) {
        retValue = ''
      }
      return retValue;
    },

    categoryList() {
      let retValue = [];
      Object.values(this.marketplaceFilterCategories.categories).forEach((item) => {
        retValue.push({ value: (item.id).toString(), caption: item.value });
      });
      return retValue;
    },

    locationList() {
      let retValue = [];
      Object.values(this.marketplaceFilterCategories.locations).forEach((item) => {
        retValue.push({ value: (item.id).toString(), caption: item.value });
      });
      return retValue;
    },

    durationList() {
      let retValue = [];
      Object.values(this.marketplaceFilterCategories.durations).forEach((item) => {
        retValue.push({ value: (item.id).toString(), caption: item.value });
      });
      return retValue;
    },

    conditionList() {
      let retValue = [];
      Object.values(this.marketplaceFilterCategories.conditions).forEach((item) => {
        retValue.push({ value: (item.id).toString(), caption: item.value });
      });
      return retValue;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },
  },

  created() {
    if (this.detailData) {
      this.loadData();
    }
    this.$store.dispatch(STORE_MODULES.MARKETPLACE + '/' + ACTIONS_CONSTANTS.FETCH_MARKETPLACE_FILTER_DATA);
    this.sendStatistics(MARKETPLACE);
    this.initialized = true;
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    validateForm() {
      let decision = false;

      if (this.formName.length > 0 &&
        this.formDescription.length > 0 &&
        this.formPrice.length > 0 &&
        this.formCategory.length > 0 &&
        this.formCondition.length > 0 &&
        this.formLocation.length > 0 &&
        this.formDuration.length > 0) {
        decision = true;
      }

      if (decision && this.formCompleted) {
        decision = this.formConsent;
      }

      this.isFormValid = decision;
    },

    processedPhoto(photoObject) {
      this.formImage = photoObject;
    },

    removeTakenPicture() {
      this.formImage = null;
    },

    submitForm() {
      let formData = new FormData();
      formData.append('name', this.formName);
      formData.append('description', this.formDescription);
      formData.append('price', this.formPrice);
      formData.append('category', this.formCategory);
      formData.append('condition', this.formCondition);
      formData.append('location', this.formLocation);
      formData.append('duration', this.formDuration);
      formData.append('republish', Number(this.formExpired));
      formData.append('consent', Number(this.formConsent));
      formData.append('id', this.formId);
      formData.append('image', this.formImage);
      this.sending = true;

      dataLoader.sendMarketplaceAd(
        formData
      ).then(() => {
        logger.info('Ad has been sent!');
        this.formSent = true;
      }).catch(error => {
        logger.error('Sending ad has failed:', error);
        this.formFailed = true;
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.sending = false;
      })
    },

    navigateBack() {
      if (this.formCompleted && !this.formSent) {
        this.formCompleted = false;
      } else {
        this.$router.go(-1);
      }
      this.validateForm();
    },

    goToMyAds() {
      this.$router.push({ name: 'marketplace-list-my' })
    },

    goToReview() {
      this.validateForm();
      this.$nextTick(() => {
        this.formCompleted = true;
      })
    },

    clearForm() {
      this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_RESET);
      this.formSent = false;
      this.hasError = false;
    },

    loadData() {
      if (parseInt(this.detailData.id) === parseInt(this.pageId) && this.detailData.owner) {
        this.formId = parseInt(this.detailData.id);
        this.formName = this.detailData.name;
        this.formDescription = this.detailData.description;
        this.formPrice = this.detailData.price;
        this.formCategory = this.detailData.categoryId + '';
        this.formCondition = this.detailData.condition + '';
        this.formLocation = this.detailData.location + '';
        this.formDuration = this.detailData.duration + '';
        this.formExpired = this.detailData.expired;
        this.formConsent = !!this.detailData.consent;
        this.formImageOld = this.detailData.image;
        this.validateForm();
      }
    },

    clickButtonRules() {
      this.$router.push({ name: 'marketplace-rules' })
      return false;
    },

    clickButtonPhone() {
      this.$router.push({ name: 'profile-edit-phone', params: { referrer: 'marketplace-form' } })
    }
  }
}
</script>
