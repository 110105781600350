<template>
  <div>
    <div
      class="vue-restaurant-detail-headline-image"
      :class="classObject"
      :style="getFoodImage()"
    />
    <div class="vue-restaurant-detail-body">
      <h2 class="vue-restaurant-detail-headline">
        {{ selectedMeal.name | makeSentence }}
      </h2>

      <div class="vue-restaurant-detail-info">
        <div
          v-if="!isSoup && displayRating"
        >
          <span
            v-if="!ratedToday"
            class="vue-restaurant-detail-rating-icon"
            @click="openRatingModal"
          >
            {{ $t('meals.rateMeal') }}
          </span>

          <span v-else>
            {{ $t('meals.ratedMeal') }}
          </span>
        </div>

        <div
          v-if="typeof selectedMeal.price !== 'undefined' && selectedMeal.price.toString().length > 0"
          class="vue-restaurant-detail-price"
        >
          {{ selectedMeal.price }}
          <span
            v-if="!hasContainingCurrency && 1 === 0"
          >
            {{ $t('general.czechCurrency') }}
          </span>
        </div>
      </div>

      <div class="vue-restaurant-detail-body-divider" />

      <div
        v-if="selectedMeal.pictures.pictograms.length > 0"
        class="vue-restaurant-detail-pictograms"
      >
        <img
          v-for="(image, index) in selectedMeal.pictures.pictograms"
          :key="'pictogram' + index"
          :src="image"
          @click="goToPictogramsList()"
        />
      </div>

      <p
        v-if="typeof selectedMeal.attributes !== 'undefined' && selectedMeal.attributes.length > 0"
        class="vue-restaurant-detail-additional-info"
      >
        {{ $t('meals.energyValue') }}: {{ selectedMeal.attributes }}
      </p>
      <p
        v-if="typeof selectedMeal.alergens !== 'undefined' && selectedMeal.alergens.length > 0"
        class="vue-restaurant-detail-additional-info"
      >
        {{ $t('meals.allergens') }}: {{ selectedMeal.alergens }}
        <span
          class="vue-restaurant-detail-additional-info-icon"
          @click="goToAllergensList"
        />
      </p>
      <p
        v-if="typeof selectedMeal.weight !== 'undefined' && selectedMeal.weight.length > 0"
        class="vue-restaurant-detail-additional-info"
      >
        {{ $t('meals.weight', { weight: selectedMeal.weight }) }}
      </p>
      <p
        v-if="typeof selectedMeal.alergens !== 'undefined' && selectedMeal.alergens.length > 0"
        class="vue-restaurant-detail-additional-info"
      >
        {{ $t('meals.allergensInfo') }}
      </p>
    </div>

    <gen1009-overlay
      :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
      :active.sync="showRating"
      :positionCustom="contextContainerPositions"
    >
      <div
        class="vue-restaurant-detail-rating"
      >
        <h3>{{ $t('meals.yourMealRating') }}</h3>
        <prj1008-item-rating
          @rated="sendRating"
        />
      </div>
    </gen1009-overlay>

    <gen1009-overlay
      class="vue-has-no-padding"
      :active.sync="showRatingResult"
    >
      <gen1006-info-panel
        v-if="sendFoodRating"
        type="success"
      >
        {{ $t('meals.thankYou') }}
      </gen1006-info-panel>

      <gen1006-info-panel
        v-if="failedFoodRating"
        type="error"
      >
        {{ $t('meals.ratingFailed') }}
      </gen1006-info-panel>
    </gen1009-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import router from '@/router';
import dataLoader from '@/utils/data-loader';
import utilsGeneral from '@/utils/utils-general';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

import Prj1008ItemRating from '@/components/prj1008-item-rating/prj1008-item-rating';

export default {
  name: 'Prj1007FoodDetail',
  components: {
    Prj1008ItemRating
  },

  mixins: [
    mxDetectDesktop
  ],

  props: {
    selectedMeal: {
      type: Object,
      required: true
    },
    restaurantsToday: {
      type: Object,
      required: false
    },
    displayRating: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      showRating: false,
      sendFoodRating: false,
      failedFoodRating: false,
      showRatingResult: false,
      contextContainerPositions: false
    }
  },

  computed: {
    ...mapState('persistentStorage', [
      'yearAndWeek',
      'lastFoodRatingTimestamp'
    ]),
    ...mapState('restaurants', [
      'selectedRestaurant'
    ]),
    ...mapState('user', [
      'deviceId'
    ]),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),

    allergensListArticleId() {
      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'ALLERGENS_LIST_ARTICLE_ID') {
            return item.value;
          }
        });
      }
      return -1;
    },

    selectedMealId() {
      return this.$route.params.id;
    },

    ratedToday() {
      return utilsGeneral.checkTimestampIsToday(this.lastFoodRatingTimestamp);
    },

    selectedMealObject() {
      return {
        id: this.selectedMeal.id,
        name: this.selectedMeal.name,
        price: this.selectedMeal.price,
        returnBox: 0,
        packagePrice: this.selectedMeal.package_price,
        image: this.selectedMeal.pictures.small,
        weight: this.selectedMeal.weight?.toString()
      }
    },

    isSoup() {
      if (typeof this.restaurantsToday !== 'undefined') {
        const categoryName = this.restaurantsToday.categories[this.selectedMealId].category_name;

        return categoryName.toLowerCase().includes('polévka') || categoryName.toLowerCase().includes('soup');
      } else {
        return false;
      }
    },

    hasContainingCurrency() {
      return this.selectedMeal.price?.toString().includes('Kč');
    },

    classObject() {
      return {
        'vue-restaurant-detail-headline-image-placeholder': this.isSoup
      }
    }
  },

  watch: {
    showRating(value) {
      if (!value) {
        this.closeRatingModal();
      }
    }
  },

  methods: {
    getFoodImage() {
      if (typeof this.selectedMeal.pictures.large !== 'undefined') {
        return `background-image: url("${this.selectedMeal.pictures.large}")`;
      }
      return '';
    },

    getUniqueStringByTimestamp() {
      let now = new Date();

      if (!this.uniqueFoodImageUrlSuffix) {
        this.uniqueFoodImageUrlSuffix = now.getTime();
      } else {
        let compare = now.getTime() - (5 * 60 * 1000);

        if (compare > this.uniqueFoodImageUrlSuffix) {
          this.uniqueFoodImageUrlSuffix = now.getTime();
        }
      }

      return this.uniqueFoodImageUrlSuffix;
    },

    openRatingModal(event) {
      if (this.isDesktopLayout) {
        this.contextContainerPositions = {
          x: event.pageX,
          y: event.pageY,
          mode: 'top-left'
        }
      } else {
        this.contextContainerPositions = false
      }
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.showRating = true;
      this.$emit('openedRatingModal');
    },

    closeRatingModal() {
      this.$emit('closedRatingModal');
    },

    sendRating(ratingValue) {
      let ratingDataObject = {
        foodIndex: this.selectedMealId,
        score: ratingValue,
        name: this.selectedMeal.name,
        category: this.restaurantsToday.categories[this.selectedMealId].category_name,
        restaurant: this.selectedRestaurant,
        deviceId: this.deviceId
      }

      dataLoader.sendFoodRating(ratingDataObject)
        .then(resolve => {
          if (resolve) {
            this.showRatingResult = true;
            this.sendFoodRating = true;
            this.$store.commit(STORE_MODULES.PERSISTENT_STORAGE + '/' + MUTATIONS_CONSTANTS.SET_LAST_FOOD_RATING_TIMESTAMP);
          }
        })
        .catch(() => {
          this.showRatingResult = true;
          this.failedFoodRating = true;
        })
        .finally(() => {
          this.showRating = false;
          setTimeout(() => {
            this.sendFoodRating = false;
            this.failedFoodRating = false;
            this.showRatingResult = false;
          }, 4000);
        });
    },

    goToAllergensList() {
      router.push({ name: 'news', params: { id: this.allergensListArticleId, navbarButtonMode: 'history' } })
    },

    goToPictogramsList() {
      router.push({ name: 'restaurants-pictograms'})
    }
  }
}
</script>
