<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    id="vue-page-service"
    class="vue-page vue-page-service"
  >
    <component :is="component" />
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'PageService',
  components: {},
  props: {
    component: [Object, Function],
  }
};
</script>
