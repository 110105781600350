<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div class="vue-b-adaptation-dashboard">
          <div class="vue-adaptation-header">
            <h1 class="vue-adaptation-title">
              {{ $t('adaptation.dashboardTitle') }}
            </h1>
          </div>

          <div class="vue-dashboard-tiles">
            <frm1006-button
              class="vue-dashboard-item-tile vue-tile-adaptation-first-day"
              type="tile"
              @buttonClickEvent="goToView('adaptation-first-day')"
            >
              <template v-slot:iconLeft>
                <div class="vue-tile-icon" />
              </template>
              {{ $t('adaptation.firstDay') }}
            </frm1006-button>

            <frm1006-button
              class="vue-dashboard-item-tile vue-tile-adaptation-intro"
              type="tile"
              @buttonClickEvent="goToView('adaptation-intro')"
            >
              <template v-slot:iconLeft>
                <div class="vue-tile-icon" />
              </template>
              {{ $t('adaptation.intro') }}
            </frm1006-button>

            <frm1006-button
              class="vue-dashboard-item-tile vue-tile-adaptation-benefits"
              type="tile"
              @buttonClickEvent="goToView('adaptation-benefits')"
            >
              <template v-slot:iconLeft>
                <div class="vue-tile-icon" />
              </template>
              {{ $t('adaptation.benefits') }}
            </frm1006-button>

            <frm1006-button
              class="vue-dashboard-item-tile vue-tile-adaptation-contacts"
              type="tile"
              @buttonClickEvent="goToView('adaptation-contacts')"
            >
              <template v-slot:iconLeft>
                <div class="vue-tile-icon" />
              </template>
              {{ $t('adaptation.contacts') }}
            </frm1006-button>
          </div>
        </div>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT,
  ADAPTATION_DASHBOARD_ID,
} from '@/constants/app-constants';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import { mapState } from 'vuex';

export default {
  name: 'AdaptationDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault,
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      categories: [],
      applicant: {},
      daysToBoarding: ''
    };
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },
  },

  mounted() {
    this.getOnboardingDashboardCategories();
    this.initialized = true;
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    goToView(viewName) {
      if (viewName === 'adaptation-contacts' && this.isPrivateApp) {
        this.$router.push({
          name: 'adaptation-contacts',
          params: {categoryId: this.getCategoryId(viewName), categorySlug: this.getCategorySlug(viewName)}
        })
      } else {
        this.$router.push({ name: 'adaptation-detail', params: {categoryId: this.getCategoryId(viewName), categorySlug: this.getCategorySlug(viewName)} });
      }
    },

    getOnboardingDashboardCategories() {
      this.setLoadingState(true);

      return dataLoader.fetchApiDataList(ADAPTATION_DASHBOARD_ID)
        .then(data => {
          this.categories = data.adaptationCategories;
        })
        .catch(error => {
          logger.error(error);
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    getCategoryId(viewName) {
      return this.categories.find(({categoryName}) => categoryName === viewName).categoryId
    },

    getCategorySlug(viewName) {
      return viewName.replace('adaptation-', '');
    },
  },
}
</script>
