import * as MUTATIONS_CONSTANTS from '../constants/mutations';
import * as ACTIONS_CONSTANTS from '../constants/actions';

const state = {
  categories: {},
  activePopupModule: '',
  isPopupVisible: false,
  popupPosition: false
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_CATEGORIES](state, categories) {
    state.categories = categories;
  },
  [MUTATIONS_CONSTANTS.TOGGLE_POPUP](state, popupState) {
    state.isPopupVisible = popupState;
  },
  [MUTATIONS_CONSTANTS.SET_ACTIVE_POPUP_MODULE](state, whitePopupModule) {
    state.activePopupModule = whitePopupModule;
  },
  [MUTATIONS_CONSTANTS.SET_POPUP_POSITION](state, popupPosition) {
    state.popupPosition = popupPosition;
  },
};

const actions = {
  [ACTIONS_CONSTANTS.TOGGLE_POPUP]({ commit }, popupModule) {
    commit(MUTATIONS_CONSTANTS.SET_ACTIVE_POPUP_MODULE, popupModule);
    commit(MUTATIONS_CONSTANTS.TOGGLE_POPUP, true);
  },
  [ACTIONS_CONSTANTS.POPUP_POSITION]({ commit }, popupPosition) {
    commit(MUTATIONS_CONSTANTS.SET_POPUP_POSITION, popupPosition);
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
