<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <a
    v-if="url !== ''"
    class="flex flex-col items-center h-28 xs:h-32 px-4 justify-evenly rounded-xl"
    target="_blank"
    :class="{'cursor-pointer': url, 'bg-fest-home': promo, 'bg-white': !promo}"
    :href="url"
  >
    <span
      v-if="hasIcon"
      class="relative flex items-end top-3 h-2/6"
      :class="promoTextColor"
    >
      <slot name="icon" />
    </span>

    <span
      class="flex items-center h-4/6 text-center text-base leading-6 font-semibold"
      :class="promoTextColor"
    >
      <slot name="text" />
    </span>
  </a>
  <div
    v-else
    class="flex flex-col items-center h-28 xs:h-32 px-4 justify-evenly rounded-xl"
    :class="{'cursor-pointer': routeName, 'bg-fest-home': promo, 'bg-white': !promo}"
    @click="goToPage"
  >
    <span
      v-if="hasIcon"
      class="relative flex items-end top-3 h-2/6"
      :class="promoTextColor"
    >
      <slot name="icon" />
    </span>

    <span
      class="flex items-center h-4/6 text-center text-base leading-6 font-semibold"
      :class="promoTextColor"
    >
      <slot name="text" />
    </span>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1002Tile',
  components: {},
  props: {
    routeName: String,
    promo: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    hasIcon() {
      return !!this.$slots.icon;
    },
    promoTextColor() {
      return this.promo ? 'text-white' : 'text-black'
    }
  },
  methods: {
    goToPage() {
      if (this.routeName) {
        this.$router.push({ name: this.routeName });
      }
    }
  }
}
</script>
