<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== HEADER =================================-->
    <!--===================================================-->
    <template v-slot:header>
      <div
        v-if="workoutsAreLoaded"
        class="vue-image-wrapper"
      >
        <div
          class="vue-image"
          :class="classObject"
          :style="styleObject"
        />
        <span class="vue-tag">{{ $t('workouts.caption') }}</span>
      </div>
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        v-if="workoutsAreLoaded"
        class="vue-workout-detail"
      >
        <div
          class="vue-detail-content"
          :class="classObject"
        >
          <h1 class="vue-heading">
            {{ getSelectedWorkout.name }}
          </h1>

          <div class="vue-duration">
            <div v-html="getQuartalLessonsDateTime" />
          </div>

          <div class="vue-place">
            {{ getSelectedWorkout.building }} / {{ getSelectedWorkout.place }}
          </div>

          <div class="vue-description">
            {{ getSelectedWorkout.description }}
          </div>

          <prj1021-profile-card
            :fullName="getSelectedWorkout.trainerName"
            :email="getSelectedWorkout.trainerEmail"
            :profession="$t('workouts.trainerProfession')"
            :avatar="getSelectedWorkout.trainerAvatarPath"
          />
        </div>
      </div>

      <gen1006-info-panel
        v-if="!workoutsAreLoaded && !selectedWorkout.length"
        type="neutral"
      >
        {{ $t('workouts.workoutDetailNotAvailable') }}
      </gen1006-info-panel>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
      <frm1006-button
        class="vue-button-confirm vue-button-page-layout-fixed"
        mode="anchor"
        :link="getRegistrationUrl"
        :linkNewWindow="true"
      >
        {{ $t('workouts.register') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="false"
      >
        <gen1006-info-panel type="error">
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import utilsGeneral from '@/utils/utils-general';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxEventDate from '@/mixins/mx-event-date';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1021ProfileCard from '@/components/prj1021-profile-card/prj1021-profile-card';

export default {
  name: 'WorkoutDetail',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1021ProfileCard
  },

  mixins: [
    mxNavigationDefault,
    mxEventDate
  ],

  data() {
    return {
      selectedWorkout: {},
      hasError: false
    }
  },

  computed: {
    ...mapState('workouts', [
      'workouts',
      'workoutsOptions',
      'workoutsAreLoaded',
      'workoutsHasError',
      'selectedBuilding',
      'selectedQuartal'
    ]),

    classObject() {
      return {
        'vue-image-placeholder': this.getSelectedWorkout.imagePath === ''
      }
    },

    styleObject() {
      return {
        backgroundImage: this.getSelectedWorkout.imagePath === '' ? false : this.getSelectedWorkout.imagePath,
      }
    },

    getIdOfPage() {
      return this.$route.params.id
    },

    getDisabledChangeTerm() {
      return !!this.$route.params.changeTermDisabled
    },

    getSelectedWorkout() {
      return this.selectedWorkout;
    },

    getRegistrationUrl() {
      return this.selectedWorkout.registrationUrl ? this.selectedWorkout.registrationUrl : 'mailto: ' + this.selectedWorkout.trainerEmail;
    },

    getDayName() {
      let numOfDay = parseInt(this.getSelectedWorkout.dayNum) - 1;
      return this.$i18n.t('time.days.' + numOfDay);
    },

    getQuartalLessonsDateTime() {
      return this.$i18n.t('workouts.quartalyLessonsDateTime', {
        dayName: this.getDayName,
        workoutStartTime: this.workoutStartTime,
        workoutEndTime: this.workoutEndTime,
        workoutsNextQuartalFrom: this.workoutsQuartalFrom,
        workoutsNextQuartalTo: this.workoutsQuartalTo
      });
    },

    workoutsQuartalFrom() {
      let quartalFrom = this.selectedQuartal === this.workoutsOptions.currentlyQuartalNum + 'Q' ? this.workoutsOptions.currentlyQuartalFrom : this.workoutsOptions.nextQuartalFrom;
      return utilsGeneral.localeDateString(utilsGeneral.sqlDateTimeToDate(quartalFrom));
    },

    workoutsQuartalTo() {
      let quartalTo = this.selectedQuartal === this.workoutsOptions.currentlyQuartalNum + 'Q' ? this.workoutsOptions.currentlyQuartalTo : this.workoutsOptions.nextQuartalTo;
      return utilsGeneral.localeDateString(utilsGeneral.sqlDateTimeToDate(quartalTo));
    },

    workoutStartTime() {
      if (this.getSelectedWorkout.activityFrom) {
        return utilsGeneral.localeTimeString(utilsGeneral.sqlDateTimeToDate(this.getSelectedWorkout.activityFrom));
      }
      return null;
    },

    workoutEndTime() {
      if (this.getSelectedWorkout.activityTo) {
        return utilsGeneral.localeTimeString(utilsGeneral.sqlDateTimeToDate(this.getSelectedWorkout.activityTo));
      }
      return null;
    },
  },

  watch: {
    workoutsAreLoaded() {
      this.selectedWorkout = this.getWorkoutData();
    },

    workoutsHasError(newVal) {
      this.hasError = newVal;
    },
  },

  created() {
    if (!Object.keys(this.workouts).length) {
      this.fetchWorkouts()
        .then(() => {
          this.initializeWorkoutDetail();
        });
    } else {
      this.initializeWorkoutDetail();
    }
  },

  methods: {
    fetchWorkouts() {
      return new Promise(resolve => {
        this.$store.dispatch(STORE_MODULES.WORKOUTS + '/' + ACTIONS_CONSTANTS.FETCH_WORKOUTS_QUARTERS)
          .then(() => {
            resolve();
          })
      });
    },

    initializeWorkoutDetail() {
      this.selectedWorkout = this.getWorkoutData();
    },

    getWorkoutData() {
      let result = [];
      if (this.workouts) {
        Object.entries(this.workouts).forEach(quartals => {
          let quartalObjects = quartals[1];

          Object.entries(quartalObjects).forEach(entry => {
            let childObjects = entry[1];

            Object.entries(childObjects).forEach(subEntry => {
              let subChildObjects = subEntry[1];

              Object.entries(subChildObjects).forEach(workoutItem => {
                let workoutObject = workoutItem[1];

                if (parseInt(workoutObject.id) === parseInt(this.getIdOfPage)) {
                  result = workoutObject;
                }
              });
            });
          });
        });
      }

      return result;
    },
  }
}
</script>
