<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template v-if="initialized && !isLoading">
        <h2 class="vue-adaptation-header">{{ $t('adaptation.person.title') }}</h2>
        <template v-if="applicant.mgrName">
          <h2 class="vue-adaptation-header">{{ $t('adaptation.person.yourManager') }}</h2>
          <h3 class="vue-adaptation-subheader">{{ $t('adaptation.person.yourManagerNote') }}</h3>
          <div class="wcm-contact wcm-contact-personal">
            <div class="wcm-contact-content">
              <p class="wcm-contact-title">{{ applicant.mgrName }}</p>
              <p
                v-if="applicant.mgrEmail"
                class="wcm-contact-mail"
              >
                <a :href="`mailto:${applicant.mgrEmail}`">{{ applicant.mgrEmail }}</a>
              </p>
              <p
                v-if="applicant.mgrPhone"
                class="wcm-contact-phone"
              >
                <a :href="`tel:${applicant.mgrPhone}`">{{ applicant.mgrPhone }}</a>
              </p>
            </div>
          </div>
        </template>
        <template v-if="applicant.perName">
          <h2 class="vue-adaptation-header">{{ $t('adaptation.person.yourHrManager') }}</h2>
          <h3 class="vue-adaptation-subheader">{{ $t('adaptation.person.yourHrManagerNote') }}</h3>
          <div class="wcm-contact wcm-contact-personal">
            <div class="wcm-contact-content">
              <p class="wcm-contact-title">{{ applicant.perName }}</p>
              <p
                v-if="applicant.perEmail"
                class="wcm-contact-mail"
              >
                <a :href="`mailto:${applicant.perEmail}`">{{ applicant.perEmail }}</a>
              </p>
              <p
                v-if="applicant.perPhone"
                class="wcm-contact-phone"
              >
                <a :href="`tel:${applicant.perPhone}`">{{ applicant.perPhone }}</a>
              </p>
            </div>
          </div>
        </template>
        <template v-if="applicant.acctName">
          <h2 class="vue-adaptation-header">{{ $t('adaptation.person.yourAccountant') }}</h2>
          <h3 class="vue-adaptation-subheader">{{ $t('adaptation.person.yourAccountantNote') }}</h3>
          <div class="wcm-contact wcm-contact-personal">
            <div class="wcm-contact-content">
              <p class="wcm-contact-title">{{ applicant.acctName }}</p>
              <p
                v-if="applicant.acctEmail"
                class="wcm-contact-mail"
              >
                <a :href="`mailto:${applicant.acctEmail}`">{{ applicant.acctEmail }}</a>
              </p>
              <p
                v-if="applicant.acctPhone"
                class="wcm-contact-phone"
              >
                <a :href="`tel:${applicant.acctPhone}`">{{ applicant.acctPhone }}</a>
              </p>
            </div>
          </div>
        </template>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import dataLoaderAuthorizedMode from '@/utils/data-loader/authorized-mode';
import logger from '@/utils/logger';

export default {
  name: 'AdaptationContacts',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault,
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      navbarCaptionData: null,
      applicant: {
        mgrName: '',
        mgrEmail: '',
        mgrPhone: '',
        perName: '',
        perEmail: '',
        perPhone: '',
        acctName: '',
        acctEmail: '',
        acctPhone: '',
      }
    };
  },

  computed: {},

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.fetchOrgContacts();
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    fetchOrgContacts() {
      this.setLoadingState(true);

      dataLoaderAuthorizedMode.fetchUserOrgContacts()
        .then(data => {
          // manager data
          this.applicant.mgrName = data?.manager.name;
          this.applicant.mgrEmail = data?.manager.email;
          this.applicant.mgrPhone = data?.manager.phone;

          // personalist data
          this.applicant.perName = data?.personalist.name;
          this.applicant.perEmail = data?.personalist.email;
          this.applicant.perPhone = data?.personalist.phone;

          // accountant data
          this.applicant.acctName = data?.accountant.name;
          this.applicant.acctEmail = data?.accountant.email;
          this.applicant.acctPhone = data?.accountant.phone;
        })
        .catch(error => {
          logger.error(error);

          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.setLoadingState(false);
          this.initialized = true;
        });
    },
  },
}
</script>
