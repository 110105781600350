import { EXPIRED, ORDERED_STATUS_INDEXED } from '@/constants/esg/status'

/**
 * Sorting Events
 * @param {Array} events
 * @description Sorting Challenges and Competitions by status
 *              1. status = running
 *              2. status = waiting
 *              3. status = expired - 1. resultsReady = 0
 *                                    2. resultsReady = 1
 * @returns {*[]}
 */
export const sortingEvents = (events) => {
  return [
    ...events
      .filter((item) => item.status !== EXPIRED)
      .sort((a, b) => ORDERED_STATUS_INDEXED.indexOf(a.status) - ORDERED_STATUS_INDEXED.indexOf(b.status)),
    ...events.filter((item) => item.status === EXPIRED).sort((a, b) => a.resultsReady - b.resultsReady),
  ]
}
