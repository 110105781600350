import store from '../store/index';
import * as STORE_MODULES from './../store/store-modules';
import * as MUTATIONS_CONSTANTS from './../store/constants/mutations';

const holidays = ['0,1','4,8','6,5','6,6','8,28','9,28','10,17','4,1','11,24','11,25','11,26'];

let utilsGeneral = {
  omit(obj, omitKey) {
    return Object.keys(obj).reduce((result, key) => {
      if (key !== omitKey) {
        result[key] = obj[key];
      }
      return result;
    }, {});
  },

  yearAndWeekCurrent(offset) {
    let today = new Date();

    let currentWeekYear = this.getWeekNumber(today);
    let year = currentWeekYear[0];
    let weekInYear = currentWeekYear[1];
    let yearAndWeekCurrent = {
      year: year,
      week: weekInYear
    };

    if (offset) {
      let nextWeekDay = this.nextWeek(offset);
      let nextWeekNumber = this.getWeekNumber(nextWeekDay);
      yearAndWeekCurrent = {
        year: nextWeekNumber[0],
        week: nextWeekNumber[1]
      }
    }

    return yearAndWeekCurrent;
  },

  nextWeek(offset) {
    var today = new Date();
    var nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+(offset * 7));
    return nextweek;
  },

  getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    let weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    weekNo = weekNo < 10 ? '0'+weekNo : weekNo;
    return [d.getUTCFullYear(), weekNo];
  },

  yearAndWeekSplit(yearAndWeekCombined) {
    let yearAndWeek = yearAndWeekCombined.split('-');
    yearAndWeek = {
      year: parseInt(yearAndWeek[0]),
      week: parseInt(yearAndWeek[1]),
    };

    return yearAndWeek;
  },

  /**
   * Method for normalizing date string to iOS readable format
   * @param date string in format YYYY-MM-DD HH:MM:SS
   * @return date string with T instead of plain space between date and time
  */
  // Source -> How to avoid invalid NaN dates on iOS devices -> https://stackoverflow.com/questions/13363673/javascript-date-is-invalid-on-ios
  normalizeDateString(dateString) {
    return dateString.replace(' ', 'T');
  },

  dateOffsetByDay(offset) {
    return new Date(new Date().setDate(new Date().getDate()+offset));
  },
  // used for wordpress post datetime coversion
  // example modifiedDate: "2019-05-26 23:25:35" should return modifiedTimestamp: 1558913135
  parseDate(dateString) {
    let dateTimeParts = dateString.split('T');
    let timeParts = dateTimeParts[1].split(':');
    let dateParts = dateTimeParts[0].split('-');

    let date = new Date(dateParts[0], parseInt(dateParts[1], 10) - 1, dateParts[2], timeParts[0], timeParts[1], timeParts[2]);
    let timestamp = (Number(date) / 1000) + (2 * 60 * 60); // 2 hours + GMT, should supply date / modified NOT date_gmt / modified_gmt!!!
    return {
      date: date,
      timestamp: timestamp
    };
  },
  dateTimeToTimestamp(date) {
    // TODO MBU do math floor / ceil based on seconds value
    return Math.floor(date / 1000);
  },
  timestampToDate(timestamp) {
    let date = new Date(timestamp * 1000);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = '0' + date.getMinutes();
    return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes.substr(-2);
  },
  timestampToDateAndMonth(timestamp) {
    let date = new Date(timestamp * 1000);
    let day = date.getDate();
    let month = date.getMonth() + 1;

    return day + '. ' + month + '.';
  },
  timestampToFullDate(timestamp) {
    let date = new Date(timestamp * 1000);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return day + '. ' + month + '. ' + year;
  },
  subtractDateFromToday(date) {
    return new Promise((resolve) => {
      const today = new Date();
      const timezoneCorrection = today.getTimezoneOffset() * 60 * 1000;
      const lastArticleDate = new Date((date * 1000) + timezoneCorrection);
      const timeDiff = Math.abs(today - lastArticleDate);

      return resolve(timeDiff);
    });
  },
  localDate(date) {
    return date.slice(8,10) + '. ' + date.slice(5,7) + '. ' + date.slice(0,4);
  },
  sortContentDataCategory(items) {
    let itemsSorted = [];

    // sort by timestamp helper array
    let itemsSortedHelperArray = [];
    for (let itemId in items) {
      itemsSortedHelperArray.push([itemId, items[itemId].createdTimestamp]);
    }

    itemsSortedHelperArray.sort(function(a, b) {
      return b[1] - a[1];
    });

    for (let i = 0; i < itemsSortedHelperArray.length; i++) {
      let itemId = itemsSortedHelperArray[i][0];

      itemsSorted.push(items[itemId]);
    }

    return itemsSorted;
  },
  sortContentDataBothCategory(items, importantItems) {
    let itemsSorted = [];

    // sort by timestamp helper array
    let itemsSortedHelperArray = [];
    for (let itemId in items) {
      itemsSortedHelperArray.push([itemId, items[itemId].createdTimestamp]);
    }
    for (let impItemId in importantItems) {
      itemsSortedHelperArray.push([impItemId, importantItems[impItemId].createdTimestamp]);
    }

    itemsSortedHelperArray.sort(function(a, b) {
      return b[1] - a[1];
    });

    for (let i = 0; i < itemsSortedHelperArray.length; i++) {
      let itemId = itemsSortedHelperArray[i][0];
      if(typeof items[itemId] !== 'undefined') {
        itemsSorted.push({
          id: items[itemId].id,
          title: items[itemId].title,
          important: false,
          featuredImage: (typeof items[itemId].featuredImage !== 'undefined') ? items[itemId].featuredImage : null
        });
      }
      if(typeof importantItems[itemId] !== 'undefined') {
        itemsSorted.push({
          id: importantItems[itemId].id,
          title: importantItems[itemId].title,
          important: true,
          featuredImage: (typeof importantItems[itemId].featuredImage !== 'undefined') ? importantItems[itemId].featuredImage : null
        });
      }
    }

    return itemsSorted;
  },
  sortContentDataBothCarousel(items, importantItems) {
    let itemsSorted = [];

    // sort by timestamp helper array
    let itemsSortedHelperArray = [];
    for (let itemId in items) {
      if(items[itemId].carousel === '1' && Object.prototype.hasOwnProperty.call(items[itemId], 'featuredImage')) {
        itemsSortedHelperArray.push([itemId, items[itemId].createdTimestamp]);
      }
    }
    for (let impItemId in importantItems) {
      if(importantItems[impItemId].carousel === '1' && Object.prototype.hasOwnProperty.call(importantItems[impItemId], 'featuredImage')) {
        itemsSortedHelperArray.push([impItemId, importantItems[impItemId].createdTimestamp]);
      }
    }

    itemsSortedHelperArray.sort(function(a, b) {
      return b[1] - a[1];
    });

    for (let i = 0; i < itemsSortedHelperArray.length; i++) {
      let itemId = itemsSortedHelperArray[i][0];
      if(typeof items[itemId] !== 'undefined') {
        itemsSorted.push({
          image: items[itemId].featuredImage.mediumLarge.src,
          text: items[itemId].carouselText === null ? '' : items[itemId].carouselText,
          link: '/news/' + items[itemId].id,
          important: false
        });
      }
      if(typeof importantItems[itemId] !== 'undefined') {
        itemsSorted.push({
          image: importantItems[itemId].featuredImage.mediumLarge.src,
          text: importantItems[itemId].carouselText === null ? '' : importantItems[itemId].carouselText,
          link: '/news/' + importantItems[itemId].id,
          important: true
        });
      }
    }

    return itemsSorted;
  },
  createContentDataCategoryItemTypes(itemsCurrentPassed, items) {
    // items current
    let itemsCurrent = {};
    if (Object.keys(itemsCurrentPassed).length > 0) {
      itemsCurrent = itemsCurrentPassed;
    }

    // items to update
    let itemsToUpdate = {};
    if (items.itemsToUpdate) {
      itemsToUpdate = items.itemsToUpdate;
    }

    // items to delete
    let itemsToDelete = {};
    if (items.itemsToDelete) {
      itemsToDelete = items.itemsToDelete;
    }

    let itemsVariables = {
      itemsCurrent: itemsCurrent,
      itemsToUpdate: itemsToUpdate,
      itemsToDelete: itemsToDelete
    };

    return itemsVariables;
  },
  updateContentDataCategory(itemsCurrent, itemsToUpdate, itemsToDelete, itemsLimit) {
    let itemsToCommit = {};

    //=== FILL ITEMS TO COMMIT ==============================//
    //=======================================================//

    // fill items to commit with items current
    for (let itemId in itemsCurrent) {
      itemsToCommit[itemId] = itemsCurrent[itemId];
    }

    // fill items to commit with items to update (overwrite current with updated)
    for (let itemId in itemsToUpdate) {
      itemsToCommit[itemId] = itemsToUpdate[itemId];
    }

    // remove items to commit
    for (let i = 0;i < itemsToDelete.length; i++) {
      for (let itemId in itemsToCommit) {
        if(itemId === itemsToDelete[i]) {
          delete itemsToCommit[itemId];
        }
      }
    }

    //=== LIMIT ITEMS COUNT =================================//
    //=======================================================//
    if (itemsLimit > 0) {
      if (Object.keys(itemsToCommit).length > itemsLimit) {

        // sort by timestamp helper array
        let itemsToCommitSortedHelperArray = [];
        for (let itemId in itemsToCommit) {
          itemsToCommitSortedHelperArray.push([itemId, itemsToCommit[itemId].createdTimestamp]);
        }

        itemsToCommitSortedHelperArray.sort(function (a, b) {
          return b[1] - a[1];
        });

        // delete items over limit from itemsToCommit
        let itemsOverLimitCount = itemsToCommitSortedHelperArray.length - itemsLimit;
        let itemsOverLimit = itemsToCommitSortedHelperArray.splice(itemsToCommitSortedHelperArray.length - itemsOverLimitCount, itemsToCommitSortedHelperArray.length);

        // delete items over limit from itemsToCommit
        for (let i = 0; i < itemsOverLimit.length; i++) {
          delete itemsToCommit[itemsOverLimit[i][0]];
        }
      }
    }

    let contentDataCategory = {
      itemsToCommit: itemsToCommit,
    };

    return contentDataCategory;
  },
  /**
     * Checks, if received restaurant data contains menu for today. Also checks,
     * if contains a valid week number according to dates
     *
     * @param restaurantData {Object} data structure with menu indexed by days string in format "YYYY-WW"
     * @return {boolean} true if content is valid and contains everything, false otherwise
     */
  validateRestaurantDataForCurrentWeek(restaurantData) {
    let yearAndWeekCurrent = this.yearAndWeekCurrent();
    let weekId = yearAndWeekCurrent.year + '-' + yearAndWeekCurrent.week;

    if(!restaurantData[weekId]) {
      return false;
    }
    let today = new Date();
    let todayIndex = today.getDay() - 1;
    let todayMealsObj = restaurantData[weekId].content[todayIndex];
    let todayStr = today.getDate() < 10 ? '0' + today.getDate() : '' + today.getDate();
    let month = today.getMonth() + 1;
    let monthStr = month < 10 ? '0' + month : '' + month;

    if(todayMealsObj !== undefined) {
      return todayMealsObj.date === today.getFullYear() + '-' + monthStr + '-' + todayStr;
    }
    return false;
  },
  /**
   * Generates random string with specified length, used typically for indentifying user device
   *
   * @param length char length of random string
   * @returns Radnom generated {string}
   */
  makeDeviceId(length) {
    var text = '';
    var char_list = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for(let i=0; i < length; i++ ) {
      text += char_list.charAt(Math.floor(Math.random() * char_list.length));
    }
    return text;
  },
  /**
   * The most basic validator for zip number
   *
   * @param zipNumber String with zip number
   * @returns {boolean} true, if structure matches correct zip format (5 digits)
   */
  isValidZipNumber(zipNumber) {
    return /^\d{5}$/.test(zipNumber);
  },
  /**
   * The most basic validator for telephone number
   *
   * @param telNumber String telephone number
   * @returns {boolean} true, if structure matches correct telephone format (9 digits)
   */
  isValidPhoneNumber(telNumber) {
    return /^\d{9}$/.test(telNumber);
  },
  /**
   * The most basic validator for email address structure
   *
   * @param email String email address
   * @returns {boolean} true, if structure matches email format
   */
  isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  },
  /**
   * Checks, whether email contains one of the CSOB Group domains
   * @param emailAddress
   */
  isEmailFromGroup(emailAddress) {
    let lowered = emailAddress.toLowerCase();
    let groupDomains = ['@csob.cz', '@csobleasing.cz', '@hypotecnibanka.cz',
      '@patria.cz', '@cmss.cz', '@csobfactoring.cz',
      '@csobpm.cz', '@csobadvisory.cz', '@banit.cz',
      '@usetreno.cz', '@top-pojisteni.cz', '@kbc.be',
      '@csobpoj.cz', '@csobstavebni.cz'];
    let found = false;
    for(let i = 0; i < groupDomains.length; i++) {
      if(lowered.indexOf(groupDomains[i]) > 0) {
        found = true;
        break;
      }
    }
    return found;
  },

  isValidURL(url) {
    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);

    return url.match(regex);
  },

  isPositiveNumeric(value) {
    return /^\d+$/.test(value);
  },

  /**
   * Converts standard SQL datetime string to JS date object
   * @param sqlDatetime {String} date and time in format YYYY-MM-DD HH:MM:SS
   * @returns {Date} JS date object representing passed string
   */
  sqlDateTimeToDate(sqlDatetime) {
    return new Date(sqlDatetime.replace(/-/g,'/'));
  },

  /**
   * Converts date to string respecting locale settings
   *
   * @param date {Date} date object to convert
   * @returns {string} Date string without time
   */
  localeDateString(date) {
    return date.toLocaleDateString(['cs-CZ']);
  },

  localeDateWidgetString(date) {
    return date.toLocaleDateString(['cs-CZ'], {month: 'numeric', day: 'numeric'});
  },

  /**
   * Converts date to to time string respecting locale settings
   *
   * @param date {Date} date object to convert
   * @returns {string} time string without date and seconds (hours and minutes only)
   */
  localeTimeString(date) {
    return date.toLocaleTimeString(['cs-CZ'], {hour: 'numeric', minute:'2-digit', hour12: false});
  },

  /**
   * Converts date to to datetime string respecting locale settings
   *
   * @param date {Date} date object to convert
   * @returns {string} time string with date and time(hours and minutes only)
   */
  localeDateTimeString(date) {
    return date.toLocaleTimeString(['cs-CZ'], {month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: false});
  },

  /**
   * Assigns standard event object to sorted object by Year, Month and Day
   *
   * @param eventObject Event object to convert
   * @returns { Object } with sorted days by Year, Month and Day
   */
  sortEventObect(eventObject) {
    let dateRegexPattern = /^(\d{4})-(\d{2})-(\d{2})/;
    let months = ['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

    let results = Object.values(eventObject).reduce(function (results, value) {
      let dateArray = value.activityFrom.match(dateRegexPattern);
      let temp = results;
      let key = dateArray[1];

      if (!temp.hasOwnProperty(key)) temp[key] = {};
      temp = temp[key];
      key = months[dateArray[2]|0];

      if (!temp.hasOwnProperty(key)) temp[key] = {};
      temp = temp[key];
      key = dateArray[3];

      if (key.charAt(0) === '0') {
        key = key.replace('0', '');
      }

      if (!temp.hasOwnProperty(key)) temp[key] = [];
      temp = temp[key];

      temp.push(value);
      return results;
    }, {});

    return results;
  },
  /**
   * source: https://github.com/GoogleChromeLabs/web-push-codelab/blob/master/app/scripts/main.js
   * @param base64String
   * @return {Uint8Array}
   */
  urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  },

  checkTimestampIsToday(timestamp) {
    const today = new Date();
    const convertedTimestamp = new Date(timestamp);

    const convertTimestampToString = dateTimestamp => {
      return dateTimestamp.toISOString().substr(0,10);
    }

    return convertTimestampToString(today) === convertTimestampToString(convertedTimestamp);
  },

  parseNumbersFromArray(array) {
    if (typeof array === 'undefined' || !array.length && typeof array !== 'number') return '';

    if (typeof array === 'number') return array;

    return array.join();
  },

  convertToCamelCase(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  },

  cutValue(value, length) {
    if(typeof value === 'undefined') {
      return '';
    }
    return value.substring(0, length);
  },

  getLowestNumberFromArray(array) {
    return array.reduce((acc, val) => {
      return acc < val ? acc : val;
    });
  },

  createTimedId() {
    return this.randomChars(8) + '-' + new Date().getTime();
  },

  randomChars(length) {
    let result = '';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  },

  startAsyncProcess() {
    let processId = this.createTimedId();
    store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.START_PROCESS, processId);
    return processId;
  },

  stopAsyncProcess(processId) {
    store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.STOP_PROCESS, processId);
  },

  isWeekend(date) {
    const dayOfWeek = date.getDay();
    return (dayOfWeek === 6) || (dayOfWeek === 0);
  },

  getEastersSunday(year) {
    var date, a, b, c, m, d;

    date = new Date;
    date.setHours( 0, 0, 0, 0 );
    date.setFullYear( year );

    a = year % 19;
    b = ( 2200 <= year && year <= 2299 ) ?
      ( ( 11 * a ) + 4 ) % 30 :
      ( ( 11 * a ) + 5 ) % 30;
    c = ( ( b === 0 ) || ( b === 1 && a > 10 ) ) ? ( b + 1 ) : b;
    m = ( 1 <= c && c <= 19 ) ? 3 : 2;
    d = ( 50 - c ) % 31;
    date.setMonth( m, d );
    date.setMonth( m, d + ( 7 - date.getDay() ) );

    // Gregorian Western Easter Sunday
    return date;

  },

  removeTime(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  },

  isEastersHoliday(date) {
    const dateTime = this.removeTime(date).getTime();
    const sunday = this.getEastersSunday(date.getFullYear());

    // check against Big Friday
    let friday = new Date(sunday);
    friday.setDate(sunday.getDate() - 2);
    if (dateTime === friday.getTime()) {
      return true;
    }

    // check against Easters Monday
    let monday = new Date(sunday);
    monday.setDate(sunday.getDate() + 1);
    return dateTime === monday.getTime();
  },

  isPublicHoliday(date) {
    return (holidays.includes(date.getMonth() + ',' + date.getDate()) || this.isEastersHoliday(date));
  },

  isWeekendOrPublicHoliday(date) {
    return this.isPublicHoliday(date) || this.isWeekend(date);
  }
};

export default utilsGeneral;
