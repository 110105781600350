<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        :infoButton="true"
        :infoButtonDestination="'building-intro-page'"
        :infoButtonDestinationParams="infoButtonDestinationParams"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-building-dashboard">
        <frm1006-button
          v-for="item in blockButtons"
          :key="item.id"
          :icon-right="true"
          class="vue-dashboard-item-block"
          type="internal"
          @buttonClickEvent="goToView(item)"
        >
          {{ item.title }}
        </frm1006-button>
        <div class="vue-dashboard-tiles">
          <frm1006-button
            v-for="item in tileButtons"
            :key="item.id"
            class="vue-dashboard-item-tile"
            :class="generateTile(item)"
            type="tile"
            @buttonClickEvent="goToView(item)"
          >
            <template v-slot:iconLeft>
              <div
                class="vue-tile-icon"
              />
            </template>
            {{ item.title }}
          </frm1006-button>
        </div>
        <a
          v-for="item in linkButtons"
          :key="item.id"
          class="vue-dashboard-item-link"
          :href="item.url"
          target="_blank"
          download
        >
          <span class="vue-dashboard-item-link-icon" />
          {{ item.title }}
        </a>
      </div>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import {
  HHQ_DASHBOARD_ID,
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';

import mxNavigationDefault from '@/mixins/mx-navigation-default';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import router from '@/router';

export default {
  name: 'BuildingHHQDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      navbarCaptionData: null,
      dashboardCategories: [],
      dashboardCategoriesLoaded: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      categoryStyles: {
        block: 'block',
        tile: 'tile',
        link: 'link',
        intro: 'intro',
      },
      categoryMapID: 1
    };
  },

  computed: {
    ...mapState('persistentStorage', [
      'isBuildingHHQIntroVisited',
    ]),

    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },
    blockButtons() {
      return this.dashboardCategories.filter(item => item.style === this.categoryStyles.block)
    },

    tileButtons() {
      return this.dashboardCategories.filter(item => item.style === this.categoryStyles.tile)
    },

    linkButtons() {
      return this.dashboardCategories.filter(item => item.style === this.categoryStyles.link)
    },

    infoButtonDestinationParams() {
      const item = this.dashboardCategories.find(item => item.style === this.categoryStyles.intro)
      if (this.dashboardCategoriesLoaded && item) return {id: this.dashboardCategories.find(item => item.style === this.categoryStyles.intro).pageId}
      return {}
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.getBuildingDashboardCategories().then(() => {
      const pageId = this.infoButtonDestinationParams.id
      this.initialized = true;
      if (!this.isBuildingHHQIntroVisited) router.push({ name: 'hhq-building-start', params: { pageId } });
      this.setLoadingState(false);
    });

  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getBuildingDashboardCategories() {
      this.setLoadingState(true);

      return dataLoader.fetchApiDataList(HHQ_DASHBOARD_ID)
        .then(data => {
          this.dashboardCategories = data.HHQCategories;
          this.dashboardCategoriesLoaded = true;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
          this.setLoadingState(false);
        });
    },

    generateTile(item) {
      if (item.icon.length > 0) {
        return 'vue-tile-' + item.icon;
      }
    },

    goToView(item) {
      if ( item.categoryId ) {
        router.push({ name: 'building-category', params: { categoryId: item.categoryId, categoryType: item.categoryType } })
      }

      if ( item.pageId ) {
        router.push({ name: 'building-info-page', params: { id: item.pageId, buttonMapVisible: parseInt(item.id) === this.categoryMapID } })
      }
    },
  },
}
</script>

