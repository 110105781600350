<template>
  <div
    class="vue-c-directory-list"
    :class="{'vue-is-widget': use === 'widget'}"
  >
    <prj1048-search
      :module="'directory'"
      :placeholder="$t('directory.searchPlaceholder')"
      :submit-button-disable="true"
      :show-history="false"
      :show-results="true"
      :auto-focus="false"
      :disabled-compact="use === 'module' && !isDesktopLayout"
    />

    <div
      v-if="appDataPinnedListCount > 0 && !isLoadingItems"
      class="vue-b-directory-list-section vue-is-pinned"
    >
      <prj1020-separator
        :caption="$t('directory.pinned')"
      />
      <prj1052-directory-list-item
        v-for="(appPinnedItem, pinnedIndex) in appDataPinnedList"
        :key="'pinned-app-' + pinnedIndex"
        :item-data="appPinnedItem"
      >
      </prj1052-directory-list-item>
    </div>

    <div
      v-if="getAppDataList.length > 0"
      class="vue-b-directory-list-section vue-is-other"
    >
      <prj1020-separator
        :caption="$t('directory.others')"
      />
      <prj1052-directory-list-item
        v-for="(appItem, index) in getAppDataList"
        :key="'other-app-' + index"
        :class="{'vue-is-loading': isLoadingItems}"
        :item-data="appItem"
      >
      </prj1052-directory-list-item>
    </div>

    <div
      v-if="isInfinityScrollEnabled"
      ref="infinityScrollTrigger"
    >
      <gen1016-loading-indicator
        :active="isInfinityScrollEnabled"
      />
    </div>

    <gen1006-info-panel
      v-if="!appDataList.length && appDataPinnedListCount === 0 && !isLoadingItems"
      type="neutral"
      class="vue-no-data"
    >
      {{ $t('directory.noApplication') }}
    </gen1006-info-panel>

    <div
      v-if="use === 'module'"
      class="vue-wish-app-infobox"
      @click="clickEventShowWishAppForm()"
      v-html="$t('directory.myWishApp')"
    />

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <gen1009-overlay
      class="vue-is-modal-form"
      :active.sync="isModalFormActive"
      :buttonClose="true"
      preventClosing="all"
    >
      <div class="vue-form-application">
        <h2>{{ $t('directory.createCustomApplication') }}</h2>

        <frm1035-form-group>
          <frm1001-input-field
            id="name"
            v-model="formName"
            :disabled="formCompleted"
            :tooltip="false"
            :label="$i18n.t('directory.forms.labelName')"
            :caption="$i18n.t('directory.forms.labelName')"
            :required="true"
            @inputChangeEvent="validateForm"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            id="description"
            v-model="formDescription"
            :disabled="formCompleted"
            :tooltip="false"
            :label="$i18n.t('directory.forms.labelDescription')"
            :caption="$i18n.t('directory.forms.labelDescription')"
            :required="true"
            @inputChangeEvent="validateForm"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            id="link"
            v-model="formLink"
            :disabled="formCompleted"
            :tooltip="false"
            :label="$i18n.t('directory.forms.labelLink')"
            :required="true"
            @inputChangeEvent="validateForm"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <div class="vue-photo-image-wrap">
            <label>{{ $i18n.t('directory.forms.labelIcon') }}</label>

            <prj1010-image-upload
              v-if="!formIcon"
              class="vue-app-upload-icon"
              :cropper="true"
              :acceptFiles="['image/jpeg', 'image/png']"
              :cropper-aspect-ratio="0"
              :caption="'png/jpeg'"
              @uploadedPhoto="processedPhoto"
            />

            <div class="vue-camera-canvas">
              <div
                v-if="formIcon || formIconOld"
                class="vue-app-uploaded-file"
              >
                <img
                  :src="formIcon ?? formIconOld"
                  :style="styleObjectImg"
                />

                <frm1006-button
                  v-if="!formCompleted"
                  class="vue-button-remove-icon"
                  type="internal"
                  :disabled="!!formIconOld"
                  :iconLeft="true"
                  :captionHidden="true"
                  @buttonClickEvent="removeTakenPicture"
                >
                  {{ $t('directory.forms.buttonRemoveIcon') }}
                </frm1006-button>
              </div>
            </div>
          </div>
        </frm1035-form-group>

        <frm1035-form-group class="vue-b-color-picker">
          <frm1001-input-field
            id="iconColor"
            v-model="formIconColor"
            :disabled="formCompleted"
            :tooltip="false"
            :label="$i18n.t('directory.forms.labelIconColor')"
            @inputChangeEvent="validateForm"
          />
          <v-swatches
            v-model="formIconColor"
            popover-x="left"
          ></v-swatches>
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            id="linkWiki"
            v-model="formLinkWiki"
            :disabled="formCompleted"
            :tooltip="false"
            :label="$i18n.t('directory.forms.labelLinkWiki')"
            @inputChangeEvent="validateForm"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            id="supportEmail"
            v-model="formSupportEmail"
            :disabled="formCompleted"
            :tooltip="false"
            :label="$i18n.t('directory.forms.labelSupportEmail')"
            @inputChangeEvent="validateForm"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            id="supportPhone"
            v-model="formSupportPhone"
            :disabled="formCompleted"
            :tooltip="false"
            :label="$i18n.t('directory.forms.labelSupportPhone')"
            @inputChangeEvent="validateForm"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            id="supportSnowGroup"
            v-model="formSupportSnowGroup"
            :disabled="formCompleted"
            :tooltip="false"
            :label="$i18n.t('directory.forms.labelSupportSnowGroup')"
            @inputChangeEvent="validateForm"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1003-checkbox
            v-model="formPrivateOnly"
            :tooltip="false"
          >
            {{ $t('directory.forms.labelPrivateOnly') }}
          </frm1003-checkbox>
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1003-checkbox
            v-model="formDesktopOnly"
            :tooltip="false"
          >
            {{ $t('directory.forms.labelDesktopOnly') }}
          </frm1003-checkbox>
        </frm1035-form-group>

        <frm1006-button
          type="primary"
          class="vue-app-form-submit"
          :class="classObject"
          :disabled="!isFormValid || sending || formSent"
          @buttonClickEvent="clickEventSubmitForm()"
        >
          {{ $t('directory.forms.buttonSave') }}
        </frm1006-button>
      </div>
    </gen1009-overlay>

    <gen1009-overlay
      v-if="detailData"
      class="vue-is-modal-detail"
      :active.sync="isModalDetailActive"
      :buttonClose="true"
      preventClosing="all"
    >
      <div
        class="vue-b-info"
        :class="{'vue-is-private': detailData.privateOnly, 'vue-is-desktop': detailData.desktopOnly}"
      >
        <div class="vue-b-logo-wrapper">
          <div
            class="vue-item-letter"
            :style="'color:' + detailData.iconColor"
          >
            {{ getFirstLetter(detailData.name) }}

            <div
              v-if="detailData.iconUrl"
              v-lazy:background-image="detailData.iconUrl"
              class="vue-item-logo"
            />
          </div>
        </div>

        <h1 class="vue-headline">{{ detailData.name }}</h1>
        <p class="vue-description">{{ detailData.description }}</p>
        <a
          v-if="detailData.homepageUrl"
          :href="detailData.homepageUrl"
          target="_blank"
          class="vue-wiki-link"
        >
          {{ detailData.homepageUrl }}
        </a>
      </div>

      <div class="vue-b-actions">
        <frm1006-button
          :icon-left="true"
          class="vue-favorite-button"
          :class="{'vue-is-pinned-off': detailData.favorite}"
          type="internal"
          @buttonClickEvent="clickEventFavorite()"
        >
          {{ detailData.favorite ? $t('directory.forms.buttonFavoriteOff') : $t('directory.forms.buttonFavoriteOn') }}
        </frm1006-button>

        <frm1006-button
          v-if="canManageItem"
          :icon-left="true"
          class="vue-edit-button"
          type="internal"
          @buttonClickEvent="clickEventEdit()"
        >
          {{ $t('directory.forms.buttonEdit') }}
        </frm1006-button>

        <frm1006-button
          v-if="canManageItem"
          :icon-left="true"
          class="vue-delete-button"
          type="internal"
          @buttonClickEvent="clickEventDelete()"
        >
          {{ $t('directory.forms.buttonDelete') }}
        </frm1006-button>
      </div>

      <div class="vue-b-contacts">
        <gen1015-section
          v-if="detailData.supportEmail || detailData.supportPhone || detailData.supportSnowGroup"
          :collapsible="true"
          :collapsed="true"
          class="is-animated"
        >
          <template v-slot:header>
            <prj1020-separator
              :caption="$t('directory.support')"
            />
          </template>
          <template v-slot:content>
            <div class="vue-b-content-animated">
              <div>
                <a
                  v-if="detailData.supportPhone"
                  class="vue-icon vue-detail-phone"
                  :href="`tel:${detailData.supportPhone}`"
                >
                  {{ detailData.supportPhone }}
                </a>

                <a
                  v-if="detailData.supportEmail"
                  class="vue-icon vue-detail-email"
                  :href="`mailto:${detailData.supportEmail}`"
                >
                  {{ detailData.supportEmail }}
                </a>

                <span
                  v-if="detailData.supportSnowGroup"
                  class="vue-detail-snow"
                >
                  <span class="vue-detail-snow-label">{{ $t('directory.snowGroup') }}&nbsp;</span>
                  {{ detailData.supportSnowGroup }}
                </span>
              </div>
            </div>
          </template>
        </gen1015-section>

        <gen1015-section
          v-if="detailData.owner"
          :collapsible="true"
          :collapsed="true"
          class="is-animated"
        >
          <template v-slot:header>
            <prj1020-separator
              :caption="$t('directory.manager')"
            />
          </template>
          <template v-slot:content>
            <div class="vue-b-content-animated">
              <div v-if="detailData.owner">
                <prj1021-profile-card
                  :fullName="detailData.owner?.name"
                  :avatar="''"
                  :profession="''"
                  :isClickable="false"
                />

                <a
                  v-if="detailData.owner?.phone"
                  class="vue-icon vue-detail-phone"
                  :href="`tel:${detailData.owner?.phone}`"
                >
                  {{ detailData.owner?.phone }}
                </a>

                <a
                  v-if="detailData.owner?.email"
                  class="vue-icon vue-detail-email"
                  :href="`mailto:${detailData.owner?.email}`"
                >
                  {{ detailData.owner?.email }}
                </a>
              </div>
            </div>
          </template>
        </gen1015-section>
      </div>

      <div class="vue-b-maintenance">
        <gen1006-info-panel
          v-if="detailData.maintenanceActive"
          type="warning"
        >
          {{ detailData.maintenanceMessage }}
        </gen1006-info-panel>

        <gen1006-info-panel
          v-if="detailData.privateOnly && !isPrivateApp"
          type="neutral"
        >
          <p
            v-html="$i18n.t('directory.privateMessage', { pageId: installationPageId})"
          />
        </gen1006-info-panel>
      </div>

      <div class="vue-b-controls">
        <a
          :href="detailData.url"
          target="_blank"
          class="vue-run-button"
        >
          {{ $t('directory.forms.buttonRun') }}
        </a>
      </div>
    </gen1009-overlay>

    <gen1009-overlay
      class="vue-is-modal-wish-form"
      :active.sync="isModalWishActive"
      :buttonClose="true"
      preventClosing="all"
    >
      <div class="vue-form-application">
        <h2>{{ $t('directory.myWishAppHeadline') }}</h2>

        <frm1035-form-group>
          <label>{{ $t('directory.forms.labelWishDescription') }}</label>
          <frm1001-input-field
            id="name"
            v-model="formWishDescription"
            :tooltip="false"
            :required="true"
            @inputChangeEvent="validateWishForm"
          />
        </frm1035-form-group>

        <frm1006-button
          type="primary"
          class="vue-app-wish-form-submit"
          :class="classObjectWish"
          :disabled="!isFormWishValid || sending || formSent"
          @buttonClickEvent="clickEventSubmitWishForm()"
        >
          {{ $t('directory.forms.buttonSave') }}
        </frm1006-button>
      </div>
    </gen1009-overlay>

    <gen1009-overlay
      class="vue-is-info-panel"
      :active.sync="hasError"
      :buttonClose="true"
      :closeAfter="ERROR_MODAL_TIMEOUT"
    >
      <gen1006-info-panel
        type="error"
      >
        {{ $t('error.generic') }}
      </gen1006-info-panel>
    </gen1009-overlay>
  </div>
</template>

<script>
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

import { mapState } from 'vuex';
import mxInfinityScrolling from '@/mixins/mx-infinity-scrolling';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import VSwatches from 'vue-swatches';

import {
  DIRECTORY_EMPTY_DATA_ITEMS,
  ERROR_MODAL_TIMEOUT,
} from '@/constants/app-constants';
import Prj1020Separator from '@/components/prj1020-separator/prj1020-separator.vue';
import Prj1052DirectoryListItem from '@/components/prj1052-directory-list/prj1052-directory-list-item.vue';
import Prj1048Search from '@/components/prj1048-search/prj1048-search.vue';
import Prj1010ImageUpload from '@/components/prj1010-image-upload/prj1010-image-upload.vue';
import Prj1021ProfileCard from '@/components/prj1021-profile-card/prj1021-profile-card.vue';

export default {
  name: 'Prj1052DirectoryList',
  components: {
    Prj1021ProfileCard,
    Prj1010ImageUpload,
    Prj1048Search,
    Prj1020Separator,
    Prj1052DirectoryListItem,
    VSwatches
  },

  mixins: [
    mxInfinityScrolling,
    mxDetectDesktop
  ],

  props: {
    use: {
      type: String,
      required: false,
      default: 'widget'
    }
  },

  data() {
    return {
      hasError: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      isFormValid: false,
      isFormWishValid: false,
      sending: false,
      formSent: false,
      emptyDataItems: DIRECTORY_EMPTY_DATA_ITEMS,
      currentPosition: 0,
    }
  },

  computed: {
    ...mapState('user', [
      'profile',
    ]),
    ...mapState('directory', [
      'categories',
      'entities',
      'applications',
      'modalFormActive',
      'modalDetailActive',
      'modalWishActive',
      'formData',
      'detailApplication',
      'searchQuery',
      'isLoadingItems',
    ]),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),

    installationPageId() {
      let pageId = -1;

      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'HELP_INSTALLATION_PAGE_ID') {
            pageId = parseInt(item.value);
          }
        });
      }

      return pageId;
    },

    isModalFormActive: {
      get() {
        return this.modalFormActive;
      },
      set(newValue) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_FORM, newValue);
        if (!newValue) {
          this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_RESET);
        } else {
          this.formSent = false;
        }
      }
    },

    isModalDetailActive: {
      get() {
        return this.modalDetailActive;
      },
      set(newValue) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_DETAIL, newValue);
      }
    },

    isModalWishActive: {
      get() {
        return this.modalWishActive;
      },
      set(newValue) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_WISH, newValue);
        if (!newValue) {
          this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_WISH_DESCRIPTION, '');
        } else {
          this.formSent = false;
        }
      }
    },

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },

    formId: {
      get () {
        return this.formData.id;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ID, value);
      }
    },

    formName: {
      get () {
        return this.formData.name;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_NAME, value);
      }
    },

    formDescription: {
      get () {
        return this.formData.description;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_DESCRIPTION, value);
      }
    },

    formWishDescription: {
      get () {
        return this.formData.wishDescription;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_WISH_DESCRIPTION, value);
      }
    },

    formLink: {
      get () {
        return this.formData.link;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_LINK, value);
      }
    },

    formLinkWiki: {
      get () {
        return this.formData.linkWiki;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_LINK_WIKI, value);
      }
    },

    formIcon: {
      get () {
        return this.formData.icon;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ICON, value);
      }
    },

    formIconOld: {
      get () {
        return this.formData.iconOldPath;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ICON_OLD, value);
      }
    },

    formIconColor: {
      get () {
        return this.formData.iconColor;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ICON_COLOR, value);
      }
    },

    formSupportEmail: {
      get () {
        return this.formData.supportEmail;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_SUPPORT_EMAIL, value);
      }
    },

    formSupportPhone: {
      get () {
        return this.formData.supportPhone;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_SUPPORT_PHONE, value);
      }
    },

    formSupportSnowGroup: {
      get () {
        return this.formData.supportSnowGroup;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_SUPPORT_SNOW_GROUP, value);
      }
    },

    formPrivateOnly: {
      get () {
        return this.formData.privateOnly;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_PRIVATE_ONLY, value);
      }
    },

    formDesktopOnly: {
      get () {
        return this.formData.desktopOnly;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_DESKTOP_ONLY, value);
      }
    },

    formCompleted: {
      get () {
        return this.formData.completed;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_COMPLETED, value);
      }
    },

    detailData() {
      return this.detailApplication;
    },

    canManageItem() {
      let retValue = false;
      if (this.detailData) {
        retValue = this.detailData?.managers.includes(this.profile.id) || (this.detailData.owner?.id === this.profile.id);
      }
      return retValue;
    },

    classObject() {
      return {
        'vue-is-active': this.isFormValid,
      }
    },

    classObjectWish() {
      return {
        'vue-is-active': this.isFormWishValid,
      }
    },

    styleObjectImg() {
      return {
        maxWidth: '200px',
        maxHeight: '200px',
      }
    },

    getAppDataList() {
      return this.isLoadingItems ? this.emptyDataItems : this.appDataList;
    },

    appDataList() {
      let retValue = [];
      this.applications.forEach(item => {
        if (!item.favorite) {
          let categories = '';
          item.categories.forEach(categoryId => {
            let category = this.categories.find(cat => cat.id === categoryId);
            categories += categories.length === 0 ? category?.name : ', ' + category?.name;
          });
          item.categoriesNames = categories;
          retValue.push(item);
        }
      });
      return retValue;
    },

    appDataListCount() {
      return this.appDataList.length;
    },

    appDataPinnedList() {
      let retValue = [];
      this.applications.forEach(item => {
        if (item.favorite) {
          let categories = '';
          item.categories.forEach(categoryId => {
            let category = this.categories.find(cat => cat.id === categoryId);
            categories += categories.length === 0 ? category?.name : ', ' + category?.name;
          });
          item.categoriesNames = categories;
          retValue.push(item);
        }
      });
      return retValue;
    },

    appDataPinnedListCount() {
      return this.appDataPinnedList.length;
    },

    isInfinityScrollEnabled() {
      return this.listItemsCount > 20 && !this.noMoreItems
    },

    paramFilter() {
      let retValue = '';

      if (this.searchQuery !== '') {
        retValue += 'fullText|has|' + this.searchQuery + ';';
      }

      return retValue;
    },

    paramSort() {
      return 'name';
    },

    endpointListOptions() {
      return [
        this.paramFilter,
        this.paramSort,
        this.currentPosition,
        40
      ]
    },
  },

  watch: {
    searchQuery() {
      this.fetchSearch();
    }
  },

  mounted() {
    Promise.all([
      this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.FETCH_DIRECTORY_CATEGORIES, {force: this.use === 'module'}),
      this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.FETCH_DIRECTORY_ENTITIES, {force: this.use === 'module'})
    ])
      .then(() => {
        this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.FETCH_DIRECTORY_ITEMS, {force: this.use === 'module', params: [...this.endpointListOptions]})
      });
  },

  methods: {
    getFirstLetter(name) {
      return name.substring(0, 1);
    },

    validateForm() {
      let decision = false;

      if (this.formName.length > 0 &&
        this.formDescription.length > 0 &&
        this.formLink.length > 0) {
        decision = true;
      }

      this.isFormValid = decision;
    },

    validateWishForm() {
      let decision = false;

      if (this.formWishDescription.length > 0) {
        decision = true;
      }

      this.isFormWishValid = decision;
    },

    processedPhoto(photoObject) {
      this.formIcon = photoObject;
    },

    removeTakenPicture() {
      this.formIcon = null;
    },

    fetchSearch() {
      this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.FETCH_DIRECTORY_ITEMS, {force: true, params: [...this.endpointListOptions]});
    },

    clickEventSubmitForm() {
      if (this.formId > 0) {
        this.editAppForm();
      } else {
        this.newAppForm();
      }
    },

    clickEventSubmitWishForm() {
      this.wishAppForm();
    },

    newAppForm() {
      let formData = new FormData();
      formData.append('name', this.formName);
      formData.append('description', this.formDescription);
      formData.append('url', this.formLink);
      formData.append('icon', this.formIcon);
      formData.append('iconColor', this.formIconColor);
      formData.append('homepageUrl', this.formLinkWiki);
      formData.append('privateOnly', this.formPrivateOnly  | 0);
      formData.append('desktopOnly', this.formDesktopOnly  | 0);
      formData.append('supportSnowGroup', this.formSupportSnowGroup);
      formData.append('supportPhone', this.formSupportPhone);
      formData.append('supportEmail', this.formSupportEmail);
      this.sending = true;

      dataLoader.postDirectoryApplication(
        formData
      ).then(() => {
        logger.info('Form has been sent!');
        this.formSent = true;
      }).catch(error => {
        logger.error('Sending form has failed:', error);
        //this.formFailed = true;
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.sending = false;
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_FORM, false);
        this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.FETCH_DIRECTORY_ITEMS, {force: true, params: [...this.endpointListOptions]});
      })
    },

    editAppForm() {
      let formData = new FormData();
      formData.append('id', this.formId);
      formData.append('name', this.formName);
      formData.append('description', this.formDescription);
      formData.append('url', this.formLink);
      formData.append('icon', this.formIcon);
      formData.append('iconColor', this.formIconColor);
      formData.append('homepageUrl', this.formLinkWiki);
      formData.append('privateOnly', this.formPrivateOnly | 0);
      formData.append('desktopOnly', this.formDesktopOnly | 0);
      formData.append('supportSnowGroup', this.formSupportSnowGroup);
      formData.append('supportPhone', this.formSupportPhone);
      formData.append('supportEmail', this.formSupportEmail);
      this.sending = true;

      dataLoader.putDirectoryApplication(
        this.formId,
        formData
      ).then(() => {
        logger.info('Form has been sent!');
        this.formSent = true;
      }).catch(error => {
        logger.error('Sending form has failed:', error);
        //this.formFailed = true;
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.sending = false;
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_FORM, false);
        this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.FETCH_DIRECTORY_ITEMS, {force: true, params: [...this.endpointListOptions]});
      })
    },

    wishAppForm() {
      let formData = new FormData();
      formData.append('description', this.formWishDescription);
      this.sending = true;

      dataLoader.postDirectoryApplicationWish(
        formData
      ).then(() => {
        logger.info('Form wish has been sent!');
        this.formSent = true;
      }).catch(error => {
        logger.error('Sending form wish has failed:', error);
        //this.formFailed = true;
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.sending = false;
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_WISH, false);
      })
    },

    clickEventFavorite() {
      if (this.detailData) {
        if (this.detailData.favorite) {
          this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.SET_DIRECTORY_ITEM_FAVORITE_OFF, this.detailData)
        } else {
          this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.SET_DIRECTORY_ITEM_FAVORITE, this.detailData)
        }
      }
    },

    clickEventEdit() {
      if (this.detailData && this.canManageItem) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ID, this.detailData.id);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_NAME, this.detailData.name);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_DESCRIPTION, this.detailData.description);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_LINK, this.detailData.url);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_LINK_WIKI, this.detailData.homepageUrl);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ICON_OLD, this.detailData.iconUrl);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ICON_COLOR, this.detailData.iconColor);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_SUPPORT_SNOW_GROUP, this.detailData.supportSnowGroup);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_SUPPORT_EMAIL, this.detailData.supportEmail);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_SUPPORT_PHONE, this.detailData.supportPhone);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_PRIVATE_ONLY, this.detailData.privateOnly);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_DESKTOP_ONLY, this.detailData.desktopOnly);
        this.validateForm();
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_DETAIL, false);
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_FORM, true);
      }
    },

    clickEventDelete() {
      if (this.detailData && this.canManageItem) {
        this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_DETAIL, false);
        this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.SET_DIRECTORY_ITEM_DELETE, this.detailData);
        this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.FETCH_DIRECTORY_ITEMS, {force: true, params: [...this.endpointListOptions]});
      }
    },

    clickEventShowWishAppForm() {
      this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_WISH, true);
    },
  }
}
</script>
