<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <template v-slot:header>
      <prj1001-detail-header
        v-if="serviceData"
        :headerImage="serviceData.image"
        :headerTag="''"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        v-if="!isLoading"
        class="vue-event-detail-content"
      >
        <!--Heading-->
        <prj1017-heading
          :detailheader="serviceData.name"
        />

        <!--Description-->
        <div
          v-if="serviceData.description"
          class="vue-event-detail-text"
          v-html="serviceData.description"
        />

        <!--Open hours-->
        <div
          v-if="serviceData.openingHours && serviceData.openingHours !== '[]'"
          class="vue-service-day-time"
        >
          <prj1028-day-time
            v-for="(item, index) in JSON.parse(serviceData.openingHours)"
            :key="index"
            :day="item.name"
            :time="item.value"
          />
        </div>

        <!--Details-->
        <div class="vue-service-details">
          <p
            v-if="serviceData.contactAddress"
            class="vue-address"
          >
            {{ serviceData.contactAddress }}
          </p>
          <a
            v-if="serviceData.contactAddress"
            class="vue-icon vue-map"
            :href="generateGoogleMapsUrl(serviceData.contactAddress)"
            target="_blank"
          >
            {{ $t('general.map') }}
          </a>
          <a
            v-if="serviceData.contactPhone"
            :href="`tel:${serviceData.contactPhone}`"
            class="vue-icon vue-phone"
            target="_blank"
          >
            {{ serviceData.contactPhone }}
          </a>
          <a
            v-if="serviceData.contactEmail"
            :href="`mailto:${serviceData.contactEmail}`"
            class="vue-icon vue-email"
            target="_blank"
          >
            {{ serviceData.contactEmail }}
          </a>
          <a
            v-if="serviceData.contactUrl"
            :href="serviceData.contactUrl"
            class="vue-icon vue-website"
            target="_blank"
          >
            {{ $t('services.websiteLink') }}
          </a>
        </div>

        <frm1031-button-container>
          <frm1006-button
            v-if="serviceData.room"
            type="primary"
            :title="$i18n.t('services.findInBuilding')"
            @buttonClickEvent="goToMapSearch(serviceData.room)"
          >
            {{ $t('services.findInBuilding') }}
          </frm1006-button>
        </frm1031-button-container>
      </div>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
          :class="errorClassObject"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import Prj1002Navbar from '../../components/prj1002-navbar/prj1002-navbar';
import PageLayout from '../../templates/partials/page-layout';

import mxNavigationDefault from '../../mixins/mx-navigation-default';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import Prj1001DetailHeader from '@/components/prj1001-detail-header/prj1001-detail-header';
import {ERROR_MODAL_TIMEOUT} from '@/constants/app-constants';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import mxErrorMessage from '@/mixins/mx-error-message';
import Prj1028DayTime from '@/components/prj1028-day-time/prj1028-day-time';
import {mapState} from 'vuex';
import router from '@/router';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'ServicesDetail',

  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading,
    Prj1001DetailHeader,
    Prj1028DayTime
  },

  mixins: [
    mxNavigationDefault,
    mxErrorMessage
  ],

  data() {
    return {
      isLoading: false,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      serviceFetched: {},
    }
  },

  computed: {
    ...mapState('services', [
      'detailData'
    ]),

    serviceId() {
      return this.$route.params.id;
    },

    serviceCached() {
      return this.detailData;
    },

    serviceData() {
      if (Object.keys(this.serviceCached).length) {
        return this.serviceCached;
      }
      return this.serviceFetched;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value && Object.keys(this.serviceData).length) {
        this.setLoadingState(false);
      }
    },
  },

  mounted() {
    if (this.serviceCached?.id !== this.serviceId) {
      this.getServiceDetail(this.serviceId);
    } else {
      this.initialized = true;
    }
  },

  methods: {
    setLoadingState(value) {
      if (value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getServiceDetail(serviceId) {
      this.setLoadingState(true);

      return dataLoader.fetchMyServiceDetail(serviceId)
        .then(data => {
          this.serviceFetched = data;
          this.$store.commit(STORE_MODULES.SERVICES + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, this.serviceFetched);
        })
        .catch(error => {
          logger.error(error);
          this.setErrorMessage(error?.response?.data);
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.initialized = true;
          this.setLoadingState(false);
        });
    },

    generateGoogleMapsUrl(address) {
      const googleAddressBase = 'https://www.google.com/maps/search/?api=1&query=';
      const escapedAddressQuery = encodeURIComponent(address);

      return googleAddressBase + escapedAddressQuery;
    },

    goToMapSearch(searchString) {
      if (searchString !== '') {
        router.push({ name: 'search', params: { room: searchString, serviceId: this.serviceId }});
      }
    }
  }
}
</script>
