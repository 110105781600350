import axiosConfig from '@/utils/data-loader/axiosConfig'
import * as envConfig from 'env-config'
import utilsGeneral from '@/utils/esg/utils-general'
import logger from '@/utils/logger'
import { GET_APP_CONFIG_FAIL, GET_APP_CONFIG_SUCCESS } from '@/store/constants/esg/response-types'

export default {
  async getAppConfig() {
    const processId = utilsGeneral.startAsyncProcess()

    try {
      const res = await axiosConfig.get(envConfig.default.webServices.ESG_WEB_SERVICES.CMS_APP_CONFIG)
      const { data, status } = res
      if (status === 200) return { type: GET_APP_CONFIG_SUCCESS, data }
    } catch ({ response }) {
      logger.error(response)
      return { type: GET_APP_CONFIG_FAIL, data: response.data}
    } finally {
      utilsGeneral.stopAsyncProcess(processId)
    }
  },
}
