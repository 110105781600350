import * as MUTATIONS_CONSTANTS from '../constants/mutations';
import * as ACTIONS_CONSTANTS from '../constants/actions';

const state = {
  eventsCategories: [],
  eventsSelectedCategories: [],
  registeredEvent: {
    id: 0,
    name: ''
  },
  registeredEventsArray: [],
  searchQuery: '',
  detailData: {},
  isSearchActive: false,
  isSearchHistoryActive: false,
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_EVENTS_CATEGORIES](state, eventsCategories) {
    state.eventsCategories = eventsCategories;
  },

  [MUTATIONS_CONSTANTS.SET_FILTERED_CATEGORIES](state, categoriesToFilter) {
    state.eventsSelectedCategories = categoriesToFilter
  },

  [MUTATIONS_CONSTANTS.SET_EVENT_REGISTRATION_ID](state, payload) {
    state.registeredEvent.id = payload.id;
    state.registeredEvent.name = payload.name;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_QUERY](state, searchQuery) {
    state.searchQuery = searchQuery;
  },

  [MUTATIONS_CONSTANTS.SET_DETAIL_DATA](state, detailData) {
    state.detailData = detailData;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_STATUS](state, status) {
    state.isSearchActive = status;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS](state, status) {
    state.isSearchHistoryActive = status;
  },
};

const actions = {
  [ACTIONS_CONSTANTS.RESET_ALL_EVENTS]({ commit }) {
    commit(MUTATIONS_CONSTANTS.SET_FILTERED_CATEGORIES, []);
  }
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
