<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div class="">
    <!-- Field -->
    <div class="relative mb-4">
      <label class="prj-form-control-w-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        <span class="flex-1 pr-6 break-words">
          <span
            v-if="!croppedImageConfirmed"
            class="text-gray-500"
          >
            {{ placeholder }}
          </span>

          <span
            v-else
            class="text-black"
          >
            {{ selectedFileName }}
          </span>
        </span>
        <input
          ref="photoFileUpload"
          class="hidden"
          type="file"
          accept="image/jpeg, image/png"
          @change="uploadPhoto"
        />
      </label>

      <!-- Close button -->
      <span
        v-if="croppedImageConfirmed"
        class="absolute top-1/2 transform -translate-y-2/4 right-3"
        @click="cancelUpload"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
    </div>

    <!-- Image edit mode -->
    <div
      v-show="modeEdit"
      class="fixed top-0 right-0 bottom-0 left-0 bg-white overflow-y-auto"
    >
      <div class="relative mx-auto prj-layout-padding-x w-full sm:w-144 h-full flex flex-col">
        <div
          v-show="!croppedImageConfirmed"
          class="flex-1 flex items-center justify-center relative"
        >
          <VueCropper
            v-show="selectedFile"
            ref="cropper"
            :src="selectedFile"
            :aspectRatio="cropperAspectRatio"
            :zoomable="false"
            :viewMode="Number(3)"
            :autoCropArea="1"
          />
        </div>

        <div
          v-if="!croppedImageConfirmed"
          class="prj-controls flex justify-between relative z-10"
        >
          <t-button
            variant="secondary"
            class="flex mr-4 w-24 justify-center"
            @click="cancelUpload"
          >
            <span class="hidden">{{ $t("esg.forms.buttons.buttonCancel") }}</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </t-button>
          <t-button
            class="flex flex-1 justify-center"
            @click="confirmImage"
          >
            <span class="hidden">{{ $t("esg.forms.buttons.buttonConfirm") }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </t-button>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>

import VueCropper from 'vue-cropperjs';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1011ImageUpload',
  components: { VueCropper },
  props: {
    //=== GENERAL
    name: String,
    placeholder: {
      type: [String, global.VueI18n],
      default: null,
    },
    croppedImageHeight: { type: Number, default: 512 },
    // must be included in props
    fileSelected: {
      type: null
    },
    value: String, // cropped image in Base64
    label: String,
    labelValidation: String,
    required: Boolean,
    showFileName: Boolean,
    //=== ASPECT RATIO
    cropperAspectRatio: { type: Number, default: 1 },
    //=== VALIDATION
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    validationMode: {
      default: 'eager',
      type: String
    },
    //=== MISC
    id: String
  },
  data() {
    return {
      fileSelectedData: '',
      selectedFileName: '',
      croppedImageConfirmed: false,
      modeEdit: false,
      // cropper
      croppedImage: '',
      croppedImageBase64: '',
      autoCrop: false,
      selectedFile: ''
    };
  },
  computed: {

  },
  watch: {
    croppedImageConfirmed(value) {
      if (value) {
        this.$emit('input', this.croppedImageBase64);
      } else {
        this.$emit('input', '');
      }
    }
  },
  created() {
    if (this.fileSelected) {
      this.fileSelectedData = this.fileSelected;
    }
  },
  methods: {
    confirmImage() {
      this.croppedImage = this.$refs.cropper.getCroppedCanvas({
        width: this.croppedImageHeight * this.cropperAspectRatio,
        height: this.croppedImageHeight,
        imageSmoothingQuality: 'high'
      });
      this.croppedImageBase64 = this.croppedImage.toDataURL('image/jpeg');
      this.modeEdit = false;

      this.croppedImageConfirmed = true;
    },
    uploadPhoto(event) {
      if (this.croppedImageConfirmed) {
        this.cancelUpload();
      }
      const file = event.target.files[0]

      if (file) {
        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = event => {
            this.selectedFileName = file.name;
            this.selectedFile = event.target.result;
            this.$refs.cropper.replace(this.selectedFile);
            this.modeEdit = true;
          };
          reader.readAsDataURL(file);
        } else {
          alert('Sorry, FileReader API not supported');
        }
      } else {
        this.cancelUpload();
      }

      this.resetFileInput();
    },
    cancelUpload() {
      this.selectedFile = '';
      this.$refs.cropper.replace(null);
      this.modeEdit = false;
      this.croppedImageConfirmed = false;
    },
    resetFileInput() {
      if (this.$refs.photoFileUpload) this.$refs.photoFileUpload.value = ''; // Reset for re-upload same photo
    }
  },
}
</script>
