//============ GENERAL =================================//
//======================================================//
import { SET_SOCIAL_LINKS, SET_SOCIAL_LINKS_EMPTY } from '@/store/constants/fest/mutations'
import { GET_SOCIAL_LINKS } from '@/store/constants/fest/actions'

//============ API =====================================//
//======================================================//
import apiCmsSocial from '@/fest-api/cms-social'
import logger from '@/utils/logger'

//============ STATE ===================================//
//======================================================//
const state = {
  links: [],
  hasNoContent: false
}

//============ MUTATIONS ===============================//
//======================================================//
const mutations = {
  [SET_SOCIAL_LINKS](state, payload) {
    state.links = payload.reduce((acc, item) => {
      return item.url ? [...acc, item] : acc
    }, [])
  },
  [SET_SOCIAL_LINKS_EMPTY](state, payload) {
    state.hasNoContent = payload
  }
}

//============ ACTIONS =================================//
//======================================================//

const actions = {
  async [GET_SOCIAL_LINKS]({ commit }) {
    try {
      const { status, data } = await apiCmsSocial.getAllSocialLinks()
      if (status === 200) {
        return data.length ? commit(SET_SOCIAL_LINKS, data) : commit(SET_SOCIAL_LINKS_EMPTY, true)
      }
    } catch (error) {
      logger.error(error)
    }
  },
}

//============ GETTERS =================================//
//======================================================//

const getters = {}

//============ EXPORT ==================================//
//======================================================//
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
