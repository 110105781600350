<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1017-heading
        :header="$i18n.t('profile.forms.headlineCancelAccount')"
      />

      <gen1006-info-panel
        type="info"
      >
        {{ $t('profile.forms.msgCancelAccount') }}
      </gen1006-info-panel>

      <gen1006-info-panel
        v-if="sendFailed"
        type="error"
      >
        {{ $t('profile.forms.msgCancelAccountFailed') }}
      </gen1006-info-panel>

      <frm1035-form-group>
        <frm1001-input-field
          id="idea-user-name"
          v-model="sendingForm.userEmail"
          :tooltip="false"
          :label="$i18n.t('profile.forms.workEmail')"
        />
      </frm1035-form-group>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        :class="classObject"
        type="primary"
        :disabled="!isFormValid || sending"
        @buttonClickEvent="deleteAccount"
      >
        {{ $t('profile.forms.buttonCancelAccount') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />
    </template>
  </page-layout>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
// TODO MSE: improve - add to PWA Config when implementing CSOBZ-288

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import moduleStatistics from '@/mixins/mx-module-statistics';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import logger from '@/utils/logger';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import {FEST_USER} from '@/store/store-modules';
import {DELETE_USER_FESTIVAL_DATA} from '@/store/constants/fest/actions';

export default {
  name: 'ProfileCancelAccount',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      sendingForm: {
        userEmail: ''
      },
      sending: false,
      sendFailed: false
    }
  },

  computed: {
    ...mapState('user', ['profile']),
    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY
    }),

    classObject() {
      return {
        'vue-is-active': this.isFormValid,
      }
    },

    isUserEmail() {
      return this.sendingForm.userEmail === this.profile.workEmail;
    },

    isFormValid() {
      return this.isUserEmail;
    },
  },

  methods: {
    deleteAccount() {
      this.sending = true

      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.DELETE_IDENTITY_PROFILE)
        .then(() => {
          this.$store.dispatch(FEST_USER + '/' + DELETE_USER_FESTIVAL_DATA);
          window.localStorage.removeItem('zapka-localstorage');
          this.$router.push({ name: 'login', params: { autologin: false }});
        })
        .catch((error) => {
          this.sendFailed = true;
          logger.error('Sending form has failed.', error.response);
        })
        .finally(() => {
          this.sending = false;
        });
    }
  }
}
</script>
