<template>
  <div class="vue-c-food-menu">
    <template
      v-for="(item, index) in menuData"
    >
      <prj1006-food-menu-item
        v-if="item.name !== null"
        :key="'item-' + item.id + '-' + index"
        :item-data="item"
        :item-index="index"
        @mealSelected="openMealDetail"
      />
    </template>
  </div>
</template>

<script>
import Prj1006FoodMenuItem from './prj1006-food-menu-item';

export default {
  name: 'Prj1006FoodMenu',
  components: {
    Prj1006FoodMenuItem
  },

  props: {
    componentKey: {
      type: Number,
      required: true
    },
    menuData: {
      type: [Object, Array],
      required: true
    }
  },

  data() {
    return {
      todayDay: new Date().getDate(),
      hasError: false
    }
  },

  computed: {
    firstItemsOfCategories() {
      let usedCategoryIds = [];
      let firstItemsArray = [];

      this.menuData.forEach(menuItem => {
        if (!usedCategoryIds.includes(menuItem.category_id)) {
          firstItemsArray.push(menuItem.id);
          usedCategoryIds.push(menuItem.category_id);
        }
      });

      return firstItemsArray;
    }
  },

  methods: {
    openMealDetail(itemIndex, event) {
      this.$emit('openMealDetail', itemIndex, event);
    }
  }
}
</script>
