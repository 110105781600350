<template>
  <div class="vue-c-separator">
    <label
      v-if="caption"
    >{{ caption }}</label>
  </div>
</template>

<script>
// TODO: MSE & JNO: how to solve super simple components - CSS components only / something else?
export default {
  name: 'Prj1020Separator',
  props: {
    caption: {
      type: String,
      required: false,
      default: null
    },
  },
}
</script>
