<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <prj1007-event-card
    :class="[isFullPage ? 'h-11/12 my-auto' : 'h-vh-60']"
    :image="image"
    :isSingle="isSingle"
    :status="status"
    :participants="participants"
    :statusTag="statusTag"
  >
    <!--========== HEADER =================================-->
    <!--===================================================-->
    <template v-slot:header>
      <t-tag
        variant="sectionTitle"
        class="flex text-white line-clamp-2"
        :class="categoryId ? 'mt-3 xs:mt-7 mb-3' : 'my-3 xs:my-7'"
        tag-name="h2"
      >
        <span v-html="title"></span>
      </t-tag>
      <prj1018-fest-badge
        v-if="categoryId"
        :categoryId="categoryId"
        :themeTitle="themeTitle"
        class="mb-2"
        iconClasses="h-4 w-4"
        hasBg
        hasCategoryHash
      />
    </template>

    <!--========== CONTENT ================================-->
    <!--===================================================-->
    <template v-slot:content>
      <p
        v-if="description"
        class="line-clamp-5 xs:line-clamp-8"
      >
        {{ description }}
      </p>

      <a
        v-if="hasLink"
        class="relative flex items-center"
        target="_blank"
        :href="linkUrl"
      >
        <span
          class="mr-2"
          v-html="getEventIcon()"
        ></span>

        <span class="text-lg leading-6 font-semibold">{{ link }}</span>
      </a>
      <t-icon-button
        v-if="isRunning && isCompetitionLinked"
        variant="link"
        @click="onLinkedEventClick"
      >
        <svg
          class="h-6 w-6 mr-2"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.8284 10.1716C12.2663 8.60948 9.73367 8.60948 8.17157 10.1716L4.17157 14.1716C2.60948 15.7337 2.60948 18.2663 4.17157 19.8284C5.73367 21.3905 8.26633 21.3905 9.82843 19.8284L10.93 18.7269M10.1716 13.8284C11.7337 15.3905 14.2663 15.3905 15.8284 13.8284L19.8284 9.82843C21.3905 8.26633 21.3905 5.73367 19.8284 4.17157C18.2663 2.60948 15.7337 2.60948 14.1716 4.17157L13.072 5.27118"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="text-lg leading-6 font-semibold">{{ $t('fest.vote.linkedEventName') }}</span>
      </t-icon-button>
    </template>

    <!--========== CONTROLS ===============================-->
    <!--===================================================-->
    <template v-slot:controls>
      <div
        v-if="isRunning"
        class="flex flex-col"
      >
        <t-button
          :disabled="isPrimaryBtnDisabled"
          class="mb-2 bg-fest-home-light"
          @click="clickedPrimary"
        >
          {{ isVoted ? $t('fest.vote.cardPrimaryVotedButton') : $t('fest.vote.cardPrimaryButton') }}
        </t-button>

        <!-- <t-button
          :disabled="isSecondaryBtnDisabled"
          variant="secondarySmall"
          @click="clickedSecondary"
        >
          {{ $t('fest.vote.cardSecondaryButton') }}
        </t-button> -->
      </div>

      <div
        v-if="isWaiting"
        class="flex flex-col text-black items-center xs:mb-2.5"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8 mb-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <span class="text-lg leading-6 font-semibold">{{ $t('fest.vote.waiting') }}</span>
      </div>

      <div
        v-if="isExpired"
        class="flex flex-col text-black items-center xs:mb-2.5"
      >
        <t-button
          class="w-full bg-fest-home-light"
          :disabled="true"
        >
          {{ $t('fest.vote.expired') }}
        </t-button>
      </div>
    </template>
  </prj1007-event-card>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
import { DEFAULT, WAITING, RUNNING, EXPIRED } from '@/constants/fest/status'
import eventIcons from '@/assets/fest/events/eventIcons'

import Prj1018FestBadge from '@/components/fest/prj1018-fest-badge/prj1018-fest-badge'
import Prj1007EventCard from '@/components/fest/prj1007-event-card/prj1007-event-card'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1007VoteCard',
  components: { Prj1007EventCard, Prj1018FestBadge },
  props: {
    themeTitle: {
      type: String,
      default: null,
    },
    categoryId: {
      type: Number,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    isFullPage: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    isCompetitionLinked: {
      type: Boolean,
      default: false,
    },
    isPrimaryBtnDisabled: {
      type: Boolean,
      default: false,
    },
    isSecondaryBtnDisabled: {
      type: Boolean,
      default: false,
    },
    isResultReady: {
      type: Boolean,
      default: false,
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
    linkUrl: {
      type: String,
      default: null,
    },
    participants: {
      type: Number,
      default: null,
    },
    status: {
      type: String,
      default: DEFAULT,
      validator: (value) => [DEFAULT, WAITING, RUNNING, EXPIRED].includes(value),
    },
    statusTag: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    isVoted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      eventIcons,
    }
  },
  computed: {
    hasLink() {
      return this.link && this.linkUrl
    },
    isWaiting() {
      return this.status === WAITING
    },
    isRunning() {
      return this.status === RUNNING
    },
    isExpired() {
      return this.status === EXPIRED
    },
  },
  methods: {
    clickedPrimary() {
      this.$emit('primaryButton')
    },
    clickedSecondary() {
      this.$emit('secondaryButton')
    },
    onResultClick() {
      this.$emit('resultClick')
    },
    onLinkedEventClick() {
      this.$emit('linkedEventClick')
    },
    getEventIcon() {
      return this.eventIcons.find((icon) => icon.name === this.icon)?.icon
    },
  },
}
</script>
