<template>
  <div
    class="vue-c-stepper"
    :class="classObject"
  >
    <div
      v-for="(step, index) in stepsCount"
      :key="'step-' + index"
      class="vue-step-item"
      :class="stepItemClassObject(step)"
    />
  </div>
</template>

<script>
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

export default {
  name: 'Prj1040Stepper',

  mixins: [mxDetectDesktop],

  props: {
    stepsCount: {
      type: Number,
      required: true
    },
    activeStep: {
      type: Number,
      required: true
    }
  },

  computed: {
    classObject() {
      return [
        {
          'vue-is-desktop': this.isDesktopLayout
        }
      ];
    },
  },

  classObject() {
    return [
      {
        'vue-is-desktop': this.isDesktopLayout
      }
    ];
  },

  methods: {
    stepItemClassObject(step) {
      return {
        'vue-step-item-active': this.activeStep === step
      }
    }
  }
}
</script>
