export const DATA_ARRAY = Object.freeze({
  audience: [
    {
      'cs': {
        'title': '4,2',
        'subtitle': 'milionů zákazníků',
        'items': {
          1: {
            'value': '141 tis.',
            'description': 'firemních klientů + zákazníků z kategorie malých a středně velkých podniků',
          },
          2: {
            'value': '0,93 mil.',
            'description': 'zákazníků využívá naše investiční služby',
          },
          3: {
            'value': '141 tis.',
            'description': 'uživatelů využívá naše mobilní služby',
          },
        }
      },
      'en': {
        'title': '4,2',
        'subtitle': ' million clients',
        'items': {
          1: {
            'value': '141 thousand',
            'description': 'corporate and SME clients',
          },
          2: {
            'value': '0,93 million',
            'description': 'investment clients',
          },
          3: {
            'value': '743 thousand',
            'description': 'mobile users',
          },
        },
      }
    }
  ]
});
