<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        ref="navbar"
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj-1024-item-list
        ref="benefitsList"
        :listData="benefits"
        :listItemsCount="benefitsNumber"
        :isFilterActive="benefitsAreFiltersActive"
        :noMoreItems="benefitsNoMore"
        :componentKey="componentKey"
        :listItemConfig="listItemConfigObject"
        @loadingBenefitsList="startLoadingBenefitsList"
        @loadedBenefitsList="finishLoadingBenefitsList"
        @loadNewContent="loadNewContent"
        @openFilterDialog="openFilterDialog"
        @resetFilteredItems="resetItems"
        @scrollToTop="scrollToTop"
        @goToDetail="goToDetail"
      />
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <prj1026-category-filter
        v-if="benefitsListInitialized && convertedFilterCategories.length"
        :filterCategories="convertedFilterCategories"
        :showItems="convertedFilterCategories.length"
        :showFilterButton="benefitsAreFiltersActive"
        :activeCategory="parseInt(benefitsActiveCategory)"
        filterType="forvardino"
        @filterItems="filterItems"
        @resetFilteredItems="resetItems"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="!benefitsListInitialized || isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="hasError"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          <span>
            {{ $t('error.generic') }}
          </span>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import router from '@/router';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import EVENT_BUS from '@/event-bus';
import { ERROR_MODAL_TIMEOUT, DEFAULT_LIST_PAGINATION } from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1024ItemList from '@/components/prj1024-item-list/prj1024-item-list';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter';

export default {
  name: 'BenefitsList',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1024ItemList,
    Prj1026CategoryFilter,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      benefits: [],
      benefitsNumber: 0,
      benefitsNoMore: false,
      currentPosition: 0,
      componentKey: 0,
      listItemConfigObject: {
        displayDate: false,
        displayRating: false,
      },
      benefitsListInitialized: false,
      isLoading: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
    }
  },

  computed: {
    ...mapState('benefits', [
      'benefitsFilterCategories',
      'benefitsFiltersLoaded',
      'benefitsSelectedCategories',
      'benefitsActiveCategory',
      'benefitsAreFiltersActive',
      'searchQuery'
    ]),

    convertedFilterCategories() {
      let convertedFilterCategories = [];

      Object.values(this.benefitsFilterCategories.categories).forEach(item => {
        convertedFilterCategories.push({ 'termId': item.id, 'termName': item.name });
      })

      return convertedFilterCategories;
    },

    isInfinityScrollEnabled() {
      return this.benefitsNumber > 20 && !this.benefitsNoMore
    },

    endpointListOptions() {
      return [
        this.benefitsSelectedCategories.categories,
        this.benefitsSelectedCategories.location,
        this.searchQuery,
        this.currentPosition,
        20
      ]
    },

    pageId() {
      if (typeof this.$route.params.categoryId === 'undefined') {
        return 0;
      }

      return this.$route.params.categoryId;
    },

    navbarCaption() {
      return this.$route.meta.navbarCaption;
    },
  },

  watch: {
    benefitsActiveCategory() {
      const benefitsCategoryNumber = this.benefitsActiveCategory === 0 ? null : this.benefitsActiveCategory;

      router.push({ name: 'benefits', params: { categoryId: benefitsCategoryNumber }});
      this.fetchBenefitsData();
    },

    benefitsAreFiltersActive(oldVal, newVal) {
      if (oldVal !== newVal && newVal === false) {
        this.fetchBenefitsData();
      }
    },

    searchQuery() {
      this.fetchBenefitsData();
    }
  },

  mounted() {
    this.fetchBenefitsData();

    EVENT_BUS.$on(GLOBAL_EVENTS.FILTER_ITEM_LIST, categoryId => {
      this.filterItems(categoryId);
    });

    this.setBenefitsActiveCategory();
  },

  beforeDestroy() {
    EVENT_BUS.$off(GLOBAL_EVENTS.FILTER_ITEM_LIST);
  },

  methods: {
    fetchBenefitsData() {
      this.componentKey++;
      this.currentPosition = 0;
      this.benefitsListInitialized = false;

      this.$store.dispatch(STORE_MODULES.BENEFITS + '/' + ACTIONS_CONSTANTS.FETCH_BENEFITS_FILTER_DATA);

      dataLoader.fetchBenefitsList([...Object.values(this.endpointListOptions)])
        .then(response => {
          this.benefits = response.offers;
          this.benefitsNumber = parseInt(response.resultsCount);
          this.benefitsListInitialized = true;
        })
        .catch(error => {
          this.benefitsListInitialized = true;
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          if (this.benefitsNumber > 20) {
            this.benefitsNoMore = false;
            this.$refs.benefitsList?.initInfinityScroll();
          }
        });
    },

    async loadNewContent() {
      if (this.benefitsNoMore) return;
      this.currentPosition += DEFAULT_LIST_PAGINATION;

      await dataLoader.fetchBenefitsList([...this.endpointListOptions])
        .then(response => {
          let tempArray = this.benefits;
          this.benefits = [...tempArray, ...response.offers];

          if (response.offers.length < DEFAULT_LIST_PAGINATION) {
            this.benefitsNoMore = true;
          }
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        });
    },

    setBenefitsActiveCategory() {
      if (typeof this.$route.params.categoryId !== 'undefined') {
        this.$store.commit(STORE_MODULES.BENEFITS + '/' + MUTATIONS_CONSTANTS.SET_BENEFITS_ACTIVE_CATEGORY, this.$route.params.categoryId);

        if (parseInt(this.$route.params.categoryId) !== 0) {
          this.filterItems(this.benefitsActiveCategory);
        } else {
          this.resetItems();
        }
      }
    },

    startLoadingBenefitsList() {
      this.benefitsListInitialized = false;
    },

    finishLoadingBenefitsList() {
      this.benefitsListInitialized = true;
    },

    openFilterDialog() {
      this.$store.dispatch(STORE_MODULES.POPUP + '/' + ACTIONS_CONSTANTS.TOGGLE_POPUP, 'benefits');
    },

    /**
     * @param categoryNumber number, $emitted from Prj1026CategoryFilter
     */
    filterItems(categoryNumber) {
      let selectedCategoryPayload = {
        categories: [categoryNumber]
      };

      this.$store.commit(STORE_MODULES.BENEFITS + '/' + MUTATIONS_CONSTANTS.SET_BENEFITS_SELECTED_CATEGORIES, selectedCategoryPayload);
      this.$store.commit(STORE_MODULES.BENEFITS + '/' + MUTATIONS_CONSTANTS.SET_BENEFITS_ACTIVE_CATEGORY, categoryNumber);

      this.fetchBenefitsData();
    },

    resetItems() {
      this.$store.dispatch(STORE_MODULES.BENEFITS + '/' + ACTIONS_CONSTANTS.RESET_ALL_BENEFITS_FILTER)
        .then(() => {
          this.fetchBenefitsData();
        });
    },

    // FIXME: CSOBZ-515 - merge all scroll to top methods into one global method
    scrollToTop() {
      this.$nextTick(() => {
        this.scrollToElement();
      })
    },

    scrollToElement() {
      const ref = this.$refs.navbar.$el;

      if (ref) {
        ref.scrollIntoView(true);
      }
    },

    goToDetail(data) {
      // save to store
      this.$store.commit(STORE_MODULES.BENEFITS + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, data);
      router.push({ name: 'benefits-detail', params: { id: data.id }});
    },
  }
}
</script>
