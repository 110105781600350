<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1017-heading
        :header="$i18n.t('profile.forms.headlineEditName')"
      />

      <gen1006-info-panel
        v-if="sendFailed"
        type="error"
      >
        {{ $t('profile.forms.msgUpdateProfileFailed') }}
      </gen1006-info-panel>

      <frm1035-form-group>
        <frm1001-input-field
          id="idea-user-name"
          v-model="sendingForm.userFullName"
          class="vue-ideas-sending-item"
          :tooltip="false"
          :label="$i18n.t('profile.forms.fullName')"
        />
      </frm1035-form-group>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        class="vue-ideas-sending-submit-button"
        :class="classObject"
        type="primary"
        :disabled="!isFormValid || sending"
        @buttonClickEvent="submitName"
      >
        {{ $t('forms.submit') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />
    </template>
  </page-layout>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
// TODO MSE: improve - add to PWA Config when implementing CSOBZ-288
import {
} from '@/constants/app-constants';

import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import router from '@/router';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import mxNavigationDefault from '../../mixins/mx-navigation-default';

export default {
  name: 'ProfileEditName',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      sendingForm: {
        userFullName: '',
      },
      sending: false,
      sendFailed: false
    }
  },

  computed: {
    ...mapState('user', ['profile']),
    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY
    }),

    classObject() {
      return {
        'vue-is-active': this.isFormValid,
      }
    },

    isFormValid() {
      const requiredFields = [
        'userFullName',
      ];

      let decision = false;
      let numberOfValidInputs = 0;

      requiredFields.forEach(item => {
        if (this.sendingForm[item].length) {
          numberOfValidInputs++;
        } else {
          numberOfValidInputs--;
        }
      });

      if (numberOfValidInputs === requiredFields.length) {
        decision = true;
      }

      return decision;
    },
  },

  mounted() {
    this.sendingForm.userFullName = this.profile?.name ?? '';
  },

  methods: {
    submitName() {
      let formData = new FormData();
      formData.append('name', this.sendingForm.userFullName);

      this.sending = true
      dataLoader
        .putIdentityProfile(formData)
        .then(() => {
          logger.info('Form has been sent.')
          this.sending = false

          this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.IDENTITY_PROFILE, {force: true});

          this.goToProfile();
        })
        .catch((error) => {
          this.sending = false
          this.sendFailed = true
          logger.error('Sending form has failed.', error.response)
        });
    },

    clearForm() {
      this.sendingForm.userFullName = '';
    },

    goToProfile() {
      router.push({ name: 'profile' });
    }
  }
}
</script>
