<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:content>
          <div class="prj-layout-default">
            <!--========== ARTICLES ===============================-->
            <!--===================================================-->
            <prj1023-no-content-panel
              v-if="!sortedArticles.length && !loading"
              :text="$t('fest.news.placeholder')"
              class="mt-8"
            />
            <div
              v-for="({ articles, month, year }, i) in sortedArticles"
              v-else
              :key="`news-${month}-${year}`"
            >
              <transition
                name="fade"
                appear
              >
                <t-tag
                  variant="blockTitle"
                  class="capitalize mt-2 mb-6 transition-opacity duration-100"
                  tag-name="h3"
                  :style="transitionDelay(i)"
                >
                  {{ month }}
                </t-tag>
              </transition>
              <prj1004-article-list
                :articles="articles"
                :animDelay="getAnimDelay(i)"
                :itemAnimDuration="itemAnimDuration"
                :pathName="ROUTE_NEWS_DETAIL"
              />
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import zapUtilsGeneral from '@/utils/utils-general'
import utilsGeneral from '@/utils/fest/utils-general'
import apiCmsContent from '@/fest-api/cms-content'
import logger from '@/utils/logger'

import { mapState } from 'vuex'
import { FEST_THEMES } from '@/store/store-modules'
import { GET_THEMES_INFO } from '@/store/constants/fest/actions'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import { FESTIVAL_KEY_PREFIX } from '@/constants/app-constants'
import { ROUTE_NEWS_DETAIL, ROUTE_TARGETS } from '@/constants/fest/route-names'

import PageLayout from '@/templates/fest/page-layout'
import Prj1004ArticleList from '@/components/fest/prj1004-article-list/prj1004-article-list'
import Prj1023NoContentPanel from '@/components/fest/prj1023-no-content-panel/prj1023-no-content-panel'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTNewsList',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1004ArticleList,
    Prj1023NoContentPanel,
    PageLayout
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_NEWS_DETAIL,
      ROUTE_TARGETS,
      loading: true,
      sortedArticles: [],
      promotedTargetsPage: null,  // TODO fetch from API
      itemAnimDuration: 0.1, // equivalent to tw class duration-100
      activeThemeFilterId: this.$route.params?.themeId || 0
    }
  },
  computed: {
    ...mapState('festThemes', ['themesInfo']),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),
    festConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(FESTIVAL_KEY_PREFIX)) {
            const itemNameWithoutPrefix = item.key.replace(FESTIVAL_KEY_PREFIX, '');
            const itemName = zapUtilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },

    promotedTargetsPageId() {
      return (typeof this.festConfig?.targetPageId !== 'undefined' ) ? this.festConfig.targetPageId : 0;
    },

    newsCategoryId() {
      return (typeof this.festConfig?.newsCategoryId !== 'undefined' ) ? this.festConfig.newsCategoryId : 0;
    },

    activeVisualFilterTitle() {
      return this.activeThemeFilterId
        ? this.themesInfo?.find((theme) => theme.id === this.activeThemeFilterId)?.title
        : this.$t('fest.news.themeFilterPlaceholder')
    },
  },
  watch: {
    applicationConfigLoaded(value) {
      if (value && this.promotedTargetsPageId !== 0) {
        this.getPageById(this.promotedTargetsPageId)
      }
      if (value && this.newsCategoryId !== 0) {
        this.getArticles()
      }
    },
  },
  async created() {
    if (!this.themesInfo.length) await this.$store.dispatch(`${FEST_THEMES}/${GET_THEMES_INFO}`)

    if (this.promotedTargetsPageId !== 0) {
      this.getPageById(this.promotedTargetsPageId)
    }
    if (this.newsCategoryId !== 0) {
      this.getArticles()
    }
  },
  methods: {
    getArticles() {
      this.loading = true

      let processId = utilsGeneral.startAsyncProcess()

      apiCmsContent
        .getAllArticles(this.newsCategoryId)
        .then((response) => {
          this.sortingArticles(response)
        })
        .catch((error) => {
          logger.error(error)
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId)
          this.loading = false
        })
    },
    sortingArticles(articles) {
      let sorted = []
      let sortedIndex = 0
      let monthTemp = ''

      for (let article of articles) {
        const month = this.$date(article.date).format('MMMM')
        const year = this.$date(article.date).format('YYYY')

        if (month === monthTemp) {
          sorted[sortedIndex - 1].articles.push({ ...article, themeTitle: this.getThemeTitle(article) })
        } else {
          sortedIndex++
          monthTemp = month
          sorted.push({
            month: month,
            year: year,
            articles: [{ ...article, themeTitle: this.getThemeTitle(article) }],
          })
        }
      }

      this.sortedArticles = sorted
    },
    getPageById(id) {
      let processId = utilsGeneral.startAsyncProcess()

      apiCmsContent
        .getPageById(id)
        .then((response) => {
          this.promotedTargetsPage = response
        })
        .catch((error) => {
          logger.error(error)
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId)
        })
    },

    getThemeTitle(article) {
      return this.themesInfo.find((theme) => theme.id === article.themeId)?.title
    },

    prevDelay(i) {
      let delay = 0
      while (i) {
        delay += this.itemAnimDuration * this.sortedArticles[i - 1].articles.length
        i--
      }
      return delay
    },

    getAnimDelay(i) {
      return !i ? 0 : this.prevDelay(i)
    },

    transitionDelay(i) {
      return `transition-delay: ${this.getAnimDelay(i)}s`
    },

    async onActiveFilterChange(id) {
      this.loading = true
      this.activeThemeFilterId = id
      const { status, data } = await apiCmsContent.getNewsByTheme({ themeId: id})
      if (status === 200) {
        this.sortedArticles = []
        this.activeThemeFilterId = id
        this.sortingArticles(data)
        this.loading = false
      }
    },

    onFilterReset() {
      this.sortedArticles = []
      this.activeThemeFilterId = 0
      this.getArticles()
    }
  },
}
</script>
