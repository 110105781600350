<template>
  <div class="vue-c-calendar">
    <vue-cal
      hide-view-selector
      :time="false"
      :active-view="usage"
      :events="convertedEventsData"
      locale="cs"
      events-count-on-year-view
      small
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueCal from 'vue-cal';

export default {
  name: 'Prj1012Calendar',
  components: {
    VueCal,
  },

  props: {
    usage: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState('events', [
      'events',
      'eventsCategories'
    ]),

    convertedEventsData() {
      let calendarData = [];

      this.events.forEach(item => {
        let tempObject = {
          id: item.id,
          start: item.activityFrom,
          end: item.activityTo,
          title: item.name,
        };

        calendarData.push(tempObject);
      })

      return calendarData;
    }
  }
}
</script>
