<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaptionData"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        :infoButton="true"
        :infoButtonDestination="infoButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template v-if="initialized && !isLoading">
        <gen1006-info-panel
          type="neutral"
          class="vue-b-notification-subscribe"
        >
          <p v-html="$i18n.t('clientSatisfaction.notificationLabel')" />

          <frm1006-button
            type="internal"
            :icon-left="true"
            :class="{ 'vue-is-subscribe': !isSubscribed && !isLoadingSubscribe, 'vue-is-subscribed': isSubscribed && !isLoadingSubscribe, 'vue-is-loading': isLoadingSubscribe }"
            @buttonClickEvent="clickButtonSubscribe()"
          >
            {{ $t(isSubscribed ? 'clientSatisfaction.notificationButtonCancel' : 'clientSatisfaction.notificationButtonConfirm') }}
          </frm1006-button>
        </gen1006-info-panel>

        <gen1006-info-panel
          v-if="npsGraphValues.length === 0"
          type="neutral"
        >
          <p v-html="$t('clientSatisfaction.noData')"></p>
        </gen1006-info-panel>

        <div class="vue-b-info-controls">
          <frm1006-button
            v-for="(item, index) in npsGraphValues"
            :key="index"
            :icon-right="true"
            class="vue-control-item"
            type="internal"
            @buttonClickEvent="goToView(item)"
          >
            {{ item.name }}
          </frm1006-button>
        </div>
      </template>
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <prj1026-category-filter
        v-if="npsFiltersLoaded && convertedFilterCategories.length"
        :filterCategories="convertedFilterCategories"
        :showItems="convertedFilterCategories.length"
        :showFilterButton="npsAreFiltersActive"
        :showFilterButtonMore="false"
        :showResetButton="false"
        :showAllButtons="true"
        :activeCategory="npsActiveCategory"
        filterType="nps"
        @filterItems="filterItems"
        @resetFilteredItems="resetItems"
      />
    </template>
  </page-layout>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter.vue';
import PageLayout from '../../templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import router from '@/router';
import dataLoader from '@/utils/data-loader';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';

export default {
  name: 'ClientSatisfactionDashboard',

  components: {
    Prj1026CategoryFilter,
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [mxNavigationDefault],

  data() {
    return {
      isLoading: true,
      initialized: false,
      navbarCaptionData: this.$i18n.t('clientSatisfaction.caption'),
      infoButtonDestination: 'nps-info',
      isSubscribed: false,
      isLoadingSubscribe: false,
      preferenceModule: 'statistic',
      preferenceParam: 'notification_status'
    };
  },

  computed: {
    ...mapState('clientSatisfaction', [
      'npsGraphValues',
      'npsAreLoaded',
      'npsFilterCategories',
      'npsSelectedFilters',
      'npsFiltersLoaded',
      'npsActiveCategory',
      'npsAreFiltersActive'
    ]),
    ...mapGetters('user', {
      'preferences': GETTERS_CONSTANTS.GET_USER_PREFERENCES
    }),

    convertedFilterCategories() {
      let convertedFilterCategories = [];

      Object.values(this.npsFilterCategories.years).forEach(item => {
        convertedFilterCategories.push({ 'termId': item, 'termName': item });
      })

      return convertedFilterCategories;
    },

    getFilterUrl() {
      let filterItems = {'years': this.npsActiveCategory};
      let filterResult = '';
      let filterKeysCount = Object.keys(filterItems).length;
      Object.entries(filterItems).forEach(([key, value]) => {
        filterResult += key + '|eq|' + value;
        filterResult += (filterKeysCount > 1) ? ';' : '';
      });

      return filterResult;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  created() {
    if (!this.npsActiveCategory) {
      this.setFilters(new Date().getFullYear())
    }
  },

  mounted() {
    if (!this.npsActiveCategory) {
      this.setFilters(new Date().getFullYear())
    }

    Promise.all([
      this.$store.dispatch(STORE_MODULES.CLIENT_SATISFACTION + '/' + ACTIONS_CONSTANTS.FETCH_NPS_FILTER_DATA),
      this.$store.dispatch(STORE_MODULES.CLIENT_SATISFACTION + '/' + ACTIONS_CONSTANTS.FETCH_NPS_DATA, {filter: this.getFilterUrl})
    ]).finally(() => {
      this.initializePage();
      this.loadNotificationSubscribeStatus();
    });
  },

  methods: {
    initializePage() {
      this.initialized = true;
    },

    fetchNpsFiltersData() {
      this.isLoading = true;
      Promise.all([
        this.$store.dispatch(STORE_MODULES.CLIENT_SATISFACTION + '/' + ACTIONS_CONSTANTS.FETCH_NPS_FILTER_DATA)
      ]).finally(() => {
        this.isLoading = false;
      });
    },

    fetchNpsData() {
      this.isLoading = true;
      Promise.all([
        this.$store.dispatch(STORE_MODULES.CLIENT_SATISFACTION + '/' + ACTIONS_CONSTANTS.FETCH_NPS_DATA, {filter: this.getFilterUrl})
      ]).finally(() => {
        this.isLoading = false;
      });
    },

    compareGraphValue(value) {
      let resultValue = 50;
      if(value >= -100 && value < 0) {
        resultValue = Math.abs(50 - Math.abs(value / 2));
      }
      if(value > 0 && value <= 100) {
        resultValue = Math.abs(50 + Math.abs(value / 2));
      }
      return resultValue;
    },

    goToView(item) {
      router.push({ name: 'nps-detail', params: { id: item.id, slug: item.slug, year: this.npsActiveCategory }});
    },

    clickButtonSubscribe() {
      this.isLoadingSubscribe = true;
      this.isLoading = true;

      let reservationFormData = new FormData();
      reservationFormData.append('module', 'statistic');
      reservationFormData.append('param', 'notification_status');
      reservationFormData.append('value', this.isSubscribed ? 'off' : 'on');
      return dataLoader.postProfilePreference(reservationFormData)
        .then(data => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PREFERENCES_DATA, data);
          this.loadNotificationSubscribeStatus();
        })
        .finally(() => {
          this.isLoadingSubscribe = false;
          this.isLoading = false;
        });
    },

    loadNotificationSubscribeStatus() {
      let retValue = false;

      if (this.preferences[this.preferenceModule]) {
        Object.entries(this.preferences[this.preferenceModule]).forEach(([, val]) => {
          if (val.param === this.preferenceParam) {
            retValue = val.value;
          }
        });
      }

      this.isSubscribed = retValue === 'on';
    },

    filterItems(categoryNumber) {
      this.setFilters(categoryNumber);
      this.fetchNpsData();
    },

    setFilters(categoryNumber) {
      let selectedCategoryPayload = {
        years: [categoryNumber]
      };

      this.$store.commit(STORE_MODULES.CLIENT_SATISFACTION + '/' + MUTATIONS_CONSTANTS.SET_NPS_SELECTED_FILTERS, selectedCategoryPayload);
      this.$store.commit(STORE_MODULES.CLIENT_SATISFACTION + '/' + MUTATIONS_CONSTANTS.SET_NPS_ACTIVE_CATEGORY, categoryNumber);
    },

    resetItems() {
      this.$store.dispatch(STORE_MODULES.CLIENT_SATISFACTION + '/' + ACTIONS_CONSTANTS.RESET_ALL_NPS_FILTER)
        .then(() => {
          this.fetchNpsData();
        });
    },
  },
}
</script>
