<template>
  <div
    class="vue-event-list-item"
    :class="getClassObject"
    @click="goToItemDetail(itemData.id)"
  >
    <div
      v-if="itemData.image"
      class="vue-event-list-item-image-wrapper"
    >
      <div
        v-lazy:background-image="itemData.image"
        class="vue-event-list-item-image"
      />
    </div>

    <div
      v-else
      class="vue-event-list-item-image"
    />

    <div
      v-if="itemData.module && isWidget"
      class="vue-event-list-item-badge"
    >
      <gen1004-tag-label>
        {{ getModuleLocaleName(itemData.module) }}
      </gen1004-tag-label>
    </div>

    <div class="vue-event-list-item-info">
      <h3
        class="vue-event-list-item-name"
        :class="{['line-clamp-' + lineClamp]: lineClamp}"
        v-html="itemData.name"
      >
      </h3>
      <p class="vue-event-list-item-metainfo">
        <span
          class="vue-event-list-item-header"
        >
          <span
            v-if="itemData.target"
            class="vue-event-list-item-tag"
          >
            {{ itemData.target }}
          </span>

          <span
            v-if="categoriesExist"
            class="vue-event-list-item-tag"
          >
            {{ itemData.categories[0]?.name }}
            <template
              v-if="itemData.categories.length > 1"
            >+{{ itemData.categories.length - 1 }}</template>
          </span>

          <span
            v-if="itemData.module && !isWidget && !tagDisable"
            class="vue-event-list-item-tag vue-is-module-name"
          >
            {{ getModuleLocaleName(itemData.module) }}
          </span>

          <span
            v-if="listItemConfig.displayDate"
            class="vue-event-list-item-time"
          >
            <template v-if="!itemData.nearestDate && !itemData.created">
              {{ $t('forvardino.courseRequest') }}
            </template>
            <template v-else>
              <template v-if="itemData.created">
                <prj1038-item-age
                  :date="itemData.created"
                />
              </template>
              <template v-else>
                <span v-html="convertDate(itemData.nearestDate)" />
              </template>
            </template>
          </span>
        </span>

        <prj1019-star-rating
          v-if="listItemConfig.displayRating && itemData.score > 0"
          :item-rating="Math.round(parseFloat(itemData.score) * 10) / 10"
        />
      </p>
    </div>
  </div>
</template>

<script>
import * as envConfig from 'env-config';
import router from '@/router';
import { LIST_ITEMS_ROUTES } from '@/assets/list-items';

import Prj1019StarRating from '@/components/prj1019-star-rating/prj1019-star-rating';
import Prj1038ItemAge from '@/components/prj1038-item-age/prj1038-item-age.vue';
import utilsGeneral from '@/utils/utils-general';
import Vue from 'vue';

export default {
  name: 'Prj1024ItemListItem',
  components: {
    Prj1019StarRating,
    Prj1038ItemAge
  },

  props: {
    itemData: {
      type: Object,
      required: true,
    },
    listItemConfig: {
      type: Object,
      required: true,
    },
    promoted: {
      type: Boolean,
      default: false
    },
    promotedOnlyIcon: {
      type: Boolean,
      default: false
    },
    isWidget: {
      type: Boolean,
      default: false
    },
    module: {
      type: String,
      default: '',
    },
    lineClamp: {
      type: Number
    },
    tagDisable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    cmsUrl() {
      return envConfig.default.webServices.BASE_CMS_URL;
    },

    categoriesExist() {
      return (typeof this.itemData.categories !== 'undefined');
    },

    getClassObject() {
      return {
        ['vue-is-promoted']: this.promoted,
        ['vue-has-promoted-icon']: this.promotedOnlyIcon,
        ['vue-is-widget']: this.isWidget,
        ['vue-is-' + this.module]: this.module,
      }
    }
  },

  methods: {
    getDetailName() {
      for (const listItem of LIST_ITEMS_ROUTES) {
        if (listItem.module === this.module) {
          return listItem.routeDetail;
        }
      }
    },

    goToItemDetail(itemId) {
      this.$emit('goToDetailPrev', itemId);

      if (this.module) {
        const detailNameRoute = this.getDetailName();
        return router.push({name: detailNameRoute, params: {id: itemId, historyBack: true}});
      }

      this.$emit('goToDetail', itemId);
    },

    getModuleLocaleName(module) {
      return this.$i18n.t('promoted.' + module);
    },

    filterHourFromDate(date) {
      return Vue.filter('convertDate')(date, 'hour') + ':' + Vue.filter('convertDate')(date, 'minutes');
    },

    isToday(compareDate) {
      const now = new Date();
      return now.getFullYear() === compareDate.getFullYear()
        && now.getMonth() === compareDate.getMonth()
        && now.getDate() === compareDate.getDate();
    },

    isTomorrow(compareDate) {
      const now = new Date();
      return now.getFullYear() === compareDate.getFullYear()
        && now.getMonth() === compareDate.getMonth()
        && (now.getDate() + 1) === compareDate.getDate();
    },

    convertDate(date) {
      let dateObject = utilsGeneral.sqlDateTimeToDate(date);
      let convertedTime = this.filterHourFromDate(date);
      let result = '';

      if (this.isToday(dateObject)) {
        result += 'dnes ';
      } else if (this.isTomorrow(dateObject)) {
        result += 'zítra ';
      } else {
        result += '' + utilsGeneral.localeDateWidgetString(dateObject) + ' ';
      }
      result += '' + this.$i18n.t('time.hourShortStrong', {hours: convertedTime}) + '';

      return result;
    },
  }
}
</script>
