<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar
            :route-name="backRouteName"
          />
        </template>
        <template v-slot:content>
          <div class="prj-layout-default h-full justify-between">
            <!--========== ARTICLE BODY ===========================-->
            <!--===================================================-->
            <div
              v-if="article"
              class="prj-article"
            >
              <t-tag variant="blockTitle">
                {{ article.title.rendered }}
              </t-tag>

              <div
                class="mb-4"
                v-html="article.content.rendered"
              />
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<script>
import apiCmsContent from '@/esg-api/cms-content';
import utilsGeneral from '@/utils/esg/utils-general';
import logger from '@/utils/logger';
import '@/utils/dayjs';

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import PageLayout from '@/templates/esg/page-layout';
import Prj1005NavigationBar from '@/components/esg/prj1005-navigation-bar/prj1005-navigation-bar';

export default {
  name: 'ESGPage',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1005NavigationBar,
    PageLayout
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      article: null
    };
  },
  computed: {
    articleId() {
      return parseInt(this.$route.params.id);
    },
    backRouteName() {
      return this.$route.params.backRoute
    }
  },
  mounted() {
    this.getPageById(this.articleId);
  },
  methods: {
    getPageById(id) {
      let processId = utilsGeneral.startAsyncProcess();

      apiCmsContent
        .getPageById(id)
        .then(response => {
          this.article = response;
        })
        .catch(error => {
          logger.error(error);
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId);
        });
    }
  }
};
</script>

