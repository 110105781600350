<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <a :id="id" class="vue-component vue-c-hyperlink" :href="link" :target="newWindow ? '_blank' : null" :title="title"
        ><slot>{{ content }}</slot></a
    >
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1003Hyperlink',
    props: {
        content: String,
        link: {
            default: '',
            type: String
        },
        newWindow: {
            default: false,
            type: Boolean
        },
        title: {
            default: null,
            type: String
        },
        id: {
            default: null,
            type: String
        }
    }
};
</script>
