<template>
  <div
    v-if="tutorialVisible"
    class="vue-c-tutorial"
  >
    <prj1034-tutorial-dashboard-init :slide="tutorialSlide">
      <template v-if="tutorialSlide === 1">
        <div class="vue-b-tutorial-controls">
          <frm1006-button @buttonClickEvent="nextSlide">
            {{ $t('general.show') }}
          </frm1006-button>

          <frm1006-button
            v-if="tutorialSlide === 1"
            type="secondary"
            @buttonClickEvent="endTutorial"
          >
            {{ $t('general.skip') }}
          </frm1006-button>
        </div>
      </template>

      <frm1006-button
        v-else-if="tutorialSlide < 5"
        @buttonClickEvent="nextSlide"
      >
        {{ $t('general.continue') }}
      </frm1006-button>

      <frm1006-button
        v-else
        @buttonClickEvent="endTutorial"
      >
        {{ $t('forms.close') }}
      </frm1006-button>
    </prj1034-tutorial-dashboard-init>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import Prj1034TutorialDashboardInit from '@/components/prj1034-tutorial/prj1034-tutorial-dashboard-init';

export default {
  name: 'Prj1034Tutorial',
  components: {
    Prj1034TutorialDashboardInit
  },

  props: {
    completed: Boolean,
    tutorial: Object
  },

  data() {
    return {
      tutorialSlide: 1
    }
  },

  computed: {
    ...mapState('popup', [
      'isPopupVisible'
    ]),
    ...mapState('tutorial', [
      'isTutorialVisible'
    ]),
    tutorialVisible() {
      return !this.isPopupVisible && !this.tutorial.completed;
    }
  },

  mounted() {
    this.$store.commit(STORE_MODULES.TUTORIAL + '/' + MUTATIONS_CONSTANTS.TOGGLE_TUTORIAL, this.tutorialVisible);
  },

  methods: {
    nextSlide() {
      this.tutorialSlide++;
    },
    endTutorial() {
      this.$store.commit(STORE_MODULES.TUTORIAL + '/' + MUTATIONS_CONSTANTS.TOGGLE_TUTORIAL, false);
      this.$store.commit(STORE_MODULES.TUTORIAL + '/' + MUTATIONS_CONSTANTS.SET_TUTORIAL_IS_COMPLETED, this.tutorial);
    }
  }
}
</script>
