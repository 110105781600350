export default {
  contentData: {
    news: {
      slug: 'news',
      lastCheck: {
        timestamp: '',
        date: ''
      },
      lastUpdate: {
        timestamp: '',
        date: ''
      },
      shownBefore: [],
      data: {}
    },
    importantNews: {
      slug: 'important_news',
      lastCheck: {
        timestamp: '',
        date: ''
      },
      lastUpdate: {
        timestamp: '',
        date: ''
      },
      shownBefore: [],
      data: {}
    },
    tips: {
      slug: 'tips',
      lastCheck: {
        timestamp: '',
        date: ''
      },
      lastUpdate: {
        timestamp: '',
        date: ''
      },
      shownBefore: [],
      shownLast: {
        id: '',
        timestampShown: '',
        dateShown: '',
        timestampCreated: '',
        dateCreated: ''
      },
      showInterval: 60 * 60 * 24 * 1000, // one day
      data: {}
    },
    services: {
      slug: 'services',
      lastCheck: {
        timestamp: '',
        date: ''
      },
      lastUpdate: {
        timestamp: '',
        date: ''
      },
      data: {}
    },
    pages: {
      slug: 'page',
      lastCheck: {
        timestamp: '',
        date: ''
      },
      lastUpdate: {
        timestamp: '',
        date: ''
      },
      data: {}
    },
    restaurants: {
      foods: {
        allergens: [],
        attributes: [],
      },
      facilities: {
        restaurantNHQ: {
          id: 'restaurantNHQ',
          slug: 'restaurant_nhq',
          type: 'restaurant',
          menuPeriod: 'week',
          name: 'NHQ',
          lastCheck: {
            timestamp: '',
            date: ''
          },
          lastUpdate: {
            timestamp: '',
            date: ''
          },
          data: {}
        },
        restaurantSHQ: {
          id: 'restaurantSHQ',
          slug: 'restaurant_shq' + '',
          type: 'restaurant',
          menuPeriod: 'week',
          name: 'SHQ',
          lastCheck: {
            timestamp: '',
            date: ''
          },
          lastUpdate: {
            timestamp: '',
            date: ''
          },
          data: {}
        },
        restaurantHHQ: {
          id: 'restaurantHHQ',
          slug: 'restaurant_hhq' + '',
          type: 'restaurant',
          menuPeriod: 'week',
          name: 'HHQ',
          lastCheck: {
            timestamp: '',
            date: ''
          },
          lastUpdate: {
            timestamp: '',
            date: ''
          },
          data: {}
        },
        restaurantPHQ: {
          id: 'restaurantPHQ',
          slug: 'restaurant_phq' + '',
          type: 'restaurant',
          menuPeriod: 'week',
          name: 'PHQ',
          lastCheck: {
            timestamp: '',
            date: ''
          },
          lastUpdate: {
            timestamp: '',
            date: ''
          },
          data: {}
        },
        cafeteriaNHQ: {
          id: 'cafeteriaNHQ',
          slug: 'cafeteria_nhq',
          type: 'cafeteria',
          menuPeriod: 'stable',
          name: 'NHQ',
          lastCheck: {
            timestamp: '',
            date: ''
          },
          lastUpdate: {
            timestamp: '',
            date: ''
          },
          data: {}
        },
        cafeteriaSHQ: {
          id: 'cafeteriaSHQ',
          slug: 'cafeteria_shq',
          type: 'cafeteria',
          menuPeriod: 'stable',
          name: 'SHQ',
          lastCheck: {
            timestamp: '',
            date: ''
          },
          lastUpdate: {
            timestamp: '',
            date: ''
          },
          data: {}
        },
        cafeteriaHHQ: {
          id: 'cafeteriaHHQ',
          slug: 'cafeteria_hhq',
          type: 'cafeteria',
          menuPeriod: 'stable',
          name: 'HHQ',
          lastCheck: {
            timestamp: '',
            date: ''
          },
          lastUpdate: {
            timestamp: '',
            date: ''
          },
          data: {}
        },
        cafeteriaPHQ: {
          id: 'cafeteriaPHQ',
          slug: 'cafeteria_phq',
          type: 'cafeteria',
          menuPeriod: 'stable',
          name: 'PHQ',
          lastCheck: {
            timestamp: '',
            date: ''
          },
          lastUpdate: {
            timestamp: '',
            date: ''
          },
          data: {}
        }
      },
    },
    recommendedNews: {
      slug: 'recommended_news',
      lastCheck: {
        timestamp: '',
        date: ''
      },
      lastUpdate: {
        timestamp: '',
        date: ''
      },
      shownBefore: [],
      data: {}
    },
  },
  contentDataUpdate: {
    inProgress: false,
    checkInterval: globalConfig.CONTENT_DATA_UPDATE_CHECK_INTERVAL, // 60 * 60 * 24 * 1000 = one day
    stage: '', // ["idle", "prefetch-info", "prefetch-item-ids", "get-items-data"]
    lastCheck: {
      timestamp: '',
      date: ''
    },
    lastUpdate: {
      dataPrefetchInfo: {},
      timestamp: '',
      date: ''
    },
  },
}
