<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div class="flex flex-col">
    <t-icon-button
      :class="{'bg-lime-500': isClicked}"
      :disabled="disabled"
      @click="clicked"
    >
      <!-- Icon -->
      <span
        v-if="!isClicked"
        class="text-black"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
          />
        </svg>
      </span>

      <!-- Clicked icon -->
      <span
        v-else
        class="text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
        </svg>
      </span>

      <transition
        name="like-button-message"
        appear
        @after-enter="cancelResponseMessage"
      >
        <span
          v-if="responseMessage"
          class="absolute bottom-14 right-0 px-3 py-2 opacity-0 bg-lime-500 rounded-xl text-white text-base"
        >{{ $t('esg.likeButton.responseMessage') }}</span>
      </transition>

      <!-- Count -->
      <span
        class="ml-1.5 min-w-char"
        :class="[isClicked ? 'text-white' : 'text-black', minWidthNumbers]"
      >{{ count }}</span>
    </t-icon-button>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1014LikeButton',
  components: {},
  props: {
    liked: {
      type: Boolean,
      default: false
    },
    likes: {
      type: Number,
      default: 0
    },
    likesEnabled: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isClicked: false,
      count: 0,
      responseMessage: false
    }
  },
  computed: {
    getNumbersLength() {
      return this.count.toString().length;
    },
    minWidthNumbers() {
      return 'w-' + (this.getNumbersLength * 2);
    }
  },
  mounted() {
    this.isClicked = this.liked;
    this.count = this.likes;
  },
  methods: {
    clicked() {
      if (this.likesEnabled) {
        this.isClicked = !this.isClicked;

        this.updateCount();

        if (this.isClicked) this.showResponseMessage();
      }

      this.$emit('clickLike', this.isClicked);
    },
    updateCount() {
      if (this.isClicked) {
        this.count++
      }
      else {
        this.count = this.count > 0 ? this.count - 1 : 0
      }
    },
    showResponseMessage() {
      this.responseMessage = true;
    },
    cancelResponseMessage() {
      this.responseMessage = false;
    }
  }
}
</script>
