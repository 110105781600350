<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        buttonMode="custom"
        @buttonCustomEvent="goToRecruitmentList"
      />
    </template>

    <!--========== HEADER =================================-->
    <!--===================================================-->
    <!-- TODO MBU: create shared component for all detail headers -->
    <template v-slot:header>
      <div
        v-if="initialized && !isLoading"
        class="vue-c-recruitment-detail-header"
        :class="classObject"
      >
        <div
          class="vue-recruitment-detail-image"
          :style="styleObject"
        >
          <gen1005-tag-container v-if="renderRegionName">
            <gen1004-tag-label>
              {{ renderRegionName }}
            </gen1004-tag-label>
          </gen1005-tag-container>
        </div>
      </div>
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div
          v-if="detailData"
          class="vue-recruitment-detail-content"
        >
          <prj1017-heading
            :detailheader="renderName"
          />
          <div
            class="vue-b-description"
            v-html="renderDescription"
          >
          </div>
        </div>
        <gen1006-info-panel
          v-if="!detailData"
          type="neutral"
        >
          {{ $t('general.loadingFailed') }}
        </gen1006-info-panel>

        <gen1016-loading-indicator
          :active="isLoading"
          :overlay="true"
          :fullScreen="true"
        />
      </template>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <gen1006-info-panel
        v-if="isInterested"
        type="info"
      >
        {{ $t('recruitment.submitImInterested') }}
      </gen1006-info-panel>

      <Prj1049LoginRedirectButton
        v-if="!isInterested"
        type="primary"
        :title="$i18n.t('recruitment.submitImInterest')"
        @afterLoginEvent="redirectToDetailForm"
      >
        {{ $t('recruitment.submitImInterest') }}
      </Prj1049LoginRedirectButton>

      <Prj1049LoginRedirectButton
        type="secondary"
        :title="$i18n.t('recruitment.submitToCandidate')"
        @afterLoginEvent="redirectToDetailFormCandidate"
      >
        {{ $t('recruitment.submitToCandidate') }}
      </Prj1049LoginRedirectButton>
    </template>
  </page-layout>
</template>

<script type="application/javascript">

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import Prj1049LoginRedirectButton from '@/components/prj1049-login-redirect-button/prj1049-login-redirect-button';

import * as envConfig from 'env-config';
import router from '@/router';
import {mapState} from 'vuex';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

export default {
  name: 'RecruitmentDetail',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading,
    Prj1049LoginRedirectButton
  },

  mixins: [
    mxNavigationDefault,
  ],

  data() {
    return {
      isLoading: false,
      hasError: false,
      initialized: false,
    }
  },

  computed: {
    ...mapState('recruitment', [
      'recruitment'
    ]),

    pageId() {
      return this.$route.params.id;
    },

    classObject() {
      return {
        'vue-has-no-image': !this.hasImage,
      }
    },

    detailData() {
      if (this.recruitment.detailData) {
        return this.recruitment.detailData;
      }

      return null;
    },

    isInterested() {
      return this.recruitment.detailData.isInterested === '1';
    },

    hasImage() {
      if (this.detailData) {
        // TODO MBU: API inconsistency, Forvardino and Event returs empty string ''
        return this.detailData.imagePath !== null;
      }

      return false
    },

    styleObject() {
      if (this.detailData && this.detailData.imagePath) {
        return 'background-image: url("' + this.cmsUrl + this.recruitment.detailData.imagePath + '")'
      } else {
        return ''
      }
    },

    cmsUrl() {
      return envConfig.default.webServices.BASE_CMS_URL;
    },

    renderName() {
      if (this.recruitment.detailData.name){
        return this.recruitment.detailData.name
      } else {
        return '';
      }
    },

    renderDescription() {
      if (this.recruitment.detailData.description){
        return this.recruitment.detailData.description
      } else {
        return '';
      }
    },

    renderRegionName() {
      if (this.recruitment.detailData.regions){
        return this.recruitment.detailData.regions[0]
      } else {
        return '';
      }
    },
  },

  mounted() {
    if (!this.recruitment?.detailData?.id) {
      this.getRecruitmentData();
    } else {
      this.initialized = true;
    }
  },

  methods: {
    getRecruitmentData() {
      this.isLoading = true;
      dataLoader.fetchRecruitmentDetail('id=' + this.pageId)
        .then(response => {
          this.recruitment.detailData = response;
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.initialized = true;
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          }
        });
    },

    redirectToDetailForm() {
      router.push({ name: 'recruitment-detail-form'});
    },

    redirectToDetailFormCandidate() {
      router.push({ name: 'recruitment-detail-form-refer'});
    },

    goToRecruitmentList() {
      router.push({ name: 'recruitment-listing', params: {index: this.$route.params.index}});
    },
  },
}
</script>
