<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        ref="navbar"
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-marketplace-list">
        <prj1051-marketplace-list
          ref="marketplaceList"
          :listData="marketplaceItems"
          :listItemsCount="parseInt(marketplaceItemsNumber)"
          :isFilterActive="marketplaceAreFiltersActive"
          :noMoreItems="marketplaceNoMoreItems"
          :componentKey="componentKey"
          :listItemConfig="listItemConfigObject"
          :showFilter="false"
          @filterData="filterData"
          @loadNewContent="loadNewContent"
          @resetFilteredItems="resetItems"
          @scrollToTop="scrollToTop"
          @openFilterDialog="openFilterDialog"
          @goToDetail="goToDetail"
        />
      </div>
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <prj1026-category-filter
        v-if="marketplaceListInitialized && convertedFilterCategories.length && !showFormButton"
        :filterCategories="convertedFilterCategories"
        :showItems="convertedFilterCategories.length"
        :showFilterButton="marketplaceAreFiltersActive"
        :activeCategory="parseInt(marketplaceActiveCategory)"
        filterType="marketplace"
        @filterItems="filterItems"
        @resetFilteredItems="resetItems"
      />
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
      <frm1006-button
        v-if="showFormButton"
        class="vue-button-register vue-button-page-layout-fixed"
        @buttonClickEvent="goToForm()"
      >
        <span>
          {{ $t('marketplace.forms.newAd') }}
        </span>
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="!marketplaceListInitialized || isLoading"
        :overlay="true"
        :fullScreen="true"
      />
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import router from '@/router';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter.vue';
import Prj1051MarketplaceList from '@/components/prj1051-marketplace-list/prj1051-marketplace-list.vue';
import { DEFAULT_LIST_PAGINATION } from '@/constants/app-constants';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import {mapState} from 'vuex';
import EVENT_BUS from '@/event-bus';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';

export default {
  name: 'MarketplaceList',
  components: {
    Prj1051MarketplaceList, Prj1026CategoryFilter,
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      navbarCaptionData: null,
      marketplaceItems: [],
      marketplaceItemsNumber: 0,
      marketplaceNoMoreItems: false,
      currentPosition: 0,
      componentKey: 0,
      listItemConfigObject: {
        displayDate: true,
        displayRating: true,
      },
      marketplaceListInitialized: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapState('marketplace', [
      'marketplaceFilterCategories',
      'marketplaceSelectedCategories',
      'marketplaceActiveCategory',
      'marketplaceAreFiltersActive',
      'searchQuery'
    ]),

    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },

    listMode() {
      return this.$route.meta.listMode;
    },

    paramFilter() {
      let retValue = '';

      if (this.marketplaceSelectedCategories.category) {
        retValue += 'category|in|' + this.marketplaceSelectedCategories.category + ';';
      }

      if (this.listMode) {
        retValue += 'mode|eq|' + this.listMode + ';';
      }

      return retValue;
    },

    paramSort() {
      return 'name';
    },

    convertedFilterCategories() {
      let convertedFilterCategories = [];

      Object.values(this.marketplaceFilterCategories.categories).forEach(item => {
        convertedFilterCategories.push({ 'termId': item.id, 'termName': item.value });
      })

      return convertedFilterCategories;
    },

    endpointListOptions() {
      return [
        this.paramFilter,
        this.paramSort,
        this.currentPosition,
        20
      ]
    },

    showFormButton() {
      return this.listMode === 'my';
    }
  },

  watch: {
    marketplaceAreFiltersActive(newVal) {
      if (newVal === true) {
        this.fetchMarketplaceData();
      }
    },

    searchQuery() {
      this.fetchMarketplaceData();
    }
  },

  created() {
    this.fetchMarketplaceData();
  },

  mounted() {
    EVENT_BUS.$on(GLOBAL_EVENTS.FILTER_ITEM_LIST, categoryId => {
      this.filterItems(categoryId);
    });
  },

  methods: {
    async loadNewContent() {
      if (this.marketplaceNoMoreItems) return;
      this.currentPosition += DEFAULT_LIST_PAGINATION;

      await dataLoader.getMarketplaceFilteredList([...this.endpointListOptions])
        .then(response => {
          let tempArray = this.marketplaceItems;
          this.marketplaceItems = [...tempArray, ...response.data.items];
          if (response.data.items.length < DEFAULT_LIST_PAGINATION) {
            this.marketplaceNoMoreItems = true;
          }
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        });
    },

    filterData() {
      router.push({ name: 'marketplace-list', params: { categoryId: this.marketplaceActiveCategory }});
      this.fetchMarketplaceData();
    },

    fetchMarketplaceData() {
      this.componentKey++;
      this.currentPosition = 0;
      this.marketplaceListInitialized = false;
      this.$store.dispatch(STORE_MODULES.MARKETPLACE + '/' + ACTIONS_CONSTANTS.FETCH_MARKETPLACE_FILTER_DATA);

      dataLoader.getMarketplaceFilteredList([...Object.values(this.endpointListOptions)])
        .then(response => {
          this.marketplaceItems = response.data.items;
          this.marketplaceItemsNumber = response.data.pagingInfo.nextPage ? 40 : 20;
          this.marketplaceListInitialized = true;
        })
        .catch(error => {
          this.marketplaceListInitialized = true;
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          if (parseInt(this.marketplaceItemsNumber) > 20) {
            this.marketplaceNoMoreItems = false;
            this.$refs.marketplaceList?.initInfinityScroll();
          }
        });
    },

    resetItems() {
      this.$store.dispatch(STORE_MODULES.MARKETPLACE + '/' + ACTIONS_CONSTANTS.RESET_ALL_MARKETPLACE_FILTERS)
        .then(() => {
          this.fetchMarketplaceData();
        });
    },

    // FIXME: CSOBZ-515 - merge all scroll to top methods into one global method
    scrollToTop() {
      this.$nextTick(() => {
        this.scrollToElement();
      })
    },

    scrollToElement() {
      const ref = this.$refs.navbar.$el;

      if (ref) {
        ref.scrollIntoView(true);
      }
    },

    openFilterDialog() {
      //this.$store.dispatch(STORE_MODULES.POPUP + '/' + ACTIONS_CONSTANTS.TOGGLE_POPUP, 'marketplace');
    },

    filterItems(categoryNumber) {
      let selectedCategoryPayload = {
        category: categoryNumber
      };
      logger.info(selectedCategoryPayload);

      this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_SELECTED_CATEGORIES, selectedCategoryPayload);
      this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_MARKETPLACE_ACTIVE_CATEGORY, categoryNumber);

      this.fetchMarketplaceData();
    },

    goToDetail(data) {
      // save to store
      this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, data);
      router.push({ name: 'marketplace-detail', params: { id: data.id, listMode: this.listMode } });
    },

    goToForm() {
      router.push({
        name: 'marketplace-form',
        params: {}
      })
    },
  },
}
</script>
