<template>
  <div
    class="vue-c-event-attendance-details"
    :class="classObject"
  >
    <prj1028-day-time
      v-if="sameDay"
      :day="day"
      :time="time"
    />
    <prj1028-day-time
      v-else
      class="vue-is-not-same-day"
    >
      <template v-slot:day>
        <span class="vue-start-day vue-separate-day">{{ day }}</span>
        <span class="vue-end-day">{{ endDay }}</span>
      </template>
      <template v-slot:time>
        <span class="vue-start-time vue-separate-time">{{ startTime }}</span>
        <span class="vue-end-time">{{ endTime }}</span>
      </template>
    </prj1028-day-time>
    <div
      v-if="capacityTotal || capacityRemaining"
      class="vue-b-capacity"
    >
      <span
        v-if="capacityRemaining || capacityRemaining === 0"
        class="vue-capacity-available"
      >
        {{ $t('actions.capacityAvailable', { capacityAvailable: capacityRemaining}) }}
      </span>
      <span
        v-if="capacityTotal && capacityRemaining || capacityRemaining === 0"
        class="vue-limiter"
      >
        {{ $t('actions.capacityLimiter') }}
      </span>
      <span
        v-if="capacityTotal"
        class="vue-capacity-total"
      >
        {{ capacityTotal }}
      </span>
    </div>
    <div
      v-if="location || place"
      class="vue-b-location"
    >
      <span
        v-if="location"
        class="vue-location"
      >
        {{ location && place ? location + ', ' : location }}
      </span>
      <span
        v-if="place"
        class="vue-room"
      >
        {{ place }}
      </span>
    </div>
    <div
      v-if="showRegisteredNotice"
      class="vue-registered-notice"
    >
      <span>{{ $t('actions.alreadyRegistered') }}</span>
    </div>
    <div
      v-if="streamUrl"
      class="vue-b-stream"
    >
      <span
        class="vue-online"
      >
        {{ $t('general.online') }}
      </span>
      <span class="vue-separator">&ndash;</span>
      <a
        :href="streamUrl"
        target="_blank"
        class="vue-stream-url"
      >
        {{ $t('general.streamUrl') }}
      </a>
    </div>
  </div>
</template>

<script>
import Prj1028DayTime from '@/components/prj1028-day-time/prj1028-day-time';

export default {
  name: 'Prj1037EventAttendanceDetails',
  components: {Prj1028DayTime},

  props: {
    day: String,
    time: String,
    endDay: {
      type: String,
      required: false,
      default: ''
    },
    startTime: {
      type: String,
      required: false,
      default: ''
    },
    endTime: {
      type: String,
      required: false,
      default: ''
    },
    online: {
      type: Boolean,
      default: false,
    },
    capacityTotal: {
      type: Number,
    },
    capacityRemaining: {
      type: Number,
    },
    streamUrl: {
      type: String,
    },
    location: {
      type: String,
    },
    place: {
      type: String,
    },
    sameDay: {
      type: Boolean,
      default: true
    },
    showRegisteredNotice: {
      type: [Boolean],
      required: false,
      default: false
    }
  },

  computed: {
    classObject() {
      return {
        'vue-is-online': this.online
      }
    },
  }
}
</script>
