<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div ref="menu" class="vue-b-menu">
        <ul :id="id" class="vue-menu" role="listbox">
            <Gen1011ContextContainerMenuItem
                v-for="(item, index) in items"
                :id="item.id"
                :key="index"
                :mode="item.mode"
                :action="item.action"
                :actionProps="item.actionProps"
                :component="item.component"
                :componentProps="item.componentProps"
                :componentSlots="item.componentSlots"
                :caption="item.caption"
                :active="itemActiveIndex === index"
                :selected="item.value === itemSelectedValue"
                :disabled="item.disabled"
                :excluded="item.excluded"
                :value="item.value"
                :customCss="item.customCss"
                :index="index"
                :item="item"
                @clickEvent="itemClick"
                @itemMounted="mountedEvent"
            >
                <template v-if="$scopedSlots.contextContainerMenu" v-slot:contextContainerMenuItem="props">
                    <slot name="contextContainerMenu" :item="props.item" :index="props.index" />
                </template>
            </Gen1011ContextContainerMenuItem>
        </ul>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//
import Gen1011ContextContainerMenuItem from './gen1011-context-container-menu-item';

//============ CONSTANTS ===============================//
//======================================================//

//========= CSS SELECTORS ====================//
const CSS_SELECTOR_MENU_ITEM_ACTIVE = '.vue-menu-item.vue-is-active';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1011ContextContainerMenu',
    components: {
        Gen1011ContextContainerMenuItem
    },
    props: {
        items: Array,
        itemSelectedValue: String,
        itemSelect: Boolean,
        searchable: Boolean,
        searchString: String,
        placeholder: String,
        placeholderSelectable: Boolean,
        active: Boolean,
        id: String,
        customCss: String
    },
    data() {
        return {
            itemActiveIndex: null,
            itemSelected: null,
            itemSelectedIndex: null,
            itemSelectedValueData: this.itemSelectedValue,
            itemsMounted: null
        };
    },
    computed: {
        itemsCount() {
            return this.items.length;
        },
        itemActive() {
            if (this.itemActiveIndex !== null) {
                return this.items[this.itemActiveIndex];
            }

            return null;
        }
    },
    watch: {
        itemsMounted(value) {
            if (value === this.itemsCount) {
                let overflowElement;

                this.$emit('menuMountedEvent');

                if (this.itemSelect) {
                    if (this.itemSelectedValueData && this.itemSelectedValueData !== '') {
                        let itemActiveIndex = this.getItemIndex(this.itemSelectedValueData);
                        if (itemActiveIndex >= 0) {
                            if (this.itemSelected && this.itemSelected.excluded) {
                                this.itemActiveIndex = this.findFirstNotDisabledItem();
                            } else {
                                this.itemActiveIndex = itemActiveIndex;
                            }
                        } else {
                            this.itemActiveIndex = this.findFirstNotDisabledItem(); // if value is incorrect and does not match any item value, set first item as active
                        }
                    } else {
                        this.itemActiveIndex = this.findFirstNotDisabledItem(); // if nothing is selected, activate first
                    }
                } else {
                    if (this.itemSelectedValue && this.itemSelectedValue !== '') {
                        let itemActiveIndex = this.getItemIndex(this.itemSelectedValue);
                        if (itemActiveIndex >= 0) {
                            this.itemActiveIndex = itemActiveIndex;
                        } else {
                            this.itemActiveIndex = this.findFirstNotDisabledItem(); // if value is incorrect and does not match any item value, set first item as active
                        }
                    } else {
                        this.itemActiveIndex = this.findFirstNotDisabledItem(); // if nothing is selected, activate first
                    }
                }

                // initial scroll
                if (!this.searchable) {
                    overflowElement = this.$parent.$refs.content;
                } else {
                    overflowElement = this.$refs.menu;
                }

                if (overflowElement.scrollHeight) {
                    this.$nextTick(() => {
                        this.scrollToActiveItem('none');
                    });
                }
                document.addEventListener('keydown', this.keyDown);
            }
        },
        itemSelectedValue(value) {
            this.itemSelectedValueData = value;
        },
        itemSelectedValueData(value) {
            this.$emit('update:itemSelectedValue', value);
            this.selectItem(value);
            // TODO MBU: emitting item + index is not necessary, after refactor, the options are passed from parent, so these are synchronized, sending value is enough
            this.$emit('itemSelectEvent', this.itemSelected, this.itemSelectedIndex);
        },
        searchString(value) {
            if (this.items.length > 0 && value) {
                // select first item after filter
                let firstNotDisabledItemIndex = this.findFirstNotDisabledItem();

                if (firstNotDisabledItemIndex > -1) {
                    this.itemActiveIndex = firstNotDisabledItemIndex;

                    // wait for items to be redrawn
                    this.$nextTick().then(() => {
                        this.scrollToActiveItem('none');
                    });
                } else {
                    this.itemActiveIndex = -1;
                }
            }
        }
    },
    destroyed() {
        this.itemActiveIndex = null;
        document.removeEventListener('keydown', this.keyDown);
    },
    mounted() {
        if (this.itemSelect && this.itemSelectedValueData && this.itemSelectedValueData !== '') {
            // TODO MBU: if the search function is active, there may be case, when the currently selected item is not in options
            // and mounted is triggered (when going from no result - context container menu is not rendered to some results again)
            // do not select the item in this case - warning is triggerd in getItemIndex function - how to fix it?
            // 1) pass unfiltered options to contextContainerMenu and select item from there
            // 2) bypass warning of getItemIndex via function param and leave it as is
            let itemToSelectIndex = this.getItemIndex(this.itemSelectedValueData);
            if (itemToSelectIndex !== null) {
                this.selectItem(this.itemSelectedValueData);
            }
        }
    },
    methods: {
        //=== EVENTS
        itemClick(value) {
            // manually emit itemSelectEvent, if click is pressed on currently selected item - the value wouldn't change in selectItem method
            // therefore watcher itemSelectedValueData wouldn't be triggered and itemSelectEvent emitted
            if ((this.itemSelected && this.itemSelectedValueData !== value) || !this.itemSelected) {
                this.selectItem(value);
            } else {
                this.$emit('itemSelectEvent', this.itemSelected, this.itemSelectedIndex);
            }
        },
        keyDown(event) {
            //=== EVENT
            if (this.itemActive) {
                this.$emit('keyDown', this.itemActive.value, event);
            }
            //=== KEYS
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                this.activateItemNext();
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                this.activateItemPrev();
            } else if (event.key === 'Enter') {
                let isContextContainerMenuOpenEvent =
                    event.hasOwnProperty('customProps') &&
                    event.customProps.hasOwnProperty('contextContainerMenuOpenEvent') &&
                    event.customProps.contextContainerMenuOpenEvent;

                if (!isContextContainerMenuOpenEvent) {
                    // focus is on another element, so event must be prevented in order not to trigger it's action
                    // enter on select in parent would close the context container instantly again
                    event.preventDefault();

                    // manually emit itemSelectEvent, if enter is pressed on currently selected item - the value wouldn't change in selectItem method
                    // therefore watcher itemSelectedValueData wouldn't be triggered and itemSelectEvent emitted
                    if (this.itemSelect) {
                        if (
                            (this.itemSelected && this.itemSelectedValueData !== this.itemActive.value) ||
                            !this.itemSelected
                        ) {
                            if (this.itemActive) {
                                this.selectItem(this.itemActive.value);
                            }
                        } else {
                            if (this.itemSelectedIndex > -1) {
                                // prevent selecting via enter, when no item is selected (filtered only disabled items etc.)
                                this.$emit('itemSelectEvent', this.itemSelected, this.itemSelectedIndex);
                            }
                        }
                    } else {
                        this.selectItem(this.itemActive.value);
                    }
                }
            } else if (event.key === 'Tab') {
                event.preventDefault(); // focus is on another element, so event must be prevented in order not to trigger it's action

                // if itemSelect is true, tab serves as confirmation
                if (this.itemSelect) {
                    if (
                        (this.itemSelected && this.itemSelectedValueData !== this.itemActive.value) ||
                        !this.itemSelected
                    ) {
                        // prevent selecting via enter, when no item is selected (filtered only disabled items etc.)
                        if (this.itemActive) {
                            this.selectItem(this.itemActive.value);
                        }
                    } else {
                        this.$emit('closeEvent', 'tabbedOutWithoutChange');
                    }
                }
            }
        },
        //=== GENERAL
        getItemIndex(itemValue) {
            for (let i = 0; i < this.itemsCount; i++) {
                let item = this.items[i];

                if (item.value === itemValue) {
                    return i;
                }
            }

            // TODO MBU: rewrite to -1
            return null;
        },
        selectItem(itemValue) {
            let itemSelectedIndex = this.getItemIndex(itemValue);

            if (itemSelectedIndex !== null && itemSelectedIndex >= 0) {
                let item = this.items[itemSelectedIndex];

                // set value only if itemSelect is true, otherwise just emit event, and let developer handle it manually
                if (this.itemSelect) {
                    this.itemSelected = item;
                    this.itemSelectedIndex = itemSelectedIndex;
                    this.itemSelectedValueData = itemValue;
                    // emit is done by watcher on itemSelectedValueData, here it would cause double emit
                } else {
                    this.$emit('itemSelectEvent', item, itemSelectedIndex);
                }
            } else {
                if (this.itemSelect) {
                    this.itemSelectedIndex = null;
                    this.itemSelectedValueData = ''; // set to default
                }
                this.$emit('itemSelectEvent', null, null);

                if (itemValue !== '') {
                    console.error(
                        'Value {{' + itemValue + '}} not matching any item value! Item selected set to empty string!'
                    );
                }
            }
        },
        activateItemPrev() {
            let itemPrevIndex;
            let overflowElement;

            // if previous item is not disabled, select previous item straight away
            // else find previous not disabled item through cycle
            if (this.itemActiveIndex > 0 && !this.items[this.itemActiveIndex - 1].disabled) {
                itemPrevIndex = this.itemActiveIndex - 1;

                if (this.itemActiveIndex > 0) {
                    this.itemActiveIndex = itemPrevIndex;
                }
            } else if (this.itemActiveIndex > 0) {
                itemPrevIndex = this.findPreviousNotDisabledItem(this.itemActiveIndex, true);

                if (itemPrevIndex > -1) {
                    this.itemActiveIndex = itemPrevIndex;
                }
            }

            if (!this.searchable) {
                overflowElement = this.$parent.$refs.content;
            } else {
                overflowElement = this.$refs.menu;
            }

            if (overflowElement.scrollHeight) {
                // wait until the DOM is redrawn with newly selected item
                this.$nextTick(() => {
                    this.scrollToActiveItem('prev', this.searchable);
                });
            }
        },
        activateItemNext() {
            let itemNextIndex;
            let itemLastIndex = this.itemsCount - 1;
            let overflowElement;

            // if next item is not disabled, select next item straight away
            // else find next not disabled item through cycle
            if (this.itemActiveIndex <= itemLastIndex - 1 && !this.items[this.itemActiveIndex + 1].disabled) {
                itemNextIndex = this.itemActiveIndex + 1;

                if (itemNextIndex <= itemLastIndex) {
                    this.itemActiveIndex = itemNextIndex;
                }
            } else if (this.itemActiveIndex <= itemLastIndex - 1) {
                itemNextIndex = this.findNextNotDisabledItem(this.itemActiveIndex, true);

                if (itemNextIndex > -1) {
                    this.itemActiveIndex = itemNextIndex;
                }
            }

            if (!this.searchable) {
                overflowElement = this.$parent.$refs.content;
            } else {
                overflowElement = this.$refs.menu;
            }

            if (overflowElement.scrollHeight) {
                // wait until the DOM is redrawn with newly selected item
                this.$nextTick(() => {
                    this.scrollToActiveItem('next');
                });
            }
        },
        scrollToActiveItem(direction) {
            if (this.itemActive) {
                let itemActive = this.$el.querySelectorAll(CSS_SELECTOR_MENU_ITEM_ACTIVE)[0];
                let itemActiveBoundingRect = itemActive.getBoundingClientRect();
                let overflowElement;
                let scrollPositionY;

                if (!this.searchable) {
                    overflowElement = this.$parent.$refs.content;
                } else {
                    overflowElement = this.$refs.menu;
                }

                let overflowElementBoundingRect = overflowElement.getBoundingClientRect();

                let itemActivePositionRelative = {
                    top: itemActiveBoundingRect.top - overflowElementBoundingRect.top + overflowElement.scrollTop,
                    bottom: itemActiveBoundingRect.bottom - overflowElementBoundingRect.top + overflowElement.scrollTop
                };

                if (direction === 'prev' && itemActivePositionRelative.top <= overflowElement.scrollTop) {
                    scrollPositionY = itemActivePositionRelative.top;
                    overflowElement.scrollTo(0, scrollPositionY);
                } else if (
                    direction === 'next' &&
                    itemActivePositionRelative.bottom > overflowElementBoundingRect.height + overflowElement.scrollTop
                ) {
                    scrollPositionY =
                        itemActivePositionRelative.top -
                        overflowElementBoundingRect.height +
                        itemActiveBoundingRect.height;
                    overflowElement.scrollTo(0, scrollPositionY);
                } else if (direction === 'none') {
                    // condition when selected item is not fully visible in current scroll position
                    if (
                        itemActivePositionRelative.top < overflowElement.scrollTop ||
                        itemActivePositionRelative.bottom >
                            overflowElementBoundingRect.height + overflowElement.scrollTop
                    ) {
                        if (this.itemActiveIndex === 0) {
                            overflowElement.scrollTo(0, 0);
                        } else if (this.itemActiveIndex > 0) {
                            let itemPrevious = itemActive.previousSibling;
                            let itemPreviousBoundingRect = itemPrevious.getBoundingClientRect();
                            let itemPreviousPositionRelative = {
                                top:
                                    itemPreviousBoundingRect.top -
                                    overflowElementBoundingRect.top +
                                    overflowElement.scrollTop,
                                bottom:
                                    itemPreviousBoundingRect.bottom -
                                    overflowElementBoundingRect.top +
                                    overflowElement.scrollTop
                            };

                            // if item active and and item previous would fit inside overflow element, scroll to previous to show there are items above
                            // else scroll to active
                            if (
                                itemActiveBoundingRect.height + itemPreviousBoundingRect.height <
                                overflowElementBoundingRect.height
                            ) {
                                scrollPositionY = itemPreviousPositionRelative.top;
                                overflowElement.scrollTo(0, scrollPositionY);
                            } else {
                                scrollPositionY = itemPreviousPositionRelative.top;
                                overflowElement.scrollTo(0, scrollPositionY);
                            }
                        }
                    }
                }
            }
        },
        // TODO MBU: rework / unify with FRM1028: Advanced combo
        selectItemPrev() {
            let itemSelectedIndex;
            let itemPrevIndex;

            if (this.itemSelectedIndex !== null) {
                itemSelectedIndex = this.itemSelectedIndex;
            } else {
                // if itemSelectedIndex is null, start from the first item
                itemSelectedIndex = 0;
            }

            // if previous item is not disabled, select previous item straight away
            // else find previous not disabled item through cycle
            if (itemSelectedIndex > 0 && !this.items[itemSelectedIndex - 1].disabled) {
                itemPrevIndex = itemSelectedIndex - 1;

                if (itemSelectedIndex > 0) {
                    this.selectItem(this.items[itemPrevIndex].value);
                }
            } else if (itemSelectedIndex > 0) {
                itemPrevIndex = this.findPreviousNotDisabledItem(itemSelectedIndex, true);

                if (itemPrevIndex > -1) {
                    this.selectItem(this.items[itemPrevIndex].value);
                }
            }
        },
        // TODO MBU: rework / unify with FRM1028: Advanced combo
        selectItemNext() {
            let itemSelectedIndex;
            let itemNextIndex;
            let itemLastIndex = this.itemsCount - 1;

            if (this.itemSelectedIndex !== null) {
                itemSelectedIndex = this.itemSelectedIndex;
            } else {
                // set to -1 if itemSelectedIndex is null, so next index index is calculated as 0 - first item
                itemSelectedIndex = -1;
            }

            // if next item is not disabled, select next item straight away
            // else find next not disabled item through cycle
            if (itemSelectedIndex <= itemLastIndex - 1 && !this.items[itemSelectedIndex + 1].disabled) {
                itemNextIndex = itemSelectedIndex + 1;

                if (itemNextIndex <= itemLastIndex) {
                    this.selectItem(this.items[itemNextIndex].value);
                }
            } else if (itemSelectedIndex <= itemLastIndex - 1) {
                itemNextIndex = this.findNextNotDisabledItem(itemSelectedIndex, true);

                if (itemNextIndex > -1) {
                    this.selectItem(this.items[itemNextIndex].value);
                }
            }
        },
        // TODO MBU: duplicated method in GEN1028: Advanced combo, move to utils?
        findPreviousNotDisabledItem(index, skipEvaluatingPrevious) {
            let itemPrevIndex;

            // if we already know that previous item is disabled when can skip evaluating it
            if (skipEvaluatingPrevious) {
                itemPrevIndex = index - 2;
            } else {
                itemPrevIndex = index - 1;
            }

            for (let i = itemPrevIndex; i > -1; i--) {
                if (!this.items[i].disabled) {
                    return i;
                }
            }

            return -1;
        },
        // TODO MBU: duplicated method in GEN1028: Advanced combo, move to utils?
        // index is the index before the first one that is being evaluated
        findNextNotDisabledItem(index, skipEvaluatingNext) {
            let itemNextIndex;
            let itemLastIndex = this.itemsCount - 1;

            // if we already know that previous item is disabled when can skip evaluating it
            if (skipEvaluatingNext) {
                itemNextIndex = index + 2;
            } else {
                itemNextIndex = index + 1;
            }

            for (let i = itemNextIndex; i <= itemLastIndex; i++) {
                if (!this.items[i].disabled) {
                    return i;
                }
            }

            return -1;
        },
        findFirstNotDisabledItem() {
            return this.findNextNotDisabledItem(-1);
        },
        mountedEvent() {
            this.itemsMounted += 1;
        }
    }
};
</script>
