<script type="application/javascript">
export default {
  methods: {
    /*
     * Calculate default SVG scale
     */
    calculateTransformMultiplier(svgImage) {
      let parsedViewBox = this.parseViewbox(svgImage.getAttribute('viewBox'));
      let x = parsedViewBox.width / this.viewPortWidth;
      let y = parsedViewBox.height / this.viewPortHeight;
      let max = Math.max.apply(Math, [x, y]);

      this.transformMultiplier = {
        x: x,
        y: y,
        max: max
      };
    },
    /**
     *
     * @param viewBox
     * @return {{x: string, width: string, y: string, height: string}}
     */
    parseViewbox(viewBox) {
      let val = viewBox.split(/\s+|,/);
      let obj = {
        x: val[0],
        y: val[1],
        width: val[2],
        height: val[3],
      };

      return obj;
    }
  }
}
</script>
