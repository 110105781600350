var render = function render(){var _vm=this,_c=_vm._self._c;return _c('zap-page-layout',{scopedSlots:_vm._u([{key:"navbar",fn:function(){return [_c('zap-prj1002-navbar',{attrs:{"caption":_vm.navbarCaption,"building":_vm.navbarBuilding,"buttonType":_vm.navbarButtonType,"buttonMode":_vm.navbarButtonMode,"buttonDestination":_vm.navbarButtonDestination}})]},proxy:true},{key:"contentMain",fn:function(){return [_c('page-layout',{scopedSlots:_vm._u([{key:"nav",fn:function(){return [_c('prj1005-navigation-bar',{attrs:{"route-name":_vm.ROUTE_DASHBOARD}})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"prj-layout-default h-full"},[_c('header',{},[_c('t-tag',{staticClass:"xs:mt-6",attrs:{"variant":"sectionTitle","tag-name":"h1"}},[_vm._v(" "+_vm._s(_vm.$t('fest.themes.headline'))+" ")])],1),_c('div',{staticClass:"relative flex-1"},[_c('div',{staticClass:"absolute top-0 right-0 bottom-0 left-0"},[(!_vm.themesInfo.length)?_c('div',{staticClass:"flex h-full"},[_c('prj1023-no-content-panel',{staticClass:"h-11/12 my-auto",attrs:{"text":_vm.$t('fest.themes.placeholder')}})],1):_c('prj1006-cards-wrapper',{staticClass:"h-full",attrs:{"hasNavigation":!_vm.isSingleTheme,"itemsLength":_vm.themesInfo.length}},[_vm._l((_vm.themesInfo),function({
                    additionalLink,
                    additionalLinkUrl,
                    additionalIconType,
                    categoryId,
                    content,
                    id,
                    image,
                    timeSpanInfo,
                    title,
                  }){return [_c('prj1007-theme-card',{key:`theme-${id}`,attrs:{"statusTag":timeSpanInfo,"categoryId":categoryId,"title":title,"description":content,"image":image,"isSingle":_vm.isSingleTheme,"link":additionalLink,"linkUrl":additionalLinkUrl,"icon":additionalIconType,"isFullPage":""},on:{"primaryButton":function($event){return _vm.toThemeDetail(id)}}})]})],2)],1)])])]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }