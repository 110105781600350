<template>
  <div class="vue-popup-covid-menu">
    <template
      v-for="category in menuData"
    >
      <frm1006-button
        :key="category.termId"
        class="vue-control-menu-button"
        type="app-button"
        @buttonClickEvent="menuItemClick(category.termId)"
      >
        {{ category.termName }}
      </frm1006-button>
    </template>
  </div>
</template>

<script>
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import EVENT_BUS from '@/event-bus';

export default {
  name: 'Prj1011PopupCovidMenu',
  props: {
    menuData: {
      type: [Array, Object],
      required: false,
    }
  },

  methods: {
    menuItemClick(filteredItemId) {
      this.$store.commit(STORE_MODULES.POPUP + '/' + MUTATIONS_CONSTANTS.TOGGLE_POPUP, false);
      EVENT_BUS.$emit(GLOBAL_EVENTS.FILTER_COVID_ITEM_LIST, filteredItemId);
    }
  }
}
</script>
