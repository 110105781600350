var render = function render(){var _vm=this,_c=_vm._self._c;return _c('zap-page-layout',{scopedSlots:_vm._u([{key:"navbar",fn:function(){return [_c('zap-prj1002-navbar',{attrs:{"caption":_vm.navbarCaption,"building":_vm.navbarBuilding,"buttonType":_vm.navbarButtonType,"buttonMode":_vm.navbarButtonMode,"buttonDestination":_vm.navbarButtonDestination}})]},proxy:true},{key:"contentMain",fn:function(){return [_c('page-layout',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"prj-layout-default h-full"},[_c('header',{},[_c('t-tag',{staticClass:"xs:mb-6 md:mb-8 xs:mt-6 md:mb-10",attrs:{"variant":"sectionTitle","tag-name":"h1"}},[_vm._v(" "+_vm._s(_vm.$t('fest.vote.headline'))+" ")])],1),_c('div',{staticClass:"relative flex-1"},[_c('div',{staticClass:"absolute top-0 right-0 bottom-0 left-0"},[(_vm.challenges && !_vm.challenges.length)?_c('div',{staticClass:"flex h-full"},[_c('prj1023-no-content-panel',{staticClass:"h-11/12 my-auto",attrs:{"text":_vm.$t('fest.vote.placeholder')}})],1):_c('prj1006-cards-wrapper',{staticClass:"h-full",attrs:{"hasNavigation":!_vm.isSingleChallenge,"itemsLength":_vm.challenges.length}},[_vm._l((_vm.challenges),function({
                    themeId,
                    additionalLink,
                    additionalLinkUrl,
                    additionalIconType,
                    categoryId,
                    competitionId,
                    description,
                    endDate,
                    id,
                    image,
                    participants,
                    resultsReady,
                    status,
                    startDate,
                    title,
                  }){return [_c('prj1007-vote-card',{key:`challenge-${id}`,attrs:{"statusTag":_vm.getStatus({ event: 'challenges', status, start: startDate, end: endDate }),"themeTitle":_vm.getThemeTitle(themeId),"categoryId":categoryId,"status":status,"link":additionalLink,"linkUrl":additionalLinkUrl,"icon":additionalIconType,"participants":parseInt(participants),"title":title,"description":description,"image":image,"isCompetitionLinked":!!competitionId,"isSingle":_vm.isSingleChallenge,"isResultReady":!!resultsReady,"isVoted":_vm.hasUserVoted,"isFullPage":""},on:{"primaryButton":function($event){_vm.hasUserVoted ? _vm.toggleOverlayVoted() : _vm.toPage({ route: _vm.ROUTE_VOTE_DETAIL, voteId: id })},"resultClick":function($event){return _vm.toPage({ route: _vm.ROUTE_VOTE_RESULT, voteId: id })},"secondaryButton":function($event){return _vm.toPage({ route: _vm.ROUTE_VOTE_RESULT, voteId: id })},"linkedEventClick":function($event){return _vm.toPage({ route: _vm.ROUTE_COMPETITION_DETAIL, competitionId })}}})]})],2)],1)])]),_c('prj1009-modal',{attrs:{"active":_vm.overlayVoted},on:{"overlayCloseEvent":_vm.toggleOverlayVoted},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fest.vote.votedModal.headline'))+" ")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"mb-10"},[_c('p',[_vm._v(_vm._s(_vm.$t('fest.vote.votedModal.description')))])])]},proxy:true},{key:"controls",fn:function(){return [_c('t-button',{staticClass:"bg-fest-home flex items-center justify-center",on:{"click":function($event){return _vm.toggleOverlayVoted()}}},[_vm._v(" "+_vm._s(_vm.$t("fest.vote.votedModal.buttonConfirm"))+" ")])]},proxy:true}])})]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }