<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1040-stepper
        v-if="!completedForm"
        :stepsCount="2"
        :activeStep="formStep"
      />

      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="navigateBack"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <!--======= FORM STEP 1 =====================-->
      <template
        v-if="formStep === 1 && !completedForm"
      >
        <prj1017-heading
          :header="$t('parents.forms.headingPersonalData')"
        />

        <frm1035-form-group>
          <frm1001-input-field
            v-model="userName"
            :label="$t('general.namePlaceholder')"
            :tooltip="false"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            v-model="userPersonalNumber"
            :label="$t('general.personalNumberPlaceholder')"
            :tooltip="false"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            v-model="userPhone"
            :label="$t('general.phoneOnlyPlaceholder')"
            :tooltip="false"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            v-model="userEmail"
            :label="$t('parents.forms.labels.emailPlaceholder')"
            :tooltip="false"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1028-advanced-combo
            id="idea-category"
            v-model="userWorkingPlace"
            :options="branches"
            :tooltip="false"
            :label="$t('parents.forms.placeholderWorkingPlace')"
            :placeholder="$t('parents.forms.placeholderWorkingPlace')"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1003-checkbox
            v-model="userPrivacyPolicy"
            :tooltip="false"
          >
            {{ $t('parents.forms.consents.personalData') }}

            <a
              class="prj-text-link cursor-pointer"
              :href="$t('parents.forms.consents.personalDataLinkUrl')"
              target="_blank"
            >{{ $t("parents.forms.consents.personalDataLink") }}</a>
            {{ $t('parents.forms.consents.personalDataEnd') }}
          </frm1003-checkbox>
        </frm1035-form-group>
      </template>

      <!--======= FORM STEP 2 =====================-->
      <template
        v-if="formStep === 2 && !completedForm"
      >
        <prj1017-heading
          :header="$t('parents.forms.headingWorkingPlace')"
        />

        <div class="vue-parents-consents-description">
          <p>
            {{ $t('parents.forms.consents.newsletterGroup') }}
          </p>
        </div>

        <frm1035-form-group>
          <frm1003-checkbox
            v-model="userNewsletterTypeEmail"
            :tooltip="false"
          >
            {{ $t('parents.forms.consents.newsletterEmail') }}
          </frm1003-checkbox>

          <frm1003-checkbox
            v-model="userNewsletterTypeFacebook"
            :tooltip="false"
          >
            {{ $t('parents.forms.consents.newsletterFacebook') }}
          </frm1003-checkbox>
        </frm1035-form-group>
      </template>

      <!--======= SUCCESS PAGE ====================-->
      <div
        v-if="completedForm"
        class="vue-b-form-success"
      >
        <prj1017-heading
          :header="$i18n.t('parents.forms.thankYou.headingProgram')"
          :subheader="$i18n.t('parents.forms.thankYou.subHeadingProgram')"
        />
      </div>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <!--======= NEXT STEP BUTTON ================-->
      <frm1006-button
        v-if="formStep === 1 && !completedForm"
        class="vue-button-register"
        :disabled="!isContinueActive"
        @buttonClickEvent="nextStep"
      >
        {{ $t('general.continue') }}
      </frm1006-button>

      <!--======= REGISTRATION BUTTON =============-->
      <frm1006-button
        v-if="formStep === 2 && !completedForm"
        class="vue-button-register"
        :disabled="!isSubmitActive"
        @buttonClickEvent="submitRegistration"
      >
        {{ $t('parents.forms.buttonSubmit') }}
      </frm1006-button>

      <!--======= BACK TO DASH ====================-->
      <frm1006-button
        v-if="completedForm"
        type="primary"
        @buttonClickEvent="goToDashboard"
      >
        {{ $t('parents.forms.thankYou.backToDash') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import {PARENTS_DASHBOARD_ID} from '@/constants/app-constants';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';

import utilsGeneral from '@/utils/utils-general';
import router from '@/router';

import Prj1002Navbar from '../../components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import Prj1040Stepper from '@/components/prj1040-stepper/prj1040-stepper';

export default {
  name: 'ParentsProgramRegistration',
  components: {
    Prj1040Stepper,
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      formStep: 1,
      completedForm: false,
      isLoading: false,
      hasError: false,
      userName: '',
      userEmail: '',
      userPhone: '',
      userPrivacyPolicy: false,
      userPersonalNumber: '',
      userNewsletterTypeEmail: false,
      userNewsletterTypeFacebook: false,
      userWorkingPlace: '',
      parentsCategoryData: []
    }
  },

  computed: {
    ...mapState('user', [
      'profile'
    ]),

    paramReferer() {
      if(typeof this.$route.params?.referer === 'undefined') {
        return false;
      }
      return this.$route.params.referer
    },

    isContinueActive() {
      return !!(this.userName && this.userPersonalNumber && this.formEmailInvalidState(this.userEmail) && this.userPhone && this.userWorkingPlace && this.userPrivacyPolicy);
    },

    isSubmitActive() {
      return this.isContinueActive;
    },

    branches() {
      return this.parentsCategoryData.map(item => {
        return {
          value: item.branchId,
          caption: item.branchCaption
        }
      })
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.userName = this.profile?.name ?? '';
    this.userEmail = this.profile?.workEmail ?? (this.profile?.otherEmail ?? '');
    this.getParentsCategories();
    this.initialized = true;
  },

  methods: {
    getParentsCategories() {
      this.setLoadingState(true);

      return dataLoader.fetchApiDataList(PARENTS_DASHBOARD_ID)
        .then(data => {
          this.parentsCategoryData = data.parents;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    formEmailInvalidState(value) {
      return utilsGeneral.isValidEmail(value);
    },

    formPhoneInvalidState(value) {
      return utilsGeneral.isValidPhoneNumber(value);
    },

    nextStep() {
      ++this.formStep
    },

    previousStep() {
      if (this.formStep > 1) {
        --this.formStep
      }
    },

    navigateBack() {
      if (this.formStep === 1 || this.completedForm) {
        router.push( { name: 'parents-dashboard' } );
      } else {
        this.previousStep();
      }
    },

    goToDashboard() {
      router.push( { name: 'parents-dashboard' } );
    },

    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    submitRegistration() {
      this.setLoadingState(true);

      const sendData = {
        userName: this.userName,
        userEmail: this.userEmail,
        userPhone: this.userPhone,
        userPrivacyPolicy: this.userPrivacyPolicy,
        userPersonalNumber: this.userPersonalNumber,
        userNewsletterTypeEmail: this.userNewsletterTypeEmail,
        userNewsletterTypeFacebook: this.userNewsletterTypeFacebook,
        userWorkingPlace: this.userWorkingPlace
      }

      dataLoader.postParentProgram(
        sendData
      )
        .then(() => {
          if (this.referer) {
            router.push( {name: this.referer} )
          }
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.setLoadingState(false);
          this.completedForm = true;
        });
    }
  }
}
</script>
