import * as MUTATIONS_CONSTANTS from '../constants/mutations'

const state = {
  weekActionsSelectedCategories: {
    eventCategories: 0
  },
}

const mutations = {
  [MUTATIONS_CONSTANTS.SET_WEEK_ACTIONS_SELECTED_CATEGORIES](state, filteredCategories) {
    state.weekActionsSelectedCategories.eventCategories = filteredCategories.categories;
  }
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
