<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-tab-panel" :class="classObject">
        <nav v-if="hasControls" class="vue-b-tab-controls">
            <ul class="vue-control-list" role="tablist">
                <!-- TODO MBU: accessibility, is it ok to have aria attributes on li, or should be directly on html element button? -->
                <li
                    v-for="(tab, index) in tabs"
                    :id="tabPanelItemsIdPrefix ? `${tabPanelItemsIdPrefix}-${tab.id}-control` : `${tab.id}-control`"
                    :key="`${tab.id}-control`"
                    :class="{
                        'vue-is-active': activeTabId === tab.id,
                        'vue-is-disabled': tab.disabled,
                        [`vue-${tab.id}`]: tab.id,
                        [tab.customCss]: tab.customCss
                    }"
                    class="vue-b-control-item"
                    role="tab"
                    :aria-controls="
                        tabPanelItemsIdPrefix ? `${tabPanelItemsIdPrefix}-${tab.id}-panel` : `${tab.id}-panel`
                    "
                    :aria-selected="activeTabId === tab.id"
                >
                    <frm1006-button
                        mode="button"
                        type="internal"
                        class="vue-ci-button-tab-control"
                        :disabled="tab.disabled"
                        :title="tab.caption"
                        :active="activeTabId === tab.id"
                        :tabindex="activeTabId === tab.id ? null : -1"
                        @buttonClickEvent="buttonTabControlClick(tab.id, index)"
                        @buttonKeyUpEvent="buttonTabControlKeyUp($event)"
                        @buttonFocusEvent="buttonTabControlFocus($event, tab.id)"
                        @buttonBlurEvent="buttonTabControlBlur($event, tab.id)"
                        >{{ tab.caption }}
                    </frm1006-button>
                </li>
            </ul>
        </nav>
        <section v-if="hasPanels" class="vue-b-tab-panels">
            <slot></slot>
        </section>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import frm1006Button from '../../frm/frm1006-button/frm1006-button';

//============ CONSTANTS ===============================//
//======================================================//

//========= CSS SELECTORS ====================//
const CSS_SELECTOR_BUTTON_TAB_CONTROL_BUTTON = '.vue-ci-button-tab-control .vue-button';

//============ EXPORT ==================================//
//======================================================//

export default {
    name: 'Gen1008TabPanel',
    components: {
        frm1006Button
    },
    model: {
        prop: 'value',
        event: 'tabChangeEvent'
    },
    props: {
        value: {
            type: String,
            required: true
        },
        tabs: {
            type: Array,
            required: true
        },
        autoSwitch: {
            default: true,
            type: Boolean
        },
        hasControls: {
            default: true,
            type: Boolean
        },
        hasPanels: {
            default: true,
            type: Boolean
        },
        tabPanelItemsIdPrefix: String,
        id: {
            default: null,
            type: String
        }
    },
    data() {
        return {
            activeTabId: this.value,
            focusTabId: null
        };
    },
    computed: {
        classObject() {
            return {
                'vue-has-controls-only': !this.hasPanels && this.hasControls,
                'vue-has-panels-only': this.hasPanels && !this.hasControls,
                'vue-is-component-active': this.componentIsActive,
                'vue-is-auto-switch': this.autoSwitch,
                'vue-is-manual-switch': !this.autoSwitch
            };
        },
        componentIsActive() {
            return !!this.focusTabId;
        },
        activeTabIndex() {
            return this.getTabIndex(this.value);
        },
        focusTabIndex() {
            return this.getTabIndex(this.focusTabId);
        }
    },
    watch: {
        value(value) {
            this.tabChange(value);
        }
    },
    methods: {
        tabChange(tabId) {
            this.$emit('input', tabId);
            this.$emit('tabChangeEvent', tabId);
            this.activeTabId = tabId;
            this.focusTabControl(this.activeTabId);
        },
        buttonTabControlClick(tabId, index) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('buttonTabControlClickEvent', tabId);
            this.tabChange(tabId, index);
        },
        buttonTabControlFocus(event, tabId) {
            this.focusTabId = tabId;
        },
        buttonTabControlBlur() {
            this.focusTabId = null;
        },
        buttonTabControlKeyUp(event) {
            if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                let newId;

                if (event.key === 'ArrowLeft') {
                    this.$emit('buttonTabControlClickEventKeyUpLeft', newId);
                    newId = this.getPrevTabId();

                    if (newId != null && this.autoSwitch) {
                        this.tabChange(newId);
                    } else if (newId != null && !this.autoSwitch) {
                        this.focusTabControl(newId);
                    }
                } else if (event.key === 'ArrowRight') {
                    this.$emit('buttonTabControlClickEventKeyUpLeft', newId);
                    newId = this.getNextTabId();

                    if (newId != null && this.autoSwitch) {
                        this.tabChange(newId);
                    } else if (newId != null && !this.autoSwitch) {
                        this.focusTabControl(newId);
                    }
                }
            }
        },
        getTabIndex(tabId) {
            for (let i = 0; i < this.tabs.length; i += 1) {
                if (this.tabs[i].id === tabId) {
                    return i;
                }
            }
            return -1;
        },
        getPrevTabId() {
            let prevTabIndex = this.focusTabIndex - 1;
            let prevTab = this.tabs[prevTabIndex];

            if (prevTab && prevTab.disabled) {
                let prevIndexCount = this.tabs.length - this.focusTabIndex + 1;

                for (let i = 1; i <= prevIndexCount; i++) {
                    let tab = this.tabs[prevTabIndex - i];
                    if (!tab.disabled) {
                        return tab.id;
                    } else {
                        return null;
                    }
                }
            } else if (prevTab) {
                return prevTab.id;
            }
        },
        getNextTabId() {
            let nextTabIndex = this.focusTabIndex + 1;
            let nextTab = this.tabs[nextTabIndex];

            if (nextTab && nextTab.disabled) {
                let nextIndexCount = this.tabs.length - this.focusTabIndex + 1;

                for (let i = 1; i <= nextIndexCount; i++) {
                    let tab = this.tabs[nextTabIndex + i];
                    if (!tab.disabled) {
                        return tab.id;
                    } else {
                        return null;
                    }
                }
            } else if (nextTab) {
                return nextTab.id;
            }
        },
        focusTabControl(tabId) {
            let tabNewIdSelector = this.tabPanelItemsIdPrefix
                ? `${this.tabPanelItemsIdPrefix}-${tabId}-control`
                : `${tabId}-control`;
            let buttonTabControl = document
                .getElementById(tabNewIdSelector)
                .querySelector(CSS_SELECTOR_BUTTON_TAB_CONTROL_BUTTON);
            buttonTabControl.focus();
        }
    }
};
</script>
