<template>
  <transition
    name="vue-default"
    appear
    @after-enter="transitionEnd"
  >
    <div
      v-if="active"
      class="vue-c-status-indicator"
    >
      <span class="vue-status-indicator-text">{{ text }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Prj1041StatusIndicator',
  props: {
    text: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    transitionEnd() {
      this.$emit('transitionEnd')
    }
  }
}
</script>
