<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar
            route-name="challenges"
          />
        </template>
        <template v-slot:content>
          <!--========== PAGE BODY ==============================-->
          <!--===================================================-->
          <div
            v-if="challengeDetail"
            class="prj-article prj-promo-page"
          >
            <t-tag
              variant="sectionTitle"
              class="xs:mb-8 md:mb-10 xs:mt-6 md:mt-10"
            >
              {{ challengeDetail.title }}
              <t-tag
                v-if="challengeDetail.subTitle"
                variant="highlight"
              >
                {{ challengeDetail.subTitle }}
              </t-tag>
            </t-tag>
          </div>

          <!--========== SUMMARY (USER) =========================-->
          <!--===================================================-->
          <prj1012-saving-panel
            v-if="isHistory && challengeDetail"
            class="mb-4 xs:mb-8"
            :value="summaryValue"
            :units="units"
            :headline="savingPanelHeadline"
            :description="challengeHighestTextSettings.savingPanelHistory.text"
          />

          <!--========== SUMMARY (ALL) ==========================-->
          <!--===================================================-->

          <div
            v-if="isHistory && challengeDetail"
            class="relative rounded-2xl mb-8 p-4 xs:p-6 bg-gradient-to-r from-sky-500 to-sky-600 bg-sky-600"
          >
            <div class="flex flex-col justify-between">
              <!-- Title + description -->
              <div class="flex flex-col w-full">
                <span class="mb-2 text-2xl font-bold leading-8 text-white">
                  {{ challengeHighestTextSettings.savingPanelHistoryResult.title }}
                </span>
              </div>

              <!-- Calculated savings -->
              <div class="flex flex-col w-full flex-1">
                <div class="text-white text-right flex flex-col items-end">
                  <span class="text-5xl font-extrabold">{{ getStatsSummaryValue.all }}</span>
                  <span class="text-xl font-bold text-sky-200">{{ units[0] }}</span>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="isHistory && challengeDetail"
            class="pb-8"
          >
            <h2 class="mb-8 font-bold text-xl">{{ $t('esg.challenges.result.barChart.headline') }}</h2>

            <div class="mb-4">
              <div class="flex justify-between">
                <div class="w-1/2 flex flex-col justify-between">
                  <template v-for="(item, index) in barChartPercentageStats">
                    <div
                      :key="index"
                      class="w-full"
                      :class="{'mb-4': index === 0}"
                    >
                      <h3
                        class="font-extrabold text-5xl mb-0"
                        :class="item.cssClassHeadline"
                      >
                        {{ item.percentage }} %
                      </h3>
                      <span class="font-bold text-base">{{ item.name }}</span>
                    </div>
                  </template>
                </div>

                <div class="w-1/2 flex justify-evenly items-end">
                  <template v-for="(item, index) in barChartPercentageStats">
                    <div
                      :key="index"
                      class="w-8 h-full rounded-xl"
                      :class="item.cssClassBarLine"
                      :style="'height:' + item.percentageCss"
                    ></div>
                  </template>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="w-2 h-2 rounded bg-sky-500 flex-shrink-0 mr-2 mt-0.5"></div>
              <p class="text-xs text-gray-500">{{ $t('esg.challenges.result.barChart.legend') }}</p>
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
const HISTORY_WEEK = 'week';
const HISTORY_MONTH = 'month';

const SUMMARY_MULTIPLIER_TYPE_ID = '20';
const SUMMARY_MULTIPLIER_VALUE = 2;

//============ IMPORT ==================================//
//======================================================//
import '@/utils/dayjs';
import { mapState } from 'vuex';
import utilsGeneral from '@/utils/esg/utils-general';
import logger from '@/utils/logger';

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import garbageCleanChallengeType from '@/assets/esg/challenges/types/garbageClean';

import PageLayout from '@/templates/esg/page-layout';
import Prj1005NavigationBar from '@/components/esg/prj1005-navigation-bar/prj1005-navigation-bar';
import Prj1012SavingPanel from '@/components/esg/prj1012-saving-panel/prj1012-saving-panel';
import apiCmsChallenges from '@/esg-api/cms-challenges';

import { ESG_CHALLENGES } from '@/store/store-modules';
import { GET_CHALLENGES_INFO } from '@/store/constants/esg/actions';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'ESGChallengeHistory',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1005NavigationBar,
    Prj1012SavingPanel,
    PageLayout
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      historyData: [],
      historyDataGroup: [],
      challengeDetail: null,
      challengeHighestTextSettings: garbageCleanChallengeType.settings,
      challengeHighestValue: garbageCleanChallengeType.highestValue,
      challengeItems: garbageCleanChallengeType.transportItems,
      units: garbageCleanChallengeType.units,
      secondaryUnits: garbageCleanChallengeType.secondaryUnits,
      optionModal: false,
      options: [
        {
          type: HISTORY_WEEK,
          name: this.$t('esg.challenges.history.options.week')
        },
        {
          type: HISTORY_MONTH,
          name: this.$t('esg.challenges.history.options.month')
        }
      ],
      selectedOptionIndex: 0,
      loginOverlay: false
    }
  },
  computed: {
    ...mapState('esgChallenges', ['challengesInfo']),
    challengeId() {
      return parseInt(this.$route.params.challengeId);
    },
    historyList() {
      const historyList = this.historyData.list;

      if (this.selectedOptionIndex === 0 && historyList) {
        let weekOfYear = this.$date().isoWeek();
        let historyOfWeek = [];

        for (let item of historyList) {
          let itemWeekOfYear = this.$date(item.day).isoWeek();

          if (weekOfYear === itemWeekOfYear) {
            historyOfWeek.push(item);
          }
        }

        return historyOfWeek;
      }

      return historyList;
    },
    challengeExtendedSettings() {
      return garbageCleanChallengeType.settingsExtended;
    },
    isHistory() {
      return this.historyList;
    },
    summaryValue() {
      let summary = 0;

      for (let item of this.historyData.list) {
        if (item.challenge_id === this.challengeId.toString()) {
          summary = summary + Number(item.distance);
        }
      }

      return summary;
    },
    savingPanelHeadline() {
      let summaryText = '';
      let summaryTextTypes = [];
      let summaryTextTypesText = [];
      let counter = 0;

      for (let item of this.historyData.list) {
        if (item.challenge_id === this.challengeId.toString() && counter === 0) {
          summaryText = item.transport_name;
        }
        if (item.challenge_id === this.challengeId.toString() && !summaryTextTypes.includes(item.transport_id)) {
          summaryTextTypes.push(item.transport_id);
        }
        counter++;
      }

      summaryTextTypes.sort();

      for (let itemType of summaryTextTypes) {
        let transportItem = this.challengeItems.find(transportItem => transportItem.id === parseInt(itemType));
        if (!summaryTextTypesText.includes(transportItem.shortName)) {
          summaryTextTypesText.push(transportItem.shortName);
        }
      }

      if (summaryTextTypesText.length > 0) {
        summaryText = summaryTextTypesText.join(' a ');
      }

      return this.challengeHighestTextSettings.savingPanelHistory.title + ' ' + summaryText;
    },
    stats() {
      return this.historyData.stats;
    },
    getStatsSummaryValue() {
      const statsData = this.stats;
      const challengeId = this.challengeId.toString();
      let summaryValue = {
        all: 0,
        allWithMultiplier: 0,
        types: []
      };

      for (const challengeItem of statsData) {
        if (challengeItem.challengeId === challengeId) {
          const multiplier = challengeItem.valueType === SUMMARY_MULTIPLIER_TYPE_ID ? SUMMARY_MULTIPLIER_VALUE : 1;

          summaryValue.all += parseFloat(challengeItem.summaryValue);

          // Multiplier using challenge about cleaning garbage czk vs svk
          summaryValue.allWithMultiplier += parseFloat(challengeItem.summaryValue) * multiplier;

          // Save edited value for challenge type applied multiplier without impact to summary value. Is needed for correctly showing in bar chart
          challengeItem.summaryValue = challengeItem.summaryValue * multiplier;

          summaryValue.types.push(challengeItem);
        }
      }

      return summaryValue;
    },
    barChartPercentageStats() {
      const types = this.getStatsSummaryValue.types;
      const summaryValue = this.getStatsSummaryValue.allWithMultiplier;
      let barColumns = []

      for (const [index, value] of types.entries()) {
        let item = {
          percentage: Math.round((value.summaryValue / summaryValue) * 100),
          percentageCss: Math.round((value.summaryValue / summaryValue) * 100) + '%',
          cssClassHeadline: index === 0 ? 'text-sky-600' : 'text-sky-500',
          cssClassBarLine: index === 0 ? 'bg-sky-600' : 'bg-sky-500',
          name: value.typeName
        }

        barColumns.push(item);
      }

      return barColumns;
    }
  },
  async created() {
    if (!this.challengesInfo.length) await this.$store.dispatch(`${ESG_CHALLENGES}/${GET_CHALLENGES_INFO}`)
    this.getChallengeById()
    this.getChallengeUserHistory();
  },
  methods: {
    getChallengeById() {
      this.challengeDetail = this.challengesInfo.find((challenge) => challenge.id === this.challengeId);
    },
    challengeTypeObject(id) {
      return this.challengeItems.find(transportItem => transportItem.id === parseFloat(id))
    },
    percentageFromMaxValue(max, value) {
      const maxValue = max ? max : 1;
      const valueWithMax = value ? value : max ? value : 1;

      return Math.round( (valueWithMax / maxValue) * 100);
    },
    getChallengeUserHistory() {
      let processId = utilsGeneral.startAsyncProcess();

      apiCmsChallenges
        .getChallengeUserHistory()
        .then(response => {
          this.historyData = response;
        })
        .catch(error => {
          utilsGeneral.stopAsyncProcess(processId);
          logger.error('Sending form has failed.', error.response);
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId);
        });
    },
    toggleOptionModal() {
      this.optionModal = !this.optionModal;
    },
  }
}
</script>
