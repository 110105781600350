<template>
  <transition
    :name="showTransitionName"
  >
    <div
      v-if="isMenuOpened"
      class="vue-c-navigation"
      :class="classObject"
    >
      <div
        ref="contentContainer"
        class="vue-b-content-container"
      >
        <div
          ref="navigationContent"
          class="vue-b-content"
        >
          <div
            ref="navigationItems"
            class="vue-b-main"
          >
            <prj1005-navigation-items @searchKeyUpEvent="scrollableContent" />
          </div>

          <div
            v-if="showScrollDownIndicator"
            class="vue-scroll-down-indicator"
          ></div>
        </div>

        <div class="vue-b-bottom">
          <prj1005-navigation-info
            v-if="!isSubmenuOpened"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapState} from 'vuex';

import Prj1005NavigationItems from './prj1005-navigation-items';
import Prj1005NavigationInfo from './prj1005-navigation-info';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import EVENT_BUS from '@/event-bus';

export default {
  name: 'Prj1005Navigation',
  components: {
    Prj1005NavigationItems,
    Prj1005NavigationInfo
  },

  data() {
    return {
      isContentScrollable: false,
      contentScrolledDown: 0
    }
  },

  computed: {
    ...mapState('general', [
      'isMenuOpened',
      'isSubmenuOpened',
      'backToNavigation'
    ]),

    classObject() {
      return {
        'vue-is-active': this.isMenuOpened,
        'vue-is-subnavigation-active': this.isSubmenuOpened,
      }
    },

    showTransitionName() {
      if (this.backToNavigation && this.isMenuOpened) {
        return 'vue-toggle-back';
      } else {
        return 'vue-toggle';
      }
    },

    showScrollDownIndicator() {
      return !this.isSubmenuOpened && this.isContentScrollable && this.contentScrolledDown < 4;
    }
  },

  watch: {
    isMenuOpened(value) {
      if (value) {
        EVENT_BUS.$emit(GLOBAL_EVENTS.CLOSE_DRAWER);
        this.registerEventListeners();

        this.$nextTick(() => {
          this.scrollableContent();
          this.$refs.navigationContent.addEventListener('scroll', this.scrolledContent);
        })


      } else {
        this.unregisterEventListeners();
        this.$refs.navigationContent.removeEventListener('scroll', this.scrolledContent);
      }
    }
  },

  beforeDestroy() {
    this.unregisterEventListeners();
  },

  methods: {
    click(event) {
      if (!this.whiteListCheck(event)) {
        this.close();
      }
    },

    keyUp(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },

    close() {
      this.resetRouteParams()
        .then(() => {
          this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.CLOSE_DASHBOARD_NAVIGATION);

          if (this.isSubmenuOpened) {
            this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.TOGGLE_DASHBOARD_SUBMENU);
          }
        });
    },

    whiteListCheck(event) {
      let element = event.target;
      let navbarButton = document.querySelector('.vue-navbar-button');

      if (this.$refs.contentContainer.contains(element) || navbarButton.contains(element)) {
        return true;
      }

      return false;
    },

    registerEventListeners() {
      setTimeout(() => {
        document.addEventListener('click', this.click);
        document.addEventListener('keyup', this.keyUp);
      }, 100);
    },

    unregisterEventListeners() {
      document.removeEventListener('click', this.click);
      document.removeEventListener('keyup', this.keyUp);
    },

    resetRouteParams() {
      return new Promise(resolve => {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_BACK_TO_NAVIGATION, false);
        resolve();
      })
    },

    scrollableContent() {
      const contentHeight = this.$refs.navigationContent.offsetHeight;
      const itemsHeight = this.$refs.navigationItems.offsetHeight;

      this.isContentScrollable = itemsHeight > contentHeight;
    },

    scrolledContent() {
      this.contentScrolledDown = this.$refs.navigationContent.scrollTop;
    }
  }
}
</script>
