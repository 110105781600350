<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :button-back-type="'none'"
        :building="false"
        @activeView="buttonBackEvent"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        id="vue-m-strategy-strategy-summary"
        ref="strategyContainer"
        class="vue-strategy-page-layout"
      >
        <div class="vue-summary">
          <transition
            name="vue-anim-strategy-move-from-right"
            appear
          >
            <div class="vue-b-header">
              <h2
                class="vue-title"
              >
                {{ $t('strategy.correctAnswer') }}
              </h2>
            </div>
          </transition>

          <transition
            name="vue-anim-strategy-audience-total"
            appear
          >
            <div class="vue-summary-count">
              <span class="vue-summary-count-number">{{ answersPercentage }}%</span>
              <span class="vue-summary-count-text">{{ rightAnswersCount }} {{ $t('strategy.summaryAnswers') }} {{ answersCount }}</span>
            </div>
          </transition>

          <transition
            name="vue-anim-strategy-move-from-top"
            appear
          >
            <div
              v-transition-delay-enter="300"
              class="vue-b-content vue-strategy-container"
            >
              <gen1015-section
                :collapsible="true"
                :collapsed="true"
                class="is-animated"
              >
                <template v-slot:header>
                  <h3>{{ $t('strategy.correctAnswer') }}</h3>
                </template>

                <template v-slot:content>
                  <div class="vue-b-content-animated">
                    <div
                      ref="strategySummary"
                      class="vue-b-correct-answers"
                    >
                      <ul>
                        <li
                          v-for="(question, indexQuestion) in quizData"
                          :key="indexQuestion"
                        >
                          <div class="vue-question">
                            {{ question.question }}
                          </div>
                          <template v-for="(item, indexAnswer) in question.answers">
                            <template v-if="item.truth">
                              <div
                                :key="indexAnswer"
                                class="vue-answer"
                              >
                                {{ item.answer }}
                              </div>
                            </template>
                          </template>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </gen1015-section>
            </div>
          </transition>

          <frm1031-button-container
            class="vue-summary-buttons"
          >
            <slot>
              <transition
                name="vue-anim-strategy-move-from-right"
                appear
              >
                <frm1006-button
                  v-if="!quizSent && summaryData.result"
                  type="primary"
                  @buttonClickEvent="goToForm"
                >
                  <span class="vue-text">{{ $t('strategy.buttonQuizCompetition') }}</span>
                </frm1006-button>

                <frm1006-button
                  v-if="!quizSent && !summaryData.result"
                  type="primary"
                  @buttonClickEvent="goToQuizAgain"
                >
                  <span class="vue-text">{{ $t('strategy.tryAgain') }}</span>
                </frm1006-button>
              </transition>

              <transition
                name="vue-anim-strategy-move-from-right"
                appear
              >
                <frm1006-button
                  type="secondary"
                  @buttonClickEvent="backToDashboard"
                >
                  <span class="vue-text">{{ $t('strategy.buttonToDashboard') }}</span>
                </frm1006-button>
              </transition>
            </slot>
          </frm1031-button-container>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script type="application/javascript">

import * as STRATEGY_COMPETITION from '@/assets/mocks/strategyCompetitionData';
import { STRATEGY_DEFAULT_LANG, STRATEGY_COMPETITION_ACTIVE } from '@/constants/app-constants';
import { mapState } from 'vuex';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Gen1015Section from '../../../frameworks/vue/components/gen/gen1015-section/gen1015-section';
import router from '@/router';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import moduleStatistics from '@/mixins/mx-module-statistics';
import PageLayout from '@/templates/partials/page-layout';

export default {
  name: 'StrategySummary',
  components: {
    Gen1015Section,
    Prj1002Navbar,
    PageLayout
  },

  mixins: [
    moduleStatistics
  ],

  data() {
    return {
      competitionData: STRATEGY_COMPETITION.DATA_ARRAY.competition,
      summaryData: {
        icons: [],
        result: 0,
        answers: ''
      },
      sendButtonDisabled: true,
      viewActive: 1,
      answersPercentage: 0
    }
  },

  computed: {
    ...mapState('competition', [
      'competitions'
    ]),
    ...mapState('user', [
      'deviceId'
    ]),

    quizData() {
      let lang = this.getSelectedLang;
      if( Object.prototype.hasOwnProperty.call(this.competitionData, lang) ) {
        return this.competitionData[lang]['items'];
      }
      return this.competitionData[STRATEGY_DEFAULT_LANG]['items'];
    },

    getSelectedLang() {
      return this.$i18n.locale;
    },

    answersCount() {
      return this.summaryData.icons.length;
    },

    rightAnswersCount() {
      let number = 0;

      for (let answer of this.summaryData.icons) {
        if (answer === true) {
          number++
        }
      }

      return number;
    },

    rightAnswersPercentage() {
      return Math.floor((this.rightAnswersCount / this.answersCount) * 100);
    },

    quizCompleted() {
      if (typeof this.competitions.data[STRATEGY_COMPETITION_ACTIVE] === 'undefined') {
        return false;
      }
      return this.competitions.data[STRATEGY_COMPETITION_ACTIVE].summaryTimestamp !== null;
    },

    quizSent() {
      if (typeof this.competitions.data[STRATEGY_COMPETITION_ACTIVE] === 'undefined') {
        return false;
      }
      return this.competitions.data[STRATEGY_COMPETITION_ACTIVE].sentResult === 2;
    }
  },

  watch: {
    rightAnswersPercentage(value) {
      this.numberAnimateCounter(value)
    }
  },

  created() {
    window.addEventListener('resize', this.setCssVariablesSectionHeight);
  },

  mounted() {
    if (!this.calculateSummary()) {
      router.push({ name: 'strategy-competition-intro'});
    }
    if (!this.quizCompleted) {
      this.$store.commit(STORE_MODULES.COMPETITION + '/' + MUTATIONS_CONSTANTS.SET_COMPETITION_SUMMARY_TIMESTAMP);
    }
    this.setCssVariablesSectionHeight();
    this.sendStatistics('STRATEGY-QUIZ-SUMMARY');
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setCssVariablesSectionHeight);
  },

  methods: {
    buttonBackEvent() {
      router.push({ name: 'strategy-competition-intro'});
    },

    getAnswerTruth(questionId, answerId) {
      return this.quizData[questionId].answers[answerId].truth;
    },

    calculateSummary() {
      if(typeof this.competitions.data[STRATEGY_COMPETITION_ACTIVE] === 'undefined') {
        return false;
      }
      if(this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results === null) {
        return false;
      }
      if(Object.keys(this.quizData).length === Object.keys(this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results).length) {
        let result = 0;

        for (let key of Object.keys(this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results)) {
          key = parseInt(key);
          this.summaryData.answers += this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results[key] + ',';

          if(this.getAnswerTruth(key, this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results[key])) {
            this.summaryData.icons.push(true);
            ++result;
          } else {
            this.summaryData.icons.push(false);
          }
        }

        this.summaryData.result = result === Object.keys(this.quizData).length;
        this.$store.commit(STORE_MODULES.COMPETITION + '/' + MUTATIONS_CONSTANTS.SET_COMPETITION_RESULT, this.summaryData.result);
      } else {
        return false;
      }

      return true;
    },

    goToForm() {
      router.push(({ name: 'strategy-competition-registration'}));
    },

    goToQuizAgain() {
      this.resetQuizResults();
      this.$nextTick(() => {
        router.push({ name: 'strategy-competition'});
      });
    },

    backToDashboard() {
      router.push({ name: 'strategy' });
    },

    setCssVariablesSectionHeight() {
      const minTransitionDuration = 500;
      const transitionDurationDiff = 1;
      const contentHeight = this.$refs.strategySummary.offsetHeight;
      const transitionDurationComputed = transitionDurationDiff * contentHeight;
      const transitionDuration = transitionDurationComputed > minTransitionDuration ? transitionDurationComputed : minTransitionDuration;

      document.documentElement.style.setProperty('--strategyContentHeightSummary', contentHeight + 'px');
      document.documentElement.style.setProperty('--strategyContentDurationSummary', transitionDuration + 'ms');
    },

    numberAnimateCounter(number) {
      const duration = 2000;
      const start = 0;
      const endAudienceTotal = number;

      let numberTemp = 0;

      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);

        numberTemp = Math.floor(progress * (endAudienceTotal - start) + start);

        if (progress < 1) {
          window.requestAnimationFrame(step);
        }

        this.answersPercentage = numberTemp;
      };

      window.requestAnimationFrame(step);
    },

    resetQuizResults() {
      this.$store.commit(STORE_MODULES.COMPETITION + '/' + MUTATIONS_CONSTANTS.SET_COMPETITION_RESET);
    },
  },
}
</script>
