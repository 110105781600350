<template>
  <div
    class="vue-c-event-detail-header"
    :class="classObject"
  >
    <div
      class="vue-event-detail-image"
      :style="styleObject"
    />

    <gen1005-tag-container>
      <template v-for="(category, index) in eventData.categories">
        <gen1004-tag-label :key="'category-' + index">
          {{ category.name }}
        </gen1004-tag-label>
      </template>
    </gen1005-tag-container>
  </div>
</template>

<script>
import * as envConfig from 'env-config';

export default {
  name: 'Prj1014EventDetailHeader',
  props: {
    eventData: {
      type: Object,
      required: true
    }
  },

  computed: {
    classObject() {
      return {
        'vue-has-no-image': !this.hasImage,
      }
    },

    hasImage() {
      if (this.eventData) {
        return this.eventData.image !== '';
      }

      return false
    },

    styleObject() {
      if (this.eventData.image) {
        return 'background-image: url("' + this.cmsUrl + this.eventData.image + '")'
      } else {
        return ''
      }
    },

    cmsUrl() {
      return envConfig.default.webServices.BASE_CMS_URL;
    },
  }
}
</script>
