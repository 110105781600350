<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    v-if="overlayActive"
    class="fixed top-0 right-0 bottom-0 left-0 z-40"
    @click="overlayClickOutside"
  >
    <!-- Overlay -->
    <transition
      name="fade"
      appear
    >
      <div
        v-if="active"
        class="fixed top-0 right-0 bottom-0 left-0 bg-black opacity-50 transition duration-300"
      />
    </transition>

    <!-- Container -->
    <transition
      appear
      name="expand-modal-content"
      @afterLeave="leave"
    >
      <div
        v-if="active"
        ref="overlayComponent"
        class="absolute flex items-end right-0 bottom-0 left-0 top-0 transform translate-y-0 transition-all duration-300"
      >
        <div class="mx-auto flex flex-col pt-8 w-full sm:w-144 rounded-t-2xl bg-white min-h-0 max-h-modal rounded-t-2xl w-full">
          <div class="overflow-y-auto prj-layout-padding-x">
            <div
              v-if="headerHasTitle"
              class="text-center xs:mb-4"
            >
              <t-tag variant="sectionTitle">
                <slot name="title" />
              </t-tag>
            </div>

            <div
              v-if="footerHasContent"
              class="xs:mb-4"
            >
              <slot name="content" />
            </div>

            <div
              v-if="hasContentControls"
              class="flex flex-col mb-4 xs:mb-8"
            >
              <slot name="controls" />
            </div>
          </div>

          <div
            v-if="hasContentFixed"
            class="flex flex-col pt-2 xs:pt-3 pb-4 xs:pb-8 prj-layout-padding-x"
          >
            <slot name="fixed" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
const OVERLAY_CLOSE_EVENT = 'overlayCloseEvent';
const OVERLAY_LEAVE = 'overlayLeaveContent';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1009Modal',
  components: {},
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      overlayShowing: false
    }
  },
  computed: {
    overlayActive() {
      return this.overlayShowing
    },
    headerHasTitle() {
      return !!this.$slots.title;
    },
    footerHasContent() {
      return !!this.$slots.content;
    },
    hasContentControls() {
      return !!this.$slots.controls;
    },
    hasContentFixed() {
      return !!this.$slots.fixed;
    }
  },
  watch: {
    active(value) {
      if (value) this.overlayShowing = value;
    }
  },
  mounted() {
    this.overlayShowing = this.active;
  },
  methods: {
    overlayClickOutside(event) {
      if (event && event.target !== this.$refs.overlayComponent) {
        return;
      }
      this.$emit(OVERLAY_CLOSE_EVENT, 'clickOutside');
    },
    leave() {
      this.$emit(OVERLAY_LEAVE);
      this.overlayShowing = false;
    }
  }
}
</script>
