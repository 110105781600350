<template>
  <div
    ref="articlesList"
    class="vue-c-news-list"
  >
    <div
      ref="articleListContainer"
      class="vue-article-list-container"
      @scroll="scroll"
    >
      <template
        v-if="articlesToDisplay && articlesToDisplay.length > 0 && !isLoading"
      >
        <template
          v-for="(article, index) in articlesToDisplay"
        >
          <prj1032-news-list-item
            :key="article.id + '-' + index"
            :index="index"
            :article="article"
            :last="index === articlesToDisplay.length - 1"
            :isCategoryFilterActive="isCategoryFilterActive"
            :filteredArticlesCategoryName="filteredArticlesCategoryName"
          />
        </template>
      </template>

      <gen1006-info-panel
        v-else-if="!(articlesToDisplay && articlesToDisplay.length > 0) && !isInfinityScrollEnabled && nothingToShow"
        type="neutral"
      >
        {{ $t('articles.emptyCategory') }}
      </gen1006-info-panel>

      <div
        v-if="isInfinityScrollEnabled"
        ref="infinityScrollTrigger"
      >
        <gen1016-loading-indicator
          :active="isInfinityScrollEnabled"
        />
      </div>
    </div>

    <template
      v-if="1 === 0"
    >
      <gen1016-loading-indicator
        :active="isLoading"
        :class="classObject"
        :fullScreen="!isDashboard"
        :overlay="!isDashboard"
      />
    </template>

    <gen1009-overlay
      class="vue-is-info-panel"
      :active.sync="hasError"
      :buttonClose="true"
    >
      <gen1006-info-panel
        type="error"
      >
        {{ $t('error.generic') }}
      </gen1006-info-panel>
    </gen1009-overlay>

    <prj1026-category-filter
      v-if="categoryFilter && articlesCategories.length > 0"
      :filterCategories="articlesCategories"
      :showItems="showItemsInFilter"
      :activeCategory="parseInt(newsActiveCategory)"
      filterType="articles"
      @filterItems="filterNewsItems"
      @resetFilteredItems="resetFilteredArticles"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import mxInfinityScrolling from '@/mixins/mx-infinity-scrolling';

import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as STORE_MODULES from '@/store/store-modules';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import EVENT_BUS from '@/event-bus';

import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter';
import Prj1032NewsListItem from './prj1032-news-list-item';

export default {
  name: 'Prj1032NewsList',
  components: {
    Prj1026CategoryFilter,
    Prj1032NewsListItem
  },

  mixins: [
    mxInfinityScrolling
  ],

  props: {
    categoryIdInitial: Number,
    categoryFilter: Boolean
  },

  data() {
    return {
      hasFilteredArticles: false,
      articlesCategories: [],
      filteredArticles: [],
      filteredArticlesOffset: 0,
      noMoreArticles: false,
      initialized: false,
      isLoading: false,
      nothingToShow: false,
      hasError: false,
    }
  },

  computed: {
    ...mapState('articles', [
      'newsActiveCategory',
      'searchQuery',
    ]),
    ...mapState('dataStore', {
      articles: state => state.contentData.news.data
    }),
    ...mapState('general', [
      'viewportSize'
    ]),
    ...mapState('dashboard', [
      'selectedBuilding'
    ]),
    ...mapState('popup', [
      'isCategoriesMenuVisible'
    ]),

    classObject() {
      return {
        'vue-has-upper-loader': this.$route.meta.allowedBackground
      }
    },

    sortedArticles() {
      const unsortedArticlesObject = Object.entries(this.articles);
      let unsortedArticlesArray = [];

      unsortedArticlesObject.forEach(item => {
        unsortedArticlesArray.push(item[1]);
      });

      return unsortedArticlesArray.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      })
    },

    articlesToDisplay() {
      return this.filteredArticles;
      //return this.hasFilteredArticles ? this.filteredArticles : this.sortedArticles
    },

    isCategoryFilterActive() {
      return this.hasFilteredArticles;
    },

    filteredArticlesCategoryName() {
      if (this.newsActiveCategory && this.newsActiveCategory !== 0 && this.articlesCategories.length > 0) {
        const filteredCategoryIndex = this.articlesCategories.findIndex(item => parseInt(item.termId) === parseInt(this.newsActiveCategory));

        if (filteredCategoryIndex > -1) {
          let name = this.articlesCategories[filteredCategoryIndex].termName;
          this.$emit('filteredCategoryNameUpdatedEvent', name);
          return name;
        }
      }

      return '';
    },

    isDashboard() {
      return this.$route.name === 'dashboard';
    },

    showItemsInFilter() {
      return this.articlesCategories ? this.articlesCategories.length : 0;
    },

    isInfinityScrollEnabled() {
      const ONE_PAGE = 19;

      return this.filteredArticles.length > ONE_PAGE && !this.noMoreArticles;
    }
  },

  watch: {
    filteredArticles(value) {
      if (value.length > 0) {
        this.$emit('filteredArticlesChangeEvent', value);
      }
    },

    categoryIdInitial() {
      this.setCategoryActiveByIdInitial();
    },

    articles(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadNewContent(false);
      }
    },

    searchQuery() {
      this.loadNewContent(false);
    }
  },

  created() {
    if (this.$route.params.hasActiveCategory) {
      this.isLoading = true;
      this.hasFilteredArticles = true;
    }

    EVENT_BUS.$on(GLOBAL_EVENTS.FILTER_ITEM_LIST, selectedCategoryNumber => {
      this.filterNewsItems(selectedCategoryNumber);
    });
    EVENT_BUS.$on(GLOBAL_EVENTS.RELOAD_ARTICLES, this.resetFilteredArticles);

    // if categoryIdInitial is specified, set filter and fetch articles
    this.setCategoryActiveByIdInitial();

    this.$emit('initializedEvent');

    this.getPostsFilterCategories();
  },

  beforeDestroy() {
    EVENT_BUS.$off(GLOBAL_EVENTS.FILTER_ITEM_LIST);
    EVENT_BUS.$off(GLOBAL_EVENTS.RELOAD_ARTICLES);
    //this.resetFilteredArticles();
  },

  methods: {
    setCategoryActiveByIdInitial() {
      if (this.categoryIdInitial || parseInt(this.categoryIdInitial) === 0) {
        if (parseInt(this.categoryIdInitial) !== 0) {
          this.hasFilteredArticles = true;
          this.$store.commit(STORE_MODULES.ARTICLES + '/' + MUTATIONS_CONSTANTS.SET_NEWS_ACTIVE_CATEGORY, this.categoryIdInitial);
        } else {
          this.hasFilteredArticles = false;
          this.$store.commit(STORE_MODULES.ARTICLES + '/' + MUTATIONS_CONSTANTS.SET_NEWS_ACTIVE_CATEGORY, 0);
        }
      }

      this.filterNewsItems(this.newsActiveCategory);
    },

    getPostsFilterCategories() {
      this.isLoading = true;

      dataLoader.fetchPostsFilterCategories()
        .then(categories => {
          this.isLoading = false;
          this.articlesCategories = categories;
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
          this.isLoading = false;
        })
        .finally(() => {
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          }
        });
    },

    // Param selectedCategoryNumber emitted from prj1026-category-filter or prj1011-popup-article-menu
    filterNewsItems(selectedCategoryNumber) {
      this.$store.commit(STORE_MODULES.ARTICLES + '/' + MUTATIONS_CONSTANTS.SET_NEWS_ACTIVE_CATEGORY, selectedCategoryNumber);
      this.loadNewContent(false);
    },

    async loadNewContent(scrolled) {
      const filteredArticlesAmount = 20;
      let tempArray = [];

      if (!scrolled) {
        this.filteredArticlesOffset = 0;
        this.filteredArticles = [];
      }

      if (this.selectedBuilding === '') {
        return false;
      }

      const options = {
        categories: this.newsActiveCategory,
        offset: this.filteredArticlesOffset,
        amount: filteredArticlesAmount,
        loc: this.selectedBuilding,
        search: this.searchQuery
      };

      dataLoader.fetchPostsByCategories(options)
        .then(data => {
          tempArray = this.filteredArticles;
          this.filteredArticles = [...tempArray, ...data];

          this.$store.commit(STORE_MODULES.ARTICLES + '/' + MUTATIONS_CONSTANTS.SHOW_FILTERED_ARTICLES, true);
          this.$store.commit(STORE_MODULES.ARTICLES + '/' + MUTATIONS_CONSTANTS.ADD_FILTERED_ARTICLES, this.filteredArticles);

          if (data.length < filteredArticlesAmount) {
            this.noMoreArticles = true;
          }

          if (!data.length) {
            this.nothingToShow = true;
          }
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);

            return;
          }

          if (!this.hasFilteredArticles || !scrolled) {
            this.hasFilteredArticles = true;

            if (!this.noMoreArticles) {
              this.initInfinityScroll();
            }
          }

          this.isLoading = false;
          this.filteredArticlesOffset += filteredArticlesAmount;
        });
    },

    resetFilteredArticles() {
      this.hasFilteredArticles = false;
      this.filteredArticlesOffset = 0;

      this.$store.dispatch(STORE_MODULES.ARTICLES + '/' + ACTIONS_CONSTANTS.RESET_SELECTED_CATEGORIES);
      this.loadNewContent(false);
    },

    // Called from parent component via this.$refs
    scrollToTop() {
      this.$refs.articleListContainer.scrollTop = 0;
    },

    scroll(event) {
      if (this.$route.name === 'dashboard') {
        let scrollY = event.target.scrollTop;
        this.$emit('articleListContainerScroll', scrollY);
      }
    }
  }
}
</script>
