<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        ref="navbar"
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1024-item-list
        ref="comingList"
        :listData="coming"
        :listItemsCount="comingNumber"
        :noMoreItems="comingNoMore"
        :componentKey="componentKey"
        :listItemConfig="listItemConfigObject"
        :show-filter="false"
        all-coming
        @loadingComingList="startLoadingComingList"
        @loadedComingList="finishLoadingComingList"
        @openFilterDialog="openFilterDialog"
        @resetFilteredItems="resetItems"
        @scrollToTop="scrollToTop"
      />
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <prj1026-category-filter
        v-if="comingListInitialized && convertedFilterCategories.length"
        :filterCategories="convertedFilterCategories"
        :showItems="convertedFilterCategories.length"
        :showFilterButton="comingAreFiltersActive"
        :activeCategory="parseInt(comingActiveModule)"
        filterType="forvardino"
        @filterItems="filterItems"
        @resetFilteredItems="resetItems"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="!comingListInitialized || isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="hasError"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          <span>
            {{ $t('error.generic') }}
          </span>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import EVENT_BUS from '@/event-bus';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1024ItemList from '@/components/prj1024-item-list/prj1024-item-list';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter';

export default {
  name: 'ComingList',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1024ItemList,
    Prj1026CategoryFilter
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      coming: [],
      comingNumber: 0,
      comingNoMore: false,
      currentPosition: 0,
      componentKey: 0,
      listItemConfigObject: {
        displayDate: true,
        displayRating: true,
      },
      comingListInitialized: false,
      isLoading: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    ...mapState('comingActions', [
      'comingFilterCategories',
      'comingModules',
      'comingSelectedCategories',
      'comingAreFiltersActive',
      'comingActiveModule',
    ]),
    ...mapState('dashboard', [
      'selectedBuilding'
    ]),

    convertedFilterCategories() {
      let convertedFilterCategories = [];

      Object.values(this.comingFilterCategories.modules).forEach(item => {
        convertedFilterCategories.push({ 'termId': item.id, 'termName': item.name });
      })

      return convertedFilterCategories;
    },

    isInfinityScrollEnabled() {
      return this.comingNumber > 20 && !this.comingNoMore
    },

    pageId() {
      if (typeof this.$route.params.categoryId === 'undefined') {
        return 0;
      }

      return this.$route.params.categoryId;
    },

    navbarCaption() {
      return this.$route.meta.navbarCaption;
    },
  },

  watch: {
    comingAreFiltersActive(oldVal, newVal) {
      if (oldVal !== newVal && newVal === false) {
        this.fetchComingData();
      }
    }
  },

  mounted() {
    this.fetchComingData();

    EVENT_BUS.$on(GLOBAL_EVENTS.FILTER_ITEM_LIST, categoryId => {
      this.filterItems(categoryId);
    });

    this.setComingActiveCategory();
  },

  beforeDestroy() {
    EVENT_BUS.$off(GLOBAL_EVENTS.FILTER_ITEM_LIST);
  },

  methods: {
    fetchComingData() {
      this.componentKey++;
      this.currentPosition = 0;
      this.comingListInitialized = false;

      dataLoader.fetchComingFilter({type: this.comingActiveModule, offset: this.currentPosition, amount: 50})
        .then(response => {
          this.coming = this.convertComingData(response);
          this.comingListInitialized = true;
        })
        .catch(error => {
          this.comingListInitialized = false;
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          if (this.comingNumber > 20) {
            this.comingNoMore = false;
            this.$refs.comingList.initInfinityScroll();
          }
        });
    },

    convertComingData(data) {
      let tempObject = [];
      let counter = 0;

      data.forEach(item => {
        let itemObject = {
          name: item.name,
          id: item.id,
          actionId: item.actionId,
          nearestDate: item.nearestDate,
          image: item.featuredImage,
          module: item.module,
          score: item.score,
          target: item.target,
          categories: item.module === 'forvardino' ? [] : item.categories,
        };

        tempObject[counter] = itemObject
        counter++;
      });

      return tempObject;
    },

    setComingActiveCategory() {
      if (typeof this.$route.params.categoryId !== 'undefined') {
        this.$store.commit(STORE_MODULES.COMING_ACTIONS + '/' + MUTATIONS_CONSTANTS.SET_COMING_ACTIVE_MODULE, this.$route.params.categoryId);

        if (parseInt(this.$route.params.categoryId) !== 0) {
          this.filterItems(this.comingActiveModule);
        } else {
          this.resetItems();
        }
      }
    },

    startLoadingComingList() {
      this.comingListInitialized = false;
    },

    finishLoadingComingList() {
      this.comingListInitialized = true;
    },

    openFilterDialog() {
      this.$store.dispatch(STORE_MODULES.POPUP + '/' + ACTIONS_CONSTANTS.TOGGLE_POPUP, 'coming');
    },

    /**
     * @param moduleNumber number, $emitted from Prj1026CategoryFilter
     */
    filterItems(moduleNumber) {
      let selectedCategoryPayload = {
        modules: [moduleNumber]
      };

      this.$store.commit(STORE_MODULES.COMING_ACTIONS + '/' + MUTATIONS_CONSTANTS.SET_COMING_SELECTED_CATEGORIES, selectedCategoryPayload);
      this.$store.commit(STORE_MODULES.COMING_ACTIONS + '/' + MUTATIONS_CONSTANTS.SET_COMING_ACTIVE_MODULE, moduleNumber);

      this.fetchComingData();
    },

    resetItems() {
      this.$store.dispatch(STORE_MODULES.COMING_ACTIONS + '/' + ACTIONS_CONSTANTS.RESET_ALL_COMING_FILTER)
        .then(() => {
          this.fetchComingData();
        });
    },

    // FIXME: CSOBZ-515 - merge all scroll to top methods into one global method
    scrollToTop() {
      this.$nextTick(() => {
        this.scrollToElement();
      })
    },

    scrollToElement() {
      const ref = this.$refs.navbar.$el;

      if (ref) {
        ref.scrollIntoView(true);
      }
    },
  }
}
</script>
