import * as MUTATIONS_CONSTANTS from '../constants/mutations';
import * as ACTIONS_CONSTANTS from '../constants/actions';

const state = {
  newsActiveCategory: 0,
  showFilteredArticles: false,
  filteredArticles: {},
  searchQuery: '',
  detailData: {},
  isSearchActive: false,
  isSearchHistoryActive: false,
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_NEWS_ACTIVE_CATEGORY](state, activeCategory) {
    state.newsActiveCategory = activeCategory;
  },
  [MUTATIONS_CONSTANTS.SHOW_FILTERED_ARTICLES](state, showFilteredArticles) {
    state.showFilteredArticles = showFilteredArticles;
  },
  [MUTATIONS_CONSTANTS.ADD_FILTERED_ARTICLES](state, filteredArticles) {
    let tempObject = {};

    filteredArticles.forEach(article => {
      // FIXME MBU: CSOBZ-376 UNIFY NEWS API
      let articleObject = {
        categories: article.categories,
        commentsCount: article.commentsCount,
        commentsEnable: article.commentsEnable,
        createdTimestamp: article.created / 1000,
        content: article.content,
        excerpt: article.excerpt,
        featuredImage: {
          full: {
            src: article.featuredImage
          }
        },
        id: article.id,
        important: article.important,
        localities: article.localities,
        recommended: article.recommended,
        modifiedTimestamp: article.modified / 1000,
        title: article.title
      };

      tempObject[article.id] = articleObject
    });

    state.filteredArticles = tempObject;
  },
  [MUTATIONS_CONSTANTS.SET_SEARCH_QUERY](state, searchQuery) {
    state.searchQuery = searchQuery;
  },
  [MUTATIONS_CONSTANTS.SET_DETAIL_DATA](state, detailData) {
    state.detailData = detailData;
  },
  [MUTATIONS_CONSTANTS.SET_SEARCH_STATUS](state, status) {
    state.isSearchActive = status;
  },
  [MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS](state, status) {
    state.isSearchHistoryActive = status;
  },
};

const actions = {
  [ACTIONS_CONSTANTS.RESET_SELECTED_CATEGORIES]({ commit }) {
    commit(MUTATIONS_CONSTANTS.SET_NEWS_ACTIVE_CATEGORY, 0);
    commit(MUTATIONS_CONSTANTS.SHOW_FILTERED_ARTICLES, false);
    commit(MUTATIONS_CONSTANTS.ADD_FILTERED_ARTICLES, []);
  }
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
