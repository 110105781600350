<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== MISC
import config from '../../config';

//============ EXPORT ==================================//
//======================================================//
export default {
    props: {
        buttonClear: {
            default: () => {
                return config.globalInputButtonClear;
            },
            type: Boolean
        }
    },
    computed: {
        classObjectMixinButtonClear() {
            return [
                {
                    'vue-has-button-clear': this.hasButtonClear
                }
            ];
        },
        hasButtonClear() {
            return (
                !this.disabled &&
                !this.readonly &&
                this.buttonClear &&
                this.valueData !== '' &&
                this.placeholder &&
                this.placeholderSelectable
            );
        }
    },
    watch: {
        hasButtonClear() {
            // white list must be recomputed with delay, because if not, tooltip whitelist would be recomputed
            // instantly in tooltip after click on button, and it would stop existing, so click outside would be triggered
            // nextTick is not working here for some unknown reason
            if (this.tooltipWhiteListInitialInit) {
                let component = this;
                setTimeout(function() {
                    component.setTooltipWhiteListInitial();
                }, 0);
            }
        }
    },
    methods: {
        buttonClearClick() {
            this.$emit('buttonClearClickEvent');
            this.valueData = '';
        }
    }
};
</script>
