//=============== USER =================================//
//======================================================//
import {
  SET_USER_NAME,
  SET_USER_EMAIL,
  SET_USER_POSITION,
  SET_USER_IMAGE,
  SET_USER_TOKEN,
  SET_USER_TOKEN_EXPIRATION,
  SET_USER_TOKEN_REFRESH,
  SET_USER_REGISTERED,
  SET_USER_TRANSPORT,
  SET_USER_VOTED_BANDS,
  SET_USER_VOTED_FOOD,
  SET_USER_EMERGENCY_CONSENT
} from '@/store/constants/fest/mutations'
import {
  UPDATE_USER_TOKEN_REFRESH,
  GET_USER_FESTIVAL_DATA, DELETE_USER_FESTIVAL_DATA
} from '@/store/constants/fest/actions'
import apiCmsUser from '@/fest-api/cms-user';
import logger from '@/utils/logger';

//============ STATE ===================================//
//======================================================//
const state = {
  name: null,
  email: null,
  position: null,
  image: null,
  token: null,
  tokenExpiration: null,
  isRefreshToken: false,
  registered: null,
  transport: null,
  votedBands: null,
  votedFood: null,
  emergencyConsent: null
}

//============ MUTATIONS ===============================//
//======================================================//
const mutations = {
  [SET_USER_NAME](state, payload) {
    state.name = payload
  },
  [SET_USER_EMAIL](state, payload) {
    state.email = payload
  },
  [SET_USER_POSITION](state, payload) {
    state.position = payload
  },
  [SET_USER_IMAGE](state, payload) {
    state.image = payload
  },
  [SET_USER_TOKEN](state, payload) {
    state.token = payload
  },
  [SET_USER_TOKEN_EXPIRATION](state, payload) {
    state.tokenExpiration = payload
  },
  [SET_USER_TOKEN_REFRESH](state, payload) {
    state.isRefreshToken = payload
  },
  [SET_USER_REGISTERED](state, payload) {
    state.registered = payload
  },
  [SET_USER_TRANSPORT](state, payload) {
    state.transport = payload
  },
  [SET_USER_VOTED_BANDS](state, payload) {
    state.votedBands = payload
  },
  [SET_USER_VOTED_FOOD](state, payload) {
    state.votedFood = payload
  },
  [SET_USER_EMERGENCY_CONSENT](state, payload) {
    state.emergencyConsent = payload
  },
}

//============ ACTIONS =================================//
//======================================================//

const actions = {
  [UPDATE_USER_TOKEN_REFRESH]({ commit }, value) {
    commit(SET_USER_TOKEN_REFRESH, value)
  },
  [DELETE_USER_FESTIVAL_DATA]({ commit }) {
    commit(SET_USER_REGISTERED, null)
    commit(SET_USER_TRANSPORT, null)
    commit(SET_USER_VOTED_BANDS, null)
    commit(SET_USER_VOTED_FOOD, null)
    commit(SET_USER_EMERGENCY_CONSENT, null)
  },
  async [GET_USER_FESTIVAL_DATA]({ commit }) {
    try {
      const response = await apiCmsUser.getRegistration()
      commit(SET_USER_REGISTERED, response.data.registered)
      commit(SET_USER_TRANSPORT, response.data.transport)
      commit(SET_USER_VOTED_BANDS, response.data.votedBands)
      commit(SET_USER_VOTED_FOOD, response.data.votedFood)
    } catch (error) {
      logger.error(error)
    }
  },
}

//============ GETTERS =================================//
//======================================================//

const getters = {
  isUserIdentityPartial: state => {
    return !!state.token && !!state.email
  },
  isUserIdentityFull: state => {
    return !!state.name && !!state.token && !!state.email
  },
  hasFestivalRegistered: state => {
    return state.registered === true;
  },
  registeredTransform: state => {
    return state.transport;
  },
  festivalVoted: state => {
    return {
      bands: Array.isArray(state.votedBands) ? state.votedBands.length > 0 : false,
      food: Array.isArray(state.votedFood) ? state.votedFood.length > 0 : false
    }
  },
  hasEmergencyConsent: state => {
    return state.emergencyConsent;
  },
}

//============ EXPORT ==================================//
//======================================================//
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
