<script type="application/javascript">
export default {
  computed: {
    navbarCaption() {
      return this.$route.meta.navbarCaption;
    },

    navbarBuilding() {
      return this.$route.meta.navbarBuilding;
    },

    navbarButtonType() {
      return this.$route.meta.navbarButton;
    },

    navbarButtonMode() {
      return this.$route.meta.navbarButtonMode;
    },

    navbarButtonDestination() {
      return this.$route.meta.navbarButtonDestination;
    }
  }
}
</script>
