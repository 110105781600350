<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <prj1004-article-body
          v-if="restaurantsHelpPost"
          :articleHeading="false"
          :articleData="restaurantsHelpPost"
        />
        <gen1006-info-panel
          v-else
          type="error"
        >
          {{ $t('general.articleNonexistent') }}
        </gen1006-info-panel>
        <gen1006-info-panel
          v-if="!isLoading && !initialized"
          type="neutral"
        >
          {{ $t('general.loadingFailed') }}
        </gen1006-info-panel>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';
import { mapState } from 'vuex';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1004ArticleBody from '@/components/prj1004-article-detail/prj1004-article-body';
import {RESTAURANTS_KEY_PREFIX} from '@/constants/app-constants';
import zapUtilsGeneral from '@/utils/utils-general';

export default {
  name: 'RestaureantInfo',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1004ArticleBody
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      restaurantsHelpPost: {}
    };
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),

    restaurantsConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(RESTAURANTS_KEY_PREFIX)) {
            const itemNameWithoutPrefix = item.key.replace(RESTAURANTS_KEY_PREFIX, '');
            const itemName = zapUtilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },

    pageId() {
      return (typeof this.restaurantsConfig?.helpPageId !== 'undefined' ) ? this.restaurantsConfig.helpPageId : 0;
    },

    pageCaption() {
      return this.$route.meta.navbarCaption;
    },
  },

  watch: {
    pageId() {
      this.fetchReastarantsHelpPage();
    },

    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        if (this.articleData) {
          this.$refs.pageLayout.pageAsyncDataLoadedDone();
        }
      }
    },
  },

  mounted() {
    this.fetchReastarantsHelpPage();
    this.initialized = true;
  },

  methods: {
    fetchReastarantsHelpPage() {
      dataLoader.fetchPostById(this.pageId)
        .then(data => {
          this.restaurantsHelpPost = data[0]
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
    },
  }
}
</script>
