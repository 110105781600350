<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1007-food-detail
        v-if="typeof selectedMeal !== 'undefined' && selectedMealHasName"
        :selectedMeal="selectedMeal"
        :restaurantsToday="restaurantsToday"
        @openedRatingModal="hideFooter"
        @closedRatingModal="showFooter"
      />
    </template>

    <template
      v-if="footerVisibility"
      v-slot:fixed
    >
      <prj1039-item-slider
        :availableItems="availableMeals"
        :position="position"
        :itemsCount="availableMeals.length - 1"
        :activeId="selectedMealId"
        @moveForward="slideForward"
        @moveBackward="slideBackward"
        @goToList="goToList"
      />
    </template>
  </page-layout>
</template>

<script>
import * as APP_CONSTANTS from '@/constants/app-constants';
import { mapState } from 'vuex';
import router from '@/router';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1007FoodDetail from '@/components/prj1007-food-detail/prj1007-food-detail';
import Prj1039ItemSlider from '@/components/prj1039-item-slider/prj1039-item-slider';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

export default {
  name: 'RestaurantDetail',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1007FoodDetail,
    Prj1039ItemSlider
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      position: parseInt(this.$route.params.id),
      footerVisibility: true,
      directionBackward: false
    }
  },

  computed: {
    ...mapState('dataStore', {
      restaurantsData: state => state.contentData.restaurants.facilities
    }),
    ...mapState('restaurants', [
      'selectedRestaurant'
    ]),
    ...mapState('persistentStorage', [
      'yearAndWeek',
      'lastFoodRatingTimestamp'
    ]),

    todayIndex() {
      const correctionalConstantForDate = 1;

      return new Date().getDay() - correctionalConstantForDate;
    },

    restaurantsToday() {
      let restaurantCode = 'restaurantNHQ';
      if (this.selectedRestaurant === APP_CONSTANTS.RESTAURANT_SHQ) {
        restaurantCode = 'restaurantSHQ';
      } else if (this.selectedRestaurant === APP_CONSTANTS.RESTAURANT_HHQ) {
        restaurantCode = 'restaurantHHQ';
      } else if (this.selectedRestaurant === APP_CONSTANTS.RESTAURANT_PHQ) {
        restaurantCode = 'restaurantPHQ';
      }

      return this.restaurantsData[restaurantCode].data[this.yearAndWeek].content[this.todayIndex];
    },

    availableMeals() {
      const categories = this.restaurantsToday.categories;
      let availableMeals = [];

      categories.forEach((category, index) => {
        if (category && typeof category.name === 'string' && category.name.length) {
          availableMeals.push(index);
        }
      })

      return availableMeals;
    },

    currentPosition() {
      return this.availableMeals.indexOf(this.selectedMealId)
    },

    selectedMealId() {
      return parseInt(this.$route.params.id);
    },

    selectedMeal() {
      return this.restaurantsToday.categories[this.selectedMealId];
    },

    selectedMealHasName() {
      return typeof this.selectedMeal.name === 'string' && this.selectedMeal.name.length > 0
    }
  },

  watch: {
    position() {
      if (!this.selectedMealHasName) {
        if (this.directionBackward) {
          this.position--;
        } else {
          this.position++;
        }

        this.goToPage();
      }
    }
  },

  methods: {
    slideForward() {
      const correctionConstantForPosition = 1;
      const availableMealsLength = this.availableMeals.length;
      this.directionBackward = false;

      if (this.selectedMealId < this.availableMeals[availableMealsLength] - correctionConstantForPosition) {
        return;
      }

      this.position++;
      this.goToPage();
    },

    slideBackward() {
      this.directionBackward = true;

      if (this.position === 0) {
        return
      }

      this.position--;
      this.goToPage();
    },

    goToPage() {
      router.push({ name: 'restaurants-detail', params: { id: this.position } });
    },

    goToList() {
      router.push({ name: 'restaurants' });
    },

    hideFooter() {
      this.footerVisibility = false;
    },

    showFooter() {
      this.footerVisibility = true;
    }
  }
}
</script>
