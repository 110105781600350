<template>
  <div
    v-show="isVisible"
    class="vue-c-navbar-profile"
    :class="{ 'vue-is-clickable': hasIdentity,
              'vue-has-dropdown-open': isProfileMenuOpened
    }"
    @click="toggleMenu"
  >
    <template v-if="loggingIn">
      <gen1016-loading-indicator />
    </template>
    <template v-else>
      <div
        class="vue-navigation-avatar"
        :class="avatarClassObject"
      >
        <div
          v-if="hasProfilePhoto"
          class="vue-photo"
          :style="styleObject"
        />
      </div>
    </template>
    <transition
      name="vue-anim-move-from-top"
      appear
    >
      <div
        v-if="isProfileMenuOpened && isLogged"
        class="vue-b-dropdown-menu"
        :class="dropdownMenuClassObject"
      >
        <div
          v-if="isPrivate"
          class="vue-private-label"
        >
          <span>{{ $t('menu.labels.private') }}</span>
        </div>

        <Frm1006Button
          type="internal"
          :icon-left="true"
          class="vue-dropdown-item vue-icon vue-is-profile vue-user-name"
          @buttonClickEvent="goToProfile()"
        >
          {{ profileName }}<br>
          <small v-if="isPrivate">{{ profileAccountName }}</small>
        </Frm1006Button>

        <Frm1006Button
          type="internal"
          :icon-left="true"
          class="vue-dropdown-item vue-icon vue-is-edit"
          @buttonClickEvent="goToProfile()"
        >
          <span>{{ $t('profile.linkEditProfile') }}</span>
        </Frm1006Button>

        <Frm1006Button
          type="internal"
          :icon-left="true"
          class="vue-dropdown-item vue-icon vue-is-settings"
          @buttonClickEvent="goToSettings()"
        >
          {{ $t('navigation.settings') }}
        </Frm1006Button>

        <div class="vue-dropdown-divider"></div>

        <Frm1006Button
          type="internal"
          :icon-left="true"
          class="vue-dropdown-item vue-icon vue-is-logout"
          @buttonClickEvent="goToLogout()"
        >
          {{ $t('userSettings.logout') }}
        </Frm1006Button>
      </div>
    </transition>

    <transition
      name="vue-anim-fade"
      appear
    >
      <div
        v-if="isProfileMenuOpened && isLogged"
        class="vue-navbar-overlay"
      />
    </transition>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import router from '@/router';
import {FEST_USER} from '@/store/store-modules';
import {DELETE_USER_FESTIVAL_DATA} from '@/store/constants/fest/actions';
import {APP_DESTINATIONS} from '@/constants/general';

import mxDetectDesktop from '@/mixins/mx-detect-desktop';

export default {
  name: 'Prj1002NavbarProfile',

  mixins: [mxDetectDesktop],

  data() {
    return {
      loggingIn: false
    }
  },

  computed: {
    ...mapState('general', [
      'appDestination'
    ]),
    ...mapState('user', [
      'amUserProfile',
      'profile',
      'isLogged',
      'isProfileMenuOpened'
    ]),

    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY
    }),

    isAnonymousMode() {
      return (!this.profile || this.profile.anonymous);
    },

    profileName() {
      return this.profile.name ? this.profile.name : this.profile.workEmail;
    },

    isPrivate() {
      return this.appDestination === APP_DESTINATIONS.PRIVATE;
    },

    profileAccountName() {
      return this.isPrivate ? this.amUserProfile?.samAccountName : '';
    },

    hasProfilePhoto() {
      return this.profile.photoUrl;
    },

    profilePhotoUrl() {
      return this.profile.photoUrl;
    },

    styleObject() {
      if (this.hasProfilePhoto) {
        return {
          'background-image': 'url("' + this.profilePhotoUrl + '")'
        }
      } else {
        return false;
      }
    },

    avatarClassObject() {
      return {
        'vue-is-private': this.isPrivate,
      }
    },

    dropdownMenuClassObject() {
      return {
        'vue-is-active': this.isProfileMenuOpened
      }
    },

    currentRoute() {
      return this.$route.name;
    },

    isVisible() {
      return this.isDesktopLayout || ['dashboard'].includes(this.currentRoute);
    }
  },

  methods: {
    hexToBase64(str) {
      return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' ')));
    },

    toggleMenu() {
      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.TOGGLE_PROFILE_MENU);
    },

    goToProfile() {
      if (!this.hasIdentity) {
        return false;
      }

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
      router.push({ name: 'profile' });
    },

    goToSettings() {
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
      router.push({ name: 'user-settings' });
    },

    goToLogout() {
      if (!this.hasIdentity) {
        return false;
      }

      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.LOGOUT_USER)
        .then(() => {
          this.$store.dispatch(FEST_USER + '/' + DELETE_USER_FESTIVAL_DATA);
        })
        .finally(() => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
          window.localStorage.removeItem('zapka-localstorage');
          this.$router.push({ name: 'login', params: { autologin: false }});
        });
    }
  }
}
</script>
