import Frm1035FormGroup from './frm1035-form-group';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1035FormGroup);
    }
};

use(Plugin);

export default Plugin;

export { Frm1035FormGroup };
