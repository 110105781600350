<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    ref="floorMap"
    class="vue-component vue-c-floor"
    :class="classObject"
    @click="floorClickEmit"
  >
    <div
      ref="floorMapWrapper"
      class="vue-b-floor-map"
    >
      <div
        ref="floorMapScaler"
        class="vue-scaler"
      >
        <div
          ref="floorMapTranslator"
          class="vue-floor-translator"
        >
          <div class="vue-floor-rotor">
            <div
              ref="floorSvgPlacement"
              class="vue-floor-svg-placement"
            />
          </div>
        </div>
      </div>
      <div
        v-if="devMode"
        class="test"
      />
    </div>

    <gen1016-loading-indicator
      :active="isLoading"
      :overlay="true"
      :fullScreen="true"
    />

    <gen1009-overlay
      class="vue-is-info-panel"
      :active.sync="hasError"
      :buttonClose="true"
      :closeAfter="ERROR_MODAL_TIMEOUT"
    >
      <gen1006-info-panel
        type="error"
      >
        {{ $t('error.generic') }}
      </gen1006-info-panel>
    </gen1009-overlay>
  </div>
</template>

<script type="application/javascript">
import EVENT_BUS from '@/event-bus';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import { mapState } from 'vuex';
import mxTransform from '@/mixins/mx-transform';
import mxDetectDesktop from '../../mixins/mx-detect-desktop';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';

let options = {
  cssSelectors: {
    floorMapSvgPlacement: 'vue-floor-svg-placement'
  },
  cssClasses: {
    selected: 'vue-is-selected'
  },
  zoomLevelOnSelect: 5
};

const COMPONENT_EVENTS = Object.freeze({
  FLOOR_CLICK_EVENT: 'floorClickEvent'
})

export default {
  name: 'Prj1009MapFloor',
  components: {},
  mixins: [
    mxTransform,
    mxDetectDesktop
  ],
  props: {
    'mapData': {
      default: null,
      type: Object
    },
    'floor': {
      type: Object,
      required: true
    },
    'buildingActiveId': {
      default: null,
      type: String
    },
    'floorActiveId': {
      default: null,
      type: String
    },
    'itemActiveId': {
      default: null,
      type: String
    },
    'buildingsSelectedId': {
      default: null,
      type: Array
    },
    'floorsSelectedId': {
      default: null,
      type: Array
    },
    'roomsSelectedId': {
      default: null,
      type: Array
    },
    'id': {
      default: null,
      type: String
    },
    'centerMap': {
      default: false,
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      // new ones
      elements: {
        svgImage: null,
        roomsGroup: null,
      },
      svgViewBox: null,
      transformMultiplier: null,
      scaleRoomToViewportRatio: 5, // for example 3 means one third of viewport
      viewPortWidth: 0,
      viewPortHeight: 0,
      scale: 1,
      scaleDefault: 1,
      minScale: 0.001,
      maxScale: 0.5,
      scaleStep: 0.05,
      rotation: 0,
      lastCenter: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        scale: 1
      },
      rotationCenterPoint: {
        x: 0,
        y: 0
      },
      translate: {
        x: 0,
        y: 0
      },
      touchActiveRegion: null,
      performanceScaling: {
        scaleThreshold: 40,
        pinchThreshold: 0.05,
      },
      devMode: false,
      floorSvgViewer: null,
      roomsSelected: [],
      translateX: 0,
      translateY: 0,
      rotateA: 0,
      rotateX: 0,
      rotateY: 0,
      scaleAdditional: 1,
      isLoading: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      showPrinters: false,
    }
  },
  computed: {
    ...mapState('general', [
      'viewportSize'
    ]),
    ...mapState('persistentStorage', [
      'maps'
    ]),
    translateStep() {
      return 10 / this.scale;
    },

    scaleCenterPointX() {
      return this.viewPortWidth / 2
    },

    scaleCenterPointY() {
      return this.viewPortHeight / 2
    },

    classObject() {
      return {
        'vue-is-active': this.active,
        'vue-is-selected': this.selected,
        'vue-has-labels': this.visibilityLabels()
      }
    },

    roomsOnFloorSelectedId() {
      let roomsOnFloorSelectedId = [];
      for (let roomId in this.floor.rooms) {
        if (this.roomsSelectedId.indexOf(roomId) !== -1) {
          roomsOnFloorSelectedId.push(roomId);
        } else { // if showPrinter is active, select also all printers on the floor
          if (this.showPrinters && (roomId.includes('-A3_') || roomId.includes('-A4_'))) {
            roomsOnFloorSelectedId.push(roomId);
          }
        }
      }

      return roomsOnFloorSelectedId;
    },

    active() {
      return this.floor.id === this.floorActiveId;
    },

    selected() {
      let selected = false;

      for (let i = 0; i < this.floorsSelectedId.length; i++) {
        if (this.floor.id === this.floorsSelectedId[i]) {
          selected = true;
          break;
        }
      }

      return selected;
    },

    buildingSvgId() {
      return this.mapData[this.floor.buildingId].svgId;
    }
  },

  watch: {
    active() {
      if (this.active) {
        this.$nextTick(() => {
          if (this.roomsOnFloorSelectedId.length > 0) {
            this.unselectRooms();
            this.selectRooms();
          } else {
            this.setScale(this.scaleDefault); // always reset scale
            let roomsGroupBBox = this.elements.roomsGroup.getBBox();
            let offset = this.elements.svgImage.getBoundingClientRect();
            let mapCenterPoint = {x: roomsGroupBBox.width / 2 + offset.x, y: roomsGroupBBox.height / 2 + offset.y};
            this.lastCenter.x = mapCenterPoint.x;
            this.lastCenter.y = mapCenterPoint.y;
            this.lastCenter.width = 0;
            this.lastCenter.height = 0;
            this.lastCenter.scale = this.scale;
            this.setCenter(this.lastCenter, this.lastCenter.width, this.lastCenter.height);
          }
        });
      }
    },

    roomsOnFloorSelectedId() {
      this.unselectRooms();
      this.selectRooms();
    },

    itemActiveId() {
      this.showPrinters = false;
    }
  },

  mounted() {
    EVENT_BUS.$on(GLOBAL_EVENTS.MAP_RESET_POSITION_EVENT, () => {
      this.setCenter(this.lastCenter, this.lastCenter.width, this.lastCenter.height);
      this.setScale(this.lastCenter.scale);
    });

    EVENT_BUS.$on(GLOBAL_EVENTS.MAP_ZOOM_IN_EVENT, () => {
      let scaleNew = this.scale * 1.2;
      this.setScale(scaleNew);
    });

    EVENT_BUS.$on(GLOBAL_EVENTS.MAP_ZOOM_OUT_EVENT, () => {
      let scaleNew = this.scale * 0.8;
      this.setScale(scaleNew);
    });

    EVENT_BUS.$on(GLOBAL_EVENTS.MAP_SHOW_PRINTERS_EVENT, () => {
      this.showPrinters = !this.showPrinters;
    });

    // FIXME: temporary page area. Use ref floorMapWrapper, but fix calculation display none issue before
    // let pageArea = document.getElementsByClassName('vue-page-content')[0];
    // let pageArea = this.$refs.floorMap;
    this.viewPortWidth = this.viewportSize.width;
    this.viewPortHeight = this.viewportSize.height;

    this.fetchData();
  },

  beforeDestroy() {
    EVENT_BUS.$off(GLOBAL_EVENTS.MAP_RESET_POSITION_EVENT);
  },

  methods: {
    updateScale(delta, ignoreLimits) {
      // do not allow negative scale
      let isBellowMinScale = delta < 0 && this.scale - Math.abs(delta) < this.minScale;
      let isOverMaxScale = delta > 0 && this.scale + delta > this.maxScale;

      if (isBellowMinScale && !ignoreLimits) {
        this.scale = this.minScale;
        delta = 0;
      }

      if (isOverMaxScale && !ignoreLimits) {
        this.scale = this.maxScale;
        delta = 0;
      }

      this.scale += delta;

      if (typeof this.$refs.floorMapScaler !== 'undefined') {
        this.$refs.floorMapScaler.style.transformOrigin = `${this.scaleCenterPointX}px ${this.scaleCenterPointY}px`;
        this.$refs.floorMapScaler.style.transform = `scale(${this.scale})`;
      }

      // update transform coords accordingly
      let diffX = (delta * (this.translate.x)) / this.scale;
      let diffY = (delta * (this.translate.y)) / this.scale;

      this.translate.x -= diffX;
      this.translate.y -= diffY;
    },

    setScale(value, ignoreLimits) {
      let delta = value - this.scale;

      this.updateScale(delta, ignoreLimits);
    },

    updateTranslate(x, y) {
      this.translate.x += x / this.scale;
      this.translate.y += y / this.scale;
      this.applyTranslate();
    },

    applyTranslate() {
      let translateX = this.translate.x * this.scale;
      let translateY = this.translate.y * this.scale;

      this.$refs.floorMapTranslator.style.transform = `translate(${-translateX}px, ${-translateY}px)`;
    },

    initScale() {
      this.scaleDefault = 1 / this.transformMultiplier.max;
      this.minScale = this.scaleDefault;
      this.scaleStep = Math.round(this.viewPortWidth / this.svgViewBox.width * 1000) / 10000;
      this.setScale(this.scaleDefault, true);
    },

    initTouchEvents() {
      this.initActiveRegion()
        .then(() => {
          this.initPanEvent();
          this.initPinchEvent();
        })
    },

    initActiveRegion() {
      const makeTouchRegion = new Promise ((resolve) => {
        this.touchActiveRegion = ZingTouch.Region(this.$refs.floorMap); // eslint-disable-line
        resolve();
      })

      return makeTouchRegion;
    },

    initPanEvent() {
      this.touchActiveRegion.bind(this.$refs.floorMapWrapper, 'pan', (event) => {
        let change = event.detail.data[0].change;

        let changeX = -1 / this.scale * change.x;
        let changeY = -1 / this.scale * change.y;

        this.updateTranslate(changeX, changeY);
      }, {
        threshold: 50
      });
    },

    initPinchEvent() {
      let pinchScaleBeforeStart = null;
      let heightBeforeScale = null;
      let distanceInitial = null;

      // TODO: remove es-lint disable when ZingTouch will be imported from NPM
      let customDistance = new ZingTouch.Distance({ // eslint-disable-line
        threshold: 1
      });

      // OVERRIDE DEFAULT CALLBACKS
      // ==========================================================
      let startDistanceOriginal = customDistance.start;
      let endDistanceOriginal = customDistance.end;
      let floor = this;

      customDistance.start = function() {
        pinchScaleBeforeStart = floor.scale;
        heightBeforeScale = floor.svgViewBox.height * floor.scale;
        return startDistanceOriginal.apply(this, arguments);
      };

      customDistance.end = function() {
        pinchScaleBeforeStart = null;
        heightBeforeScale = null;
        distanceInitial = null;
        return endDistanceOriginal.apply(this, arguments);
      };

      this.touchActiveRegion.bind(this.$refs.floorMapWrapper, customDistance, (event) => {
        if(distanceInitial === null) {
          distanceInitial = event.detail.distance;
        }

        if(pinchScaleBeforeStart && heightBeforeScale) {
          let scaleNew = pinchScaleBeforeStart * Math.abs(event.detail.distance / distanceInitial);
          this.setScale(scaleNew);
        }
      });
    },

    convertRoomCoords(x, y, room) {
      let offset = this.elements.svgImage.getBoundingClientRect();
      let matrix = room.getScreenCTM();

      return {
        x: ((matrix.a * x) + (matrix.c * y) + matrix.e) - offset.left,
        y: ((matrix.b * x) + (matrix.d * y) + matrix.f) - offset.top
      };
    },

    getZoomPoint() {
      let currentScale = this.scale;
      if(currentScale !== 1) {
        this.setScale(1, true);
      }

      let minX=99999, maxX=0, minY=99999, maxY=0;
      this.roomsSelected.forEach((room) => {
        let roomBBox = room.getBBox();
        let roomCoords = this.convertRoomCoords(roomBBox.x, roomBBox.y, room);
        minX = Math.min(minX, roomCoords.x);
        minY = Math.min(minY, roomCoords.y);
        maxX = Math.max(maxX, roomCoords.x + roomBBox.width);
        maxY = Math.max(maxY, roomCoords.y + roomBBox.height);
      })

      if(currentScale !== 1) {
        this.setScale(currentScale);
      }

      return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
      }
    },

    getRotatedCoord(x, y, midpointX, midpointY, angle) {
      let cos = Math.cos;
      let sin = Math.sin;
      let angleRad = angle * Math.PI / 180;

      let xr = (x - midpointX) * cos(angleRad) - (y - midpointY) * sin(angleRad) + midpointX;
      let yr = (x - midpointX) * sin(angleRad) + (y - midpointY) * cos(angleRad) + midpointY;

      return {
        x: xr,
        y: yr
      }
    },

    setCenter(centerCoords, width, height) {
      let centerPointCompensation = {
        x: (this.viewPortWidth / 2 - this.scaleCenterPointX),
        y: (this.viewPortHeight / 2 - this.scaleCenterPointY)
      };

      let moveToX = (Math.abs(centerCoords.x) - this.viewPortWidth / 2  + (width / 2)) / this.scale;
      let moveToY = (Math.abs(centerCoords.y) - this.viewPortHeight / 2 + (height / 2)) / this.scale;

      let rotatedCoord = this.getRotatedCoord(moveToX, moveToY, 0, 0, this.rotation);
      moveToX = rotatedCoord.x;
      moveToY = rotatedCoord.y;

      if (this.isDesktopLayout) {
        let mapArea = this.$refs.floorMap;

        moveToX = (Math.abs(centerCoords.x) - mapArea.clientWidth / 2  + (width / 2) + 280) / this.scale;
        moveToY = (Math.abs(centerCoords.y) - mapArea.clientHeight / 2 + (height / 2) + 280) / this.scale;
      }

      this.translate = {
        x: moveToX + centerPointCompensation.x, // TODO: can be simplified probably with calculation above
        y: moveToY + centerPointCompensation.y  // TODO: can be simplified probably with calculation above
      };
      this.applyTranslate();
    },

    initTransformState() {
      this.applyTranslate();
    },

    fetchData() {
      this.isLoading = true;

      let floorId = this.floor.id;
      logger.info('Fetched map');
      return dataLoader.fetchMap(floorId)
        .then(data => {
          this.floorSvgImage = data;
          this.reinitMap();
        })
        .catch(error => {
          logger.error(error);

          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.initTouchEvents();
          this.isLoading = false;
          this.mapCentering();
        });
    },

    reinitMap() {
      this.renderFloorSvg(); // once svg is downloaded render it in DOM
      this.calculateTransformMultiplier(this.elements.svgImage);
      this.initScale();
      this.initTransformState();
      this.unselectRooms(); // reset all locations in case there are any left when map mode changes
      this.selectRooms(); // set initially set data on mounted
    },

    renderFloorSvg() {
      let floorMapWrapper = this.$refs.floorSvgPlacement;

      floorMapWrapper.insertAdjacentHTML('beforeend', this.floorSvgImage);
      this.elements.svgImage = floorMapWrapper.getElementsByTagName('svg')[0];
      this.elements.roomsGroup = this.elements.svgImage.getElementsByClassName('vue-floor-svg-viewer')[0];
      this.roomsGroupBBox = this.elements.roomsGroup.getBBox();
      this.svgViewBox = this.parseViewbox(this.elements.svgImage.getAttribute('viewBox'));
      this.elements.svgImage.setAttribute('width', this.svgViewBox.width);
      this.elements.svgImage.setAttribute('height', this.svgViewBox.height);
    },

    unselectRooms() {
      if (this.roomsSelected.length > 0) {
        for (let i = 0; i < this.roomsSelected.length; i++) {
          if (this.roomsSelected[i].classList !== null) {
            this.roomsSelected[i].classList.remove(options.cssClasses.selected);
          }
        }

        this.roomsSelected = [];
      }
    },

    selectRooms() {
      if (this.roomsOnFloorSelectedId.length > 0) {
        for (let i = 0; i < this.roomsOnFloorSelectedId.length; i++) {
          let room = this.floor.rooms[this.roomsOnFloorSelectedId[i]];
          let roomToSelect = null;

          // do not activate room if it is not defined in floor data
          if (room !== undefined) {
            let roomToSelectId = this.buildingSvgId + ';' + this.floor.svgId + ';' + room.svgId;
            roomToSelect = document.getElementById(roomToSelectId);
          }

          if (roomToSelect !== null) {
            this.roomsSelected.push(roomToSelect);
            this.roomsSelected[i].classList.add(options.cssClasses.selected);
          }
        }
        this.zoomToSelectedRooms();
      }
    },

    zoomToSelectedRooms() {
      let zoomPoint = this.getZoomPoint();

      let desiredDownscale = this.viewPortWidth / this.scaleRoomToViewportRatio;
      let calculatedScale = desiredDownscale / zoomPoint.width;

      if (Number.isFinite(calculatedScale)) {
        this.setScale(calculatedScale);
      }

      this.lastCenter.x = zoomPoint.x;
      this.lastCenter.y = zoomPoint.y;
      this.lastCenter.width = zoomPoint.width;
      this.lastCenter.height = zoomPoint.height;
      this.lastCenter.scale = this.scale;

      this.setCenter(this.lastCenter, this.lastCenter.width, this.lastCenter.height);
    },

    floorClickEmit() {
      this.$emit(COMPONENT_EVENTS.FLOOR_CLICK_EVENT, this.floor.buildingId, this.floor.id);
    },

    visibilityLabels() {
      if ((this.buildingActiveId === 'NHQ') && (this.scale >= 0.02)) {
        return true;
      }
      if ((this.buildingActiveId === 'SHQ') && (this.scale >= 0.25)) {
        return true;
      }
      if ((this.buildingActiveId === 'HHQ') && (this.scale >= 0.4)) {
        return true;
      }
      return false;
    },

    mapCentering() {
      this.setScale(this.scaleDefault); // always reset scale
      let roomsGroupBBox = this.elements.roomsGroup.getBBox();
      let offset = this.elements.svgImage.getBoundingClientRect();
      let mapCenterPoint = {x: roomsGroupBBox.width / 2 + offset.x, y: roomsGroupBBox.height / 2 + offset.y};
      this.lastCenter.x = mapCenterPoint.x;
      this.lastCenter.y = mapCenterPoint.y;
      this.lastCenter.width = 0;
      this.lastCenter.height = 0;
      this.lastCenter.scale = this.scale;
      this.setCenter(this.lastCenter, this.lastCenter.width, this.lastCenter.height);
    }
  }
}
</script>
