import axios from 'axios';
import axiosConfig from '@/utils/data-loader/axiosConfig'
import { setupCache } from 'axios-cache-adapter'
import * as envConfigAuthorizedMode from 'ext-authorized-mode-env-config';
import * as envConfig from 'env-config';

const cache = setupCache({
  maxAge: 5 * 60 * 1000,
  exclude: { query: false },
  invalidate: async (cfg, req) => {
    const method = req.method.toLowerCase();
    if (method !== 'get') {
      await cfg.store.iterate(async (_item, uuid) => {
        if (uuid.match(/\/parking\/reservations/) || uuid.match(/\/parking\/capacities/)) {
          await cfg.store.removeItem(uuid);
        }
      });
    }
  },
  debug: false
});

const api = axios.create({
  adapter: cache.adapter
});

const authorizedModeDataLoaders = {
  fetchUserProfile() {
    return fetch(envConfigAuthorizedMode.PRIVATE_USER_PROFILE,{mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  fetchUserPublicAuthorize(deviceId) {
    return fetch(envConfigAuthorizedMode.PRIVATE_USER_PUBLIC_AUTHORIZE + '?deviceId=' + deviceId, {method: 'POST', mode: globalConfig.ENV_FETCH_MODE})
      .then((resp) => {
        return resp.json()
          .then((data) => {
            const refreshToken=data.refreshToken;
            this.logCustomInfo(deviceId, 'fetchUserPublicAuthorize', 'debug1', refreshToken);
            let formData = new FormData();
            formData.append('deviceId', deviceId);
            formData.append('refreshToken', refreshToken);

            let options = {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
            return axiosConfig.post(envConfig.default.webServices.CMS_IDENTITY_AUTHORIZATION, formData, options)
              .then(response => response.data)
              .catch((error) => {
                this.logCustomInfo(deviceId, 'fetchUserPublicAuthorize', 'catch2', error);
                return Promise.reject(error);
              })
          })
      })
      .catch((error) => {
        this.logCustomInfo(deviceId, 'fetchUserPublicAuthorize', 'catch1', error);
        return Promise.reject(error);
      })
  },

  fetchUserOrgContacts() {
    return fetch(envConfigAuthorizedMode.PRIVATE_USER_ORG_CONTACTS,{mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  // TODO: unify API call handling - some requests use fetch, some axios, some "api" object
  fetchParkingCapacity(options) {
    return fetch(envConfigAuthorizedMode.GET_PRIVATE_PARKING_CAPACITY + '?' + new URLSearchParams({date:options[0]}),{mode: globalConfig.ENV_FETCH_MODE})
      .then(response => {
        // fetch does not consider 4xx and 5xx HTTP statuses as error, need to handle it manually
        if (response.ok) {
          return response.json().then(body => {
            return Promise.resolve(body.capacities[0]);
          })
        } else {
          return response.json().then(body => {
            return Promise.reject({response: {data: body}});
          })
        }
      })
  },

  fetchParkingCapacities(options) {
    return fetch(envConfigAuthorizedMode.GET_PRIVATE_PARKING_CAPACITY + '?' + new URLSearchParams({dateList:options.join('|')}),{mode: globalConfig.ENV_FETCH_MODE})
      .then(response => {
        // fetch does not consider 4xx and 5xx HTTP statuses as error, need to handle it manually
        if (response.ok) {
          return response.json().then(body => {
            return Promise.resolve(body.capacities);
          })
        } else {
          return response.json().then(body => {
            return Promise.reject({response: {data: body}});
          })
        }
      })
  },

  fetchMyParkingReservations(options) {
    return fetch(envConfigAuthorizedMode.PRIVATE_PARKING_RESERVATIONS + '?' + new URLSearchParams({allFields:options[0]}),{mode: globalConfig.ENV_FETCH_MODE})
      .then(response => {
        // fetch does not consider 4xx and 5xx HTTP statuses as error, need to handle it manually
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then(body => {
            return Promise.reject({response: {data: body}});
          })
        }
      })
  },

  sendParkingReservation(date, buildingCode, carName, carNumber, note) {
    const data = {
      date: date,
      buildingCode: buildingCode,
      car: {
        'model': carName,
        'registration': carNumber,
      },
      comment: note
    }
    return api
      .post(envConfigAuthorizedMode.PRIVATE_PARKING_RESERVATIONS, data)
      .then(response => response.data);
  },

  cancelParkingReservation(reservationId) {
    return axios
      .delete(envConfigAuthorizedMode.PRIVATE_PARKING_RESERVATIONS + '/' + reservationId, {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.data);
  },

  fetchMotorKPI() {
    return axios
      .get(envConfigAuthorizedMode.GET_MOTOR_KPI_DATA)
      .then(response => response.data);
  },

  fetchOnboardingSF(options) {
    return fetch(envConfigAuthorizedMode.GET_ONBOARDING_SF_CANDIDATES + '?' + new URLSearchParams({hireDateFrom: options[0],hireDateTo: options[1]}), {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  registerNewCandidate(candidate) {
    return api
      .post(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA, candidate)
      .then(response => response.data);
  },

  fetchOnboardingApplicants(searchParams) {
    return fetch(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '?' + new URLSearchParams(searchParams), {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  fetchOnboardingDepartmentsLists(searchParams) {
    return fetch(envConfigAuthorizedMode.GET_ONBOARDING_DEPARTMENTS_LIST + '?' + new URLSearchParams(searchParams), {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  fetchOnboardingDepartment(departmentId) {
    return fetch(envConfigAuthorizedMode.GET_ONBOARDING_DEPARTMENTS_LIST + '/' + departmentId, {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  fetchOnboardingJobtitlesLists(searchParams) {
    return fetch(envConfigAuthorizedMode.GET_ONBOARDING_JOBTITLES_LIST + '?' + new URLSearchParams(searchParams), {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  fetchOnboardingJobtitle(jobtitleId) {
    return fetch(envConfigAuthorizedMode.GET_ONBOARDING_JOBTITLES_LIST + '/' + jobtitleId, {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  fetchOnboardingEmployeesLists(searchParams) {
    return fetch(envConfigAuthorizedMode.GET_ONBOARDING_EMPLOYEES_LIST + '?' + new URLSearchParams(searchParams), {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  updateApplicantDetail(applicant, params) {
    return axios
      .put(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicant.id + '?' + new URLSearchParams(params), applicant, {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.data);
  },

  fetchApplicantDetail(applicantID) {
    return fetch(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID, {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  deleteApplicant(applicantID, params) {
    return axios
      .delete(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '?' + new URLSearchParams(params), {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.data);
  },

  resendWelcomeMail(applicantID) {
    return axios
      .post(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/welcomeMail')
      .then(response => response.data);
  },

  generateBackupCode(applicantID) {
    return axios
      .get(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/backupCode')
      .then(response => response.data);
  },

  sendApplicantApproval(applicantID) {
    return axios
      .post(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/approval')
      .then(response => response.data);
  },

  sendApplicantPersonalDataApprovalRequest(applicantID, data) {
    return axios
      .post(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/sap/approval', data)
      .then(response => response.data);
  },

  fetchApplicantDocuments(applicantID) {
    return fetch(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/documents', {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  fetchApplicantDocument(applicantID, documentID) {
    return fetch(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/documents/' + documentID, {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.json());
  },

  confirmApplicantDocument(applicantID, documentID, result) {
    return axios
      .put(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/documents/' + documentID, result, {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.data);
  },

  deleteApplicantDocument(applicantID, documentID) {
    return axios
      .delete(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/documents/' + documentID, {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response.data);
  },

  downloadApplicantDocumentContent(applicantID, documentID) {
    return fetch(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/documents/' + documentID + '/content', {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response);
  },

  sendApplicantDocumentsApprovalRequest(applicantID) {
    return axios
      .post(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/documents/approval')
      .then(response => response.data);
  },

  fetchApplicantForm(applicantID) {
    return fetch(envConfigAuthorizedMode.ONBOARDING_APPLICANT_DATA + '/' + applicantID + '/entryForm', {mode: globalConfig.ENV_FETCH_MODE})
      .then(response => response);
  },

  postParentSubsidy(sendData) {
    // Note usage of FormData - needed for communication with backend -
    // Do not use standard axios POST JSON - it won't work!
    let formData = new FormData();
    formData.append('full_name', sendData.userName);
    formData.append('personal_number', sendData.userPersonalNumber);
    formData.append('gcpn', sendData.userGCPN);
    formData.append('company', sendData.userBranch);
    formData.append('email', sendData.userEmail);
    formData.append('manager_full_name', sendData.userManagerName);
    formData.append('manager_email', sendData.userManagerEmail);
    formData.append('babysitting_total_amount', sendData.userTotalAmount);
    formData.append('babysitting_contribution_month', sendData.userSubsidyMonth);
    formData.append('babysitting_contribution_year', sendData.userSubsidyYear);
    formData.append('babysitting_contribution_value', sendData.userSubsidyPercent);
    formData.append('consent', sendData.userPrivacyPolicy);
    formData.append('consent_manager_contribution', sendData.userSubsidyConditions);
    formData.append('file', sendData.userUploadedFile);

    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfigAuthorizedMode.SEND_PARENTS_BABYSITTING_FORM, formData, options)
      .then(response => response.data);
  },

  postChatbotHRForm(userFullName, userEmail, userPhone, question) {
    // Note usage of FormData - needed for communication with backend -
    // Do not use standard axios POST JSON - it won't work!
    let formData = new FormData();
    formData.append('name', userFullName);
    formData.append('email', userEmail);
    formData.append('phone', userPhone);
    formData.append('question', question);

    return axiosConfig
      .post(envConfigAuthorizedMode.SEND_CHATBOT_FORM_HR, formData)
      .then(response => response.data);
  },

  postChatbotHRQuestion(sendData) {
    return axios
      .post(envConfigAuthorizedMode.CHATBOT_HR_CHAT, sendData, {})
      .then(response => response.data);
  },

  async logCustomInfo(deviceId, module, place, info) {
    fetch(envConfig.default.webServices.CMS_LOG_CUSTOM + '?id=' + deviceId + '&module=' + module + '&place=' + place + '&info=' + info,
      {
        mode: 'no-cors',
        cache: 'no-store'
      }
    )
  },
}

export default authorizedModeDataLoaders;
