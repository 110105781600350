<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <!--======= FORM INPUT ======================-->
      <div
        v-if="!isReport"
        class="vue-b-form-input"
      >
        <prj1017-heading :header="$i18n.t('recruitment.headlineDetailForm')" />

        <div
          v-if="isPrivateApp"
          class="vue-b-form"
        >
          <gen1006-info-panel type="info">
            {{ $t('recruitment.forms.interestSFNote') }}
          </gen1006-info-panel>

          <!-- FIRST NAME INPUT -->
          <frm1035-form-group>
            <frm1001-input-field
              v-model="recruitmentFormValues.firstName"
              :required="true"
              :disabled="true"
              :tooltip="false"
              :state="formNameInvalidState(recruitmentFormValues.firstName)"
              :label="$i18n.t('forms.firstName')"
            />
          </frm1035-form-group>

          <!-- LAST NAME INPUT -->
          <frm1035-form-group>
            <frm1001-input-field
              v-model="recruitmentFormValues.lastName"
              :required="true"
              :disabled="true"
              :tooltip="false"
              :state="formNameInvalidState(recruitmentFormValues.lastName)"
              :label="$i18n.t('forms.lastName')"
            />
          </frm1035-form-group>

          <!-- EMAIL INPUT -->
          <frm1035-form-group>
            <frm1001-input-field
              v-model="recruitmentFormValues.email"
              :tooltip="false"
              :disabled="true"
              inputType="email"
              :required="true"
              :state="formEmailInvalidState(recruitmentFormValues.email)? 'info' : 'invalid'"
              :label="$i18n.t('forms.email')"
            />
          </frm1035-form-group>

          <!-- PHONE INPUT -->
          <frm1035-form-group>
            <frm1001-input-field
              v-model="recruitmentFormValues.tel"
              :tooltip="false"
              inputType="tel"
              :required="true"
              :state="formTelInvalidState(recruitmentFormValues.tel)"
              :label="$i18n.t('forms.phoneNumber')"
            />
          </frm1035-form-group>

          <!-- STARTING SALARY -->
          <frm1035-form-group>
            <frm1001-input-field
              v-model="recruitmentFormValues.startingSalary"
              :tooltip="false"
              :required="true"
              :state="formTelInvalidState(recruitmentFormValues.startingSalary)"
              :label="$i18n.t('recruitment.forms.startingSalary')"
            />
          </frm1035-form-group>

          <!-- START DATE -->
          <frm1035-form-group>
            <frm1001-input-field
              v-model="recruitmentFormValues.startDate"
              :label="$i18n.t('recruitment.forms.startDate')"
              :tooltip="false"
              :disabled="true"
              :placeholder="'YYYY-MM-DD'"
            />

            <frm1006-button
              :type="'internal'"
              :icon-left="true"
              class="vue-is-calendar"
              @buttonClickEvent="openCalendar()"
            />
          </frm1035-form-group>

          <!--File upload-->
          <frm1035-form-group>
            <prj1045-file-upload
              :label="$t('recruitment.forms.placeholderCVUpload')"
              :description="$t('recruitment.forms.uploadCVCondition')"
              :acceptFiles="acceptFiles"
              @uploadedFile="processedFile"
            />
          </frm1035-form-group>
        </div>

        <gen1006-info-panel
          v-if="!isPrivateApp"
          type="neutral"
        >
          <p
            v-html="$i18n.t('recruitment.privateMessage', { pageId: installationPageId})"
          />
        </gen1006-info-panel>
      </div>

      <!--======= FORM SUCCESS ====================-->
      <div
        v-if="isReport"
        class="vue-b-form-success"
      >
        <div v-if="reportSent">
          <prj1017-heading
            :header="$i18n.t('recruitment.reportSentHeader')"
            :subheader="$i18n.t('recruitment.reportSentSubHeader')"
          />
        </div>
      </div>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <gen1006-info-panel
        v-if="isInterested"
        type="info"
      >
        {{ $t('recruitment.submitImInterested') }}
      </gen1006-info-panel>

      <frm1006-button
        v-if="!isReport && isPrivateApp"
        type="primary"
        :disabled="!isActive"
        @buttonClickEvent="validateRecruitmentResponseForm"
      >
        {{ $t('recruitment.submitImInterest') }}
      </frm1006-button>

      <frm1006-button
        v-if="reportSent && isPrivateApp"
        type="primary"
        @buttonClickEvent="redirectToBack"
      >
        {{ $t('general.back') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        :active.sync="reportFailed"
        :buttonClose="true"
        class="vue-is-info-panel"
      >
        <gen1006-info-panel type="error">
          {{ $t(errorMessage) }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />

      <gen1009-overlay
        ref="overlay"
        class="vue-is-date-picker"
        :active.sync="datePickerOpened"
        :buttonClose="true"
        :prevent-closing="'clickOutside'"
      >
        <v-date-picker
          v-model="datePickerDate"
          :min-date="datePickerMinDate"
          :max-date="datePickerMaxDate"
          :attributes="datePickerAttributes"
          is-expanded
          locale="cs-CZ"
        />
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script type="application/javascript">

import PageLayout from '@/templates/partials/page-layout.vue';
import mxNavigationDefault from '@/mixins/mx-navigation-default.vue';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar.vue';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading.vue';
import Prj1045FileUpload from '@/components/prj1045-file-upload/prj1045-file-upload';

//=== UTILITY
import logger from '@/utils/logger';
import Scroller from 'vue-scrollto'
import utilsGeneral from '@/utils/utils-general';
import {mapState} from 'vuex';
import router from '@/router';
import dataLoader from '@/utils/data-loader';

export default {
  name: 'RecruitmentFormSF',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading,
    Prj1045FileUpload
  },

  mixins: [
    mxNavigationDefault,
  ],

  data() {
    return {
      recruitmentFormValues: {
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        startDate: '',
        startingSalary: '',
        uploadedFile: null
      },
      sending: false,
      isReport: false,
      reportSent: false,
      reportFailed: false,
      errorMessage: '',
      validationErrors: [],
      jobDetail: {},
      datePickerDate: false,
      datePickerOpened: false,
      acceptFiles: [
        'application/pdf',
      ],
    }
  },

  computed: {
    ...mapState('user', [
      'deviceId',
      'profile',
      'amUserProfile'
    ]),

    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),

    ...mapState('recruitment', [
      'recruitment'
    ]),

    isActive() {
      let formValue = this.recruitmentFormValues;
      if (formValue.firstName && formValue.lastName && formValue.email && this.formEmailInvalidState(formValue.email) && formValue.tel && formValue.startDate && formValue.startingSalary && formValue.uploadedFile && !this.isInterested) {
        return true;
      } else {
        return false;
      }
    },

    isInterested() {
      return this.recruitment.detailData.isInterested === '1';
    },

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },

    isSF() {
      return !!this.recruitment.detailData.customID;
    },

    datePickerMinDate() {
      return new Date();
    },

    datePickerMaxDate() {
      let now = new Date();
      return new Date(now.setDate(now.getDate() + 365));
    },

    datePickerAttributes() {
      if(this.myReservations) {
        let tmpDotsArray = [];
        this.myReservations.forEach(item => {
          let tmpDateArray = item.nameAlt.split('.');
          tmpDotsArray.push(new Date(tmpDateArray[2], (tmpDateArray[1] - 1), tmpDateArray[0]));
        });
        return [
          {
            dot: true,
            dates: tmpDotsArray
          }
        ];
      } else {
        return [
          {}
        ];
      }
    },

    installationPageId() {
      let pageId = -1;

      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'HELP_INSTALLATION_PAGE_ID') {
            pageId = parseInt(item.value);
          }
        });
      }

      return pageId;
    }
  },

  watch: {
    datePickerDate(value) {
      if (value) {
        let tmpDate = new Date(value);
        let tmpMonth = (tmpDate.getMonth() + 1).toString();
        if (tmpMonth.length === 1) {
          tmpMonth = '0' + tmpMonth;
        }
        let tmpDay = tmpDate.getDate().toString();
        if (tmpDay.length === 1) {
          tmpDay = '0' + tmpDay;
        }
        this.recruitmentFormValues.startDate = tmpDate.getFullYear() + '-' + tmpMonth + '-' + tmpDay;
        this.datePickerOpened = false;
      }
    },
  },

  mounted() {
    if (typeof this.recruitment.detailData.id === 'undefined') {
      router.push({ name: 'recruitment'});
    }

    this.recruitmentFormValues.firstName = this.amUserProfile?.firstName ?? '';
    this.recruitmentFormValues.lastName = this.amUserProfile?.lastName ?? '';
    this.recruitmentFormValues.email = this.amUserProfile?.email ?? (this.amUserProfile?.email ?? '');
  },

  methods: {
    formNameInvalidState(value) {
      if (!value) {
        return 'invalid'
      } else {
        return 'info'
      }
    },

    formEmailInvalidState(value) {
      if (!value) {
        return true;
      }
      return utilsGeneral.isValidEmail(value);
    },

    formTelInvalidState(value) {
      if (!value) {
        return 'invalid'
      } else {
        return 'info'
      }
    },

    readUploadedFileAsBase64(inputFile) {
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort();
          reject(new DOMException('Problem parsing input file.'));
        };

        reader.onload = () => {
          const base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');
          resolve(base64String);
        };
        reader.readAsDataURL(inputFile);
      });
    },

    async processedFile(file) {
      try {
        const fileBase64 = await this.readUploadedFileAsBase64(file.fileBinary)
        this.recruitmentFormValues.uploadedFile = fileBase64;
      } catch (e) {
        logger.error(e.message);
      }
    },

    validateRecruitmentResponseForm() {
      this.validationErrors = [];
      this.reportSent = false;
      this.reportFailed = false;

      if (this.validationErrors.length === 0) {
        this.sendRecruitmentResponseForm();
      } else {
        this.scrollToTop();
      }
    },

    sendRecruitmentResponseForm() {
      let values = this.recruitmentFormValues;
      let sfId = this.recruitment.detailData.customID ? this.recruitment.detailData.customID : 0;
      let jobId = this.recruitment.detailData.id;
      this.sending = true;

      let preparedData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.tel,
        startDate: values.startDate,
        startingSalary: values.startingSalary,
        cvFile: values.uploadedFile,
        jobId: sfId,
        regId: 0,
        id: parseInt(jobId),
        regStatus: 'none'
      }

      if (!sfId) {
        dataLoader.sendJobApplicationSF(preparedData)
          .then(() => {
            this.sending = false;
            this.isReport = true;
            this.reportSent = true;
            logger.info('Job application has been sent.');
          })
          .catch((error) => {
            this.sending = false;
            this.isReport = false;
            this.reportFailed = true;
            this.errorMessage = 'forms.failReportGeneral';
            logger.error('Sending job application has failed.', error);
          });
      } else {
        dataLoader.postJobInterestSF(preparedData)
          .then((response) => {
            preparedData.regId = response.id;
            this.sending = false;
            this.isReport = true;
            this.reportSent = true;
            logger.info('Job application has been sent.');
            dataLoader.sendJobApplicationSF(preparedData);
          })
          .catch((error) => {
            this.sending = false;
            this.isReport = false;
            this.reportFailed = true;
            const errCode = error?.response?.data?.errorCode ?? '';
            switch(errCode) {
            case 'ERR-ALREADY_EXISTS':
              this.errorMessage = 'forms.failReportAlreadyExists';
              break;
            case 'ERR-CANDIDATE_NOT_EXISTS':
              this.errorMessage = 'forms.failReportCandidateNotExists';
              break;
            case 'ERR-JOBS-NOT-POSTED':
              this.errorMessage = 'forms.failReportClosedPosition';
              break;
            default:
              preparedData.regStatus = 'failed';
              dataLoader.sendJobApplicationSF(preparedData);
              this.errorMessage = 'forms.failReportGeneral';
            }
            logger.error('Sending job application has failed.', error);
          })
      }
    },

    scrollToTop() {
      let pageTop = document.getElementById('vue-page-default');
      let scrollContainer = document.getElementById('vue-b-page-content');

      Scroller.scrollTo(pageTop, 500, {container: scrollContainer});
    },

    returnFormToInitialState() {
      this.recruitmentFormValues.firstName = '';
      this.recruitmentFormValues.lastName = '';
      this.recruitmentFormValues.email = '';
      this.recruitmentFormValues.tel = '';
      this.validationErrors = [];
    },

    redirectToBack(){
      this.isReport = false;
      this.reportSent = false;
      this.reportFailed = false;
      this.$router.replace({ name: 'recruitment' });
    },

    openCalendar() {
      this.$nextTick(() => {
        this.datePickerOpened = true;
      });
    },
  },
}
</script>
