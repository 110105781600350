<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar route-name="festival" />
        </template>
        <template v-slot:content>
          <div class="prj-layout-form">
            <!--========== HEADER =================================-->
            <!--===================================================-->
            <div class="">
              <t-tag
                variant="sectionTitle"
                class="xs:mt-6"
              >
                <!-- TODO replace from rest API -->
                {{ $t('fest.transport.forms.headline') }}
              </t-tag>

              <p v-html="$t('fest.transport.forms.description')"></p>

              <div class="text-white">
                <p class="w-full bg-sky-600 p-4 text-white rounded-xl">
                  {{ $t('fest.transport.forms.infoMessage') }}
                </p>
              </div>
            </div>

            <!--========== FORM ===================================-->
            <!--===================================================-->
            <form>
              <t-select
                v-model="transportValue"
                :placeholder="$t('fest.transport.forms.fieldTransportPlaceholder')"
                :options="transportOptions"
                :disabled="!!transportIsSubmitted"
              />
            </form>

            <!--========== CONTROLS ===============================-->
            <!--===================================================-->
            <div class="prj-controls">
              <t-button
                v-if="transportIsSubmitted"
                class="w-full bg-fest-home"
                @click="cancelTransport"
              >
                {{ $t('fest.forms.buttons.buttonCancel') }}
              </t-button>

              <t-button
                v-else
                class="w-full bg-fest-home"
                :disabled="!isActive"
                @click="sendTransport"
              >
                {{ $t('fest.forms.buttons.buttonSend') }}
              </t-button>
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import {ROUTE_TRANSPORT_SUCCESS} from '@/constants/fest/route-names';
import {FEST_USER} from '@/store/store-modules';
import {SET_USER_TRANSPORT} from '@/store/constants/fest/mutations';
import {mapGetters} from 'vuex';

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'
import PageLayout from '@/templates/fest/page-layout'
import Prj1005NavigationBar from '@/components/fest/prj1005-navigation-bar/prj1005-navigation-bar'
import apiCmsUser from '@/fest-api/cms-user';
import logger from '@/utils/logger';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTTransport',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout,
    Prj1005NavigationBar
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      transportOptions: [
        { value: 'Brno', text: 'Brno' },
        { value: 'Břeclav', text: 'Břeclav' },
        { value: 'České Budějovice', text: 'České Budějovice' },
        { value: 'Frýdek-Místek', text: 'Frýdek-Místek' },
        { value: 'Hradec Králové', text: 'Hradec Králové' },
        { value: 'Jihlava', text: 'Jihlava' },
        { value: 'Karlovary Vary', text: 'Karlovary Vary' },
        { value: 'Klatovy', text: 'Klatovy' },
        { value: 'Liberec', text: 'Liberec' },
        { value: 'Mladá Boleslav', text: 'Mladá Boleslav' },
        { value: 'Olomouc', text: 'Olomouc' },
        { value: 'Ostrava', text: 'Ostrava' },
        { value: 'Pardubice', text: 'Pardubice' },
        { value: 'Písek', text: 'Písek' },
        { value: 'Plzeň', text: 'Plzeň' },
        { value: 'Prostějov', text: 'Prostějov' },
        { value: 'Šumperk', text: 'Šumperk' },
        { value: 'Tábor', text: 'Tábor' },
        { value: 'Trutnov', text: 'Trutnov' },
        { value: 'Ústí nad Labem', text: 'Ústí nad Labem' },
        { value: 'Zlín', text: 'Zlín' }
      ],
      transportValue: null
    }
  },
  computed: {
    ...mapGetters('festUser', ['registeredTransform']),
    isActive() {
      return this.transportValue
    },
    transportIsSubmitted() {
      return this.registeredTransform;
    }
  },
  mounted() {
    if (this.transportIsSubmitted) {
      this.setTransport();
    }
  },
  methods: {
    setTransport() {
      this.transportValue = this.transportIsSubmitted;
    },
    sendTransport() {
      const formData = new FormData();
      formData.transport = this.transportValue;

      apiCmsUser
        .putRegistration(formData)
        .then(() => {
          logger.info('Form has been sent.')
          this.sending = false
          this.$store.commit(FEST_USER + '/' + SET_USER_TRANSPORT, this.transportValue);
          this.goToSuccessPage();
        })
        .catch((error) => {
          this.sending = false
          this.sendFailed = true
          logger.error('Sending form has failed.', error.response)
        });
    },
    cancelTransport() {
      apiCmsUser
        .putRegistration()
        .then(() => {
          logger.info('Form has been sent.')
          this.sending = false
          this.$store.commit(FEST_USER + '/' + SET_USER_TRANSPORT, null);
          this.goToSuccessPage();
        })
        .catch((error) => {
          this.sending = false
          this.sendFailed = true
          logger.error('Sending form has failed.', error.response)
        });
    },
    goToSuccessPage() {
      this.$router.push({name: ROUTE_TRANSPORT_SUCCESS});
    },
  },
}
</script>
