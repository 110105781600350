export const ROUTE_DASHBOARD = 'festival'
export const ROUTE_ABOUT = 'festival-about'
export const ROUTE_PAGE = 'festival-page'
export const ROUTE_COMPETITIONS = 'festival-competitions'
export const ROUTE_COMPETITION_DETAIL = 'festival-competition-detail'
export const ROUTE_COMPETITION_RESULT = 'festival-competition-result'
export const ROUTE_CHALLENGES = 'festival-challenges'
export const ROUTE_CHALLENGE_DETAIL = 'festival-challenge-detail'
export const ROUTE_CHALLENGE_HISTORY = 'festival-challenge-history'
export const ROUTE_CHALLENGE_RESULT = 'festival-challenge-results'
export const ROUTE_NEWS = 'festival-news'
export const ROUTE_NEWS_DETAIL = 'festival-news-detail'
export const ROUTE_STORIES = 'festival-stories'
export const ROUTE_STORY = 'festival-story'
export const ROUTE_NEW_STORY = 'festival-new-story'
export const ROUTE_TOPIC_DETAIL = 'festival-topic-detail'
export const ROUTE_THEMES = 'festival-themes'
export const ROUTE_THEME_DETAIL = 'festival-theme-detail'
export const ROUTE_TARGETS = 'festival-targets'
export const ROUTE_TRANSPORT = 'festival-transport'
export const ROUTE_TRANSPORT_SUCCESS = 'festival-transport-success'
export const ROUTE_VOTE = 'festival-vote'
export const ROUTE_VOTE_DETAIL = 'festival-vote-detail'
export const ROUTE_VOTE_RESULT = 'festival-vote-results'
