import * as MUTATIONS_CONSTANTS from '../constants/mutations';

// ========================================================= //
// == MODULE FOR AUTHORIZED MODE ONLY                     == //
// ========================================================= //
const state = {
  parkingCapacity: {},
  myParkingReservations: {}
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_PARKING_CAPACITY](state, data) {
    state.parkingCapacity = data;
  },

  [MUTATIONS_CONSTANTS.SET_MY_PARKING_RESERVATIONS](state, data) {
    if (typeof data === 'object' && !data.hasOwnProperty('errorCode')) {
      state.myParkingReservations = data;
    } else {
      state.myParkingReservations = {};
    }
  },
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
