<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div class="vue-b-parents-dashboard">
          <frm1035-form-group>
            <frm1028-advanced-combo
              id="branch-selector"
              v-model="selectedBranchId"
              :class="parentLoadingClassObject"
              :options="branches"
              :label="$i18n.t('parents.branch')"
              :tooltip="false"
              :searchable="false"
              :disabled="!parentsDataLoaded && parentsDataLoading"
              @change="getSelectedBranchData()"
            />
          </frm1035-form-group>

          <template v-if="searchQuery.length === 0">
            <div class="vue-dashboard-tiles">
              <frm1006-button
                v-for="item in tileButtons"
                :key="item.id"
                class="vue-dashboard-item-tile"
                :class="[generateTile(item), parentLoadingClassObject]"
                type="tile"
                @buttonClickEvent="goToView(item)"
              >
                <template v-slot:iconLeft>
                  <div
                    class="vue-tile-icon"
                  />
                </template>
                {{ item.title }}
              </frm1006-button>
            </div>

            <frm1006-button
              v-for="item in blockButtons"
              :key="item.id"
              :icon-right="true"
              class="vue-dashboard-item-block"
              type="internal"
              @buttonClickEvent="goToView(item)"
            >
              {{ item.title }}
            </frm1006-button>

            <div class="vue-dashboard-additional-sections">
              <div class="vue-parents-section-sticker">
                <span class="vue-sticker-text">{{ $t('parents.formsTitle') }}</span>
              </div>

              <frm1006-button
                :icon-right="true"
                class="vue-control-item"
                type="internal"
                @buttonClickEvent="goToForm('parents-program-registration')"
              >
                {{ $t('parents.buttonFormParentProgram') }}
              </frm1006-button>

              <frm1006-button
                v-if="false"
                :icon-right="true"
                class="vue-control-item"
                type="internal"
                @buttonClickEvent="goToForm('parents-pool-login')"
              >
                {{ $t('parents.buttonFormPoolParents') }}
              </frm1006-button>

              <frm1006-button
                :icon-right="true"
                class="vue-control-item"
                type="internal"
                @buttonClickEvent="goToForm(parentsBabysittingRoute)"
              >
                {{ $t('parents.buttonFormBabysitting') }}
              </frm1006-button>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(item, index) in searchPagesResult"
              :key="index"
              class="vue-result-item"
              :class="searchLoadingClassObject"
            >
              <h3 class="vue-result-caption">
                {{ item.parent_title }}
              </h3>

              <frm1006-button
                v-for="(pageItem, pageIndex) in item.pages"
                :key="pageIndex"
                :icon-right="true"
                class="vue-control-item"
                type="internal"
                @buttonClickEvent="goToDetailPage(item.parent_ID, pageItem)"
              >
                <span v-html="pageItem.title"></span>
              </frm1006-button>
            </div>

            <gen1006-info-panel
              v-if="searchPagesResult.length === 0 && !searchPagesLoading && !parentsDataLoading"
              type="neutral"
              class="vue-no-data"
            >
              {{ $t('parents.noFilteredPages') }}
            </gen1006-info-panel>
          </template>
        </div>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import {
  PARENTS_DASHBOARD_ID,
  ERROR_MODAL_TIMEOUT,
  PARENT_EMPTY_BLOCKS,
  PARENT_EMPTY_PAGES_SEARCH
} from '@/constants/app-constants';
import PageLayout from '@/templates/partials/page-layout';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

import mxNavigationDefault from '@/mixins/mx-navigation-default';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';
import router from '@/router';

export default {
  name: 'ParentsDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      navbarCaptionData: null,
      ERROR_MODAL_TIMEOUT,
      categoryStyles: {
        block: 'block',
        tile: 'tile',
      },
      parentsData: [],
      parentsDataLoaded: false,
      parentsDataLoading: false,
      parentsEmptyBlocks: PARENT_EMPTY_BLOCKS,
      selectedBranchId: 'csob',
      selectedBranchData: [],
      searchPagesFetched: [],
      searchPagesLoading: false,
      searchPagesEmpty: PARENT_EMPTY_PAGES_SEARCH
    };
  },

  computed: {
    ...mapState('parents', [
      'searchQuery'
    ]),

    ...mapState('persistentStorage', [
      'previousSelectedBranch',
    ]),

    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },

    blockButtons() {
      return this.selectedBranchData.filter(item => item.style === this.categoryStyles.block)
    },

    tileButtons() {
      return this.parentsDataLoading ? this.parentsEmptyBlocks : this.selectedBranchData.filter(item => item.style === this.categoryStyles.tile);
    },

    branches() {
      return this.parentsData.map(item => {
        return {
          value: item.branchId,
          caption: item.branchCaption
        }
      })
    },

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },

    parentsBabysittingRoute() {
      return this.isPrivateApp ? 'parents-babysitting' : 'parents-babysitting-form-warning'
    },

    parentLoadingClassObject() {
      return {
        'vue-is-loading': this.parentsDataLoading,
      }
    },

    searchPagesResult() {
      return this.searchPagesLoading ? this.searchPagesEmpty : this.searchPagesFetched;
    },

    searchLoadingClassObject() {
      return {
        'vue-is-loading': this.searchPagesLoading,
      }
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },

    selectedBranchId() {
      this.getParentsSearch();
    },

    searchQuery() {
      this.getParentsSearch();
    }
  },

  mounted() {
    this.initialized = true;
    this.getParentsDashboardCategories().then(() => {
      if (this.previousSelectedBranch) {
        this.selectedBranchId = this.previousSelectedBranch
      }
      this.getSelectedBranchData();
      this.getParentsSearch();
    });
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getParentsDashboardCategories() {
      this.parentsDataLoading = true;

      return dataLoader.fetchApiDataList(PARENTS_DASHBOARD_ID)
        .then(data => {
          this.parentsData = data.parents;
          this.parentsDataLoaded = true;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.parentsDataLoading = false;
        });
    },

    getParentsSearch() {
      if (this.searchQuery.length === 0) {
        return false;
      }

      this.searchPagesFetched = [];
      this.searchPagesLoading = true;

      return dataLoader.fetchParentPages(this.searchQuery, this.selectedBranchId)
        .then(data => {
          this.searchPagesFetched = data;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.searchPagesLoading = false;
        });
    },

    generateTile(item) {
      if (item.icon.length > 0) {
        return 'vue-tile-' + item.icon;
      }
    },

    goToView(item) {
      if ( item.categoryId ) {
        if (item.categoryType === 'faq') {
          router.push({
            name: 'parents-faq-page',
            params: {categoryId: item.categoryId, categoryType: item.categoryType}
          })
        } else {
          router.push({ name: 'parents-category', params: { categoryId: item.categoryId, categoryType: item.categoryType, private: item.hasPrivateOption } })
        }
      }
      if ( item.pageId ) {
        router.push({ name: 'parents-info-page', params: { id: item.pageId, categoryType: item.categoryType } })
      }
    },

    goToDetailPage(parentId, item) {
      // save to store
      this.$store.commit(STORE_MODULES.PARENTS + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, item);
      router.push({ name: 'parents-page', params: { categoryId: parentId, id: item.ID, slug: item.slug, data: item }});
    },

    goToForm(pageName) {
      router.push({ name: pageName, params: {} })
    },

    getSelectedBranchData() {
      this.selectedBranchData = this.parentsData.find(item => item.branchId === this.selectedBranchId).branchData;
      this.$store.commit(STORE_MODULES.PERSISTENT_STORAGE + '/' + MUTATIONS_CONSTANTS.SET_PREVIOUS_SELECTED_BRANCH, this.selectedBranchId);
    }
  },
}
</script>

