<template>
  <div
    v-show="isVisible"
    class="vue-c-navbar-notifications"
    :class="{ 'vue-has-dropdown-open': overlayIsVisible }"
  >
    <div
      class="vue-navigation-btn"
      :class="{ 'vue-is-active': sortedNotices.length }"
      @click="toggleMenu"
    />

    <!-- Notifications popup -->
    <transition
      name="vue-anim-move-from-top"
      appear
    >
      <div
        v-if="isAppNotificationsMenuOpened"
        class="vue-b-dropdown-menu"
        :class="dropdownMenuClassObject"
      >
        <!-- Notifications no data -->
        <div
          v-if="!sortedNotices.length"
          class="vue-b-notifications-message"
        >
          <gen1006-info-panel
            type="neutral"
            class="vue-no-data"
          >
            <span v-html="$t('userSettings.appNotificationsNoData')" />
          </gen1006-info-panel>

          <gen1006-info-panel
            v-if="pushEnabled"
            type="neutral"
            class="vue-no-data vue-is-clickable"
            @click.native="goToSettings"
          >
            <span v-html="$t('userSettings.appNotificationsNoDataTip')" />
          </gen1006-info-panel>
        </div>

        <template v-else>
          <!-- Notifications list -->
          <div
            ref="notificationsList"
            class="vue-b-notifications-list"
          >
            <!-- Notification item -->
            <div
              v-for="(notification, index) in sortedNotices"
              :key="index"
              class="vue-b-notifications-item"
              :class="['vue-is-' + notification.type]"
              @click="goToModule(notification.urlTarget, notification.url, notification.id)"
            >
              <div class="vue-notification-icon" />
              <div class="vue-notification-content">
                <div class="vue-notification-title">
                  {{ notification.caption }}
                </div>
                <div class="vue-notification-description">
                  {{ notification.content }}
                </div>
                <div class="vue-notification-date">
                  <prj1038-item-age :date="notification.createdTimestamp" />
                </div>
              </div>
            </div>
          </div>

          <!-- Notifications footer -->
          <div class="vue-dropdown-divider"></div>
          <div class="vue-b-notifications-bottom">
            <Frm1006Button
              type="internal"
              class="vue-dropdown-item vue-is-clear-all"
              @buttonClickEvent="clearAllItems"
            >
              {{ $t('userSettings.clearAll') }}
            </Frm1006Button>
          </div>
        </template>
      </div>
    </transition>

    <transition
      name="vue-anim-fade"
      appear
      @after-leave="overlayAfterLeave"
    >
      <div
        v-if="isAppNotificationsMenuOpened"
        class="vue-navbar-overlay"
        @click="toggleMenu"
      />
    </transition>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as STORE_MODULES from '@/store/store-modules';
import router from '@/router';

import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import Prj1038ItemAge from '@/components/prj1038-item-age/prj1038-item-age.vue';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';

export default {
  name: 'Prj1002NavbarNotifications',
  components: {Prj1038ItemAge},
  mixins: [mxDetectDesktop],

  data() {
    return {
      loggingIn: false,
      overlayIsVisible: false
    }
  },

  computed: {
    ...mapState('general', [
      'pushNotificationsSubscribed',
    ]),
    ...mapState('user', [
      'isAppNotificationsMenuOpened',
    ]),
    ...mapGetters('general', {
      'pushBrowserSupport': GETTERS_CONSTANTS.PUSH_BROWSER_SUPPORT,
    }),
    ...mapGetters('user', {
      'sortedNotices': GETTERS_CONSTANTS.GET_USER_NOTICES
    }),
    dropdownMenuClassObject() {
      return {
        'vue-is-active': this.isAppNotificationsMenuOpened
      }
    },

    currentRoute() {
      return this.$route.name;
    },

    isVisible() {
      return this.isDesktopLayout || ['dashboard'].includes(this.currentRoute);
    },

    pushEnabled() {
      return this.pushBrowserSupport && !this.pushNotificationsSubscribed;
    }
  },

  watch: {
    isAppNotificationsMenuOpened() {
      if (this.isAppNotificationsMenuOpened) {
        this.overlayIsVisible = true;
      }
    }
  },

  methods: {
    toggleMenu() {
      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.TOGGLE_APP_NOTIFICATIONS_MENU);
    },

    closeMenu() {
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_MENU, false);
    },

    goToModule(target, url, id) {
      const app = target === 'app';

      this.closeMenu();
      this.clearItem(id);

      if (app) {
        return router.push({ path: url });
      }

      window.open(url, '_blank');
    },

    goToSettings() {
      this.closeMenu();
      router.push({ name: 'user-settings' });
    },

    clearItem(id) {
      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.APP_NOTIFICATION_READ, id);
    },

    clearAllItems() {
      this.closeMenu();

      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.APP_NOTIFICATIONS_READ_ALL);
    },

    overlayAfterLeave() {
      this.overlayIsVisible = false;
    }
  }
}
</script>
