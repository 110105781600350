<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <!--======= FORM INPUT ======================-->
      <div
        v-if="!isReport"
        class="vue-b-form-input"
      >
        <div class="vue-b-candidate">
          <prj1017-heading
            :header="$i18n.t('recruitment.headlineDetailFormCandidate')"
          />

          <div class="vue-b-form">
            <!--==== CANDIDATE INFO ===========-->

            <!-- NAME CANDIDATE INPUT -->
            <frm1035-form-group>
              <frm1001-input-field
                v-model="recruitmentFormValues.nameCandidate"
                :tooltip="false"
                :required="true"
                :state="formNameInvalidState(recruitmentFormValues.nameCandidate)"
                :label="$i18n.t('forms.name')"
              />
            </frm1035-form-group>

            <!-- EMAIL INPUT -->
            <frm1035-form-group>
              <frm1001-input-field
                v-model="recruitmentFormValues.emailCandidate"
                :tooltip="false"
                inputType="email"
                :required="true"
                :state="formEmailInvalidState(recruitmentFormValues.emailCandidate)? 'info' : 'invalid'"
                :label="$i18n.t('forms.email')"
              />
            </frm1035-form-group>

            <!-- MESSAGE INPUT -->
            <frm1035-form-group>
              <frm1013-textarea
                v-model="recruitmentFormValues.messageCandidate"
                :tooltip="false"
                :required="false"
                :label="$i18n.t('recruitment.messageToCandidate')"
                :rows="1"
                :rowsAutoExpandMinRows="1"
                :rowsAutoExpandMaxRows="5"
              />
            </frm1035-form-group>
          </div>
        </div>

        <!--==== REFFERER INFO ============-->
        <div class="vue-b-referrer">
          <prj1017-heading
            :header="$i18n.t('recruitment.yourContact')"
          />
          <!-- NAME INPUT -->
          <frm1035-form-group>
            <frm1001-input-field
              v-model="recruitmentFormValues.name"
              :tooltip="false"
              :required="true"
              :state="formNameInvalidState(recruitmentFormValues.name)"
              :label="$i18n.t('forms.name')"
            />
          </frm1035-form-group>

          <!-- EMAIL INPUT -->
          <frm1035-form-group>
            <frm1001-input-field
              v-model="recruitmentFormValues.email"
              :tooltip="false"
              inputType="email"
              :required="true"
              :state="formEmailInvalidState(recruitmentFormValues.email)? 'info' : 'invalid'"
              :label="$i18n.t('forms.email')"
            />
          </frm1035-form-group>
        </div>
        <gen1006-info-panel
          type="neutral"
          class="vue-additional-info"
        >
          {{ $t('recruitment.noteRecruitmentDetailFormCandidate') }}
        </gen1006-info-panel>
      </div>

      <!--======= FORM SUCCESS ====================-->
      <div
        v-if="isReport"
        class="vue-b-form-success"
      >
        <div v-if="reportSent">
          <!-- FIXME MBU: when reccomending, the message says, the recruiter will contact you  -->
          <prj1017-heading
            :header="$i18n.t('recruitment.reportSentHeader')"
            :subheader="$i18n.t('recruitment.reportSentSubHeader')"
          />
        </div>
      </div>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        v-if="!isReport"
        type="primary"
        :disabled="!isActive"
        @buttonClickEvent="validateRecruitmentResponseForm"
      >
        {{ $t('forms.submit') }}
      </frm1006-button>

      <frm1006-button
        v-if="reportSent"
        type="primary"
        @buttonClickEvent="redirectToBack"
      >
        {{ $t('general.back') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        :active.sync="reportFailed"
        :closeAfter="5000"
        :buttonClose="true"
        class="vue-is-info-panel"
      >
        <gen1006-info-panel type="error">
          {{ $t('forms.failReportGeneral') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />
    </template>
  </page-layout>
</template>

<script type="application/javascript">

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import logger from '@/utils/logger';
import Scroller from 'vue-scrollto'
import utilsGeneral from '@/utils/utils-general';
import dataLoader from '@/utils/data-loader';
import {mapState} from 'vuex';

export default {
  name: 'RecruitmentDetailFormCandidate',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
  ],

  data() {
    return {
      recruitmentFormValues: {
        name: '',
        email: '',
        nameCandidate: '',
        emailCandidate: '',
        messageCandidate: '',
      },
      sending: false,
      isReport: false,
      reportSent: false,
      reportFailed: false,
      validationErrors: [],
      jobDetail: {},
      // TODO MBU: not used
      loadingData: false,
      loadFailed: false
    }
  },

  computed: {
    ...mapState('user', [
      'deviceId',
      'profile'
    ]),
    ...mapState('recruitment', [
      'recruitment'
    ]),

    isActive() {
      let formValue = this.recruitmentFormValues;
      if (formValue.name && formValue.email && this.formEmailInvalidState(formValue.email) && formValue.nameCandidate && formValue.emailCandidate && formValue.messageCandidate) {
        return true;
      } else {
        return false;
      }
    }
  },

  mounted() {
    this.recruitmentFormValues.name = this.profile?.name ?? '';
    this.recruitmentFormValues.email = this.profile?.workEmail ?? (this.profile?.otherEmail ?? '');
    // Load job detail
    this.loadJobData();
  },

  methods: {
    formNameInvalidState(value) {
      if (!value && this.validationErrors.length) {
        return 'invalid'
      } else {
        return 'info'
      }
    },

    formEmailInvalidState(value) {
      if (!value) {
        return true;
      }
      return utilsGeneral.isValidEmail(value);
    },

    formTelInvalidState(value) {
      if (!value && this.validationErrors.length) {
        return 'invalid'
      } else {
        return 'info'
      }
    },

    scrollToTop() {
      let pageTop = document.getElementById('vue-page-default');
      let scrollContainer = document.getElementById('vue-b-page-content');

      Scroller.scrollTo(pageTop, 500, {container: scrollContainer});
    },

    validateRecruitmentResponseForm() {
      this.validationErrors = [];
      this.reportSent = false;
      this.reportFailed = false;

      if (this.validationErrors.length === 0) {
        this.sendRecruitmentResponseForm();
      } else {
        this.scrollToTop();
      }
    },

    sendRecruitmentResponseForm() {
      let component = this;
      let values = this.recruitmentFormValues;
      let jobId = this.recruitment.detailData.id;
      let deviceId = this.deviceId;
      this.sending = true;

      dataLoader.sendJobRecommendation(
        values.name,
        values.email,
        values.nameCandidate,
        values.emailCandidate,
        values.messageCandidate,
        deviceId,
        jobId
      ).then(() => {
        this.sending = false;
        this.isReport = true;
        this.reportSent = true;
        this.returnFormToInitialState();
        component.scrollToTop();
        logger.info('Job application has been sent.');
      }, (error) => {
        this.sending = false;
        this.isReport = false;
        this.reportFailed = true;
        logger.error('Sending job application has failed.', error);
      });
    },

    returnFormToInitialState() {
      this.recruitmentFormValues.name = '';
      this.recruitmentFormValues.email = '';
      this.recruitmentFormValues.nameCandidate = '';
      this.recruitmentFormValues.emailCandidate = '';
      this.recruitmentFormValues.messageCandidate = '';
      this.validationErrors = [];
    },

    // FIXME MBU: this relies on store data, use actual route params or id in url
    // jobDetail is actually not used
    loadJobData() {
      // TODO MBU: here should be probably loading
      this.sending = true;
      this.reportFailed = false;

      let urlId = this.recruitment.id;
      let jobData = this.recruitment.detailData;

      if (!jobData) {
        dataLoader
          .fetchRecruitmentDetail('id=' + urlId)
          .then((result) => {
            if (typeof result === 'undefined' || result.length === 0) {
              this.sending = false;
              this.reportFailed = true;
              return;
            }

            this.jobDetail = result;
            this.sending = false;
          }, () => {
            this.sending = false;
            this.reportFailed = true;
          });
      } else {
        this.jobDetail = jobData;
        this.sending = false;
      }
    },

    redirectToBack() {
      this.isReport = false;
      this.reportSent = false;
      this.reportFailed = false;
      this.$router.replace({ name: 'recruitment' });
    }
  },
}
</script>
