<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div>
    <t-icon-button
      classes="bg-white mb-3 xs:mb-5 px-0 py-2"
      :class="{ 'text-teal-600': highlightedSelection }"
      :disabled="disabled"
      @click="openOverlay"
    >
      <!-- Icon -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 transform rotate-180"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h7"
        />
      </svg>
      <span class="sr-only">{{ $t('fest.stories.filters.filtersButton') }}</span>
    </t-icon-button>

    <!-- Overlay -->
    <prj1009-modal
      :active="filterOverlay"
      @overlayCloseEvent="closeOverlay"
    >
      <template v-slot:content>
        <ul class="flex flex-wrap">
          <li
            v-for="{ value, label } in filters"
            :key="value"
            class="py-2 w-full"
          >
            <label class="prj-filter-radio flex items-center">
              <span class="pr-2 font-semibold">{{ $t(label) }}</span>
              <t-radio
                name="filter-radio"
                :value="value"
                :checked="value === computedActiveFilter"
                @click="onFilterChange(value)"
              />
            </label>
          </li>

          <li class="pt-3 w-full">
            <prj1001-section-button
              type="link"
              class="opacity-50"
            >
              <template v-slot:icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                  />
                </svg>
              </template>
              <template
                v-slot:text
              >
                <span class="pr-2 font-semibold">{{ $t('fest.stories.filters.filterMyStories') }}</span>
              </template>
            </prj1001-section-button>
          </li>
        </ul>
      </template>
    </prj1009-modal>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import Prj1001SectionButton from '@/components/fest/prj1001-section-button/prj1001-section-button'
import Prj1009Modal from '@/components/fest/prj1009-modal/prj1009-modal'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1017TextualFilters',
  components: { Prj1001SectionButton, Prj1009Modal },
  props: {
    // While fetching more stories
    disabled: {
      type: Boolean,
      default: true,
    },
    defaultFilter: {
      type: String,
      required: true,
    },
    activeFilter: {
      type: String,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterOverlay: false,
    }
  },
  computed: {
    computedActiveFilter() {
      return this.filters.find(filter => filter.value === this.activeFilter) ? this.activeFilter : this.defaultFilter
    },
    highlightedSelection() {
      return this.computedActiveFilter !== this.defaultFilter
    }
  },
  methods: {
    onFilterChange(value) {
      this.$emit('filterChange', value)
      this.closeOverlay()
    },

    openOverlay() {
      this.filterOverlay = true
    },

    closeOverlay() {
      this.filterOverlay = false
    },
  },
}
</script>
