import Frm1031ButtonContainer from './frm1031-button-container';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1031ButtonContainer);
    }
};

use(Plugin);

export default Plugin;

export { Frm1031ButtonContainer };
