<script>
export default {
  name: 'Prj1009MapMixinSearch',
  watch: {
    mapSearchString() {
      this.filterItems(this.mapSearchString, this.filterSelected);
    },

    filterSelected() {
      this.filterItems(this.mapSearchString, this.filterSelected);
    },
  },
  methods: {
    /**
     * Filter out menu items which match search string.
     * Support searching in:
     * - page name
     * - page tags
     *
     * @param stringToSearch String string to search for (filter by)
     * @private
     * @param fiterId Number building ID
     * @private
     */
    filterItems: function (stringToSearch, fiterId) {
      if (stringToSearch.length === 0) {
        this.mapRepeaterItems = [];
        return;
      }

      let filterResults = []; // helper array, do not write directly to mapRepeaterItems (performance)
      for (let i = 0; i < this.mapDataRooms.length; i++) {
        let isNeedleInCode = this._containsCaseInsensitive(stringToSearch, this.mapDataRooms[i].code, false, true);
        let isNeedleInName = this._containsCaseInsensitive(stringToSearch, this.mapDataRooms[i].name, true);
        let isNeedleInNamePretty = false;
        if(this.mapDataRooms[i].namePretty) {
          isNeedleInNamePretty = this._containsCaseInsensitive(stringToSearch, this.mapDataRooms[i].namePretty, true);
        }

        if (isNeedleInCode || isNeedleInName || isNeedleInNamePretty) {
          filterResults.push(this.mapDataRooms[i]);
        }
      }

      if (fiterId !== 0) {
        const filterCategory = this.filterCategories.find(item => item.termId === fiterId);
        this.mapRepeaterItems = filterResults.filter(item => {
          return item.buildingId === filterCategory.termName
        })
      } else {
        this.mapRepeaterItems = filterResults
      }
    }
  }
}
</script>
