<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div class="vue-fullheight">
    <div
      v-if="!isDesktopLayout"
      class="vue-b-page-layout vue-building-background vue-building-background-hidden-phablet"
      :class="classObject"
      :style="generateBackground"
    >
      <gen1016-loading-indicator
        :active="waitForAsyncData && (!pageAsyncDataLoaded || !appInitialized)"
        :fullScreen="true"
        :overlay="true"
      />

      <!--======= CONTENT HEADER ==================-->
      <div
        id="vue-b-page-content"
        class="vue-b-page-content"
        @scroll="scroll"
      >
        <!--======= PAGE NAVIGATION =================-->
        <!-- TODO MBU: special animation for navigation -->
        <transition
          v-if="navigationHasContent && pageReady"
          appear
          name="vue-anim-fade-TODO"
          mode="out-in"
        >
          <div class="vue-b-navbar">
            <slot name="navbar" />
          </div>
        </transition>

        <!--======= CONTENT HEADER ==================-->
        <transition
          v-if="headerHasContent && pageReady"
          appear
          name="vue-anim-fade"
          mode="out-in"
        >
          <div class="vue-b-page-content-header">
            <slot name="header" />
          </div>
        </transition>

        <!--======= CONTENT MAIN ====================-->
        <transition
          v-if="hasContentMain && pageReady"
          appear
          name="vue-anim-fade-special"
          mode="out-in"
        >
          <div
            id="vue-b-page-content-main"
            class="vue-b-page-content-main"
          >
            <template v-if="!hasContentRest">
              <slot name="contentMain" />
            </template>
            <template v-else>
              <div class="vue-b-content-main">
                <slot name="contentMain" />
              </div>
              <div class="vue-b-content-rest">
                <slot name="contentRest" />
              </div>
            </template>
          </div>
        </transition>
      </div>

      <!--======= CONTENT FOOTER ==================-->
      <transition
        v-if="footerHasContent && pageReady"
        appear
        name="vue-anim-fade"
        mode="out-in"
      >
        <div class="vue-b-page-footer">
          <slot name="footer" />
        </div>
      </transition>

      <!--======= CONTENT FIXED ===================-->
      <template
        v-if="hasContentFixed && pageReady"
      >
        <slot name="fixed" />
      </template>

      <!--======= BUTTON FIXED ====================-->
      <template
        v-if="hasButtonFixed && pageReady"
      >
        <slot name="buttonFixed" />
      </template>
    </div>
    <div
      v-if="isDesktopLayout"
      class="vue-b-page-layout vue-b-page-layout-desktop vue-building-background vue-building-background-hidden-phablet"
      :class="classObject"
      :style="generateBackground"
    >
      <gen1016-loading-indicator
        :active="waitForAsyncData && (!pageAsyncDataLoaded || !appInitialized)"
        :fullScreen="true"
        :overlay="true"
      />

      <!--======= PAGE NAVIGATION =================-->
      <!-- TODO MBU: special animation for navigation -->
      <transition
        v-if="navigationHasContent && pageReady"
        appear
        name="vue-anim-fade-TODO"
        mode="out-in"
      >
        <div class="vue-b-navbar">
          <slot name="navbar" />
        </div>
      </transition>

      <div class="vue-b-page-desktop-content">
        <div class="vue-b-page-desktop-navigation">
          <prj1042-dashboard-buttons />
        </div>
        <div class="vue-b-page-content-container">
          <!--======= CONTENT ==================-->
          <div
            id="vue-b-page-content-desktop"
            class="vue-b-page-content"
            @scroll="scroll"
          >
            <div class="vue-b-page-content-wrap">
              <!--======= CONTENT HEADER ==================-->
              <transition
                v-if="headerHasContent && pageReady"
                appear
                name="vue-anim-fade"
                mode="out-in"
              >
                <div class="vue-b-page-content-header">
                  <slot name="header" />
                </div>
              </transition>

              <!--======= CONTENT MAIN ====================-->
              <transition
                v-if="hasContentMain && pageReady"
                appear
                name="vue-anim-fade-special"
                mode="out-in"
              >
                <div
                  class="vue-b-page-content-main"
                >
                  <template v-if="!hasContentRest">
                    <slot name="contentMain" />
                  </template>
                  <template v-else>
                    <div class="vue-b-content-main">
                      <slot name="contentMain" />
                    </div>
                    <div class="vue-b-content-rest">
                      <slot name="contentRest" />
                    </div>
                  </template>
                </div>
              </transition>
            </div>
          </div>

          <!--======= CONTENT FOOTER ==================-->
          <transition
            v-if="footerHasContent && pageReady"
            appear
            name="vue-anim-fade"
            mode="out-in"
          >
            <div class="vue-b-page-footer">
              <slot name="footer" />
            </div>
          </transition>

          <!--======= CONTENT FIXED ===================-->
          <template
            v-if="hasContentFixed && pageReady"
          >
            <slot name="fixed" />
          </template>

          <!--======= BUTTON FIXED ====================-->
          <template
            v-if="hasButtonFixed && pageReady"
          >
            <slot name="buttonFixed" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex';
import * as envConfig from 'env-config';
import debounce from 'lodash/debounce';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import Prj1042DashboardButtons from '@/components/prj1042-dashboard-buttons/prj1042-dashboard-buttons';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'PageLayout',
  components: {
    Prj1042DashboardButtons
  },
  mixins: [mxDetectDesktop],
  props: {
    waitForAsyncData: Boolean,
    watchScroll: Boolean,
    // TODO MBU (nice to have): expand functionality, pass object with thresholds + classes to add them
    scrollThreshold: {
      default: 0,
      type: [Number, Object]
    }
  },
  data() {
    return {
      appInitialized: false,
      pageAsyncDataLoaded: false,
      scrollY: 0,
      scrollHeight: null,
      scrollContainerHeight: null
    }
  },
  computed: {
    ...mapState('general', [
      'isMenuOpened'
    ]),
    ...mapState('dashboard', [
      'dashboardBackground'
    ]),
    classObject() {
      return {
        'vue-has-navigation': this.navigationHasContent,
        'vue-has-caption': this.navbarCaption,
        'vue-has-header': this.headerHasContent,
        'vue-has-content-rest': this.hasContentRest,
        'vue-has-footer': this.footerHasContent,
        'vue-has-content-fixed': this.hasContentFixed,
        'vue-has-button-fixed': this.hasButtonFixed,
        'vue-is-scrolled': this.scrolled,
        'vue-is-scroll-top': !this.scrolled,
        // TODO MBU (nice to have): expand functionality, pass object with thresholds + classes to add them
        'vue-is-scrolled-over-threshold': this.scrolledOverThreshold,
        'vue-is-scrolled-to-bottom': this.scrolledToBottom,
        'vue-building-background-hidden': !this.showBackgroundImage,
        'vue-is-layout-desktop-full': this.isDesktopLayoutFull,
        'vue-is-dashboard': this.$route.name === 'dashboard',
        'vue-is-navigation-open': this.isMenuOpened,
      };
    },
    navigationHasContent() {
      return !!this.$slots.navbar;
    },
    headerHasContent() {
      return !!this.$slots.header;
    },
    hasContentMain() {
      return !!this.$slots.contentMain;
    },
    hasContentRest() {
      return !!this.$slots.contentRest;
    },
    footerHasContent() {
      return !!this.$slots.footer;
    },
    hasContentFixed() {
      return !!this.$slots.fixed;
    },
    hasButtonFixed() {
      return !!this.$slots.buttonFixed;
    },
    navbarCaption() {
      return typeof this.$route.meta.navbarCaption !== 'undefined' ? typeof this.$route.meta.navbarCaption : false;
    },
    pageReady() {
      if (this.waitForAsyncData) {
        return this.appInitialized && this.pageAsyncDataLoaded;
      }

      return this.appInitialized;
    },
    scrolled() {
      return this.scrollY !== 0;
    },
    scrolledOverThreshold() {
      return this.scrollY > this.scrollThreshold;
    },
    scrolledToBottom() {
      let scrollBottomPosition = this.scrollY + this.scrollContainerHeight;

      // TODO MBU: quick workaround for rounding issues / use rounding function from FRM1034: Input number - see rounding below
      return scrollBottomPosition === this.scrollHeight || scrollBottomPosition === this.scrollHeight + 1 || scrollBottomPosition === this.scrollHeight - 1;
    },
    showBackgroundImage() {
      return this.pageReady && typeof this.$route.meta.allowedBackground !== 'undefined' && this.$route.meta.allowedBackground;
    },
    generateBackground() {
      return !this.dashboardBackground ? '' : `background-image: url(${envConfig.default.webServices.BASE_CMS_URL + this.dashboardBackground}`;
    },

    orderedDashboardButtons() {
      const buttonsArray = this.dashboardButtons;

      return buttonsArray.sort((a, b) => parseInt(a.orderingDashboard) - parseInt(b.orderingDashboard));
    },
    isDesktopLayoutFull() {
      return typeof this.$route.meta.desktopLayoutFull !== 'undefined' && this.$route.meta.desktopLayoutFull;
    }
  },

  watch: {
    // TODO MBU: on newsDetailComments, there is error in infinity scroll if this watcher is present
    // hasContentMain(value) {
    //   console.log(value);
    // }
  },

  created () {
    this.handleDebouncedScroll = debounce(this.scroll, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },

  mounted() {
    if (!this.waitForAsyncData) {
      this.appInitialized = true;
    }

    // TODO MBU: this should set to true when initial App load is done - set via global event
    this.appInitialized = true;
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },

  methods: {
    // TODO MBU: implement as global event
    pageAsyncDataLoadedDone() {
      this.pageAsyncDataLoaded = true;

      // TODO MBU: workaround for watcher error on hasContentMain, see above
      this.$nextTick(() => {
        if (this.watchScroll) {
          this.updateScrollVariables();
        }
      });
    },

    pageAsyncDataLoadedInProgress() {
      this.pageAsyncDataLoaded = false;

      // TODO MBU: workaround for watcher error on hasContentMain, see above
      this.$nextTick(() => {
        if (this.watchScroll) {
          this.updateScrollVariables();
        }
      });
    },

    scroll(event) {
      // TODO MBU: add debounce
      if (this.watchScroll) {
        let scrollContainer = event.target;

        this.updateScrollVariables(scrollContainer);
        this.$emit('articleListContainerScroll', this.scrollY);
        this.$emit('articleListContainerScrolledBottom', this.scrolledToBottom);
      }
    },

    updateScrollVariables(element) {
      let scrollContainer = element;

      if (!element) {
        // TODO MWR: temporary solution
        if (this.isDesktopLayout) {
          scrollContainer = document.getElementById('vue-b-page-content-desktop');
        } else {
          scrollContainer = document.getElementById('vue-b-page-content');
        }
      }

      // TODO MBU: quick workaround for rounding issues / use rounding function from FRM1034: Input number
      this.scrollY = Math.round(scrollContainer.scrollTop); // round for devices which return non rounded values
      this.scrollHeight = scrollContainer.scrollHeight;
      this.scrollContainerHeight = scrollContainer.offsetHeight;
      this.$emit('articleListContainerScrollbarVisible', this.scrollHeight !== this.scrollContainerHeight);
    }
  }
};
</script>
