<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar :route-name="ROUTE_DASHBOARD" />
        </template>
        <template v-slot:content>
          <div class="prj-layout-default h-full">
            <!--========== HEADER =================================-->
            <!--===================================================-->
            <div class="">
              <t-tag
                variant="sectionTitle"
                class="xs:mt-6"
              >
                {{ $t('fest.stories.headline') }}
              </t-tag>

              <p class="text-sm xs:text-base">{{ $t('fest.stories.description') }}</p>
            </div>

            <!--========== CARD ==================================-->
            <!--===================================================-->
            <div class="relative flex-1">
              <div class="absolute top-0 right-0 bottom-0 left-0">
                <prj1006-cards-wrapper
                  v-if="story"
                  class="h-full"
                  :fireScrollEnd="false"
                >
                  <template v-if="story">
                    <prj1008-story-card
                      class="h-11/12 my-auto"
                      :story="story"
                      :single="true"
                    />
                  </template>
                </prj1006-cards-wrapper>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <prj1001-section-button
            :type="SECTION_BUTTON_TYPE_MAJOR"
            route-name="festival-stories"
          >
            <template v-slot:text>
              {{ $t('fest.stories.buttonToAllStories') }}
            </template>
          </prj1001-section-button>

          <!--========== LOGIN ==================================-->
          <!--===================================================-->
          <prj1010-login-modal
            :active="loginOverlay"
            @loginClose="closeLoginOverlay"
            @loginDone="closeLoginOverlay"
          />
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import utilsGeneral from '@/utils/fest/utils-general'
import logger from '@/utils/logger'
import apiCmsStories from '@/fest-api/cms-stories'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import { ROUTE_DASHBOARD } from '@/constants/fest/route-names'

import PageLayout from '@/templates/fest/page-layout'
import Prj1001SectionButton, {
  SECTION_BUTTON_TYPE_MAJOR,
} from '@/components/fest/prj1001-section-button/prj1001-section-button'
import Prj1005NavigationBar from '@/components/fest/prj1005-navigation-bar/prj1005-navigation-bar'
import Prj1006CardsWrapper from '@/components/fest/prj1006-cards-wrapper/prj1006-cards-wrapper'
import Prj1008StoryCard from '@/components/fest/prj1008-story-card/prj1008-story-card'
import Prj1010LoginModal from '@/components/fest/prj1010-login-modal/prj1010-login-modal'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTStory',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1010LoginModal,
    Prj1006CardsWrapper,
    Prj1001SectionButton,
    Prj1005NavigationBar,
    PageLayout,
    Prj1008StoryCard,
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_DASHBOARD,
      loading: false,
      loginOverlay: false,
      story: null,
      SECTION_BUTTON_TYPE_MAJOR: SECTION_BUTTON_TYPE_MAJOR,
    }
  },
  computed: {
    storyId() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.getStory()
  },
  methods: {
    getStory() {
      let processId = utilsGeneral.startAsyncProcess()
      this.loading = true

      apiCmsStories
        .getStoryById(this.storyId)
        .then((response) => {
          this.story = response
        })
        .catch((error) => {
          logger.error(error.response)
        })
        .finally(() => {
          this.loading = false
          utilsGeneral.stopAsyncProcess(processId)
        })
    },
    showLogin() {
      this.loginOverlay = true
    },
    closeLoginOverlay() {
      this.loginOverlay = false
    },
  },
}
</script>
