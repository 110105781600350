<template>
  <page-layout>
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        :infoButton="true"
        :infoButtonDestination="'restaurants-info'"
      />
    </template>

    <template v-slot:contentMain>
      <template v-if="!isLoading">
        <template v-if="!surveyDisplay">
          <div class="vue-restaurants-menu-info">
            <p v-if="!cafeMenuVisibility">
              {{ $t('meals.restaurantText.' + selectedRestaurant) }}
            </p>
            <p v-else>
              {{ $t('meals.cafeText', { building: selectedRestaurant }) }}
            </p>
          </div>

          <gen1006-info-panel
            v-if="surveyData"
            class="vue-survey-infopanel"
            type="neutral"
          >
            {{ surveyData.text }}
            <frm1006-button
              v-if="surveyData.link !== ''"
              type="neutral"
              :iconRight="true"
              iconRightName="vue-icon-external-link"
              @buttonClickEvent="openRestaurantSurvey"
            >
              {{ $t('meals.surveyLink') }}
            </frm1006-button>
          </gen1006-info-panel>

          <gen1006-info-panel
            v-show="isClosed"
            type="neutral"
          >
            {{ $t('meals.noFoodAvailable') }}
          </gen1006-info-panel>

          <gen1006-info-panel
            v-if="(isWeekend || !hasAnyValidMeal) && !cafeMenuVisibility"
            type="neutral"
            class="vue-is-centered"
          >
            {{ $t('meals.noMenuAvailable') }}
          </gen1006-info-panel>

          <gen1006-info-panel
            v-else-if="(cafeMenuVisibility && !selectedBuildingCafeMenu)"
            type="neutral"
            class="vue-is-centered"
          >
            {{ $t('meals.noCafeAvailable') }}
          </gen1006-info-panel>

          <prj1006-food-menu
            v-else-if="selectedDay !== null && !cafeMenuVisibility"
            :componentKey="componentKey"
            :menuData="selectedDayMenu.categories"
            :selectedDayNumber="selectedDayNumber"
            @openMealDetail="openMealDetail"
          />

          <template v-if="cafeMenuVisibility">
            <div
              class="vue-restaurants-menu-content"
              v-html="selectedBuildingCafeMenu"
            />
          </template>
        </template>
        <template v-else>
          <vue-friendly-iframe
            id="vue-survey-form"
            :src="surveyIframeSrc"
            class="vue-survey-form"
            @iframe-load="initializeIframe"
          />
        </template>
      </template>
    </template>

    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="info"
        >
          {{ $t('meals.noDetailAvailable') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1016-loading-indicator
        :active.sync="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
    </template>

    <template v-slot:footer>
      <prj1006-food-menu-control
        v-if="!surveyDisplay && !isLoading"
        :mainCategory="availableRestaurants"
        :activeMainCategory="selectedRestaurant"
        :secondaryCategory="availableDays"
        :activeSecondaryCategory="selectedDay"
        :showTertiaryCategory="true"
        @setActiveMainCategory="setActiveMainCategory"
        @setActiveSecondaryCategory="setActiveSecondaryCategory"
        @setActiveTertiaryCategory="setActiveTertiaryCategory"
      />
    </template>

    <template
      v-slot:buttonFixed
    >
      <frm1006-button
        v-if="surveyDisplay"
        class="vue-button-page-layout-fixed"
        type="primary"
        @buttonClickEvent="closeRestaurantSurvey"
      >
        {{ $t('meals.closeSurveyIframe') }}
      </frm1006-button>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import router from '@/router';
import * as APP_CONSTANTS from '@/constants/app-constants';
import { RESTAURANTS } from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

import moduleStatistics from '@/mixins/mx-module-statistics';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1006FoodMenu from '@/components/prj1006-food-menu/prj1006-food-menu';
import Prj1006FoodMenuControl from '@/components/prj1006-food-menu/prj1006-food-menu-control';
import * as STORE_MODULES from '@/store/store-modules';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

export default {
  name: 'RestaurantMenu',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1006FoodMenu,
    Prj1006FoodMenuControl
  },

  mixins: [
    moduleStatistics,
    mxNavigationDefault
  ],

  data() {
    return {
      currentDateTime: new Date(),
      componentKey: 0,
      todayDay: null,
      hasError: false,
      availableRestaurants: [
        APP_CONSTANTS.RESTAURANT_NHQ,
        APP_CONSTANTS.RESTAURANT_SHQ,
        APP_CONSTANTS.RESTAURANT_HHQ,
        APP_CONSTANTS.RESTAURANT_PHQ,
      ],
      availableDays: [
        {
          caption: this.$i18n.t('time.days.0'),
          value: '0'
        },
        {
          caption: this.$i18n.t('time.days.1'),
          value: '1'
        },
        {
          caption: this.$i18n.t('time.days.2'),
          value: '2'
        },
        {
          caption: this.$i18n.t('time.days.3'),
          value: '3'
        },
        {
          caption: this.$i18n.t('time.days.4'),
          value: '4'
        }
      ],
      ERROR_MODAL_TIMEOUT: APP_CONSTANTS.ERROR_MODAL_TIMEOUT,
      surveySetting: null,
      surveyDisplay: false,
      iframeLoaded: false,
      startedSurvey: false,
      isLoading: true
    }
  },

  computed: {
    ...mapState('dataStore', {
      restaurants: state => state.contentData.restaurants.facilities
    }),
    ...mapState('dataStore', [
      'contentDataUpdate'
    ]),
    ...mapState('persistentStorage', [
      'yearAndWeek',
      'previousSelectedRestaurant'
    ]),
    ...mapState('restaurants', [
      'selectedRestaurant',
      'selectedDay',
      'cafeMenuVisibility'
    ]),

    thisWeekSelectedRestaurant() {
      let currentBuilding = 'restaurantNHQ';
      if (this.selectedRestaurant === APP_CONSTANTS.RESTAURANT_SHQ) {
        currentBuilding = 'restaurantSHQ';
      } else if (this.selectedRestaurant === APP_CONSTANTS.RESTAURANT_HHQ) {
        currentBuilding = 'restaurantHHQ';
      } else if (this.selectedRestaurant === APP_CONSTANTS.RESTAURANT_PHQ) {
        currentBuilding = 'restaurantPHQ';
      }

      return this.restaurants[currentBuilding].data[this.yearAndWeek].content;
    },

    selectedDayMenu() {
      return this.thisWeekSelectedRestaurant[this.selectedDay];
    },

    hasAnyValidMeal() {
      let validMealsCount = 0;

      if (this.selectedDayMenu && typeof this.selectedDayMenu.categories === 'object') {
        this.selectedDayMenu.categories.forEach(meal => {
          if (meal.name !== null) {
            validMealsCount++;
          }
        });
      }

      return validMealsCount > 0;
    },

    selectedDayNumber() {
      return parseInt(this.selectedDayMenu.date.slice(-2));
    },

    selectedBuildingCafeMenu() {
      let cafe = 'cafeteriaNHQ';
      if (this.selectedRestaurant === APP_CONSTANTS.RESTAURANT_SHQ) {
        cafe = 'cafeteriaSHQ';
      } else if (this.selectedRestaurant === APP_CONSTANTS.RESTAURANT_HHQ) {
        cafe = 'cafeteriaHHQ';
      } else if (this.selectedRestaurant === APP_CONSTANTS.RESTAURANT_PHQ) {
        cafe = 'cafeteriaPHQ';
      }

      let tmpContent = this.restaurants[cafe].data['stable-offer'];
      if (typeof tmpContent === 'undefined') {
        return null;
      }

      return tmpContent.description;
    },

    isWeekend() {
      return this.getTodayIndex() === -1 || this.getTodayIndex() === 5;
    },

    isClosed() {
      // MWr: temporarily commented
      //return (!this.cafeMenuVisibility && this.selectedRestaurant === APP_CONSTANTS.RESTAURANT_NHQ);
      return false;
    },

    surveyData() {
      let retValue = false;
      const dateNow = new Date().getTime();

      if (this.surveySetting) {
        for (const [key, value] of Object.entries(this.surveySetting)) {
          if (key.toUpperCase() === this.selectedRestaurant && value.text.length > 0 && value.dateStart.length > 0 && value.dateEnd.length > 0) {
            const surveyStartDate = new Date(value?.dateStart).getTime();
            const surveyEndDate = new Date(value?.dateEnd).getTime();
            if (dateNow <= surveyEndDate && dateNow >= surveyStartDate) {
              retValue = value;
            }
          }
        }
      }

      return retValue;
    },

    surveyIframeSrc() {
      let retValue = '';

      if (this.surveyData) {
        retValue = this.surveyData.link;
      }

      return retValue;
    },

    classObject() {
      return {
        'vue-iframe-loaded': this.iframeLoaded
      }
    },
  },

  mounted() {
    this.$store.dispatch(STORE_MODULES.DATA_STORE + '/' + ACTIONS_CONSTANTS.FETCH_RESTAURANTS).finally(() => {
      this.$store.dispatch(STORE_MODULES.DATA_STORE + '/' + ACTIONS_CONSTANTS.FETCH_CAFES);
      this.sendStatistics(RESTAURANTS);
      this.checkTodayState();
      this.checkSelectedRestaurant();
      this.getSurveySetting();
      this.isLoading = false;
    });
  },

  methods: {
    checkTodayState() {
      this.todayDay = new Date().getDate();

      if (!this.isWeekend) {
        this.$store.commit(RESTAURANTS + '/' + MUTATIONS_CONSTANTS.SET_RESTAURANT_DAY, this.getTodayIndex());
      }
    },

    getTodayIndex() {
      // Sets monday (first day of week) as 0
      const correctionalConstantForDate = 1;
      const date = new Date();
      let dayIndex = date.getDay();

      return dayIndex - correctionalConstantForDate;
    },

    setActiveMainCategory(activeMainCategory) {
      this.$store.commit(RESTAURANTS + '/' + MUTATIONS_CONSTANTS.SET_SELECTED_RESTAURANT, activeMainCategory);
      this.$store.commit(STORE_MODULES.PERSISTENT_STORAGE + '/' + MUTATIONS_CONSTANTS.SET_PREVIOUS_SELECTED_RESTAURANT, activeMainCategory);
      this.componentKey += 1;
      if (this.surveyDisplay && this.surveyIframeSrc === '') {
        this.surveyDisplay = false;
      }
    },

    setActiveSecondaryCategory(activeSecondaryCategory) {
      let dayIndexNumber = parseInt(activeSecondaryCategory.value);

      this.$store.commit(RESTAURANTS + '/' + MUTATIONS_CONSTANTS.SET_RESTAURANT_DAY, dayIndexNumber);
    },

    setActiveTertiaryCategory() {
      this.$store.commit(RESTAURANTS + '/' + MUTATIONS_CONSTANTS.SET_CAFE_VISIBILITY);
    },

    openMealDetail(itemIndex) {
      if (this.selectedDayNumber === this.todayDay) {
        router.push({name: 'restaurants-detail', params: {id: itemIndex}});
      }
    },

    checkSelectedRestaurant() {
      if (this.previousSelectedRestaurant) {
        this.$store.commit(RESTAURANTS + '/' + MUTATIONS_CONSTANTS.SET_SELECTED_RESTAURANT, this.previousSelectedRestaurant);
      }
    },

    getSurveySetting() {
      return dataLoader.fetchApiDataList(APP_CONSTANTS.RESTAURANTS_SURVEY_SETTING_ID)
        .then(data => {
          this.$nextTick(function() {
            this.surveySetting = data;
          });
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        });
    },

    openRestaurantSurvey() {
      this.surveyDisplay = true;
    },

    closeRestaurantSurvey() {
      this.surveyDisplay = false;
    },

    initializeIframe() {
      this.iframeLoaded = true;
      this.startedSurvey = true;
    },
  }
}
</script>
