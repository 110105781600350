<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    class="vue-component vue-c-map-repeater-item"
    :class="classObject"
    tabindex="0"
    @click="mapRepeaterItemClickEmit"
    @keydown.up.stop.prevent="setFocusPrevItem"
    @keydown.down.stop.prevent="setFocusNextItem"
  >
    <div class="vue-b-data">
      <div class="vue-b-building">
        <div class="vue-building-tag">
          {{ mapData[item.buildingId].code }}
        </div>
      </div>
      <div class="vue-b-room-code">
        {{ item.code }}
      </div>
      <div class="vue-b-room-name">
        {{ item.namePretty || item.name }}
      </div>
    </div>
  </div>
</template>

<script>
const COMPONENT_EVENTS = Object.freeze({
  MAP_BUILDING_FLOOR_CLICK_EVENT: 'mapRepeaterItemClickEvent',
})

export default {
  name: 'Prj1009MapRepeaterItem',
  props: {
    'mapData': {
      default: null,
      type: Object
    },
    'item': {
      default: null,
      type: Object
    },
    'itemActiveId': {
      default: null,
      type: String
    },
    'itemActiveType': {
      default: null,
      type: String
    },
    'id': {
      default: null,
      type: String
    },
  },

  data() {
    return {}
  },

  computed: {
    classObject() {
      return {
        [`vue-is-building-${this.item.buildingId}`]: this.item.buildingId,
        'vue-is-active': this.active,
      }
    },

    active() {
      return (this.item.id === this.itemActiveId);
    }
  },

  methods: {
    mapRepeaterItemClickEmit() {
      this.$emit(COMPONENT_EVENTS.MAP_BUILDING_FLOOR_CLICK_EVENT, this.item.buildingId, this.item.floorId, this.item.id);
    },

    setFocusNextItem() {
      let prevItem = this.$el.nextSibling;
      if (prevItem != null) {
        prevItem.focus();
      }
    },

    setFocusPrevItem() {
      let nextItem = this.$el.previousSibling;
      if (nextItem != null) {
        nextItem.focus();
      }
    }
  }
}
</script>
