//============ GENERAL =================================//
//======================================================//
export const SET_VIEWPORT_SIZE = 'SET_VIEWPORT_SIZE';
export const START_PROCESS = 'START_PROCESS';
export const STOP_PROCESS = 'STOP_PROCESS';
export const SET_SW_REGISTRATION = 'SET_SW_REGISTRATION';
export const SET_SW_INSTANCE = 'SET_SW_INSTANCE';
export const SET_APP_UPDATE_READY = 'SET_APP_UPDATE_READY';
export const SET_APP_UPDATE_FAILED = 'SET_APP_UPDATE_FAILED';
export const SET_NETWORK_ERROR = 'SET_NETWORK_ERROR';

//============ CONFIG ==================================//
//======================================================//
export const SET_APP_CONFIG = 'SET_APP_CONFIG';

//============ INTRO ===================================//
//======================================================//
export const SET_INTRO_APP_SEEN = 'SET_INTRO_APP_SEEN';

//============ THEMES ===================================//
//======================================================//
export const SET_THEMES_INFO = 'SET_THEMES_INFO';

//============ CHALLENGES ==============================//
//======================================================//
export const SET_CHALLENGES_INFO = 'SET_CHALLENGES_INFO';
export const SORT_CHALLENGES_INFO = 'SORT_CHALLENGES_INFO';
export const SET_CHALLENGE_INVOLVE = 'SET_CHALLENGE_INVOLVE';

//============ COMPETITIONS ============================//
//======================================================//
export const SET_COMPETITIONS_INFO = 'SET_COMPETITIONS_INFO';
export const SORT_COMPETITIONS_INFO = 'SORT_COMPETITIONS_INFO';

//============ STORIES =================================//
//======================================================//
export const SET_LIKE_STORY = 'SET_LIKE_STORY';
export const REMOVE_LIKE_STORY = 'REMOVE_LIKE_STORY';

//============ SOCIAL ==================================//
//======================================================//
export const SET_SOCIAL_LINKS = 'SET_SOCIAL_LINKS';
export const SET_SOCIAL_LINKS_EMPTY = 'SET_SOCIAL_LINKS_EMPTY';
