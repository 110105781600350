<template>
  <div class="vue-navigation-items">
    <transition
      name="vue-fade"
      mode="out-in"
    >
      <div
        v-if="!isSubmenuOpened"
        class="vue-navigation"
      >
        <div class="vue-b-navigation-search">
          <div class="vue-search-field">
            <div class="vue-search-icon" />
            <frm1001-input-field
              ref="search"
              v-model="searchModel"
              :tooltip="false"
              :placeholder="$i18n.t('general.searchPlaceholder')"
              class="vue-has-search-icon"
              @inputKeyUpEvent="searchChange"
            />
          </div>
        </div>

        <template v-for="(item, index) in navigationLinks">
          <!-- FIXME MSE: change BE to return proper data type for privateOnly (integer), not string -->
          <div
            :key="'item-' + index"
            class="vue-navigation-item"
            :class="classPrivateObject(item)"
            @click="revealSubitems(item, index)"
          >
            <span class="vue-name">{{ item.name }}</span>
            <span
              v-if="item.privateOnly === '1' && appDestination === APP_DESTINATIONS.PUBLIC"
              class="vue-navigation-sticker vue-is-private"
            >
              {{ $t('menu.labels.private') }}
            </span>
            <span
              v-if="item.sticker && item.sticker.length"
              class="vue-navigation-sticker"
              :class="getNavItemCssClasses(item)"
            >
              {{ renderTranslateKey(item.sticker) }}
            </span>
            <span
              v-if="item.filteredTag"
              class="vue-tag"
            >{{ item.filteredTag }}</span>
          </div>
        </template>
      </div>
    </transition>

    <transition name="vue-fade">
      <div
        v-if="isSubmenuOpened"
        class="vue-subnavigation"
      >
        <template
          v-for="(subitem, subitemIndex) in selectedSubitemData"
        >
          <div
            v-if="subitem.privateOnly === '0' || (subitem.privateOnly === '1' && appDestination === APP_DESTINATIONS.PRIVATE)"
            :key="'subitem-' + subitemIndex"
          >
            <!-- FIXME JNO: change BE to return proper data type for privateOnly (integer), not string boolean -->
            <div
              v-if="subitem.headline"
              class="vue-subnavigation-headline"
              :class="assignHeadlineClass(subitem)"
            >
              {{ subitem.name }}
            </div>

            <div
              v-else
              class="vue-subnavigation-item vue-subitem-icon"
              :class="subnavigationClassObject(subitem)"
              @click="goToRoute(subitem)"
            >
              <div
                class="vue-subnavigation-item-link"
              >
                <span class="vue-name">
                  {{ subitem.name }}
                </span>
                <span
                  v-if="subitem.sticker.length"
                  class="vue-navigation-sticker"
                  :class="getNavItemCssClasses(subitem)"
                >
                  {{ renderTranslateKey(subitem.sticker) }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import router from '@/router';

import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import { APP_DESTINATIONS } from '@/constants/general';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import dataLoader from '@/utils/data-loader';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

export default {
  name: 'Prj1005NavigationItems',
  mixins: [
    mxDetectDesktop
  ],
  props: {
    openedSubItem: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      APP_DESTINATIONS,
      selectedSubitem: 0,
      searchModel: ''
    }
  },

  computed: {
    ...mapState('general', [
      'appDestination',
      'isSubmenuOpened',
      'navigationData',
      'originSubmenuIndex',
      'applicationConfig',
      'applicationConfigLoaded',
    ]),

    ...mapState('user', [
      'deviceId'
    ]),

    selectedSubitemData() {
      if (this.selectedSubitem === '' || !this.navigationData[this.selectedSubitem] || !this.navigationData[this.selectedSubitem].children) {
        return null;
      }

      return this.navigationData[this.selectedSubitem].children;
    },

    navigationLinks() {
      const filteredNavigationLinks = [];

      if (this.searchModel.length > 0) {
        const searchedValue = this.searchModel.toUpperCase();
        const searchedValueLength = this.searchModel.length;

        for (const item of this.navigationData) {
          const name = item.name.slice(0, searchedValueLength).toUpperCase() === searchedValue;
          const alias = item.alias.slice(0, searchedValueLength).toUpperCase() === searchedValue;
          const tags = item.tags?.split(',').find(tag => tag.trim().slice(0, searchedValueLength).toUpperCase() === searchedValue);

          if (name || alias || tags) {
            item.filteredTag = tags;

            filteredNavigationLinks.push(item);
          }
        }

        return filteredNavigationLinks;
      }

      return this.navigationData;
    },

    installationPageId() {
      let pageId = -1;

      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'HELP_INSTALLATION_PAGE_ID') {
            pageId = parseInt(item.value);
          }
        });
      }

      return pageId;
    },
  },

  mounted() {
    if (typeof this.originSubmenuIndex === 'number') {
      this.revealSubitems(null, this.originSubmenuIndex);
    }
    if (this.isDesktopLayout) {
      this.inputSetFocus();
    }
  },

  methods: {
    goToRoute(subitem) {
      const routeToGo = {};

      if (subitem.routeName.startsWith('/')) {
        routeToGo.path = subitem.routeName;
      } else {
        routeToGo.name = subitem.routeName;
      }

      if (!subitem.categoryId && subitem.postId) {
        routeToGo.params = { id: this.postId };
      }

      else if (subitem.categoryId) {
        routeToGo.query = { categoryId: this.categoryId };
      }

      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.CLOSE_DASHBOARD_NAVIGATION);
      router.push(routeToGo);
    },

    revealSubitems(item, index) {
      if (item && typeof item.children === 'undefined') {
        this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.TOGGLE_DASHBOARD_MENU);
        if (item.privateOnly === '1' && this.appDestination === APP_DESTINATIONS.PUBLIC) {
          router.push({
            name: 'help-article',
            params: {
              id: this.installationPageId
            }
          });
        } else {
          router.push(item.routeName);
        }
      } else {
        this.selectedSubitem = index;
        this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.TOGGLE_DASHBOARD_SUBMENU);
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU_CATEGORY, this.selectedSubitem);
      }
    },

    assignHeadlineClass(subitem) {
      return {
        // TODO: this value is temporary - stringy boolean
        'vue-no-background': subitem.background == 'false',
        'vue-restaurants-shq': subitem.title === 'SHQ' && subitem.background,
        'vue-restaurants-nhq': subitem.title === 'NHQ' && subitem.background,
      }
    },

    subnavigationClassObject(subitem) {
      return {
        ['vue-subitem-icon-' + subitem.icon]: subitem.icon,
        'vue-subitem-icon': subitem.icon || subitem.cssClass,
        [subitem.cssClass]: subitem.cssClass,
      }
    },

    getNavItemCssClasses(navItem) {
      let cssClasses = {};

      if (navItem.sticker) {
        cssClasses['vue-is-' + navItem.sticker] = true;
      }

      return cssClasses;
    },

    renderTranslateKey(key) {
      return this.$i18n.t('navigation.' + key);
    },

    searchChange(value, event) {
      dataLoader.logSearchQuery(this.deviceId, 'navigation', value);
      this.$emit('searchKeyUpEvent', value, event);
    },

    inputSetFocus() {
      this.$nextTick(() => {
        this.$refs.search.inputSetFocus();
      });
    },

    classPrivateObject(item) {
      if (item.privateOnly === '1' && this.appDestination === APP_DESTINATIONS.PUBLIC) {
        return 'vue-is-private';
      }
    },
  },
}
</script>
