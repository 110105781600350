<template>
  <div class="vue-c-pwa-installer">
    <div class="vue-pwa-installer-heading">
      <div class="vue-pwa-installer-heading-logo" />
      <h2 class="vue-pwa-installer-heading-text">
        {{ $t('pwaInstaller.heading') }}
      </h2>
    </div>

    <p>{{ $t('pwaInstaller.text') }}</p>

    <p
      v-if="platformIsApple"
      v-html="$i18n.t('pwaInstaller.textApple')"
    />

    <template
      v-if="platformIsAndroid"
    >
      <template
        v-if="activeStep === 1"
      >
        <frm1006-button
          v-if="!isProcessing"
          class="vue-pwa-installer-button"
          @buttonClickEvent="installAppAndroid"
        >
          {{ $t('pwaInstaller.buttonText') }}
        </frm1006-button>

        <gen1016-loading-indicator
          v-else
        />
      </template>

      <template
        v-if="activeStep === 2"
      >
        <p>{{ $t('pwaInstaller.desktopInstallationNotice') }}</p>

        <frm1006-button
          class="vue-pwa-installer-button"
          @buttonClickEvent="closeOverlay"
        >
          {{ $t('pwaInstaller.accept') }}
        </frm1006-button>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as STRING_VALUES from '@/store/string-values';

export default {
  name: 'Prj1035PwaInstaller',
  data() {
    return {
      activeStep: 1,
      isProcessing: false,
    }
  },

  computed: {
    ...mapState('user', [
      'platform'
    ]),

    platformIsApple() {
      return this.platform === STRING_VALUES.PLATFORM_IOS;
    },

    platformIsAndroid() {
      return this.platform === STRING_VALUES.PLATFORM_ANDROID;
    }
  },

  created() {
    window.addEventListener('beforeinstallprompt', this.createDeferredPrompt);
  },

  beforeDestroy() {
    window.removeEventListener('beforeinstallprompt', this.createDeferredPrompt);
  },

  methods: {
    createDeferredPrompt(event) {
      event.preventDefault();
      window.deferredPrompt = event;
    },

    installAppAndroid(event) {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;

      this.isProcessing = true;
      window.deferredPrompt.prompt();

      window.deferredPrompt.userChoice
        .then(choiceResult => {
          this.isProcessing = false;
          window.deferredPrompt = null;

          if (choiceResult.outcome === 'accepted') {
            this.activeStep = 2;
          }
        });
    },

    closeOverlay() {
      this.$emit('closePwaInstallerOverlay');
    }
  }
}
</script>
