let tmpUrl = '/zapka-be/cms/';
if (typeof globalConfig != 'undefined') {
  tmpUrl = globalConfig.BASE_CMS_URL;
}
export const BASE_CMS_URL = tmpUrl;
export const VERSION_CHECK = '/version.json';

// ============================================================== //
// ///////////////////// GENERAL //////////////////////////////// //
// ============================================================== //
export const FAULT_REPORT_API = BASE_CMS_URL + 'wp-json/jp8-comm-interface/v1/fault-report';
export const IDEAS_REPORT_API = BASE_CMS_URL + 'wp-json/jp8-comm-interface/v1/ideas-report';
export const CMS_LOG_SEARCH_QUERY = BASE_CMS_URL + 'util/search-log.php';
export const CMS_LOG_CUSTOM = BASE_CMS_URL + 'util/fe-log.php';


// ============================================================== //
// ///////////////////// AUCTION //////////////////////////////// //
// ============================================================== //
export const CMS_AUCTION_DATA = BASE_CMS_URL + 'wp-json/jp8-auction/v1/bid/list';
export const CMS_AUCTION_PLACE_BID = BASE_CMS_URL + 'wp-json/jp8-auction/v1/bid/offer';


// ============================================================== //
// ///////////////////// COMPETITION //////////////////////////// //
// ============================================================== //
export const CMS_COMPETITION_RESULT = BASE_CMS_URL + 'wp-json/jp8-comm-interface/v1/competition/application';
export const CMS_COMPETITION_RESULT_COUNT = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/competition/result';


// ============================================================== //
// ///////////////////// FORVARDINO ///////////////////////////// //
// ============================================================== //
export const CMS_FORVARDINO_CATEGORIES = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/categories';
export const CMS_FORVARDINO_FILTERS = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/filters';
export const CMS_FORVARDINO_COURSES = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/course/list';
export const CMS_FORVARDINO_DETAIL = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/course/detail';
export const CMS_FORVARDINO_FILTERED_ITEMS_NUMBER = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/course/count';
export const CMS_FORVARDINO_LECTOR_DETAIL = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/speaker/detail';
export const CMS_FORVARDINO_MY_LESSONS = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/course/my-list';
export const CMS_FORVARDINO_COURSE_SCORES = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/course/scores';
export const CMS_FORVARDINO_LESSON_SIGN = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/lesson';
export const CMS_FORVARDINO_NEWSLETTER = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/newsletter';
export const CMS_FORVARDINO_COURSE_RATING = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/lesson/score';
export const CMS_FORVARDINO_COURSE_INTEREST = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/lesson/interested';
export const CMS_FORVARDINO_COURSE_LESSONS = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/course/lessons';
export const CMS_FORVARDINO_LESSON_ATTENDANCE = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/lesson/attendance';
export const CMS_FORVARDINO_LESSON_ATTENDANCE_EXPORT = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/lesson/attendance/export';
export const CMS_FORVARDINO_COURSE = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/course';
export const CMS_FORVARDINO_COURSE_LESSON = BASE_CMS_URL + 'wp-json/jp8-forvardino/v1/course/lesson';


// ============================================================== //
// ///////////////////// MARKETPLACE //////////////////////////// //
// ============================================================== //
export const CMS_MARKETPLACE_FILTERS = BASE_CMS_URL + 'wp-json/jp8-marketplace/v1/lists/categories';
export const CMS_MARKETPLACE_ITEMS = BASE_CMS_URL + 'wp-json/jp8-marketplace/v1/items';
export const CMS_MARKETPLACE_ITEMS_BOOKMARK = BASE_CMS_URL + 'wp-json/jp8-marketplace/v1/item/bookmark';


// ============================================================== //
// ///////////////////// DIRECTORY ////////////////////////////// //
// ============================================================== //
export const CMS_DIRECTORY_CATEGORIES = BASE_CMS_URL + 'wp-json/jp8-app-directory/v1/lists/categories';
export const CMS_DIRECTORY_ENTITIES = BASE_CMS_URL + 'wp-json/jp8-app-directory/v1/lists/entities';
export const CMS_DIRECTORY_APPLICATIONS = BASE_CMS_URL + 'wp-json/jp8-app-directory/v1/applications';
export const CMS_DIRECTORY_APPLICATIONS_WISH = BASE_CMS_URL + 'wp-json/jp8-app-directory/v1/applications/wish';


// ============================================================== //
// ///////////////////// EVENTS ///////////////////////////////// //
// ============================================================== //
export const CMS_EVENTS_FILTER_CATEGORIES = BASE_CMS_URL + 'wp-json/jp8-event/v1/event/categories';
export const CMS_EVENTS_FILTER = BASE_CMS_URL + 'wp-json/jp8-event/v1/event/list';
export const CMS_EVENT = BASE_CMS_URL + 'wp-json/jp8-event/v1/event/detail';
export const CMS_EVENTS_FETCH_MY_EVENTS = BASE_CMS_URL + 'wp-json/jp8-event/v1/event/list/my';
export const CMS_EVENT_SEND_REGISTRATION = BASE_CMS_URL + 'wp-json/jp8-event/v1/event/lesson';


// ============================================================== //
// ///////////////////// WORKOUTS /////////////////////////////// //
// ============================================================== //
export const CMS_WORKOUTS_QUARTERS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/workout/quarter';
export const CMS_WORKOUTS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/workout/list';
export const CMS_WORKOUT = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/workout/detail';
export const CMS_WORKOUT_SEND_REGISTRATION = BASE_CMS_URL + 'wp-json/jp8-comm-interface/v1/workout/lesson-application';


// ============================================================== //
// ///////////////////// RECRUITMENT //////////////////////////// //
// ============================================================== //
export const CMS_RECRUITMENT_REGISTRIES = BASE_CMS_URL + 'wp-json/jp8-hiring/v2/recruitment/jobs-registries';
export const CMS_RECRUITMENT_RESULTS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/recruitment/jobs-list';
export const CMS_RECRUITMENT_RESULTS_COUNT = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/recruitment/jobs-count';
export const CMS_RECRUITMENT_DETAIL_RESULT = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/recruitment/job-detail';
export const CMS_RECRUITMENT_SEND_JOB_APPLICATION = BASE_CMS_URL + 'wp-json/jp8-comm-interface/v1/recruitment/job-application';
export const CMS_RECRUITMENT_SEND_JOB_APPLICATION_SF = BASE_CMS_URL + 'wp-json/jp8-comm-interface/v1/recruitment/job-sf';
export const CMS_RECRUITMENT_SEND_JOB_RECOMMENDATION = BASE_CMS_URL + 'wp-json/jp8-comm-interface/v1/recruitment/job-recommendation';
export const CMS_RECRUITMENT_SEND_JOB_ROTATION_INTEREST = BASE_CMS_URL + 'wp-json/jp8-hiring/v2/job/interest';
export const SEND_JOB_INTEREST_SF = '/zapka-be/v1/jobs/applications';
export const CMS_RECRUITMENT_JOB_WATCHDOG = BASE_CMS_URL + 'wp-json/jp8-hiring/v2/job/watchdog';


// ============================================================== //
// ///////////////////// SURVEY ///////////////////////////////// //
// ============================================================== //
export const SURVEY_STATE = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/survey/state';
export const SURVEY_RESULT = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/survey/result';


// ============================================================== //
// ///////////////////// PUSH NOTIFICATIONS ///////////////////// //
// ============================================================== //
export const PUSH_SERVER_API = BASE_CMS_URL + 'wp-json/jp8-notification/v1/subscription';
export const PUSH_SERVER_API_READ = BASE_CMS_URL + 'wp-json/jp8-notification/v1/push/read';
export const PUSH_SERVER_API_CLICKED = BASE_CMS_URL + 'wp-json/jp8-notification/v1/push/click';


// ============================================================== //
// //////////////////////// RESTAURANTS ///////////////////////// //
// ============================================================== //
export const CMS_FOOD_RATING_STATS = BASE_CMS_URL + 'wp-json/jp8-comm-interface/v1/food/rating';
export const CMS_RESTAURANTS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/restaurants';
export const CMS_CAFES = BASE_CMS_URL + 'wp-json/jp8-content-manager/v1/cafe';


// ============================================================== //
// //////////////////////// BENEFITY //////////////////////////// //
// ============================================================== //
export const CMS_BENEFITS_FILTERS_DATA = BASE_CMS_URL + 'wp-json/jp8-benefit/v1/filters';
export const CMS_BENEFITS_LIST = BASE_CMS_URL + 'wp-json/jp8-benefit/v1/offers';
export const CMS_BENEFITS_COUNT = BASE_CMS_URL + 'wp-json/jp8-benefit/v1/offers/count';
export const CMS_BENEFITS_DETAIL = BASE_CMS_URL + 'wp-json/jp8-benefit/v1/offer/';


// ============================================================== //
// //////////////////////// PWA CONFIG ////////////////////////// //
// ============================================================== //
export const CMS_API_APP_SETTINGS = BASE_CMS_URL + 'wp-json/jp8-pwa-config/v1/app-settings';
export const CMS_MENU_ITEMS = BASE_CMS_URL + 'wp-json/jp8-pwa-config/v1/navigation';
export const CMS_API_DATA_LIST = BASE_CMS_URL + 'wp-json/jp8-pwa-config/v1/data-lists';
export const CMS_CHANGELOG = BASE_CMS_URL + 'wp-json/jp8-pwa-config/v1/changelog';
export const CMS_APP_RELEASE = BASE_CMS_URL + 'wp-json/jp8-pwa-config/v1/app-release';


// ============================================================== //
// //////////////////// IDENTITY //////////////////////////////// //
// ============================================================== //
export const CMS_IDENTITY_AUTHORIZATION = BASE_CMS_URL + 'wp-json/jp8-identity/v1/authorize';
export const CMS_IDENTITY_UNAUTHORIZATION = BASE_CMS_URL + 'wp-json/jp8-identity/v1/unauthorize';
export const CMS_IDENTITY_PROFILE = BASE_CMS_URL + 'wp-json/jp8-identity/v1/profile';
export const CMS_IDENTITY_PROFILE_PHOTO = BASE_CMS_URL + 'wp-json/jp8-identity/v1/profile/photo';
export const CMS_IDENTITY_PROFILE_PREFERENCE = BASE_CMS_URL + 'wp-json/jp8-identity/v1/profile/preference';
export const CMS_IDENTITY_PROFILE_NOTIFICATION = BASE_CMS_URL + 'wp-json/jp8-identity/v1/profile/notification';
export const CMS_IDENTITY_MESSAGE = BASE_CMS_URL + 'wp-json/jp8-identity/v1/profile/message';
export const CMS_IDENTITY_MESSAGES = BASE_CMS_URL + 'wp-json/jp8-identity/v1/profile/messages';
export const CMS_IDENTITY_PROFILE_PHONE_VERIFY = BASE_CMS_URL + 'wp-json/jp8-identity/v1/profile/phone/verify';


// ============================================================== //
// /////////////////// APP NOTIFICATIONS //////////////////////// //
// ============================================================== //
export const CMS_APP_NOTIFICATIONS = BASE_CMS_URL + 'wp-json/jp8-notification/v1/notices';
export const CMS_APP_NOTIFICATIONS_READ_ALL = BASE_CMS_URL + 'wp-json/jp8-notification/v1/notices/read';
export const CMS_APP_NOTIFICATIONS_READ = BASE_CMS_URL + 'wp-json/jp8-notification/v1/notice/read';


// ============================================================== //
// ///////////////////// WS CONTENT API ///////////////////////// //
// ============================================================== //
export const CMS_RECOMMENDED_POSTS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/posts/recommended';
export const CMS_WEEK_ACTIONS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/week-events';
export const CMS_CHECK_LAST_MODIFICATION = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/check-last-modification';
export const CMS_POSTS_FILTER_CATEGORIES = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/posts/filter/categories';
export const CMS_POSTS_BY_CATEGORIES = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v2/posts';
export const CMS_GET_UPDATED_AGGREGATE_SINCE = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/updated-aggregate-since';
export const CMS_GET_ARTICLE_CONTENT = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/articles-content';
export const CMS_SUB_PAGES = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/pages/sub';
export const CMS_UPDATE_STATISTICS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/statistics/update';
export const CMS_POST_DETAIL = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/post/detail';
export const GET_NPS_DATA = BASE_CMS_URL + 'wp-json/jp8-statistic/v1/nps';
export const CMS_NPS_FILTERS_DATA = BASE_CMS_URL + 'wp-json/jp8-statistic/v1/nps/filters';
export const CMS_COVID19_FAQ_ITEMS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/covid/faq';
export const CMS_ADVENT_POSTS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/posts/advent';
export const CMS_MAPS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/maps';
export const CMS_COMING_ACTIONS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/coming-actions';
export const CMS_GLOBAL_SEARCH = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/search';
export const CMS_GLOBAL_SEARCH_HISTORY = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/search/history';
export const CMS_ARTICLE_COMMENT_SEND = BASE_CMS_URL + 'wp-json/jp8-comm-interface/v1/post/comment';
export const CMS_ARTICLE_COMMENTS = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/post/comments/list';

// ============================================================== //
// //////////////////////// MAPS //////////////////////////////// //
// ============================================================== //
export const CMS_MAPS_REPORT = BASE_CMS_URL + 'wp-json/jp8-maps/v1/report';


// ============================================================== //
// //////////////////////// PARENTS ///////////////////////////// //
// ============================================================== //
export const CMS_PARENTS_FORM = BASE_CMS_URL + 'wp-json/jp8-parent/v1/offer';
export const CMS_PARENTS_FORM_LOG_OUT = BASE_CMS_URL + 'wp-json/jp8-parent/v1/offer/log-out';
export const CMS_PARENTS_PAGES = BASE_CMS_URL + 'wp-json/jp8-parent/v1/pages';


// ============================================================== //
// //////////////////////// SERVICES //////////////////////////// //
// ============================================================== //
export const CMS_GET_SERVICES = BASE_CMS_URL + 'wp-json/jp8-content-manager/v1/services';
export const CMS_GET_SERVICE_CONTENT = BASE_CMS_URL + 'wp-json/jp8-content-manager/v1/service';


// ============================================================== //
// //////////////////////// KNOWLEDGE BASE ////////////////////// //
// ============================================================== //
export const CMS_GET_KNOWLEDGE_BASE_LIST = BASE_CMS_URL + 'wp-json/jp8-content-manager/v1/knowledge-base';
export const CMS_GET_KNOWLEDGE_BASE_CONTENT = BASE_CMS_URL + 'wp-json/jp8-content-manager/v1/knowledge-base';


// ============================================================== //
// //////////////////////// SURF STUDIO ///////////////////////// //
// ============================================================== //
export const CMS_GET_SURF_KEMP_EVENTS = BASE_CMS_URL + 'wp-json/jp8-event/v1/surf-studio/surfkemp';


// ============================================================== //
// //////////////////////// EMERGENCY /////////////////////////// //
// ============================================================== //
export const CMS_EMERGENCY_PAGE = BASE_CMS_URL + 'wp-json/jp8-ws-content-api/v1/pages/emergency';


// ============================================================== //
// ///////////////////// PROMOTED ///////////////////////////////// //
// ============================================================== //
export const CMS_PROMOTED_FILTER = BASE_CMS_URL + 'wp-json/jp8-promoted/v1/promoted';


// ============================================================== //
// //////////////////////////// ESG ///////////////////////////// //
// ============================================================== //
const SUSTAINABILITY_CMS_API_CONTENT_URL = BASE_CMS_URL + 'wp-json/jp8-sustainability/v1/';
const BASE_CMS_API_CONTENT_URL = BASE_CMS_URL + 'wp-json/wp/v2/';

export const ESG_WEB_SERVICES = {
  //============ CONFIG: =================================//
  //======================================================//
  CMS_APP_CONFIG: SUSTAINABILITY_CMS_API_CONTENT_URL + 'config',

  //============ CONTENT:=================================//
  //======================================================//
  CMS_CONTENT_NEWS: BASE_CMS_API_CONTENT_URL + 'news',
  CMS_CONTENT_PAGES: BASE_CMS_API_CONTENT_URL + 'pages',
  CMS_CONTENT_THEMES: SUSTAINABILITY_CMS_API_CONTENT_URL + 'themes',
  CMS_CONTENT_TOPICS: SUSTAINABILITY_CMS_API_CONTENT_URL + 'topics',

  //============ STORIES:=================================//
  //======================================================//
  CMS_CONTENT_STORIES: SUSTAINABILITY_CMS_API_CONTENT_URL + 'stories',

  //============ USER:====================================//
  //======================================================//
  CMS_USER_APP_REGISTRATION: SUSTAINABILITY_CMS_API_CONTENT_URL + 'registration',

  //============ CHALLENGES:==============================//
  //======================================================//
  CMS_CONTENT_CHALLENGE: SUSTAINABILITY_CMS_API_CONTENT_URL + 'challenge',
  CMS_CONTENT_CHALLENGES: SUSTAINABILITY_CMS_API_CONTENT_URL + 'challenges',

  //============ COMPETITIONS: ===========================//
  //======================================================//
  CMS_CONTENT_COMPETITIONS: SUSTAINABILITY_CMS_API_CONTENT_URL + 'competitions',

  //============ SOCIAL ==================================//
  //======================================================//
  CMS_CONTENT_SOCIAL: SUSTAINABILITY_CMS_API_CONTENT_URL + 'social-links',
};

// ============================================================== //
// //////////////////////////// FEST //////////////////////////// //
// ============================================================== //
const FESTIVAL_CMS_API_CONTENT_URL = BASE_CMS_URL + 'wp-json/jp8-festival/v1/';

export const FEST_WEB_SERVICES = {
  //============ CONFIG: =================================//
  //======================================================//
  CMS_APP_CONFIG: FESTIVAL_CMS_API_CONTENT_URL + 'config',

  //============ CONTENT:=================================//
  //======================================================//
  CMS_CONTENT_NEWS: BASE_CMS_API_CONTENT_URL + 'news',
  CMS_CONTENT_PAGES: BASE_CMS_API_CONTENT_URL + 'pages',
  CMS_CONTENT_THEMES: FESTIVAL_CMS_API_CONTENT_URL + 'themes',
  CMS_CONTENT_TOPICS: FESTIVAL_CMS_API_CONTENT_URL + 'topics',

  //============ STORIES:=================================//
  //======================================================//
  CMS_CONTENT_STORIES: FESTIVAL_CMS_API_CONTENT_URL + 'stories',

  //============ USER:====================================//
  //======================================================//
  CMS_USER_APP_REGISTRATION: FESTIVAL_CMS_API_CONTENT_URL + 'registrations',

  //============ CHALLENGES:==============================//
  //======================================================//
  CMS_CONTENT_CHALLENGE: FESTIVAL_CMS_API_CONTENT_URL + 'challenge',
  CMS_CONTENT_CHALLENGES: FESTIVAL_CMS_API_CONTENT_URL + 'challenges',

  //============ COMPETITIONS: ===========================//
  //======================================================//
  CMS_CONTENT_COMPETITIONS: FESTIVAL_CMS_API_CONTENT_URL + 'competitions',

  //============ SOCIAL ==================================//
  //======================================================//
  CMS_CONTENT_SOCIAL: FESTIVAL_CMS_API_CONTENT_URL + 'social-links',
};
