<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <!--========== MAIN PAGE ==============================-->
  <!--===================================================-->
  <prj1009-modal
    v-if="emergencyOverlay"
    :active="emergencyOverlay"
  >
    <template v-slot:title>
      {{ emergencyPageTitle }}
    </template>

    <template v-slot:content>
      <div
        v-if="loader"
        class="flex py-12 items-center justify-center"
      >
        <svg
          class="w-8 h-8 text-fest-home animate-spin"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20C4 17.3847 4.62748 14.9159 5.74011 12.7361"
            stroke="currentColor"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>
      </div>

      <div
        v-if="!loader && page"
        class="flex flex-col max-h-vh-60"
      >
        <!-- Main text -->
        <div class="mb-4 p-4 flex-1 rounded-xl bg-gray-100 overflow-y-auto">
          <p v-html="emergencyPageContent" />
        </div>

        <!-- Subpages -->
        <prj1001-section-button
          v-for="pageChild in page.items"
          :key="pageChild.id"
          class="bg-sky-100"
          @click.native="toggleSubPage(pageChild)"
        >
          <template v-slot:text>{{ pageChild.title }}</template>
        </prj1001-section-button>
      </div>
    </template>

    <template v-slot:controls>
      <template v-if="requiredCheckbox">
        <label class="flex items-center mb-4">
          <t-checkbox
            v-model="consentsChecked"
            name="options"
          />
          <span class="ml-2 text-sm">
            {{ $t('fest.emergency.consentEmergency') }}
          </span>
        </label>

        <t-button
          class="bg-fest-home"
          :disabled="!consentsChecked"
          @click="closeEmergency"
        >
          {{ $t("fest.forms.buttons.buttonNext") }}
        </t-button>
      </template>

      <template v-else>
        <t-button
          class="bg-fest-home"
          @click="closeEmergency"
        >
          {{ $t("fest.forms.buttons.buttonClose") }}
        </t-button>
      </template>
    </template>
  </prj1009-modal>

  <!--========== SUBPAGES ===============================-->
  <!--===================================================-->
  <prj1009-modal
    v-else
    :active="!emergencyOverlay"
  >
    <template v-slot:title>
      {{ subPage.title }}
    </template>

    <template v-slot:content>
      <div
        v-if="loader"
        class="flex py-12 items-center justify-center"
      >
        <svg
          class="w-8 h-8 text-fest-home animate-spin"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20C4 17.3847 4.62748 14.9159 5.74011 12.7361"
            stroke="currentColor"
            stroke-width="4"
            stroke-linecap="round"
          />
        </svg>
      </div>

      <div
        v-if="!loader && subPageChildren"
        class="flex flex-col"
      >
        <!-- Main text -->
        <div class="mb-4">
          <p v-html="subPage.content" />
        </div>

        <!-- Subpages -->
        <prj1025-collapsible-card
          v-for="subPageChild in subPageChildren.items"
          :key="subPageChild.id"
          class="mb-4"
          :title="subPageChild.title"
          :description="subPageChild.content"
          :isCardExpanded="getIsCardExpanded(subPageChild.id)"
          @toggleCard="onToggleCard(subPageChild.id)"
        />
      </div>
    </template>

    <template v-slot:controls>
      <t-button
        class="bg-fest-home"
        @click="toggleSubPage"
      >
        {{ $t("fest.forms.buttons.buttonBack") }}
      </t-button>
    </template>
  </prj1009-modal>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>

//============ EXPORT ==================================//
//======================================================//
import Prj1001SectionButton from '@/components/esg/prj1001-section-button/prj1001-section-button';
import Prj1009Modal from '@/components/esg/prj1009-modal/prj1009-modal';
import Prj1025CollapsibleCard from '@/components/fest/prj1025-collapsible-card/prj1025-collapsible-card';
import utilsGeneral from '@/utils/fest/utils-general';
import logger from '@/utils/logger';
import {mapState} from 'vuex';
import dataLoader from '@/utils/data-loader';

export default {
  name: 'Prj1024Emergency',
  components: {Prj1025CollapsibleCard, Prj1009Modal, Prj1001SectionButton},
  props: {
    requiredCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      emergencyOverlay: true,
      consentsChecked: false,
      activeCardId: null,
      page: null,
      subPage: null,
      subPageChildren: null,
      loader: false
    }
  },
  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),

    emergencyPageId() {
      let pageId = null;

      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'FESTIVAL_EMERGENCY_PAGE_ID') {
            pageId = parseInt(item.value);
          }
        });
      }

      return pageId;
    },
    emergencyPageTitle() {
      return this.page?.title;
    },
    emergencyPageContent() {
      return this.page?.content;
    }
  },
  watch: {
    subPage(value) {
      if (value) {
        this.getEmergencySubPages(value.id);
      }
    }
  },
  mounted() {
    this.getEmergencySubPages(this.emergencyPageId);
  },
  methods: {
    onToggleCard(id) {
      this.activeCardId = this.activeCardId === id ? null : id
    },
    getIsCardExpanded(id) {
      return this.activeCardId === id
    },
    toggleSubPage(subPage) {
      this.emergencyOverlay = !this.emergencyOverlay;
      this.subPage = this.emergencyOverlay ? null : subPage;
    },
    closeEmergency() {
      this.$emit('closeButton', this.consentsChecked);
    },
    getEmergencySubPages(id) {
      let processId = utilsGeneral.startAsyncProcess();
      this.loader = true;

      dataLoader.fetchSubPages(id)
        .then(response => {
          if (this.emergencyOverlay) {
            this.page = response;

            return;
          }

          this.subPageChildren = response;

        })
        .catch(error => {
          logger.error(error);
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId);
          this.loader = false;
        });
    },
  }
}
</script>
