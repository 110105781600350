<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div
          class="vue-intro-content"
        >
          <h1
            class="wcm-faq-sub-headline"
            v-html="dashboardContent"
          />
        </div>

        <div class="vue-b-faq-controls">
          <frm1006-button
            v-for="(item, index) in adventPosts"
            :key="index"
            :icon-right="true"
            :class="controlClassObject(item)"
            class="vue-faq-item"
            type="internal"
            @buttonClickEvent="goToView(item)"
          >
            {{ item.title }}
          </frm1006-button>
        </div>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import Vue from 'vue';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import { mapState } from 'vuex';
import { ADVENT_KEY_PREFIX, ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import router from '@/router';
import utilsGeneral from '@/utils/utils-general';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import Scroller from 'vue-scrollto';

const ADVENT_DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'AdventDashboard',
  components: {
    Prj1002Navbar,
    PageLayout
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      adventPosts: {},
      dashboardContent: null
    }
  },

  computed: {
    ...mapState('dataStore', [
      'contentData'
    ]),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),

    adventDashboardConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(ADVENT_KEY_PREFIX)) {
            const itemNameWithoutPrefix = item.key.replace(ADVENT_KEY_PREFIX, '');
            const itemName = utilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },

    adventDashboardPageId() {
      return (typeof this.adventDashboardConfig.dashboardPageId !== 'undefined' ? this.adventDashboardConfig.dashboardPageId : 0);
    },

    adventDashboardContentData() {
      return (typeof this.contentData.pages.data[this.adventDashboardPageId]?.content !== 'undefined' ? this.contentData.pages.data[this.adventDashboardPageId]?.content : false);
    },

    todayDate() {
      return new Date();
    },

    todayDateUrl() {
      return (this.todayDate).format(ADVENT_DATE_FORMAT);
    },
  },

  watch: {
    applicationConfigLoaded() {
      this.fetchDashboardData();
    },
  },

  mounted() {
    this.initialized = true;
    this.fetchAdventPosts();

    if(!this.adventDashboardContentData) {
      this.fetchDashboardData();
    } else {
      this.dashboardContent = this.adventDashboardContentData;
    }

    setTimeout(() => {
      this.$nextTick(function() {
        this.findTodayItem();
      });
    }, 1500);
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    fetchAdventPosts() {
      this.setLoadingState(true);

      dataLoader.fetchAdventPosts()
        .then(data => {
          this.adventPosts = data;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    fetchDashboardData() {
      if(this.adventDashboardPageId > 0) {
        this.setLoadingState(true);

        dataLoader.fetchSubPages(this.adventDashboardPageId)
          .then(data => {
            this.dashboardContent = data.content;
          })
          .catch(error => {
            logger.error(error);
            this.hasError = true;
          })
          .finally(() => {
            this.setLoadingState(false);
          });
      }
    },

    convertDate(datetimeString) {
      let date = new Date(utilsGeneral.normalizeDateString(datetimeString));
      // FIXME MWr: Workaround for bad convert day name - CSOBZ-563
      let dateDay = (date.getDay() === 0 ? 6 : date.getDay() - 1);
      let weekDayName = this.$i18n.t('time.days.' + dateDay);
      let weekDayNumber = Vue.filter('convertDate')(datetimeString, 'day');
      let monthNumber = Vue.filter('convertDate')(datetimeString, 'month');
      let monthName = this.$i18n.t('time.months.' + monthNumber);
      let lessonMonth = this.$i18n.t('inflection.months.2.' + monthName);
      let lessonTime = Vue.filter('convertDate')(datetimeString, 'hour') + ':' + Vue.filter('convertDate')(datetimeString, 'minutes');

      return `${weekDayName} ${weekDayNumber}. ${lessonMonth}, ${lessonTime}`;
    },

    goToView(article) {
      if(article.status === 'publish') {
        router.push({ name: 'advent-detail', params: { id: article.id }});
      }
    },

    controlClassObject(item) {
      return {
        'vue-is-disabled': item.status === 'future',
        'vue-is-today': this.todayDateShortly(item.created)
      }
    },

    todayDateShortly(created) {
      let todayDate = this.todayDateUrl.slice(8,10) + '. ' + this.todayDateUrl.slice(5,7) + '. ' + this.todayDateUrl.slice(0,4);
      let createdDate = created.slice(8,10) + '. ' + created.slice(5,7) + '. ' + created.slice(0,4);
      return todayDate === createdDate;
    },

    scrollToItem(element) {
      const scrollContainer = document.getElementById('vue-b-page-content');

      this.$nextTick(function() {
        Scroller.scrollTo(element, 500, { container: scrollContainer });
      });
    },

    findTodayItem() {
      let element = document.getElementsByClassName('vue-is-today');

      if(element.length > 0) {
        this.scrollToItem(element[0]);
        return true;
      }

      return false;
    }
  }
}
</script>
