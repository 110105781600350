export const APP_DESTINATIONS = Object.freeze({
  PUBLIC: 'public',
  PRIVATE: 'private'
});
export const APP_ENTRY_PATH_STORAGE_KEY = 'zapka-entry-path'

export const ERROR_CODES_LOGIN = Object.freeze([
  'rest_unregistered_email',
  'rest_incomplete_login',
  'rest_incorrect_code',
  'rest_unconfirmed_account',
  'rest_invalid_token',
  'rest_invalid_password',
  'rest_invalid_params',
  'internal_error',
]);
