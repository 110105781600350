<template>
  <div class="vue-c-popup">
    <!-- TODO MBU: prevent closing on esc, when another context container / overlay in content is active -->
    <gen1009-overlay
      ref="overlay"
      class="vue-is-full-screen"
      :active.sync="activeData"
      :buttonClose="true"
      :positionCustom="popupPosition"
      preventClosing="clickOutside"
      @removedEvent="closePopup"
    >
      <template v-if="activePopupModule === 'articleMenu'">
        <prj1011-popup-article-menu
          :menuData="menuData"
          :menuType="menuType"
        />
      </template>

      <template v-if="activePopupModule === 'eventSearch'">
        <h1>THERE WILL BE EVENT SEARCH</h1>
      </template>

      <template v-if="activePopupModule === 'eventFilter'">
        <prj1011-popup-event-filter />
      </template>

      <template v-if="activePopupModule === 'eventCalendar'">
        <prj1012-calendar
          usage="month"
        />
      </template>

      <template v-if="activePopupModule === 'forvardino'">
        <prj1011-popup-forvardino-filter />
      </template>

      <template v-if="activePopupModule === 'benefits'">
        <prj1011-popup-benefits-filter />
      </template>

      <template v-if="activePopupModule === 'covidCategory'">
        <prj1011-popup-covid-menu
          :menuData="menuData"
        />
      </template>

      <template v-if="activePopupModule === 'onboardingExternalFilter'">
        <prj1011-popup-onboarding-external-filter />
      </template>

      <template v-if="activePopupModule === 'onboardingApplicantsFilter'">
        <prj1011-popup-onboarding-applicants-filter />
      </template>

      <template v-if="activePopupModule === 'servicesFilter'">
        <prj1011-popup-services-filter />
      </template>
    </gen1009-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

import Prj1011PopupArticleMenu from './prj1011-popup-article-menu';
import Prj1011PopupEventFilter from './prj1011-popup-event-filter';
import Prj1011PopupForvardinoFilter from './prj1011-popup-forvardino-filter';
import Prj1011PopupBenefitsFilter from './prj1011-popup-benefits-filter';
import Prj1011PopupCovidMenu from './prj1011-popup-covid-menu';
import Prj1012Calendar from '@/components/prj1012-calendar/prj1012-calendar';
import Prj1011PopupOnboardingExternalFilter from './prj1011-popup-onboarding-external-filter';
import Prj1011PopupOnboardingApplicantsFilter from './prj1011-popup-onboarding-applicants-filter';
import Prj1011PopupServicesFilter from './prj1011-popup-services-filter';

export default {
  name: 'Prj1011Popup',
  components: {
    Prj1011PopupArticleMenu,
    Prj1011PopupEventFilter,
    Prj1011PopupForvardinoFilter,
    Prj1011PopupBenefitsFilter,
    Prj1011PopupCovidMenu,
    Prj1012Calendar,
    Prj1011PopupOnboardingExternalFilter,
    Prj1011PopupOnboardingApplicantsFilter,
    Prj1011PopupServicesFilter,
  },

  props: {
    active: Boolean,
    menuData: {
      type: [Array, Object],
      required: false,
    },
    menuType: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      activeData: false,
    }
  },

  computed: {
    ...mapState('popup', [
      'activePopupModule',
      'popupPosition',
    ])
  },

  mounted() {
    this.activeData = true;
  },

  methods: {
    closePopup() {
      this.$store.commit(STORE_MODULES.POPUP + '/' + MUTATIONS_CONSTANTS.TOGGLE_POPUP, false);
    }
  }
}
</script>
