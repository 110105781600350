import { WAITING, RUNNING, EXPIRED } from '@/constants/esg/status'
import '@/utils/dayjs'

export const eventStatus = {
  data() {
    return {
      WAITING,
      EXPIRED
    }
  },
  methods: {
    getStatus({ event, status, start, end }) {
      const startDate = this.$date(start).format('L')
      const endDate = this.$date(end).format('L')
      switch (status) {
      case WAITING:
        return this.$t(`esg.events.${status}`, { startDate })
      case RUNNING:
        return this.$t(`esg.events.${status}`, { startDate, endDate })
      case EXPIRED:
        return this.$t(`esg.${event}.expired`)
      }
    }
  }
}
