<template>
  <page-layout>
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <template v-slot:contentMain>
      <div
        class="vue-innovation-content"
      >
        <p class="vue-text">
          {{ $t('annualInnovationReport.annualReportNote') }}
        </p>

        <gen1006-info-panel
          type="neutral"
        >
          {{ $t('annualInnovationReport.annualReportSize') }}
        </gen1006-info-panel>

        <img
          class="vue-image"
          src="/img/annual-innovation-report/intro_2022.jpg"
          alt="annual innovation report banner"
        >
      </div>
    </template>

    <template v-slot:buttonFixed>
      <frm1006-button
        class="vue-button-confirm vue-button-page-layout-fixed"
        type="primary"
        @buttonClickEvent="goToReport"
      >
        <span>
          {{ $t('annualInnovationReport.goToReport') }}
        </span>
      </frm1006-button>
    </template>

    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1016-loading-indicator
        :active="!isLoaded"
        :overlay="true"
        :fullScreen="true"
      />
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import router from '@/router';
import * as APP_CONSTANTS from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

const REPORT_TYPE_ANNUAL_REPORT = 'annualReport';
const REPORT_TYPE_KATE = 'kate';

export default {
  name: 'AnnualInnovationReport',
  components: {
    PageLayout,
    Prj1002Navbar
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      isLoaded: false,
      hasError: false,
      activeSection: REPORT_TYPE_ANNUAL_REPORT,
      ERROR_MODAL_TIMEOUT: APP_CONSTANTS.ERROR_MODAL_TIMEOUT,
      REPORT_TYPE_ANNUAL_REPORT: REPORT_TYPE_ANNUAL_REPORT,
      REPORT_TYPE_KATE: REPORT_TYPE_KATE
    }
  },
  mounted() {
    this.isLoaded = true;
  },
  methods: {
    switchSection(section) {
      this.activeSection = section;
    },

    handleActiveButtonStyle(buttonType) {
      return {
        'is-active': buttonType === this.activeSection,
      }
    },

    goToReport() {
      const innovationReportRouteName = this.activeSection === REPORT_TYPE_ANNUAL_REPORT ? 'annual-innovation-report-document' : 'annual-innovation-report-kate';
      const alternativePath = this.activeSection === REPORT_TYPE_ANNUAL_REPORT && !this.$isMobile() ? 'alt' : 'for';
      const bottomIndentButton = this.activeSection === REPORT_TYPE_ANNUAL_REPORT && this.$isMobile();

      router.push({
        name: innovationReportRouteName,
        params: {
          'nav': 'opened',
          'alt': alternativePath,
          'bottomIndentButton': bottomIndentButton
        }
      });
    }
  }
}
</script>
