<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar route-name="challenges" />
        </template>
        <template v-slot:content>
          <div class="prj-layout-default h-full">
            <!--========== PAGE HEADER ============================-->
            <!--===================================================-->
            <header
              v-if="competitionDetail"
              class="mb-8"
            >
              <t-tag
                variant="sectionTitle"
                class="xs:mb-6 md:mb-8 xs:mt-6 md:mb-10"
                tag-name="h1"
                v-html="competitionDetail.name"
              ></t-tag>
              <p
                v-if="competitionDetail.perex"
                v-html="competitionDetail.perex"
              ></p>
            </header>

            <!--========== PAGE BODY ==============================-->
            <!--===================================================-->
            <section v-if="linkedPage">
              <t-tag
                v-if="linkedPage.post_title"
                variant="blockTitle"
                tag-name="h2"
                v-html="linkedPage.post_title"
              ></t-tag>
              <div
                v-if="linkedPage.post_content"
                class="prj-article"
                v-html="linkedPage.post_content"
              ></div>
              <div
                v-if="linkedSubpages"
                class="mt-6"
              >
                <template v-for="{ ID, post_title } in linkedSubpages">
                  <prj1001-section-button
                    :key="`page-${ID}`"
                    class="w-full bg-emerald-100"
                    :route-name="ROUTE_PAGE"
                    :route-params="{ id: ID }"
                  >
                    <template v-slot:text><span v-html="post_title"></span></template>
                  </prj1001-section-button>
                </template>
              </div>
            </section>
          </div>
        </template>
        <template v-slot:footer>
          <div class="prj-controls w-full">
            <t-button
              class="w-full"
              @click="goNewStory"
            >
              {{ $t("esg.competitions.cardPrimaryButton") }}
            </t-button>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>

//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex'
import { ESG_THEMES, ESG_COMPETITIONS } from '@/store/store-modules'
import { GET_THEMES_INFO, GET_COMPETITIONS_INFO } from '@/store/constants/esg/actions'
import { ROUTE_NEW_STORY, ROUTE_PAGE } from '@/constants/esg/route-names'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import PageLayout from '@/templates/esg/page-layout';
import Prj1001SectionButton from '@/components/esg/prj1001-section-button/prj1001-section-button';
import Prj1005NavigationBar from '@/components/esg/prj1005-navigation-bar/prj1005-navigation-bar'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'ESGCompetitionDetail',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1005NavigationBar,
    PageLayout,
    Prj1001SectionButton
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_PAGE,
      competitionDetail: null,
    };
  },
  computed: {
    ...mapState('esgCompetitions', ['competitionsInfo']),
    ...mapState('esgThemes', ['themesInfo']),
    competitionId() {
      return parseInt(this.$route.params.competitionId);
    },
    challengeId() {
      return this.competitionDetail?.challengeId
    },
    linkedPage() {
      return this.competitionDetail?.pages
    },
    linkedSubpages() {
      return this.linkedPage?.subPages
    },
    themeId() {
      return this.competitionDetail?.themeId
    },
    categoryId() {
      return this.competitionDetail?.categoryId
    }
  },
  async created() {
    if (!this.competitionsInfo?.length) await this.$store.dispatch(`${ESG_COMPETITIONS}/${GET_COMPETITIONS_INFO}`)
    if (!this.themesInfo?.length) await this.$store.dispatch(`${ESG_THEMES}/${GET_THEMES_INFO}`)

    this.getCompetitionById()
  },
  methods: {
    getCompetitionById() {
      this.competitionDetail = this.competitionsInfo.find(competition => competition.id === this.competitionId)
    },
    goNewStory() {
      this.$router.push({
        name: ROUTE_NEW_STORY,
        params: {
          competitionCategoryId: this.categoryId,
          competitionThemeId: this.themeId,
          competitionId: this.competitionId,
        }});
    },
  }
}
</script>
