<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div ref="component" class="vue-component vue-c-checkbox" :class="classObjectComputed">
        <div class="vue-b-form-field">
            <!-- TODO MBU: changed from span to div, reflect in demo component in catalogue? Or leave as span? -->
            <div class="vue-decorated-field">
                <input
                    :id="idComputed"
                    ref="input"
                    v-model="valueComputed"
                    type="checkbox"
                    class="vue-checkbox"
                    :name="name"
                    :disabled="disabled"
                    :value="valueNativeComputed"
                    :indeterminate.prop="indeterminate"
                    :true-value="valueTrue"
                    :false-value="valueFalse"
                    @click="checkboxClick($event.target.value, $event)"
                    @keydown="checkboxKeyDown($event.target.value, $event)"
                    @keyup="checkboxKeyUp($event.target.value, $event)"
                    @change="checkboxChange($event.target.value, $event)"
                    @focus="checkboxFocus($event.target.value, $event)"
                    @blur="checkboxBlur($event.target.value, $event)"
                />
                <!-- TODO MBU: changed from span to div, reflect in demo component in catalogue? Or leave as span? -->
                <div ref="decorator" class="vue-decorator"></div>
                <div v-if="decorator" ref="decoratorAdditional" class="vue-decorator-additional"></div>
            </div>
            <label ref="label" class="vue-label" :for="idComputed">
                <slot></slot>
            </label>
        </div>
        <gen1010-information-tooltip
            v-if="tooltipComputed"
            ref="tooltip"
            :expanded.sync="tooltipExpandedData"
            :state="state"
            :disabled="tooltipDisabled"
            :content="tooltipContent"
            :whiteList="tooltipWhiteListComputed"
            :boundComponentActive="componentIsActive"
            :boundComponentPreventLosingFocus="tooltipPreventLosingFocus"
            class="vue-ci-tooltip"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltip from '../../gen/gen1010-information-tooltip/gen1010-information-tooltip';

//=== MIXINS
import Tooltip from '../../mixins/tooltip';

// TODO MBU: move to separate folder which is included with components package.
// project needs structure refactor to separate components from the catalogue
import config from '../../../config';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1003Checkbox',
    components: {
        Gen1010InformationTooltip
    },
    mixins: [Tooltip],
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        name: {
            type: String
        },
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        // value tied to v-model
        value: {
            default: null,
            type: [String, Number, Boolean, Function, Object, Array, Symbol]
        },
        // value setting real input / HTMLInputElement(js) value - https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement, probably only useful when sending form via GET/POST
        valueNative: {
            default: null,
            type: [String, Number, Boolean, Function, Object, Array, Symbol]
        },
        valueTrue: {
            type: [String, Number, Boolean, Function, Object, Array, Symbol],
            default: true
        },
        valueFalse: {
            type: [String, Number, Boolean, Function, Object, Array, Symbol],
            default: false
        },
        indeterminate: {
            default: false,
            type: Boolean
        },
        required: {
            default: false,
            type: Boolean
        },
        disabled: {
            default: false,
            type: Boolean
        },
        //=== ADDITIONAL ELEMENTS
        decorator: {
            default: false,
            type: Boolean
        },
        //=== TOOLTIP
        focusOnTooltipOpen: {
            default: true,
            type: Boolean
        },
        tooltipPreventLosingFocus: {
            default: true,
            type: Boolean
        },
        //=== OTHER
        idPrefix: {
            default: 'frm1003',
            type: String
        },
        id: {
            default: null,
            type: String
        }
    },
    data() {
        return {
            valueData: this.value,
            checked: false,
            componentIsActive: false
        };
    },
    computed: {
        classObject() {
            return [
                'vue-is-' + this.state,
                {
                    'vue-is-required': this.required,
                    'vue-is-disabled': this.disabled,
                    'vue-is-indeterminate': this.indeterminate,
                    'vue-has-decorator': this.decorator,
                    'vue-is-checked': this.checked,
                    'vue-is-component-active': this.componentIsActive
                }
            ];
        },
        // TODO REVIEW: think of better naming
        classObjectComputed() {
            return [...this.classObject, ...this.classObjectMixinTooltip];
        },
        valueComputed: {
            get() {
                return this.valueData;
            },
            set(value) {
                this.valueData = value;
                // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
                this.$emit('change', value);
            }
        },
        valueNativeComputed() {
            if (this.valueNative === null) {
                return 'on';
            } else if (this.valueTrue !== true) {
                return this.valueTrue;
            } else {
                return this.valueNative;
            }
        },
        idComputed() {
            // manual id generation
            if (this.idPrefix && this.id) {
                return this.idPrefix + '-' + this.id;
            }
            // enforce exact id (must set id prefix to falsy value manually)
            else if (!this.idPrefix && this.id) {
                return this.id;
            }
            // automatic id generation
            return this.idPrefix + '-' + this._uid;
        }
    },
    watch: {
        value(value) {
            this.valueData = value;
            // WARNING: necessary to use next tick, so input checked property settles down after change
            this.$nextTick(() => {
                this.checked = this.$refs.input.checked;
            });
        },
        // TODO MBU: is not registered when not using v-model (using just value)
        checked() {
            this.checkboxCheck(this.valueNative, this.checked);
            // close tooltip upon interacting with checkbox
            this.tooltipExpandedData = false;
        },
        tooltipExpandedData() {
            this.setComponentActiveState();
            if (this.focusOnTooltipOpen && !this.componentIsActive && this.tooltipExpandedData) {
                this.$refs.input.focus();
            }
        },
        componentIsActive(value) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('componentIsActiveEvent', value, this.idComputed);
        },
        tooltipWhiteListInitial(value) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('tooltipWhiteListInitial', value);
        }
    },
    mounted() {
        // WARNING: this invokes radioCheckEvent on mounted
        this.checked = this.$refs.input.checked;

        //=== TOOLTIP
        this.tooltipExpandedData = this.tooltipExpanded;
        if (this.tooltipWhiteListInitialInit) {
            this.setTooltipWhiteListInitial();
        }

        this.$emit('componentIsActiveEvent', this.componentIsActive, this.idComputed);
    },
    methods: {
        //=== GENERAL
        inputSetFocus() {
            this.$refs.input.focus();
        },
        inputSetBlur() {
            this.$refs.input.blur();
        },
        setComponentActiveState() {
            this.componentIsActive = this.tooltipWhiteListComputed.indexOf(document.activeElement) > -1;
        },
        //=== EVENTS
        checkboxClick(valueNative, event) {
            this.$nextTick(() => {
                // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
                this.$emit('checkboxClickEvent', valueNative, event);
            });
        },
        checkboxKeyDown(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('checkboxKeyDownEvent', valueNative, event);
        },
        checkboxKeyUp(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('checkboxKeyUpEvent', valueNative, event);
        },
        checkboxChange(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('checkboxChangeEvent', valueNative, event);
        },
        checkboxFocus(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('checkboxFocusEvent', valueNative, event);
            this.setComponentActiveState();
            // TODO REVIEW: improve readability, extract into several named statements
            if (
                this.tooltipComputed &&
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
        },
        checkboxBlur(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('checkboxBlurEvent', valueNative, event);
            this.setComponentActiveState();
        },
        checkboxCheck(valueNative, checked) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('checkboxCheckEvent', valueNative, checked);
        },
        //=== TOOLTIP
        setTooltipWhiteListInitial() {
            this.tooltipWhiteListInitial.push(this.$refs.input);
            this.tooltipWhiteListInitial.push(this.$refs.decorator);

            if (this.$refs.decoratorAdditional !== undefined) {
                this.tooltipWhiteListInitial.push(this.$refs.decoratorAdditional);
            }

            if (this.$refs.label !== undefined) {
                this.tooltipWhiteListInitial.push(this.$refs.label);
            }
        }
    }
};
</script>
