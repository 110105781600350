<template>
  <div class="vue-c-event-list">
    <prj1023-my-actions
      v-if="eventsAreLoaded && myEvents.length > 0"
      :eventsData="myEvents"
      component-type="events"
    />

    <prj1027-event-filter
      v-if="showFilter"
      :displayFilterButton="displayFilterButton"
      @openFilterDialog="openFilterDialog"
      @resetFilteredItems="resetFilteredEvents"
    />

    <template
      v-if="sortedEvents"
    >
      <template
        v-for="year in sortedEvents"
      >
        <template
          v-for="(month, monthValue) in year"
        >
          <template
            v-for="(day, dayValue) in month"
          >
            <div
              :key="'item-' + monthValue + dayValue"
              class="vue-event-list-day"
            >
              <span class="vue-event-list-day-item">
                <span
                  class="vue-event-list-day-name"
                  v-html="$t('time.days.' + day[0].dayShort)"
                />
                {{ dayValue }}. {{ monthValue }}.
              </span>
            </div>
            <div
              :key="'items-' + monthValue + dayValue"
              class="vue-event-list-items"
            >
              <prj1013-event-list-item
                v-for="(item, index) in day"
                :key="'day-item-' + item.id + '-' + index"
                :itemData="item"
              />
            </div>
          </template>
        </template>
      </template>
    </template>

    <div
      v-if="isInfinityScrollEnabled"
      ref="infinityScrollTrigger"
    >
      <gen1016-loading-indicator
        :active="isInfinityScrollEnabled"
      />
    </div>

    <gen1006-info-panel
      v-if="!events.length"
      type="neutral"
      class="vue-no-data"
    >
      {{ $t('myActions.noFilteredEvents') }}
    </gen1006-info-panel>

    <gen1009-overlay
      class="vue-is-info-panel"
      :active.sync="hasError"
      :buttonClose="true"
      :closeAfter="ERROR_MODAL_TIMEOUT"
    >
      <gen1006-info-panel
        type="error"
      >
        {{ $t('error.generic') }}
      </gen1006-info-panel>
    </gen1009-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { DEFAULT_EVENT_PAGINATION, ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';
import utilsGeneral from '@/utils/utils-general';
import mxInfinityScrolling from '@/mixins/mx-infinity-scrolling';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

import Prj1013EventListItem from './prj1013-event-list-item';
import Prj1023MyActions from '@/components/prj1023-my-actions/prj1023-my-actions';
import Prj1027EventFilter from '@/components/prj1027-event-filter/prj1027-event-filter';
import * as MUTATIONS_CONSTANTS from '../../store/constants/mutations';

export default {
  name: 'Prj1013EventList',
  components: {
    Prj1013EventListItem,
    Prj1023MyActions,
    Prj1027EventFilter,
  },

  mixins: [
    mxInfinityScrolling,
    mxDetectDesktop
  ],

  props: {
    showFilter: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      events: [],
      eventsAreLoaded: false,
      eventsAreNoMore: false,
      hasError: false,
      myEventsData: [],
      currentPositon: 0,
      defaultEventsNumber: DEFAULT_EVENT_PAGINATION,
      showSyncReminder: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    ...mapState('events', [
      'eventsCategories',
      'eventsSelectedCategories',
      'searchQuery'
    ]),

    sortedEvents() {
      return utilsGeneral.sortEventObect(this.events);
    },

    myEvents() {
      return this.myEventsData;
    },

    eventsArray() {
      return Object.values(this.events);
    },

    isInfinityScrollEnabled() {
      return !this.eventsAreNoMore;
    },

    displayFilterButton() {
      return !this.eventsSelectedCategories.length > 0;
    }
  },

  watch: {
    eventsSelectedCategories() {
      this.fetchEvents();
    },

    searchQuery() {
      this.fetchEvents();
    }
  },

  created() {
    this.fetchEvents();
    this.fetchMyEvents();
  },

  methods: {
    fetchEvents() {
      const defaultEventsOffset = 0;
      // TODO MBU: handle loader controls external loadingIndicator, there should be aggregated data variable
      // for multiple fetches, now first fetch hides loadingIndicator, but other fetches are still in progress
      this.$emit('handleLoader', true);

      if (!this.eventsCategories.length) {
        dataLoader.fetchEventsFilterCategories()
          .then(categories => {
            this.$store.commit(STORE_MODULES.EVENTS + '/' + MUTATIONS_CONSTANTS.SET_EVENTS_CATEGORIES, categories);
            this.$emit('handleLoader', false);
          }).catch(error => {
            logger.error(error);
            this.hasError = true;
            this.$emit('handleLoader', false);
          })
          .finally(() => {});
      }

      dataLoader.fetchEventsFilter(this.eventsSelectedCategories, this.searchQuery, defaultEventsOffset, this.defaultEventsNumber)
        .then(eventsData => {
          const eventsLength = Object.keys(eventsData).length;

          if (eventsLength < DEFAULT_EVENT_PAGINATION) {
            this.eventsAreNoMore = true;
          } else {
            this.eventsAreNoMore = false;
            this.$nextTick(() => {
              this.initInfinityScroll();
            });
          }

          this.events = Object.values(eventsData);
          this.eventsAreLoaded = true;
          this.$emit('handleLoader', false);
        })
        .catch(error => {
          logger.error(error);
          this.$emit('handleLoader', false);
          this.hasError = true;
          this.eventsAreNoMore = true;

        })
        .finally(() => {})
    },

    async loadNewContent() {
      if (this.eventsAreNoMore) return;
      this.currentPositon += DEFAULT_EVENT_PAGINATION;

      await dataLoader.fetchEventsFilter(this.eventsSelectedCategories, this.searchQuery, this.currentPositon, this.defaultEventsNumber)
        .then(newEventsData => {
          let tempArray = this.events;
          this.events = [...tempArray, ...Object.values(newEventsData)];

          if (Object.keys(newEventsData).length < DEFAULT_EVENT_PAGINATION) {
            this.eventsAreNoMore = true;
          }
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
          this.eventsAreNoMore = true;
        })
        .finally(() => {});
    },

    fetchMyEvents() {
      this.$emit('handleLoader', true);

      dataLoader.fetchMyEvents()
        .then(myEventsData => {
          this.myEventsData = myEventsData;
          this.$emit('handleLoader', false);
        }).catch(error => {
          logger.error(error);
          this.hasError = true;
          this.$emit('handleLoader', false);
        })
        .finally(() => {
          this.$emit('handleLoader', false);
        });
    },

    openFilterDialog(event) {
      let contextContainerPositions = false;
      if (this.isDesktopLayout) {
        contextContainerPositions = {
          x: event.pageX,
          y: false,
          mode: 'top-left'
        }
      }
      this.$store.dispatch(STORE_MODULES.POPUP + '/' + ACTIONS_CONSTANTS.POPUP_POSITION, contextContainerPositions);
      this.$store.dispatch(STORE_MODULES.POPUP + '/' + ACTIONS_CONSTANTS.TOGGLE_POPUP, 'eventFilter');
    },

    resetFilteredEvents() {
      this.$store.dispatch(STORE_MODULES.EVENTS + '/' + ACTIONS_CONSTANTS.RESET_ALL_EVENTS);
    },
  }
}
</script>
