<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1017-heading
        :header="pageCaption"
      />

      <div class="vue-b-info-controls">
        <frm1006-button
          v-for="(item, index) in subPages"
          :key="index"
          :icon-right="true"
          :disabled="!applicationConfigLoaded"
          class="vue-control-item"
          type="internal"
          @buttonClickEvent="goToView(item.id, item.slug)"
        >
          {{ item.title }}
        </frm1006-button>
        <frm1006-button
          :icon-right="true"
          :disabled="!applicationConfigLoaded"
          class="vue-control-item"
          type="internal"
          mode="anchor"
          :link="`mailto:${$t('forvardino.helpDeskEmail')}`"
        >
          {{ $t('forvardino.askingForTopic') }}
        </frm1006-button>
      </div>
      <gen1006-info-panel
        v-if="!isLoading && !initialized"
        type="neutral"
      >
        {{ $t('general.loadingFailed') }}
      </gen1006-info-panel>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';
import { FORVARDINO } from '@/store/store-modules';
import { mapState } from 'vuex';
import router from '@/router';
import * as STORE_MODULES from '@/store/store-modules';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import * as MUTATION_CONSTANTS from '@/store/constants/mutations';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

export default {
  name: 'ForvardinoInfo',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
    };
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),
    ...mapState('forvardino', [
      'forvardinoPages',
      'forvardinoPagesLastUpdate'
    ]),

    pageId() {
      let tmpPageId = -1;
      if (this.applicationConfigLoaded) {
        let tmpCustomFields = this.applicationConfig.customFields;
        tmpCustomFields.forEach(item => {
          if (item.key === 'FORVARDINO_PAGE_ID') {
            tmpPageId = parseInt(item.value);
          }
        });
      }

      return tmpPageId;
    },

    pageCaption() {
      return this.$route.meta.navbarCaption;
    },

    subPages() {
      if (Object.keys(this.forvardinoPages).length === 0) {
        return false;
      }

      if (Object.prototype.hasOwnProperty.call(this.forvardinoPages, 'items')) {
        return this.forvardinoPages['items'];
      }

      return false;
    }
  },

  watch: {
    pageId() {
      this.fetchForvardinoPages();
    },

    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        if (this.articleData) {
          this.$refs.pageLayout.pageAsyncDataLoadedDone();
        }
      }
    },
  },

  mounted() {
    if(!this.subPages) {
      this.fetchForvardinoPages();
    } else {
      this.isLoading = false;
    }

    this.initialized = true;
    this.sendStatistics(FORVARDINO);
  },

  methods: {
    fetchForvardinoPages() {
      dataLoader.fetchSubPages(this.pageId)
        .then(pages => {
          this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATION_CONSTANTS.SET_FORVARDINO_PAGES, pages);
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
    },

    goToView(articleId, articleSlug) {
      router.push({ name: 'forvardino-info-page', params: { id: articleId, name: articleSlug }});
    },
  }
}
</script>
