import makeSentence from './make-sentence';
import convertStock from './convert-stock';
import convertDate from './convert-date';

export default {
  install(Vue) {
    Vue.filter('makeSentence', makeSentence);
    Vue.filter('convertStock', convertStock);
    Vue.filter('convertDate', convertDate);
  }
}
