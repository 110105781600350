<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template v-if="initialized && !isLoading">
        <template
          v-if="!completedForm"
        >
          <prj1017-heading
            :header="$i18n.t('faultReporting.headline')"
          />

          <frm1035-form-group>
            <frm1028-advanced-combo
              id="selected-building"
              v-model="faultReportingForm.selectedBuilding"
              :options="formData.buildings"
              :searchable="formData && formData.buildings && formData.buildings.length > 6"
              :tooltip="false"
              :label="$i18n.t('faultReporting.building')"
              :placeholder="$i18n.t('faultReporting.buildingPlaceholder')"
            />
          </frm1035-form-group>

          <frm1035-form-group
            v-if="showBranches"
          >
            <frm1028-advanced-combo
              id="selected-branch"
              v-model="faultReportingForm.selectedBranch"
              :options="formDataBranches"
              :searchable="true"
              :tooltip="false"
              :label="$i18n.t('faultReporting.branch')"
              :placeholder="$i18n.t('faultReporting.branchPlaceholder')"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1001-input-field
              id="room-number"
              v-model="faultReportingForm.faultRoom"
              :tooltip="false"
              :label="$i18n.t('faultReporting.room')"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1028-advanced-combo
              id="fault-category"
              v-model="faultReportingForm.faultCategory"
              :options="categoryList"
              :tooltip="false"
              :label="$i18n.t('faultReporting.category')"
              :placeholder="$i18n.t('faultReporting.categoryPlaceholder')"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1013-textarea
              id="fault-text"
              v-model="faultReportingForm.faultText"
              class="vue-fault-reporting-textarea"
              :class="classObject"
              :tooltip="false"
              :label="$i18n.t('faultReporting.fault')"
              :rowsAutoExpand="true"
              :rows="1"
              :rowsAutoExpandMinRows="1"
              :rowsAutoExpandMaxRows="5"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1001-input-field
              id="fault-phone-number"
              v-model="faultReportingForm.faultPhoneNumber"
              class="vue-fault-reporting-item"
              :tooltip="false"
              :label="$i18n.t('faultReporting.phoneNumber')"
            />
          </frm1035-form-group>

          <gen1006-info-panel type="neutral">
            {{ $t('faultReporting.note') }}
          </gen1006-info-panel>

          <prj1010-image-upload
            v-if="!faultReportingForm.faultImage"
            class="vue-fault-reporting-upload-image"
            @uploadedPhoto="processedPhoto"
          />

          <frm1035-form-group>
            <div
              class="vue-camera-canvas"
            >
              <canvas
                ref="faultReportingPictureCameraCanvas"
                class="vue-camera-canvas-element"
                width="240"
                height="320"
              />

              <div
                v-if="faultReportingForm.faultImage"
                class="vue-fault-reporting-uploaded-file"
              >
                <span class="vue-fault-reporting-photo-name">
                  {{ faultReportingForm.faultImageName }}
                </span>
                <frm1006-button
                  class="vue-button-remove-photo"
                  type="internal"
                  :iconLeft="true"
                  :captionHidden="true"
                  @buttonClickEvent="removeTakenPicture"
                >
                  {{ $t('faultReporting.removePhoto') }}
                </frm1006-button>
              </div>
            </div>
          </frm1035-form-group>
        </template>
        <div
          v-else
          class="vue-b-form-success"
        >
          <prj1017-heading
            :header="$i18n.t('faultReporting.thankyou.heading')"
            :subheader="$i18n.t('faultReporting.thankyou.subheading')"
          />
        </div>
      </template>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <template
        v-if="!completedForm"
      >
        <frm1006-button
          class="vue-fault-reporting-submit-button"
          :class="classObject"
          type="primary"
          :disabled="!isFormValid || sending || reportSent"
          @buttonClickEvent="submitFaultReport"
        >
          {{ $t('faultReporting.submit') }}
        </frm1006-button>
      </template>
      <template v-else>
        <frm1006-button
          type="primary"
          @buttonClickEvent="goToDashboard"
        >
          {{ $t('faultReporting.thankyou.backToDash') }}
        </frm1006-button>

        <frm1006-button
          type="secondary"
          @buttonClickEvent="clearForm"
        >
          {{ $t('faultReporting.thankyou.nextFaultReporting') }}
        </frm1006-button>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
// TODO MSE: improve - add to PWA Config when implementing CSOBZ-288
import dataLoader from '@/utils/data-loader/index';
import logger from '@/utils/logger';
import {
  ERROR_MODAL_TIMEOUT,
  CSOB_BUILDINGS_LIST_ID,
  FAULT_REPORTING_CATEGORIES_LIST_ID
} from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1010ImageUpload from '@/components/prj1010-image-upload/prj1010-image-upload';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import { FAULT_REPORTING } from '@/store/store-modules';
import moduleStatistics from '@/mixins/mx-module-statistics';

export default {
  name: 'Reporting',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1010ImageUpload,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      completedForm: false,
      hasError: false,
      formData: {
        buildings: [],
        categories: [],
      },
      faultReportingForm: {
        selectedBuilding: '',
        selectedBranch: '',
        faultRoom: '',
        faultCategory: '',
        faultText: '',
        faultPhoneNumber: '',
        faultImage: null,
        faultImageName: ''
      },
      refElements: {
        player: null,
        canvas: null,
        fileUpload: null
      },
      pictureOptions: {
        camera: false,
        fileUpload: true
      },
      sending: false,
      reportSent: false,
      reportFailed: false,
      isLoading: true,
      initialized: false
    }
  },

  computed: {
    classObject() {
      return {
        'vue-is-active': this.isFormValid,
      }
    },

    isFormValid() {
      const requiredFields = [
        'faultRoom',
        'faultPhoneNumber',
        'faultText',
        'faultCategory',
        'selectedBuilding'
      ];

      if (this.showBranches) {
        requiredFields.push('selectedBranch');
      }

      let decision = false;
      let numberOfValidInputs = 0;

      requiredFields.forEach(item => {
        if (this.faultReportingForm[item].length) {
          numberOfValidInputs++;
        } else {
          numberOfValidInputs--;
        }
      });

      if (numberOfValidInputs === requiredFields.length) {
        decision = true;
      }

      return decision;
    },

    showBranches() {
      let retValues = false;
      Object.values(this.formData.buildings).forEach((item) => {
        if (item.value === this.faultReportingForm.selectedBuilding && item.branches.length > 0) {
          retValues = true;
        }
      });
      return retValues;
    },

    formDataBranches() {
      let retValues = [];
      Object.values(this.formData.buildings).forEach((item) => {
        if (item.value === this.faultReportingForm.selectedBuilding && item.branches.length > 0) {
          retValues = item.branches;
        }
      });
      return retValues;
    },

    categoryList() {
      let categories = Object.values(this.formData.categories).filter(cat => cat.building === 'all');
      let extraCategories = Object.values(this.formData.categories).filter(cat => cat.building === this.faultReportingForm.selectedBuilding);
      if (extraCategories.length > 0) {
        categories = categories.concat(extraCategories);
      }
      return categories;
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },
  },

  mounted() {
    this.getFaultReportingFormData();
    this.refElements.canvas = this.$refs.faultReportingPictureCameraCanvas;
    this.refElements.fileUpload = this.$refs.faultReportingPictureFile;
    this.sendStatistics(FAULT_REPORTING);
    this.initialized = true;
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    processedPhoto(photoObject) {
      this.faultReportingForm.faultImage = photoObject.blob;
      this.faultReportingForm.faultImageName = photoObject.name;
    },

    removeTakenPicture() {
      this.faultReportingForm.faultImage = null;
      this.faultReportingForm.faultImageName = '';
    },

    submitFaultReport() {
      let { canvas } = this.refElements;
      let imageBinary;
      let imageBase64;

      this.sending = true;

      if (this.pictureOptions.camera) {
        imageBase64 = canvas.toDataURL('image/jpeg');
      }

      if (this.pictureOptions.fileUpload && this.faultReportingForm.faultImage) {
        imageBinary = this.faultReportingForm.faultImage;
      }

      if (!this.showBranches) {
        this.faultReportingForm.selectedBranch = '';
      }

      dataLoader.sendFaultReport(
        this.faultReportingForm.selectedBuilding,
        this.faultReportingForm.selectedBranch,
        this.faultReportingForm.faultCategory,
        this.faultReportingForm.faultRoom,
        this.faultReportingForm.faultPhoneNumber,
        this.faultReportingForm.faultText,
        imageBase64,
        imageBinary
      ).then(() => {
        logger.info('Fault reporting post has been sent!');
        this.reportSent = true;
        this.completedForm = true;
      }).catch(error => {
        logger.error('Sending fault report has failed:', error);
        this.reportFailed = true;
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.sending = false;
      })
    },

    getFaultReportingFormData() {
      this.setLoadingState(true);

      const dataListsIdsArray = [
        CSOB_BUILDINGS_LIST_ID,
        FAULT_REPORTING_CATEGORIES_LIST_ID
      ];

      dataLoader.fetchMultipleDataListsById(dataListsIdsArray)
        .then(data => {
          data.forEach(response => {
            const categoryType = Object.keys(response.data);
            this.formData[categoryType] = response.data[categoryType];
          })
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    goToDashboard() {
      this.$router.push('/dashboard')
    },

    clearForm() {
      this.faultReportingForm.selectedBuilding = '';
      this.faultReportingForm.faultCategory = '';
      this.faultReportingForm.faultRoom = '';
      this.faultReportingForm.faultPhoneNumber = '';
      this.faultReportingForm.faultText = '';
      this.faultReportingForm.faultImage = null;
      this.faultReportingForm.faultImageName = '';
      this.completedForm = false;
      this.reportSent = false;
      this.hasError = false;
    }
  }
}
</script>
