<template>
  <div
    class="vue-c-directory-list-item"
    :class="{'vue-is-private': itemData.privateOnly, 'vue-is-disabled': itemData.privateOnly && !isPrivateApp, 'vue-is-desktop': itemData.desktopOnly}"
  >
    <div class="vue-b-logo-wrapper">
      <a
        :href="itemData.url"
        target="_blank"
        class="vue-app-link"
      >
        <div
          class="vue-item-letter"
          :style="'color:' + itemData.iconColor"
        >
          {{ getFirstLetter(itemData.name) }}

          <div
            v-if="itemData.iconUrl"
            v-lazy:background-image="itemData.iconUrl"
            class="vue-item-logo"
          />
        </div>
      </a>
    </div>

    <div class="vue-b-content">
      <a
        :href="itemData.url"
        target="_blank"
        class="vue-app-link"
      >
        <div class="vue-app-headline">
          {{ itemData.name }}
          <i
            v-if="itemData.maintenanceActive"
            class="vue-icon-maintenance"
          ></i>
        </div>
        <div
          class="vue-app-category"
        >
          {{ itemData.categoriesNames }}
        </div>
      </a>
    </div>

    <div class="vue-b-controls">
      <frm1006-button
        :icon-left="true"
        class="vue-favorite-button"
        :class="{'vue-is-pinned-off': itemData.favorite}"
        type="internal"
        @buttonClickEvent="clickEventFavorite(itemData)"
      />

      <frm1006-button
        :icon-left="true"
        class="vue-info-button"
        type="internal"
        @buttonClickEvent="clickEventDetail(itemData)"
      />
    </div>
  </div>
</template>

<script>
import mxInfinityScrolling from '@/mixins/mx-infinity-scrolling';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

import {
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import { mapState } from 'vuex';

export default {
  name: 'Prj1052DirectoryListItem',
  components: {},

  mixins: [
    mxInfinityScrolling,
    mxDetectDesktop
  ],

  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hasError: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    ...mapState('user', [
      'profile',
    ]),

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },
  },

  mounted() {},

  methods: {
    clickEventDetail(detail) {
      this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_DETAIL, true);
      this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_DETAIL_APP, detail);
    },

    clickEventFavorite(item) {
      if (item.favorite) {
        this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.SET_DIRECTORY_ITEM_FAVORITE_OFF, item)
      } else {
        this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.SET_DIRECTORY_ITEM_FAVORITE, item)
      }
    },

    getFirstLetter(name) {
      return name.substring(0, 1);
    }
  }
}
</script>
