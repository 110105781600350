import axiosConfig from '@/utils/data-loader/axiosConfig'
import * as envConfig from 'env-config'

export default {
  getAllChallenges() {
    return axiosConfig.get(envConfig.default.webServices.ESG_WEB_SERVICES.CMS_CONTENT_CHALLENGES).then((response) => response.data)
  },
  getChallengeById(id) {
    return axiosConfig
      .get(envConfig.default.webServices.ESG_WEB_SERVICES.CMS_CONTENT_CHALLENGES + '/' + id + '?_embed')
      .then((response) => response.data)
  },
  getChallengesByThemeId({ id }) {
    return axiosConfig
      .get(`${envConfig.default.webServices.ESG_WEB_SERVICES.CMS_CONTENT_CHALLENGES}?filter[themeId]=${id}`)
      .then((response) => response.data)
  },
  sendChallenge({ challengeId, competitionId, themeId, categoryId, challengeValue, challengeValueTypeId, day }) {
    let data = {
      id: challengeId,
      competition_id: competitionId,
      theme_id: themeId,
      category_id: categoryId,
      value: challengeValue,
      value_type: challengeValueTypeId,
      day: day,
    }
    return axiosConfig
      .post(envConfig.default.webServices.ESG_WEB_SERVICES.CMS_CONTENT_CHALLENGE, data)
      .then((response) => response.data)
  },
  getChallengeUserHistory() {
    return axiosConfig
      .get(envConfig.default.webServices.ESG_WEB_SERVICES.CMS_CONTENT_CHALLENGES + '/history')
      .then((response) => response.data)
  },
}
