<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <!-- Button prev -->
    <li v-if="itemType === 'buttonPrev'" class="vue-pagination-item vue-is-prev">
        <Frm1006Button
            :type="buttonType"
            :disabled="disabled"
            class="vue-ci-pagination-button vue-ci-pagination-button-direction vue-ci-pagination-button-prev"
            @buttonClickEvent="paginationItemButtonPrevClickEmit"
        >
            <slot></slot>
        </Frm1006Button>
    </li>
    <!-- Button page -->
    <li v-else-if="itemType === 'buttonPage'" class="vue-pagination-item vue-is-page">
        <Frm1006Button
            :type="buttonType"
            :page="page"
            :disabled="disabled"
            class="vue-ci-pagination-button vue-ci-pagination-button-page"
            :class="buttonClassObject"
            @buttonClickEvent="paginationItemButtonPageClickEmit(page)"
        >
            <slot></slot>
        </Frm1006Button>
    </li>
    <!-- Input -->
    <li v-else-if="itemType === 'input'" class="vue-pagination-item vue-is-input">
        <Frm1034InputNumber
            :value="pageCurrent"
            :min="1"
            :max="pagesTotal"
            :unit="pagesTotalWithSeparator"
            :liveUpdate="false"
            :tooltip="false"
            class="vue-ci-pagination-input-field-page"
            :selectValueOnFocus="true"
            @update="paginationItemInputChangePageEmit"
        />
    </li>
    <!-- Frm1006Button next -->
    <li v-else-if="itemType === 'buttonNext'" class="vue-pagination-item vue-is-next">
        <Frm1006Button
            :type="buttonType"
            :disabled="disabled"
            class="vue-ci-pagination-button vue-ci-pagination-button-direction vue-ci-pagination-button-next"
            @buttonClickEvent="paginationItemButtonNextClickEmit(page)"
        >
            <slot></slot>
        </Frm1006Button>
    </li>
    <!-- Ellipsis -->
    <li v-else-if="itemType === 'ellipsis'" class="vue-pagination-item vue-is-ellipsis">
        <slot></slot>
    </li>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//
// import Frm1001InputField from '../../frm/frm1001-input-field/frm1001-input-field';
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';
import Frm1034InputNumber from '../../frm/frm1034-input-number/frm1034-input-number';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Tbl1002PaginationItem',
    components: {
        Frm1034InputNumber,
        Frm1006Button
    },
    props: {
        itemType: {
            default: null,
            type: String
        },
        buttonType: {
            default: 'internal',
            type: String
        },
        page: {
            default: null,
            type: Number
        },
        pagesTotal: {
            default: null,
            type: Number
        },
        pageCurrent: {
            default: null,
            type: Number
        },
        disabled: {
            default: false,
            type: Boolean
        },
        id: {
            default: null,
            type: String
        }
    },
    data() {
        return {
            inputFocus: false
        };
    },
    computed: {
        buttonClassObject: function() {
            return {
                'vue-ci-pagination-button-page-current': this.page === this.pageCurrent
            };
        },
        pagesTotalWithSeparator() {
            return '/ ' + this.pagesTotal;
        }
    },
    methods: {
        paginationItemButtonPrevClickEmit() {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('paginationItemButtonPrevClickEvent');
        },
        paginationItemButtonPageClickEmit(page) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('paginationItemButtonPageClickEvent', page);
        },
        paginationItemInputChangePageEmit(page) {
            let pageAsNumber = parseInt(page);
            if (pageAsNumber && pageAsNumber > 0) {
                this.$emit('paginationItemInputChangePageEvent', pageAsNumber);
            }
        },
        paginationItemButtonNextClickEmit() {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('paginationItemButtonNextClickEvent');
        }
    }
};
</script>
