<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaptionCustom"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="navigateBack"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div
          v-for="(message, index) in messages"
          :key="index"
          :class="getStateClass(message.state)"
          class="vue-c-message"
        >
          <h2>{{ getModuleCaption(message.module) }}</h2>

          <ul>
            <li class="vue-b-message-data">
              <div class="vue-b-message-data-row">
                <span class="vue-caption">{{ $i18n.t('messages.labels.state') }}:</span>
                <span class="vue-value">{{ getStateCaption(message.state) }}</span>
              </div>

              <div class="vue-b-message-data-row">
                <span class="vue-caption">{{ $i18n.t('messages.labels.answer') }}:</span>
                <span class="vue-value">{{ message.answer }}</span>
              </div>
            </li>
          </ul>

          <gen1015-section
            :collapsible="true"
            :collapsed="true"
            class="is-animated"
          >
            <template v-slot:header>
              <h3>{{ $i18n.t('messages.labels.question') }}:</h3>
            </template>
            <template v-slot:content>
              <div class="vue-b-content-animated">
                <div class="vue-c-article-body vue-wcm-content">
                  <ul>
                    <li class="vue-b-message-data">
                      <div
                        v-for="(originItem, originIndex) in message.source"
                        :key="originIndex"
                        class="vue-b-message-data-row"
                      >
                        <span class="vue-caption">{{ getCaptionName(originIndex) }}:</span>
                        <span class="vue-value">{{ originItem }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </gen1015-section>

          <div class="vue-b-controls">
            <frm1006-button
              :icon-left="true"
              class="vue-delete-button"
              type="internal"
              @buttonClickEvent="deleteMessage(message.id)"
            >
              {{ $i18n.t('messages.deleteMessage') }}
            </frm1006-button>
          </div>
        </div>

        <gen1006-info-panel
          v-if="!isLoading && !initialized"
          type="neutral"
        >
          {{ $t('general.loadingFailed') }}
        </gen1006-info-panel>

        <gen1006-info-panel
          v-if="!messages.length"
          type="neutral"
          class="vue-no-data"
        >
          {{ $t('messages.noMessagesFound') }}
        </gen1006-info-panel>
      </template>
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <prj1026-category-filter
        :filterCategories="convertedFilterCategories"
        :showItems="convertedFilterCategories.length"
        :showFilterButton="messagesAreFiltersActive"
        :activeCategory="parseInt(messagesActiveModule)"
        filterType="messages"
        @filterItems="filterItems"
        @resetFilteredItems="resetItems"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {mapState} from 'vuex';
import router from '@/router';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as STORE_MODULES from '@/store/store-modules';import PageLayout from '../../templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

import mxNavigationDefault from '../../mixins/mx-navigation-default';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter.vue';
import dataLoader from '@/utils/data-loader';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import logger from '@/utils/logger';

export default {
  name: 'Messages',

  components: {
    Prj1026CategoryFilter,
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      navbarCaptionCustom: this.navbarCaption,
      messages: []
    }
  },

  computed: {
    ...mapState('user', ['profile', 'messagesActiveModule', 'messagesAreFiltersActive']),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),

    paramModule() {
      if(typeof this.$route.params?.module === 'undefined') {
        return 'none';
      }
      return this.$route.params.module
    },

    paramCaption() {
      if(typeof this.$route.params?.caption === 'undefined') {
        return 'none';
      }
      return this.$route.params.caption
    },

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },

    convertedFilterCategories() {
      let convertedFilterCategories = [];

      convertedFilterCategories.push({ 'termId': 1, 'termName': 'Aktivní' });
      convertedFilterCategories.push({ 'termId': 2, 'termName': 'Uzavřený' });

      return convertedFilterCategories;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.initializePage();
    this.fetchMessagesData();
  },

  methods: {
    initializePage() {
      if (this.paramCaption !== 'none') {
        this.navbarCaptionCustom = this.paramCaption
      }
      this.initialized = true;
    },

    async fetchMessagesData(type = 'none') {
      dataLoader.fetchMessages(type)
        .then(data => {
          if (data) {
            let tmpData = data;
            tmpData.forEach(item => {
              let tmpSource = JSON.parse(item.source);
              item.source = tmpSource;

            });
            this.messages = tmpData;
            this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_USER_MESSAGES, tmpData);
          } else {
            this.messages = [];
            this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_USER_MESSAGES, []);
          }
        });
    },

    goToView(name) {
      router.push({ name: name });
    },

    filterItems(categoryNumber) {
      let selectedCategoryPayload = {
        modules: [categoryNumber]
      };

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_USER_MESSAGES_SELECTED_CATEGORIES, selectedCategoryPayload);
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_USER_MESSAGES_ACTIVE_MODULE, categoryNumber);
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_USER_MESSAGES_FILTER_ACTIVE, false);

      this.fetchMessagesData(this.getStateType(categoryNumber));
    },

    resetItems() {
      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.RESET_ALL_MESSAGES_FILTER)
        .then(() => {
          this.fetchMessagesData();
        });
    },

    getCaptionName(key) {
      let retValue = '';

      switch (key) {
      case 'name':
        retValue = this.$i18n.t('forms.name');
        break;
      case 'email':
        retValue = this.$i18n.t('forms.email');
        break;
      case 'phone':
        retValue = this.$i18n.t('forms.phoneNumber');
        break;
      case 'description':
        retValue = this.$i18n.t('forms.message');
        break;
      case 'filter':
        retValue = this.$i18n.t('messages.usedFilter');
        break;
      case 'interest':
        retValue = this.$i18n.t('recruitment.interestedIn');
        break;
      }

      return retValue;
    },

    getModuleCaption(module) {
      let retValue = '';

      switch (module) {
      case 'hiring_rotation':
        retValue = this.$i18n.t('messages.hiringRotation');
        break;
      }

      return retValue;
    },

    getStateCaption(state) {
      let retValue = '';

      switch (state) {
      case '1':
        retValue = this.$i18n.t('messages.states.active');
        break;
      case '0':
        retValue = this.$i18n.t('messages.states.closed');
        break;
      }

      return retValue;
    },

    getStateType(state) {
      let retValue = '';

      switch (state) {
      case 1:
        retValue = 'active';
        break;
      case 2:
        retValue = 'closed';
        break;
      }

      return retValue;
    },

    getStateClass(state) {
      return {
        ['vue-is-closed']: state === '0',
      }
    },

    navigateBack() {
      if (this.historyBack) {
        router.go(-1);
      }

      if (this.paramModule === '') {
        this.$router.push({name: 'dashboard'})
      } else if (this.paramModule === 'recruitment') {
        this.$router.push({name: 'recruitment-dashboard'})
      } else if (this.$route.params.origin) {
        this.$router.push({ name: this.$route.params.origin})
      } else {
        this.$router.push({name: 'recruitment-dashboard'})
      }
    },

    deleteMessage(id) {
      this.isLoading = true;
      dataLoader.deleteMessage(id)
        .then(() => {
          this.isLoading = false;
          this.fetchMessagesData();
        }, (error) => {
          this.isLoading = false;
          logger.error('Delete message failed.', error);
        });
    }
  }
}
</script>
