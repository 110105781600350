import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import dataLoader from '@/utils/data-loader';

const state = {
  selectedBuilding: '2', // must be string for FRM1028: Advanced combo usage
  dashboardBackground: '',
  promoted: [],
  promotedInitialized: false,
  promotedLastCheck: {
    timestamp: '',
    date: ''
  },
  coming: [],
  comingInitialized: false,
  comingLastCheck: {
    timestamp: '',
    date: ''
  },
  news: [],
  newsInitialized: false,
  newsLastCheck: {
    timestamp: '',
    date: ''
  },
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_SELECTED_BUILDING](state, building) {
    state.selectedBuilding = building;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_BACKGROUND](state, background) {
    state.dashboardBackground = background;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_PROMOTED](state, data) {
    let tempObject = [];
    let counter = 0;

    data.forEach(item => {
      let itemObject = {
        name: item.title,
        id: item.id,
        actionId: item.id,
        image: item.featuredImage,
        module: item.module,
        target: item.module
      };

      tempObject[counter] = itemObject
      counter++;
    });

    state.promoted = tempObject;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_PROMOTED_INITIALIZED](state, value) {
    state.promotedInitialized = value;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_PROMOTED_LAST_CHECK](state) {
    let date = new Date();
    let timestamp = Number(date);
    state.promotedLastCheck.date = date;
    state.promotedLastCheck.timestamp = timestamp;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_COMING](state, data) {
    let tempObject = [];
    let counter = 0;

    data.forEach(item => {
      let itemObject = {
        name: item.name,
        id: item.id,
        actionId: item.actionId,
        image: item.featuredImage,
        module: item.module,
        score: item.score,
        target: item.target,
        categories: item.module === 'forvardino' ? [] : item.categories,
        displayDate: true,
        nearestDate: item.nearestDate
      };

      tempObject[counter] = itemObject
      counter++;
    });

    state.coming = tempObject;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_COMING_INITIALIZED](state, value) {
    state.comingInitialized = value;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_COMING_LAST_CHECK](state) {
    let date = new Date();
    let timestamp = Number(date);
    state.comingLastCheck.date = date;
    state.comingLastCheck.timestamp = timestamp;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_NEWS](state, data) {
    let tempObject = [];
    let counter = 0;

    data.forEach(article => {
      let articleObject = {
        categories: article.categories,
        commentsCount: article.commentsCount,
        commentsEnable: article.commentsEnable,
        createdTimestamp: article.created / 1000,
        content: article.content,
        excerpt: article.excerpt,
        image: article.featuredImage,
        id: article.id,
        actionId: article.id,
        important: article.important,
        localities: article.localities,
        recommended: article.recommended,
        modifiedTimestamp: article.modified / 1000,
        name: article.title,
        target: '',
        created: article.created / 1000,
        displayDate: true
      };

      tempObject[counter] = articleObject
      counter++;
    });

    state.news = tempObject;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_NEWS_INITIALIZED](state, value) {
    state.newsInitialized = value;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_NEWS_LAST_CHECK](state) {
    let date = new Date();
    let timestamp = Number(date);
    state.newsLastCheck.date = date;
    state.newsLastCheck.timestamp = timestamp;
  }
};

const actions = {
  [ACTIONS_CONSTANTS.FETCH_DASHBOARD_PROMOTED]({ state, commit }, options) {
    let currentDateTime = new Date();
    let checkInterval = globalConfig.CONTENT_DATA_UPDATE_CHECK_INTERVAL;
    if ((currentDateTime - state.promotedLastCheck.timestamp) < checkInterval && !options.force) {
      return false;
    }

    commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_PROMOTED_INITIALIZED, false);
    dataLoader.fetchPromotedFilter(options)
      .then(data => {
        commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_PROMOTED, data);
        commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_PROMOTED_LAST_CHECK);
        commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_PROMOTED_INITIALIZED, true);
      });
  },

  [ACTIONS_CONSTANTS.FETCH_DASHBOARD_COMING]({ state, commit }, options) {
    let currentDateTime = new Date();
    let checkInterval = globalConfig.CONTENT_DATA_UPDATE_CHECK_INTERVAL;
    if ((currentDateTime - state.comingLastCheck.timestamp) < checkInterval && !options.force) {
      return false;
    }

    commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_COMING_INITIALIZED, false);
    dataLoader.fetchComingFilter(options)
      .then(data => {
        commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_COMING, data);
        commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_COMING_LAST_CHECK);
        commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_COMING_INITIALIZED, true);
      });
  },

  [ACTIONS_CONSTANTS.FETCH_DASHBOARD_NEWS]({ state, commit }, options) {
    let currentDateTime = new Date();
    let checkInterval = globalConfig.CONTENT_DATA_UPDATE_CHECK_INTERVAL;
    if ((currentDateTime - state.newsLastCheck.timestamp) < checkInterval && !options.force) {
      return false;
    }

    commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_NEWS_INITIALIZED, false);
    dataLoader.fetchPostsByCategories(options)
      .then(data => {
        commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_NEWS, data);
        commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_NEWS_LAST_CHECK);
        commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_NEWS_INITIALIZED, true);
      });
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
