<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        :infoButton="true"
        :infoButtonDestination="'surf-studio-creative-power-menu-info'"
        :infoButtonDestinationParams="infoButtonDestinationParams"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1023-my-actions
        v-if="loadedRegisteredEvents && displayMyEvents"
        :eventsData="forvardinoRegisteredEventsData"
        :registeredEvents="myForvardinoEventsIds"
        component-type="forvardino"
      />

      <prj1024-item-list
        v-if="applicationConfigLoaded && menuCategoryId > 0"
        ref="forvardinoList"
        :listData="forvardinoEvents"
        :listItemsCount="parseInt(forvardinoEventsNumber)"
        :noMoreItems="true"
        :componentKey="componentKey"
        :listItemConfig="listItemConfigObject"
        :showFilter="false"
        @scrollToTop="scrollToTop"
        @goToDetail="goToDetail"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="!forvardinoListInitialized || isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="eventSignupSuccess"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          v-if="(typeof registrationSuccessMessage !== 'undefined')"
          type="success"
        >
          {{ $t('forvardino.' + registrationSuccessMessage) }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {mapState} from 'vuex';
import dataLoader from '@/utils/data-loader';
import utilsGeneral from '@/utils/utils-general';
import logger from '@/utils/logger';
import router from '@/router';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import {
  ERROR_MODAL_TIMEOUT,
  SURF_STUDIO_DASHBOARD_ID,
} from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1024ItemList from '@/components/prj1024-item-list/prj1024-item-list';

export default {
  name: 'SurfStudioCreativePowerMenu',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1024ItemList,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      forvardinoEvents: [],
      forvardinoEventsNumber: 0,
      forvardinoRegisteredEventsData: [],
      loadedRegisteredEvents: false,
      forvardinoListInitialized: false,
      currentPosition: 0,
      componentKey: 0,
      listItemConfigObject: {
        displayDate: true,
        displayRating: true,
      },
      isLoading: false,
      eventSignupSuccess: false,
      userEmail: '',
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      infoPageId: null,
      initialized: false
    }
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),
    ...mapState('user', [
      'deviceId',
    ]),
    ...mapState('forvardino', [
      'showRegisteredSuccess'
    ]),
    ...mapState('persistentStorage', [
      'registeredForvardinoEvents'
    ]),

    pageId() {
      if (typeof this.$route.params.categoryId === 'undefined') {
        return 0;
      }

      return this.$route.params.categoryId;
    },

    navbarCaption() {
      return this.$route.meta.navbarCaption;
    },

    registrationSuccessMessage() {
      return this.$route.params.registrationMessage;
    },

    isValidEmail() {
      return utilsGeneral.isValidEmail(this.userEmail);
    },

    endpointListOptions() {
      return [
        this.menuCategoryId,
        '',
        '',
        0,
        '',
        null,
        null
      ]
    },

    displayMyEvents() {
      return Object.values(this.forvardinoEvents).length > 0 && this.myForvardinoEventsIds.length > 0;
    },

    myForvardinoEventsIds() {
      return this.registeredForvardinoEvents;
    },

    infoButtonDestinationParams() {
      return {id: this.infoPageId}
    },

    menuCategoryId() {
      let categoryId = 0;
      if (this.applicationConfigLoaded) {
        let tmpCustomFields = this.applicationConfig.customFields;
        tmpCustomFields.forEach(item => {
          if(item.key === 'SURFSTUDIO_FORVARDINO_CAT_ID') {
            categoryId = parseInt(item.value);
          }
        });
      }
      return categoryId;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },

    menuCategoryId(value) {
      if (value > 0) {
        this.fetchForvardinoData();
      }
    }
  },

  created() {
    if (this.menuCategoryId > 0) {
      this.fetchForvardinoData();
    }
  },

  mounted() {
    this.getSurfStudioPowerMenuInfo();

    if (this.myForvardinoEventsIds.length > 0) {
      this.fetchMyLessons();
    }

    this.handleSuccessModal();
    this.initialized = true;
  },

  methods: {
    fetchForvardinoData() {
      this.componentKey++;
      this.currentPosition = 0;
      this.forvardinoListInitialized = false;

      dataLoader.getForvardinoFilteredList([...Object.values(this.endpointListOptions)])
        .then(response => {
          this.forvardinoEvents = response.courses;
          this.forvardinoEventsNumber = response.resultsCount;
          this.forvardinoListInitialized = true;
        })
        .catch(error => {
          this.forvardinoListInitialized = true;
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
        });
    },

    handleSuccessModal() {
      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'successfullRegistration')) {
        // FIXME: CSOBZ-521 - remove nextTick due the bug in the automatic modal window closing
        this.$nextTick(() => {
          this.eventSignupSuccess = true;
        });
      }
    },

    /**
     * @param categoryNumber number, $emitted from Prj1026CategoryFilter
     */
    filterItems(categoryNumber) {
      let selectedCategoryPayload = {
        categories: [categoryNumber]
      };

      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_SELECTED_CATEGORIES, selectedCategoryPayload);
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_ACTIVE_CATEGORY, categoryNumber);

      if (this.menuCategoryId > 0) {
        this.fetchForvardinoData();
      }
    },

    fetchMyLessons() {
      dataLoader.getForvardinoMyLessons(this.myForvardinoEventsIds)
        .then(data => {
          this.forvardinoRegisteredEventsData = data;
          this.loadedRegisteredEvents = true;
        }).catch(error => {
          this.hasError = true;
          logger.error(error);
        });
    },

    // FIXME: CSOBZ-515 - merge all scroll to top methods into one global method
    scrollToTop() {
      this.$nextTick(() => {
        this.scrollToElement();
      })
    },

    scrollToElement() {
      const ref = this.$refs.navbar.$el;

      if (ref) {
        ref.scrollIntoView(true);
      }
    },

    goToDetail(data) {
      router.push({name: 'forvardino-detail', params: {id: data.id, origin: this.$route.name}});
    },

    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getSurfStudioPowerMenuInfo() {
      this.setLoadingState(true);

      return dataLoader.fetchApiDataList(SURF_STUDIO_DASHBOARD_ID)
        .then(data => {
          this.infoPageId = data.surfStudioCreativePowerMenuPageId;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },
  }
}
</script>
