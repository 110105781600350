<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-c-filter-item" :class="classObject">
        <!--========== HEADER =================================-->
        <!--===================================================-->

        <!--======= CUSTOM ==========================-->
        <div v-if="hasHeaderCustom" class="vue-b-filter-group-header">
            <slot name="header"></slot>
        </div>

        <!--======= DEFAULT =========================-->
        <div v-else-if="hasHeaderDefault" class="vue-b-filter-item-header">
            <div class="vue-b-filter-item-caption">
                <template v-if="$slots.caption">
                    <slot name="caption">{{ caption }}</slot>
                </template>
                <span v-else-if="!labelFor" class="vue-caption">
                    <slot name="caption">{{ caption }}</slot>
                </span>
                <label v-else class="vue-label" :for="labelFor">{{ caption }}</label>
            </div>
        </div>

        <!--========== CONTENT ================================-->
        <!--===================================================-->
        <div class="vue-b-filter-item-content">
            <slot></slot>
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1029FilterItem',
    props: {
        caption: String,
        labelFor: String,
        id: String
    },
    computed: {
        classObject() {
            return {
                'vue-has-header': this.hasHeader
            };
        },
        hasHeaderDefault() {
            return !!this.$slots.caption || this.caption;
        },
        hasHeaderCustom() {
            return !!this.$slots.header;
        },
        hasHeader() {
            return this.hasHeaderDefault || this.hasHeaderCustom;
        }
    }
};
</script>
