<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
    :class="categoryType"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <gen1006-info-panel
        v-if="showPrivateMessage"
        type="neutral"
      >
        <p
          v-html="$i18n.t('parents.privateMessage', { pageId: installationPageId})"
        />
      </gen1006-info-panel>
      <div
        v-else
        class="vue-b-info-controls"
      >
        <frm1006-button
          v-for="(item, index) in listItems"
          :key="index"
          :icon-right="true"
          class="vue-control-item"
          type="internal"
          @buttonClickEvent="goToView(item)"
        >
          {{ item.title }}
        </frm1006-button>
      </div>

      <gen1006-info-panel
        v-if="!isLoading && !initialized"
        type="neutral"
      >
        {{ $t('general.loadingFailed') }}
      </gen1006-info-panel>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <transition
        name="vue-anim-strategy-move-from-bottom"
        appear
      >
        <div
          v-if="showIllustration"
          class="vue-parents-illustration"
        />
      </transition>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import { mapState } from 'vuex';
import router from '@/router';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

export default {
  name: 'ParentsList',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      listTitle: '',
      listItems: null
    };
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
      'navigationData',
      'navigationDataLoaded'
    ]),

    pageId() {
      return this.$route.params.categoryId;
    },

    categoryType() {
      return `vue-parents-category-${this.$route.params.categoryType}`;
    },

    navbarCaption() {
      return this.listTitle || '';
    },

    showPrivateMessage() {
      return this.$route.params.private && !this.isPrivateApp
    },

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },

    showIllustration() {
      return !this.showPrivateMessage
    },

    installationPageId() {
      let pageId = -1;

      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'HELP_INSTALLATION_PAGE_ID') {
            pageId = parseInt(item.value);
          }
        });
      }

      return pageId;
    }
  },

  watch: {
    pageId() {
      this.fetchListPages();
    },

    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        if (this.articleData) {
          this.$refs.pageLayout.pageAsyncDataLoadedDone();
        }
      }
    }
  },

  mounted() {
    if(!this.listItems) {
      this.fetchListPages();
    } else {
      this.isLoading = false;
    }

    this.initialized = true;
  },

  methods: {
    fetchListPages() {
      dataLoader.fetchSubPages(this.pageId, this.isPrivateApp)
        .then(data => {
          this.listItems = data.items;
          this.listTitle = data.title;
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
    },

    goToView(item) {
      if (item.meta_page_path === '') {
        router.push({ name: 'parents-page', params: { id: item.id, slug: item.slug }});
      } else {
        // TODO: move to global place
        let tmpPathByAlias = ''
        this.navigationData.forEach(link => {
          if (link.alias === item.meta_page_path) {
            tmpPathByAlias = link.routeName;
          } else {
            if (link.hasOwnProperty('children')) {
              let tmpChildren = link.children;
              tmpChildren.forEach(childLink => {
                if (childLink.alias === item.meta_page_path) {
                  tmpPathByAlias = childLink.routeName;
                }
              });
            }
          }

          if (tmpPathByAlias === '') {
            router.push({ name: 'parents-page', params: { id: item.id, slug: item.slug }});
          } else {
            router.push({ path: tmpPathByAlias, query: { back: 'parents' }});
          }
        });
      }
    },
  }
}
</script>
