import * as MUTATIONS_CONSTANTS from '../constants/mutations';

const state = {
  isTutorialVisible: false,
  tutorials: {
    dashboardInit: {
      completed: false
    }
  }
};

const mutations = {
  [MUTATIONS_CONSTANTS.TOGGLE_TUTORIAL](state, tutorialState) {
    state.isTutorialVisible = tutorialState;
  },
  [MUTATIONS_CONSTANTS.SET_TUTORIAL_IS_COMPLETED](state, completedTutorial) {
    completedTutorial.completed = true;
  }
  ,
  [MUTATIONS_CONSTANTS.SET_TUTORIAL_IS_NOT_COMPLETED](state, notCompletedTutorial) {
    notCompletedTutorial.completed = false;
  }
};

export default {
  namespaced: true,
  state,
  mutations
}
