<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout :classNames="themeCategoryWallPaper">
        <template v-slot:nav>
          <prj1005-navigation-bar :route-name="ROUTE_DASHBOARD" />
        </template>
        <template v-slot:content>
          <!--========== THEME HEADER ===========================-->
          <!--===================================================-->
          <div
            class="prj-layout-default min-h-full"
            :class="{ 'justify-around': hasNoContent }"
          >
            <transition
              name="fade"
              appear
            >
              <header
                v-if="theme"
                class="my-5 pt-6 transition-opacity duration-300"
              >
                <t-tag
                  key="theme-detail-heading"
                  tag-name="h1"
                  class="text-4xl font-extrabold mb-5"
                >
                  <span
                    class="inline-block relative top-1.5 mr-2 h-10 w-11"
                    v-html="themeCategoryHash"
                  ></span>
                  <span :class="categoryTxtColor">{{ theme.title }}</span>
                </t-tag>
                <p key="theme-detail-par">{{ theme.content }}</p>
              </header>
            </transition>

            <div class="mb-9">
              <!--========== TOPICS =================================-->
              <!--===================================================-->
              <transition-group
                v-if="relatedTopics && relatedTopics.length"
                tag="section"
                name="fade"
                class="flex flex-col mb-9 prj-article"
                appear
              >
                <prj1001-section-button
                  v-for="({ id, icon, title }, i) in relatedTopics"
                  :key="`topic-${id}`"
                  class="transition-opacity duration-100"
                  :style="`transition-delay: ${i * 0.09}s`"
                  :route-name="ROUTE_TOPIC_DETAIL"
                  :route-params="{ id }"
                >
                  <template
                    v-if="icon"
                    v-slot:icon
                  >
                    <span v-html="icon" />
                  </template>

                  <template v-slot:text>{{ title }}</template>
                </prj1001-section-button>
              </transition-group>

              <!--========== THEMES NEWS ============================-->
              <!--===================================================-->
              <section
                v-if="!!relatedNews.length"
                class="pb-9"
              >
                <transition
                  name="fade"
                  appear
                >
                  <t-tag
                    variant="subTitle"
                    tag-name="h2"
                    class="transition-opacity duration-300"
                  >
                    {{ $t('esg.news.headline') }}
                  </t-tag>
                </transition>

                <prj1004-article-list
                  :articles="relatedNews"
                  :itemAnimDuration="itemAnimDuration"
                  :pathName="ROUTE_NEWS_DETAIL"
                  classNames="py-2"
                />

                <!--    TODO JBa decide on route      -->
                <transition
                  v-if="hasMoreNews"
                  name="fade"
                  appear
                >
                  <prj1001-section-button
                    :route-name="ROUTE_NEWS"
                    :route-params="{ themeId }"
                    class="w-full transition-opacity duration-300"
                    :style="`transition-delay: ${relatedNews.length * itemAnimDuration}s`"
                  >
                    <template v-slot:text>{{ $t('esg.news.moreArticles') }}</template>
                  </prj1001-section-button>
                </transition>
              </section>

              <!--========== STORIES ===============================-->
              <!--===================================================-->
              <transition-group
                v-if="!!relatedStories.length"
                tag="section"
                name="fade"
                class="pb-9"
                appear
              >
                <t-tag
                  key="stories-section-title"
                  variant="subTitle"
                  tag-name="h2"
                  class="transition-opacity duration-200"
                >
                  {{ $t('esg.stories.headline') }}
                </t-tag>
                <prj1006-cards-wrapper
                  key="stories-section-cards"
                  class="transition-opacity duration-200"
                  style="transition-delay: 100ms"
                  :itemsLength="relatedStories.length"
                  :hasNavigation="!isSingleStory"
                  :fireScrollEnd="true"
                  @scrollEnd="loadMoreStories"
                >
                  <template>
                    <prj1008-story-card
                      v-for="story in relatedStories"
                      :key="`story-${story.id}`"
                      class="h-vh-50"
                      :data-id="`${story.id}`"
                      :story="story"
                      :single="isSingleStory"
                      @showLogin="onShowLogin"
                    />
                  </template>
                </prj1006-cards-wrapper>
              </transition-group>

              <!--========== CHALLENGES ============================-->
              <!--===================================================-->
              <transition-group
                v-if="relatedChallenges && !!relatedChallenges.length"
                tag="section"
                name="fade"
                class="pb-9"
                appear
              >
                <t-tag
                  key="challenges-section-title"
                  variant="subTitle"
                  tag-name="h2"
                  class="transition-opacity duration-200"
                >
                  {{ $t('esg.challenges.headline') }}
                </t-tag>
                <prj1006-cards-wrapper
                  key="challenges-section-cards"
                  class="transition-opacity duration-200"
                  style="transition-delay: 100ms"
                  :hasNavigation="!isSingleChallenge"
                  :itemsLength="relatedChallenges.length"
                >
                  <template
                    v-for="{
                      additionalLink,
                      additionalLinkUrl,
                      additionalIconType,
                      competitionId,
                      description,
                      endDate,
                      id,
                      image,
                      participants,
                      resultsReady,
                      status,
                      startDate,
                      title,
                    } in relatedChallenges"
                  >
                    <prj1007-challenge-card
                      :key="`challenge-${id}`"
                      :statusTag="getStatus({ event: 'challenges', status, start: startDate, end: endDate })"
                      :status="status"
                      :link="additionalLink"
                      :linkUrl="additionalLinkUrl"
                      :icon="additionalIconType"
                      :isCompetitionLinked="!!competitionId"
                      :participants="parseInt(participants)"
                      :title="title"
                      :description="description"
                      :image="image"
                      :isSingle="isSingleChallenge"
                      :isResultReady="!!resultsReady"
                      :isSecondaryBtnDisabled="!getIsUserInvolvedInChallenge({ challengeId: id })"
                      @primaryButton="
                        toPage({ route: ROUTE_CHALLENGE_DETAIL, challengeId: id })
                      "
                      @secondaryButton="toPage({ route: ROUTE_CHALLENGE_HISTORY, challengeId: id })"
                      @resultClick="toPage({ route: ROUTE_CHALLENGE_RESULT, challengeId: id })"
                      @linkedEventClick="toPage({ route: ROUTE_COMPETITION_DETAIL, competitionId })"
                    />
                  </template>
                </prj1006-cards-wrapper>
              </transition-group>

              <!--========== COMPETITIONS ===========================-->
              <!--===================================================-->
              <transition-group
                v-if="relatedCompetitions && !!relatedCompetitions.length"
                tag="section"
                name="fade"
                class="pb-9"
                appear
              >
                <t-tag
                  key="competitions-section-title"
                  variant="subTitle"
                  tag-name="h2"
                  class="transition-opacity duration-200"
                >
                  {{ $t('esg.competitions.headline') }}
                </t-tag>
                <prj1006-cards-wrapper
                  key="competitions-section-cards"
                  class="transition-opacity duration-200"
                  style="transition-delay: 100ms"
                  :itemsLength="relatedCompetitions.length"
                  :hasNavigation="!isSingleCompetition"
                >
                  <template
                    v-for="{
                      additionalLink,
                      additionalLinkUrl,
                      additionalIconType,
                      challengeId,
                      endDate,
                      id,
                      image,
                      name,
                      perex,
                      resultsReady,
                      status,
                      startDate,
                    } in relatedCompetitions"
                  >
                    <prj1007-competition-card
                      :key="`competition-${id}`"
                      :statusTag="getStatus({ event: 'competitions', status, start: startDate, end: endDate })"
                      :status="status"
                      :isPrimaryBtnDisabled="isCompetitionLocked({ competitionId: id, challengeId })"
                      :link="additionalLink"
                      :linkUrl="additionalLinkUrl"
                      :isChallengeLinked="!!challengeId"
                      :icon="additionalIconType"
                      :title="name"
                      :description="perex"
                      :image="image"
                      :isSingle="isSingleCompetition"
                      :isResultReady="!!resultsReady"
                      @primaryButton="toPage({ route: ROUTE_COMPETITION_DETAIL, competitionId: id })"
                      @resultClick="toPage({ route: ROUTE_COMPETITION_RESULT, competitionId: id })"
                      @linkedEventClick="toPage({ route: ROUTE_CHALLENGE_DETAIL, challengeId })"
                    />
                  </template>
                </prj1006-cards-wrapper>
              </transition-group>

              <!--========== NO CONTENT ============================-->
              <!--===================================================-->
              <transition
                name="fade"
                appear
              >
                <section
                  v-if="hasNoContent"
                  class="flex flex-col text-black items-center py-10 px-8 transition-opacity duration-300"
                >
                  <svg
                    class="w-6 h-6"
                    viewBox="0 0 32 33"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 11.1667V16.5L20 20.5M28 16.5C28 23.1274 22.6274 28.5 16 28.5C9.37258 28.5 4 23.1274 4 16.5C4 9.87258 9.37258 4.5 16 4.5C22.6274 4.5 28 9.87258 28 16.5Z"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span class="text-lg text-center leading-6 font-semibold pt-6">{{ $t('esg.themes.preparation') }}</span>
                </section>
              </transition>
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<script>
import { mapState } from 'vuex'
import { ESG_THEMES } from '@/store/store-modules'
import { GET_THEMES_INFO } from '@/store/constants/esg/actions'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import apiCmsChallenges from '@/esg-api/cms-challenges'
import apiCmsCompetitions from '@/esg-api/cms-competitions'
import apiCmsTopics from '@/esg-api/cms-topics'
import apiCmsContent from '@/esg-api/cms-content'
import apiCmsStories from '@/esg-api/cms-stories'

import '@/utils/dayjs'
import categoryConfig from '@/assets/esg/categories/config'
import {
  ROUTE_DASHBOARD,
  ROUTE_NEWS,
  ROUTE_NEWS_DETAIL,
  ROUTE_TOPIC_DETAIL,
  ROUTE_COMPETITION_DETAIL,
  ROUTE_COMPETITION_RESULT,
  ROUTE_CHALLENGE_DETAIL,
  ROUTE_CHALLENGE_HISTORY,
  ROUTE_CHALLENGE_RESULT
} from '@/constants/esg/route-names'
import { FILTER_THEME } from '@/constants/esg/string-values'
import { eventStatus } from '@/mixins/esg/eventStatus'

import PageLayout from '@/templates/esg/page-layout'
import Prj1001SectionButton from '@/components/esg/prj1001-section-button/prj1001-section-button'
import Prj1004ArticleList from '@/components/esg/prj1004-article-list/prj1004-article-list'
import Prj1005NavigationBar from '@/components/esg/prj1005-navigation-bar/prj1005-navigation-bar'
import Prj1006CardsWrapper from '@/components/esg/prj1006-cards-wrapper/prj1006-cards-wrapper'
import Prj1007ChallengeCard from '@/components/esg/prj1007-event-card/prj1007-challenge-card'
import Prj1007CompetitionCard from '@/components/esg/prj1007-event-card/prj1007-competition-card'
import Prj1008StoryCard from '@/components/esg/prj1008-story-card/prj1008-story-card'

export default {
  name: 'ESGThemeDetail',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout,
    Prj1001SectionButton,
    Prj1004ArticleList,
    Prj1005NavigationBar,
    Prj1006CardsWrapper,
    Prj1007ChallengeCard,
    Prj1007CompetitionCard,
    Prj1008StoryCard,
  },
  mixins: [mxNavigationDefault, eventStatus],
  data() {
    return {
      isLoading: true,
      ROUTE_DASHBOARD,
      ROUTE_NEWS,
      ROUTE_NEWS_DETAIL,
      ROUTE_TOPIC_DETAIL,
      ROUTE_CHALLENGE_DETAIL,
      ROUTE_CHALLENGE_HISTORY,
      ROUTE_CHALLENGE_RESULT,
      ROUTE_COMPETITION_DETAIL,
      ROUTE_COMPETITION_RESULT,
      FILTER_THEME,
      themeId: parseInt(this.$route.params.id),
      theme: null,
      numberOfCards: 3,
      relatedTopics: [],
      relatedNews: [],
      hasMoreNews: false,
      relatedStories: [],
      storyPage: 1,
      noMoreStoriesLoad: false,
      relatedChallenges: [],
      relatedCompetitions: [],
      itemAnimDuration: 0.1, // equivalent to tw class duration-100
      categoryConfig,
    }
  },
  computed: {
    ...mapState('esgThemes', ['themesInfo']),
    ...mapState('esgChallenges', ['challengesInfo', 'challengesUserInvolved']),
    ...mapState('esgCompetitions', ['competitionsInfo']),
    themeCategoryId() {
      return this.theme?.categoryId || 0
    },
    currentThemeCategorySettings() {
      return this.categoryConfig?.find((item) => item.id === this.themeCategoryId)
    },
    themeCategoryHash() {
      return this.currentThemeCategorySettings?.svg.hash
    },
    themeCategoryWallPaper() {
      return this.currentThemeCategorySettings?.wallPaper
    },
    isSingleStory() {
      return this.relatedStories.length === 1
    },
    isSingleChallenge() {
      return this.relatedChallenges.length === 1
    },
    isSingleCompetition() {
      return this.relatedCompetitions.length === 1
    },
    categoryTxtColor() {
      return this.currentThemeCategorySettings?.txtCol
    },
    categoryBgColor() {
      return this.currentThemeCategorySettings?.bgCol
    },
    hasNoContent() {
      return (
        !this.isLoading &&
        !this.relatedNews.length &&
        !this.relatedStories.length &&
        !this.relatedChallenges.length &&
        !this.relatedCompetitions.length
      )
    },
  },
  async created() {
    if (!this.themesInfo?.length) await this.$store.dispatch(`${ESG_THEMES}/${GET_THEMES_INFO}`)
    await this.getContent()
    this.isLoading = false
  },
  methods: {
    async getContent() {
      this.theme = this.themesInfo.find((theme) => theme.id === this.themeId)
      this.relatedTopics = await this.getRelatedTopics()
      this.relatedNews = await this.getRelatedNews()
      this.relatedStories = await this.getRelatedStories()

      this.relatedChallenges = this.challengesInfo.length
        ? this.challengesInfo.reduce(
          (acc, challenge) => (challenge.themeId === this.themeId ? [...acc, challenge] : acc),
          []
        )
        : await apiCmsChallenges.getChallengesByThemeId({  id: this.themeId })

      this.relatedCompetitions = this.competitionsInfo.length
        ? this.competitionsInfo.reduce(
          (acc, competition) => (competition.themeId === this.themeId ? [...acc, competition] : acc),
          []
        )
        : await apiCmsCompetitions.getCompetitionsByThemeId({ id: this.themeId })
    },

    async getRelatedTopics() {
      const { data, status } = await apiCmsTopics.getTopicsByParam({
        topicFilters: [{ filter: FILTER_THEME, id: this.themeId }],
      })
      if (status === 200) return (this.relatedTopics = data)
    },

    async getRelatedNews() {
      const { data, status } = await apiCmsContent.getNewsByTheme({ perPage: this.numberOfCards + 1, themeId: this.themeId })
      if (status === 200) {
        if (data.length > this.numberOfCards) this.hasMoreNews = true
        return data
          .slice(0, this.numberOfCards)
          .reduce((acc, item) => [...acc, { ...item, themeTitle: this.getThemeTitle(item) }], [])
      }
    },

    // BE does not send info about the quantity of all stories, need to fetch one extra to check for more stories eligibility
    async getRelatedStories() {
      const { data, status } = await apiCmsStories.getStoriesByParam({
        page: this.storyPage,
        perPage: this.numberOfCards,
        storyFilters: [{ filter: FILTER_THEME, id: this.themeId }],
      })
      if (status === 200) {
        if (!data.length) {
          (this.noMoreStoriesLoad = true)
          return data
        }
        return data.reduce((acc, item) => [...acc, { ...item, themeTitle: this.getThemeTitle(item) }], [])
      }
    },

    // Subsequent call
    async loadMoreStories() {
      if (this.noMoreStoriesLoad) return false

      this.storyPage++
      const moreStories = await this.getRelatedStories()
      this.relatedStories = [...this.relatedStories, ...moreStories]
    },

    getThemeTitle(item) {
      return this.themesInfo.find((theme) => theme.id === item.themeId).title
    },

    toPage({ route, challengeId = null, competitionId = null }) {
      this.$router.push({
        name: route,
        params: {
          challengeId,
          competitionId
        },
      })
    },

    /**
     * Two major types of competitions:
     * 1. Competition dependent on Challenge involvement - has challengeId
     * 2. Standalone competition - challengeId is set to null
     * @see https://jpower8.atlassian.net/browse/ESG-529
     */
    isCompetitionLocked({ competitionId, challengeId }) {
      if (challengeId) {
        return !this.getIsUserInvolvedInChallenge({ competitionId })
      }
      return false
    },

    getIsUserInvolvedInChallenge({ competitionId = null, challengeId = null }) {
      if (competitionId) return this.challengesUserInvolved.some(item => item.linkedCompetition === competitionId)
      if (challengeId) return this.challengesUserInvolved.some(item => item.challenge === challengeId)
      return false
    },
  },
}
</script>
