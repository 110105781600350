// This filter accepts string date only in format 'YYYY-MM-DD HH:MM:SS'

const convertDate = (dateString, setting) => {
  const splittedDateString = dateString.split(' ');
  const date = splittedDateString[0];
  const time = splittedDateString[1];

  let result;

  // TODO: consider switch if syntax to switch
  if (setting === 'hour') {
    result = time.slice(0, 2);
  }

  if (setting === 'minutes') {
    result = time.slice(3, 5);
  }

  if (setting === 'year') {
    result = date.slice(0, 4);
  }

  if (setting === 'month') {
    let subresult = date.slice(5, 7);

    result = removeZeroFromResult(subresult);
  }

  if (setting === 'day') {
    let subresult = date.slice(8, 10);

    result = removeZeroFromResult(subresult);
  }

  if (setting === 'exactHours') {
    return time.substring(0, time.length-3);
  }

  return result;
}

const removeZeroFromResult = (item) => {
  let result;

  if (item.charAt(0) === '0') {
    result = item.charAt(1);
  } else {
    result = item;
  }

  return result
}

export default convertDate;
