<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div class="vue-b-dashboard">
          <div class="vue-header">
            <img
              class="vue-header-logo"
              :src="headerLogoPath"
            >

            <p
              class="vue-dashboard-title"
              v-html="$t('emergency.dashboardPerex')"
            />
          </div>

          <div class="vue-controls">
            <div class="vue-dashboard-additional-sections">
              <frm1006-button
                :icon-right="true"
                class="vue-control-item"
                type="internal"
                @buttonClickEvent="goToView(null, 'emergency-contacts')"
              >
                {{ $t('emergency.buttonImportantPhoneNumbers') }}
              </frm1006-button>
            </div>

            <div class="vue-dashboard-tiles">
              <frm1006-button
                v-for="item in tileButtons"
                :key="item.id"
                class="vue-dashboard-item-tile"
                :class="generateTile(item)"
                type="tile"
                @buttonClickEvent="goToView(item)"
              >
                <template v-slot:iconLeft>
                  <div
                    class="vue-tile-icon"
                  />
                </template>
                {{ item.title }}
              </frm1006-button>
            </div>
          </div>
        </div>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import router from '@/router';

export default {
  name: 'EmergencyDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      navbarCaptionData: null,
      ERROR_MODAL_TIMEOUT,
      tileButtons: [
        {
          'id': '1',
          'title': 'Obecné informace',
          'pageId':  null,
          'path':  'emergency-information',
          'icon': 'emergency-info',
        },
        {
          'id': '2',
          'title': 'Evakuační plán',
          'pageId':  null,
          'path':  'emergency-building-plans',
          'icon': 'emergency-evacuation-plan',
        },
        {
          'id': '3',
          'title': 'Krizové situace',
          'pageId':  null,
          'path':  'emergency-crisis-situation',
          'icon': 'emergency-crisis-situation',
        },
      ],
      headerLogoPath: '/img/emergency/dashboard-header.png',
    };
  },

  computed: {
    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },
  },

  mounted() {
    this.initialized = true;
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    generateTile(item) {
      if (item.icon.length > 0) {
        return 'vue-tile-' + item.icon;
      }
    },

    goToView(item, name = null) {
      if (item === null) {
        router.push({ name: name });
      } else {
        if (item.pageId !== null) {
          router.push({ name: (name !== null ? name : item.path), params: { id: item.pageId } });
        } else {
          router.push({ name: (name !== null ? name : item.path) });
        }
      }
    },
  },
}
</script>

