<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <template v-slot:header>
      <prj1001-detail-header
        v-if="knowledgeData"
        :headerImage="knowledgeData.image"
        :headerTag="''"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        v-if="!isLoading"
        class="vue-event-detail-content"
      >
        <!--Heading-->
        <prj1017-heading
          :detailheader="knowledgeData.name"
        />

        <!--Description-->
        <div
          v-if="knowledgeData.description"
          class="vue-knowledge-detail-text"
          v-html="knowledgeData.description"
        />
      </div>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
          :class="errorClassObject"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import Prj1002Navbar from '../../components/prj1002-navbar/prj1002-navbar';
import Prj1001DetailHeader from '@/components/prj1001-detail-header/prj1001-detail-header';
import PageLayout from '../../templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import {ERROR_MODAL_TIMEOUT} from '@/constants/app-constants';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import mxErrorMessage from '@/mixins/mx-error-message';
import {mapState} from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'KnowledgeBaseDetail',

  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading,
    Prj1001DetailHeader
  },

  mixins: [
    mxNavigationDefault,
    mxErrorMessage
  ],

  data() {
    return {
      isLoading: false,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      knowledgeFetched: {},
    }
  },

  computed: {
    ...mapState('knowledge', [
      'detailData'
    ]),

    pageId() {
      return this.$route.params.pageId;
    },

    knowledgeCached() {
      return this.detailData;
    },

    knowledgeData() {
      if (Object.keys(this.knowledgeCached).length) {
        return this.knowledgeCached;
      }
      return this.knowledgeFetched;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value && Object.keys(this.knowledgeData).length) {
        this.setLoadingState(false);
      }
    },
  },

  mounted() {
    if (this.knowledgeCached?.id !== this.pageId) {
      this.getknowledgeBaseDetail(this.pageId);
    } else {
      this.initialized = true;
    }
  },


  methods: {
    setLoadingState(value) {
      if (value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getknowledgeBaseDetail(pageId) {
      this.setLoadingState(true);

      return dataLoader.fetchKnowledgeBaseDetail(pageId)
        .then(data => {
          this.knowledgeFetched = data;
          this.$store.commit(STORE_MODULES.KNOWLEDGE + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, this.knowledgeFetched);
        })
        .catch(error => {
          logger.error(error);
          this.setErrorMessage(error?.response?.data);
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },
  }
}
</script>
