<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <transition :name="transitionName">
        <div v-if="active" :id="id" class="vue-component vue-c-loading-indicator" :class="classObject">
            <div class="vue-b-indicator"></div>
            <div v-if="this.$slots.default || caption" class="vue-b-content">
                <span v-if="caption" class="vue-caption">
                    {{ caption }}
                </span>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
export default {
    name: 'Gen1016LoadingIndicator',
    props: {
        active: {
            default: true,
            type: Boolean
        },
        caption: String,
        overlay: Boolean,
        fullScreen: Boolean,
        transitionName: {
            default: 'vue-default',
            type: String
        },
        id: String
    },
    computed: {
        classObject: function() {
            return {
                'vue-has-content': this.$slots.default,
                'vue-has-overlay': this.overlay,
                'vue-is-full-screen': this.fullScreen
            };
        }
    },
    watch: {
        active(value) {
            if (value && this.fullScreen) {
                document.addEventListener('keyup', this.overlayKeyUpEsc);
            } else if (this.fullScreen) {
                document.removeEventListener('keyup', this.overlayKeyUpEsc);
            }
        },
        fullScreen(value) {
            if (!value) {
                document.removeEventListener('keyup', this.overlayKeyUpEsc);
            }
        }
    },
    destroyed() {
        if (this.fullScreen) {
            document.removeEventListener('keyup', this.overlayKeyUpEsc);
        }
    },
    methods: {
        overlayKeyUpEsc(e) {
            if (e.key === 'Escape') {
                // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
                this.$emit('overlayKeyUpEscEvent');
            }
        }
    }
};
</script>
