<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div
          v-if="faqContent"
          class="vue-c-article-body vue-wcm-content"
          v-html="faqContent"
        />

        <div class="vue-faq-content vue-expanded-section">
          <gen1015-section
            v-for="item in faqItems"
            :key="item.id"
            :collapsible="true"
            :collapsed="true"
            class="is-animated"
          >
            <template v-slot:header>
              <h3>{{ item.title }}</h3>
            </template>
            <template v-slot:content>
              <div class="vue-b-content-animated">
                <div
                  class="vue-c-article-body vue-wcm-content"
                  v-html="item.content"
                />
              </div>
            </template>
          </gen1015-section>
        </div>

        <gen1006-info-panel
          v-if="!isLoading && !initialized"
          type="neutral"
        >
          {{ $t('general.loadingFailed') }}
        </gen1006-info-panel>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <transition
        name="vue-anim-strategy-move-from-bottom"
        appear
      >
        <div class="vue-parents-illustration" />
      </transition>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import PageLayout from '../../templates/partials/page-layout';

import mxNavigationDefault from '../../mixins/mx-navigation-default';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';

export default {
  name: 'AdaptationDetail',

  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault,
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT,
      faqItems: [],
      faqTitle: '',
      faqContent: null,
    }
  },

  computed: {
    routeName() {
      return this.$route.name;
    },

    categoryId() {
      return this.$route.params.categoryId
    },

    navbarCaption() {
      return this.faqTitle ? this.faqTitle : '';
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.fetchFaqData().then(() => {
      this.initializePage();
    });
  },

  methods: {
    fetchFaqData() {
      return dataLoader.fetchSubPages(this.categoryId)
        .then(data => {
          this.faqTitle = data.title;
          this.faqContent = data.content;
          this.faqItems = data.items;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.initializePage();
        })
    },

    initializePage() {
      this.initialized = true;
    },
  }
}
</script>
