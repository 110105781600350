import axiosConfig from '@/utils/data-loader/axiosConfig'
import * as envConfig from 'env-config'

export default {
  getAllCompetitions() {
    return axiosConfig.get(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_COMPETITIONS).then((response) => response.data)
  },
  getCompetitionsByThemeId({ id }) {
    return axiosConfig
      .get(`${envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_COMPETITIONS}?filter[themeId]=${id}`)
      .then((response) => response.data)
  },
}
