// GENERAL
export const ONE_YEAR_IN_MS = 31556952e3;
export const TWO_WEEKS_IN_MS = 12096e5;
export const HOUR_IN_MS = 1000 * 60 * 60;
export const DAY_IN_MS = HOUR_IN_MS * 24;
export const SUCCESS_MODAL_TIMEOUT = 2000;
export const ERROR_MODAL_TIMEOUT = 5000;
export const SHOW_CART_TIMEOUT = 15000;
export const MOBILE_BREAKPOINT_SIZE = 580;
export const DESKTOP_APP_BORDER_SIZE = 50;
export const DESKTOP_BREAKPOINT_SIZE = 1024;

// SYNC
export const LINKED = 'linked';
export const SYNC_STATUS = Object.freeze({
  ACTIVE: 1,
  PENDING: 2,
  EXPIRED: 3
});
export const SYNC_METHOD_SET = 'set';
export const SYNC_METHOD_REMOVE = 'remove';

// LISTS
export const DEFAULT_LIST_PAGINATION = 20;

// FORVADINO
export const SYNC_FORVARDINO_LESSON_ID = 'forvardinoLessonId';
export const FORVARDINO_EVENT_STATUS = Object.freeze({
  EVENT_ACTIVE: 1,
  EVENT_INACTIVE_WITH_RATING: 0,
  EVENT_INACTIVE: -1
});

// EVENTS
export const SYNC_EVENT_ID = 'eventId';
export const DEFAULT_EVENT_PAGINATION = 20;
export const EVENT_SPOT_ONLINE = 'online';
export const EVENT_SPOT_NHQ = 'nhq';
export const EVENT_SPOT_SHQ = 'shq';
export const EVENT_SPOT_OTHER = 'other';

// RESTAURANTS
export const RESTAURANT_NHQ = 'NHQ';
export const RESTAURANT_SHQ = 'SHQ';
export const RESTAURANT_HHQ = 'HHQ';
export const RESTAURANT_PHQ = 'PHQ';

// SURVEY
export const SURVEY_KEY_PREFIX = 'SURVEY_'

// DATA LISTS IDS
export const ORGANIZATION_STRUCTURE_ID = 1;
export const CSOB_BUILDINGS_LIST_ID = 2;
export const CSOB_BRANCHES_LIST_ID = 3; // MWR: unused from 04.07.2022 - fault report branch rework
export const FAULT_REPORTING_CATEGORIES_LIST_ID = 4;
export const COVID_DASHBOARD_CATEGORIES_LIST = 5;
export const HHQ_DASHBOARD_ID = 7;
export const MOTOR_DASHBOARD_ID = 8;
export const PARENTS_DASHBOARD_ID = 9;
export const IDEAS_CATEGORIES_LIST_ID = 10;
export const SURF_STUDIO_DASHBOARD_ID = 12;
export const RESTAURANTS_SURVEY_SETTING_ID = 13;
export const ADAPTATION_DASHBOARD_ID = 14;

// ANNUAL REPORT
export const KUULA_ID = 'KUULA_IFRAME_POST_ID';

// COVID19 DASHBOARD
export const COVID19_KEY_PREFIX = 'COVID19_';

// AUCTION DASHBOARD
export const AUCTION_KEY_PREFIX = 'AUCTION_';

// ADVENT DASHBOARD
export const ADVENT_KEY_PREFIX = 'ADVENT_';

// EDUCATION DASHBOARD
export const EDUCATION_KEY_PREFIX = 'EDUCATION_';

// STRATEGY
export const STRATEGY_DEFAULT_LANG = 'en';
export const STRATEGY_COMPETITION_ACTIVE = 1;
export const STRATEGY_KEY_PREFIX = 'STRATEGY_';

// BUILDING HHQ
export const BUILDING_HHQ_COORDINATES = Object.freeze({
  LAT: '50.2167893',
  LON: '15.8294072'
});
export const BUILDING_HHQ_ID = 'ChIJ2Z6AeRUrDEcRHOi1kESCNL8';

// Sustainability
export const SUSTAINABILITY_KEY_PREFIX = 'SUSTAINABILITY_';

// Festival
export const FESTIVAL_KEY_PREFIX = 'FESTIVAL_';

// Restaurants
export const RESTAURANTS_KEY_PREFIX = 'RESTAURANTS_';

// NPS
export const NPS_KEY_PREFIX = 'NPS_';

// MARKETPLACE
export const MARKETPLACE_KEY_PREFIX = 'MARKETPLACE_';

// APP DIRECTORY
export const APP_DIRECTORY_KEY_PREFIX = 'APP_DIRECTORY_';

// SERVICES
export const SERVICES_PAGE_SIZE = 20;
export const SERVICES_CATEGORIES = ['kampus', 'hhq', 'other'];

// EMPTY DATA
export const DASHBOARD_EMPTY_BUTTONS = [
  {
    activeFrom: null,
    activeTo: null,
    alias: '',
    categoryId: null,
    cssClass: '',
    depth: '1',
    groupDashboard: '',
    icon: '',
    iconCustom: '',
    iconDashboard: '',
    id: '1',
    modified: '',
    name: '',
    nameEng: '',
    nameShort: '',
    nameShortEng: '',
    onDashboard: '1',
    ordering: '0',
    orderingDashboard: '0',
    parentId: '0',
    postId: null,
    privateOnly: '0',
    routeName: '',
    routeNameLogged: '',
    state: '1',
    sticker: '',
    tags: ''
  },
  {
    activeFrom: null,
    activeTo: null,
    alias: '',
    categoryId: null,
    cssClass: '',
    depth: '1',
    groupDashboard: '',
    icon: '',
    iconCustom: '',
    iconDashboard: '',
    id: '2',
    modified: '',
    name: '',
    nameEng: '',
    nameShort: '',
    nameShortEng: '',
    onDashboard: '1',
    ordering: '0',
    orderingDashboard: '0',
    parentId: '0',
    postId: null,
    privateOnly: '0',
    routeName: '',
    routeNameLogged: '',
    state: '1',
    sticker: '',
    tags: ''
  },
  {
    activeFrom: null,
    activeTo: null,
    alias: '',
    categoryId: null,
    cssClass: '',
    depth: '1',
    groupDashboard: '',
    icon: '',
    iconCustom: '',
    iconDashboard: '',
    id: '3',
    modified: '',
    name: '',
    nameEng: '',
    nameShort: '',
    nameShortEng: '',
    onDashboard: '1',
    ordering: '0',
    orderingDashboard: '0',
    parentId: '0',
    postId: null,
    privateOnly: '0',
    routeName: '',
    routeNameLogged: '',
    state: '1',
    sticker: '',
    tags: ''
  },
  {
    activeFrom: null,
    activeTo: null,
    alias: '',
    categoryId: null,
    cssClass: '',
    depth: '1',
    groupDashboard: '',
    icon: '',
    iconCustom: '',
    iconDashboard: '',
    id: '4',
    modified: '',
    name: '',
    nameEng: '',
    nameShort: '',
    nameShortEng: '',
    onDashboard: '1',
    ordering: '0',
    orderingDashboard: '0',
    parentId: '0',
    postId: null,
    privateOnly: '0',
    routeName: '',
    routeNameLogged: '',
    state: '1',
    sticker: '',
    tags: ''
  },
  {
    activeFrom: null,
    activeTo: null,
    alias: '',
    categoryId: null,
    cssClass: '',
    depth: '1',
    groupDashboard: '',
    icon: '',
    iconCustom: '',
    iconDashboard: '',
    id: '5',
    modified: '',
    name: '',
    nameEng: '',
    nameShort: '',
    nameShortEng: '',
    onDashboard: '1',
    ordering: '0',
    orderingDashboard: '0',
    parentId: '0',
    postId: null,
    privateOnly: '0',
    routeName: '',
    routeNameLogged: '',
    state: '1',
    sticker: '',
    tags: ''
  },
  {
    activeFrom: null,
    activeTo: null,
    alias: '',
    categoryId: null,
    cssClass: '',
    depth: '1',
    groupDashboard: '',
    icon: '',
    iconCustom: '',
    iconDashboard: '',
    id: '6',
    modified: '',
    name: '',
    nameEng: '',
    nameShort: '',
    nameShortEng: '',
    onDashboard: '1',
    ordering: '0',
    orderingDashboard: '0',
    parentId: '0',
    postId: null,
    privateOnly: '0',
    routeName: '',
    routeNameLogged: '',
    state: '1',
    sticker: '',
    tags: ''
  }
];
export const PARENT_EMPTY_PAGES_SEARCH = [
  {
    parent_ID: '1',
    parent_title: '&nbsp;&nbsp;&nbsp;',
    pages: [
      {
        ID: '',
        title: '',
        content: '',
        slug: ''
      },
      {
        ID: '',
        title: '',
        content: '',
        slug: ''
      },
      {
        ID: '',
        title: '',
        content: '',
        slug: ''
      },
    ]
  }
];
export const PARENT_EMPTY_BLOCKS = [
  {
    id: '1',
    title: '',
    style: 'tile',
    pageId: null,
    icon: '',
    hasPrivateOption: false,
    categoryId: '',
    categoryType: ''
  },
  {
    id: '2',
    title: '',
    style: 'tile',
    pageId: null,
    icon: '',
    hasPrivateOption: false,
    categoryId: '',
    categoryType: ''
  },
  {
    id: '3',
    title: '',
    style: 'tile',
    pageId: null,
    icon: '',
    hasPrivateOption: false,
    categoryId: '',
    categoryType: ''
  },
  {
    id: '4',
    title: '',
    style: 'tile',
    pageId: null,
    icon: '',
    hasPrivateOption: false,
    categoryId: '',
    categoryType: ''
  },
  {
    id: '5',
    title: '',
    style: 'tile',
    pageId: null,
    icon: '',
    hasPrivateOption: false,
    categoryId: '',
    categoryType: ''
  },
  {
    id: '6',
    title: '',
    style: 'tile',
    pageId: null,
    icon: '',
    hasPrivateOption: false,
    categoryId: '',
    categoryType: ''
  },
  {
    id: '7',
    title: '',
    style: 'tile',
    pageId: null,
    icon: '',
    hasPrivateOption: false,
    categoryId: '',
    categoryType: ''
  },
  {
    id: '8',
    title: '',
    style: 'tile',
    pageId: null,
    icon: '',
    hasPrivateOption: false,
    categoryId: '',
    categoryType: ''
  },
];
export const WIDGET_EMPTY_DATA_ITEMS = [
  {
    id: '1',
    actionId: '1',
    image: '',
    module: '',
    name: '',
    target: '',
  },
  {
    id: '2',
    actionId: '2',
    image: '',
    module: '',
    name: '',
    target: '',
  },
  {
    id: '3',
    actionId: '3',
    image: '',
    module: '',
    name: '',
    target: '',
  },
  {
    id: '4',
    actionId: '4',
    image: '',
    module: '',
    name: '',
    target: '',
  },
  {
    id: '5',
    actionId: '5',
    image: '',
    module: '',
    name: '',
    target: '',
  },
  {
    id: '6',
    actionId: '6',
    image: '',
    module: '',
    name: '',
    target: '',
  },
];
export const DIRECTORY_EMPTY_DATA_ITEMS = [
  {
    aliases: [],
    assetTag: '',
    businessEntities: [],
    categories: [],
    description: '',
    favorite: true,
    homepageUrl: '',
    iconColor: 'black',
    iconUrl: '',
    id: 1,
    maintenanceActive: false,
    maintenanceFrom: null,
    maintenanceMessage: '',
    maintenanceTo: null,
    managers: [],
    name: '',
    owner: {id: 0, name: '', email: '', phone: ''},
    privateOnly: true,
    supportEmail: '',
    supportPhone: '',
    supportSnowGroup: '',
    type: 'general',
    url: ''
  },
  {
    aliases: [],
    assetTag: '',
    businessEntities: [],
    categories: [],
    description: '',
    favorite: true,
    homepageUrl: '',
    iconColor: 'black',
    iconUrl: '',
    id: 2,
    maintenanceActive: false,
    maintenanceFrom: null,
    maintenanceMessage: '',
    maintenanceTo: null,
    managers: [],
    name: '',
    owner: {id: 0, name: '', email: '', phone: ''},
    privateOnly: true,
    supportEmail: '',
    supportPhone: '',
    supportSnowGroup: '',
    type: 'general',
    url: ''
  },
  {
    aliases: [],
    assetTag: '',
    businessEntities: [],
    categories: [],
    description: '',
    favorite: true,
    homepageUrl: '',
    iconColor: 'black',
    iconUrl: '',
    id: 3,
    maintenanceActive: false,
    maintenanceFrom: null,
    maintenanceMessage: '',
    maintenanceTo: null,
    managers: [],
    name: '',
    owner: {id: 0, name: '', email: '', phone: ''},
    privateOnly: true,
    supportEmail: '',
    supportPhone: '',
    supportSnowGroup: '',
    type: 'general',
    url: ''
  },
  {
    aliases: [],
    assetTag: '',
    businessEntities: [],
    categories: [],
    description: '',
    favorite: true,
    homepageUrl: '',
    iconColor: 'black',
    iconUrl: '',
    id: 4,
    maintenanceActive: false,
    maintenanceFrom: null,
    maintenanceMessage: '',
    maintenanceTo: null,
    managers: [],
    name: '',
    owner: {id: 0, name: '', email: '', phone: ''},
    privateOnly: true,
    supportEmail: '',
    supportPhone: '',
    supportSnowGroup: '',
    type: 'general',
    url: ''
  },
  {
    aliases: [],
    assetTag: '',
    businessEntities: [],
    categories: [],
    description: '',
    favorite: true,
    homepageUrl: '',
    iconColor: 'black',
    iconUrl: '',
    id: 5,
    maintenanceActive: false,
    maintenanceFrom: null,
    maintenanceMessage: '',
    maintenanceTo: null,
    managers: [],
    name: '',
    owner: {id: 0, name: '', email: '', phone: ''},
    privateOnly: true,
    supportEmail: '',
    supportPhone: '',
    supportSnowGroup: '',
    type: 'general',
    url: ''
  },
];
