<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="!completedForm"
      >
        <prj1017-heading
          :header="$i18n.t('ideas.forms.headline')"
        />

        <frm1035-form-group>
          <frm1001-input-field
            id="idea-user-name"
            v-model="ideaSendingForm.userFullName"
            class="vue-ideas-sending-item"
            :tooltip="false"
            :label="$i18n.t('ideas.forms.fullName')"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            id="idea-user-name"
            v-model="ideaSendingForm.userEmail"
            class="vue-ideas-sending-item"
            :tooltip="false"
            :label="$i18n.t('ideas.forms.email')"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            id="idea-name"
            v-model="ideaSendingForm.ideaName"
            class="vue-ideas-sending-item"
            :tooltip="false"
            :label="$i18n.t('ideas.forms.ideaName')"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1028-advanced-combo
            id="idea-category"
            v-model="ideaSendingForm.category"
            :options="formData.categories"
            :tooltip="false"
            :label="$i18n.t('ideas.forms.category')"
            :placeholder="$i18n.t('ideas.forms.categoryPlaceholder')"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1013-textarea
            id="idea-text"
            v-model="ideaSendingForm.ideaText"
            class="vue-ideas-sending-textarea"
            :class="classObject"
            :tooltip="false"
            :label="$i18n.t('ideas.forms.ideaText')"
            :rowsAutoExpand="true"
            :rows="1"
            :rowsAutoExpandMinRows="1"
            :rowsAutoExpandMaxRows="5"
          />
        </frm1035-form-group>

        <prj1010-image-upload
          v-if="!ideaSendingForm.image"
          class="vue-ideas-sending-upload-image"
          @uploadedPhoto="processedPhoto"
        />

        <frm1035-form-group>
          <div
            class="vue-camera-canvas"
          >
            <canvas
              ref="ideaSendingPictureCameraCanvas"
              class="vue-camera-canvas-element"
              width="240"
              height="320"
            />

            <div
              v-if="ideaSendingForm.image"
              class="vue-ideas-sending-uploaded-file"
            >
              <span class="vue-ideas-sending-photo-name">
                {{ ideaSendingForm.imageName }}
              </span>
              <frm1006-button
                class="vue-button-remove-photo"
                type="internal"
                :iconLeft="true"
                :captionHidden="true"
                @buttonClickEvent="removeTakenPicture"
              >
                {{ $t('ideas.forms.removePhoto') }}
              </frm1006-button>
            </div>
          </div>
        </frm1035-form-group>
      </template>
      <div
        v-else
        class="vue-b-form-success"
      >
        <prj1017-heading
          :header="$i18n.t('ideas.forms.thankYou.heading')"
          :subheader="$i18n.t('ideas.forms.thankYou.subHeading')"
        />
      </div>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <template
        v-if="!completedForm"
      >
        <frm1006-button
          class="vue-ideas-sending-submit-button"
          :class="classObject"
          type="primary"
          :disabled="!isFormValid || sending || reportSent"
          @buttonClickEvent="submitIdea"
        >
          {{ $t('ideas.forms.submit') }}
        </frm1006-button>
      </template>
      <template v-else>
        <frm1006-button
          type="primary"
          @buttonClickEvent="goToDashboard"
        >
          {{ $t('ideas.forms.thankYou.backToDash') }}
        </frm1006-button>

        <frm1006-button
          type="secondary"
          @buttonClickEvent="clearForm"
        >
          {{ $t('ideas.forms.thankYou.nextIdeaSending') }}
        </frm1006-button>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
// TODO MSE: improve - add to PWA Config when implementing CSOBZ-288
import dataLoader from '@/utils/data-loader/index';
import logger from '@/utils/logger';
import {
  ERROR_MODAL_TIMEOUT,
  IDEAS_CATEGORIES_LIST_ID
} from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1010ImageUpload from '@/components/prj1010-image-upload/prj1010-image-upload';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import { IDEAS_SENDING } from '@/store/store-modules';
import moduleStatistics from '@/mixins/mx-module-statistics';
import utilsGeneral from '@/utils/utils-general';

export default {
  name: 'IdeasSending',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1010ImageUpload,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      completedForm: false,
      hasError: false,
      formData: {
        categories: [],
      },
      ideaSendingForm: {
        userFullName: '',
        userEmail: '',
        category: '',
        ideaName: '',
        ideaText: '',
        image: null,
        imageName: ''
      },
      refElements: {
        player: null,
        canvas: null,
        fileUpload: null
      },
      pictureOptions: {
        camera: false,
        fileUpload: true
      },
      sending: false,
      reportSent: false,
      reportFailed: false
    }
  },

  computed: {
    ...mapState('user', [
      'profile'
    ]),

    classObject() {
      return {
        'vue-is-active': this.isFormValid,
      }
    },

    isValidEmail() {
      return utilsGeneral.isValidEmail(this.ideaSendingForm.userEmail);
    },

    isFormValid() {
      const isValidEmail = this.isValidEmail;
      const requiredFields = [
        'userFullName',
        'category',
        'ideaName',
        'ideaText'
      ];

      let decision = false;
      let numberOfValidInputs = 0;

      requiredFields.forEach(item => {
        if (this.ideaSendingForm[item].length) {
          numberOfValidInputs++;
        } else {
          numberOfValidInputs--;
        }
      });

      if (numberOfValidInputs === requiredFields.length) {
        decision = true;
      }

      return decision && isValidEmail;
    },

    navbarButtonDestination() {
      return !this.$route.params.isSurfStudio ? 'dashboard' : 'surf-studio'
    }
  },

  created() {
    this.getIdeaSendingFormData();
  },

  mounted() {
    this.ideaSendingForm.userFullName = this.profile?.name ?? '';
    this.ideaSendingForm.userEmail = this.profile?.workEmail ?? (this.profile?.otherEmail ?? '');
    this.refElements.canvas = this.$refs.ideaSendingPictureCameraCanvas;
    this.refElements.fileUpload = this.$refs.ideaSendingPictureFile;
    this.sendStatistics(IDEAS_SENDING);
  },

  methods: {
    processedPhoto(photoObject) {
      this.ideaSendingForm.image = photoObject.blob;
      this.ideaSendingForm.imageName = photoObject.name;
    },

    removeTakenPicture() {
      this.ideaSendingForm.image = null;
      this.ideaSendingForm.imageName = '';
    },

    submitIdea() {
      let { canvas } = this.refElements;
      let imageBinary;
      let imageBase64;

      this.sending = true;

      if (this.pictureOptions.camera) {
        imageBase64 = canvas.toDataURL('image/jpeg');
      }

      if (this.pictureOptions.fileUpload && this.ideaSendingForm.image) {
        imageBinary = this.ideaSendingForm.image;
      }

      return dataLoader.sendIdeaReport(
        this.ideaSendingForm.userFullName,
        this.ideaSendingForm.userEmail,
        this.ideaSendingForm.category,
        this.ideaSendingForm.ideaName,
        this.ideaSendingForm.ideaText,
        imageBase64,
        imageBinary
      ).then(() => {
        logger.info('Fault reporting post has been sent!');
        this.reportSent = true;
        this.completedForm = true;
      }).catch(error => {
        logger.error('Sending fault report has failed:', error);
        this.reportFailed = true;
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.sending = false;
      })
    },

    getIdeaSendingFormData() {
      this.isLoading = true;

      const dataListsIdsArray = [
        IDEAS_CATEGORIES_LIST_ID
      ];

      dataLoader.fetchMultipleDataListsById(dataListsIdsArray)
        .then(data => {
          data.forEach(response => {
            const categoryType = Object.keys(response.data);

            this.formData[categoryType] = response.data[categoryType];
          })
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    goToDashboard() {
      this.$router.push(!this.$route.params.isSurfStudio ? '/dashboard' : '/surf-studio')
    },

    clearForm() {
      this.ideaSendingForm.userFullName = '';
      this.ideaSendingForm.userEmail = '';
      this.ideaSendingForm.category = '';
      this.ideaSendingForm.ideaName = '';
      this.ideaSendingForm.ideaText = '';
      this.ideaSendingForm.image = null;
      this.ideaSendingForm.imageName = '';
      this.completedForm = false;
      this.reportSent = false;
      this.hasError = false;
    }
  }
}
</script>
