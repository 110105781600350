<template>
  <page-layout>
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="navigateBack"
      />
    </template>

    <template v-slot:contentMain>
      <template
        v-if="courseRatingData.length > 0"
      >
        <h1>
          <strong>{{ $t('forvardino.allEventRatings') }}</strong>
        </h1>

        <div
          v-for="(rating, index) in courseRatingData"
          :key="'rating-' + index"
          class="vue-forvardino-rating-item"
        >
          <div class="vue-forvardino-rating-item-row">
            <span class="vue-forvardino-rating-item-name">
              <strong>{{ rating.fullName }}</strong>
            </span>

            <prj1019-star-rating
              :itemRating="rating.score"
            />
          </div>

          <p class="vue-forvardino-rating-item-text">
            {{ rating.comment }}
          </p>
        </div>
      </template>

      <gen1006-info-panel
        v-else
        type="neutral"
      >
        {{ $t('forvardino.noEventRatings') }}
      </gen1006-info-panel>

      <div
        v-if="isInfinityScrollEnabled"
        ref="infinityScrollTrigger"
      >
        <gen1016-loading-indicator
          :active="infinityScrollLoading"
        />
      </div>

      <gen1016-loading-indicator
        :active.sync="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
    </template>

    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';

import mxInfinityScrolling from '@/mixins/mx-infinity-scrolling';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1019StarRating from '@/components/prj1019-star-rating/prj1019-star-rating';
import router from '@/router';

export default {
  name: 'ForvardinoCourseRating',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1019StarRating
  },

  mixins: [
    mxInfinityScrolling,
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      isInfinityScrollEnabled: false,
      infinityScrollLoading: false,
      courseRatingData: [],
      currentPosition: 0,
      hasError: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    courseId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.fetchCourseScores();
  },

  methods: {
    fetchCourseScores() {
      dataLoader.getForvardinoCourseScores(this.courseId, this.currentPosition)
        .then(response => {
          this.courseRatingData = response;

          if (response.length > 19) {
            this.isInfinityScrollEnabled = true;
          }
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          if (this.isInfinityScrollEnabled) {
            this.initInfinityScroll();
          }

          this.isLoading = false;
        });
    },

    async loadNewContent() {
      if (!this.isInfinityScrollEnabled) { return; }
      this.infinityScrollLoading = true;
      this.currentPosition += 20;

      await dataLoader.getForvardinoCourseScores(this.courseId, this.currentPosition)
        .then(response => {
          let tempArray = this.courseRatingData;
          this.courseRatingData = [...tempArray, ...response];

          if (response.length < 20) {
            this.isInfinityScrollEnabled = false;
          }
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          this.infinityScrollLoading = false;
        });
    },

    navigateBack() {
      if (this.$route.params.eventId) {
        router.push({
          name: 'forvardino-detail', params: {
            id: this.$route.params.eventId,
            origin: this.$route.params.origin
          }
        });
      } else {
        router.push({name: 'forvardino-list'});
      }
    }
  }
}
</script>
