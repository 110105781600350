import axios from 'axios';
import axiosConfig from '@/utils/data-loader/axiosConfig'
import * as envConfig from 'env-config';
import store from '@/store/index'

function customHeaders() {
  const userStore = store.state.user;
  return {
    withCredentials: true,
    headers: {
      token: userStore.refreshToken,
      axiosType: 'custom',
      deviceId: userStore.deviceId
    }
  }
}

export default {
  putRegistration(formData) {
    let data = {
      transport: formData ? formData.transport : null,
    }
    return axios
      .post(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_USER_APP_REGISTRATION, data, customHeaders())
      .then((response) => response.data)
  },

  deleteRegistration() {
    return axios
      .delete(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_USER_APP_REGISTRATION, customHeaders())
      .then((response) => response.data)
  },

  getRegistration() {
    return axios
      .get(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_USER_APP_REGISTRATION, customHeaders())
      .then((response) => response.data)
  },

  // deprecated
  registration(userData) {
    return axiosConfig.post(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_USER_APP_REGISTRATION, userData).then(response => response.data);
  },
};
