<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1032-news-list
        v-if="newsCategoryId > 0"
        ref="newsList"
        :categoryIdInitial="newsCategoryId"
        :categoryFilter="false"
        @initializedEvent="initializeNewsListEvent"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import {
  ERROR_MODAL_TIMEOUT,
} from '@/constants/app-constants';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1032NewsList from '@/components/prj1032-news-list/prj1032-news-list';

export default {
  name: 'SurfStudioNews',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1032NewsList
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      newsListInitialized: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    };
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),

    newsCategoryId() {
      let categoryId = 0;
      if (this.applicationConfigLoaded) {
        let tmpCustomFields = this.applicationConfig.customFields;
        tmpCustomFields.forEach(item => {
          if(item.key === 'SURFSTUDIO_NEWS_CAT_ID') {
            categoryId = parseInt(item.value);
          }
        });
      }
      return categoryId;
    },

    navbarCaption() {
      return this.$route.meta.navbarCaption;
    },

    componentInitialized() {
      return this.newsListInitialized && (this.newsCategoryId > 0)
    }
  },

  watch: {
    componentInitialized() {
      this.isLoading = false;
    },
  },

  mounted() {
    this.isLoading = true;
  },

  methods: {
    initializeNewsListEvent() {
      this.newsListInitialized = true;
    },
  }
}
</script>
