import axiosConfig from '@/utils/data-loader/axiosConfig'
import * as envConfig from 'env-config'
import utilsGeneral from '@/utils/fest/utils-general'
import logger from '@/utils/logger'

export default {
  getArticleById(id) {
    return axiosConfig
      .get(`${envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_NEWS}/${id}?_embed`)
      .then((response) => response.data)
  },
  getAllArticles(categories = 0, page = 1, perPage = 20, orderBy = 'date', order = 'desc') {
    return axiosConfig
      .get(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_NEWS, {
        params: {
          page: page,
          per_page: perPage,
          orderby: orderBy,
          order: order,
          categories: categories,
          _embed: '',
        },
      },
      )
      .then((response) => response.data)
  },
  getPageById(id) {
    return axiosConfig
      .get(`${envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_PAGES}/${id}?_embed`)
      .then((response) => response.data)
  },
  /**
   * Get News By Theme
   * @param {Number} perPage - default null - all available items are fetched
   * @param {Number} themeId
   * @returns {Promise<{status}|{data: any, status: number}>}
   */
  async getNewsByTheme({ perPage = null, themeId }) {
    const processId = utilsGeneral.startAsyncProcess()

    try {
      const res = await axiosConfig.get(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_NEWS, {
        params: {
          page: 1,
          per_page: perPage,
          orderby: 'date',
          order: 'desc',
          meta_key: 'themeId',
          meta_value: themeId,
          _embed: '',
        },
      })

      const { data, status } = res
      if (status === 200) return { data, status }
    } catch (error) {
      logger.error(error.response)
      return { status: error.response.data.code }
    } finally {
      utilsGeneral.stopAsyncProcess(processId)
    }
  },
}
