import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import dataLoader from '@/utils/data-loader';

const state = {
  benefitsFilterCategories: {
    locations: {},
    categories: {}
  },
  benefitsFiltersLoaded: false,
  benefitsSelectedCategories: {
    categories: [],
    location: [],
    searchString: ''
  },
  benefitsActiveCategory: 0,
  benefitsAreFiltersActive: false,
  searchQuery: '',
  detailData: {},
  isSearchActive: false,
  isSearchHistoryActive: false,
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_BENEFITS_FILTER_CATEGORIES](state, categoriesObject) {
    state.benefitsFilterCategories.locations = categoriesObject.locations;
    state.benefitsFilterCategories.categories = categoriesObject.categories;
  },

  [MUTATIONS_CONSTANTS.SET_BENEFITS_FILTERS_LOADED](state) {
    state.benefitsFiltersLoaded = true;
  },

  [MUTATIONS_CONSTANTS.SET_BENEFITS_ACTIVE_CATEGORY](state, activeCategory) {
    state.benefitsActiveCategory = activeCategory;
  },

  [MUTATIONS_CONSTANTS.SET_BENEFITS_FILTER_ACTIVE](state, filterState) {
    state.benefitsAreFiltersActive = filterState;
  },

  [MUTATIONS_CONSTANTS.SET_BENEFITS_SELECTED_CATEGORIES](state, selectedCategories) {
    state.benefitsSelectedCategories.categories = selectedCategories.categories;
    state.benefitsSelectedCategories.location = selectedCategories.locations;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_QUERY](state, searchQuery) {
    state.searchQuery = searchQuery;
  },

  [MUTATIONS_CONSTANTS.SET_DETAIL_DATA](state, detailData) {
    state.detailData = detailData;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_STATUS](state, status) {
    state.isSearchActive = status;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS](state, status) {
    state.isSearchHistoryActive = status;
  },
};

const actions = {
  [ACTIONS_CONSTANTS.FETCH_BENEFITS_FILTER_DATA]({ commit }) {
    dataLoader.fetchBenefitsFiltersData()
      .then(filterData => {
        commit(MUTATIONS_CONSTANTS.SET_BENEFITS_FILTER_CATEGORIES, filterData);
        commit(MUTATIONS_CONSTANTS.SET_BENEFITS_FILTERS_LOADED, true);
      });
  },

  [ACTIONS_CONSTANTS.RESET_ALL_BENEFITS_FILTER]({ commit }) {
    const resettedBenefitsObject = {
      locations: [],
      categories: [],
    }

    commit(MUTATIONS_CONSTANTS.SET_BENEFITS_SELECTED_CATEGORIES, resettedBenefitsObject);
    commit(MUTATIONS_CONSTANTS.SET_BENEFITS_ACTIVE_CATEGORY, 0);
    commit(MUTATIONS_CONSTANTS.SET_BENEFITS_FILTER_ACTIVE, false);
  }
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
