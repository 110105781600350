<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div class="vue-b-knowledge-base-category">
          <frm1035-form-group>
            <frm1028-advanced-combo
              v-model="selectedDepartment"
              :options="departmentOptions"
              :label="departmentName"
              :tooltip="false"
              :searchable="false"
            />
          </frm1035-form-group>
          <div
            class="vue-b-info-controls"
          >
            <frm1006-button
              v-for="(page, index) in filteredPages"
              :key="index"
              :icon-right="true"
              class="vue-control-item"
              type="internal"
              @buttonClickEvent="goToView(page)"
            >
              <span v-html="page.name"></span>
            </frm1006-button>
          </div>
          <gen1006-info-panel
            v-if="!filteredPages.length"
            type="neutral"
            class="vue-no-data"
          >
            {{ $t('knowledgeBase.noKnowledges') }}
          </gen1006-info-panel>
        </div>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <transition
        name="vue-anim-strategy-move-from-bottom"
        appear
      >
        <div class="vue-knowledge-base-category-illustration" />
      </transition>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import { mapState } from 'vuex';
import router from '@/router';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'KnowledgeBaseCategory',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: false,
      hasError: false,
      initialized: false,
      pages: [],
      selectedDepartment: '',
      departments: [],
    };
  },

  computed: {
    ...mapState('knowledge', [
      'searchQuery'
    ]),

    categoryId() {
      return this.$route.params.categoryId;
    },

    navbarCaption() {
      return this.$t('knowledgeBase.categories')[this.categoryId].name;
    },

    departmentName() {
      return this.$t('knowledgeBase.categories')[this.categoryId].departmentName;
    },

    departmentOptions() {
      const options =  this.departments.map(item => {
        return {value: item.id, caption: item.name}
      })
      return [{value: '', caption: this.$t('knowledgeBase.all')}, ...options]
    },

    filteredPages() {
      if (!this.selectedDepartment) return this.pages;
      return this.pages.filter(page => page.structureId.includes(this.selectedDepartment))
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },

    searchQuery() {
      this.getKnowledgeBaseList();
    }
  },

  mounted() {
    this.getKnowledgeBaseList().then(() => {
      this.initialized = true;
    });
  },

  methods: {
    setLoadingState(value) {
      if (value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getKnowledgeBaseList() {
      this.isLoading = true;

      return dataLoader.fetchKnowledgeBaseList(this.categoryId, this.searchQuery)
        .then(data => {
          this.pages = data.pages;
          this.departments = data.structures;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.isLoading = false;
        })
    },

    goToView(item) {
      // save to store
      this.$store.commit(STORE_MODULES.KNOWLEDGE + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, item);
      router.push({ name: 'knowledge-base-detail', params: { categoryId: this.categoryId, pageId: item.id }});
    },
  }
}
</script>
