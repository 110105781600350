import axios from 'axios';
import * as envConfig from 'env-config';
import store from '@/store/index.js';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
//import router from '@/router';
import logger from '@/utils/logger';
import {FEST_USER} from '@/store/store-modules';
import {DELETE_USER_FESTIVAL_DATA} from '@/store/constants/fest/actions';

const axiosConfig = axios.create({
  withCredentials: true
});

function axiosCustomConfig() {
  return {
    withCredentials: true,
    headers: {
      'X-Zapka-appVersion': store?.state?.general?.appVersion,
      'X-Zapka-appDestination': store?.state?.general?.appDestination,
      'X-Zapka-isStandalone': store?.state?.user?.isStandalone,
      deviceId: store?.state?.user?.deviceId,
      axiosType: 'custom'
    }
  }
}

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

/*
function delay(t) {
  return new Promise(resolve => setTimeout(resolve, t));
}
*/

// Add a request interceptor to add headers in runtime - anything starting X-Zapka- will get automatically logged
axiosConfig.interceptors.request.use(
  function (request) {
    request.headers['X-Zapka-appVersion'] = store?.state?.general?.appVersion;
    request.headers['X-Zapka-appDestination'] = store?.state?.general?.appDestination;
    request.headers['X-Zapka-isStandalone'] = store?.state?.user?.isStandalone;
    request.headers['deviceId'] = store?.state?.user?.deviceId;
    request.headers['axiosType'] = 'default';
    return request;
  },
  function(error) {
    return error;
  }
);

// Add a response interceptor to handle 401 error
axiosConfig.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    logger.info('Axios error');
    logger.info(error.request);

    if (error.request && error.request.status === 401 && error.response.data.code === 'rest_unauthenticated') {
      logger.info('401 error');
      const userStore = store.state.user;
      /*
      if (userStore.tokenStatus === 'REFRESHING') { // another request is currently refreshig token, try again later
        return delay(1000).then(() => {
          let cancelableConfig = error.config;
          cancelableConfig.cancelToken = source.token;
          return axios.request(cancelableConfig);
        });
      }


      store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_TOKEN_STATUS, 'REFRESHING');
      */
      if (userStore.refreshToken) {  // refresh login via token
        let formData = new FormData();
        formData.append('deviceId', userStore.deviceId);
        formData.append('refreshToken', userStore.refreshToken);
        return axios.post(envConfig.default.webServices.CMS_IDENTITY_AUTHORIZATION, formData, axiosCustomConfig())
          .then((response) => {
            logger.info('Authorize via refresh token OK');
            store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_REFRESH_TOKEN, response.data.refreshToken);
            store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PUBLIC_KEY, response.data.publicKey);
            // Retry failed request
            return axios.request(error.config);
          })
          .catch((authError) => {
            if (authError.request && authError.request.status === 401 && authError.response.data.code === 'rest_invalid_token') {
              store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.LOGOUT_USER);
              store.dispatch(FEST_USER + '/' + DELETE_USER_FESTIVAL_DATA);
            }
            logger.error('Authorize token error');
            logger.error(authError);
            source.cancel();
            return Promise.reject(error);
            //router.push({name: 'login'});
          })
          .finally(()=> {
            store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_TOKEN_STATUS, 'N/A');
          })
      } else if (userStore.publicKey) {  // use stored publicKey to login and convert it to refresh token
        let formData = new FormData();
        formData.append('deviceId', userStore.deviceId);
        formData.append('password', userStore.publicKey);
        return axios.post(envConfig.default.webServices.CMS_IDENTITY_AUTHORIZATION, formData, axiosCustomConfig())
          .then((response) => {
            logger.info('Authorize via publicKey OK');
            store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_REFRESH_TOKEN, response.data.refreshToken);
            // store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PUBLIC_KEY, null);   // TODO: povolit az BE nebude nikde pouzivat public key

            // Retry failed request
            return axios.request(error.config);
          })
          .catch((authError) => {
            logger.error('Authorize publickey error');
            logger.error(authError);
            source.cancel();
            return Promise.reject(error);
            //router.push({name: 'login'});
          })
          .finally(()=> {
            store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_TOKEN_STATUS, '');
          })
      } else {  // redirect to login page
        store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_TOKEN_STATUS, '');
        source.cancel();
        //router.push({name: 'login'});
      }
    } else {
      logger.info('non 401 error');
      return Promise.reject(error);
    }
  }
);

export default axiosConfig;
