<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div class="relative prj-article-image h-66 md:h-80 bg-gray-100">
    <img
      v-if="hasImage"
      :src="articleImageUrl"
      alt
      class="w-full h-full object-cover"
    />
    <prj1018-esg-badge
      v-if="categoryId"
      class="absolute left-8 bottom-8"
      iconClasses="h-4 w-4"
      :categoryId="categoryId"
      :themeTitle="themeTitle"
      hasBg
      hasCategoryTitle
      hasCategoryHash
      hasCategoryIcon
    />
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ EXPORT ==================================//
//======================================================//
import Prj1018EsgBadge from '@/components/esg/prj1018-esg-badge/prj1018-esg-badge'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1003ArticleImage',
  components: { Prj1018EsgBadge },
  props: {
    articleImageUrl: null,
    categoryId: {
      type: Number,
      default: null,
    },
    themeTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    hasImage() {
      return this.articleImageUrl
    },
  },
  mounted() {},
  methods: {},
}
</script>
