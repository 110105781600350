<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div class="vue-b-repeater-footer-content">
        <!--========== CONTROLS ===============================-->
        <!--===================================================-->
        <div class="vue-b-repeater-controls">
            <!-- BUTTON TOP  -->
            <div v-if="hasButtonScrollTop" class="vue-b-to-the-top">
                <Frm1006Button
                    :type="buttonScrollTopType"
                    :title="buttonScrollTopCaption"
                    class="vue-ci-to-the-top-button"
                    @buttonClickEvent="repeaterScrollTop"
                    >{{ buttonScrollTopCaption }}
                </Frm1006Button>
            </div>

            <!-- BUTTON LOAD MORE -->
            <div v-if="hasButtonLoadMore" class="vue-b-load-more">
                <Frm1006Button
                    :type="buttonLoadMoreType"
                    :title="buttonLoadMoreCaption"
                    class="vue-ci-load-more-button"
                    @buttonClickEvent="repeaterLoadMore"
                >
                    <slot name="buttonLoadMore" :loadMoreItemsCount="repeaterLoadMoreItemsCount">{{
                        buttonLoadMoreCaption
                    }}</slot>
                </Frm1006Button>
            </div>

            <!-- PAGINATION -->
            <div v-if="hasPagination" class="vue-b-pagination">
                <Tbl1002Pagination
                    :buttons="paginationButtons"
                    :buttonPrevType="paginationButtonPrevType"
                    :buttonPageType="paginationButtonPageType"
                    :buttonNextType="paginationButtonNextType"
                    :input="paginationInput"
                    :pageCurrent="repeaterPageCurrent"
                    :pageCurrentRadius="paginationPageCurrentRadius"
                    :pagesTotal="repeaterPagesTotal"
                    class="vue-ci-repeater-pagination"
                    @paginationButtonPrevClickEvent="repeaterPagePrev"
                    @paginationButtonPageClickEvent="repeaterPageSet"
                    @paginationButtonNextClickEvent="repeaterPageNext"
                    @paginationInputChangeEvent="repeaterPageSet"
                />
                <frm1002-combo
                    v-model="itemsPerPageData"
                    :options="itemsPerPageOptions"
                    :label="itemsPerPageLabelComputed"
                    :tooltip="false"
                    @selectChangeEvent="setItemsPerPage"
                />
            </div>
        </div>

        <!--========== MISC ===================================-->
        <!--===================================================-->
        <div v-if="$scopedSlots.countSummary" class="vue-b-repeater-count-summary">
            <div class="vue-repeater-count-summary">
                <slot
                    name="countSummary"
                    :itemsFrom="repeaterItemsFrom"
                    :itemsTo="repeaterItemsTo"
                    :itemsTotal="repeaterItemsTotal"
                ></slot>
            </div>
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//=== GEN
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';
import Tbl1002Pagination from '../tbl1002-pagination/tbl1002-pagination.vue';
import Frm1002Combo from '../../frm/frm1002-combo/frm1002-combo';

//=== MIXINS
import Localization from '../../mixins/localization';

export default {
    name: 'Tbl1001RepeaterControls',
    components: {
        Frm1002Combo,
        Frm1006Button,
        Tbl1002Pagination
    },
    mixins: [Localization],
    props: {
        //============ PAGINATION ==============================//
        //======================================================//
        paginationButtons: {
            default: true,
            type: Boolean
        },
        paginationInput: { default: true, type: Boolean },
        paginationButtonPrevType: {
            default: 'internal',
            type: String
        },
        paginationButtonPageType: {
            default: 'internal',
            type: String
        },
        paginationButtonNextType: {
            default: 'internal',
            type: String
        },
        paginationPageCurrentRadius: {
            default: 2,
            type: Number
        },
        itemsPerPageOptions: {
            type: Array,
            default: () => [
                {
                    value: '5',
                    caption: '5'
                },
                {
                    value: '10',
                    caption: '10'
                },
                {
                    value: '20',
                    caption: '20'
                },
                {
                    value: '50',
                    caption: '50'
                },
                {
                    value: '100',
                    caption: '100'
                }
            ]
        },
        itemsPerPageLabel: String,
        //============ ADDITIONAL ELEMENTS =====================//
        //======================================================//
        buttonScrollTop: {
            default: true,
            type: Boolean
        },
        buttonScrollTopCaption: {
            default: 'Nahoru',
            type: String
        },
        buttonScrollTopType: {
            default: 'internal',
            type: String
        },
        buttonLoadMore: {
            default: true,
            type: Boolean
        },
        buttonLoadMoreCaption: String,
        buttonLoadMoreType: {
            default: 'internal',
            type: String
        },
        //============ REPEATER ================================//
        //======================================================//
        //=== DATA
        repeaterPageCurrent: Number,
        //=== PROPS
        repeaterPaginated: Boolean,
        repeaterItemsPerPage: Number,
        //=== COMPUTED
        repeaterHasItems: Boolean,
        repeaterPagesTotal: Number,
        repeaterItemsFrom: Number,
        repeaterItemsTo: Number,
        repeaterItemsTotal: Number,
        repeaterLoadMoreItemsCount: Number,
        //=== METHODS
        repeaterPageSet: Function,
        repeaterPagePrev: Function,
        repeaterPageNext: Function,
        repeaterLoadMore: Function,
        repeaterScrollTop: Function,
        repeaterSetCssClassesAdditional: Function,
        repeaterSetItemsPerPage: Function
    },
    data() {
        return {
            pageCurrentData: this.repeaterPageCurrent,
            itemsPerPageData: this.repeaterItemsPerPage.toString()
        };
    },
    computed: {
        hasPagination() {
            return this.repeaterPaginated && this.repeaterHasItems;
        },
        hasButtonScrollTop() {
            return this.buttonScrollTop && this.repeaterHasItems;
        },
        hasButtonLoadMore() {
            return this.buttonLoadMore && this.repeaterLoadMoreItemsCount > 0;
        },
        repeaterCssClassesAdditional() {
            let repeaterCssClassesAdditional = [];

            if (this.hasPagination) {
                repeaterCssClassesAdditional.push('vue-has-pagination');
            }

            if (this.hasButtonScrollTop) {
                repeaterCssClassesAdditional.push('vue-has-button-scroll-top');
            }

            if (this.hasButtonLoadMore) {
                repeaterCssClassesAdditional.push('vue-has-button-load-more');
            }

            return repeaterCssClassesAdditional;
        },

        itemsPerPageLabelComputed() {
            if (this.itemsPerPageLabel) {
                return this.itemsPerPageLabel;
            }

            return this.i18n('itemsPerPage');
        }
    },
    watch: {
        repeaterItemsPerPage(value) {
            this.itemsPerPageData = value.toString();
        },
        repeaterCssClassesAdditional: {
            immediate: true,
            handler(value) {
                this.repeaterSetCssClassesAdditional(value);
            }
        }
    },
    mounted() {},
    methods: {
        setItemsPerPage(value) {
            let valueNumber = parseInt(value);

            this.repeaterPageSet(1);
            this.repeaterSetItemsPerPage(valueNumber);
        }
    }
};
</script>
