<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    class="prj-c-event-card flex-shrink-0 flex max-h-px-680 first:pl-7 last:pr-7"
    :class="computedWidth"
  >
    <div
      class="prj-pseudo-img flex flex-col w-full p-5 rounded-2xl"
      :class="classObject"
      :style="cssVars"
      :tabindex="isClickable ? 0 : null"
      :role="isClickable ? 'button' : null"
      :aria-pressed="isClickable ? false : null"
      @keyup.enter="onCardClick"
      @click="onCardClick"
    >
      <!--========== EFFECTS OVERLAY =========================-->
      <!--===================================================-->
      <div :class="overlayClassObject"></div>

      <!--========== PARTICIPANTS ===========================-->
      <!--===================================================-->
      <div
        v-if="participants"
        class="
          absolute
          -top-3
          -right-2
          flex flex-row
          px-3
          py-2
          bg-yellow-200
          rounded-2xl
          text-sm
          leading-5
          font-semibold
          z-20
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>

        {{ getParticipantsLabel() }}
      </div>

      <!--========== CATEGORY STAMP =========================-->
      <!--===================================================-->
      <!--      TODO check position -->
      <div
        v-if="hasStampsSlot"
        class="absolute top-5 right-4"
      >
        <slot name="stamps"></slot>
      </div>

      <!--========== HEADER + STATUS TAG ====================-->
      <!--===================================================-->
      <section
        class="relative text-white z-20"
        :class="[isModeOverlay ? 'mt-3' : 'mt-2']"
      >
        <span
          v-if="statusTag"
          class="absolute -top-2 xs:top-0 left-0 font-semibold"
          :class="[isModeOverlay ? 'text-xs' : 'text-sm']"
        >
          {{ statusTag }}
        </span>
        <slot name="header" />
      </section>

      <!--========== CONTENT ================================-->
      <!--===================================================-->
      <section class="relative text-white z-20 xs:mt-2 mb-2 xs:mb-4">
        <slot name="content" />
      </section>

      <!--========== CONTROLS ===============================-->
      <!--===================================================-->
      <section class="mt-auto z-20">
        <slot name="controls" />
      </section>
    </div>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
import { DEFAULT, EXPIRED, RUNNING, WAITING } from '@/constants/esg/status'

/**
 * Event card
 * @description UI component with two main modes
 * @param {Boolean} isModeOverlay 1. false: hold content within regular page
 *                                2. true: renders smaller version to hold content within overlays
 */
//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1007EventCard',
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    isModeOverlay: {
      type: Boolean,
      default: false,
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: DEFAULT,
      validator: (value) => [DEFAULT, WAITING, RUNNING, EXPIRED].includes(value),
    },
    participants: {
      type: Number,
      default: null,
    },
    statusTag: {
      type: String,
      default: null,
    },
    widthClass: {
      type: String,
      default: 'w-6/7',
    },
  },
  computed: {
    hasStampsSlot() {
      return !!this.$slots.stamps
    },
    computedWidth() {
      return this.isSingle ? 'w-full' : this.widthClass
    },
    cssVars() {
      return {
        '--bg-img': `url(${this.image})`,
      }
    },
    isDefault() {
      return this.status === DEFAULT
    },
    isWaiting() {
      return this.status === WAITING
    },
    isRunning() {
      return this.status === RUNNING
    },
    isExpired() {
      return this.status === EXPIRED
    },
    classObject() {
      return {
        'before:filter before:grayscale before:opacity-50': this.isWaiting || this.isExpired,
        'before:filter before:contrast-75': this.isDefault || this.isRunning,
        'after:prj-pseudo-bounds after:absolute after:bg-emerald-500 after:opacity-50': this.isWaiting,
        'ring-8 ring-teal-600': this.isHighlighted,
      }
    },
    overlayClassObject() {
      return {
        'prj-pseudo-overlay-default': this.isDefault && !this.isModeOverlay,
        'prj-pseudo-overlay-default-lg': this.isDefault && this.isModeOverlay,
        'prj-pseudo-overlay-waiting': this.isWaiting,
        'prj-pseudo-overlay-running': this.isRunning,
        'prj-pseudo-overlay-expired': this.isExpired,
      }
    },
  },
  methods: {
    onCardClick() {
      if (this.isClickable) return this.$emit('cardClick')
      return false
    },
    getParticipantsLabel() {
      if (this.participants === 1) {
        return this.$t('esg.events.participantsLabel.single', { participants: this.participants })
      }
      if (this.participants > 1 && this.participants < 5) {
        return this.$t('esg.events.participantsLabel.upToFour', { participants: this.participants })
      }
      return this.$t('esg.events.participantsLabel.multiple', { participants: this.participants })
    },
  },
}
</script>
