import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/i18n';
import store from '@/store/index.js';

//============ TEMPLATES ===============================//
//======================================================//
import PageDefault from '@/templates/page-default';
import PageService from '@/templates/page-service';

//============ VIEWS ===================================//
//======================================================//
import NotFound404 from '@/views/errors/NotFound404';

import Login from '@/views/user/Login';
import VerifyCode from '@/views/user/VerifyCode';
import Registration from '@/views/user/Registration';
import RegistrationParents from '@/views/user/RegistrationParents';

import Dashboard from '@/views/dashboard/Dashboard';

import NewsList from '@/views/news/NewsList';
import NewsDetail from '@/views/news/NewsDetail';
import NewsDetailComments from '@/views/news/NewsDetailComments';
import NewsDetailCommentAdd from '@/views/news/NewsDetailCommentAdd';

import ServicesList from '@/views/services/ServicesList';
import ServicesDetail from '@/views/services/ServicesDetail';
// DEPRECATED //
import ServiceDetail from '@/views/services/ServiceDetail';

import RestaurantsMenu from '@/views/restaurants-menu/RestaurantsMenu';
import RestaurantsDetail from '@/views/restaurants-menu/RestaurantsDetail';
import RestaurantsInfo from '@/views/restaurants-menu/RestaurantsInfo';
import RestaurantsPictograms from '@/views/restaurants-menu/RestaurantsPictograms';

import RoomSearch from '@/views/room-search/RoomSearch';

import FaultReporting from '@/views/fault-reporting/FaultReporting';

import Events from '@/views/events/Events';
import EventDetail from '@/views/events/EventDetail';

import ForvardinoList from '@/views/forvardino/ForvardinoList';
import FovardinoDetail from '@/views/forvardino/ForvardinoDetail';
import ForvardinoLector from '@/views/forvardino/ForvardinoLector';
import ForvardinoCourseRating from '@/views/forvardino/ForvardinoCourseRating';
import ForvardinoInfo from '@/views/forvardino/ForvardinoInfo';
import ForvardinoCourseManager from '@/views/forvardino/ForvardinoCourseManager';

import Workouts from '@/views/workouts/Workouts';
import WorkoutDetail from '@/views/workouts/WorkoutDetail';

import RecruitmentSearch from '@/views/recruitment/RecruitmentSearch';
import RecruitmentDashboard from '@/views/recruitment/RecruitmentDashboard';
import RecruitmentListing from '@/views/recruitment/RecruitmentListing';
import RecruitmentListingForm from'@/views/recruitment/RecruitmentListingForm';
import RecruitmentDetail from '@/views/recruitment/RecruitmentDetail';
import RecruitmentFormSF from '@/views/recruitment/RecruitmentFormSF';
import RecruitmentDetailFormCandidate from '@/views/recruitment/RecruitmentDetailFormCandidate';

import Strategy from '@/views/strategy/Strategy';
import Strategy2022 from '@/views/strategy/Strategy2022';
import StrategyDetail from '@/views/strategy/StrategyDetail';
import StrategyCompetitionIntro from '@/views/strategy/StrategyCompetitionIntro';
import StrategyCompetitionRegistration from '@/views/strategy/StrategyCompetitionRegistration';
import StrategyCompetition from '@/views/strategy/StrategyCompetition';
import StrategyCompetitionSummary from '@/views/strategy/StrategySummary';
import StrategyMainObjectives from '@/views/strategy/StrategyMainObjectives';

import UserSettings from '@/views/user/UserSettings';
import Messages from '@/views/user/Messages';

import IframeViewer from '@/views/iframe-viewer/IframeViewer';

import NPSDashboard from '@/views/nps/NPSDashboard';
import NPSDetail from '@/views/nps/NPSDetail';
import NPSInfo from '@/views/nps/NPSInfo';

import PagesDetail from '@/views/pages/PagesDetail';

import Covid19Dashboard from '@/views/covid-19/Covid19Dashboard';
import Covid19FormInfo from '@/views/covid-19/Covid19FormInfo';
import TrioDashboard from '@/views/trio/TrioDashboard';

import SurveyIntro from '@/views/survey/SurveyIntro';
import SurveyForm from '@/views/survey/SurveyForm';
import SurveyThankYou from '@/views/survey/SurveyThankYou';

import AdventDashboard from '@/views/advent/AdventDashboard';
import AdventDetail from '@/views/advent/AdventDetail';

import BuildingHHQDashboard from '@/views/building-hhq/BuildingHHQDashboard';
import BuildingHHQList from '@/views/building-hhq/BuildingHHQList';
import BuildingHHQStart from '@/views/building-hhq/BuildingHHQStart';
import BuildingHHQInfo from '@/views/building-hhq/BuildingHHQInfo';

import ParentsDashboard from '@/views/parents/ParentsDashboard';
import ParentsList from '@/views/parents/ParentsList';
import ParentsFaq from '@/views/parents/ParentsFaq';
import ParentsProgramRegistration from '@/views/parents/ParentsProgramRegistration';
import ParentsPoolLogin from '@/views/parents/ParentsPoolLogin';
import ParentsBabysittingFormWarning from '@/views/parents/ParentsBabysittingFormWarning';

import HelpPage from '@/views/help/Help';

import AnnualInnovationReport from '@/views/annual-innovation-report/AnnualInnovationReport';

import DockDellDashboard from '@/views/dock-dell/DockDellDashboard';

import BenefitsList from '@/views/benefits/BenefitsList';
import BenefitsDetail from '@/views/benefits/BenefitsDetail';
import IdeasSending from '@/views/ideas/IdeasSending';

import KnowledgeBaseDashboard from '@/views/knowledge-base/KnowledgeBaseDashboard.vue';
import KnowledgeBaseCategory from '@/views/knowledge-base/KnowledgeBaseCategory.vue';
import KnowledgeBaseDetail from '@/views/knowledge-base/KnowledgeBaseDetail.vue';

import MarketplaceDashboard from '@/views/marketplace/MarketplaceDashboard.vue';
import MarketplaceInfo from '@/views/marketplace/MarketplaceInfo.vue';
import MarketplaceRules from '@/views/marketplace/MarketplaceRules.vue';
import MarketplaceForm from '@/views/marketplace/MarketplaceForm.vue';
import MarketplaceList from '@/views/marketplace/MarketplaceList.vue';
import MarketplaceDetail from '@/views/marketplace/MarketplaceDetail.vue';

import SurfStudioDashboard from '@/views/surf-studio/SurfStudioDashboard.vue';
import SurfStudioNews from '@/views/surf-studio/SurfStudioNews.vue';
import SurfStudioVoice from '@/views/surf-studio/SurfStudioVoice.vue';
import SurfStudioVoiceContent from '@/views/surf-studio/SurfStudioVoiceContent.vue';
import SurfStudioEvents from '@/views/surf-studio/SurfStudioEvents.vue';
import SurfStudioCreativePowerMenu from '@/views/surf-studio/SurfStudioCreativePowerMenu.vue';
import SurfStudioTrendBook from '@/views/surf-studio/SurfStudioTrendBook.vue';
import SurfStudioSurfKemp from '@/views/surf-studio/SurfStudioSurfKemp.vue';

import EmergencyDashboard from '@/views/emergency/EmergencyDashboard.vue';
import EmergencyContacts from '@/views/emergency/EmergencyContacts.vue';
import EmergencyGeneralInformation from '@/views/emergency/EmergencyGeneralInformation.vue';
import EmergencyCrisisSituation from '@/views/emergency/EmergencyCrisisSituation.vue';
import EmergencyCrisisSituationDetail from '@/views/emergency/EmergencyCrisisSituationDetail.vue';
import EmergencyPageDetail from '@/views/emergency/EmergencyPageDetail.vue';
import EmergencyBuildingPlans from '@/views/emergency/EmergencyBuildingPlans.vue';
import EmergencyBuildingPlanDetail from '@/views/emergency/EmergencyBuildingPlanDetail.vue';

import AdaptationDashboard from '@/views/adaptation/AdaptationDashboard.vue';
import AdaptationDetail from '@/views/adaptation/AdaptationDetail.vue';
import AdaptationContacts from '@/views/adaptation/AdaptationContacts.vue';

import EducationDashboard from '@/views/education/EducationDashboard.vue';

import DirectoryList from '@/views/directory/DirectoryList.vue';

import Profile from '@/views/user/Profile.vue'
import ProfileEditName from '@/views/user/ProfileEditName.vue'
import ProfileEditPhone from '@/views/user/ProfileEditPhone.vue'
import ProfileCancelAccount from '@/views/user/ProfileCancelAccount.vue'

import loginUtils from '@/utils/login-utils';
import {APP_ENTRY_PATH_STORAGE_KEY} from '@/constants/general';

import Promoted from '@/views/promoted/PromotedList.vue';

import ComingActions from '@/views/coming-actions/ComingList.vue';

//============ ESG =====================================//
//======================================================//
import ESGDashboard from '@/views-esg/Dashboard';

import ESGAbout from '@/views-esg/About';

import ESGNewsList from '@/views-esg/news/News.vue';
import ESGNewsDetail from '@/views-esg/news/NewsDetail.vue';

import ESGTopicDetail from '@/views-esg/topics/TopicDetail';

import ESGThemes from '@/views-esg/themes/Themes';
import ESGThemeDetail from '@/views-esg/themes/ThemeDetail';

import ESGTargets from '@/views-esg/targets/Targets';

import ESGChallenges from '@/views-esg/challenges/Challenges';
import ESGChallengeDetail from '@/views-esg/challenges/ChallengeDetail';
import ESGChallengeHistory from '@/views-esg/challenges/ChallengeHistory';
import ESGChallengeResults from '@/views-esg/challenges/ChallengeResults';

import ESGCompetitionDetail from '@/views-esg/competitions/CompetitionDetail';
import ESGCompetitionResult from '@/views-esg/competitions/CompetitionResult';
import ESGCompetitions from '@/views-esg/competitions/Competitions';

import ESGStories from '@/views-esg/stories/Stories';
import ESGStory from '@/views-esg/stories/Story';
import ESGNewStory from '@/views-esg/stories/NewStory';
import ESGWinnersStories from '@/views-esg/stories/WinnersStories';

import ESGSuccessForm from '@/views-esg/forms/success';

import ESGPage from '@/views-esg/pages/Page';

//============ FEST ====================================//
//======================================================//
import FESTDashboard from '@/views-fest/Dashboard';

import FESTAbout from '@/views-fest/About';

import FESTNewsList from '@/views-fest/news/News.vue';
import FESTNewsDetail from '@/views-fest/news/NewsDetail.vue';

import FESTTopicDetail from '@/views-fest/topics/TopicDetail';

import FESTThemes from '@/views-fest/themes/Themes';
import FESTThemeDetail from '@/views-fest/themes/ThemeDetail';

import FESTTargets from '@/views-fest/targets/Targets';

import FESTChallenges from '@/views-fest/challenges/Challenges';
import FESTChallengeDetail from '@/views-fest/challenges/ChallengeDetail';
import FESTChallengeHistory from '@/views-fest/challenges/ChallengeHistory';
import FESTChallengeResults from '@/views-fest/challenges/ChallengeResults';

import FESTCompetitionDetail from '@/views-fest/competitions/CompetitionDetail';
import FESTCompetitionResult from '@/views-fest/competitions/CompetitionResult';
import FESTCompetitions from '@/views-fest/competitions/Competitions';

import FESTStories from '@/views-fest/stories/Stories';
import FESTStory from '@/views-fest/stories/Story';
import FESTNewStory from '@/views-fest/stories/NewStory';
import FESTWinnersStories from '@/views-fest/stories/WinnersStories';

import FESTSuccessForm from '@/views-fest/forms/success';

import FESTPage from '@/views-fest/pages/Page';

import FESTTransport from '@/views-fest/transport/Transport';

import FESTVote from '@/views-fest/vote/Vote';
import FESTVoteDetail from '@/views-fest/vote/VoteDetail';
import FESTVoteResult from '@/views-fest/vote/VoteResults';

//============ EXTENSIONS ==============================//
//======================================================//
import extAuthorizedMode from 'ext-authorized-mode-routes';

//============ OPTIONS =================================//
//======================================================//
Vue.use(VueRouter);

//============ ROUTES ==================================//
//======================================================//

let routesExtend = [
  ...extAuthorizedMode
];

// FIXME: CSOBZ-379 - split routes into multiple smaller modules
const routes = [
  {
    path: '/',
    redirect: {
      name: 'dashboard'
    }
  },

  {
    path: '/login',
    name: 'login',
    component: PageDefault,
    props: {
      component: Login,
      modules: ['login']
    },
    meta: {
      caption: 'ČSOB Zapka',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom'
    }
  },

  {
    path: '/verify',
    name: 'verify-code',
    component: PageDefault,
    props: {
      component: VerifyCode,
      modules: ['login']
    },
    meta: {
      caption: 'ČSOB Zapka',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom'
    }
  },

  {
    path: '/registration',
    name: 'registration',
    component: PageDefault,
    props: {
      component: Registration,
      modules: ['login']
    },
    meta: {
      caption: 'ČSOB Zapka',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom'
    }
  },

  {
    path: '/registration-parents',
    name: 'registration-parents',
    component: PageDefault,
    props: {
      component: RegistrationParents,
      modules: ['login']
    },
    meta: {
      caption: 'ČSOB Zapka',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom'
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: PageDefault,
    props: {
      component: Dashboard,
      modules: ['dashboard']
    },
    meta: {
      caption: 'ČSOB Zapka',
      navbarBuilding: true,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
      allowedBackground: true,
      desktopLayoutFull: true
    }
  },

  {
    path: '/news-list',
    name: 'news-list',
    component: PageDefault,
    props: {
      component: NewsList,
      modules:  ['articles', 'articles-list', 'news', 'news-list'],
      modulePage: 'list',
    },
    meta: {
      // FIXME: JNO - name strings must use i18n values due the translation ability
      caption: 'ČSOB Zapka',
      navbarCaption: 'Novinky',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
      desktopLayoutFull: false

      // EXAMPLE of metaTags written inside head of document
      // metaTags: [
      //   {
      //     name: 'description',
      //     content: 'Zaměstnanecká aplikace...'
      //   },
      //   {
      //     property: 'og:description',
      //     content: 'Zaměstnanecká aplikace...'
      //   }
      // ]
    }
  },

  {
    path: '/news/:id',
    name: 'news',
    component: PageDefault,
    props: {
      component: NewsDetail,
      modules:  ['articles', 'articles-detail', 'news', 'news-detail'],
    },
    meta: {
      title: 'news-detail',
      caption: 'ČSOB Zapka',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/news/comments/:id',
    name: 'news-comments',
    component: PageDefault,
    props: {
      component: NewsDetailComments,
      modules:  ['articles', 'news', 'news-detail-comments'],
    },
    meta: {
      caption: 'ČSOB Zapka',
      navbarCaption: 'Komentáře',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
    }
  },

  {
    path: '/news/comment-add/:id',
    name: 'news-comment-add',
    component: PageDefault,
    props: {
      component: NewsDetailCommentAdd,
      modules:  ['articles', 'news', 'news-detail-comment-add'],
    },
    meta: {
      caption: 'ČSOB Zapka',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
    }
  },

  {
    path: '/restaurants',
    name: 'restaurants',
    component: PageDefault,
    props: {
      component: RestaurantsMenu,
      modules: ['restaurants-menu']
    },
    meta: {
      caption: 'Jídelna',
      navbarCaption: 'Jídelna',
      navbarBuilding: true,
      navbarButton: 'back',
      navbarButtonMode: 'back',
      navbarButtonDestination: 'dashboard',
      desktopLayoutFull: false
    }
  },

  {
    path: '/restaurants/detail/:id',
    name: 'restaurants-detail',
    component: PageDefault,
    props: {
      component: RestaurantsDetail,
      modules: ['restaurants-detail']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'restaurants',
    }
  },

  {
    path: '/restaurants/info',
    name: 'restaurants-info',
    component: PageDefault,
    props: {
      component: RestaurantsInfo,
      modules: ['restaurants-menu']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/restaurants/pictograms',
    name: 'restaurants-pictograms',
    component: PageDefault,
    props: {
      component: RestaurantsPictograms,
      modules: ['restaurants-menu']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/search',
    alias: '/search/:mode',
    name: 'search',
    component: PageDefault,
    props: {
      component: RoomSearch,
      modules: ['where-to-find']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
      navbarButtonDestination: 'dashboard',
      desktopLayoutFull: true
    }
  },

  {
    path: '/fault-reporting',
    name: 'fault-reporting',
    component: PageDefault,
    props: {
      component: FaultReporting,
      modules: ['fault-reporting', 'form'],
    },
    meta: {
      caption: 'Hlášení závad',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'dashboard',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/events',
    name: 'events',
    component: PageDefault,
    props: {
      component: Events,
      modules: ['events', 'events-list'],
    },
    meta: {
      caption: 'Akce',
      navbarCaption: 'Akce',
      navbarBuilding: false,
      navbarButton: 'dashboard',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
      desktopLayoutFull: false
    }
  },
  {
    path: '/events/:id',
    name: 'event-detail',
    component: PageDefault,
    props: {
      component: EventDetail,
      modules: ['events', 'events-detail']
    },
    meta: {
      caption: 'Detail akce',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom'
    }
  },
  {
    path: '/recruitment',
    name: 'recruitment-dashboard',
    component: PageDefault,
    props: {
      component: RecruitmentDashboard,
      modules: ['recruitment', 'recruitment-dashboard']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/recruitment/search',
    name: 'recruitment-search',
    component: PageDefault,
    props: {
      component: RecruitmentSearch,
      modules: ['recruitment', 'recruitment-search', 'form']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButtonMode: 'path',
      navbarButtonDestination: 'recruitment'
    }
  },
  {
    path: '/recruitment/listing',
    name: 'recruitment-listing',
    component: PageDefault,
    props: {
      component: RecruitmentListing,
      modules: ['recruitment', 'recruitment-listing']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButtonMode: 'path',
      navbarButtonDestination: 'recruitment/search',
      desktopLayoutFull: false
    }
  },
  {
    path: '/recruitment/listing/form',
    name: 'recruitment-listing-form',
    component: PageDefault,
    props: {
      component: RecruitmentListingForm,
      modules: ['recruitment', 'recruitment-listing-form', 'form']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButtonMode: 'history',
      navbarButtonDestination: -1 // TODO MBU: on success keep -1, or redirect to recruitment / dashboard
    }
  },
  {
    path: '/recruitment/detail/:id',
    name: 'recruitment-detail',
    component: PageDefault,
    props: {
      component: RecruitmentDetail,
      modules: ['recruitment', 'recruitment-detail']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },
  {
    path: '/recruitment/form',
    name: 'recruitment-detail-form',
    component: PageDefault,
    props: {
      component: RecruitmentFormSF,
      modules: ['recruitment', 'recruitment-detail-form','form']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButtonMode: 'history',
      navbarButtonDestination: -1 // TODO MBU: on success keep -1, or redirect to recruitment / dashboard
    }
  },
  {
    path: '/recruitment/detail/form-refer',
    name: 'recruitment-detail-form-refer',
    component: PageDefault,
    props: {
      component: RecruitmentDetailFormCandidate,
      modules: ['recruitment', 'recruitment-detail-form-candidate','form']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButtonMode: 'history',
      navbarButtonDestination: -1 // TODO MBU: on success keep -1, or redirect to recruitment / dashboard
    }
  },
  {
    path: '/forvardino/list/:categoryId?',
    name: 'forvardino-list',
    component: PageDefault,
    props: {
      component: ForvardinoList,
      modules: ['forvardino', 'forvardino-list']
    },
    meta: {
      caption: 'Forvardino',
      navbarCaption: 'Forvardino',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
      desktopLayoutFull: false

    }
  },
  {
    path: '/forvardino/detail/:id',
    name: 'forvardino-detail',
    component: PageDefault,
    props: {
      component: FovardinoDetail,
      modules: ['forvardino', 'forvardino-detail']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
    }
  },
  {
    path: '/forvardino/lector/:id',
    name: 'forvardino-lector',
    component: PageDefault,
    props: {
      component: ForvardinoLector,
      modules: ['forvardino', 'forvardino-lector']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
    }
  },
  {
    path: '/forvardino/course-rating/:id',
    name: 'forvardino-course-rating',
    component: PageDefault,
    props: {
      component: ForvardinoCourseRating,
      modules: ['forvardino-course-rating']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
    }
  },
  {
    path: '/forvardino/info',
    name: 'forvardino-info',
    component: PageDefault,
    props: {
      component: ForvardinoInfo,
      modules: ['forvardino-info']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'forvardino/list'
    }
  },
  {
    path: '/forvardino/info/:id/:name',
    name: 'forvardino-info-page',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['forvardino-info']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'none',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'forvardino/info',
    }
  },


  {
    path: '/forvardino/course/manager',
    name: 'forvardino-course-manager',
    component: PageDefault,
    props: {
      component: ForvardinoCourseManager,
      modules: ['forvardino', 'forvardino-manager']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
    }
  },


  {
    path: '/workouts',
    name: 'workouts',
    component: PageDefault,
    props: {
      component: Workouts,
      modules: ['workouts', 'workouts-list']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
      desktopLayoutFull: false
    }
  },
  {
    path: '/workouts/:id',
    name: 'workout-detail',
    component: PageDefault,
    props: {
      component: WorkoutDetail,
      modules: ['workouts', 'workouts-detail']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
    }
  },
  {
    path: '/user-settings/',
    name: 'user-settings',
    component: PageDefault,
    props: {
      component: UserSettings,
      modules: ['user-settings']
    },
    meta: {
      caption: 'Nastavení',
      navbarCaption: 'Nastavení',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/messages/',
    name: 'messages',
    component: PageDefault,
    props: {
      component: Messages,
      modules: ['messages']
    },
    meta: {
      caption: 'Zprávy',
      navbarCaption: 'Zprávy',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom'
    }
  },
  {
    path: '/strategy/',
    name: 'strategy',
    component: PageDefault,
    props: {
      component: Strategy,
      modules: ['strategy']
    },
    meta: {
      navbarCaption: 'Strategie skupiny ČSOB',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/strategy2022/:nav?',
    name: 'strategy2022',
    component: PageDefault,
    props: {
      component: Strategy2022,
      modules: ['strategy2022', 'strategy-main-objectives']
    },
    meta: {
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/strategy/detail/:id/:lang?',
    name: 'strategy-detail',
    component: PageDefault,
    props: {
      component: StrategyDetail,
      modules: ['strategy2022', 'strategy-detail']
    },
    meta: {
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'starategy'
    }
  },
  {
    path: '/strategy/competition/intro',
    name: 'strategy-competition-intro',
    component: PageDefault,
    props: {
      component: StrategyCompetitionIntro,
      modules: ['strategy2022', 'strategy-competition-intro']
    },
    meta: {
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/strategy/competition/registration',
    name: 'strategy-competition-registration',
    component: PageDefault,
    props: {
      component: StrategyCompetitionRegistration,
      modules: ['strategy2022', 'strategy-competition-registration']
    },
    meta: {
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/strategy/competition',
    name: 'strategy-competition',
    component: PageDefault,
    props: {
      component: StrategyCompetition,
      modules: ['strategy2022', 'strategy-competition']
    },
    meta: {
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/strategy/competition/summary',
    name: 'strategy-competition-summary',
    component: PageDefault,
    props: {
      component: StrategyCompetitionSummary,
      modules: ['strategy2022', 'strategy-summary', 'strategy-competition']
    },
    meta: {
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/strategy/main-objectives',
    name: 'strategy-main-objectives',
    component: PageDefault,
    props: {
      component: StrategyMainObjectives,
      modules: ['strategy2022', 'strategy-detail', 'strategy-main-objectives']
    },
    meta: {
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/nps',
    name: 'nps',
    component: PageDefault,
    props: {
      component: NPSDashboard,
      modules:  ['nps']
    },
    meta: {
      caption: 'NPS (zpětná vazba)',
      navbarCaption: 'NPS (zpětná vazba)',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/nps/:year/:id',
    name: 'nps-detail',
    component: PageDefault,
    props: {
      component: NPSDetail,
      modules:  ['nps', 'nps-detail']
    },
    meta: {
      caption: 'NPS (zpětná vazba)',
      navbarCaption: 'NPS (zpětná vazba)',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'nps',
    }
  },
  {
    path: '/nps/info',
    name: 'nps-info',
    component: PageDefault,
    props: {
      component: NPSInfo,
      modules: ['nps', 'nps-info']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'nps'
    }
  },
  {
    path: '/pages/:id',
    name: 'pages',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['pages']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/covid-19',
    name: 'covid-19',
    component: PageDefault,
    props: {
      component: Covid19Dashboard,
      modules:  ['covid-19']
    },
    meta: {
      caption: 'Covid 19',
      navbarCaption: 'Covid 19',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/covid-19/form',
    name: 'covid-19-form',
    component: PageDefault,
    props: {
      component: Covid19FormInfo,
      modules:  ['covid-19', 'covid-19-form']
    },
    meta: {
      caption: 'Jdu do práce',
      navbarCaption: 'Jdu do práce',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/covid-19/faq/:id',
    name: 'covid-19-faq',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['covid-19']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'none',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'covid-19',
    }
  },
  {
    path: '/trio',
    name: 'trio',
    component: PageDefault,
    props: {
      component: TrioDashboard,
      modules:  ['trio']
    },
    meta: {
      caption: 'Videokonference s Triem',
      navbarCaption: 'Videokonference s Triem',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/trio/:id/:name',
    name: 'trio-page',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['trio']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'none',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'trio',
    }
  },
  {
    path: '/survey/intro',
    name: 'survey-intro',
    component: PageDefault,
    props: {
      component: SurveyIntro,
      modules: ['survey', 'survey-intro']
    },
    meta: {
      caption: 'Vítejte v průzkumu',
      navbarBuilding: false,
      navbarButton: 'dashboard',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/survey/form',
    name: 'survey-form',
    component: PageDefault,
    props: {
      component: SurveyForm,
      modules: ['survey', 'survey-form']
    },
    meta: {
      caption: 'ČSOB průzkum',
      navbarBuilding: false,
      navbarButton: 'dashboard',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/survey/thank-you',
    name: 'survey-thank-you',
    component: PageDefault,
    props: {
      component: SurveyThankYou,
      modules: ['survey', 'survey-thank-you']
    },
    meta: {
      caption: 'Děkujeme za účast v průzkumu',
      navbarBuilding: false,
      navbarButton: 'dashboard',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/advent',
    name: 'advent',
    component: PageDefault,
    props: {
      component: AdventDashboard,
      modules:  ['advent', 'advent-dashboard']
    },
    meta: {
      caption: 'Adventní kalendář',
      navbarCaption: 'Adventní kalendář',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/advent/:id',
    name: 'advent-detail',
    component: PageDefault,
    props: {
      component: AdventDetail,
      modules:  ['advent', 'advent-detail']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'advent'
    }
  },
  {
    path: '/help',
    name: 'help',
    component: PageDefault,
    props: {
      component: HelpPage,
      modules:  ['help']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  },
  {
    path: '/help/:id',
    name: 'help-article',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['help']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'none',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'help',
    }
  },
  {
    path: '/annual-innovation-report/',
    name: 'annual-innovation-report',
    component: PageDefault,
    props: {
      component: AnnualInnovationReport,
      modules:  ['annual-innovation-report']
    },
    meta: {
      caption: '',
      navbarCaption: 'Inovace 2022',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/annual-innovation-report/report/:alt/:nav?',
    name: 'annual-innovation-report-document',
    component: PageDefault,
    props: {
      component: IframeViewer,
      modules: ['iframe-viewer']
    },
    meta: {
      caption: 'Innovation Annual Report',
      iframePrefix: 'INNOVATION_REPORT',
      iframeDefaultPageId: '1',
      iframeBackToPage: true,
      iframeLoader: true,
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'annual-innovation-report'
    }
  },
  {
    path: '/annual-innovation-report/kate/:alt/:nav?',
    name: 'annual-innovation-report-kate',
    component: PageDefault,
    props: {
      component: IframeViewer,
      modules: ['iframe-viewer']
    },
    meta: {
      caption: 'Kate',
      iframePrefix: 'INNOVATION_REPORT_KATE',
      iframeDefaultPageId: '',
      iframeBackToPage: true,
      iframeLoader: false,
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'annual-innovation-report'
    }
  },
  {
    path: '/dock-dell',
    name: 'dock-dell',
    component: PageDefault,
    props: {
      component: DockDellDashboard,
      modules:  ['dock-dell']
    },
    meta: {
      caption: 'Univerzální dokovací stanice DELL',
      navbarCaption: 'Univerzální dokovací stanice DELL',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/dock-dell/:id/:name',
    name: 'dock-dell-detail',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['dock-dell', 'dock-dell-detail']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'none',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dock-dell',
    }
  },

  {
    path: '/benefits/:categoryId?',
    name: 'benefits',
    component: PageDefault,
    props: {
      component: BenefitsList,
      modules:  ['benefits']
    },
    meta: {
      caption: '',
      navbarCaption: 'Slevy pro vás',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
      desktopLayoutFull: false
    }
  },

  {
    path: '/benefits-detail/:id',
    name: 'benefits-detail',
    component: PageDefault,
    props: {
      component: BenefitsDetail,
      modules:  ['benefits-detail']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
      navbarButtonDestination: 'benefits',
    }
  },

  {
    path: '/hhq/dashboard',
    name: 'hhq-building',
    component: PageDefault,
    props: {
      component: BuildingHHQDashboard,
      modules:  ['building-hhq']
    },
    meta: {
      caption: '',
      navbarCaption: 'Vítejte v HHQ',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/hhq/start',
    name: 'hhq-building-start',
    component: PageDefault,
    props: {
      component: BuildingHHQStart,
      modules:  ['building-hhq']
    },
    meta: {
      caption: '',
      navbarCaption: 'Vítejte v HHQ',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/hhq/info/:id',
    name: 'building-info-page',
    component: PageDefault,
    props: {
      component: BuildingHHQInfo,
      modules:  ['building-hhq']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'none',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'hhq/dashboard',
    }
  },

  {
    path: '/hhq/:categoryId-:categoryType',
    name: 'building-category',
    component: PageDefault,
    props: {
      component: BuildingHHQList,
      modules: ['building-hhq', 'building-hhq-category']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'hhq/dashboard'
    }
  },

  {
    path: '/hhq/:categoryId-:categoryType/:id',
    name: 'building-page',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['building-hhq']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'none',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/hhq/intro/:id',
    name: 'building-intro-page',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['building-hhq']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'none',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'hhq/dashboard',
    }
  },

  {
    path: '/parents',
    name: 'parents-dashboard',
    component: PageDefault,
    props: {
      component: ParentsDashboard,
      modules:  ['parents']
    },
    meta: {
      caption: '',
      navbarCaption: 'Rodiče ve skupině ČSOB',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/parents/:categoryId/faq',
    name: 'parents-faq-page',
    component: PageDefault,
    props: {
      component: ParentsFaq,
      modules:  ['parents', 'parents-page', 'parents-page-faq']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'parents',
    }
  },

  {
    path: '/parents/:categoryId-:categoryType',
    name: 'parents-category',
    component: PageDefault,
    props: {
      component: ParentsList,
      modules: ['parents', 'parents-category']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'parents'
    }
  },

  {
    path: '/parents/:categoryId/:id-:slug',
    name: 'parents-page',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['parents', 'parents-page']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/parents/:id-:categoryType',
    name: 'parents-info-page',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['parents', 'parents-page']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'parents',
    }
  },

  {
    path: '/parents/parents-program/form',
    name: 'parents-program-registration',
    component: PageDefault,
    props: {
      component: ParentsProgramRegistration,
      modules:  ['parents', 'parents-form', 'form']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
      navbarButtonDestination: 'parents',
    }
  },

  {
    path: '/parents/parents-pool/form',
    name: 'parents-pool-login',
    component: PageDefault,
    props: {
      component: ParentsPoolLogin,
      modules:  ['parents', 'parents-form', 'form']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
      navbarButtonDestination: 'parents',
    }
  },

  {
    path: '/parents/parents-babysitting/form-warning',
    name: 'parents-babysitting-form-warning',
    component: PageDefault,
    props: {
      component: ParentsBabysittingFormWarning,
      modules:  ['parents', 'parents-form', 'form']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
      navbarButtonDestination: 'parents',
    }
  },

  {
    path: '/marketplace',
    name: 'marketplace',
    component: PageDefault,
    props: {
      component: MarketplaceDashboard,
      modules:  ['marketplace', 'marketplace-dashboard']
    },
    meta: {
      caption: 'Bazar',
      navbarCaption: 'Bazar',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/marketplace/info',
    name: 'marketplace-info',
    component: PageDefault,
    props: {
      component: MarketplaceInfo,
      modules:  ['marketplace', 'marketplace-info']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'marketplace',
    }
  },

  {
    path: '/marketplace/rules',
    name: 'marketplace-rules',
    component: PageDefault,
    props: {
      component: MarketplaceRules,
      modules:  ['marketplace', 'marketplace-rules']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/marketplace/form',
    name: 'marketplace-form',
    component: PageDefault,
    props: {
      component: MarketplaceForm,
      modules:  ['marketplace', 'marketplace-form']
    },
    meta: {
      caption: 'Přidat inzerát',
      navbarCaption: 'Přidat inzerát',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
    }
  },

  {
    path: '/marketplace/list',
    name: 'marketplace-list',
    component: PageDefault,
    props: {
      component: MarketplaceList,
      modules:  ['marketplace', 'marketplace-list']
    },
    meta: {
      caption: 'Inzeráty',
      navbarCaption: 'Inzeráty',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'marketplace',
      listMode: 'all'
    }
  },

  {
    path: '/marketplace/list/saved',
    name: 'marketplace-list-saved',
    component: PageDefault,
    props: {
      component: MarketplaceList,
      modules:  ['marketplace', 'marketplace-list', 'marketplace-list-saved']
    },
    meta: {
      caption: 'Uložené inzeráty',
      navbarCaption: 'Uložené inzeráty',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'marketplace',
      listMode: 'saved'
    }
  },

  {
    path: '/marketplace/list/my',
    name: 'marketplace-list-my',
    component: PageDefault,
    props: {
      component: MarketplaceList,
      modules:  ['marketplace', 'marketplace-list', 'marketplace-list-my']
    },
    meta: {
      caption: 'Prodávám',
      navbarCaption: 'Prodávám',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'marketplace',
      listMode: 'my'
    }
  },

  {
    path: '/marketplace/detail/:id',
    name: 'marketplace-detail',
    component: PageDefault,
    props: {
      component: MarketplaceDetail,
      modules:  ['marketplace', 'marketplace-detail']
    },
    meta: {
      caption: 'Bazar',
      navbarCaption: 'Bazar',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
    }
  },

  {
    path: '/knowledge-base',
    name: 'knowledge-base-dashboard',
    component: PageDefault,
    props: {
      component: KnowledgeBaseDashboard,
      modules:  ['knowledge-base']
    },
    meta: {
      caption: '',
      navbarCaption: 'Návody',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/knowledge-base/:categoryId',
    name: 'knowledge-base-category',
    component: PageDefault,
    props: {
      component: KnowledgeBaseCategory,
      modules: ['knowledge-base', 'knowledge-base-category']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'knowledge-base'
    }
  },

  {
    path: '/knowledge-base/:categoryId/:pageId',
    name: 'knowledge-base-detail',
    component: PageDefault,
    props: {
      component: KnowledgeBaseDetail,
      modules:  ['knowledge-base', 'knowledge-base-detail']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/surf-studio',
    name: 'surf-studio-dashboard',
    component: PageDefault,
    props: {
      component: SurfStudioDashboard,
      modules:  ['surf-studio']
    },
    meta: {
      caption: '',
      navbarCaption: 'Surf Studio',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/surf-studio/news',
    name: 'surf-studio-news',
    component: PageDefault,
    props: {
      component: SurfStudioNews,
      modules:  ['surf-studio']
    },
    meta: {
      caption: '',
      navbarCaption: 'Novinky',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'surf-studio',
    }
  },

  {
    path: '/surf-studio/voice',
    name: 'surf-studio-voice',
    component: PageDefault,
    props: {
      component: SurfStudioVoice,
      modules: ['surf-studio', 'surf-studio-voice']
    },
    meta: {
      caption: '',
      navbarCaption: 'Surf Voice',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'surf-studio',
    },
  },

  {
    path: '/surf-studio/voice/:id',
    name: 'surf-studio-voice-page',
    component: PageDefault,
    props: {
      component: SurfStudioVoiceContent,
      modules: ['surf-studio']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'surf-studio/voice',
    },
  },

  {
    path: '/surf-studio/events',
    name: 'surf-studio-events',
    component: PageDefault,
    props: {
      component: SurfStudioEvents,
      modules:  ['surf-studio']
    },
    meta: {
      caption: '',
      navbarCaption: 'Akce',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'surf-studio',
    }
  },

  {
    path: '/surf-studio/events/:id',
    name: 'surf-studio-events-info',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['surf-studio']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'surf-studio/events',
    }
  },

  {
    path: '/surf-studio/creative-power-menu',
    name: 'surf-studio-creative-power-menu',
    component: PageDefault,
    props: {
      component: SurfStudioCreativePowerMenu,
      modules:  ['surf-studio', 'surf-studio-creative-menu']
    },
    meta: {
      caption: '',
      navbarCaption: 'Creative Power Menu',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'surf-studio',
    }
  },

  {
    path: '/surf-studio/creative-power-menu/:id',
    name: 'surf-studio-creative-power-menu-info',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['surf-studio']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'surf-studio/creative-power-menu',
    }
  },

  {
    path: '/surf-studio/trendbook',
    name: 'surf-studio-trendbook',
    component: PageDefault,
    props: {
      component: SurfStudioTrendBook,
      modules:  ['surf-studio', 'surf-studio-trend-book']
    },
    meta: {
      caption: '',
      navbarCaption: 'Trendbook',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'surf-studio',
    }
  },

  {
    path: '/surf-studio/surf-kemp',
    name: 'surf-studio-surf-kemp',
    component: PageDefault,
    props: {
      component: SurfStudioSurfKemp,
      modules:  ['surf-studio', 'surf-studio-surf-kemp', 'week-actions']
    },
    meta: {
      caption: '',
      navbarCaption: 'Surf Kemp',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'surf-studio',
    }
  },

  {
    path: '/surf-studio/surf-kemp/:id',
    name: 'surf-studio-surf-kemp-info',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['surf-studio']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'surf-studio/surf-kemp',
    }
  },

  // ESG
  {
    path: '/sustainability',
    name: 'sustainability',
    component: PageDefault,
    props: {
      component: ESGDashboard,
      modules:  ['sustainability', 'sustainability-dashboard']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/sustainability/about',
    name: 'sustainability-about',
    component: PageDefault,
    props: {
      component: ESGAbout,
      modules:  ['sustainability', 'sustainability-about']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/sustainability/themes',
    name: 'sustainability-themes',
    component: PageDefault,
    props: {
      component: ESGThemes,
      modules:  ['sustainability', 'sustainability-themes']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability',
    }
  },

  {
    path: '/sustainability/themes/:id',
    name: 'sustainability-theme-detail',
    component: PageDefault,
    props: {
      component: ESGThemeDetail,
      modules:  ['sustainability', 'sustainability-theme-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },
  {
    path: '/sustainability/topics/:id',
    name: 'sustainability-topic-detail',
    component: PageDefault,
    props: {
      component: ESGTopicDetail,
      modules:  ['sustainability', 'sustainability-topic-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/sustainability/targets/:id',
    name: 'sustainability-targets',
    component: PageDefault,
    props: {
      component: ESGTargets,
      modules:  ['sustainability', 'sustainability-targets']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability/news',
    }
  },

  {
    path: '/sustainability/challenges',
    name: 'sustainability-challenges',
    component: PageDefault,
    props: {
      component: ESGChallenges,
      modules:  ['sustainability', 'sustainability-challenges']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability',
    }
  },

  {
    path: '/sustainability/challenges/:challengeId',
    name: 'sustainability-challenge-detail',
    component: PageDefault,
    props: {
      component: ESGChallengeDetail,
      modules:  ['sustainability', 'sustainability-challenge-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability/challenges',
    }
  },

  {
    path: '/sustainability/challenges/success/:challengeId',
    name: 'sustainability-challenge-success',
    component: PageDefault,
    props: {
      component: ESGSuccessForm,
      modules:  ['sustainability', 'sustainability-challenge-success']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability/challenges',
      content: i18n.t('esg.challenges.forms.successDescription'),
      primaryButton: i18n.t('esg.challenges.buttonCompetitive'),
      primaryButtonRoute: 'sustainability-competition-detail',
      secondaryButton: i18n.t('esg.challenges.buttonNext'),
      secondaryButtonRoute: 'sustainability-challenge-detail'
    },
  },

  {
    path: '/sustainability/challenges/history/:challengeId',
    name: 'sustainability-challenge-history',
    component: PageDefault,
    props: {
      component: ESGChallengeHistory,
      modules:  ['sustainability', 'sustainability-challenge-history']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability/challenges',
    }
  },

  {
    path: '/sustainability/challenges/results/:challengeId',
    name: 'sustainability-challenge-results',
    component: PageDefault,
    props: {
      component: ESGChallengeResults,
      modules:  ['sustainability', 'sustainability-challenge-results']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/sustainability/news',
    name: 'sustainability-news',
    component: PageDefault,
    props: {
      component: ESGNewsList,
      modules:  ['sustainability', 'sustainability-news']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability',
    }
  },

  {
    path: '/sustainability/news/:id',
    name: 'sustainability-news-detail',
    component: PageDefault,
    props: {
      component: ESGNewsDetail,
      modules:  ['sustainability', 'sustainability-news-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability/news',
    }
  },

  {
    path: '/sustainability/stories',
    name: 'sustainability-stories',
    component: PageDefault,
    props: {
      component: ESGStories,
      modules:  ['sustainability', 'sustainability-stories']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability',
    }
  },

  {
    path: '/sustainability/stories/:id',
    name: 'sustainability-story',
    component: PageDefault,
    props: {
      component: ESGStory,
      modules:  ['sustainability', 'sustainability-story']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability/news',
    }
  },

  {
    path: '/sustainability/new-story',
    name: 'sustainability-new-story',
    component: PageDefault,
    props: {
      component: ESGNewStory,
      modules:  ['sustainability', 'sustainability-new-story']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability/stories',
    }
  },

  {
    path: '/sustainability/new-story/success',
    name: 'sustainability-new-story-success',
    component: PageDefault,
    props: {
      component: ESGSuccessForm,
      modules:  ['sustainability', 'sustainability-new-story-success']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability/stories',
      content: i18n.t('esg.stories.forms.successDescription'),
      primaryButtonRoute: 'sustainability-stories'
    },
  },

  {
    path: '/sustainability/winners-stories',
    name: 'sustainability-winners-stories',
    component: PageDefault,
    props: {
      component: ESGWinnersStories,
      modules:  ['sustainability', 'sustainability-winners-stories']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability/stories',
    },
  },

  {
    path: '/sustainability/pages/:id',
    name: 'sustainability-page',
    component: PageDefault,
    props: {
      component: ESGPage,
      modules:  ['sustainability', 'sustainability-page']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/sustainability/competitions',
    name: 'sustainability-competitions',
    component: PageDefault,
    props: {
      component: ESGCompetitions,
      modules:  ['sustainability', 'sustainability-competitions']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability',
    }
  },

  {
    path: '/sustainability/competitions/:competitionId',
    name: 'sustainability-competition-detail',
    component: PageDefault,
    props: {
      component: ESGCompetitionDetail,
      modules:  ['sustainability', 'sustainability-competition-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'sustainability/competitions',
    }
  },

  {
    path: '/sustainability/competition/result/:competitionId',
    name: 'sustainability-competition-result',
    component: PageDefault,
    props: {
      component: ESGCompetitionResult,
      modules:  ['sustainability', 'sustainability-competition-result']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/ideas-sending',
    name: 'ideas-sending',
    component: PageDefault,
    props: {
      component: IdeasSending,
      modules: ['ideas-sending', 'form'],
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'dashboard',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/services',
    name: 'services-list',
    component: PageDefault,
    props: {
      component: ServicesList,
      modules:  ['services', 'services-list'],
      modulePage: 'list',
    },
    meta: {
      caption: 'ČSOB Zapka',
      navbarCaption: 'Služby',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
      desktopLayoutFull: false
    }
  },

  {
    path: '/services/:id',
    name: 'services-detail',
    component: PageDefault,
    props: {
      component: ServicesDetail,
      modules:  ['services', 'services-detail'],
    },
    meta: {
      caption: 'ČSOB Zapka',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/service/:id',
    name: 'services',
    component: PageDefault,
    props: {
      component: ServiceDetail,
      modules:  ['articles', 'articles-detail', 'services', 'services-detail'],
    },
    meta: {
      caption: 'ČSOB Zapka',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'backToNavigation',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/emergency',
    name: 'emergency-dashboard',
    component: PageDefault,
    props: {
      component: EmergencyDashboard,
      modules:  ['emergency']
    },
    meta: {
      caption: '',
      navbarCaption: 'Krizová komunikace',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/emergency/contacts',
    name: 'emergency-contacts',
    component: PageDefault,
    props: {
      component: EmergencyContacts,
      modules:  ['emergency', 'emergency-contacts']
    },
    meta: {
      caption: '',
      navbarCaption: 'Důležitá tel. čísla',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'emergency',
    }
  },

  {
    path: '/emergency/information',
    name: 'emergency-information',
    component: PageDefault,
    props: {
      component: EmergencyGeneralInformation,
      modules:  ['emergency', 'emergency-information']
    },
    meta: {
      caption: '',
      navbarCaption: 'Obecné informace',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'emergency',
    }
  },

  {
    path: '/emergency/detail/:id/:slug',
    name: 'emergency-page-detail',
    component: PageDefault,
    props: {
      component: EmergencyPageDetail,
      modules:  ['emergency', 'emergency-page-detail']
    },
    meta: {
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/emergency/plans',
    name: 'emergency-building-plans',
    component: PageDefault,
    props: {
      component: EmergencyBuildingPlans,
      modules:  ['emergency', 'emergency-building-plans']
    },
    meta: {
      caption: '',
      navbarCaption: 'Evakuační plány',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/emergency/plans/:id',
    name: 'emergency-building-plan-detail',
    component: PageDefault,
    props: {
      component: EmergencyBuildingPlanDetail,
      modules:  ['emergency', 'emergency-building-plan-detail']
    },
    meta: {
      caption: '',
      navbarCaption: 'Evakuační plány',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/emergency/crisis-situation',
    name: 'emergency-crisis-situation',
    component: PageDefault,
    props: {
      component: EmergencyCrisisSituation,
      modules:  ['emergency', 'emergency-crisis-situation']
    },
    meta: {
      caption: '',
      navbarCaption: 'Krizové situace',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'emergency',
    }
  },

  {
    path: '/emergency/crisis-situation/detail/:id/:slug',
    name: 'emergency-crisis-situation-detail',
    component: PageDefault,
    props: {
      component: EmergencyCrisisSituationDetail,
      modules:  ['emergency', 'emergency-crisis-situation']
    },
    meta: {
      caption: '',
      navbarCaption: 'Krizové situace',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/profile',
    name: 'profile',
    component: PageDefault,
    props: {
      component: Profile,
      modules:  ['profile']
    },
    meta: {
      caption: '',
      navbarCaption: 'Profil',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/profile-edit-name',
    name: 'profile-edit-name',
    component: PageDefault,
    props: {
      component: ProfileEditName,
      modules:  ['profile', 'profile-edit-name']
    },
    meta: {
      caption: '',
      navbarCaption: 'Profil',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'profile',
    }
  },

  {
    path: '/profile-edit-phone',
    name: 'profile-edit-phone',
    component: PageDefault,
    props: {
      component: ProfileEditPhone,
      modules:  ['profile', 'profile-edit-phone']
    },
    meta: {
      caption: '',
      navbarCaption: 'Profil',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'profile',
    }
  },

  {
    path: '/profile-cancel-account',
    name: 'profile-cancel-account',
    component: PageDefault,
    props: {
      component: ProfileCancelAccount,
      modules:  ['profile', 'profile-cancel-account']
    },
    meta: {
      caption: '',
      navbarCaption: 'Profil',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'profile',
    }
  },

  {
    path: '/promoted',
    name: 'promoted-list',
    component: PageDefault,
    props: {
      component: Promoted,
      modules:  ['promoted']
    },
    meta: {
      caption: '',
      navbarCaption: 'Nepřehlédněte',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  {
    path: '/coming',
    name: 'coming-list',
    component: PageDefault,
    props: {
      component: ComingActions,
      modules:  ['coming']
    },
    meta: {
      caption: '',
      navbarCaption: 'Co nás čeká',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1,
    }
  },

  // FEST
  {
    path: '/festival',
    name: 'festival',
    component: PageDefault,
    props: {
      component: FESTDashboard,
      modules:  ['festival', 'festival-dashboard']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/festival/about',
    name: 'festival-about',
    component: PageDefault,
    props: {
      component: FESTAbout,
      modules:  ['festival', 'festival-about']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/festival/themes',
    name: 'festival-themes',
    component: PageDefault,
    props: {
      component: FESTThemes,
      modules:  ['festival', 'festival-themes']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival',
    }
  },

  {
    path: '/festival/themes/:id',
    name: 'festival-theme-detail',
    component: PageDefault,
    props: {
      component: FESTThemeDetail,
      modules:  ['festival', 'festival-theme-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },
  {
    path: '/festival/topics/:id',
    name: 'festival-topic-detail',
    component: PageDefault,
    props: {
      component: FESTTopicDetail,
      modules:  ['festival', 'festival-topic-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/festival/targets/:id',
    name: 'festival-targets',
    component: PageDefault,
    props: {
      component: FESTTargets,
      modules:  ['festival', 'festival-targets']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/news',
    }
  },

  {
    path: '/festival/challenges',
    name: 'festival-challenges',
    component: PageDefault,
    props: {
      component: FESTChallenges,
      modules:  ['festival', 'festival-challenges']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival',
    }
  },

  {
    path: '/festival/challenges/:challengeId',
    name: 'festival-challenge-detail',
    component: PageDefault,
    props: {
      component: FESTChallengeDetail,
      modules:  ['festival', 'festival-challenge-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/challenges',
    }
  },

  {
    path: '/festival/challenges/success/:challengeId',
    name: 'festival-challenge-success',
    component: PageDefault,
    props: {
      component: FESTSuccessForm,
      modules:  ['festival', 'festival-challenge-success']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/challenges',
      content: i18n.t('fest.challenges.forms.successDescription'),
      primaryButton: i18n.t('fest.challenges.buttonCompetitive'),
      primaryButtonRoute: 'festival-competition-detail',
      secondaryButton: i18n.t('fest.challenges.buttonNext'),
      secondaryButtonRoute: 'festival-challenge-detail'
    },
  },

  {
    path: '/festival/challenges/history/:challengeId',
    name: 'festival-challenge-history',
    component: PageDefault,
    props: {
      component: FESTChallengeHistory,
      modules:  ['festival', 'festival-challenge-history']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/challenges',
    }
  },

  {
    path: '/festival/challenges/results/:challengeId',
    name: 'festival-challenge-results',
    component: PageDefault,
    props: {
      component: FESTChallengeResults,
      modules:  ['festival', 'festival-challenge-results']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/festival/news',
    name: 'festival-news',
    component: PageDefault,
    props: {
      component: FESTNewsList,
      modules:  ['festival', 'festival-news']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival',
    }
  },

  {
    path: '/festival/news/:id',
    name: 'festival-news-detail',
    component: PageDefault,
    props: {
      component: FESTNewsDetail,
      modules:  ['festival', 'festival-news-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/news',
    }
  },

  {
    path: '/festival/stories',
    name: 'festival-stories',
    component: PageDefault,
    props: {
      component: FESTStories,
      modules:  ['festival', 'festival-stories']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival',
    }
  },

  {
    path: '/festival/stories/:id',
    name: 'festival-story',
    component: PageDefault,
    props: {
      component: FESTStory,
      modules:  ['festival', 'festival-story']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/news',
    }
  },

  {
    path: '/festival/new-story',
    name: 'festival-new-story',
    component: PageDefault,
    props: {
      component: FESTNewStory,
      modules:  ['festival', 'festival-new-story']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/stories',
    }
  },

  {
    path: '/festival/new-story/success',
    name: 'festival-new-story-success',
    component: PageDefault,
    props: {
      component: FESTSuccessForm,
      modules:  ['festival', 'festival-new-story-success']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/stories',
      content: i18n.t('fest.stories.forms.successDescription'),
      primaryButtonRoute: 'festival-stories'
    },
  },

  {
    path: '/festival/winners-stories',
    name: 'festival-winners-stories',
    component: PageDefault,
    props: {
      component: FESTWinnersStories,
      modules:  ['festival', 'festival-winners-stories']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/stories',
    },
  },

  {
    path: '/festival/pages/:id',
    name: 'festival-page',
    component: PageDefault,
    props: {
      component: FESTPage,
      modules:  ['festival', 'festival-page']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/festival/competitions',
    name: 'festival-competitions',
    component: PageDefault,
    props: {
      component: FESTCompetitions,
      modules:  ['festival', 'festival-competitions']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival',
    }
  },

  {
    path: '/festival/competitions/:competitionId',
    name: 'festival-competition-detail',
    component: PageDefault,
    props: {
      component: FESTCompetitionDetail,
      modules:  ['festival', 'festival-competition-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/competitions',
    }
  },

  {
    path: '/festival/competition/result/:competitionId',
    name: 'festival-competition-result',
    component: PageDefault,
    props: {
      component: FESTCompetitionResult,
      modules:  ['festival', 'festival-competition-result']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/festival/transport',
    name: 'festival-transport',
    component: PageDefault,
    props: {
      component: FESTTransport,
      modules:  ['festival', 'festival-transport']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival',
    }
  },

  {
    path: '/festival/transport/success/',
    name: 'festival-transport-success',
    component: PageDefault,
    props: {
      component: FESTSuccessForm,
      modules:  ['festival', 'festival-transport-success']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/transport',
      content: i18n.t('fest.transport.forms.successDescription'),
      primaryButtonOverride: true,
      primaryButton: i18n.t('fest.transport.forms.buttonSuccessPrimary'),
      primaryButtonRoute: 'festival',
      secondaryButton: i18n.t('fest.transport.forms.buttonSuccessSecondary'),
      secondaryButtonRoute: 'festival-transport'
    },
  },

  {
    path: '/festival/vote',
    name: 'festival-vote',
    component: PageDefault,
    props: {
      component: FESTVote,
      modules:  ['festival', 'festival-vote']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival',
    }
  },

  {
    path: '/festival/vote/:voteId',
    name: 'festival-vote-detail',
    component: PageDefault,
    props: {
      component: FESTVoteDetail,
      modules:  ['festival', 'festival-vote-detail']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/vote',
    }
  },

  {
    path: '/festival/vote/success/:voteId',
    name: 'festival-vote-success',
    component: PageDefault,
    props: {
      component: FESTSuccessForm,
      modules:  ['festival', 'festival-vote-success']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'festival/vote',
      content: i18n.t('fest.vote.forms.successDescription'),
      primaryButton: i18n.t('fest.vote.buttonFestival'),
      primaryButtonRoute: 'festival'
    },
  },

  {
    path: '/festival/vote/results/:voteId',
    name: 'festival-vote-results',
    component: PageDefault,
    props: {
      component: FESTVoteResult,
      modules:  ['festival', 'festival-vote-results']
    },
    meta: {
      caption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'history',
      navbarButtonDestination: -1
    }
  },

  {
    path: '/adaptation',
    name: 'adaptation',
    component: PageDefault,
    props: {
      component: AdaptationDashboard,
      modules: ['adaptation', 'adaptation-dashboard']
    },
    meta: {
      title: 'Adaptace nováčka',
      caption: 'Adaptace nováčka',
      navbarCaption: '',
      navbarBuilding: true,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/adaptation/:categoryId-:categorySlug',
    name: 'adaptation-detail',
    component: PageDefault,
    props: {
      component: AdaptationDetail,
      modules: ['adaptation', 'adaptation-detail']
    },
    meta: {
      title: 'Adaptace nováčka',
      caption: 'Adaptace nováčka',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'adaptation',
    }
  },

  {
    path: '/adaptation/contacts',
    name: 'adaptation-contacts',
    component: PageDefault,
    props: {
      component: AdaptationContacts,
      modules: ['adaptation', 'adaptation-contacts']
    },
    meta: {
      title: 'Důležité kontakty',
      caption: 'Důležité kontakty',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'adaptation',
    }
  },

  {
    path: '/education',
    name: 'education-dashboard',
    component: PageDefault,
    props: {
      component: EducationDashboard,
      modules: ['education', 'education-dashboard']
    },
    meta: {
      title: 'Vzdělávání',
      caption: 'Vzdělávání',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  {
    path: '/directory',
    name: 'directory-list',
    component: PageDefault,
    props: {
      component: DirectoryList,
      modules: ['directory', 'directory-list']
    },
    meta: {
      title: 'Adresář aplikací',
      caption: 'Adresář aplikací',
      navbarCaption: 'Adresář aplikací <span class="vue-custom-label">Beta</span>',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },

  ...routesExtend,
  { path: '*',
    component: PageService,
    props: {
      component: NotFound404,
      modules:  ['error-pages', '404']
    },
    meta: {
      title: 'Page not found',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROOT_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0};
    }
  },
});

router.beforeEach((to, from, next) => {
  let isLogged = loginUtils.checkLoggedState();
  let isAnonymous = loginUtils.checkAnonymousState();
  const entryPath = window.location.pathname + window.location.search;

  if (
    (from.name === 'dashboard' && to.name === 'news') ||
    (from.name === 'news-list' && to.name === 'news') ||
    (from.name === 'news' && to.name === 'dashboard') ||
    (from.name === 'news' && to.name === 'news-list')
  ) {
    // do not clear search keywords
  } else {
    store.state.articles.searchQuery = '';
    store.state.articles.isSearchActive = false;
  }

  if (!isLogged && entryPath.indexOf('login') === -1 && entryPath !== '/user-settings') {
    const entryPathFixed = entryPath.replace(new RegExp('^' + process.env.BASE_URL),'/'); // remove base path, e.g. /pilot
    localStorage.setItem(APP_ENTRY_PATH_STORAGE_KEY, entryPathFixed);
  }

  if (from.name === 'verify-code' && to.name === 'login' && isAnonymous && to.params?.option !== 'forced') {
    return;
  }

  if (!['login', 'verify-code', 'registration', 'registration-parents', 'user-settings'].includes(to.name) && isAnonymous) {
    next({ name: 'login' });
  } else {
    next();
  }
});

// ============================================================== //
// //////////////////////////// MISC //////////////////////////// //
// ============================================================== //

//============ PAGE TITLE / META =======================//
//======================================================//
// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// https://alligator.io/vuejs/vue-router-modify-head/
// This callback runs before every route change, including on page load.

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  const titlePrefix = 'Zapka - ';
  if (nearestWithTitle) {
    document.title = titlePrefix + nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = titlePrefix + previousNearestWithMeta.meta.title
  } else {
    document.title = titlePrefix + to.name;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

const originalPush = router.push;
router.push = function push(location, onResolve, onReject)
{
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

export default router;
