<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar :route-name="ROUTE_DASHBOARD" />
        </template>
        <template v-slot:content>
          <div class="prj-layout-default h-full">
            <!--========== HEADER =================================-->
            <!--===================================================-->
            <header class="">
              <t-tag
                variant="sectionTitle"
                class="xs:mt-6"
                tag-name="h1"
              >
                {{ $t('fest.competitions.headline') }}
              </t-tag>
            </header>

            <!--========== CHALLENGES CARDS =======================-->
            <!--===================================================-->
            <div class="relative flex-1">
              <div class="absolute top-0 right-0 bottom-0 left-0">
                <div
                  v-if="competitions && !competitions.length"
                  class="flex h-full"
                >
                  <prj1023-no-content-panel
                    :text="$t('fest.competitions.placeholder')"
                    class="h-11/12 my-auto"
                  />
                </div>
                <prj1006-cards-wrapper
                  v-else
                  class="h-full"
                  :hasNavigation="!isSingleCompetition"
                  :itemsLength="competitions.length"
                >
                  <template
                    v-for="{
                      themeId,
                      additionalLink,
                      additionalLinkUrl,
                      additionalIconType,
                      categoryId,
                      challengeId,
                      endDate,
                      id,
                      image,
                      name,
                      perex,
                      resultsReady,
                      status,
                      startDate,
                    } in competitions"
                  >
                    <prj1007-competition-card
                      :key="`competition-${id}`"
                      :statusTag="getStatus({ event: 'competitions', status, start: startDate, end: endDate })"
                      :themeTitle="getThemeTitle(themeId)"
                      :categoryId="categoryId"
                      :status="status"
                      :isPrimaryBtnDisabled="isCompetitionLocked({ competitionId: id, challengeId })"
                      :link="additionalLink"
                      :linkUrl="additionalLinkUrl"
                      :icon="additionalIconType"
                      :title="name"
                      :description="perex"
                      :image="image"
                      :isChallengeLinked="!!challengeId"
                      :isSingle="isSingleCompetition"
                      :isResultReady="!!resultsReady"
                      isFullPage
                      @primaryButton="toPage({ route: ROUTE_COMPETITION_DETAIL, competitionId: id })"
                      @resultClick="toPage({ route: ROUTE_COMPETITION_RESULT, competitionId: id })"
                      @linkedEventClick="toPage({ route: ROUTE_CHALLENGE_DETAIL, challengeId })"
                    />
                  </template>
                </prj1006-cards-wrapper>
              </div>
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex'
import { FEST_THEMES, FEST_COMPETITIONS } from '@/store/store-modules'
import { GET_THEMES_INFO, GET_COMPETITIONS_INFO } from '@/store/constants/fest/actions'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import { WAITING, EXPIRED } from '@/constants/fest/status'
import '@/utils/dayjs'
import { eventStatus } from '@/mixins/fest/eventStatus'

import { ROUTE_DASHBOARD, ROUTE_COMPETITION_DETAIL, ROUTE_COMPETITION_RESULT, ROUTE_CHALLENGE_DETAIL } from '@/constants/fest/route-names'

import PageLayout from '@/templates/fest/page-layout'
import Prj1005NavigationBar from '@/components/fest/prj1005-navigation-bar/prj1005-navigation-bar'
import Prj1006CardsWrapper from '@/components/fest/prj1006-cards-wrapper/prj1006-cards-wrapper'
import Prj1007CompetitionCard from '@/components/fest/prj1007-event-card/prj1007-competition-card'
import Prj1023NoContentPanel from '@/components/fest/prj1023-no-content-panel/prj1023-no-content-panel'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTCompetitions',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout,
    Prj1005NavigationBar,
    Prj1006CardsWrapper,
    Prj1007CompetitionCard,
    Prj1023NoContentPanel
  },
  mixins: [mxNavigationDefault, eventStatus],
  data() {
    return {
      ROUTE_DASHBOARD,
      ROUTE_COMPETITION_DETAIL,
      ROUTE_COMPETITION_RESULT,
      ROUTE_CHALLENGE_DETAIL,
      loading: false,
      loginOverlay: false,
      today: this.$date.utc(),
      WAITING,
      EXPIRED,
    }
  },
  computed: {
    ...mapState('festUser', {
      userName: (state) => state.name,
      userEmail: (state) => state.email,
      userToken: (state) => state.token,
      userTokenExpiration: (state) => state.tokenExpiration,
    }),
    ...mapState('festCompetitions', {
      competitions: (state) => state.sortCompetitionsInfo,
      activeCompetitionsCount: (state) => state.competitionsCount,
    }),
    ...mapState('festChallenges', {
      challengesUserInvolved: (state) => state.challengesUserInvolved,
    }),
    ...mapState('festThemes', ['themesInfo']),
    isUserExist() {
      return this.userToken && this.userEmail
    },
    isSingleCompetition() {
      return this.competitions.length === 1
    },
  },
  created() {
    if (!this.competitions?.length) this.$store.dispatch(`${FEST_COMPETITIONS}/${GET_COMPETITIONS_INFO}`)
    if (!this.themesInfo?.length) this.$store.dispatch(`${FEST_THEMES}/${GET_THEMES_INFO}`)
  },
  methods: {
    toPage({ route, competitionId = null, challengeId = null }) {
      this.$router.push({
        name: route,
        params: {
          competitionId,
          challengeId,
        },
      })
    },

    /**
     * Two major types of competitions:
     * 1. Competition dependent on Challenge involvement - has challengeId
     * 2. Standalone competition - challengeId is set to null
     * @see https://jpower8.atlassian.net/browse/ESG-529
     */
    isCompetitionLocked({ competitionId, challengeId }) {
      if (challengeId) {
        return !this.getIsUserInvolvedInChallenge(competitionId)
      }
      return false
    },

    getIsUserInvolvedInChallenge(competitionId) {
      return this.challengesUserInvolved.some(item => item.linkedCompetition === competitionId)
    },

    getThemeTitle(themeId) {
      return this.themesInfo?.find((theme) => theme.id === themeId).title
    },
  },
}
</script>
