//============ GENERAL =================================//
//======================================================//
import { SET_APP_CONFIG } from '@/store/constants/esg/mutations'
import { GET_APP_CONFIG } from '@/store/constants/esg/actions'
import { GET_APP_CONFIG_SUCCESS } from '@/store/constants/esg/response-types'

//============ API =====================================//
//======================================================//
import apiCmsConfig from '@/esg-api/cms-config'

//============ STATE ===================================//
//======================================================//
const state = {
  aboutPage: null,
  personalPrivacyPolicyPage: null
}

//============ MUTATIONS ===============================//
//======================================================//
const mutations = {
  [SET_APP_CONFIG](state, payload) {
    payload.map((item) => {
      if (item.type === 'introductory_page') state.aboutPage = item
      if (item.type === 'personal_information_conditions') state.personalPrivacyPolicyPage = item
    })
  },
}

//============ ACTIONS =================================//
//======================================================//
const actions = {
  async [GET_APP_CONFIG]({ commit }) {
    const { type, data } = await apiCmsConfig.getAppConfig()
    if (type === GET_APP_CONFIG_SUCCESS) commit(SET_APP_CONFIG, data)
  }
}

//============ GETTERS =================================//
//======================================================//

const getters = {}

//============ EXPORT ==================================//
//======================================================//
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
