<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-c-component vue-c-repeater" :class="classObject">
        <!--========== HEADER =================================-->
        <!--===================================================-->
        <div v-if="hasHeader" class="vue-b-repeater-header">
            <slot
                name="header"
                :repeaterPaginated="paginated"
                :repeaterItemsPerPage="itemsPerPage"
                :repeaterPageCurrent="pageCurrentData"
                :repeaterHasItems="hasItems"
                :repeaterItemsFrom="itemsFrom"
                :repeaterItemsTo="itemsTo"
                :repeaterItemsTotal="itemsTotal"
                :repeaterPagesTotal="pagesTotal"
                :repeaterLoadMoreItemsCount="loadMoreItemsCount"
                :repeaterPageSet="pageSet"
                :repeaterPagePrev="pagePrev"
                :repeaterPageNext="pageNext"
                :repeaterLoadMore="loadMore"
                :repeaterLoadMoreCountReset="loadMoreCountReset"
                :repeaterScrollTop="scrollTop"
                :repeaterSetCssClassesAdditional="setCssClassesAdditional"
                :repeaterSetItemsPerPage="setItemsPerPage"
            ></slot>
        </div>

        <!--=== CONTENT =====================================-->
        <!--=================================================-->

        <!--======= MODE CUSTOM =====================-->
        <!--=========================================-->
        <div v-if="mode === 'custom'" class="vue-b-repeater-content">
            <!--==== HEAD =====================-->
            <div v-if="hasItemsHead" class="vue-b-items-head">
                <slot
                    v-if="$scopedSlots.itemsHead"
                    name="itemsHead"
                    :repeaterItemElements="itemElements"
                    :repeaterSortingItemElementsActive="sortingItemElementsActive"
                ></slot>
                <template v-else>
                    <div class="vue-b-repeater-item-head">
                        <div
                            v-for="(itemElement, index) in itemElementsVisible"
                            :key="index"
                            class="vue-b-repeater-item-head-element"
                            :class="[
                                itemElement.customCss,
                                {
                                    'vue-is-fixed': itemElement.fixed,
                                    'vue-is-sortable': itemElement.sortable,
                                    'vue-is-sorting-active-asc':
                                        itemElement.sortable &&
                                        sortingItemElementsActive[itemElement.id] &&
                                        sortingItemElementsActive[itemElement.id].direction === 'asc',
                                    'vue-is-sorting-active-desc':
                                        itemElement.sortable &&
                                        sortingItemElementsActive[itemElement.id] &&
                                        sortingItemElementsActive[itemElement.id].direction === 'desc'
                                }
                            ]"
                            :style="{
                                width:
                                    itemElement.width === undefined
                                        ? null
                                        : isNaN(itemElement.width)
                                        ? itemElement.width
                                        : itemElement.width + 'px'
                            }"
                            @click="sortByItemElement(itemElement)"
                        >
                            <span class="vue-caption">{{ itemElement.caption }}</span>
                            <template v-if="!sortingMultiple">
                                <div
                                    v-if="itemElement.sortable && sortingItemElementsActive[itemElement.id]"
                                    class="vue-sortable-indicator"
                                >
                                    <template v-if="sortingItemElementsActive[itemElement.id].direction === 'asc'">
                                        {{ sortingAscCaptionComputed }}
                                    </template>
                                    <template
                                        v-else-if="sortingItemElementsActive[itemElement.id].direction === 'desc'"
                                    >
                                        {{ sortingDescCaptionComputed }}
                                    </template>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>

            <!--==== CONTENT ==================-->
            <div v-if="itemsVisible.length" class="vue-b-items-content">
                <template v-for="(item, index) in itemsVisible">
                    <div
                        :key="index"
                        class="vue-b-repeater-item"
                        :class="[
                            itemClass(item, index),
                            item.customCss,
                            {
                                'vue-is-selected': itemsSelectedData.indexOf(getValueByPath(item, itemKey)) > -1,
                                'vue-is-filtered-out': itemsFilteredOut.indexOf(getValueByPath(item, itemKey)) > -1
                            }
                        ]"
                        :draggable="itemsDraggable"
                        @click.ctrl="itemSelect(item, index)"
                        @dragstart="itemDragStart($event, item, index)"
                        @dragleave="itemDragLeave($event, item, index)"
                        @dragover="itemDragOver($event, item, index)"
                        @drop="itemDrop($event, item, index)"
                    >
                        <slot v-if="$scopedSlots.item" name="item" :item="item" :index="index" />
                        <template v-else>
                            <div
                                v-for="itemElement in itemElementsVisible"
                                :key="itemElement.id"
                                class="vue-b-repeater-item-element"
                                :class="[itemElement.customCss]"
                            >
                                <span
                                    v-if="itemElement.renderHtml"
                                    v-html="getValueByPath(item, itemElement.valuePath)"
                                />
                                <template v-else>
                                    {{ getValueByPath(item, itemElement.valuePath) }}
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
            <div v-else class="vue-b-items-content">
                <div class="vue-is-empty">
                    <slot name="empty" />
                </div>
            </div>
        </div>

        <!--======= MODE TABLE ======================-->
        <!--=========================================-->
        <table v-if="mode === 'table'" class="vue-b-repeater-content">
            <!--==== HEAD =====================-->
            <thead v-if="hasItemsHead" class="vue-b-items-head">
                <slot
                    v-if="$scopedSlots.itemsHead"
                    name="itemsHead"
                    :repeaterItemElements="itemElements"
                    :repeaterSortingItemElementsActive="sortingItemElementsActive"
                ></slot>
                <template v-else>
                    <tr class="vue-b-repeater-item-head">
                        <th
                            v-for="(itemElement, index) in itemElementsVisible"
                            :key="index"
                            class="vue-b-repeater-item-head-element"
                            :class="[
                                itemElement.customCss,
                                {
                                    'vue-is-fixed': itemElement.fixed,
                                    'vue-is-sortable': itemElement.sortable,
                                    'vue-is-sorting-active-asc':
                                        itemElement.sortable &&
                                        sortingItemElementsActive[itemElement.id] &&
                                        sortingItemElementsActive[itemElement.id].direction === 'asc',
                                    'vue-is-sorting-active-desc':
                                        itemElement.sortable &&
                                        sortingItemElementsActive[itemElement.id] &&
                                        sortingItemElementsActive[itemElement.id].direction === 'desc'
                                }
                            ]"
                            :style="{
                                width:
                                    itemElement.width === undefined
                                        ? null
                                        : isNaN(itemElement.width)
                                        ? itemElement.width
                                        : itemElement.width + 'px'
                            }"
                            @click="sortByItemElement(itemElement)"
                        >
                            <span class="vue-caption">{{ itemElement.caption }}</span>
                            <template v-if="!sortingMultiple">
                                <div
                                    v-if="itemElement.sortable && sortingItemElementsActive[itemElement.id]"
                                    class="vue-sortable-indicator"
                                >
                                    <template v-if="sortingItemElementsActive[itemElement.id].direction === 'asc'">
                                        {{ sortingAscCaptionComputed }}
                                    </template>
                                    <template
                                        v-else-if="sortingItemElementsActive[itemElement.id].direction === 'desc'"
                                    >
                                        {{ sortingDescCaptionComputed }}
                                    </template>
                                </div>
                            </template>
                        </th>
                    </tr>
                </template>
            </thead>

            <!--==== CONTENT ==================-->
            <tbody v-if="itemsVisible.length" class="vue-b-items-content">
                <template v-for="(item, index) in itemsVisible">
                    <tr
                        :key="index"
                        class="vue-b-repeater-item"
                        :class="[
                            itemClass(item, index),
                            item.customCss,
                            {
                                'vue-is-selected': itemsSelectedData.indexOf(getValueByPath(item, itemKey)) > -1,
                                'vue-is-filtered-out': itemsFilteredOut.indexOf(getValueByPath(item, itemKey)) > -1
                            }
                        ]"
                        :draggable="itemsDraggable"
                        @click.ctrl="itemSelect(item, index)"
                        @dragstart="itemDragStart($event, item, index)"
                        @dragleave="itemDragLeave($event, item, index)"
                        @dragover="itemDragOver($event, item, index)"
                        @drop="itemDrop($event, item, index)"
                    >
                        <slot v-if="$scopedSlots.item" name="item" :item="item" :index="index" />
                        <template v-else>
                            <td
                                v-for="itemElement in itemElementsVisible"
                                :key="itemElement.id"
                                class="vue-b-repeater-item-element"
                                :class="[itemElement.customCss]"
                            >
                                <span
                                    v-if="itemElement.renderHtml"
                                    v-html="getValueByPath(item, itemElement.valuePath)"
                                />
                                <template v-else>
                                    {{ getValueByPath(item, itemElement.valuePath) }}
                                </template>
                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
            <tbody v-else>
                <tr class="vue-is-empty">
                    <td :colspan="itemElementsVisible.length">
                        <slot name="empty" />
                    </td>
                </tr>
            </tbody>
        </table>

        <!--========== FOOTER =================================-->
        <!--===================================================-->
        <div v-if="hasFooter" class="vue-b-repeater-footer">
            <slot
                name="footer"
                :repeaterPaginated="paginated"
                :repeaterItemsPerPage="itemsPerPage"
                :repeaterPageCurrent="pageCurrentData"
                :repeaterHasItems="hasItems"
                :repeaterItemsFrom="itemsFrom"
                :repeaterItemsTo="itemsTo"
                :repeaterItemsTotal="itemsTotal"
                :repeaterPagesTotal="pagesTotal"
                :repeaterLoadMoreItemsCount="loadMoreItemsCount"
                :repeaterPageSet="pageSet"
                :repeaterPagePrev="pagePrev"
                :repeaterPageNext="pageNext"
                :repeaterLoadMore="loadMore"
                :repeaterLoadMoreCountReset="loadMoreCountReset"
                :repeaterScrollTop="scrollTop"
                :repeaterSetCssClassesAdditional="setCssClassesAdditional"
                :repeaterSetItemsPerPage="setItemsPerPage"
            ></slot>
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//=== MISC
import Lodash from 'lodash';
import utilsEval from './../../../utils/eval/utils-eval';

//=== MIXINS
import Localization from '../../mixins/localization';

export default {
    name: 'Tbl1001Repeater',
    mixins: [Localization],
    model: {
        prop: 'items',
        event: 'changeEvent'
    },
    props: {
        //============ GENERAL =================================//
        //======================================================//
        mode: {
            default: 'custom',
            type: String,
            validator: value => {
                return ['custom', 'table'].indexOf(value) !== -1;
            }
        },
        pageCurrent: {
            default: 1,
            type: Number
        },
        itemsPerPage: {
            default: 10,
            type: Number
        },
        //============ DATA ====================================//
        //======================================================//
        itemElements: {
            type: Array,
            default: () => []
        },
        items: {
            type: Array,
            default: () => []
        },
        // TODO MBU: implement performance optimizations,
        // if items prop is static, there is no need to watch it for changes
        itemsStatic: Boolean,
        itemsChecked: {
            type: Array,
            default: () => []
        },
        itemsSelected: {
            type: Array,
            default: () => []
        },
        itemsFilteredOut: {
            type: Array,
            default: () => []
        },
        itemsDetailExpanded: {
            type: Array,
            default: () => []
        },
        // TODO MBU: implement custom draggable functionality
        itemsDraggable: {
            default: 'false',
            type: String,
            validator: value => {
                return ['true', 'false', 'auto'].indexOf(value) !== -1;
            }
        },
        //============ ITEM ====================================//
        //======================================================//
        itemClass: {
            type: Function,
            default: () => ''
        },
        itemKey: String,
        filters: {
            type: Array,
            default: () => []
        },
        filteredItemsRemoved: {
            default: true,
            type: Boolean
        },
        sorting: {
            type: Array,
            default: () => []
        },
        sortingMultiple: Boolean, // TODO MBU: implement sorting by multiple itemElements
        sortingAscCaption: String,
        sortingDescCaption: String,
        //============ FUNCTIONALITY ===========================//
        //======================================================//
        // TODO MBU: manual version not implemented yet
        drivenBy: {
            default: 'watchers',
            type: String,
            validator: value => {
                return ['watchers', 'manual'].indexOf(value) !== -1;
            }
        },
        sortAndFilterOnItemsUpdate: Boolean,
        jumpToFirstPageOnFilter: {
            default: true,
            type: Boolean
        },
        jumpToFirstPageOnSort: {
            default: true,
            type: Boolean
        },
        //============ PAGINATION ==============================//
        //======================================================//
        paginated: {
            default: true,
            type: Boolean
        },
        //============ ADDITIONAL ELEMENTS =====================//
        //======================================================//
        itemsHead: {
            default: true,
            type: Boolean
        },
        //============ MISC ====================================//
        //======================================================//
        id: {
            default: null,
            type: String
        }
    },
    data() {
        return {
            pageCurrentData: this.pageCurrent,
            itemsPerPageData: this.itemsPerPage,
            itemElementsData: [...this.itemElements],
            itemsData: [...this.items],
            itemsCheckedData: [...this.itemsChecked],
            itemsSelectedData: [...this.itemsSelected],
            itemsFilteredOutData: [...this.itemsFilteredOut],
            itemsDetailExpandedData: [...this.itemsDetailExpanded],
            sortingData: [...this.sorting],
            filtersData: [...this.filters],
            itemsWatcherUnwatch: null,
            sortingWatcherUnwatch: null,
            sortingDataWatcherUnwatch: null,
            filtersWatcherUnwatch: null,
            filtersDataWatcherUnwatch: null,
            loadMoreCount: 0,
            cssClassesAdditional: [],
            initialized: false
        };
    },
    computed: {
        classObject: function() {
            return [
                this.cssClassesAdditional,
                {
                    'vue-has-header': this.hasHeader,
                    'vue-has-footer': this.hasFooter,
                    'vue-has-items-head': this.hasItemsHead,
                    'vue-has-items': this.hasItems,
                    'vue-has-no-items': this.hasNoItems,
                    'vue-is-paginated': this.paginated,
                    'vue-is-single-page': this.isSinglePage
                }
            ];
        },
        //============ SORTING =================================//
        //======================================================//
        sortingItemElementsActive() {
            let sortingItemElementsActive = {};

            for (let i = 0; i < this.sortingData.length; i++) {
                let sortingItem = this.sortingData[i];

                if (sortingItem.elementId) {
                    sortingItemElementsActive[sortingItem.elementId] = {};
                    sortingItemElementsActive[sortingItem.elementId].id = sortingItem.elementId;
                    sortingItemElementsActive[sortingItem.elementId].direction = sortingItem.direction;
                    sortingItemElementsActive[sortingItem.elementId].fn = sortingItem.fn;
                    sortingItemElementsActive[sortingItem.elementId].priority = i;
                }
            }

            return sortingItemElementsActive;
        },
        sortingAscCaptionComputed() {
            if (this.sortingAscCaption) {
                return this.sortingAscCaption;
            }

            return this.i18n('sortingAsc');
        },
        sortingDescCaptionComputed() {
            if (this.sortingAscCaption) {
                return this.sortingAscCaption;
            }

            return this.i18n('sortingDesc');
        },
        //============ PAGINATION ==============================//
        //======================================================//
        hasItems() {
            return this.itemsTotal > 0;
        },
        hasNoItems() {
            return this.itemsTotal === 0;
        },
        isSinglePage() {
            return this.pagesTotal === 1;
        },
        itemElementsVisible() {
            if (!this.itemElementsData) return this.itemElementsData;
            return this.itemElementsData.filter(itemElement => {
                return itemElement.visibility || itemElement.visibility === undefined;
            });
        },
        itemsVisible() {
            if (!this.paginated) {
                return this.itemsData;
            }

            if (this.itemsData.length <= this.itemsPerPageData) {
                return this.itemsData;
            } else {
                // +1, because end is not included
                return this.itemsData.slice(this.itemsFromIndex, this.itemsToIndex + 1);
            }
        },
        itemsTotal() {
            return this.itemsData.length;
        },
        pagesTotal() {
            let pagesTotal = Math.ceil(this.itemsTotal / this.itemsPerPageData);
            if (pagesTotal > 0) {
                return pagesTotal;
            } else {
                return 0;
            }
        },
        itemsRest() {
            return this.itemsTotal % this.itemsPerPageData;
        },
        loadMoreItemsCount() {
            let itemsRest = this.itemsRest;
            if (this.pageCurrentData === this.pagesTotal - 1) {
                if (itemsRest === 0) {
                    return this.itemsPerPageData;
                } else {
                    return itemsRest;
                }
            } else if (this.pageCurrentData < this.pagesTotal - 1 && this.pageCurrentData > 0) {
                return this.itemsPerPageData;
            } else {
                return 0;
            }
        },
        //============ SLOTS ===================================//
        //======================================================//
        hasItemsHead() {
            return !!this.$scopedSlots.itemsHead || this.itemsHead;
        },
        hasHeader() {
            return !!this.$scopedSlots.header;
        },
        hasFooter() {
            return !!this.$scopedSlots.footer;
        },
        //============ MISC ====================================//
        //======================================================//
        // TODO MBU: rename to itemsFromIndexComputed and add itemsFromIndex prop
        // to allow for complete pagination customization
        itemsFromIndex() {
            return (this.pageCurrentData - 1 - this.loadMoreCount) * this.itemsPerPageData;
        },
        itemsFrom() {
            let itemsFrom = this.itemsFromIndex + 1;

            if (this.hasNoItems) {
                itemsFrom = 0;
            }

            return itemsFrom;
        },
        // TODO MBU: rename to itemsToIndexComputed and add itemsToIndex prop
        // to allow for complete pagination customization
        itemsToIndex() {
            let itemsToIndex =
                parseInt(this.itemsFromIndex + this.loadMoreCount * this.itemsPerPageData, 10) +
                parseInt(this.itemsPerPageData, 10) -
                1;

            return itemsToIndex;
        },
        itemsTo() {
            let itemsTo = this.itemsToIndex + 1;

            if (itemsTo > this.itemsTotal) {
                itemsTo = this.itemsTotal;
            }

            return itemsTo;
        }
    },
    watch: {
        pageCurrent(page) {
            this.pageCurrentData = page;
        },
        itemsChecked(items) {
            this.itemsCheckedData = [...items];
        },
        itemsSelected(items) {
            this.itemsSelectedData = [...items];
        },
        itemsDetailExpanded(items) {
            this.itemsDetailExpandedData = [...items];
        }
    },
    mounted() {
        this.filterAndSort();

        if (this.drivenBy === 'watchers') {
            //=== ITEMS
            this.itemsWatcherUnwatch = this.$watch('items', this.itemsWatcherCallback, {
                deep: true
            });

            //=== SORTING
            this.sortingWatcherUnwatch = this.$watch('sorting', this.sortingWatcherCallback, { deep: true });
            this.sortingDataWatcherUnwatch = this.$watch('sortingData', this.sortingDataWatcherCallback, {
                deep: true
            });

            //=== FILTERS
            this.filtersWatcherUnwatch = this.$watch('filters', this.filtersWatcherCallback, { deep: true });
            this.filtersDataWatcherUnwatch = this.$watch('filtersData', this.filtersDataWatcherCallback, {
                deep: true
            });
        }

        this.initialized = true;
    },
    methods: {
        itemsWatcherCallback(newValue) {
            this.itemsData = [...newValue];
        },
        sortingWatcherCallback(newValue) {
            this.sortingData = [...newValue];
        },
        sortingDataWatcherCallback() {
            this.sortItems();
        },
        filtersWatcherCallback(newValue) {
            this.filtersData = [...newValue];
        },
        filtersDataWatcherCallback() {
            this.filterItems();
        },
        //============ GENERAL =================================//
        //======================================================//
        getValueByPath(obj, path) {
            let pathSplit = path.split('.');
            let value;

            if (!pathSplit.length > 1) {
                value = obj[path];
            } else {
                value = pathSplit.reduce((o, i) => o[i], obj);
            }
            return value;
        },
        filterAndSort() {
            this.filterItems();
            this.sortItems();
        },
        //============ FILTERING ===============================//
        //======================================================//
        filterItems() {
            // TODO MBU: reset filtered out data each time?
            this.itemsFilteredOutData = [];

            if (this.filteredItemsRemoved && this.filtersData.length > 0) {
                // filter items from itemsData
                this.itemsData = this.items.filter(item => this.isItemFiltered(item));
            } else if (!this.filteredItemsRemoved && this.filtersData.length > 0) {
                // items will be still present in itemsData, but will have class vue-is-filtered-out
                for (let i = 0; i < this.items.length; i++) {
                    let item = this.items[i];
                    this.isItemFiltered(item);
                }
            } else {
                // if no filtersData are set, set itemsData from items prop
                this.itemsData = [...this.items];
            }

            // is sortingData are set, sort items
            if (this.sortingData.length > 0) {
                this.sortItems();
            }

            // itemsFilteredOut is filled inside isItemFiltered function
            this.$emit('update:itemsFilteredOut', this.itemsFilteredOutData);
            if (this.jumpToFirstPageOnFilter && this.initialized) {
                this.pageCurrentData = 1;
                this.$emit('update:pageCurrent', this.pageCurrentData);
            }
        },
        isItemFiltered(item) {
            let itemKey = this.getValueByPath(item, this.itemKey);
            let itemsFilteredOutItemIndex = this.itemsFilteredOutData.indexOf(itemKey);
            let fnResult = false; // result of filtering function - false means item is filtered out

            // iterate over multiple filtering conditions
            for (let i = 0; i < this.filtersData.length; i++) {
                let filterItem = this.filtersData[i];

                let value = this.getValueByPath(item, filterItem.valuePath);

                if (Lodash.isFunction(filterItem.fn)) {
                    fnResult = filterItem.fn(value);
                } else if (Lodash.isString(filterItem.fn)) {
                    let rule = filterItem.fn;
                    fnResult = utilsEval.evalDefinedFunction(value, rule);
                }

                if (!fnResult) {
                    break;
                }
            }

            // if filtering conditions are not met (fnResult === false), item is filtered out
            if (!fnResult) {
                if (itemsFilteredOutItemIndex < 0) {
                    this.itemsFilteredOutData.push(itemKey);
                }
            }

            return fnResult;
        },

        //============ SORTING =================================//
        //======================================================//
        sortByItemElement(itemElement) {
            let valuePath = itemElement.valuePath;
            let fn = itemElement.sortFn;
            let direction;
            let elementId = itemElement.id;

            if (itemElement.sortable) {
                if (!this.sortingMultiple) {
                    let existingSortItem = this.sortingItemElementsActive[elementId];

                    if (!existingSortItem) {
                        direction = 'asc';
                    } else {
                        // if sorting by this itemElement is already active, reverse sort direction
                        if (existingSortItem.direction === 'asc') {
                            direction = 'desc';
                        } else if (existingSortItem.direction === 'desc') {
                            direction = 'asc';
                        }
                    }

                    // overwrite sortingData
                    let sortingData = {
                        valuePath,
                        fn,
                        direction,
                        elementId
                    };

                    this.sortingData = [sortingData];
                    this.$emit('update:sorting', this.sortingData);
                }
            }
        },
        sortItems() {
            if (this.sortingData.length > 0) {
                //============ SINGLE SORT =============================//
                //======================================================//
                if (this.sortingData.length === 1) {
                    let sortingItem = this.sortingData[0];
                    let sortingItemFn;
                    let sortingItemDirection = 'asc';
                    let sortingItemElementId;

                    if (sortingItem.fn) {
                        sortingItemFn = sortingItem.fn;
                    }

                    if (sortingItem.direction) {
                        sortingItemDirection = sortingItem.direction;
                    }

                    if (sortingItem.elementId) {
                        sortingItemElementId = sortingItem.elementId;
                    }

                    this.sortBySingle(sortingItem.valuePath, sortingItemFn, sortingItemDirection, sortingItemElementId);
                }
                //============ MULTIPLE SORT ===========================//
                //======================================================//
                else {
                    // TODO MBU: implement multicolumn sorting
                }
            } else {
                // if the sorting is cleared, fill data again from items and perform filterItems (if any)
                this.itemsData = [...this.items];
                if (this.filtersData.length > 0) {
                    this.filterItems();
                }
            }

            if (this.jumpToFirstPageOnSort && this.initialized) {
                this.pageCurrentData = 1;
                this.$emit('update:pageCurrent', this.pageCurrentData);
            }
        },
        sortBySingle(valuePath, fn, direction = 'asc') {
            this.itemsData = this.sortBy(this.itemsData, valuePath, fn, direction);
        },
        sortBy(array, valuePath, fn, direction = 'asc') {
            let result = [];
            if (fn && typeof fn === 'function') {
                result = [...array].sort((a, b) => fn(a, b, direction));
            } else {
                result = [...array].sort((a, b) => {
                    let newA = this.getValueByPath(a, valuePath);
                    let newB = this.getValueByPath(b, valuePath);

                    // boolean
                    if (typeof newA === 'boolean' && typeof newB === 'boolean') {
                        if (direction === 'asc') {
                            return newA - newB;
                        } else if (direction === 'desc') {
                            return newB - newA;
                        }
                    }

                    if (!newA && newA !== 0) {
                        return 1;
                    }
                    if (!newB && newB !== 0) {
                        return -1;
                    }
                    if (newA === newB) {
                        return 0;
                    }

                    // TODO MBU: check diacritics sorting / do as default option or fn?
                    // string
                    newA = typeof newA === 'string' ? newA.toUpperCase() : newA;
                    newB = typeof newB === 'string' ? newB.toUpperCase() : newB;

                    if (direction === 'asc') {
                        return newA > newB ? 1 : -1;
                    } else if (direction === 'desc') {
                        return newA > newB ? -1 : 1;
                    }
                });
            }

            return result;
        },
        //============ TOUCH ===================================//
        //======================================================//
        itemDragStart(event, item, index) {
            this.$emit('itemDragStartEvent', { event, item, index });
        },
        itemDragLeave(event, item, index) {
            this.$emit('itemDragLeaveEvent', { event, item, index });
        },
        itemDragOver(event, item, index) {
            this.$emit('itemDragOverEvent', { event, item, index });
        },
        itemDrop(event, item, index) {
            this.$emit('itemDropEvent', { event, item, index });
        },
        //============ SELECT ==================================//
        //======================================================//
        // TODO MBU: click on children results in selecting the item
        itemSelect(item, index) {
            let itemSelectedId = this.getValueByPath(item, this.itemKey);

            let itemSelectedIndex = this.itemsSelectedData.indexOf(itemSelectedId);

            if (itemSelectedIndex > -1) {
                this.itemsSelectedData.splice(itemSelectedIndex, 1);
            } else {
                this.itemsSelectedData.push(itemSelectedId);
            }

            this.$emit('update:itemsSelected', this.itemsSelectedData);
            this.$emit('itemSelectEvent', item, index);
        },
        //============ PAGINATION ==============================//
        //======================================================//
        pagePrev() {
            this.pageCurrentData -= 1;
            this.loadMoreCountReset();
            this.$emit('pagePrevEvent', this.pageCurrentData);
            this.$emit('update:pageCurrent', this.pageCurrentData);
        },
        pageSet(page) {
            this.pageCurrentData = page;
            this.loadMoreCountReset();
            this.$emit('pageSetEvent', page);
            this.$emit('update:pageCurrent', this.pageCurrentData);
        },
        pageNext() {
            this.pageCurrentData += 1;
            this.loadMoreCountReset();
            this.$emit('pageNextEvent', this.pageCurrentData);
            this.$emit('update:pageCurrent', this.pageCurrentData);
        },
        //============ LOAD MORE ===============================//
        //======================================================//
        loadMore() {
            this.pageCurrentData += 1;
            this.loadMoreCount += 1;
            this.$emit('loadMoreEvent', this.loadMoreCount, this.pageCurrentData);
            this.$emit('update:pageCurrent', this.pageCurrentData);
        },
        loadMoreCountReset() {
            this.loadMoreCount = 0;
        },
        //============ MISC ====================================//
        //======================================================//
        scrollTop() {
            // TODO MBU: add scroll functionality and not just event
            this.$emit('scrollTopEvent');
        },
        setCssClassesAdditional(classes) {
            this.cssClassesAdditional = classes;
        },
        setItemsPerPage(value) {
            this.itemsPerPageData = value;
            this.$emit('update:itemsPerPage', this.pageCurrentData);
        }
    }
};
</script>
