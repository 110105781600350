import * as MUTATIONS_CONSTANTS from '../constants/mutations';
import * as ACTIONS_CONSTANTS from '../constants/actions';
import * as GETTERS_CONSTANTS from '../constants/getters';
import * as STORE_MODULES from '@/store/store-modules';
import dataLoader from '@/utils/data-loader';
import utilsGeneral from '../../utils/utils-general';
import apiClient from '../../utils/data-loader/index';
import logger from '../../utils/logger';
import * as APP_CONSTANTS from '@/constants/app-constants';
import { APP_DESTINATIONS } from '@/constants/general';

const state = {
  appDestination: process.env.VUE_APP_DESTINATION,
  appUpdateFailed: false,
  appUpdateReady: false,
  swRegistration: null,
  swInstance: null,
  pushNotificationsSubscribed: null,
  pushNotificationsEndpoint: null,
  pushNotificationsPermission: null,
  appVersion: null,
  appVersionOnServer: null,
  appChangelog: null,
  viewportSize: {
    windowHeight: 0,
    windowWidth: 0,
    height: 0,
    width: 0,
    correctionalConstant: 0,
  },
  isMenuOpened: false,
  isSubmenuOpened: false,
  originSubmenuIndex: null,
  backToNavigation: false,
  isDrawerOpened: false,
  applicationConfig: {},
  applicationConfigLoaded: false,
  applicationConfigLastCheck: {
    timestamp: '',
    date: ''
  },
  surveyConfig: {},
  navigationData: [],
  navigationDataLoaded: false,
  dashboardButtons: [],
  dashboardGroupButtons: {},
  networkError: false,
  networkErrorLastDismiss: 0,
  statistics: {
    timestamps: {
      [STORE_MODULES.RESTAURANTS]: 0,
      [STORE_MODULES.FAULT_REPORTING]: 0,
      [STORE_MODULES.NEWS]: 0,
      [STORE_MODULES.ARTICLE]: 0,
      [STORE_MODULES.SERVICES]: 0,
      [STORE_MODULES.WHERE_TO_FIND]: 0,
      [STORE_MODULES.IDEAS_SENDING]: 0
    }
  },
  newsActiveCategory: 0,
  covid19DashboardActiveCategory: 1,
  processesRunning: [],
  dashboardButtonsExpanded: false,
  dashboardButtonsExpandedWidth: 170,
  searchQuery: '',
  isSearchActive: false,
  isSearchToggleActive: false,
  isSearchHistoryActive: false,
  searchResultData: null,
  searchResultCount: null,
  searchResultFullData: null
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_APP_UPDATE_READY](state, swInstanceWaiting) {
    state.appUpdateReady = true;
    state.swInstanceWaiting = swInstanceWaiting;
  },
  [MUTATIONS_CONSTANTS.SET_APP_UPDATE_FAILED](state) {
    state.appUpdateFailed = true;
  },
  [MUTATIONS_CONSTANTS.SET_SW_REGISTRATION](state, registration) {
    state.swRegistration = registration;
  },
  [MUTATIONS_CONSTANTS.SET_SW_INSTANCE](state, instance) {
    state.swInstanceWaiting = null;
    state.swInstance = instance;
  },

  [MUTATIONS_CONSTANTS.SET_VIEWPORT_SIZE](state, payload) {
    state.viewportSize.windowHeight = payload.windowHeight;
    state.viewportSize.windowWidth = payload.windowWidth;
    state.viewportSize.height = payload.height;
    state.viewportSize.width = payload.width;
    state.viewportSize.correctionalConstant = payload.correctionalConstant;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU](state, menuState) {
    state.isMenuOpened = menuState;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU](state, submenuState) {
    state.isSubmenuOpened = submenuState;
  },

  [MUTATIONS_CONSTANTS.SET_BACK_TO_NAVIGATION](state, navigationState) {
    state.backToNavigation = navigationState;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU_CATEGORY](state, originSubmenuIndex) {
    state.originSubmenuIndex = originSubmenuIndex;
  },

  [MUTATIONS_CONSTANTS.SET_DRAWER_IS_OPENED](state, drawerState) {
    state.isDrawerOpened = drawerState;
  },

  [MUTATIONS_CONSTANTS.SET_APPLICATION_CONFIG](state, config) {
    state.applicationConfig = config;
    state.applicationConfigLoaded = true;
  },

  [MUTATIONS_CONSTANTS.SET_APPLICATION_CONFIG_LAST_CHECK](state) {
    let date = new Date();
    let timestamp = Number(date);
    state.applicationConfigLastCheck.date = date;
    state.applicationConfigLastCheck.timestamp = timestamp;
  },

  [MUTATIONS_CONSTANTS.SET_SURVEY_CONFIG](state, surveyConfig) {
    state.surveyConfig = surveyConfig;
  },

  [MUTATIONS_CONSTANTS.SET_NAVIGATION_DATA](state, navigationData) {
    state.navigationData = navigationData;
    state.navigationDataLoaded = true;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_BUTTONS](state, buttons) {
    state.dashboardButtons = buttons;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_GROUP_BUTTONS](state, buttons) {
    state.dashboardGroupButtons = buttons;
  },

  [MUTATIONS_CONSTANTS.SET_APP_VERSION](state, version) {
    state.appVersion = version;
  },

  [MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_STATUS](state, status) {
    state.pushNotificationsSubscribed = status;
  },

  [MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_ENDPOINT](state, endpoint) {
    state.pushNotificationsEndpoint = endpoint;
  },

  [MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_PERMISSION](state, permission) {
    state.pushNotificationsPermission = permission;
  },

  [MUTATIONS_CONSTANTS.SET_NETWORK_ERROR](state, status) {
    state.networkError = status;
    if(!status) {
      state.networkErrorLastDismiss = new Date().getTime() / 1000
    }
  },
  [MUTATIONS_CONSTANTS.SET_STATISTICS_TIMESTAMP](state, payload) {
    state.statistics.timestamps[payload.module] = payload.timestamp;
  },
  [MUTATIONS_CONSTANTS.SET_VERSION_ON_SERVER](state, version) {
    state.appVersionOnServer = version;
  },
  [MUTATIONS_CONSTANTS.SET_APP_CHANGELOG](state, changelog) {
    state.appChangelog = changelog;
  },
  [MUTATIONS_CONSTANTS.SET_COVID19_ACTIVE_CATEGORY](state, activeCategory) {
    state.covid19DashboardActiveCategory = activeCategory;
  },
  [MUTATIONS_CONSTANTS.START_PROCESS](state, processId) {
    state.processesRunning.push(processId);
  },
  [MUTATIONS_CONSTANTS.STOP_PROCESS](state, processId) {
    const loadingIdPosition = state.processesRunning.indexOf(processId);
    if (loadingIdPosition > -1) {
      state.processesRunning.splice(loadingIdPosition, 1);
    } else {
      logger.error(`Loading ID ${processId} does not exist!`);
    }
  },
  [MUTATIONS_CONSTANTS.SET_DASHBOARD_BUTTONS_EXPANDED](state, expanded) {
    state.dashboardButtonsExpanded = expanded;
  },
  [MUTATIONS_CONSTANTS.SET_DASHBOARD_BUTTONS_EXPANDED_WIDTH](state, width) {
    state.dashboardButtonsExpandedWidth = width;
  },
  [MUTATIONS_CONSTANTS.SET_SEARCH_QUERY](state, searchQuery) {
    state.searchQuery = searchQuery;
  },
  [MUTATIONS_CONSTANTS.SET_SEARCH_TOGGLE_STATUS](state, status) {
    state.isSearchToggleActive = status;
  },
  [MUTATIONS_CONSTANTS.SET_SEARCH_STATUS](state, status) {
    state.isSearchActive = status;
  },
  [MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS](state, status) {
    state.isSearchHistoryActive = status;
  },
  [MUTATIONS_CONSTANTS.SET_SEARCH_RESULTS_DATA](state, results) {
    state.searchResultData = results;
  },
  [MUTATIONS_CONSTANTS.SET_SEARCH_RESULTS_COUNT](state, resultsCount) {
    state.searchResultCount = resultsCount;
  },
  [MUTATIONS_CONSTANTS.SET_SEARCH_RESULTS_FULL_DATA](state, results) {
    state.searchResultFullData = results;
  },
};

const actions = {
  [ACTIONS_CONSTANTS.TOGGLE_DASHBOARD_MENU]({state, commit}) {
    let menuState = state.isMenuOpened;

    commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU, menuState = !menuState);
  },

  [ACTIONS_CONSTANTS.TOGGLE_DASHBOARD_SUBMENU]({state, commit}) {
    let submenuState = state.isSubmenuOpened;

    commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU, submenuState = !submenuState);
  },

  [ACTIONS_CONSTANTS.SET_NEW_VERSION_INSTALLED]({ state }) {
    let newVersion = state.appVersion;
    return dataLoader.postAppRelease(newVersion);
  },

  [ACTIONS_CONSTANTS.GET_APPLICATION_SETTINGS]({ state, commit }, options) {
    let dashboardButtons = [];
    let dashboardGroupButtons = [];
    let currentDateTime = new Date();
    let checkInterval = globalConfig.CONTENT_DATA_UPDATE_CHECK_INTERVAL;
    if ((currentDateTime - state.applicationConfigLastCheck.timestamp) < checkInterval && !options.force) {
      return false;
    }

    commit(MUTATIONS_CONSTANTS.SET_APPLICATION_CONFIG_LAST_CHECK);

    return dataLoader.getApiAppSettings()
      .then(applicationConfig => {
        commit(MUTATIONS_CONSTANTS.SET_APPLICATION_CONFIG, applicationConfig);

        if (!applicationConfig.hasOwnProperty('localities')) {
          return false;
        }

        // TODO MSE: check this, probably will not always work, left here for explanation - state in actions is copy not reference
        // const navigationIdIndex = state.applicationConfig.localities.findIndex(item => item.id === options.selectedBuilding);
        // const navigationId = state.applicationConfig.localities[navigationIdIndex].navigationId;
        let navigationIdIndex = 0;
        navigationIdIndex = applicationConfig.localities.findIndex(item => item.id === options.selectedBuilding);
        if(navigationIdIndex < 0) {
          navigationIdIndex = 0;
        }
        const navigationId = applicationConfig.localities[navigationIdIndex].navigationId;

        return dataLoader.getMenuNavigationData(navigationId)
          .then(data => {
            const sortedNavigationDataByAlphabetASC = [];
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(data)) {
              sortedNavigationDataByAlphabetASC.push(value);
            }
            sortedNavigationDataByAlphabetASC.sort((a, b) => a.name.localeCompare(b.name));

            commit(MUTATIONS_CONSTANTS.SET_NAVIGATION_DATA, sortedNavigationDataByAlphabetASC);

            data.forEach(item => {
              if (typeof item.children !== 'undefined') {
                item.children.forEach(childrenItem => {
                  if (childrenItem.onDashboard === '1') {
                    dashboardButtons.push(childrenItem);
                  }
                })
              }

              if (item.onDashboard === '1') {
                let filteredObject = Object.assign({}, item);
                delete filteredObject.children;

                dashboardButtons.push(filteredObject);
              }
            });
          })
          .finally(() => {
            let dashboardGroups = [];
            dashboardButtons.forEach(dashButton => {
              if (state.appDestination === APP_DESTINATIONS.PRIVATE) {
                if (dashButton.groupDashboard !== null && dashButton.groupDashboard !== '') {
                  if (dashboardGroups.indexOf(dashButton.groupDashboard) === -1) {
                    dashboardGroups.push(dashButton.groupDashboard);
                  }
                  dashboardGroupButtons.push(dashButton);
                }
              } else {

                if (dashButton.groupDashboard !== null && dashButton.groupDashboard !== '' && dashButton.privateOnly === '0') {
                  if (dashboardGroups.indexOf(dashButton.groupDashboard) === -1) {
                    dashboardGroups.push(dashButton.groupDashboard);
                  }
                  dashboardGroupButtons.push(dashButton);
                }
              }
            });

            if (dashboardGroups.length < 1) {
              dashboardGroupButtons = [];
            } else {
              let newDashboardButtons = [];
              dashboardButtons.forEach(dashButton2 => {
                if (!dashboardGroupButtons.find(({ name }) => name === dashButton2.name)) {
                  newDashboardButtons.push(dashButton2);
                }
              });
              dashboardButtons = [ ...newDashboardButtons ];

              let newDashboardGroupButtons = {};
              dashboardGroups.forEach(group => {
                newDashboardGroupButtons[group] = {
                  items: []
                };
              });
              dashboardGroupButtons.forEach(dashButton3 => {
                if (typeof newDashboardGroupButtons[dashButton3.groupDashboard] !== 'undefined') {
                  newDashboardGroupButtons[dashButton3.groupDashboard].items.push(dashButton3);
                }
              });

              Object.entries(newDashboardGroupButtons).forEach(([key, val]) => {
                if (val.items.length === 1) {
                  val.items.forEach(item => {
                    dashboardButtons.push(item);
                  })
                  delete newDashboardGroupButtons[key];
                }
              });

              dashboardGroupButtons = newDashboardGroupButtons;
            }

            commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_BUTTONS, dashboardButtons);
            commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_GROUP_BUTTONS, dashboardGroupButtons);
          })
      })
  },

  [ACTIONS_CONSTANTS.SUBSCRIBE_PUSH_NOTIFICATIONS]({ state, commit }) {
    if(!state.swRegistration || !state.swRegistration.pushManager) {
      commit(MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_STATUS, false);
      logger.info('Push manager not supported, nothing to do.');
      return Promise.resolve();
    }
    const applicationServerKey = utilsGeneral.urlB64ToUint8Array(globalConfig.PUSH_NOTIFICATIONS_PUBLIC_KEY);
    return state.swRegistration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      })
      .then(function(subscription) {
        commit(MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_STATUS, true);
        commit(MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_ENDPOINT, subscription.endpoint);
        return apiClient.subscribeOnServer(subscription);
      })
      .catch(function(error) {
        commit(MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_STATUS, false);
        logger.error(error);
        return Promise.reject();
      })
      .finally(() => {
        commit(MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_PERMISSION, Notification.permission);
      });
  },

  [ACTIONS_CONSTANTS.UNSUBSCRIBE_PUSH_NOTIFICATIONS]({ state, commit }) {
    if(!state.swRegistration || !state.swRegistration.pushManager) {
      logger.info('Push manager not supported, nothing to do.');
      return Promise.resolve();
    }
    return state.swRegistration.pushManager
      .getSubscription()
      .then(function(subscription) {
        if (subscription) {
          return subscription.unsubscribe();
        } else {
          return Promise.reject();
        }
      })
      .then(function() {
        let pushEndpoint = state.pushNotificationsEndpoint;
        commit(MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_STATUS, false);
        commit(MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_ENDPOINT, null);
        return apiClient.unsubscribeOnServer(pushEndpoint);
      })
      .catch(function(error) {
        logger.error('Error in unsubscription of push notifications.', error);
      });
  },

  [ACTIONS_CONSTANTS.TOGGLE_PUSH_NOTIFICATIONS]({ dispatch }, active) {
    if (active) {
      return dispatch(ACTIONS_CONSTANTS.SUBSCRIBE_PUSH_NOTIFICATIONS)
    } else {
      return dispatch(ACTIONS_CONSTANTS.UNSUBSCRIBE_PUSH_NOTIFICATIONS);
    }
  },

  [ACTIONS_CONSTANTS.LOAD_PUSH_NOTIFICATIONS_STATUS]({ state, commit }) {
    if(state.swRegistration) {
      if(!state.swRegistration || !state.swRegistration.pushManager) {
        commit(MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_STATUS, false);
        return Promise.reject('Push manager not supported.')
      }
      return state.swRegistration.pushManager
        .getSubscription()
        .then(function (subscription) {
          commit(MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_STATUS, !!subscription);
          if(subscription) {
            commit(MUTATIONS_CONSTANTS.SET_PUSH_NOTIFICATIONS_ENDPOINT, subscription.endpoint);
          }
        });
    }
    return Promise.resolve();
  },

  [ACTIONS_CONSTANTS.OPEN_DASHBOARD_NAVIGATION]({ commit }) {
    commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU, true);
  },

  [ACTIONS_CONSTANTS.CLOSE_DASHBOARD_NAVIGATION]({ commit }) {
    commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU, false);
    commit(MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU, false);
  },

  [ACTIONS_CONSTANTS.FETCH_NEW_VERSION_INFO]({ commit }) {
    apiClient.fetchVersionFromServer()
      .then(data => {
        commit(MUTATIONS_CONSTANTS.SET_VERSION_ON_SERVER, data.appVersion);
        return apiClient.fetchChangelog(data.appVersion)
      })
      .then(changelog => {
        if(changelog && changelog.description) {
          commit(MUTATIONS_CONSTANTS.SET_APP_CHANGELOG, changelog.description);
        } else {
          commit(MUTATIONS_CONSTANTS.SET_APP_CHANGELOG, null);
        }
      }).catch(error => {
        logger.error(error);
        commit(MUTATIONS_CONSTANTS.SET_VERSION_ON_SERVER, null);
      })
  },

  [ACTIONS_CONSTANTS.MAKE_SURVEY_CONFIG]({ state, commit }) {
    const surveyKeyName = APP_CONSTANTS.SURVEY_KEY_PREFIX;
    let surveyConfigArray = [];
    let surveyConfigObject = {};

    surveyConfigArray = Object.values(state.applicationConfig.customFields).filter(item => item.key.includes(surveyKeyName));

    surveyConfigArray.forEach(configItem => {
      surveyConfigObject[configItem.key] = {
        type: configItem.type,
        value: configItem.value
      };
    })

    commit(MUTATIONS_CONSTANTS.SET_SURVEY_CONFIG, surveyConfigObject);
  }
};

const getters = {
  [GETTERS_CONSTANTS.PUSH_BROWSER_SUPPORT]: state => {
    return !!(state.swRegistration && 'PushManager' in window);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
