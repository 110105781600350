export const WIDGET_NEWS = {
  title: 'general.widgetNews',
  destination: {
    name: 'news-list',
    params: null
  },
  iconColor: '#3B9C28',
  bgColor: '#E2F2DF',
  iconSVG: '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<rect x="8.32895" y="6" width="24" height="28" rx="4" stroke="currentColor" stroke-width="2.67"/>\n' +
    '<path d="M13.5837 12.9741H26.9289" stroke="currentColor" stroke-width="2.67" stroke-linecap="square"/>\n' +
    '<path d="M13.5837 19.9741H26.9289" stroke="currentColor" stroke-width="2.67" stroke-linecap="square"/>\n' +
    '<path d="M13.5867 26.9741H20.416" stroke="currentColor" stroke-width="2.67" stroke-linecap="square"/>\n' +
    '</svg>'
}

export const WIDGET_PROMOTED = {
  title: 'general.widgetRecommended',
  destination: {
    name: 'promoted-list',
    params: null
  },
  iconColor: '#376DBF',
  bgColor: '#E3EEFF',
  iconSVG: '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M28.7 31.7252L25.442 33.4322C25.3453 33.4825 25.2365 33.505 25.1278 33.4971C25.0191 33.4892 24.9148 33.4512 24.8264 33.3874C24.738 33.3236 24.6691 33.2365 24.6274 33.1358C24.5856 33.0351 24.5727 32.9248 24.59 32.8172L25.2125 29.2007L22.577 26.6402C22.4983 26.5642 22.4426 26.4675 22.4163 26.3614C22.3899 26.2552 22.3939 26.1437 22.4278 26.0397C22.4618 25.9357 22.5243 25.8433 22.6082 25.7731C22.6921 25.7029 22.7941 25.6577 22.9025 25.6427L26.5445 25.1147L28.1735 21.8252C28.2222 21.7272 28.2973 21.6448 28.3903 21.5872C28.4833 21.5296 28.5906 21.499 28.7 21.499C28.8094 21.499 28.9167 21.5296 29.0097 21.5872C29.1027 21.6448 29.1778 21.7272 29.2265 21.8252L30.8555 25.1147L34.4975 25.6427C34.6056 25.6583 34.7071 25.7037 34.7907 25.774C34.8742 25.8443 34.9365 25.9365 34.9703 26.0403C35.0042 26.1441 35.0083 26.2553 34.9823 26.3613C34.9563 26.4673 34.9011 26.5639 34.823 26.6402L32.1875 29.2007L32.8085 32.8157C32.8271 32.9235 32.8152 33.0344 32.774 33.1357C32.7327 33.2371 32.664 33.3248 32.5754 33.389C32.4868 33.4532 32.382 33.4913 32.2729 33.499C32.1637 33.5066 32.0546 33.4835 31.958 33.4322L28.7 31.7252Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M11.3 31.7252L8.04198 33.4322C7.9453 33.4825 7.83653 33.505 7.72782 33.4971C7.61911 33.4892 7.51474 33.4512 7.42637 33.3874C7.33799 33.3236 7.26908 33.2365 7.22734 33.1358C7.1856 33.0351 7.17266 32.9248 7.18998 32.8172L7.81248 29.2007L5.17698 26.6402C5.09832 26.5642 5.04262 26.4675 5.01624 26.3614C4.98987 26.2552 4.99388 26.1437 5.02783 26.0397C5.06177 25.9357 5.12427 25.8433 5.2082 25.7731C5.29213 25.7029 5.3941 25.6577 5.50248 25.6427L9.14448 25.1147L10.7735 21.8252C10.8222 21.7272 10.8973 21.6448 10.9903 21.5872C11.0833 21.5296 11.1906 21.499 11.3 21.499C11.4094 21.499 11.5166 21.5296 11.6097 21.5872C11.7027 21.6448 11.7778 21.7272 11.8265 21.8252L13.4555 25.1147L17.0975 25.6427C17.2055 25.6583 17.3071 25.7037 17.3907 25.774C17.4742 25.8443 17.5365 25.9365 17.5703 26.0403C17.6042 26.1441 17.6083 26.2553 17.5823 26.3613C17.5563 26.4673 17.5011 26.5639 17.423 26.6402L14.7875 29.2007L15.4085 32.8157C15.4271 32.9235 15.4152 33.0344 15.3739 33.1357C15.3327 33.2371 15.2639 33.3248 15.1754 33.389C15.0868 33.4532 14.982 33.4913 14.8729 33.499C14.7637 33.5066 14.6546 33.4835 14.558 33.4322L11.3 31.7252Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M20 16.7252L16.742 18.4322C16.6453 18.4825 16.5366 18.505 16.4278 18.4971C16.3191 18.4892 16.2148 18.4512 16.1264 18.3874C16.038 18.3236 15.9691 18.2365 15.9274 18.1358C15.8856 18.0351 15.8727 17.9248 15.89 17.8172L16.5125 14.2007L13.877 11.6402C13.7983 11.5642 13.7426 11.4675 13.7163 11.3614C13.6899 11.2552 13.6939 11.1437 13.7279 11.0397C13.7618 10.9357 13.8243 10.8433 13.9082 10.7731C13.9922 10.7029 14.0941 10.6577 14.2025 10.6427L17.8445 10.1147L19.4735 6.82522C19.5222 6.72724 19.5973 6.64481 19.6903 6.58718C19.7833 6.52955 19.8906 6.49902 20 6.49902C20.1094 6.49902 20.2167 6.52955 20.3097 6.58718C20.4027 6.64481 20.4778 6.72724 20.5265 6.82522L22.1555 10.1147L25.7975 10.6427C25.9056 10.6583 26.0071 10.7037 26.0907 10.774C26.1742 10.8443 26.2365 10.9365 26.2703 11.0403C26.3042 11.1441 26.3084 11.2553 26.2823 11.3613C26.2563 11.4673 26.2011 11.5639 26.123 11.6402L23.4875 14.2007L24.1085 17.8157C24.1271 17.9235 24.1152 18.0344 24.074 18.1357C24.0328 18.2371 23.964 18.3248 23.8754 18.389C23.7868 18.4532 23.682 18.4913 23.5729 18.499C23.4637 18.5066 23.3547 18.4835 23.258 18.4322L20 16.7252Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '</svg>'
}

export const WIDGET_EVENTS = {
  title: 'general.widgetAwait',
  destination: {
    name: 'coming-list',
    params: null
  },
  iconColor: '#E75A2B',
  bgColor: '#F9E7E1',
  iconSVG: '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M14.829 12.5002L32.329 12.5557" stroke="currentColor" stroke-width="2.92" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<circle cx="8.82898" cy="12.5" r="1.5" fill="currentColor"/>\n' +
    '<path d="M14.829 19.5002H25.829" stroke="currentColor" stroke-width="2.92" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<circle cx="8.82898" cy="19.5" r="1.5" fill="currentColor"/>\n' +
    '<path d="M14.829 26.5002H21.829" stroke="currentColor" stroke-width="2.92" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<circle cx="8.82898" cy="26.5" r="1.5" fill="currentColor"/>\n' +
    '</svg>'
}

export const WIDGET_DIRECTORY = {
  title: 'general.widgetDirectory',
  destination: {
    name: 'directory-list',
    params: null
  },
  iconColor: '#fccf35',
  bgColor: '#fff4cc',
  iconSVG: '<svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<circle cx="7" cy="7" r="3" fill="#001F3A"/>' +
    '<circle cx="17" cy="7" r="3" fill="#001F3A"/>' +
    '<circle cx="7" cy="17" r="3" fill="#001F3A"/>' +
    '<circle cx="17" cy="17" r="3" fill="#001F3A"/>' +
    '</svg>'
}
