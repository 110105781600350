<template>
  <div
    class="vue-event-list-item"
    @click="goToItemDetail(itemData)"
  >
    <div
      v-if="itemData.image"
      class="vue-event-list-item-image-wrapper"
    >
      <div
        v-lazy:background-image="imageUrl"
        class="vue-event-list-item-image"
      />
    </div>

    <div
      v-else
      class="vue-event-list-item-image"
    />

    <div class="vue-event-list-item-info">
      <h3
        class="vue-event-list-item-name"
        v-html="itemData.name"
      >
      </h3>
      <div
        class="vue-event-list-item-metainfo"
        :class="metaClassObject(itemData.spots)"
      >
        <span
          v-if="itemData.categories.length"
          class="vue-event-list-item-tag"
        >
          {{ itemData.categories[0].name }}
          <template
            v-if="itemData.categories.length > 1"
          >+{{ itemData.categories.length - 1 }}</template>

        </span>
        <span
          class="vue-event-list-item-time"
          :class="classObject"
          v-html="itemActivityFromConverted(itemData.activityFrom)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as envConfig from 'env-config';
import router from '@/router';
import Vue from 'vue';
import { EVENT_SPOT_ONLINE } from '@/constants/app-constants';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'Prj1013EventListItem',

  props: {
    itemData: {
      type: [Object, Array],
      required: true,
    }
  },

  computed: {
    classObject() {
      return {
        'vue-event-list-item-tag-hidden': !this.itemData.categories.length
      }
    },

    cmsUrl() {
      return envConfig.default.webServices.BASE_CMS_URL;
    },

    imageUrl() {
      return this.itemData.image ? `${this.cmsUrl}${this.itemData.image}` : ''
    },
  },

  methods: {
    goToItemDetail(itemData) {
      // save to store
      this.$store.commit(STORE_MODULES.EVENTS + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, itemData);
      router.push({ name: 'event-detail', params: { id: itemData.id, origin: this.$route.name } });
    },

    itemActivityFromConverted(activityFrom) {
      return this.$i18n.t('time.hourShort', { hours: (Vue.filter('convertDate')(activityFrom, 'exactHours')) });
    },

    metaClassObject(spots) {
      return {
        'vue-icon vue-is-online': this.arrayContains(spots, EVENT_SPOT_ONLINE)
      }
    },

    // FIXME: Why does array have a simple parameter name? This reduces method readability.
    arrayContains(a, obj) {
      let i = a.length;
      while (i--) {
        if (a[i].name === obj.toLowerCase()) {
          return true;
        }
      }
      return false;
    }
  }
}
</script>
