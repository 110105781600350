import * as MUTATIONS_CONSTANTS from '../constants/mutations';

// ========================================================= //
// == MODULE FOR AUTHORIZED MODE ONLY                     == //
// ========================================================= //
const state = {
  onboardingExternalCandidates: {},
  onboardingExternalCandidatesLoaded: false,
  onboardingFilteredInterval: {
    startDate: '',
    endDate: ''
  },
  onboardingApplicants: [],
  onboardingApplicantsFilter: {
    department: '',
    startDate: '',
    status: '',
  },
  onboardingApplicantsLoaded: false,
  onboardingSelectedUserRole: null,
  onboardingSelectedUserMode: null,
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_ONBOARDING_EXTERNAL_CANDIDATES](state, candidates) {
    state.onboardingExternalCandidates = candidates;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_EXTERNAL_CANDIDATES_LOADED](state, loadedState) {
    state.onboardingExternalCandidatesLoaded = loadedState;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_FILTERED_INTERVAL](state, interval) {
    state.onboardingFilteredInterval.startDate = interval.startDate;
    state.onboardingFilteredInterval.endDate = interval.endDate;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_APPLICANTS](state, applicants) {
    state.onboardingApplicants = applicants;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_APPLICANTS_LOADED](state, loadedState) {
    state.onboardingApplicantsLoaded = loadedState;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_APPLICANTS_FILTER](state, list) {
    state.onboardingApplicantsFilter = list;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_USER_ROLE](state, role) {
    state.onboardingSelectedUserRole = role;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_USER_MODE](state, mode) {
    state.onboardingSelectedUserMode = mode;
  },
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
