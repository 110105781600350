/* eslint-disable no-console */

const DEBUG_ENABLED = globalConfig.ENV_DEBUG;
const CONSOLE_LOGGING = globalConfig.ENV_CONSOLE_LOG;

export default {
  infoMessages: [],
  warningMessages: [],
  errorMessages: [],

  _logToConsole(msg, relatedObject, isError) {
    if(CONSOLE_LOGGING) {
      if (isError) {
        console.error(msg);
      } else {
        console.log(msg);
      }
      if (relatedObject) {
        console.log(relatedObject);
      }
    }
  },

  info(msg, relatedObject) {
    if(DEBUG_ENABLED) {
      this.infoMessages.push({
        message: msg,
        related: relatedObject
      });
      this._logToConsole(msg, relatedObject, false);
    }
  },

  warn(msg, relatedObject) {
    if(DEBUG_ENABLED) {
      this.warningMessages.push({
        message: msg,
        related: relatedObject
      });
      this._logToConsole(msg, relatedObject, false);
    }
  },

  error(msg, relatedObject) {
    if(DEBUG_ENABLED) {
      this.errorMessages.push({
        message: msg,
        related: relatedObject
      });
      this.errorMessages.push(msg);
      this._logToConsole(msg, relatedObject, true);
    }
  }
}
