import Gen1002Heading from './gen1002-heading';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1002Heading);
    }
};

use(Plugin);

export default Plugin;

export { Gen1002Heading };
