<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:content>
          <div class="prj-layout-form">
            <div class="pt-8 xs:pt-20">
              <!--========== IMAGE ==================================-->
              <!--===================================================-->
              <div class="flex justify-center xs:mb-4 text-fest-home">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-48 w-48"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>

              <!--========== HEADLINE ===============================-->
              <!--===================================================-->
              <t-tag
                variant="sectionTitle"
                class="xs:mt-6 text-center"
              >
                {{ $t('fest.forms.headline.successForm') }}
              </t-tag>

              <p class="text-center">{{ formContent }}</p>
            </div>

            <!--========== CONTROLS ===============================-->
            <!--===================================================-->
            <div class="prj-controls">
              <t-button
                class="w-full mb-4 bg-fest-home"
                @click="toPage(routePrimaryName)"
              >
                {{ namePrimaryButton }}
              </t-button>

              <t-button
                v-if="nameSecondaryButton"
                class="w-full"
                variant="secondary"
                @click="toPage(routeSecondaryName)"
              >
                {{ nameSecondaryButton }}
              </t-button>
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { ROUTE_DASHBOARD } from '@/constants/fest/route-names'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import PageLayout from '@/templates/fest/page-layout'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTSuccess',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout,
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {}
  },
  computed: {
    isPrimaryBackRoute() {
      return this.$route.meta.primaryButtonOverride || (this.$route.meta.primaryButtonRoute || this.competitionId)
    },
    formContent() {
      return this.$route.meta.content ? this.$route.meta.content : ''
    },
    routePrimaryName() {
      return this.isPrimaryBackRoute ? this.$route.meta.primaryButtonRoute : ROUTE_DASHBOARD
    },
    namePrimaryButton() {
      return this.isPrimaryBackRoute ? this.$route.meta.primaryButton : this.$t('fest.forms.buttons.buttonBack')
    },
    routeSecondaryName() {
      return this.$route.meta.secondaryButtonRoute
    },
    nameSecondaryButton() {
      return this.$route.meta.secondaryButton
    },
    routeParams() {
      return this.$route.params
    },
    competitionId() {
      return this.$route.params.competitionId
    },
  },
  methods: {
    toPage(routeName) {
      if (this.isPrimaryBackRoute) {
        this.$router.push({ name: routeName })
      } else {
        this.$router.push({ name: routeName, params: this.routeParams })
      }
    },
  },
}
</script>
