export const DATA_ARRAY = Object.freeze({
  currentObjectives: [
    {
      'cs': {
        'title': 'Naše hlavní letošní cíle ',
        'items': {
          1: {
            'value': '',
            'description': 'Získat 1,2 miliónu uživatelů mobilní aplikace Smart.',
            'detailId': 5,
            'attachmentData': {
              'start': '743 tis. na začátku roku',
              'end': '1,2. mil. na konci roku',
              'current': 'Aktuálně 1 milion uživatelů'
            }
          },
          2: {
            'value': '50 % aktivních uživatelů',
            'description': 'aplikace ČSOB Smart používá Kate.<span class="vue-audience-value-ext">Aktuálně 36 %.</span>',
            'detailId': 3
          },
          3: {
            'value': '60 % prodeje v Retailu',
            'description': 'probíhá digitálně.<span class="vue-audience-value-ext">Aktuálně 42 %.</span>',
            'detailId': 5
          },
          4: {
            'value': '40 % prodeje jednoduchých produktů',
            'description': 've Vztahovém bankovnictví probíhá digitálně.<span class="vue-audience-value-ext">Aktuálně 30-40%.</span>',
            'detailId': 5
          },
          5: {
            'value': '24 % prodeje Retailu',
            'description': 'se uskuteční díky Lead Managementu.<span class="vue-audience-value-ext">Aktuálně 14,7 %.</span>',
            'detailId': 4
          },
          6: {
            'value': '46,3 % prodejů',
            'description': ' digitálně nabízených produktů proběhne automatizovaně (straight through).<span class="vue-audience-value-ext">Aktuálně 44,8 %.</span>',
            'detailId': 7
          },
          7: {
            'value': 'Rozvíjíme klíčové dovednosti',
            'description': 'a máme angažované lidi.' +
              '<span class="vue-audience-value-ext">Aktuální informace najdete v ' +
              '<a href="/strategy/detail/1/cs" class="vue-audience-list-value">My v ČSOB</a>.</span>',
            'detailId': 1
          },
          8: {
            'value': 'Dbáme na udržitelnost,',
            'description': ' rozšíříme produktovou nabídku ESG a financování energeticky prospěšných projektů.' +
              '<span class="vue-audience-value-ext">Aktuální informace najdete v části ' +
              '<a href="/strategy/detail/2/cs" class="vue-audience-list-value">Naše udržitelná obchodní řešení</a>.</span>',
            'detailId': 2
          },
        }
      },
      'en': {
        'title': 'Our main goals 2022',
        'items': {
          1: {
            'value': '',
            'description': '1.2m mobile users.',
            'detailId': 5,
            'attachmentData': {
              'start': '743 ths. at the beginning of the year',
              'end': '1.2 million at the end of the year',
              'current': 'Currently 1 million users'
            }
          },
          2: {
            'value': 'Kate ',
            'description': 'used by 50% of active Smart users.<span class="vue-audience-value-ext">Currently 36%.</span>',
            'detailId': 3
          },
          3: {
            'value': 'E2E digital sales',
            'description': 'in RET – 60%.<span class="vue-audience-value-ext">Currently 42%.</span>',
            'detailId': 5
          },
          4: {
            'value': '40% digital sales',
            'description': ' of simple products in RS.<span class="vue-audience-value-ext">Currently 30-40%.</span>',
            'detailId': 5
          },
          5: {
            'value': '24%',
            'description': ' Lead Management share on RET sales.<span class="vue-audience-value-ext">Currently 14.7%.</span>',
            'detailId': 4
          },
          6: {
            'value': 'STP ratio 46.3%.',
            'description': '<span class="vue-audience-value-ext">Currently 44.8%.</span>',
            'detailId': 7
          },
          7: {
            'value': 'Keep our colleagues engaged.',
            'description': '<span class="vue-audience-value-ext">For current information, see ' +
              '<a href="/strategy/detail/1/en" class="vue-audience-list-value">We at ČSOB and Pearl+</a>.</span>',
            'detailId': 1
          },
          8: {
            'value': 'Expanding ESG’s product offering',
            'description': ', fund energy-saving projects and pay attention to sustainability.' +
              '<span class="vue-audience-value-ext">For current information, see ' +
              '<a href="/strategy/detail/2/en" class="vue-audience-list-value">Our Sustainable Business Solutions</a>.</span>',
            'detailId': 2
          },
        },
      }
    }
  ]
});
