<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1004-article-body
        :articleHeading="false"
        :articleData="articleData"
      />
    </template>
  </page-layout>
</template>

<script>
import Prj1002Navbar from '../../components/prj1002-navbar/prj1002-navbar';
import Prj1004ArticleBody from '@/components/prj1004-article-detail/prj1004-article-body';

import PageLayout from '../../templates/partials/page-layout';

import mxNavigationDefault from '../../mixins/mx-navigation-default';
import {mapState} from 'vuex';
import {SERVICES} from '@/store/store-modules';
import moduleStatistics from '@/mixins/mx-module-statistics';

export default {
  name: 'ServicesDetail',

  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1004ArticleBody,
  },

  mixins: [mxNavigationDefault, moduleStatistics],

  computed: {
    ...mapState('dataStore', [
      'contentData'
    ]),

    routeName() {
      return this.$route.name;
    },

    routeId() {
      return this.$route.params.id;
    },

    routeBack() {
      return this.$route.query.back;
    },

    articleData() {
      return this.contentData[this.routeName].data[this.routeId];
    },

    navbarCaption() {
      return this.articleData.title;
    },

    navbarButtonMode() {
      return this.routeBack ? 'history' : this.$route.meta.navbarButtonMode;
    },

    navbarButtonDestination() {
      return this.routeBack ? '-2' : this.$route.meta.navbarButtonDestination;
    }
  },

  mounted() {
    this.sendStatistics(SERVICES);
  },

  methods: {
    navigateBack() {
      this.$router.push('/dashboard');
    }
  }
}
</script>
