<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1013-event-list
        @handleLoader="handleLoader"
      />
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <prj1026-category-filter
        :filterCategories="convertedFilterCategories"
        :showItems="convertedFilterCategories.length"
        :showFilterButton="eventsSelectedCategories.length > 0"
        :activeCategory="activeCategory"
        filterType="forvardino"
        @filterItems="filterItems"
        @resetFilteredItems="resetFilteredEvents"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active.sync="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="eventSignupSuccess"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          v-if="(typeof registrationSuccessMessage !== 'undefined')"
          type="success"
        >
          {{ $t('actions.' + registrationSuccessMessage) }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="eventSignOutSuccess"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          v-if="(typeof registrationSuccessMessage !== 'undefined')"
          type="success"
        >
          {{ $t('actions.' + registrationSuccessMessage) }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '../../store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import EVENT_BUS from '@/event-bus';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1013EventList from '@/components/prj1013-event-list/prj1013-event-list';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

export default {
  name: 'Events',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1013EventList,
    Prj1026CategoryFilter,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      eventSignupSuccess: false,
      eventSignOutSuccess: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
    }
  },

  computed: {
    ...mapState('events', [
      'eventsCategories',
      'eventsSelectedCategories'
    ]),

    convertedFilterCategories() {
      let convertedFilterCategories = [];
      Object.values(this.eventsCategories).forEach(item => {
        convertedFilterCategories.push({ 'termId': item.catId, 'termName': item.catName });
      })
      return convertedFilterCategories;
    },

    activeCategory() {
      if (this.eventsSelectedCategories.length === 1) {
        return parseInt(this.eventsSelectedCategories[0]);
      } else {
        return 0;
      }
    },

    registrationSuccessMessage() {
      return this.$route.params.registrationMessage;
    },
  },

  watch: {
    eventsCategories() {
      this.$store.commit(STORE_MODULES.POPUP + '/' + MUTATIONS_CONSTANTS.SET_CATEGORIES, this.convertedFilterCategories);
    }
  },

  mounted() {
    EVENT_BUS.$on(GLOBAL_EVENTS.FILTER_ITEM_LIST, categoryId => {
      this.filterItems(categoryId);
    });
    this.handleSuccessModal();
  },

  beforeDestroy() {
    EVENT_BUS.$off(GLOBAL_EVENTS.FILTER_ITEM_LIST);
  },

  methods: {
    handleLoader(loaderState) {
      this.isLoading = loaderState;
    },

    /**
     * @param categoryNumber number, $emitted from Prj1026CategoryFilter
     */
    filterItems(categoryNumber) {
      this.$store.commit(STORE_MODULES.EVENTS + '/' + MUTATIONS_CONSTANTS.SET_FILTERED_CATEGORIES, [categoryNumber]);
    },

    resetFilteredEvents() {
      this.$store.dispatch(STORE_MODULES.EVENTS + '/' + ACTIONS_CONSTANTS.RESET_ALL_EVENTS);
    },

    handleSuccessModal() {
      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'successfullRegistration')) {
        // FIXME: CSOBZ-521 - remove nextTick due the bug in the automatic modal window closing
        this.$nextTick(() => {
          this.eventSignupSuccess = true;
        });
      } else if (Object.prototype.hasOwnProperty.call(this.$route.params, 'successfullUnRegistration')) {
        // FIXME: CSOBZ-521 - remove nextTick due the bug in the automatic modal window closing
        this.$nextTick(() => {
          this.eventSignOutSuccess = true;
        });
      }
    },
  },
}
</script>
