<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-recruitment-dashboard">
        <div>
          <prj1017-heading
            :header="$i18n.t('recruitment.headlineRecruitment')"
          />

          <gen1006-info-panel
            type="neutral"
          >
            <p v-html="$i18n.t('recruitment.noteRecruitmentDashboard')" />
          </gen1006-info-panel>
        </div>
        <div class="vue-dashboard-tiles">
          <frm1006-button
            class="vue-dashboard-item-tile vue-tile-search"
            type="tile"
            @buttonClickEvent="goToSearch()"
          >
            <template v-slot:iconLeft>
              <div
                class="vue-tile-icon"
              />
            </template>
            {{ $t('recruitment.search') }}
          </frm1006-button>

          <frm1006-button
            class="vue-dashboard-item-tile vue-tile-requests"
            type="tile"
            @buttonClickEvent="goToMessages()"
          >
            <template v-slot:iconLeft>
              <div
                class="vue-tile-icon"
              />
            </template>
            {{ $t('recruitment.myRequests') }}
          </frm1006-button>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

export default {
  name: 'RecruitmentDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      completedForm: false,
      validationErrors: [],
      searchRequestSending: false,
      searchRequestFailed: false,
      hasFeaturedImage: true,
      locationsList: [],
      specializationsList: [],
      contractTypeList: [],
      recruitmentFormValues: {
        search: '',
        region: '',
        type: '',
        specialization: ''
      },
      recentMatches: 0,
      netConnection: false,
      sending: false
    }
  },

  computed: {
    ...mapState('user', [
      'deviceId'
    ]),

    ...mapState('recruitment', [
      'recruitment'
    ]),
  },

  watch: {
  },

  mounted() {
  },

  methods: {
    goToSearch() {
      this.$router.push({ name: 'recruitment-search'});
    },

    goToMessages() {
      this.$router.push({ name: 'messages', params: { module: 'recruitment', caption: '' }});
    }
  }
}
</script>
