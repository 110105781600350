export const DATA_ARRAY = Object.freeze({
  competition: {
    'cs': {
      'title': 'KVÍZ',
      'description': '<p><strong>Všechno jde zjednodušit. I naše strategie. Otestujte si svoje znalosti.</strong></p>' +
        '<p>100 účastníků se správnými odpověďmi získá voucher na 500 Kč do Alzy. Odměníme 10 nejrychlejších odpovědí a dále vylosujeme 90 výherců z ostatních účastníků.</p>' +
        '<p>Kvíz můžete vyplňovat až do 31. července.</p>',
      'items': {
        1: {
          'question': 'K čemu využíváme Lead Management?',
          'image': '',
          'answers': {
            1: {
              'answer': 'Aby klient dostal na svoji poptávku personalizovanou odpověď.',
              'truth': false
            },
            2: {
              'answer': 'Abychom klienta kontaktovali ve všech prodejních kanálech stejně.',
              'truth': false
            },
            3: {
              'answer': 'Aby ke klientovi dorazila správná zpráva ve správném kanálu a s optimálním načasováním.',
              'truth': true
            },
          }
        },
        2: {
          'question': 'Kdo je naším strategickým partnerem pro získávání talentů do oblastí IT/Data/Digital?',
          'image': '',
          'answers': {
            1: {
              'answer': 'Fajitas',
              'truth': false
            },
            2: {
              'answer': 'Czechitas',
              'truth': true
            },
            3: {
              'answer': 'Chickitas',
              'truth': false
            }
          }
        },
        3: {
          'question': 'Co chceme snížit do roku 2030 o více než 80 %?',
          'image': '',
          'answers': {
            1: {
              'answer': 'Vlastní emise skleníkových plynů',
              'truth': true
            },
            2: {
              'answer': 'Množství tištěných dokumentů',
              'truth': false
            },
            3: {
              'answer': 'Množství služebních cest ve prospěch online schůzek',
              'truth': false
            },
          }
        },
        4: {
          'question': 'V jakém poměru se Kate zaměřuje na prodej a na servisní úkony?',
          'image': '',
          'answers': {
            1: {
              'answer': 'Ze 20 % na prodej a ze 80 % na servisní úkony.',
              'truth': false
            },
            2: {
              'answer': 'Ze 40 % na prodej a ze 60 % na servisní úkony.',
              'truth': true
            },
            3: {
              'answer': 'Ze 60 % na prodej a ze 40 % na servisní úkony.',
              'truth': false
            },
          }
        },
        5: {
          'question': 'Jak se jmenuje naše skupinová datová a analytická platforma?',
          'image': '',
          'answers': {
            1: {
              'answer': 'Bell',
              'truth': false
            },
            2: {
              'answer': 'Tesla',
              'truth': false
            },
            3: {
              'answer': 'Edison',
              'truth': true
            }
          }
        }
      }
    },
    'en': {
      'title': 'QUIZ',
      'description': '<p><strong>Everything can be simplified. Even our strategies and objectives. See how well you know them.</strong></p>' +
        '<p>100 participants who answer correctly will win a CZK 500 voucher for Alza.cz. We will reward the 10 fastest answers and draw 90 more winners from all the other participants. </p>' +
        '<p>You can complete the quiz until the 16th of July.</p>',
      'items': {
        1: {
          'question': 'Why do we use Lead Management?',
          'image': '',
          'answers': {
            1: {
              'answer': 'To give clients personalised response to their enquiries.',
              'truth': false
            },
            2: {
              'answer': 'To contact clients in all sales channels the same way.',
              'truth': false
            },
            3: {
              'answer': 'To ensure we deliver to our clients the right message in the right channel and with optimal timing.',
              'truth': true
            },
          }
        },
        2: {
          'question': 'Who is our strategic partner for recruiting talents for IT/Data/Digital?',
          'image': '',
          'answers': {
            1: {
              'answer': 'Fajitas',
              'truth': false
            },
            2: {
              'answer': 'Czechitas',
              'truth': true
            },
            3: {
              'answer': 'Chickitas',
              'truth': false
            }
          }
        },
        3: {
          'question': 'What do we want to reduce by more than 80% by 2030?',
          'image': '',
          'answers': {
            1: {
              'answer': 'Own greenhouse emissions.',
              'truth': true
            },
            2: {
              'answer': 'The volume of printed documents.',
              'truth': false
            },
            3: {
              'answer': 'The number of business trips, to be replaced by online meetings.',
              'truth': false
            },
          }
        },
        4: {
          'question': 'What proportion of her workload does Kate allocate to sales and to service?',
          'image': '',
          'answers': {
            1: {
              'answer': '20% sales, 80% service.',
              'truth': false
            },
            2: {
              'answer': '40% sales, 60% service.',
              'truth': true
            },
            3: {
              'answer': '60% sales, 40% service.',
              'truth': false
            },
          }
        },
        5: {
          'question': 'What is the name of our group data and analytics platform?',
          'image': '',
          'answers': {
            1: {
              'answer': 'Bell',
              'truth': false
            },
            2: {
              'answer': 'Tesla',
              'truth': false
            },
            3: {
              'answer': 'Edison',
              'truth': true
            }
          }
        }
      }
    }
  }
});
