import Frm1004Radio from './frm1004-radio';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1004Radio);
    }
};

use(Plugin);

export default Plugin;

export { Frm1004Radio };
