<!-- FIXME MBU: article head is now news specific due to important category!-->
<template>
  <div
    class="vue-c-article-head"
    :class="classObject"
  >
    <div class="vue-b-article-head-image">
      <img
        v-if="hasFeaturedImage"
        class="vue-article-head-image"
        :src="articleData.featuredImage.full.src"
        :alt="articleData.title"
      >

      <div
        v-else
        class="vue-b-article-head-image-placeholder"
      />

      <div class="vue-article-head-badge-container">
        <!--========== WITHOUT IMPORTANT ======================-->
        <!--===================================================-->
        <template
          v-for="(category, index) in categoriesComputed"
        >
          <div
            v-if="index < displayedCategoriesCount"
            :key="'category-' + index"
            class="vue-article-head-badge"
            :class="[
              {
                'vue-is-important': category.id === newsImportantCategoryId
              }
            ]"
          >
            {{ category.name }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Prj1004ArticleHead',
  props: {
    articleData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      displayedCategoriesCount: 2
    }
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),
    classObject() {
      return [
        {
          'vue-has-featured-image':  this.hasFeaturedImage,
        }
      ];
    },

    hasFeaturedImage() {
      return typeof this.articleData.featuredImage !== 'undefined' && this.articleData?.featuredImage?.full?.src?.length > 0;
    },

    newsImportantCategoryId() {
      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'NEWS_IMPORTANT_CATEGORY_ID') {
            return item.value;
          }
        });
      }
      return -1;
    },

    important() {
      if (this.articleData.categories && this.articleData.categories.length > 0) {
        if (this.articleData.categories.filter(category => category.id === this.newsImportantCategoryId).length > 0) {
          return true;
        }
      }

      return false;
    },

    // FIXME MBU: article head is now news specific!
    // in other content types (pages/services) important category ID might be different category
    // move important category to first place
    categoriesComputed() {
      if (this.articleData.categories) {
        let categoriesComputed = [...this.articleData.categories];

        if (this.important) {
          let importantCategoryIndex = categoriesComputed.findIndex(category => category.id === this.newsImportantCategoryId);
          let importantCategoryItem = categoriesComputed[importantCategoryIndex];
          categoriesComputed.splice(importantCategoryIndex, 1);

          categoriesComputed.unshift(importantCategoryItem);
        }

        return categoriesComputed;
      }

      return [];
    }
  }
}
</script>
