export const DATA = Object.freeze({
  'SHQ': {
    'name': 'SHQ',
    'id': 'SHQ',
    'code': 'SHQ',
    'svgId': 'SHQ0',
    'floors': {
      'SHQ-1NP': {
        'name': '1. patro',
        'code': '1NP',
        'svgId': '1NP',
        'buildingId': 'SHQ',
        'id': 'SHQ-1NP',
        'rooms': {
          'SHQ-1NP-1S04': {
            'name': 'Výtah',
            'code': '1S04',
            'svgId': '1S04',
            'id': 'SHQ-1NP-1S04',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S05': {
            'name': 'Výtah',
            'code': '1S05',
            'svgId': '1S05',
            'id': 'SHQ-1NP-1S05',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S06': {
            'name': 'Výtah',
            'code': '1S06',
            'svgId': '1S06',
            'id': 'SHQ-1NP-1S06',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S41': {
            'name': 'Jednací místnost',
            'code': '1S41',
            'svgId': '1S41',
            'id': 'SHQ-1NP-1S41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S42': {
            'name': 'Jednací místnost',
            'code': '1S42',
            'svgId': '1S42',
            'id': 'SHQ-1NP-1S42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S43': {
            'name': 'Kancelář',
            'code': '1S43',
            'svgId': '1S43',
            'id': 'SHQ-1NP-1S43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S44': {
            'name': 'Jednací místnost',
            'code': '1S44',
            'svgId': '1S44',
            'id': 'SHQ-1NP-1S44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S47': {
            'name': 'Jednací místnost',
            'code': '1S47',
            'svgId': '1S47',
            'id': 'SHQ-1NP-1S47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S48': {
            'name': 'Jednací místnost',
            'code': '1S48',
            'svgId': '1S48',
            'id': 'SHQ-1NP-1S48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S49': {
            'name': 'Jednací místnost',
            'code': '1S49',
            'svgId': '1S49',
            'id': 'SHQ-1NP-1S49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S50': {
            'name': 'Jednací místnost',
            'code': '1S50',
            'svgId': '1S50',
            'id': 'SHQ-1NP-1S50',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S51': {
            'name': 'Jednací místnost',
            'code': '1S51',
            'svgId': '1S51',
            'id': 'SHQ-1NP-1S51',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S52': {
            'name': 'Jednací místnost',
            'code': '1S52',
            'svgId': '1S52',
            'id': 'SHQ-1NP-1S52',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S53': {
            'name': 'Jednací místnost',
            'code': '1S53',
            'svgId': '1S53',
            'id': 'SHQ-1NP-1S53',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S54': {
            'name': 'Jednací místnost',
            'code': '1S54',
            'svgId': '1S54',
            'id': 'SHQ-1NP-1S54',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S55': {
            'name': 'Jednací místnost',
            'code': '1S55',
            'svgId': '1S55',
            'id': 'SHQ-1NP-1S55',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S56': {
            'name': 'Jednací místnost',
            'code': '1S56',
            'svgId': '1S56',
            'id': 'SHQ-1NP-1S56',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S57': {
            'name': 'Centrální atrium',
            'code': '1S57',
            'svgId': '1S57',
            'id': 'SHQ-1NP-1S57',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1S99': {
            'name': 'Kancelář',
            'code': '1S99',
            'svgId': '1S99',
            'id': 'SHQ-1NP-1S99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1T02': {
            'name': 'Výtah',
            'code': '1T02',
            'svgId': '1T02',
            'id': 'SHQ-1NP-1T02',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1T03': {
            'name': 'Výtah',
            'code': '1T03',
            'svgId': '1T03',
            'id': 'SHQ-1NP-1T03',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1T41': {
            'name': 'Oddělená kancelář',
            'code': '1T41',
            'svgId': '1T41',
            'id': 'SHQ-1NP-1T41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1T45': {
            'name': 'Jednací místnost',
            'code': '1T45',
            'svgId': '1T45',
            'id': 'SHQ-1NP-1T45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1T46': {
            'name': 'Jednací místnost',
            'code': '1T46',
            'svgId': '1T46',
            'id': 'SHQ-1NP-1T46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1T48': {
            'name': 'Jednací místnost',
            'code': '1T48',
            'svgId': '1T48',
            'id': 'SHQ-1NP-1T48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1T50': {
            'name': 'Jednací místnost',
            'code': '1T50',
            'svgId': '1T50',
            'id': 'SHQ-1NP-1T50',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1T99': {
            'name': 'Kancelář',
            'code': '1T99',
            'svgId': '1T99',
            'id': 'SHQ-1NP-1T99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U05': {
            'name': 'Výtah',
            'code': '1U05',
            'svgId': '1U05',
            'id': 'SHQ-1NP-1U05',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U06': {
            'name': 'Výtah',
            'code': '1U06',
            'svgId': '1U06',
            'id': 'SHQ-1NP-1U06',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U42': {
            'name': 'Jednací místnost',
            'code': '1U42',
            'svgId': '1U42',
            'id': 'SHQ-1NP-1U42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U44': {
            'name': 'Patrové minicafé',
            'code': '1U44',
            'svgId': '1U44',
            'id': 'SHQ-1NP-1U44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U45': {
            'name': 'Jednací místnost',
            'code': '1U45',
            'svgId': '1U45',
            'id': 'SHQ-1NP-1U45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U46': {
            'name': 'Jednací místnost',
            'code': '1U46',
            'svgId': '1U46',
            'id': 'SHQ-1NP-1U46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U47': {
            'name': 'Jednací místnost krizová',
            'code': '1U47',
            'svgId': '1U47',
            'id': 'SHQ-1NP-1U47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U48': {
            'name': 'VIP jednací místnost',
            'code': '1U48',
            'svgId': '1U48',
            'id': 'SHQ-1NP-1U48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U54': {
            'name': 'VIP jednací místnost',
            'code': '1U54',
            'svgId': '1U54',
            'id': 'SHQ-1NP-1U54',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U56': {
            'name': 'Jednací místnost',
            'code': '1U56',
            'svgId': '1U56',
            'id': 'SHQ-1NP-1U56',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U57': {
            'name': 'Jednací místnost',
            'code': '1U57',
            'svgId': '1U57',
            'id': 'SHQ-1NP-1U57',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U98': {
            'name': 'Kancelář',
            'code': '1U98',
            'svgId': '1U98',
            'id': 'SHQ-1NP-1U98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1U99': {
            'name': 'Kancelář',
            'code': '1U99',
            'svgId': '1U99',
            'id': 'SHQ-1NP-1U99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V42': {
            'name': 'Jednací místnost',
            'code': '1V42',
            'svgId': '1V42',
            'id': 'SHQ-1NP-1V42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V45': {
            'name': 'Jednací místnost',
            'code': '1V45',
            'svgId': '1V45',
            'id': 'SHQ-1NP-1V45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V46': {
            'name': 'Jednací místnost',
            'code': '1V46',
            'svgId': '1V46',
            'id': 'SHQ-1NP-1V46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V47': {
            'name': 'Kancelář',
            'code': '1V47',
            'svgId': '1V47',
            'id': 'SHQ-1NP-1V47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V48': {
            'name': 'Jednací místnost',
            'code': '1V48',
            'svgId': '1V48',
            'id': 'SHQ-1NP-1V48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V49': {
            'name': 'Technické zázemí kanceláře',
            'code': '1V49',
            'svgId': '1V49',
            'id': 'SHQ-1NP-1V49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V97': {
            'name': 'Kancelář',
            'code': '1V97',
            'svgId': '1V97',
            'id': 'SHQ-1NP-1V97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V97A': {
            'name': 'Velkoprostorová kancelář',
            'code': '1V97A',
            'svgId': '1V97A',
            'id': 'SHQ-1NP-1V97A',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V98': {
            'name': 'Kancelář',
            'code': '1V98',
            'svgId': '1V98',
            'id': 'SHQ-1NP-1V98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V99': {
            'name': 'Kancelář',
            'code': '1V99',
            'svgId': '1V99',
            'id': 'SHQ-1NP-1V99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1V99A': {
            'name': 'Kancelář',
            'code': '1V99A',
            'svgId': '1V99A',
            'id': 'SHQ-1NP-1V99A',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X02': {
            'name': 'Výtah',
            'code': '1X02',
            'svgId': '1X02',
            'id': 'SHQ-1NP-1X02',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X03': {
            'name': 'Výtah',
            'code': '1X03',
            'svgId': '1X03',
            'id': 'SHQ-1NP-1X03',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X32': {
            'name': 'Hala',
            'code': '1X32',
            'svgId': '1X32',
            'id': 'SHQ-1NP-1X32',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X43': {
            'name': 'Jednací místnost',
            'code': '1X43',
            'svgId': '1X43',
            'id': 'SHQ-1NP-1X43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X46': {
            'name': 'Kancelář',
            'code': '1X46',
            'svgId': '1X46',
            'id': 'SHQ-1NP-1X46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X47': {
            'name': 'Kancelář',
            'code': '1X47',
            'svgId': '1X47',
            'id': 'SHQ-1NP-1X47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X48': {
            'name': 'Jednací místnost',
            'code': '1X48',
            'svgId': '1X48',
            'id': 'SHQ-1NP-1X48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X49': {
            'name': 'Jednací místnost',
            'code': '1X49',
            'svgId': '1X49',
            'id': 'SHQ-1NP-1X49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X97': {
            'name': 'Kancelář',
            'code': '1X97',
            'svgId': '1X97',
            'id': 'SHQ-1NP-1X97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X98': {
            'name': 'Kancelář',
            'code': '1X98',
            'svgId': '1X98',
            'id': 'SHQ-1NP-1X98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X99': {
            'name': 'Velkoprostorová kancelář',
            'code': '1X99',
            'svgId': '1X99',
            'id': 'SHQ-1NP-1X99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X99A': {
            'name': 'Kancelář',
            'code': '1X99A',
            'svgId': '1X99A',
            'id': 'SHQ-1NP-1X99A',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1X99C': {
            'name': 'Velkoprostorová kancelář',
            'code': '1X99C',
            'svgId': '1X99C',
            'id': 'SHQ-1NP-1X99C',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Y42': {
            'name': 'Jednací místnost',
            'code': '1Y42',
            'svgId': '1Y42',
            'id': 'SHQ-1NP-1Y42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Y45': {
            'name': 'Jednací místnost',
            'code': '1Y45',
            'svgId': '1Y45',
            'id': 'SHQ-1NP-1Y45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Y46': {
            'name': 'Jednací místnost',
            'code': '1Y46',
            'svgId': '1Y46',
            'id': 'SHQ-1NP-1Y46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Y47': {
            'name': 'Jednací místnost',
            'code': '1Y47',
            'svgId': '1Y47',
            'id': 'SHQ-1NP-1Y47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Y96': {
            'name': 'Kancelář',
            'code': '1Y96',
            'svgId': '1Y96',
            'id': 'SHQ-1NP-1Y96',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Y97': {
            'name': 'Kancelář',
            'code': '1Y97',
            'svgId': '1Y97',
            'id': 'SHQ-1NP-1Y97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Y98': {
            'name': 'Kancelář',
            'code': '1Y98',
            'svgId': '1Y98',
            'id': 'SHQ-1NP-1Y98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Y99': {
            'name': 'Kancelář',
            'code': '1Y99',
            'svgId': '1Y99',
            'id': 'SHQ-1NP-1Y99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Z41': {
            'name': 'Jednací místnost',
            'code': '1Z41',
            'svgId': '1Z41',
            'id': 'SHQ-1NP-1Z41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Z42': {
            'name': 'Kancelář',
            'code': '1Z42',
            'svgId': '1Z42',
            'id': 'SHQ-1NP-1Z42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Z43': {
            'name': 'Kancelář',
            'code': '1Z43',
            'svgId': '1Z43',
            'id': 'SHQ-1NP-1Z43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Z44': {
            'name': 'Jednací místnost',
            'code': '1Z44',
            'svgId': '1Z44',
            'id': 'SHQ-1NP-1Z44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Z46': {
            'name': 'Jednací místnost',
            'code': '1Z46',
            'svgId': '1Z46',
            'id': 'SHQ-1NP-1Z46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Z97': {
            'name': 'Kancelář',
            'code': '1Z97',
            'svgId': '1Z97',
            'id': 'SHQ-1NP-1Z97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Z98': {
            'name': 'Kancelář',
            'code': '1Z98',
            'svgId': '1Z98',
            'id': 'SHQ-1NP-1Z98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-1Z99': {
            'name': 'Kancelář',
            'code': '1Z99',
            'svgId': '1Z99',
            'id': 'SHQ-1NP-1Z99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-A3_PCZSHQ2056': {
            'name': 'Tiskárna A3 / 1V',
            'code': 'A3_PCZSHQ2056',
            'svgId': 'A3_PCZSHQ2056',
            'id': 'SHQ-1NP-A3_PCZSHQ2056',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-A4_PCZSHQ2063': {
            'name': 'Tiskárna A4 / 1X',
            'code': 'A4_PCZSHQ2063',
            'svgId': 'A4_PCZSHQ2063',
            'id': 'SHQ-1NP-A4_PCZSHQ2063',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-A4_PCZSHQ2064': {
            'name': 'Tiskárna A4 / 1X',
            'code': 'A4_PCZSHQ2064',
            'svgId': 'A4_PCZSHQ2064',
            'id': 'SHQ-1NP-A4_PCZSHQ2064',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-A4_PCZSHQ2073': {
            'name': 'Tiskárna A4 / 1X',
            'code': 'A4_PCZSHQ2073',
            'svgId': 'A4_PCZSHQ2073',
            'id': 'SHQ-1NP-A4_PCZSHQ2073',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          },
          'SHQ-1NP-A3_PCZSHQ2057': {
            'name': 'Tiskárna A3 / 1S',
            'code': 'A3_PCZSHQ2057',
            'svgId': 'A3_PCZSHQ2057',
            'id': 'SHQ-1NP-A3_PCZSHQ2057',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1NP',
            'type': ''
          }
        }
      },
      'SHQ-1PP': {
        'name': '1. patro',
        'code': '1PP',
        'svgId': '1PP',
        'buildingId': 'SHQ',
        'id': 'SHQ-1PP',
        'rooms': {
          'SHQ-1PP-0S04': {
            'name': 'Výtah',
            'code': '0S04',
            'svgId': '0S04',
            'id': 'SHQ-1PP-0S04',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0S05': {
            'name': 'Výtah',
            'code': '0S05',
            'svgId': '0S05',
            'id': 'SHQ-1PP-0S05',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0S06': {
            'name': 'Výtah',
            'code': '0S06',
            'svgId': '0S06',
            'id': 'SHQ-1PP-0S06',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0S41': {
            'name': 'Kancelář',
            'code': '0S41',
            'svgId': '0S41',
            'id': 'SHQ-1PP-0S41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0S42': {
            'name': 'Recepce',
            'code': '0S42',
            'svgId': '0S42',
            'id': 'SHQ-1PP-0S42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0S43': {
            'name': 'Jednací místnost',
            'code': '0S43',
            'svgId': '0S43',
            'id': 'SHQ-1PP-0S43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0S44': {
            'name': 'Jednací místnost',
            'code': '0S44',
            'svgId': '0S44',
            'id': 'SHQ-1PP-0S44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0S45': {
            'name': 'Podatelna',
            'code': '0S45',
            'svgId': '0S45',
            'id': 'SHQ-1PP-0S45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0S49': {
            'name': 'Kavárna',
            'code': '0S49',
            'svgId': '0S49',
            'id': 'SHQ-1PP-0S49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0S51': {
            'name': 'Jednací místnost',
            'code': '0S51',
            'svgId': '0S51',
            'id': 'SHQ-1PP-0S51',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0S52': {
            'name': 'Jednací místnost',
            'code': '0S52',
            'svgId': '0S52',
            'id': 'SHQ-1PP-0S52',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0T03': {
            'name': 'Výtah',
            'code': '0T03',
            'svgId': '0T03',
            'id': 'SHQ-1PP-0T03',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0T04': {
            'name': 'Výtah',
            'code': '0T04',
            'svgId': '0T04',
            'id': 'SHQ-1PP-0T04',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0T21': {
            'name': 'Sprcha - ženy',
            'code': '0T21',
            'svgId': '0T21',
            'id': 'SHQ-1PP-0T21',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0T22': {
            'name': 'Sprcha - ženy',
            'code': '0T22',
            'svgId': '0T22',
            'id': 'SHQ-1PP-0T22',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0T23': {
            'name': 'Sprcha - muži',
            'code': '0T23',
            'svgId': '0T23',
            'id': 'SHQ-1PP-0T23',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0U05': {
            'name': 'Výtah',
            'code': '0U05',
            'svgId': '0U05',
            'id': 'SHQ-1PP-0U05',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0U06': {
            'name': 'Výtah',
            'code': '0U06',
            'svgId': '0U06',
            'id': 'SHQ-1PP-0U06',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0U42': {
            'name': 'Kavárna - klub',
            'code': '0U42',
            'svgId': '0U42',
            'id': 'SHQ-1PP-0U42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0V42': {
            'name': 'Jídelna',
            'code': '0V42',
            'svgId': '0V42',
            'id': 'SHQ-1PP-0V42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0V60': {
            'name': 'Venkovní jednací místo',
            'code': '0V60',
            'svgId': '0V60',
            'id': 'SHQ-1PP-0V60',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X01': {
            'name': 'Vedlejší schodiště',
            'code': '0X01',
            'svgId': '0X01',
            'id': 'SHQ-1PP-0X01',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X02': {
            'name': 'Výtah',
            'code': '0X02',
            'svgId': '0X02',
            'id': 'SHQ-1PP-0X02',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X03': {
            'name': 'Výtah',
            'code': '0X03',
            'svgId': '0X03',
            'id': 'SHQ-1PP-0X03',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X32': {
            'name': 'Komunikační prostor - hala',
            'code': '0X32',
            'svgId': '0X32',
            'id': 'SHQ-1PP-0X32',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X41': {
            'name': 'Kancelář',
            'code': '0X41',
            'svgId': '0X41',
            'id': 'SHQ-1PP-0X41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X43': {
            'name': 'Jednací místnost',
            'code': '0X43',
            'svgId': '0X43',
            'id': 'SHQ-1PP-0X43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X46': {
            'name': 'Jednací místnost',
            'code': '0X46',
            'svgId': '0X46',
            'id': 'SHQ-1PP-0X46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X47': {
            'name': 'Jednací místnost',
            'code': '0X47',
            'svgId': '0X47',
            'id': 'SHQ-1PP-0X47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X48': {
            'name': 'Kancelář',
            'code': '0X48',
            'svgId': '0X48',
            'id': 'SHQ-1PP-0X48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X49': {
            'name': 'Jednací místnost',
            'code': '0X49',
            'svgId': '0X49',
            'id': 'SHQ-1PP-0X49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X50': {
            'name': 'Pobočka',
            'code': '0X50',
            'svgId': '0X50',
            'id': 'SHQ-1PP-0X50',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X51': {
            'name': 'Jednací místnost',
            'code': '0X51',
            'svgId': '0X51',
            'id': 'SHQ-1PP-0X51',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X52': {
            'name': 'Jednací místnost',
            'code': '0X52',
            'svgId': '0X52',
            'id': 'SHQ-1PP-0X52',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X97': {
            'name': 'Kancelář',
            'code': '0X97',
            'svgId': '0X97',
            'id': 'SHQ-1PP-0X97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X98': {
            'name': 'Kancelář',
            'code': '0X98',
            'svgId': '0X98',
            'id': 'SHQ-1PP-0X98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0X99': {
            'name': 'Kancelář',
            'code': '0X99',
            'svgId': '0X99',
            'id': 'SHQ-1PP-0X99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Y42': {
            'name': 'Jednací místnost',
            'code': '0Y42',
            'svgId': '0Y42',
            'id': 'SHQ-1PP-0Y42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Y44': {
            'name': 'Technické zázemí kanceláře',
            'code': '0Y44',
            'svgId': '0Y44',
            'id': 'SHQ-1PP-0Y44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Y45': {
            'name': 'Jednací místnost',
            'code': '0Y45',
            'svgId': '0Y45',
            'id': 'SHQ-1PP-0Y45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Y46': {
            'name': 'Jednací místnost',
            'code': '0Y46',
            'svgId': '0Y46',
            'id': 'SHQ-1PP-0Y46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Y47': {
            'name': 'Jednací místnost',
            'code': '0Y47',
            'svgId': '0Y47',
            'id': 'SHQ-1PP-0Y47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Y96': {
            'name': 'Kancelář',
            'code': '0Y96',
            'svgId': '0Y96',
            'id': 'SHQ-1PP-0Y96',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Y97': {
            'name': 'Kancelář',
            'code': '0Y97',
            'svgId': '0Y97',
            'id': 'SHQ-1PP-0Y97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Y98': {
            'name': 'Kancelář',
            'code': '0Y98',
            'svgId': '0Y98',
            'id': 'SHQ-1PP-0Y98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Y99': {
            'name': 'Kancelář',
            'code': '0Y99',
            'svgId': '0Y99',
            'id': 'SHQ-1PP-0Y99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z41': {
            'name': 'Jednací místnost',
            'code': '0Z41',
            'svgId': '0Z41',
            'id': 'SHQ-1PP-0Z41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z42': {
            'name': 'Jednací místnost',
            'code': '0Z42',
            'svgId': '0Z42',
            'id': 'SHQ-1PP-0Z42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z43': {
            'name': 'Jednací místnost',
            'code': '0Z43',
            'svgId': '0Z43',
            'id': 'SHQ-1PP-0Z43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z44': {
            'name': 'Jednací místnost krizová',
            'code': '0Z44',
            'svgId': '0Z44',
            'id': 'SHQ-1PP-0Z44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z45': {
            'name': 'Jednací místnost',
            'code': '0Z45',
            'svgId': '0Z45',
            'id': 'SHQ-1PP-0Z45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z46': {
            'name': 'Jednací místnost',
            'code': '0Z46',
            'svgId': '0Z46',
            'id': 'SHQ-1PP-0Z46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z47': {
            'name': 'Tělocvična',
            'code': '0Z47',
            'svgId': '0Z47',
            'id': 'SHQ-1PP-0Z47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z48': {
            'name': 'Jednací místnost',
            'code': '0Z48',
            'svgId': '0Z48',
            'id': 'SHQ-1PP-0Z48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z49': {
            'name': 'Jednací místnost',
            'code': '0Z49',
            'svgId': '0Z49',
            'id': 'SHQ-1PP-0Z49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z50': {
            'name': 'Jednací místnost',
            'code': '0Z50',
            'svgId': '0Z50',
            'id': 'SHQ-1PP-0Z50',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z51': {
            'name': 'Jednací místnost',
            'code': '0Z51',
            'svgId': '0Z51',
            'id': 'SHQ-1PP-0Z51',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z52': {
            'name': 'Jednací místnost',
            'code': '0Z52',
            'svgId': '0Z52',
            'id': 'SHQ-1PP-0Z52',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z53': {
            'name': 'Jednací místnost',
            'code': '0Z53',
            'svgId': '0Z53',
            'id': 'SHQ-1PP-0Z53',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-0Z55': {
            'name': 'Jednací místnost',
            'code': '0Z55',
            'svgId': '0Z55',
            'id': 'SHQ-1PP-0Z55',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-A4_PCZSHQ2069': {
            'name': 'Tiskárna A4 / 0Y',
            'code': 'A4_PCZSHQ2069',
            'svgId': 'A4_PCZSHQ2069',
            'id': 'SHQ-1PP-A4_PCZSHQ2069',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-A4_PCZSHQ2065': {
            'name': 'Tiskárna A4 / 0S',
            'code': 'A4_PCZSHQ2065',
            'svgId': 'A4_PCZSHQ2065',
            'id': 'SHQ-1PP-A4_PCZSHQ2065',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-A4_PCZSHQ2060': {
            'name': 'Tiskárna A4 / 0X',
            'code': 'A4_PCZSHQ2060',
            'svgId': 'A4_PCZSHQ2060',
            'id': 'SHQ-1PP-A4_PCZSHQ2060',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-A4_PCZSHQ2074': {
            'name': 'Tiskárna A4 / 0X',
            'code': 'A4_PCZSHQ2074',
            'svgId': 'A4_PCZSHQ2074',
            'id': 'SHQ-1PP-A4_PCZSHQ2074',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-A4_PCZSHQ2055': {
            'name': 'Tiskárna A4 / 0X',
            'code': 'A4_PCZSHQ2055',
            'svgId': 'A4_PCZSHQ2055',
            'id': 'SHQ-1PP-A4_PCZSHQ2055',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-A3_PCZSHQ2070': {
            'name': 'Tiskárna A3 / 0X',
            'code': 'A3_PCZSHQ2070',
            'svgId': 'A3_PCZSHQ2070',
            'id': 'SHQ-1PP-A3_PCZSHQ2070',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-A4_PCZSHQ2062': {
            'name': 'Tiskárna A4 / 0X',
            'code': 'A4_PCZSHQ2062',
            'svgId': 'A4_PCZSHQ2062',
            'id': 'SHQ-1PP-A4_PCZSHQ2062',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          },
          'SHQ-1PP-A4_PCZSHQ2066': {
            'name': 'Tiskárna A4 / 0X',
            'code': 'A4_PCZSHQ2066',
            'svgId': 'A4_PCZSHQ2066',
            'id': 'SHQ-1PP-A4_PCZSHQ2066',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-1PP',
            'type': ''
          }
        }
      },
      'SHQ-2NP': {
        'name': '2. patro',
        'code': '2NP',
        'svgId': '2NP',
        'buildingId': 'SHQ',
        'id': 'SHQ-2NP',
        'rooms': {
          'SHQ-2NP-2S04': {
            'name': 'Výtah',
            'code': '2S04',
            'svgId': '2S04',
            'id': 'SHQ-2NP-2S04',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S05': {
            'name': 'Výtah',
            'code': '2S05',
            'svgId': '2S05',
            'id': 'SHQ-2NP-2S05',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S06': {
            'name': 'Výtah',
            'code': '2S06',
            'svgId': '2S06',
            'id': 'SHQ-2NP-2S06',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S41': {
            'name': 'Kancelář',
            'code': '2S41',
            'svgId': '2S41',
            'id': 'SHQ-2NP-2S41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S42': {
            'name': 'Jednací místnost',
            'code': '2S42',
            'svgId': '2S42',
            'id': 'SHQ-2NP-2S42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S43': {
            'name': 'Jednací místnost',
            'code': '2S43',
            'svgId': '2S43',
            'id': 'SHQ-2NP-2S43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S45': {
            'name': 'Jednací místnost',
            'code': '2S45',
            'svgId': '2S45',
            'id': 'SHQ-2NP-2S45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S46': {
            'name': 'Jednací místnost',
            'code': '2S46',
            'svgId': '2S46',
            'id': 'SHQ-2NP-2S46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S47': {
            'name': 'Jednací místnost',
            'code': '2S47',
            'svgId': '2S47',
            'id': 'SHQ-2NP-2S47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S48': {
            'name': 'Jednací místnost',
            'code': '2S48',
            'svgId': '2S48',
            'id': 'SHQ-2NP-2S48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S49': {
            'name': 'Jednací místnost',
            'code': '2S49',
            'svgId': '2S49',
            'id': 'SHQ-2NP-2S49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S51': {
            'name': 'Alternativní pracoviště',
            'code': '2S51',
            'svgId': '2S51',
            'id': 'SHQ-2NP-2S51',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S52': {
            'name': 'Jednací místnost',
            'code': '2S52',
            'svgId': '2S52',
            'id': 'SHQ-2NP-2S52',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S54': {
            'name': 'Jednací místnost',
            'code': '2S54',
            'svgId': '2S54',
            'id': 'SHQ-2NP-2S54',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S55': {
            'name': 'Jednací místnost',
            'code': '2S55',
            'svgId': '2S55',
            'id': 'SHQ-2NP-2S55',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S56': {
            'name': 'Jednací místnost',
            'code': '2S56',
            'svgId': '2S56',
            'id': 'SHQ-2NP-2S56',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S57': {
            'name': 'Centrální atrium',
            'code': '2S57',
            'svgId': '2S57',
            'id': 'SHQ-2NP-2S57',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2S99': {
            'name': 'Kancelář',
            'code': '2S99',
            'svgId': '2S99',
            'id': 'SHQ-2NP-2S99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T03': {
            'name': 'Výtah',
            'code': '2T03',
            'svgId': '2T03',
            'id': 'SHQ-2NP-2T03',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T04': {
            'name': 'Výtah',
            'code': '2T04',
            'svgId': '2T04',
            'id': 'SHQ-2NP-2T04',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T41': {
            'name': 'Tréninková PC místnost',
            'code': '2T41',
            'svgId': '2T41',
            'id': 'SHQ-2NP-2T41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T42': {
            'name': 'Tréninková místnost',
            'code': '2T42',
            'svgId': '2T42',
            'id': 'SHQ-2NP-2T42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T44': {
            'name': 'Tréninková místnost',
            'code': '2T44',
            'svgId': '2T44',
            'id': 'SHQ-2NP-2T44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T46': {
            'name': 'Tréninková místnost',
            'code': '2T46',
            'svgId': '2T46',
            'id': 'SHQ-2NP-2T46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T50': {
            'name': 'Tréninková místnost',
            'code': '2T50',
            'svgId': '2T50',
            'id': 'SHQ-2NP-2T50',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T51': {
            'name': 'Tréninková místnost',
            'code': '2T51',
            'svgId': '2T51',
            'id': 'SHQ-2NP-2T51',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T52': {
            'name': 'Tréninková místnost',
            'code': '2T52',
            'svgId': '2T52',
            'id': 'SHQ-2NP-2T52',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T53': {
            'name': 'Tréninková PC místnost',
            'code': '2T53',
            'svgId': '2T53',
            'id': 'SHQ-2NP-2T53',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T55': {
            'name': 'Tréninková místnost',
            'code': '2T55',
            'svgId': '2T55',
            'id': 'SHQ-2NP-2T55',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T56': {
            'name': 'Minicafé',
            'code': '2T56',
            'svgId': '2T56',
            'id': 'SHQ-2NP-2T56',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T57': {
            'name': 'Tréninková PC místnost',
            'code': '2T57',
            'svgId': '2T57',
            'id': 'SHQ-2NP-2T57',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2T60': {
            'name': 'Venkovní terasa - jednací místo',
            'code': '2T60',
            'svgId': '2T60',
            'id': 'SHQ-2NP-2T60',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U05': {
            'name': 'Výtah',
            'code': '2U05',
            'svgId': '2U05',
            'id': 'SHQ-2NP-2U05',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U06': {
            'name': 'Výtah',
            'code': '2U06',
            'svgId': '2U06',
            'id': 'SHQ-2NP-2U06',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U41': {
            'name': 'Centrálni atrium',
            'code': '2U41',
            'svgId': '2U41',
            'id': 'SHQ-2NP-2U41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U42': {
            'name': 'Jednací místnost',
            'code': '2U42',
            'svgId': '2U42',
            'id': 'SHQ-2NP-2U42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U44': {
            'name': 'Patrové minicafé',
            'code': '2U44',
            'svgId': '2U44',
            'id': 'SHQ-2NP-2U44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U46': {
            'name': 'Alternativní pracoviště',
            'code': '2U46',
            'svgId': '2U46',
            'id': 'SHQ-2NP-2U46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U47': {
            'name': 'Jednací místnost',
            'code': '2U47',
            'svgId': '2U47',
            'id': 'SHQ-2NP-2U47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U48': {
            'name': 'Jednací místnost',
            'code': '2U48',
            'svgId': '2U48',
            'id': 'SHQ-2NP-2U48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U49': {
            'name': 'Jednací místnost',
            'code': '2U49',
            'svgId': '2U49',
            'id': 'SHQ-2NP-2U49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U56': {
            'name': 'Jednací místnost',
            'code': '2U56',
            'svgId': '2U56',
            'id': 'SHQ-2NP-2U56',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U57': {
            'name': 'Jednací místnost',
            'code': '2U57',
            'svgId': '2U57',
            'id': 'SHQ-2NP-2U57',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U98': {
            'name': 'Kancelář',
            'code': '2U98',
            'svgId': '2U98',
            'id': 'SHQ-2NP-2U98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2U99': {
            'name': 'Kancelář',
            'code': '2U99',
            'svgId': '2U99',
            'id': 'SHQ-2NP-2U99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2V42': {
            'name': 'Jednací místnost',
            'code': '2V42',
            'svgId': '2V42',
            'id': 'SHQ-2NP-2V42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2V45': {
            'name': 'Kancelář',
            'code': '2V45',
            'svgId': '2V45',
            'id': 'SHQ-2NP-2V45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2V46': {
            'name': 'Kancelář',
            'code': '2V46',
            'svgId': '2V46',
            'id': 'SHQ-2NP-2V46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2V47': {
            'name': 'Kancelář',
            'code': '2V47',
            'svgId': '2V47',
            'id': 'SHQ-2NP-2V47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2V48': {
            'name': 'Jednací místnost',
            'code': '2V48',
            'svgId': '2V48',
            'id': 'SHQ-2NP-2V48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2V49': {
            'name': 'Technické zázemí kanceláře',
            'code': '2V49',
            'svgId': '2V49',
            'id': 'SHQ-2NP-2V49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2V97': {
            'name': 'Kancelář',
            'code': '2V97',
            'svgId': '2V97',
            'id': 'SHQ-2NP-2V97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2V98': {
            'name': 'Kancelář',
            'code': '2V98',
            'svgId': '2V98',
            'id': 'SHQ-2NP-2V98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2V99': {
            'name': 'Kancelář',
            'code': '2V99',
            'svgId': '2V99',
            'id': 'SHQ-2NP-2V99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2X01': {
            'name': 'Venkovní jednací místo',
            'code': '2X01',
            'svgId': '2X01',
            'id': 'SHQ-2NP-2X01',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Y42': {
            'name': 'Jednací místnost',
            'code': '2Y42',
            'svgId': '2Y42',
            'id': 'SHQ-2NP-2Y42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Y45': {
            'name': 'Jednací místnost',
            'code': '2Y45',
            'svgId': '2Y45',
            'id': 'SHQ-2NP-2Y45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Y46': {
            'name': 'Jednací místnost',
            'code': '2Y46',
            'svgId': '2Y46',
            'id': 'SHQ-2NP-2Y46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Y47': {
            'name': 'Jednací místnost',
            'code': '2Y47',
            'svgId': '2Y47',
            'id': 'SHQ-2NP-2Y47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Y96': {
            'name': 'Kancelář',
            'code': '2Y96',
            'svgId': '2Y96',
            'id': 'SHQ-2NP-2Y96',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Y97': {
            'name': 'Kancelář',
            'code': '2Y97',
            'svgId': '2Y97',
            'id': 'SHQ-2NP-2Y97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Y98': {
            'name': 'Kancelář',
            'code': '2Y98',
            'svgId': '2Y98',
            'id': 'SHQ-2NP-2Y98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Y98A': {
            'name': 'Kancelář',
            'code': '2Y98A',
            'svgId': '2Y98A',
            'id': 'SHQ-2NP-2Y98A',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Y99': {
            'name': 'Kancelář',
            'code': '2Y99',
            'svgId': '2Y99',
            'id': 'SHQ-2NP-2Y99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Z41': {
            'name': 'Jednací místnost',
            'code': '2Z41',
            'svgId': '2Z41',
            'id': 'SHQ-2NP-2Z41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Z42': {
            'name': 'Jednací místnost',
            'code': '2Z42',
            'svgId': '2Z42',
            'id': 'SHQ-2NP-2Z42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Z43': {
            'name': 'Kancelář',
            'code': '2Z43',
            'svgId': '2Z43',
            'id': 'SHQ-2NP-2Z43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Z44': {
            'name': 'Jednací místnost',
            'code': '2Z44',
            'svgId': '2Z44',
            'id': 'SHQ-2NP-2Z44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Z46': {
            'name': 'Jednací místnost',
            'code': '2Z46',
            'svgId': '2Z46',
            'id': 'SHQ-2NP-2Z46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Z97': {
            'name': 'Kancelář',
            'code': '2Z97',
            'svgId': '2Z97',
            'id': 'SHQ-2NP-2Z97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Z98': {
            'name': 'Kancelář',
            'code': '2Z98',
            'svgId': '2Z98',
            'id': 'SHQ-2NP-2Z98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-2Z99': {
            'name': 'Kancelář',
            'code': '2Z99',
            'svgId': '2Z99',
            'id': 'SHQ-2NP-2Z99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-A3_PCZSHQ2059': {
            'name': 'Tiskárna A3 / 2S',
            'code': 'A3_PCZSHQ2059',
            'svgId': 'A3_PCZSHQ2059',
            'id': 'SHQ-2NP-A3_PCZSHQ2059',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-A4_PCZSHQ2068': {
            'name': 'Tiskárna A4 / 2U',
            'code': 'A4_PCZSHQ2068',
            'svgId': 'A4_PCZSHQ2068',
            'id': 'SHQ-2NP-A4_PCZSHQ2068',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          },
          'SHQ-2NP-A4_PCZSHQ2067': {
            'name': 'Tiskárna A4 / 2V',
            'code': 'A4_PCZSHQ2067',
            'svgId': 'A4_PCZSHQ2067',
            'id': 'SHQ-2NP-A4_PCZSHQ2067',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-2NP',
            'type': ''
          }
        }
      },
      'SHQ-3NP': {
        'name': '3. patro',
        'code': '3NP',
        'svgId': '3NP',
        'buildingId': 'SHQ',
        'id': 'SHQ-3NP',
        'rooms': {
          'SHQ-3NP-3S04': {
            'name': 'Výtah',
            'code': '3S04',
            'svgId': '3S04',
            'id': 'SHQ-3NP-3S04',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S05': {
            'name': 'Výtah',
            'code': '3S05',
            'svgId': '3S05',
            'id': 'SHQ-3NP-3S05',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S06': {
            'name': 'Výtah',
            'code': '3S06',
            'svgId': '3S06',
            'id': 'SHQ-3NP-3S06',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S43': {
            'name': 'Jednací místnost',
            'code': '3S43',
            'svgId': '3S43',
            'id': 'SHQ-3NP-3S43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S45': {
            'name': 'Kancelář',
            'code': '3S45',
            'svgId': '3S45',
            'id': 'SHQ-3NP-3S45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S46': {
            'name': 'Kancelář',
            'code': '3S46',
            'svgId': '3S46',
            'id': 'SHQ-3NP-3S46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S47': {
            'name': 'Kancelář',
            'code': '3S47',
            'svgId': '3S47',
            'id': 'SHQ-3NP-3S47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S48': {
            'name': 'Minicafé',
            'code': '3S48',
            'svgId': '3S48',
            'id': 'SHQ-3NP-3S48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S49': {
            'name': 'Jednací místnost',
            'code': '3S49',
            'svgId': '3S49',
            'id': 'SHQ-3NP-3S49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S50': {
            'name': 'Jednací místnost',
            'code': '3S50',
            'svgId': '3S50',
            'id': 'SHQ-3NP-3S50',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S54': {
            'name': 'Oddychová místnost',
            'code': '3S54',
            'svgId': '3S54',
            'id': 'SHQ-3NP-3S54',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S56': {
            'name': 'Jednací místnost',
            'code': '3S56',
            'svgId': '3S56',
            'id': 'SHQ-3NP-3S56',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S94': {
            'name': 'Kancelář',
            'code': '3S94',
            'svgId': '3S94',
            'id': 'SHQ-3NP-3S94',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S95': {
            'name': 'Kancelář',
            'code': '3S95',
            'svgId': '3S95',
            'id': 'SHQ-3NP-3S95',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S96': {
            'name': 'Kancelář',
            'code': '3S96',
            'svgId': '3S96',
            'id': 'SHQ-3NP-3S96',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S96A': {
            'name': 'Kancelář',
            'code': '3S96A',
            'svgId': '3S96A',
            'id': 'SHQ-3NP-3S96A',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S97': {
            'name': 'Kancelář',
            'code': '3S97',
            'svgId': '3S97',
            'id': 'SHQ-3NP-3S97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S98': {
            'name': 'Kancelář',
            'code': '3S98',
            'svgId': '3S98',
            'id': 'SHQ-3NP-3S98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3S99': {
            'name': 'Kancelář',
            'code': '3S99',
            'svgId': '3S99',
            'id': 'SHQ-3NP-3S99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T03': {
            'name': 'Výtah',
            'code': '3T03',
            'svgId': '3T03',
            'id': 'SHQ-3NP-3T03',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T04': {
            'name': 'Výtah',
            'code': '3T04',
            'svgId': '3T04',
            'id': 'SHQ-3NP-3T04',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T35': {
            'name': 'Komunikační prostor - hala',
            'code': '3T35',
            'svgId': '3T35',
            'id': 'SHQ-3NP-3T35',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T41': {
            'name': 'Tréninková PC místnost',
            'code': '3T41',
            'svgId': '3T41',
            'id': 'SHQ-3NP-3T41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T42': {
            'name': 'Minicafé',
            'code': '3T42',
            'svgId': '3T42',
            'id': 'SHQ-3NP-3T42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T43': {
            'name': 'Kancelář',
            'code': '3T43',
            'svgId': '3T43',
            'id': 'SHQ-3NP-3T43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T44': {
            'name': 'Kancelář',
            'code': '3T44',
            'svgId': '3T44',
            'id': 'SHQ-3NP-3T44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T45': {
            'name': 'Tréninková místnost',
            'code': '3T45',
            'svgId': '3T45',
            'id': 'SHQ-3NP-3T45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T46': {
            'name': 'Agilní místnost',
            'code': '3T46',
            'svgId': '3T46',
            'id': 'SHQ-3NP-3T46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T52': {
            'name': 'Tréninková PC místnost',
            'code': '3T52',
            'svgId': '3T52',
            'id': 'SHQ-3NP-3T52',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T53': {
            'name': 'Tréninková místnost',
            'code': '3T53',
            'svgId': '3T53',
            'id': 'SHQ-3NP-3T53',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T54': {
            'name': 'Tréninková místnost',
            'code': '3T54',
            'svgId': '3T54',
            'id': 'SHQ-3NP-3T54',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T55': {
            'name': 'Tréninková místnost',
            'code': '3T55',
            'svgId': '3T55',
            'id': 'SHQ-3NP-3T55',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T56': {
            'name': 'Tréninková místnost',
            'code': '3T56',
            'svgId': '3T56',
            'id': 'SHQ-3NP-3T56',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T57': {
            'name': 'Tréninková místnost',
            'code': '3T57',
            'svgId': '3T57',
            'id': 'SHQ-3NP-3T57',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T58': {
            'name': 'Tréninková místnost',
            'code': '3T58',
            'svgId': '3T58',
            'id': 'SHQ-3NP-3T58',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3T59': {
            'name': 'Minicafé',
            'code': '3T59',
            'svgId': '3T59',
            'id': 'SHQ-3NP-3T59',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U05': {
            'name': 'Výtah',
            'code': '3U05',
            'svgId': '3U05',
            'id': 'SHQ-3NP-3U05',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U06': {
            'name': 'Výtah',
            'code': '3U06',
            'svgId': '3U06',
            'id': 'SHQ-3NP-3U06',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U40': {
            'name': 'Kancelář',
            'code': '3U40',
            'svgId': '3U40',
            'id': 'SHQ-3NP-3U40',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U41': {
            'name': 'Jednací místnost',
            'code': '3U41',
            'svgId': '3U41',
            'id': 'SHQ-3NP-3U41',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U42': {
            'name': 'Jednací místnost',
            'code': '3U42',
            'svgId': '3U42',
            'id': 'SHQ-3NP-3U42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U43': {
            'name': 'Kancelář',
            'code': '3U43',
            'svgId': '3U43',
            'id': 'SHQ-3NP-3U43',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U44': {
            'name': 'Jednací místnost',
            'code': '3U44',
            'svgId': '3U44',
            'id': 'SHQ-3NP-3U44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U45': {
            'name': 'Jednací místnost',
            'code': '3U45',
            'svgId': '3U45',
            'id': 'SHQ-3NP-3U45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U46': {
            'name': 'Minicafé',
            'code': '3U46',
            'svgId': '3U46',
            'id': 'SHQ-3NP-3U46',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U47': {
            'name': 'Jednací místnost',
            'code': '3U47',
            'svgId': '3U47',
            'id': 'SHQ-3NP-3U47',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U48': {
            'name': 'Jednací místnost',
            'code': '3U48',
            'svgId': '3U48',
            'id': 'SHQ-3NP-3U48',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U50': {
            'name': 'Oddychová místnost',
            'code': '3U50',
            'svgId': '3U50',
            'id': 'SHQ-3NP-3U50',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U51': {
            'name': 'Kancelář',
            'code': '3U51',
            'svgId': '3U51',
            'id': 'SHQ-3NP-3U51',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U52': {
            'name': 'Kancelář',
            'code': '3U52',
            'svgId': '3U52',
            'id': 'SHQ-3NP-3U52',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U56': {
            'name': 'Jednací místnost',
            'code': '3U56',
            'svgId': '3U56',
            'id': 'SHQ-3NP-3U56',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U95': {
            'name': 'Kancelář',
            'code': '3U95',
            'svgId': '3U95',
            'id': 'SHQ-3NP-3U95',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U96': {
            'name': 'Kancelář',
            'code': '3U96',
            'svgId': '3U96',
            'id': 'SHQ-3NP-3U96',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U97': {
            'name': 'Kancelář',
            'code': '3U97',
            'svgId': '3U97',
            'id': 'SHQ-3NP-3U97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U97A': {
            'name': 'Velkoprostorová kancelář',
            'code': '3U97A',
            'svgId': '3U97A',
            'id': 'SHQ-3NP-3U97A',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-3U99': {
            'name': 'Kancelář',
            'code': '3U99',
            'svgId': '3U99',
            'id': 'SHQ-3NP-3U99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-A4_PCZSHQ2075': {
            'name': 'Tiskárna A4 / 3T',
            'code': 'A4_PCZSHQ2075',
            'svgId': 'A4_PCZSHQ2075',
            'id': 'SHQ-3NP-A4_PCZSHQ2075',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-A3_PCZSHQ2058': {
            'name': 'Tiskárna A3 / 3U',
            'code': 'A3_PCZSHQ2058',
            'svgId': 'A3_PCZSHQ2058',
            'id': 'SHQ-3NP-A3_PCZSHQ2058',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-A4_PCZSHQ2061': {
            'name': 'Tiskárna A4 / 3U',
            'code': 'A4_PCZSHQ2061',
            'svgId': 'A4_PCZSHQ2061',
            'id': 'SHQ-3NP-A4_PCZSHQ2061',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          },
          'SHQ-3NP-A4_PCZSHQ2076': {
            'name': 'Tiskárna A4 / 3S',
            'code': 'A4_PCZSHQ2076',
            'svgId': 'A4_PCZSHQ2076',
            'id': 'SHQ-3NP-A4_PCZSHQ2076',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-3NP',
            'type': ''
          }
        }
      },
      'SHQ-4NP': {
        'name': '4. patro',
        'code': '4NP',
        'svgId': '4NP',
        'buildingId': 'SHQ',
        'id': 'SHQ-4NP',
        'rooms': {
          'SHQ-4NP-4S03': {
            'name': 'Street basketbal',
            'code': '4S03',
            'svgId': '4S03',
            'id': 'SHQ-4NP-4S03',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U05': {
            'name': 'Výtah',
            'code': '4U05',
            'svgId': '4U05',
            'id': 'SHQ-4NP-4U05',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U06': {
            'name': 'Výtah',
            'code': '4U06',
            'svgId': '4U06',
            'id': 'SHQ-4NP-4U06',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U24': {
            'name': 'Oddychová místnost',
            'code': '4U24',
            'svgId': '4U24',
            'id': 'SHQ-4NP-4U24',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U42': {
            'name': 'Jednací místnost',
            'code': '4U42',
            'svgId': '4U42',
            'id': 'SHQ-4NP-4U42',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U44': {
            'name': 'Jednací místnost',
            'code': '4U44',
            'svgId': '4U44',
            'id': 'SHQ-4NP-4U44',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U45': {
            'name': 'Jednací místnost',
            'code': '4U45',
            'svgId': '4U45',
            'id': 'SHQ-4NP-4U45',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U49': {
            'name': 'Oddychová místnost',
            'code': '4U49',
            'svgId': '4U49',
            'id': 'SHQ-4NP-4U49',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U50': {
            'name': 'Jednací místnost',
            'code': '4U50',
            'svgId': '4U50',
            'id': 'SHQ-4NP-4U50',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U51': {
            'name': 'Jednací místnost',
            'code': '4U51',
            'svgId': '4U51',
            'id': 'SHQ-4NP-4U51',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U52': {
            'name': 'Jednací místnost',
            'code': '4U52',
            'svgId': '4U52',
            'id': 'SHQ-4NP-4U52',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U53': {
            'name': 'Recepce',
            'code': '4U53',
            'svgId': '4U53',
            'id': 'SHQ-4NP-4U53',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U54': {
            'name': 'Kancelář',
            'code': '4U54',
            'svgId': '4U54',
            'id': 'SHQ-4NP-4U54',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U55': {
            'name': 'Kancelář',
            'code': '4U55',
            'svgId': '4U55',
            'id': 'SHQ-4NP-4U55',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U56': {
            'name': 'Oddychová místnost',
            'code': '4U56',
            'svgId': '4U56',
            'id': 'SHQ-4NP-4U56',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U57': {
            'name': 'Minicafé',
            'code': '4U57',
            'svgId': '4U57',
            'id': 'SHQ-4NP-4U57',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U58': {
            'name': 'Jednací místnost',
            'code': '4U58',
            'svgId': '4U58',
            'id': 'SHQ-4NP-4U58',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U59': {
            'name': 'Jednací místnost',
            'code': '4U59',
            'svgId': '4U59',
            'id': 'SHQ-4NP-4U59',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U95': {
            'name': 'Kancelář',
            'code': '4U95',
            'svgId': '4U95',
            'id': 'SHQ-4NP-4U95',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U96': {
            'name': 'Kancelář',
            'code': '4U96',
            'svgId': '4U96',
            'id': 'SHQ-4NP-4U96',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U96A': {
            'name': 'Kancelář',
            'code': '4U96A',
            'svgId': '4U96A',
            'id': 'SHQ-4NP-4U96A',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U97': {
            'name': 'Kancelář',
            'code': '4U97',
            'svgId': '4U97',
            'id': 'SHQ-4NP-4U97',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U98': {
            'name': 'Kancelář',
            'code': '4U98',
            'svgId': '4U98',
            'id': 'SHQ-4NP-4U98',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-4U99': {
            'name': 'Kancelář',
            'code': '4U99',
            'svgId': '4U99',
            'id': 'SHQ-4NP-4U99',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-A3_PCZSHQ2071': {
            'name': 'Tiskárna A3 / 4U',
            'code': 'A3_PCZSHQ2071',
            'svgId': 'A3_PCZSHQ2071',
            'id': 'SHQ-4NP-A3_PCZSHQ2071',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          },
          'SHQ-4NP-A3_PCZSHQ2072': {
            'name': 'Tiskárna A3 / 4U',
            'code': 'A3_PCZSHQ2072',
            'svgId': 'A3_PCZSHQ2072',
            'id': 'SHQ-4NP-A3_PCZSHQ2072',
            'buildingId': 'SHQ',
            'floorId': 'SHQ-4NP',
            'type': ''
          }
        }
      }
    }
  },
  'NHQ': {
    'name': 'NHQ',
    'id': 'NHQ',
    'code': 'NHQ',
    'svgId': 'NHQ0',
    'floors': {
      'NHQ-1NP': {
        'name': '1. patro',
        'code': '1NP',
        'svgId': '1NP',
        'buildingId': 'NHQ',
        'id': 'NHQ-1NP',
        'rooms': {
          'NHQ-1NP-1A101': {
            'name': 'Schodiště',
            'code': '1A101',
            'svgId': '1A101',
            'id': 'NHQ-1NP-1A101',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A102': {
            'name': 'Výtah',
            'code': '1A102',
            'svgId': '1A102',
            'id': 'NHQ-1NP-1A102',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A103': {
            'name': 'Kuchyňka',
            'code': '1A103',
            'svgId': '1A103',
            'id': 'NHQ-1NP-1A103',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A104': {
            'name': 'MFD',
            'code': '1A104',
            'svgId': '1A104',
            'id': 'NHQ-1NP-1A104',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A104A': {
            'name': 'Chodba',
            'code': '1A104A',
            'svgId': '1A104A',
            'id': 'NHQ-1NP-1A104A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A106': {
            'name': 'Šatna',
            'code': '1A106',
            'svgId': '1A106',
            'id': 'NHQ-1NP-1A106',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A109': {
            'name': 'Sprcha',
            'code': '1A109',
            'svgId': '1A109',
            'id': 'NHQ-1NP-1A109',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A110': {
            'name': 'Šatna',
            'code': '1A110',
            'svgId': '1A110',
            'id': 'NHQ-1NP-1A110',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A111': {
            'name': 'Kancelář',
            'code': '1A111',
            'svgId': '1A111',
            'id': 'NHQ-1NP-1A111',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A111A': {
            'name': 'Kancelář',
            'code': '1A111A',
            'svgId': '1A111A',
            'id': 'NHQ-1NP-1A111A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A112': {
            'name': 'Jednací zóna',
            'code': '1A112',
            'svgId': '1A112',
            'id': 'NHQ-1NP-1A112',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A113': {
            'name': 'Kancelář',
            'code': '1A113',
            'svgId': '1A113',
            'id': 'NHQ-1NP-1A113',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A114': {
            'name': 'Kancelář',
            'code': '1A114',
            'svgId': '1A114',
            'id': 'NHQ-1NP-1A114',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A115': {
            'name': 'Jednací místnost',
            'code': '1A115',
            'svgId': '1A115',
            'id': 'NHQ-1NP-1A115',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A116': {
            'name': 'Jednací místnost',
            'code': '1A116',
            'svgId': '1A116',
            'id': 'NHQ-1NP-1A116',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A117': {
            'name': 'Kancelář',
            'code': '1A117',
            'svgId': '1A117',
            'id': 'NHQ-1NP-1A117',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A120': {
            'name': 'Diskrétní jednání',
            'code': '1A120',
            'svgId': '1A120',
            'id': 'NHQ-1NP-1A120',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A121': {
            'name': 'Diskrétní jednání',
            'code': '1A121',
            'svgId': '1A121',
            'id': 'NHQ-1NP-1A121',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A122': {
            'name': 'Diskrétní jednání',
            'code': '1A122',
            'svgId': '1A122',
            'id': 'NHQ-1NP-1A122',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A142': {
            'name': 'WC muži',
            'code': '1A142',
            'svgId': '1A142',
            'id': 'NHQ-1NP-1A142',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A143': {
            'name': 'WC muži imobil',
            'code': '1A143',
            'svgId': '1A143',
            'id': 'NHQ-1NP-1A143',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A144': {
            'name': 'WC muži',
            'code': '1A144',
            'svgId': '1A144',
            'id': 'NHQ-1NP-1A144',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A145': {
            'name': 'WC muži',
            'code': '1A145',
            'svgId': '1A145',
            'id': 'NHQ-1NP-1A145',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A146': {
            'name': 'WC muži',
            'code': '1A146',
            'svgId': '1A146',
            'id': 'NHQ-1NP-1A146',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A147': {
            'name': 'WC ženy',
            'code': '1A147',
            'svgId': '1A147',
            'id': 'NHQ-1NP-1A147',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A148': {
            'name': 'WC ženy',
            'code': '1A148',
            'svgId': '1A148',
            'id': 'NHQ-1NP-1A148',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A149': {
            'name': 'WC ženy',
            'code': '1A149',
            'svgId': '1A149',
            'id': 'NHQ-1NP-1A149',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A150': {
            'name': 'WC ženy imobil',
            'code': '1A150',
            'svgId': '1A150',
            'id': 'NHQ-1NP-1A150',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A151': {
            'name': 'WC ženy',
            'code': '1A151',
            'svgId': '1A151',
            'id': 'NHQ-1NP-1A151',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A154': {
            'name': 'Příruční spisovna',
            'code': '1A154',
            'svgId': '1A154',
            'id': 'NHQ-1NP-1A154',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A154A': {
            'name': 'Příruční spisovna',
            'code': '1A154A',
            'svgId': '1A154A',
            'id': 'NHQ-1NP-1A154A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A155': {
            'name': 'Chodba',
            'code': '1A155',
            'svgId': '1A155',
            'id': 'NHQ-1NP-1A155',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A156': {
            'name': 'Jednací místnost',
            'code': '1A156',
            'svgId': '1A156',
            'id': 'NHQ-1NP-1A156',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A157': {
            'name': 'Jednací místnost',
            'code': '1A157',
            'svgId': '1A157',
            'id': 'NHQ-1NP-1A157',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A159': {
            'name': 'Jednací místnost',
            'code': '1A159',
            'svgId': '1A159',
            'id': 'NHQ-1NP-1A159',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A160': {
            'name': 'Kancelář',
            'code': '1A160',
            'svgId': '1A160',
            'id': 'NHQ-1NP-1A160',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A161': {
            'name': 'Jednací místnost',
            'code': '1A161',
            'svgId': '1A161',
            'id': 'NHQ-1NP-1A161',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A162': {
            'name': 'Kancelář',
            'code': '1A162',
            'svgId': '1A162',
            'id': 'NHQ-1NP-1A162',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A163': {
            'name': 'Kancelář',
            'code': '1A163',
            'svgId': '1A163',
            'id': 'NHQ-1NP-1A163',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A164': {
            'name': 'Kancelář',
            'code': '1A164',
            'svgId': '1A164',
            'id': 'NHQ-1NP-1A164',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A165': {
            'name': 'Kancelář',
            'code': '1A165',
            'svgId': '1A165',
            'id': 'NHQ-1NP-1A165',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A166': {
            'name': 'Flexibilní zóna',
            'code': '1A166',
            'svgId': '1A166',
            'id': 'NHQ-1NP-1A166',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A166A': {
            'name': 'Flexibilní zóna',
            'code': '1A166A',
            'svgId': '1A166A',
            'id': 'NHQ-1NP-1A166A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A167': {
            'name': 'Flexibilní zóna',
            'code': '1A167',
            'svgId': '1A167',
            'id': 'NHQ-1NP-1A167',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A167A': {
            'name': 'Flexibilní zóna',
            'code': '1A167A',
            'svgId': '1A167A',
            'id': 'NHQ-1NP-1A167A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1A168': {
            'name': 'Kancelář',
            'code': '1A168',
            'svgId': '1A168',
            'id': 'NHQ-1NP-1A168',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B202': {
            'name': 'WC muži',
            'code': '1B202',
            'svgId': '1B202',
            'id': 'NHQ-1NP-1B202',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B203': {
            'name': 'WC muži imobil',
            'code': '1B203',
            'svgId': '1B203',
            'id': 'NHQ-1NP-1B203',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B204': {
            'name': 'WC muži',
            'code': '1B204',
            'svgId': '1B204',
            'id': 'NHQ-1NP-1B204',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B205': {
            'name': 'WC muži',
            'code': '1B205',
            'svgId': '1B205',
            'id': 'NHQ-1NP-1B205',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B206': {
            'name': 'WC muži',
            'code': '1B206',
            'svgId': '1B206',
            'id': 'NHQ-1NP-1B206',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B207': {
            'name': 'WC ženy',
            'code': '1B207',
            'svgId': '1B207',
            'id': 'NHQ-1NP-1B207',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B208': {
            'name': 'WC ženy',
            'code': '1B208',
            'svgId': '1B208',
            'id': 'NHQ-1NP-1B208',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B209': {
            'name': 'WC ženy',
            'code': '1B209',
            'svgId': '1B209',
            'id': 'NHQ-1NP-1B209',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B210': {
            'name': 'WC ženy imobil',
            'code': '1B210',
            'svgId': '1B210',
            'id': 'NHQ-1NP-1B210',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B211': {
            'name': 'WC ženy',
            'code': '1B211',
            'svgId': '1B211',
            'id': 'NHQ-1NP-1B211',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B214': {
            'name': 'Předsíň výtahu',
            'code': '1B214',
            'svgId': '1B214',
            'id': 'NHQ-1NP-1B214',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B215': {
            'name': 'Výtah evakuační',
            'code': '1B215',
            'svgId': '1B215',
            'id': 'NHQ-1NP-1B215',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B216': {
            'name': 'Příruční spisovna',
            'code': '1B216',
            'svgId': '1B216',
            'id': 'NHQ-1NP-1B216',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B217': {
            'name': 'Chodba',
            'code': '1B217',
            'svgId': '1B217',
            'id': 'NHQ-1NP-1B217',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B218': {
            'name': 'Chodba',
            'code': '1B218',
            'svgId': '1B218',
            'id': 'NHQ-1NP-1B218',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B219': {
            'name': 'Jednací místnost',
            'code': '1B219',
            'svgId': '1B219',
            'id': 'NHQ-1NP-1B219',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B220': {
            'name': 'Jednací místnost',
            'code': '1B220',
            'svgId': '1B220',
            'id': 'NHQ-1NP-1B220',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B221': {
            'name': 'Šatna',
            'code': '1B221',
            'svgId': '1B221',
            'id': 'NHQ-1NP-1B221',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B224': {
            'name': 'Kancelář',
            'code': '1B224',
            'svgId': '1B224',
            'id': 'NHQ-1NP-1B224',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B225': {
            'name': 'Kancelář',
            'code': '1B225',
            'svgId': '1B225',
            'id': 'NHQ-1NP-1B225',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B226': {
            'name': 'Kancelář',
            'code': '1B226',
            'svgId': '1B226',
            'id': 'NHQ-1NP-1B226',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B241': {
            'name': 'Schodiště',
            'code': '1B241',
            'svgId': '1B241',
            'id': 'NHQ-1NP-1B241',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B242': {
            'name': 'Výtah',
            'code': '1B242',
            'svgId': '1B242',
            'id': 'NHQ-1NP-1B242',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B243': {
            'name': 'Kuchyňka',
            'code': '1B243',
            'svgId': '1B243',
            'id': 'NHQ-1NP-1B243',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B244': {
            'name': 'Chodba',
            'code': '1B244',
            'svgId': '1B244',
            'id': 'NHQ-1NP-1B244',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B245': {
            'name': 'Chodba',
            'code': '1B245',
            'svgId': '1B245',
            'id': 'NHQ-1NP-1B245',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B246': {
            'name': 'Jednací místnost - Bukurešť',
            'code': '1B246',
            'svgId': '1B246',
            'id': 'NHQ-1NP-1B246',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B247': {
            'name': 'Šatna',
            'code': '1B247',
            'svgId': '1B247',
            'id': 'NHQ-1NP-1B247',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B248': {
            'name': 'Předsíň soc. zařízení',
            'code': '1B248',
            'svgId': '1B248',
            'id': 'NHQ-1NP-1B248',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B251': {
            'name': 'Sprcha',
            'code': '1B251',
            'svgId': '1B251',
            'id': 'NHQ-1NP-1B251',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B252': {
            'name': 'Jednací místnost - Ankara',
            'code': '1B252',
            'svgId': '1B252',
            'id': 'NHQ-1NP-1B252',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B253': {
            'name': 'Jednací místnost - Kišiněv',
            'code': '1B253',
            'svgId': '1B253',
            'id': 'NHQ-1NP-1B253',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B254': {
            'name': 'Jednací místnost',
            'code': '1B254',
            'svgId': '1B254',
            'id': 'NHQ-1NP-1B254',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B255': {
            'name': 'Jednací místnost',
            'code': '1B255',
            'svgId': '1B255',
            'id': 'NHQ-1NP-1B255',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B256': {
            'name': 'Jednací místnost',
            'code': '1B256',
            'svgId': '1B256',
            'id': 'NHQ-1NP-1B256',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B257': {
            'name': 'Kancelář',
            'code': '1B257',
            'svgId': '1B257',
            'id': 'NHQ-1NP-1B257',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B258': {
            'name': 'Jednací místnost',
            'code': '1B258',
            'svgId': '1B258',
            'id': 'NHQ-1NP-1B258',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1B259': {
            'name': 'Kancelář',
            'code': '1B259',
            'svgId': '1B259',
            'id': 'NHQ-1NP-1B259',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C301': {
            'name': 'Schodiště',
            'code': '1C301',
            'svgId': '1C301',
            'id': 'NHQ-1NP-1C301',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C302': {
            'name': 'Výtah',
            'code': '1C302',
            'svgId': '1C302',
            'id': 'NHQ-1NP-1C302',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C303': {
            'name': 'Kuchyňka',
            'code': '1C303',
            'svgId': '1C303',
            'id': 'NHQ-1NP-1C303',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C304': {
            'name': 'Chodba u výtahu C',
            'code': '1C304',
            'svgId': '1C304',
            'id': 'NHQ-1NP-1C304',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C305': {
            'name': 'Chodba',
            'code': '1C305',
            'svgId': '1C305',
            'id': 'NHQ-1NP-1C305',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C307': {
            'name': 'Předsíň soc. zařízení',
            'code': '1C307',
            'svgId': '1C307',
            'id': 'NHQ-1NP-1C307',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C309': {
            'name': 'Sprcha',
            'code': '1C309',
            'svgId': '1C309',
            'id': 'NHQ-1NP-1C309',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C310': {
            'name': 'ČP – Czech point',
            'code': '1C310',
            'svgId': '1C310',
            'id': 'NHQ-1NP-1C310',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C311': {
            'name': 'Šatna',
            'code': '1C311',
            'svgId': '1C311',
            'id': 'NHQ-1NP-1C311',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C312': {
            'name': 'Chodba',
            'code': '1C312',
            'svgId': '1C312',
            'id': 'NHQ-1NP-1C312',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C313': {
            'name': 'Kancelář',
            'code': '1C313',
            'svgId': '1C313',
            'id': 'NHQ-1NP-1C313',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C314': {
            'name': 'Jednací místnost',
            'code': '1C314',
            'svgId': '1C314',
            'id': 'NHQ-1NP-1C314',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C315': {
            'name': 'Česká pošta',
            'code': '1C315',
            'svgId': '1C315',
            'id': 'NHQ-1NP-1C315',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C330': {
            'name': 'Výtah',
            'code': '1C330',
            'svgId': '1C330',
            'id': 'NHQ-1NP-1C330',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C331': {
            'name': 'Předsíň výtahu',
            'code': '1C331',
            'svgId': '1C331',
            'id': 'NHQ-1NP-1C331',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C332': {
            'name': 'Česká pošta',
            'code': '1C332',
            'svgId': '1C332',
            'id': 'NHQ-1NP-1C332',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C333': {
            'name': 'Česká pošta',
            'code': '1C333',
            'svgId': '1C333',
            'id': 'NHQ-1NP-1C333',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C342': {
            'name': 'WC muži',
            'code': '1C342',
            'svgId': '1C342',
            'id': 'NHQ-1NP-1C342',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C343': {
            'name': 'WC muži imobil',
            'code': '1C343',
            'svgId': '1C343',
            'id': 'NHQ-1NP-1C343',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C344': {
            'name': 'WC muži',
            'code': '1C344',
            'svgId': '1C344',
            'id': 'NHQ-1NP-1C344',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C345': {
            'name': 'WC muži',
            'code': '1C345',
            'svgId': '1C345',
            'id': 'NHQ-1NP-1C345',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C346': {
            'name': 'WC muži',
            'code': '1C346',
            'svgId': '1C346',
            'id': 'NHQ-1NP-1C346',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C347': {
            'name': 'WC ženy',
            'code': '1C347',
            'svgId': '1C347',
            'id': 'NHQ-1NP-1C347',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C348': {
            'name': 'WC ženy',
            'code': '1C348',
            'svgId': '1C348',
            'id': 'NHQ-1NP-1C348',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C349': {
            'name': 'WC ženy',
            'code': '1C349',
            'svgId': '1C349',
            'id': 'NHQ-1NP-1C349',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C350': {
            'name': 'WC ženy imobil',
            'code': '1C350',
            'svgId': '1C350',
            'id': 'NHQ-1NP-1C350',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C351': {
            'name': 'WC ženy',
            'code': '1C351',
            'svgId': '1C351',
            'id': 'NHQ-1NP-1C351',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C354': {
            'name': 'Kancelář',
            'code': '1C354',
            'svgId': '1C354',
            'id': 'NHQ-1NP-1C354',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C355': {
            'name': 'Chodba',
            'code': '1C355',
            'svgId': '1C355',
            'id': 'NHQ-1NP-1C355',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C356': {
            'name': 'WC ženy',
            'code': '1C356',
            'svgId': '1C356',
            'id': 'NHQ-1NP-1C356',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C357': {
            'name': 'WC ženy',
            'code': '1C357',
            'svgId': '1C357',
            'id': 'NHQ-1NP-1C357',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C358': {
            'name': 'WC ženy',
            'code': '1C358',
            'svgId': '1C358',
            'id': 'NHQ-1NP-1C358',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C359': {
            'name': 'WC ženy imobil',
            'code': '1C359',
            'svgId': '1C359',
            'id': 'NHQ-1NP-1C359',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C360': {
            'name': 'WC muži imobil',
            'code': '1C360',
            'svgId': '1C360',
            'id': 'NHQ-1NP-1C360',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C361': {
            'name': 'WC muži',
            'code': '1C361',
            'svgId': '1C361',
            'id': 'NHQ-1NP-1C361',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C362': {
            'name': 'WC muži',
            'code': '1C362',
            'svgId': '1C362',
            'id': 'NHQ-1NP-1C362',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C363': {
            'name': 'WC muži',
            'code': '1C363',
            'svgId': '1C363',
            'id': 'NHQ-1NP-1C363',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C365': {
            'name': 'Kuchyňka',
            'code': '1C365',
            'svgId': '1C365',
            'id': 'NHQ-1NP-1C365',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C366': {
            'name': 'Odpočivná zóna',
            'code': '1C366',
            'svgId': '1C366',
            'id': 'NHQ-1NP-1C366',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C367': {
            'name': 'Chodba',
            'code': '1C367',
            'svgId': '1C367',
            'id': 'NHQ-1NP-1C367',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C368': {
            'name': 'Videokonferenční m.-Tokio',
            'code': '1C368',
            'svgId': '1C368',
            'id': 'NHQ-1NP-1C368',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C372': {
            'name': 'Jednací místnost - Stockholm',
            'code': '1C372',
            'svgId': '1C372',
            'id': 'NHQ-1NP-1C372',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C373': {
            'name': 'Jednací místnost - Varšava',
            'code': '1C373',
            'svgId': '1C373',
            'id': 'NHQ-1NP-1C373',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C374': {
            'name': 'Kancelář - Riga',
            'code': '1C374',
            'svgId': '1C374',
            'id': 'NHQ-1NP-1C374',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C375': {
            'name': 'Kancelář - Vilnius',
            'code': '1C375',
            'svgId': '1C375',
            'id': 'NHQ-1NP-1C375',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C376': {
            'name': 'Videokonferenční m.-Bratislava',
            'code': '1C376',
            'svgId': '1C376',
            'id': 'NHQ-1NP-1C376',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C377': {
            'name': 'Videokonferenční m. - Praha',
            'code': '1C377',
            'svgId': '1C377',
            'id': 'NHQ-1NP-1C377',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C380': {
            'name': 'Šatna',
            'code': '1C380',
            'svgId': '1C380',
            'id': 'NHQ-1NP-1C380',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C381': {
            'name': 'Pračka+sušička',
            'code': '1C381',
            'svgId': '1C381',
            'id': 'NHQ-1NP-1C381',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C383': {
            'name': 'Hygienické zázemí děti',
            'code': '1C383',
            'svgId': '1C383',
            'id': 'NHQ-1NP-1C383',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C384': {
            'name': 'WC zaměstnanci',
            'code': '1C384',
            'svgId': '1C384',
            'id': 'NHQ-1NP-1C384',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1C387': {
            'name': 'Herna',
            'code': '1C387',
            'svgId': '1C387',
            'id': 'NHQ-1NP-1C387',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D402': {
            'name': 'WC muži',
            'code': '1D402',
            'svgId': '1D402',
            'id': 'NHQ-1NP-1D402',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D403': {
            'name': 'WC muži imobil',
            'code': '1D403',
            'svgId': '1D403',
            'id': 'NHQ-1NP-1D403',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D404': {
            'name': 'WC muži',
            'code': '1D404',
            'svgId': '1D404',
            'id': 'NHQ-1NP-1D404',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D405': {
            'name': 'WC muži',
            'code': '1D405',
            'svgId': '1D405',
            'id': 'NHQ-1NP-1D405',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D406': {
            'name': 'WC muži',
            'code': '1D406',
            'svgId': '1D406',
            'id': 'NHQ-1NP-1D406',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D407': {
            'name': 'WC ženy',
            'code': '1D407',
            'svgId': '1D407',
            'id': 'NHQ-1NP-1D407',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D408': {
            'name': 'WC ženy',
            'code': '1D408',
            'svgId': '1D408',
            'id': 'NHQ-1NP-1D408',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D409': {
            'name': 'WC ženy',
            'code': '1D409',
            'svgId': '1D409',
            'id': 'NHQ-1NP-1D409',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D410': {
            'name': 'WC ženy imobil',
            'code': '1D410',
            'svgId': '1D410',
            'id': 'NHQ-1NP-1D410',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D411': {
            'name': 'WC ženy',
            'code': '1D411',
            'svgId': '1D411',
            'id': 'NHQ-1NP-1D411',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D414': {
            'name': 'Miniklub pro rodiče s dětmi',
            'code': '1D414',
            'svgId': '1D414',
            'id': 'NHQ-1NP-1D414',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D415': {
            'name': 'Chodba',
            'code': '1D415',
            'svgId': '1D415',
            'id': 'NHQ-1NP-1D415',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D416': {
            'name': 'Odpočivná zóna',
            'code': '1D416',
            'svgId': '1D416',
            'id': 'NHQ-1NP-1D416',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D417': {
            'name': 'Chodba',
            'code': '1D417',
            'svgId': '1D417',
            'id': 'NHQ-1NP-1D417',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D418': {
            'name': 'Jednací místnost - Peking',
            'code': '1D418',
            'svgId': '1D418',
            'id': 'NHQ-1NP-1D418',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D419': {
            'name': 'Jednací místnost - Tchaj Pej',
            'code': '1D419',
            'svgId': '1D419',
            'id': 'NHQ-1NP-1D419',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D420': {
            'name': 'Jednací místnost - Skopje',
            'code': '1D420',
            'svgId': '1D420',
            'id': 'NHQ-1NP-1D420',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D421': {
            'name': 'Jednací místnost - Tirana',
            'code': '1D421',
            'svgId': '1D421',
            'id': 'NHQ-1NP-1D421',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D422': {
            'name': 'Jednací místnost - Podgorica',
            'code': '1D422',
            'svgId': '1D422',
            'id': 'NHQ-1NP-1D422',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D423': {
            'name': 'Jednací místnost - Bělehrad',
            'code': '1D423',
            'svgId': '1D423',
            'id': 'NHQ-1NP-1D423',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D424': {
            'name': 'Jednací místnost - Záhřeb',
            'code': '1D424',
            'svgId': '1D424',
            'id': 'NHQ-1NP-1D424',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D425': {
            'name': 'Jednací místnost - Sarajevo',
            'code': '1D425',
            'svgId': '1D425',
            'id': 'NHQ-1NP-1D425',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D426': {
            'name': 'Jednací místnost - Singapur',
            'code': '1D426',
            'svgId': '1D426',
            'id': 'NHQ-1NP-1D426',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D441': {
            'name': 'Schodiště',
            'code': '1D441',
            'svgId': '1D441',
            'id': 'NHQ-1NP-1D441',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D442': {
            'name': 'Výtah',
            'code': '1D442',
            'svgId': '1D442',
            'id': 'NHQ-1NP-1D442',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D443': {
            'name': 'Kuchyňka',
            'code': '1D443',
            'svgId': '1D443',
            'id': 'NHQ-1NP-1D443',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D444': {
            'name': 'Chodba u výtahu D',
            'code': '1D444',
            'svgId': '1D444',
            'id': 'NHQ-1NP-1D444',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D445': {
            'name': 'Chodba',
            'code': '1D445',
            'svgId': '1D445',
            'id': 'NHQ-1NP-1D445',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D447': {
            'name': 'Předsíň soc. zařízení',
            'code': '1D447',
            'svgId': '1D447',
            'id': 'NHQ-1NP-1D447',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D449': {
            'name': 'Sprcha',
            'code': '1D449',
            'svgId': '1D449',
            'id': 'NHQ-1NP-1D449',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D451': {
            'name': 'Šatna',
            'code': '1D451',
            'svgId': '1D451',
            'id': 'NHQ-1NP-1D451',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D452': {
            'name': 'Chodba',
            'code': '1D452',
            'svgId': '1D452',
            'id': 'NHQ-1NP-1D452',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D453': {
            'name': 'Kancelář',
            'code': '1D453',
            'svgId': '1D453',
            'id': 'NHQ-1NP-1D453',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D453A': {
            'name': 'Kancelář',
            'code': '1D453A',
            'svgId': '1D453A',
            'id': 'NHQ-1NP-1D453A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D455': {
            'name': 'Surf studio',
            'code': '1D455',
            'svgId': '1D455',
            'id': 'NHQ-1NP-1D455',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D458': {
            'name': 'Flexibilní zóna',
            'code': '1D458',
            'svgId': '1D458',
            'id': 'NHQ-1NP-1D458',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D481': {
            'name': 'WC muži',
            'code': '1D481',
            'svgId': '1D481',
            'id': 'NHQ-1NP-1D481',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D482': {
            'name': 'WC muži',
            'code': '1D482',
            'svgId': '1D482',
            'id': 'NHQ-1NP-1D482',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D483': {
            'name': 'WC muži',
            'code': '1D483',
            'svgId': '1D483',
            'id': 'NHQ-1NP-1D483',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D484': {
            'name': 'WC muži',
            'code': '1D484',
            'svgId': '1D484',
            'id': 'NHQ-1NP-1D484',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D485': {
            'name': 'WC muži',
            'code': '1D485',
            'svgId': '1D485',
            'id': 'NHQ-1NP-1D485',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D486': {
            'name': 'WC ženy',
            'code': '1D486',
            'svgId': '1D486',
            'id': 'NHQ-1NP-1D486',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D487': {
            'name': 'WC ženy',
            'code': '1D487',
            'svgId': '1D487',
            'id': 'NHQ-1NP-1D487',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D488': {
            'name': 'WC ženy',
            'code': '1D488',
            'svgId': '1D488',
            'id': 'NHQ-1NP-1D488',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D489': {
            'name': 'WC ženy',
            'code': '1D489',
            'svgId': '1D489',
            'id': 'NHQ-1NP-1D489',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D491': {
            'name': 'Cafeteria WC',
            'code': '1D491',
            'svgId': '1D491',
            'id': 'NHQ-1NP-1D491',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D492': {
            'name': 'Cafeteria WC',
            'code': '1D492',
            'svgId': '1D492',
            'id': 'NHQ-1NP-1D492',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D494': {
            'name': 'Cafeteria šatna',
            'code': '1D494',
            'svgId': '1D494',
            'id': 'NHQ-1NP-1D494',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1D497': {
            'name': 'Cafeteria kuchyňka',
            'code': '1D497',
            'svgId': '1D497',
            'id': 'NHQ-1NP-1D497',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E501': {
            'name': 'Schodiště',
            'code': '1E501',
            'svgId': '1E501',
            'id': 'NHQ-1NP-1E501',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E502': {
            'name': 'Výtah',
            'code': '1E502',
            'svgId': '1E502',
            'id': 'NHQ-1NP-1E502',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E503': {
            'name': 'Kuchyňka',
            'code': '1E503',
            'svgId': '1E503',
            'id': 'NHQ-1NP-1E503',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E504': {
            'name': 'Chodba u výtahu E',
            'code': '1E504',
            'svgId': '1E504',
            'id': 'NHQ-1NP-1E504',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E505': {
            'name': 'Chodba',
            'code': '1E505',
            'svgId': '1E505',
            'id': 'NHQ-1NP-1E505',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E507': {
            'name': 'Předsíň soc. zařízení',
            'code': '1E507',
            'svgId': '1E507',
            'id': 'NHQ-1NP-1E507',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E509': {
            'name': 'Sprcha',
            'code': '1E509',
            'svgId': '1E509',
            'id': 'NHQ-1NP-1E509',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E510': {
            'name': 'Jednací místnost - Amsterdam',
            'code': '1E510',
            'svgId': '1E510',
            'id': 'NHQ-1NP-1E510',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E511': {
            'name': 'Šatna',
            'code': '1E511',
            'svgId': '1E511',
            'id': 'NHQ-1NP-1E511',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E512': {
            'name': 'Chodba',
            'code': '1E512',
            'svgId': '1E512',
            'id': 'NHQ-1NP-1E512',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E513': {
            'name': 'Kancelář',
            'code': '1E513',
            'svgId': '1E513',
            'id': 'NHQ-1NP-1E513',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E516': {
            'name': 'Jednací místnost - Brusel',
            'code': '1E516',
            'svgId': '1E516',
            'id': 'NHQ-1NP-1E516',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E517': {
            'name': 'Šatna',
            'code': '1E517',
            'svgId': '1E517',
            'id': 'NHQ-1NP-1E517',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E519': {
            'name': 'Šatna',
            'code': '1E519',
            'svgId': '1E519',
            'id': 'NHQ-1NP-1E519',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E520': {
            'name': 'Sprcha',
            'code': '1E520',
            'svgId': '1E520',
            'id': 'NHQ-1NP-1E520',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E521': {
            'name': 'WC',
            'code': '1E521',
            'svgId': '1E521',
            'id': 'NHQ-1NP-1E521',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E524': {
            'name': 'Hygienické zázemí děti',
            'code': '1E524',
            'svgId': '1E524',
            'id': 'NHQ-1NP-1E524',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E525': {
            'name': 'WC zaměstnanci',
            'code': '1E525',
            'svgId': '1E525',
            'id': 'NHQ-1NP-1E525',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E526': {
            'name': 'Chodba',
            'code': '1E526',
            'svgId': '1E526',
            'id': 'NHQ-1NP-1E526',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E528': {
            'name': 'Odkládací zóna',
            'code': '1E528',
            'svgId': '1E528',
            'id': 'NHQ-1NP-1E528',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E529': {
            'name': 'Herna',
            'code': '1E529',
            'svgId': '1E529',
            'id': 'NHQ-1NP-1E529',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E540': {
            'name': 'Terasa',
            'code': '1E540',
            'svgId': '1E540',
            'id': 'NHQ-1NP-1E540',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E542': {
            'name': 'WC muži',
            'code': '1E542',
            'svgId': '1E542',
            'id': 'NHQ-1NP-1E542',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E543': {
            'name': 'WC muži imobil',
            'code': '1E543',
            'svgId': '1E543',
            'id': 'NHQ-1NP-1E543',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E544': {
            'name': 'WC muži',
            'code': '1E544',
            'svgId': '1E544',
            'id': 'NHQ-1NP-1E544',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E545': {
            'name': 'WC muži',
            'code': '1E545',
            'svgId': '1E545',
            'id': 'NHQ-1NP-1E545',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E546': {
            'name': 'WC muži',
            'code': '1E546',
            'svgId': '1E546',
            'id': 'NHQ-1NP-1E546',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E547': {
            'name': 'WC ženy',
            'code': '1E547',
            'svgId': '1E547',
            'id': 'NHQ-1NP-1E547',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E548': {
            'name': 'WC ženy',
            'code': '1E548',
            'svgId': '1E548',
            'id': 'NHQ-1NP-1E548',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E549': {
            'name': 'WC ženy',
            'code': '1E549',
            'svgId': '1E549',
            'id': 'NHQ-1NP-1E549',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E550': {
            'name': 'WC ženy imobil',
            'code': '1E550',
            'svgId': '1E550',
            'id': 'NHQ-1NP-1E550',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E551': {
            'name': 'WC ženy',
            'code': '1E551',
            'svgId': '1E551',
            'id': 'NHQ-1NP-1E551',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E554': {
            'name': 'Předsíň výtahu',
            'code': '1E554',
            'svgId': '1E554',
            'id': 'NHQ-1NP-1E554',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E555': {
            'name': 'Výtah evakuační',
            'code': '1E555',
            'svgId': '1E555',
            'id': 'NHQ-1NP-1E555',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E556': {
            'name': 'Příruční spisovna',
            'code': '1E556',
            'svgId': '1E556',
            'id': 'NHQ-1NP-1E556',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E557': {
            'name': 'Chodba',
            'code': '1E557',
            'svgId': '1E557',
            'id': 'NHQ-1NP-1E557',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E558': {
            'name': 'Jednací místnost',
            'code': '1E558',
            'svgId': '1E558',
            'id': 'NHQ-1NP-1E558',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E559': {
            'name': 'Kancelář',
            'code': '1E559',
            'svgId': '1E559',
            'id': 'NHQ-1NP-1E559',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E560': {
            'name': 'Kancelář',
            'code': '1E560',
            'svgId': '1E560',
            'id': 'NHQ-1NP-1E560',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E560A': {
            'name': 'Kancelář',
            'code': '1E560A',
            'svgId': '1E560A',
            'id': 'NHQ-1NP-1E560A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E561': {
            'name': 'Jednací místnost',
            'code': '1E561',
            'svgId': '1E561',
            'id': 'NHQ-1NP-1E561',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E562': {
            'name': 'Jednací místnost',
            'code': '1E562',
            'svgId': '1E562',
            'id': 'NHQ-1NP-1E562',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E565': {
            'name': 'Čekárna',
            'code': '1E565',
            'svgId': '1E565',
            'id': 'NHQ-1NP-1E565',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E565A': {
            'name': 'Chodba',
            'code': '1E565A',
            'svgId': '1E565A',
            'id': 'NHQ-1NP-1E565A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E566': {
            'name': 'WC imobil',
            'code': '1E566',
            'svgId': '1E566',
            'id': 'NHQ-1NP-1E566',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E567': {
            'name': 'WC',
            'code': '1E567',
            'svgId': '1E567',
            'id': 'NHQ-1NP-1E567',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E568': {
            'name': 'WC',
            'code': '1E568',
            'svgId': '1E568',
            'id': 'NHQ-1NP-1E568',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E569': {
            'name': 'WC',
            'code': '1E569',
            'svgId': '1E569',
            'id': 'NHQ-1NP-1E569',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E570': {
            'name': 'WC',
            'code': '1E570',
            'svgId': '1E570',
            'id': 'NHQ-1NP-1E570',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E571': {
            'name': 'Ordinace',
            'code': '1E571',
            'svgId': '1E571',
            'id': 'NHQ-1NP-1E571',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E572': {
            'name': 'Denní místnost',
            'code': '1E572',
            'svgId': '1E572',
            'id': 'NHQ-1NP-1E572',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E573': {
            'name': 'Ordinace',
            'code': '1E573',
            'svgId': '1E573',
            'id': 'NHQ-1NP-1E573',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E574': {
            'name': 'Ordinace',
            'code': '1E574',
            'svgId': '1E574',
            'id': 'NHQ-1NP-1E574',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E575': {
            'name': 'Ordinace',
            'code': '1E575',
            'svgId': '1E575',
            'id': 'NHQ-1NP-1E575',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E576': {
            'name': 'Chodba',
            'code': '1E576',
            'svgId': '1E576',
            'id': 'NHQ-1NP-1E576',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E577': {
            'name': 'Ordinace',
            'code': '1E577',
            'svgId': '1E577',
            'id': 'NHQ-1NP-1E577',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E578': {
            'name': 'Ordinace',
            'code': '1E578',
            'svgId': '1E578',
            'id': 'NHQ-1NP-1E578',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E579': {
            'name': 'Ordinace',
            'code': '1E579',
            'svgId': '1E579',
            'id': 'NHQ-1NP-1E579',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E580': {
            'name': 'Chodba',
            'code': '1E580',
            'svgId': '1E580',
            'id': 'NHQ-1NP-1E580',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E581': {
            'name': 'Šatna',
            'code': '1E581',
            'svgId': '1E581',
            'id': 'NHQ-1NP-1E581',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E582': {
            'name': 'WC',
            'code': '1E582',
            'svgId': '1E582',
            'id': 'NHQ-1NP-1E582',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E583': {
            'name': 'WC',
            'code': '1E583',
            'svgId': '1E583',
            'id': 'NHQ-1NP-1E583',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E584': {
            'name': 'Sprcha',
            'code': '1E584',
            'svgId': '1E584',
            'id': 'NHQ-1NP-1E584',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E586': {
            'name': 'Šatna',
            'code': '1E586',
            'svgId': '1E586',
            'id': 'NHQ-1NP-1E586',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E587': {
            'name': 'WC',
            'code': '1E587',
            'svgId': '1E587',
            'id': 'NHQ-1NP-1E587',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E588': {
            'name': 'Sprcha',
            'code': '1E588',
            'svgId': '1E588',
            'id': 'NHQ-1NP-1E588',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E589': {
            'name': 'WC',
            'code': '1E589',
            'svgId': '1E589',
            'id': 'NHQ-1NP-1E589',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E591': {
            'name': 'Příruční sterilizace',
            'code': '1E591',
            'svgId': '1E591',
            'id': 'NHQ-1NP-1E591',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E595': {
            'name': 'Výtah',
            'code': '1E595',
            'svgId': '1E595',
            'id': 'NHQ-1NP-1E595',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E597': {
            'name': 'Schodiště',
            'code': '1E597',
            'svgId': '1E597',
            'id': 'NHQ-1NP-1E597',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1E599': {
            'name': 'Chodba',
            'code': '1E599',
            'svgId': '1E599',
            'id': 'NHQ-1NP-1E599',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F602': {
            'name': 'WC muži',
            'code': '1F602',
            'svgId': '1F602',
            'id': 'NHQ-1NP-1F602',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F603': {
            'name': 'WC muži imobil',
            'code': '1F603',
            'svgId': '1F603',
            'id': 'NHQ-1NP-1F603',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F604': {
            'name': 'WC muži',
            'code': '1F604',
            'svgId': '1F604',
            'id': 'NHQ-1NP-1F604',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F605': {
            'name': 'WC muži',
            'code': '1F605',
            'svgId': '1F605',
            'id': 'NHQ-1NP-1F605',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F606': {
            'name': 'WC muži',
            'code': '1F606',
            'svgId': '1F606',
            'id': 'NHQ-1NP-1F606',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F607': {
            'name': 'WC ženy',
            'code': '1F607',
            'svgId': '1F607',
            'id': 'NHQ-1NP-1F607',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F608': {
            'name': 'WC ženy',
            'code': '1F608',
            'svgId': '1F608',
            'id': 'NHQ-1NP-1F608',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F609': {
            'name': 'WC ženy',
            'code': '1F609',
            'svgId': '1F609',
            'id': 'NHQ-1NP-1F609',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F610': {
            'name': 'WC ženy imobil',
            'code': '1F610',
            'svgId': '1F610',
            'id': 'NHQ-1NP-1F610',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F611': {
            'name': 'WC ženy',
            'code': '1F611',
            'svgId': '1F611',
            'id': 'NHQ-1NP-1F611',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F614': {
            'name': 'Projektová místnost',
            'code': '1F614',
            'svgId': '1F614',
            'id': 'NHQ-1NP-1F614',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F615': {
            'name': 'Chodba',
            'code': '1F615',
            'svgId': '1F615',
            'id': 'NHQ-1NP-1F615',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F616': {
            'name': 'Odpočivná zóna',
            'code': '1F616',
            'svgId': '1F616',
            'id': 'NHQ-1NP-1F616',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F617': {
            'name': 'Chodba',
            'code': '1F617',
            'svgId': '1F617',
            'id': 'NHQ-1NP-1F617',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F618': {
            'name': 'Jednací místnost - Síň slávy',
            'code': '1F618',
            'svgId': '1F618',
            'id': 'NHQ-1NP-1F618',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F619': {
            'name': 'Ecosphere Hub',
            'code': '1F619',
            'svgId': '1F619',
            'id': 'NHQ-1NP-1F619',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F620': {
            'name': 'Jednací místnost - Lublaň',
            'code': '1F620',
            'svgId': '1F620',
            'id': 'NHQ-1NP-1F620',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F621': {
            'name': 'Jednací místnost - Valletta',
            'code': '1F621',
            'svgId': '1F621',
            'id': 'NHQ-1NP-1F621',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F622': {
            'name': 'Jednací místnost - San Marino',
            'code': '1F622',
            'svgId': '1F622',
            'id': 'NHQ-1NP-1F622',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F623': {
            'name': 'Jednací místnost - Řím',
            'code': '1F623',
            'svgId': '1F623',
            'id': 'NHQ-1NP-1F623',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F624': {
            'name': 'Jednací místnost - Vatikán',
            'code': '1F624',
            'svgId': '1F624',
            'id': 'NHQ-1NP-1F624',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F625': {
            'name': 'Jednací místnost - Monako',
            'code': '1F625',
            'svgId': '1F625',
            'id': 'NHQ-1NP-1F625',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F626': {
            'name': 'Jednací místnost - Hongkong',
            'code': '1F626',
            'svgId': '1F626',
            'id': 'NHQ-1NP-1F626',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F641': {
            'name': 'Schodiště',
            'code': '1F641',
            'svgId': '1F641',
            'id': 'NHQ-1NP-1F641',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F642': {
            'name': 'Výtah',
            'code': '1F642',
            'svgId': '1F642',
            'id': 'NHQ-1NP-1F642',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F643': {
            'name': 'Kuchyňka',
            'code': '1F643',
            'svgId': '1F643',
            'id': 'NHQ-1NP-1F643',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F644': {
            'name': 'Chodba u výtahu F',
            'code': '1F644',
            'svgId': '1F644',
            'id': 'NHQ-1NP-1F644',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F645': {
            'name': 'Chodba',
            'code': '1F645',
            'svgId': '1F645',
            'id': 'NHQ-1NP-1F645',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F647': {
            'name': 'Předsíň soc. zařízení',
            'code': '1F647',
            'svgId': '1F647',
            'id': 'NHQ-1NP-1F647',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F649': {
            'name': 'Sprcha',
            'code': '1F649',
            'svgId': '1F649',
            'id': 'NHQ-1NP-1F649',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F650': {
            'name': 'Jednací místnost - Madrid',
            'code': '1F650',
            'svgId': '1F650',
            'id': 'NHQ-1NP-1F650',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F651': {
            'name': 'Šatna',
            'code': '1F651',
            'svgId': '1F651',
            'id': 'NHQ-1NP-1F651',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F652': {
            'name': 'Chodba',
            'code': '1F652',
            'svgId': '1F652',
            'id': 'NHQ-1NP-1F652',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F653': {
            'name': 'Kancelář',
            'code': '1F653',
            'svgId': '1F653',
            'id': 'NHQ-1NP-1F653',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F654': {
            'name': 'Jednací místnost',
            'code': '1F654',
            'svgId': '1F654',
            'id': 'NHQ-1NP-1F654',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F655': {
            'name': 'Kancelář',
            'code': '1F655',
            'svgId': '1F655',
            'id': 'NHQ-1NP-1F655',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F656': {
            'name': 'Jednací místnost',
            'code': '1F656',
            'svgId': '1F656',
            'id': 'NHQ-1NP-1F656',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F681': {
            'name': 'WC muži',
            'code': '1F681',
            'svgId': '1F681',
            'id': 'NHQ-1NP-1F681',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F682': {
            'name': 'WC muži',
            'code': '1F682',
            'svgId': '1F682',
            'id': 'NHQ-1NP-1F682',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F683': {
            'name': 'WC muži',
            'code': '1F683',
            'svgId': '1F683',
            'id': 'NHQ-1NP-1F683',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F684': {
            'name': 'WC muži',
            'code': '1F684',
            'svgId': '1F684',
            'id': 'NHQ-1NP-1F684',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F685': {
            'name': 'WC muži',
            'code': '1F685',
            'svgId': '1F685',
            'id': 'NHQ-1NP-1F685',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F686': {
            'name': 'WC ženy',
            'code': '1F686',
            'svgId': '1F686',
            'id': 'NHQ-1NP-1F686',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F687': {
            'name': 'WC ženy',
            'code': '1F687',
            'svgId': '1F687',
            'id': 'NHQ-1NP-1F687',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F688': {
            'name': 'WC ženy',
            'code': '1F688',
            'svgId': '1F688',
            'id': 'NHQ-1NP-1F688',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F689': {
            'name': 'WC ženy',
            'code': '1F689',
            'svgId': '1F689',
            'id': 'NHQ-1NP-1F689',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1F692': {
            'name': 'Kuchyňka',
            'code': '1F692',
            'svgId': '1F692',
            'id': 'NHQ-1NP-1F692',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U701': {
            'name': 'Ulice',
            'code': '1U701',
            'svgId': '1U701',
            'id': 'NHQ-1NP-1U701',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U708': {
            'name': 'Vstup',
            'code': '1U708',
            'svgId': '1U708',
            'id': 'NHQ-1NP-1U708',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U709': {
            'name': 'Chodba',
            'code': '1U709',
            'svgId': '1U709',
            'id': 'NHQ-1NP-1U709',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U710': {
            'name': 'Výtah',
            'code': '1U710',
            'svgId': '1U710',
            'id': 'NHQ-1NP-1U710',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U711': {
            'name': 'Schodiště',
            'code': '1U711',
            'svgId': '1U711',
            'id': 'NHQ-1NP-1U711',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U712': {
            'name': 'Cafeteria',
            'code': '1U712',
            'svgId': '1U712',
            'id': 'NHQ-1NP-1U712',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U713': {
            'name': 'Informační stánek',
            'code': '1U713',
            'svgId': '1U713',
            'id': 'NHQ-1NP-1U713',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U714': {
            'name': 'Schodiště',
            'code': '1U714',
            'svgId': '1U714',
            'id': 'NHQ-1NP-1U714',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U715': {
            'name': 'Bazén',
            'code': '1U715',
            'svgId': '1U715',
            'id': 'NHQ-1NP-1U715',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U716': {
            'name': 'Bazén',
            'code': '1U716',
            'svgId': '1U716',
            'id': 'NHQ-1NP-1U716',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U717': {
            'name': 'Dracenový háj',
            'code': '1U717',
            'svgId': '1U717',
            'id': 'NHQ-1NP-1U717',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U718': {
            'name': 'Dracenový háj',
            'code': '1U718',
            'svgId': '1U718',
            'id': 'NHQ-1NP-1U718',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U719': {
            'name': 'Odpočivná zóna',
            'code': '1U719',
            'svgId': '1U719',
            'id': 'NHQ-1NP-1U719',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U720': {
            'name': 'Recepce',
            'code': '1U720',
            'svgId': '1U720',
            'id': 'NHQ-1NP-1U720',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-1U721': {
            'name': 'Vstup západ',
            'code': '1U721',
            'svgId': '1U721',
            'id': 'NHQ-1NP-1U721',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-198_R': {
            'name': 'Směrovka NHQ',
            'code': '198_R',
            'svgId': '198_R',
            'id': 'NHQ-1NP-198_R',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-A4_PCZNHQ2034': {
            'name': 'Tiskárna A4 / 1B',
            'code': 'A4_PCZNHQ2034',
            'svgId': 'A4_PCZNHQ2034',
            'id': 'NHQ-1NP-A4_PCZNHQ2034',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-A3_PCZNHQ2028': {
            'name': 'Tiskárna A3 / 1B',
            'code': 'A3_PCZNHQ2028',
            'svgId': 'A3_PCZNHQ2028',
            'id': 'NHQ-1NP-A3_PCZNHQ2028',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-A4_PCZNHQ2040': {
            'name': 'Tiskárna A4 / 1B',
            'code': 'A4_PCZNHQ2040',
            'svgId': 'A4_PCZNHQ2040',
            'id': 'NHQ-1NP-A4_PCZNHQ2040',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-A4_PCZNHQ2035': {
            'name': 'Tiskárna A4 / 1B',
            'code': 'A4_PCZNHQ2035',
            'svgId': 'A4_PCZNHQ2035',
            'id': 'NHQ-1NP-A4_PCZNHQ2035',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-A4_PCZNHQ2022': {
            'name': 'Tiskárna A4 / 1D',
            'code': 'A4_PCZNHQ2022',
            'svgId': 'A4_PCZNHQ2022',
            'id': 'NHQ-1NP-A4_PCZNHQ2022',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-A4_PCZNHQ2026': {
            'name': 'Tiskárna A4 / 1E',
            'code': 'A4_PCZNHQ2026',
            'svgId': 'A4_PCZNHQ2026',
            'id': 'NHQ-1NP-A4_PCZNHQ2026',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-A3_PCZNHQ2027': {
            'name': 'Tiskárna A3 / 1C',
            'code': 'A3_PCZNHQ2027',
            'svgId': 'A3_PCZNHQ2027',
            'id': 'NHQ-1NP-A3_PCZNHQ2027',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-A4_PCZNHQ2017': {
            'name': 'Tiskárna A4 / 1A',
            'code': 'A4_PCZNHQ2017',
            'svgId': 'A4_PCZNHQ2017',
            'id': 'NHQ-1NP-A4_PCZNHQ2017',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          },
          'NHQ-1NP-A4_PCZNHQ2009': {
            'name': 'Tiskárna A4 / 1A',
            'code': 'A4_PCZNHQ2009',
            'svgId': 'A4_PCZNHQ2009',
            'id': 'NHQ-1NP-A4_PCZNHQ2009',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1NP',
            'type': ''
          }
        }
      },
      'NHQ-1PP': {
        'name': '1. patro',
        'code': '1PP',
        'svgId': '1PP',
        'buildingId': 'NHQ',
        'id': 'NHQ-1PP',
        'rooms': {
          'NHQ-1PP-DA101': {
            'name': 'Schodiště',
            'code': 'DA101',
            'svgId': 'DA101',
            'id': 'NHQ-1PP-DA101',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA102': {
            'name': 'Výtah',
            'code': 'DA102',
            'svgId': 'DA102',
            'id': 'NHQ-1PP-DA102',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA103': {
            'name': 'Chodba u výtahu A',
            'code': 'DA103',
            'svgId': 'DA103',
            'id': 'NHQ-1PP-DA103',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA104': {
            'name': 'Chodba',
            'code': 'DA104',
            'svgId': 'DA104',
            'id': 'NHQ-1PP-DA104',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA105': {
            'name': 'Schodiště',
            'code': 'DA105',
            'svgId': 'DA105',
            'id': 'NHQ-1PP-DA105',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA106': {
            'name': 'Chodba',
            'code': 'DA106',
            'svgId': 'DA106',
            'id': 'NHQ-1PP-DA106',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA109': {
            'name': 'Jednací místnost',
            'code': 'DA109',
            'svgId': 'DA109',
            'id': 'NHQ-1PP-DA109',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA110': {
            'name': 'Umyvárna',
            'code': 'DA110',
            'svgId': 'DA110',
            'id': 'NHQ-1PP-DA110',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA111': {
            'name': 'Sprcha',
            'code': 'DA111',
            'svgId': 'DA111',
            'id': 'NHQ-1PP-DA111',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA113': {
            'name': 'WC muži',
            'code': 'DA113',
            'svgId': 'DA113',
            'id': 'NHQ-1PP-DA113',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA114': {
            'name': 'WC muži',
            'code': 'DA114',
            'svgId': 'DA114',
            'id': 'NHQ-1PP-DA114',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA115': {
            'name': 'WC muži',
            'code': 'DA115',
            'svgId': 'DA115',
            'id': 'NHQ-1PP-DA115',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA116': {
            'name': 'WC muži',
            'code': 'DA116',
            'svgId': 'DA116',
            'id': 'NHQ-1PP-DA116',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA117': {
            'name': 'WC ženy',
            'code': 'DA117',
            'svgId': 'DA117',
            'id': 'NHQ-1PP-DA117',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA118': {
            'name': 'WC ženy',
            'code': 'DA118',
            'svgId': 'DA118',
            'id': 'NHQ-1PP-DA118',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA119': {
            'name': 'WC ženy',
            'code': 'DA119',
            'svgId': 'DA119',
            'id': 'NHQ-1PP-DA119',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA120': {
            'name': 'WC ženy',
            'code': 'DA120',
            'svgId': 'DA120',
            'id': 'NHQ-1PP-DA120',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA121': {
            'name': 'Kuchyňka',
            'code': 'DA121',
            'svgId': 'DA121',
            'id': 'NHQ-1PP-DA121',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA122': {
            'name': 'Chodba',
            'code': 'DA122',
            'svgId': 'DA122',
            'id': 'NHQ-1PP-DA122',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA124': {
            'name': 'Kancelář',
            'code': 'DA124',
            'svgId': 'DA124',
            'id': 'NHQ-1PP-DA124',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA125': {
            'name': 'Odpočivná zóna',
            'code': 'DA125',
            'svgId': 'DA125',
            'id': 'NHQ-1PP-DA125',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA126': {
            'name': 'Jednací místnost - Dublin',
            'code': 'DA126',
            'svgId': 'DA126',
            'id': 'NHQ-1PP-DA126',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA127': {
            'name': 'Jednací místnost',
            'code': 'DA127',
            'svgId': 'DA127',
            'id': 'NHQ-1PP-DA127',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA128': {
            'name': 'Jednací místnost',
            'code': 'DA128',
            'svgId': 'DA128',
            'id': 'NHQ-1PP-DA128',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA129': {
            'name': 'Nábor',
            'code': 'DA129',
            'svgId': 'DA129',
            'id': 'NHQ-1PP-DA129',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA130': {
            'name': 'Kancelář',
            'code': 'DA130',
            'svgId': 'DA130',
            'id': 'NHQ-1PP-DA130',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA140': {
            'name': 'Vstup',
            'code': 'DA140',
            'svgId': 'DA140',
            'id': 'NHQ-1PP-DA140',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA141': {
            'name': 'Vstup',
            'code': 'DA141',
            'svgId': 'DA141',
            'id': 'NHQ-1PP-DA141',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA142': {
            'name': 'Chodba',
            'code': 'DA142',
            'svgId': 'DA142',
            'id': 'NHQ-1PP-DA142',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA143': {
            'name': 'Výtah',
            'code': 'DA143',
            'svgId': 'DA143',
            'id': 'NHQ-1PP-DA143',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA144': {
            'name': 'Výtah',
            'code': 'DA144',
            'svgId': 'DA144',
            'id': 'NHQ-1PP-DA144',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA145': {
            'name': 'Mytí stolního nádobí',
            'code': 'DA145',
            'svgId': 'DA145',
            'id': 'NHQ-1PP-DA145',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA146': {
            'name': 'Regenerace',
            'code': 'DA146',
            'svgId': 'DA146',
            'id': 'NHQ-1PP-DA146',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA147': {
            'name': 'Bufet s výdejem',
            'code': 'DA147',
            'svgId': 'DA147',
            'id': 'NHQ-1PP-DA147',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DA151': {
            'name': 'Jídelna',
            'code': 'DA151',
            'svgId': 'DA151',
            'id': 'NHQ-1PP-DA151',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB201': {
            'name': 'Výtah evakuační',
            'code': 'DB201',
            'svgId': 'DB201',
            'id': 'NHQ-1PP-DB201',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB202': {
            'name': 'Předsíň výtahu',
            'code': 'DB202',
            'svgId': 'DB202',
            'id': 'NHQ-1PP-DB202',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB203': {
            'name': 'Chodba',
            'code': 'DB203',
            'svgId': 'DB203',
            'id': 'NHQ-1PP-DB203',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB205': {
            'name': 'Chodba',
            'code': 'DB205',
            'svgId': 'DB205',
            'id': 'NHQ-1PP-DB205',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB206': {
            'name': 'Jednací místnost-POUZE DEALING',
            'code': 'DB206',
            'svgId': 'DB206',
            'id': 'NHQ-1PP-DB206',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB207': {
            'name': 'Šatna muži',
            'code': 'DB207',
            'svgId': 'DB207',
            'id': 'NHQ-1PP-DB207',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB208': {
            'name': 'Sprcha',
            'code': 'DB208',
            'svgId': 'DB208',
            'id': 'NHQ-1PP-DB208',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB209': {
            'name': 'WC',
            'code': 'DB209',
            'svgId': 'DB209',
            'id': 'NHQ-1PP-DB209',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB210': {
            'name': 'Šatna ženy',
            'code': 'DB210',
            'svgId': 'DB210',
            'id': 'NHQ-1PP-DB210',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB211': {
            'name': 'Sprcha + WC',
            'code': 'DB211',
            'svgId': 'DB211',
            'id': 'NHQ-1PP-DB211',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB216': {
            'name': 'Tiskové centrum',
            'code': 'DB216',
            'svgId': 'DB216',
            'id': 'NHQ-1PP-DB216',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB217': {
            'name': 'Jednací místnost-POUZE DEALING',
            'code': 'DB217',
            'svgId': 'DB217',
            'id': 'NHQ-1PP-DB217',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB218': {
            'name': 'Jednací místrnost - POUZE DEALING',
            'code': 'DB218',
            'svgId': 'DB218',
            'id': 'NHQ-1PP-DB218',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB219': {
            'name': 'Kancelář',
            'code': 'DB219',
            'svgId': 'DB219',
            'id': 'NHQ-1PP-DB219',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB220': {
            'name': 'Dealing',
            'code': 'DB220',
            'svgId': 'DB220',
            'id': 'NHQ-1PP-DB220',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB221': {
            'name': 'Kuchyňka',
            'code': 'DB221',
            'svgId': 'DB221',
            'id': 'NHQ-1PP-DB221',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB225': {
            'name': 'Chodba',
            'code': 'DB225',
            'svgId': 'DB225',
            'id': 'NHQ-1PP-DB225',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB227': {
            'name': 'WC ženy',
            'code': 'DB227',
            'svgId': 'DB227',
            'id': 'NHQ-1PP-DB227',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB228': {
            'name': 'WC ženy',
            'code': 'DB228',
            'svgId': 'DB228',
            'id': 'NHQ-1PP-DB228',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB230': {
            'name': 'WC ženy imobil',
            'code': 'DB230',
            'svgId': 'DB230',
            'id': 'NHQ-1PP-DB230',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB231': {
            'name': 'WC muži',
            'code': 'DB231',
            'svgId': 'DB231',
            'id': 'NHQ-1PP-DB231',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB232': {
            'name': 'WC muži',
            'code': 'DB232',
            'svgId': 'DB232',
            'id': 'NHQ-1PP-DB232',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB233': {
            'name': 'WC muži',
            'code': 'DB233',
            'svgId': 'DB233',
            'id': 'NHQ-1PP-DB233',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB234': {
            'name': 'WC muži',
            'code': 'DB234',
            'svgId': 'DB234',
            'id': 'NHQ-1PP-DB234',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB235': {
            'name': 'WC muži',
            'code': 'DB235',
            'svgId': 'DB235',
            'id': 'NHQ-1PP-DB235',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB236': {
            'name': 'WC muži',
            'code': 'DB236',
            'svgId': 'DB236',
            'id': 'NHQ-1PP-DB236',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB237': {
            'name': 'Kancelář',
            'code': 'DB237',
            'svgId': 'DB237',
            'id': 'NHQ-1PP-DB237',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB238': {
            'name': 'WC muži imobil',
            'code': 'DB238',
            'svgId': 'DB238',
            'id': 'NHQ-1PP-DB238',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB241': {
            'name': 'Schodiště',
            'code': 'DB241',
            'svgId': 'DB241',
            'id': 'NHQ-1PP-DB241',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB242': {
            'name': 'Výtah',
            'code': 'DB242',
            'svgId': 'DB242',
            'id': 'NHQ-1PP-DB242',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB243': {
            'name': 'Chodba',
            'code': 'DB243',
            'svgId': 'DB243',
            'id': 'NHQ-1PP-DB243',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DB244': {
            'name': 'Chodba',
            'code': 'DB244',
            'svgId': 'DB244',
            'id': 'NHQ-1PP-DB244',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC301': {
            'name': 'Schodiště',
            'code': 'DC301',
            'svgId': 'DC301',
            'id': 'NHQ-1PP-DC301',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC302': {
            'name': 'Výtah',
            'code': 'DC302',
            'svgId': 'DC302',
            'id': 'NHQ-1PP-DC302',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC303': {
            'name': 'Chodba',
            'code': 'DC303',
            'svgId': 'DC303',
            'id': 'NHQ-1PP-DC303',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC304': {
            'name': 'Chodba',
            'code': 'DC304',
            'svgId': 'DC304',
            'id': 'NHQ-1PP-DC304',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC305': {
            'name': 'IT centrum',
            'code': 'DC305',
            'svgId': 'DC305',
            'id': 'NHQ-1PP-DC305',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC305A': {
            'name': 'IT centrum',
            'code': 'DC305A',
            'svgId': 'DC305A',
            'id': 'NHQ-1PP-DC305A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC308': {
            'name': 'Sprcha',
            'code': 'DC308',
            'svgId': 'DC308',
            'id': 'NHQ-1PP-DC308',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC309': {
            'name': 'Předsíň',
            'code': 'DC309',
            'svgId': 'DC309',
            'id': 'NHQ-1PP-DC309',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC312': {
            'name': 'WC ženy',
            'code': 'DC312',
            'svgId': 'DC312',
            'id': 'NHQ-1PP-DC312',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC313': {
            'name': 'WC ženy',
            'code': 'DC313',
            'svgId': 'DC313',
            'id': 'NHQ-1PP-DC313',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC314': {
            'name': 'WC ženy',
            'code': 'DC314',
            'svgId': 'DC314',
            'id': 'NHQ-1PP-DC314',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC315': {
            'name': 'WC ženy',
            'code': 'DC315',
            'svgId': 'DC315',
            'id': 'NHQ-1PP-DC315',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC316': {
            'name': 'WC ženy',
            'code': 'DC316',
            'svgId': 'DC316',
            'id': 'NHQ-1PP-DC316',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC317': {
            'name': 'WC muži',
            'code': 'DC317',
            'svgId': 'DC317',
            'id': 'NHQ-1PP-DC317',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC318': {
            'name': 'WC muži',
            'code': 'DC318',
            'svgId': 'DC318',
            'id': 'NHQ-1PP-DC318',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC319': {
            'name': 'WC muži',
            'code': 'DC319',
            'svgId': 'DC319',
            'id': 'NHQ-1PP-DC319',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC320': {
            'name': 'WC muži',
            'code': 'DC320',
            'svgId': 'DC320',
            'id': 'NHQ-1PP-DC320',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC321': {
            'name': 'WC muži',
            'code': 'DC321',
            'svgId': 'DC321',
            'id': 'NHQ-1PP-DC321',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC322': {
            'name': 'Chodba',
            'code': 'DC322',
            'svgId': 'DC322',
            'id': 'NHQ-1PP-DC322',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC326': {
            'name': 'Kuchyňka',
            'code': 'DC326',
            'svgId': 'DC326',
            'id': 'NHQ-1PP-DC326',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC327': {
            'name': 'Schodiště',
            'code': 'DC327',
            'svgId': 'DC327',
            'id': 'NHQ-1PP-DC327',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC328': {
            'name': 'Chodba',
            'code': 'DC328',
            'svgId': 'DC328',
            'id': 'NHQ-1PP-DC328',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC329': {
            'name': 'Chodba s bezp. propustí',
            'code': 'DC329',
            'svgId': 'DC329',
            'id': 'NHQ-1PP-DC329',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DC341': {
            'name': 'Výtah',
            'code': 'DC341',
            'svgId': 'DC341',
            'id': 'NHQ-1PP-DC341',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU701': {
            'name': 'Ulice v mezipatře',
            'code': 'DU701',
            'svgId': 'DU701',
            'id': 'NHQ-1PP-DU701',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU710': {
            'name': 'Výtah',
            'code': 'DU710',
            'svgId': 'DU710',
            'id': 'NHQ-1PP-DU710',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU711': {
            'name': 'Konferenční sál',
            'code': 'DU711',
            'svgId': 'DU711',
            'id': 'NHQ-1PP-DU711',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU712': {
            'name': 'Konferenční sál',
            'code': 'DU712',
            'svgId': 'DU712',
            'id': 'NHQ-1PP-DU712',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU713': {
            'name': 'Zázemí konfer. sálu',
            'code': 'DU713',
            'svgId': 'DU713',
            'id': 'NHQ-1PP-DU713',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU719': {
            'name': 'Zázemí konfer. sálu',
            'code': 'DU719',
            'svgId': 'DU719',
            'id': 'NHQ-1PP-DU719',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU720': {
            'name': 'Výtah',
            'code': 'DU720',
            'svgId': 'DU720',
            'id': 'NHQ-1PP-DU720',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU721': {
            'name': 'Předsíň',
            'code': 'DU721',
            'svgId': 'DU721',
            'id': 'NHQ-1PP-DU721',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU723': {
            'name': 'Schodiště',
            'code': 'DU723',
            'svgId': 'DU723',
            'id': 'NHQ-1PP-DU723',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU724': {
            'name': 'Pojízdné schody',
            'code': 'DU724',
            'svgId': 'DU724',
            'id': 'NHQ-1PP-DU724',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU725': {
            'name': 'Schodiště',
            'code': 'DU725',
            'svgId': 'DU725',
            'id': 'NHQ-1PP-DU725',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU726': {
            'name': 'WC ženy',
            'code': 'DU726',
            'svgId': 'DU726',
            'id': 'NHQ-1PP-DU726',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU727': {
            'name': 'WC ženy',
            'code': 'DU727',
            'svgId': 'DU727',
            'id': 'NHQ-1PP-DU727',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU728': {
            'name': 'WC ženy',
            'code': 'DU728',
            'svgId': 'DU728',
            'id': 'NHQ-1PP-DU728',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU729': {
            'name': 'WC ženy',
            'code': 'DU729',
            'svgId': 'DU729',
            'id': 'NHQ-1PP-DU729',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU730': {
            'name': 'WC ženy',
            'code': 'DU730',
            'svgId': 'DU730',
            'id': 'NHQ-1PP-DU730',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU731': {
            'name': 'WC ženy',
            'code': 'DU731',
            'svgId': 'DU731',
            'id': 'NHQ-1PP-DU731',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU732': {
            'name': 'WC ženy',
            'code': 'DU732',
            'svgId': 'DU732',
            'id': 'NHQ-1PP-DU732',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU733': {
            'name': 'Předsíň WC muži',
            'code': 'DU733',
            'svgId': 'DU733',
            'id': 'NHQ-1PP-DU733',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU734': {
            'name': 'WC muži',
            'code': 'DU734',
            'svgId': 'DU734',
            'id': 'NHQ-1PP-DU734',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU735': {
            'name': 'WC muži',
            'code': 'DU735',
            'svgId': 'DU735',
            'id': 'NHQ-1PP-DU735',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU736': {
            'name': 'WC muži',
            'code': 'DU736',
            'svgId': 'DU736',
            'id': 'NHQ-1PP-DU736',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-DU737': {
            'name': 'WC muži',
            'code': 'DU737',
            'svgId': 'DU737',
            'id': 'NHQ-1PP-DU737',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2901': {
            'name': 'Garáže',
            'code': 'S2901',
            'svgId': 'S2901',
            'id': 'NHQ-1PP-S2901',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2902': {
            'name': 'Schodiště',
            'code': 'S2902',
            'svgId': 'S2902',
            'id': 'NHQ-1PP-S2902',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2903': {
            'name': 'Výtah',
            'code': 'S2903',
            'svgId': 'S2903',
            'id': 'NHQ-1PP-S2903',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2904': {
            'name': 'Chodba',
            'code': 'S2904',
            'svgId': 'S2904',
            'id': 'NHQ-1PP-S2904',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2907': {
            'name': 'Chodba',
            'code': 'S2907',
            'svgId': 'S2907',
            'id': 'NHQ-1PP-S2907',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2908': {
            'name': 'Výtah',
            'code': 'S2908',
            'svgId': 'S2908',
            'id': 'NHQ-1PP-S2908',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2909': {
            'name': 'Schodiště',
            'code': 'S2909',
            'svgId': 'S2909',
            'id': 'NHQ-1PP-S2909',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2910': {
            'name': 'Předsíň výtahu',
            'code': 'S2910',
            'svgId': 'S2910',
            'id': 'NHQ-1PP-S2910',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2911': {
            'name': 'Výtah evakuační',
            'code': 'S2911',
            'svgId': 'S2911',
            'id': 'NHQ-1PP-S2911',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2912': {
            'name': 'Schodiště',
            'code': 'S2912',
            'svgId': 'S2912',
            'id': 'NHQ-1PP-S2912',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2913': {
            'name': 'Výtah',
            'code': 'S2913',
            'svgId': 'S2913',
            'id': 'NHQ-1PP-S2913',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2914': {
            'name': 'Chodba',
            'code': 'S2914',
            'svgId': 'S2914',
            'id': 'NHQ-1PP-S2914',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2917': {
            'name': 'Rampa sjezd',
            'code': 'S2917',
            'svgId': 'S2917',
            'id': 'NHQ-1PP-S2917',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2918': {
            'name': 'Chodba',
            'code': 'S2918',
            'svgId': 'S2918',
            'id': 'NHQ-1PP-S2918',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2920': {
            'name': 'Schodiště',
            'code': 'S2920',
            'svgId': 'S2920',
            'id': 'NHQ-1PP-S2920',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2921': {
            'name': 'Chodba',
            'code': 'S2921',
            'svgId': 'S2921',
            'id': 'NHQ-1PP-S2921',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2925': {
            'name': 'Chodba',
            'code': 'S2925',
            'svgId': 'S2925',
            'id': 'NHQ-1PP-S2925',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2926': {
            'name': 'Schodiště',
            'code': 'S2926',
            'svgId': 'S2926',
            'id': 'NHQ-1PP-S2926',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2928': {
            'name': 'Rampa nájezd',
            'code': 'S2928',
            'svgId': 'S2928',
            'id': 'NHQ-1PP-S2928',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2929': {
            'name': 'Vstup k nádržím sprinklerů',
            'code': 'S2929',
            'svgId': 'S2929',
            'id': 'NHQ-1PP-S2929',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2930': {
            'name': 'Schodiště',
            'code': 'S2930',
            'svgId': 'S2930',
            'id': 'NHQ-1PP-S2930',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2931': {
            'name': 'Garáže - vjezd, výjezd',
            'code': 'S2931',
            'svgId': 'S2931',
            'id': 'NHQ-1PP-S2931',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-S2932': {
            'name': 'Recepce + ostraha',
            'code': 'S2932',
            'svgId': 'S2932',
            'id': 'NHQ-1PP-S2932',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-A4_CZNHQ2046': {
            'name': 'Tiskárna A4 / DA',
            'code': 'A4_CZNHQ2046',
            'svgId': 'A4_CZNHQ2046',
            'id': 'NHQ-1PP-A4_CZNHQ2046',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-A3_PCZNHQ2001': {
            'name': 'Tiskárna A3 / DB',
            'code': 'A3_PCZNHQ2001',
            'svgId': 'A3_PCZNHQ2001',
            'id': 'NHQ-1PP-A3_PCZNHQ2001',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          },
          'NHQ-1PP-A4_PCZNHQ2051': {
            'name': 'Tiskárna A4 / DC',
            'code': 'A4_PCZNHQ2051',
            'svgId': 'A4_PCZNHQ2051',
            'id': 'NHQ-1PP-A4_PCZNHQ2051',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-1PP',
            'type': ''
          }
        }
      },
      'NHQ-2NP': {
        'name': '2. patro',
        'code': '2NP',
        'svgId': '2NP',
        'buildingId': 'NHQ',
        'id': 'NHQ-2NP',
        'rooms': {
          'NHQ-2NP-2A101': {
            'name': 'Schodiště',
            'code': '2A101',
            'svgId': '2A101',
            'id': 'NHQ-2NP-2A101',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A102': {
            'name': 'Výtah',
            'code': '2A102',
            'svgId': '2A102',
            'id': 'NHQ-2NP-2A102',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A103': {
            'name': 'Kuchyňka',
            'code': '2A103',
            'svgId': '2A103',
            'id': 'NHQ-2NP-2A103',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A104': {
            'name': 'Chodba',
            'code': '2A104',
            'svgId': '2A104',
            'id': 'NHQ-2NP-2A104',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A106': {
            'name': 'Šatna',
            'code': '2A106',
            'svgId': '2A106',
            'id': 'NHQ-2NP-2A106',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A109': {
            'name': 'Sprcha',
            'code': '2A109',
            'svgId': '2A109',
            'id': 'NHQ-2NP-2A109',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A110': {
            'name': 'Lockery',
            'code': '2A110',
            'svgId': '2A110',
            'id': 'NHQ-2NP-2A110',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A111': {
            'name': 'Kancelář',
            'code': '2A111',
            'svgId': '2A111',
            'id': 'NHQ-2NP-2A111',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A112': {
            'name': 'Multifunkční prostor',
            'code': '2A112',
            'svgId': '2A112',
            'id': 'NHQ-2NP-2A112',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A113': {
            'name': 'Jednací místnost',
            'code': '2A113',
            'svgId': '2A113',
            'id': 'NHQ-2NP-2A113',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A114': {
            'name': 'Jednací místnost',
            'code': '2A114',
            'svgId': '2A114',
            'id': 'NHQ-2NP-2A114',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A115': {
            'name': 'Jednací místnost',
            'code': '2A115',
            'svgId': '2A115',
            'id': 'NHQ-2NP-2A115',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A116': {
            'name': 'Jednací místnost',
            'code': '2A116',
            'svgId': '2A116',
            'id': 'NHQ-2NP-2A116',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A120': {
            'name': 'Diskrétní jednání',
            'code': '2A120',
            'svgId': '2A120',
            'id': 'NHQ-2NP-2A120',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A121': {
            'name': 'Diskrétní jednání',
            'code': '2A121',
            'svgId': '2A121',
            'id': 'NHQ-2NP-2A121',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A122': {
            'name': 'Diskrétní jednání',
            'code': '2A122',
            'svgId': '2A122',
            'id': 'NHQ-2NP-2A122',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A142': {
            'name': 'WC muži',
            'code': '2A142',
            'svgId': '2A142',
            'id': 'NHQ-2NP-2A142',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A143': {
            'name': 'WC muži imobil',
            'code': '2A143',
            'svgId': '2A143',
            'id': 'NHQ-2NP-2A143',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A144': {
            'name': 'WC muži',
            'code': '2A144',
            'svgId': '2A144',
            'id': 'NHQ-2NP-2A144',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A145': {
            'name': 'WC muži',
            'code': '2A145',
            'svgId': '2A145',
            'id': 'NHQ-2NP-2A145',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A146': {
            'name': 'WC muži',
            'code': '2A146',
            'svgId': '2A146',
            'id': 'NHQ-2NP-2A146',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A147': {
            'name': 'WC ženy',
            'code': '2A147',
            'svgId': '2A147',
            'id': 'NHQ-2NP-2A147',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A148': {
            'name': 'WC ženy',
            'code': '2A148',
            'svgId': '2A148',
            'id': 'NHQ-2NP-2A148',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A149': {
            'name': 'WC ženy',
            'code': '2A149',
            'svgId': '2A149',
            'id': 'NHQ-2NP-2A149',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A150': {
            'name': 'WC ženy imobil',
            'code': '2A150',
            'svgId': '2A150',
            'id': 'NHQ-2NP-2A150',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A151': {
            'name': 'WC ženy',
            'code': '2A151',
            'svgId': '2A151',
            'id': 'NHQ-2NP-2A151',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A154': {
            'name': 'Příruční spisovna',
            'code': '2A154',
            'svgId': '2A154',
            'id': 'NHQ-2NP-2A154',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A155': {
            'name': 'Chodba',
            'code': '2A155',
            'svgId': '2A155',
            'id': 'NHQ-2NP-2A155',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A156': {
            'name': 'Jednací místnost',
            'code': '2A156',
            'svgId': '2A156',
            'id': 'NHQ-2NP-2A156',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A157': {
            'name': 'Jednací místnost',
            'code': '2A157',
            'svgId': '2A157',
            'id': 'NHQ-2NP-2A157',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A159': {
            'name': 'Jednací místnost',
            'code': '2A159',
            'svgId': '2A159',
            'id': 'NHQ-2NP-2A159',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A160': {
            'name': 'Studovna',
            'code': '2A160',
            'svgId': '2A160',
            'id': 'NHQ-2NP-2A160',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A162': {
            'name': 'Kancelář',
            'code': '2A162',
            'svgId': '2A162',
            'id': 'NHQ-2NP-2A162',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A163': {
            'name': 'Jednací místnost',
            'code': '2A163',
            'svgId': '2A163',
            'id': 'NHQ-2NP-2A163',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A164': {
            'name': 'Jednací místnost',
            'code': '2A164',
            'svgId': '2A164',
            'id': 'NHQ-2NP-2A164',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A165': {
            'name': 'Jednací místnost',
            'code': '2A165',
            'svgId': '2A165',
            'id': 'NHQ-2NP-2A165',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A166': {
            'name': 'Kancelář',
            'code': '2A166',
            'svgId': '2A166',
            'id': 'NHQ-2NP-2A166',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A167': {
            'name': 'Jednací místnost',
            'code': '2A167',
            'svgId': '2A167',
            'id': 'NHQ-2NP-2A167',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A182': {
            'name': 'Chodba',
            'code': '2A182',
            'svgId': '2A182',
            'id': 'NHQ-2NP-2A182',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A183': {
            'name': 'Balkón',
            'code': '2A183',
            'svgId': '2A183',
            'id': 'NHQ-2NP-2A183',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A184': {
            'name': 'Schodiště',
            'code': '2A184',
            'svgId': '2A184',
            'id': 'NHQ-2NP-2A184',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A185': {
            'name': 'Flexibilní zóna',
            'code': '2A185',
            'svgId': '2A185',
            'id': 'NHQ-2NP-2A185',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2A186': {
            'name': 'Flexibilní zóna',
            'code': '2A186',
            'svgId': '2A186',
            'id': 'NHQ-2NP-2A186',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B202': {
            'name': 'WC muži',
            'code': '2B202',
            'svgId': '2B202',
            'id': 'NHQ-2NP-2B202',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B203': {
            'name': 'WC muži imobil',
            'code': '2B203',
            'svgId': '2B203',
            'id': 'NHQ-2NP-2B203',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B204': {
            'name': 'WC muži',
            'code': '2B204',
            'svgId': '2B204',
            'id': 'NHQ-2NP-2B204',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B205': {
            'name': 'WC muži',
            'code': '2B205',
            'svgId': '2B205',
            'id': 'NHQ-2NP-2B205',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B206': {
            'name': 'WC muži',
            'code': '2B206',
            'svgId': '2B206',
            'id': 'NHQ-2NP-2B206',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B207': {
            'name': 'WC ženy',
            'code': '2B207',
            'svgId': '2B207',
            'id': 'NHQ-2NP-2B207',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B208': {
            'name': 'WC ženy',
            'code': '2B208',
            'svgId': '2B208',
            'id': 'NHQ-2NP-2B208',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B209': {
            'name': 'WC ženy',
            'code': '2B209',
            'svgId': '2B209',
            'id': 'NHQ-2NP-2B209',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B210': {
            'name': 'WC ženy imobil',
            'code': '2B210',
            'svgId': '2B210',
            'id': 'NHQ-2NP-2B210',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B211': {
            'name': 'WC ženy',
            'code': '2B211',
            'svgId': '2B211',
            'id': 'NHQ-2NP-2B211',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B214': {
            'name': 'Předsíň výtahu',
            'code': '2B214',
            'svgId': '2B214',
            'id': 'NHQ-2NP-2B214',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B215': {
            'name': 'Výtah evakuační',
            'code': '2B215',
            'svgId': '2B215',
            'id': 'NHQ-2NP-2B215',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B216': {
            'name': 'Příruční spisovna',
            'code': '2B216',
            'svgId': '2B216',
            'id': 'NHQ-2NP-2B216',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B217': {
            'name': 'Chodba',
            'code': '2B217',
            'svgId': '2B217',
            'id': 'NHQ-2NP-2B217',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B218': {
            'name': 'Balkón',
            'code': '2B218',
            'svgId': '2B218',
            'id': 'NHQ-2NP-2B218',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B219': {
            'name': 'Jednací místnost',
            'code': '2B219',
            'svgId': '2B219',
            'id': 'NHQ-2NP-2B219',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B220': {
            'name': 'Jednací místnost',
            'code': '2B220',
            'svgId': '2B220',
            'id': 'NHQ-2NP-2B220',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B221': {
            'name': 'Flexibilní zóna',
            'code': '2B221',
            'svgId': '2B221',
            'id': 'NHQ-2NP-2B221',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B222': {
            'name': 'Jednací místnost',
            'code': '2B222',
            'svgId': '2B222',
            'id': 'NHQ-2NP-2B222',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B223': {
            'name': 'Jednací místnost',
            'code': '2B223',
            'svgId': '2B223',
            'id': 'NHQ-2NP-2B223',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B224': {
            'name': 'Kancelář',
            'code': '2B224',
            'svgId': '2B224',
            'id': 'NHQ-2NP-2B224',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B225': {
            'name': 'Kancelář',
            'code': '2B225',
            'svgId': '2B225',
            'id': 'NHQ-2NP-2B225',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B241': {
            'name': 'Schodiště',
            'code': '2B241',
            'svgId': '2B241',
            'id': 'NHQ-2NP-2B241',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B242': {
            'name': 'Výtah',
            'code': '2B242',
            'svgId': '2B242',
            'id': 'NHQ-2NP-2B242',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B243': {
            'name': 'Kuchyňka',
            'code': '2B243',
            'svgId': '2B243',
            'id': 'NHQ-2NP-2B243',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B244': {
            'name': 'Chodba',
            'code': '2B244',
            'svgId': '2B244',
            'id': 'NHQ-2NP-2B244',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B246': {
            'name': 'Šatna',
            'code': '2B246',
            'svgId': '2B246',
            'id': 'NHQ-2NP-2B246',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B249': {
            'name': 'Sprcha',
            'code': '2B249',
            'svgId': '2B249',
            'id': 'NHQ-2NP-2B249',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B250': {
            'name': 'Jednací místnost',
            'code': '2B250',
            'svgId': '2B250',
            'id': 'NHQ-2NP-2B250',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B251': {
            'name': 'Jednací místnost',
            'code': '2B251',
            'svgId': '2B251',
            'id': 'NHQ-2NP-2B251',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B252': {
            'name': 'Jednací místnost',
            'code': '2B252',
            'svgId': '2B252',
            'id': 'NHQ-2NP-2B252',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B253': {
            'name': 'Jednací místnost',
            'code': '2B253',
            'svgId': '2B253',
            'id': 'NHQ-2NP-2B253',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B255': {
            'name': 'Kancelář',
            'code': '2B255',
            'svgId': '2B255',
            'id': 'NHQ-2NP-2B255',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B256': {
            'name': 'Jednací místnost',
            'code': '2B256',
            'svgId': '2B256',
            'id': 'NHQ-2NP-2B256',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B260': {
            'name': 'Diskrétní jednání',
            'code': '2B260',
            'svgId': '2B260',
            'id': 'NHQ-2NP-2B260',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B261': {
            'name': 'Diskrétní jednání',
            'code': '2B261',
            'svgId': '2B261',
            'id': 'NHQ-2NP-2B261',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B262': {
            'name': 'Diskrétní jednání',
            'code': '2B262',
            'svgId': '2B262',
            'id': 'NHQ-2NP-2B262',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2B281': {
            'name': 'Kancelář',
            'code': '2B281',
            'svgId': '2B281',
            'id': 'NHQ-2NP-2B281',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C301': {
            'name': 'Schodiště',
            'code': '2C301',
            'svgId': '2C301',
            'id': 'NHQ-2NP-2C301',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C302': {
            'name': 'Výtah',
            'code': '2C302',
            'svgId': '2C302',
            'id': 'NHQ-2NP-2C302',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C303': {
            'name': 'Kuchyňka',
            'code': '2C303',
            'svgId': '2C303',
            'id': 'NHQ-2NP-2C303',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C304': {
            'name': 'Chodba',
            'code': '2C304',
            'svgId': '2C304',
            'id': 'NHQ-2NP-2C304',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C306': {
            'name': 'Šatna',
            'code': '2C306',
            'svgId': '2C306',
            'id': 'NHQ-2NP-2C306',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C309': {
            'name': 'Sprcha',
            'code': '2C309',
            'svgId': '2C309',
            'id': 'NHQ-2NP-2C309',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C311': {
            'name': 'Kancelář',
            'code': '2C311',
            'svgId': '2C311',
            'id': 'NHQ-2NP-2C311',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C312': {
            'name': 'Jednací místnost',
            'code': '2C312',
            'svgId': '2C312',
            'id': 'NHQ-2NP-2C312',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C313': {
            'name': 'Jednací místnost',
            'code': '2C313',
            'svgId': '2C313',
            'id': 'NHQ-2NP-2C313',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C314': {
            'name': 'Jednací místnost',
            'code': '2C314',
            'svgId': '2C314',
            'id': 'NHQ-2NP-2C314',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C315': {
            'name': 'Jednací místnost',
            'code': '2C315',
            'svgId': '2C315',
            'id': 'NHQ-2NP-2C315',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C320': {
            'name': 'Diskrétní jednání',
            'code': '2C320',
            'svgId': '2C320',
            'id': 'NHQ-2NP-2C320',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C321': {
            'name': 'Diskrétní jednání',
            'code': '2C321',
            'svgId': '2C321',
            'id': 'NHQ-2NP-2C321',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C322': {
            'name': 'Diskrétní jednání',
            'code': '2C322',
            'svgId': '2C322',
            'id': 'NHQ-2NP-2C322',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C342': {
            'name': 'WC muži',
            'code': '2C342',
            'svgId': '2C342',
            'id': 'NHQ-2NP-2C342',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C343': {
            'name': 'WC muži imobil',
            'code': '2C343',
            'svgId': '2C343',
            'id': 'NHQ-2NP-2C343',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C344': {
            'name': 'WC muži',
            'code': '2C344',
            'svgId': '2C344',
            'id': 'NHQ-2NP-2C344',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C345': {
            'name': 'WC muži',
            'code': '2C345',
            'svgId': '2C345',
            'id': 'NHQ-2NP-2C345',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C346': {
            'name': 'WC muži',
            'code': '2C346',
            'svgId': '2C346',
            'id': 'NHQ-2NP-2C346',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C347': {
            'name': 'WC ženy',
            'code': '2C347',
            'svgId': '2C347',
            'id': 'NHQ-2NP-2C347',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C348': {
            'name': 'WC ženy',
            'code': '2C348',
            'svgId': '2C348',
            'id': 'NHQ-2NP-2C348',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C349': {
            'name': 'WC ženy',
            'code': '2C349',
            'svgId': '2C349',
            'id': 'NHQ-2NP-2C349',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C350': {
            'name': 'WC ženy imobil',
            'code': '2C350',
            'svgId': '2C350',
            'id': 'NHQ-2NP-2C350',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C351': {
            'name': 'WC ženy',
            'code': '2C351',
            'svgId': '2C351',
            'id': 'NHQ-2NP-2C351',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C355': {
            'name': 'Chodba',
            'code': '2C355',
            'svgId': '2C355',
            'id': 'NHQ-2NP-2C355',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C356': {
            'name': 'Jednací místnost',
            'code': '2C356',
            'svgId': '2C356',
            'id': 'NHQ-2NP-2C356',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C357': {
            'name': 'Jednací místnost',
            'code': '2C357',
            'svgId': '2C357',
            'id': 'NHQ-2NP-2C357',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C362': {
            'name': 'Kancelář',
            'code': '2C362',
            'svgId': '2C362',
            'id': 'NHQ-2NP-2C362',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C362A': {
            'name': 'Kancelář',
            'code': '2C362A',
            'svgId': '2C362A',
            'id': 'NHQ-2NP-2C362A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C363': {
            'name': 'Jednací místnost',
            'code': '2C363',
            'svgId': '2C363',
            'id': 'NHQ-2NP-2C363',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C364': {
            'name': 'Jednací místnost',
            'code': '2C364',
            'svgId': '2C364',
            'id': 'NHQ-2NP-2C364',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C365': {
            'name': 'Jednací místnost',
            'code': '2C365',
            'svgId': '2C365',
            'id': 'NHQ-2NP-2C365',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C366': {
            'name': 'Jednací místnost',
            'code': '2C366',
            'svgId': '2C366',
            'id': 'NHQ-2NP-2C366',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C367': {
            'name': 'Videokonferenční místnost',
            'code': '2C367',
            'svgId': '2C367',
            'id': 'NHQ-2NP-2C367',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C382': {
            'name': 'Chodba',
            'code': '2C382',
            'svgId': '2C382',
            'id': 'NHQ-2NP-2C382',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C383': {
            'name': 'Balkón',
            'code': '2C383',
            'svgId': '2C383',
            'id': 'NHQ-2NP-2C383',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C384': {
            'name': 'Schodiště',
            'code': '2C384',
            'svgId': '2C384',
            'id': 'NHQ-2NP-2C384',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C385': {
            'name': 'Flexibilní zóna',
            'code': '2C385',
            'svgId': '2C385',
            'id': 'NHQ-2NP-2C385',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C386': {
            'name': 'Flexibilní zóna',
            'code': '2C386',
            'svgId': '2C386',
            'id': 'NHQ-2NP-2C386',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C390': {
            'name': 'Flexibilní zóna',
            'code': '2C390',
            'svgId': '2C390',
            'id': 'NHQ-2NP-2C390',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C391': {
            'name': 'Diskrétní jednání',
            'code': '2C391',
            'svgId': '2C391',
            'id': 'NHQ-2NP-2C391',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C392': {
            'name': 'Diskrétní jednání',
            'code': '2C392',
            'svgId': '2C392',
            'id': 'NHQ-2NP-2C392',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C393': {
            'name': 'Diskrétní jednání',
            'code': '2C393',
            'svgId': '2C393',
            'id': 'NHQ-2NP-2C393',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C394': {
            'name': 'Diskrétní jednání',
            'code': '2C394',
            'svgId': '2C394',
            'id': 'NHQ-2NP-2C394',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C395': {
            'name': 'Diskrétní jednání',
            'code': '2C395',
            'svgId': '2C395',
            'id': 'NHQ-2NP-2C395',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2C396': {
            'name': 'Diskrétní jednání',
            'code': '2C396',
            'svgId': '2C396',
            'id': 'NHQ-2NP-2C396',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D402': {
            'name': 'WC muži',
            'code': '2D402',
            'svgId': '2D402',
            'id': 'NHQ-2NP-2D402',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D403': {
            'name': 'WC muži imobil',
            'code': '2D403',
            'svgId': '2D403',
            'id': 'NHQ-2NP-2D403',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D404': {
            'name': 'WC muži',
            'code': '2D404',
            'svgId': '2D404',
            'id': 'NHQ-2NP-2D404',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D405': {
            'name': 'WC muži',
            'code': '2D405',
            'svgId': '2D405',
            'id': 'NHQ-2NP-2D405',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D406': {
            'name': 'WC muži',
            'code': '2D406',
            'svgId': '2D406',
            'id': 'NHQ-2NP-2D406',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D407': {
            'name': 'WC ženy',
            'code': '2D407',
            'svgId': '2D407',
            'id': 'NHQ-2NP-2D407',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D408': {
            'name': 'WC ženy',
            'code': '2D408',
            'svgId': '2D408',
            'id': 'NHQ-2NP-2D408',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D409': {
            'name': 'WC ženy',
            'code': '2D409',
            'svgId': '2D409',
            'id': 'NHQ-2NP-2D409',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D410': {
            'name': 'WC ženy imobil',
            'code': '2D410',
            'svgId': '2D410',
            'id': 'NHQ-2NP-2D410',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D411': {
            'name': 'WC ženy',
            'code': '2D411',
            'svgId': '2D411',
            'id': 'NHQ-2NP-2D411',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D414': {
            'name': 'Příruční spisovna',
            'code': '2D414',
            'svgId': '2D414',
            'id': 'NHQ-2NP-2D414',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D415': {
            'name': 'Chodba',
            'code': '2D415',
            'svgId': '2D415',
            'id': 'NHQ-2NP-2D415',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D416': {
            'name': 'Jednací místnost',
            'code': '2D416',
            'svgId': '2D416',
            'id': 'NHQ-2NP-2D416',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D417': {
            'name': 'Kancelář',
            'code': '2D417',
            'svgId': '2D417',
            'id': 'NHQ-2NP-2D417',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D422': {
            'name': 'Kancelář',
            'code': '2D422',
            'svgId': '2D422',
            'id': 'NHQ-2NP-2D422',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D423': {
            'name': 'Jednací místnost',
            'code': '2D423',
            'svgId': '2D423',
            'id': 'NHQ-2NP-2D423',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D424': {
            'name': 'Jednací místnost',
            'code': '2D424',
            'svgId': '2D424',
            'id': 'NHQ-2NP-2D424',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D425': {
            'name': 'Jednací místnost',
            'code': '2D425',
            'svgId': '2D425',
            'id': 'NHQ-2NP-2D425',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D426': {
            'name': 'Jednací místnost',
            'code': '2D426',
            'svgId': '2D426',
            'id': 'NHQ-2NP-2D426',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D427': {
            'name': 'Jednací místnost',
            'code': '2D427',
            'svgId': '2D427',
            'id': 'NHQ-2NP-2D427',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D441': {
            'name': 'Schodiště',
            'code': '2D441',
            'svgId': '2D441',
            'id': 'NHQ-2NP-2D441',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D442': {
            'name': 'Výtah',
            'code': '2D442',
            'svgId': '2D442',
            'id': 'NHQ-2NP-2D442',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D443': {
            'name': 'Kuchyňka',
            'code': '2D443',
            'svgId': '2D443',
            'id': 'NHQ-2NP-2D443',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D444': {
            'name': 'Chodba',
            'code': '2D444',
            'svgId': '2D444',
            'id': 'NHQ-2NP-2D444',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D446': {
            'name': 'Šatna',
            'code': '2D446',
            'svgId': '2D446',
            'id': 'NHQ-2NP-2D446',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D449': {
            'name': 'Sprcha',
            'code': '2D449',
            'svgId': '2D449',
            'id': 'NHQ-2NP-2D449',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D451': {
            'name': 'Videokonferenční místnost',
            'code': '2D451',
            'svgId': '2D451',
            'id': 'NHQ-2NP-2D451',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D452': {
            'name': 'Jednací místnost',
            'code': '2D452',
            'svgId': '2D452',
            'id': 'NHQ-2NP-2D452',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D453': {
            'name': 'jednací místnost',
            'code': '2D453',
            'svgId': '2D453',
            'id': 'NHQ-2NP-2D453',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D455': {
            'name': 'Kancelář',
            'code': '2D455',
            'svgId': '2D455',
            'id': 'NHQ-2NP-2D455',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D456': {
            'name': 'Jednací místnost',
            'code': '2D456',
            'svgId': '2D456',
            'id': 'NHQ-2NP-2D456',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D460': {
            'name': 'Diskrétní jednání',
            'code': '2D460',
            'svgId': '2D460',
            'id': 'NHQ-2NP-2D460',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D461': {
            'name': 'Diskrétní jednání',
            'code': '2D461',
            'svgId': '2D461',
            'id': 'NHQ-2NP-2D461',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D462': {
            'name': 'Diskrétní jednání',
            'code': '2D462',
            'svgId': '2D462',
            'id': 'NHQ-2NP-2D462',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D482': {
            'name': 'Chodba',
            'code': '2D482',
            'svgId': '2D482',
            'id': 'NHQ-2NP-2D482',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D483': {
            'name': 'Balkón',
            'code': '2D483',
            'svgId': '2D483',
            'id': 'NHQ-2NP-2D483',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D484': {
            'name': 'Schodiště',
            'code': '2D484',
            'svgId': '2D484',
            'id': 'NHQ-2NP-2D484',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D485': {
            'name': 'Flexibilní zóna',
            'code': '2D485',
            'svgId': '2D485',
            'id': 'NHQ-2NP-2D485',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D486': {
            'name': 'Flexibilní zóna',
            'code': '2D486',
            'svgId': '2D486',
            'id': 'NHQ-2NP-2D486',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D490': {
            'name': 'Flexibilní zóna',
            'code': '2D490',
            'svgId': '2D490',
            'id': 'NHQ-2NP-2D490',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D491': {
            'name': 'Diskrétní jednání',
            'code': '2D491',
            'svgId': '2D491',
            'id': 'NHQ-2NP-2D491',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D492': {
            'name': 'Diskrétní jednání',
            'code': '2D492',
            'svgId': '2D492',
            'id': 'NHQ-2NP-2D492',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D493': {
            'name': 'Diskrétní jednání',
            'code': '2D493',
            'svgId': '2D493',
            'id': 'NHQ-2NP-2D493',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D494': {
            'name': 'Diskrétní jednání',
            'code': '2D494',
            'svgId': '2D494',
            'id': 'NHQ-2NP-2D494',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D495': {
            'name': 'Diskrétní jednání',
            'code': '2D495',
            'svgId': '2D495',
            'id': 'NHQ-2NP-2D495',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2D496': {
            'name': 'Diskrétní jednání',
            'code': '2D496',
            'svgId': '2D496',
            'id': 'NHQ-2NP-2D496',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E501': {
            'name': 'Schodiště',
            'code': '2E501',
            'svgId': '2E501',
            'id': 'NHQ-2NP-2E501',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E502': {
            'name': 'Výtah',
            'code': '2E502',
            'svgId': '2E502',
            'id': 'NHQ-2NP-2E502',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E503': {
            'name': 'Kuchyňka',
            'code': '2E503',
            'svgId': '2E503',
            'id': 'NHQ-2NP-2E503',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E504': {
            'name': 'Chodba',
            'code': '2E504',
            'svgId': '2E504',
            'id': 'NHQ-2NP-2E504',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E506': {
            'name': 'Šatna',
            'code': '2E506',
            'svgId': '2E506',
            'id': 'NHQ-2NP-2E506',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E509': {
            'name': 'Sprcha',
            'code': '2E509',
            'svgId': '2E509',
            'id': 'NHQ-2NP-2E509',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E511': {
            'name': 'Kancelář',
            'code': '2E511',
            'svgId': '2E511',
            'id': 'NHQ-2NP-2E511',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E511A': {
            'name': 'Kancelář',
            'code': '2E511A',
            'svgId': '2E511A',
            'id': 'NHQ-2NP-2E511A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E512': {
            'name': 'Jednací místnost',
            'code': '2E512',
            'svgId': '2E512',
            'id': 'NHQ-2NP-2E512',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E513': {
            'name': 'Jednací místnost',
            'code': '2E513',
            'svgId': '2E513',
            'id': 'NHQ-2NP-2E513',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E514': {
            'name': 'Jednací místnost',
            'code': '2E514',
            'svgId': '2E514',
            'id': 'NHQ-2NP-2E514',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E515': {
            'name': 'Jednací místnost',
            'code': '2E515',
            'svgId': '2E515',
            'id': 'NHQ-2NP-2E515',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E516': {
            'name': 'Jednací místnost',
            'code': '2E516',
            'svgId': '2E516',
            'id': 'NHQ-2NP-2E516',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E520': {
            'name': 'Diskrétní jednání',
            'code': '2E520',
            'svgId': '2E520',
            'id': 'NHQ-2NP-2E520',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E521': {
            'name': 'Diskrétní jednání',
            'code': '2E521',
            'svgId': '2E521',
            'id': 'NHQ-2NP-2E521',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E522': {
            'name': 'Diskrétní jednání',
            'code': '2E522',
            'svgId': '2E522',
            'id': 'NHQ-2NP-2E522',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E542': {
            'name': 'WC muži',
            'code': '2E542',
            'svgId': '2E542',
            'id': 'NHQ-2NP-2E542',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E543': {
            'name': 'WC muži imobil',
            'code': '2E543',
            'svgId': '2E543',
            'id': 'NHQ-2NP-2E543',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E544': {
            'name': 'WC muži',
            'code': '2E544',
            'svgId': '2E544',
            'id': 'NHQ-2NP-2E544',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E545': {
            'name': 'WC muži',
            'code': '2E545',
            'svgId': '2E545',
            'id': 'NHQ-2NP-2E545',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E546': {
            'name': 'WC muži',
            'code': '2E546',
            'svgId': '2E546',
            'id': 'NHQ-2NP-2E546',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E547': {
            'name': 'WC ženy',
            'code': '2E547',
            'svgId': '2E547',
            'id': 'NHQ-2NP-2E547',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E548': {
            'name': 'WC ženy',
            'code': '2E548',
            'svgId': '2E548',
            'id': 'NHQ-2NP-2E548',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E549': {
            'name': 'WC ženy',
            'code': '2E549',
            'svgId': '2E549',
            'id': 'NHQ-2NP-2E549',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E550': {
            'name': 'WC ženy imobil',
            'code': '2E550',
            'svgId': '2E550',
            'id': 'NHQ-2NP-2E550',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E551': {
            'name': 'WC ženy',
            'code': '2E551',
            'svgId': '2E551',
            'id': 'NHQ-2NP-2E551',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E554': {
            'name': 'Předsíň výtahu',
            'code': '2E554',
            'svgId': '2E554',
            'id': 'NHQ-2NP-2E554',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E555': {
            'name': 'Výtah evakuační',
            'code': '2E555',
            'svgId': '2E555',
            'id': 'NHQ-2NP-2E555',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E556': {
            'name': 'Příruční spisovna',
            'code': '2E556',
            'svgId': '2E556',
            'id': 'NHQ-2NP-2E556',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E557': {
            'name': 'Chodba',
            'code': '2E557',
            'svgId': '2E557',
            'id': 'NHQ-2NP-2E557',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E559': {
            'name': 'Jednací místnost',
            'code': '2E559',
            'svgId': '2E559',
            'id': 'NHQ-2NP-2E559',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E561': {
            'name': 'Kancelář',
            'code': '2E561',
            'svgId': '2E561',
            'id': 'NHQ-2NP-2E561',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E563': {
            'name': 'Jednací místnost',
            'code': '2E563',
            'svgId': '2E563',
            'id': 'NHQ-2NP-2E563',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E564': {
            'name': 'Jednací místnost',
            'code': '2E564',
            'svgId': '2E564',
            'id': 'NHQ-2NP-2E564',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E565': {
            'name': 'Kancelář',
            'code': '2E565',
            'svgId': '2E565',
            'id': 'NHQ-2NP-2E565',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E566': {
            'name': 'Kancelář',
            'code': '2E566',
            'svgId': '2E566',
            'id': 'NHQ-2NP-2E566',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E566A': {
            'name': 'Kancelář',
            'code': '2E566A',
            'svgId': '2E566A',
            'id': 'NHQ-2NP-2E566A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E567': {
            'name': 'Jednací místnost',
            'code': '2E567',
            'svgId': '2E567',
            'id': 'NHQ-2NP-2E567',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E581': {
            'name': 'Kancelář',
            'code': '2E581',
            'svgId': '2E581',
            'id': 'NHQ-2NP-2E581',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E581A': {
            'name': 'Kancelář',
            'code': '2E581A',
            'svgId': '2E581A',
            'id': 'NHQ-2NP-2E581A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2E581B': {
            'name': 'Kancelář',
            'code': '2E581B',
            'svgId': '2E581B',
            'id': 'NHQ-2NP-2E581B',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F602': {
            'name': 'WC muži',
            'code': '2F602',
            'svgId': '2F602',
            'id': 'NHQ-2NP-2F602',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F603': {
            'name': 'WC muži imobil',
            'code': '2F603',
            'svgId': '2F603',
            'id': 'NHQ-2NP-2F603',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F604': {
            'name': 'WC muži',
            'code': '2F604',
            'svgId': '2F604',
            'id': 'NHQ-2NP-2F604',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F605': {
            'name': 'WC muži',
            'code': '2F605',
            'svgId': '2F605',
            'id': 'NHQ-2NP-2F605',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F606': {
            'name': 'WC muži',
            'code': '2F606',
            'svgId': '2F606',
            'id': 'NHQ-2NP-2F606',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F607': {
            'name': 'WC ženy',
            'code': '2F607',
            'svgId': '2F607',
            'id': 'NHQ-2NP-2F607',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F608': {
            'name': 'WC ženy',
            'code': '2F608',
            'svgId': '2F608',
            'id': 'NHQ-2NP-2F608',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F609': {
            'name': 'WC ženy',
            'code': '2F609',
            'svgId': '2F609',
            'id': 'NHQ-2NP-2F609',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F610': {
            'name': 'WC ženy imobil',
            'code': '2F610',
            'svgId': '2F610',
            'id': 'NHQ-2NP-2F610',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F611': {
            'name': 'WC ženy',
            'code': '2F611',
            'svgId': '2F611',
            'id': 'NHQ-2NP-2F611',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F614': {
            'name': 'Úschovna',
            'code': '2F614',
            'svgId': '2F614',
            'id': 'NHQ-2NP-2F614',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F615': {
            'name': 'Chodba',
            'code': '2F615',
            'svgId': '2F615',
            'id': 'NHQ-2NP-2F615',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F616': {
            'name': 'Jednací místnost',
            'code': '2F616',
            'svgId': '2F616',
            'id': 'NHQ-2NP-2F616',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F617': {
            'name': 'Jednací místnost',
            'code': '2F617',
            'svgId': '2F617',
            'id': 'NHQ-2NP-2F617',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F619': {
            'name': 'Jednací místnost',
            'code': '2F619',
            'svgId': '2F619',
            'id': 'NHQ-2NP-2F619',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F620': {
            'name': 'Jednací místnost',
            'code': '2F620',
            'svgId': '2F620',
            'id': 'NHQ-2NP-2F620',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F621': {
            'name': 'Jednací místnost',
            'code': '2F621',
            'svgId': '2F621',
            'id': 'NHQ-2NP-2F621',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F622': {
            'name': 'Kancelář',
            'code': '2F622',
            'svgId': '2F622',
            'id': 'NHQ-2NP-2F622',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F623': {
            'name': 'Jednací místnost',
            'code': '2F623',
            'svgId': '2F623',
            'id': 'NHQ-2NP-2F623',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F624': {
            'name': 'Jednací místnost',
            'code': '2F624',
            'svgId': '2F624',
            'id': 'NHQ-2NP-2F624',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F625': {
            'name': 'Jednací místnost',
            'code': '2F625',
            'svgId': '2F625',
            'id': 'NHQ-2NP-2F625',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F626': {
            'name': 'Jednací místnost',
            'code': '2F626',
            'svgId': '2F626',
            'id': 'NHQ-2NP-2F626',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F627': {
            'name': 'Jednací místnost',
            'code': '2F627',
            'svgId': '2F627',
            'id': 'NHQ-2NP-2F627',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F641': {
            'name': 'Schodiště',
            'code': '2F641',
            'svgId': '2F641',
            'id': 'NHQ-2NP-2F641',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F642': {
            'name': 'Výtah',
            'code': '2F642',
            'svgId': '2F642',
            'id': 'NHQ-2NP-2F642',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F643': {
            'name': 'Kuchyňka',
            'code': '2F643',
            'svgId': '2F643',
            'id': 'NHQ-2NP-2F643',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F644': {
            'name': 'Chodba',
            'code': '2F644',
            'svgId': '2F644',
            'id': 'NHQ-2NP-2F644',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F646': {
            'name': 'Šatna',
            'code': '2F646',
            'svgId': '2F646',
            'id': 'NHQ-2NP-2F646',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F649': {
            'name': 'Sprcha',
            'code': '2F649',
            'svgId': '2F649',
            'id': 'NHQ-2NP-2F649',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F650': {
            'name': 'Jednací místnost',
            'code': '2F650',
            'svgId': '2F650',
            'id': 'NHQ-2NP-2F650',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F651': {
            'name': 'Jednací místnost',
            'code': '2F651',
            'svgId': '2F651',
            'id': 'NHQ-2NP-2F651',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F652': {
            'name': 'Jednací místnost',
            'code': '2F652',
            'svgId': '2F652',
            'id': 'NHQ-2NP-2F652',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F653': {
            'name': 'Jednací místnost',
            'code': '2F653',
            'svgId': '2F653',
            'id': 'NHQ-2NP-2F653',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F654': {
            'name': 'Příruční spisovna',
            'code': '2F654',
            'svgId': '2F654',
            'id': 'NHQ-2NP-2F654',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F655': {
            'name': 'Kancelář',
            'code': '2F655',
            'svgId': '2F655',
            'id': 'NHQ-2NP-2F655',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F656': {
            'name': 'Jednací místnost',
            'code': '2F656',
            'svgId': '2F656',
            'id': 'NHQ-2NP-2F656',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F657': {
            'name': 'Kancelář',
            'code': '2F657',
            'svgId': '2F657',
            'id': 'NHQ-2NP-2F657',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F660': {
            'name': 'Diskrétní jednání',
            'code': '2F660',
            'svgId': '2F660',
            'id': 'NHQ-2NP-2F660',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F661': {
            'name': 'Diskrétní jednání',
            'code': '2F661',
            'svgId': '2F661',
            'id': 'NHQ-2NP-2F661',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F662': {
            'name': 'Diskrétní jednání',
            'code': '2F662',
            'svgId': '2F662',
            'id': 'NHQ-2NP-2F662',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F682': {
            'name': 'Chodba',
            'code': '2F682',
            'svgId': '2F682',
            'id': 'NHQ-2NP-2F682',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F683': {
            'name': 'Balkón',
            'code': '2F683',
            'svgId': '2F683',
            'id': 'NHQ-2NP-2F683',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F684': {
            'name': 'Schodiště',
            'code': '2F684',
            'svgId': '2F684',
            'id': 'NHQ-2NP-2F684',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F685': {
            'name': 'Flexibilní zóna',
            'code': '2F685',
            'svgId': '2F685',
            'id': 'NHQ-2NP-2F685',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-2F686': {
            'name': 'Flexibilní zóna',
            'code': '2F686',
            'svgId': '2F686',
            'id': 'NHQ-2NP-2F686',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-A4_PCZNHQ2024': {
            'name': 'Tiskárna A4 / 2A',
            'code': 'A4_PCZNHQ2024',
            'svgId': 'A4_PCZNHQ2024',
            'id': 'NHQ-2NP-A4_PCZNHQ2024',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-A3_PCZNHQ2004': {
            'name': 'Tiskárna A3 / 2A',
            'code': 'A3_PCZNHQ2004',
            'svgId': 'A3_PCZNHQ2004',
            'id': 'NHQ-2NP-A3_PCZNHQ2004',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-A4_PCZNHQ2012': {
            'name': 'Tiskárna A4 / 2C',
            'code': 'A4_PCZNHQ2012',
            'svgId': 'A4_PCZNHQ2012',
            'id': 'NHQ-2NP-A4_PCZNHQ2012',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-A3_PCZNHQ2002': {
            'name': 'Tiskárna A3 / 2E',
            'code': 'A3_PCZNHQ2002',
            'svgId': 'A3_PCZNHQ2002',
            'id': 'NHQ-2NP-A3_PCZNHQ2002',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-A4_PCZNHQ2010': {
            'name': 'Tiskárna A4 / 2F',
            'code': 'A4_PCZNHQ2010',
            'svgId': 'A4_PCZNHQ2010',
            'id': 'NHQ-2NP-A4_PCZNHQ2010',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-A4_PCZNHQ2020': {
            'name': 'Tiskárna A4 / 2F',
            'code': 'A4_PCZNHQ2020',
            'svgId': 'A4_PCZNHQ2020',
            'id': 'NHQ-2NP-A4_PCZNHQ2020',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-A4_PCZNHQ2038': {
            'name': 'Tiskárna A4 / 2D',
            'code': 'A4_PCZNHQ2038',
            'svgId': 'A4_PCZNHQ2038',
            'id': 'NHQ-2NP-A4_PCZNHQ2038',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          },
          'NHQ-2NP-A3_PCZNHQ2003': {
            'name': 'Tiskárna A3 / 2B',
            'code': 'A3_PCZNHQ2003',
            'svgId': 'A3_PCZNHQ2003',
            'id': 'NHQ-2NP-A3_PCZNHQ2003',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-2NP',
            'type': ''
          }
        }
      },
      'NHQ-3NP': {
        'name': '3. patro',
        'code': '3NP',
        'svgId': '3NP',
        'buildingId': 'NHQ',
        'id': 'NHQ-3NP',
        'rooms': {
          'NHQ-3NP-3A101': {
            'name': 'Schodiště',
            'code': '3A101',
            'svgId': '3A101',
            'id': 'NHQ-3NP-3A101',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A102': {
            'name': 'Výtah',
            'code': '3A102',
            'svgId': '3A102',
            'id': 'NHQ-3NP-3A102',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A103': {
            'name': 'Kuchyňka',
            'code': '3A103',
            'svgId': '3A103',
            'id': 'NHQ-3NP-3A103',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A104': {
            'name': 'Chodba',
            'code': '3A104',
            'svgId': '3A104',
            'id': 'NHQ-3NP-3A104',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A106': {
            'name': 'Šatna',
            'code': '3A106',
            'svgId': '3A106',
            'id': 'NHQ-3NP-3A106',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A109': {
            'name': 'Sprcha',
            'code': '3A109',
            'svgId': '3A109',
            'id': 'NHQ-3NP-3A109',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A110': {
            'name': 'Lockery',
            'code': '3A110',
            'svgId': '3A110',
            'id': 'NHQ-3NP-3A110',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A111': {
            'name': 'Kancelář',
            'code': '3A111',
            'svgId': '3A111',
            'id': 'NHQ-3NP-3A111',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A111A': {
            'name': 'Kancelář',
            'code': '3A111A',
            'svgId': '3A111A',
            'id': 'NHQ-3NP-3A111A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A112': {
            'name': 'Multifunkční prostor',
            'code': '3A112',
            'svgId': '3A112',
            'id': 'NHQ-3NP-3A112',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A113': {
            'name': 'Jednací místnost',
            'code': '3A113',
            'svgId': '3A113',
            'id': 'NHQ-3NP-3A113',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A114': {
            'name': 'Jednací místnost',
            'code': '3A114',
            'svgId': '3A114',
            'id': 'NHQ-3NP-3A114',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A115': {
            'name': 'Jednací místnost',
            'code': '3A115',
            'svgId': '3A115',
            'id': 'NHQ-3NP-3A115',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A116': {
            'name': 'Jednací místnost',
            'code': '3A116',
            'svgId': '3A116',
            'id': 'NHQ-3NP-3A116',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A120': {
            'name': 'Diskrétní jednání',
            'code': '3A120',
            'svgId': '3A120',
            'id': 'NHQ-3NP-3A120',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A121': {
            'name': 'Diskrétní jednání',
            'code': '3A121',
            'svgId': '3A121',
            'id': 'NHQ-3NP-3A121',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A122': {
            'name': 'Diskrétní jednání',
            'code': '3A122',
            'svgId': '3A122',
            'id': 'NHQ-3NP-3A122',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A142': {
            'name': 'WC muži',
            'code': '3A142',
            'svgId': '3A142',
            'id': 'NHQ-3NP-3A142',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A143': {
            'name': 'WC muži imobil',
            'code': '3A143',
            'svgId': '3A143',
            'id': 'NHQ-3NP-3A143',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A144': {
            'name': 'WC muži',
            'code': '3A144',
            'svgId': '3A144',
            'id': 'NHQ-3NP-3A144',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A145': {
            'name': 'WC muži',
            'code': '3A145',
            'svgId': '3A145',
            'id': 'NHQ-3NP-3A145',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A146': {
            'name': 'WC muži',
            'code': '3A146',
            'svgId': '3A146',
            'id': 'NHQ-3NP-3A146',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A147': {
            'name': 'WC ženy',
            'code': '3A147',
            'svgId': '3A147',
            'id': 'NHQ-3NP-3A147',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A148': {
            'name': 'WC ženy',
            'code': '3A148',
            'svgId': '3A148',
            'id': 'NHQ-3NP-3A148',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A149': {
            'name': 'WC ženy',
            'code': '3A149',
            'svgId': '3A149',
            'id': 'NHQ-3NP-3A149',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A150': {
            'name': 'WC ženy imobil',
            'code': '3A150',
            'svgId': '3A150',
            'id': 'NHQ-3NP-3A150',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A151': {
            'name': 'WC ženy',
            'code': '3A151',
            'svgId': '3A151',
            'id': 'NHQ-3NP-3A151',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A154': {
            'name': 'Příruční spisovna',
            'code': '3A154',
            'svgId': '3A154',
            'id': 'NHQ-3NP-3A154',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A155': {
            'name': 'Chodba',
            'code': '3A155',
            'svgId': '3A155',
            'id': 'NHQ-3NP-3A155',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A156': {
            'name': 'Jednací místnost',
            'code': '3A156',
            'svgId': '3A156',
            'id': 'NHQ-3NP-3A156',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A157': {
            'name': 'Jednací místnost',
            'code': '3A157',
            'svgId': '3A157',
            'id': 'NHQ-3NP-3A157',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A159': {
            'name': 'Jednací místnost',
            'code': '3A159',
            'svgId': '3A159',
            'id': 'NHQ-3NP-3A159',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A160': {
            'name': 'Jednací místnost',
            'code': '3A160',
            'svgId': '3A160',
            'id': 'NHQ-3NP-3A160',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A161': {
            'name': 'Jednací místnost',
            'code': '3A161',
            'svgId': '3A161',
            'id': 'NHQ-3NP-3A161',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A162': {
            'name': 'Kancelář',
            'code': '3A162',
            'svgId': '3A162',
            'id': 'NHQ-3NP-3A162',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A163': {
            'name': 'Jednací místnost',
            'code': '3A163',
            'svgId': '3A163',
            'id': 'NHQ-3NP-3A163',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A164': {
            'name': 'Jednací místnost',
            'code': '3A164',
            'svgId': '3A164',
            'id': 'NHQ-3NP-3A164',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A165': {
            'name': 'Jednací místnost',
            'code': '3A165',
            'svgId': '3A165',
            'id': 'NHQ-3NP-3A165',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A166': {
            'name': 'Projektová místnost',
            'code': '3A166',
            'svgId': '3A166',
            'id': 'NHQ-3NP-3A166',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A167': {
            'name': 'Jednací místnost',
            'code': '3A167',
            'svgId': '3A167',
            'id': 'NHQ-3NP-3A167',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A182': {
            'name': 'Chodba',
            'code': '3A182',
            'svgId': '3A182',
            'id': 'NHQ-3NP-3A182',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A183': {
            'name': 'Balkón',
            'code': '3A183',
            'svgId': '3A183',
            'id': 'NHQ-3NP-3A183',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A184': {
            'name': 'Schodiště',
            'code': '3A184',
            'svgId': '3A184',
            'id': 'NHQ-3NP-3A184',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A185': {
            'name': 'Flexibilní zóna',
            'code': '3A185',
            'svgId': '3A185',
            'id': 'NHQ-3NP-3A185',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A186': {
            'name': 'Flexibilní zóna',
            'code': '3A186',
            'svgId': '3A186',
            'id': 'NHQ-3NP-3A186',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3A199': {
            'name': 'Terasa',
            'code': '3A199',
            'svgId': '3A199',
            'id': 'NHQ-3NP-3A199',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B202': {
            'name': 'WC muži',
            'code': '3B202',
            'svgId': '3B202',
            'id': 'NHQ-3NP-3B202',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B203': {
            'name': 'WC muži imobil',
            'code': '3B203',
            'svgId': '3B203',
            'id': 'NHQ-3NP-3B203',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B204': {
            'name': 'WC muži',
            'code': '3B204',
            'svgId': '3B204',
            'id': 'NHQ-3NP-3B204',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B205': {
            'name': 'WC muži',
            'code': '3B205',
            'svgId': '3B205',
            'id': 'NHQ-3NP-3B205',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B206': {
            'name': 'WC muži',
            'code': '3B206',
            'svgId': '3B206',
            'id': 'NHQ-3NP-3B206',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B207': {
            'name': 'WC ženy',
            'code': '3B207',
            'svgId': '3B207',
            'id': 'NHQ-3NP-3B207',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B208': {
            'name': 'WC ženy',
            'code': '3B208',
            'svgId': '3B208',
            'id': 'NHQ-3NP-3B208',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B209': {
            'name': 'WC ženy',
            'code': '3B209',
            'svgId': '3B209',
            'id': 'NHQ-3NP-3B209',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B210': {
            'name': 'WC ženy imobil',
            'code': '3B210',
            'svgId': '3B210',
            'id': 'NHQ-3NP-3B210',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B211': {
            'name': 'WC ženy',
            'code': '3B211',
            'svgId': '3B211',
            'id': 'NHQ-3NP-3B211',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B214': {
            'name': 'Předsíň výtahu',
            'code': '3B214',
            'svgId': '3B214',
            'id': 'NHQ-3NP-3B214',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B215': {
            'name': 'Výtah evakuační',
            'code': '3B215',
            'svgId': '3B215',
            'id': 'NHQ-3NP-3B215',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B216': {
            'name': 'Příruční spisovna',
            'code': '3B216',
            'svgId': '3B216',
            'id': 'NHQ-3NP-3B216',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B217': {
            'name': 'Chodba',
            'code': '3B217',
            'svgId': '3B217',
            'id': 'NHQ-3NP-3B217',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B218': {
            'name': 'Balkón',
            'code': '3B218',
            'svgId': '3B218',
            'id': 'NHQ-3NP-3B218',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B219': {
            'name': 'Jednací místnost',
            'code': '3B219',
            'svgId': '3B219',
            'id': 'NHQ-3NP-3B219',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B220': {
            'name': 'Jednací místnost',
            'code': '3B220',
            'svgId': '3B220',
            'id': 'NHQ-3NP-3B220',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B222': {
            'name': 'Jednací místnost',
            'code': '3B222',
            'svgId': '3B222',
            'id': 'NHQ-3NP-3B222',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B223': {
            'name': 'Jednací místnost',
            'code': '3B223',
            'svgId': '3B223',
            'id': 'NHQ-3NP-3B223',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B224': {
            'name': 'Kancelář',
            'code': '3B224',
            'svgId': '3B224',
            'id': 'NHQ-3NP-3B224',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B225': {
            'name': 'Kancelář',
            'code': '3B225',
            'svgId': '3B225',
            'id': 'NHQ-3NP-3B225',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B241': {
            'name': 'Schodiště',
            'code': '3B241',
            'svgId': '3B241',
            'id': 'NHQ-3NP-3B241',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B242': {
            'name': 'Výtah',
            'code': '3B242',
            'svgId': '3B242',
            'id': 'NHQ-3NP-3B242',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B243': {
            'name': 'Kuchyňka',
            'code': '3B243',
            'svgId': '3B243',
            'id': 'NHQ-3NP-3B243',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B244': {
            'name': 'Chodba',
            'code': '3B244',
            'svgId': '3B244',
            'id': 'NHQ-3NP-3B244',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B246': {
            'name': 'Šatna',
            'code': '3B246',
            'svgId': '3B246',
            'id': 'NHQ-3NP-3B246',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B249': {
            'name': 'Sprcha',
            'code': '3B249',
            'svgId': '3B249',
            'id': 'NHQ-3NP-3B249',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B250': {
            'name': 'Jednací místnost',
            'code': '3B250',
            'svgId': '3B250',
            'id': 'NHQ-3NP-3B250',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B251': {
            'name': 'Jednací místnost',
            'code': '3B251',
            'svgId': '3B251',
            'id': 'NHQ-3NP-3B251',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B252': {
            'name': 'Jednací místnost',
            'code': '3B252',
            'svgId': '3B252',
            'id': 'NHQ-3NP-3B252',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B253': {
            'name': 'Kancelář',
            'code': '3B253',
            'svgId': '3B253',
            'id': 'NHQ-3NP-3B253',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B255': {
            'name': 'Kancelář',
            'code': '3B255',
            'svgId': '3B255',
            'id': 'NHQ-3NP-3B255',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B256': {
            'name': 'Jednací místnost',
            'code': '3B256',
            'svgId': '3B256',
            'id': 'NHQ-3NP-3B256',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B260': {
            'name': 'Diskrétní jednání',
            'code': '3B260',
            'svgId': '3B260',
            'id': 'NHQ-3NP-3B260',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B261': {
            'name': 'Diskrétní jednání',
            'code': '3B261',
            'svgId': '3B261',
            'id': 'NHQ-3NP-3B261',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B262': {
            'name': 'Diskrétní jednání',
            'code': '3B262',
            'svgId': '3B262',
            'id': 'NHQ-3NP-3B262',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3B281': {
            'name': 'Kancelář',
            'code': '3B281',
            'svgId': '3B281',
            'id': 'NHQ-3NP-3B281',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C301': {
            'name': 'Schodiště',
            'code': '3C301',
            'svgId': '3C301',
            'id': 'NHQ-3NP-3C301',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C302': {
            'name': 'Výtah',
            'code': '3C302',
            'svgId': '3C302',
            'id': 'NHQ-3NP-3C302',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C303': {
            'name': 'Kuchyňka',
            'code': '3C303',
            'svgId': '3C303',
            'id': 'NHQ-3NP-3C303',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C304': {
            'name': 'Chodba',
            'code': '3C304',
            'svgId': '3C304',
            'id': 'NHQ-3NP-3C304',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C306': {
            'name': 'Šatna',
            'code': '3C306',
            'svgId': '3C306',
            'id': 'NHQ-3NP-3C306',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C309': {
            'name': 'Sprcha',
            'code': '3C309',
            'svgId': '3C309',
            'id': 'NHQ-3NP-3C309',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C311': {
            'name': 'Kancelář',
            'code': '3C311',
            'svgId': '3C311',
            'id': 'NHQ-3NP-3C311',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C312': {
            'name': 'Jednací místnost',
            'code': '3C312',
            'svgId': '3C312',
            'id': 'NHQ-3NP-3C312',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C313': {
            'name': 'Jednací místnost',
            'code': '3C313',
            'svgId': '3C313',
            'id': 'NHQ-3NP-3C313',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C314': {
            'name': 'Jednací místnost',
            'code': '3C314',
            'svgId': '3C314',
            'id': 'NHQ-3NP-3C314',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C315': {
            'name': 'Videokonferenční místnost',
            'code': '3C315',
            'svgId': '3C315',
            'id': 'NHQ-3NP-3C315',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C320': {
            'name': 'Diskrétní jednání',
            'code': '3C320',
            'svgId': '3C320',
            'id': 'NHQ-3NP-3C320',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C321': {
            'name': 'Diskrétní jednání',
            'code': '3C321',
            'svgId': '3C321',
            'id': 'NHQ-3NP-3C321',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C322': {
            'name': 'Diskrétní jednání',
            'code': '3C322',
            'svgId': '3C322',
            'id': 'NHQ-3NP-3C322',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C342': {
            'name': 'WC muži',
            'code': '3C342',
            'svgId': '3C342',
            'id': 'NHQ-3NP-3C342',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C343': {
            'name': 'WC muži imobil',
            'code': '3C343',
            'svgId': '3C343',
            'id': 'NHQ-3NP-3C343',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C344': {
            'name': 'WC muži',
            'code': '3C344',
            'svgId': '3C344',
            'id': 'NHQ-3NP-3C344',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C345': {
            'name': 'WC muži',
            'code': '3C345',
            'svgId': '3C345',
            'id': 'NHQ-3NP-3C345',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C346': {
            'name': 'WC muži',
            'code': '3C346',
            'svgId': '3C346',
            'id': 'NHQ-3NP-3C346',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C347': {
            'name': 'WC ženy',
            'code': '3C347',
            'svgId': '3C347',
            'id': 'NHQ-3NP-3C347',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C348': {
            'name': 'WC ženy',
            'code': '3C348',
            'svgId': '3C348',
            'id': 'NHQ-3NP-3C348',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C349': {
            'name': 'WC ženy',
            'code': '3C349',
            'svgId': '3C349',
            'id': 'NHQ-3NP-3C349',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C350': {
            'name': 'WC ženy imobil',
            'code': '3C350',
            'svgId': '3C350',
            'id': 'NHQ-3NP-3C350',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C351': {
            'name': 'WC ženy',
            'code': '3C351',
            'svgId': '3C351',
            'id': 'NHQ-3NP-3C351',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C355': {
            'name': 'Chodba',
            'code': '3C355',
            'svgId': '3C355',
            'id': 'NHQ-3NP-3C355',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C356': {
            'name': 'Jednací místnost',
            'code': '3C356',
            'svgId': '3C356',
            'id': 'NHQ-3NP-3C356',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C357': {
            'name': 'Jednací místnost',
            'code': '3C357',
            'svgId': '3C357',
            'id': 'NHQ-3NP-3C357',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C358': {
            'name': 'Průchod',
            'code': '3C358',
            'svgId': '3C358',
            'id': 'NHQ-3NP-3C358',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C359': {
            'name': 'Jednací místnost',
            'code': '3C359',
            'svgId': '3C359',
            'id': 'NHQ-3NP-3C359',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C360': {
            'name': 'Jednací místnost',
            'code': '3C360',
            'svgId': '3C360',
            'id': 'NHQ-3NP-3C360',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C361': {
            'name': 'Jednací místnost',
            'code': '3C361',
            'svgId': '3C361',
            'id': 'NHQ-3NP-3C361',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C362': {
            'name': 'Kancelář',
            'code': '3C362',
            'svgId': '3C362',
            'id': 'NHQ-3NP-3C362',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C363': {
            'name': 'Jednací místnost',
            'code': '3C363',
            'svgId': '3C363',
            'id': 'NHQ-3NP-3C363',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C364': {
            'name': 'APOST',
            'code': '3C364',
            'svgId': '3C364',
            'id': 'NHQ-3NP-3C364',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C365': {
            'name': 'Jednací místnost',
            'code': '3C365',
            'svgId': '3C365',
            'id': 'NHQ-3NP-3C365',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C366': {
            'name': 'Kancelář',
            'code': '3C366',
            'svgId': '3C366',
            'id': 'NHQ-3NP-3C366',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C367': {
            'name': 'Jednací místnost',
            'code': '3C367',
            'svgId': '3C367',
            'id': 'NHQ-3NP-3C367',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C382': {
            'name': 'Chodba',
            'code': '3C382',
            'svgId': '3C382',
            'id': 'NHQ-3NP-3C382',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C383': {
            'name': 'Balkón',
            'code': '3C383',
            'svgId': '3C383',
            'id': 'NHQ-3NP-3C383',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C384': {
            'name': 'Schodiště',
            'code': '3C384',
            'svgId': '3C384',
            'id': 'NHQ-3NP-3C384',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C385': {
            'name': 'Flexibilní zóna',
            'code': '3C385',
            'svgId': '3C385',
            'id': 'NHQ-3NP-3C385',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C386': {
            'name': 'Flexibilní zóna',
            'code': '3C386',
            'svgId': '3C386',
            'id': 'NHQ-3NP-3C386',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3C399': {
            'name': 'Terasa',
            'code': '3C399',
            'svgId': '3C399',
            'id': 'NHQ-3NP-3C399',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D402': {
            'name': 'WC muži',
            'code': '3D402',
            'svgId': '3D402',
            'id': 'NHQ-3NP-3D402',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D403': {
            'name': 'WC muži imobil',
            'code': '3D403',
            'svgId': '3D403',
            'id': 'NHQ-3NP-3D403',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D404': {
            'name': 'WC muži',
            'code': '3D404',
            'svgId': '3D404',
            'id': 'NHQ-3NP-3D404',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D405': {
            'name': 'WC muži',
            'code': '3D405',
            'svgId': '3D405',
            'id': 'NHQ-3NP-3D405',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D406': {
            'name': 'WC muži',
            'code': '3D406',
            'svgId': '3D406',
            'id': 'NHQ-3NP-3D406',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D407': {
            'name': 'WC ženy',
            'code': '3D407',
            'svgId': '3D407',
            'id': 'NHQ-3NP-3D407',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D408': {
            'name': 'WC ženy',
            'code': '3D408',
            'svgId': '3D408',
            'id': 'NHQ-3NP-3D408',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D409': {
            'name': 'WC ženy',
            'code': '3D409',
            'svgId': '3D409',
            'id': 'NHQ-3NP-3D409',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D410': {
            'name': 'WC ženy imobil',
            'code': '3D410',
            'svgId': '3D410',
            'id': 'NHQ-3NP-3D410',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D411': {
            'name': 'WC ženy',
            'code': '3D411',
            'svgId': '3D411',
            'id': 'NHQ-3NP-3D411',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D414': {
            'name': 'Příruční spisovna',
            'code': '3D414',
            'svgId': '3D414',
            'id': 'NHQ-3NP-3D414',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D415': {
            'name': 'Chodba',
            'code': '3D415',
            'svgId': '3D415',
            'id': 'NHQ-3NP-3D415',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D416': {
            'name': 'Jednací místnost',
            'code': '3D416',
            'svgId': '3D416',
            'id': 'NHQ-3NP-3D416',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D417': {
            'name': 'Jednací místnost',
            'code': '3D417',
            'svgId': '3D417',
            'id': 'NHQ-3NP-3D417',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D418': {
            'name': 'Průchod',
            'code': '3D418',
            'svgId': '3D418',
            'id': 'NHQ-3NP-3D418',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D419': {
            'name': 'Jednací místnost',
            'code': '3D419',
            'svgId': '3D419',
            'id': 'NHQ-3NP-3D419',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D420': {
            'name': 'kancelář',
            'code': '3D420',
            'svgId': '3D420',
            'id': 'NHQ-3NP-3D420',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D421': {
            'name': 'Jednací místnost',
            'code': '3D421',
            'svgId': '3D421',
            'id': 'NHQ-3NP-3D421',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D422': {
            'name': 'Kancelář',
            'code': '3D422',
            'svgId': '3D422',
            'id': 'NHQ-3NP-3D422',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D423': {
            'name': 'Jednací místnost',
            'code': '3D423',
            'svgId': '3D423',
            'id': 'NHQ-3NP-3D423',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D424': {
            'name': 'Kancelář',
            'code': '3D424',
            'svgId': '3D424',
            'id': 'NHQ-3NP-3D424',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D425': {
            'name': 'Jednací místnost',
            'code': '3D425',
            'svgId': '3D425',
            'id': 'NHQ-3NP-3D425',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D426': {
            'name': 'Kancelář',
            'code': '3D426',
            'svgId': '3D426',
            'id': 'NHQ-3NP-3D426',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D427': {
            'name': 'Jednací místnost',
            'code': '3D427',
            'svgId': '3D427',
            'id': 'NHQ-3NP-3D427',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D441': {
            'name': 'Schodiště',
            'code': '3D441',
            'svgId': '3D441',
            'id': 'NHQ-3NP-3D441',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D442': {
            'name': 'Výtah',
            'code': '3D442',
            'svgId': '3D442',
            'id': 'NHQ-3NP-3D442',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D443': {
            'name': 'Kuchyňka',
            'code': '3D443',
            'svgId': '3D443',
            'id': 'NHQ-3NP-3D443',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D444': {
            'name': 'Chodba',
            'code': '3D444',
            'svgId': '3D444',
            'id': 'NHQ-3NP-3D444',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D445': {
            'name': 'Jednací místnost',
            'code': '3D445',
            'svgId': '3D445',
            'id': 'NHQ-3NP-3D445',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D446': {
            'name': 'Šatna',
            'code': '3D446',
            'svgId': '3D446',
            'id': 'NHQ-3NP-3D446',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D449': {
            'name': 'Sprcha',
            'code': '3D449',
            'svgId': '3D449',
            'id': 'NHQ-3NP-3D449',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D450': {
            'name': 'Jednací místnost',
            'code': '3D450',
            'svgId': '3D450',
            'id': 'NHQ-3NP-3D450',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D451': {
            'name': 'Jednací místnost',
            'code': '3D451',
            'svgId': '3D451',
            'id': 'NHQ-3NP-3D451',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D452': {
            'name': 'Jednací místnost',
            'code': '3D452',
            'svgId': '3D452',
            'id': 'NHQ-3NP-3D452',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D453': {
            'name': 'Jednací místnost',
            'code': '3D453',
            'svgId': '3D453',
            'id': 'NHQ-3NP-3D453',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D455': {
            'name': 'Kancelář',
            'code': '3D455',
            'svgId': '3D455',
            'id': 'NHQ-3NP-3D455',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D456': {
            'name': 'Jednací místnost',
            'code': '3D456',
            'svgId': '3D456',
            'id': 'NHQ-3NP-3D456',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D482': {
            'name': 'Chodba',
            'code': '3D482',
            'svgId': '3D482',
            'id': 'NHQ-3NP-3D482',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D483': {
            'name': 'Balkón',
            'code': '3D483',
            'svgId': '3D483',
            'id': 'NHQ-3NP-3D483',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D484': {
            'name': 'Schodiště',
            'code': '3D484',
            'svgId': '3D484',
            'id': 'NHQ-3NP-3D484',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D485': {
            'name': 'Flexibilní zóna',
            'code': '3D485',
            'svgId': '3D485',
            'id': 'NHQ-3NP-3D485',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3D486': {
            'name': 'Flexibilní zóna',
            'code': '3D486',
            'svgId': '3D486',
            'id': 'NHQ-3NP-3D486',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E501': {
            'name': 'Schodiště',
            'code': '3E501',
            'svgId': '3E501',
            'id': 'NHQ-3NP-3E501',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E502': {
            'name': 'Výtah',
            'code': '3E502',
            'svgId': '3E502',
            'id': 'NHQ-3NP-3E502',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E503': {
            'name': 'Kuchyňka',
            'code': '3E503',
            'svgId': '3E503',
            'id': 'NHQ-3NP-3E503',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E504': {
            'name': 'Chodba',
            'code': '3E504',
            'svgId': '3E504',
            'id': 'NHQ-3NP-3E504',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E505': {
            'name': 'Jednací místnost',
            'code': '3E505',
            'svgId': '3E505',
            'id': 'NHQ-3NP-3E505',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E506': {
            'name': 'Šatna',
            'code': '3E506',
            'svgId': '3E506',
            'id': 'NHQ-3NP-3E506',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E509': {
            'name': 'Sprcha',
            'code': '3E509',
            'svgId': '3E509',
            'id': 'NHQ-3NP-3E509',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E511': {
            'name': 'Kancelář',
            'code': '3E511',
            'svgId': '3E511',
            'id': 'NHQ-3NP-3E511',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E512': {
            'name': 'Jednací místnost',
            'code': '3E512',
            'svgId': '3E512',
            'id': 'NHQ-3NP-3E512',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E513': {
            'name': 'Jednací místnost',
            'code': '3E513',
            'svgId': '3E513',
            'id': 'NHQ-3NP-3E513',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E514': {
            'name': 'Jednací místnost',
            'code': '3E514',
            'svgId': '3E514',
            'id': 'NHQ-3NP-3E514',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E515': {
            'name': 'Jednací místnost',
            'code': '3E515',
            'svgId': '3E515',
            'id': 'NHQ-3NP-3E515',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E516': {
            'name': 'Jednací místnost',
            'code': '3E516',
            'svgId': '3E516',
            'id': 'NHQ-3NP-3E516',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E542': {
            'name': 'WC muži',
            'code': '3E542',
            'svgId': '3E542',
            'id': 'NHQ-3NP-3E542',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E543': {
            'name': 'WC muži imobil',
            'code': '3E543',
            'svgId': '3E543',
            'id': 'NHQ-3NP-3E543',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E544': {
            'name': 'WC muži',
            'code': '3E544',
            'svgId': '3E544',
            'id': 'NHQ-3NP-3E544',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E545': {
            'name': 'WC muži',
            'code': '3E545',
            'svgId': '3E545',
            'id': 'NHQ-3NP-3E545',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E546': {
            'name': 'WC muži',
            'code': '3E546',
            'svgId': '3E546',
            'id': 'NHQ-3NP-3E546',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E547': {
            'name': 'WC ženy',
            'code': '3E547',
            'svgId': '3E547',
            'id': 'NHQ-3NP-3E547',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E548': {
            'name': 'WC ženy',
            'code': '3E548',
            'svgId': '3E548',
            'id': 'NHQ-3NP-3E548',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E549': {
            'name': 'WC ženy',
            'code': '3E549',
            'svgId': '3E549',
            'id': 'NHQ-3NP-3E549',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E550': {
            'name': 'WC ženy imobil',
            'code': '3E550',
            'svgId': '3E550',
            'id': 'NHQ-3NP-3E550',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E551': {
            'name': 'WC ženy',
            'code': '3E551',
            'svgId': '3E551',
            'id': 'NHQ-3NP-3E551',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E554': {
            'name': 'Předsíň výtahu',
            'code': '3E554',
            'svgId': '3E554',
            'id': 'NHQ-3NP-3E554',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E555': {
            'name': 'Výtah evakuační',
            'code': '3E555',
            'svgId': '3E555',
            'id': 'NHQ-3NP-3E555',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E556': {
            'name': 'Příruční spisovna',
            'code': '3E556',
            'svgId': '3E556',
            'id': 'NHQ-3NP-3E556',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E557': {
            'name': 'Chodba',
            'code': '3E557',
            'svgId': '3E557',
            'id': 'NHQ-3NP-3E557',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E558': {
            'name': 'Jednací místnost',
            'code': '3E558',
            'svgId': '3E558',
            'id': 'NHQ-3NP-3E558',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E559': {
            'name': 'Jednací místnost',
            'code': '3E559',
            'svgId': '3E559',
            'id': 'NHQ-3NP-3E559',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E561': {
            'name': 'Kancelář',
            'code': '3E561',
            'svgId': '3E561',
            'id': 'NHQ-3NP-3E561',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E563': {
            'name': 'Jednací místnost',
            'code': '3E563',
            'svgId': '3E563',
            'id': 'NHQ-3NP-3E563',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3E581': {
            'name': 'Kancelář',
            'code': '3E581',
            'svgId': '3E581',
            'id': 'NHQ-3NP-3E581',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F602': {
            'name': 'WC muži',
            'code': '3F602',
            'svgId': '3F602',
            'id': 'NHQ-3NP-3F602',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F603': {
            'name': 'WC muži imobil',
            'code': '3F603',
            'svgId': '3F603',
            'id': 'NHQ-3NP-3F603',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F604': {
            'name': 'WC muži',
            'code': '3F604',
            'svgId': '3F604',
            'id': 'NHQ-3NP-3F604',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F605': {
            'name': 'WC muži',
            'code': '3F605',
            'svgId': '3F605',
            'id': 'NHQ-3NP-3F605',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F606': {
            'name': 'WC muži',
            'code': '3F606',
            'svgId': '3F606',
            'id': 'NHQ-3NP-3F606',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F607': {
            'name': 'WC ženy',
            'code': '3F607',
            'svgId': '3F607',
            'id': 'NHQ-3NP-3F607',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F608': {
            'name': 'WC ženy',
            'code': '3F608',
            'svgId': '3F608',
            'id': 'NHQ-3NP-3F608',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F609': {
            'name': 'WC ženy',
            'code': '3F609',
            'svgId': '3F609',
            'id': 'NHQ-3NP-3F609',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F610': {
            'name': 'WC ženy imobil',
            'code': '3F610',
            'svgId': '3F610',
            'id': 'NHQ-3NP-3F610',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F611': {
            'name': 'WC ženy',
            'code': '3F611',
            'svgId': '3F611',
            'id': 'NHQ-3NP-3F611',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F614': {
            'name': 'Příruční spisovna',
            'code': '3F614',
            'svgId': '3F614',
            'id': 'NHQ-3NP-3F614',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F615': {
            'name': 'Chodba',
            'code': '3F615',
            'svgId': '3F615',
            'id': 'NHQ-3NP-3F615',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F616': {
            'name': 'Jednací místnost',
            'code': '3F616',
            'svgId': '3F616',
            'id': 'NHQ-3NP-3F616',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F617': {
            'name': 'Jednací místnost',
            'code': '3F617',
            'svgId': '3F617',
            'id': 'NHQ-3NP-3F617',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F619': {
            'name': 'Jednací místnost',
            'code': '3F619',
            'svgId': '3F619',
            'id': 'NHQ-3NP-3F619',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F620': {
            'name': 'Jednací místnost',
            'code': '3F620',
            'svgId': '3F620',
            'id': 'NHQ-3NP-3F620',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F621': {
            'name': 'Jednací místnost',
            'code': '3F621',
            'svgId': '3F621',
            'id': 'NHQ-3NP-3F621',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F622': {
            'name': 'Kancelář',
            'code': '3F622',
            'svgId': '3F622',
            'id': 'NHQ-3NP-3F622',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F623': {
            'name': 'Jednací místnost',
            'code': '3F623',
            'svgId': '3F623',
            'id': 'NHQ-3NP-3F623',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F624': {
            'name': 'Jednací místnost',
            'code': '3F624',
            'svgId': '3F624',
            'id': 'NHQ-3NP-3F624',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F625': {
            'name': 'Jednací místnost',
            'code': '3F625',
            'svgId': '3F625',
            'id': 'NHQ-3NP-3F625',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F626': {
            'name': 'Jednací místnost',
            'code': '3F626',
            'svgId': '3F626',
            'id': 'NHQ-3NP-3F626',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F627': {
            'name': 'Jednací místnost',
            'code': '3F627',
            'svgId': '3F627',
            'id': 'NHQ-3NP-3F627',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F641': {
            'name': 'Schodiště',
            'code': '3F641',
            'svgId': '3F641',
            'id': 'NHQ-3NP-3F641',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F642': {
            'name': 'Výtah',
            'code': '3F642',
            'svgId': '3F642',
            'id': 'NHQ-3NP-3F642',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F643': {
            'name': 'Kuchyňka',
            'code': '3F643',
            'svgId': '3F643',
            'id': 'NHQ-3NP-3F643',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F644': {
            'name': 'Chodba',
            'code': '3F644',
            'svgId': '3F644',
            'id': 'NHQ-3NP-3F644',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F646': {
            'name': 'Šatna',
            'code': '3F646',
            'svgId': '3F646',
            'id': 'NHQ-3NP-3F646',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F649': {
            'name': 'Sprcha',
            'code': '3F649',
            'svgId': '3F649',
            'id': 'NHQ-3NP-3F649',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F650': {
            'name': 'Jednací místnost',
            'code': '3F650',
            'svgId': '3F650',
            'id': 'NHQ-3NP-3F650',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F651': {
            'name': 'Jednací místnost',
            'code': '3F651',
            'svgId': '3F651',
            'id': 'NHQ-3NP-3F651',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F652': {
            'name': 'Jednací místnost',
            'code': '3F652',
            'svgId': '3F652',
            'id': 'NHQ-3NP-3F652',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F653': {
            'name': 'Jednací místnost',
            'code': '3F653',
            'svgId': '3F653',
            'id': 'NHQ-3NP-3F653',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F654': {
            'name': 'Příruční spisovna',
            'code': '3F654',
            'svgId': '3F654',
            'id': 'NHQ-3NP-3F654',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F655': {
            'name': 'Kancelář',
            'code': '3F655',
            'svgId': '3F655',
            'id': 'NHQ-3NP-3F655',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F656': {
            'name': 'Jednací místnost',
            'code': '3F656',
            'svgId': '3F656',
            'id': 'NHQ-3NP-3F656',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F657': {
            'name': 'Kancelář',
            'code': '3F657',
            'svgId': '3F657',
            'id': 'NHQ-3NP-3F657',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F660': {
            'name': 'Diskrétní jednání',
            'code': '3F660',
            'svgId': '3F660',
            'id': 'NHQ-3NP-3F660',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F661': {
            'name': 'Diskrétní jednání',
            'code': '3F661',
            'svgId': '3F661',
            'id': 'NHQ-3NP-3F661',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F662': {
            'name': 'Diskrétní jednání',
            'code': '3F662',
            'svgId': '3F662',
            'id': 'NHQ-3NP-3F662',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F682': {
            'name': 'Chodba',
            'code': '3F682',
            'svgId': '3F682',
            'id': 'NHQ-3NP-3F682',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F683': {
            'name': 'Balkón',
            'code': '3F683',
            'svgId': '3F683',
            'id': 'NHQ-3NP-3F683',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F684': {
            'name': 'Schodiště',
            'code': '3F684',
            'svgId': '3F684',
            'id': 'NHQ-3NP-3F684',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F685': {
            'name': 'Flexibilní zóna',
            'code': '3F685',
            'svgId': '3F685',
            'id': 'NHQ-3NP-3F685',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-3F686': {
            'name': 'Flexibilní zóna',
            'code': '3F686',
            'svgId': '3F686',
            'id': 'NHQ-3NP-3F686',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2047': {
            'name': 'Tiskárna A4 / 3B',
            'code': 'A4_PCZNHQ2047',
            'svgId': 'A4_PCZNHQ2047',
            'id': 'NHQ-3NP-A4_PCZNHQ2047',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2045': {
            'name': 'Tiskárna A4 / 3B',
            'code': 'A4_PCZNHQ2045',
            'svgId': 'A4_PCZNHQ2045',
            'id': 'NHQ-3NP-A4_PCZNHQ2045',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A3_PCZNHQ2029': {
            'name': 'Tiskárna A3 / 3B',
            'code': 'A3_PCZNHQ2029',
            'svgId': 'A3_PCZNHQ2029',
            'id': 'NHQ-3NP-A3_PCZNHQ2029',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A3_PCZNHQ2030': {
            'name': 'Tiskárna A3 / 3B',
            'code': 'A3_PCZNHQ2030',
            'svgId': 'A3_PCZNHQ2030',
            'id': 'NHQ-3NP-A3_PCZNHQ2030',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2043': {
            'name': 'Tiskárna A4 / 3B',
            'code': 'A4_PCZNHQ2043',
            'svgId': 'A4_PCZNHQ2043',
            'id': 'NHQ-3NP-A4_PCZNHQ2043',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2042': {
            'name': 'Tiskárna A4 / 3D',
            'code': 'A4_PCZNHQ2042',
            'svgId': 'A4_PCZNHQ2042',
            'id': 'NHQ-3NP-A4_PCZNHQ2042',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2032': {
            'name': 'Tiskárna A4 / 3D',
            'code': 'A4_PCZNHQ2032',
            'svgId': 'A4_PCZNHQ2032',
            'id': 'NHQ-3NP-A4_PCZNHQ2032',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2041': {
            'name': 'Tiskárna A4 / 3F',
            'code': 'A4_PCZNHQ2041',
            'svgId': 'A4_PCZNHQ2041',
            'id': 'NHQ-3NP-A4_PCZNHQ2041',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A3_PCZNHQ2005': {
            'name': 'Tiskárna A3 / 3F',
            'code': 'A3_PCZNHQ2005',
            'svgId': 'A3_PCZNHQ2005',
            'id': 'NHQ-3NP-A3_PCZNHQ2005',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2052': {
            'name': 'Tiskárna A4 / 3E',
            'code': 'A4_PCZNHQ2052',
            'svgId': 'A4_PCZNHQ2052',
            'id': 'NHQ-3NP-A4_PCZNHQ2052',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2015': {
            'name': 'Tiskárna A4 / 3E',
            'code': 'A4_PCZNHQ2015',
            'svgId': 'A4_PCZNHQ2015',
            'id': 'NHQ-3NP-A4_PCZNHQ2015',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2014': {
            'name': 'Tiskárna A4 / 3C',
            'code': 'A4_PCZNHQ2014',
            'svgId': 'A4_PCZNHQ2014',
            'id': 'NHQ-3NP-A4_PCZNHQ2014',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2013': {
            'name': 'Tiskárna A4 / 3C',
            'code': 'A4_PCZNHQ2013',
            'svgId': 'A4_PCZNHQ2013',
            'id': 'NHQ-3NP-A4_PCZNHQ2013',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2016': {
            'name': 'Tiskárna A4 / 3C',
            'code': 'A4_PCZNHQ2016',
            'svgId': 'A4_PCZNHQ2016',
            'id': 'NHQ-3NP-A4_PCZNHQ2016',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2033': {
            'name': 'Tiskárna A4 / 3A',
            'code': 'A4_PCZNHQ2033',
            'svgId': 'A4_PCZNHQ2033',
            'id': 'NHQ-3NP-A4_PCZNHQ2033',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          },
          'NHQ-3NP-A4_PCZNHQ2037': {
            'name': 'Tiskárna A4 / 3A',
            'code': 'A4_PCZNHQ2037',
            'svgId': 'A4_PCZNHQ2037',
            'id': 'NHQ-3NP-A4_PCZNHQ2037',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-3NP',
            'type': ''
          }
        }
      },
      'NHQ-4NP': {
        'name': '4. patro',
        'code': '4NP',
        'svgId': '4NP',
        'buildingId': 'NHQ',
        'id': 'NHQ-4NP',
        'rooms': {
          'NHQ-4NP-4A101': {
            'name': 'Schodiště',
            'code': '4A101',
            'svgId': '4A101',
            'id': 'NHQ-4NP-4A101',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A102': {
            'name': 'Výtah',
            'code': '4A102',
            'svgId': '4A102',
            'id': 'NHQ-4NP-4A102',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A103': {
            'name': 'Kuchyňka',
            'code': '4A103',
            'svgId': '4A103',
            'id': 'NHQ-4NP-4A103',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A104': {
            'name': 'Chodba',
            'code': '4A104',
            'svgId': '4A104',
            'id': 'NHQ-4NP-4A104',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A106': {
            'name': 'Předsíň soc. zázemí',
            'code': '4A106',
            'svgId': '4A106',
            'id': 'NHQ-4NP-4A106',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A108': {
            'name': 'Sprcha',
            'code': '4A108',
            'svgId': '4A108',
            'id': 'NHQ-4NP-4A108',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A109': {
            'name': 'Kuchyňka',
            'code': '4A109',
            'svgId': '4A109',
            'id': 'NHQ-4NP-4A109',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A110': {
            'name': 'WC',
            'code': '4A110',
            'svgId': '4A110',
            'id': 'NHQ-4NP-4A110',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A111': {
            'name': 'Sprcha',
            'code': '4A111',
            'svgId': '4A111',
            'id': 'NHQ-4NP-4A111',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A112': {
            'name': 'WC',
            'code': '4A112',
            'svgId': '4A112',
            'id': 'NHQ-4NP-4A112',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A113': {
            'name': 'Kancelář',
            'code': '4A113',
            'svgId': '4A113',
            'id': 'NHQ-4NP-4A113',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A114': {
            'name': 'Kancelář',
            'code': '4A114',
            'svgId': '4A114',
            'id': 'NHQ-4NP-4A114',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A115': {
            'name': 'Kancelář',
            'code': '4A115',
            'svgId': '4A115',
            'id': 'NHQ-4NP-4A115',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A116': {
            'name': 'Kancelář',
            'code': '4A116',
            'svgId': '4A116',
            'id': 'NHQ-4NP-4A116',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A117': {
            'name': 'Flexibilní zóna',
            'code': '4A117',
            'svgId': '4A117',
            'id': 'NHQ-4NP-4A117',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A118': {
            'name': 'Jednací místnost',
            'code': '4A118',
            'svgId': '4A118',
            'id': 'NHQ-4NP-4A118',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A119': {
            'name': 'Terasa',
            'code': '4A119',
            'svgId': '4A119',
            'id': 'NHQ-4NP-4A119',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A120': {
            'name': 'Odpočivná zóna',
            'code': '4A120',
            'svgId': '4A120',
            'id': 'NHQ-4NP-4A120',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A142': {
            'name': 'WC muži',
            'code': '4A142',
            'svgId': '4A142',
            'id': 'NHQ-4NP-4A142',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A143': {
            'name': 'WC muži',
            'code': '4A143',
            'svgId': '4A143',
            'id': 'NHQ-4NP-4A143',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A144': {
            'name': 'WC muži',
            'code': '4A144',
            'svgId': '4A144',
            'id': 'NHQ-4NP-4A144',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A145': {
            'name': 'WC muži',
            'code': '4A145',
            'svgId': '4A145',
            'id': 'NHQ-4NP-4A145',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A146': {
            'name': 'WC muži',
            'code': '4A146',
            'svgId': '4A146',
            'id': 'NHQ-4NP-4A146',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A147': {
            'name': 'WC ženy',
            'code': '4A147',
            'svgId': '4A147',
            'id': 'NHQ-4NP-4A147',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A148': {
            'name': 'WC ženy',
            'code': '4A148',
            'svgId': '4A148',
            'id': 'NHQ-4NP-4A148',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A149': {
            'name': 'WC ženy',
            'code': '4A149',
            'svgId': '4A149',
            'id': 'NHQ-4NP-4A149',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A150': {
            'name': 'WC ženy',
            'code': '4A150',
            'svgId': '4A150',
            'id': 'NHQ-4NP-4A150',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A151': {
            'name': 'WC ženy',
            'code': '4A151',
            'svgId': '4A151',
            'id': 'NHQ-4NP-4A151',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A155': {
            'name': 'Chodba',
            'code': '4A155',
            'svgId': '4A155',
            'id': 'NHQ-4NP-4A155',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A157': {
            'name': 'Schodiště',
            'code': '4A157',
            'svgId': '4A157',
            'id': 'NHQ-4NP-4A157',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A158': {
            'name': 'Šatna',
            'code': '4A158',
            'svgId': '4A158',
            'id': 'NHQ-4NP-4A158',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A159': {
            'name': 'Jednací místnost',
            'code': '4A159',
            'svgId': '4A159',
            'id': 'NHQ-4NP-4A159',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A160': {
            'name': 'Jednací místnost',
            'code': '4A160',
            'svgId': '4A160',
            'id': 'NHQ-4NP-4A160',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A161': {
            'name': 'Kancelář',
            'code': '4A161',
            'svgId': '4A161',
            'id': 'NHQ-4NP-4A161',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A162': {
            'name': 'Chodba',
            'code': '4A162',
            'svgId': '4A162',
            'id': 'NHQ-4NP-4A162',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A163': {
            'name': 'Jednací místnost',
            'code': '4A163',
            'svgId': '4A163',
            'id': 'NHQ-4NP-4A163',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A164': {
            'name': 'Kancelář',
            'code': '4A164',
            'svgId': '4A164',
            'id': 'NHQ-4NP-4A164',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A165': {
            'name': 'Jednací místnost',
            'code': '4A165',
            'svgId': '4A165',
            'id': 'NHQ-4NP-4A165',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A166': {
            'name': 'Kancelář',
            'code': '4A166',
            'svgId': '4A166',
            'id': 'NHQ-4NP-4A166',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A167': {
            'name': 'Kancelář',
            'code': '4A167',
            'svgId': '4A167',
            'id': 'NHQ-4NP-4A167',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A168': {
            'name': 'Kancelář',
            'code': '4A168',
            'svgId': '4A168',
            'id': 'NHQ-4NP-4A168',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A169': {
            'name': 'Jednací místnost',
            'code': '4A169',
            'svgId': '4A169',
            'id': 'NHQ-4NP-4A169',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A170': {
            'name': 'Kancelář',
            'code': '4A170',
            'svgId': '4A170',
            'id': 'NHQ-4NP-4A170',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A171': {
            'name': 'Kancelář',
            'code': '4A171',
            'svgId': '4A171',
            'id': 'NHQ-4NP-4A171',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A172': {
            'name': 'Kancelář',
            'code': '4A172',
            'svgId': '4A172',
            'id': 'NHQ-4NP-4A172',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A173': {
            'name': 'Kancelář',
            'code': '4A173',
            'svgId': '4A173',
            'id': 'NHQ-4NP-4A173',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A174': {
            'name': 'Kancelář',
            'code': '4A174',
            'svgId': '4A174',
            'id': 'NHQ-4NP-4A174',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A175': {
            'name': 'Jednací místnost',
            'code': '4A175',
            'svgId': '4A175',
            'id': 'NHQ-4NP-4A175',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A176': {
            'name': 'Jednací místnost',
            'code': '4A176',
            'svgId': '4A176',
            'id': 'NHQ-4NP-4A176',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A180': {
            'name': 'Kuchyňka',
            'code': '4A180',
            'svgId': '4A180',
            'id': 'NHQ-4NP-4A180',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A181': {
            'name': 'Chodba',
            'code': '4A181',
            'svgId': '4A181',
            'id': 'NHQ-4NP-4A181',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A182': {
            'name': 'Chodba',
            'code': '4A182',
            'svgId': '4A182',
            'id': 'NHQ-4NP-4A182',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A183': {
            'name': 'Balkón',
            'code': '4A183',
            'svgId': '4A183',
            'id': 'NHQ-4NP-4A183',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A185': {
            'name': 'Kancelář',
            'code': '4A185',
            'svgId': '4A185',
            'id': 'NHQ-4NP-4A185',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A186': {
            'name': 'Kancelář',
            'code': '4A186',
            'svgId': '4A186',
            'id': 'NHQ-4NP-4A186',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4A187': {
            'name': 'Flexibilní zóna',
            'code': '4A187',
            'svgId': '4A187',
            'id': 'NHQ-4NP-4A187',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B202': {
            'name': 'WC muži',
            'code': '4B202',
            'svgId': '4B202',
            'id': 'NHQ-4NP-4B202',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B203': {
            'name': 'WC muži',
            'code': '4B203',
            'svgId': '4B203',
            'id': 'NHQ-4NP-4B203',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B204': {
            'name': 'WC muži',
            'code': '4B204',
            'svgId': '4B204',
            'id': 'NHQ-4NP-4B204',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B205': {
            'name': 'WC muži',
            'code': '4B205',
            'svgId': '4B205',
            'id': 'NHQ-4NP-4B205',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B206': {
            'name': 'WC muži',
            'code': '4B206',
            'svgId': '4B206',
            'id': 'NHQ-4NP-4B206',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B207': {
            'name': 'WC ženy',
            'code': '4B207',
            'svgId': '4B207',
            'id': 'NHQ-4NP-4B207',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B208': {
            'name': 'WC ženy',
            'code': '4B208',
            'svgId': '4B208',
            'id': 'NHQ-4NP-4B208',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B209': {
            'name': 'WC ženy',
            'code': '4B209',
            'svgId': '4B209',
            'id': 'NHQ-4NP-4B209',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B210': {
            'name': 'WC ženy',
            'code': '4B210',
            'svgId': '4B210',
            'id': 'NHQ-4NP-4B210',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B211': {
            'name': 'WC ženy',
            'code': '4B211',
            'svgId': '4B211',
            'id': 'NHQ-4NP-4B211',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B214': {
            'name': 'Předsíň výtahu',
            'code': '4B214',
            'svgId': '4B214',
            'id': 'NHQ-4NP-4B214',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B215': {
            'name': 'Výtah evakuační',
            'code': '4B215',
            'svgId': '4B215',
            'id': 'NHQ-4NP-4B215',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B216': {
            'name': 'Příruční spisovna',
            'code': '4B216',
            'svgId': '4B216',
            'id': 'NHQ-4NP-4B216',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B217': {
            'name': 'Chodba',
            'code': '4B217',
            'svgId': '4B217',
            'id': 'NHQ-4NP-4B217',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B218': {
            'name': 'Balkón',
            'code': '4B218',
            'svgId': '4B218',
            'id': 'NHQ-4NP-4B218',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B221': {
            'name': 'Šatna',
            'code': '4B221',
            'svgId': '4B221',
            'id': 'NHQ-4NP-4B221',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B222': {
            'name': 'Jednací místnost',
            'code': '4B222',
            'svgId': '4B222',
            'id': 'NHQ-4NP-4B222',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B223': {
            'name': 'Kancelář',
            'code': '4B223',
            'svgId': '4B223',
            'id': 'NHQ-4NP-4B223',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B224': {
            'name': 'Kancelář',
            'code': '4B224',
            'svgId': '4B224',
            'id': 'NHQ-4NP-4B224',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B225': {
            'name': 'Kancelář',
            'code': '4B225',
            'svgId': '4B225',
            'id': 'NHQ-4NP-4B225',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B241': {
            'name': 'Schodiště',
            'code': '4B241',
            'svgId': '4B241',
            'id': 'NHQ-4NP-4B241',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B242': {
            'name': 'Výtah',
            'code': '4B242',
            'svgId': '4B242',
            'id': 'NHQ-4NP-4B242',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B243': {
            'name': 'Kuchyňka',
            'code': '4B243',
            'svgId': '4B243',
            'id': 'NHQ-4NP-4B243',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B244': {
            'name': 'Chodba',
            'code': '4B244',
            'svgId': '4B244',
            'id': 'NHQ-4NP-4B244',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B245': {
            'name': 'Jednací místnost',
            'code': '4B245',
            'svgId': '4B245',
            'id': 'NHQ-4NP-4B245',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B246': {
            'name': 'Šatna',
            'code': '4B246',
            'svgId': '4B246',
            'id': 'NHQ-4NP-4B246',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B249': {
            'name': 'Sprcha',
            'code': '4B249',
            'svgId': '4B249',
            'id': 'NHQ-4NP-4B249',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B250': {
            'name': 'Odpočivná zóna',
            'code': '4B250',
            'svgId': '4B250',
            'id': 'NHQ-4NP-4B250',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B254': {
            'name': 'Šatna',
            'code': '4B254',
            'svgId': '4B254',
            'id': 'NHQ-4NP-4B254',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B255': {
            'name': 'Kancelář',
            'code': '4B255',
            'svgId': '4B255',
            'id': 'NHQ-4NP-4B255',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B256': {
            'name': 'Jednací místnost',
            'code': '4B256',
            'svgId': '4B256',
            'id': 'NHQ-4NP-4B256',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4B281': {
            'name': 'Kancelář',
            'code': '4B281',
            'svgId': '4B281',
            'id': 'NHQ-4NP-4B281',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C301': {
            'name': 'Schodiště',
            'code': '4C301',
            'svgId': '4C301',
            'id': 'NHQ-4NP-4C301',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C302': {
            'name': 'Výtah',
            'code': '4C302',
            'svgId': '4C302',
            'id': 'NHQ-4NP-4C302',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C303': {
            'name': 'Kuchyňka',
            'code': '4C303',
            'svgId': '4C303',
            'id': 'NHQ-4NP-4C303',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C304': {
            'name': 'Chodba',
            'code': '4C304',
            'svgId': '4C304',
            'id': 'NHQ-4NP-4C304',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C306': {
            'name': 'Šatna',
            'code': '4C306',
            'svgId': '4C306',
            'id': 'NHQ-4NP-4C306',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C309': {
            'name': 'Sprcha',
            'code': '4C309',
            'svgId': '4C309',
            'id': 'NHQ-4NP-4C309',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C310': {
            'name': 'Šatna',
            'code': '4C310',
            'svgId': '4C310',
            'id': 'NHQ-4NP-4C310',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C311': {
            'name': 'Kancelář',
            'code': '4C311',
            'svgId': '4C311',
            'id': 'NHQ-4NP-4C311',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C312': {
            'name': 'Jednací místnost',
            'code': '4C312',
            'svgId': '4C312',
            'id': 'NHQ-4NP-4C312',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C313': {
            'name': 'Odpočivná zóna',
            'code': '4C313',
            'svgId': '4C313',
            'id': 'NHQ-4NP-4C313',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C320': {
            'name': 'Diskrétní jednání',
            'code': '4C320',
            'svgId': '4C320',
            'id': 'NHQ-4NP-4C320',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C321': {
            'name': 'Diskrétní jednání',
            'code': '4C321',
            'svgId': '4C321',
            'id': 'NHQ-4NP-4C321',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C322': {
            'name': 'Diskrétní jednání',
            'code': '4C322',
            'svgId': '4C322',
            'id': 'NHQ-4NP-4C322',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C342': {
            'name': 'WC muži',
            'code': '4C342',
            'svgId': '4C342',
            'id': 'NHQ-4NP-4C342',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C343': {
            'name': 'WC muži imobil',
            'code': '4C343',
            'svgId': '4C343',
            'id': 'NHQ-4NP-4C343',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C344': {
            'name': 'WC muži',
            'code': '4C344',
            'svgId': '4C344',
            'id': 'NHQ-4NP-4C344',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C345': {
            'name': 'WC muži',
            'code': '4C345',
            'svgId': '4C345',
            'id': 'NHQ-4NP-4C345',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C346': {
            'name': 'WC muži',
            'code': '4C346',
            'svgId': '4C346',
            'id': 'NHQ-4NP-4C346',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C347': {
            'name': 'WC ženy',
            'code': '4C347',
            'svgId': '4C347',
            'id': 'NHQ-4NP-4C347',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C348': {
            'name': 'WC ženy',
            'code': '4C348',
            'svgId': '4C348',
            'id': 'NHQ-4NP-4C348',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C349': {
            'name': 'WC ženy',
            'code': '4C349',
            'svgId': '4C349',
            'id': 'NHQ-4NP-4C349',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C350': {
            'name': 'WC ženy imobil',
            'code': '4C350',
            'svgId': '4C350',
            'id': 'NHQ-4NP-4C350',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C351': {
            'name': 'WC ženy',
            'code': '4C351',
            'svgId': '4C351',
            'id': 'NHQ-4NP-4C351',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C355': {
            'name': 'Chodba',
            'code': '4C355',
            'svgId': '4C355',
            'id': 'NHQ-4NP-4C355',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C357': {
            'name': 'Schodiště',
            'code': '4C357',
            'svgId': '4C357',
            'id': 'NHQ-4NP-4C357',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C358': {
            'name': 'Šatna',
            'code': '4C358',
            'svgId': '4C358',
            'id': 'NHQ-4NP-4C358',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C359': {
            'name': 'Jednací místnost',
            'code': '4C359',
            'svgId': '4C359',
            'id': 'NHQ-4NP-4C359',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C360': {
            'name': 'Jednací místnost',
            'code': '4C360',
            'svgId': '4C360',
            'id': 'NHQ-4NP-4C360',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C361': {
            'name': 'Kancelář',
            'code': '4C361',
            'svgId': '4C361',
            'id': 'NHQ-4NP-4C361',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C362': {
            'name': 'Kancelář',
            'code': '4C362',
            'svgId': '4C362',
            'id': 'NHQ-4NP-4C362',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C363': {
            'name': 'Flexibilní zóna',
            'code': '4C363',
            'svgId': '4C363',
            'id': 'NHQ-4NP-4C363',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C364': {
            'name': 'Jednací místnost',
            'code': '4C364',
            'svgId': '4C364',
            'id': 'NHQ-4NP-4C364',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C365': {
            'name': 'Jednací místnost',
            'code': '4C365',
            'svgId': '4C365',
            'id': 'NHQ-4NP-4C365',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C366': {
            'name': 'Kancelář',
            'code': '4C366',
            'svgId': '4C366',
            'id': 'NHQ-4NP-4C366',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C367': {
            'name': 'Jednací místnost',
            'code': '4C367',
            'svgId': '4C367',
            'id': 'NHQ-4NP-4C367',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C381': {
            'name': 'Chodba',
            'code': '4C381',
            'svgId': '4C381',
            'id': 'NHQ-4NP-4C381',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C382': {
            'name': 'Chodba',
            'code': '4C382',
            'svgId': '4C382',
            'id': 'NHQ-4NP-4C382',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C383': {
            'name': 'Balkón',
            'code': '4C383',
            'svgId': '4C383',
            'id': 'NHQ-4NP-4C383',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C385': {
            'name': 'Kancelář',
            'code': '4C385',
            'svgId': '4C385',
            'id': 'NHQ-4NP-4C385',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C386': {
            'name': 'Kancelář',
            'code': '4C386',
            'svgId': '4C386',
            'id': 'NHQ-4NP-4C386',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4C387': {
            'name': 'Kancelář',
            'code': '4C387',
            'svgId': '4C387',
            'id': 'NHQ-4NP-4C387',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D402': {
            'name': 'WC muži',
            'code': '4D402',
            'svgId': '4D402',
            'id': 'NHQ-4NP-4D402',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D403': {
            'name': 'WC muži imobil',
            'code': '4D403',
            'svgId': '4D403',
            'id': 'NHQ-4NP-4D403',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D404': {
            'name': 'WC muži',
            'code': '4D404',
            'svgId': '4D404',
            'id': 'NHQ-4NP-4D404',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D405': {
            'name': 'WC muži',
            'code': '4D405',
            'svgId': '4D405',
            'id': 'NHQ-4NP-4D405',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D406': {
            'name': 'WC muži',
            'code': '4D406',
            'svgId': '4D406',
            'id': 'NHQ-4NP-4D406',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D407': {
            'name': 'WC ženy',
            'code': '4D407',
            'svgId': '4D407',
            'id': 'NHQ-4NP-4D407',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D408': {
            'name': 'WC ženy',
            'code': '4D408',
            'svgId': '4D408',
            'id': 'NHQ-4NP-4D408',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D409': {
            'name': 'WC ženy',
            'code': '4D409',
            'svgId': '4D409',
            'id': 'NHQ-4NP-4D409',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D410': {
            'name': 'WC ženy imobil',
            'code': '4D410',
            'svgId': '4D410',
            'id': 'NHQ-4NP-4D410',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D411': {
            'name': 'WC ženy',
            'code': '4D411',
            'svgId': '4D411',
            'id': 'NHQ-4NP-4D411',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D414': {
            'name': 'Příruční spisovna',
            'code': '4D414',
            'svgId': '4D414',
            'id': 'NHQ-4NP-4D414',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D415': {
            'name': 'Chodba',
            'code': '4D415',
            'svgId': '4D415',
            'id': 'NHQ-4NP-4D415',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D417': {
            'name': 'Schodiště',
            'code': '4D417',
            'svgId': '4D417',
            'id': 'NHQ-4NP-4D417',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D418': {
            'name': 'Šatna',
            'code': '4D418',
            'svgId': '4D418',
            'id': 'NHQ-4NP-4D418',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D419': {
            'name': 'Jednací místnost',
            'code': '4D419',
            'svgId': '4D419',
            'id': 'NHQ-4NP-4D419',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D420': {
            'name': 'Kancelář',
            'code': '4D420',
            'svgId': '4D420',
            'id': 'NHQ-4NP-4D420',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D421': {
            'name': 'Jednací místnost',
            'code': '4D421',
            'svgId': '4D421',
            'id': 'NHQ-4NP-4D421',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D422': {
            'name': 'Kancelář',
            'code': '4D422',
            'svgId': '4D422',
            'id': 'NHQ-4NP-4D422',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D423': {
            'name': 'Jednací místnost',
            'code': '4D423',
            'svgId': '4D423',
            'id': 'NHQ-4NP-4D423',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D424': {
            'name': 'Jednací místnost',
            'code': '4D424',
            'svgId': '4D424',
            'id': 'NHQ-4NP-4D424',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D425': {
            'name': 'Jednací místnost',
            'code': '4D425',
            'svgId': '4D425',
            'id': 'NHQ-4NP-4D425',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D426': {
            'name': 'Jednací místnost',
            'code': '4D426',
            'svgId': '4D426',
            'id': 'NHQ-4NP-4D426',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D427': {
            'name': 'Jednací místnost',
            'code': '4D427',
            'svgId': '4D427',
            'id': 'NHQ-4NP-4D427',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D441': {
            'name': 'Schodiště',
            'code': '4D441',
            'svgId': '4D441',
            'id': 'NHQ-4NP-4D441',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D442': {
            'name': 'Výtah',
            'code': '4D442',
            'svgId': '4D442',
            'id': 'NHQ-4NP-4D442',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D443': {
            'name': 'Kuchyňka',
            'code': '4D443',
            'svgId': '4D443',
            'id': 'NHQ-4NP-4D443',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D444': {
            'name': 'Chodba',
            'code': '4D444',
            'svgId': '4D444',
            'id': 'NHQ-4NP-4D444',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D445': {
            'name': 'Jednací místnost',
            'code': '4D445',
            'svgId': '4D445',
            'id': 'NHQ-4NP-4D445',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D446': {
            'name': 'Šatna',
            'code': '4D446',
            'svgId': '4D446',
            'id': 'NHQ-4NP-4D446',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D449': {
            'name': 'Sprcha',
            'code': '4D449',
            'svgId': '4D449',
            'id': 'NHQ-4NP-4D449',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D450': {
            'name': 'Odpočivná zóna',
            'code': '4D450',
            'svgId': '4D450',
            'id': 'NHQ-4NP-4D450',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D454': {
            'name': 'Šatna',
            'code': '4D454',
            'svgId': '4D454',
            'id': 'NHQ-4NP-4D454',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D455': {
            'name': 'Kancelář',
            'code': '4D455',
            'svgId': '4D455',
            'id': 'NHQ-4NP-4D455',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D456': {
            'name': 'Jednací místnost',
            'code': '4D456',
            'svgId': '4D456',
            'id': 'NHQ-4NP-4D456',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D481': {
            'name': 'Chodba',
            'code': '4D481',
            'svgId': '4D481',
            'id': 'NHQ-4NP-4D481',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D482': {
            'name': 'Chodba',
            'code': '4D482',
            'svgId': '4D482',
            'id': 'NHQ-4NP-4D482',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D483': {
            'name': 'Balkón',
            'code': '4D483',
            'svgId': '4D483',
            'id': 'NHQ-4NP-4D483',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D485': {
            'name': 'Kancelář',
            'code': '4D485',
            'svgId': '4D485',
            'id': 'NHQ-4NP-4D485',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D486': {
            'name': 'Kancelář',
            'code': '4D486',
            'svgId': '4D486',
            'id': 'NHQ-4NP-4D486',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4D487': {
            'name': 'Kancelář',
            'code': '4D487',
            'svgId': '4D487',
            'id': 'NHQ-4NP-4D487',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E501': {
            'name': 'Schodiště',
            'code': '4E501',
            'svgId': '4E501',
            'id': 'NHQ-4NP-4E501',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E502': {
            'name': 'Výtah',
            'code': '4E502',
            'svgId': '4E502',
            'id': 'NHQ-4NP-4E502',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E503': {
            'name': 'Kuchyňka',
            'code': '4E503',
            'svgId': '4E503',
            'id': 'NHQ-4NP-4E503',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E504': {
            'name': 'Chodba',
            'code': '4E504',
            'svgId': '4E504',
            'id': 'NHQ-4NP-4E504',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E506': {
            'name': 'Šatna',
            'code': '4E506',
            'svgId': '4E506',
            'id': 'NHQ-4NP-4E506',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E509': {
            'name': 'Sprcha',
            'code': '4E509',
            'svgId': '4E509',
            'id': 'NHQ-4NP-4E509',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E510': {
            'name': 'Šatna',
            'code': '4E510',
            'svgId': '4E510',
            'id': 'NHQ-4NP-4E510',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E511': {
            'name': 'Kancelář',
            'code': '4E511',
            'svgId': '4E511',
            'id': 'NHQ-4NP-4E511',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E512': {
            'name': 'Jednací místnost',
            'code': '4E512',
            'svgId': '4E512',
            'id': 'NHQ-4NP-4E512',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E513': {
            'name': 'Odpočivná zóna',
            'code': '4E513',
            'svgId': '4E513',
            'id': 'NHQ-4NP-4E513',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E520': {
            'name': 'Diskrétní jednání',
            'code': '4E520',
            'svgId': '4E520',
            'id': 'NHQ-4NP-4E520',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E521': {
            'name': 'Diskrétní jednání',
            'code': '4E521',
            'svgId': '4E521',
            'id': 'NHQ-4NP-4E521',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E522': {
            'name': 'Diskrétní jednání',
            'code': '4E522',
            'svgId': '4E522',
            'id': 'NHQ-4NP-4E522',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E542': {
            'name': 'WC muži',
            'code': '4E542',
            'svgId': '4E542',
            'id': 'NHQ-4NP-4E542',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E543': {
            'name': 'WC muži',
            'code': '4E543',
            'svgId': '4E543',
            'id': 'NHQ-4NP-4E543',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E544': {
            'name': 'WC muži',
            'code': '4E544',
            'svgId': '4E544',
            'id': 'NHQ-4NP-4E544',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E545': {
            'name': 'WC muži',
            'code': '4E545',
            'svgId': '4E545',
            'id': 'NHQ-4NP-4E545',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E546': {
            'name': 'WC muži',
            'code': '4E546',
            'svgId': '4E546',
            'id': 'NHQ-4NP-4E546',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E547': {
            'name': 'WC ženy',
            'code': '4E547',
            'svgId': '4E547',
            'id': 'NHQ-4NP-4E547',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E548': {
            'name': 'WC ženy',
            'code': '4E548',
            'svgId': '4E548',
            'id': 'NHQ-4NP-4E548',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E549': {
            'name': 'WC ženy',
            'code': '4E549',
            'svgId': '4E549',
            'id': 'NHQ-4NP-4E549',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E550': {
            'name': 'WC ženy',
            'code': '4E550',
            'svgId': '4E550',
            'id': 'NHQ-4NP-4E550',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E551': {
            'name': 'WC ženy',
            'code': '4E551',
            'svgId': '4E551',
            'id': 'NHQ-4NP-4E551',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E554': {
            'name': 'Předsíň výtahu',
            'code': '4E554',
            'svgId': '4E554',
            'id': 'NHQ-4NP-4E554',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E555': {
            'name': 'Výtah evakuační',
            'code': '4E555',
            'svgId': '4E555',
            'id': 'NHQ-4NP-4E555',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E556': {
            'name': 'Příruční spisovna',
            'code': '4E556',
            'svgId': '4E556',
            'id': 'NHQ-4NP-4E556',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E557': {
            'name': 'Chodba',
            'code': '4E557',
            'svgId': '4E557',
            'id': 'NHQ-4NP-4E557',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E560': {
            'name': 'Šatna',
            'code': '4E560',
            'svgId': '4E560',
            'id': 'NHQ-4NP-4E560',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E561': {
            'name': 'Kancelář',
            'code': '4E561',
            'svgId': '4E561',
            'id': 'NHQ-4NP-4E561',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E563': {
            'name': 'Jednací místnost',
            'code': '4E563',
            'svgId': '4E563',
            'id': 'NHQ-4NP-4E563',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E564': {
            'name': 'Jednací místnost',
            'code': '4E564',
            'svgId': '4E564',
            'id': 'NHQ-4NP-4E564',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4E581': {
            'name': 'Kancelář',
            'code': '4E581',
            'svgId': '4E581',
            'id': 'NHQ-4NP-4E581',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F602': {
            'name': 'WC muži',
            'code': '4F602',
            'svgId': '4F602',
            'id': 'NHQ-4NP-4F602',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F603': {
            'name': 'WC muži',
            'code': '4F603',
            'svgId': '4F603',
            'id': 'NHQ-4NP-4F603',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F604': {
            'name': 'WC muži',
            'code': '4F604',
            'svgId': '4F604',
            'id': 'NHQ-4NP-4F604',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F605': {
            'name': 'WC muži',
            'code': '4F605',
            'svgId': '4F605',
            'id': 'NHQ-4NP-4F605',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F606': {
            'name': 'WC muži',
            'code': '4F606',
            'svgId': '4F606',
            'id': 'NHQ-4NP-4F606',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F607': {
            'name': 'WC ženy',
            'code': '4F607',
            'svgId': '4F607',
            'id': 'NHQ-4NP-4F607',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F608': {
            'name': 'WC ženy',
            'code': '4F608',
            'svgId': '4F608',
            'id': 'NHQ-4NP-4F608',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F609': {
            'name': 'WC ženy',
            'code': '4F609',
            'svgId': '4F609',
            'id': 'NHQ-4NP-4F609',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F610': {
            'name': 'WC ženy',
            'code': '4F610',
            'svgId': '4F610',
            'id': 'NHQ-4NP-4F610',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F611': {
            'name': 'WC ženy',
            'code': '4F611',
            'svgId': '4F611',
            'id': 'NHQ-4NP-4F611',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F614': {
            'name': 'Příruční spisovna',
            'code': '4F614',
            'svgId': '4F614',
            'id': 'NHQ-4NP-4F614',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F615': {
            'name': 'Chodba',
            'code': '4F615',
            'svgId': '4F615',
            'id': 'NHQ-4NP-4F615',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F617': {
            'name': 'Schodiště',
            'code': '4F617',
            'svgId': '4F617',
            'id': 'NHQ-4NP-4F617',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F618': {
            'name': 'Šatna',
            'code': '4F618',
            'svgId': '4F618',
            'id': 'NHQ-4NP-4F618',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F619': {
            'name': 'kancelář',
            'code': '4F619',
            'svgId': '4F619',
            'id': 'NHQ-4NP-4F619',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F620': {
            'name': 'kancelář',
            'code': '4F620',
            'svgId': '4F620',
            'id': 'NHQ-4NP-4F620',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F621': {
            'name': 'Jednací místnost',
            'code': '4F621',
            'svgId': '4F621',
            'id': 'NHQ-4NP-4F621',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F622': {
            'name': 'Kancelář',
            'code': '4F622',
            'svgId': '4F622',
            'id': 'NHQ-4NP-4F622',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F623': {
            'name': 'Jednací místnost',
            'code': '4F623',
            'svgId': '4F623',
            'id': 'NHQ-4NP-4F623',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F624': {
            'name': 'Jednací místnost',
            'code': '4F624',
            'svgId': '4F624',
            'id': 'NHQ-4NP-4F624',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F625': {
            'name': 'Jednací místnost',
            'code': '4F625',
            'svgId': '4F625',
            'id': 'NHQ-4NP-4F625',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F626': {
            'name': 'Jednací místnost',
            'code': '4F626',
            'svgId': '4F626',
            'id': 'NHQ-4NP-4F626',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F627': {
            'name': 'Jednací místnost',
            'code': '4F627',
            'svgId': '4F627',
            'id': 'NHQ-4NP-4F627',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F641': {
            'name': 'Schodiště',
            'code': '4F641',
            'svgId': '4F641',
            'id': 'NHQ-4NP-4F641',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F642': {
            'name': 'Výtah',
            'code': '4F642',
            'svgId': '4F642',
            'id': 'NHQ-4NP-4F642',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F643': {
            'name': 'Kuchyňka',
            'code': '4F643',
            'svgId': '4F643',
            'id': 'NHQ-4NP-4F643',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F644': {
            'name': 'Chodba',
            'code': '4F644',
            'svgId': '4F644',
            'id': 'NHQ-4NP-4F644',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F645': {
            'name': 'Jednací místnost',
            'code': '4F645',
            'svgId': '4F645',
            'id': 'NHQ-4NP-4F645',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F646': {
            'name': 'Šatna',
            'code': '4F646',
            'svgId': '4F646',
            'id': 'NHQ-4NP-4F646',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F649': {
            'name': 'Sprcha',
            'code': '4F649',
            'svgId': '4F649',
            'id': 'NHQ-4NP-4F649',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F650': {
            'name': 'Odpočivná zóna',
            'code': '4F650',
            'svgId': '4F650',
            'id': 'NHQ-4NP-4F650',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F654': {
            'name': 'Šatna',
            'code': '4F654',
            'svgId': '4F654',
            'id': 'NHQ-4NP-4F654',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F655': {
            'name': 'Kancelář',
            'code': '4F655',
            'svgId': '4F655',
            'id': 'NHQ-4NP-4F655',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F656': {
            'name': 'Jednací místnost',
            'code': '4F656',
            'svgId': '4F656',
            'id': 'NHQ-4NP-4F656',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F657': {
            'name': 'Kancelář',
            'code': '4F657',
            'svgId': '4F657',
            'id': 'NHQ-4NP-4F657',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F658': {
            'name': 'Flexibilní prostor',
            'code': '4F658',
            'svgId': '4F658',
            'id': 'NHQ-4NP-4F658',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F681': {
            'name': 'Chodba',
            'code': '4F681',
            'svgId': '4F681',
            'id': 'NHQ-4NP-4F681',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F682': {
            'name': 'Chodba',
            'code': '4F682',
            'svgId': '4F682',
            'id': 'NHQ-4NP-4F682',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F683': {
            'name': 'Balkón',
            'code': '4F683',
            'svgId': '4F683',
            'id': 'NHQ-4NP-4F683',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F685': {
            'name': 'Kancelář',
            'code': '4F685',
            'svgId': '4F685',
            'id': 'NHQ-4NP-4F685',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F685A': {
            'name': 'Kancelář',
            'code': '4F685A',
            'svgId': '4F685A',
            'id': 'NHQ-4NP-4F685A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F686': {
            'name': 'Kancelář',
            'code': '4F686',
            'svgId': '4F686',
            'id': 'NHQ-4NP-4F686',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F686A': {
            'name': 'Kancelář',
            'code': '4F686A',
            'svgId': '4F686A',
            'id': 'NHQ-4NP-4F686A',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-4F687': {
            'name': 'Kancelář',
            'code': '4F687',
            'svgId': '4F687',
            'id': 'NHQ-4NP-4F687',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-A3_PCZNHQ2007': {
            'name': 'Tiskárna A3 / 4A',
            'code': 'A3_PCZNHQ2007',
            'svgId': 'A3_PCZNHQ2007',
            'id': 'NHQ-4NP-A3_PCZNHQ2007',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-A4_PCZNHQ2048': {
            'name': 'Tiskárna A4 / 4C',
            'code': 'A4_PCZNHQ2048',
            'svgId': 'A4_PCZNHQ2048',
            'id': 'NHQ-4NP-A4_PCZNHQ2048',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-A3_PCZNHQ2008': {
            'name': 'Tiskárna A3 / 4C',
            'code': 'A3_PCZNHQ2008',
            'svgId': 'A3_PCZNHQ2008',
            'id': 'NHQ-4NP-A3_PCZNHQ2008',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-A4_PCZNHQ2011': {
            'name': 'Tiskárna A4 / 4E',
            'code': 'A4_PCZNHQ2011',
            'svgId': 'A4_PCZNHQ2011',
            'id': 'NHQ-4NP-A4_PCZNHQ2011',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-A4_PCZNHQ2031': {
            'name': 'Tiskárna A4 / 4F',
            'code': 'A4_PCZNHQ2031',
            'svgId': 'A4_PCZNHQ2031',
            'id': 'NHQ-4NP-A4_PCZNHQ2031',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-A4_PCZNHQ2036': {
            'name': 'Tiskárna A4 / 4F',
            'code': 'A4_PCZNHQ2036',
            'svgId': 'A4_PCZNHQ2036',
            'id': 'NHQ-4NP-A4_PCZNHQ2036',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-A4_PCZNHQ2039': {
            'name': 'Tiskárna A4 / 4D',
            'code': 'A4_PCZNHQ2039',
            'svgId': 'A4_PCZNHQ2039',
            'id': 'NHQ-4NP-A4_PCZNHQ2039',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-A4_PCZNHQ2044': {
            'name': 'Tiskárna A4 / 4D',
            'code': 'A4_PCZNHQ2044',
            'svgId': 'A4_PCZNHQ2044',
            'id': 'NHQ-4NP-A4_PCZNHQ2044',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-A4_PCZNHQ2018': {
            'name': 'Tiskárna A4 / 4D',
            'code': 'A4_PCZNHQ2018',
            'svgId': 'A4_PCZNHQ2018',
            'id': 'NHQ-4NP-A4_PCZNHQ2018',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          },
          'NHQ-4NP-A4_PCZNHQ2019': {
            'name': 'Tiskárna A4 / 4D',
            'code': 'A4_PCZNHQ2019',
            'svgId': 'A4_PCZNHQ2019',
            'id': 'NHQ-4NP-A4_PCZNHQ2019',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-4NP',
            'type': ''
          }
        }
      },
      'NHQ-5NP': {
        'name': '5. patro',
        'code': '5NP',
        'svgId': '5NP',
        'buildingId': 'NHQ',
        'id': 'NHQ-5NP',
        'rooms': {
          'NHQ-5NP-5A101': {
            'name': 'Schodiště',
            'code': '5A101',
            'svgId': '5A101',
            'id': 'NHQ-5NP-5A101',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A102': {
            'name': 'Výtah',
            'code': '5A102',
            'svgId': '5A102',
            'id': 'NHQ-5NP-5A102',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A103': {
            'name': 'Chodba',
            'code': '5A103',
            'svgId': '5A103',
            'id': 'NHQ-5NP-5A103',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A104': {
            'name': 'Kuchyňka',
            'code': '5A104',
            'svgId': '5A104',
            'id': 'NHQ-5NP-5A104',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A106': {
            'name': 'VIP Restaurace Vyšehrad',
            'code': '5A106',
            'svgId': '5A106',
            'id': 'NHQ-5NP-5A106',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A107': {
            'name': 'Zádveří',
            'code': '5A107',
            'svgId': '5A107',
            'id': 'NHQ-5NP-5A107',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A141': {
            'name': 'WC muži',
            'code': '5A141',
            'svgId': '5A141',
            'id': 'NHQ-5NP-5A141',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A142': {
            'name': 'WC muži',
            'code': '5A142',
            'svgId': '5A142',
            'id': 'NHQ-5NP-5A142',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A143': {
            'name': 'WC muži imobil',
            'code': '5A143',
            'svgId': '5A143',
            'id': 'NHQ-5NP-5A143',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A144': {
            'name': 'WC muži',
            'code': '5A144',
            'svgId': '5A144',
            'id': 'NHQ-5NP-5A144',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A146': {
            'name': 'WC ženy',
            'code': '5A146',
            'svgId': '5A146',
            'id': 'NHQ-5NP-5A146',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A147': {
            'name': 'WC ženy',
            'code': '5A147',
            'svgId': '5A147',
            'id': 'NHQ-5NP-5A147',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A148': {
            'name': 'WC ženy',
            'code': '5A148',
            'svgId': '5A148',
            'id': 'NHQ-5NP-5A148',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A150': {
            'name': 'Kuchyňka',
            'code': '5A150',
            'svgId': '5A150',
            'id': 'NHQ-5NP-5A150',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A154': {
            'name': 'Chodba',
            'code': '5A154',
            'svgId': '5A154',
            'id': 'NHQ-5NP-5A154',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A155': {
            'name': 'Projektová místnost',
            'code': '5A155',
            'svgId': '5A155',
            'id': 'NHQ-5NP-5A155',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A181': {
            'name': 'Chodba',
            'code': '5A181',
            'svgId': '5A181',
            'id': 'NHQ-5NP-5A181',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A182': {
            'name': 'Kancelář',
            'code': '5A182',
            'svgId': '5A182',
            'id': 'NHQ-5NP-5A182',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5A199': {
            'name': 'Terasa',
            'code': '5A199',
            'svgId': '5A199',
            'id': 'NHQ-5NP-5A199',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B201': {
            'name': 'WC muži',
            'code': '5B201',
            'svgId': '5B201',
            'id': 'NHQ-5NP-5B201',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B202': {
            'name': 'WC muži',
            'code': '5B202',
            'svgId': '5B202',
            'id': 'NHQ-5NP-5B202',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B203': {
            'name': 'WC muži',
            'code': '5B203',
            'svgId': '5B203',
            'id': 'NHQ-5NP-5B203',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B205': {
            'name': 'WC ženy',
            'code': '5B205',
            'svgId': '5B205',
            'id': 'NHQ-5NP-5B205',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B206': {
            'name': 'WC ženy imobil',
            'code': '5B206',
            'svgId': '5B206',
            'id': 'NHQ-5NP-5B206',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B207': {
            'name': 'WC ženy',
            'code': '5B207',
            'svgId': '5B207',
            'id': 'NHQ-5NP-5B207',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B208': {
            'name': 'WC ženy',
            'code': '5B208',
            'svgId': '5B208',
            'id': 'NHQ-5NP-5B208',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B210': {
            'name': 'Kuchyňka',
            'code': '5B210',
            'svgId': '5B210',
            'id': 'NHQ-5NP-5B210',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B214': {
            'name': 'Chodba',
            'code': '5B214',
            'svgId': '5B214',
            'id': 'NHQ-5NP-5B214',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B215': {
            'name': 'Předsíň výtahu',
            'code': '5B215',
            'svgId': '5B215',
            'id': 'NHQ-5NP-5B215',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B216': {
            'name': 'Výtah evakuační',
            'code': '5B216',
            'svgId': '5B216',
            'id': 'NHQ-5NP-5B216',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B241': {
            'name': 'Schodiště',
            'code': '5B241',
            'svgId': '5B241',
            'id': 'NHQ-5NP-5B241',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B242': {
            'name': 'Výtah',
            'code': '5B242',
            'svgId': '5B242',
            'id': 'NHQ-5NP-5B242',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B243': {
            'name': 'Chodba',
            'code': '5B243',
            'svgId': '5B243',
            'id': 'NHQ-5NP-5B243',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B246': {
            'name': 'Jednací místnost - Hanoj',
            'code': '5B246',
            'svgId': '5B246',
            'id': 'NHQ-5NP-5B246',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B247': {
            'name': 'Zádveří',
            'code': '5B247',
            'svgId': '5B247',
            'id': 'NHQ-5NP-5B247',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B281': {
            'name': 'Chodba',
            'code': '5B281',
            'svgId': '5B281',
            'id': 'NHQ-5NP-5B281',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5B299': {
            'name': 'Terasa',
            'code': '5B299',
            'svgId': '5B299',
            'id': 'NHQ-5NP-5B299',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C301': {
            'name': 'Schodiště',
            'code': '5C301',
            'svgId': '5C301',
            'id': 'NHQ-5NP-5C301',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C302': {
            'name': 'Výtah',
            'code': '5C302',
            'svgId': '5C302',
            'id': 'NHQ-5NP-5C302',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C303': {
            'name': 'Chodba',
            'code': '5C303',
            'svgId': '5C303',
            'id': 'NHQ-5NP-5C303',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C306': {
            'name': 'Jednací místnost - Havana',
            'code': '5C306',
            'svgId': '5C306',
            'id': 'NHQ-5NP-5C306',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C307': {
            'name': 'Zádveří',
            'code': '5C307',
            'svgId': '5C307',
            'id': 'NHQ-5NP-5C307',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C341': {
            'name': 'WC muži',
            'code': '5C341',
            'svgId': '5C341',
            'id': 'NHQ-5NP-5C341',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C342': {
            'name': 'WC muži',
            'code': '5C342',
            'svgId': '5C342',
            'id': 'NHQ-5NP-5C342',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C343': {
            'name': 'WC muži imobil',
            'code': '5C343',
            'svgId': '5C343',
            'id': 'NHQ-5NP-5C343',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C344': {
            'name': 'WC muži',
            'code': '5C344',
            'svgId': '5C344',
            'id': 'NHQ-5NP-5C344',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C346': {
            'name': 'WC ženy',
            'code': '5C346',
            'svgId': '5C346',
            'id': 'NHQ-5NP-5C346',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C347': {
            'name': 'WC ženy',
            'code': '5C347',
            'svgId': '5C347',
            'id': 'NHQ-5NP-5C347',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C348': {
            'name': 'WC ženy',
            'code': '5C348',
            'svgId': '5C348',
            'id': 'NHQ-5NP-5C348',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C350': {
            'name': 'Kuchyňka',
            'code': '5C350',
            'svgId': '5C350',
            'id': 'NHQ-5NP-5C350',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C354': {
            'name': 'Chodba',
            'code': '5C354',
            'svgId': '5C354',
            'id': 'NHQ-5NP-5C354',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C355': {
            'name': 'Jednací místnost - Harare',
            'code': '5C355',
            'svgId': '5C355',
            'id': 'NHQ-5NP-5C355',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C381': {
            'name': 'Chodba',
            'code': '5C381',
            'svgId': '5C381',
            'id': 'NHQ-5NP-5C381',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C382': {
            'name': 'Kancelář',
            'code': '5C382',
            'svgId': '5C382',
            'id': 'NHQ-5NP-5C382',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5C399': {
            'name': 'Terasa',
            'code': '5C399',
            'svgId': '5C399',
            'id': 'NHQ-5NP-5C399',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D401': {
            'name': 'WC muži',
            'code': '5D401',
            'svgId': '5D401',
            'id': 'NHQ-5NP-5D401',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D402': {
            'name': 'WC muži',
            'code': '5D402',
            'svgId': '5D402',
            'id': 'NHQ-5NP-5D402',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D403': {
            'name': 'WC muži',
            'code': '5D403',
            'svgId': '5D403',
            'id': 'NHQ-5NP-5D403',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D405': {
            'name': 'WC ženy',
            'code': '5D405',
            'svgId': '5D405',
            'id': 'NHQ-5NP-5D405',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D406': {
            'name': 'WC ženy imobil',
            'code': '5D406',
            'svgId': '5D406',
            'id': 'NHQ-5NP-5D406',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D407': {
            'name': 'WC ženy',
            'code': '5D407',
            'svgId': '5D407',
            'id': 'NHQ-5NP-5D407',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D408': {
            'name': 'WC ženy',
            'code': '5D408',
            'svgId': '5D408',
            'id': 'NHQ-5NP-5D408',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D410': {
            'name': 'Kuchyňka',
            'code': '5D410',
            'svgId': '5D410',
            'id': 'NHQ-5NP-5D410',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D414': {
            'name': 'Chodba',
            'code': '5D414',
            'svgId': '5D414',
            'id': 'NHQ-5NP-5D414',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D415': {
            'name': 'Kancelář',
            'code': '5D415',
            'svgId': '5D415',
            'id': 'NHQ-5NP-5D415',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D441': {
            'name': 'Schodiště',
            'code': '5D441',
            'svgId': '5D441',
            'id': 'NHQ-5NP-5D441',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D442': {
            'name': 'Výtah',
            'code': '5D442',
            'svgId': '5D442',
            'id': 'NHQ-5NP-5D442',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D443': {
            'name': 'Chodba',
            'code': '5D443',
            'svgId': '5D443',
            'id': 'NHQ-5NP-5D443',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D446': {
            'name': 'Jednací místnost - Helsinky',
            'code': '5D446',
            'svgId': '5D446',
            'id': 'NHQ-5NP-5D446',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D447': {
            'name': 'Zádveří',
            'code': '5D447',
            'svgId': '5D447',
            'id': 'NHQ-5NP-5D447',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D481': {
            'name': 'Chodba',
            'code': '5D481',
            'svgId': '5D481',
            'id': 'NHQ-5NP-5D481',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D482': {
            'name': 'Kancelář',
            'code': '5D482',
            'svgId': '5D482',
            'id': 'NHQ-5NP-5D482',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D498': {
            'name': 'Venkovní terasa - Lima',
            'code': '5D498',
            'svgId': '5D498',
            'id': 'NHQ-5NP-5D498',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5D499': {
            'name': 'Terasa',
            'code': '5D499',
            'svgId': '5D499',
            'id': 'NHQ-5NP-5D499',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E501': {
            'name': 'Schodiště',
            'code': '5E501',
            'svgId': '5E501',
            'id': 'NHQ-5NP-5E501',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E502': {
            'name': 'Výtah',
            'code': '5E502',
            'svgId': '5E502',
            'id': 'NHQ-5NP-5E502',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E503': {
            'name': 'Chodba',
            'code': '5E503',
            'svgId': '5E503',
            'id': 'NHQ-5NP-5E503',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E506': {
            'name': 'Jednací místnost - Honiara',
            'code': '5E506',
            'svgId': '5E506',
            'id': 'NHQ-5NP-5E506',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E507': {
            'name': 'Zádveří',
            'code': '5E507',
            'svgId': '5E507',
            'id': 'NHQ-5NP-5E507',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E541': {
            'name': 'WC muži',
            'code': '5E541',
            'svgId': '5E541',
            'id': 'NHQ-5NP-5E541',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E542': {
            'name': 'WC muži',
            'code': '5E542',
            'svgId': '5E542',
            'id': 'NHQ-5NP-5E542',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E543': {
            'name': 'WC muži imobil',
            'code': '5E543',
            'svgId': '5E543',
            'id': 'NHQ-5NP-5E543',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E544': {
            'name': 'WC muži',
            'code': '5E544',
            'svgId': '5E544',
            'id': 'NHQ-5NP-5E544',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E546': {
            'name': 'WC ženy',
            'code': '5E546',
            'svgId': '5E546',
            'id': 'NHQ-5NP-5E546',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E547': {
            'name': 'WC ženy',
            'code': '5E547',
            'svgId': '5E547',
            'id': 'NHQ-5NP-5E547',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E548': {
            'name': 'WC ženy',
            'code': '5E548',
            'svgId': '5E548',
            'id': 'NHQ-5NP-5E548',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E550': {
            'name': 'Kuchyňka',
            'code': '5E550',
            'svgId': '5E550',
            'id': 'NHQ-5NP-5E550',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E554': {
            'name': 'Chodba',
            'code': '5E554',
            'svgId': '5E554',
            'id': 'NHQ-5NP-5E554',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E555': {
            'name': 'Předsíň výtahu',
            'code': '5E555',
            'svgId': '5E555',
            'id': 'NHQ-5NP-5E555',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E556': {
            'name': 'Výtah evakuační',
            'code': '5E556',
            'svgId': '5E556',
            'id': 'NHQ-5NP-5E556',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E581': {
            'name': 'Chodba',
            'code': '5E581',
            'svgId': '5E581',
            'id': 'NHQ-5NP-5E581',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E598': {
            'name': 'Venkovní terasa Dallas BBQ',
            'code': '5E598',
            'svgId': '5E598',
            'id': 'NHQ-5NP-5E598',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5E599': {
            'name': 'Terasa',
            'code': '5E599',
            'svgId': '5E599',
            'id': 'NHQ-5NP-5E599',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F601': {
            'name': 'WC muži',
            'code': '5F601',
            'svgId': '5F601',
            'id': 'NHQ-5NP-5F601',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F602': {
            'name': 'WC muži',
            'code': '5F602',
            'svgId': '5F602',
            'id': 'NHQ-5NP-5F602',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F603': {
            'name': 'WC muži',
            'code': '5F603',
            'svgId': '5F603',
            'id': 'NHQ-5NP-5F603',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F605': {
            'name': 'WC ženy',
            'code': '5F605',
            'svgId': '5F605',
            'id': 'NHQ-5NP-5F605',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F606': {
            'name': 'WC ženy imobil',
            'code': '5F606',
            'svgId': '5F606',
            'id': 'NHQ-5NP-5F606',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F607': {
            'name': 'WC ženy',
            'code': '5F607',
            'svgId': '5F607',
            'id': 'NHQ-5NP-5F607',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F608': {
            'name': 'WC ženy',
            'code': '5F608',
            'svgId': '5F608',
            'id': 'NHQ-5NP-5F608',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F610': {
            'name': 'Kuchyňka',
            'code': '5F610',
            'svgId': '5F610',
            'id': 'NHQ-5NP-5F610',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F614': {
            'name': 'Chodba',
            'code': '5F614',
            'svgId': '5F614',
            'id': 'NHQ-5NP-5F614',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F615': {
            'name': 'Jednací místnost - Honolulu',
            'code': '5F615',
            'svgId': '5F615',
            'id': 'NHQ-5NP-5F615',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F641': {
            'name': 'Schodiště',
            'code': '5F641',
            'svgId': '5F641',
            'id': 'NHQ-5NP-5F641',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F642': {
            'name': 'Výtah',
            'code': '5F642',
            'svgId': '5F642',
            'id': 'NHQ-5NP-5F642',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F643': {
            'name': 'Chodba',
            'code': '5F643',
            'svgId': '5F643',
            'id': 'NHQ-5NP-5F643',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F646': {
            'name': 'Odpočivná zóna Havaj',
            'code': '5F646',
            'svgId': '5F646',
            'id': 'NHQ-5NP-5F646',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F647': {
            'name': 'Zádveří',
            'code': '5F647',
            'svgId': '5F647',
            'id': 'NHQ-5NP-5F647',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F681': {
            'name': 'Chodba',
            'code': '5F681',
            'svgId': '5F681',
            'id': 'NHQ-5NP-5F681',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F682': {
            'name': 'Kancelář',
            'code': '5F682',
            'svgId': '5F682',
            'id': 'NHQ-5NP-5F682',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F698': {
            'name': 'Venkovní terasa Buenos Aires',
            'code': '5F698',
            'svgId': '5F698',
            'id': 'NHQ-5NP-5F698',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-5F699': {
            'name': 'Terasa',
            'code': '5F699',
            'svgId': '5F699',
            'id': 'NHQ-5NP-5F699',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-A4_PCZNHQ2049': {
            'name': 'Tiskárna A4 / 5F',
            'code': 'A4_PCZNHQ2049',
            'svgId': 'A4_PCZNHQ2049',
            'id': 'NHQ-5NP-A4_PCZNHQ2049',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-A4_PCZNHQ2025': {
            'name': 'Tiskárna A4 / 5C',
            'code': 'A4_PCZNHQ2025',
            'svgId': 'A4_PCZNHQ2025',
            'id': 'NHQ-5NP-A4_PCZNHQ2025',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-A4_PCZNHQ2021': {
            'name': 'Tiskárna A4 / 5D',
            'code': 'A4_PCZNHQ2021',
            'svgId': 'A4_PCZNHQ2021',
            'id': 'NHQ-5NP-A4_PCZNHQ2021',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          },
          'NHQ-5NP-A4_PCZNHQ2050': {
            'name': 'Tiskárna A4 / 5A',
            'code': 'A4_PCZNHQ2050',
            'svgId': 'A4_PCZNHQ2050',
            'id': 'NHQ-5NP-A4_PCZNHQ2050',
            'buildingId': 'NHQ',
            'floorId': 'NHQ-5NP',
            'type': ''
          }
        }
      }
    }
  },
  'HHQ': {
    'name': 'HHQ',
    'id': 'HHQ',
    'code': 'HHQ',
    'svgId': 'HHQ0',
    'floors': {
      'HHQ-1NP': {
        'name': '1. patro',
        'code': '1NP',
        'svgId': '1NP',
        'buildingId': 'HHQ',
        'id': 'HHQ-1NP',
        'rooms': {
          'HHQ-1NP-1C01': {
            'name': 'Knihovna',
            'code': '1C01',
            'svgId': '1C01',
            'id': 'HHQ-1NP-1C01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C02': {
            'name': 'Jednací místnost',
            'code': '1C02',
            'svgId': '1C02',
            'id': 'HHQ-1NP-1C02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C03': {
            'name': 'Jednací místnost',
            'code': '1C03',
            'svgId': '1C03',
            'id': 'HHQ-1NP-1C03',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C04': {
            'name': 'Jednací místnost',
            'code': '1C04',
            'svgId': '1C04',
            'id': 'HHQ-1NP-1C04',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C05': {
            'name': 'Jednací místnost',
            'code': '1C05',
            'svgId': '1C05',
            'id': 'HHQ-1NP-1C05',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C06': {
            'name': 'Jednací místnost',
            'code': '1C06',
            'svgId': '1C06',
            'id': 'HHQ-1NP-1C06',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C07': {
            'name': 'Jednací místnost',
            'code': '1C07',
            'svgId': '1C07',
            'id': 'HHQ-1NP-1C07',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C08': {
            'name': 'Jednací místnost',
            'code': '1C08',
            'svgId': '1C08',
            'id': 'HHQ-1NP-1C08',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C09': {
            'name': 'Jednací místnost',
            'code': '1C09',
            'svgId': '1C09',
            'id': 'HHQ-1NP-1C09',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C10': {
            'name': 'Jednací místnost',
            'code': '1C10',
            'svgId': '1C10',
            'id': 'HHQ-1NP-1C10',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C11': {
            'name': 'Jednací místnost',
            'code': '1C11',
            'svgId': '1C11',
            'id': 'HHQ-1NP-1C11',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C12': {
            'name': 'Jednací místnost',
            'code': '1C12',
            'svgId': '1C12',
            'id': 'HHQ-1NP-1C12',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '210'
          },
          'HHQ-1NP-1C99': {
            'name': 'Vstupní hala, recepce',
            'code': '1C99',
            'svgId': '1C99',
            'id': 'HHQ-1NP-1C99',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '40'
          },
          'HHQ-1NP-1JA1': {
            'name': 'DC - herna',
            'code': '1JA1',
            'svgId': '1JA1',
            'id': 'HHQ-1NP-1JA1',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '221'
          },
          'HHQ-1NP-1JA3': {
            'name': 'DC - kuchyňka',
            'code': '1JA3',
            'svgId': '1JA3',
            'id': 'HHQ-1NP-1JA3',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '220'
          },
          'HHQ-1NP-1J01': {
            'name': 'Výtah',
            'code': '1J01',
            'svgId': '1J01',
            'id': 'HHQ-1NP-1J01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '90'
          },
          'HHQ-1NP-1J02': {
            'name': 'Výtah',
            'code': '1J02',
            'svgId': '1J02',
            'id': 'HHQ-1NP-1J02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '90'
          },
          'HHQ-1NP-1J45': {
            'name': 'Kavárna',
            'code': '1J45',
            'svgId': '1J45',
            'id': 'HHQ-1NP-1J45',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '161'
          },
          'HHQ-1NP-1J72': {
            'name': 'Pobočka',
            'code': '1J72',
            'svgId': '1J72',
            'id': 'HHQ-1NP-1J72',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '130'
          },
          'HHQ-1NP-1S01': {
            'name': 'Výtah',
            'code': '1S01',
            'svgId': '1S01',
            'id': 'HHQ-1NP-1S01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '90'
          },
          'HHQ-1NP-1S02': {
            'name': 'Výtah',
            'code': '1S02',
            'svgId': '1S02',
            'id': 'HHQ-1NP-1S02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '90'
          },
          'HHQ-1NP-1S37': {
            'name': 'Výtah',
            'code': '1S37',
            'svgId': '1S37',
            'id': 'HHQ-1NP-1S37',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '90'
          },
          'HHQ-1NP-1S38': {
            'name': 'Výtah',
            'code': '1S38',
            'svgId': '1S38',
            'id': 'HHQ-1NP-1S38',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '90'
          },
          'HHQ-1NP-1S52': {
            'name': 'Jídelna',
            'code': '1S52',
            'svgId': '1S52',
            'id': 'HHQ-1NP-1S52',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '161'
          },
          'HHQ-1NP-1S53': {
            'name': 'Jídelna',
            'code': '1S53',
            'svgId': '1S53',
            'id': 'HHQ-1NP-1S53',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '161'
          },
          'HHQ-1NP-1S90': {
            'name': 'Podatelna',
            'code': '1S90',
            'svgId': '1S90',
            'id': 'HHQ-1NP-1S90',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': '260'
          },
          'HHQ-1NP-A3_PCZHHQ2001': {
            'name': 'Tiskárna A3 / 1S',
            'code': 'A3_PCZHHQ2001',
            'svgId': 'A3_PCZHHQ2001',
            'id': 'HHQ-1NP-A3_PCZHHQ2001',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-1NP',
            'type': ''
          }
        }
      },
      'HHQ-2NP': {
        'name': '2. patro',
        'code': '2NP',
        'svgId': '2NP',
        'buildingId': 'HHQ',
        'id': 'HHQ-2NP',
        'rooms': {
          'HHQ-2NP-2C01': {
            'name': 'Tréninková místnost',
            'code': '2C01',
            'svgId': '2C01',
            'id': 'HHQ-2NP-2C01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '300'
          },
          'HHQ-2NP-2C03': {
            'name': 'Koučink',
            'code': '2C03',
            'svgId': '2C03',
            'id': 'HHQ-2NP-2C03',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2C04': {
            'name': 'Koučink',
            'code': '2C04',
            'svgId': '2C04',
            'id': 'HHQ-2NP-2C04',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2C05': {
            'name': 'Koučink',
            'code': '2C05',
            'svgId': '2C05',
            'id': 'HHQ-2NP-2C05',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2C06': {
            'name': 'Tréninková místnost',
            'code': '2C06',
            'svgId': '2C06',
            'id': 'HHQ-2NP-2C06',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '300'
          },
          'HHQ-2NP-2C08': {
            'name': 'Tréninková místnost',
            'code': '2C08',
            'svgId': '2C08',
            'id': 'HHQ-2NP-2C08',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '300'
          },
          'HHQ-2NP-2C10': {
            'name': 'Koučink',
            'code': '2C10',
            'svgId': '2C10',
            'id': 'HHQ-2NP-2C10',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2J01': {
            'name': 'Výtah',
            'code': '2J01',
            'svgId': '2J01',
            'id': 'HHQ-2NP-2J01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '90'
          },
          'HHQ-2NP-2J02': {
            'name': 'Výtah',
            'code': '2J02',
            'svgId': '2J02',
            'id': 'HHQ-2NP-2J02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '90'
          },
          'HHQ-2NP-2J48': {
            'name': 'Kuchyňka',
            'code': '2J48',
            'svgId': '2J48',
            'id': 'HHQ-2NP-2J48',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '220'
          },
          'HHQ-2NP-2J55': {
            'name': 'Jednací místnost',
            'code': '2J55',
            'svgId': '2J55',
            'id': 'HHQ-2NP-2J55',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2J58': {
            'name': 'Jednací místnost',
            'code': '2J58',
            'svgId': '2J58',
            'id': 'HHQ-2NP-2J58',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2J61': {
            'name': 'Kuchyňka',
            'code': '2J61',
            'svgId': '2J61',
            'id': 'HHQ-2NP-2J61',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '220'
          },
          'HHQ-2NP-2J66': {
            'name': 'Jednací místnost',
            'code': '2J66',
            'svgId': '2J66',
            'id': 'HHQ-2NP-2J66',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2J71': {
            'name': 'Jednací místnost',
            'code': '2J71',
            'svgId': '2J71',
            'id': 'HHQ-2NP-2J71',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2J75': {
            'name': 'Kuchyňka',
            'code': '2J75',
            'svgId': '2J75',
            'id': 'HHQ-2NP-2J75',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '220'
          },
          'HHQ-2NP-2J80': {
            'name': 'Jednací místnost',
            'code': '2J80',
            'svgId': '2J80',
            'id': 'HHQ-2NP-2J80',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2J81': {
            'name': 'Jednací místnost',
            'code': '2J81',
            'svgId': '2J81',
            'id': 'HHQ-2NP-2J81',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2J82': {
            'name': 'Jednací místnost',
            'code': '2J82',
            'svgId': '2J82',
            'id': 'HHQ-2NP-2J82',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2J83': {
            'name': 'Kancelář',
            'code': '2J83',
            'svgId': '2J83',
            'id': 'HHQ-2NP-2J83',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '170'
          },
          'HHQ-2NP-2J94': {
            'name': 'Koučink',
            'code': '2J94',
            'svgId': '2J94',
            'id': 'HHQ-2NP-2J94',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2J98': {
            'name': 'Open space',
            'code': '2J98',
            'svgId': '2J98',
            'id': 'HHQ-2NP-2J98',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '170'
          },
          'HHQ-2NP-2S01': {
            'name': 'Výtah',
            'code': '2S01',
            'svgId': '2S01',
            'id': 'HHQ-2NP-2S01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '90'
          },
          'HHQ-2NP-2S02': {
            'name': 'Výtah',
            'code': '2S02',
            'svgId': '2S02',
            'id': 'HHQ-2NP-2S02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '90'
          },
          'HHQ-2NP-2S48': {
            'name': 'Kuchyňka',
            'code': '2S48',
            'svgId': '2S48',
            'id': 'HHQ-2NP-2S48',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '220'
          },
          'HHQ-2NP-2S55': {
            'name': 'Jednací místnost',
            'code': '2S55',
            'svgId': '2S55',
            'id': 'HHQ-2NP-2S55',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2S58': {
            'name': 'Jednací místnost',
            'code': '2S58',
            'svgId': '2S58',
            'id': 'HHQ-2NP-2S58',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2S61': {
            'name': 'Kuchyňka',
            'code': '2S61',
            'svgId': '2S61',
            'id': 'HHQ-2NP-2S61',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '220'
          },
          'HHQ-2NP-2S66': {
            'name': 'Jednací místnost',
            'code': '2S66',
            'svgId': '2S66',
            'id': 'HHQ-2NP-2S66',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2S71': {
            'name': 'Jednací místnost',
            'code': '2S71',
            'svgId': '2S71',
            'id': 'HHQ-2NP-2S71',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2S75': {
            'name': 'Kuchyňka',
            'code': '2S75',
            'svgId': '2S75',
            'id': 'HHQ-2NP-2S75',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '220'
          },
          'HHQ-2NP-2S80': {
            'name': 'Jednací místnost',
            'code': '2S80',
            'svgId': '2S80',
            'id': 'HHQ-2NP-2S80',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2S81': {
            'name': 'Jednací místnost',
            'code': '2S81',
            'svgId': '2S81',
            'id': 'HHQ-2NP-2S81',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2S82': {
            'name': 'Jednací místnost',
            'code': '2S82',
            'svgId': '2S82',
            'id': 'HHQ-2NP-2S82',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2S83': {
            'name': 'Jednací místnost',
            'code': '2S83',
            'svgId': '2S83',
            'id': 'HHQ-2NP-2S83',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2S94': {
            'name': 'Koučink',
            'code': '2S94',
            'svgId': '2S94',
            'id': 'HHQ-2NP-2S94',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '210'
          },
          'HHQ-2NP-2S98': {
            'name': 'Open space',
            'code': '2S98',
            'svgId': '2S98',
            'id': 'HHQ-2NP-2S98',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '170'
          },
          'HHQ-2NP-2S99': {
            'name': 'Open space',
            'code': '2S99',
            'svgId': '2S99',
            'id': 'HHQ-2NP-2S99',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': '170'
          },
          'HHQ-2NP-A4_PCZHHQ2017': {
            'name': 'Tiskárna A4 / 2S',
            'code': 'A4_PCZHHQ2017',
            'svgId': 'A4_PCZHHQ2017',
            'id': 'HHQ-2NP-A4_PCZHHQ2017',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': ''
          },
          'HHQ-2NP-A4_PCZHHQ2018': {
            'name': 'Tiskárna A4 / 2J',
            'code': 'A4_PCZHHQ2018',
            'svgId': 'A4_PCZHHQ2018',
            'id': 'HHQ-2NP-A4_PCZHHQ2018',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': ''
          },
          'HHQ-2NP-A4_PCZHHQ2019': {
            'name': 'Tiskárna A4 / 2S',
            'code': 'A4_PCZHHQ2019',
            'svgId': 'A4_PCZHHQ2019',
            'id': 'HHQ-2NP-A4_PCZHHQ2019',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-2NP',
            'type': ''
          }
        }
      },
      'HHQ-3NP': {
        'name': '3. patro',
        'code': '3NP',
        'svgId': '3NP',
        'buildingId': 'HHQ',
        'id': 'HHQ-3NP',
        'rooms': {
          'HHQ-3NP-3C01': {
            'name': 'Jednací místnost',
            'code': '3C01',
            'svgId': '3C01',
            'id': 'HHQ-3NP-3C01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3C02': {
            'name': 'Jednací místnost',
            'code': '3C02',
            'svgId': '3C02',
            'id': 'HHQ-3NP-3C02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3C03': {
            'name': 'Jednací místnost',
            'code': '3C03',
            'svgId': '3C03',
            'id': 'HHQ-3NP-3C03',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3C05': {
            'name': 'Jednací místnost',
            'code': '3C05',
            'svgId': '3C05',
            'id': 'HHQ-3NP-3C05',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3C06': {
            'name': 'Jednací místnost',
            'code': '3C06',
            'svgId': '3C06',
            'id': 'HHQ-3NP-3C06',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3C07': {
            'name': 'Jednací místnost',
            'code': '3C07',
            'svgId': '3C07',
            'id': 'HHQ-3NP-3C07',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3C08': {
            'name': 'Jednací místnost',
            'code': '3C08',
            'svgId': '3C08',
            'id': 'HHQ-3NP-3C08',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3C10': {
            'name': 'Jednací místnost',
            'code': '3C10',
            'svgId': '3C10',
            'id': 'HHQ-3NP-3C10',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3J01': {
            'name': 'Výtah',
            'code': '3J01',
            'svgId': '3J01',
            'id': 'HHQ-3NP-3J01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '90'
          },
          'HHQ-3NP-3J02': {
            'name': 'Výtah',
            'code': '3J02',
            'svgId': '3J02',
            'id': 'HHQ-3NP-3J02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '90'
          },
          'HHQ-3NP-3J48': {
            'name': 'Kuchyňka',
            'code': '3J48',
            'svgId': '3J48',
            'id': 'HHQ-3NP-3J48',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '220'
          },
          'HHQ-3NP-3J55': {
            'name': 'Jednací místnost',
            'code': '3J55',
            'svgId': '3J55',
            'id': 'HHQ-3NP-3J55',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3J56': {
            'name': 'Jednací místnost',
            'code': '3J56',
            'svgId': '3J56',
            'id': 'HHQ-3NP-3J56',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3J61': {
            'name': 'Kuchyňka',
            'code': '3J61',
            'svgId': '3J61',
            'id': 'HHQ-3NP-3J61',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '220'
          },
          'HHQ-3NP-3J70': {
            'name': 'Jednací místnost',
            'code': '3J70',
            'svgId': '3J70',
            'id': 'HHQ-3NP-3J70',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3J75': {
            'name': 'Kuchyňka',
            'code': '3J75',
            'svgId': '3J75',
            'id': 'HHQ-3NP-3J75',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '220'
          },
          'HHQ-3NP-3J81': {
            'name': 'Kancelář',
            'code': '3J81',
            'svgId': '3J81',
            'id': 'HHQ-3NP-3J81',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '170'
          },
          'HHQ-3NP-3J83': {
            'name': 'Jednací místnost',
            'code': '3J83',
            'svgId': '3J83',
            'id': 'HHQ-3NP-3J83',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3J88': {
            'name': 'Jednací místnost',
            'code': '3J88',
            'svgId': '3J88',
            'id': 'HHQ-3NP-3J88',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3J91': {
            'name': 'Jednací místnost',
            'code': '3J91',
            'svgId': '3J91',
            'id': 'HHQ-3NP-3J91',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3J93': {
            'name': 'Jednací místnost',
            'code': '3J93',
            'svgId': '3J93',
            'id': 'HHQ-3NP-3J93',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3J97': {
            'name': 'Open space',
            'code': '3J97',
            'svgId': '3J97',
            'id': 'HHQ-3NP-3J97',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '170'
          },
          'HHQ-3NP-3J98': {
            'name': 'Open space',
            'code': '3J98',
            'svgId': '3J98',
            'id': 'HHQ-3NP-3J98',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '170'
          },
          'HHQ-3NP-3J99': {
            'name': 'Open space',
            'code': '3J99',
            'svgId': '3J99',
            'id': 'HHQ-3NP-3J99',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '170'
          },
          'HHQ-3NP-3S01': {
            'name': 'Výtah',
            'code': '3S01',
            'svgId': '3S01',
            'id': 'HHQ-3NP-3S01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '90'
          },
          'HHQ-3NP-3S02': {
            'name': 'Výtah',
            'code': '3S02',
            'svgId': '3S02',
            'id': 'HHQ-3NP-3S02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '90'
          },
          'HHQ-3NP-3S48': {
            'name': 'Kuchyňka',
            'code': '3S48',
            'svgId': '3S48',
            'id': 'HHQ-3NP-3S48',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '220'
          },
          'HHQ-3NP-3S55': {
            'name': 'Jednací místnost',
            'code': '3S55',
            'svgId': '3S55',
            'id': 'HHQ-3NP-3S55',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3S58': {
            'name': 'Jednací místnost',
            'code': '3S58',
            'svgId': '3S58',
            'id': 'HHQ-3NP-3S58',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3S61': {
            'name': 'Kuchyňka',
            'code': '3S61',
            'svgId': '3S61',
            'id': 'HHQ-3NP-3S61',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '220'
          },
          'HHQ-3NP-3S68': {
            'name': 'Jednací místnost',
            'code': '3S68',
            'svgId': '3S68',
            'id': 'HHQ-3NP-3S68',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3S75': {
            'name': 'Kuchyňka',
            'code': '3S75',
            'svgId': '3S75',
            'id': 'HHQ-3NP-3S75',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '220'
          },
          'HHQ-3NP-3S81': {
            'name': 'Jednací místnost',
            'code': '3S81',
            'svgId': '3S81',
            'id': 'HHQ-3NP-3S81',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3S83': {
            'name': 'Jednací místnost',
            'code': '3S83',
            'svgId': '3S83',
            'id': 'HHQ-3NP-3S83',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3S88': {
            'name': 'Jednací místnost',
            'code': '3S88',
            'svgId': '3S88',
            'id': 'HHQ-3NP-3S88',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3S91': {
            'name': 'Jednací místnost',
            'code': '3S91',
            'svgId': '3S91',
            'id': 'HHQ-3NP-3S91',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '210'
          },
          'HHQ-3NP-3S97': {
            'name': 'Open space',
            'code': '3S97',
            'svgId': '3S97',
            'id': 'HHQ-3NP-3S97',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '170'
          },
          'HHQ-3NP-3S98': {
            'name': 'Open space',
            'code': '3S98',
            'svgId': '3S98',
            'id': 'HHQ-3NP-3S98',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '170'
          },
          'HHQ-3NP-3S99': {
            'name': 'Open space',
            'code': '3S99',
            'svgId': '3S99',
            'id': 'HHQ-3NP-3S99',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': '170'
          },
          'HHQ-3NP-A3_PCZHHQ2002': {
            'name': 'Tiskárna A3 / 3J',
            'code': 'A3_PCZHHQ2002',
            'svgId': 'A3_PCZHHQ2002',
            'id': 'HHQ-3NP-A3_PCZHHQ2002',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': ''
          },
          'HHQ-3NP-A4_PCZHHQ2007': {
            'name': 'Tiskárna A4 / 3J',
            'code': 'A4_PCZHHQ2007',
            'svgId': 'A4_PCZHHQ2007',
            'id': 'HHQ-3NP-A4_PCZHHQ2007',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': ''
          },
          'HHQ-3NP-A4_PCZHHQ2013': {
            'name': 'Tiskárna A4 / 3S',
            'code': 'A4_PCZHHQ2013',
            'svgId': 'A4_PCZHHQ2013',
            'id': 'HHQ-3NP-A4_PCZHHQ2013',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': ''
          },
          'HHQ-3NP-A4_PCZHHQ2014': {
            'name': 'Tiskárna A4 / 3J',
            'code': 'A4_PCZHHQ2014',
            'svgId': 'A4_PCZHHQ2014',
            'id': 'HHQ-3NP-A4_PCZHHQ2014',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': ''
          },
          'HHQ-3NP-A3_PCZHHQ2005': {
            'name': 'Tiskárna A3 / 3S',
            'code': 'A3_PCZHHQ2005',
            'svgId': 'A3_PCZHHQ2005',
            'id': 'HHQ-3NP-A3_PCZHHQ2005',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': ''
          },
          'HHQ-3NP-A4_PCZHHQ2020': {
            'name': 'Tiskárna A4 / 3S',
            'code': 'A4_PCZHHQ2020',
            'svgId': 'A4_PCZHHQ2020',
            'id': 'HHQ-3NP-A4_PCZHHQ2020',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': ''
          },
          'HHQ-3NP-A3_PCZHHQ2003': {
            'name': 'Tiskárna A3 / 3J',
            'code': 'A3_PCZHHQ2003',
            'svgId': 'A3_PCZHHQ2003',
            'id': 'HHQ-3NP-A3_PCZHHQ2003',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': ''
          },
          'HHQ-3NP-A4_PCZHHQ2006': {
            'name': 'Tiskárna A4 / 3S',
            'code': 'A4_PCZHHQ2006',
            'svgId': 'A4_PCZHHQ2006',
            'id': 'HHQ-3NP-A4_PCZHHQ2006',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': ''
          },
          'HHQ-3NP-A4_PCZHHQ2008': {
            'name': 'Tiskárna A4 / 3J',
            'code': 'A4_PCZHHQ2008',
            'svgId': 'A4_PCZHHQ2008',
            'id': 'HHQ-3NP-A4_PCZHHQ2008',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': ''
          },
          'HHQ-3NP-A4_PCZHHQ2012': {
            'name': 'Tiskárna A4 / 3S',
            'code': 'A4_PCZHHQ2012',
            'svgId': 'A4_PCZHHQ2012',
            'id': 'HHQ-3NP-A4_PCZHHQ2012',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-3NP',
            'type': ''
          }
        }
      },
      'HHQ-4NP': {
        'name': '4. patro',
        'code': '4NP',
        'svgId': '4NP',
        'buildingId': 'HHQ',
        'id': 'HHQ-4NP',
        'rooms': {
          'HHQ-4NP-4C01': {
            'name': 'Jednací místnost',
            'code': '4C01',
            'svgId': '4C01',
            'id': 'HHQ-4NP-4C01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4C02': {
            'name': 'Jednací místnost',
            'code': '4C02',
            'svgId': '4C02',
            'id': 'HHQ-4NP-4C02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4C03': {
            'name': 'Jednací místnost',
            'code': '4C03',
            'svgId': '4C03',
            'id': 'HHQ-4NP-4C03',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4C05': {
            'name': 'Jednací místnost',
            'code': '4C05',
            'svgId': '4C05',
            'id': 'HHQ-4NP-4C05',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4C06': {
            'name': 'Jednací místnost',
            'code': '4C06',
            'svgId': '4C06',
            'id': 'HHQ-4NP-4C06',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4C08': {
            'name': 'Jednací místnost',
            'code': '4C08',
            'svgId': '4C08',
            'id': 'HHQ-4NP-4C08',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4C10': {
            'name': 'Jednací místnost',
            'code': '4C10',
            'svgId': '4C10',
            'id': 'HHQ-4NP-4C10',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4J01': {
            'name': 'Výtah',
            'code': '4J01',
            'svgId': '4J01',
            'id': 'HHQ-4NP-4J01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '90'
          },
          'HHQ-4NP-4J02': {
            'name': 'Výtah',
            'code': '4J02',
            'svgId': '4J02',
            'id': 'HHQ-4NP-4J02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '90'
          },
          'HHQ-4NP-4J48': {
            'name': 'Kuchyňka',
            'code': '4J48',
            'svgId': '4J48',
            'id': 'HHQ-4NP-4J48',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '220'
          },
          'HHQ-4NP-4J55': {
            'name': 'Jednací místnost',
            'code': '4J55',
            'svgId': '4J55',
            'id': 'HHQ-4NP-4J55',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4J61': {
            'name': 'Kuchyňka',
            'code': '4J61',
            'svgId': '4J61',
            'id': 'HHQ-4NP-4J61',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '220'
          },
          'HHQ-4NP-4J65': {
            'name': 'Jednací místnost',
            'code': '4J65',
            'svgId': '4J65',
            'id': 'HHQ-4NP-4J65',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4J70': {
            'name': 'Jednací místnost',
            'code': '4J70',
            'svgId': '4J70',
            'id': 'HHQ-4NP-4J70',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4J75': {
            'name': 'Kuchyňka',
            'code': '4J75',
            'svgId': '4J75',
            'id': 'HHQ-4NP-4J75',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '220'
          },
          'HHQ-4NP-4J81': {
            'name': 'Jednací místnost neformální',
            'code': '4J81',
            'svgId': '4J81',
            'id': 'HHQ-4NP-4J81',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4J83': {
            'name': 'Jednací místnost',
            'code': '4J83',
            'svgId': '4J83',
            'id': 'HHQ-4NP-4J83',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4J88': {
            'name': 'Jednací místnost',
            'code': '4J88',
            'svgId': '4J88',
            'id': 'HHQ-4NP-4J88',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4J91': {
            'name': 'Jednací místnost',
            'code': '4J91',
            'svgId': '4J91',
            'id': 'HHQ-4NP-4J91',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4J93': {
            'name': 'Jednací místnost',
            'code': '4J93',
            'svgId': '4J93',
            'id': 'HHQ-4NP-4J93',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4J97': {
            'name': 'Open space',
            'code': '4J97',
            'svgId': '4J97',
            'id': 'HHQ-4NP-4J97',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '170'
          },
          'HHQ-4NP-4S01': {
            'name': 'Výtah',
            'code': '4S01',
            'svgId': '4S01',
            'id': 'HHQ-4NP-4S01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '90'
          },
          'HHQ-4NP-4S02': {
            'name': 'Výtah',
            'code': '4S02',
            'svgId': '4S02',
            'id': 'HHQ-4NP-4S02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '90'
          },
          'HHQ-4NP-4S45': {
            'name': 'Jednací místnost',
            'code': '4S45',
            'svgId': '4S45',
            'id': 'HHQ-4NP-4S45',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4S46': {
            'name': 'Jednací místnost',
            'code': '4S46',
            'svgId': '4S46',
            'id': 'HHQ-4NP-4S46',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4S47': {
            'name': 'Jednací místnost',
            'code': '4S47',
            'svgId': '4S47',
            'id': 'HHQ-4NP-4S47',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4S48': {
            'name': 'Kuchyňka',
            'code': '4S48',
            'svgId': '4S48',
            'id': 'HHQ-4NP-4S48',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '220'
          },
          'HHQ-4NP-4S51': {
            'name': 'Kancelář',
            'code': '4S51',
            'svgId': '4S51',
            'id': 'HHQ-4NP-4S51',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '170'
          },
          'HHQ-4NP-4S52': {
            'name': 'Jednací místnost',
            'code': '4S52',
            'svgId': '4S52',
            'id': 'HHQ-4NP-4S52',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4S61': {
            'name': 'Kuchyňka',
            'code': '4S61',
            'svgId': '4S61',
            'id': 'HHQ-4NP-4S61',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '220'
          },
          'HHQ-4NP-4S65': {
            'name': 'Jednací místnost',
            'code': '4S65',
            'svgId': '4S65',
            'id': 'HHQ-4NP-4S65',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4S70': {
            'name': 'Jednací místnost',
            'code': '4S70',
            'svgId': '4S70',
            'id': 'HHQ-4NP-4S70',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4S75': {
            'name': 'Kuchyňka',
            'code': '4S75',
            'svgId': '4S75',
            'id': 'HHQ-4NP-4S75',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '220'
          },
          'HHQ-4NP-4S81': {
            'name': 'Kancelář',
            'code': '4S81',
            'svgId': '4S81',
            'id': 'HHQ-4NP-4S81',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '170'
          },
          'HHQ-4NP-4S83': {
            'name': 'Jednací místnost',
            'code': '4S83',
            'svgId': '4S83',
            'id': 'HHQ-4NP-4S83',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4S88': {
            'name': 'Jednací místnost',
            'code': '4S88',
            'svgId': '4S88',
            'id': 'HHQ-4NP-4S88',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4S91': {
            'name': 'Kancelář',
            'code': '4S91',
            'svgId': '4S91',
            'id': 'HHQ-4NP-4S91',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '170'
          },
          'HHQ-4NP-4S93': {
            'name': 'Jednací místnost',
            'code': '4S93',
            'svgId': '4S93',
            'id': 'HHQ-4NP-4S93',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '210'
          },
          'HHQ-4NP-4S97': {
            'name': 'Open space',
            'code': '4S97',
            'svgId': '4S97',
            'id': 'HHQ-4NP-4S97',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '170'
          },
          'HHQ-4NP-4S98': {
            'name': 'Open space',
            'code': '4S98',
            'svgId': '4S98',
            'id': 'HHQ-4NP-4S98',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': '170'
          },
          'HHQ-4NP-A3_PCZHHQ2004': {
            'name': 'Tiskárna A3 / 4S',
            'code': 'A3_PCZHHQ2004',
            'svgId': 'A3_PCZHHQ2004',
            'id': 'HHQ-4NP-A3_PCZHHQ2004',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': ''
          },
          'HHQ-4NP-A4_PCZHHQ2010': {
            'name': 'Tiskárna A4 / 4J',
            'code': 'A4_PCZHHQ2010',
            'svgId': 'A4_PCZHHQ2010',
            'id': 'HHQ-4NP-A4_PCZHHQ2010',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': ''
          },
          'HHQ-4NP-A4_PCZHHQ2015': {
            'name': 'Tiskárna A4 / 4S',
            'code': 'A4_PCZHHQ2015',
            'svgId': 'A4_PCZHHQ2015',
            'id': 'HHQ-4NP-A4_PCZHHQ2015',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': ''
          },
          'HHQ-4NP-A4_PCZHHQ2016': {
            'name': 'Tiskárna A4 / 4S',
            'code': 'A4_PCZHHQ2016',
            'svgId': 'A4_PCZHHQ2016',
            'id': 'HHQ-4NP-A4_PCZHHQ2016',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': ''
          },
          'HHQ-4NP-A4_PCZHHQ2009': {
            'name': 'Tiskárna A4 / 4S',
            'code': 'A4_PCZHHQ2009',
            'svgId': 'A4_PCZHHQ2009',
            'id': 'HHQ-4NP-A4_PCZHHQ2009',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': ''
          },
          'HHQ-4NP-A4_PCZHHQ2011': {
            'name': 'Tiskárna A4 / 4S',
            'code': 'A4_PCZHHQ2011',
            'svgId': 'A4_PCZHHQ2011',
            'id': 'HHQ-4NP-A4_PCZHHQ2011',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-4NP',
            'type': ''
          }
        }
      },
      'HHQ-5NP': {
        'name': '5. patro',
        'code': '5NP',
        'svgId': '5NP',
        'buildingId': 'HHQ',
        'id': 'HHQ-5NP',
        'rooms': {
          'HHQ-5NP-5C01': {
            'name': 'Jednací místnost',
            'code': '5C01',
            'svgId': '5C01',
            'id': 'HHQ-5NP-5C01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '210'
          },
          'HHQ-5NP-5C02': {
            'name': 'Jednací místnost',
            'code': '5C02',
            'svgId': '5C02',
            'id': 'HHQ-5NP-5C02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '210'
          },
          'HHQ-5NP-5C03': {
            'name': 'Jednací místnost',
            'code': '5C03',
            'svgId': '5C03',
            'id': 'HHQ-5NP-5C03',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '210'
          },
          'HHQ-5NP-5C04': {
            'name': 'Jednací místnost',
            'code': '5C04',
            'svgId': '5C04',
            'id': 'HHQ-5NP-5C04',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '210'
          },
          'HHQ-5NP-5C98': {
            'name': 'Terasa - Intenzivní vegetační střecha',
            'code': '5C98',
            'svgId': '5C98',
            'id': 'HHQ-5NP-5C98',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '181'
          },
          'HHQ-5NP-5J01': {
            'name': 'Výtah',
            'code': '5J01',
            'svgId': '5J01',
            'id': 'HHQ-5NP-5J01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '90'
          },
          'HHQ-5NP-5J02': {
            'name': 'Výtah',
            'code': '5J02',
            'svgId': '5J02',
            'id': 'HHQ-5NP-5J02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '90'
          },
          'HHQ-5NP-5J46': {
            'name': 'Týmová pracovna',
            'code': '5J46',
            'svgId': '5J46',
            'id': 'HHQ-5NP-5J46',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '223'
          },
          'HHQ-5NP-5J98': {
            'name': 'Terasa',
            'code': '5J98',
            'svgId': '5J98',
            'id': 'HHQ-5NP-5J98',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '181'
          },
          'HHQ-5NP-5J99': {
            'name': 'Terasa',
            'code': '5J99',
            'svgId': '5J99',
            'id': 'HHQ-5NP-5J99',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '181'
          },
          'HHQ-5NP-5S01': {
            'name': 'Výtah',
            'code': '5S01',
            'svgId': '5S01',
            'id': 'HHQ-5NP-5S01',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '90'
          },
          'HHQ-5NP-5S02': {
            'name': 'Výtah',
            'code': '5S02',
            'svgId': '5S02',
            'id': 'HHQ-5NP-5S02',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '90'
          },
          'HHQ-5NP-5S98': {
            'name': 'Terasa',
            'code': '5S98',
            'svgId': '5S98',
            'id': 'HHQ-5NP-5S98',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '181'
          },
          'HHQ-5NP-5S99': {
            'name': 'Terasa',
            'code': '5S99',
            'svgId': '5S99',
            'id': 'HHQ-5NP-5S99',
            'buildingId': 'HHQ',
            'floorId': 'HHQ-5NP',
            'type': '181'
          }
        }
      }
    }
  }
});
