export const strings = Object.freeze({
    cz: {
        globalInputButtonClear: 'Smazat obsah vstupního pole',
        inputNumberIncrement: 'Zvýšit',
        inputNumberDecrement: 'Snížit',
        passwordMaskToggleShow: 'Ukázat heslo',
        passwordMaskToggleHide: 'Skrýt heslo',
        filterGroupToggleVisibilityExpand: 'Rozbalit filtr',
        filterGroupToggleVisibilityCollapse: 'Sbalit filtr',
        sectionToggleVisibilityExpand: 'Rozbalit sekci',
        sectionToggleVisibilityCollapse: 'Sbalit sekci',
        buttonFilter: 'Filtrovat',
        buttonClearFilter: 'Vyčistit filtr',
        buttonDelete: 'Smazat',
        buttonClose: 'Zavřít',
        buttonDropdown: 'Další možnosti',
        buttonToggleCalendarOpen: 'Otevřít kalendář',
        buttonToggleCalendarClose: 'Zavřít kalendář',
        formStepCurrent: 'Současný krok:',
        formStepPassed: 'Dokončený krok:',
        formStepFuture: 'Budoucí krok:',
        itemsPerPage: 'Počet položek',
        sortingAsc: 'Vzestupně',
        sortingDesc: 'Sestupně',
        paginationNext: 'Další strana',
        paginationPrev: 'Předchozí strana',
        advancedComboSearchEmptyResult: 'Hledání neodpovídají žádné výsledky',
        advancedComboSearchPlaceholder: 'Hledat',
        minimumNumberOfCharsIs: "Minimalni pocet znaku jsou 3",
    },
    en: {
        globalInputButtonClear: 'Clear input',
        passwordMaskToggleShow: 'Show password',
        passwordMaskToggleHide: 'Hide password',
        filterGroupToggleVisibilityExpand: 'Expand filter',
        filterGroupToggleVisibilityCollapse: 'Collapse filter',
        sectionToggleVisibilityExpand: 'Expand section',
        sectionToggleVisibilityCollapse: 'Collapse section',
        buttonFilter: 'Filter',
        buttonClearFilter: 'Clear filter',
        buttonDelete: 'Delete',
        buttonClose: 'Close',
        buttonDropdown: 'More options',
        buttonToggleCalendarOpen: 'Open calendar',
        buttonToggleCalendarClose: 'Close calendar',
        formStepCurrent: 'Current step:',
        formStepPassed: 'Finished step:',
        formStepFuture: 'Unfinished step:',
        itemsPerPage: 'Items per page',
        sortingAsc: 'Ascending',
        sortingDesc: 'Descending',
        paginationNext: 'Next page',
        paginationPrev: 'Previous page',
        advancedComboSearchEmptyResult: 'No results found',
        advancedComboSearchPlaceholder: 'Search',
        minimumNumberOfCharsIs: "Minimum number of chars is 3",
    }
});
