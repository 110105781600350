<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-form-steps" :class="classObject">
        <ul class="vue-steps-list">
            <frm1021-form-step
                v-for="(step, index) in steps"
                :key="index"
                :step="step"
                :stepCurrent="stepCurrent"
                :index="index"
                :mode="mode"
                :captionCurrent="stepCaptionCurrentComputed"
                :captionPassed="stepCaptionPassedComputed"
                :captionFuture="stepCaptionFutureComputed"
                @stepActivateEvent="stepActivate"
            />
        </ul>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Frm1021FormStep from './frm1021-form-step';

//=== MIXINS
import Component from '../../mixins/component';
import Localization from '../../mixins/localization';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1021';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1021FormSteps',
    components: { Frm1021FormStep },
    mixins: [Component, Localization],
    props: {
        mode: {
            default: 'link',
            validator: value => {
                return ['button', 'link'].includes(value);
            }
        },
        steps: null,
        stepCurrent: {
            default: 1,
            type: Number
        },
        stepCaptionCurrent: String,
        stepCaptionPassed: String,
        stepCaptionFuture: String,
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    computed: {
        classObject() {
            return ['vue-is-' + this.mode];
        },
        stepCaptionCurrentComputed() {
            if (!this.stepCaptionCurrent) {
                return this.i18n('formStepCurrent');
            }

            return this.stepCaptionCurrent;
        },
        stepCaptionPassedComputed() {
            if (!this.stepCaptionPassed) {
                return this.i18n('formStepPassed');
            }

            return this.stepCaptionPassed;
        },
        stepCaptionFutureComputed() {
            if (!this.stepCaptionFuture) {
                return this.i18n('formStepFuture');
            }

            return this.stepCaptionFuture;
        },
        stepsCount() {
            return this.steps.length;
        },
        stepNext() {
            if (this.stepCurrent < this.stepsCount) {
                return this.stepCurrent + 1;
            }
            return null;
        },
        stepNextIndex() {
            if (this.stepNext) {
                return this.stepNext - 1;
            }
            return -1;
        },
        stepNextObj() {
            if (this.stepNext) {
                return this.steps[this.stepNextIndex];
            }
            return null;
        },
        stepPrev() {
            if (this.stepCurrent > 1) {
                return this.stepCurrent - 1;
            }
            return null;
        },
        stepPrevIndex() {
            if (this.stepPrev) {
                return this.stepPrev - 1;
            }
            return -1;
        },
        stepPrevObj() {
            if (this.stepPrev) {
                return this.steps[this.stepPrevIndex];
            }
            return null;
        }
    },
    mounted() {
        this.$emit('componentInitializedEvent', true);
    },
    methods: {
        stepActivate(stepNumber) {
            this.$emit('update:stepCurrent', stepNumber);
        },
        setStepNext() {
            if (this.stepNext && !this.stepNextObj.disabled) {
                this.$emit('update:stepCurrent', this.stepNext);
                this.$emit('nextStepEvent', this.stepNext, this.stepNextObj);
            }
        },
        setStepPrev() {
            if (this.stepPrev && !this.stepPrevObj.disabled) {
                this.$emit('update:stepCurrent', this.stepPrev);
                this.$emit('nextStepEvent', this.stepPrev, this.stepPrevObj);
            }
        }
    }
};
</script>
