<template>
  <div class="vue-c-workout-list">
    <prj1018-workout-list-week
      v-if="sortedWorkouts.length > 0"
      :rows="days"
      :columns="hours"
      :caption="$t('workouts.freeWorkouts')"
    >
      <div
        v-for="(item, workoutIndex) in sortedWorkouts"
        :key="'workout' + workoutIndex"
        class="vue-schedule-item"
        :style="item.styleObj"
        @click="goToWorkoutDetail(item.id)"
      >
        <span
          class="vue-name"
        >
          {{ item.name }}
        </span>
      </div>
    </prj1018-workout-list-week>

    <gen1006-info-panel
      v-else
      type="neutral"
    >
      {{ $t('workouts.noWorkouts') }}
    </gen1006-info-panel>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Prj1018WorkoutListWeek from './prj1018-workout-list-week';

import router from '@/router';

export default {
  name: 'Prj1018WorkoutList',
  components: {
    Prj1018WorkoutListWeek
  },

  data() {
    return {
      days: [
        this.$i18n.t('time.days.0'),
        this.$i18n.t('time.days.1'),
        this.$i18n.t('time.days.2'),
        this.$i18n.t('time.days.3'),
        this.$i18n.t('time.days.4')
      ],
      hours: ['07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00']
    }
  },

  computed: {
    ...mapState('workouts', [
      'workouts',
      'workoutsOptions',
      'workoutsAreLoaded',
      'selectedBuilding',
      'selectedQuartal',
    ]),

    convertedSelectedQuartal() {
      return this.selectedQuartal.replace('Q', '');
    },

    sortedWorkouts() {
      const groupByHour = (array, start, end) => array.reduce((result, currentValue) => {
        if (!result.length || (new Date(currentValue[start])) - (new Date(result[result.length - 1][0][start])) >= 1000 * 60 * 60 || ((new Date(currentValue[start])) > (new Date(result[result.length - 1][0][end])))) result.push([currentValue]);
        else result[result.length - 1].push(currentValue);
        return result;
      }, []);

      let workouts = [];
      let tmpWorkouts = this.workouts[this.convertedSelectedQuartal]

      if(tmpWorkouts) {
        Object.entries(tmpWorkouts).forEach( entry => {
          let childKey = entry[0];
          let childObjects = entry[1];
          if(this.selectedBuilding.toLowerCase() === childKey.toLowerCase()) {
            Object.entries(childObjects).forEach( subEntry => {
              let subChildKey = subEntry[0];
              let subChildObjects = subEntry[1];

              const subChildObjectsGroup = groupByHour(subChildObjects, 'activityFrom', 'activityTo')

              subChildObjectsGroup.forEach(workoutGroup => {
                workoutGroup.forEach((workoutItem, index, group) => {
                  let tmpStartMin = new Date(Date.parse(workoutItem.activityFrom.replace(/\s+/g, 'T')));
                  let tmpEndMin = new Date(Date.parse(workoutItem.activityTo.replace(/\s+/g, 'T')));

                  let tmpStartMinString = tmpStartMin.getHours() + ':' + (tmpStartMin.getMinutes() < 10 ? '0'+tmpStartMin.getMinutes() : tmpStartMin.getMinutes());
                  let tmpEndMinString = tmpEndMin.getHours() + ':' + (tmpEndMin.getMinutes() < 10 ? '0'+tmpEndMin.getMinutes() : tmpEndMin.getMinutes());
                  let startMin = tmpStartMinString.split(':')[0] * 90 + tmpStartMinString.split(':')[1] * 1.5;
                  let endMin = tmpEndMinString.split(':')[0] * 90 + tmpEndMinString.split(':')[1] * 1.5;
                  let difMin = (endMin - startMin);

                  let tmpStyleHeight = parseFloat(80 / workoutGroup.length);
                  let tmpStyleWidth = parseFloat(difMin);
                  let tmpStyleLeftBorderWidth = parseFloat((startMin - this.hours[0].split(':')[0] * 90) / 90) - 1;
                  let tmpStyleTop = parseFloat((subChildKey - 1) * 80 + 38) + tmpStyleHeight * index;
                  let tmpStyleLeft = parseFloat(((startMin - (this.hours[0].split(':')[0] * 90 + this.hours[0].split(':')[1] * 1.5)) + 30) - tmpStyleLeftBorderWidth);

                  workoutItem.styleObj = {
                    width: tmpStyleWidth + 'px',
                    top: tmpStyleTop + 'px',
                    left: tmpStyleLeft + 'px',
                    backgroundColor: workoutItem.trainerColor,
                    fontSize: tmpStyleWidth < 90 || group.length > 1 ? '8px' : '13px',
                    padding: tmpStyleWidth < 90 || group.length > 1 ? '0' : '7px',
                    height: tmpStyleHeight + 'px'
                  };
                  workoutItem.dayNum = subChildKey;
                  workouts.push(workoutItem);
                })
              })
            });
          }
        });
      }

      return workouts;
    },
  },

  methods: {
    goToWorkoutDetail(workoutId) {
      router.push({ name: 'workout-detail', params: { id: workoutId } });
    },
  }
}
</script>
