<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout classNames="bg-fest-green">
        <template v-slot:nav>
          <prj1005-navigation-bar
            route-name="festival-news"
            nav-button-variant="inverse"
          />
        </template>
        <template v-slot:content>
          <div class="prj-layout-default h-full">
            <!--========== PAGE BODY ==============================-->
            <!--===================================================-->
            <div
              v-if="page"
              class="prj-article prj-article-inverse prj-promo-page"
            >
              <t-tag
                variant="sectionTitle"
                class="text-white xs:mb-6 md:mb-8 xs:mt-6 md:mb-10"
              >
                {{ page.title.rendered }}
              </t-tag>

              <div
                v-html="page.content.rendered"
              />

              <!--========== BACKGROUND =============================-->
              <!--===================================================-->
              <div class="absolute bottom-0 md:-bottom-40 left-0 text-black opacity-60 mix-blend-overlay">
                <svg
                  class="h-full w-screen"
                  viewBox="0 0 375 173"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M375 163V0L293.5 53.2583L191 78.2789L65.3452 94.1309C64.1255 94.2847 62.9447 94.6622 61.8619 95.2442L0 128.499V173H365C370.523 173 375 168.523 375 163Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>

//============ IMPORT ==================================//
//======================================================//
import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import PageLayout from '@/templates/fest/page-layout';
import utilsGeneral from '@/utils/fest/utils-general';
import apiCmsContent from '@/fest-api/cms-content';
import logger from '@/utils/logger';
import Prj1005NavigationBar from '@/components/fest/prj1005-navigation-bar/prj1005-navigation-bar';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTTargets',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1005NavigationBar,
    PageLayout
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      page: null
    }
  },
  computed: {
    pageId() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getPageById(this.pageId)
  },
  methods: {
    getPageById(id) {
      let processId = utilsGeneral.startAsyncProcess();

      apiCmsContent
        .getPageById(id)
        .then(response => {
          this.page = response;
        })
        .catch(error => {
          logger.error(error);
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId);
        });
    }
  }
}
</script>
