export default {
  processSlugs(nameArray, state, type) {
    let slugArray = [];

    nameArray.forEach(item => {
      if (type === 'general') {
        slugArray.push(state.contentData[item].slug);
      }

      if (type === 'restaurants') {
        slugArray.push(state.contentData.restaurants.facilities[item].slug);
      }
    });

    return slugArray;
  },

  processCategories(categoriesData, categoriesApi, categoryId, data, state, type) {
    let categoryIdData = categoriesData[categoriesApi.indexOf(categoryId)];
    let categoryIdApi;

    if (type === 'general') {
      categoryIdApi = state.contentData[categoryIdData].slug;

      if (data[categoryIdApi].modifiedTimestamp !== state.contentData[categoryIdData].lastUpdate.timestamp) {
        return categoryIdApi
      } else {
        return null;
      }
    }

    if (type === 'restaurants') {
      categoryIdApi = state.contentData.restaurants.facilities[categoryIdData].slug;

      if (data[categoryIdApi].modifiedTimestamp !== state.contentData.restaurants.facilities[categoryIdData].lastUpdate.timestamp) {
        return categoryIdApi;
      } else {
        return null;
      }
    }
  },

  removeEmptyItems(array) {
    let filteredArray = array.filter(item => {
      return item != null;
    })

    return filteredArray;
  }
}
