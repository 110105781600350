export const ID_EXTENSIONS = Object.freeze({
    INPUT: '-input',
    SELECT: '-select',
    TEXTAREA: '-textarea',
    LABEL: '-label',
    CAPTION: '-caption',
    AMOUNT: '-amount',
    CURRENCY: '-currency',
    PREFIX: '-prefix',
    NUMBER: '-number',
    CONTENT: '-content',
    TOOLTIP_CONTENT: '-tooltip-content'
});
