<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :button-back-type="'none'"
        :building="false"
        @activeView="buttonBackEvent"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        id="vue-m-strategy-competition"
        ref="strategyContainer"
        :class="classObject"
        class="vue-strategy-page-layout"
      >
        <div class="vue-intro-content">
          <transition
            name="vue-anim-strategy-move-from-right"
            appear
          >
            <prj1022-competition-item
              v-if="renderComponent"
              :question-id="questionActive"
              :question="questionTitle"
              :question-caption="dynamicCaption"
              :question-image="questionImage"
              :answer-selected="answerSelected"
              :answers="questionAnswers"
              :competition-data="competitionData"
              :lang="getSelectedLang"
              class="vue-strategy-container"
              @quizAnswerSelected="selectedAnswer"
            />
          </transition>

          <frm1006-button
            type="primary"
            class="vue-is-next"
            @buttonClickEvent="nextQuestion"
          >
            {{ $t('strategy.next') }}
          </frm1006-button>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';
import * as STRATEGY_COMPETITION from '@/assets/mocks/strategyCompetitionData';
import { mapState } from 'vuex';
import router from '@/router';
import { STRATEGY_DEFAULT_LANG, STRATEGY_COMPETITION_ACTIVE } from '@/constants/app-constants';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1022CompetitionItem from '@/components/prj1022-competition-item/prj1022-competition-item';
import PageLayout from '@/templates/partials/page-layout';

export default {
  name: 'StrategyCompetition',
  components: {
    Prj1002Navbar,
    Prj1022CompetitionItem,
    PageLayout
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      questionActive: 1,
      answerSelected: false,
      competitionData: STRATEGY_COMPETITION.DATA_ARRAY.competition,
      renderComponent: true
    }
  },

  computed: {
    ...mapState('competition', [
      'competitions'
    ]),

    classObject() {
      return {
        'vue-is-selected': this.answerSelected
      };
    },

    quizData() {
      let lang = this.getSelectedLang;
      if( Object.prototype.hasOwnProperty.call(this.competitionData, lang) ) {
        return this.competitionData[lang]['items'];
      }
      return this.competitionData[STRATEGY_DEFAULT_LANG]['items'];
    },

    questionData() {
      if( Object.keys(this.quizData).length > 0 ) {
        return this.quizData[this.questionActive];
      }
      return false;
    },

    questionTitle() {
      return this.questionData.question;
    },

    questionImage() {
      return this.questionData.image;
    },

    questionAnswers() {
      return this.questionData.answers;
    },

    dynamicCaption() {
      return this.questionActive + '.';
    },

    getSelectedLang() {
      return this.$i18n.locale;
    }
  },

  mounted() {
    this.loadStateQuiz();
    this.sendStatistics('STRATEGY-QUIZ-FORM');
  },

  methods: {
    loadStateQuiz() {
      let tmpFinalCountQuiz = Object.keys(this.quizData).length;
      let tmpActuallyCountQuiz = 0;

      if(typeof this.competitions.data[STRATEGY_COMPETITION_ACTIVE] !== 'undefined') {
        tmpActuallyCountQuiz = Object.keys(this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results).length;
        if (tmpActuallyCountQuiz < tmpFinalCountQuiz) {
          //go to not answered question
          this.questionActive = (tmpActuallyCountQuiz + 1);
        }
      } else {
        this.questionActive = 1;
      }
    },

    buttonBackEvent() {
      router.push({ name: 'strategy-competition-intro'});
    },

    nextQuestion() {
      if(this.questionActive < Object.keys(this.quizData).length) {
        // next question
        this.questionActive++;
        this.answerSelected = false;
        this.forceRerender();
      } else {
        //go to summary
        router.push({ name: 'strategy-competition-summary'});
      }
    },

    selectedAnswer() {
      this.answerSelected = true;
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
  },
}
</script>
