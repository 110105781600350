<template>
  <page-layout class="vue-m-intro">
    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <frm1006-button
        v-if="paramAuthType !== 'none'"
        class="vue-navbar-button vue-is-back vue-content-back-button"
        :iconLeft="true"
        :captionHidden="true"
        type="app-button"
        @buttonClickEvent="navigateBack"
      >
        <template v-slot:iconLeft>
          <span class="vue-icon-inner" />
        </template>
      </frm1006-button>

      <img
        class="vue-zapka-logo"
        alt="Zapka Logo"
        src="../../../public/img/logo/intro-logo-with-branding-horizontal.svg"
      >

      <p
        class="vue-intro-message"
        v-html="$t('intro.message')"
      />

      <gen1006-info-panel
        v-if="authType === 'parent'"
        type="neutral"
      >
        {{ $t('intro.parentsMessage') }}
      </gen1006-info-panel>

      <div
        class="vue-login-form"
      >
        <frm1035-form-group>
          <frm1001-input-field
            v-if="authType !== '' && authType !== 'unknown'"
            id="email-input"
            ref="emailInput"
            v-model="email"
            name="email"
            :maskToggle="maskToggle"
            :tooltip="false"
            :label="$i18n.t('general.emailPlaceholder')"
            :disabled="isAuthorizing"
            @inputKeyDownEvent="loginFromInput"
          />
        </frm1035-form-group>
      </div>
    </template>
    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <!-- TYPES OF LOGIN -->
      <div
        v-if="authType === '' || authType === 'unknown'"
        class="vue-login-controls"
      >
        <Frm1006Button
          type="primary"
          @buttonClickEvent="setAuthType('login')"
        >
          {{ $t('intro.loginButton') }}
        </Frm1006Button>

        <Frm1006Button
          type="secondary"
          @buttonClickEvent="setAuthType('parent')"
        >
          <span v-html="$t('intro.parentButton')"></span>
        </Frm1006Button>
      </div>

      <!-- LOGIN BUTTON -->
      <template
        v-else
      >
        <Frm1006Button
          type="primary"
          :disabled="((authType === 'login' || authType === 'registration' || authType === 'parent') && email.length < 1)"
          @buttonClickEvent="loginProcess()"
        >
          {{ $t('general.continue') }}
        </Frm1006Button>

        <Frm1006Button
          v-if="!paramForward"
          type="secondary"
          @buttonClickEvent="backToAuthType()"
        >
          {{ $t('general.backToSelection') }}
        </Frm1006Button>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isAuthorizing"
        :fullScreen="true"
        :overlay="true"
      />
      <gen1009-overlay
        class="vue-intro-error-overlay vue-is-info-panel"
        :buttonClose="true"
        :active.sync="hasError"
      >
        <gen1006-info-panel
          v-if="formError.length > 0"
          :type="'error'"
        >
          {{ $t('intro.error.' + formError) }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-login-modal"
        :active="loginModal && authType === ''"
      >
        <h2 class="vue-login-headline">
          {{ $t('intro.titleModal') }}
        </h2>

        <div v-html="$t('intro.messageModal')"></div>

        <frm1006-button
          type="primary"
          @buttonClickEvent="closeLoginModal()"
        >
          {{ $t('forms.close') }}
        </frm1006-button>
      </gen1009-overlay>
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <div class="vue-footer-controls">
        <prj1020-separator />

        <Frm1006Button
          type="internal"
          :icon-left="true"
          class="vue-btn-settings"
          @buttonClickEvent="goToSettings()"
        >
          {{ $t('navigation.settings') }}
        </Frm1006Button>
      </div>
    </template>
  </page-layout>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import logger from '@/utils/logger';
import client from '@/utils/data-loader';
import { APP_ENTRY_PATH_STORAGE_KEY } from '@/constants/general';
import * as envConfigAuthorizedMode from 'ext-authorized-mode-env-config';
import PageLayout from '@/templates/partials/page-layout';
import Prj1020Separator from '@/components/prj1020-separator/prj1020-separator';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import { APP_DESTINATIONS, ERROR_CODES_LOGIN } from '@/constants/general';
import router from '@/router';
import * as MUTATION_CONSTANTS from '@/store/constants/mutations';
import utilsGeneral from '@/utils/utils-general';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import dataLoaderAuthorizedMode from '@/utils/data-loader/authorized-mode';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';

export default {
  name: 'Login',
  components: {
    PageLayout,
    Prj1020Separator
  },

  mixins: [
    mxDetectDesktop
  ],

  data() {
    return {
      email: '',
      maskToggle: false,
      isAuthorizing: false,
      hasError: false,
      formError: '',
      authType: '',   // host, login, parent, registration, unknown
      personalEmail: false,
      loginModal: true
    }
  },

  computed: {
    ...mapState('general', [
      'appDestination'
    ]),
    ...mapState('dashboard', [
      'selectedBuilding'
    ]),
    ...mapState('user', [
      'isStandalone',
      'platform',
      'browser',
      'deviceId',
      'refreshToken',
      'profile',
      'isLogged',
      'publicKey',
    ]),
    ...mapGetters('festUser', ['hasFestivalRegistered']),
    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY
    }),

    paramAutologin() {
      if(typeof this.$route.params?.autologin === 'undefined') {
        return true;
      }
      return this.$route.params.autologin;
    },

    paramAuthType() {
      if(typeof this.$route.params?.authType === 'undefined') {
        return 'none';
      }
      return this.$route.params.authType
    },

    paramAuthReferer() {
      if(typeof this.$route.params?.authReferer === 'undefined') {
        return '';
      }
      return this.$route.params.authReferer
    },

    paramIntroDescription() {
      if(typeof this.$route.params?.introDescription === 'undefined') {
        return '';
      }
      return this.$route.params.introDescription
    },

    paramForward() {
      if(typeof this.$route.params?.forward === 'undefined') {
        return false;
      }
      return this.$route.params.forward
    },

    paramAuthRefererAction() {
      if(typeof this.$route.params?.authRefererAction === 'undefined') {
        return '';
      }
      return this.$route.params.authRefererAction
    },

    paramAuthRefererParams() {
      if(typeof this.$route.params?.authRefererParams === 'undefined') {
        return {};
      }
      return this.$route.params.authRefererParams
    }
  },

  created() {
    this.generateDeviceId();

    if (this.paramAuthType !== 'none') {
      this.authType = this.paramAuthType;
    }

    if (this.isLogged === true && this.publicKey !== '' && this.authType === '' && this.hasIdentity) {
      this.$router.push({name: 'dashboard'});
    }
  },

  mounted() {
    if (envConfigAuthorizedMode.PUBLIC_KEY_HASH.length > 0) { // && this.paramAutologin
      this.isAuthorizing = true;
      try {
        this.autoLoginProcess();
      } catch (error) {
        logger.error('Autologin error')
        logger.error(error);
        const data = {
          publicKey: envConfigAuthorizedMode.PUBLIC_KEY_HASH,
          refreshToken: null,
          anonymous: true
        }
        this.loginSuccessProcess(data);
      }
    }

    if (this.hasIdentity) {
      this.$router.push({name: 'dashboard'});
    }
  },

  methods: {
    generateDeviceId() {
      if (!this.deviceId) {
        this.$store.commit(STORE_MODULES.USER + '/' + MUTATION_CONSTANTS.SET_DEVICE_ID, utilsGeneral.makeDeviceId(36));
      }
    },

    autoLoginProcess() {
      dataLoaderAuthorizedMode.logCustomInfo(this.deviceId, 'autoLoginProcess', 'debug1', 'login init');
      return dataLoaderAuthorizedMode.fetchUserPublicAuthorize(this.deviceId)
        .then(data => {
          this.loginSuccessProcess(data);
          this.$router.push({name: 'dashboard'});
        })
        .catch((error) => {
          logger.error(error);
          this.isAuthorizing = false;
        })
    },

    loginProcess() {
      if (!this.deviceId) {
        this.generateDeviceId();
      }

      // LOGIN | REGISTRATION
      if (['login', 'registration', 'parent'].includes(this.authType)) {
        this.loginProcessUser();
      }
    },

    loginProcessUser() {
      if (utilsGeneral.isValidEmail(this.email) && this.deviceId) {
        this.isAuthorizing = true;

        let formData = new FormData();
        formData.append('deviceId', this.deviceId);
        formData.append('email', this.email);
        formData.append('personalEmailFlag', this.personalEmail);

        this.loginProcessUserFetch(formData);
      } else {
        this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
        this.isAuthorizing = false;
        this.hasError = true;
        this.formError = 'wrongEmail';
      }
    },

    loginProcessHostFetch(formData) {
      client.fetchAuthorization(formData)
        .then(result => {
          this.loginSuccessProcess(result);

          const entryPath = localStorage.getItem(APP_ENTRY_PATH_STORAGE_KEY);
          if (entryPath) {
            localStorage.setItem(APP_ENTRY_PATH_STORAGE_KEY, '');
            this.$router.push(entryPath);
          } else {
            this.$router.push({name: 'dashboard'});
          }
        }, error => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
          this.isAuthorizing = false;
          this.hasError = true;

          if (error.response.data.hasOwnProperty('code') && ERROR_CODES_LOGIN.includes(error.response.data.code)) {
            this.formError = error.response.data.code;
          } else if (this.appDestination === APP_DESTINATIONS.PRIVATE && this.browser !== 'Chrome') {
            // desktop PC in CSOB has internet access limited to Chrome browser only, while default browser is Edge.
            // we customize the error message to advise user to switch to Chrome when CMS is not available
            this.formError = 'communicationErrorPrivate';
          } else {
            this.formError = 'communicationError';
          }

          logger.error('Error processing login', error);
        })
    },

    loginProcessUserFetch(formData) {
      client.fetchAuthorization(formData)
        .then(() => {
          this.isAuthorizing = false;

          this.$router.push({ name: 'verify-code', params: {
            email: this.email,
            personalEmail: this.personalEmail,
            authType: this.authType,
            authReferer: this.paramAuthReferer,
            authRefererAction: this.paramAuthRefererAction,
            authRefererParams: this.paramAuthRefererParams
          }});
        }, error => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
          this.isAuthorizing = false;
          this.hasError = true;

          if (error.response.data.hasOwnProperty('code') && ERROR_CODES_LOGIN.includes(error.response.data.code)) {
            this.formError = error.response.data.code;
          } else if (this.appDestination === APP_DESTINATIONS.PRIVATE && this.browser !== 'Chrome') {
            // desktop PC in CSOB has internet access limited to Chrome browser only, while default browser is Edge.
            // we customize the error message to advise user to switch to Chrome when CMS is not available
            this.formError = 'communicationErrorPrivate';
          } else {
            this.formError = 'communicationError';
          }

          logger.error('Error processing login', error);
        })
    },

    loginSuccessProcess(data) {
      dataLoaderAuthorizedMode.logCustomInfo(this.deviceId, 'loginSuccessProcess', 'debug1', JSON.stringify(data));
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PUBLIC_KEY, data.publicKey);
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_ANONYMOUS, data.anonymous);
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_REFRESH_TOKEN, data.refreshToken);
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_LAST_LOGGED, new Date().getTime());
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, true);

      let profileLoading = true;
      let settingsLoading = true;

      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.IDENTITY_PROFILE, {force: true})
        .then(() => {})
        .catch(error => {
          logger.error(error);
          dataLoaderAuthorizedMode.logCustomInfo(this.deviceId, 'loginSuccessProcess', 'catch1A', JSON.stringify(error.response.data));
        })
        .finally(() => {
          profileLoading = false;
          this.isAuthorizing = profileLoading || settingsLoading;
        });

      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.GET_APPLICATION_SETTINGS, {
        selectedBuilding: this.selectedBuilding
      })
        .then(() => {})
        .catch(error => {
          logger.error(error);
          dataLoaderAuthorizedMode.logCustomInfo(this.deviceId, 'loginSuccessProcess', 'catch1B', JSON.stringify(error.response.data));
        })
        .finally(() => {
          settingsLoading = false;
          this.isAuthorizing = profileLoading || settingsLoading;
        });
    },

    loginFromInput(value, event) {
      if (event.key === 'Enter') {
        this.loginProcess();
      }
    },

    navigateBack() {
      router.go(-1);
    },

    backToAuthType() {
      this.authType = '';
    },

    setAuthType(type) {
      this.authType = type;

      // if (this.authType === 'parent') {
      //   this.$router.push({name: 'parents-program-registration', params: { referer: 'login' }});
      //   return;
      // }
      this.personalEmail = this.authType === 'parent';

      if (['login', 'registration', 'parent'].includes(type) && this.isDesktopLayout) {
        this.$nextTick(() => {
          this.$refs.emailInput.inputSetFocus();
        });
      }
    },

    closeLoginModal() {
      this.loginModal = false;
    },

    goToSettings() {
      this.$router.push({name: 'user-settings'});
    }
  }
}
</script>
