<template>
  <div class="vue-c-item-slider">
    <!-- TODO: Consider there gesture control for sliding between meal details -->
    <frm1006-button
      class="vue-item-slider-control-btn vue-item-slider-control-prev"
      type="internal"
      :disabled="!hasPrevStep"
      @buttonClickEvent="goBackward"
    />

    <frm1006-button
      class="vue-item-slider-control-btn vue-item-slider-control-back"
      type="internal"
      @buttonClickEvent="goToList"
    />

    <frm1006-button
      class="vue-item-slider-control-btn vue-item-slider-control-next"
      type="internal"
      :disabled="!hasNextStep"
      @buttonClickEvent="goForward"
    />
  </div>
</template>

<script>
export default {
  name: 'Prj1039ItemSlider',
  props: {
    position: Number,
    itemsCount: Number,
    availableItems: Array,
    activeId: Number
  },
  computed: {
    hasPrevStep() {
      return this.position > 0;
    },

    hasNextStep() {
      const arrayLength = this.availableItems.length;
      const lastArrayItem = this.availableItems[arrayLength - 1];

      return this.position < lastArrayItem;
    }
  },
  methods: {
    goForward() {
      if (this.hasNextStep) {
        this.$emit('moveForward')
      }
    },

    goToList() {
      this.$emit('goToList')
    },

    goBackward() {
      if (this.hasPrevStep) {
        this.$emit('moveBackward');
      }
    }
  }
}
</script>
