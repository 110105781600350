const checkLoggedState = () => {
  const localStorageStoreObject = JSON.parse(localStorage.getItem('zapka-localstorage'));

  if (localStorage.getItem('zapka-localstorage') != null) {
    return localStorageStoreObject.user.isLogged;
  } else {
    return false;
  }
}

const checkAnonymousState = () => {
  const localStorageStoreObject = JSON.parse(localStorage.getItem('zapka-localstorage'));

  if (localStorage.getItem('zapka-localstorage') != null) {
    return localStorageStoreObject.user.profile.anonymous === null || localStorageStoreObject.user.profile.anonymous === true;
  } else {
    return false;
  }
}

export default {
  checkLoggedState,
  checkAnonymousState
}
