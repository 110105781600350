<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template v-if="step === 1">
        <prj1017-heading
          :header="$i18n.t('profile.forms.headlineEditPhone')"
        />

        <gen1006-info-panel
          v-if="sendFailed"
          type="error"
        >
          {{ $t('profile.forms.msgFetchProfilePhoneFailed') }}
        </gen1006-info-panel>

        <ValidationObserver ref="observerStep1">
          <frm1035-form-group>
            <ValidationProvider
              v-slot="{ errors, classes }"
              :name="$i18n.t('profile.forms.phone')"
              :rules="{required: true, phone: true}"
              tag="div"
            >
              <frm1001-input-field
                v-model="phoneModel"
                :tooltip="false"
                :label="$i18n.t('profile.forms.phoneLabel')"
                :class="classes"
                @inputKeyUpEvent="validateFormStep1"
              />
              <span class="vue-validation-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </frm1035-form-group>
        </ValidationObserver>
      </template>

      <template v-if="step === 2">
        <prj1017-heading
          :header="$i18n.t('profile.forms.confirmationCode')"
        />

        <gen1006-info-panel
          v-if="!sendFailed"
          type="info"
        >
          {{ sentCodeAgain ? $t('profile.forms.confirmationCodeSentAgainInfo2') : $t('profile.forms.confirmationCodeSentAgainInfo') }}
        </gen1006-info-panel>

        <gen1006-info-panel
          v-if="sendFailed"
          type="error"
        >
          {{ $t('profile.forms.msgFetchProfilePhoneCodeFailed') }}
        </gen1006-info-panel>

        <ValidationObserver ref="observerStep2">
          <frm1035-form-group>
            <ValidationProvider
              v-slot="{ errors, classes }"
              :name="$i18n.t('profile.forms.confirmationCode')"
              :rules="{required: true, code: true}"
              tag="div"
            >
              <frm1001-input-field
                v-model="codeModel"
                :tooltip="false"
                :label="$i18n.t('profile.forms.confirmationCode')"
                :class="classes"
                @inputKeyUpEvent="validateFormStep2"
              />
              <span class="vue-validation-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </frm1035-form-group>
        </ValidationObserver>
      </template>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        v-if="step === 1"
        class="vue-button-page-layout-fixed"
        type="primary"
        :disabled="sending || !isForm1Valid || isSamePhone"
        @buttonClickEvent="submitPhoneCheck"
      >
        {{ $t('profile.forms.buttonNext') }}
      </frm1006-button>

      <frm1006-button
        v-if="step === 2"
        class="vue-button-page-layout-fixed vue-is-second"
        type="primary"
        :disabled="sending || !isForm2Valid"
        @buttonClickEvent="submitCodeCheck"
      >
        {{ $t('profile.forms.buttonNext') }}
      </frm1006-button>

      <frm1006-button
        v-if="step === 2"
        class="vue-button-page-layout-fixed"
        type="secondary"
        :disabled="sending"
        @buttonClickEvent="submitCodeAgain"
      >
        {{ $t('profile.forms.confirmationCodeSentAgain') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />
    </template>
  </page-layout>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {
} from '@/constants/app-constants';
import '@/utils/validation';

import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import router from '@/router';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import mxNavigationDefault from '../../mixins/mx-navigation-default';

export default {
  name: 'ProfileEditPhone',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      phoneModel: '',
      codeModel: '',
      sending: false,
      sendFailed: false,
      isForm1Valid: false,
      isForm2Valid: false,
      sentCodeAgain: false,
      step: 1
    }
  },

  computed: {
    ...mapState('user', ['profile']),
    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY
    }),

    paramReferrer() {
      if(typeof this.$route.params?.referrer === 'undefined') {
        return 'none';
      }
      return this.$route.params.referrer
    },

    isSamePhone() {
      let retValue = false;

      if (this.profile?.phone === this.phoneModel || ('+420' + this.profile?.phone) === this.phoneModel || ('00420' + this.profile?.phone) === this.phoneModel ) {
        retValue = true;
      }

      return retValue;
    }
  },

  mounted() {
    this.sentCodeAgain = false;
    this.phoneModel = this.profile?.phone || '-';
  },

  methods: {
    validateFormStep1() {
      this.$refs.observerStep1.validate().then(isValidate => {
        this.isForm1Valid = isValidate;
      });
    },

    validateFormStep2() {
      this.$refs.observerStep2.validate().then(isValidate => {
        this.isForm2Valid = isValidate;
      });
    },

    submitPhoneCheck() {
      this.$refs.observerStep1.validate().then(isValidate => {
        if (isValidate) {
          let formData = new FormData();
          formData.append('phone', this.phoneModel);

          this.sending = true;
          this.sendFailed = false;
          dataLoader
            .postIdentityProfilePhoneVerify(formData)
            .then(() => {
              logger.info('Form phone check has been sent.')
              this.sending = false;
              this.sendFailed = false;
              this.step = 2;
            })
            .catch((error) => {
              this.sending = false
              this.sendFailed = true
              logger.error('Sending form has failed.', error.response)
            });
        }
      });
    },

    submitCodeCheck() {
      this.$refs.observerStep2.validate().then(isValidate => {
        if (isValidate) {
          let formData = new FormData();
          formData.append('phone', this.phoneModel);
          formData.append('code', this.codeModel);

          this.sending = true;
          this.sendFailed = false;
          dataLoader
            .postIdentityProfilePhoneVerify(formData)
            .then((data) => {
              logger.info('Form code check has been sent.')
              this.sending = false;
              this.sendFailed = false;

              if (data.state) {
                this.goToBack();
              }
            })
            .catch((error) => {
              this.sending = false
              this.sendFailed = true
              logger.error('Sending form has failed.', error.response)
            });
        }
      });
    },

    submitCodeAgain() {
      let formData = new FormData();
      formData.append('phone', this.phoneModel);

      this.sentCodeAgain = true;
      this.sending = true;
      this.sendFailed = false;
      dataLoader
        .postIdentityProfilePhoneVerify(formData)
        .then(() => {
          logger.info('Form phone check has been sent.')
          this.sending = false;
          this.sendFailed = false;
          this.step = 2;
        })
        .catch((error) => {
          this.sending = false
          this.sendFailed = true
          logger.error('Sending form has failed.', error.response)
        });
    },

    clearForm() {
      this.step = 1;
      this.phoneModel = '';
    },

    goToBack() {
      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.IDENTITY_PROFILE, {force: true});
      if (this.paramReferrer === 'none') {
        router.push({ name: 'profile' });
      } else {
        router.push({ name: this.paramReferrer, params: { preload: true } });
      }
    }
  }
}
</script>
