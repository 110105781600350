<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
    :class="categoryType"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-info-controls">
        <frm1006-button
          v-for="(item, index) in subPages"
          :key="index"
          :icon-right="true"
          class="vue-control-item"
          type="internal"
          @buttonClickEvent="goToView(item.id)"
        >
          {{ item.title }}
        </frm1006-button>
      </div>

      <gen1006-info-panel
        v-if="!isLoading && !initialized"
        type="neutral"
      >
        {{ $t('general.loadingFailed') }}
      </gen1006-info-panel>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <transition
        name="vue-anim-strategy-move-from-bottom"
        appear
      >
        <div class="vue-building-illustration" />
      </transition>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import router from '@/router';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

export default {
  name: 'BuildingHHQList',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      listPages: [],
      listHeader: {}
    };
  },

  computed: {
    pageId() {
      return this.$route.params.categoryId;
    },

    categoryType() {
      return `vue-building-category-${this.$route.params.categoryType}`;
    },

    pageCaption() {
      return this.$route.meta.navbarCaption;
    },

    subPages() {
      if (Object.keys(this.listPages).length === 0) {
        return false;
      }

      if (Object.prototype.hasOwnProperty.call(this.listPages, 'items')) {
        return this.listPages['items'];
      }

      return false;
    },

    navbarCaption() {
      if(this.listHeader) {
        return this.listHeader.title;
      }
      return '';
    }
  },

  watch: {
    pageId() {
      this.fetchListPages();
    },

    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        if (this.articleData) {
          this.$refs.pageLayout.pageAsyncDataLoadedDone();
        }
      }
    },
  },

  mounted() {
    if(!this.subPages) {
      this.fetchListPages();
      this.fetchListPage();
    } else {
      this.isLoading = false;
    }

    this.initialized = true;
  },

  methods: {
    fetchListPages() {
      dataLoader.fetchSubPages(this.pageId)
        .then(pages => {
          this.listPages = pages;
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
    },

    fetchListPage() {
      dataLoader.fetchPostById(this.pageId)
        .then(data => {
          this.listHeader = data[0];
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
    },

    goToView(pageId) {
      router.push({ name: 'building-page', params: { id: pageId}});
    },
  }
}
</script>
