import * as MUTATIONS_CONSTANTS from '../constants/mutations'
import * as ACTIONS_CONSTANTS from '../constants/actions';
import dataLoader from '@/utils/data-loader';

const state = {
  forvardinoFilterCategories: {
    locations: {},
    categories: {},
    targets: {}
  },
  forvardinoSelectedCategories: {
    eventLocation: '',
    eventTargets: [],
    eventCategories: [],
    eventSearchString: '',
    onlyOnline: 0,
  },
  forvardinoRegisteredEvent: {
    id: 0,
    name: '',
    selectedLessonId: 0,
    activityFrom: ''
  },
  forvardinoActiveCategory: 0,
  forvardinoAreFiltersActive: false,
  noMoreItems: false,
  forvardinoPages: {},
  searchQuery: '',
  detailData: {},
  isSearchActive: false,
  isSearchHistoryActive: false,
  modalCustomCourseActive: false,
  modalCustomCourseFormActive: false,
  modalNewLessonFormActive: false,
  modalEditCourseFormActive: false,
  draftFormData: {
    id: null,
    name: '',
    description: '',
    categories: [],
    target: '',
    image: null,
    imageOld: '',
    state: false,
    completed: false
  },
  newLessonFormData: {
    id: null,
    dateStart: '',
    dateEnd: '',
    location: '',
    place: '',
    online: false,
    url: '',
    capacity: '',
    completed: false
  }
}

const mutations = {
  [MUTATIONS_CONSTANTS.SET_FORVARDINO_FILTER_CATEGORIES](state, categoriesArray) {
    state.forvardinoFilterCategories.locations = categoriesArray.locations;
    state.forvardinoFilterCategories.categories = categoriesArray.categories;
    state.forvardinoFilterCategories.targets = categoriesArray.targets;
  },

  [MUTATIONS_CONSTANTS.SET_REGISTERED_FORVARDINO_EVENT](state, event) {
    state.forvardinoRegisteredEvent.id = event.id;
    state.forvardinoRegisteredEvent.name = event.name;
    state.forvardinoRegisteredEvent.selectedLessonId = event.selectedLessonId;
    state.forvardinoRegisteredEvent.activityFrom = event.activityFrom;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_SELECTED_CATEGORIES](state, filteredCategories) {
    state.forvardinoSelectedCategories.eventCategories = filteredCategories.categories;
    state.forvardinoSelectedCategories.eventLocation = filteredCategories.locations;
    state.forvardinoSelectedCategories.eventTargets = filteredCategories.targets;
    state.forvardinoSelectedCategories.eventSearchString = filteredCategories.search
    state.forvardinoSelectedCategories.onlyOnline = filteredCategories.onlyOnline;
  },

  [MUTATIONS_CONSTANTS.SET_ARE_FILTERS_ACTIVE](state, filtersState) {
    state.forvardinoAreFiltersActive = filtersState;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_ACTIVE_CATEGORY](state, activeCategory) {
    state.forvardinoActiveCategory = activeCategory;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_PAGES](state, pages) {
    state.forvardinoPages = pages;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_QUERY](state, searchQuery) {
    state.searchQuery = searchQuery;
  },

  [MUTATIONS_CONSTANTS.SET_DETAIL_DATA](state, detailData) {
    state.detailData = detailData;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_STATUS](state, status) {
    state.isSearchActive = status;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS](state, status) {
    state.isSearchHistoryActive = status;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_CUSTOM_COURSE_MODAL](state, active) {
    state.modalCustomCourseActive = active;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_CUSTOM_COURSE_FORM_MODAL](state, active) {
    state.modalCustomCourseFormActive = active;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_MODAL](state, active) {
    state.modalNewLessonFormActive = active;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_EDIT_COURSE_FORM_MODAL](state, active) {
    state.modalEditCourseFormActive = active;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_ID](state, id) {
    state.draftFormData.id = id;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_NAME](state, name) {
    state.draftFormData.name = name;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_DESCRIPTION](state, description) {
    state.draftFormData.description = description;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_CATEGORY](state, category) {
    state.draftFormData.categories = category;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_TARGET](state, target) {
    state.draftFormData.target = target;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_IMAGE](state, image) {
    state.draftFormData.image = image;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_IMAGE_OLD](state, imageOld) {
    state.draftFormData.imageOld = imageOld;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_STATE](state, value) {
    state.draftFormData.state = value;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_COMPLETED](state, completed) {
    state.draftFormData.formCompleted = completed;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_RESET](state) {
    state.draftFormData.id = null;
    state.draftFormData.name = '';
    state.draftFormData.description = '';
    state.draftFormData.categories = [];
    state.draftFormData.target = '';
    state.draftFormData.image = null;
    state.draftFormData.imageOld = '';
    state.draftFormData.state = null;
    state.draftFormData.completed = false;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_ID](state, id) {
    state.newLessonFormData.id = id;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_DATE_START](state, dateStart) {
    state.newLessonFormData.dateStart = dateStart;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_DATE_END](state, dateEnd) {
    state.newLessonFormData.dateEnd = dateEnd;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_LOCATION](state, location) {
    state.newLessonFormData.location = location;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_PLACE](state, place) {
    state.newLessonFormData.place = place;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_ONLINE](state, online) {
    state.newLessonFormData.online = online;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_URL](state, url) {
    state.newLessonFormData.url = url;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_CAPACITY](state, capacity) {
    state.newLessonFormData.capacity = capacity;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_COMPLETED](state, completed) {
    state.newLessonFormData.completed = completed;
  },

  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_RESET](state) {
    state.newLessonFormData.id = null;
    state.newLessonFormData.dateStart = '';
    state.newLessonFormData.dateEnd = '';
    state.newLessonFormData.location = '';
    state.newLessonFormData.place = '';
    state.newLessonFormData.online = false;
    state.newLessonFormData.url = '';
    state.newLessonFormData.capacity = '';
    state.newLessonFormData.completed = false;
  },
}

const actions = {
  [ACTIONS_CONSTANTS.FETCH_FORVARDINO_FILTER_DATA]({ commit }) {
    dataLoader.getForvardinoFilters()
      .then(response => {
        commit(MUTATIONS_CONSTANTS.SET_FORVARDINO_FILTER_CATEGORIES, response);
      })
  },

  [ACTIONS_CONSTANTS.RESET_ALL_FORVARDINO_FILTERS]({ commit }) {
    const resettedFilterObject = {
      eventLocation: '',
      eventTargets: [],
      eventCategories: [],
      onlyOnline: 0,
    }

    commit(MUTATIONS_CONSTANTS.SET_FORVARDINO_SELECTED_CATEGORIES, resettedFilterObject);
    commit(MUTATIONS_CONSTANTS.SET_FORVARDINO_ACTIVE_CATEGORY, 0);
    commit(MUTATIONS_CONSTANTS.SET_ARE_FILTERS_ACTIVE, false);
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
