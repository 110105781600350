<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template v-if="initialized && !isLoading">
        <gen1015-section
          :collapsible="true"
          :collapsed="false"
          class="is-animated"
        >
          <template v-slot:header>
            <h3>{{ detailNPS?.name }}</h3>
          </template>
          <template v-slot:content>
            <div class="vue-b-content-animated">
              <div class="vue-c-nps">
                <gen1006-info-panel
                  v-if="lastYearData"
                  type="neutral"
                >
                  <p v-html="$t('clientSatisfaction.lastYearData')"></p>
                </gen1006-info-panel>

                <div class="vue-b-nps-content">
                  <p
                    v-if="detailNPS?.description"
                    class="vue-description"
                  >
                    {{ detailNPS?.description }}
                  </p>

                  <span
                    v-if="detailNPS?.respondents"
                    class="vue-respondents"
                  >
                    {{ $t('clientSatisfaction.respondents', {value: detailNPS.respondents}) }}
                  </span>
                </div>

                <div class="vue-b-nps-image">
                  <div
                    v-if="detailNPS?.summary"
                    v-html="makeSvg(detailNPS.summary, detailNPS.npsState)"
                  />
                </div>

                <div class="vue-b-nps-graph">
                  <div class="relative">
                    <prj1031-nps-graph
                      v-if="detailNPS?.data"
                      :title="npsGraphData.title"
                      :chartData="detailNPS.data"
                      :valuesLegend="npsGraphData.valuesLegend"
                      :base-values-legend="npsGraphData.baseValuesLegend"
                      :values-units="npsGraphData.valuesUnits"
                      :time-units="npsGraphData.timeUnits"
                      :class-color="'text-graph-summary-' + getGraphState(detailNPS.summary)"
                    />
                  </div>
                </div>

                <div class="vue-b-nps-data">
                  <template v-if="detailNPS?.data">
                    <button
                      class="inline-flex absolute py-2 right-0 -translate-y-full z-50"
                      @click="onToggleCard"
                    >
                      <span class="mr-2">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            v-if="isCardExpanded"
                            d="M6 18L18 6M6 6L18 18"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            v-else
                            d="M3 10H21M3 14H21M12 10V18M5 18H19C20.1046 18 21 17.1046 21 16V8C21 6.89543 20.1046 6 19 6H5C3.89543 6 3 6.89543 3 8V16C3 17.1046 3.89543 18 5 18Z"
                            stroke="currentColor"
                            stroke-width="2"
                          />
                        </svg>
                      </span>
                      <span>{{ $t('clientSatisfaction.tableActiveBtn') }}</span>
                    </button>

                    <transition
                      name="fade"
                      appear
                    >
                      <table
                        v-if="isCardExpanded"
                        class="vue-table overflow-hidden transition-all opacity-1 max-h-px-920 duration-200"
                      >
                        <thead>
                          <tr>
                            <th>{{ $t('clientSatisfaction.captionMonth') }}</th>
                            <th><span class="vue-caption-plan"></span></th>
                            <th>
                              <span
                                :class="'vue-caption-summary-' + getGraphState(detailNPS.summary)"
                                class="vue-caption-summary"
                              ></span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in detailNPS.data"
                            :key="index"
                          >
                            <td>{{ $t('time.months.' + item.month) }}</td>
                            <td>{{ item.plan ?? '' }}</td>
                            <td>{{ item.summary ?? '' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </transition>
                  </template>
                  <template v-else>{{ $t('clientSatisfaction.tableNoData') }}</template>
                </div>
              </div>
            </div>
          </template>
        </gen1015-section>

        <template v-if="detailNPS?.children">
          <gen1015-section
            v-for="npsChild in detailNPS.children"
            :key="'child-' + detailId + '-' + npsChild.id"
            :collapsible="true"
            :collapsed="true"
            class="is-animated vue-is-child"
          >
            <template v-slot:header>
              <h3>{{ npsChild.name }}</h3>
            </template>
            <template v-slot:content>
              <div class="vue-b-content-animated">
                <div class="vue-c-nps">
                  <div class="vue-b-nps-content">
                    <p
                      v-if="npsChild?.description"
                      class="vue-description"
                    >
                      {{ npsChild?.description }}
                    </p>

                    <span
                      v-if="npsChild?.respondents"
                      class="vue-respondents"
                    >
                      {{ $t('clientSatisfaction.respondents', {value: npsChild.respondents}) }}
                    </span>
                  </div>

                  <div class="vue-b-nps-image">
                    <div
                      v-if="npsChild.summary"
                      v-html="makeSvg(npsChild.summary, npsChild.npsState)"
                    />
                  </div>

                  <div class="vue-b-nps-graph">
                    <div class="relative">
                      <prj1031-nps-graph
                        v-if="npsChild.data"
                        :title="npsGraphData.title"
                        :chartData="npsChild.data"
                        :valuesLegend="npsGraphData.valuesLegend"
                        :base-values-legend="npsGraphData.baseValuesLegend"
                        :values-units="npsGraphData.valuesUnits"
                        :time-units="npsGraphData.timeUnits"
                        :class-color="'text-graph-summary-' + getGraphState(npsChild.summary)"
                      />
                    </div>
                  </div>

                  <div class="vue-b-nps-data">
                    <template v-if="npsChild?.data">
                      <button
                        class="inline-flex absolute py-2 right-0 -translate-y-full z-50"
                        @click="onToggleCard"
                      >
                        <span class="mr-2">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              v-if="isCardExpanded"
                              d="M6 18L18 6M6 6L18 18"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              v-else
                              d="M3 10H21M3 14H21M12 10V18M5 18H19C20.1046 18 21 17.1046 21 16V8C21 6.89543 20.1046 6 19 6H5C3.89543 6 3 6.89543 3 8V16C3 17.1046 3.89543 18 5 18Z"
                              stroke="currentColor"
                              stroke-width="2"
                            />
                          </svg>
                        </span>
                        <span>{{ $t('clientSatisfaction.tableActiveBtn') }}</span>
                      </button>

                      <transition
                        name="fade"
                        appear
                      >
                        <table
                          v-if="isCardExpanded"
                          class="vue-table overflow-hidden transition-all opacity-1 max-h-px-920 duration-200"
                        >
                          <thead>
                            <tr>
                              <th>{{ $t('clientSatisfaction.captionMonth') }}</th>
                              <th><span class="vue-caption-plan"></span></th>
                              <th>
                                <span
                                  :class="'vue-caption-summary-' + getGraphState(detailNPS.summary)"
                                  class="vue-caption-summary"
                                ></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in npsChild.data"
                              :key="index"
                            >
                              <td>{{ $t('time.months.' + item.month) }}</td>
                              <td>{{ item.plan ?? '' }}</td>
                              <td>{{ item.summary ?? '' }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </transition>
                    </template>
                    <template v-else>{{ $t('clientSatisfaction.tableNoData') }}</template>
                  </div>
                </div>
              </div>
            </template>
          </gen1015-section>
        </template>
      </template>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1031NpsGraph from '@/components/prj1031-nps-graph/prj1031-nps-graph'

import PageLayout from '../../templates/partials/page-layout';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import {chartUI} from '@/assets/charts';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import router from '@/router';

export default {
  name: 'ClientSatisfactionDetail',

  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1031NpsGraph
  },

  mixins: [mxNavigationDefault],

  data() {
    return {
      isLoading: true,
      initialized: false,
      chartUI,
      isCardExpanded: false,
      npsGraphData: {
        title: '',
        valuesLegend: this.$i18n.t('clientSatisfaction.graphLegendSummary'),
        baseValuesLegend: this.$i18n.t('clientSatisfaction.graphLegendPlan'),
        timeUnits: 'months',
        valuesUnits: ''
      }
    };
  },

  computed: {
    ...mapState('clientSatisfaction', [
      'npsGraphValues',
      'npsAreLoaded',
      'npsFilterCategories',
      'npsSelectedFilters',
      'npsFiltersLoaded',
      'npsActiveCategory',
      'npsAreFiltersActive'
    ]),

    detailId() {
      return this.$route.params.id;
    },

    detailYear() {
      return this.$route.params.year;
    },

    detailNPS() {
      let retValue = null;

      if (this.npsGraphValues) {
        Object.entries(this.npsGraphValues).forEach(item => {
          if (item[1].id === this.detailId) {
            retValue = item[1];
          }
        });
      }

      if (retValue?.data) {
        Object.entries(retValue.data).forEach(item => {
          let tmpBaseValue = item[1].plan ? parseInt(item[1].plan) : null;
          if (tmpBaseValue !== null && tmpBaseValue > 0) {
            tmpBaseValue = Math.round(tmpBaseValue / 2);
          }
          if (tmpBaseValue !== null && tmpBaseValue < 0) {
            tmpBaseValue = Math.round((-1 * tmpBaseValue) / 2);
          }
          let tmpValue = item[1].summary ? parseInt(item[1].summary) : null;
          if (tmpValue !== null && tmpValue > 0) {
            tmpValue = Math.round(tmpValue / 2);
          }
          if (tmpValue !== null && tmpValue < 0) {
            tmpValue = Math.round((-1 * tmpValue) / 2);
          }
          item[1].baseValue = tmpBaseValue;
          item[1].value = tmpValue;
          item[1].date = new Date(item[1].year, (item[1].month) - 1, 1).format('YYYY-MM-DD');
        });
      }

      if (retValue?.children) {
        Object.entries(retValue.children).forEach(childItem => {
          Object.entries(childItem[1].data).forEach(childItemData => {
            let tmpBaseValue = childItemData[1].plan ? parseInt(childItemData[1].plan) : null;
            if (tmpBaseValue !== null && tmpBaseValue > 0) {
              tmpBaseValue = Math.round(tmpBaseValue / 2);
            }
            if (tmpBaseValue !== null && tmpBaseValue < 0) {
              tmpBaseValue = Math.round((-1 * tmpBaseValue) / 2);
            }
            let tmpValue = childItemData[1].summary ? parseInt(childItemData[1].summary) : null;
            if (tmpValue !== null && tmpValue > 0) {
              tmpValue = Math.round(tmpValue / 2);
            }
            if (tmpValue !== null && tmpValue < 0) {
              tmpValue = Math.round((-1 * tmpValue) / 2);
            }
            childItemData[1].baseValue = tmpBaseValue;
            childItemData[1].value = tmpValue;
            childItemData[1].date = new Date(childItemData[1].year, (childItemData[1].month) - 1, 1).format('YYYY-MM-DD');
          });
        });
      }

      return retValue;
    },

    lastYearData() {
      let retValue = false;
      if (this.detailNPS) {
        Object.entries(this.detailNPS?.data).forEach(item => {
          if (item[1].year !== new Date().getFullYear()) {
            retValue = true;
          }
        });
      }
      return retValue;
    },

    getFilterUrl() {
      let filterItems = {'years': this.npsActiveCategory};
      let filterResult = '';
      let filterKeysCount = Object.keys(filterItems).length;
      Object.entries(filterItems).forEach(([key, value]) => {
        filterResult += key + '|eq|' + value;
        filterResult += (filterKeysCount > 1) ? ';' : '';
      });

      return filterResult;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  mounted() {
    if (!this.detailYear) {
      router.push({ name: 'nps' });
    }

    if (!this.npsActiveCategory) {
      this.setFilters(this.detailYear)
    }

    if (!this.detailNPS) {
      this.fetchNpsData();
    } else {
      this.initializePage();
    }
  },

  methods: {
    initializePage() {
      this.initialized = true;
    },

    fetchNpsData() {
      this.isLoading = true;
      Promise.all([
        this.$store.dispatch(STORE_MODULES.CLIENT_SATISFACTION + '/' + ACTIONS_CONSTANTS.FETCH_NPS_DATA, {filter: this.getFilterUrl})
      ]).finally(() => {
        this.isLoading = false;
        this.initializePage();
      });
    },

    setFilters(categoryNumber) {
      let selectedCategoryPayload = {
        years: [categoryNumber]
      };

      this.$store.commit(STORE_MODULES.CLIENT_SATISFACTION + '/' + MUTATIONS_CONSTANTS.SET_NPS_SELECTED_FILTERS, selectedCategoryPayload);
      this.$store.commit(STORE_MODULES.CLIENT_SATISFACTION + '/' + MUTATIONS_CONSTANTS.SET_NPS_ACTIVE_CATEGORY, categoryNumber);
    },

    compareGraphValue(value) {
      let resultValue = 50;
      if(value >= -100 && value < 0) {
        resultValue = Math.abs(50 - Math.abs(value / 2));
      }
      if(value > 0 && value <= 100) {
        resultValue = Math.abs(50 + Math.abs(value / 2));
      }
      return resultValue;
    },

    getGraphState(summary) {
      let state = '';

      if(summary < 0){
        state = 'danger';
      } else if(summary > 0 && summary <= 50){
        state = 'warning';
      } else{
        state = 'success';
      }

      return state;
    },

    makeSvg(summary, npsState, inner_text=''){
      if (summary === null) {
        return false;
      }

      let percentage = summary;
      let abs_percentage = Math.abs(percentage).toString();
      let percentage_str = percentage.toString();
      let classes = this.getGraphState(summary) + '-stroke';

      // if(percentage < 0){
      //   classes += '-stroke circle-chart__circle--negative';
      // } else if(percentage > 0 && percentage <= 50){
      //   classes += '-stroke';
      // } else{
      //   classes += '-stroke';
      // }

      let svg = '<svg class="circle-chart" viewbox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg">'
        + '<circle class="circle-chart__background" cx="16.9" cy="16.9" r="15.4" />'
        + '<circle class="circle-chart__circle '+classes+'" '
        + 'stroke-dasharray="'+ abs_percentage+',100" cx="16.9" cy="16.9" r="15.4" />'
        + '<g class="circle-chart__info">'
        + '   <text class="circle-chart__percent" x="16.9" y="16.5">'+percentage_str+'</text>';

      if(npsState === 'down') {
        svg += '<svg width="4" height="4" x="24" y="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M4.66667 1.66667C4.66667 1.11438 5.11438 0.666666 5.66667 0.666666C6.21895 0.666666 6.66667 1.11438 6.66667 1.66667H4.66667ZM6.66667 10.3333C6.66667 10.8856 6.21895 11.3333 5.66667 11.3333C5.11438 11.3333 4.66667 10.8856 4.66667 10.3333H6.66667ZM0.292893 7.04044C-0.0976311 6.64992 -0.0976311 6.01675 0.292893 5.62623C0.683417 5.2357 1.31658 5.2357 1.70711 5.62623L0.292893 7.04044ZM5.66667 11L6.37377 11.7071C5.98325 12.0976 5.35008 12.0976 4.95956 11.7071L5.66667 11ZM9.62623 5.62623C10.0168 5.2357 10.6499 5.2357 11.0404 5.62623C11.431 6.01675 11.431 6.64992 11.0404 7.04044L9.62623 5.62623ZM6.66667 1.66667V10.3333H4.66667V1.66667H6.66667ZM1.70711 5.62623L6.37377 10.2929L4.95956 11.7071L0.292893 7.04044L1.70711 5.62623ZM4.95956 10.2929L9.62623 5.62623L11.0404 7.04044L6.37377 11.7071L4.95956 10.2929Z" fill="#D70808"/>\n' +
          '</svg>\n'
      }

      if(npsState === 'up') {
        svg += '<svg width="4" height="4" x="24" y="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M4.66667 10.3333C4.66667 10.8856 5.11438 11.3333 5.66667 11.3333C6.21895 11.3333 6.66667 10.8856 6.66667 10.3333H4.66667ZM6.66667 1.66667C6.66667 1.11438 6.21895 0.666667 5.66667 0.666667C5.11438 0.666667 4.66667 1.11438 4.66667 1.66667H6.66667ZM0.292893 4.95956C-0.0976311 5.35008 -0.0976311 5.98325 0.292893 6.37377C0.683417 6.7643 1.31658 6.7643 1.70711 6.37377L0.292893 4.95956ZM5.66667 1L6.37377 0.292893C5.98325 -0.0976311 5.35008 -0.0976311 4.95956 0.292893L5.66667 1ZM9.62623 6.37377C10.0168 6.7643 10.6499 6.7643 11.0404 6.37377C11.431 5.98325 11.431 5.35008 11.0404 4.95956L9.62623 6.37377ZM6.66667 10.3333V1.66667H4.66667V10.3333H6.66667ZM1.70711 6.37377L6.37377 1.70711L4.95956 0.292893L0.292893 4.95956L1.70711 6.37377ZM4.95956 1.70711L9.62623 6.37377L11.0404 4.95956L6.37377 0.292893L4.95956 1.70711Z" fill="#29A810"/>\n' +
          '</svg>\n'
      }

      if(inner_text){
        svg += '<text class="circle-chart__subline" x="16.91549431" y="22">'+inner_text+'</text>'
      }

      svg += ' </g></svg>';

      return svg
    },

    onToggleCard() {
      return (this.isCardExpanded = !this.isCardExpanded)
    },
  },
}
</script>
