<template>
  <div class="vue-c-date-list">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Prj1030DateList'
}
</script>
