import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';

const state = {
  promotedFilterCategories: {
    modules: [
      {
        id: 1,
        name: 'Akce',
        shortcode: 'event'
      },
      {
        id: 2,
        name: 'Benefity',
        shortcode: 'benefit'
      },
      {
        id: 3,
        name: 'Forvardino',
        shortcode: 'forvardino'
      },
      {
        id: 4,
        name: 'Novinky',
        shortcode: 'news'
      },
    ]
  },
  promotedModules: [],
  promotedSelectedCategories: {
    modules: []
  },
  promotedActiveModule: 0,
  promotedAreFiltersActive: false,
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_PROMOTED_MODULES](state, promotedModules) {
    state.promotedModules = promotedModules;
  },

  [MUTATIONS_CONSTANTS.SET_PROMOTED_ACTIVE_MODULE](state, activeModule) {
    state.promotedActiveModule = activeModule;
  },

  [MUTATIONS_CONSTANTS.SET_DETAIL_DATA](state, detailData) {
    state.detailData = detailData;
  },

  [MUTATIONS_CONSTANTS.SET_PROMOTED_SELECTED_CATEGORIES](state, selectedCategories) {
    state.promotedSelectedCategories.modules = selectedCategories.modules;
  },

  [MUTATIONS_CONSTANTS.SET_PROMOTED_FILTER_ACTIVE](state, filterState) {
    state.promotedAreFiltersActive = filterState;
  },
};

const actions = {
  [ACTIONS_CONSTANTS.RESET_ALL_PROMOTED_FILTER]({ commit }) {
    const resettedPromotedObject = {
      modules: []
    }

    commit(MUTATIONS_CONSTANTS.SET_PROMOTED_SELECTED_CATEGORIES, resettedPromotedObject);
    commit(MUTATIONS_CONSTANTS.SET_PROMOTED_ACTIVE_MODULE, 0);
    commit(MUTATIONS_CONSTANTS.SET_PROMOTED_FILTER_ACTIVE, false);
  }
};

const getters = {
  [GETTERS_CONSTANTS.GET_FILTER_CATEGORIES_MODULE_ITEM]: state => {
    const result = state.promotedFilterCategories.modules.find(({ id }) => id === state.promotedActiveModule);
    if (typeof result === 'undefined') {
      return 'any';
    }
    return result.shortcode;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
