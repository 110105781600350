<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <transition-group
    tag="ul"
    name="fade"
    class="mb-4"
    :class="classNames"
    appear
  >
    <li
      v-for="(article, index) in articles"
      :key="`article-item-${article.id}`"
      class="mb-5 last:mb-0 transition-opacity duration-100"
      :style="transitionDelay(index)"
    >
      <prj1004-article-item
        :article="article"
        :pathName="pathName"
      />
    </li>
  </transition-group>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import '@/utils/dayjs'

import Prj1004ArticleItem from '@/components/esg/prj1004-article-list/prj1004-article-item'

//============ EXPORT ==================================//
//======================================================//

export default {
  name: 'Prj1004ArticleList',
  components: { Prj1004ArticleItem },
  props: {
    articles: {
      type: Array,
      required: true,
    },
    animDelay: {
      type: Number,
      default: 0,
    },
    itemAnimDuration: {
      type: Number,
      default: 0,
    },
    classNames: {
      type: String,
      default: null,
    },
    pathName: {
      type: String,
      required: true,
    },
  },
  methods: {
    transitionDelay(index) {
      return `transition-delay: ${index * this.itemAnimDuration + this.animDelay}s`
    },
  },
}
</script>
