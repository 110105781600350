<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div>
    <t-icon-button
      variant="link"
      @click="onBtnClick"
    >
      <!-- Icon -->
      <svg
        class="h-6 w-6 mr-3"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 20L11 4M13 20L17 4M6 9H20M4 15H18"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span class="text-sm">{{ $t('esg.social.headline') }}</span>
    </t-icon-button>

    <!-- Overlay -->
    <prj1009-modal
      :active="activeOverlay"
      @overlayCloseEvent="closeOverlay"
    >
      <template v-slot:title>
        <t-tag
          variant="sectionTitle"
          tag-name="h2"
        >
          {{ $t('esg.social.headline') }}
        </t-tag>
        <p class="text-base font-medium mb-12">
          {{ $t('esg.social.subtitle') }}
        </p>
      </template>
      <template v-slot:content>
        <prj1023-no-content-panel
          v-if="hasNoContent"
          :text="$t('esg.social.placeholder')"
          class="mb-8"
        />
        <ul
          v-else
          class="mb-8"
        >
          <li
            v-for="link in links"
            :key="link.id"
            class="mb-5 last:mb-0"
          >
            <prj1021-social-links-item
              :iconType="link.iconType"
              :title="link.text"
              :url="link.url"
            />
          </li>
        </ul>
      </template>
    </prj1009-modal>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex'
import { GET_SOCIAL_LINKS } from '@/store/constants/esg/actions'
import { ESG_SOCIAL } from '@/store/store-modules'

import Prj1009Modal from '@/components/esg/prj1009-modal/prj1009-modal'
import Prj1021SocialLinksItem from './prj1021-social-links-item'
import Prj1023NoContentPanel from '@/components/esg/prj1023-no-content-panel/prj1023-no-content-panel'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1021SocialLinks',
  components: {Prj1009Modal, Prj1021SocialLinksItem, Prj1023NoContentPanel},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeOverlay: false,
    }
  },
  computed: {
    ...mapState('esgSocial', ['links', 'hasNoContent']),
  },
  methods: {
    onBtnClick() {
      this.$store.dispatch(`${ESG_SOCIAL}/${GET_SOCIAL_LINKS}`)
      this.openOverlay()
    },

    openOverlay() {
      this.activeOverlay = true
    },

    closeOverlay() {
      this.activeOverlay = false
    },
  },
}
</script>
