<template>
  <page-layout
    :class="classObject"
  >
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <template v-slot:contentMain>
      <template
        v-if="!isLoading"
      >
        <div
          v-if="hasOrgStructure && !startedSurvey"
          class="vue-survey-organization-structure"
        >
          <h2
            class="vue-headline"
          >
            {{ $t('survey.fillYourDivision') }}
          </h2>

          <template
            v-if="!isLoading"
          >
            <frm1035-form-group>
              <strong>{{ $i18n.t('survey.companyLabel') }}</strong>
              <frm1028-advanced-combo
                id="company-selector"
                v-model="organizationStructure.company"
                :options="companySelectOptions"
                :tooltip="false"
                :searchable="true"
                :placeholder="$i18n.t('survey.company')"
                @change="selectCompany"
              />
            </frm1035-form-group>

            <frm1035-form-group>
              <strong v-if="typeof organizationStructure !== 'undefined' && typeof organizationStructure.company !== 'undefined' && organizationStructure.company.length !== 0">{{ $i18n.t('survey.divisionLabel') }}</strong>
              <frm1028-advanced-combo
                v-if="typeof organizationStructure !== 'undefined' && typeof organizationStructure.company !== 'undefined' && organizationStructure.company.length !== 0"
                id="division-selector"
                v-model="organizationStructure.division"
                :options="divisionSelectOptions"
                :tooltip="false"
                :searchable="true"
                :placeholder="$i18n.t('survey.division')"
              />
            </frm1035-form-group>
          </template>
        </div>

        <template v-else>
          <vue-friendly-iframe
            id="vue-survey-form"
            :src="surveyIframeSrc"
            class="vue-survey-form"
            @iframe-load="initializeIframe"
          />
        </template>
      </template>
    </template>

    <template
      v-if="!startedSurvey"
      v-slot:buttonFixed
    >
      <frm1006-button
        class="vue-button-page-layout-fixed"
        type="primary"
        :disabled="organizationStructure.company === '' || organizationStructure.division === ''"
        @buttonClickEvent="startSurvey"
      >
        {{ $t('survey.startSurvey') }}
      </frm1006-button>
    </template>

    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import router from '@/router';
import surveyVuexModule from '@/store/modules/survey';

import { ORGANIZATION_STRUCTURE_ID, ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

export default {
  name: 'SurveyForm',
  components: {
    PageLayout,
    Prj1002Navbar
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      loadedStore: false,
      organizationStructure: {
        company: '',
        division: ''
      },
      startedSurvey: false,
      organizationStructureData: false,
      isLoading: true,
      hasError: false,
      iframeLoaded: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
    }
  },

  computed: {
    ...mapState('general', [
      'applicationConfigLoaded',
      'surveyConfig'
    ]),
    ...mapState('survey', [
      'surveyLoaded',
      'surveyHasError',
      'surveyContent'
    ]),

    classObject() {
      return {
        'vue-iframe-loaded': this.iframeLoaded
      }
    },

    companySelectOptions() {
      let companiesList = [];
      if (!this.organizationStructureData) {
        return companiesList;
      }

      const companies = this.organizationStructureData.companies;
      Object.values(companies).forEach(company => {
        const companyItem = {
          value: company.value.toString(),
          caption: company.name
        }

        companiesList.push(companyItem);
      });

      return companiesList;
    },

    divisionSelectOptions() {
      let divisionsList = [];
      if (this.organizationStructure.company === '') {
        return divisionsList;
      }
      if (!this.organizationStructureData) {
        return divisionsList;
      }

      const selectedCompany = this.organizationStructure.company.toString();
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.organizationStructureData.companies).forEach(([key, value]) => {
        if (value.value === parseInt(selectedCompany)) {
          let divisions = value.items;
          divisions.forEach(division => {
            const divisionItem = {
              value: division.value.toString(),
              caption: division.name + ' - ' + division.department,
            }

            divisionsList.push(divisionItem);
          })
        }
      });

      return divisionsList;
    },

    selectedCompany() {
      let retValue = '';
      if (this.organizationStructure.company === '') {
        return retValue;
      }
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.organizationStructureData.companies).forEach(([key, value]) => {
        if (value.value === parseInt(this.organizationStructure.company)) {
          retValue = value.name;
        }
      });
      return retValue;
    },

    selectedDivision() {
      let retValue = '';
      if (this.organizationStructure.company === '' || this.organizationStructure.division === '') {
        return retValue;
      }
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.organizationStructureData.companies).forEach(([key, value]) => {
        if (value.value === parseInt(this.organizationStructure.company)) {
          let divisions = value.items;
          divisions.forEach(division => {
            if (division.value === parseInt(this.organizationStructure.division)) {
              retValue = division.name;
            }
          })
        }
      });
      return retValue;
    },

    hasOrgStructure() {
      return this.surveyContent.surveyHasOrgStructure?.value;
    },

    surveyIframeSrc() {
      if (this.hasOrgStructure) {
        return this.surveyContent.surveyCmsUrl.value + '&structure-company=' + encodeURIComponent(this.selectedCompany) + '&structure-group=' + encodeURIComponent(this.selectedDivision);
      } else {
        return this.surveyContent.surveyCmsUrl?.value;
      }
    }
  },

  watch: {
    applicationConfigLoaded() {
      this.initializeSurvey();
    },
  },

  beforeCreate() {
    // TODO: CSOBZ-626 - Refactor module lazy loading into router
    if (typeof this.$store.state.survey === 'undefined') {
      this.$store.registerModule('survey', surveyVuexModule);
    }
  },

  created() {
    this.getOrganizationStructureData();
    window.addEventListener('message', this.onMessage, false);
  },

  beforeDestroy() {
    window.removeEventListener('message', this.onMessage, false);
  },

  methods: {
    getOrganizationStructureData() {
      dataLoader.fetchApiDataList(ORGANIZATION_STRUCTURE_ID)
        .then(data => {
          this.organizationStructureData = data;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.isLoading = false;
        })
    },

    initializeSurvey() {
      this.isLoading = true;

      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.MAKE_SURVEY_CONFIG)
        .then(() => {
          this.makeSurveyData();
        });
    },

    makeSurveyData() {
      const surveyConfigOptions = {
        surveyConfigItems: this.surveyConfig
      }

      this.$store.dispatch(STORE_MODULES.SURVEY + '/' + ACTIONS_CONSTANTS.MAKE_SURVEY_CONTENT, surveyConfigOptions)
        .then(() => {
          this.isLoading = false;
        });
    },

    startSurvey() {
      this.startedSurvey = true;
    },

    initializeIframe() {
      this.iframeLoaded = true;
      this.startedSurvey = true;
    },

    onMessage(event) {
      let data = event.data;

      if (data.action === 'surveyFinished') {
        this.$store.commit(STORE_MODULES.PERSISTENT_STORAGE + '/' + MUTATIONS_CONSTANTS.SET_HAS_FILLED_SURVEY, true);
        this.$store.commit(STORE_MODULES.PERSISTENT_STORAGE + '/' + MUTATIONS_CONSTANTS.SET_FILLED_SURVEY_ID, this.surveyConfig.SURVEY_ACTIVE_ID.value);
        router.push({ name: 'survey-thank-you' });
      }
    },

    selectCompany() {
      this.organizationStructure.division = '';
    },
  }
}
</script>
