<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar
            class="absolute top-0 left-7 z-10"
            route-name="news"
            nav-button-variant="through"
          />
        </template>
        <template v-slot:content>
          <!--========== ARTICLE IMAGE ==========================-->
          <!--===================================================-->
          <prj1003-article-image
            :article-image-url="articleImageUrl"
            :categoryId="articleCategoryId"
            :themeTitle="articleThemeTitle"
            class="mb-8"
          />

          <!--========== ARTICLE BODY ===========================-->
          <!--===================================================-->
          <div
            v-if="article"
            class="prj-article prj-article-extend-fest pb-4"
          >
            <t-tag variant="blockTitle">
              {{ article.title.rendered }}
            </t-tag>
            <div class="text-gray-400 text-sm -mt-2 mb-4 whitespace-nowrap">{{ $date(article.date).format('LL') }}</div>

            <div
              class="mb-4"
              v-html="article.content.rendered"
            />

            <div class="grid grid-flow-col gap-4">
              <!-- Share -->
              <prj-1015-share-button
                :title="articleTitle"
                :text="articleText"
                :url="articleUrl"
                :disabled="false"
              />

              <!-- Likes -->
            </div>
          </div>

          <!--========== RELATED ARTICLES =======================-->
          <!--===================================================-->
          <div
            v-if="relatedArticles.length > 0"
            class="pb-4"
          >
            <t-tag
              variant="blockTitle"
              class="mb-6"
            >
              {{ $t('fest.news.relatedArticles') }}
            </t-tag>

            <prj1004-article-list
              :articles="relatedArticles"
              :pathName="ROUTE_NEWS_DETAIL"
            />
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<script>
import apiCmsContent from '@/fest-api/cms-content'
import utilsGeneral from '@/utils/fest/utils-general'
import logger from '@/utils/logger'
import '@/utils/dayjs'
import zapUtilsGeneral from '@/utils/utils-general';

import { mapState } from 'vuex'
import { FEST_THEMES } from '@/store/store-modules'
import { GET_THEMES_INFO } from '@/store/constants/fest/actions'
import { ROUTE_NEWS_DETAIL } from '@/constants/fest/route-names'
import { FESTIVAL_KEY_PREFIX } from '@/constants/app-constants';

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import PageLayout from '@/templates/fest/page-layout'
import Prj1003ArticleImage from '@/components/fest/prj1003-article-image/prj1003-article-image'
import Prj1004ArticleList from '@/components/fest/prj1004-article-list/prj1004-article-list'
import Prj1005NavigationBar from '@/components/fest/prj1005-navigation-bar/prj1005-navigation-bar'
import Prj1015ShareButton from '@/components/fest/prj1015-share-button/prj1015-share-button'

export default {
  name: 'FESTNewsDetail',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout,
    Prj1004ArticleList,
    Prj1003ArticleImage,
    Prj1005NavigationBar,
    Prj1015ShareButton,
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_NEWS_DETAIL,
      article: null,
      relatedArticlesCount: 3,
      relatedArticles: [],
    }
  },
  computed: {
    ...mapState('festThemes', ['themesInfo']),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),
    articleImageUrl() {
      if (this.article && this.article._embedded['wp:featuredmedia']) {
        const featuredMedia = this.article._embedded['wp:featuredmedia'][0]

        if (featuredMedia.media_details?.sizes?.medium) {
          return featuredMedia.media_details.sizes.medium.source_url
        } else {
          return featuredMedia.source_url
        }
      }

      return null
    },
    festConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(FESTIVAL_KEY_PREFIX)) {
            const itemNameWithoutPrefix = item.key.replace(FESTIVAL_KEY_PREFIX, '');
            const itemName = zapUtilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },
    newsCategoryId() {
      return (typeof this.festConfig?.newsCategoryId !== 'undefined' ) ? this.festConfig.newsCategoryId : 0;
    },
    articleCategoryId() {
      return this.article?.categoryId || null
    },
    articleThemeId() {
      return this.article?.themeId || null
    },
    articleId() {
      return parseInt(this.$route.params.id)
    },
    articleUrl() {
      return window.location.href
    },
    articleTitle() {
      return this.article?.title?.rendered
    },
    articleText() {
      return this.article?.excerpt?.rendered
    },
    articleThemeTitle() {
      return this.themesInfo.find((theme) => theme?.id === this.articleThemeId)?.title
    }
  },
  watch: {
    applicationConfigLoaded(value) {
      if (value && this.newsCategoryId !== 0) {
        this.getRelatedArticles()
      }
    },
    '$route' (to, from){
      if (from.name === ROUTE_NEWS_DETAIL && to.name === ROUTE_NEWS_DETAIL) {
        this.article = null;
        this.relatedArticles = [];

        this.getArticleById(to.params.id);
        if (this.newsCategoryId !== 0) {
          this.getRelatedArticles()
        }
      }
    }
  },
  created() {
    this.getArticleById(this.articleId)
    if (this.newsCategoryId !== 0) {
      this.getRelatedArticles()
    }
    if (!this.themesInfo.length) this.$store.dispatch(`${FEST_THEMES}/${GET_THEMES_INFO}`)
  },
  methods: {
    getArticleById(id) {
      let processId = utilsGeneral.startAsyncProcess()

      apiCmsContent
        .getArticleById(id)
        .then((response) => {
          this.article = response
        })
        .catch((error) => {
          logger.error(error)
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId);
        })
    },
    getRelatedArticles() {
      let processId = utilsGeneral.startAsyncProcess()

      apiCmsContent
        .getAllArticles(this.newsCategoryId)
        .then((response) => {
          this.fillRelatedArticles(response)
        })
        .catch((error) => {
          logger.error(error)
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId)
        })
    },
    fillRelatedArticles(articles) {
      const related = []

      for (let article of articles) {
        if (article.id !== this.articleId) {
          related.push(article)
        }

        if (related.length > this.relatedArticlesCount) break
      }

      this.relatedArticles = related
    },
  },
}
</script>
