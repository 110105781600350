<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1017-heading
        :header="pageCaption"
      />

      <div class="vue-b-faq-controls">
        <frm1006-button
          v-for="(item, index) in subpages"
          :key="index"
          :icon-right="true"
          :disabled="!applicationConfigLoaded"
          class="vue-faq-item"
          type="internal"
          @buttonClickEvent="goToView(item.id, item.slug)"
        >
          {{ item.title }}
        </frm1006-button>
      </div>

      <div
        class="vue-intro-content"
        v-html="trioPageIntroContent"
      />

      <gen1016-loading-indicator
        :active="!loadingComplete"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1006-info-panel
        v-if="!trioAreLoaded && loadingComplete"
        class="vue-no-data"
        type="neutral"
      >
        {{ $t('error.contentNotAvailable') }}
      </gen1006-info-panel>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';
import { TRIO } from '@/store/store-modules';
import { mapState } from 'vuex';
import router from '@/router';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

export default {
  name: 'TrioDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      loadingInProgress: true,
      hasError: false,
    };
  },

  computed: {
    ...mapState('trio', [
      'trioPages',
      'trioAreLoaded',
      'trioHasError'
    ]),
    ...mapState('dataStore', [
      'contentData'
    ]),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),

    pageId() {
      let tmpPageId = -1;
      if (this.applicationConfigLoaded) {
        let tmpCustomFields = this.applicationConfig.customFields;
        tmpCustomFields.forEach(item => {
          if (item.key === 'TRIO_PAGE_ID') {
            tmpPageId = parseInt(item.value);
          }
        });
      }

      return tmpPageId;
    },

    pageCaption() {
      return this.$route.meta.navbarCaption;
    },

    loadingComplete() {
      return !this.loadingInProgress;
    },

    trioPageIntroContent() {
      if (!this.trioPageIntro) {
        return null;
      }

      return this.trioPageIntro;
    },

    trioPageIntro() {
      if (!this.trioPages.content) {
        return false;
      }

      return this.trioPages.content;
    },

    subpages() {
      if (Object.keys(this.trioPages).length === 0) {
        return false;
      }

      if (Object.prototype.hasOwnProperty.call(this.trioPages, 'items')) {
        return this.trioPages['items'];
      }

      return false;
    }
  },

  watch: {
    pageId() {
      this.fetchTrioData();
    },

    trioHasError(newValue) {
      this.handleHasError(newValue);
    }
  },

  mounted() {
    if (!this.trioAreLoaded) {
      this.fetchTrioData();
    } else {
      this.loadingInProgress = false;
    }

    this.sendStatistics(TRIO);
  },

  methods: {
    fetchTrioData() {
      this.$store.dispatch(STORE_MODULES.TRIO + '/' + ACTIONS_CONSTANTS.FETCH_TRIO_DATA, {
        pageId: this.pageId
      })
        .finally(() => {
          this.loadingInProgress = false;
        })
    },

    goToView(articleId, articleSlug) {
      router.push({ name: 'trio-page', params: { id: articleId, name: articleSlug }});
    },

    handleHasError(hasError) {
      this.hasError = hasError;
    }
  }
}
</script>
