import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

const state = {
  comingFilterCategories: {
    modules: [
      {
        id: 1,
        name: 'Akce',
      },
      {
        id: 2,
        name: 'Forvardino',
      },
    ]
  },
  comingModules: [],
  comingSelectedCategories: {
    modules: []
  },
  comingActiveModule: 0,
  comingAreFiltersActive: false,
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_COMING_MODULES](state, comingModules) {
    state.comingModules = comingModules;
  },

  [MUTATIONS_CONSTANTS.SET_COMING_ACTIVE_MODULE](state, activeModule) {
    state.comingActiveModule = activeModule;
  },

  [MUTATIONS_CONSTANTS.SET_DETAIL_DATA](state, detailData) {
    state.detailData = detailData;
  },

  [MUTATIONS_CONSTANTS.SET_COMING_SELECTED_CATEGORIES](state, selectedCategories) {
    state.comingSelectedCategories.modules = selectedCategories.modules;
  },

  [MUTATIONS_CONSTANTS.SET_COMING_FILTER_ACTIVE](state, filterState) {
    state.comingAreFiltersActive = filterState;
  },
};

const actions = {
  [ACTIONS_CONSTANTS.RESET_ALL_COMING_FILTER]({ commit }) {
    const resettedComingObject = {
      modules: []
    }

    commit(MUTATIONS_CONSTANTS.SET_COMING_SELECTED_CATEGORIES, resettedComingObject);
    commit(MUTATIONS_CONSTANTS.SET_COMING_ACTIVE_MODULE, 0);
    commit(MUTATIONS_CONSTANTS.SET_COMING_FILTER_ACTIVE, false);
  }
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
