<template>
  <div
    class="vue-event-list-item"
    :class="getClassObject"
    @click="goToItemDetail(itemData.id)"
  >
    <div
      v-if="itemData.image"
      class="vue-event-list-item-image-wrapper"
    >
      <div
        v-lazy:background-image="itemData.image"
        class="vue-event-list-item-image"
      />
    </div>
    <div
      v-else
      class="vue-event-list-item-image vue-has-no-image"
    />

    <div
      v-if="itemData.module && isWidget"
      class="vue-event-list-item-badge"
    >
      <gen1004-tag-label>
        {{ getModuleLocaleName(itemData.module) }}
      </gen1004-tag-label>
    </div>

    <div
      class="vue-event-list-item-info"
      :class="{ 'vue-is-bookmarked': itemData.bookmarked }"
    >
      <h3
        class="vue-event-list-item-name"
        :class="{['line-clamp-' + lineClamp]: lineClamp}"
        v-html="itemData.name"
      >
      </h3>

      <div
        v-if="itemData.expired"
        class="vue-event-item-expired"
      >
        {{ $t('marketplace.durationExpired') }}
      </div>

      <p class="vue-event-list-item-metainfo">
        <span
          class="vue-event-list-item-header"
        >
          <span
            v-if="categoryExist"
            class="vue-event-list-item-tag"
          >
            {{ categoryName }}
          </span>

          <span
            v-if="itemData.module && !isWidget && !tagDisable"
            class="vue-event-list-item-tag vue-is-module-name"
          >
            {{ getModuleLocaleName(itemData.module) }}
          </span>
        </span>

        <span
          v-if="itemData.priceFormatted"
          class="vue-event-list-item-price"
        >
          <span
            v-if="itemData.priceOld && itemData?.priceOld !== '0.00' && itemData.priceOld !== itemData.price"
            class="vue-price-old"
          >{{ itemData.priceOld }}</span>
          <span class="vue-price">{{ itemData.price === '0.00' ? $t('marketplace.priceNone') : itemData.priceFormatted }}</span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import * as envConfig from 'env-config';
import router from '@/router';
import utilsGeneral from '@/utils/utils-general';
import Vue from 'vue';
import { LIST_ITEMS_ROUTES } from '@/assets/list-items';
import { mapState } from 'vuex';

export default {
  name: 'Prj1051MarketplaceListItem',
  components: {

  },

  props: {
    itemData: {
      type: Object,
      required: true,
    },
    listItemConfig: {
      type: Object,
      required: true,
    },
    promoted: {
      type: Boolean,
      default: false
    },
    promotedOnlyIcon: {
      type: Boolean,
      default: false
    },
    isWidget: {
      type: Boolean,
      default: false
    },
    module: {
      type: String,
      default: '',
    },
    lineClamp: {
      type: Number
    },
    tagDisable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    ...mapState('marketplace', [
      'marketplaceFilterCategories'
    ]),

    cmsUrl() {
      return envConfig.default.webServices.BASE_CMS_URL;
    },

    categoryExist() {
      return (typeof this.itemData.categoryId !== 'undefined');
    },

    categoryName() {
      return this.itemData?.categoryName;
    },

    getClassObject() {
      return {
        ['vue-is-promoted']: this.promoted,
        ['vue-has-promoted-icon']: this.promotedOnlyIcon,
        ['vue-is-widget']: this.isWidget,
        ['vue-is-' + this.module]: this.module,
      }
    }
  },

  methods: {
    getDetailName() {
      for (const listItem of LIST_ITEMS_ROUTES) {
        if (listItem.module === this.module) {
          return listItem.routeDetail;
        }
      }
    },

    goToItemDetail(itemId) {
      this.$emit('goToDetailPrev', itemId);

      if (this.module) {
        const detailNameRoute = this.getDetailName();
        return router.push({name: detailNameRoute, params: {id: itemId, historyBack: true}});
      }

      this.$emit('goToDetail', itemId);
    },

    getModuleLocaleName(module) {
      return this.$i18n.t('promoted.' + module);
    },

    filterHourFromDate(date) {
      return Vue.filter('convertDate')(date, 'hour') + ':' + Vue.filter('convertDate')(date, 'minutes');
    },

    isToday(compareDate) {
      const now = new Date();
      return now.getFullYear() === compareDate.getFullYear()
        && now.getMonth() === compareDate.getMonth()
        && now.getDate() === compareDate.getDate();
    },

    isTomorrow(compareDate) {
      const now = new Date();
      return now.getFullYear() === compareDate.getFullYear()
        && now.getMonth() === compareDate.getMonth()
        && (now.getDate() + 1) === compareDate.getDate();
    },

    convertDate(date) {
      let dateObject = utilsGeneral.sqlDateTimeToDate(date);
      let convertedTime = this.filterHourFromDate(date);
      let result = '';

      if (this.isToday(dateObject)) {
        result += 'dnes ';
      } else if (this.isTomorrow(dateObject)) {
        result += 'zítra ';
      } else {
        result += '' + utilsGeneral.localeDateWidgetString(dateObject) + ' ';
      }
      result += '' + this.$i18n.t('time.hourShortStrong', {hours: convertedTime}) + '';

      return result;
    },
  }
}
</script>
