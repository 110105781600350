<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="navigateBack"
      />
    </template>

    <template v-slot:header>
      <prj1014-event-detail-header
        :eventData="eventData"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        v-if="!isLoading && !loadingFailed"
        class="vue-event-detail-content"
      >
        <prj1017-heading
          ref="forvardinoTop"
          :detailheader="eventData.name"
        />

        <prj1030-date-list>
          <!-- TODO MBU: use props instead of slots, renderLessonString used on forvardino might work here as well-->
          <prj1028-day-time>
            <template v-slot:day>
              {{ $t('time.days.' + eventData.dayShort) }} {{ eventData.activityFrom | convertDate('day') }}. {{ $t('inflection.months.2.' + eventData.monthName) }}
            </template>
            <template v-slot:time>
              {{ eventData.activityFrom | convertDate('exactHours') }} - {{ eventData.activityTo | convertDate('exactHours') }}
            </template>
          </prj1028-day-time>
        </prj1030-date-list>

        <div class="vue-event-detail-places-left">
          <template v-if="eventData.capacity != 0">
            {{ $t('actions.availablePlaces', { availablePlaces: getAvailablePlaces, totalPlaces: eventData.capacity }) }}
          </template>
          <template v-else>
            {{ $t('actions.noRegistration') }}
          </template>
        </div>

        <div class="vue-spots">
          <template
            v-for="(item, index) in eventData.spots"
          >
            <div
              v-if="isSpotOnline(item.name)"
              :key="index"
              class="vue-spot vue-is-online"
            >
              <span class="vue-name">{{ $i18n.t('general.online') }} - </span>
              <a
                class="vue-external-link-blue"
                :href="item.value"
                target="_blank"
              >
                {{ $i18n.t('general.streamUrl') }}
              </a>
            </div>
            <div
              v-else
              :key="index"
              class="vue-spot"
            >
              <span
                class="vue-name"
                :class="spotClassObject(item.name)"
              >
                {{ item.name }}
              </span> - <span class="vue-value">{{ item.value }}</span>
            </div>
          </template>
        </div>

        <div
          v-if="eventData.attachment"
          class="vue-docs"
        >
          <div class="vue-doc">
            <span class="vue-name">{{ $i18n.t('general.attachment') }} - </span>
            <a
              :href="attachmentUrl(eventData)"
              class="vue-external-link-blue"
              target="_blank"
            >
              {{ $i18n.t('general.externalAttachment') }}
            </a>
          </div>
        </div>

        <div
          class="vue-event-detail-text"
          v-html="eventData.description"
        />

        <div
          v-if="eventData.speakers.length"
          class="vue-event-speakers"
        >
          <prj1021-profile-card
            v-for="(speaker, index) in eventData.speakers"
            :key="'lector-' + index"
            :fullName="speaker.name"
            :avatar="speaker.image"
            :profession="$i18n.t('general.speaker')"
          />
        </div>

        <div
          v-if="eventData.organizers.length"
          class="vue-event-organizers"
        >
          <prj1021-profile-card
            v-for="(organizer, index) in eventData.organizers"
            :key="'organizer-' + index"
            :fullName="organizer.name"
            :avatar="organizer.image"
            :profession="$i18n.t('general.organizer')"
          />
        </div>
      </div>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
      <!-- This template condition ensures correct evaluation of slot present -->
      <template v-if="!isLoading && !loadingFailed && (!isRegistered && parseInt(eventData.capacity) > 0) || isRegistered">
        <frm1006-button
          v-if="!isLoading && !isRegistered"
          class="vue-button-register vue-button-page-layout-fixed"
          :disabled="parseInt(eventData.capacity) < 1 || isExpired"
          @buttonClickEvent="registerDialog"
        >
          {{ $t('actions.register') }}
        </frm1006-button>

        <frm1006-button
          v-if="!isLoading && isRegistered"
          class="vue-button-register vue-button-page-layout-fixed"
          @buttonClickEvent="unregisterDialog"
        >
          {{ $t('actions.registrationCancel') }}
        </frm1006-button>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
        :active.sync="showRegisterDialogModal"
      >
        <div
          class="vue-event-detail-register"
        >
          <h3 v-html="$t('actions.eventRegistrationConfirmTitle', { 'name': eventData?.name })"></h3>

          <gen1006-info-panel
            type="neutral"
            class="vue-disclaimer"
          >
            {{ $i18n.t('actions.eventRegistrationNote') }}
          </gen1006-info-panel>

          <frm1006-button
            type="secondary"
            @buttonClickEvent="cancelRegisterDialog()"
          >
            {{ $t('actions.forms.cancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            @buttonClickEvent="submitRegisterDialog"
          >
            {{ $t('actions.forms.confirm') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>

      <gen1009-overlay
        :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
        :active.sync="showUnregisterDialogModal"
      >
        <div
          class="vue-event-detail-register"
        >
          <h3 v-html="$t('actions.eventUnregistrationConfirmTitle', { 'name': eventData?.name })"></h3>

          <frm1006-button
            type="secondary"
            @buttonClickEvent="cancelUnregisterDialog()"
          >
            {{ $t('actions.forms.cancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            @buttonClickEvent="submitUnregisterDialog"
          >
            {{ $t('actions.forms.confirm') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

import { ERROR_MODAL_TIMEOUT, EVENT_SPOT_ONLINE, EVENT_SPOT_NHQ, EVENT_SPOT_SHQ } from '@/constants/app-constants';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1014EventDetailHeader from '@/components/prj1014-event-detail/prj1014-event-detail-header';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import Prj1021ProfileCard from '@/components/prj1021-profile-card/prj1021-profile-card';
import Prj1028DayTime from '@/components/prj1028-day-time/prj1028-day-time';
import Prj1030DateList from '@/components/prj1030-date-list/prj1030-date-list';
import router from '@/router';

export default {
  name: 'EventsDetail',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1014EventDetailHeader,
    Prj1017Heading,
    Prj1021ProfileCard,
    Prj1028DayTime,
    Prj1030DateList
  },

  mixins: [
    mxNavigationDefault,
    mxDetectDesktop
  ],

  data() {
    return {
      isLoading: true,
      loadingFailed: false,
      hasError: false,
      eventFetched: {},
      showRegisterDialogModal: false,
      showUnregisterDialogModal: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    ...mapState('events', [
      'events',
      'detailData'
    ]),

    pageId() {
      return this.$route.params.id
    },

    historyBack() {
      return !!this.$route.params.historyBack;
    },

    getAvailablePlaces() {
      if (!this.isLoading) {
        return this.eventData.capacity - this.eventData.occupancy;
      }

      return -1;
    },

    isRegistered() {
      return this.eventData?.isRegistered;
    },

    isExpired() {
      return (new Date().getTime() > new Date(this.eventData.activityTo.replace(' ', 'T')).getTime());
    },

    eventCached() {
      return this.detailData;
    },

    eventData() {
      if (Object.keys(this.eventCached).length) {
        return this.eventCached;
      }
      return this.eventFetched;
    },
  },

  created() {
    if (this.eventCached?.id !== this.pageId) {
      this.fetchEvent()
    } else {
      this.isLoading = false;
    }
  },

  methods: {
    fetchEvent() {
      dataLoader.fetchEvent(this.pageId)
        .then(data => {
          this.eventFetched = data.detail;
          this.$store.commit(STORE_MODULES.EVENTS + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, this.eventFetched);
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
          this.loadingFailed = true;
          this.isLoading = false;
        })
    },

    registerDialog() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.showRegisterDialogModal = true;
    },

    submitRegisterDialog() {
      this.sendRegister();
    },

    cancelRegisterDialog() {
      this.showRegisterDialogModal = false;
    },

    sendRegister() {
      this.isLoading = true;

      dataLoader.sendEventApplication(this.pageId)
        .then(() => {
          router.push({ name: 'events',
            params: {
              successfullRegistration: true,
              registrationMessage: 'registrationSucessfull'
            }
          });
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
          this.isLoading = false;
        });
    },

    unregisterDialog() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.showUnregisterDialogModal = true;
    },

    submitUnregisterDialog() {
      this.sendUnregister();
    },

    cancelUnregisterDialog() {
      this.showUnregisterDialogModal = false;
    },

    sendUnregister() {
      this.isLoading = true;

      dataLoader.cancelEventApplication(this.pageId)
        .then(() => {
          router.push({ name: 'events',
            params: {
              successfullRegistration: true,
              registrationMessage: 'unregistrationSucessfull'
            }
          });
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
          this.isLoading = false;
        });
    },

    isSpotOnline(name) {
      return (name === EVENT_SPOT_ONLINE);
    },

    spotClassObject(name) {
      return {
        'vue-is-uppercase': (name === EVENT_SPOT_NHQ || name === EVENT_SPOT_SHQ)
      }
    },

    attachmentUrl(eventData) {
      return globalConfig.BASE_CMS_URL + eventData.attachment;
    },

    navigateBack() {
      if (this.historyBack) {
        router.go(-1);
      }

      if (!this.$route.params.origin) {
        this.$router.push({ name: 'events' })
      } else {
        this.$router.push({ name: this.$route.params.origin})
      }
    },
  }
}
</script>
