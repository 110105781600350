<template>
  <div
    class="vue-c-photo-upload"
  >
    <input
      :id="fileUploadId"
      ref="photoUpload"
      class="vue-image-upload-input"
      type="file"
      name="myImage"
      :accept="acceptFiles.join(',')"
      @change="uploadPhoto"
    >

    <frm1006-button
      class="vue-image-upload-button"
      type="app-button"
      :caption="caption"
      @buttonClickEvent="loadImage"
    />

    <!-- Image edit mode -->
    <div
      v-show="modeEdit"
      class="vue-b-image-upload-cropper"
    >
      <div class="relative mx-auto prj-layout-padding-x w-full sm:w-144 h-full flex flex-col">
        <div
          v-show="!croppedImageConfirmed"
          class="flex-1 flex items-center justify-center relative"
        >
          <VueCropper
            v-show="selectedFile"
            ref="cropper"
            :src="selectedFile"
            :aspectRatio="cropperAspectRatio"
            :zoomable="false"
            :viewMode="Number(3)"
            :autoCropArea="1"
          />
        </div>

        <div
          v-if="!croppedImageConfirmed"
          class="prj-controls flex justify-between relative z-10"
        >
          <frm1006-button
            type="secondary"
            @buttonClickEvent="cancelUpload"
          >
            {{ $t('profile.forms.buttonCancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            @buttonClickEvent="confirmImage"
          >
            {{ $t('profile.forms.buttonConfirm') }}
          </frm1006-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import logger from '@/utils/logger';
import imageOptimalization from '@/utils/imageOptimalization';

import VueCropper from 'vue-cropperjs';

export default {
  name: 'Prj1010ImageUpload',
  components: { VueCropper },

  props: {
    cropper: {
      type: Boolean,
      default: false
    },
    caption: {
      type: String,
      required: false,
      default: ''
    },
    acceptFiles: {
      type: Array,
      default: () => ['image/jpeg']
    },
    //=== Cropper
    croppedImageHeight: { type: Number, default: 512 },
    // must be included in props
    fileSelected: {
      type: null
    },
    //=== ASPECT RATIO
    cropperAspectRatio: { type: Number, default: 1 }
  },

  data() {
    return {
      runAnimation: false,
      name: '',
      // cropper
      fileSelectedData: '',
      selectedFileName: '',
      croppedImageConfirmed: false,
      modeEdit: false,
      croppedImage: '',
      croppedImageBase64: '',
      autoCrop: false,
      selectedFile: ''
    };
  },

  computed: {
    fileUploadId() {
      return 'file-upload-' + this.id;
    }
  },

  watch: {
    croppedImageConfirmed(value) {
      if (value) {
        this.$emit('input', this.croppedImageBase64);
      } else {
        this.$emit('input', '');
      }
    }
  },

  created() {
    if (this.fileSelected) {
      this.fileSelectedData = this.fileSelected;
    }
  },

  methods: {
    uploadPhoto(event) {
      if (this.cropper) {
        return this.uploadCroppedPhoto(event);
      }

      imageOptimalization
        .compressImage(event)
        .then(photoObject => {
          this.$emit('uploadedPhoto', photoObject);
        })
        .catch(error => {
          logger.error(error);
        });
    },

    loadImage() {
      this.$refs.photoUpload.click();
    },

    // cropper
    confirmImage() {
      this.croppedImage = this.$refs.cropper.getCroppedCanvas({
        width: this.croppedImageHeight * this.cropperAspectRatio,
        height: this.croppedImageHeight,
        imageSmoothingQuality: 'high'
      });
      this.croppedImageBase64 = this.croppedImage.toDataURL('image/jpeg');
      this.modeEdit = false;

      this.croppedImageConfirmed = true;
      this.$emit('uploadedPhoto', this.croppedImageBase64);
    },
    uploadCroppedPhoto(event) {
      if (this.croppedImageConfirmed) {
        this.cancelUpload();
      }
      const file = event.target.files[0]

      if (file) {
        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = event => {
            this.selectedFileName = file.name;
            this.selectedFile = event.target.result;
            this.$refs.cropper.replace(this.selectedFile);
            this.modeEdit = true;
          };
          reader.readAsDataURL(file);
        } else {
          alert('Sorry, FileReader API not supported');
        }
      } else {
        this.cancelUpload();
      }

      this.resetFileInput();
    },
    cancelUpload() {
      this.selectedFile = '';
      this.$refs.cropper.replace(null);
      this.modeEdit = false;
      this.croppedImageConfirmed = false;
    },
    resetFileInput() {
      if (this.$refs.photoFileUpload) this.$refs.photoFileUpload.value = ''; // Reset for re-upload same photo
    }
  }
};
</script>
