<template>
  <div
    id="app"
    class="vue-page-layout vue-building-background vue-building-background-hidden-phone"
    :class="classObject"
    :style="generateBackground"
  >
    <!-- TODO MBU: v-if="!appInitializing" to solve page transtions on page-layout? -->
    <!-- alternative is to create global event on init -->
    <router-view />
    <prj1005-navigation />
    <prj1011-popup
      v-if="isPopupVisible && !isTutorialVisible"
      :active="isPopupVisible"
      :menuData="categories"
      menuType="category"
    />

    <gen1009-overlay
      :active="appUpdaterActive && swInstance !== null && !isTutorialVisible"
      class="vue-update-overlay"
      preventClosing="all"
    >
      <template v-if="appUpdating">
        <p>{{ $t('general.updatingApp') }}</p>
        <gen1016-loading-indicator />
      </template>
      <template v-else>
        <p>
          {{ $t('general.updateAvailable') }}
        </p>
        <div
          v-if="appChangelog"
          class="vue-b-changelog"
          v-html="appChangelog"
        />
        <frm1006-button
          @buttonClickEvent="updateApplication"
        >
          {{ $t('general.update') }}
        </frm1006-button>
      </template>
    </gen1009-overlay>

    <gen1009-overlay :active="showPushRegistrationTip && !isTutorialVisible">
      <template v-if="subscribingPushNotifications">
        <p>{{ $t('general.subscribingPushNotifications') }}</p>
        <gen1016-loading-indicator />
      </template>
      <template v-else>
        <h1 class="vue-overlay-heading">
          {{ $t('general.pushAvailableHeading') }}
        </h1>
        <p>
          {{ $t('general.pushAvailableContent') }}
        </p>
        <frm1006-button
          :disabled="pushNotificationsSubscribed"
          @buttonClickEvent="subscribePushNotifications"
        >
          {{ $t('general.registerPushNotifications') }}
        </frm1006-button>
        <frm1006-button
          type="secondary"
          @buttonClickEvent="postponePushNotifications"
        >
          {{ $t('general.notNow') }}
        </frm1006-button>
      </template>
    </gen1009-overlay>

    <gen1009-overlay :active="networkError">
      <h1 class="vue-overlay-heading">
        {{ $t('general.networkUnstable') }}
      </h1>
      <p>{{ $t('general.contentPossiblyOutdated') }}</p>
      <frm1006-button
        type="primary"
        @buttonClickEvent="confirmNetworkProblems"
      >
        {{ $t('general.acknowledge') }}
      </frm1006-button>
    </gen1009-overlay>

    <gen1009-overlay
      :active="isInMaintenance"
      preventClosing="all"
      class="vue-maintenance-overlay vue-is-full-screen"
    >
      <img
        class="vue-zapka-logo"
        alt="Zapka Logo"
        src="../public/img/logo/intro-logo-with-branding.svg"
      >
      <h1 class="vue-overlay-heading">
        {{ $t('general.maintenanceHeading') }}
      </h1>
      <p>{{ $t('general.maintenanceContent') }}</p>
      <gen1016-loading-indicator :active="appConfigReloading" />
    </gen1009-overlay>

    <gen1009-overlay
      class="vue-is-position-bottom"
      :active.sync="showInstallReminderModal"
    >
      <prj1035-pwa-installer
        @closePwaInstallerOverlay="closeInstallReminderModal"
      />
    </gen1009-overlay>

    <gen1009-overlay
      :active="appUpdateFailedActive && !isTutorialVisible"
      class="vue-update-overlay"
      preventClosing="all"
    >
      {{ $t('general.appUpdateFailed') }}

      <frm1006-button
        @buttonClickEvent="manuallyRefreshApp"
      >
        {{ $t('userSettings.restartApp') }}
      </frm1006-button>
      <frm1006-button
        @buttonClickEvent="cancelUpdate"
      >
        {{ $t('userSettings.continueWithOldVersion') }}
      </frm1006-button>
    </gen1009-overlay>

    <!-- TODO MSE: this is just a temporary solution until global loaders are introduced - CSOBZ-324 -->
    <div
      v-if="appInitializing"
      class="vue-app-loading"
    >
      <gen1016-loading-indicator
        :active="true"
      />
    </div>
  </div>
</template>

<script>
import logger from './utils/logger';
import { mapState, mapGetters } from 'vuex';

import EVENT_BUS from '@/event-bus';
import {APP_DIRECTORY_KEY_PREFIX, ERROR_MODAL_TIMEOUT} from '@/constants/app-constants';
import { APP_DESTINATIONS } from '@/constants/general';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATION_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import * as STRING_VALUES from '@/store/string-values';
import '../themes/theme-zapp-2/css/profile-default/profile/profile.scss';

import Prj1005Navigation from '@/components/prj1005-navigation/prj1005-navigation';
import Prj1011Popup from '@/components/prj1011-popup/prj1011-popup';
import Prj1035PwaInstaller from '@/components/prj1035-pwa-installer/prj1035-pwa-installer';

import utilsGeneral from './utils/utils-general';
import UtilsBrowser from '@/utils/utils-browser';
import * as envConfig from 'env-config';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import zapUtilsGeneral from '@/utils/utils-general';

export default {
  name: 'ZapkaApp',
  components: {
    Prj1005Navigation,
    Prj1011Popup,
    Prj1035PwaInstaller
  },

  data() {
    return {
      appInitializing: true,
      appConfigReloading: false,
      appUpdating: false,
      appUpdaterActive: false,
      appUpdateFailedActive: false,
      subscribingPushNotifications: false,
      currentDateTime: new Date(),
      workarounds: {
        iOSOrientationTimeout: null,
        iOSLayoutSelector: 'vue-page-layout'
      },
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      showInstallReminderModal: false,
      orientation: this.ORIENTATION_LANDSCAPE,
      allowedZeroPaddingLayout: [
        'login',
        'verify-code',
        'registration',
        'registration-parents',
        'dashboard',
        'restaurants',
        'restaurantsDetail',
        'restaurants-pictograms',
        'restaurants-info',
        'pages',
        'news',
        'news-list',
        'news-comments',
        'news-comment-add',
        'search',
        'recruitment-dashboard',
        'recruitment-search',
        'recruitment-detail',
        'recruitment-detail-form',
        'recruitment-detail-form-refer',
        'recruitment-listing',
        'recruitment-listing-form',
        'events',
        'event-detail',
        'event-registration',
        'forvardino-dashboard',
        'forvardino-list',
        'forvardino-detail',
        'forvardino-lector',
        'forvardino-registration',
        'forvardino-course-interest',
        'forvardino-course-rating',
        'forvardino-info',
        'forvardino-info-page',
        'forvardino-course-manager',
        'fault-reporting',
        'strategy',
        'strategy-detail',
        'strategy-competition-intro',
        'strategy-competition-registration',
        'strategy-competition',
        'strategy-competition-summary',
        'strategy-main-objectives',
        'workouts',
        'workout-detail',
        'workout-registration',
        'nps',
        'nps-info',
        'nps-detail',
        'nps-feedback',
        'user-settings',
        'covid-19',
        'covid-19-faq',
        'covid-19-form',
        'covid-19-form-info',
        'trio',
        'trio-page',
        'week-actions',
        'survey-intro',
        'survey-form',
        'survey-thank-you',
        'annual-report',
        'parking',
        'parking-finish',
        'parking-form',
        'auction',
        'auction-detail',
        'auction-conditions',
        'advent',
        'advent-detail',
        'help',
        'help-article',
        'annual-innovation-report',
        'annual-innovation-report-kate',
        'annual-innovation-report-document',
        'dock-dell',
        'dock-dell-detail',
        'benefits',
        'benefits-detail',
        'motor-dashboard',
        'motor-page',
        'motor-kpi',
        'motor-kpi-detail',
        'parents-dashboard',
        'parents-category',
        'parents-page',
        'parents-info-page',
        'parents-faq-page',
        'parents-program-registration',
        'parents-pool-login',
        'parents-babysitting',
        'hhq-building',
        'hhq-building-start',
        'building-info-page',
        'building-category',
        'building-page',
        'building-intro-page',
        'sustainability',
        'sustainability-page',
        'sustainability-about',
        'sustainability-stories',
        'sustainability-story',
        'sustainability-new-story',
        'sustainability-new-story-success',
        'sustainability-news',
        'sustainability-news-detail',
        'sustainability-targets',
        'sustainability-themes',
        'sustainability-theme-detail',
        'sustainability-topic-detail',
        'sustainability-challenges',
        'sustainability-challenge-detail',
        'sustainability-challenge-history',
        'sustainability-challenge-results',
        'sustainability-challenge-success',
        'sustainability-competitions',
        'sustainability-competition-detail',
        'sustainability-competition-result',
        'ideas-sending',
        'onboarding-dashboard',
        'onboarding-external-candidates',
        'onboarding-external-candidate',
        'onboarding-applicants',
        'onboarding-recruiter',
        'onboarding-approve-thank',
        'onboarding-applicant-profile',
        'onboarding-applicant-detail',
        'onboarding-perka-personal',
        'onboarding-applicant-documents',
        'onboarding-applicant-document',
        'services-list',
        'services-detail',
        'knowledge-base-dashboard',
        'knowledge-base-category',
        'knowledge-base-detail',
        'surf-studio-dashboard',
        'surf-studio-news',
        'surf-studio-voice',
        'surf-studio-voice-page',
        'surf-studio-events',
        'surf-studio-events-info',
        'surf-studio-creative-power-menu',
        'surf-studio-creative-power-menu-info',
        'surf-studio-trendbook',
        'surf-studio-surf-kemp',
        'surf-studio-surf-kemp-info',
        'chatbot-hr',
        'chatbot-hr-chat',
        'chatbot-hr-form',
        'emergency-dashboard',
        'emergency-contacts',
        'emergency-information',
        'emergency-page-detail',
        'emergency-building-plans',
        'emergency-building-plan-detail',
        'emergency-crisis-situation',
        'emergency-crisis-situation-detail',
        'festival',
        'festival-themes',
        'festival-theme-detail',
        'festival-topic-detail',
        'festival-targets',
        'festival-challenges',
        'festival-challenge-detail',
        'festival-challenge-success',
        'festival-challenge-history',
        'festival-challenge-results',
        'festival-news',
        'festival-news-detail',
        'festival-stories',
        'festival-story',
        'festival-new-story',
        'festival-new-story-success',
        'festival-transport',
        'festival-transport-success',
        'festival-vote',
        'festival-vote-detail',
        'festival-vote-success',
        'festival-vote-results',
        'festival-page',
        'profile',
        'profile-edit-name',
        'profile-cancel-account',
        'promoted-list',
        'coming-list',
        'messages',
        'marketplace',
        'marketplace-info',
        'marketplace-rules',
        'marketplace-list',
        'marketplace-list-saved',
        'marketplace-list-my',
        'marketplace-form',
        'marketplace-detail',
        'education-dashboard',
        'directory-list',
      ],
      allowedFullscreenLayout: [
        'annual-innovation-report-kate',
        'annual-innovation-report-document',
      ],
      enabledScrolling: [
        'recruitment',
        'recruitment-detail-form-refer',
        'recruitment-detail-form',
        'recruitment-listing-form',
        'recruitment-listing',
        'strategy-competition-intro',
        'strategy-competition-summary',
        'strategy-detail',
        'strategy-competition',
        'strategy-main-objectives'
      ],
      pushNotificationsTemporaryDisabled: true,
    }
  },

  computed: {
    ...mapState('persistentStorage', [
      'pushHintPostponedUntil'
    ]),
    ...mapState('user', [
      'isLogged',
      'deviceId',
      'platform',
      'installReminderTimestamp',
      'isStandalone',
      'amUserProfile'
    ]),
    ...mapState('dashboard', [
      'selectedBuilding',
      'dashboardBackground'
    ]),
    ...mapState('general', [
      'appUpdateReady',
      'appUpdateFailed',
      'swRegistration',
      'swInstance',
      'swInstanceWaiting',
      'appChangelog',
      'appDestination',
      'isMenuOpened',
      'applicationConfig',
      'applicationConfigLoaded',
      'navigationData',
      'pushNotificationsSubscribed',
      'pushNotificationsEndpoint',
      'pushNotificationsPermission',
      'networkError'
    ]),
    ...mapState('popup', [
      'isPopupVisible',
      'categories'
    ]),
    ...mapState('tutorial', [
      'isTutorialVisible'
    ]),
    ...mapState('articles', [
      'newsActiveCategory'
    ]),
    ...mapGetters('general', {
      'pushBrowserSupport': GETTERS_CONSTANTS.PUSH_BROWSER_SUPPORT
    }),
    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY
    }),

    isInMaintenance() {
      if(this.applicationConfig && typeof this.applicationConfig.customFields === 'object' && this.applicationConfig.customFields.length) {
        // TODO MSE: create getter providing converted key-based custom fields and replace forEach here
        for(let item of this.applicationConfig.customFields) {
          if(item.key === 'SERVICE_MAINTENANCE') {
            return item.value === 'true'; // TODO MSE: upgrade custom fields to provide values in proper data-types, not strings only
          }
        }
      }
      return false;
    },

    classObject() {
      return {
        'vue-zero-padding-layout': this.allowedZeroPaddingLayout.includes(this.currentRoute),
        'vue-fullscreen-layout': this.allowedFullscreenLayout.includes(this.currentRoute),
        'vue-is-scroll': this.enabledScrolling.includes(this.currentRoute),
        'vue-restaurants-detail-layout': this.$route.name === 'restaurants-detail',
        [`vue-is-${this.orientation}`]: this.orientation,
        [`vue-is-${this.platform}`]: this.platform,
        'vue-is-unsupported-browser': !this.usesSupportedBrowser
      }
    },

    currentRoute() {
      return this.$route.name;
    },

    showPushRegistrationTip() {
      if(!this.pushBrowserSupport || !this.swInstance || !this.isLogged || this.pushNotificationsSubscribed === null || this.pushNotificationsTemporaryDisabled) {
        return false;
      }

      let currentTimeSecs = Math.floor(new Date().getTime() / 1000);
      let postponed = false;

      if(this.pushHintPostponedUntil) {
        postponed = currentTimeSecs < this.pushHintPostponedUntil;
      }

      return !this.appUpdating && !this.pushNotificationsSubscribed && this.pushNotificationsPermission !== 'denied' && !postponed;
    },

    isBuildingSet() {
      return typeof this.selectedBuilding !== 'undefined';
    },
    generateBackground() {
      return !this.dashboardBackground ? '' : `background-image: url(${envConfig.default.webServices.BASE_CMS_URL + this.dashboardBackground}`;
    },

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },

    appDirectoryConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(APP_DIRECTORY_KEY_PREFIX)) {
            const itemNameWithoutPrefix = item.key.replace(APP_DIRECTORY_KEY_PREFIX, '');
            const itemName = zapUtilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },

    appDirectoryDisabled() {
      return (typeof this.appDirectoryConfig?.disabled !== 'undefined' ) ? this.appDirectoryConfig.disabled : true;
    },
  },

  watch: {
    swInstance() {
      this.loadPushNotificationsStatus();
    },

    appUpdateReady() {
      logger.info('new release installed');
      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.SET_NEW_VERSION_INSTALLED);
      this.appUpdaterActive = false;
    },

    appUpdateFailed() {
      this.appUpdateFailedActive = this.appUpdateFailed && !this.appUpdaterActive;
    },

    isPopupVisible(value) {
      logger.info('popup active = ' + value);
    },

    hasIdentity(value) {
      if (value) {
        this.loadRequiredData();
      }
    }
  },

  created() {
    this.removeFastResponseDomElements();
    this.detectPlatform();
    this.detectSystemVersion();
    this.detectBrowser();
    this.detectStandalone();
    this.verticalHeightCorrection();
    this.loadPushNotificationsStatus();
    this.generateDeviceId();

    if (!this.newsActiveCategory) {
      this.$store.commit(STORE_MODULES.ARTICLES + '/' + MUTATIONS_CONSTANTS.SET_NEWS_ACTIVE_CATEGORY, 0);
    }

    window.addEventListener('resize', this.onWindowResize);
    document.addEventListener('visibilitychange', this.onDocumentVisibilityChange);
    document.addEventListener('orientationchange', this.onOrientationChange);

    this.loadRequiredData();

    logger.info('LOGGER => Zapka has been loaded');
  },

  mounted() {
    this.calculateViewportSize();
    this.detectScreenOrientation();
    this.openInstallerPrompt();
    this.$store.dispatch(STORE_MODULES.PERSISTENT_STORAGE + '/' + ACTIONS_CONSTANTS.GENERATE_YEAR_AND_WEEK);
    this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATION_CONSTANTS.SET_APP_VERSION, this.getVersionFromHTML());
    this.$store.commit(STORE_MODULES.TUTORIAL + '/' + MUTATIONS_CONSTANTS.TOGGLE_TUTORIAL, false);
    this.$store.commit(STORE_MODULES.TUTORIAL + '/' + MUTATIONS_CONSTANTS.SET_TUTORIAL_IS_COMPLETED, this.$store.state.tutorial.tutorials.dashboardInit);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
    document.removeEventListener('visibilitychange', this.onDocumentVisibilityChange);
    document.removeEventListener('orientationchange', this.onOrientationChange);
  },

  methods: {
    async loadRequiredData() {
      if (!this.isBuildingSet) {
        this.$store.commit(STORE_MODULES.DASHBOARD + '/' + MUTATIONS_CONSTANTS.SET_SELECTED_BUILDING, '2');
      }

      await this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.IDENTITY_PROFILE, {force: true});

      if (!this.hasIdentity) {
        this.appInitializing = false;
        return false;
      }

      await this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.IDENTITY_PREFERENCES, {force: true});
      // Temporary
      if (this.pushNotificationsSubscribed) {
        this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.IDENTITY_PUSH_NOTIFICATION_SUB, [
          this.pushNotificationsEndpoint
        ]);
      }

      if(this.appDestination === APP_DESTINATIONS.PRIVATE) {
        await this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.AM_LOAD_AUTHORIZED_MODE_PROFILE);
      }

      await this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.GET_APPLICATION_SETTINGS, {
        selectedBuilding: (this.isBuildingSet ? this.selectedBuilding : '2'),
        force: true
      })

      this.$store.dispatch(STORE_MODULES.DASHBOARD + '/' + ACTIONS_CONSTANTS.FETCH_DASHBOARD_PROMOTED, {force: false, locality: (this.isBuildingSet ? this.selectedBuilding : '2'), module: 'any', offset: 0, limit: 20});
      this.$store.dispatch(STORE_MODULES.DASHBOARD + '/' + ACTIONS_CONSTANTS.FETCH_DASHBOARD_COMING, {force: false, type: 0, offset: 0, amount: 50});
      this.$store.dispatch(STORE_MODULES.DASHBOARD + '/' + ACTIONS_CONSTANTS.FETCH_DASHBOARD_NEWS, {force: false, categories: this.newsActiveCategory, offset: 0, amount: 10, loc: (this.isBuildingSet ? this.selectedBuilding : '2'), search: ''});
      if (!this.appDirectoryDisabled) {
        Promise.all([
          this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.FETCH_DIRECTORY_CATEGORIES, {force: false}),
          this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.FETCH_DIRECTORY_ENTITIES, {force: false})
        ])
          .then(() => {
            this.$store.dispatch(STORE_MODULES.DIRECTORY + '/' + ACTIONS_CONSTANTS.FETCH_DIRECTORY_ITEMS, {force: false, params: ['', 'name', 0, 40]});
          });
      }

      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.FETCH_APP_NOTIFICATIONS);

      this.appInitializing = false;
    },

    reloadApplicationConfig() {
      // TODO MSE: possibly debounce in future? Could frequent visibilitychange cause problems?
      this.appConfigReloading = true;
      const settingsPayload = {
        selectedBuilding: (this.isBuildingSet ? this.selectedBuilding : '2'),
        force: true
      }

      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.GET_APPLICATION_SETTINGS, settingsPayload)
        .catch(error => {
          logger.error(error)
        })
        .finally(() => {
          this.appConfigReloading = false;
          this.appInitializing = false;
        });
    },

    detectPlatform() {
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      let android = /(android)/i.test(navigator.userAgent);
      let platform = STRING_VALUES.PLATFORM_OTHER;

      if(iOS) {
        platform = STRING_VALUES.PLATFORM_IOS;
      }

      else if(android) {
        platform = STRING_VALUES.PLATFORM_ANDROID;
      }

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATION_CONSTANTS.SET_PLATFORM, platform)
    },

    detectSystemVersion() {
      let version = STRING_VALUES.SYSTEM_VERSION_UNKNOWN;

      if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        let v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        version = parseInt(v[1], 10) + '.' + parseInt(v[2], 10) + '.' + parseInt(v[3] || 0, 10);
      }

      else if(/android\s([0-9.]*)/.test(navigator.userAgent.toLowerCase())) {
        let match = navigator.userAgent.toLowerCase().match(/android\s([0-9.]*)/i);
        version = match ? match[1] : STRING_VALUES.SYSTEM_VERSION_UNKNOWN;
      }

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATION_CONSTANTS.SET_SYSTEM_VERSION, version)
    },

    detectBrowser() {
      let userAgentConstructor = new UtilsBrowser();
      let userAgent = userAgentConstructor.init();

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATION_CONSTANTS.SET_BROWSER, userAgent.browser.name);
    },

    detectStandalone() {
      let isInStandaloneModeIOS = ('standalone' in window.navigator) && (window.navigator.standalone);
      let isInStandaloneModeAndroid = (window.matchMedia('(display-mode: standalone)').matches);
      let isStandalone = isInStandaloneModeIOS || isInStandaloneModeAndroid;

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATION_CONSTANTS.SET_IS_STANDALONE, isStandalone)
    },

    /*
     * We need to fix orientation in JS for following reasons:
     * - iOS PWA does not support manifest orientation
     * - app running in web browser
     * - in future, when supported, there is a possibility of use https://developer.mozilla.org/en-US/docs/Web/API/Screen/lockOrientation#Browser_compatibility
     *
     * Current solution is based on:
     * https://stackoverflow.com/questions/1207008/how-do-i-lock-the-orientation-to-portrait-mode-in-a-iphone-web-application
     * https://stackoverflow.com/questions/5298467/prevent-orientation-change-in-ios-safari
     */

    detectScreenOrientation() {
      this.orientation = screen.width > screen.height ? STRING_VALUES.ORIENTATION_LANDSCAPE : STRING_VALUES.ORIENTATION_PORTRAIT;

      if (this.platform === STRING_VALUES.PLATFORM_IOS) {
        this.orientation = this.detectOrientation();

        /**
         * WORKAROUND: iOS recalculates values with some delay, but not synchronously and breaks
         * vh CSS variable. We must recalculate after some time (500ms is enough).
         * This occurs ONLY in PWA mode, in Safari problem does not exist. Remove, when
         * iOS fixes issue.
         * Inspired by: https://github.com/dimsemenov/PhotoSwipe/issues/1315
         */
        clearTimeout(this.workarounds.iOSOrientationTimeout);

        this.workarounds.iOSOrientationTimeout = setTimeout(() => {
          this.verticalHeightCorrection();
        }, 500);
      }
    },

    openInstallerPrompt() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
          .then(() => {
            if (this.platform !== STRING_VALUES.PLATFORM_OTHER && !this.isStandalone && this.currentDateTime.getTime() > this.installReminderTimestamp && !this.isPrivateApp) {
              this.showInstallReminderModal = true;
              this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.MAKE_INSTALL_REMINDER_TIMESTAMP);
            }
          });
      }
    },

    /**
     * WORKAROUND: iOS recalculates values with some delay, but not synchronously and breaks
     * vh CSS variable. We must recalculate after some time (500ms is enough).
     * This occurs ONLY in PWA mode, in Safari problem does not exist. Remove, when
     * iOS fixes issue.
     * Inspired by: https://github.com/dimsemenov/PhotoSwipe/issues/1315
     */

    fixIOSCSSHeightCalculation() {
      if (this.platform === STRING_VALUES.PLATFORM_IOS) {
        setTimeout(() => {
          this.verticalHeightCorrection();
        }, 500);
      }
    },

    verticalHeightCorrection() {
      // used to fix iOS Safari PWA mode wrong property calculation
      if (this.platform === STRING_VALUES.PLATFORM_IOS) {
        let vh = window.innerHeight * 0.01;
        try {
          document.getElementsByClassName(this.workarounds.iOSLayoutSelector)[0].style.setProperty('--vh', `${vh}px`);
        } catch(err) {
          logger.error(err);
        }
      }

      this.verticalHeight = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${this.verticalHeight}px`);
    },

    detectOrientation() {
      return (window.orientation % 180 !== 0) ? STRING_VALUES.ORIENTATION_LANDSCAPE : STRING_VALUES.ORIENTATION_PORTRAIT;
    },

    /**
     * Gets version from data attribute version on document <head>
     *
     * @return {string} version of application printed in HTML
     */
    getVersionFromHTML() { /*TODO need to implement application updates, compare versions and get the latest version of the application, etc.*/
      let head = document.head || document.getElementsByTagName('head')[0];
      return head.dataset.version;
    },

    updateApplication() {
      if (this.swInstanceWaiting) {
        this.appUpdating = true;
        this.swInstanceWaiting.postMessage({ action: 'skipWaiting' });
      }
    },

    cancelUpdate() {
      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATION_CONSTANTS.SET_APP_UPDATE_FAILED, false);
    },

    calculateViewportSize() {
      let viewportSize = {
        'windowHeight': window.innerHeight,
        'windowWidth': window.innerWidth,
        'height': document.getElementById('app').offsetHeight,
        'width': document.getElementById('app').offsetWidth,
        'correctionalConstant': window.innerHeight * 0.01,
      };

      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATION_CONSTANTS.SET_VIEWPORT_SIZE, viewportSize);
      EVENT_BUS.$emit(GLOBAL_EVENTS.RESIZE_EVENT);
      EVENT_BUS.$emit(GLOBAL_EVENTS.CLOSE_DRAWER);
    },

    postponePushNotifications() {
      this.$store.commit(STORE_MODULES.PERSISTENT_STORAGE + '/' + MUTATION_CONSTANTS.POSTPONE_PUSH_NOTIFICATIONS);
    },

    subscribePushNotifications() {
      this.subscribingPushNotifications = true;
      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.TOGGLE_PUSH_NOTIFICATIONS, true)
        .catch(error => {
          logger.error(error);
        })
        .finally(() => {
          this.subscribingPushNotifications = false;
        })
    },

    loadPushNotificationsStatus() {
      if(this.pushBrowserSupport) {
        this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.LOAD_PUSH_NOTIFICATIONS_STATUS)
          .catch(error => {
            logger.error(error);
          })
      }
    },

    generateDeviceId() {
      if (!this.deviceId) {
        this.$store.commit(STORE_MODULES.USER + '/' + MUTATION_CONSTANTS.SET_DEVICE_ID, utilsGeneral.makeDeviceId(36));
      }
    },

    confirmNetworkProblems() {
      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATION_CONSTANTS.SET_NETWORK_ERROR, false);
    },

    onDocumentVisibilityChange() {
      if (document.visibilityState === 'visible') {
        if (this.swRegistration) {
          this.swRegistration.update();
        }
        this.loadRequiredData();
      }
    },

    removeFastResponseDomElements() {
      let criticalLoadingElement = document.getElementById('zap-critical-loading');
      if(criticalLoadingElement) {
        criticalLoadingElement.remove();
      }
    },

    onWindowResize() {
      this.detectScreenOrientation();
      this.verticalHeightCorrection();
      this.calculateViewportSize();
    },

    onOrientationChange() {
      this.detectScreenOrientation();
      this.verticalHeightCorrection();
    },

    openInstallReminderModal() {
      this.showInstallReminderModal = true;
      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.MAKE_INSTALL_REMINDER_TIMESTAMP);
    },

    closeInstallReminderModal() {
      this.showInstallReminderModal = false;
    },

    manuallyRefreshApp() {
      window.location.reload();
    },
  }
}
</script>
