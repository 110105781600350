<template>
  <page-layout>
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <template v-slot:contentMain>
      <prj1017-heading
        :header="pageCaption"
      />

      <div
        class="vue-b-items"
      >
        <frm1006-button
          v-for="(item, index) in dockDellData.items"
          :key="'dock-dell-item-' + index"
          :icon-right="true"
          :disabled="!applicationConfigLoaded"
          class="vue-item"
          type="internal"
          @buttonClickEvent="goToView(item.id, item.slug)"
        >
          {{ item.title }}
        </frm1006-button>
      </div>

      <div
        class="vue-intro-content"
        v-html="pageContent"
      />
    </template>

    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import dataLoader from '@/utils/data-loader';
import router from '@/router';
import logger from '@/utils/logger';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

export default {
  name: 'DockDellDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      dockDellData: {},
      isLoading: true,
      hasError: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),

    pageId() {
      let pageId = -1;

      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'DOCKDELL_PAGE_ID') {
            pageId = parseInt(item.value);
          }
        });
      }

      return pageId;
    },

    pageCaption() {
      return this.$route.meta.navbarCaption;
    },

    pageContent() {
      if (!this.dockDellData?.content) {
        return null;
      }

      return this.dockDellData?.content;
    }
  },

  watch: {
    pageId() {
      if (this.pageId !== -1) {
        this.getDockDellArticlesData();
      }
    }
  },

  mounted() {
    this.sendStatistics('DOCK-DELL');

    if (this.pageId !== -1) {
      this.getDockDellArticlesData();
    }
  },

  methods: {
    getDockDellArticlesData() {
      dataLoader.fetchSubPages(this.pageId)
        .then(data => {
          this.dockDellData = data;
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
          this.isLoading = false;
        })
    },

    goToView(itemId, itemSlug) {
      router.push({ name: 'dock-dell-detail', params: { id: itemId, name: itemSlug } });
    }
  }
}
</script>
