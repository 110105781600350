import Frm1023FormRow from './frm1023-form-row';
import Frm1023FormRowSlot from './frm1023-form-row-slot';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1023FormRow);
        registerComponent(Vue, Frm1023FormRowSlot);
    }
};

use(Plugin);

export default Plugin;

export { Frm1023FormRow, Frm1023FormRowSlot };
