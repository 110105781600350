<template>
  <div
    class="vue-c-item-rating"
  >
    <ul class="vue-item-rating-list">
      <li
        v-for="(star, index) in maxStars"
        :key="'star-' + index"
        class="vue-item-rating-star"
        :class="makeActiveStars(star, index)"
        @mouseover="hoverStars(star, index)"
        @click="rateStars(star)"
      />
    </ul>

    <div
      v-if="showTextRating"
      class="vue-item-rating-text"
    >
      <frm1035-form-group>
        <frm1013-textarea
          v-model="userTextRating"
          class="vue-item-rating-input"
          :placeholder="$i18n.t('userRating.textRatingPlaceholder')"
          :rowsAutoExpand="true"
          :rowsAutoExpandMinRows="1"
          :tooltip="false"
        />
      </frm1035-form-group>

      <frm1006-button
        class="vue-item-rating-submit"
        :disabled="!formIsComplete"
        @buttonClickEvent="submitUserTextRating"
      >
        {{ $t('forms.submit') }}
      </frm1006-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prj1008ItemRating',
  props: {
    textRating: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      maxStars: 5,
      currentStars: 0,
      hoveredStars: 0,
      showTextRating: false,
      userTextRating: '',
    }
  },

  computed: {
    formIsComplete() {
      return this.userTextRating.length > 0;
    }
  },

  methods: {
    makeActiveStars(star, index) {
      return {
        'vue-item-rating-star-active': this.currentStars >= star || this.hoveredStars > index
      }
    },

    hoverStars(index) {
      this.hoveredStars = index;
    },

    rateStars(star) {
      this.currentStars = star;

      if (this.textRating) {
        this.showTextRating = true;
      } else {
        this.$emit('rated', this.currentStars);
      }
    },

    submitUserTextRating() {
      const ratingPayload = {
        userStarRating: this.currentStars,
        userTextRating: this.userTextRating
      };

      this.$emit('rated', ratingPayload);
    },
  }
}
</script>
