<template>
  <div
    class="vue-c-file-upload"
    :class="classObject"
  >
    <gen1006-info-panel
      v-if="description"
      type="neutral"
    >
      {{ description }}
    </gen1006-info-panel>

    <template v-if="!fileUploaded">
      <input
        :id="fileUploadId"
        ref="fileUpload"
        class="vue-file-upload-input"
        type="file"
        name="myFile"
        :accept="acceptFiles.join(',')"
        @change="uploadFile"
      >

      <frm1006-button
        class="vue-file-upload-button"
        type="app-button"
        @buttonClickEvent="loadFile"
      />

      <span
        v-if="label"
        class="vue-file-label"
      >
        {{ label }}
      </span>
    </template>

    <template v-else>
      <div class="vue-uploaded-file">
        <span class="vue-file-name">
          {{ fileName }}
        </span>

        <frm1006-button
          class="vue-button-remove-file"
          type="internal"
          :iconLeft="true"
          :captionHidden="true"
          @buttonClickEvent="removeTakenFile"
        >
          {{ $t('ideas.forms.removePhoto') }}
        </frm1006-button>
      </div>
    </template>

    <gen1009-overlay
      class="vue-is-info-panel"
      :active.sync="hasError"
      :buttonClose="true"
      :closeAfter="ERROR_MODAL_TIMEOUT"
    >
      <gen1006-info-panel
        type="error"
      >
        {{ $t('error.exceededSizeLimit', {size: sizeLimitMB}) }}
      </gen1006-info-panel>
    </gen1009-overlay>
  </div>
</template>

<script type="application/javascript">
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';

export default {
  name: 'Prj1045FileUpload',
  props: {
    label: {
      type: String
    },
    acceptFiles: {
      type: Array,
      default: () => ['application/pdf']
    },
    sizeLimitMB: {
      type: Number,
      default: 5
    },
    description: {
      type: String
    }
  },

  data() {
    return {
      fileUploaded: false,
      fileName: '',
      hasError: false,
      ERROR_MODAL_TIMEOUT
    };
  },

  computed: {
    fileUploadId() {
      return 'file-upload-' + this.id;
    },
    classObject() {
      return {
        'vue-has-label': this.label,
      }
    }
  },

  methods: {
    uploadFile(event) {
      const file = event.target.files[0];
      const fileSize = file?.size / 1024 / 1024; // Converting size file to MB

      event.target.value = ''; // Reset input for uploading same file

      if (fileSize < this.sizeLimitMB) {
        this.fileName = file.name;
        this.fileUploaded = true;
        this.$emit('uploadedFile',{
          fileBinary: file,
          fileBase64: event.target.result
        });
      }
      else {
        this.hasError = true
      }
    },

    loadFile() {
      this.$refs.fileUpload.click();
    },

    removeTakenFile() {
      this.fileUploaded = false;
      this.fileName = '';
    },
  }
};
</script>
