<template>
  <div
    class="vue-c-profile-card"
    :class="classObject"
    @click="emitClick"
  >
    <div
      class="vue-avatar"
      :class="avatarClassObject"
    >
      <img
        v-if="getAvatarPath"
        :src="getAvatarPath"
      >
    </div>
    <div class="vue-detail">
      <div
        v-if="isProfessionFilled"
        class="vue-profession"
      >
        {{ profession }}
      </div>
      <div
        class="vue-name"
      >
        {{ fullName }}
      </div>
      <div
        v-if="isEmailFilled"
        class="vue-email"
      >
        {{ email }}
      </div>
      <div
        v-if="isPhoneNumberFilled"
        class="vue-phone-number"
      >
        {{ phoneNumber }}
      </div>
    </div>
  </div>
</template>

<script>
import * as envConfig from 'env-config';

export default {
  name: 'Prj1021ProfileCard',

  props: {
    profileView: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullName: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: false
    },
    avatar: {
      type: String,
      required: false
    },
    profession: {
      type: String,
      required: false
    },
    phoneNumber: {
      type: String,
      required: false
    },
    isClickable: {
      type: Boolean,
    }
  },

  computed: {
    classObject() {
      return {
        'vue-is-clickable': this.isClickable,
        'vue-is-profile-view': this.profileView
      }
    },

    isAvatarFilled() {
      return this.avatar.length > 0;
    },

    isEmailFilled() {
      return typeof this.email !== 'undefined';
    },

    isPhoneNumberFilled() {
      return typeof this.phoneNumber !== 'undefined';
    },

    isProfessionFilled() {
      return this.profession.length > 0;
    },

    getAvatarPath() {
      return this.isAvatarFilled ? this.getUrlImage(this.avatar) : false;
    },

    avatarClassObject() {
      return {
        'vue-is-empty': !this.isAvatarFilled,
      }
    },
  },

  methods: {
    getUrlImage(path) {
      return (path.substring(0, 8) === 'https://') ? path : envConfig.default.webServices.BASE_CMS_URL + '/' + path;
    },

    emitClick() {
      if (this.isClickable) {
        this.$emit('clickedProfile');
      }
    }
  }
}
</script>
