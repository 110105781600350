<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        ref="navbar"
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="goToForvardinoList"
      />
    </template>

    <!--========== HEADER =================================-->
    <!--===================================================-->
    <template v-slot:header>
      <prj1001-detail-header
        v-if="forvardinoData"
        ref="forvardinoDetailHeader"
        :headerImage="forvardinoData.image"
        :headerRating="forvardinoData.score"
        :headerTag="forvardinoData.categories[0]"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        v-if="forvardinoData"
        class="vue-forvardino-detail-content"
      >
        <!-- TODO: Heading props are in lowercase, change it to camelCase globally -->
        <prj1017-heading
          ref="forvardinoTop"
          :detailheader="forvardinoData.name"
        />

        <prj1037-event-attendance-details
          v-if="selectedDateIdData && !olderTwoWeeks(selectedDateEvent.activityTo)"
          class="vue-attendance-details"
          :day="renderLessonString(selectedDateEvent, 'day')"
          :end-day="renderLessonString(selectedDateEvent, 'endDay')"
          :time="renderLessonString(selectedDateEvent, 'time')"
          :start-time="renderLessonString(selectedDateEvent, 'startTime')"
          :end-time="renderLessonString(selectedDateEvent, 'endTime')"
          :same-day="selectedDateEvent.activitySameDay"
          :online="selectedDateEvent.isOnline === '1'"
          :stream-url="selectedDateEvent.onlineLink"
          :location="selectedDateEvent.locationName"
          :place="selectedDateEvent.locationValue"
          :capacity-total="parseInt(selectedDateEvent.capacity)"
          :capacity-remaining="parseInt(getAvailablePlaces)"
        />

        <gen1006-info-panel
          v-if="showRateButton"
          type="info"
        >
          {{ $t('forvardino.ratingPossible') }}
        </gen1006-info-panel>

        <gen1006-info-panel
          v-if="showRatedVisited"
          type="info"
        >
          {{ $t('forvardino.ratedVisibledPossible') }}
        </gen1006-info-panel>

        <gen1006-info-panel
          v-if="!hasAvailableLessonSlots && selectedDateIdData"
          type="info"
        >
          {{ $t('forvardino.unableToRegister') }}
        </gen1006-info-panel>

        <div
          class="vue-forvardino-detail-text"
          v-html="forvardinoData.description"
        />

        <div class="vue-forvardino-lectors">
          <template
            v-if="forvardinoData.lectors && !selectedDateIdData"
          >
            <prj1021-profile-card
              v-for="(lector, index) in forvardinoData.lectors"
              :key="'lector-' + index"
              :fullName="lector.name"
              :avatar="lector.avatar"
              :profession="lector.profession"
              :isClickable="true"
              @clickedProfile="goToLectorPage(lector.id)"
            />
          </template>

          <template v-else>
            <prj1021-profile-card
              v-for="(lector, index) in selectedDateEvent.lectors"
              :key="'lector-' + index"
              :fullName="lector.name"
              :avatar="lector.avatar"
              :profession="lector.profession"
              :isClickable="true"
              @clickedProfile="goToLectorPage(lector.id)"
            />
          </template>
        </div>

        <div
          v-if="forvardinoData && forvardinoData?.lessons.length > 0"
          class="vue-c-forvardino-lessons"
        >
          <template
            v-if="visibleLessons.length > 0"
          >
            <h3 class="vue-forvardino-lessons-heading">
              {{ $t('forvardino.lessonDates') }}
            </h3>

            <prj1030-date-list>
              <template
                v-for="lesson in visibleLessons"
              >
                <prj1037-event-attendance-details
                  :key="'lesson-' + lesson.id"
                  class="vue-lesson"
                  :day="renderLessonString(lesson, 'day')"
                  :time="renderLessonString(lesson, 'time')"
                  :end-day="renderLessonString(lesson, 'endDay')"
                  :start-time="renderLessonString(lesson, 'startTime')"
                  :end-time="renderLessonString(lesson, 'endTime')"
                  :same-day="lesson.activitySameDay"
                  :online="lesson.isOnline === '1'"
                  :location="lesson.locationName"
                  :place="lesson.locationValue"
                  :capacity-total="parseInt(lesson.capacity)"
                  :capacity-remaining="parseInt(lesson.capacity) - parseInt(lesson.occupancy)"
                  :class="getClassObject(lesson.id)"
                  :showRegisteredNotice="lesson.isRegistered"
                  @click.native="selectDate({ value: lesson.id })"
                />
              </template>
            </prj1030-date-list>
          </template>

          <template
            v-else
          >
            <gen1006-info-panel
              type="neutral"
            >
              {{ $t('forvardino.forvardinoNoAvailableLessons') }}
            </gen1006-info-panel>
          </template>
        </div>
      </div>

      <gen1006-info-panel
        v-if="!forvardinoData"
        type="neutral"
      >
        {{ $t('general.loadingFailed') }}
      </gen1006-info-panel>

      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        v-if="forvardinoData?.canManage"
        class="vue-forvardino-manager-button"
        type="secondary"
        @buttonClickEvent="goToManagerLessons(pageId)"
      >
        {{ $t('forvardino.courseManager') }}
      </frm1006-button>

      <div
        v-if="forvardinoData && !selectedDateIdData && visibleLessons.length > 0"
        class="vue-b-choose-date"
      >
        <frm1033-dropdown-button
          :options="convertedAvaiableDatesNew"
          :optionPreselectedValue="selectedDateIdData"
          :caption="(selectedDateIdData === '') ? $t('forvardino.chooseDate') : $t('forvardino.changeRegisterDate')"
          class="vue-is-no-icon"
          :type="selectedDateIdData === '' ? 'primary' : 'secondary'"
          contextContainerCustomCss="vue-is-simple vue-is-position-bottom"
          @contextContainerMenuItemSelectEvent="selectDate"
        >
          <template v-slot:item="props">
            <prj1028-day-time
              :day="props.item.day"
              :time="props.item.time"
            />
          </template>
        </frm1033-dropdown-button>
      </div>

      <Prj1049LoginRedirectButton
        v-if="showInterestButton"
        buttonId="interestButton"
        class="vue-button-interest"
        :type="'secondary'"
        @afterLoginEvent="interestDialog()"
      >
        <template v-if="forvardinoData?.nearestDate !== null">{{ $t('forvardino.courseInterestNew') }}</template>
        <template v-else>{{ $t('forvardino.courseInterest') }}</template>
      </Prj1049LoginRedirectButton>

      <template
        v-if="forvardinoData && forvardinoData.lessons.length > 0"
      >
        <prj1020-separator />

        <div class="vue-forvardino-rating-buttons">
          <frm1006-button
            class="vue-forvardino-detail-button"
            type="app-button"
            @buttonClickEvent="goToRatingList(pageId)"
          >
            {{ $t('forvardino.allRatings') }}
          </frm1006-button>

          <frm1006-button
            v-if="initialized && ratingEnabled && !ratedEvent"
            class="vue-forvardino-detail-button"
            type="app-button"
            @buttonClickEvent="showRatingDialog"
          >
            {{ $t('forvardino.rateCourse') }}
          </frm1006-button>
        </div>
      </template>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <!-- TODO MBU: add button already registered - already done on events -->
    <template v-slot:buttonFixed>
      <Prj1049LoginRedirectButton
        v-if="showRegisterButton"
        buttonId="registerButton"
        class="vue-button-register vue-button-page-layout-fixed"
        :disabled="!hasAvailableLessonSlots"
        @afterLoginEvent="registerDialog()"
      >
        <span>
          {{ $t('forvardino.register') }}
        </span>
      </Prj1049LoginRedirectButton>

      <frm1006-button
        v-if="showUnRegisterButton"
        class="vue-button-register vue-button-page-layout-fixed"
        @buttonClickEvent="logoutDialog()"
      >
        <span>
          {{ $t('forvardino.unregister') }}
        </span>
      </frm1006-button>

      <frm1006-button
        v-if="showRateButton"
        class="vue-button-register vue-button-page-layout-fixed"
        @buttonClickEvent="showRatingDialog()"
      >
        <span>
          {{ $t('forvardino.rateCourse') }}
        </span>
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="ratingSuccessful"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="success"
        >
          {{ $t('forvardino.courseRatingSuccessful') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
        :active.sync="showRatingDialogModal"
      >
        <div
          class="vue-forvardino-detail-rating"
        >
          <h3 v-html="$t('forvardino.forvardinoCourseRating')"></h3>
          <prj1008-item-rating
            :textRating="true"
            @rated="sendRating"
          />
        </div>
      </gen1009-overlay>

      <gen1009-overlay
        :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
        :active.sync="showLogoutDialogModal"
      >
        <div
          class="vue-forvardino-detail-logout"
        >
          <h3 v-html="$t('forvardino.forvardinoCourseLogout', { 'name': forvardinoData?.name })"></h3>

          <frm1006-button
            type="secondary"
            @buttonClickEvent="cancelLogoutDialog()"
          >
            {{ $t('forvardino.forms.cancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            @buttonClickEvent="submitLogoutDialog"
          >
            {{ $t('forvardino.forms.confirm') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>

      <gen1009-overlay
        :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
        :active.sync="showInterestDialogModal"
      >
        <div
          class="vue-forvardino-detail-interest"
        >
          <h3
            v-if="forvardinoData?.nearestDate !== null"
            v-html="$t('forvardino.forvardinoCourseInterestNew', { 'name': forvardinoData?.name })"
          ></h3>
          <h3
            v-else
            v-html="$t('forvardino.forvardinoCourseInterest', { 'name': forvardinoData?.name })"
          ></h3>

          <frm1006-button
            type="secondary"
            @buttonClickEvent="cancelInterestDialog()"
          >
            {{ $t('forvardino.forms.cancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            @buttonClickEvent="submitInterestDialog"
          >
            {{ $t('forvardino.forms.confirm') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>

      <gen1009-overlay
        :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
        :active.sync="showRegisterDialogModal"
      >
        <div
          class="vue-forvardino-detail-register"
        >
          <h3 v-html="$t('forvardino.forvardinoCourseRegister', { 'name': forvardinoData?.name })"></h3>

          <gen1006-info-panel
            v-if="someLessonRegistered"
            type="info"
            class="vue-disclaimer"
          >
            {{ getExistedLessonDisclaimer() }}
          </gen1006-info-panel>

          <gen1006-info-panel
            type="neutral"
            class="vue-disclaimer"
          >
            {{ $i18n.t('forvardino.courseRegistartionNote') }}
          </gen1006-info-panel>

          <frm1006-button
            type="secondary"
            @buttonClickEvent="cancelRegisterDialog()"
          >
            {{ $t('forvardino.forms.cancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            @buttonClickEvent="submitRegisterDialog"
          >
            {{ $t('forvardino.forms.confirm') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import {
  ERROR_MODAL_TIMEOUT,
  TWO_WEEKS_IN_MS,
  FORVARDINO_EVENT_STATUS,
} from '@/constants/app-constants';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import router from '@/router';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import mxNavigationDefault from '../../mixins/mx-navigation-default';
import mxEventDate from '@/mixins/mx-event-date';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import PageLayout from '@/templates/partials/page-layout';

import Prj1001DetailHeader from '@/components/prj1001-detail-header/prj1001-detail-header';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1008ItemRating from '@/components/prj1008-item-rating/prj1008-item-rating';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import Prj1020Separator from '@/components/prj1020-separator/prj1020-separator';
import Prj1021ProfileCard from '@/components/prj1021-profile-card/prj1021-profile-card';
import Prj1028DayTime from '@/components/prj1028-day-time/prj1028-day-time';
import Prj1030DateList from '@/components/prj1030-date-list/prj1030-date-list';
import Prj1037EventAttendanceDetails from '@/components/prj1037-event-attendance-details/prj1037-event-attendance-details';
import Prj1049LoginRedirectButton from '@/components/prj1049-login-redirect-button/prj1049-login-redirect-button';

export default {
  name: 'ForvardinoDetail',
  components: {
    PageLayout,
    Prj1001DetailHeader,
    Prj1002Navbar,
    Prj1008ItemRating,
    Prj1017Heading,
    Prj1020Separator,
    Prj1021ProfileCard,
    Prj1028DayTime,
    Prj1030DateList,
    Prj1037EventAttendanceDetails,
    Prj1049LoginRedirectButton,
  },

  mixins: [
    mxNavigationDefault,
    mxEventDate,
    mxDetectDesktop
  ],

  data() {
    return {
      today: new Date().getTime(),
      isLoading: true,
      hasError: false,
      initialized: false,
      forvardinoFetched: null,
      selectedDateIdData: '',
      selectedLessonRegistered: false,
      eventDateModalVisibility: false,
      showRatingDialogModal: false,
      showLogoutDialogModal: false,
      showInterestDialogModal: false,
      showRegisterDialogModal: false,
      ratingSuccessful: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    ...mapState('user', [
      'deviceId'
    ]),
    ...mapState('forvardino', [
      'forvardinoActiveCategory',
      'detailData'
    ]),
    ...mapState('persistentStorage', [
      'registeredForvardinoEvents',
      'ratedForvardinoEvents'
    ]),

    pageId() {
      return this.$route.params.id;
    },

    historyBack() {
      return !!this.$route.params.historyBack;
    },

    visibleLessons() {
      let visibleLessons = [];

      this.forvardinoData.lessons.forEach(lesson => {
        const lessonDateTimestamp = new Date(lesson.activityTo.replace(' ', 'T')).getTime();

        if (this.today < lessonDateTimestamp && parseInt(lesson.state) === FORVARDINO_EVENT_STATUS.EVENT_ACTIVE) {
          visibleLessons.push(lesson);
        }
      });

      return visibleLessons;
    },

    ratingableLessons() {
      let ratingableLessons = [];

      this.forvardinoData?.lessons.forEach(lesson => {
        const lessonDateTimestamp = new Date(lesson.activityTo.replace(' ', 'T')).getTime();
        if (!this.olderTwoWeeks(lesson.activityTo) && this.today > lessonDateTimestamp && lesson.state !== FORVARDINO_EVENT_STATUS.EVENT_INACTIVE && lesson.isRegistered) {
          ratingableLessons.push(lesson);
        }
      });

      return ratingableLessons;
    },

    myForvardinoEventsIds() {
      return this.registeredForvardinoEvents;
    },

    convertedAvaiableDatesNew() {
      if (!this.isLoading) {
        let avaiableDates = [];

        Object.values(this.visibleLessons).forEach(item => {
          const itemObject = {
            value: item.id.toString(),
            day: item.activitySameDay ? this.renderLessonString(item, 'day') : this.renderLessonString(item, 'day') + ' - ' + this.renderLessonString(item, 'endDay'),
            time: this.renderLessonString(item, 'time')
          };

          avaiableDates.push(itemObject);
        });

        return avaiableDates;
      }

      return []
    },

    selectedDateEvent() {
      if (this.selectedDateIdData) {
        const lessons = Object.values(this.forvardinoData.lessons);
        const selectedItemIndex = lessons.findIndex(event => event.id == parseInt(this.selectedDateIdData));

        if (selectedItemIndex === -1) {
          return lessons[0];
        }

        return lessons[selectedItemIndex];
      }

      return [];
    },

    getAvailablePlaces() {
      if (!this.isLoading) {
        return this.selectedDateEvent.capacity - this.selectedDateEvent.occupancy;
      }

      return {};
    },

    hasAvailableLessonSlots() {
      return (this.selectedDateEvent.capacity - this.selectedDateEvent.occupancy) > 0;
    },

    ratingEnabled() {
      const ratingableLessonsIds = [];

      this.ratingableLessons.forEach(lesson => {
        if (parseInt(this.selectedDateIdData) === parseInt(lesson.id)) {
          ratingableLessonsIds.push(lesson.id);
        }
      });

      return ratingableLessonsIds.length > 0;
    },

    ratedEvent() {
      return this.selectedDateEvent.isRated;
    },

    forvardinoCached() {
      return this.detailData;
    },

    forvardinoData() {
      if (Object.keys(this.forvardinoCached).length) {
        return this.forvardinoCached;
      }
      return this.forvardinoFetched;
    },

    showRegisterButton() {
      return !this.selectedLessonRegistered && this.hasAvailableLessonSlots;
    },

    showUnRegisterButton() {
      return this.selectedLessonRegistered;
    },

    showInterestButton() {
      return !this.isLoading && !this.ratingEnabled;
    },

    showRateButton() {
      return this.ratingEnabled && !this.ratedEvent;
    },

    showRatedVisited() {
      return this.forvardinoData?.signOutExpired && this.ratedEvent && this.ratingEnabled;
    },

    someLessonRegistered() {
      let retValue = false;

      this.forvardinoData?.lessons.forEach((lesson) => {
        if (!lesson.signOutExpired && lesson.isRegistered) {
          retValue = true;
        }
      });

      return retValue;
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value && Object.keys(this.forvardinoData).length) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  mounted() {
    if (this.forvardinoCached?.id !== this.pageId) {
      this.getForvardinoDetailData();
    } else {
      this.selectedDateIdData = this.forvardinoData?.registeredLessonId;
      this.checkRegisteredActions();
      this.initialized = true;
    }
  },

  methods: {
    getForvardinoDetailData() {
      dataLoader.getForvardinoEventDetail(this.pageId)
        .then(response => {
          this.forvardinoFetched = response;
          this.selectedDateIdData = this.forvardinoFetched.registeredLessonId;
          this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, this.forvardinoFetched);
          if (Object.keys(response).length > 0) {
            this.isLoading = false;
          }

          this.checkRegisteredActions();
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // FIXME: CSOBZ-521 - overlay delay, when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.initialized = true;
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          }
        });
    },

    goToForvardinoList() {
      const params = {};

      if (this.historyBack) {
        router.go(-1);
      }

      if (this.forvardinoActiveCategory) {
        params.categoryId = this.forvardinoActiveCategory
      }

      if (!this.$route.params.origin) {
        router.push({ name: 'forvardino-list', params })
      } else {
        router.push({ name: this.$route.params.origin})
      }
    },

    checkRegisteredActions() {
      this.selectedLessonRegistered = false;
      this.forvardinoData.lessons.forEach((lesson) => {
        if (lesson.id === this.selectedDateIdData && lesson.isRegistered && !this.olderTwoWeeks(lesson.activityTo)) {
          this.selectedLessonRegistered = true;
        }
      });
    },

    selectDate(item) {
      this.selectedDateIdData = item.value;
      this.checkRegisteredActions();
    },

    showRatingDialog() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.showRatingDialogModal = true;
    },

    sendRating(ratingData) {
      this.isLoading = true;

      const ratingPayload = {
        comment: ratingData.userTextRating,
        score: ratingData.userStarRating,
        courseId: this.forvardinoData.id,
        lessonId: this.selectedDateIdData,
      };

      dataLoader.sendForvardinoCourseRating(ratingPayload)
        .then(() => {
          this.ratingSuccessful = true;
          router.push({ name: 'forvardino-list',
            params: {
              categoryId: this.forvardinoActiveCategory,
              successfullRegistration: true,
              registrationMessage: 'courseRatingSuccessful'
            }
          });
        })
        .catch(error => {
          logger.error('error -> ', error);
          this.$nextTick(() => {
            this.hasError = true;
          });
        })
        .finally(() => {
          this.showRatingDialogModal = false;
          this.isLoading = false;
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          }
        });
    },

    logoutDialog() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.showLogoutDialogModal = true;
    },

    submitLogoutDialog() {
      this.sendLogout();
    },

    cancelLogoutDialog() {
      this.showLogoutDialogModal = false;
    },

    sendLogout() {
      this.isLoading = true;

      dataLoader.sendForvardinoLessonSignOut(this.selectedDateIdData)
        .then(() => {
          router.push({ name: 'forvardino-list',
            params: {
              categoryId: this.forvardinoActiveCategory,
              successfullRegistration: true,
              registrationMessage: 'unregistrationSucessfull'
            }
          });
        })
        .catch(error => {
          logger.error('error -> ', error);
          this.$nextTick(() => {
            this.hasError = true;
          });
        })
        .finally(() => {
          this.showLogoutDialogModal = false;
          this.isLoading = false;
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          }
        });
    },

    interestDialog() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.showInterestDialogModal = true;
    },

    submitInterestDialog() {
      this.sendInterest();
    },

    cancelInterestDialog() {
      this.showInterestDialogModal = false;
    },

    sendInterest() {
      this.isLoading = true;

      const postData = {
        courseId: this.pageId,
      };

      dataLoader.sendForvardinoCourseInterest(postData)
        .then(() => {
          router.push({name: 'forvardino-list',
            params: {
              categoryId: 0,
              successfullRegistration: true,
              registrationMessage: 'courseRegistrationSuccess'
            }
          });
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
          this.isLoading = false;
        });
    },

    registerDialog() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.showRegisterDialogModal = true;
    },

    submitRegisterDialog() {
      this.sendRegister();
    },

    cancelRegisterDialog() {
      this.showRegisterDialogModal = false;
    },

    sendRegister() {
      this.isLoading = true;

      dataLoader.sendForvardinoLessonSignIn(this.selectedDateIdData)
        .then(() => {
          router.push({ name: 'forvardino-list',
            params: {
              categoryId: this.forvardinoActiveCategory,
              successfullRegistration: true,
              registrationMessage: 'registrationSucessfull'
            }
          });
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
          this.isLoading = false;
        });
    },

    goToLectorPage(id) {
      router.push({ name: 'forvardino-lector',
        params: {
          id: id,
          eventId: this.forvardinoData.id,
          origin: this.$route.params.origin
        }
      });
    },

    goToRatingList(id) {
      router.push({ name: 'forvardino-course-rating',
        params: {
          id: id,
          eventId: this.forvardinoData.id,
          origin: this.$route.params.origin
        }
      });
    },

    getClassObject(lessonId) {
      return [
        lessonId == this.selectedDateIdData || lessonId == this.forvardinoData.lessonId ? 'vue-is-selected' : ''
      ];
    },

    getExistedLessonDisclaimer() {
      return this.$i18n.t('forvardino.courseRegistartionAgainNote', { 'courseDate': '', 'courseDateNew': ''})
    },

    olderTwoWeeks(timestamp) {
      const lessonDateTimestamp = new Date(timestamp.replace(' ', 'T')).getTime();
      const lessonDateTimestampPlusTwoWeeks = lessonDateTimestamp + TWO_WEEKS_IN_MS;

      return this.today > lessonDateTimestampPlusTwoWeeks;
    },

    goToManagerLessons(pageId) {
      router.push({ name: 'forvardino-course-manager',
        params: {
          id: pageId,
          origin: this.$route.params.origin
        }
      });
    }
  }
}
</script>
