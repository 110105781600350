import * as STORE_MODULES from './store/store-modules';
import * as MUTATIONS from './store/constants/mutations';
import logger from './utils/logger';
export default function(store) {
  if (process.env.VUE_APP_ENV != 'local') {
    if ('serviceWorker' in navigator) {
      let newWorker;
      let appRefreshing = false;
      logger.info('Registering SW...');
      navigator.serviceWorker
        .register(`${process.env.VUE_APP_ROOT_URL}service-worker.js`)
        .then(registration => {
          logger.info('SW registration OK');
          store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS.SET_SW_REGISTRATION, registration);
          // TODO: try to fix no-registration.active on this place when page is loaded for the first time, value is null because this prop is filled after activating lifecycle hook
          store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS.SET_SW_INSTANCE, registration.active);
          registration.addEventListener('updatefound', () => {
            logger.info(['[SW] Found update']);
            newWorker = registration.installing;
            newWorker.addEventListener('statechange', event => {
              let state = event.target.state;
              logger.info(['[SW] State changed to: ' + state, navigator.serviceWorker.controller]);

              if (state === 'installed' && navigator.serviceWorker.controller) {
                logger.info(['[SW] Installed']);
                store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS.SET_APP_UPDATE_READY, newWorker);
              } else if (state === 'activated' && navigator.serviceWorker.controller) {
                store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS.SET_SW_INSTANCE, newWorker);
              } else if (state === 'redundant') {
                logger.info('[SW] State redundant - informing user');
                store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS.SET_APP_UPDATE_FAILED);
              }
            });
          });
        })
        .catch(error => {
          logger.info('Error registering the Service Worker: ' + error);
        });
      navigator.serviceWorker.addEventListener('message', event => {
        logger.info('[SW] Client has received a message');
        const message = JSON.parse(event.data);
        // TODO: currently we ignore refresh SW message. AFAIK we does not need it, but test a little in workload
        //       and then decide.
        if (message.type === 'network-error') {
          // debounce multiple resources error, do not show more than once in 10 minutes
          let timestamp = new Date().getTime() / 1000;
          if (!store.state.general.networkError && (timestamp > store.state.general.networkErrorLastDismiss + 600)) {
            store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS.SET_NETWORK_ERROR, true);
          }
        }
      });

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // new worker has taken control, reload app
        logger.info('[SW] New worker has taken control, refreshing.');
        if (appRefreshing) {
          return;
        }
        appRefreshing = true;
        window.location.reload();
      });
    }
  }
}
