import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import general from './modules/general';
import persistentStorage from './modules/persistent-storage';
import dataStore from './modules/data-store/index';
import articles from './modules/articles';
import user from './modules/user';
import dashboard from './modules/dashboard';
import popup from './modules/popup';
import events from './modules/events';
import recruitment from './modules/recruitment';
import restaurants from './modules/restaurants';
import forvardino from './modules/forvardino';
import competition from './modules/competition';
import workouts from './modules/workouts';
import clientSatisfaction from './modules/nps';
import tutorial from './modules/tutorial';
import trio from './modules/trio';
import weekActions from './modules/week-actions';
import parking from './modules/parking';
import benefits from './modules/benefits';
import onboarding from './modules/onboarding';
import services from './modules/services';
import knowledge from './modules/knowledge';
import parents from './modules/parents';
import promoted from './modules/promoted';
import comingActions from './modules/coming-actions';
import marketplace from './modules/marketplace';
import directory from './modules/directory';
import maps from './modules/maps';

//ESG
import esgIntro from './modules/esg/intro';
import esgGeneral from './modules/esg/general';
import esgChallenges from './modules/esg/challenges';
import esgStories from './modules/esg/stories';
import esgThemes from './modules/esg/themes';
import esgCompetitions from './modules/esg/competitions';
import esgSocial from './modules/esg/social';
import esgConfig from './modules/esg/config';

//FEST
import festIntro from './modules/fest/intro';
import festGeneral from './modules/fest/general';
import festChallenges from './modules/fest/challenges';
import festStories from './modules/fest/stories';
import festUser from './modules/fest/user';
import festThemes from './modules/fest/themes';
import festCompetitions from './modules/fest/competitions';
import festSocial from './modules/fest/social';
import festConfig from './modules/fest/config';

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: 'zapka-localstorage',
  storage: window.localStorage,
  modules: [
    'persistentStorage',
    'dataStore',
    'user',
    'dashboard',
    'tutorial',
    'competition',
    'esgIntro',
    'esgThemes',
    'esgChallenges',
    'esgCompetitions',
    'esgStories',
    'esgSocial',
    'esgConfig',
    'festIntro',
    'festUser',
    'festThemes',
    'festChallenges',
    'festCompetitions',
    'festStories',
    'festSocial',
    'festConfig'
  ]
});

export default new Vuex.Store({
  modules: {
    general,
    persistentStorage,
    dataStore,
    articles,
    user,
    dashboard,
    popup,
    events,
    recruitment,
    restaurants,
    forvardino,
    competition,
    workouts,
    clientSatisfaction,
    tutorial,
    trio,
    weekActions,
    parking,
    benefits,
    onboarding,
    services,
    knowledge,
    parents,
    promoted,
    comingActions,
    marketplace,
    directory,
    maps,
    esgIntro,
    esgGeneral,
    esgChallenges,
    esgStories,
    esgThemes,
    esgCompetitions,
    esgSocial,
    esgConfig,
    festIntro,
    festGeneral,
    festChallenges,
    festStories,
    festUser,
    festThemes,
    festCompetitions,
    festSocial,
    festConfig
  },
  plugins: [vuexLocal.plugin]
});
