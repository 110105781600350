<template>
  <section>
    <t-tag
      v-if="title"
      class="font-bold text-xl pb-5"
      tag-name="h3"
      v-html="title"
    ></t-tag>
    <div
      ref="svgContainer"
      class="vue-graph-path relative mt-12 mb-1"
    >
      <div
        v-if="yLegendPlan.length > 0 && yLegend.length === 0"
        class="absolute inset-0"
      >
        <span
          v-for="({ x, y, value, plan }, index) in yLegendPlan"
          :key="`value-legend-${value}-${index}`"
          class="absolute font-bold origin-bottom-left transform"
          :style="getYLegendStyle(x, y, index)"
        >
          <span
            v-if="yLegendPlan.length === (index + 1)"
            class="relative"
          >
            <span class="flex flex-row items-baseline rounded-lg bg-graph-plan">
              <span
                v-if="plan"
                class="text-graph-plan"
              >
                {{ plan }}{{ valuesUnits }}
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        v-if="yLegend.length"
        class="absolute inset-0"
      >
        <span
          v-for="({ x, y, value, summary, plan }, index) in yLegend"
          :key="`value-legend-${value}-${index}`"
          class="absolute font-bold origin-bottom-left transform"
          :style="getYLegendStyle(x, y, index)"
        >
          <span
            v-if="yLegend.length === (index + 1)"
            class="relative"
          >
            <span class="flex flex-row rounded-lg bg-graph-summary">
              <span
                v-if="plan"
                class="text-graph-summary-plan"
              >
                {{ plan }}{{ valuesUnits }}
              </span>
              <span
                v-if="plan && summary"
                class="vue-graph-value-separator"
              >/</span>
              <span
                v-if="summary"
                :class="classColor"
                class="text-graph-summary"
              >
                {{ summary }}{{ valuesUnits }}
              </span>
            </span>
          </span>
        </span>
      </div>
      <svg
        class="overflow-visible"
        height="100%"
        width="100%"
        :viewBox="`0 0 ${dimensions.width} ${dimensions.height}`"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          v-if="basePath"
          :class="chartUI.targetBaseTxtCol"
        >
          <path
            ref="basePathRef"
            class="graph-line-grow"
            :style="`animation-duration: ${basePathLength * 2}ms`"
            :d="basePath"
            stroke="currentColor"
            :stroke-width="stroke"
            :stroke-dasharray="basePathLength"
            :stroke-dashoffset="basePathLength"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <g
          v-if="path"
          :class="classColor"
        >
          <path
            ref="highlightedPathRef"
            class="graph-line-grow"
            :style="`animation-duration: ${highLightedPathLength * 2}ms`"
            :d="path"
            stroke="currentColor"
            :stroke-width="stroke"
            :stroke-dasharray="highLightedPathLength"
            :stroke-dashoffset="highLightedPathLength"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </div>
    <div
      class="vue-graph-axis relative"
      :class="timeUnits === DATE_FORMATS.YYYY ? 'pb-14' : 'pb-10'"
    >
      <span
        v-for="({ date, x }, index) in xLegend"
        :key="`date-${x}-${index}`"
        class="absolute font-semibold text-gray-400 origin-top-right transform"
        :style="getXLegendStyle(x, index)"
      >{{ date }}</span>
    </div>
    <div class="vue-graph-legend">
      <ul>
        <li class="flex items-center mb-2">
          <span
            class="w-2 h-2 mr-1.5"
            :class="classColor"
            v-html="chartUI.legendIcon"
          ></span>
          <span
            class="text-gray-500 text-xs"
            v-html="valuesLegend"
          ></span>
        </li>
        <li
          v-if="baseValuesLegend"
          class="flex items-center"
        >
          <span
            class="w-2 h-2 mr-1.5"
            :class="chartUI.baseTxtCol"
            v-html="chartUI.legendIcon"
          ></span>
          <span
            class="text-gray-500 text-xs"
            v-html="baseValuesLegend"
          ></span>
        </li>
      </ul>
    </div>
  </section>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ CONSTS ==================================//
//======================================================//
export const DATE_FORMATS = { YYYY: 'years' , MM: 'months' , DD: 'days' }

export const SVG_SIZES = [
  { size: '1200', maxItems: 48, stroke: 2 },
  { size: '768', maxItems: 24, stroke: 4 },
  { size: '428', maxItems: 12, stroke: 6 },
  { size: '335', maxItems: 8, stroke: 8 },
  { size: '320', maxItems: 5, stroke: 8 },
  { size: '0', maxItems: 4, stroke: 8 },
]

//============ IMPORT ==================================//
//======================================================//
import '@/utils/dayjs'
import { chartUI } from '@/assets/charts'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1031NpsGraph',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    valuesLegend: {
      type: String,
      required: true,
    },
    baseValuesLegend: {
      type: String,
      default: null,
    },
    valuesUnits: {
      type: String,
      default: '%',
    },
    timeUnits: {
      type: String,
      default: DATE_FORMATS.YYYY,
      validator: (value) => [DATE_FORMATS.YYYY, DATE_FORMATS.MM, DATE_FORMATS.DD].includes(value),
    },
    classColor: {
      type: String,
      required: false,
      default: 'text-graph-summary-success'
    }
  },
  data() {
    return {
      DATE_FORMATS,
      chartUI,
      stroke: 8,
      dimensions: {
        width: 300,
        height: 100,
      },
      orderedChartData: null,
      dateTimeChartData: null,
      isBaseLine: false,
      xLineData: [],
      yLineData: [],
      xLineSegment: null,
      yLineSegment: null,
      path: null,
      basePath: null,
      xLegend: [],
      yLegend: [],
      yLegendPlan: [],
      svgDynamicSizeConfig: null,
      highLightedPathLength: null,
      basePathLength: null,
    }
  },
  computed: {},
  mounted() {
    this.getCalculatedData()
  },
  methods: {
    getCalculatedData() {
      this.isBaseLine = this.chartData.every((item) => item.baseValue)
      this.svgDynamicSizeConfig = SVG_SIZES.find(
        (item) => this.$refs.svgContainer.getBoundingClientRect().width > item.size
      )
      this.stroke = this.svgDynamicSizeConfig.stroke
      this.getOrderedTimeLine()
      this.getDateTimeConversion()
      this.getXLineData()
      this.getOrderedYLineValues()

      this.xLineSegment = this.dimensions.width / this.xLineData[this.xLineData.length - 1]
      this.yLineSegment = this.dimensions.height / this.yLineData[this.yLineData.length - 1]

      this.getXLegend()

      this.getPath()
      if (this.isBaseLine) this.getBasePath()
    },
    getOrderedTimeLine() {
      this.orderedChartData = this.chartData
        .reduce((acc, item) => [...acc, { ...item, date: new Date(item.date) }], [])
        .sort((a, b) => a.date - b.date);
    },

    getDateTimeConversion() {
      this.dateTimeChartData = this.orderedChartData.reduce((acc, item) => {
        return [...acc, { ...item, time: item.date.valueOf() - this.orderedChartData[0].date.valueOf() }]
      }, []);
    },

    getXLineData() {
      return (this.xLineData = this.dateTimeChartData.reduce((acc, item) => {
        return [...acc, item.time]
      }, []));
    },

    getOrderedYLineValues() {
      return (this.yLineData = this.dateTimeChartData
        .reduce((acc, item) => {
          return this.isBaseLine ? [...acc, item.value, item.baseValue] : [...acc, item.value]
        }, [])
        .sort((a, b) => a - b))
    },

    getPath() {
      const y0 = this.dimensions.height - this.getYCoordinate(this.dateTimeChartData[0].value)
      const firstNullValueIndex = this.dateTimeChartData.indexOf(
        this.dateTimeChartData.find((item) => item.value === null)
      )
      const pathSourceData =
        firstNullValueIndex === -1 ? this.dateTimeChartData : this.dateTimeChartData.slice(0, firstNullValueIndex)
      if (pathSourceData.length === 1) return

      this.path = pathSourceData.reduce((prevVal, currVal, index) => {
        if (index === 0) {
          this.getYLegend({ x: 0, y: y0, value: pathSourceData[0].value, summary: pathSourceData[0].summary, plan: pathSourceData[0].plan })
          return `M0 ${y0}L`
        }
        if (index === pathSourceData.length - 1) {
          const x = this.getXCoordinate(pathSourceData[index].time)
          const y = this.dimensions.height - this.getYCoordinate(pathSourceData[index].value)
          this.getYLegend({ x, y, value: pathSourceData[index].value, summary: pathSourceData[index].summary, plan: pathSourceData[index].plan })

          return `${prevVal}${x} ${y}`
        }
        if (index > 0) {
          const x = this.getXCoordinate(pathSourceData[index].time)
          const y = this.dimensions.height - this.getYCoordinate(pathSourceData[index].value)
          this.getYLegend({ x, y, value: pathSourceData[index].value, summary: pathSourceData[index].summary, plan: pathSourceData[index].plan })

          return `${prevVal}${x} ${y}L`
        }
      }, '')

      this.$nextTick(() => {
        if (this.path) {
          this.highLightedPathLength = Math.ceil(this.$refs.highlightedPathRef.getTotalLength())
        }
      })
    },

    getBasePath() {
      const y0 = this.dimensions.height - this.getYCoordinate(this.dateTimeChartData[0].baseValue)

      this.basePath = this.dateTimeChartData.reduce((prevVal, currVal, index) => {
        if (index === 0) {
          this.getYLegendPlan({ x: 0, y: y0, value: this.dateTimeChartData[0].baseValue, plan: this.dateTimeChartData[0].plan })
          return `M0 ${y0}L`
        }
        if (index === this.dateTimeChartData.length - 1) {
          const x = this.getXCoordinate(this.dateTimeChartData[index].time)
          const y = this.dimensions.height - this.getYCoordinate(this.dateTimeChartData[index].baseValue)
          this.getYLegendPlan({ x, y, value: this.dateTimeChartData[index].baseValue, plan: this.dateTimeChartData[index].plan })

          return `${prevVal}${x} ${y}`
        }
        if (index > 0) {
          const x = this.getXCoordinate(this.dateTimeChartData[index].time)
          const y = this.dimensions.height - this.getYCoordinate(this.dateTimeChartData[index].baseValue)
          this.getYLegendPlan({ x, y, value: this.dateTimeChartData[index].baseValue, plan: this.dateTimeChartData[index].plan })

          return `${prevVal}${x} ${y}L`
        }
      }, '')

      this.$nextTick(() => {
        if (this.basePath) {
          this.basePathLength = Math.ceil(this.$refs.basePathRef.getTotalLength())
        }
      })
    },

    getYLegend({ x, y, value, summary, plan }) {
      return (this.yLegend = [...this.yLegend, { x, y, value, summary, plan }])
    },

    getYLegendPlan({ x, y, value, plan }) {
      return (this.yLegendPlan = [...this.yLegendPlan, { x, y, value, plan }])
    },

    getXLegend() {
      const data = this.dateTimeChartData.reduce((acc, item) => {
        return [...acc, { date: this.getDisplayedDate(item.date), x: this.getXCoordinate(item.time) }]
      }, [])

      return (this.xLegend = data)
    },

    getDisplayedDate(date) {
      switch (this.timeUnits) {
      case DATE_FORMATS.DD:
        return this.$date(date).format('dd')
      case DATE_FORMATS.MM:
        return this.$date(date).format('MM')
      case DATE_FORMATS.YYYY:
        return date.getFullYear()
      }
    },

    getXCoordinate(value) {
      return Math.round(value * this.xLineSegment)
    },

    getYCoordinate(value) {
      return Math.round(value * this.yLineSegment)
    },

    getLegendVisSequence(maxItems) {
      return Math.round(this.xLegend.length / maxItems)
    },

    getXLegendStyle(x, index) {
      const maxItems = this.svgDynamicSizeConfig.maxItems
      if (this.chartData.length <= maxItems) {
        return `right: ${100 - (100 * x) / this.dimensions.width}%; top: 0;`
      }

      const sequence = this.getLegendVisSequence(maxItems)
      let display = (index + 1 + (this.chartData.length % 2)) % sequence ? 'none' : 'inline-block'
      display = [2, 5, 8, 11].includes(index) ? 'inline-block' : 'none';
      return `right: ${100 - (100 * x) / this.dimensions.width}%; top: 0; display: ${display}`
    },

    getYLegendStyle(x, y, index) {
      const maxItems = this.svgDynamicSizeConfig.maxItems
      if (this.chartData.length <= maxItems) {
        return `left: ${(100 * x) / this.dimensions.width}%; bottom: ${100 - y}%;`
      }

      const sequence = this.getLegendVisSequence(maxItems)
      const display = (index + 1 + (this.chartData.length % 2)) % sequence ? 'none' : 'inline-block'
      return `left: ${(100 * x) / this.dimensions.width}%; bottom: ${100 - y}%; display: ${display}`
    },
  },
}
</script>
