<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div v-if="!completedForm">
        <prj1017-heading
          :header="$i18n.t('recruitment.headlineRecruitment')"
        />

        <div
          v-if="locationsList.length > 0"
          class="vue-b-form"
        >
          <!-- LOCATION -->
          <frm1035-form-group>
            <frm1028-advanced-combo
              id="recruitment-location-search"
              v-model="recruitmentFormValues.region"
              :options="locationsList"
              :tooltip="false"
              :label="$i18n.t('recruitment.place')"
            />
          </frm1035-form-group>

          <!-- SPECIALIZATION -->
          <frm1035-form-group>
            <frm1028-advanced-combo
              id="recruitment-specialization-search"
              v-model="recruitmentFormValues.specialization"
              :options="specializationsList"
              :tooltip="false"
              :label="$i18n.t('recruitment.specialization')"
            />
          </frm1035-form-group>

          <!-- CONTRACT TYPE -->
          <frm1035-form-group>
            <frm1028-advanced-combo
              id="recruitment-contract-type-search"
              v-model="recruitmentFormValues.type"
              :options="contractTypeList"
              :tooltip="false"
              :label="$i18n.t('recruitment.obligation')"
            />
          </frm1035-form-group>

          <!-- FULLTEXT SEARCH -->
          <frm1035-form-group>
            <frm1001InputField
              id="recruitment-keyword-filter"
              v-model="recruitmentFormValues.search"
              name="recruitment-keyword"
              :tooltip="false"
              :label="$i18n.t('recruitment.positionName')"
            />
          </frm1035-form-group>

          <gen1006-info-panel
            type="neutral"
            class="vue-additional-info"
          >
            {{ $t('recruitment.noteRecruitment') }}
          </gen1006-info-panel>
        </div>
      </div>

      <gen1009-overlay
        :active.sync="showGroupMenu"
        class="vue-c-watchdog"
      >
        <div class="vue-b-watchdog-section-active">
          <h3 class="vue-watchdog-headline">{{ $t('recruitment.watchdogActiveHeadline') }}</h3>

          <div
            v-if="!searchRequestSending && !searchRequestFailed && activeWatchdogs.length > 0"
          >
            <gen1015-section
              v-for="(item, index) in activeWatchdogs"
              :key="index"
              :collapsible="true"
              :collapsed="true"
              class="is-animated"
            >
              <template v-slot:header>
                <h3>{{ item.name }}:</h3>
              </template>
              <template v-slot:content>
                <div class="vue-watchdog-container">
                  <div
                    v-if="item.region !== '0'"
                    class="vue-watchdog-row"
                  >
                    <div class="vue-caption">{{ $t('recruitment.place') }}</div>
                    <div class="vue-value">{{ getRegionName(item.region) }}</div>
                  </div>

                  <div
                    v-if="item.specialization !== '0'"
                    class="vue-watchdog-row"
                  >
                    <div class="vue-caption">{{ $t('recruitment.specialization') }}</div>
                    <div class="vue-value">{{ getSpecializationName(item.specialization) }}</div>
                  </div>

                  <div
                    v-if="item.type !== '0'"
                    class="vue-watchdog-row"
                  >
                    <div class="vue-caption">{{ $t('recruitment.obligation') }}</div>
                    <div class="vue-value">{{ getTypeName(item.type) }}</div>
                  </div>

                  <div
                    v-if="item.search !== ''"
                    class="vue-watchdog-row"
                  >
                    <div class="vue-caption">{{ $t('recruitment.position') }}</div>
                    <div class="vue-value">{{ item.search }}</div>
                  </div>

                  <div class="vue-watchdog-row">
                    <div class="vue-value">
                      <frm1006-button
                        class="vue-button-remove-watchdog"
                        type="internal"
                        :iconLeft="true"
                        :captionHidden="false"
                        @buttonClickEvent="submitRemoveWatchdog(item.id)"
                      >
                        {{ $t('forms.cancel') }}
                      </frm1006-button>
                    </div>
                  </div>
                </div>
              </template>
            </gen1015-section>
          </div>

          <gen1006-info-panel
            v-else
            type="neutral"
          >
            {{ $t('recruitment.noActiveWatchdog') }}
          </gen1006-info-panel>
        </div>

        <prj1020-separator />

        <div class="vue-b-watchdog-section-new">
          <h3 class="vue-watchdog-headline">{{ $t('recruitment.watchdogNewHeadline') }}</h3>

          <template v-if="isSelectedRegion || isSelectedSpecialization || isSelectedType">
            <div class="vue-watchdog-container">
              <div
                v-if="isSelectedRegion"
                class="vue-watchdog-row"
              >
                <div class="vue-caption">{{ $t('recruitment.place') }}</div>
                <div class="vue-value">{{ selectedRegionValue }}</div>
              </div>

              <div
                v-if="isSelectedSpecialization"
                class="vue-watchdog-row"
              >
                <div class="vue-caption">{{ $t('recruitment.specialization') }}</div>
                <div class="vue-value">{{ selectedSpecializationValue }}</div>
              </div>

              <div
                v-if="isSelectedType"
                class="vue-watchdog-row"
              >
                <div class="vue-caption">{{ $t('recruitment.obligation') }}</div>
                <div class="vue-value">{{ selectedTypeValue }}</div>
              </div>
            </div>
          </template>

          <gen1006-info-panel
            v-else
            type="info"
          >
            {{ $t('recruitment.watchdogNewNote') }}
          </gen1006-info-panel>

          <frm1006-button
            type="primary"
            :disabled="(!isSelectedRegion && !isSelectedSpecialization && !isSelectedType) || !netConnection"
            class="vue-watchdog-button-set"
            @buttonClickEvent="submitNewWatchdog"
          >
            {{ $t('recruitment.watchdogButtonSet') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        type="primary"
        :disabled="!isActive || !netConnection"
        @buttonClickEvent="submitJobSearch"
      >
        {{ $t('recruitment.submitShowPosition') }} {{ recentMatchesOutput }}
      </frm1006-button>

      <frm1006-button
        type="secondary"
        :disabled="!netConnection"
        @buttonClickEvent="submitShowWatchdog($event)"
      >
        {{ $t('recruitment.submitShowWatchdog') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="locationsList.length === 0"
        :fullScreen="true"
        :overlay="true"
      />

      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import * as MUTATIONS_CONSTANTS from '../../store/constants/mutations';
import Scroller from 'vue-scrollto'
import * as STORE_MODULES from '../../store/store-modules';
import dataLoader from '@/utils/data-loader';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import Prj1020Separator from '@/components/prj1020-separator/prj1020-separator.vue';

export default {
  name: 'RecruitmentSearch',
  components: {
    Prj1020Separator,
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
    mxDetectDesktop
  ],

  data() {
    return {
      completedForm: false,
      validationErrors: [],
      showGroupMenu: false,
      contextContainerPositions: false,
      searchRequestSending: false,
      searchRequestFailed: false,
      hasFeaturedImage: true,
      locationsList: [],
      specializationsList: [],
      contractTypeList: [],
      recruitmentFormValues: {
        search: '',
        region: '',
        type: '',
        specialization: ''
      },
      recentMatches: 0,
      netConnection: false,
      sending: false,
      activeWatchdogs: []
    }
  },

  computed: {
    ...mapState('user', [
      'deviceId'
    ]),

    ...mapState('recruitment', [
      'recruitment'
    ]),

    recentMatchesOutput() {
      if(this.recentMatches > 0 || (this.recruitmentFormValues.region || this.recruitmentFormValues.specialization || this.recruitmentFormValues.type || this.recruitmentFormValues.search)) {
        return '(' + this.recentMatches + ')';
      } else {
        return '';
      }
    },

    isActive() {
      let formValue = this.recruitmentFormValues;
      if (this.recentMatches === 0) {
        return false;
      }
      else if (formValue.region || formValue.specialization || formValue.type || formValue.search && this.recentMatches > 0) {
        return true;
      } else{
        return false;
      }
    },

    isSelectedRegion() {
      return this.recruitmentFormValues.region !== '' && this.recruitmentFormValues.region !== '0'
    },

    selectedRegionValue() {
      if (this.isSelectedRegion) {
        return this.locationsList.find(({ value }) => value === this.recruitmentFormValues.region).caption;
      } else {
        return '';
      }
    },

    isSelectedSpecialization() {
      return this.recruitmentFormValues.specialization !== '' && this.recruitmentFormValues.specialization !== '0'
    },

    selectedSpecializationValue() {
      if (this.isSelectedSpecialization) {
        return this.specializationsList.find(({ value }) => value === this.recruitmentFormValues.specialization).caption;
      } else {
        return '';
      }
    },

    isSelectedType() {
      return this.recruitmentFormValues.type !== '' && this.recruitmentFormValues.type !== '0'
    },

    selectedTypeValue() {
      if (this.isSelectedType) {
        return this.contractTypeList.find(({ value }) => value === this.recruitmentFormValues.type).caption;
      } else {
        return '';
      }
    },
  },

  watch: {
    recruitmentFormValues: {
      handler() {
        // store filter params
        this.$store.commit(STORE_MODULES.RECRUITMENT + '/' + MUTATIONS_CONSTANTS.SET_RECRUITMENT_FILTER_LOCATION, this.recruitmentFormValues.region);
        this.$store.commit(STORE_MODULES.RECRUITMENT + '/' + MUTATIONS_CONSTANTS.SET_RECRUITMENT_FILTER_SPECIALIZATION, this.recruitmentFormValues.specialization);
        this.$store.commit(STORE_MODULES.RECRUITMENT + '/' + MUTATIONS_CONSTANTS.SET_RECRUITMENT_FILTER_TYPE, this.recruitmentFormValues.type);
        this.$store.commit(STORE_MODULES.RECRUITMENT + '/' + MUTATIONS_CONSTANTS.SET_RECRUITMENT_FILTER_KEYWORD, this.recruitmentFormValues.search);

        if (this.validateJobSearch()) {
          this.getSumOfMatchingJobs();
        } else {
          this.recentMatches = 0;
        }
      },
      deep: true
    },
  },

  async mounted() {
    await this.getRegistries();
    await this.getWatchdogs();
    this.$store.commit(STORE_MODULES.RECRUITMENT + '/' + MUTATIONS_CONSTANTS.SET_RECRUITMENT_RESULT_DATA, {});
    this.netConnection = window.navigator.onLine;
  },

  methods: {
    scrollToTop() {
      let pageTop = document.getElementById('vue-page-default');
      let scrollContainer = document.getElementById('vue-b-page-content');

      Scroller.scrollTo(pageTop, 500, {container: scrollContainer});
    },

    submitJobSearch() {
      if (this.validateJobSearch()) {
        this.sendSearchRequest();
      } else {
        this.scrollToTop();
      }
    },

    validateJobSearch() {
      let formData = this.recruitmentFormValues;
      this.validationErrors = [];
      if (!formData.search && !formData.region && !formData.type && !formData.specialization) {
        this.validationErrors.push('Prosím zadejte alespoň jedno vyhledávací kritérium');
        return false;
      }
      return true;
    },

    getSumOfMatchingJobs() {
      this.searchRequestSending = true;
      this.searchRequestFailed = false;
      dataLoader
        .fetchRecruitmentResultsCount(this.parseFormToUrl())
        .then((countData) => {
          if (typeof countData === 'undefined') {
            throw Error('Wrong count response format!');
          }

          this.recentMatches = parseInt(countData.count);
          this.searchRequestSending = false;
        });
    },

    parseFormToUrl() {
      let formValues = this.recruitmentFormValues;
      return Object.keys(formValues).map(function(key) {
        if(formValues[key].length > 0) {
          return key + '=' + formValues[key];
        } else {
          return key + '=0';
        }
      }).join('&');
    },

    sendSearchRequest() {
      this.$router.push({ name: 'recruitment-listing' });
    },

    async getRegistries() {
      this.searchRequestSending = true;
      this.searchRequestFailed = false;

      dataLoader
        .fetchRecruitmentRegistries()
        .then((registriesData) => {

          if (typeof registriesData === 'undefined') {
            throw Error('Wrong registries response format!');
          }

          this.$store.commit(STORE_MODULES.RECRUITMENT + '/' + MUTATIONS_CONSTANTS.SET_RECRUITMENT_REGISTRIES, registriesData);
          let tmpRegions = [];
          let registriesRegions = registriesData.regions;

          tmpRegions.push({'value':'0', 'caption':'Nerozhoduje'});
          Object.keys(registriesRegions).forEach(function (value) {

            let tmpRegion = {'value': registriesRegions[value].id, 'caption': registriesRegions[value].name};
            tmpRegions.push(tmpRegion);
          });

          this.locationsList = tmpRegions;
          let tmpSpecializations = [];
          let registriesSpecializations = registriesData.specializations;

          tmpSpecializations.push({'value':'0', 'caption':'Nerozhoduje'});

          Object.keys(registriesSpecializations).forEach(function (value) {
            let tmpSpecialization = {'value': registriesSpecializations[value].id, 'caption': registriesSpecializations[value].name};
            tmpSpecializations.push(tmpSpecialization);
          });

          this.specializationsList = tmpSpecializations;
          let tmpTypes = [];
          let registriesTypes = registriesData.types;
          tmpTypes.push({'value':'0', 'caption':'Nerozhoduje'});

          Object.keys(registriesTypes).forEach(function (value) {
            let tmpType = {'value': registriesTypes[value].id, 'caption': registriesTypes[value].name};
            tmpTypes.push(tmpType);
          });

          this.contractTypeList = tmpTypes;
          this.searchRequestSending = false;

        }).then(() => {

          this.recruitmentFormValues.region = this.recruitment.filterLocation === null ? '' : this.recruitment.filterLocation;
          this.recruitmentFormValues.specialization = this.recruitment.filterSpecialization === null ? '' : this.recruitment.filterSpecialization;
          this.recruitmentFormValues.type = this.recruitment.filterType === null ? '' : this.recruitment.filterType;
          this.recruitmentFormValues.search = this.recruitment.filterKeyword === null ? '' : this.recruitment.filterKeyword;

        });
    },

    sendWatchdogCreate() {
      this.searchRequestSending = true;
      this.searchRequestFailed = false;
      this.sending = true;

      let tmpName = '';
      if (this.recruitmentFormValues.search !== '') {
        tmpName = this.recruitmentFormValues.search;
      } else {
        if (this.isSelectedSpecialization) {
          tmpName += tmpName === '' ? this.selectedSpecializationValue : ', ' + this.selectedSpecializationValue;
        }
        if (this.isSelectedRegion) {
          tmpName += tmpName === '' ? this.selectedRegionValue : ', ' + this.selectedRegionValue;
        }
        if (this.isSelectedType) {
          tmpName += tmpName === '' ? this.selectedTypeValue : ', ' + this.selectedTypeValue;
        }
      }

      dataLoader
        .sendJobWatchdog(
          tmpName,
          this.recruitmentFormValues.region,
          this.recruitmentFormValues.specialization,
          this.recruitmentFormValues.type,
          this.recruitmentFormValues.search)
        .then(() => {
          this.searchRequestSending = false;
          this.sending = false;
          this.getWatchdogs();
        });

    },

    async getWatchdogs() {
      this.searchRequestSending = true;
      this.searchRequestFailed = false;

      dataLoader
        .fetchJobWatchdogs()
        .then((data) => {
          this.activeWatchdogs = data;
          this.searchRequestSending = false;
        });
    },

    clearForm() {
      this.recruitmentFormValues.region = '';
      this.recruitmentFormValues.specialization = '';
      this.recruitmentFormValues.type = '';
      this.recruitmentFormValues.search = '';
    },

    submitShowWatchdog(event) {
      this.showGroupMenu = true;

      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;

      if (this.isDesktopLayout) {
        this.contextContainerPositions = {
          x: event.pageX,
          y: event.pageY,
          mode: 'top-left'
        }
      } else {
        this.contextContainerPositions = false
      }
    },

    submitNewWatchdog() {
      this.sendWatchdogCreate();
    },

    async submitRemoveWatchdog(id) {
      this.sending = true;
      await dataLoader.deleteJobWatchdog(id).then(() => {
        this.getWatchdogs();
        this.sending = false;
      });
    },

    getRegionName(regionId) {
      return this.locationsList.find(({ value }) => value === regionId).caption;
    },

    getSpecializationName(specializationId) {
      return this.specializationsList.find(({ value }) => value === specializationId).caption;
    },

    getTypeName(typeId) {
      return this.contractTypeList.find(({ value }) => value === typeId).caption;
    },
  }
}
</script>
