import Frm1033DropdownButton from './frm1033-dropdown-button';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1033DropdownButton);
    }
};

use(Plugin);

export default Plugin;

export { Frm1033DropdownButton };
