export const DATA_ARRAY = Object.freeze({
  slides: [
    {
      'cs': {
        'title': 'Jak dosáhneme našich cílů',
        'items': {
          1: {
            'title': 'Lidé: My v ČSOB a PEARL+',
            'subtitle': 'Skupina ČSOB je místem pro úspěšné lidi s vášní pro klienty.',
            'color': 'color-1',
            'colorLight': 'color-1',
            'contentItems': [
              {
                'id': 1,
                'header': 'Angažovaní lidé',
                'items': [
                  {
                    'id': 1,
                    'body': 'Rozvíjíme klíčové dovednosti, které jako firma potřebujeme pro to, abychom mohli realizovat naše strategické cíle. Budujeme s našimi lidmi dlouhodobý vztah a nabízíme jim efektivní způsoby rekvalifikace, ať už se bude jednat o zaměstnance, jejichž pracovní náplň se mění či mizí, nebo ty, kteří se prostě chtějí posouvat dále. Zintenzivníme spolupráci s vysokými školami a s Czechitas, strategickým partnerem pro získávání talentů do oblastí IT/Data/Digital.',
                  },
                  {
                    'id': 2,
                    'body': 'Systematicky nasloucháme našim lidem a děláme konkrétní kroky na základě jejich zpětné vazby z pravidelných zaměstnaneckých průzkumů, stejně jako z adaptačních a exit rozhovorů a dotazníků.',
                  },
                  {
                    'id': 3,
                    'body': '<strong>Aktuálně</strong><br>Spustili jsme nový HR systém ke správě vzdělávání – Edoceo, je uživatelsky jednodušší než systém předchozí, a umožní nám mimo jiné efektivně sdílet informace o školení mezi jednotlivými entitami skupiny, což umožní hladké přechody zaměstnanců bez nutnosti absolvovat podobná školení znovu. Již jsme zahájili jednání s entitami o možném připojení do skupinového LMS (Learning Management System). V novém HR systému najdete přehlednou nabídku osvědčených školení. Navýšili jsme výrazně odměny v referenčním programu a spustili jsme onboarding v Zapce, který pomůže nově nastupujícím kolegům v jejich hladké adaptaci. Pro naše kolegy jsme připravili balíček „Průvodce změnou – podpora v náročných situacích“, který nabízí různé formy individuální i týmové pomoci, a pokračujeme s podporou, poskytovanou manažerům při práci s výsledky zaměstnaneckého průzkumu.',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Inspirující lídři',
                'items': [
                  {
                    'id': 1,
                    'body': 'Rozvíjíme manažerské dovednosti v ucelené nabídce rozvojových programů (Next Level Leadership pro top management, Start Level Leadership pro širokou manažerskou populaci nebo Autentický Leadership pro aktivaci ženského a mužského principu ve vedení lidí) a podporujeme dovednosti potřebné k efektivní práci s vedením týmů v hybridním způsobu práce. ',
                  },
                  {
                    'id': 2,
                    'body': 'Systematicky se věnujeme rozvoji našich talentů, ať už s manažerským nebo expertním potenciálem, a aktivně podporujeme zpětnou vazbu 360 a práci s individuálními rozvojovými plány.',
                  },
                  {
                    'id': 3,
                    'body': '<strong>Aktuálně</strong><br>Programem pro rozvoj manažerských dovedností prošlo již 30 manažerů (Start level). Celkem 60 účastníků prochází letos programem Autentický leadership, a pokračujeme také v Next Level Leadership programu pro top management a talenty. V rámci podpory kultury zpětné vazby doporučujeme všem manažerům, aby si prošli 360 zpětnou vazbou, kterou zdarma nabízíme v HR systému.<br>V letošním roce jsme nabídli rozvojové aktivity celkem více než 300 talentům. Zaměřujeme se na podporu networkingu a rozvoj ve vybraných tématech, navázaných na téma růstového myšlení.'
                  }
                ],
              },
              {
                'id': 3,
                'header': 'Flexibilní organizace',
                'items': [
                  {
                    'id': 1,
                    'body': '„Flexibility Next Level“ – aplikujeme hybridní způsoby práce, hledáme efektivní využití našich budov po covidu a podporujeme částečné úvazky.',
                  },
                  {
                    'id': 2,
                    'body': 'Nadále v naší skupině podporujeme diverzitu a inkluzi ve všech podobách. Zaměřujeme se na to, aby odchod na mateřskou či rodičovskou neznamenal „na shledanou za 3 roky“, ale aby již během ní bylo normální být v kontaktu s firmou a nacházet vhodná pracovní uplatnění.',
                  },
                  {
                    'id': 3,
                    'body': '<strong>Aktuálně</strong><br>Rodičům na mateřské či rodičovské dovolené nově nabízíme účast v Akademii pro rodiče. Cílem akademie je připravit rodiče na plynulý a rychlejší návrat zpět do práce a aktivně navázat kontakty mezi rodiči, manažery a HR. Pilotní běh proběhl v Hradci Králové a zúčastnilo se ho 14 maminek, z nichž velká část již během Akademie začala pracovat. Od podzimu akademii budeme nabízet na centrále, ve Středočeském kraji, a postupně ve všech regionech.'
                  }
                ],
              },
              {
                'id': 4,
                'header': 'Férové odměňování',
                'items': [
                  {
                    'id': 1,
                    'body': 'Zrevidujeme systém odměňování a zharmonizujeme ho v rámci naší skupiny, abychom našim zaměstnancům nabízeli férový a atraktivní balíček celkové odměny postavený na systému řízení výkonu.',
                  },
                  {
                    'id': 2,
                    'body': 'Budeme nadále snižovat tzv. Gender Pay Gap částečnou valorizací maminek v průběhu mateřské a rodičovské dovolené a podporou managementu při prosazování principů spravedlivého odměňování.',
                  },
                  {
                    'id': 3,
                    'body': '<strong>Aktuálně</strong><br>Zrevidovali jsme pozice a jejich ohodnocení v rámci celé skupiny ČSOB a částečně jsme snížili Gender Pay Gap promítnutím valorizace do mezd maminek na mateřské a rodičovské dovolené.<br>Připravili jsme školení pro manažery na oblast Odměňování. Doposud jsme proškolili 50 manažerů a do konce roku máme v plánu přizvat k tématu dalších 100 manažerů.'
                  }
                ],
              },
              {
                'id': 5,
                'header': 'Naše firemní kultura PEARL+',
                'subheader': 'Žijeme firemní kulturu PEARL+',
                'pearls': [
                  {
                    'id': 1,
                    'header': 'Performance: CHCEME VÍTĚZIT',
                    'items': [
                      {
                        'id': 1,
                        'body': 'Co slíbíme, to dodáme.',
                      }
                    ],
                  },
                  {
                    'id': 2,
                    'header': 'Empowerment: MÁME ODVAHU',
                    'items': [
                      {
                        'id': 1,
                        'body': 'Podporujeme kreativitu a talent každého zaměstnance.',
                      }
                    ],
                  },
                  {
                    'id': 3,
                    'header': 'Accountability: PŘIJÍMÁME ODPOVĚDNOST',
                    'items': [
                      {
                        'id': 1,
                        'body': 'Přijímáme osobní odpovědnost směrem k našim klientům / kolegům / akcionářům / společnosti, v níž žijeme.',
                      }
                    ],
                  },
                  {
                    'id': 4,
                    'header': 'Responsiveness: VNÍMÁME DRUHÉ',
                    'items': [
                      {
                        'id': 1,
                        'body': 'Vnímáme potřeby klientů a kolegů a jsme otevření jejich názorům a připomínkám.',
                      }
                    ],
                  },
                  {
                    'id': 5,
                    'header': 'Local embeddedness: JSME TU DOMA',
                    'items': [
                      {
                        'id': 1,
                        'body': 'Ctíme diverzitu našich týmů a klientů na různých trzích.',
                      }
                    ],
                  },
                  {
                    'id': 6,
                    'header': '+ SPOLUPRACUJEME',
                    'lettersEscape': 1,
                    'items': [
                      {
                        'id': 1,
                        'body': 'PEARL jsme rozšířili o znaménko „+“. Chceme tím demonstrovat důležitost spolupráce a synergie v celé skupině.',
                      },
                      {
                        'id': 2,
                        'body': 'Podporujeme aktivní dialog mezi zaměstnanci a managementem napříč skupinou ČSOB a celou skupinou KBC.',
                      },
                      {
                        'id': 3,
                        'body': 'Věříme v potenciál efektivnějšího zavádění jednotných řešení napříč společnostmi skupiny KBC.',
                      },
                      {
                        'id': 4,
                        'body': 'To je Team Blue v akci!',
                      },
                    ],
                  },
                  {
                    'id': 7,
                    'header': 'Základem této kultury PEARL+ jsou naše tři zásadní hodnoty:',
                    'bulletExcluded': true,
                    'items': [
                      {
                        'id': 1,
                        'body': '<strong>Respectful</strong>: JSME RESPEKTUJÍCÍ',
                      },
                      {
                        'id': 2,
                        'body': '<strong>Responsive</strong>: JSME VNÍMAVÍ',
                      },
                      {
                        'id': 3,
                        'body': '<strong>Results-driven</strong>: JSME ORIENTOVANÍ NA VÝSLEDEK',
                      }
                    ],
                  },
                ]
              },
            ],
          },
          2: {
            'title': 'Naše udržitelná obchodní řešení',
            'subtitle': '',
            'color': 'color-2',
            'colorLight': 'color-2',
            'contentItems': [
              {
                'id': 1,
                'header': '',
                'bulletExcluded': true,
                'items': [
                  {
                    'id': 1,
                    'body': 'V rámci našeho udržitelného přístupu se snažíme poskytovat finanční řešení a obchodní příležitosti, které mají pozitivní dopad na přírodu a společnost.',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Pro přírodu',
                'items': [
                  {
                    'id': 1,
                    'body': 'S respektem pro přírodu nabízíme šetrné varianty produktů, financujeme energeticky prospěšné projekty, zaměřujeme se na vlastní udržitelnost a zapojujeme se do odborných platforem zaměřených na dekarbonizaci české ekonomiky.',
                  },
                ],
              },
              {
                'id': 3,
                'header': 'Skupinové závazky udržitelného byznysu',
                'items': [
                  {
                    'id': 1,
                    'body': '<ul><li>65 % podíl obnovitelných zdrojů na celkovém energetickém portfoliu do roku 2025</li><li>12 % hrubých prodejů za rok 2022 ve fondech SRI</li><li>13 % podíl úvěrů na úsporné bydlení do roku 2022</li><li>Nabídka šetrných půjček a dluhopisů a poradenství v oblasti udržitelných řešení pro segmenty SME/COR</li></ul>',
                  },
                  {
                    'id': 2,
                    'body': '<strong>Aktuálně</strong><br>Díky cenové pobídce dosáhly úvěry na koupi nového úsporného bydlení za 1. pololetí skvělých 25 % z celkového objemu nových prodejů (6,5 mld. Kč). Předpokládá se, že celkově dosáhneme 10,9 mld. Kč (22 % z celkového objemu) a že tím překonáme letošní cíl.'
                  },
                  {
                    'id': 3,
                    'body': 'Podle počtu smluv jsme prodali 112 vozidel s čistou energií, což odpovídá 2,8 % z celkového počtu. Předpokládáme, že dosáhneme 240 smluv, tzn. 3,0 %.'
                  },
                  {
                    'id': 4,
                    'body': 'Hrubé prodeje fondů odpovědného investování za 1. pololetí rok 2022 dosáhly 1,7 miliardy Kč, tzn. 5,2 % (plán na fiskální rok je 4,9 mld. Kč, tzn. 12%).  Připravuje se akční plán pro dosažení absolutního cíle ke konci roku.'
                  }
                ],
              },
              {
                'id': 4,
                'header': 'Snižujeme naši přímou uhlíkovou stopu',
                'items': [
                  {
                    'id': 1,
                    'body': '<ul><li>Snížíme vlastní emise skleníkových plynů do roku 2030 o více než 80 %.</li><li>Od roku 2021 spotřebováváme 100 % elektřiny z obnovitelných zdrojů.</li><li>Zapojíme naše zaměstnance (např. povinný e-kurz na udržitelnost).</li><li>Podpoříme vzdělávání formou veřejných přednášek a kulatých stolů.</li><li>Představíme kalkulačku CO₂ pro klienty (RET/COR) a pro zaměstnance.</li></ul>',
                  },
                  {
                    'id': 2,
                    'body': 'Podporujeme projekty a aktivity, které přispívají k potřebám místních komunit, ve kterých působíme. Účastníme se sponzorských aktivit, iniciativ a kampaní, které jsou často spojeny s naší strategií.',
                  },
                ],
              },
              {
                'id': 5,
                'header': 'Pro vzdělání',
                'items': [
                  {
                    'id': 1,
                    'body': 'Vzděláváme mladou generaci v oblasti digitální a finanční gramotnosti.',
                  },
                  {
                    'id': 2,
                    'body': '<strong>Aktuálně</strong><br>Prvního ročníku Finanční olympiády, kterou pořádala ČSOB prostřednictvím aplikace Filip – Poznej svět financí se zúčastnilo na 180 škol. Finále se uskutečnilo v budově ČNB. Aplikace Filip a Filipova olympiáda získala 2. místo v soutěži Zlatá koruna v kategorii CSR aktivit. Aplikaci si stáhlo za prvních šest měsíců na 6 tisíc uživatelů. Ve spolupráci s Policií ČR jsme natočili hraný edukativní seriál Tvoje cesta #onlinem, který upozorňuje na nejaktuálnější kybernetické hrozby.',
                  },
                ],
              },
              {
                'id': 6,
                'header': 'Pro podnikání',
                'items': [
                  {
                    'id': 1,
                    'body': 'Podporujeme startupy na lokální úrovni.',
                  },
                  {
                    'id': 2,
                    'body': '<strong>Aktuálně</strong><br>V rámci programu Start-it jsme v letošním roce realizovali 2 vlny akcelerace. Poprvé jsme zadali konkrétní výzvu Green Challenge, abychom podpořili nová řešení v oblasti Udržitelnosti. Podporu získalo šest konkrétních řešení. Současně jsme pokračovali v tradiční akceleraci komerčních startupů, čímž se jejich celkový počet zvýšil na 65. Hospodářské noviny v rámci Inovátora roku ocenili dva naše alumni startupisty v TOP 10 (Michal Gloger z Mebsteru a Martin Rehák z Resistant AI).',
                  },
                ],
              },
              {
                'id': 7,
                'header': 'Pro soužití',
                'items': [
                  {
                    'id': 1,
                    'body': 'Pomáháme seniorům a hendikepovaným.',
                  },
                  {
                    'id': 2,
                    'body': '<strong>Aktuálně</strong><br>Společně s domovem Sue Ryder jsme v červnu spustili vylepšenou a rozšířenou verzi internetového portálu Neztratit se ve stáří (www.neztratitsevestari.cz). Senioři a zejména jejich blízcí zde naleznou velké množství rad, které jim mohou pomoci překonat různá úskalí spojená s vyšším věkem. Pomoci s konkrétními problémy jsou připraveni odborníci i na telefonu nebo osobně.',
                  },
                  {
                    'id': 3,
                    'body': 'Máme radost, jak skvěle se daří našim kolegyním z klientského centra vyřizovat požadavky starších klientů volajících na infolinku banky. Zde jsou klienti ve věku 70 + automaticky přesměrováni na tým speciálně vyškolený pro komunikaci se seniory. Více jak 1400 odbavených telefonátů týdně a nadprůměrná spokojenost klientů potvrzuje potřebu této unikátní služby.',
                  },
                ],
              },
            ],
          },
          3: {
            'title': 'Kate',
            'subtitle': 'Osobní virtuální hlasová asistentka, která proaktivně nabízí personalizovaná řešení na míru.',
            'color': 'color-3',
            'colorLight': 'color-3',
            'contentItems': [
              {
                'id': 1,
                'header': 'Jednička v inovacích ve světě financí',
                'items': [
                  {
                    'id': 1,
                    'body': 'Kate pomáhá našim klientům šetřit čas, peníze a poskytuje skvělý zákaznický zážitek se zaměřením na hlasové ovládání. Je to budoucnost naší komunikace se zákazníky.',
                  },
                  {
                    'id': 2,
                    'body': 'Kate bude přirozenou součástí ostatních digi kanálů.',
                  },
                  {
                    'id': 3,
                    'body': 'Kate se zaměřuje ze 40 % na prodej a ze 60 % na servisní úkony.',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Cíle',
                'items': [
                  {
                    'id': 1,
                    'body': '105 nových případů, v nichž se dá Kate využít (celkem 200).<span class="vue-value-ext">Aktuálně 61.</span>',
                  },
                  {
                    'id': 2,
                    'body': 'Asistentku Kate používá 50 % aktivních uživatelů aplikace Smart.<span class="vue-value-ext">Aktuálně 36 %.</span>',
                  },
                  {
                    'id': 3,
                    'body': 'Kate využívá 30% aktivních uživatelů mobilních služeb CEB.<span class="vue-value-ext">Aktuálně 27 %.</span>',
                  },
                  {
                    'id': 4,
                    'body': 'Letos zaznamenala služba Kate (měřeno od 1. 1. do 15. 8.):<ul><li>500 000 rozhovorů, včetně nezávazných konverzací</li><li>260 000 případů z toho se týkalo bankovních a pojistných služeb,</li><li>70 000 případů z toho bylo úspěšně dokončeno.</li></ul>',
                  },
                ],
              },
            ],
          },
          4: {
            'title': 'Digital Lead Management a Kate pro zaměstnance',
            'subtitle': 'Personalizovaná komunikace založená na datech a podnětech ovlivňujících chování zákazníků – správná zpráva ve správném kanálu a s optimálním načasováním.',
            'color': 'color-4',
            'colorLight': 'color-4',
            'contentItems': [
              {
                'id': 1,
                'header': 'Akce – Lead Management (LM)',
                'items': [
                  {
                    'id': 1,
                    'body': '„Mozek s umělou inteligencí“ nasazený v plném rozsahu v reálném prostředí, pro potřeby Kate a LM',
                  },
                  {
                    'id': 2,
                    'body': 'Technologie Exponea v SB, IB a e-mailech',
                  },
                  {
                    'id': 3,
                    'body': 'Konsolidovaná historie kontaktů a odpovědí',
                  },
                  {
                    'id': 4,
                    'body': 'Data v reálném čase v nástrojích CI (Customer Insight)',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Cíle',
                'items': [
                  {
                    'id': 1,
                    'body': '20-30% podíl LM na obratu Retailu.<span class="vue-value-ext">1Q 2022 14,7 %.</span>',
                  },
                  {
                    'id': 2,
                    'body': '10-15% podíl LM na obratu Vztahového bankovnictví.',
                  },
                  {
                    'id': 3,
                    'body': '5‒7% konverze v digitálních kanálech Retailu.<span class="vue-value-ext">1Q 2022 6,7 %.</span>',
                  },
                  {
                    'id': 4,
                    'body': '2‒3% konverze v digitálních kanálech Vztahového bankovnictví',
                  },
                ],
              },
              {
                'id': 3,
                'header': 'Akce – Kate pro zaměstnance',
                'items': [
                  {
                    'id': 1,
                    'body': 'Asistence s hypotékami, penzijním spořením a životním pojištěním',
                  },
                  {
                    'id': 2,
                    'body': 'Pomoc s přípravou na schůzku s klientem ',
                  },
                  {
                    'id': 3,
                    'body': 'Zobrazení historie komunikace mezi zákazníkem a Kate',
                  },
                  {
                    'id': 4,
                    'body': 'Pilotní projekt workforce managementu',
                  },
                  {
                    'id': 5,
                    'body': '<strong>Aktuálně</strong><div style="margin-top:14px;">Ve 2. čtvrtletí použilo Kate téměř 2,5 tisíce uživatelů.</div><ul><li>Dařilo se nám hlavně v oblasti pojištění – 475 pojištění domácnosti (40% konverze), 132 pojištění auta (30% konverze) a 318 životních pojištění (25% konverze).</li><li>Připravili jsme první tipy pro FOP (v obsluze RET). Kate dnes počítá tipy na úvěrové produkty u 13 tisíc klientů a obohacuje je o 39 hyper-personalizovaných argumentů.</li><li>V tipech od Kate lze jednodušeji vyhledávat pomocí filtrů. V CRM máme vylepšený reporting pro manažery.</li></ul>',
                  },
                ],
              },
              {
                'id': 4,
                'header': 'Cíle',
                'items': [
                  {
                    'id': 1,
                    'body': 'Kate pro zaměstnance na pobočkách České pošty',
                  },
                  {
                    'id': 2,
                    'body': '12 případů pro Retail',
                  },
                  {
                    'id': 3,
                    'body': '5 případů  pro Vztahové bankovnictví',
                  },
                ],
              },
            ],
          },
          5: {
            'title': 'Distribution model next level (DMNL)',
            'subtitle': 'Přesuneme jednoduché prodejní a servisní úkony z fyzických do digitálních kanálů, zrychlíme využívání digitálních řešení ze strany zákazníků.',
            'color': 'color-5',
            'colorLight': 'color-5',
            'contentItems': [
              {
                'id': 1,
                'header': 'Retail',
                'bulletExcluded': true,
                'items': [
                  {
                    'id': 1,
                    'body': '<strong>Fyzická distribuce v Retailu se blíží k naplnění modelu digital first</strong>',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Lidský přístup',
                'items': [
                  {
                    'id': 1,
                    'body': 'Zdvojnásobíme počet osobních poradců a specialistů podporovaných Kate pro zaměstnance. Přesuneme prodej jednoduchých produktů do digitálních kanálů. Snížíme počet ručně poskytovaných služeb.',
                  },
                  {
                    'id': 2,
                    'body': 'Motivační schéma zaměříme na poradenství, komplexní produkty a podporu digitalizace služeb a prodejů jednoduchých produktů.',
                  },
                  {
                    'id': 3,
                    'body': 'Zavedeme novou službu Meeter greeter na 50 % poboček.',
                  },
                  {
                    'id': 4,
                    'body': 'Automatizujeme pokladní služby – místo pokladen recyklační bankomaty.',
                  },
                  {
                    'id': 5,
                    'body': 'Zvýšíme počet poradců a snížíme počet pracovních míst zaměřených na servisní úkony.',
                  },
                  {
                    'id': 6,
                    'body': 'K4E se stává klíčovým manažerským nástrojem pro řízení práce a podporuje zákaznický zážitek v rámci našeho poradenského konceptu.',
                  },
                ],
              },
              {
                'id': 3,
                'header': 'Cíle',
                'items': [
                  {
                    'id': 1,
                    'body': 'Prodejní kapacita určená pro jednoduchý prodej a poskytování služeb: 2022 – 20-35 %;<span class="vue-value-ext">1Q 2022 41 %.</span>',
                  },
                  {
                    'id': 2,
                    'body': 'Prodejní kapacita určená pro komplexní prodej a poskytování služeb: 2022 – +75 %;<span class="vue-value-ext">1Q 2022 59 %.</span>',
                  },
                ],
              },
              {
                'id': 4,
                'header': 'Digitál',
                'items': [
                  {
                    'id': 1,
                    'body': 'Další růst prostřednictvím digitálních prodejů ve skupině, exkluzivní digitální nabídky, asistovaný prodej a personalizace a nové online procesy.',
                  },
                  {
                    'id': 2,
                    'body': 'Pokračujeme s přesunem služeb a jednoduchého prodeje do digitální formy pomocí tzv. přístupu „shoulder-to-shoulder“ na pobočkách a v klientském centru.',
                  },
                  {
                    'id': 3,
                    'body': 'Rozvíjíme a zavádíme E2E online procesy.',
                  },
                  {
                    'id': 4,
                    'body': 'Dále vylepšujeme automatizované zpracování požadavků zákazníků pomocí hlasového robota.',
                  },
                  {
                    'id': 5,
                    'body': 'Podpoříme stávající a navrhneme nové cílené kampaně a exkluzivní digitální nabídky, které motivují klienty k digitalizaci.',
                  },
                  {
                    'id': 6,
                    'body': 'Průběžně vylepšujeme nabídku služeb na míru a Lead Management prostřednictvím nových technologií (Exponea) a nových prodejních scénářů pro Kate.',
                  },
                  {
                    'id': 7,
                    'body': 'Využíváme příležitosti v rámci skupiny.',
                  },
                ],
              },
              {
                'id': 5,
                'header': 'Cíle v digitální oblasti',
                'items': [
                  {
                    'id': 1,
                    'body': 'Procento digitálního prodeje E2E na celkových prodejích</br></br> <ul><li>všechny produkty: 2022 – 30-60 %</li><li>1Q 2022 - 42 %</li></ul><br><ul><li>jednoduché produkty: 2022 – 60-80 %</li><li>1Q 2022 - 51 %</li></ul>',
                  },
                ],
              },
              {
                'id': 6,
                'header': 'Vztahové bankovnictví',
                'bulletExcluded': true,
                'items': [
                  {
                    'id': 1,
                    'body': '<strong>Prodej jednoduchých produktů samoobslužně prostřednictvím CEB, nový obslužný model v segmentu SME.</strong>',
                  },
                ],
              },
              {
                'id': 7,
                'header': 'Lidský přístup',
                'items': [
                  {
                    'id': 1,
                    'body': 'Zavedeme nový provozní model v SME, včetně návrhu a zavedení prodeje a poskytování služeb digitálně.',
                  },
                  {
                    'id': 2,
                    'body': 'Zavedeme nové cloudové CRM jako klíčový nástroj pro DMNL a použijeme infrastrukturu Retailu na Lead Management včetně K4E.',
                  },
                  {
                    'id': 3,
                    'body': 'Rozšíříme digi akademii a digi koučink pro přesun jednoduchých prodejních a servisních úkonů do digitálních kanálů.',
                  },
                  {
                    'id': 4,
                    'body': 'V obchodní síti začneme používat tablety.',
                  },
                  {
                    'id': 5,
                    'body': 'Začneme používat nové jednoduché procesy mezi pobočkami a back office založené na M365 technologii (více než 20 v přípravě).',
                  },
                  {
                    'id': 6,
                    'body': 'Prodejní kapacita určená pro komplexní prodejní a servisní úkony <table><tr><th></th><th>2021</th><th>2022</th></tr><tr><td>Micro-SME</td><td><strong>57%</strong></td><td><strong>+60%</strong></td></tr><tr><td>SME</td><td><strong>67%</strong></td><td><strong>+70%</strong></td></tr><tr><td>COR</td><td><strong>80 %</strong></td><td><strong>+85%</strong></td></tr></table>',
                  },
                ],
              },
              {
                'id': 8,
                'header': 'Digitál',
                'items': [
                  {
                    'id': 1,
                    'body': 'Rozšíříme mobilní aplikaci CEB, včetně nových funkcí, jako je Virtuální pobočka CEB v mobilní aplikaci.',
                  },
                  {
                    'id': 2,
                    'body': 'Podpoříme růst počtu klientů schopných využívat digitální řešení v CEB, včetně virtuální pobočky a jednoduššího elektronického podepisování pomocí Smart klíče.',
                  },
                  {
                    'id': 3,
                    'body': 'Připravujeme nový účet Smart/Digital.',
                  },
                  {
                    'id': 4,
                    'body': 'Budeme rozvíjet API pro CEB, s cílem rozšířit možnosti integrace služeb CEB s ERP ze strany klientů.',
                  },
                  {
                    'id': 5,
                    'body': 'Podpoříme další rozvoj a zavádění E2E STP procesů.',
                  },
                  {
                    'id': 6,
                    'body': 'Umožníme založení digitálního účtu pomocí BankID a Caroline.',
                  },
                  {
                    'id': 7,
                    'body': '<strong>Procento digitálního prodeje na celkových prodejích jednoduchých produktů:  2021 – 31 %; 2022 – 40 %</strong><span class="vue-value-ext">2Q 2022 35-40 %.</span>',
                  },
                ],
              },
            ],
          },
          6: {
            'title': 'Práce s daty',
            'subtitle': 'Poskytování datové infrastruktury pro potřeby Kate, Lead Managementu, DIGI a dalších – datová řešení v reálném čase, inteligentní dashboarding, modely AI atd.',
            'color': 'color-6',
            'colorLight': 'color-6',
            'contentItems': [
              {
                'id': 1,
                'header': 'EDISON',
                'items': [
                  {
                    'id': 1,
                    'body': 'Jedná se o celoskupinovou datovou a analytickou platformu postavenou nad Amazon Web services, zejména pro pokročilou analýzu dat a modely.',
                  },
                  {
                    'id': 2,
                    'body': 'Aktuálně probíhá migrace Machine learningvých/AI modelů v rámci ADAM oddělení, které ji využije místo z dosluhujícího Teradata řešení.',
                  },
                  {
                    'id': 3,
                    'body': 'Nová platforma nám umožní automatizovanější nasazení modelů pro Kate a přinese nové možnosti např. při zpracování dokumentů.',
                  },
                  {
                    'id': 4,
                    'body': 'Je to platfoma, která bude využita pro další využití napříč skupinou (levné uložení a zálohování některých typů dat, vývojové prostředí pro napojení externích komponent apod.).',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Datové řešení v reálném čase DIH (Data Integration Hub)',
                'items': [
                  {
                    'id': 1,
                    'body': 'Na infrastruktuře vystavěné v roce 2021 realizováno již 27 casů, například:' +
                      '<ul><li>(ve spolupráci s LM) bezprostřední oslovení klienta s nabídkou cestovního pojištění nebo informací, že ho má součástí kreditní karty, jakmile si koupí zájezd v cestovní kanceláři,</li>' +
                      '<li>(prostřednictvím Kate) okamžité oslovení klienta při zamítnuté karetní transakci a nabídka možných řešení,</li>' +
                      '<li>(prostřednictvím Kate) upozornění na nízký zůstatek na účtu a nabídka možných řešení.</li></ul>',
                  },
                  {
                    'id': 2,
                    'body': 'Plánujeme:' +
                      '<ul><li>realizaci dalších casů zejména pro Kate, LM a GroupDIGI,</li>' +
                      '<li>zabezpečení bezodstávkového provozu (i v rámci DIH releasů).</li>' +
                      '</ul>',
                  },
                ],
              },
              {
                'id': 3,
                'header': 'Databáze klientů skupiny ČSOB a rozdělení CZ/SK',
                'items': [
                  {
                    'id': 1,
                    'body': 'Zahájili jsme projekt s cílem oddělení klientské databáze (CMDB) naší skupiny od ČSOB SK.',
                  },
                  {
                    'id': 2,
                    'body': 'Související analýzy již probíhají a jsou či budou v nich zahrnuti kolegové z celé skupiny.',
                  },
                  {
                    'id': 3,
                    'body': 'K fyzickému rozdělení CMDB mezi ČSOB CZ a ČSOB SK by mělo dojít ke konci roku 2024.',
                  },
                ],
              },
              {
                'id': 4,
                'header': 'Rainbow',
                'items': [
                  {
                    'id': 1,
                    'body': 'GO Live pro CRISP1: Výpočet ze skupinové RAY aplikace se stal „master“ a pomalu opouštíme lokální výpočet, který se stal pouze validačním nástrojem. Od 2023 budeme používat pouze centrální RAY.',
                  },
                  {
                    'id': 2,
                    'body': 'Pokračujeme v plánovaných dodávkách pro Sustainability, Talta a Finrep.',
                  },
                  {
                    'id': 3,
                    'body': 'Pokračujeme v budování CROS týmu, který se bude start o nové datové toky a reportovací procesy v Rainbow.',
                  },
                ],
              },
              {
                'id': 5,
                'header': 'Jsme organizace založená na datech',
                'items': [
                  {
                    'id': 1,
                    'body': 'V červnu proběhl Data-X týden, na který v říjnu bude navazovat workshop Datové minimum. V září proběhnul také Data Inspiration day k podpoře „self service data“.',
                  },
                  {
                    'id': 2,
                    'body': 'Připravujeme trainee program + odborné články na podporu datové gramotnosti.',
                  },
                  {
                    'id': 3,
                    'body': 'Pracujeme na prostředí, ve kterém bude možné pracovat s daty s minimální podporou (ale i bez) od datového oddělení.',
                  },
                ],
              },
            ],
          },
          7: {
            'title': 'Straight-Through Processing (STP)',
            'subtitle': 'Digitalizace procesů, aby probíhaly, bez potřeby lidského zásahu.',
            'color': 'color-7',
            'colorLight': 'color-7',
            'contentItems': [
              {
                'id': 1,
                'header': '',
                'bulletExcluded': true,
                'items': [
                  {
                    'id': 2,
                    'body': 'Veškeré procesy související s nabídkou určité služby, jejím schválením a následným zpracováním proběhnou automaticky, bez potřeby lidského zásahu.',
                  },
                  {
                    'id': 3,
                    'body': 'Vytvoříme silné propojení mezi procesem APC a STP metrikami a klíčovými ukazateli výkonnosti (KPI).',
                  },
                  {
                    'id': 4,
                    'body': 'Dokončíme rozhraní mezi aktuálně izolovanými procesy.',
                  },
                  {
                    'id': 5,
                    'body': 'Pravidelně kontrolujeme výkonnost/ziskovost ze strany Financí, pravidelně probíhá NAPP procedura řízená Riskem a kontrola STP procesu.',
                  },
                  {
                    'id': 6,
                    'body': 'Vytvoříme procesní skupinu pro sdílení zkušeností mezi všemi subjekty.',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Cíle',
                'items': [
                  {
                    'id': 1,
                    'body': 'Prohloubíme povědomí o STP v rámci skupiny.',
                  },
                  {
                    'id': 2,
                    'body': 'Poměr STP – produkty zpracované bez lidského zásahu ze všech produktů nabízených digitálně.',
                  },
                  {
                    'id': 3,
                    'body': '<table><tr><th align="center"><strong><br>4Q 2021</strong></th><th align="center"><strong><br>2Q 2022</strong></th><th align="center"><strong>Plán<br>4Q 2022</strong></th><th align="center"><strong>Plán<br>2023</strong></th></tr><tr><td>27,5 %</td><td>44,8 %</td><td>46,3 %</td><td>60 %</td></tr></table>'
                  }
                ],
              },
            ],
          },
        }
      },
      'en': {
        'title': 'Ways to achieve our goal',
        'items': {
          1: {
            'title': 'People: We at ČSOB and PEARL+',
            'subtitle': '',
            'color': 'color-1',
            'colorLight': 'color-1',
            'contentItems': [
              {
                'id': 1,
                'header': 'Engaged people',
                'items': [
                  {
                    'id': 1,
                    'body': 'We are developing key skills for the future to make our strategic goals happen. We are providing reskilling for employees whose current jobs are to disappear or who just want to move further. We are intensifying cooperation with universities and with Czechitas, our strategic partner for attracting talents to IT/Data/Digital.',
                  },
                  {
                    'id': 2,
                    'body': 'We are continuously listening to our people and act on the basis of the feedback from employee surveys as well as adaptation interviews and exit interviews.',
                  },
                  {
                    'id': 3,
                    'body': '<strong>Currently</strong><br>We have launched Edoceo, a new HR training management system that is easier to use than the previous system and which will allow us to effectively share training information between the group\'s entities, enabling smooth employee transitions without the need to undergo similar training again. We have already started discussions with entities about possible connection to the group LMS(Learning Management System). The new HR system provides a clear offer of proven training courses. We have significantly increased the rewards in the reference programme and have launched onboarding in Zapka to help new colleagues with their smooth orientation. We have developed the “Change Guide - Support in Challenging Situations” package for our colleagues, which offers various forms of individual and team support, and we are continuing with the support provided to managers when working with employee survey results.',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Inspiring leaders',
                'items': [
                  {
                    'id': 1,
                    'body': 'We are developing leadership skills in a comprehensive offer of learning and development programmes (Next Level Leadership for senior management, Start Level Leadership for the broad management population or Authentic Leadership for activating male and female principles in leadership) and supporting the skills needed for effective leadership in a hybrid way of working.',
                  },
                  {
                    'id': 2,
                    'body': 'We are developing our talents, both with leadership and expert potential, and we are actively promoting 360 feedbacks and work with individual development plans.',
                  },
                  {
                    'id': 3,
                    'body': '<strong>Currently</strong><br>Thirty managers (Start level) have already been through the programme for the development of managerial skills. A total of 60 participants are going through the Authentic Leadership programme this year, and we are also continuing the Next Level Leadership programme for top management and talented employees. To promote a culture of feedback, we encourage all managers to go through the 360 Feedback programme offered for free in the HR system.<br>This year, we offered development activities to more than 300 talented employees. We focus on promoting networking and development in selected topics related to the theme of growth mindset.',
                  },
                ],
              },
              {
                'id': 3,
                'header': 'Flexible organisation',
                'items': [
                  {
                    'id': 1,
                    'body': 'Flexi Next Level strategy – apply a hybrid working mode, look for efficient usage of our offices post-covid, and support part-time work.',
                  },
                  {
                    'id': 2,
                    'body': 'We are further embedding diversity and inclusion of all kinds in the organisation. We focus on a “ Goodbye for three years” strategy for working parents.',
                  },
                  {
                    'id': 3,
                    'body': '<strong>Currently</strong><br>Parents on maternity or parental leave are now offered the opportunity to attend the Academy for Parents, the aim of which is to prepare parents for a smooth and quick return to work and to actively foster contacts between parents, managers and HR. The pilot run took place in Hradec Králové and was attended by 14 mothers, many of whom had already started working during the Academy. Starting in the autumn, we will offer the Academy at our headquarters in the Central Bohemian Region before gradually spreading it to all regions.',
                  },
                ],
              },
              {
                'id': 4,
                'header': 'Fair reward',
                'items': [
                  {
                    'id': 1,
                    'body': 'We will review and harmonise the remuneration system within the CSOB Group to have for our employees a fair and attractive total package driven by a performance reward system.',
                  },
                  {
                    'id': 2,
                    'body': 'We will further lower the Gender Pay Gap by a partial salary review for mothers on maternity leave and by supporting management in the promotion of fair reward principles.',
                  },
                  {
                    'id': 3,
                    'body': '<strong>Currently</strong><br>We have revised positions and their remuneration across the entire ČSOB Group and partially reduced the Gender Pay Gap by reflecting the adjustments in the wages of mothers on maternity and parental leave.<br>We have prepared a training course for managers on the subject of Remuneration. To date we have trained 50 managers and plan to invite another 100 managers by the end of the year.',
                  },
                ],
              },
              {
                'id': 5,
                'header': 'Our PEARL+ culture',
                'subheader': 'We live our company culture PEARL+',
                'pearls': [
                  {
                    'id': 1,
                    'header': 'Performance',
                    'items': [
                      {
                        'id': 1,
                        'body': 'What we promise, we deliver.',
                      },
                    ],
                  },
                  {
                    'id': 2,
                    'header': 'Empowerment',
                    'items': [
                      {
                        'id': 1,
                        'body': 'We foster creativity and talent of each employee.',
                      },
                    ],
                  },
                  {
                    'id': 3,
                    'header': 'Accountability',
                    'items': [
                      {
                        'id': 1,
                        'body': 'We take personal responsibility to wards our clients / colleagues / shareholders / communities.',
                      },
                    ],
                  },
                  {
                    'id': 4,
                    'header': 'Responsiveness',
                    'items': [
                      {
                        'id': 1,
                        'body': 'We act responsively to the needs of our clients and colleagues and we are open to their opinions and suggestions.',
                      },
                    ],
                  },
                  {
                    'id': 5,
                    'header': 'Local embeddedness',
                    'items': [
                      {
                        'id': 1,
                        'body': 'We embrace diversity of our teams and clients in different markets.',
                      },
                    ],
                  },
                  {
                    'id': 6,
                    'header': '+ for Collaboration',
                    'lettersEscape': 5,
                    'items': [
                      {
                        'id': 1,
                        'body': 'We have added a + sign to PEARL to demonstrate the importance of cooperation and synergy at the group level.',
                      },
                      {
                        'id': 2,
                        'body': 'We support active dialogue amlng employees and management across CSOB group and the whole KBC group.',
                      },
                      {
                        'id': 3,
                        'body': 'We trust in the potential of faster, more efficient implementation of single solutions across KBC group companies.',
                      },
                      {
                        'id': 4,
                        'body': 'This is Team Blue in action!',
                      },
                    ],
                  },
                  {
                    'id': 7,
                    'header': 'Foundation of our PEARL+ culture are our three core values',
                    'bulletExcluded': true,
                    'items': [
                      {
                        'id': 1,
                        'body': '<strong>Respectful</strong>',
                      },
                      {
                        'id': 2,
                        'body': '<strong>Responsive</strong>',
                      },
                      {
                        'id': 3,
                        'body': '<strong>Results-driven</strong>',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          2: {
            'title': 'Our sustainable business solutions',
            'subtitle': '',
            'color': 'color-2',
            'colorLight': 'color-2',
            'contentItems': [
              {
                'id': 1,
                'header': '',
                'bulletExcluded': true,
                'items': [
                  {
                    'id': 1,
                    'body': 'As part of our sustainable approach, we strive to provide financial solutions and business opportunities that have a positive impact on nature and society.',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'For nature',
                'items': [
                  {
                    'id': 2,
                    'body': 'With <strong>respect to The Nature</strong> we offer green product variants, fund energy-saving projects and pay attention to sustainability.',
                  },
                ],
              },
              {
                'id': 3,
                'header': 'Sustainable business commitments',
                'items': [
                  {
                    'id': 1,
                    'body': '<ul><li>65% share of renewables in total energy portfolio by 2025</li><li>12% of gross sales in 2022 in SRI funds</li><li>13% share of loans for economical housing by 2022</li><li>SME/COR Green loans and green bonds, advisory</li></ul>',
                  },
                  {
                    'id': 2,
                    'body': '<strong>Currently</strong><br>Loans for Economical Housing new sales reached attractive 25% of total new sales in 1H22 (≈ CZK 6,5bn) driven by price incentive. Forecast is to reach CZK 10,9bn (≈ 22% of total) and therefore overcome this year\'s target.',
                  },
                  {
                    'id': 3,
                    'body': 'New sales of Clean energy cars (# of contracts) reached 112 cars ≈ 2,8% of total aiming for more ambitious forecast of 240 contracts ≈ 3,0%.',
                  },
                  {
                    'id': 4,
                    'body': 'Gross sales of Responsible Investments funds for 1H22 reached CZK 1,7bn ≈ 5,2% (vs. FY plan CZK 4,9bn ≈ 12%). Action plan is being prepared to reach the EoY absolute target.',
                  },
                ],
              },
              {
                'id': 4,
                'header': 'We are reducing our carbon footprint',
                'items': [
                  {
                    'id': 1,
                    'body': '<ul><li>80%+ reduction of own GHG emissions by 2030</li><li>100% consumption of green electricity since 2021 onwards</li><li>Employee engagement (compulsory SUS e-course)</li><li>Education on the topic in the form of public lectures and round tables + CO2 calculator for clients (RET/COR) and employees</li></ul>',
                  },
                  {
                    'id': 2,
                    'body': 'We support projects and activities that contribute to the needs of the local communities in which we operate. We take part in sponsorship activities, initiatives and campaigns that are often directly linked to our strategy.',
                  },
                ],
              },
              {
                'id': 5,
                'header': 'For Education',
                'items': [
                  {
                    'id': 1,
                    'body': 'Educating young generation in digital and financial literacy',
                  },
                  {
                    'id': 2,
                    'body': '<strong>Currently</strong><br>A total of 180 schools participated in the first year of the Financial Olympics organised by ČSOB through the Filip – Get to Know the World of Finance app. The final took place in the ČNB building. The Filip application and the Filip Olympics won 2nd place in the Golden Crown competition in the category of Corporate Social Responsibility (CSR) activities. The app has been downloaded by 6,000 users in the first six months. In cooperation with the Police of the Czech Republic, we have filmed an educational series called Tvoje cesta #onlinem, which highlights the latest cyber threats.',
                  },
                ],
              },
              {
                'id': 6,
                'header': 'For Business',
                'items': [
                  {
                    'id': 1,
                    'body': 'Encourageing and supporting local startups',
                  },
                  {
                    'id': 2,
                    'body': '<strong>Currently</strong><br>This year we have implemented two waves of acceleration within the Start-it programme. For the first time, we have launched a specific Green Challenge to encourage new solutions in the field of Sustainability. Six specific solutions were supported. At the same time, we continued our traditional acceleration of commercial startups, bringing their total number to 65. Hospodarske noviny awarded two of our alumni startups in the TOP 10 (Michal Gloger from Mebster and Martin Rehák from Resistant AI) in its Innovator of the Year competition.',
                  },
                ],
              },
              {
                'id': 7,
                'header': 'For Longevity',
                'items': [
                  {
                    'id': 1,
                    'body': 'Helping the elderly and handicapped',
                  },
                  {
                    'id': 2,
                    'body': '<strong>Currently</strong><br>Together with the Sue Ryder Home, we launched an improved and expanded version of the Neztratit se ve stáří (Don\'t Get Lost in Old Age) web portal(www.neztratitsevestari.cz) in June. Seniors, and especially their loved ones, will find a wealth of advice here that can help them overcome the various challenges associated with older age. Experts are also available over the phone or in person to help with specific problems.',
                  },
                  {
                    'id': 3,
                    'body': 'We are delighted with how well our colleagues from the Client Centre are handling the requests of older clients calling the bank\'s hotline, where clients aged 70+ are automatically directed to a team specially trained to communicate with the elderly. More than 1,400 answered calls per week and above average client satisfaction confirms the need for this unique service.',
                  },
                ],
              },
            ],
          },
          3: {
            'title': 'Kate',
            'subtitle': 'Personal digital assistant, mobile first, voice first, proactively providing personalised and relevant solutions.',
            'color': 'color-3',
            'colorLight': 'color-3',
            'contentItems': [
              {
                'id': 1,
                'header': '#1 innovation for finances and daily life',
                'items': [
                  {
                    'id': 1,
                    'body': 'Kate helps our clients to save time and money and provides a great customer experience, focusing on voice control: "the future of customer interaction"',
                  },
                  {
                    'id': 2,
                    'body': 'Experience in Kate and digital channels will blend seamlessly',
                  },
                  {
                    'id': 3,
                    'body': 'Kate is 40% sales and 60% services',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Goals',
                'items': [
                  {
                    'id': 1,
                    'body': '105 new use cases (total of 200).<span class="vue-value-ext">Currently 61.</span>',
                  },
                  {
                    'id': 2,
                    'body': 'Kate used by 50% of active Smart users.<span class="vue-value-ext">Currently 36%.</span>',
                  },
                  {
                    'id': 3,
                    'body': 'Kate used by 30% of active CEB mobile users.<span class="vue-value-ext">Currently 27%.</span>',
                  },
                  {
                    'id': 4,
                    'body': 'This year Kate had (measuring from 1.1. – 15.8.):<ul><li>500 000 conversations, including small talk of which</li><li>260 000 were bank or insurance relevant cases of which</li><li>70 000 cases were successfully finished</li></ul>',
                  },
                ],
              },
            ],
          },
          4: {
            'title': 'Digital Lead Management (LM) + K4E',
            'subtitle': 'Data-driven, personalised communication based on customer journey triggers – the right message in the right channel and optimal timing.',
            'color': 'color-4',
            'colorLight': 'color-4',
            'contentItems': [
              {
                'id': 1,
                'header': 'Measures planned in 2022 in Lead Management (LM)',
                'items': [
                  {
                    'id': 1,
                    'body': 'Brain in full scope in production executing first Kate and LM orchestration use cases',
                  },
                  {
                    'id': 2,
                    'body': 'Exponea in SB, IB and emails',
                  },
                  {
                    'id': 3,
                    'body': 'Consolidated contact and response history',
                  },
                  {
                    'id': 4,
                    'body': 'Real time data in CI tools',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Goals of LM',
                'items': [
                  {
                    'id': 1,
                    'body': '20-30% LM share on RET sales<span class="vue-value-ext">1Q 2022 14.7%.</span>',
                  },
                  {
                    'id': 2,
                    'body': '10-15% LM share on RS sales',
                  },
                  {
                    'id': 3,
                    'body': '5-7% LM conversion in RET in digital channels<span class="vue-value-ext">1Q 2022 6.7%.</span>',
                  },
                  {
                    'id': 4,
                    'body': '2-3% LM conversion in RS in digital channels',
                  },
                ],
              },
              {
                'id': 3,
                'header': 'Measures planned in 2022 in K4E',
                'items': [
                  {
                    'id': 1,
                    'body': 'Help with mortgages, pension savings and life insurance',
                  },
                  {
                    'id': 2,
                    'body': 'Help with preparation for client meeting ',
                  },
                  {
                    'id': 3,
                    'body': 'Display client history with Kate ',
                  },
                  {
                    'id': 4,
                    'body': 'Workforce management pilot',
                  },
                  {
                    'id': 5,
                    'body': '<strong>Currently</strong><div style="margin-top:14px;">Nearly 2,500 users used Kate in 2Q.</div><ul><li>We did well mainly in the insurance sector – 475 home insurance (40% conversion), 132 car insurance (30% conversion) and 318 life insurance (25% conversion). </li><li>We have prepared the first tips for individual entrepreneurs (in RET service). Today, Kate has already provided loan product tips to 13,000 clients and enriches them with 39 hyper-personalized arguments.</li><li>It\'s easier to search Kate\'s tips using filters. We have improved reporting for managers in CRM.</li></ul>',
                  },
                ],
              },
              {
                'id': 4,
                'header': 'Goals of K4E',
                'items': [
                  {
                    'id': 1,
                    'body': 'K4E at Czech Post',
                  },
                  {
                    'id': 2,
                    'body': '12 Use cases for RET',
                  },
                  {
                    'id': 3,
                    'body': '5 Use cases for RS',
                  },
                ],
              },
            ],
          },
          5: {
            'title': 'Distribution model next level (DMNL)',
            'subtitle': 'Moving simple sales and service tasks from physical to digital channels, acceleration of customer usage of digital platforms.',
            'color': 'color-5',
            'colorLight': 'color-5',
            'contentItems': [
              {
                'id': 1,
                'header': 'Retail',
                'bulletExcluded': true,
                'items': [
                  {
                    'id': 1,
                    'body': '<strong>Physical Retail distribution maturing towards an optimised digital first model</strong>',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Human',
                'items': [
                  {
                    'id': 1,
                    'body': 'Double the number of personal advisors and specialists augmented with the AI Kate for employees engine. Move sales of simple products to digital. Reduction of services done manually.',
                  },
                  {
                    'id': 2,
                    'body': 'Motivation scheme oriented towards advisory and complex products and to support digitisation of service and sales of simple products',
                  },
                  {
                    'id': 3,
                    'body': 'Rollout of new Meeter greeter to 50% of branches ',
                  },
                  {
                    'id': 4,
                    'body': 'Automation of cash services – from cash desks to recycling ATMs',
                  },
                  {
                    'id': 5,
                    'body': 'Upscaling to advisory positions and reduction of service-oriented positions',
                  },
                  {
                    'id': 6,
                    'body': 'K4E to become the key work management tool for managers and drive customer experience with our advisory concept ',
                  },
                ],
              },
              {
                'id': 3,
                'header': 'Goals in Human',
                'items': [
                  {
                    'id': 1,
                    'body': 'Sales capacity dedicated to simple sales and servicing: 2022 – 20-35%;<span class="vue-value-ext">1Q 2022 41%.</span>',
                  },
                  {
                    'id': 2,
                    'body': 'Sales capacity dedicated to complex sales and servicing: 2022 – +75%;<span class="vue-value-ext">1Q 2022 59%.</span>',
                  },
                ],
              },
              {
                'id': 4,
                'header': 'Digitál',
                'items': [
                  {
                    'id': 1,
                    'body': 'Further growth through Digi sales in the GROUP, Exclusive digital offers, assisted sales and personalisation + New online processes',
                  },
                  {
                    'id': 2,
                    'body': 'Further shift of service and simple sales to digital through shoulder-to-shoulder mode at branches and the client centre',
                  },
                  {
                    'id': 3,
                    'body': 'Development and deployment of E2E online processes',
                  },
                  {
                    'id': 4,
                    'body': 'Further enhancements in automated voicebot processing of clients’ needs',
                  },
                  {
                    'id': 5,
                    'body': 'Focused campaigns and exclusive digital propositions to motivate clients into digital – we will boost existing ones and extend with new ones',
                  },
                  {
                    'id': 6,
                    'body': 'Personalisation and continuous enhancement of lead management through new technologies (Exponea) and new sales scenarios for KATE',
                  },
                  {
                    'id': 7,
                    'body': 'Leveraging opportunities within the GROUP',
                  },
                ],
              },
              {
                'id': 5,
                'header': 'Goals in Digital',
                'items': [
                  {
                    'id': 1,
                    'body': 'Percent of E2E digital sales</br></br> <ul><li>all products: 2022 – 30-60 %</li><li>1Q 2022 - 42%</li></ul><br><ul><li>simple products: 2022 – 60-80%</li><li>1Q 2022 - 51%</li></ul>',
                  },
                ],
              },
              {
                'id': 6,
                'header': 'Relationship',
                'bulletExcluded': true,
                'items': [
                  {
                    'id': 1,
                    'body': '<strong>Self-serviced sales of simple products via CEB, new servicing model in SME</strong>',
                  },
                ],
              },
              {
                'id': 7,
                'header': 'Human',
                'items': [
                  {
                    'id': 1,
                    'body': 'New SME operating model incl. design and implementation of the remote/digital sales and servicing model',
                  },
                  {
                    'id': 2,
                    'body': 'Implementation of a new cloud-based CRM for RS as a key enabler for DMNL and to reuse the RET LM infrastructure incl. K4E',
                  },
                  {
                    'id': 3,
                    'body': 'Extension of digi academy and digi coaching to ensure a shift of simple sales and service to digital',
                  },
                  {
                    'id': 4,
                    'body': 'Rollout of tablets to corporate network',
                  },
                  {
                    'id': 5,
                    'body': 'The new simple branch-to-back office processes developed on the 0365 technology (20+ in pipeline)',
                  },
                  {
                    'id': 6,
                    'body': 'Sales and servicing of comprehensive products <table><tr><th></th><th>2021</th><th>2022</th></tr><tr><td>Micro-SME</td><td><strong>57%</strong></td><td><strong>+60%</strong></td></tr><tr><td>SME</td><td><strong>67 %</strong></td><td><strong>+70%</strong></td></tr><tr><td>COR</td><td><strong>80%</strong></td><td><strong>+85%</strong></td></tr></table>',
                  },
                ],
              },
              {
                'id': 8,
                'header': 'Digitál',
                'items': [
                  {
                    'id': 1,
                    'body': 'Boost adoption of CEB mobile, incl. new features such as CEB Virtual Branch in mobile app',
                  },
                  {
                    'id': 2,
                    'body': 'Acceleration of digital eligible clients in CEB, incl. virtual branch and simplification of e-signing using SmartKey',
                  },
                  {
                    'id': 3,
                    'body': 'New Smart/Digital account',
                  },
                  {
                    'id': 4,
                    'body': 'Evolution of CEB API to extend options for clients to  integrate the CEB service with ERP',
                  },
                  {
                    'id': 5,
                    'body': 'Further development and deployment of STP E2E processes',
                  },
                  {
                    'id': 6,
                    'body': 'Digital account opening using BankID and Caroline',
                  },
                  {
                    'id': 7,
                    'body': '<strong>Percent digital sales – simple products: 2021 – 31%; 2022 – 40%</strong><span class="vue-value-ext">2Q 2022 35-40 %.</span>',
                  },
                ],
              },
            ],
          },
          6: {
            'title': 'Work with data',
            'subtitle': 'Providing data infrastructure for Kate, Lead management, DIGI and others – real-time data solutions, smart dashboarding, AI models, etc.',
            'color': 'color-6',
            'colorLight': 'color-6',
            'contentItems': [
              {
                'id': 1,
                'header': 'EDISON',
                'items': [
                  {
                    'id': 1,
                    'body': 'It is a group-wide data and analytics platform built on top of Amazon Web services, especially for advanced data analytics and models.',
                  },
                  {
                    'id': 2,
                    'body': 'The migration of machine learning/AI models is currently underway in the ADAM Department, which will use it to replace the outdated Teradata solution.',
                  },
                  {
                    'id': 3,
                    'body': 'The new platform will allow us to deploy models for Kate in a more automated way and bring new possibilities, e.g. in document processing.',
                  },
                  {
                    'id': 4,
                    'body': 'It is a platform that will be used for further use across the group (inexpensive storage and backup of certain types of data, development environment for connecting external components, etc.).',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Real-time data solution DIH',
                'items': [
                  {
                    'id': 1,
                    'body': 'Twenty-seven cases have already been implemented on the infrastructure built in 2021, e.g.:' +
                      '<ul><li>(in cooperation with LM) directly approaching clients with offers for travel insurance or information that it is included with the credit card once they have purchased a trip from the travel agency;</li>' +
                      '<li>(via Kate) immediately contacting clients when a card transaction is declined and offering possible solutions;</li>' +
                      '<li>(via Kate) alerting clients to low account balances and offering possible solutions.</li></ul>',
                  },
                  {
                    'id': 2,
                    'body': 'We are planning:' +
                      '<ul><li>the implementation of other cases, especially for Kate, LM and GroupDIGI;</li>' +
                      '<li>to secure continuous operation (also within DIH releases).</li>' +
                      '</ul>',
                  },
                ],
              },
              {
                'id': 3,
                'header': 'CSOB Group client database and CZ/SK split',
                'items': [
                  {
                    'id': 1,
                    'body': 'We have launched a project with the aim of separating the client database (CMDB) of our group from ČSOB SK.',
                  },
                  {
                    'id': 2,
                    'body': 'Related analyses are already underway and include or will include colleagues from across the entire group.',
                  },
                  {
                    'id': 3,
                    'body': 'The physical division of the CMDB between ČSOB CZ and ČSOB SK is expected to take place by the end of 2024.',
                  },
                ],
              },
              {
                'id': 4,
                'header': 'Rainbow',
                'items': [
                  {
                    'id': 1,
                    'body': 'GO Live for CRISP1: The calculation from the group RAY application has become the "master" and we are slowly leaving the local calculation, which has become only a validation tool. From 2023 we will only use the central RAY.',
                  },
                  {
                    'id': 2,
                    'body': 'We continue to make planned deliveries for Sustainability, Talta and Finrep.',
                  },
                  {
                    'id': 3,
                    'body': 'We are continuing to build the CROS team, which will start on new data flows and reporting processes in Rainbow.',
                  },
                ],
              },
              {
                'id': 5,
                'header': 'Data driven organization',
                'items': [
                  {
                    'id': 1,
                    'body': 'Data-X week took place in June and will be followed by the Data Minimum workshop in October. A Data Inspiration day was also held in September to promote "self-service data".',
                  },
                  {
                    'id': 2,
                    'body': 'We are preparing a trainee program + professional articles to promote data literacy.',
                  },
                  {
                    'id': 3,
                    'body': 'We are working on an environment in which it will be possible to work with data with minimal (or no) support from the Data Department.',
                  },
                ],
              },
            ],
          },
          7: {
            'title': 'Straight-Trough Processing',
            'subtitle': 'Digitisation of processes so that they run without human intervention.',
            'color': 'color-7',
            'colorLight': 'color-7',
            'contentItems': [
              {
                'id': 1,
                'header': '',
                'bulletExcluded': true,
                'items': [
                  {
                    'id': 1,
                    'body': 'Accelerate digital usage',
                  },
                  {
                    'id': 2,
                    'body': 'All processes related to the offer of a certain service and its approval and subsequent processing occur automatically without human intervention',
                  },
                  {
                    'id': 3,
                    'body': 'Creation of a strong link between the APC process and STP metrics and KPIs',
                  },
                  {
                    'id': 4,
                    'body': 'Finalisation of interfaces between currently isolated processes',
                  },
                  {
                    'id': 5,
                    'body': 'Regular performance/profitability scan by Finance, regular NAPP procedure (driven by Risk) and regular STP process check.',
                  },
                  {
                    'id': 6,
                    'body': 'Process Community set up to share experience across all entities',
                  },
                ],
              },
              {
                'id': 2,
                'header': 'Goals',
                'items': [
                  {
                    'id': 1,
                    'body': 'Further deepening of awareness in the GROUP',
                  },
                  {
                    'id': 2,
                    'body': 'STP ratio – products processed without human intervention (out of all digitally offered products)',
                  },
                  {
                    'id': 3,
                    'body': '<table><tr><th align="center"><strong><br>4Q2021</strong></th><th align="center"><strong><br>2Q2022</strong></th><th align="center"><strong>Plan<br>4Q2022</strong></th><th align="center"><strong>Plan<br>2023</strong></th></tr><tr><td>27.5%</td><td>44.8%</td><td>46.3%</td><td>60%</td></tr></table>'
                  }
                ],
              },
            ],
          },
        }
      }
    }
  ]
});

