export const ROUTE_DASHBOARD = 'sustainability'
export const ROUTE_ABOUT = 'sustainability-about'
export const ROUTE_PAGE = 'sustainability-page'
export const ROUTE_COMPETITIONS = 'sustainability-competitions'
export const ROUTE_COMPETITION_DETAIL = 'sustainability-competition-detail'
export const ROUTE_COMPETITION_RESULT = 'sustainability-competition-result'
export const ROUTE_CHALLENGES = 'sustainability-challenges'
export const ROUTE_CHALLENGE_DETAIL = 'sustainability-challenge-detail'
export const ROUTE_CHALLENGE_HISTORY = 'sustainability-challenge-history'
export const ROUTE_CHALLENGE_RESULT = 'sustainability-challenge-results'
export const ROUTE_NEWS = 'sustainability-news'
export const ROUTE_NEWS_DETAIL = 'sustainability-news-detail'
export const ROUTE_STORIES = 'sustainability-stories'
export const ROUTE_STORY = 'sustainability-story'
export const ROUTE_NEW_STORY = 'sustainability-new-story'
export const ROUTE_TOPIC_DETAIL = 'sustainability-topic-detail'
export const ROUTE_THEMES = 'sustainability-themes'
export const ROUTE_THEME_DETAIL = 'sustainability-theme-detail'
export const ROUTE_TARGETS = 'sustainability-targets'
