import logger from '@/utils/logger';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as MUTATIONS_CONSTANTS from '../constants/mutations';
import * as ACTIONS_CONSTANTS from '../constants/actions';
import dataLoader from '../../utils/data-loader/index';

const state = {
  trioAreLoaded: false,
  trioHasError: false,
  trioPages: {}
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_TRIO_PAGES](state, pages) {
    state.trioPages = pages;
  },

  [MUTATIONS_CONSTANTS.SET_TRIO_IS_LOADED](state, trioAreLoaded) {
    state.trioAreLoaded = trioAreLoaded;
  },

  [MUTATIONS_CONSTANTS.SET_TRIO_HAS_ERROR](state, errorState) {
    state.trioHasError = errorState;
  }
}

const actions = {
  [ACTIONS_CONSTANTS.FETCH_TRIO_DATA]({ commit, state }, options) {
    dataLoader.fetchSubPages(options.pageId)
      .then(pages => {
        commit(MUTATIONS_CONSTANTS.SET_TRIO_PAGES, pages);
        commit(MUTATIONS_CONSTANTS.SET_TRIO_IS_LOADED, true);
      })
      .catch(error => {
        logger.error(error);
        commit(MUTATIONS_CONSTANTS.SET_TRIO_HAS_ERROR, true);
      })
      .finally(() => {
        if (state.trioHasError) {
          setTimeout(() => {
            commit(MUTATIONS_CONSTANTS.SET_TRIO_HAS_ERROR, false);
          }, ERROR_MODAL_TIMEOUT);
        }
      })
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
