<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:content>
          <div class="prj-layout-default h-full">
            <!--========== HEADER =================================-->
            <!--===================================================-->
            <header class="">
              <t-tag
                variant="sectionTitle"
                class="xs:mb-6 md:mb-8 xs:mt-6 md:mb-10"
                tag-name="h1"
              >
                {{ $t('fest.vote.headline') }}
              </t-tag>
            </header>

            <!--========== CHALLENGES CARDS =======================-->
            <!--===================================================-->
            <div class="relative flex-1">
              <div class="absolute top-0 right-0 bottom-0 left-0">
                <div
                  v-if="challenges && !challenges.length"
                  class="flex h-full"
                >
                  <prj1023-no-content-panel
                    :text="$t('fest.vote.placeholder')"
                    class="h-11/12 my-auto"
                  />
                </div>
                <prj1006-cards-wrapper
                  v-else
                  class="h-full"
                  :hasNavigation="!isSingleChallenge"
                  :itemsLength="challenges.length"
                >
                  <template
                    v-for="{
                      themeId,
                      additionalLink,
                      additionalLinkUrl,
                      additionalIconType,
                      categoryId,
                      competitionId,
                      description,
                      endDate,
                      id,
                      image,
                      participants,
                      resultsReady,
                      status,
                      startDate,
                      title,
                    } in challenges"
                  >
                    <prj1007-vote-card
                      :key="`challenge-${id}`"
                      :statusTag="getStatus({ event: 'challenges', status, start: startDate, end: endDate })"
                      :themeTitle="getThemeTitle(themeId)"
                      :categoryId="categoryId"
                      :status="status"
                      :link="additionalLink"
                      :linkUrl="additionalLinkUrl"
                      :icon="additionalIconType"
                      :participants="parseInt(participants)"
                      :title="title"
                      :description="description"
                      :image="image"
                      :isCompetitionLinked="!!competitionId"
                      :isSingle="isSingleChallenge"
                      :isResultReady="!!resultsReady"
                      :isVoted="hasUserVoted"
                      isFullPage
                      @primaryButton="hasUserVoted ? toggleOverlayVoted() : toPage({ route: ROUTE_VOTE_DETAIL, voteId: id })"
                      @resultClick="toPage({ route: ROUTE_VOTE_RESULT, voteId: id })"
                      @secondaryButton="toPage({ route: ROUTE_VOTE_RESULT, voteId: id })"
                      @linkedEventClick="toPage({ route: ROUTE_COMPETITION_DETAIL, competitionId })"
                    />
                  </template>
                </prj1006-cards-wrapper>
              </div>
            </div>
          </div>

          <!--========== USER VOTED =============================-->
          <!--===================================================-->
          <prj1009-modal
            :active="overlayVoted"
            @overlayCloseEvent="toggleOverlayVoted"
          >
            <template v-slot:title>
              {{ $t('fest.vote.votedModal.headline') }}
            </template>

            <template v-slot:content>
              <div
                class="mb-10"
              >
                <p>{{ $t('fest.vote.votedModal.description') }}</p>
              </div>
            </template>

            <template v-slot:controls>
              <t-button
                class="bg-fest-home flex items-center justify-center"
                @click="toggleOverlayVoted()"
              >
                {{ $t("fest.vote.votedModal.buttonConfirm") }}
              </t-button>
            </template>
          </prj1009-modal>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import {mapGetters, mapState} from 'vuex'
import { FEST_THEMES, FEST_CHALLENGES } from '@/store/store-modules'
import { GET_THEMES_INFO, GET_CHALLENGES_INFO } from '@/store/constants/fest/actions'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import '@/utils/dayjs'
import { WAITING, EXPIRED } from '@/constants/fest/status'
import {
  ROUTE_CHALLENGE_DETAIL,
  ROUTE_CHALLENGE_HISTORY,
  ROUTE_CHALLENGE_RESULT,
  ROUTE_COMPETITION_DETAIL,
  ROUTE_VOTE_DETAIL,
  ROUTE_VOTE_RESULT,
} from '@/constants/fest/route-names'
import { eventStatus } from '@/mixins/fest/eventStatus'

import PageLayout from '@/templates/fest/page-layout'
import Prj1007VoteCard from '@/components/fest/prj1007-event-card/prj1007-vote-card'
import Prj1006CardsWrapper from '@/components/fest/prj1006-cards-wrapper/prj1006-cards-wrapper'
import Prj1023NoContentPanel from '@/components/fest/prj1023-no-content-panel/prj1023-no-content-panel'
import Prj1009Modal from '@/components/esg/prj1009-modal/prj1009-modal';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTVote',
  components: {
    Prj1009Modal,
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1006CardsWrapper,
    PageLayout,
    Prj1007VoteCard,
    Prj1023NoContentPanel
  },
  mixins: [mxNavigationDefault, eventStatus],
  data() {
    return {
      ROUTE_CHALLENGE_DETAIL,
      ROUTE_CHALLENGE_HISTORY,
      ROUTE_CHALLENGE_RESULT,
      ROUTE_COMPETITION_DETAIL,
      ROUTE_VOTE_DETAIL,
      ROUTE_VOTE_RESULT,
      WAITING,
      EXPIRED,
      overlayVoted: false
    }
  },
  computed: {
    ...mapState('festChallenges', {
      challenges: (state) => state.sortedChallengesInfo,
      activeChallengesCount: (state) => state.challengesCount,
      challengesUserInvolved: (state) => state.challengesUserInvolved,
    }),
    ...mapState('festThemes', ['themesInfo']),
    ...mapGetters('festUser', ['festivalVoted']),
    isSingleChallenge() {
      return this.challenges.length === 1
    },
    hasUserVoted() {
      return this.festivalVoted.food; // Set manually for current vote
    }
  },
  created() {
    if (!this.challenges.length) this.$store.dispatch(`${FEST_CHALLENGES}/${GET_CHALLENGES_INFO}`)
    if (!this.themesInfo.length) this.$store.dispatch(`${FEST_THEMES}/${GET_THEMES_INFO}`)
  },
  methods: {
    toPage({ route, competitionId = null, voteId = null }) {
      this.$router.push({
        name: route,
        params: {
          competitionId,
          voteId,
        },
      })
    },

    getThemeTitle(themeId) {
      return this.themesInfo?.find((theme) => theme.id === themeId)?.title
    },

    toggleOverlayVoted() {
      this.overlayVoted = !this.overlayVoted;
    }
  },
}
</script>
