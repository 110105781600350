<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-numeric-value" :class="classObject">
        <span class="vue-numeric-value">{{ valueComputedString }}</span>
        <span v-if="unitSeparator" class="vue-separator">{{ unitSeparator }}</span>
        <span class="vue-unit">{{ unitComputed }}</span>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->

<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== MISC
import localization from '../../../localization';
import { getUnit, round } from '../../../utils/utils-general';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1021NumericValue',
    props: {
        value: Number,
        valueSignModifier: Boolean,
        //=== NUMBER
        min: Number,
        max: Number,
        localized: Boolean,
        locale: {
            default: () => {
                return localization.locale;
            },
            type: String
        },
        toLocaleStringOptions: {
            default() {
                return {
                    maximumFractionDigits: 2
                };
            },
            type: Object
        },
        rounding: Number,
        unit: null,
        unitSeparator: {
            default: ' ',
            type: String
        },
        id: String
    },
    computed: {
        classObject() {
            return [
                {
                    'vue-is-positive': this.valueSignModifier && this.valueSign > 0,
                    'vue-is-negative': this.valueSignModifier && this.valueSign < 0,
                    'vue-is-zero': this.valueSignModifier && this.valueSign === 0
                }
            ];
        },
        //=== NUMBER
        localeComputed() {
            if (this.locale && this.locale !== '') {
                return this.locale;
            }

            return navigator.language;
        },
        unitComputed() {
            return getUnit(this.valueComputed, this.unit);
        },
        valueComputed() {
            //=== min / max
            let valueComputed = this.value;

            if (this.max && this.value > this.max) {
                return this.max;
            } else if (this.min && this.value < this.min) {
                return this.min;
            } else {
                //=== ROUNDING
                if (this.rounding && this.rounding !== 0) {
                    return round(valueComputed, this.rounding);
                }

                return this.value;
            }
        },
        valueComputedString() {
            if (this.valueComputed || this.valueComputed === 0) {
                if (this.localized) {
                    return this.valueComputed.toLocaleString(this.localeComputed, this.toLocaleStringOptions);
                } else {
                    return this.valueComputed.toString();
                }
            }

            return null;
        },
        valueSign() {
            return Math.sign(this.valueComputed);
        }
    }
};
</script>
