<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-c-filter" :class="classObject">
        <div class="vue-b-filter-content">
            <slot></slot>
        </div>

        <div v-if="!$slots.controls && (buttonFilter || buttonClearFilter)" class="vue-b-filter-controls">
            <frm1006-button
                v-if="buttonFilter"
                type="internal"
                class="vue-ci-button-filter"
                :title="buttonFilterCaptionComputed"
                @buttonClickEvent="buttonFilterClick"
            >
                {{ buttonFilterCaptionComputed }}
            </frm1006-button>
            <frm1006-button
                v-if="buttonClearFilter"
                type="internal"
                class="vue-ci-button-clear-filter"
                :title="buttonClearFilterCaptionComputed"
                @buttonClickEvent="buttonClearFilterClick"
            >
                {{ buttonClearFilterCaptionComputed }}
            </frm1006-button>
        </div>

        <div v-else-if="!!this.$slots.controls" class="vue-b-filter-controls">
            <slot name="controls" />
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//=== MIXINS
import Localization from '../../mixins/localization';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1027Filter',
    components: {
        Frm1006Button
    },
    mixins: [Localization],
    props: {
        type: String,
        buttonFilter: {
            default: true,
            type: Boolean
        },
        buttonFilterCaption: String,
        buttonClearFilter: Boolean,
        buttonClearFilterCaption: String,
        id: {
            default: null,
            type: String
        }
    },
    computed: {
        classObject() {
            return {
                'vue-has-button-filter': this.buttonFilter,
                'vue-has-button-clear-filter': this.buttonClearFilter,
                'vue-has-controls': this.hasControls,
                [`vue-is-${this.type}`]: this.type
            };
        },
        hasControls() {
            return !!this.$slots.controls || this.buttonFilter || this.buttonClearFilter;
        },
        buttonFilterCaptionComputed() {
            return this.buttonFilterCaption ? this.buttonFilterCaption : this.i18n('buttonFilter');
        },
        buttonClearFilterCaptionComputed() {
            return this.buttonFilterCaption ? this.buttonFilterCaption : this.i18n('buttonClearFilter');
        }
    },
    methods: {
        buttonFilterClick() {
            this.$emit('buttonFilterClickEvent');
        },
        buttonClearFilterClick() {
            this.$emit('buttonClearFilterClickEvent');
        }
    }
};
</script>
