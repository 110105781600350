import * as MUTATIONS_CONSTANTS from '../constants/mutations'
import * as ACTIONS_CONSTANTS from '../constants/actions';
import dataLoader from '@/utils/data-loader';

const state = {
  categories: [],
  categoriesLastCheck: {
    timestamp: '',
    date: ''
  },
  entities: [],
  entitiesLastCheck: {
    timestamp: '',
    date: ''
  },
  applications: [],
  applicationsLastCheck: {
    timestamp: '',
    date: ''
  },
  detailApplication: null,
  modalFormActive: false,
  modalDetailActive: false,
  modalWishActive: false,
  formData: {
    id: 0,
    name: '',
    description: '',
    wishDescription: '',
    link: '',
    linkWiki: '',
    icon: null,
    iconOldPath: '',
    iconColor: '',
    supportEmail: '',
    supportPhone: '',
    supportSnowGroup: '',
    privateOnly: false,
    desktopOnly: false,
    completed: false
  },
  searchQuery: '',
  isSearchActive: false,
  isSearchHistoryActive: false,
  isSearchToggleActive: false,
  isLoadingItems: false
}

const mutations = {
  [MUTATIONS_CONSTANTS.SET_DIRECTORY_CATEGORIES](state, data) {
    state.categories = data.categories;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_CATEGORIES_LAST_CHECK](state) {
    let date = new Date();
    let timestamp = Number(date);
    state.categoriesLastCheck.date = date;
    state.categoriesLastCheck.timestamp = timestamp;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_ENTITIES](state, data) {
    state.entities = data.entities;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_ENTITIES_LAST_CHECK](state) {
    let date = new Date();
    let timestamp = Number(date);
    state.entitiesLastCheck.date = date;
    state.entitiesLastCheck.timestamp = timestamp;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEMS](state, data) {
    state.applications = data.applications;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEMS_LAST_CHECK](state) {
    let date = new Date();
    let timestamp = Number(date);
    state.applicationsLastCheck.date = date;
    state.applicationsLastCheck.timestamp = timestamp;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_FORM](state, active) {
    state.modalFormActive = active;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_DETAIL](state, active) {
    state.modalDetailActive = active;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_WISH](state, active) {
    state.modalWishActive = active;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ID](state, id) {
    state.formData.id = id;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_NAME](state, name) {
    state.formData.name = name;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_DESCRIPTION](state, description) {
    state.formData.description = description;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_WISH_DESCRIPTION](state, wishDescription) {
    state.formData.wishDescription = wishDescription;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_LINK](state, link) {
    state.formData.link = link;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_LINK_WIKI](state, linkWiki) {
    state.formData.linkWiki = linkWiki;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ICON](state, icon) {
    state.formData.icon = icon;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ICON_OLD](state, iconOldPath) {
    state.formData.iconOldPath = iconOldPath;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_ICON_COLOR](state, iconColor) {
    state.formData.iconColor = iconColor;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_SUPPORT_EMAIL](state, supportEmail) {
    state.formData.supportEmail = supportEmail;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_SUPPORT_PHONE](state, supportPhone) {
    state.formData.supportPhone = supportPhone;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_SUPPORT_SNOW_GROUP](state, supportSnowGroup) {
    state.formData.supportSnowGroup = supportSnowGroup;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_PRIVATE_ONLY](state, privateOnly) {
    state.formData.privateOnly = privateOnly;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_DESKTOP_ONLY](state, desktopOnly) {
    state.formData.desktopOnly = desktopOnly;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_COMPLETED](state, completed) {
    state.formData.completed = completed;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_FORM_RESET](state) {
    state.formData.id = 0;
    state.formData.name = '';
    state.formData.description = '';
    state.formData.link = '';
    state.formData.linkWiki = '';
    state.formData.icon = null;
    state.formData.iconOldPath = '';
    state.formData.iconColor = '';
    state.formData.supportEmail = '';
    state.formData.supportPhone = '';
    state.formData.supportSnowGroup = '';
    state.formData.privateOnly = false;
    state.formData.desktopOnly = false;
    state.formData.completed = false;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_DETAIL_APP](state, detail) {
    state.detailApplication = detail;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEM_FAVORITE](state, item) {
    Object.entries(state.applications).forEach(([, val]) => {
      if (val.id === item.id) {
        val.favorite = true;
      }
    })
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEM_FAVORITE_OFF](state, item) {
    Object.entries(state.applications).forEach(([, val]) => {
      if (val.id === item.id) {
        val.favorite = false;
      }
    })
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEM_DELETE](state, itemDelete) {
    let indexApplication = state.applications.findIndex(item => item.id === itemDelete.id);
    if (indexApplication > -1) {
      state.applications.splice(indexApplication, 1);
    }
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_QUERY](state, searchQuery) {
    state.searchQuery = searchQuery;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_STATUS](state, status) {
    state.isSearchActive = status;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS](state, status) {
    state.isSearchHistoryActive = status;
  },

  [MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEMS_LOADING](state, status) {
    state.isLoadingItems = status;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_TOGGLE_STATUS](state, status) {
    state.isSearchToggleActive = status;
  },
}

const actions = {
  [ACTIONS_CONSTANTS.FETCH_DIRECTORY_CATEGORIES]({ state, commit }, data) {
    let currentDateTime = new Date();
    let checkInterval = 60000 * 60;
    if ((currentDateTime - state.categoriesLastCheck.timestamp) < checkInterval && !data.force) {
      return false;
    }
    commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_CATEGORIES_LAST_CHECK);
    dataLoader.fetchDirectoryCategories()
      .then(response => {
        commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_CATEGORIES, response.data);
      });
  },

  [ACTIONS_CONSTANTS.FETCH_DIRECTORY_ENTITIES]({ state, commit }, data) {
    let currentDateTime = new Date();
    let checkInterval = 60000 * 60;
    if ((currentDateTime - state.entitiesLastCheck.timestamp) < checkInterval && !data.force) {
      return false;
    }
    commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_ENTITIES_LAST_CHECK);
    dataLoader.fetchDirectoryEntities()
      .then(response => {
        commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_ENTITIES, response.data);
      });
  },

  [ACTIONS_CONSTANTS.FETCH_DIRECTORY_ITEMS]({ state, commit }, data) {
    let currentDateTime = new Date();
    let checkInterval = 60000 * 30;
    if ((currentDateTime - state.applicationsLastCheck.timestamp) < checkInterval && !data.force) {
      return false;
    }
    commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEMS_LAST_CHECK);
    commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEMS_LOADING, true);
    dataLoader.fetchDirectoryApplications(data.params)
      .then(response => {
        commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEMS, response.data);
      })
      .finally(() => {
        commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEMS_LOADING, false);
      });
  },

  [ACTIONS_CONSTANTS.SET_DIRECTORY_ITEM_FAVORITE]({ commit }, item) {
    commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEM_FAVORITE, item);
    dataLoader.postDirectoryApplicationFavorite(item.id)
      .then(() => {});
  },

  [ACTIONS_CONSTANTS.SET_DIRECTORY_ITEM_FAVORITE_OFF]({ commit }, item) {
    commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEM_FAVORITE_OFF, item);
    dataLoader.deleteDirectoryApplicationFavorite(item.id)
      .then(() => {});
  },

  [ACTIONS_CONSTANTS.SET_DIRECTORY_ITEM_DELETE]({ commit }, item) {
    commit(MUTATIONS_CONSTANTS.SET_DIRECTORY_ITEM_DELETE, item);
    dataLoader.deleteDirectoryApplication(item.id)
      .then(() => {});
  },
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
