<template>
  <div class="vue-c-item-age">
    <gen1021-numeric-value
      v-if="isOlderThanHour"
      :class="classObject"
      :value="calculatedTimeDiff"
      :localized="true"
      :unit="pluralizeExactTime"
    />

    <span
      v-else-if="isOlderThanTwoWeeks"
    >
      {{ makeExactDate(date) }}
    </span>

    <span v-else>
      {{ $t('general.lessThanHour') }}
    </span>
  </div>
</template>

<script>
import utilsGeneral from '@/utils/utils-general';
import {
  HOUR_IN_MS,
  DAY_IN_MS,
  TWO_WEEKS_IN_MS,
  ONE_YEAR_IN_MS
} from '@/constants/app-constants';

export default {
  name: 'Prj1038ItemAge',
  props: {
    date: {
      type: Number,
      required: true
    },
    showFullDate: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      timestampDiff: 0,
      pluralizedDays: {
        0: this.$i18n.t('inflection.days.0'),
        1: this.$i18n.t('inflection.days.1'),
        2: this.$i18n.t('inflection.days.2'),
        5: this.$i18n.t('inflection.days.5')
      },
      pluralizedHours: {
        0: this.$i18n.t('inflection.hours.0'),
        1: this.$i18n.t('inflection.hours.1'),
        2: this.$i18n.t('inflection.hours.2'),
        5: this.$i18n.t('inflection.hours.5'),
      }
    }
  },

  computed: {
    classObject() {
      return {
        'vue-less-than-hour': !this.isOlderThanHour,
        'vue-more-than-hour': this.isOlderThanHour
      }
    },

    calculatedTimeDiff() {
      const hoursDiff = Math.ceil(this.timestampDiff / HOUR_IN_MS);
      const daysDiff = Math.ceil(this.timestampDiff / DAY_IN_MS);

      if (this.timestampDiff < DAY_IN_MS) {
        return hoursDiff
      } else {
        return daysDiff
      }
    },

    pluralizeExactTime() {
      if (DAY_IN_MS < this.timestampDiff) {
        return this.pluralizedDays;
      } else {
        return this.pluralizedHours;
      }
    },

    isOlderThanHour() {
      return HOUR_IN_MS < this.timestampDiff && !this.isOlderThanTwoWeeks;
    },

    isOlderThanTwoWeeks() {
      return TWO_WEEKS_IN_MS < this.timestampDiff;
    }
  },

  mounted() {
    this.calculateDateCreation(this.date);
  },

  methods: {
    calculateDateCreation(date) {
      utilsGeneral.subtractDateFromToday(date)
        .then(diffResult => {
          this.timestampDiff = diffResult;
        });
    },

    makeExactDate(date) {
      const halfYearInMs = ONE_YEAR_IN_MS / 2;

      if (halfYearInMs < this.timestampDiff || this.showFullDate) {
        return utilsGeneral.timestampToFullDate(date);
      }

      return utilsGeneral.timestampToDateAndMonth(date);
    }
  }
}
</script>
