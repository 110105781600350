<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-frm-slot" :class="classObject">
        <slot></slot>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
// TODO MBU: move to separate folder which is included with components package.
// project needs structure refactor to separate components from the catalogue
import config from '../../../config';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1023FormRowSlot',
    props: {
        size: {
            default: 'medium',
            type: String,
            validator: value => {
                return config.formRowSlotSizes.includes(value);
            }
        },
        id: {
            default: null,
            type: String
        }
    },
    computed: {
        classObject() {
            return {
                // TODO REVIEW: create constant with prefix
                [`vue-frm-${this.size}`]: this.size
            };
        }
    }
};
</script>
