import logger from '@/utils/logger';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as MUTATIONS_CONSTANTS from '../constants/mutations';
import * as ACTIONS_CONSTANTS from '../constants/actions';
import dataLoader from '../../utils/data-loader/index';

const state = {
  workouts: [],
  workoutsAreLoaded: false,
  workoutsHasError: false,
  workoutsOptions: [],
  selectedBuilding: 'NHQ',
  selectedQuartal: '1Q',
  selectedLessonId: 0, // FIXME MBU: initial value 0, gets replaced with string - unify types
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_ALL_WORKOUTS](state, workouts) {
    state.workouts = workouts.lessons;
    state.workoutsOptions = workouts.options;
    state.selectedQuartal = workouts.options.currentlyQuartalNum + 'Q';
  },

  [MUTATIONS_CONSTANTS.SET_WORKOUTS_OPTIONS](state, workoutsOptions) {
    state.workoutsOptions = workoutsOptions;
  },

  [MUTATIONS_CONSTANTS.SET_WORKOUTS_IS_LOADED](state, workoutsLoadedState) {
    state.workoutsAreLoaded = workoutsLoadedState;
  },

  [MUTATIONS_CONSTANTS.SET_WORKOUT_BUILDING](state, selectedBuilding) {
    state.selectedBuilding = selectedBuilding;
  },

  [MUTATIONS_CONSTANTS.SET_WORKOUT_QUARTAL](state, selectedQuartal) {
    state.selectedQuartal = selectedQuartal;
  },

  [MUTATIONS_CONSTANTS.SET_WORKOUTS_HAS_ERROR](state, errorState) {
    state.workoutsHasError = errorState;
  }
}

const actions = {
  [ACTIONS_CONSTANTS.FETCH_WORKOUTS]({ commit, state }) {
    dataLoader.fetchWorkouts()
      .then(workouts => {
        commit(MUTATIONS_CONSTANTS.SET_ALL_WORKOUTS, workouts);
        commit(MUTATIONS_CONSTANTS.SET_WORKOUTS_IS_LOADED, true);
      })
      .catch(error => {
        logger.error(error)
        commit(MUTATIONS_CONSTANTS.SET_WORKOUTS_HAS_ERROR, true);
      })
      .finally(() => {
        // TODO MBU: move this logic into view
        if (state.workoutsHasError) {
          setTimeout(() => {
            commit(MUTATIONS_CONSTANTS.SET_WORKOUTS_HAS_ERROR, false);
          }, ERROR_MODAL_TIMEOUT);
        }
      });
  },

  [ACTIONS_CONSTANTS.FETCH_WORKOUTS_QUARTERS]({ commit, state }) {
    dataLoader.fetchWorkoutsQuarters()
      .then(workouts => {
        commit(MUTATIONS_CONSTANTS.SET_ALL_WORKOUTS, workouts);
        commit(MUTATIONS_CONSTANTS.SET_WORKOUTS_IS_LOADED, true);
      })
      .catch(error => {
        logger.error(error)
        commit(MUTATIONS_CONSTANTS.SET_WORKOUTS_HAS_ERROR, true);
      })
      .finally(() => {
        // TODO MBU: move this logic into view
        if (state.workoutsHasError) {
          setTimeout(() => {
            commit(MUTATIONS_CONSTANTS.SET_WORKOUTS_HAS_ERROR, false);
          }, ERROR_MODAL_TIMEOUT);
        }
      });
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
