<template>
  <div
    v-if="itemData.name"
  >
    <h2
      v-if="itemData.category_name !== null && itemData.category_name.length > 0"
      class="vue-food-menu-item-headline"
    >
      {{ itemData.category_name | makeSentence }}
    </h2>

    <div class="vue-food-menu-container">
      <div
        class="vue-food-menu-item"
      >
        <div
          class="vue-food-menu-item-left"
          @click="goToMealDetail(itemIndex, $event)"
        >
          <div
            class="vue-b-food-menu-item-image"
          >
            <div
              :id="'vue-food-menu-item-image' + itemIndex"
              v-lazy:background-image="getFoodImageUrl()"
              class="vue-food-menu-item-image"
            />
            <div
              :id="'vue-food-menu-pictogram' + itemIndex"
              v-lazy:background-image="getFoodImagePictogramUrl()"
              class="vue-food-menu-pictogram"
            />
          </div>
        </div>
        <div class="vue-food-menu-item-right">
          <div
            @click="goToMealDetail(itemIndex, $event)"
          >
            <div class="vue-food-menu-item-name">
              {{ itemData.name | makeSentence }}
            </div>
            <div
              v-if="typeof itemData.price !== 'undefined' && itemData.price.toString().length > 0"
              class="vue-food-menu-item-currency"
            >
              {{ itemData.price | makeSentence }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Prj1006FoodMenuItem',

  props: {
    itemData: {
      type: Object,
      required: true
    },
    itemIndex: {
      type: Number,
      required: true
    },
  },

  data() {
    return {
      uniqueFoodImageUrlSuffix: ''
    }
  },

  computed: {
    ...mapState('restaurants', [
      'selectedRestaurant',
      'selectedDay'
    ]),
  },

  methods: {
    getFoodImageUrl() {
      if (this.itemData) {
        return `${this.itemData.pictures.large + '?v=' + this.getUniqueStringByTimestamp()}`
      }

      return '';
    },

    getFoodImagePictogramUrl() {
      if (this.itemData && this.itemData.pictures.pictograms.length > 0) {
        return `${this.itemData.pictures.pictograms[0] + '?v=' + this.getUniqueStringByTimestamp()}`
      }

      return '';
    },

    getUniqueStringByTimestamp() {
      let now = new Date();

      if (!this.uniqueFoodImageUrlSuffix) {
        this.uniqueFoodImageUrlSuffix = now.getTime();
      } else {
        let compare = now.getTime() - (5 * 60 * 1000);

        if (compare > this.uniqueFoodImageUrlSuffix) {
          this.uniqueFoodImageUrlSuffix = now.getTime();
        }
      }

      return this.uniqueFoodImageUrlSuffix;
    },

    goToMealDetail(itemIndex, event) {
      this.$emit('mealSelected', itemIndex, event);
    }
  }
}
</script>
