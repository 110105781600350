<template>
  <transition
    name="vue-fade"
    appear
  >
    <div class="vue-navigation-info">
      <prj1020-separator />
      <div class="vue-navigation-info-items">
        <frm1006-button
          class="vue-navigation-info-btn"
          type="app-button"
          @buttonClickEvent="goToPage('/user-settings')"
        >
          {{ $t('navigation.settings') }}
        </frm1006-button>
        <frm1006-button
          class="vue-navigation-info-link"
          type="app-button"
          :icon-left="true"
          @buttonClickEvent="goToPage('/help')"
        >
          {{ $t('navigation.help') }}
        </frm1006-button>
      </div>
    </div>
  </transition>
</template>

<script>
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

import Prj1020Separator from '@/components/prj1020-separator/prj1020-separator';

export default {
  name: 'Prj1005NavigationInfo',
  components: {
    Prj1020Separator
  },

  methods: {
    goToPage(target) {
      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.TOGGLE_DASHBOARD_MENU);
      this.$router.push(target);
    }
  }
}
</script>
