const COMPRESS_QUALITY_JPG = 0.85;
import EXIF from 'exif-js';

export default {
  /**
   * Inspired by https://zocada.com/compress-resize-images-javascript-browser/
   *
   * @return Promise(Blob) converted image file on success or error on reject
   */
  compressImage(fileUploadChangeEvent, width, height) {
    return new Promise((resolve, reject) => {
      const image = fileUploadChangeEvent.target.files[0];
      const imageFileName = image.name;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = event => {
        let originalWidth = 0;
        let originalHeight = 0;

        const img = new Image();
        img.src = event.target.result;

        img.onload = async () => {
          // inspired by https://stackoverflow.com/questions/19463126/how-to-draw-photo-with-correct-orientation-in-canvas-after-capture-photo-by-usin

          if (!width) {
            originalWidth = img.width;
            width = this.reduceImageSize(originalWidth);
          }

          if (!height) {
            originalHeight = img.height;
            height = this.reduceImageSize(originalHeight);
          }

          const originalOrientation = await this.detectOrientation(fileUploadChangeEvent.target.files[0], width, height);
          const imageSize = this.getImageSizeByMinimal(width, height, originalWidth, originalHeight);

          const elem = document.createElement('canvas');
          elem.width = imageSize.width;
          elem.height = imageSize.height;

          const ctx = elem.getContext('2d');

          if (originalOrientation === 3 || originalOrientation === 8) {
            ctx.translate(elem.width, elem.height);
            ctx.rotate(Math.PI);
          }

          ctx.drawImage(img, 0, 0, elem.width, elem.height);
          ctx.canvas.toBlob(blob => {
            let blobObject = {
              blob: blob,
              name: imageFileName
            }
            resolve(blobObject);
          }, 'image/jpeg', COMPRESS_QUALITY_JPG);
        };
        reader.onerror = error => {
          reject(error);
        };
      };
    });
  },

  detectOrientation(img) {
    return new Promise(resolve => {
      EXIF.getData(img, function() {
        let orientationCode;

        if (!EXIF.getTag(this, 'Orientation')) {
          orientationCode = 1;
        } else {
          orientationCode = Number(EXIF.getTag(this, 'Orientation'));
        }

        resolve(orientationCode);
      });
    });
  },

  getImageSizeByMinimal(width, height, originalWidth, originalHeight) {
    if (width > height) {
      let ratio = width / originalWidth;
      height = originalHeight * ratio;
    } else {
      let ratio = height / originalHeight;
      width = originalWidth * ratio;
    }

    return {
      width: width,
      height: height
    };
  },

  reduceImageSize(imageSize) {
    if (imageSize > 1000) {
      let imageRatio = Math.ceil(imageSize / 1000);
      return imageSize / imageRatio;
    } else {
      return imageSize;
    }
  }
}
