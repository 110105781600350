<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :button-back-type="'none'"
        @activeView="buttonBackEvent"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-strategy-page-layout">
        <div class="vue-c-strategy-target">
          <transition
            name="vue-anim-strategy-move-from-right"
            appear
          >
            <div class="vue-b-header vue-strategy-container">
              <h2 class="vue-title">
                {{ $t('strategy.mainObjectivesHeader') }}
              </h2>
            </div>
          </transition>

          <transition
            name="vue-anim-strategy-move-from-right"
            appear
          >
            <div class="vue-b-content vue-strategy-container">
              <gen1015-section
                v-for="objective in objectives"
                :key="objective.id"
                :collapsible="true"
                :collapsed="true"
                class="is-animated"
              >
                <template v-slot:header>
                  <h3>{{ objective.title }}</h3>
                </template>

                <template v-slot:content>
                  <div class="vue-b-content-animated">
                    <div
                      ref="strategyContent"
                      class="vue-b-content-wrapper"
                    >
                      <p class="vue-objective-description">
                        {{ objective.body }}
                      </p>

                      <!--======= OBJECTIVE - CLIENTS =============-->
                      <!--=========================================-->
                      <div
                        v-if="objective.visualisation === 'nps-people'"
                        class="vue-objective-visualisation vue-is-nps-people"
                      >
                        <div class="vue-nps vue-is-9">
                          <span
                            class="vue-control"
                          >
                            <span
                              class="vue-control-text"
                            >97%</span>
                          </span>
                          <div class="vue-bar-items">
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                          </div>
                          <span class="vue-legend-from">0</span>
                          <span class="vue-legend-to">1.2 mil.</span>
                        </div>
                      </div>

                      <!--======= OBJECTIVE - DIGITAL =============-->
                      <!--=========================================-->
                      <div
                        v-if="objective.visualisation === 'fill-mobile'"
                        class="vue-objective-visualisation vue-is-fill-mobile"
                      >
                        <div class="vue-mobile vue-is-10">
                          <span
                            class="vue-control"
                          >
                            <span
                              class="vue-control-text"
                            >99%</span>
                          </span>
                          <div class="vue-mobile-item" />
                          <span class="vue-legend-from">0</span>
                          <span class="vue-legend-to">700 tis.</span>
                        </div>
                      </div>

                      <!--======= OBJECTIVE - KATE ================-->
                      <!--=========================================-->
                      <div
                        v-if="objective.visualisation === 'nps-tasks'"
                        class="vue-objective-visualisation vue-is-nps-tasks"
                      >
                        <div class="vue-nps vue-is-9">
                          <span
                            class="vue-control"
                          >
                            <span
                              class="vue-control-text"
                            >92%</span>
                          </span>

                          <div class="vue-bar-items">
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                            <span class="vue-bar-item" />
                          </div>
                          <span class="vue-legend-from">0</span>
                          <span class="vue-legend-to">75</span>
                        </div>
                      </div>


                      <!--======= OBJECTIVE - CLIMATIC ============-->
                      <!--=========================================-->
                      <div
                        v-if="objective.visualisation === 'climatic-change'"
                        class="vue-objective-visualisation vue-is-climatic-change"
                      >
                        <!-- Animation about #ZelenaVyzva -->
                        <div class="vue-green-challenge">
                          <div class="vue-clouds" />
                          <div class="vue-bike" />

                          <div class="vue-challenge-logo" />

                          <div class="vue-ground" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </gen1015-section>
            </div>
          </transition>

          <transition
            name="vue-anim-strategy-move-from-top"
            appear
          >
            <div
              v-transition-delay-enter="200"
              class="vue-detail-illustration"
            />
          </transition>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script type="application/javascript">

//=== PRJ
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

//=== CONSTANTS
import * as STRATEGY_MAIN_OBJECTIVES from '@/assets/mocks/strategyMainObjectivesData';
import {STRATEGY_DEFAULT_LANG} from '@/constants/app-constants';
import PageLayout from '@/templates/partials/page-layout';
import router from '@/router';

export default {
  name: 'StrategyDetail',
  components: {
    Prj1002Navbar,
    PageLayout
  },

  data() {
    return {}
  },

  computed: {
    objectives() {
      let lang = this.$i18n.locale;
      const strategyObjectivesData = STRATEGY_MAIN_OBJECTIVES.DATA_ARRAY.mainObjectives;

      if (strategyObjectivesData.length === 1) {
        const objectives = strategyObjectivesData[0];

        if (!Object.prototype.hasOwnProperty.call(objectives, lang)) {
          lang = STRATEGY_DEFAULT_LANG;
        }

        return objectives[lang]['items']
      }

      return [];
    }
  },

  created() {
    window.addEventListener('resize', this.setCssVariablesSectionHeight);
  },

  mounted() {
    this.$nextTick(function () {
      this.setCssVariablesSectionHeight();
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setCssVariablesSectionHeight);
  },

  methods: {
    bulletContent(text) {
      return text.charAt(0)
    },
    // TODO: moved to utils because it is also used in summary and detail
    setCssVariablesSectionHeight() {
      const minTransitionDuration = 500;
      const transitionDurationDiff = 1;

      for (let i = 0; i < this.$refs.strategyContent.length; i++) {
        const contentHeight = this.$refs.strategyContent[i].offsetHeight;
        const transitionDurationComputed = transitionDurationDiff * contentHeight;
        const transitionDuration = transitionDurationComputed > minTransitionDuration ? transitionDurationComputed : minTransitionDuration;

        document.documentElement.style.setProperty('--strategyContentHeightId' + i, contentHeight + 'px');
        document.documentElement.style.setProperty('--strategyContentDurationId' + i, transitionDuration + 'ms');
      }
    },

    excludedValueClassObject(excluded) {
      return [
        {
          'vue-is-bullet-excluded': excluded,
        }
      ]
    },

    firstLetterEscape(value, escape) {
      return escape ? value.substring(escape).trim() : value;
    },

    buttonBackEvent() {
      router.push({name: 'strategy'});
    },
  },
}
</script>
