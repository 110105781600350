import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { version } from '../package.json';

export default function(Vue) {
  if (globalConfig.ENV_SENTRY) {
    Sentry.init({
      Vue,
      dsn: 'https://93c2588be57e46cda526eb718610b866@o504823.ingest.sentry.io/5596716',
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.1, // for now, 20% recorded transactions globally is enough
      environment: globalConfig.ENV_NAME,
      release: 'zapka@' + version
    });
  }
}
