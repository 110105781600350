<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-surf-studio-voice">
        <template
          v-if="initialized && !isLoading"
        >
          <prj1004-article-body
            v-if="articleData"
            :articleData="articleData"
          />
          <gen1006-info-panel
            v-else
            type="error"
          >
            {{ $t('general.articleNonexistent') }}
          </gen1006-info-panel>

          <gen1006-info-panel
            v-if="!isLoading && !initialized"
            type="neutral"
          >
            {{ $t('general.loadingFailed') }}
          </gen1006-info-panel>
        </template>
        <div class="vue-navigation-wrap">
          <frm1006-button
            v-for="control in controls"
            :key="control.id"
            :icon-right="true"
            class="vue-control-item"
            type="internal"
            @buttonClickEvent="goToView(control)"
          >
            <span>{{ control.title }}</span>
          </frm1006-button>
        </div>
      </div>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {mapState} from 'vuex';
import {
  ERROR_MODAL_TIMEOUT, SURF_STUDIO_DASHBOARD_ID
} from '@/constants/app-constants';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import router from '@/router';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import Prj1004ArticleBody from '@/components/prj1004-article-detail/prj1004-article-body';

export default {
  name: 'SurfStudioVoice',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1004ArticleBody
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      articleFetched: {},
      ERROR_MODAL_TIMEOUT,
      controls: null,
      pageId: ''
    };
  },

  computed: {
    ...mapState('dataStore', [
      'contentData'
    ]),

    articleCached() {
      return (typeof this.contentData.pages.data[this.pageId] !== 'undefined' ? this.contentData.pages.data[this.pageId] : false);
    },

    articleData() {
      return this.articleCached || this.articleFetched;
    },

    navbarCaption() {
      return this.$route.meta.navbarCaption;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.getSurfStudioVoiceControls().then(() => {
      this.fetchPageDetail();
      this.initialized = true;
    });
  },

  methods: {
    setLoadingState(value) {
      if (value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    fetchPageDetail() {
      this.setLoadingState(true);

      return dataLoader.fetchPostById(this.pageId)
        .then(data => {
          this.articleFetched = data[0];
        })
        .catch(error => {
          logger.error(error);
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.setLoadingState(false);
        })
    },

    getSurfStudioVoiceControls() {
      this.setLoadingState(true);

      return dataLoader.fetchApiDataList(SURF_STUDIO_DASHBOARD_ID)
        .then(data => {
          this.controls = data.surfStudioVoiceControls;
          this.pageId = data.surfStudioVoicePageId;
          this.surfStudioDataLoaded = true;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    goToView(item) {
      let params = {
        id: item.pageId
      }
      if (item.hasButton) {
        params.button = {
          buttonText: item.buttonText,
          buttonLink: item.buttonLink
        }
      }
      router.push({name: 'surf-studio-voice-page', params})
    },
  },
}
</script>

