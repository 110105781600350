<template>
  <page-layout
    ref="pageLayout"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="goToDashboard"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <gen1006-info-panel
        type="neutral"
      >
        <p
          v-html="$i18n.t('parents.privateMessage', { pageId: installationPageId})"
        />
      </gen1006-info-panel>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import router from '@/router';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

export default {
  name: 'ParentsBabysittingFormWarning',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
    }
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),

    installationPageId() {
      let pageId = -1;

      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'HELP_INSTALLATION_PAGE_ID') {
            pageId = parseInt(item.value);
          }
        });
      }

      return pageId;
    }
  },

  methods: {
    goToDashboard() {
      router.push( { name: 'parents-dashboard' } );
    },
  }
}
</script>
