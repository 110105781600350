<template>
  <div
    class="vue-c-marketplace-list-item"
  >
    <prj1027-event-filter
      v-if="showFilter"
      :displayFilterButton="!isFilterActive"
      @openFilterDialog="openFilterDialog"
      @resetFilteredItems="resetFilteredItems"
    />

    <div class="vue-b-item-list">
      <div
        v-for="(listItem, index) in listData"
        :key="'list-item-' + index"
        class="vue-b-item-row"
        :class="{'vue-has-row-2': listItem.promoted}"
      >
        <prj1051-marketplace-list-item
          :key="componentKey"
          :itemData="listItem"
          :listItemConfig="listItemConfig"
          :promoted="allPromoted || listItem.promoted"
          @goToDetail="goToDetail(listItem)"
        />
      </div>
    </div>

    <div
      v-if="isInfinityScrollEnabled"
      ref="infinityScrollTrigger"
    >
      <gen1016-loading-indicator
        :active="isInfinityScrollEnabled"
      />
    </div>

    <gen1006-info-panel
      v-if="!listData.length"
      type="neutral"
      class="vue-no-data"
    >
      {{ $t('marketplace.noAds') }}
    </gen1006-info-panel>

    <gen1009-overlay
      class="vue-is-info-panel"
      :active.sync="hasError"
      :buttonClose="true"
      :closeAfter="ERROR_MODAL_TIMEOUT"
    >
      <gen1006-info-panel
        type="error"
      >
        {{ $t('error.generic') }}
      </gen1006-info-panel>
    </gen1009-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mxInfinityScrolling from '@/mixins/mx-infinity-scrolling';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

import {
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';

import Prj1051MarketplaceListItem from './prj1051-marketplace-list-item';
import Prj1027EventFilter from '@/components/prj1027-event-filter/prj1027-event-filter';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

export default {
  name: 'Prj1051MarketplaceList',
  components: {
    Prj1051MarketplaceListItem,
    Prj1027EventFilter
  },

  mixins: [
    mxInfinityScrolling,
    mxDetectDesktop
  ],

  props: {
    listData: {
      type: Array,
      required: true,
    },
    listItemsCount: {
      type: Number,
      required: true,
    },
    isFilterActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    noMoreItems: {
      type: Boolean,
      required: true,
    },
    componentKey: {
      type: Number,
      required: true,
    },
    listItemConfig: {
      type: Object,
      required: true,
    },
    showFilter: {
      type: Boolean,
      required: false,
      default: true
    },
    allPromoted: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasError: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    ...mapState('user', [
      'syncData'
    ]),

    isInfinityScrollEnabled() {
      return this.listItemsCount > 20 && !this.noMoreItems
    },
  },

  methods: {
    loadNewContent() {
      this.$emit('loadNewContent');
    },

    openFilterDialog(event) {
      let contextContainerPositions = false;
      if (this.isDesktopLayout) {
        contextContainerPositions = {
          x: event.pageX,
          y: false,
          mode: 'top-left'
        }
      }
      this.$store.dispatch(STORE_MODULES.POPUP + '/' + ACTIONS_CONSTANTS.POPUP_POSITION, contextContainerPositions);
      this.$emit('openFilterDialog');
    },

    resetFilteredItems() {
      this.$emit('resetFilteredItems');
    },

    goToDetail(item) {
      this.$emit('goToDetail', item);
    }
  }
}
</script>
