import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import dataLoader from '@/utils/data-loader';
import utilsGeneral from '@/utils/utils-general';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import logger from '@/utils/logger';

const state = {
  surveyLoaded: false,
  surveyHasError: false,
  surveyContent: {},
  surveyOrganizationStructure: {
    company: '',
    division: ''
  }
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_SURVEY_IS_LOADED](state, surveyState) {
    state.surveyLoaded = surveyState;
  },

  [MUTATIONS_CONSTANTS.SET_SURVEY_HAS_ERROR](state, surveyErrorState) {
    state.surveyHasError = surveyErrorState;
  },

  [MUTATIONS_CONSTANTS.SET_SURVEY_CONTENT](state, surveyContent) {
    state.surveyContent = surveyContent;
  },

  [MUTATIONS_CONSTANTS.SET_SURVEY_ORGANIZATION_STRUCTURE](state, orgStructure) {
    state.surveyOrganizationStructure.company = orgStructure.company;
    state.surveyOrganizationStructure.division = orgStructure.division;
  }
};

const actions = {
  [ACTIONS_CONSTANTS.MAKE_SURVEY_CONTENT]({ commit, state }, options) {
    return new Promise((resolve) => {
      const surveyConfigItems = Object.keys(options.surveyConfigItems);
      let fetchPostsIds = [];
      let surveyContentObject = {};

      surveyConfigItems.forEach(configItem => {
        const convertedItemName = utilsGeneral.convertToCamelCase(configItem);

        if (options.surveyConfigItems[configItem].type === 'postId') {
          fetchPostsIds.push(options.surveyConfigItems[configItem].value);
        } else {
          surveyContentObject[convertedItemName] = {
            value: options.surveyConfigItems[configItem].value,
            type: options.surveyConfigItems[configItem].type
          }
        }
      });

      if (fetchPostsIds.length) {
        dataLoader.fetchMultiplePostsById(fetchPostsIds)
          .then(data => {
            data.forEach(post => {
              const postData = post.data[0];
              let postCategoryId = Object.entries(options.surveyConfigItems).filter(item => item[1].value === postData.id);
              const name = utilsGeneral.convertToCamelCase(postCategoryId[0][0]).replace('PageId', 'Content');
              const value = postData.content;

              surveyContentObject[name] = {
                type: 'content',
                value: value
              };
            })
          })
          .catch(error => {
            logger.error(error);
            commit(MUTATIONS_CONSTANTS.SET_SURVEY_HAS_ERROR, true);
          })
          .finally(() => {
            if (state.surveyHasError) {
              setTimeout(() => {
                commit(MUTATIONS_CONSTANTS.SET_SURVEY_HAS_ERROR, false);
              }, ERROR_MODAL_TIMEOUT);
            } else {
              commit(MUTATIONS_CONSTANTS.SET_SURVEY_CONTENT, surveyContentObject);
              commit(MUTATIONS_CONSTANTS.SET_SURVEY_IS_LOADED, true);

              return resolve();
            }
          });
      } else {
        commit(MUTATIONS_CONSTANTS.SET_SURVEY_CONTENT, surveyContentObject);
        commit(MUTATIONS_CONSTANTS.SET_SURVEY_IS_LOADED, true);

        return resolve();
      }
    });
  }
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
