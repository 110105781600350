// Dayjs

import Vue from 'vue';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/cs';
import 'dayjs/locale/en-gb';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isoWeek);

const lang = document.documentElement.lang;
let locale;

switch (lang) {
case 'en':
  locale = 'en-gb';
  break;
case 'cs':
  locale = 'cs';
  break;
}
dayjs.locale(locale);

const updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  formats : {
    L: 'MM/DD',
    LL: 'MM/DD/YYYY',
  }
})

dayjs.updateLocale('cs', {
  formats : {
    L: 'D.  M.',
    LL: 'D.  M. YYYY',
  }
})

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs;
    }
  }
});
