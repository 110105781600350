<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div class="flex -ml-2 pt-6 pb-2">
    <!--========== BACK BUTTON ============================-->
    <!--===================================================-->
    <t-nav-button
      class="ml-1 opacity-0 pointer-events-none"
      :variant="navButtonVariant"
      @click="processButtonNavigationClick"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </t-nav-button>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1005NavigationBar',
  components: {},
  props: {
    routeName: String,
    navButtonVariant: String,
  },
  computed: {

  },
  methods: {
    processButtonNavigationClick() {
      if (this.routeName) {
        this.$router.push({ name: this.routeName });
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>
