<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    id="vue-page-default"
    class="vue-page vue-page-default"
    :class="classObject"
  >
    <component :is="component" />
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'PageDefault',
  props: {
    component: [Object, Function],
    modules: Array,
  },

  computed: {
    classObject() {
      let moduleClasses = [];

      for (let moduleKey in this.modules) {
        let moduleTitle = this.modules[moduleKey];

        moduleClasses.push('vue-m-' + moduleTitle);
      }

      return [
        moduleClasses
      ];
    }
  }
};
</script>
