export const PUBLIC_KEY_HASH = '';
export const PRIVATE_USER_PROFILE = '';
export const PRIVATE_USER_ORG_CONTACTS = '';
export const PRIVATE_USER_PUBLIC_AUTHORIZE = '';
export const GET_PRIVATE_PARKING_CAPACITY = '';
export const PRIVATE_PARKING_RESERVATIONS = '';
export const SEND_JOB_INTEREST_SF = '';

export const GET_MOTOR_KPI_DATA = '';
export const GET_ONBOARDING_SF_CANDIDATES = '';
export const ONBOARDING_APPLICANT_DATA = '';
export const GET_ONBOARDING_DEPARTMENTS_LIST = '';
export const GET_ONBOARDING_JOBTITLES_LIST = '';
export const GET_ONBOARDING_EMPLOYEES_LIST = '';
export const HOST = '';
export const SEND_PARENTS_BABYSITTING_FORM = '';
export const SEND_CHATBOT_FORM_HR = '';
export const CHATBOT_HR_CHAT = '';
