export const chartUI = {
  baseTxtCol: 'text-gray-400',
  baseBgCol: 'bg-gray-400',
  targetBaseTxtCol: 'text-gray-300',
  targetBaseBgCol: 'bg-gray-300',
  highlightedTxtCol: 'text-graph-summary-success',
  highlightedBgCol: 'bg-teal-500',
  legendIcon: `<svg
          width="100%"
          height="100%"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
         >
          <circle
            cx="5"
            cy="5"
            r="5"
            fill="currentColor"
          />
         </svg>`,
  personIcon: `<svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 4.35418C12.7329 3.52375 13.8053 3 15 3C17.2091 3 19 4.79086 19 7C19 9.20914 17.2091 11 15 11C13.8053 11 12.7329 10.4762 12 9.64582M15 21H3V20C3 16.6863 5.68629 14 9 14C12.3137 14 15 16.6863 15 20V21ZM15 21H21V20C21 16.6863 18.3137 14 15 14C13.9071 14 12.8825 14.2922 12 14.8027M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>`
}
