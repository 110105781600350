<template>
  <div
    class="vue-c-detail-header"
    :class="classObject"
  >
    <div
      v-if="headerImageClick && hasImage"
      v-img="vImgOptions"
      class="vue-detail-image"
      :style="styleObject"
    >
      <gen1005-tag-container
        v-if="headerTag"
      >
        <gen1004-tag-label>
          {{ headerTag }}
        </gen1004-tag-label>
      </gen1005-tag-container>

      <prj1019-star-rating
        v-if="headerRating"
        class="vue-detail-rating"
        :itemRating="headerRating"
      />
    </div>
    <div
      v-else
      class="vue-detail-image"
      :style="styleObject"
    >
      <gen1005-tag-container
        v-if="headerTag"
      >
        <gen1004-tag-label>
          {{ headerTag }}
        </gen1004-tag-label>
      </gen1005-tag-container>

      <prj1019-star-rating
        v-if="headerRating"
        class="vue-detail-rating"
        :itemRating="headerRating"
      />
    </div>
  </div>
</template>

<script>
import Prj1019StarRating from '@/components/prj1019-star-rating/prj1019-star-rating';

export default {
  name: 'Prj1001DetailHeader',
  components: {
    Prj1019StarRating
  },

  props: {
    headerImage: {
      type: String,
      required: true,
      default: '',
    },
    headerImageClick: {
      type: Boolean,
      required: false,
      default: false
    },
    headerTag: {
      type: String,
      required: false,
    },
    headerRating: {
      type: String,
      required: false,
    },
  },

  computed: {
    classObject() {
      return {
        'vue-has-no-image': !this.hasImage,
      }
    },

    vImgOptions() {
      return {
        src: this.headerImage ?? ''
      }
    },

    hasImage() {
      return this.headerImage !== '';
    },

    styleObject() {
      if (this.headerImage) {
        return 'background-image: url("' + this.headerImage + '")'
      } else {
        return ''
      }
    },
  },
}
</script>
