<template>
  <page-layout class="vue-m-intro">
    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <frm1006-button
        class="vue-navbar-button vue-is-back vue-content-back-button"
        :iconLeft="true"
        :captionHidden="true"
        type="app-button"
        @buttonClickEvent="navigateBack"
      >
        <template v-slot:iconLeft>
          <span class="vue-icon-inner" />
        </template>
      </frm1006-button>

      <prj1017-heading
        :header="$i18n.t('general.confirmationCode')"
      />

      <gen1006-info-panel
        type="info"
      >
        {{ $t('general.confirmationCodeSentAgainInfo') }}
      </gen1006-info-panel>

      <frm1035-form-group>
        <frm1001-input-field
          id="code-input"
          ref="codeInput"
          v-model="code"
          name="code"
          :maskToggle="maskToggle"
          :tooltip="false"
          :label="$i18n.t('general.confirmationCode')"
          :disabled="isAuthorizing"
          @inputKeyDownEvent="loginFromInput"
        />
      </frm1035-form-group>
    </template>
    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <!-- CONFIRMATION BUTTON -->
      <Frm1006Button
        type="primary"
        :disabled="code.length < 1"
        @buttonClickEvent="confirmationProcess()"
      >
        {{ $t('general.continue') }}
      </Frm1006Button>

      <Frm1006Button
        type="secondary"
        @buttonClickEvent="confirmationCodeSendAgainProcess()"
      >
        {{ $t('general.confirmationCodeSentAgain') }}
      </Frm1006Button>
    </template>
    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isAuthorizing"
        :fullScreen="true"
        :overlay="true"
      />
      <gen1009-overlay
        class="vue-intro-error-overlay vue-is-info-panel"
        :buttonClose="true"
        :active.sync="hasError"
      >
        <gen1006-info-panel
          v-if="formError.length > 0"
          :type="'error'"
        >
          {{ $t('intro.error.' + formError) }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import logger from '@/utils/logger';
import client from '@/utils/data-loader';
import PageLayout from '@/templates/partials/page-layout';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import {APP_DESTINATIONS, ERROR_CODES_LOGIN} from '@/constants/general';
import router from '@/router';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import {mapGetters, mapState} from 'vuex';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';

export default {
  name: 'VerifyCode',
  components: {
    PageLayout,
    Prj1017Heading
  },

  mixins: [
    mxDetectDesktop
  ],

  data() {
    return {
      code: '',
      maskToggle: false,
      isAuthorizing: false,
      hasError: false,
      formError: '',
    }
  },

  computed: {
    ...mapState('general', [
      'appDestination'
    ]),
    ...mapState('user', [
      'isStandalone',
      'platform',
      'browser',
      'deviceId',
      'profile',
    ]),
    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY
    }),

    paramEmail() {
      if(typeof this.$route.params?.email === 'undefined') {
        return '';
      }
      return this.$route.params.email
    },

    paramPersonalEmail() {
      if(typeof this.$route.params?.personalEmail === 'undefined') {
        return false;
      }
      return this.$route.params.personalEmail
    },

    paramAuthType() {
      return this.$route.params.authType
    },

    paramAuthReferer() {
      if(typeof this.$route.params?.authReferer === 'undefined') {
        return '';
      }
      return this.$route.params.authReferer
    },

    paramAuthRefererAction() {
      if(typeof this.$route.params?.authRefererAction === 'undefined') {
        return '';
      }
      return this.$route.params.authRefererAction
    },

    paramAuthRefererParams() {
      if(typeof this.$route.params?.authRefererParams === 'undefined') {
        return {};
      }
      return this.$route.params.authRefererParams
    }
  },

  created() {
    if (this.paramEmail === '' || this.paramAuthType === '') {
      this.navigateBack();
    }
  },

  mounted() {
    if (this.isDesktopLayout) {
      this.$nextTick(() => {
        this.$refs.codeInput.inputSetFocus();
      });
    }
  },

  methods: {
    confirmationProcess() {
      if (this.code !== '') {
        this.isAuthorizing = true;

        let formData = new FormData();
        formData.append('deviceId', this.deviceId);
        formData.append('code', this.code);
        formData.append('email', this.paramEmail);
        formData.append('personalEmailFlag', this.paramPersonalEmail);

        this.confirmationProcessFetch(formData);
      }
    },

    confirmationCodeSendAgainProcess() {
      this.isAuthorizing = true;

      let formData = new FormData();
      formData.append('deviceId', this.deviceId);
      formData.append('email', this.paramEmail);
      formData.append('personalEmailFlag', this.paramPersonalEmail);

      this.confirmationCodeSendAgainProcessFetch(formData);
    },

    confirmationProcessFetch(formData) {
      client.fetchAuthorization(formData)
        .then(result => {
          if (!result.refreshToken && this.paramPersonalEmail) {
            this.$router.push({ name: 'registration-parents', params: {
              deviceId: this.deviceId,
              email: this.paramEmail,
              personalEmailFlag: this.paramPersonalEmail
            }
            });
          } else {
            this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_ANONYMOUS, result.anonymous);
            this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_REFRESH_TOKEN, result.refreshToken);
            this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PUBLIC_KEY, result.publicKey);
            this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_LAST_LOGGED, new Date().getTime());
            this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, true);

            this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.IDENTITY_PROFILE, {force: true}).finally(() => {
              this.isAuthorizing = false;
              if (this.profile.name !== '' && this.profile.consentPersonalData === true) {
                // Login finished and go back to referrer
                if (this.paramAuthReferer !== '') {
                  if (this.paramAuthRefererAction === '') {
                    this.$router.push({ name: this.paramAuthReferer, params: this.paramAuthRefererParams});
                  } else {
                    this.$router.push({
                      name: this.paramAuthReferer,
                      params: {
                        ...this.paramAuthRefererParams,
                        action: this.paramAuthRefererAction
                      }
                    });
                  }
                } else {
                  // Go to Dashboard
                  this.$router.push({ name: 'dashboard'});
                }
              } else {
                // Continue to registration
                this.$router.push(
                  { name: 'registration',
                    params: {
                      email: this.email,
                      authType: this.paramAuthType,
                      authReferer: this.paramAuthReferer,
                      authRefererAction: this.paramAuthRefererAction,
                    }
                  }
                );
              }
            });
          }
        }, error => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
          this.isAuthorizing = false;
          this.hasError = true;

          if (error.response.data.hasOwnProperty('code') && ERROR_CODES_LOGIN.includes(error.response.data.code)) {
            this.formError = error.response.data.code;
          } else if (this.appDestination === APP_DESTINATIONS.PRIVATE && this.browser !== 'Chrome') {
            // desktop PC in CSOB has internet access limited to Chrome browser only, while default browser is Edge.
            // we customize the error message to advise user to switch to Chrome when CMS is not available
            this.formError = 'communicationErrorPrivate';
          } else {
            this.formError = 'communicationError';
          }

          logger.error('Error processing login', error);
        })
    },

    confirmationCodeSendAgainProcessFetch(formData) {
      client.fetchAuthorization(formData)
        .then(() => {
          this.isAuthorizing = false;
        }, error => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
          this.isAuthorizing = false;
          this.hasError = true;

          if (error.response.data.hasOwnProperty('code') && ERROR_CODES_LOGIN.includes(error.response.data.code)) {
            this.formError = error.response.data.code;
          } else if (this.appDestination === APP_DESTINATIONS.PRIVATE && this.browser !== 'Chrome') {
            // desktop PC in CSOB has internet access limited to Chrome browser only, while default browser is Edge.
            // we customize the error message to advise user to switch to Chrome when CMS is not available
            this.formError = 'communicationErrorPrivate';
          } else {
            this.formError = 'communicationError';
          }

          logger.error('Error processing login', error);
        })
    },

    loginFromInput(value, event) {
      if (event.key === 'Enter') {
        this.confirmationProcess();
      }
    },

    navigateBack() {
      if (this.hasIdentity) {
        router.go(-1);
      } else {
        this.$router.push({ name: 'login', params: { option: 'forced' } })
      }
    },
  }
}
</script>
