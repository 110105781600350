<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div
        :id="idComponent"
        class="vue-component vue-c-information-tooltip"
        :class="classObject"
        :aria-hidden="!expandedData"
    >
        <div ref="tooltipIcon" class="vue-icon" @click="toggle" @mouseover="mouseOver" @mouseleave="mouseLeave" />

        <Gen1010InformationTooltipMessage
            ref="tooltipMessage"
            :buttonClose="buttonClose"
            :buttonCloseCaption="buttonCloseCaption"
            :content="content"
            :contentId="contentIdComputed"
            :expanded="expandedData"
            @closeEvent="close('button')"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltipMessage from './gen1010-information-tooltip-message.vue';

//=== MIXINS
import Component from '../../mixins/component';
import Position from '../../mixins/position';

//=== MISC
import config from '../../../config';
import { ID_EXTENSIONS } from '../../../constants';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'gen1010';

//============ OPTIONS =================================//
//======================================================//
let options = {
    messagePositionDefault: {
        x: 'left',
        y: 'top'
    },
    scrollDebounceTimeout: 150
};

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1010InformationTooltip',
    components: {
        Gen1010InformationTooltipMessage
    },
    mixins: [Component, Position],
    props: {
        mode: {
            default: 'click',
            type: String,
            validator: value => {
                return ['click', 'hover'].includes(value);
            }
        },
        expanded: Boolean,
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        disabled: Boolean,
        content: String,
        contentId: String,
        buttonClose: {
            default: true,
            type: Boolean
        },
        buttonCloseCaption: {
            default: 'Zavřít',
            type: String
        },
        whiteList: {
            default: () => [],
            type: Array
        },
        boundComponentActive: Boolean,
        // if this option is active, clicking on tooltip icon preventDefault is triggered to prevent bound component losing focus
        boundComponentPreventLosingFocus: Boolean,
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    data() {
        return {
            messagePosition: {
                x: options.messagePositionDefault.x,
                y: options.messagePositionDefault.y
            },
            expandedData: false,
            scrollDebounce: null
        };
    },
    computed: {
        classObject() {
            return [
                'vue-is-' + this.state,
                'vue-has-position-' + this.messagePosition.x,
                'vue-has-position-' + this.messagePosition.y,
                {
                    'vue-is-expanded': this.expandedData,
                    'vue-is-empty': this.content === '' || this.content === null,
                    'vue-is-disabled': this.disabled
                }
            ];
        },
        contentIdComputed() {
            if (this.contentId && !this.id) {
                return this.contentId;
            } else if (!this.contentId && this.id) {
                return this.idComputed + ID_EXTENSIONS.CONTENT;
            }

            return null;
        }
    },
    watch: {
        expanded(value) {
            // watch prop, if it changes set data
            this.expandedData = value;
        },
        expandedData(value) {
            this.$emit('update:expanded', value);

            if (this.expandedData) {
                // wait until message is really rendered
                this.$nextTick().then(() => {
                    this.updateMessagePosition();
                    this.createViewportChangeListeners();
                });
                document.addEventListener('click', this.click);
                document.addEventListener('keyup', this.keyUp);
            } else {
                // on close we need to reset position of tooltip to default (in case it stays hidden in dom)
                this.resetMessagePosition();
                this.removeViewportChangeListeners();
                document.removeEventListener('click', this.click);
                document.removeEventListener('keyup', this.keyUp);
            }
        }
    },
    mounted() {
        // set initial state from prop to data
        this.expandedData = this.expanded;

        // this icon is used when preventing losing focus on bound component, notice @mousedown.prevent -->
        if (this.$refs.tooltipIcon) {
            this.$refs.tooltipIcon.addEventListener('mousedown', this.mouseDown);
        }
    },
    destroyed() {
        if (this.$refs.tooltipIcon) {
            this.$refs.tooltipIcon.removeEventListener('mousedown', this.mouseDown);
        }
    },
    methods: {
        //=== EVENTS
        mouseOver(event) {
            if (this.mode === 'hover' && !this.disabled) {
                this.expandedData = true;
                // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
                this.$emit('mouseover', event);
                this.$emit('tooltipMessageExpandEvent', 'mouseOver');
            }
        },
        mouseLeave(event) {
            if (this.mode === 'hover') {
                this.expandedData = false;
                // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
                this.$emit('mouseleave', event);
                this.$emit('tooltipMessageCloseEvent', 'mouseLeave');
            }
        },
        mouseDown(event) {
            // prevent losing focus on bound component when clicking on tooltip icon
            if (this.boundComponentPreventLosingFocus && this.boundComponentActive) {
                event.preventDefault();
            }

            this.$emit('mouseDownEvent', event);
        },
        //=== GENERAL
        toggle(trigger) {
            if (this.mode === 'click' && !this.disabled) {
                this.expandedData = !this.expandedData;
                this.$emit('tooltipMessageToggleEvent', trigger, this.expandedData); // TODO MBU: consider calling open or close function which would handle emitting events
            }
        },
        open(trigger) {
            this.expandedData = true;
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('tooltipMessageExpandEvent', trigger);
        },
        close(trigger) {
            this.expandedData = false;
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('tooltipMessageCloseEvent', trigger);
        },
        //=== POSITION
        // TODO MBU: mixin?
        updateMessagePosition() {
            // set current position for calculation
            let positionData = this.getPositionRelativeTo(this.$refs.tooltipMessage.$el, this.messagePosition);
            // update only when needed
            this.messagePosition = positionData.position;
        },
        resetMessagePosition() {
            this.messagePosition.x = options.messagePositionDefault.x;
            this.messagePosition.y = options.messagePositionDefault.y;
        },
        windowScrolled() {
            if (this.scrollDebounce) {
                clearTimeout(this.scrollDebounce);
            }
            this.scrollDebounce = setTimeout(() => {
                this.updateMessagePosition();
                this.scrollDebounce = null;
            }, options.scrollDebounceTimeout);
        },
        windowResized() {
            if (this.expandedData) {
                this.updateMessagePosition();
            }
        },
        createViewportChangeListeners() {
            window.addEventListener('scroll', this.windowScrolled);
            window.addEventListener('resize', this.windowResized);
        },
        removeViewportChangeListeners() {
            window.removeEventListener('scroll', this.windowScrolled);
            window.removeEventListener('scroll', this.windowResized);
            if (this.scrollDebounce) {
                clearTimeout(this.scrollDebounce);
                this.scrollDebounce = null;
            }
        },
        //=== TOOLTIP MESSAGE
        click(event) {
            if (!this.whiteListCheck(event)) {
                this.close('clickOutside');
            }
        },
        keyUp(event) {
            if (event.key === 'Escape') {
                this.close('esc');
            }
            // TODO MBU: check for another solution
            // focus check is placed on tab keyup (handles also shift tab automatically)
            // originally should have been on watch on boundComponentActive, but on tabs between multiple component elements
            // document.activeElement is set to component element > body > component element which results in
            // boundComponentActive is set to true > false > true
            // if the check is set to keyup, it's computed later, when boundComponentActive stabilized to final value after focus / blur events
            if (event.key === 'Tab') {
                if (
                    this.expandedData &&
                    !this.boundComponentActive &&
                    this.whiteList.indexOf(document.activeElement) === -1
                ) {
                    this.close('focusedOutsideWhiteList');
                }
            }
        },
        // checks if clicked item is on white list, if it is, it won't trigger clickOutside
        whiteListCheck(event) {
            let element = event.target;

            // tooltip icon
            if (element === this.$refs.tooltipIcon) {
                return true;
            }

            // tooltip message
            // TODO MBU: manual solution with path, instead of this.$refs.contentContainer.contains(element) recommended here https://stackoverflow.com/a/28432139
            // TODO MBU: that solution was occasionaly failing in some unpredicatable cases, validate solution, delete old one below if ok
            // if (this.$refs.tooltipMessage.$el.contains(element)) {
            //     return true;
            // }

            for (let element of event.path) {
                if (element === this.$refs.tooltipMessage.$el) {
                    return true;
                }
            }

            // passed white list
            if (this.whiteList.length > 0 && this.whiteList.indexOf(element) > -1) {
                return true;
            }

            return false;
        }
    }
};
</script>
