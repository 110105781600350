import * as MUTATIONS_CONSTANTS from '../constants/mutations'
import * as ACTIONS_CONSTANTS from '../constants/actions';
import dataLoader from '@/utils/data-loader';

const state = {
  marketplaceForm: {
    id: 0,
    name: '',
    description: '',
    price: '',
    category: '',
    condition: '',
    location: '',
    duration: '',
    expired: false,
    image: null,
    imageOldPath: '',
    consent: false,
    completed: false
  },
  marketplaceFilterCategories: {
    locations: {},
    categories: {},
    durations: {},
    conditions: {}
  },
  marketplaceSelectedCategories: {
    location: '',
    category: '',
    duration: '',
    condition: '',
    searchString: ''
  },
  marketplaceActiveCategory: 0,
  marketplaceAreFiltersActive: false,
  searchQuery: '',
  detailData: {},
  isSearchActive: false,
  isSearchHistoryActive: false,
}

const mutations = {
  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_ID](state, id) {
    state.marketplaceForm.id = id;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_NAME](state, name) {
    state.marketplaceForm.name = name;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_DESCRIPTION](state, description) {
    state.marketplaceForm.description = description;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_PRICE](state, price) {
    state.marketplaceForm.price = price;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_CATEGORY](state, category) {
    state.marketplaceForm.category = category;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_CONDITION](state, condition) {
    state.marketplaceForm.condition = condition;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_LOCATION](state, location) {
    state.marketplaceForm.location = location;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_DURATION](state, duration) {
    state.marketplaceForm.duration = duration;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_EXPIRED](state, expired) {
    state.marketplaceForm.expired = expired;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_IMAGE](state, image) {
    state.marketplaceForm.image = image;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_IMAGE_OLD](state, imageOld) {
    state.marketplaceForm.imageOldPath = imageOld;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_CONSENT](state, consent) {
    state.marketplaceForm.consent = consent;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_COMPLETED](state, completed) {
    state.marketplaceForm.completed = completed;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FORM_RESET](state) {
    state.marketplaceForm.id = 0;
    state.marketplaceForm.name = '';
    state.marketplaceForm.description = '';
    state.marketplaceForm.price = '';
    state.marketplaceForm.category = '';
    state.marketplaceForm.condition = '';
    state.marketplaceForm.location = '';
    state.marketplaceForm.duration = '';
    state.marketplaceForm.expired = false;
    state.marketplaceForm.image = null;
    state.marketplaceForm.imageOldPath = '';
    state.marketplaceForm.consent = false;
    state.marketplaceForm.completed = false;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_FILTER_CATEGORIES](state, categoriesArray) {
    state.marketplaceFilterCategories.locations = categoriesArray.data.locations;
    state.marketplaceFilterCategories.categories = categoriesArray.data.categories;
    state.marketplaceFilterCategories.durations = categoriesArray.data.durations;
    state.marketplaceFilterCategories.conditions = categoriesArray.data.conditions;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_SELECTED_CATEGORIES](state, filteredCategories) {
    state.marketplaceSelectedCategories.category = filteredCategories.category;
    state.marketplaceSelectedCategories.location = filteredCategories.location;
    state.marketplaceSelectedCategories.duration = filteredCategories.duration;
    state.marketplaceSelectedCategories.condition = filteredCategories.condition;
    state.marketplaceSelectedCategories.searchString = filteredCategories.search
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_ARE_FILTERS_ACTIVE](state, filtersState) {
    state.marketplaceAreFiltersActive = filtersState;
  },

  [MUTATIONS_CONSTANTS.SET_MARKETPLACE_ACTIVE_CATEGORY](state, activeCategory) {
    state.marketplaceActiveCategory = activeCategory;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_QUERY](state, searchQuery) {
    state.searchQuery = searchQuery;
  },

  [MUTATIONS_CONSTANTS.SET_DETAIL_DATA](state, detailData) {
    state.detailData = detailData;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_STATUS](state, status) {
    state.isSearchActive = status;
  },

  [MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS](state, status) {
    state.isSearchHistoryActive = status;
  },
}

const actions = {
  [ACTIONS_CONSTANTS.FETCH_MARKETPLACE_FILTER_DATA]({ commit }) {
    dataLoader.getMarketplaceFilters()
      .then(response => {
        commit(MUTATIONS_CONSTANTS.SET_MARKETPLACE_FILTER_CATEGORIES, response);
      })
  },

  [ACTIONS_CONSTANTS.RESET_ALL_MARKETPLACE_FILTERS]({ commit }) {
    const resettedFilterObject = {
      location: '',
      category: '',
      duration: '',
      condition: '',
      search: ''
    }

    commit(MUTATIONS_CONSTANTS.SET_MARKETPLACE_SELECTED_CATEGORIES, resettedFilterObject);
    commit(MUTATIONS_CONSTANTS.SET_MARKETPLACE_ACTIVE_CATEGORY, 0);
    commit(MUTATIONS_CONSTANTS.SET_MARKETPLACE_ARE_FILTERS_ACTIVE, false);
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
