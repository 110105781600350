<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="$t('workouts.caption')"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1018-workout-list />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <prj1018-workout-list-menu />
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

import Prj1002Navbar from '../../components/prj1002-navbar/prj1002-navbar';
import Prj1018WorkoutList from '../../components/prj1018-workout-list/prj1018-workout-list';
import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import Prj1018WorkoutListMenu from '@/components/prj1018-workout-list/prj1018-workout-list-menu';

export default {
  name: 'Workouts',
  components: {
    Prj1002Navbar,
    Prj1018WorkoutList,
    Prj1018WorkoutListMenu,
    PageLayout
  },

  mixins: [
    mxNavigationDefault,
  ],

  data() {
    return {
      hasError: false,
      registredSuccessfully: true,
    }
  },

  computed: {
    ...mapState('workouts', [
      'workouts',
      'workoutsHasError',
    ])
  },

  watch: {
    workoutsHasError(newVal) {
      this.hasError = newVal;
    }
  },

  created() {
    if (!this.workouts.length) {
      this.fetchWorkouts();
    }
  },

  methods: {
    fetchWorkouts() {
      this.$store.dispatch(STORE_MODULES.WORKOUTS + '/' + ACTIONS_CONSTANTS.FETCH_WORKOUTS_QUARTERS);
    },
  },
}
</script>
