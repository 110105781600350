<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        :infoButton="true"
        :infoButtonDestination="'surf-studio-events-info'"
        :infoButtonDestinationParams="infoButtonDestinationParams"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1013-event-list
        v-if="eventsCategoryId"
        :showFilter="false"
        @handleLoader="handleLoader"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active.sync="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import {
  ERROR_MODAL_TIMEOUT, SURF_STUDIO_DASHBOARD_ID,
} from '@/constants/app-constants';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1013EventList from '@/components/prj1013-event-list/prj1013-event-list';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

export default {
  name: 'SurfStudioEvents',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1013EventList
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT,
      infoPageId: null,
      isEventListLoading: true
    };
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),

    eventsCategoryId() {
      let categoryId = 0;
      if (this.applicationConfigLoaded) {
        let tmpCustomFields = this.applicationConfig.customFields;
        tmpCustomFields.forEach(item => {
          if(item.key === 'SURFSTUDIO_EVENTS_CAT_ID') {
            categoryId = parseInt(item.value);
          }
        });
      }
      return categoryId;
    },

    navbarCaption() {
      return this.$route.meta.navbarCaption;
    },

    infoButtonDestinationParams() {
      return {id: this.infoPageId}
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },

    eventsCategoryId() {
      this.setActiveCategory(this.eventsCategoryId)
    },
  },

  mounted() {
    if (this.eventsCategoryId) this.setActiveCategory(this.eventsCategoryId)
    this.getSurfStudioEventsInfo().then(() => {
      this.initialized = true;
    });
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getSurfStudioEventsInfo() {
      this.setLoadingState(true);

      return dataLoader.fetchApiDataList(SURF_STUDIO_DASHBOARD_ID)
        .then(data => {
          this.infoPageId = data.surfStudioEventsInfoPageId;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    handleLoader(loaderState) {
      this.isLoading = loaderState;
    },

    setActiveCategory(categoryId) {
      this.$store.commit(STORE_MODULES.EVENTS + '/' + MUTATIONS_CONSTANTS.SET_FILTERED_CATEGORIES, [categoryId.toString()])
    }
  }
}
</script>
