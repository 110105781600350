<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:content>
          <div class="prj-layout-default h-full">
            <!--========== HEADER =================================-->
            <!--===================================================-->
            <header class="">
              <t-tag
                variant="sectionTitle"
                class="xs:mt-6"
                tag-name="h1"
              >
                {{ $t('fest.stories.winners.headline') }}
              </t-tag>

              <p
                class="text-sm xs:text-base"
              >
                {{ $t('fest.stories.winners.description') }}
              </p>
            </header>

            <!--========== CARDS ==================================-->
            <!--===================================================-->
            <div class="relative flex-1">
              <div class="absolute top-0 right-0 bottom-0 left-0">
                <prj1006-cards-wrapper
                  v-if="!!stories.length"
                  class="h-full"
                  :itemsLength="stories.length"
                  :hasNavigation="!isSingleStory"
                >
                  <template>
                    <prj1008-story-card
                      v-for="story in stories"
                      :key="`winner-story-${story.id}`"
                      class="h-11/12 xs:h-4/5 my-auto"
                      :data-id="`${story.id}`"
                      :story="story"
                      :single="isSingleStory"
                      isWinner
                      @showLogin="onShowLogin"
                    />
                  </template>
                </prj1006-cards-wrapper>
              </div>
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex'
import apiCmsStories from '@/fest-api/cms-stories'

import { FEST_THEMES } from '@/store/store-modules'
import { GET_THEMES_INFO } from '@/store/constants/fest/actions'

import '@/utils/dayjs'
import { ROUTE_NEW_STORY } from '@/constants/fest/route-names'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import PageLayout from '@/templates/fest/page-layout'
import Prj1006CardsWrapper from '@/components/fest/prj1006-cards-wrapper/prj1006-cards-wrapper'
import Prj1008StoryCard from '@/components/fest/prj1008-story-card/prj1008-story-card'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTWinnersStories',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout,
    Prj1006CardsWrapper,
    Prj1008StoryCard,
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_NEW_STORY,
      loading: false,
      stories: [],
      loginOverlay: false,
      winnersIds: [49, 43, 44], // mock data
    }
  },
  computed: {
    ...mapState('festStories', {
      currentUserLikedStories: (state) => state.currentUserLikedStories,
    }),
    ...mapState('festThemes', ['themesInfo']),
    isSingleStory() {
      return this.stories.length === 1
    },
  },
  created() {
    this.getStories()
    if (!this.themesInfo.length) this.$store.dispatch(`${FEST_THEMES}/${GET_THEMES_INFO}`)
  },
  methods: {
    async getStories() {
      this.loading = true
      await Promise.all(this.winnersIds.map(async (id) => await this.getWinnerStory(id)))
      this.loading = false
    },
    async getWinnerStory(id) {
      const { data, status } = await apiCmsStories.getStoriesByParam({
        perPage: 1,
        orderBy: 'likes',
        storyFilters: [{ filter: 'userId', id }],
      })
      if (status === 200) this.stories = [...this.stories, ...data]
    },

    onShowLogin() {
      this.loginOverlay = true
    },

    closeLoginOverlay() {
      this.loginOverlay = false
    },
  },
}
</script>
