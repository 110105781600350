import axiosConfig from '@/utils/data-loader/axiosConfig'
import * as envConfig from 'env-config'
import utilsGeneral from '@/utils/fest/utils-general'
import logger from '@/utils/logger'
import { ORDER_NEW } from '@/constants/fest/string-values'

export default {
  /**
   * Get Topics by parameter
   * @description parameters provide means for sifting through data; sorting and/or filtering
   *              BE supports filtering by multiple filters
   *
   * @param {Number} page
   * @param {Number} perPage
   * @param {String} orderBy
   * @param {String} order
   * @param {Array<{filter: String, id: Number}>} storyFilters
   * @param {Number} archive - 1 stands for unpublished stories
   * @returns {Promise<{data: Array, status: number}>}
   */
  async getTopicsByParam({
    page = 1,
    perPage = 20,
    orderBy = ORDER_NEW,
    order = 'desc',
    topicFilters = [],
    archive = null,
  }) {
    const processId = utilsGeneral.startAsyncProcess()

    const filters = topicFilters.reduce((acc, { filter, id }) => {
      return { ...acc, [`filter[${filter}]`]: id }
    }, {})

    try {
      const res = await axiosConfig.get(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_TOPICS, {
        params: {
          page: page,
          per_page: perPage,
          orderby: orderBy,
          order: order,
          ...filters,
          archive,
        },
      },
      )

      const { data, status } = res
      if (status === 200) return { data, status }
    } catch (error) {
      // TODO JBa Provide proper error handling
      logger.error(error.response)
    } finally {
      utilsGeneral.stopAsyncProcess(processId)
    }
  },
  async getTopicById(id) {
    const processId = utilsGeneral.startAsyncProcess()

    try {
      const res = await axiosConfig.get(`${envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_TOPICS}/${id}`)

      const { data, status } = res
      if (status === 200) return { data, status }
    } catch (error) {
      logger.error(error.response)
      return error.response
    } finally {
      utilsGeneral.stopAsyncProcess(processId)
    }
  },
}
