import Gen1003Hyperlink from './gen1003-hyperlink';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1003Hyperlink);
    }
};

use(Plugin);

export default Plugin;

export { Gen1003Hyperlink };
