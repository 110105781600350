<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-info-panel" :class="classObject">
        <div class="vue-b-content">
            <slot></slot>
        </div>

        <frm1006-button
            v-if="buttonClose"
            type="internal"
            class="vue-ci-button-close"
            :title="buttonCloseCaption"
            @buttonClickEvent="infoPanelButtonCloseClickEmit"
        >
            {{ buttonCloseCaption }}
        </frm1006-button>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//=== MISC
import config from '../../../config';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1006InfoPanel',
    components: {
        Frm1006Button
    },
    props: {
        type: {
            default: 'info',
            type: String,
            validator: value => {
                return config.infoStates.includes(value);
            }
        },
        id: {
            default: null,
            type: String
        },
        buttonClose: {
            default: false,
            type: Boolean
        },
        buttonCloseCaption: {
            default: 'Zavřít',
            type: String
        }
    },
    computed: {
        classObject: function() {
            return {
                [`vue-is-${this.type}`]: this.type,
                'vue-has-close': this.buttonClose
            };
        }
    },
    methods: {
        infoPanelButtonCloseClickEmit() {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('infoPanelButtonCloseClickEvent', this.id);
            this.$emit('infoPanelCloseEvent', this.id);
        }
    }
};
</script>
