export const DATA_ARRAY = Object.freeze({
  mainObjectives: [
    {
      'cs': {
        'items': [
          {
            'id': 1,
            'visualisation': 'fill-mobile',
            'title': 'Digitální svět',
            'body': 'K 2. 11. máme 694 tis. klientů mobilního bankovnictví, do konce roku plánujeme 700 tis. Plníme náš plán.'
          },
          {
            'id': 2,
            'visualisation': 'nps-people',
            'title': 'Skupinoví klienti',
            'body': 'K 1. 11. máme 1 144 000 skupinových klientů a plníme plán 2021 na 97 %.'
          },
          {
            'id': 3,
            'visualisation': 'nps-tasks',
            'title': 'Kate',
            'body': 'K 1. 11. si naše virtuální asistentka Kate umí poradit s 69 situacemi v retailovém segmentu a do konce roku jich plánujeme 75.'
          },
          {
            'id': 4,
            'visualisation': 'climatic-change',
            'title': 'Klimatická změna',
            'body': 'Snižujeme uhlíkovou stopu – v říjnu jsme sbírali “zelené” kilometry při našem dojíždění do práce.'
          }
        ]
      },
      'en': {
        'items': [
          {
            'id': 1,
            'visualisation': 'fill-mobile',
            'title': 'Digital world',
            'body': 'As of 1 November, we have 684 000 mobile banking clients, and we plan to have 700 000 by the end of the year. We meet the planned targets.'
          },
          {
            'id': 2,
            'visualisation': 'nps-people',
            'title': 'Group clients',
            'body': 'As of 1 November, we have 1 144 0000 group clients and we are at 97 % of our 2021 plan.'
          },
          {
            'id': 3,
            'visualisation': 'nps-tasks',
            'title': 'Kate',
            'body': 'As of 1 November, our virtual assistant Kate can handle 69 situations in the retail segment and we plan to have 75 situations by the end of the year.'
          },
          {
            'id': 4,
            'visualisation': 'climatic-change',
            'title': 'Climatic change',
            'body': 'We are reducing our carbon footprint – in October we collected green kilometres on our commutes to work.'
          }
        ]
      }
    }
  ]
});
