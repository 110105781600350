<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== HEADER =================================-->
    <!--===================================================-->
    <template v-slot:header>
      <prj1004-article-head
        v-if="adventPost"
        :articleData="adventPost"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <prj1004-article-body
          v-if="adventPost"
          :articleData="adventPost"
        />

        <gen1006-info-panel
          v-else
          type="info"
        >
          {{ $t('advent.postNotPublish') }}
        </gen1006-info-panel>

        <gen1006-info-panel
          v-if="!isLoading && !initialized"
          type="neutral"
        >
          {{ $t('general.loadingFailed') }}
        </gen1006-info-panel>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import Vue from 'vue';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import router from '@/router';
import utilsGeneral from '@/utils/utils-general';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1004ArticleHead from '@/components/prj1004-article-detail/prj1004-article-head';
import Prj1004ArticleBody from '@/components/prj1004-article-detail/prj1004-article-body';

export default {
  name: 'AdventDetail',
  components: {
    Prj1002Navbar,
    PageLayout,
    Prj1004ArticleHead,
    Prj1004ArticleBody
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      adventPost: {}
    }
  },

  computed: {
    routeId() {
      return this.$route.params.id
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  created() {},

  mounted() {
    this.initialized = true;
    this.fetchPageDetail();
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    fetchPageDetail() {
      this.setLoadingState(true);

      dataLoader.fetchPostById(this.routeId)
        .then(data => {
          this.adventPost = data[0];
        })
        .catch(error => {
          logger.error(error);
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.setLoadingState(false);
        })
    },

    convertDate(datetimeString) {
      let date = new Date(utilsGeneral.normalizeDateString(datetimeString));
      // FIXME MWr: Workaround for bad convert day name - CSOBZ-563
      let dateDay = (date.getDay() === 0 ? 6 : date.getDay() - 1);
      let weekDayName = this.$i18n.t('time.days.' + dateDay);
      let weekDayNumber = Vue.filter('convertDate')(datetimeString, 'day');
      let monthNumber = Vue.filter('convertDate')(datetimeString, 'month');
      let monthName = this.$i18n.t('time.months.' + monthNumber);
      let lessonMonth = this.$i18n.t('inflection.months.2.' + monthName);
      let lessonTime = Vue.filter('convertDate')(datetimeString, 'hour') + ':' + Vue.filter('convertDate')(datetimeString, 'minutes');

      return `${weekDayName} ${weekDayNumber}. ${lessonMonth}, ${lessonTime}`;
    },

    goToView(article) {
      if(article.status === 'publish') {
        router.push({ name: 'advent-detail', params: { id: article.id }});
      }
    },

    controlClassObject(status) {
      return {
        'vue-is-disabled': status === 'future'
      }
    },
  }
}
</script>
