<template>
  <page-layout
    ref="pageLayout"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-plan-detail">
        <h2 class="vue-plan-title">{{ $t('emergency.buildingPlanDetailTitle', {building: buildingName}) }}</h2>

        <img
          v-if="imageUrl"
          class="vue-plan-image"
          :src="imageUrl"
          alt=""
        />
      </div>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import evacuationPlans from '@/assets/mocks/emergency/evacuationPlans';

export default {
  name: 'EmergencyBuildingPlanDetail',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      navbarCaptionData: null,
      emergencyBuildingPlans: evacuationPlans,
      buildingId: null
    };
  },

  computed: {
    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },

    findBuilding() {
      if (this.buildingId) {
        const buildingPlan = this.emergencyBuildingPlans.plans.find(plan => plan.id === this.buildingId);
        return buildingPlan;
      }

      return null;
    },

    imageUrl() {
      return this.findBuilding?.fileUrl;
    },

    buildingName() {
      return this.findBuilding?.name;
    }
  },

  watch: {},

  mounted() {
    this.buildingId = parseFloat(this.$route.params.id);
  },

  methods: {},
}
</script>

