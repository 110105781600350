export const LIST_ITEMS_ROUTES = [
  {
    module: 'news',
    routeModule: 'news-list',
    routeDetail: 'news'
  },
  {
    module: 'event',
    routeModule: 'events',
    routeDetail: 'event-detail'
  },
  {
    module: 'forvardino',
    routeModule: 'forvardino-list',
    routeDetail: 'forvardino-detail'
  },
  {
    module: 'benefit',
    routeModule: 'benefits',
    routeDetail: 'benefits-detail'
  },
  {
    module: 'marketplace',
    routeModule: 'marketplace-list',
    routeDetail: 'marketplace-detail'
  }
]
