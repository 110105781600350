<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <td class="vue-calendar-day" :class="classObject" @click="calendarDayClick(date, $event)">
        <span>
            <slot></slot>
        </span>
    </td>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1010Day',
    props: {
        date: String,
        dateSelected: String,
        otherMonth: Boolean,
        weekend: Boolean,
        today: String
    },
    computed: {
        classObject() {
            return {
                'vue-is-selected': this.date === this.dateSelected,
                'vue-is-today': this.date === this.today,
                'vue-is-other-month': this.otherMonth,
                'vue-is-weekend': this.weekend
            };
        }
    },
    methods: {
        calendarDayClick(date, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('calendarDayClickEvent', date, event);
        }
    }
};
</script>
