//============ IMPORT ==================================//
//======================================================//
import * as rules from './rules';

//============ OPTIONS =================================//
//======================================================//
const RULES = {};

//============ EXPORT ==================================//
//======================================================//
// TODO MBU: rename
export default class EvalRules {
    static add(name, { evaluate, paramNames }) {
        RULES[name] = {
            evaluate,
            paramNames
        };
    }
    static get rules() {
        return RULES;
    }
    static getParamNames(ruleName) {
        return RULES[ruleName] && RULES[ruleName].paramNames;
    }
    static getEvalFunction(ruleName) {
        return RULES[ruleName] ? RULES[ruleName].evaluate : null;
    }
}

// TODO MBU: add rules somewhere else using some config / cycle
EvalRules.add('between', {
    evaluate: rules.between.evaluate,
    paramNames: rules.between.paramNames
});

EvalRules.add('search', {
    evaluate: rules.search.evaluate,
    paramNames: rules.search.paramNames
});
