<template>
  <page-layout
    :class="classObject"
  >
    <template
      v-slot:navbar
    >
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <template v-slot:contentMain>
      <prj1017-heading
        :header="iframeViewerConfigs.headline"
      />

      <p>{{ $t('iframeViewer.headlineCaption') }}</p>

      <div
        v-if="loadingIframeContent || iframeVisible || isDirect"
        class="vue-b-iframe"
        :class="iframeClassObject"
      >
        <iframe
          v-show="iframeVisible"
          :src="iframeLink"
          @load="iframeContentLoaded"
        />
      </div>

      <frm1031-button-container
        v-if="!iframeVisible"
      >
        <frm1006Button
          type="primary"
          class="vue-open-report"
          :disabled="iframeVisible"
          @buttonClickEvent="openReport"
        >
          {{ $t('iframeViewer.showMe') }}
        </frm1006Button>
      </frm1031-button-container>

      <gen1006-info-panel
        type="info"
      >
        {{ $t('iframeViewer.note') }}
      </gen1006-info-panel>
    </template>

    <template v-slot:fixed>
      <gen1016-loading-indicator
        v-if="iframeLoader"
        :active="isLoading || loadingIframeContent"
        :overlay="true"
        :fullScreen="true"
      />
    </template>

    <template
      v-slot:buttonFixed
    >
      <frm1006Button
        v-if="iframeVisible"
        class="vue-close-button"
        type="primary"
        @buttonClickEvent="buttonBackEvent"
      >
        {{ $t('forms.close') }}
      </frm1006Button>
    </template>
  </page-layout>
</template>

<script type="application/javascript">

import { mapState } from 'vuex';
import utilsGeneral from '@/utils/utils-general';

import pageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

export default {
  name: 'IframeViewer',
  components: {
    pageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      iframeVisible: false,
      loadingIframeContent: false,
      netConnection: false,
      isLoading: true,
      pageId: (this.$route.query.pageId || '1')
    }
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),
    isDirect() {
      return this.$route.query.mode === 'direct';
    },

    isAltUrl() {
      if(typeof this.$route.params.alt === 'undefined') {
        return false;
      }
      return this.$route.params.alt === 'alt';
    },

    hasBottomIndentButton() {
      if(typeof this.$route.params.bottomIndentButton === 'undefined') {
        return true;
      }
      return this.$route.params.bottomIndentButton;
    },

    classObject() {
      return {
        'vue-no-scroll': this.iframeVisible,
        'vue-has-no-bottom-indent-button': !this.hasBottomIndentButton
      }
    },

    iframeClassObject() {
      return {
        'vue-is-loaded': this.iframeVisible
      }
    },

    iframeViewerConfigs() {
      const iframeViewerConfigsConst = this.$route.meta.iframePrefix;
      let iframeViewerConfigObject = {};

      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key.includes(iframeViewerConfigsConst)) {
            const itemNameWithoutPrefix = item.key.replace(`${iframeViewerConfigsConst}_`, '');
            const itemKey = utilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            iframeViewerConfigObject[itemKey] = item.value;
          }
        });
      }

      return iframeViewerConfigObject;
    },

    iframeLink() {
      const altUrl = this.isAltUrl ? `${this.iframeViewerConfigs.url2}` : `${this.iframeViewerConfigs.url}`;

      if (!this.$route.meta.iframeDefaultPageId.length) {
        return `${altUrl}`;
      }

      return this.isAltUrl ? `${altUrl}` : `${altUrl}&start_page=${this.$route.meta.iframeDefaultPageId}`;
    },

    iframeLoader() {
      if(typeof this.$route.meta.iframeLoader === 'undefined') {
        return true;
      }
      return this.$route.meta.iframeLoader;
    }
  },

  watch: {
    applicationConfigLoaded() {
      if (this.applicationConfigLoaded) {
        this.isLoading = false;
      }
    }
  },

  mounted() {
    if (this.applicationConfigLoaded) {
      this.isLoading = false;
    }

    this.netConnection = window.navigator.onLine;
    this.loadParams();
  },

  methods: {
    loadParams(){
      if (typeof this.$route.params.nav === 'undefined'){
        this.$route.params.nav = 'closed';
      }

      if (this.$route.params.nav === 'opened'){
        this.loadingIframeContent = true;
        this.iframeVisible = true;
      }

      else if(this.$route.params.nav === 'closed'){
        this.loadingIframeContent = false;
        this.iframeVisible = false;
      }
    },

    buttonBackEvent() {
      this.iframeVisible = false;
      this.loadingIframeContent = false;

      if (this.$route.meta.iframeBackToPage) {
        this.$router.replace({ name: this.$route.meta.navbarButtonDestination });
      } else {
        this.$router.replace({ name: this.$route.name });
      }
    },

    openReport() {
      this.loadingIframeContent = true;

      this.$router.replace({ name: this.$route.name, params: {nav:'opened'}});
    },

    iframeContentLoaded() {
      this.loadingIframeContent = false;
      this.iframeVisible = true;
    }
  }
}
</script>
