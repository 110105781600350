import Hlp1001TableSimple from './hlp1001-table-simple';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Hlp1001TableSimple);
    }
};

use(Plugin);

export default Plugin;

export { Hlp1001TableSimple };
