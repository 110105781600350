<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :button-back-type="'none'"
        :building="false"
        @activeView="buttonBackEvent"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        id="vue-m-strategy-competition-registration"
        ref="strategyContainer"
        class="vue-strategy-page-layout"
      >
        <div
          v-if="!isDataSent"
          class="vue-page-content vue-competition-registration vue-strategy-container"
        >
          <prj1017-heading
            :header="$t('strategy.competitionHeader')"
          />
          <frm1035-form-group>
            <frm1001-input-field
              id="registration-email"
              v-model="userEmail"
              :tooltip="false"
              :label="$i18n.t('strategy.email')"
            />
            <p class="vue-competition-registration-consent-description">
              {{ $i18n.t('strategy.competitionDataConsent') }}
            </p>
            <frm1003-checkbox
              v-model="emailConsent"
              :valueNative="true"
              :tooltip="false"
            >
              <a
                :href="urlPDF"
                target="_blank"
              >{{ $i18n.t('strategy.competitionConfirm') }}</a>
            </frm1003-checkbox>
          </frm1035-form-group>
          <frm1006-button
            class="vue-button-register vue-is-next"
            :disabled="!isRegisterButtonUnlocked"
            @buttonClickEvent="submitForm"
          >
            {{ $t('strategy.next') }}
          </frm1006-button>
        </div>

        <!--Confirmation-->
        <div
          v-if="isDataSent"
          class="vue-page-content vue-competition-registration-finish"
        >
          <prj1017-heading
            :header="$t('strategy.competitionFinishHeader')"
            :subheader="$t('strategy.competitionFinishSubheader')"
          />
          <div class="vue-competition-registration-illustration" />
          <frm1031-button-container
            class="vue-summary-buttons"
          >
            <slot>
              <frm1006-button
                type="primary"
                @buttonClickEvent="buttonBackEvent"
              >
                <span class="vue-text">{{ $t('strategy.back') }}</span>
              </frm1006-button>
            </slot>
            <slot>
              <frm1006-button
                type="secondary"
                @buttonClickEvent="goToDashboard"
              >
                <span class="vue-text">{{ $t('strategy.backToDashboard') }}</span>
              </frm1006-button>
            </slot>
          </frm1031-button-container>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>

import router from '@/router';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import utilsGeneral from '@/utils/utils-general';
import dataLoader from '@/utils/data-loader';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import { mapState } from 'vuex';
import * as STRATEGY_COMPETITION from '@/assets/mocks/strategyCompetitionData';
import { STRATEGY_DEFAULT_LANG, STRATEGY_COMPETITION_ACTIVE } from '@/constants/app-constants';
import logger from '@/utils/logger';
import PageLayout from '@/templates/partials/page-layout';

export default {
  name: 'StrategyCompetitionRegistration',
  components: {
    Prj1002Navbar,
    Prj1017Heading,
    PageLayout
  },
  data() {
    return {
      competitionData: STRATEGY_COMPETITION.DATA_ARRAY.competition,
      userEmail: '',
      emailConsent: false,
      isDataSent: false,
      urlPDF: 'https://zapkacms.csob.cz/wp-content/uploads/2021/06/soutez_strategie_pravidla_3.pdf'
    }
  },
  computed: {
    ...mapState('competition', [
      'competitions'
    ]),
    ...mapState('user', [
      'deviceId'
    ]),

    quizData() {
      let lang = this.getSelectedLang;
      if( Object.prototype.hasOwnProperty.call(this.competitionData, lang) ) {
        return this.competitionData[lang]['items'];
      }
      return this.competitionData[STRATEGY_DEFAULT_LANG]['items'];
    },

    getSelectedLang() {
      return this.$i18n.locale;
    },

    isEmailValid() {
      return utilsGeneral.isValidEmail(this.userEmail) && utilsGeneral.isEmailFromGroup(this.userEmail);
    },

    isRegisterButtonUnlocked() {
      return this.isEmailValid && this.emailConsent
    }
  },
  methods: {
    buttonBackEvent() {
      router.push({ name: 'strategy-competition-summary'});
    },

    submitForm() {
      this.initiateSendResultProcess()
    },

    goToDashboard() {
      router.push({ name: 'strategy' });
    },

    initiateSendResultProcess() {
      let answers = ''
      let result = this.competitions.data[STRATEGY_COMPETITION_ACTIVE].result;
      if(Object.keys(this.quizData).length === Object.keys(this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results).length) {
        for (let key of Object.keys(this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results)) {
          key = parseInt(key);
          answers += this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results[key] + ',';
        }
      }
      let competitionValues = {
        answers: answers,
        result: result ? 1 : 0,
        email: this.userEmail,
        deviceId: this.deviceId,
        lang: this.getSelectedLang
      };

      this.sendResult(competitionValues);
    },
    prepareSendResult() {
      this.loadingSend = true;
      this.initiateSendResultProcess();
    },

    sendResult(competitionValues) {
      dataLoader
        .sendCompetitionApplicationResult(competitionValues)
        .then(() => {
          this.$store.commit(STORE_MODULES.COMPETITION + '/' + MUTATIONS_CONSTANTS.SET_COMPETITION_SENT_RESULT, 2);
          this.$store.commit(STORE_MODULES.COMPETITION + '/' + MUTATIONS_CONSTANTS.SET_COMPETITION_SENT_TIMESTAMP);
          this.loadingSend = false;
          this.isDataSent = true
        }, (error) => {
          if(error.response.status === 422) {
            logger.error('Competition - nebyl odeslán validní obsah parametrů.', error);
          } else if(error.response.status === 409) {
            logger.error('Competition - tento návštěvník má již vytvořený záznam.', error);
          } else if(error.response.status === 401) {
            logger.error('Competition - neautorizovaný požadavek.', error);
          } else {
            logger.error('Competition - nebyl odeslán validní obsah parametrů.', error);
          }
          this.$store.commit(STORE_MODULES.COMPETITION + '/' + MUTATIONS_CONSTANTS.SET_COMPETITION_SENT_RESULT, 1);
          this.$store.commit(STORE_MODULES.COMPETITION + '/' + MUTATIONS_CONSTANTS.SET_COMPETITION_SENT_TIMESTAMP);
          this.loadingSend = false;
        });
    },

    getAnswerTruth(questionId, answerId) {
      return this.quizData[questionId].answers[answerId].truth;
    },
  },
}
</script>
