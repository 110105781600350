//============ IMPORT ==================================//
//======================================================//
import { utilsGeneral } from '../utils-general';
import EvalRules from './rules-container';

//============ EXPORT ==================================//
//======================================================//
let utilsEval = {
    evalDefinedFunction(value, rule) {
        let ruleParsed = utilsEval.parseRule(rule);

        let functionName = ruleParsed.name;
        let functionParams = ruleParsed.params;

        let evalFunction = EvalRules.getEvalFunction(functionName);

        let evalFunctionParamNames = EvalRules.getParamNames(functionName);

        // fill in params for validation function
        let evalFunctionParams = {};

        for (let i = 0; i < evalFunctionParamNames.length; i += 1) {
            let paramName = evalFunctionParamNames[i];
            let paramValue = functionParams[i];
            evalFunctionParams[paramName] = paramValue;
        }

        return evalFunction(value, evalFunctionParams);
    },
    parseRule(rule) {
        let params = [];
        const name = rule.split(':')[0];

        if (utilsGeneral.includes(rule, ':')) {
            params = rule
                .split(':')
                .slice(1)
                .join(':')
                .split(',');
        }

        return { name, params };
    }
};

export default utilsEval;
