<template>
  <div
    class="vue-workout-list-menu"
  >
    <div class="vue-b-buildings">
      <template
        v-for="building in availableBuildings"
      >
        <frm1006-button
          :key="building"
          class="vue-menu-control-button"
          :class="buildingActiveButton(building)"
          type="secondary"
          @buttonClickEvent="selectBuilding(building)"
        >
          {{ building }}
        </frm1006-button>
      </template>
    </div>

    <div class="vue-b-quartals">
      <frm1033-dropdown-button
        id="selected-building"
        class="vue-menu-control-button vue-menu-control-quartal-selector"
        type="app-button"
        :caption="selectedQuartal"
        :options="availableQuartals"
        :optionPreselectedValue="selectedQuartal"
        :tooltip="false"
        contextContainerCustomCss="vue-is-simple vue-is-position-bottom"
        @contextContainerMenuItemSelectEvent="selectQuartal"
      />
    </div>
  </div>
</template>

<script>
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as STORE_MODULES from '@/store/store-modules';
import {mapState} from 'vuex';

export default {
  name: 'Prj1018WorkoutListMenu',

  data() {
    return {
      availableBuildings: ['NHQ','SHQ','HHQ'],
      availableQuartals: [
        {
          caption: '1Q',
          value: '1Q'
        },
        {
          caption: '2Q',
          value: '2Q'
        },
        {
          caption: '3Q',
          value: '3Q'
        },
        {
          caption: '4Q',
          value: '4Q'
        }
      ],
    }
  },

  computed: {
    ...mapState('workouts', [
      'selectedBuilding',
      'selectedQuartal'
    ]),

    ...mapState('dashboard', {
      storedSelectedBuilding: state => state.selectedBuilding
    }),

    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),
  },

  watch: {
    applicationConfigLoaded(value) {
      if (value) {
        this.preferredBuildingChoice();
      }
    }
  },

  created() {
    this.preferredBuildingChoice();
  },

  methods: {
    buildingActiveButton(building) {
      return {
        'vue-is-active': this.selectedBuilding === building
      }
    },

    selectBuilding(building) {
      this.$store.commit(STORE_MODULES.WORKOUTS + '/' + MUTATIONS_CONSTANTS.SET_WORKOUT_BUILDING, building);
    },

    selectQuartal(quartal) {
      this.$store.commit(STORE_MODULES.WORKOUTS + '/' + MUTATIONS_CONSTANTS.SET_WORKOUT_QUARTAL, quartal.value);
    },

    preferredBuildingChoice() {
      if (!this.applicationConfigLoaded) {
        return false;
      }

      if (typeof this.applicationConfig.localities !== 'undefined') {
        this.applicationConfig.localities.forEach(e => {

          if (e.id === this.storedSelectedBuilding && e.name === 'HHQ') {
            this.selectBuilding('HHQ')
          }
        });
      }
    },
  }
}
</script>
