<template>
  <div class="vue-popup-services">
    <frm1035-form-group>
      <frm1028-advanced-combo
        v-model="locationId"
        :options="locationOptions"
        :placeholder="$i18n.t('services.location')"
        :tooltip="false"
        :label="$i18n.t('services.location')"
      />
    </frm1035-form-group>
    <div
      class="vue-bottom-content"
    >
      <frm1006-button
        :disabled="untouchedFilter"
        @buttonClickEvent="setFilterOptions"
      >
        {{ $t('advancedFilter.filterList') }}
      </frm1006-button>
    </div>
    <gen1006-info-panel
      v-if="hasError"
      type="error"
    >
      {{ $t('error.generic') }}
    </gen1006-info-panel>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import {SERVICES_CATEGORIES} from '@/constants/app-constants';

export default {
  name: 'Prj1011PopupServicesFilter',
  data() {
    return {
      hasError: false,
      locationId: '',
      locationWasChanged: false,
    }
  },

  computed: {
    ...mapState('services', [
      'servicesSelectedCategoryId',
      'servicesSearchTerm',
    ]),

    untouchedFilter() {
      return !this.locationWasChanged
    },

    locationOptions() {
      return SERVICES_CATEGORIES.map((item, index) => {
        return {
          value: index.toString(),
          caption: this.$t('services.categoryName')[item]
        }
      })
    }
  },

  watch: {
    locationId(newValue, oldValue) {
      if (oldValue !== '') this.locationWasChanged = true
    }
  },

  mounted() {
    this.locationId = this.servicesSelectedCategoryId.toString();
  },

  methods: {
    setFilterOptions() {
      this.$store.commit(STORE_MODULES.SERVICES + '/' + MUTATIONS_CONSTANTS.SET_SERVICES_SELECTED_CATEGORY, parseInt(this.locationId));
      this.$store.commit(STORE_MODULES.POPUP + '/' + MUTATIONS_CONSTANTS.TOGGLE_POPUP, false);
    },
  }
}
</script>
