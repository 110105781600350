<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar
            route-name="challenges"
          />
        </template>

        <template v-slot:content>
          <div class="flex flex-col h-full justify-between">
            <!--========== PAGE BODY ==============================-->
            <!--===================================================-->
            <div
              v-if="challengeDetail"
              class="prj-article prj-promo-page"
            >
              <t-tag
                variant="sectionTitle"
                class="xs:mb-6 md:mb-8 xs:mt-6 md:mb-10"
              >
                {{ challengeDetail.title }}
                <t-tag
                  v-if="challengeDetail.subtitle"
                  variant="highlight"
                >
                  {{ challengeDetail.subtitle }}
                </t-tag>
              </t-tag>

              <p>{{ challengeDetail.description }}</p>
            </div>

            <div class="flex flex-col justify-center flex-1">
              <!--========== SAVING PANEL ===========================-->
              <!--===================================================-->
              <prj1012-saving-panel
                v-if="challengeExtendedSettings"
                class="mb-12"
                :value="getSettingsValue"
                :secondary-value="valuesEntireDistance"
                :units="challengeType.units"
                :headline="challengeType.settings.savingPanelDetail.title"
                :description="challengeType.settings.savingPanelDetail.text"
              />

              <!-- Days -->
              <div
                v-if="hasChallengeValues"
                class="relative grid grid-cols-1 justify-center mx-auto w-1/2 rounded-lg overflow-hidden cursor-pointer"
                @click="toggleValuesOverlay"
              >
                <div
                  v-if="!selectedValue"
                  class="relative w-full h-36 border-3 rounded-lg border-gray-200"
                ></div>

                <div
                  v-else
                  class="relative w-full flex flex-col justify-evenly items-center py-4 px-4 rounded-xl cursor-pointer"
                  :class="selectedValue.cssClass"
                >
                  <div
                    class="flex items-center w-4/6 h-4/6 max-h-24 mb-4"
                    v-html="selectedValue.icon"
                  ></div>
                  <span class="text-center text-sm font-semibold">{{ selectedValue.name }}</span>
                </div>
              </div>
            </div>
          </div>

          <!--========== OVERLAYS ===============================-->
          <!--===================================================-->

          <!-- Initial -->
          <prj1009-modal :active="initOverlay && !isUserInvolved">
            <template v-slot:title>
              {{ challengeTitle }}
            </template>

            <template v-slot:content>
              <div
                v-if="challengeRunningPage"
                v-html="challengeRunningPage.post_content"
              ></div>

              <!-- Subpages/Running -->
              <div
                v-if="challengeRunningSubpages"
                class="mt-6"
              >
                <template v-for="({ ID, post_title }) in challengeRunningSubpages">
                  <prj1001-section-button
                    :key="`page-${ID}`"
                    class="w-full bg-emerald-100"
                    route-name="sustainability-page"
                    :route-params="{id: ID}"
                  >
                    <template v-slot:text>{{ post_title }}</template>
                  </prj1001-section-button>
                </template>
              </div>
            </template>

            <template v-slot:controls>
              <t-button @click="challengeExtendedSettings ? showSettings() : showValues()">{{ $t("esg.forms.buttons.buttonNext") }}</t-button>
            </template>
          </prj1009-modal>

          <!-- Settings -->
          <prj1009-modal :active="(settingsOverlay || (isUserInvolved && initOverlay)) && challengeExtendedSettings">
            <template v-slot:title>
              {{ challengeType.settings.title }}
            </template>

            <template v-slot:content>
              <div
                class="mb-10"
                v-html="challengeType.settings.content"
              ></div>

              <div class="flex items-center justify-between mb-8">
                <span class="text-gray-500 mr-2">
                  {{ $t("esg.challenges.forms.hoursOfCleaning") }}
                </span>
                <div class="relative w-2/5">
                  <t-input
                    v-model="settingsValue"
                    class="w-full text-2xl font-bold text-right pr-14 prj-input-hide-arrows"
                    type="number"
                    step="0.1"
                    :maxlength="4"
                  />
                  <div class="absolute right-3 top-0 bottom-0 flex items-center">
                    <span class="text-xl text-gray-400 font-bold">{{ $t("esg.challenges.forms.unitHour") }}</span>
                  </div>
                </div>
              </div>

              <div class="flex items-center justify-between mb-8">
                <span class="text-gray-500 mr-2">
                  {{ $t("esg.challenges.forms.numbersOfPeople") }}
                </span>
                <div class="relative w-2/5">
                  <t-input
                    v-model="settingsSecondValue"
                    class="w-full text-2xl font-bold text-right pr-14 prj-input-hide-arrows"
                    type="number"
                    step="0.1"
                    :maxlength="4"
                  />
                  <div class="absolute right-3 top-0 bottom-0 flex items-center">
                    <span class="text-xl text-gray-400 font-bold">{{ unitPeopleInflection }}</span>
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:controls>
              <t-button @click="hasChallengeValues ? showValues() : toggleSettingsOverlay()">{{ $t("esg.forms.buttons.buttonNext") }}</t-button>
            </template>
          </prj1009-modal>

          <!-- Value select -->
          <prj1009-modal :active="valuesOverlay">
            <template v-slot:content>
              <!-- Tiles -->
              <div
                v-if="challengeType.transportItems"
                class="grid gap-4 grid-cols-2"
              >
                <div
                  v-for="item in challengeType.transportItems"
                  :key="item.id"
                  class="flex flex-col justify-evenly items-center py-4 px-2 rounded-xl cursor-pointer"
                  :class="item.cssClass"
                  @click="selectValue(item)"
                >
                  <div
                    class="flex items-center w-4/6 h-4/6 max-h-24 mb-4"
                    v-html="item.icon"
                  ></div>
                  <span class="text-center text-sm font-semibold">{{ item.name }}</span>
                </div>
              </div>
            </template>
          </prj1009-modal>
        </template>

        <template v-slot:footer>
          <!--========== CONTROLS ===============================-->
          <!--===================================================-->
          <div class="prj-controls">
            <t-button
              v-if="!selectedValue"
              class="w-full"
              @click="toggleSelectingWeek(true)"
            >
              {{ $t("esg.forms.buttons.buttonChoice") }}
            </t-button>

            <t-button
              v-else
              class="w-full"
              @click="sendChallenge()"
            >
              {{ $t("esg.forms.buttons.buttonSend") }}
            </t-button>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex';
import utilsGeneral from '@/utils/esg/utils-general';

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import PageLayout from '@/templates/esg/page-layout';
import Prj1005NavigationBar from '@/components/esg/prj1005-navigation-bar/prj1005-navigation-bar';
import Prj1012SavingPanel from '@/components/esg/prj1012-saving-panel/prj1012-saving-panel';
import Prj1009Modal from '@/components/esg/prj1009-modal/prj1009-modal';
import Prj1001SectionButton from '@/components/esg/prj1001-section-button/prj1001-section-button';
import apiCmsChallenges from '@/esg-api/cms-challenges';
import logger from '@/utils/logger';

import garbageCleanChallengeType from '@/assets/esg/challenges/types/garbageClean';

import { ESG_CHALLENGES } from '@/store/store-modules';
import { SET_CHALLENGE_INVOLVE } from '@/store/constants/esg/mutations';
import { GET_CHALLENGES_INFO } from '@/store/constants/esg/actions';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'ESGChallengeDetail',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1001SectionButton,
    Prj1005NavigationBar,
    Prj1009Modal,
    Prj1012SavingPanel,
    PageLayout,
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      openedSelectingWeek: false,
      loginOverlay: false,
      initOverlay: true,
      settingsOverlay: false,
      valuesOverlay: false,
      challengeDetail: null,
      challengeType: garbageCleanChallengeType, // TODO make type assignment dynamic
      settingsValue: '',
      settingsSecondValue: '',
      selectedValue: null,
      sending: false,
      sendFailed: false
    }
  },
  computed: {
    ...mapState('esgChallenges', ['challengesInfo', 'challengesUserInvolved']),
    challengeId() {
      return parseInt(this.$route.params.challengeId);
    },
    competitionId() {
      return this.challengeDetail?.competitionId;
    },
    competitionPageId() {
      return this.challengeDetail?.competitionPageId;
    },
    challengeExtendedSettings() {
      return this.challengeType.settingsExtended;
    },
    challengeRunningPage() {
      return this.challengeDetail?.pages
    },
    challengeRunningSubpages() {
      return this.challengeRunningPage?.subPages
    },
    challengeTitle() {
      return this.challengeRunningPage?.post_title || this.challengeDetail.title
    },
    getSettingsValue() {
      const firstValue = parseFloat(this.settingsValue);
      const secondValue = this.settingsSecondValue ? parseFloat(this.settingsSecondValue) : 1;

      return firstValue ? firstValue * secondValue : this.challengeType.settingsValueDefault;
    },
    hasChallengeValues() {
      return this.challengeType.transportItems.length;
    },
    highestValueOfItems() {
      if (!this.challengeType.highestValue) {
        let tempHighestValue = 0;
        const items = this.challengeType.transportItems;

        for (let item of items) {
          if (item.value > tempHighestValue) tempHighestValue = parseFloat(item.value);
        }

        return tempHighestValue;
      }

      return this.challengeType.highestValue;
    },
    completedValue() {
      let completedValue = 0;
      if (this.selectedValue === null) return this.highestValueOfItems;

      completedValue = this.highestValueOfItems - (this.highestValueOfItems - this.selectedValue.value);

      return completedValue;
    },
    valuesEntireDistance() {
      if (this.selectedValue) {
        const calculateValue = (this.highestValueOfItems - this.completedValue) * this.getSettingsValue;

        return calculateValue > 0 ? calculateValue : 0;
      }

      return 0;
    },
    dateDay() {
      return new Date
    },
    isUserInvolved() {
      return this.challengesUserInvolved.some(item => item.challenge === this.challengeId)
    },
    unitPeopleInflection() {
      // Temporary function for units show people
      let unitIndex = 0;

      if (this.settingsSecondValue > '1') {
        unitIndex = 1;
      }

      if (this.settingsSecondValue > '4' || this.settingsSecondValue === '0' || this.settingsSecondValue === '') {
        unitIndex = 2;
      }

      return this.$t('esg.challenges.forms.unitPeople[' + unitIndex + ']');
    }
  },
  async created() {
    if (!this.challengesInfo.length) await this.$store.dispatch(`${ESG_CHALLENGES}/${GET_CHALLENGES_INFO}`)
    this.getChallengeById()
  },
  mounted() {
    // Show first values if not exist settings overlay
    this.valuesOverlay = (this.isUserInvolved && this.initOverlay) && !this.challengeExtendedSettings;
  },
  methods: {
    getChallengeById() {
      this.challengeDetail = this.challengesInfo.find((challenge) => challenge.id === this.challengeId)
    },
    sendChallenge() {
      let processId = utilsGeneral.startAsyncProcess();
      this.sendFailed = false;
      this.sending = true;

      const postData = {
        challengeId: this.challengeId,
        challengeValue: this.getSettingsValue.toString(),
        challengeValueTypeId: this.selectedValue.id,
        competitionId: this.competitionId,
        day: this.dateDay,
        themeId: null, // temp, get data from api
        categoryId: null, // temp, get data from api
      }

      apiCmsChallenges
        .sendChallenge(postData)
        .then((result) => {
          logger.info('result: ', result);
          logger.info('Form has been sent.');

          this.sending = false;
          utilsGeneral.stopAsyncProcess(processId);
          this.setIsUserInvolved();

          this.$router.push({
            name: 'sustainability-challenge-success',
            params: {
              challengeId: this.challengeId,
              competitionId: this.competitionId,
            }
          });
        })
        .catch(error => {
          this.sending = false;
          this.sendFailed = true;
          utilsGeneral.stopAsyncProcess(processId);
          logger.error('Sending form has failed.', error.response);
        })
    },
    setIsUserInvolved() {
      if (!this.isUserInvolved) {
        this.$store.commit(`${ESG_CHALLENGES}/${SET_CHALLENGE_INVOLVE}`, { challenge: this.challengeId, linkedCompetition: this.competitionId });
      }
    },
    selectValue(item) {
      this.selectedValue = item;
      this.toggleValuesOverlay();
    },
    toggleSelectingWeek(value) {
      this.openedSelectingWeek = value;
    },
    toggleInitOverlay() {
      this.initOverlay = !this.initOverlay;
    },
    toggleSettingsOverlay() {
      this.settingsOverlay = !this.settingsOverlay;
    },
    toggleValuesOverlay() {
      this.valuesOverlay = !this.valuesOverlay;
    },
    showSettings() {
      this.toggleInitOverlay();
      this.toggleSettingsOverlay();
    },
    showValues() {
      this.initOverlay = false;
      this.settingsOverlay = false;

      this.toggleValuesOverlay();
    }
  }
}
</script>
