import axiosConfig from '@/utils/data-loader/axiosConfig'
import * as envConfig from 'env-config'
import utilsGeneral from '@/utils/fest/utils-general'
import logger from '@/utils/logger'
import { ORDER_NEW } from '@/constants/fest/string-values'

export default {
  /**
   * Get Stories by parameter
   * @description parameters provide means for sifting through data; sorting and/or filtering
   *              BE supports filtering by multiple filters
   *
   * @param {Number} page
   * @param {Number} perPage
   * @param {String} orderBy
   * @param {String} order
   * @param {Array<{filter: String, id: Number}>} storyFilters
   * @param {Record<filter: String, operand: String>} filterOps
   * @param {Number} archive - 1 stands for unpublished stories
   * @returns {Promise<{data: Array, status: number}>}
   */
  async getStoriesByParam({
    page = 1,
    perPage = 20,
    orderBy = ORDER_NEW,
    order = 'desc',
    storyFilters = [],
    filterOps = null,
    archive = null,
  }) {
    const processId = utilsGeneral.startAsyncProcess()

    const filters = storyFilters.reduce((acc, { filter, id }) => {
      return { ...acc, [`filter[${filter}]`]: id }
    }, {})

    const filterOperand = filterOps ? { [`filterOps[${filterOps.filter}]`]: filterOps.operand } : null

    try {
      const res = await axiosConfig.get(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_STORIES, {
        params: {
          page: page,
          per_page: perPage,
          orderby: orderBy,
          order: order,
          ...filters,
          ...filterOperand,
          archive,
        },
      },
      )

      const { data, status } = res
      if (status === 200) return { data, status }
    } catch (error) {
      // TODO JBa Provide proper error handling
      logger.error(error.response)
    } finally {
      utilsGeneral.stopAsyncProcess(processId)
    }
  },

  getStoryById(id) {
    return axiosConfig.get(`${envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_STORIES}/${id}`).then((response) => response.data)
  },
  sendStory(story) {
    let data = {
      theContent: story.content,
      featuredImage: story.photoBase64,
      themeId: story.themeId,
      competitionId: story.competitionId,
      story_owner: JSON.stringify({
        name: story.storyOwner.name,
        position: story.storyOwner.position,
        image: story.storyOwner.photoBase64,
      }),
      consents: story.consents,
    }
    return axiosConfig
      .post(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_STORIES, data)
      .then((response) => response.data)
  },

  likeStory(id) {
    return axiosConfig
      .put(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_STORIES + '/like/' + id, 1)
      .then((response) => response.data)
  },

  dislikeStory(id) {
    return axiosConfig
      .delete(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_STORIES + '/like/' + id)
      .then((response) => response.data)
  },
}
