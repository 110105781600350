<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        ref="navbar"
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        :infoButton="true"
        :infoText="$i18n.t('forvardino.whatIsForvardino')"
        :infoButtonDestination="'forvardino-info'"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1023-my-registered-actions
        v-if="loadedRegisteredEvents && displayMyEvents"
        :eventsData="forvardinoRegisteredEventsData"
        component-type="forvardino"
      />

      <prj1024-item-list
        ref="forvardinoList"
        :listData="forvardinoEvents"
        :listItemsCount="parseInt(forvardinoEventsNumber)"
        :isFilterActive="forvardinoAreFiltersActive"
        :noMoreItems="forvardinoNoMoreEvents"
        :componentKey="componentKey"
        :listItemConfig="listItemConfigObject"
        @filterData="filterData"
        @loadNewContent="loadNewContent"
        @resetFilteredItems="resetItems"
        @scrollToTop="scrollToTop"
        @openFilterDialog="openFilterDialog"
        @openCustomEventDialog="openCustomEventDialog"
        @goToDetail="goToDetail"
      />
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <prj1026-category-filter
        v-if="forvardinoListInitialized && convertedFilterCategories.length"
        :filterCategories="convertedFilterCategories"
        :showItems="convertedFilterCategories.length"
        :showFilterButton="forvardinoAreFiltersActive"
        :activeCategory="parseInt(forvardinoActiveCategory)"
        filterType="forvardino"
        @filterItems="filterItems"
        @resetFilteredItems="resetItems"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="!forvardinoListInitialized || isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="eventSignupSuccess"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          v-if="(typeof registrationSuccessMessage !== 'undefined')"
          type="success"
        >
          {{ $t('forvardino.' + registrationSuccessMessage) }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="eventSignOutSuccess"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          v-if="(typeof registrationSuccessMessage !== 'undefined')"
          type="success"
        >
          {{ $t('forvardino.' + registrationSuccessMessage) }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="hasError"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          <span
            v-if="newsletterSubmitMailDuplicity"
          >
            {{ $t('error.emailAlreadyRegistered') }}
          </span>

          <span v-else>
            {{ $t('error.generic') }}
          </span>
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="newsletterSubmitSuccessfull"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="success"
        >
          {{ $t('forvardino.submitNewsletterSuccessfull') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="draftFormSent"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="success"
        >
          {{ $t('forvardino.customCourseSuccessfull') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-forvardino-newsletter-modal"
        :active.sync="newsletterSubmitModal"
      >
        <h2 class="vue-forvardino-newsletter-headline">
          {{ $t('forvardino.submitNewsletterHeading') }}
        </h2>

        <p>{{ $t('forvardino.submitNewsletterText') }}</p>
        <p></p>

        <Prj1049LoginRedirectButton
          @afterLoginEvent="submitToNewsletter()"
        >
          {{ $t('general.submit') }}
        </Prj1049LoginRedirectButton>

        <frm1006-button
          type="app-button"
          @buttonClickEvent="declineNewsletter"
        >
          {{ $t('general.notNow') }}
        </frm1006-button>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-forvardino-custom-course-modal"
        :active.sync="isCustomCourseModalActive"
        :buttonClose="true"
        preventClosing="all"
      >
        <div class="vue-b-custom-course">
          <h2>{{ $t('forvardino.customCourseCaption') }}</h2>

          <p v-html="$t('forvardino.customCourseText')" />

          <template v-if="forvardinoEventsCreate">
            <p v-html="$t('forvardino.customCourseText2')" />
            <frm1006-button
              type="primary"
              @buttonClickEvent="openCustomEventFormDialog"
            >
              {{ $t('forvardino.customCourseFormButton') }}
            </frm1006-button>
          </template>
        </div>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-forvardino-custom-course-form-modal"
        :active.sync="isCustomCourseFormModalActive"
        :buttonClose="true"
        preventClosing="all"
      >
        <div class="vue-b-custom-course">
          <h2>{{ $t('forvardino.customCourseCaption') }}</h2>

          <frm1035-form-group>
            <frm1001-input-field
              v-model="draftFormName"
              :disabled="draftFormCompleted"
              :required="true"
              :tooltip="false"
              :label="$i18n.t('forvardino.forms.labelName')"
              @inputChangeEvent="draftFormValidate"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <wysiwyg v-model="draftFormDescription" />
          </frm1035-form-group>

          <frm1035-form-group :required="true">
            <label>{{ $i18n.t('forvardino.forms.labelCategory') }}</label>
            <frm1025-option-group
              :name="'frm-categories-list'"
              :multiselect="true"
              :options="draftFormCategoriesOptions"
              :value="draftFormCategories"
              :tooltip="false"
              @change="changeEventCategories"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1028-advanced-combo
              v-model="draftFormTarget"
              :options="draftFormTargets"
              :required="true"
              :tooltip="false"
              :label="$i18n.t('forvardino.forms.labelTarget')"
              :placeholder="$i18n.t('forvardino.forms.labelTarget')"
              @change="draftFormValidate"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <div class="vue-photo-image-wrap">
              <label>{{ $i18n.t('forvardino.forms.labelImage') }}</label>

              <prj1010-image-upload
                v-if="!draftFormImage"
                class="vue-app-upload-icon"
                :cropper="true"
                :acceptFiles="['image/jpeg', 'image/png']"
                :cropper-aspect-ratio="16 / 9"
                :caption="'png/jpeg'"
                @uploadedPhoto="processedPhoto"
              />

              <div class="vue-camera-canvas">
                <div
                  v-if="draftFormImage"
                  class="vue-app-uploaded-file"
                >
                  <img
                    :src="draftFormImage"
                    :style="styleObjectImg"
                  />

                  <frm1006-button
                    v-if="!draftFormCompleted"
                    class="vue-button-remove-icon"
                    type="internal"
                    :iconLeft="true"
                    :captionHidden="true"
                    @buttonClickEvent="removeTakenPicture"
                  >
                    {{ $t('forvardino.forms.buttonRemoveImage') }}
                  </frm1006-button>
                </div>
              </div>
            </div>
          </frm1035-form-group>

          <gen1006-info-panel
            type="neutral"
            class="vue-disclaimer"
          >
            <span v-html="$i18n.t('forvardino.customCourseDraftNote')"></span>
          </gen1006-info-panel>
          <p>&nbsp;</p>

          <frm1006-button
            type="primary"
            class="vue-draft-form-submit"
            :class="classObject"
            :disabled="!isDraftFormValid || draftFormSending || draftFormSent"
            @buttonClickEvent="clickEventSubmitDraftForm()"
          >
            {{ $t('forvardino.forms.confirm') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import router from '@/router';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import EVENT_BUS from '@/event-bus';
import {
  ERROR_MODAL_TIMEOUT,
  DEFAULT_LIST_PAGINATION
} from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1023MyRegisteredActions from '@/components/prj1023-my-actions/prj1023-my-registered-actions';
import Prj1024ItemList from '@/components/prj1024-item-list/prj1024-item-list';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter';
import Prj1049LoginRedirectButton from '@/components/prj1049-login-redirect-button/prj1049-login-redirect-button';
import Prj1010ImageUpload from '@/components/prj1010-image-upload/prj1010-image-upload.vue';
import Frm1025OptionGroup from '../../../frameworks/vue/components/frm/frm1025-option-group/frm1025-option-group.vue';

export default {
  name: 'ForvardinoList',
  components: {
    Frm1025OptionGroup,
    Prj1010ImageUpload,
    PageLayout,
    Prj1002Navbar,
    Prj1023MyRegisteredActions,
    Prj1024ItemList,
    Prj1026CategoryFilter,
    Prj1049LoginRedirectButton
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      forvardinoEvents: [],
      forvardinoEventsNumber: 0,
      forvardinoEventsCreate: false,
      forvardinoRegisteredEventsData: [],
      loadedRegisteredEvents: false,
      forvardinoListInitialized: false,
      forvardinoNoMoreEvents: false,
      currentPosition: 0,
      componentKey: 0,
      listItemConfigObject: {
        displayDate: true,
        displayRating: true,
      },
      isLoading: false,
      eventSignupSuccess: false,
      eventSignOutSuccess: false,
      hasError: false,
      newsletterSubmitModal: false,
      newsletterSubmitSuccessfull: false,
      newsletterSubmitMailDuplicity: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      isDraftFormValid: false,
      draftFormSending: false,
      draftFormSent: false
    }
  },

  computed: {
    ...mapState('user', [
      'deviceId',
      'forvardinoNewsletterState',
      'profile'
    ]),
    ...mapState('forvardino', [
      'forvardinoFilterCategories',
      'forvardinoSelectedCategories',
      'forvardinoActiveCategory',
      'forvardinoAreFiltersActive',
      'showRegisteredSuccess',
      'searchQuery',
      'modalCustomCourseActive',
      'modalCustomCourseFormActive',
      'draftFormData',
    ]),
    ...mapState('persistentStorage', [
      'registeredForvardinoEvents'
    ]),

    pageId() {
      if (typeof this.$route.params.categoryId === 'undefined') {
        return 0;
      }

      return this.$route.params.categoryId;
    },

    navbarCaption() {
      return this.$route.meta.navbarCaption;
    },

    registrationSuccessMessage() {
      return this.$route.params.registrationMessage;
    },

    convertedFilterCategories() {
      let convertedFilterCategories = [];

      Object.values(this.forvardinoFilterCategories.categories).forEach(item => {
        convertedFilterCategories.push({ 'termId': item.id, 'termName': item.name });
      })

      return convertedFilterCategories;
    },

    draftFormCategoriesOptions() {
      let convertedFilterCategories = [];

      Object.values(this.forvardinoFilterCategories.categories).forEach(item => {
        convertedFilterCategories.push({ 'value': item.id, 'caption': item.name });
      })

      return convertedFilterCategories;
    },

    draftFormTargets() {
      let convertedFilterCategories = [];

      Object.values(this.forvardinoFilterCategories.targets).forEach(item => {
        convertedFilterCategories.push({ 'value': item.id, 'caption': item.name });
      })

      return convertedFilterCategories;
    },

    endpointListOptions() {
      return [
        this.forvardinoSelectedCategories.eventCategories,
        this.forvardinoSelectedCategories.eventLocation,
        this.forvardinoSelectedCategories.eventTargets,
        this.forvardinoSelectedCategories.onlyOnline,
        this.searchQuery,
        this.currentPosition,
        20
      ]
    },

    displayMyEvents() {
      return Object.values(this.forvardinoEvents).length > 0;
    },

    myForvardinoEventsIds() {
      return this.registeredForvardinoEvents;
    },

    isCustomCourseModalActive: {
      get() {
        return this.modalCustomCourseActive;
      },
      set(newValue) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_CUSTOM_COURSE_MODAL, newValue);
      }
    },

    isCustomCourseFormModalActive: {
      get() {
        return this.modalCustomCourseFormActive;
      },
      set(newValue) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_CUSTOM_COURSE_FORM_MODAL, newValue);
      }
    },

    draftFormId: {
      get () {
        return this.draftFormData.id;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_ID, value);
      }
    },

    draftFormName: {
      get () {
        return this.draftFormData.name;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_NAME, value);
      }
    },

    draftFormDescription: {
      get () {
        return this.draftFormData.description;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_DESCRIPTION, value);
      }
    },

    draftFormCategories: {
      get () {
        return this.draftFormData.categories;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_CATEGORY, value);
      }
    },

    draftFormTarget: {
      get () {
        return this.draftFormData.target;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_TARGET, value);
      }
    },

    draftFormImage: {
      get () {
        return this.draftFormData.image;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_IMAGE, value);
      }
    },

    draftFormCompleted: {
      get () {
        return this.draftFormData.completed;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_COMPLETED, value);
      }
    },

    classObject() {
      return {
        'vue-is-active': this.isDraftFormValid,
      }
    },

    styleObjectImg() {
      return {
        maxWidth: '200px',
        maxHeight: '200px',
      }
    },
  },

  watch: {
    forvardinoAreFiltersActive(newVal) {
      if (newVal === true) {
        this.fetchForvardinoData();
      }
    },

    searchQuery() {
      this.fetchForvardinoData();
    },

    draftFormDescription() {
      this.draftFormValidate();
    }
  },

  created() {
    this.fetchForvardinoData();
  },

  mounted() {
    EVENT_BUS.$on(GLOBAL_EVENTS.FILTER_ITEM_LIST, categoryId => {
      this.filterItems(categoryId);
    });

    this.fetchMyLessons();

    this.setForvardinoActiveCategory();
    this.handleNewsletterModal();
    this.handleSuccessModal();
  },

  beforeDestroy() {
    EVENT_BUS.$off(GLOBAL_EVENTS.FILTER_ITEM_LIST);
  },

  methods: {
    fetchForvardinoData() {
      this.componentKey++;
      this.currentPosition = 0;
      this.forvardinoListInitialized = false;
      this.$store.dispatch(STORE_MODULES.FORVARDINO + '/' + ACTIONS_CONSTANTS.FETCH_FORVARDINO_FILTER_DATA);

      dataLoader.getForvardinoFilteredList([...Object.values(this.endpointListOptions)])
        .then(response => {
          this.forvardinoEvents = response.courses;
          this.forvardinoEventsNumber = response.resultsCount;
          this.forvardinoEventsCreate = response.courseCreate;
          this.forvardinoListInitialized = true;
        })
        .catch(error => {
          this.forvardinoListInitialized = true;
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          if (parseInt(this.forvardinoEventsNumber) > 20) {
            this.forvardinoNoMoreEvents = false;
            this.$refs.forvardinoList?.initInfinityScroll();
          }
        });
    },

    async loadNewContent() {
      if (this.forvardinoNoMoreEvents) return;
      this.currentPosition += DEFAULT_LIST_PAGINATION;

      await dataLoader.getForvardinoFilteredList([...this.endpointListOptions])
        .then(response => {
          let tempArray = this.forvardinoEvents;
          this.forvardinoEvents = [...tempArray, ...response.courses];

          if (response.courses.length < DEFAULT_LIST_PAGINATION) {
            this.forvardinoNoMoreEvents = true;
          }
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        });
    },

    filterData() {
      router.push({ name: 'forvardino-list', params: { categoryId: this.forvardinoActiveCategory }});
      this.fetchForvardinoData();
    },

    setForvardinoActiveCategory() {
      if (typeof this.$route.params.categoryId !== 'undefined') {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_ACTIVE_CATEGORY, this.$route.params.categoryId);

        if (parseInt(this.$route.params.categoryId) !== 0) {
          this.filterItems(this.forvardinoActiveCategory);
        }
      }
    },

    startLoadingForvardinoList() {
      this.forvardinoListInitialized = false;
    },

    finishLoadingForvardinoList() {
      this.forvardinoListInitialized = true;
    },

    handleNewsletterModal() {
      if (typeof this.registrationSuccessMessage === 'undefined' && this.forvardinoNewsletterState === null) {
        this.newsletterSubmitModal = true;
      }
    },

    handleSuccessModal() {
      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'successfullRegistration')) {
        // FIXME: CSOBZ-521 - remove nextTick due the bug in the automatic modal window closing
        this.$nextTick(() => {
          this.eventSignupSuccess = true;
        });
      } else if (Object.prototype.hasOwnProperty.call(this.$route.params, 'successfullUnRegistration')) {
        // FIXME: CSOBZ-521 - remove nextTick due the bug in the automatic modal window closing
        this.$nextTick(() => {
          this.eventSignOutSuccess = true;
        });
      }
    },

    openFilterDialog() {
      this.$store.dispatch(STORE_MODULES.POPUP + '/' + ACTIONS_CONSTANTS.TOGGLE_POPUP, 'forvardino');
    },

    openCustomEventDialog() {
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_RESET);
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_CUSTOM_COURSE_MODAL, true);
    },

    openCustomEventFormDialog() {
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_CUSTOM_COURSE_MODAL, false);
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_CUSTOM_COURSE_FORM_MODAL, true);
    },

    /**
     * @param categoryNumber number, $emitted from Prj1026CategoryFilter
     */
    filterItems(categoryNumber) {
      let selectedCategoryPayload = {
        categories: [categoryNumber]
      };

      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_SELECTED_CATEGORIES, selectedCategoryPayload);
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_ACTIVE_CATEGORY, categoryNumber);

      this.fetchForvardinoData();
    },

    resetItems() {
      this.$store.dispatch(STORE_MODULES.FORVARDINO + '/' + ACTIONS_CONSTANTS.RESET_ALL_FORVARDINO_FILTERS)
        .then(() => {
          this.fetchForvardinoData();
        });
    },

    fetchMyLessons() {
      dataLoader.getForvardinoMyLessons()
        .then(data => {
          this.forvardinoRegisteredEventsData = data.courses;
          this.loadedRegisteredEvents = true;
        }).catch(error => {
          this.hasError = true;
          logger.error(error);
        });
    },

    // FIXME: CSOBZ-515 - merge all scroll to top methods into one global method
    scrollToTop() {
      this.$nextTick(() => {
        this.scrollToElement();
      })
    },

    scrollToElement() {
      const ref = this.$refs.navbar.$el;

      if (ref) {
        ref.scrollIntoView(true);
      }
    },

    submitToNewsletter() {
      this.isLoading = true;

      dataLoader.sendForvardinoNewsletterSignIn()
        .then(response => {
          if (response.success) {
            this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEWSLETTER_STATE, true);

            this.newsletterSubmitSuccessfull = true;
          }

          if (!response.success) {
            if (response.data.message.includes('PFDA0005')) {
              this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEWSLETTER_STATE, false);
              this.newsletterSubmitMailDuplicity = true;
            }

            this.hasError = true;
            logger.error(response.data.message);
          }
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          this.newsletterSubmitModal = false;
          this.isLoading = false;
        })
    },

    clickEventSubmitDraftForm() {
      let formData = new FormData();
      formData.append('id', this.draftFormId);
      formData.append('name', this.draftFormName);
      formData.append('description', this.draftFormDescription);
      formData.append('category', this.draftFormCategories.join(','));
      formData.append('target', this.draftFormTarget);
      formData.append('image', this.draftFormImage);
      this.draftFormSending = true;

      dataLoader.sendForvardinoCourse(
        formData
      ).then(() => {
        logger.info('Form has been sent!');
        this.draftFormSent = true;
      }).catch(error => {
        logger.error('Sending form has failed:', error);
        //this.formFailed = true;
        this.draftFormSent = false;
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.draftFormSending = false;
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_CUSTOM_COURSE_FORM_MODAL, false);
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_RESET);
      })
    },

    declineNewsletter() {
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEWSLETTER_STATE, false);
      this.newsletterSubmitModal = false;
    },

    goToDetail(data) {
      // save to store
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, data);
      router.push({ name: 'forvardino-detail', params: { id: data.id } });
    },

    draftFormDescriptionInvalidState() {
      if (this.isDraftFormValid) {
        return 'info'
      } else {
        return 'invalid'
      }
    },

    draftFormValidate() {
      let decision = false;

      if (this.draftFormData.name.length > 0 &&
          this.draftFormData.description.length > 0 &&
          this.draftFormData.categories.length > 0 &&
          this.draftFormData.target.length > 0) {
        decision = true;
      }

      this.isDraftFormValid = decision;
    },

    processedPhoto(photoObject) {
      this.draftFormImage = photoObject;
    },

    removeTakenPicture() {
      this.draftFormImage = null;
    },

    changeEventCategories(value) {
      let categories = [];
      Object.entries(value).forEach(([, val]) => {
        categories.push(val);
      });
      this.draftFormCategories = categories;
    },
  }
}
</script>
