<template>
  <div
    class="vue-c-widget"
    :class="getClassObject"
  >
    <div
      class="vue-b-header"
      :style="getHeaderStyleObject"
      @click="goToAction()"
    >
      <span
        v-if="iconSvg"
        class="vue-header-icon"
        :style="getIconStyleObject"
        v-html="iconSvg"
      />
      <span
        class="vue-header-text"
        v-html="title"
      />
    </div>
    <div
      class="vue-b-content"
    >
      <div class="vue-b-content-wrap">
        <template
          v-for="item in getDataItems"
        >
          <prj1024-item-list-item
            :key="item.actionId"
            :itemData="item"
            :listItemConfig="item"
            :promoted="promoted && isDesktopLayout ? promoted : item.promoted"
            :promoted-only-icon="item.promoted && !promoted"
            is-widget
            :module="item.module ? item.module : module"
            :line-clamp="promoted && isDesktopLayout ? 2 : null"
            :class="dataLoadingClassObject"
          />
        </template>

        <div
          v-if="dataItems.length === 0 && dataLoaded && !customContent"
          class="vue-empty-notice"
        >
          {{ $t('general.widgetNoContent') }}
        </div>

        <slot name="customContent"></slot>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import router from '@/router';
import Prj1024ItemListItem from '@/components/prj1024-item-list/prj1024-item-list-item';
import { WIDGET_EMPTY_DATA_ITEMS } from '@/constants/app-constants';

import mxDetectDesktop from '@/mixins/mx-detect-desktop';

export default {
  name: 'Prj1050Widget',
  components: { Prj1024ItemListItem },
  mixins: [mxDetectDesktop],
  props: {
    title: {
      type: String,
      required: true
    },
    iconSvg: {
      type: String,
      required: false
    },
    dataItems: {
      type: Array,
      required: true
    },
    iconColor: {
      type: String,
      required: false,
      default: 'Black'
    },
    bgColor: {
      type: String,
      required: false,
      default: 'White'
    },
    promoted: {
      type: Boolean,
      default: false
    },
    promotedLimit: {
      type: Number,
      default: 3
    },
    destination: {
      type: [Boolean, Object],
      required: false,
      default: false
    },
    module: {
      type: String,
      required: false,
      default: 'event'
    },
    dataLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    dataLoaded: {
      type: Boolean,
      required: false,
      default: true
    },
    customContent: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      emptyDataItems: WIDGET_EMPTY_DATA_ITEMS
    }
  },

  computed: {
    ...mapState('user', [
      'deviceId'
    ]),

    getClassObject() {
      return {
        ['vue-is-promoted']: this.promoted,
      }
    },

    getHeaderStyleObject() {
      return {
        'background-color': this.bgColor
      };
    },

    getIconStyleObject() {
      return {
        'color': this.iconColor
      };
    },

    getDataItems() {
      return this.dataLoaded ? (this.promoted ? this.dataItems.slice(0, this.promotedLimit) : this.dataItems) : (this.promoted ? this.emptyDataItems.slice(0, this.promotedLimit) : this.emptyDataItems);
    },

    dataLoadingClassObject() {
      return {
        'vue-is-loading': !this.dataLoaded,
      }
    },
  },

  watch: {},

  created() {},

  mounted() {},

  methods: {
    goToAction() {
      if (!this.destination) {
        return false;
      }

      router.push({name: this.destination.name, params: this.destination.params});
    },
  }
}
</script>
