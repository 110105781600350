<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar :route-name="ROUTE_CHALLENGES" />
        </template>
        <template v-slot:content>
          <div
            v-if="challengeResultsPage"
            class="prj-layout-default h-full"
          >
            <header class="mb-8 xs:mt-6 md:mb-10">
              <t-tag
                variant="sectionTitle"
                class="mb-8"
                tag-name="h1"
                v-html="title"
              ></t-tag>
              <p class="inline-block px-4 py-2 bg-gray-100 rounded-lg">{{ challengeDuration() }}</p>
            </header>

            <!--========== PAGE BODY ==============================-->
            <!--===================================================-->
            <section
              class="prj-article prj-article-extend-fest mb-8"
              v-html="content"
            ></section>
          </div>
          <!--========== SUMMARY ================================-->
          <!--===================================================-->
          <!--        <prj1022-result-panel-->
          <!--          v-if="challengeResult"-->
          <!--          class="mt-8 mb-8"-->
          <!--          :primary-value="primaryResult"-->
          <!--          :secondary-value="secondaryResult"-->
          <!--          :participants="challengeResult.summary.participants"-->
          <!--          :stories="challengeResult.summary.stories"-->
          <!--          :sharing="true"-->
          <!--          :share-image-name="challengeMainInfo.title"-->
          <!--          :title="challengeMainInfo.title"-->
          <!--          :sub-title="challengeMainInfo.subTitle"-->
          <!--          :description="challengeMainInfo.description"-->
          <!--          :type-title="challengeTypeTitle"-->
          <!--          :savings-units="savingsUnits"-->
          <!--        />-->
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>

//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex';
import '@/utils/dayjs';
import utilsGeneral from '@/utils/utils-general'
import apiCmsContent from '@/fest-api/cms-content'
import logger from '@/utils/logger'

import { FEST_CHALLENGES } from '@/store/store-modules';
import { GET_CHALLENGES_INFO } from '@/store/constants/fest/actions';
import { ROUTE_CHALLENGES } from '@/constants/fest/route-names'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import greenKmsChallengeType from '@/assets/fest/challenges/types/greenKms';

import PageLayout from '@/templates/fest/page-layout';
import Prj1005NavigationBar from '@/components/fest/prj1005-navigation-bar/prj1005-navigation-bar';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTChallengeResults',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1005NavigationBar,
    PageLayout
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_CHALLENGES,
      challengeDetail: null,
      challengeResultsPage: null,
      challengeResult: {
        type: '',
        summary: {
          primaryValue: '720 000',
          secondaryValue: '210 000 kg',
          participants: '1500',
          stories: '200'
        },
        extendedSummaryList: []
      },
      challengeType: greenKmsChallengeType
    }
  },
  computed: {
    ...mapState('festChallenges', ['challengesInfo']),
    challengeId() {
      return parseInt(this.$route.params.challengeId);
    },
    title() {
      return this.challengeResultsPage?.title.rendered
    },
    content() {
      return this.challengeResultsPage?.content.rendered
    },
    challengeMainInfo() {
      return {
        title: this.challengeDetail.title,
        subTitle: this.challengeDetail.subTitle,
        description: this.challengeDetail.description
      }
    },
    challengeTypeTitle() {
      return this.challengeType.settings.title;
    },
    savingsUnits() {
      return this.challengeType.settings.savingsUnits;
    },
    isExtendedList() {
      return this.challengeResult.extendedSummaryList.length > 0;
    },
    extendedList() {
      return this.challengeResult.extendedSummaryList;
    },
    primaryResult() {
      return this.challengeResult.summary.primaryValue;
    },
    secondaryResult() {
      return this.challengeResult.summary.secondaryValue;
    },
    numberOfParticipants() {
      return this.challengeResult.summary.participants;
    },
    numberOfStories() {
      return this.challengeResult.summary.stories;
    }
  },

  async created() {
    if (!this.challengesInfo.length) await this.$store.dispatch(`${FEST_CHALLENGES}/${GET_CHALLENGES_INFO}`)
    this.getChallengeById()
    this.getAfterPage()
  },

  methods: {
    getChallengeById() {
      this.challengeDetail = this.challengesInfo.find((challenge) => challenge.id === this.challengeId)
    },
    getAfterPage() {
      const pageId = this.challengeDetail?.afterPages?.ID

      if (!pageId) return

      const processId = utilsGeneral.startAsyncProcess()
      apiCmsContent
        .getPageById(pageId)
        .then((response) => {
          this.challengeResultsPage = response
        })
        .catch((error) => {
          logger.error(error)
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId)
        })
    },
    challengeDuration() {
      const start = this.challengeDetail.startDate
      const end = this.challengeDetail.endDate

      const startDate = this.$date(start).format('L')
      const endDate = this.$date(end).format('L')

      return this.$t('fest.challenges.result.duration', { startDate, endDate })
    },
    challengeTypeObject(id) {
      return this.challengeItems.find(transportItem => transportItem.id === parseFloat(id))
    },
    percentageFromMaxValue(max, value) {
      const maxValue = max ? max : 1;
      const valueWithMax = value ? value : max ? value : 1;

      return Math.round( (valueWithMax / maxValue) * 100);
    }
  }
}
</script>
