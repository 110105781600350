<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar :route-name="ROUTE_DASHBOARD" />
        </template>
        <template v-slot:content>
          <div class="prj-layout-default h-full">
            <!--========== HEADER =================================-->
            <!--===================================================-->
            <header class="">
              <t-tag
                variant="sectionTitle"
                class="xs:mt-6"
                tag-name="h1"
              >
                {{ $t('esg.stories.headline') }}
              </t-tag>

              <p
                v-if="activeTextualFilterTitle"
                class="text-2xl xs:text-3xl font-semibold text-teal-600"
              >
                {{ $t(activeTextualFilterTitle) }}
              </p>
              <p
                v-else
                class="text-sm xs:text-base"
              >
                {{ $t('esg.stories.description') }}
              </p>
            </header>

            <!--========== CARDS ==================================-->
            <!--===================================================-->
            <div class="relative flex-1">
              <div class="absolute top-0 right-0 bottom-0 left-0">
                <div
                  v-if="!stories.length && !loading"
                  class="flex h-full"
                >
                  <prj1023-no-content-panel
                    :text="$t('esg.stories.placeholder')"
                    class="h-11/12 xs:h-4/5 my-auto"
                  />
                </div>
                <prj1006-cards-wrapper
                  v-else
                  class="h-full"
                  :itemsLength="stories.length"
                  :hasNavigation="!isSingleStory"
                  :fireScrollEnd="true"
                  :startPosition="scrollToIndex"
                  @scrollEnd="loadMoreStories"
                >
                  <template>
                    <prj1008-story-card
                      v-for="story in stories"
                      :key="`filter-${activeFilterName}-${story.id}`"
                      class="h-11/12 xs:h-4/5 my-auto"
                      :data-id="`${story.id}`"
                      :story="story"
                      :single="isSingleStory"
                    />
                  </template>
                </prj1006-cards-wrapper>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex items-center">
            <prj1019-visual-modal-select
              containerClasses="mr-auto"
              btnClasses="bg-white mb-3 xs:mb-5 px-0 py-2 text-sm"
              btnTxtHighlighted
              :disabled="loading"
              :resetBtnCaption="$t('esg.themes.allThemesStories')"
              :activeSelection="activeFilterName === FILTER_THEME ? activeFilterId : 0"
              :options="themesInfo"
              :overlayBtnCaption="activeVisualFilterTitle"
              @select="(id) => onActiveFilterChange({ id })"
              @selectionReset="onFilterReset"
            >
              <template v-slot:icon>
                <svg
                  class="h-6 w-6 mr-3"
                  viewBox="0 0 24 24"
                  stroke="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.35362 6H7.28125L4 18.1903H6.07362L9.35362 6Z" />
                  <path d="M14.6636 6H12.5859L9.30469 18.1903H11.3836L14.6636 6Z" />
                  <path d="M19.9636 6H17.8906L14.6094 18.1903H16.6836L19.9636 6Z" />
                </svg>
              </template>
            </prj1019-visual-modal-select>
            <prj1001-section-button
              type="link"
              class="mr-4"
              :route-name="ROUTE_NEW_STORY"
            >
              <template v-slot:icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 transform -rotate-90"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </template>
              <template v-slot:text>
                <span class="sr-only">{{ $t('esg.stories.buttonWriteStory') }}</span>
              </template>
            </prj1001-section-button>
            <prj1017-textual-filters
              :disabled="loading"
              :defaultFilter="textualFilters[0].value"
              :activeFilter="`${activeFilterName}`"
              :filters="textualFilters"
              @filterChange="(filter) => onActiveFilterChange({ filter })"
            />
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex'
import apiCmsStories from '@/esg-api/cms-stories'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import { ESG_THEMES } from '@/store/store-modules'
import { GET_THEMES_INFO } from '@/store/constants/esg/actions'

import '@/utils/dayjs'
import { ROUTE_DASHBOARD, ROUTE_NEW_STORY } from '@/constants/esg/route-names'
import { FILTER_THEME, FILTER_CORP, FILTER_PROM, ORDER_NEW, FILTER_LIKED } from '@/constants/esg/string-values'

import PageLayout from '@/templates/esg/page-layout'
import Prj1001SectionButton from '@/components/esg/prj1001-section-button/prj1001-section-button'
import Prj1005NavigationBar from '@/components/esg/prj1005-navigation-bar/prj1005-navigation-bar'
import Prj1006CardsWrapper from '@/components/esg/prj1006-cards-wrapper/prj1006-cards-wrapper'
import Prj1008StoryCard from '@/components/esg/prj1008-story-card/prj1008-story-card'
import Prj1017TextualFilters from '@/components/esg/prj1017-textual-filters/prj1017-textual-filters'
import Prj1019VisualModalSelect from '@/components/esg/prj1019-visual-modal-select/prj1019-visual-modal-select'
import Prj1023NoContentPanel from '@/components/esg/prj1023-no-content-panel/prj1023-no-content-panel'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'ESGStories',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout,
    Prj1001SectionButton,
    Prj1005NavigationBar,
    Prj1006CardsWrapper,
    Prj1008StoryCard,
    Prj1017TextualFilters,
    Prj1019VisualModalSelect,
    Prj1023NoContentPanel
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_DASHBOARD,
      ROUTE_NEW_STORY,
      FILTER_THEME,
      page: 1,
      noMoreLoad: false,
      loading: true,
      stories: [],
      loginOverlay: false,
      textualFilters: [
        { value: ORDER_NEW, label: 'esg.stories.order.orderByDate' },
        { value: FILTER_LIKED, label: 'esg.stories.filters.filterLiked' },
        { value: FILTER_CORP, label: 'esg.stories.filters.filterCorporate' },
        { value: FILTER_PROM, label: 'esg.stories.filters.filterPromoted' },
      ],
      activeFilterName: this.$route.params.filterName || ORDER_NEW, // TODO JBa active filter param from URL OR new
      activeFilterId: this.$route.params.filterId || 0,
      scrollToIndex: null
    }
  },
  computed: {
    ...mapState('esgStories', {
      currentUserLikedStories: (state) => state.currentUserLikedStories,
    }),
    ...mapState('esgThemes', ['themesInfo']),
    isSingleStory() {
      return this.stories.length === 1
    },
    activeVisualFilterTitle() {
      return this.activeFilterName === FILTER_THEME
        ? this.themesInfo?.find((theme) => theme.id === this.activeFilterId)?.title
        : this.$t('esg.themes.headline')
    },
    activeTextualFilterTitle() {
      return (
        this.textualFilters?.find(
          (filter) => filter.value !== this.textualFilters[0].value && filter.value === this.activeFilterName
        )?.label || null
      )
    },
  },
  created() {
    if (this.$route.params.scrollToIndex) this.scrollToIndex = this.$route.params.scrollToIndex
    this.getStories({ filter: this.activeFilterName, id: this.activeFilterId })
    if (!this.themesInfo.length) this.$store.dispatch(`${ESG_THEMES}/${GET_THEMES_INFO}`)
  },
  methods: {
    async getStories({ filter, id }) {
      this.loading = true

      // call API based on filter
      const { data, status } = await this.callFilterApi({ filter, id })

      if (status === 200) {
        if (!data.length) {
          this.loading = false
          return (this.noMoreLoad = true)
        }

        this.stories.push.apply(this.stories, data)
        this.loading = false
      }
    },

    // Subsequent call
    loadMoreStories() {
      if (this.noMoreLoad) return false

      this.page++
      this.getStories({ filter: this.activeFilterName, id: this.activeFilterId })
    },

    // Set component data to default values
    onActiveFilterChange({ filter = FILTER_THEME, id = 1 }) {
      this.page = 1
      this.stories = []
      this.activeFilterName = filter
      this.activeFilterId = id
      this.scrollToIndex = null
      this.getStories({ filter, id })
    },

    async callFilterApi({ filter, id }) {
      switch (filter) {
      case ORDER_NEW:
        return await apiCmsStories.getStoriesByParam({ page: this.page, orderBy: filter })
      case FILTER_LIKED:
        return await apiCmsStories.getStoriesByParam({ page: this.page, orderBy: filter, storyFilters: [{ filter, id: 0 }], filterOps: { filter, operand: 'gt' } })
      default:
        return await apiCmsStories.getStoriesByParam({ page: this.page, storyFilters: [{ filter, id }] })
      }
    },

    onFilterReset() {
      this.page = 1
      this.stories = []
      this.activeFilterName = ORDER_NEW
      this.activeFilterId = 0
      this.scrollToIndex = null
      this.getStories({ filter: ORDER_NEW })
    },
  },
}
</script>
