<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar :route-name="ROUTE_DASHBOARD" />
        </template>
        <template v-slot:content>
          <div class="prj-layout-default h-full">
            <!--========== HEADER =================================-->
            <!--===================================================-->
            <header class="">
              <t-tag
                variant="sectionTitle"
                class="xs:mt-6"
                tag-name="h1"
              >
                {{ $t('fest.themes.headline') }}
              </t-tag>
            </header>

            <!--========== THEMES CARDS ===========================-->
            <!--===================================================-->
            <div class="relative flex-1">
              <div class="absolute top-0 right-0 bottom-0 left-0">
                <div
                  v-if="!themesInfo.length"
                  class="flex h-full"
                >
                  <prj1023-no-content-panel
                    :text="$t('fest.themes.placeholder')"
                    class="h-11/12 my-auto"
                  />
                </div>
                <prj1006-cards-wrapper
                  v-else
                  class="h-full"
                  :hasNavigation="!isSingleTheme"
                  :itemsLength="themesInfo.length"
                >
                  <template
                    v-for="{
                      additionalLink,
                      additionalLinkUrl,
                      additionalIconType,
                      categoryId,
                      content,
                      id,
                      image,
                      timeSpanInfo,
                      title,
                    } in themesInfo"
                  >
                    <prj1007-theme-card
                      :key="`theme-${id}`"
                      :statusTag="timeSpanInfo"
                      :categoryId="categoryId"
                      :title="title"
                      :description="content"
                      :image="image"
                      :isSingle="isSingleTheme"
                      :link="additionalLink"
                      :linkUrl="additionalLinkUrl"
                      :icon="additionalIconType"
                      isFullPage
                      @primaryButton="toThemeDetail(id)"
                    />
                  </template>
                </prj1006-cards-wrapper>
              </div>
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex'
import { FEST_THEMES } from '@/store/store-modules'
import { GET_THEMES_INFO } from '@/store/constants/fest/actions'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import { ROUTE_DASHBOARD, ROUTE_THEME_DETAIL } from '@/constants/fest/route-names'
import '@/utils/dayjs'

import PageLayout from '@/templates/fest/page-layout'
import Prj1005NavigationBar from '@/components/fest/prj1005-navigation-bar/prj1005-navigation-bar'
import Prj1006CardsWrapper from '@/components/fest/prj1006-cards-wrapper/prj1006-cards-wrapper'
import Prj1007ThemeCard from '@/components/fest/prj1007-event-card/prj1007-theme-card'
import Prj1023NoContentPanel from '@/components/fest/prj1023-no-content-panel/prj1023-no-content-panel'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTThemes',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout,
    Prj1005NavigationBar,
    Prj1006CardsWrapper,
    Prj1007ThemeCard,
    Prj1023NoContentPanel
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_DASHBOARD,
    }
  },
  computed: {
    ...mapState('festThemes', ['themesInfo']),
    isSingleTheme() {
      return this.themesInfo?.length === 1
    },
  },
  created() {
    if (!this.themesInfo?.length) this.$store.dispatch(`${FEST_THEMES}/${GET_THEMES_INFO}`)
  },
  methods: {
    toThemeDetail(id) {
      this.$router.push({
        name: ROUTE_THEME_DETAIL,
        params: { id },
      })
    },
  },
}
</script>
