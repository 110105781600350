<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-phone-input" :class="classObjectComputed">
        <div class="vue-b-form-field">
            <div class="vue-b-label">
                <label v-if="label" :id="idComputed + ID_EXTENSIONS.LABEL" class="vue-label">{{ label }}</label>
            </div>
            <div class="vue-b-prefix">
                <frm1001-input-field
                    :id="prefixIdComputed"
                    ref="prefix"
                    v-model="valueComputed.prefix"
                    :idPrefix="null"
                    :name="prefixName"
                    :state="prefixStateComputed"
                    :required="prefixRequiredComputed"
                    :readonly="prefixReadonlyComputed"
                    :disabled="prefixDisabledComputed"
                    :label="prefixLabel"
                    :labeledBy="prefixLabeledByComputed"
                    :describedBy="prefixDescribedByComputed"
                    :placeholder="prefixPlaceholder"
                    :buttonClear="prefixButtonClear"
                    :tooltip="false"
                    :liveUpdate="liveUpdate"
                    @inputClickEvent="prefixClick"
                    @inputKeyDownEvent="prefixKeyDown"
                    @inputKeyUpEvent="prefixKeyUp"
                    @inputInputEvent="prefixInput"
                    @inputChangeEvent="prefixChange"
                    @inputFocusEvent="prefixFocus"
                    @inputBlurEvent="prefixBlur"
                    @componentIsActiveEvent="prefixIsActiveSet"
                    @tooltipWhiteListInitial="prefixTooltipWhiteListInitialSet"
                />
            </div>
            <div v-if="divider" class="vue-b-divider">
                <span class="vue-divider">{{ divider }}</span>
            </div>
            <div class="vue-b-number">
                <frm1001-input-field
                    :id="numberIdComputed"
                    ref="numberInput"
                    v-model="valueComputed.number"
                    :idPrefix="null"
                    :name="numberName"
                    :state="numberStateComputed"
                    :required="numberRequiredComputed"
                    :readonly="numberReadonlyComputed"
                    :disabled="numberDisabledComputed"
                    :label="numberLabel"
                    :labeledBy="numberLabeledByComputed"
                    :describedBy="numberDescribedByComputed"
                    :placeholder="numberPlaceholder"
                    :tooltip="false"
                    :liveUpdate="liveUpdate"
                    @inputClickEvent="numberClick"
                    @inputKeyDownEvent="numberKeyDown"
                    @inputKeyUpEvent="numberKeyUp"
                    @inputInputEvent="numberInput"
                    @inputChangeEvent="numberChange"
                    @inputFocusEvent="numberFocus"
                    @inputBlurEvent="numberBlur"
                    @componentIsActiveEvent="numberIsActiveSet"
                    @tooltipWhiteListInitial="numberTooltipWhiteListInitialSet"
                />
            </div>
        </div>

        <gen1010-information-tooltip
            v-if="tooltipComputed"
            ref="tooltip"
            :expanded.sync="tooltipExpandedData"
            :state="state"
            :disabled="tooltipDisabled"
            :content="tooltipContent"
            :contentId="idComputed + ID_EXTENSIONS.TOOLTIP_CONTENT"
            :whiteList="tooltipWhiteListComputed"
            :boundComponentActive="componentIsActive"
            :boundComponentPreventLosingFocus="tooltipPreventLosingFocus"
            class="vue-ci-tooltip"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltip from '../../gen/gen1010-information-tooltip/gen1010-information-tooltip';
import Frm1001InputField from '../frm1001-input-field/frm1001-input-field';

//=== MIXINS
import Component from '../../mixins/component';
import Tooltip from '../../mixins/tooltip';

//=== MISC
import config from '../../../config';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1015';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1015PhoneInput',
    components: {
        Gen1010InformationTooltip,
        Frm1001InputField
    },
    mixins: [Component, Tooltip],
    model: {
        prop: 'value',
        event: 'phoneNumberInputUpdateEvent'
    },
    props: {
        name: {
            type: String
        },
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        required: {
            default: false,
            type: Boolean
        },
        readonly: {
            default: false,
            type: Boolean
        },
        disabled: {
            default: false,
            type: Boolean
        },
        divider: String,
        label: String,
        value: {
            default: () => ({ prefix: '', number: '' }),
            type: Object
        },
        liveUpdate: {
            default: () => {
                return config.inputLiveUpdate;
            },
            type: Boolean
        },
        //=== PREFIX
        prefixState: {
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        prefixRequired: {
            default: false,
            type: Boolean
        },
        prefixReadonly: {
            default: false,
            type: Boolean
        },
        prefixDisabled: {
            default: false,
            type: Boolean
        },
        prefixLabel: String,
        prefixLabeledBy: String,
        prefixDescribedBy: String,
        prefixPlaceholder: String,
        prefixButtonClear: {
            default: () => {
                return config.globalInputButtonClear;
            },
            type: Boolean
        },
        //=== NUMBER
        numberState: {
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        numberRequired: {
            default: false,
            type: Boolean
        },
        numberReadonly: {
            default: false,
            type: Boolean
        },
        numberDisabled: {
            default: false,
            type: Boolean
        },
        numberLabel: String,
        numberLabeledBy: String,
        numberDescribedBy: String,
        numberPlaceholder: {
            default: null,
            type: String
        },
        //=== TOOLTIP
        focusOnTooltipOpen: {
            default: true,
            type: Boolean
        },
        tooltipPreventLosingFocus: {
            default: true,
            type: Boolean
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    data: function() {
        return {
            valueData: this.value,
            prefixIsActive: false,
            numberIsActive: false,
            prefixTooltipWhiteListInitial: [],
            numberTooltipWhiteListInitial: []
        };
    },
    computed: {
        classObject() {
            return [
                'vue-is-' + this.state,
                {
                    'vue-is-required': this.required,
                    'vue-is-readonly': this.readonly,
                    'vue-is-disabled': this.disabled,
                    'vue-has-divider': this.divider,
                    'vue-has-prefix-readonly': this.prefixReadonlyComputed,
                    'vue-has-prefix-disabled': this.prefixDisabledComputed,
                    'vue-has-number-readonly': this.numberReadonlyComputed,
                    'vue-has-number-disabled': this.numberDisabledComputed,
                    'vue-is-component-active': this.componentIsActive
                }
            ];
        },
        // TODO REVIEW: better naming, classObject is also computed
        classObjectComputed() {
            return [...this.classObject, ...this.classObjectMixinTooltip];
        },
        //========= ID & ACCESSIBILITY ===============//
        //============================================//
        generateAutoId() {
            return !!this.label || this.tooltipHasContent;
        },
        valueComputed: {
            get() {
                return this.valueData;
            },
            set(value) {
                this.valueData = value;
                if (this.liveUpdate) {
                    this.$emit('phoneNumberInputUpdateEvent', value);
                }
            }
        },
        componentIsActive() {
            // FIXME MBU: between focus / blur cycle between prefix / number is componentIsActive set to true > false > true, use timeOut?
            return this.prefixIsActive || this.numberIsActive;
        },
        //=== PREFIX
        prefixName() {
            return this.name ? this.name + 'prefix' : null;
        },
        prefixStateComputed() {
            return this.prefixState ? this.prefixState : this.state;
        },
        prefixRequiredComputed() {
            return this.prefixRequired ? this.prefixRequired : this.required;
        },
        prefixReadonlyComputed() {
            return this.prefixReadonly && !this.readonly ? this.prefixReadonly : this.readonly;
        },
        prefixDisabledComputed() {
            return this.prefixDisabled && !this.disabled ? this.prefixDisabled : this.disabled;
        },
        prefixIdComputed() {
            return this.idComputed ? this.idComputed + this.ID_EXTENSIONS.PREFIX : null;
        },
        prefixLabeledByComputed() {
            if (this.label && this.prefixLabel && !this.prefixLabeledBy) {
                return (
                    this.idComputed + this.ID_EXTENSIONS.LABEL + ' ' + this.prefixIdComputed + this.ID_EXTENSIONS.LABEL
                );
            }

            return this.prefixLabeledBy;
        },
        prefixDescribedByComputed() {
            if (!this.prefixDescribedBy && this.tooltipHasContent) {
                return this.idComputed + this.ID_EXTENSIONS.TOOLTIP_CONTENT;
            }

            return this.prefixDescribedBy;
        },
        //=== NUMBER
        numberName() {
            return this.name ? this.name + 'prefix' : null;
        },
        numberStateComputed() {
            return this.numberState ? this.prefixState : this.state;
        },
        numberRequiredComputed() {
            return this.numberRequired ? this.numberRequired : this.required;
        },
        numberReadonlyComputed() {
            return this.numberReadonly && !this.readonly ? this.numberReadonly : this.readonly;
        },
        numberDisabledComputed() {
            return this.numberDisabled && !this.disabled ? this.numberDisabled : this.disabled;
        },
        numberIdComputed() {
            return this.idComputed ? this.idComputed + this.ID_EXTENSIONS.NUMBER : null;
        },
        numberLabeledByComputed() {
            if (this.label && this.numberLabel && !this.numberLabeledBy) {
                return (
                    this.idComputed + this.ID_EXTENSIONS.LABEL + ' ' + this.numberIdComputed + this.ID_EXTENSIONS.LABEL
                );
            }

            return this.numberLabeledBy;
        },
        numberDescribedByComputed() {
            if (!this.numberDescribedBy && this.tooltipHasContent) {
                return this.idComputed + this.ID_EXTENSIONS.TOOLTIP_CONTENT;
            }

            return this.numberDescribedBy;
        }
    },
    watch: {
        value(value) {
            this.valueData = value;
        },
        tooltipExpandedData() {
            if (this.focusOnTooltipOpen && !this.componentIsActive && this.tooltipExpandedData) {
                this.$refs.prefix.$refs.input.focus();
            }
        },
        prefixTooltipWhiteListInitial() {
            this.setTooltipWhiteListInitial();
        },
        numberTooltipWhiteListInitial() {
            this.setTooltipWhiteListInitial();
        }
    },
    mounted() {
        this.tooltipExpandedData = this.tooltipExpanded;
        if (this.tooltipWhiteListInitialInit) {
            this.setTooltipWhiteListInitial();
        }
    },
    methods: {
        //=== GENERAL
        prefixIsActiveSet(value) {
            this.prefixIsActive = value;
        },
        numberIsActiveSet(value) {
            this.numberIsActive = value;
        },
        prefixTooltipWhiteListInitialSet(value) {
            this.prefixTooltipWhiteListInitial = value;
        },
        numberTooltipWhiteListInitialSet(value) {
            this.numberTooltipWhiteListInitial = value;
        },
        //=== PREFIX
        prefixClick(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('prefixClickEvent', value, event);
        },
        prefixKeyDown(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('prefixKeyDownEvent', value, event);
        },
        prefixKeyUp(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('prefixKeyUpEvent', value, event);
        },
        prefixChange(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('prefixChangeEvent', value, event);

            let numberInputValue = {
                prefix: value,
                number: this.valueData.number
            };

            if (!this.liveUpdate) {
                this.$emit('phoneNumberInputUpdateEvent', numberInputValue);
            }
        },
        prefixInput(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('prefixInputEvent', value, event);
        },
        prefixFocus(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('prefixFocusEvent', value, event);
            // TODO REVIEW: improve readability, extract into several named statements
            if (
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
        },
        prefixBlur(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('prefixBlurEvent', value, event);
        },
        //=== NUMBER
        numberClick(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('numberClickEvent', value, event);
        },
        numberKeyDown(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('numberKeyDownEvent', value, event);
        },
        numberKeyUp(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('numberKeyUpEvent', value, event);
        },
        numberChange(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('numberChangeEvent', value, event);

            let numberInputValue = {
                prefix: this.valueData.prefix,
                number: value
            };

            if (!this.liveUpdate) {
                this.$emit('phoneNumberInputUpdateEvent', numberInputValue);
            }
        },
        numberInput(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('numberInputEvent', value, event);
        },
        numberFocus(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('numberFocusEvent', value, event);
            // TODO REVIEW: improve readability, extract into several named statements
            if (
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
        },
        numberBlur(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('numberBlurEvent', value, event);
        },
        //=== TOOLTIP
        setTooltipWhiteListInitial() {
            // always reset it
            this.tooltipWhiteListInitial = [];

            let whiteList = [];

            //=== PREFIX
            if (this.prefixTooltipWhiteListInitial.length > 0) {
                for (let i = 0; i < this.prefixTooltipWhiteListInitial.length; i++) {
                    let element = this.prefixTooltipWhiteListInitial[i];
                    whiteList.push(element);
                }
            }

            //=== NUMBER
            if (this.numberTooltipWhiteListInitial.length > 0) {
                for (let i = 0; i < this.numberTooltipWhiteListInitial.length; i++) {
                    let element = this.numberTooltipWhiteListInitial[i];
                    whiteList.push(element);
                }
            }

            this.tooltipWhiteListInitial = whiteList;
        }
    }
};
</script>
