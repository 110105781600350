export default [
  {
    id: 1,
    title: 'LinkedIn',
    iconType: 'LinkedIn',
    txtCol: 'text-esg-linkedin',
    bgCol: 'bg-esg-linkedin',
    icon: `<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.6725 3H4.3275C3.6 3 3 3.5775 3 4.29V19.71C3 20.4225 3.6 21 4.3275 21H19.6725C20.4075 21 21 20.4225 21 19.71V4.29C21 3.5775 20.4 3 19.6725 3ZM8.4525 18.075H5.7375V9.93H8.4525V18.075ZM7.1025 8.82H7.08C6.165 8.82 5.58 8.1975 5.58 7.4175C5.58 6.6225 6.18 6.015 7.1175 6.015C8.0475 6.015 8.6175 6.615 8.6325 7.4175C8.6325 8.1975 8.0475 8.82 7.095 8.82H7.1025ZM18.255 18.075H15.5325V13.725C15.5325 12.6375 15.1425 11.8875 14.16 11.8875C13.41 11.8875 12.96 12.39 12.7575 12.8775C12.6825 13.05 12.675 13.29 12.675 13.5375V18.075H9.96C9.96 18.075 9.9975 10.71 9.96 9.945H12.6825V11.1C12.9283 10.6735 13.2859 10.3222 13.7169 10.0843C14.1478 9.84636 14.6356 9.73076 15.1275 9.75C16.92 9.75 18.2625 10.92 18.2625 13.4175V18.075H18.255Z" fill="currentColor"/>
</svg>`,
  },
  {
    id: 2,
    title: 'Twitter',
    iconType: 'Twitter',
    txtCol: 'text-esg-twitter',
    bgCol: 'bg-esg-twitter',
    icon: `<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 6.2775C20.3275 6.58533 19.6116 6.7877 18.8775 6.8775C19.6562 6.3975 20.2345 5.65137 20.505 4.7775C19.7925 5.2125 19.005 5.5275 18.1575 5.6925C17.8155 5.31645 17.3986 5.0161 16.9336 4.81073C16.4686 4.60536 15.9658 4.49952 15.4575 4.5C14.892 4.50578 14.3352 4.63964 13.8289 4.89151C13.3226 5.14338 12.8799 5.50672 12.5342 5.95424C12.1884 6.40175 11.9486 6.92179 11.8327 7.4753C11.7168 8.0288 11.7278 8.60138 11.865 9.15C10.3875 9.06934 8.94415 8.67438 7.63141 7.9915C6.31867 7.30862 5.16673 6.35353 4.2525 5.19C3.77557 6.02436 3.62884 7.00723 3.84129 7.94451C4.05374 8.88179 4.60992 9.70533 5.4 10.2525C4.81173 10.2311 4.2376 10.0663 3.7275 9.7725V9.825C3.7275 11.655 5.0025 13.185 6.69 13.5375C6.14716 13.6863 5.57715 13.7069 5.025 13.5975C5.4975 15.105 6.8625 16.2 8.475 16.23C7.71488 16.8466 6.83836 17.3039 5.89772 17.5745C4.95709 17.8451 3.97159 17.9235 3 17.805C4.68036 18.9132 6.6496 19.5027 8.6625 19.5C15.4575 19.5 19.1625 13.725 19.1625 8.7225V8.235C19.8825 7.7025 20.505 7.035 21 6.2775V6.2775Z" fill="currentColor"/>
</svg>`,
  },
  {
    id: 3,
    title: 'Facebook',
    iconType: 'Facebook',
    txtCol: 'text-esg-facebook',
    bgCol: 'bg-esg-facebook',
    icon: `<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 12.0525C21 7.0575 16.9725 3 12 3C7.0275 3 3 7.05 3 12.0525C3 16.575 6.2925 20.325 10.5975 21V14.67H8.31V12.0525H10.59V10.0575C10.59 7.7925 11.94 6.5325 13.995 6.5325C14.9775 6.5325 16.005 6.7125 16.005 6.7125V8.94H14.88C13.755 8.94 13.41 9.6375 13.41 10.3575V12.0525H15.9L15.5025 14.6775H13.4025V21C17.715 20.325 21 16.575 21 12.0525Z" fill="currentColor"/>
</svg>`,
  },
  {
    id: 4,
    title: 'YouTube',
    iconType: 'YouTube',
    txtCol: 'text-esg-youtube',
    bgCol: 'bg-esg-youtube',
    icon: `<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.03 5.62493C12.4725 5.62493 17.685 5.63993 19.035 5.99993C19.4182 6.10555 19.7669 6.31016 20.046 6.59321C20.3251 6.87626 20.5248 7.22777 20.625 7.61243C21 9.03743 21 11.9999 21 11.9999V12.0299C21 12.3524 20.9775 15.0524 20.625 16.3799C20.5259 16.766 20.3268 17.119 20.0476 17.4035C19.7684 17.6879 19.4191 17.8937 19.035 17.9999C17.715 18.3599 12.6975 18.3749 12.06 18.3824H11.9325C11.295 18.3824 6.2775 18.3599 4.965 18.0074C4.57996 17.9005 4.23006 17.6937 3.95081 17.4078C3.67156 17.122 3.4729 16.7674 3.375 16.3799C3.06 15.1724 3.0075 12.8549 3 12.1799V11.8049C3.0075 11.1299 3.06 8.81243 3.375 7.60493C3.47638 7.22165 3.67658 6.87169 3.9556 6.59004C4.23462 6.30838 4.58269 6.1049 4.965 5.99993C6.315 5.63243 11.5275 5.61743 11.97 5.61743L12.03 5.62493ZM10.155 9.29993V14.6849L14.865 11.9999L10.155 9.30743V9.29993Z" fill="currentColor"/>
</svg>
`,
  },
]
