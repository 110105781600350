export default {
  install(Vue) {
    Vue.directive('transition-delay-enter', function(el, binding) {
      el.style.transitionDelay = binding.value + 'ms';
      if (binding.arg === 'once') {
        el.addEventListener(
          'transitionend',
          function resetTransition() {
            el.style.transition = 'none';
            el.removeEventListener('transitionend', resetTransition, false);
          },
          false
        );
      }
    });

    Vue.directive('transition-delay-leave', {
      unbind: function(el, binding) {
        if (binding.value === 'none') {
          el.style.transition = 'none';
        }
        el.style.transitionDelay = binding.value + 'ms';
      }
    });
  }
}