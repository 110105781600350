<template>
  <page-layout>
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <template v-slot:contentMain>
      <h1
        class="vue-survey-headline"
      >
        {{ surveyName }}
      </h1>

      <img
        class="vue-survey-logo"
        :src="surveyLogo"
        alt="Survey logo"
      >

      <div
        v-if="showRespondsCounter"
      >
        <gen1006-info-panel
          class="vue-survey-result-counter"
          type="neutral"
        >
          <div class="vue-caption">
            {{ $t('survey.surveyStatus') }}
          </div>
          <div class="vue-filled-percentage">
            &nbsp;{{ filledPercentage }}&nbsp;%
          </div>
        </gen1006-info-panel>
      </div>

      <template
        v-if="isSurveyLoaded"
      >
        <div
          class="vue-survey-article-content"
          v-html="surveyText"
        />
      </template>

      <frm1006-button
        v-if="hasFaqPage"
        class="vue-survey-button"
        type="link"
        @buttonClickEvent="goToFaqPage"
      >
        {{ $t('survey.faqPage') }}
      </frm1006-button>
    </template>

    <template v-slot:buttonFixed>
      <frm1006-button
        v-if="isSurveyLoaded && isSurveyActive && !surveyContent.surveyMaintenance.value && !hasFilledSurvey"
        class="vue-button-page-layout-fixed"
        type="primary"
        @buttonClickEvent="submitToSurvey"
      >
        {{ $t('survey.enter') }}
      </frm1006-button>
    </template>

    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1016-loading-indicator
        :active="!isSurveyLoaded"
        :overlay="true"
        :fullScreen="true"
      />
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import router from '@/router';
import surveyVuexModule from '@/store/modules/survey';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import dataLoader from '@/utils/data-loader';

export default {
  name: 'SurveyIntro',
  components: {
    PageLayout,
    Prj1002Navbar
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      surveyResultsCount: 0,
      surveyResultsCountLoaded: false,
      isSurveyLoaded: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      surveyName: '',
      surveyLogo: '',
      surveyText: '',
      surveyStartDateTime: '',
      surveyEndDateTime: '',
      surveyActive: false,
    }
  },

  computed: {
    ...mapState('general', [
      'applicationConfigLoaded',
      'surveyConfig'
    ]),
    ...mapState('survey', [
      'surveyLoaded',
      'surveyHasError',
      'surveyContent'
    ]),
    ...mapState('persistentStorage', [
      'surveyInfo'
    ]),

    surveyHasError() {
      return this.hasError || this.surveyHasError;
    },

    isSurveyActive() {
      let retValue = false;

      if (this.surveyStartDateTime !== '' && this.surveyEndDateTime !== '') {
        const dateNow = new Date();
        const surveyStartDate = new Date((this.surveyStartDateTime).replace(/\s/, 'T')).getTime();
        const surveyEndDate = new Date((this.surveyEndDateTime).replace(/\s/, 'T')).getTime();

        retValue = dateNow > surveyStartDate && dateNow < surveyEndDate;
      }

      return retValue;
    },

    hasSurveyEnded() {
      const dateNow = new Date();
      const surveyEndDate = new Date(this.surveyContent?.surveyEndDatetime?.value.replace(' ', 'T')).getTime();

      return dateNow > surveyEndDate;
    },

    isMaintenanceActive() {
      if (typeof this.surveyContent === 'undefined' || typeof this.surveyContent?.surveyMaintenance === 'undefined') {
        return false;
      }
      return this.surveyContent.surveyMaintenance.value;
    },

    showRespondsCounter() {
      return this.isSurveyLoaded && !this.isMaintenanceActive && this.surveyResultsCountLoaded && this.surveyActive || this.hasSurveyEnded || this.hasFilledSurvey;
    },

    showSurveyApplyButton() {
      return this.isSurveyLoaded && this.surveyActive && !this.surveyContent.surveyMaintenance.value && !this.hasFilledSurvey;
    },

    hasFaqPage() {
      return this.surveyConfig.SURVEY_FAQ_POST_ID?.value > 0;
    },

    filledPercentage() {
      let percentage = 0;
      if(this.surveyConfig?.SURVEY_RESPONDENTS_MAX && typeof this.surveyResultsCount === 'number') {
        percentage = Math.round((this.surveyResultsCount / this.surveyConfig.SURVEY_RESPONDENTS_MAX.value) * 1000) / 10;
        // never show more than 100%, even on error
        if(percentage > 99.6) {
          percentage = 99.6;
        }
      }
      return percentage;
    },

    hasFilledSurvey() {
      return this.surveyLoaded && (this.surveyConfig.SURVEY_ACTIVE_ID.value === this.surveyInfo.filledSurveyId) && this.surveyInfo.hasFilledSurvey;
    },

    activePagePart() {
      if (this.isMaintenanceActive) {
        return 'surveyMaintenanceContent'
      }

      if (this.surveyActive && this.hasFilledSurvey) {
        return 'surveyThankYouContent'
      }

      if (this.surveyActive && !this.hasFilledSurvey) {
        return 'surveyIntroDuringContent';
      }

      if (this.hasSurveyEnded) {
        return 'surveyEndedContent';
      }

      return 'surveyBeforeStartContent';
    },
  },

  watch: {
    applicationConfigLoaded(newVal) {
      if (newVal) {
        this.initializeSurvey();
      }
    },

    surveyConfig() {
      if(this.surveyConfig) {
        this.getSurveyResults();
      }
    },

    surveyContent(value) {
      if (typeof value !== 'undefined') {
        this.surveyName = value.surveyName.value
        this.surveyLogo = value.surveyLogoUrl.value
        this.surveyText = value[this.activePagePart].value
        this.surveyStartDateTime = value.surveyStartDatetime.value
        this.surveyEndDateTime = value.surveyEndDatetime.value
      }
    },

    isSurveyActive(value) {
      this.surveyActive = value;
    },

    activePagePart(value) {
      if (typeof this.surveyContent !== 'undefined') {
        this.surveyText = this.surveyContent[value].value;
      }
    }
  },

  beforeCreate() {
    // TODO: CSOBZ-626 - Refactor module lazy loading into router
    if (typeof this.$store.state.survey === 'undefined') {
      this.$store.registerModule('survey', surveyVuexModule);
    }
  },

  mounted() {
    if (this.applicationConfigLoaded) {
      this.initializeSurvey();
    }
  },

  methods: {
    initializeSurvey() {
      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.MAKE_SURVEY_CONFIG)
        .then(() => {
          this.makeSurveyData();
        });
    },

    getSurveyResults() {
      dataLoader.fetchSurveyResultsCount(this.surveyConfig?.SURVEY_ACTIVE_ID.value || 0)
        .then(data => {
          this.surveyResultsCount = data.count;
          this.surveyResultsCountLoaded = true;
        });
    },

    makeSurveyData() {
      const surveyConfigOptions = {
        surveyConfigItems: this.surveyConfig,
      }

      this.$store.dispatch(STORE_MODULES.SURVEY + '/' + ACTIONS_CONSTANTS.MAKE_SURVEY_CONTENT, surveyConfigOptions)
        .then(() => {
          this.isSurveyLoaded = true;
        });
    },

    submitToSurvey() {
      router.push({ name: 'survey-form' });
    },

    goToFaqPage() {
      router.push({
        name: 'news',
        params: {
          id: this.surveyConfig.SURVEY_FAQ_POST_ID.value,
          navbarButtonMode: 'history'
        }
      });
    },
  }
}
</script>
