<template>
  <div
    class="vue-c-my-actions"
    :class="classObject"
  >
    <h3
      v-if="filteredMyActions.length > 0"
      class="vue-my-actions-headline"
    >
      {{ returnHeadline }}
    </h3>

    <div
      v-if="filteredMyActions.length > 0"
      class="vue-b-my-actions"
    >
      <div
        v-for="(event, index) in filteredMyActions"
        :key="'my-event-number' + index"
        class="vue-my-actions-item"
        @click="goToEventDetail(event)"
      >
        <div class="vue-my-actions-date">
          <span
            class="vue-my-actions-month"
            v-html="$t('time.months.' + makeMonthNumber(event))"
          />
          <span
            v-if="isForvardino"
            class="vue-my-actions-day"
          >
            {{ event.date | convertDate('day') }}
          </span>
          <span
            v-else
            class="vue-my-actions-day"
          >
            {{ event.activityFrom | convertDate('day') }}
          </span>
        </div>

        <div class="vue-my-actions-name">
          {{ event.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import router from '@/router';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'Prj1023MyRegisteredActions',
  props: {
    eventsData: {
      type: [Object, Array],
      required: true,
    },
    registeredEvents: {
      type: Array,
      required: false,
    },
    // FIXME: CSOBZ-569 - remove property componentType and computed this.isForvardino
    componentType: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      filteredMyActions: []
    }
  },

  computed: {
    classObject() {
      return [
        `vue-my-actions-${this.componentType}`
      ]
    },

    returnHeadline() {
      return this.$i18n.t('myActions.' + this.componentType);
    },

    isForvardino() {
      return this.componentType === 'forvardino'
    }
  },

  mounted() {
    this.createMyActionsArray();
  },

  methods: {
    createMyActionsArray() {
      const data = this.eventsData;

      data.forEach(item => {
        (item.lessons).forEach(subItem => {
          if (subItem.isRegistered && subItem.id === item.registeredLessonId) {
            item.date = subItem.activityFrom;
          }
        });

        if (item.hasOwnProperty('date')) {
          this.filteredMyActions.push(item);
        }
      });
    },

    makeMonthNumber(event) {
      if (this.isForvardino) {
        return Vue.filter('convertDate')(event.date, 'month');
      } else {
        return Vue.filter('convertDate')(event.activityFrom, 'month');
      }
    },

    goToEventDetail(event) {
      if (this.isForvardino) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, event);
        router.push({ name: 'forvardino-detail', params: { id: event.id } });
      } else {
        router.push({ name: 'event-detail', params: { id: event.id } });
      }
    }
  }
}
</script>
