<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar
            route-name="challenges"
          />
        </template>

        <template v-slot:content>
          <div class="flex flex-col h-full justify-between">
            <!--========== PAGE BODY ==============================-->
            <!--===================================================-->
            <div
              v-if="challengeDetail"
              class="prj-article prj-article-extend-fest prj-promo-page"
            >
              <t-tag
                variant="sectionTitle"
                class="xs:mb-6 md:mb-8 xs:mt-6 md:mb-10"
              >
                {{ challengeDetail.title }}
                <t-tag
                  v-if="challengeDetail.subtitle"
                  variant="highlight"
                >
                  {{ challengeDetail.subtitle }}
                </t-tag>
              </t-tag>

              <p>{{ challengeDetail.description }}</p>
            </div>

            <div
              class="flex flex-col justify-center flex-1"
            >
              <!-- Days -->
              <div
                v-if="hasChallengeValues"
                class="relative flex flex-wrap justify-center mx-auto w-full px-0 md:px-20 gap-4 rounded-lg overflow-hidden cursor-pointer"
                @click="toggleValuesOverlay"
              >
                <div
                  v-if="!selectedValues.length"
                  class="relative w-full h-36 border-3 rounded-lg border-gray-200"
                ></div>

                <div
                  v-for="value in selectedValues"
                  v-else
                  :key="value.id"
                  class="relative w-2/5 flex flex-col justify-evenly items-center py-4 px-4 rounded-xl overflow-hidden cursor-pointer"
                  :class="[value.cssClass, {'h-40': challengeType.imagesAsBackground}]"
                >
                  <!-- Default icon with image -->
                  <template v-if="!challengeType.imagesAsBackground">
                    <div
                      class="flex items-center w-4/6 h-4/6 max-h-24 mb-4 pointer-events-none"
                      v-html="value.icon"
                    ></div>
                  </template>

                  <!-- Image as background -->
                  <template v-else>
                    <div
                      class="absolute top-0 left-0 w-full h-full flex items-center transition pointer-events-none"
                      v-html="value.icon"
                    ></div>
                    <span class="absolute z-20 bg-white rounded-2xl px-2 py-1 bottom-3 text-center text-sm font-semibold pointer-events-none">{{ value.name }}</span>
                  </template>
                </div>
              </div>

              <div
                v-if="additionalQuestion && question.length"
                class="flex flex-col justify-start px-2 pt-4"
              >
                <span class="text-sm mb-1">{{ additionalQuestion.question }}</span>
                <span class="text-left italic font-bold">„{{ question }}“</span>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:footer>
          <!--========== CONTROLS ===============================-->
          <!--===================================================-->
          <div class="prj-controls">
            <t-button
              class="w-full bg-fest-home"
              :disabled="sending"
              @click="sendChallenge()"
            >
              <template v-if="sending">
                <svg
                  class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#1C64F2"
                  />
                </svg>
              </template>
              {{ $t("fest.forms.buttons.buttonSend") }}
            </t-button>
          </div>
        </template>

        <template v-slot:fixed>
          <!--========== OVERLAYS ===============================-->
          <!--===================================================-->

          <!-- Value select -->
          <prj1009-modal :active="valuesOverlay">
            <template v-slot:content>
              <div class="relative">
                <!-- Tiles -->
                <div
                  v-if="challengeType.items"
                  class="grid gap-4 grid-cols-2 pb-20"
                >
                  <div
                    v-for="item in challengeType.items"
                    :key="item.id"
                    class="relative flex flex-col justify-evenly items-center py-4 px-2 rounded-xl overflow-hidden cursor-pointer"
                    :class="[
                      {'ring-4 ring-black ring-inset' : item.multipleSelected},
                      item.cssClass,
                      {'h-40': challengeType.imagesAsBackground}]"
                    @click="item.multipleSelected || selectedValues.length < 3 ? selectValue(item) : ''"
                  >
                    <!-- Default icon with image -->
                    <template v-if="!challengeType.imagesAsBackground">
                      <div
                        class="flex items-center w-4/6 h-4/6 max-h-24 mb-4 pointer-events-none"
                        v-html="item.icon"
                      ></div>
                    </template>

                    <!-- Image as background -->
                    <template v-else>
                      <div
                        class="absolute top-0 left-0 w-full h-full z-10 bg-fest-home opacity-0 pointer-events-none transition"
                        :class="{'opacity-60': item.multipleSelected}"
                      />
                      <div
                        class="absolute top-0 left-0 w-full h-full flex items-center transition pointer-events-none"
                        v-html="item.icon"
                      ></div>
                      <span class="absolute z-20 bg-white rounded-2xl px-2 py-1 bottom-3 text-center text-sm font-semibold pointer-events-none">{{ item.name }}</span>
                    </template>
                  </div>
                </div>

                <!-- Controls -->
                <div class="absolute right-0 bottom-4 left-0">
                  <t-button
                    class="w-full bg-fest-home"
                    :disabled="!isSelectedValuesBetweenAllowedCounts"
                    @click="toggleValuesOverlay"
                  >
                    {{ additionalQuestion ? $t("fest.forms.buttons.buttonNext") : $t("fest.forms.buttons.buttonConfirm") }}
                  </t-button>
                </div>
              </div>
            </template>
          </prj1009-modal>

          <!-- Additional question to selected values -->
          <prj1009-modal :active="questionOverlay">
            <template v-slot:title>
              {{ additionalQuestion.title }}
            </template>

            <template v-slot:content>
              <p class="mb-10">
                {{ additionalQuestion.question }}
              </p>

              <div class="relative w-full mb-8">
                <t-input
                  v-model="question"
                  class="w-full text-xl text-left"
                  type="text"
                />
              </div>
            </template>

            <template v-slot:controls>
              <t-button
                class="bg-fest-home"
                :disabled="question.length === 0"
                @click="closeQuestion"
              >
                {{ $t("fest.forms.buttons.buttonConfirm") }}
              </t-button>
            </template>
          </prj1009-modal>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapGetters, mapState } from 'vuex';
import utilsGeneral from '@/utils/fest/utils-general';

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import PageLayout from '@/templates/fest/page-layout';
import Prj1005NavigationBar from '@/components/fest/prj1005-navigation-bar/prj1005-navigation-bar';
import Prj1009Modal from '@/components/fest/prj1009-modal/prj1009-modal';
import apiCmsChallenges from '@/fest-api/cms-challenges';
import logger from '@/utils/logger';

import foodTypesVoteType from '@/assets/fest/vote/types/foodTypes';

import {FEST_CHALLENGES, FEST_USER} from '@/store/store-modules';
import {
  SET_CHALLENGE_INVOLVE,
  SET_USER_VOTED_FOOD,
  //SET_USER_VOTED_BANDS
} from '@/store/constants/fest/mutations';
import { GET_CHALLENGES_INFO } from '@/store/constants/fest/actions';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTVote',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    Prj1005NavigationBar,
    Prj1009Modal,
    PageLayout,
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      openedSelectingWeek: false,
      valuesOverlay: true,
      questionOverlay: false,
      challengeDetail: null,
      challengeType: null, // TODO make type assignment dynamic
      settingsValue: '1',
      selectedValues: [],
      question: '',
      sending: false,
      sendFailed: false
    }
  },
  computed: {
    ...mapState('festUser', {
      userName: state => state.name,
      userEmail: state => state.email
    }),
    ...mapGetters('festUser', ['isUserIdentityPartial', 'festivalVoted']),
    ...mapState('festChallenges', ['challengesInfo', 'challengesUserInvolved']),
    challengeId() {
      return parseInt(this.$route.params.voteId);
    },
    competitionId() {
      return this.challengeDetail?.competitionId;
    },
    competitionPageId() {
      return this.challengeDetail?.competitionPageId;
    },
    challengeExtendedSettings() {
      return this.challengeType.settingsExtended;
    },
    challengeRunningPage() {
      return this.challengeDetail?.pages
    },
    challengeRunningSubpages() {
      return this.challengeRunningPage?.subPages
    },
    challengeTitle() {
      return this.challengeRunningPage?.post_title || this.challengeDetail.title
    },
    getMinAllowedCount() {
      return this.challengeType.minAllowedCount || 1;
    },
    getMaxAllowedCount() {
      return this.challengeType.maxAllowedCount || 1;
    },
    isSelectedValuesBetweenAllowedCounts() {
      return this.selectedValues.length >= this.getMinAllowedCount && this.selectedValues.length <= this.getMaxAllowedCount;
    },
    getSettingsValue() {
      return this.settingsValue
    },
    hasChallengeValues() {
      return this.challengeType.items.length;
    },
    dateDay() {
      return new Date
    },
    isUserInvolved() {
      return this.challengesUserInvolved.some(item => item.challenge === this.challengeId)
    },
    additionalQuestion() {
      return this.challengeType.itemsAdditionalQuestion
    },
    hasUserVoted() {
      return this.festivalVoted.food; // Set manually for current vote
    }
  },
  async created() {
    if (!this.challengesInfo.length) await this.$store.dispatch(`${FEST_CHALLENGES}/${GET_CHALLENGES_INFO}`)
    this.getChallengeById()
  },
  mounted() {
    this.setMultipleItems();
  },
  methods: {
    setMultipleItems() {
      this.challengeType = foodTypesVoteType;

      for (const item of this.challengeType.items) {
        item.multipleSelected = false
      }
    },
    getChallengeById() {
      this.challengeDetail = this.challengesInfo.find((challenge) => challenge.id === this.challengeId)
    },
    sendChallenge() {
      if (this.hasUserVoted) {
        return;
      }

      let processId = utilsGeneral.startAsyncProcess();
      this.sendFailed = false;
      this.sending = true;

      // Values from array to string
      const selectedValuesFlatted = this.selectedValues.flatMap(value => value.id);
      const selectedValuesIds = selectedValuesFlatted.toString();

      const postData = {
        challengeId: this.challengeId,
        challengeValue: this.question,
        challengeValueTypeId: selectedValuesIds,
        competitionId: this.competitionId,
        day: this.dateDay,
        themeId: null, // temp, get data from api
        categoryId: null, // temp, get data from api
      }

      apiCmsChallenges
        .sendChallenge(postData)
        .then((result) => {
          logger.info('result: ', result);
          logger.info('Form has been sent.');

          //this.$store.commit(FEST_USER + '/' + SET_USER_VOTED_BANDS, selectedValuesIds);
          this.$store.commit(FEST_USER + '/' + SET_USER_VOTED_FOOD, selectedValuesIds);

          this.sending = false;
          utilsGeneral.stopAsyncProcess(processId);
          this.setIsUserInvolved();

          this.$router.push({
            name: 'festival-vote-success',
            params: {
              challengeId: this.challengeId,
              competitionId: this.competitionId,
            }
          });
        })
        .catch(error => {
          this.sending = false;
          this.sendFailed = true;
          utilsGeneral.stopAsyncProcess(processId);
          logger.error('Sending form has failed.', error.response);
        })
    },
    setIsUserInvolved() {
      if (!this.isUserInvolved) {
        this.$store.commit(`${FEST_CHALLENGES}/${SET_CHALLENGE_INVOLVE}`, { challenge: this.challengeId, linkedCompetition: this.competitionId });
      }
    },
    selectValue(item) {
      item.multipleSelected = !item.multipleSelected; // toggle choice

      for (const [i, value] of this.selectedValues.entries()) {
        if (value.id === item.id) {
          this.selectedValues.splice(i, 1);
          return;
        }
      }

      this.selectedValues.push(item);
    },
    toggleValuesOverlay() {
      this.valuesOverlay = !this.valuesOverlay;

      if (this.additionalQuestion && !this.valuesOverlay) {
        this.questionOverlay = true
      }
    },
    closeQuestion() {
      this.questionOverlay = false
    },
    showValues() {
      this.toggleValuesOverlay();
    }
  }
}
</script>
