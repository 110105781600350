const evaluate = (value, { min, max } = {}) => {
    if (Array.isArray(value)) {
        return value.every(val => evaluate(val, { min, max }));
    }

    return Number(min) <= value && Number(max) >= value;
};

const paramNames = ['min', 'max'];

export { evaluate, paramNames };

export default {
    evaluate,
    paramNames
};
