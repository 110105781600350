export const DATA_ARRAY = Object.freeze({
  strategyGroup: [
    {
      'cs': {
        'title': 'Strategie skupiny KBC',
        'subTitle': 'Differently the Next Level – připomeňte si strategii celé KBC',
        'items': {
          1: {
            'value': 'Bankopojištění+',
            'description': 'Rozšiřujeme naši nabídku také o nefinanční řešení, která ale úzce souvisí s těmi finančními.',
          },
          2: {
            'value': 'Naše práce je založená na datech',
            'description': 'Data a jejich analýza jsou u nás středobodem vývoje produktů, komunikace s klienty a obchodních rozhodnutí.',
          },
          3: {
            'value': 'Zákaznická zkušenost',
            'description': 'Při každé interakci s klienty se snažíme splnit nebo překonat jejich očekávání.',
          },
          4: {
            'value': 'Provozní efektivita',
            'description': 'Zlepšujeme zákaznickou zkušenost tím, že nabízíme jednoduché produkty okamžitě a bez zbytečných komplikací.',
          },
          5: {
            'value': 'Na prvním místě jsme digitální',
            'description': 'Digitální komunikace s klienty tvoří základ našeho obchodního modelu.',
          },
          6: {
            'value': 'Pearl+',
            'description': 'Smartcopy, sdílení zkušeností v rámci celé skupiny KBC, nám pomáhá rychle a efektivně implementovat opakovatelná řešení na místní úrovni. To je Team Blue v akci.',
          },
        }
      },
      'en': {
        'title': 'Strategy KBC Group',
        'subTitle': 'Differently the Next Level – reminder of KBC Group strategy',
        'items': {
          1: {
            'value': 'Bank insurance+',
            'description': 'We are broadening our offer to clients by also distributing non-financial – but closely connected – solutions',
          },
          2: {
            'value': 'Data driven organisation',
            'description': 'We are making data & analytics the centre of solution development, client interactions & business decisions',
          },
          3: {
            'value': 'Customer experience',
            'description': 'We are trying to meet or exceed clients’ expectations during every interaction.',
          },
          4: {
            'value': 'Operational efficiency',
            'description': 'We are boosting customer experience by delivering simple products in an instant and no-frills manner.',
          },
          5: {
            'value': 'Digital first',
            'description': 'Digital interactions with clients will form the basis of our business model',
          },
          6: {
            'value': 'Pearl+',
            'description': 'Smartcopy, sharing the best across the KBC Group, will help us to implement repeatable solutions at the local level quickly and efficiently. This is Team Blue in action!',
          },
        },
      }
    }
  ]
});
