<script>
export default {
  data() {
    return {
      errorMessage: {
        header: '',
        text: '',
        ticketId: '',
        expanded: false
      }
    }
  },
  computed: {
    errorClassObject() {
      return {
        'vue-has-error-message': this.errorMessage.text.length > 0,
        'vue-is-message-expanded': this.errorMessage.expanded
      }
    },

    buttonClassObject() {
      return {
        'vue-is-expanded': this.errorMessage.expanded
      }
    },
  },
  methods: {
    setErrorMessage(errorData) {
      this.errorMessage.header = this.$t('error.serverDetail')[errorData?.errorCode] ;
      this.errorMessage.text = errorData?.errorMessage;
      this.errorMessage.ticketId = errorData?.ticketId;
    },

    showMessageDetail() {
      this.errorMessage.expanded = !this.errorMessage.expanded;
    },
  }
}
</script>
