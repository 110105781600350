<template>
  <div class="vue-popup-onboarding">
    <frm1035-form-group>
      <!-- Date interval: last N days-->
      <frm1028-advanced-combo
        v-model="interval"
        class="vue-popup-benefits-city-selector"
        :options="intervalOptions"
        :placeholder="$i18n.t('onboarding.chooseInterval')"
        :tooltip="false"
        :label="$i18n.t('onboarding.date')"
        @change="selectInterval"
      />
    </frm1035-form-group>

    <!-- Date interval: selected days-->
    <template v-if="isIntervalSelectionVisible">
      <frm1035-form-group>
        <frm1001-input-field
          v-model="localInterval"
          :options="null"
          :label="$i18n.t('onboarding.chooseInterval')"
          :tooltip="false"
          :disabled="true"
        />
        <frm1006-button
          :type="'internal'"
          :icon-left="true"
          class="vue-is-calendar"
          @buttonClickEvent="toggleCalendar()"
        />
      </frm1035-form-group>
      <v-date-picker
        v-if="isCalendarVisible"
        v-model="range"
        is-range
        :model-config="modelConfig"
        class="vue-date-picker"
        locale="cs-CZ"
      />
    </template>


    <div
      class="vue-bottom-content"
    >
      <frm1006-button
        :disabled="untouchedFilter"
        @buttonClickEvent="filterCandidates"
      >
        {{ $t('advancedFilter.filterList') }}
      </frm1006-button>
    </div>

    <gen1006-info-panel
      v-if="hasError"
      type="error"
    >
      {{ $t('error.generic') }}
    </gen1006-info-panel>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import { ONBOARDING_INTERVAL_DURATIONS } from 'ext-authorized-mode-constants';


export default {
  name: 'Prj1011PopupOnboardingExternalFilter',
  data() {
    return {
      hasError: false,
      selectedInterval: {
        startDate: '',
        endDate: '',
      },
      interval: '',
      isCalendarVisible: false,
      range: {
        start: new Date(),
        end: new Date(),
      },
      localRange: {
        start: '',
        end: '',
      },
      modelConfig: {
        start: {
          type: 'string',
          mask: 'YYYY-MM-DD',
        },
        end: {
          type: 'string',
          mask: 'YYYY-MM-DD',
        },
      },
    }
  },

  computed: {
    ...mapState('onboarding', [
      'onboardingFilteredInterval',
    ]),

    intervalOptions() {
      let intervals = [{
        value: '-1',
        caption: this.$i18n.t('onboarding.selectedInterval')
      }];

      ONBOARDING_INTERVAL_DURATIONS.forEach(item => {
        const intervalObject = {
          value: item.toString(),
          caption: this.$i18n.t('onboarding.lastDays', { duration: item.toString()})
        }

        intervals.push(intervalObject);
      });

      return intervals;
    },

    untouchedFilter() {
      return this.interval === ''
    },

    localInterval() {
      const localRangeStart = this.ISOToLocaleDate(this.range.start)
      const localRangeEnd = this.ISOToLocaleDate(this.range.end)
      return localRangeStart + ' - ' + localRangeEnd
    },

    isIntervalSelectionVisible() {
      return parseInt(this.interval) < 0
    }
  },

  watch: {
    range: {
      deep: true,
      handler() {
        this.selectedInterval.startDate = this.range.start;
        this.selectedInterval.endDate = this.range.end;
      }
    }
  },

  mounted() {
    this.range.start = this.calculateDateBefore(90);
    this.range.end = this.calculateDateBefore(0);
  },

  methods: {
    filterCandidates() {
      this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_FILTERED_INTERVAL, this.selectedInterval);
      this.$store.commit(STORE_MODULES.POPUP + '/' + MUTATIONS_CONSTANTS.TOGGLE_POPUP, false);
    },

    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible
    },

    ISOToLocaleDate(dateString) {
      if (!dateString) return ''
      const date = new Date(dateString);
      if (date && !isNaN(date)) {
        return date.getDate() + '. ' + (date.getMonth() + 1) + '. ' + date.getFullYear();
      }
      return '';
    },

    calculateDateBefore(daysBefore) {
      let today = new Date();
      today.setTime(today.getTime() - daysBefore * 24 * 60 * 60 * 1000);
      return today.toISOString().substr(0,10);
    },

    selectInterval(value) {
      const interval = parseInt(value);
      if (interval > 0) {
        this.selectedInterval.startDate = this.calculateDateBefore(interval);
        this.selectedInterval.endDate = this.calculateDateBefore(0);
      } else {
        this.selectedInterval.startDate = this.range.start;
        this.selectedInterval.endDate = this.range.end;
      }
    }
  }
}
</script>
