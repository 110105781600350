const convertStock = (stockCode) => {
  let slicedStockCode = stockCode.slice(4);

  if (stockCode === 'SHQ-1PP') {
    return '0';
  }

  if (slicedStockCode === '0PP' || slicedStockCode === '1PP') {
    return '-' + slicedStockCode.substring(0, 1);
  } else {
    return slicedStockCode.substring(0, 1);
  }
}

export default convertStock;
