import Frm1008SplitButton from './frm1008-split-button';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1008SplitButton);
    }
};

use(Plugin);

export default Plugin;

export { Frm1008SplitButton };
