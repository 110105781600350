<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-surf-studio-trendbook">
        <div class="vue-dashboard-header">
          <img
            class="vue-logo"
            src="/img/surf-studio/KBClogo.jpg"
          >
          <p
            class="vue-title"
          >
            {{ $t('surfStudio.trendBook.header') }}
          </p>
          <gen1006-info-panel
            v-if="!isPrivateApp"
            type="neutral"
          >
            <p
              v-html="$i18n.t('surfStudio.privateMessage', { pageId: installationPageId})"
            />
          </gen1006-info-panel>
        </div>
      </div>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
      <frm1006-button
        class="vue-button-page-layout-fixed"
        mode="anchor"
        :link="trendBookLink"
        :linkNewWindow="true"
        :disabled="!isPrivateApp"
      >
        {{ $t('surfStudio.trendBook.button') }}
      </frm1006-button>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import {
  SURF_STUDIO_DASHBOARD_ID,
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';

import mxNavigationDefault from '@/mixins/mx-navigation-default';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import router from '@/router';

export default {
  name: 'SurfStudioTrendBook',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT,
      trendBookLink: ''
    };
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),

    navbarCaption() {
      return this.$route.meta.navbarCaption;
    },

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },

    installationPageId() {
      let pageId = -1;

      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'HELP_INSTALLATION_PAGE_ID') {
            pageId = parseInt(item.value);
          }
        });
      }

      return pageId;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.getSurfStudioDashboardCategories().then(() => {
      this.initialized = true;
    });
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getSurfStudioDashboardCategories() {
      this.setLoadingState(true);

      return dataLoader.fetchApiDataList(SURF_STUDIO_DASHBOARD_ID)
        .then(data => {
          this.trendBookLink = data.surfStudioTrendBookLink;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    goToView(item) {
      if ( item.name ) {
        router.push({ name: item.name, params: {isSurfStudio: true} })
      }
    },
  },
}
</script>
