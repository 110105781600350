<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-knowledge-base-dashboard">
        <div>
          <prj1017-heading
            :header="$t('knowledgeBase.intro')"
          />
          <gen1006-info-panel
            type="neutral"
          >
            <p v-html="$i18n.t('knowledgeBase.info')" />
          </gen1006-info-panel>
        </div>
        <div class="vue-dashboard-tiles">
          <frm1006-button
            class="vue-dashboard-item-tile vue-tile-it"
            type="tile"
            @buttonClickEvent="goToView('it')"
          >
            <template v-slot:iconLeft>
              <div
                class="vue-tile-icon"
              />
            </template>
            {{ $t('knowledgeBase.categories.it.name') }}
          </frm1006-button>
          <frm1006-button
            class="vue-dashboard-item-tile vue-tile-facility-management"
            type="tile"
            @buttonClickEvent="goToView('fam')"
          >
            <template v-slot:iconLeft>
              <div
                class="vue-tile-icon"
              />
            </template>
            {{ $t('knowledgeBase.categories.fam.name') }}
          </frm1006-button>
          <frm1006-button
            class="vue-dashboard-item-tile vue-tile-hr"
            type="tile"
            @buttonClickEvent="goToView('hr')"
          >
            <template v-slot:iconLeft>
              <div
                class="vue-tile-icon"
              />
            </template>
            {{ $t('knowledgeBase.categories.hr.name') }}
          </frm1006-button>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import router from '@/router';

export default {
  name: 'KnowledgeBaseDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      navbarCaptionData: null,
    };
  },

  computed: {
    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },
  },

  methods: {
    goToView(categoryId) {
      router.push({
        name: 'knowledge-base-category',
        params: { categoryId }
      })
    },
  },
}
</script>

