import { render, staticRenderFns } from "./prj1012-saving-panel.vue?vue&type=template&id=43ffb423&"
import script from "./prj1012-saving-panel.vue?vue&type=script&lang=js&"
export * from "./prj1012-saving-panel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports