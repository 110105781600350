<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1040-stepper
        v-if="!completedForm && formIsLogin"
        :stepsCount="2"
        :activeStep="formStep"
      />

      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="navigateBack"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <!--======= FORM STEP 1 =====================-->
      <template
        v-if="formStep === 1 && !completedForm"
      >
        <prj1017-heading
          :header="formIsLogin ? $t('parents.forms.headingPersonalData') : $t('parents.forms.headingLogout')"
        />

        <!--======= SWITCH LOGIN / LOGOUT ===========-->
        <frm1035-form-group>
          <frm1025-option-group
            :name="'form-type'"
            :multiselect="false"
            :options="optionsType"
            :value="formType"
            :tooltip="false"
            class="vue-is-form-type-select"
            @change="optionGroupChangeEvent"
          />
        </frm1035-form-group>

        <!--======= LOGIN / LOGOUT INPUTS ===========-->
        <frm1035-form-group>
          <frm1001-input-field
            v-model="userName"
            :label="$t('general.namePlaceholder')"
            :tooltip="false"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            v-model="userPersonalNumber"
            :label="$t('general.personalNumberPlaceholder')"
            :tooltip="false"
          />
        </frm1035-form-group>

        <!--======= LOGIN INPUTS ====================-->
        <template v-if="formIsLogin">
          <frm1035-form-group>
            <frm1001-input-field
              v-model="userPhone"
              :label="$t('general.phoneOnlyPlaceholder')"
              :tooltip="false"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1001-input-field
              v-model="userEmail"
              :label="$t('parents.forms.labels.emailPlaceholder')"
              :tooltip="false"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1003-checkbox
              v-model="userPrivacyPolicy"
              :tooltip="false"
            >
              {{ $t('parents.forms.consents.personalData') }}

              <a
                class="prj-text-link cursor-pointer"
                :href="$t('parents.forms.consents.personalDataLinkUrl')"
                target="_blank"
              >{{ $t("parents.forms.consents.personalDataLink") }}</a>
              {{ $t('parents.forms.consents.personalDataEnd') }}
            </frm1003-checkbox>
          </frm1035-form-group>
        </template>
      </template>

      <!--======= FORM STEP 2 =====================-->
      <template
        v-if="formStep === 2 && !completedForm"
      >
        <prj1017-heading
          :header="$t('parents.forms.headingWork')"
        />

        <frm1035-form-group>
          <prj1045-file-upload
            :label="$t('parents.forms.placeholderFileUpload')"
            :description="$t('parents.forms.uploadFileCondition')"
            @uploadedFile="processedFile"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1013-textarea
            v-model="userWorkSpecialization"
            class="vue-parents-sending-textarea"
            :tooltip="false"
            :label="$t('parents.forms.placeholderWorkSpecialization')"
            :rowsAutoExpand="true"
            :rows="1"
            :rowsAutoExpandMinRows="1"
            :rowsAutoExpandMaxRows="5"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1013-textarea
            v-model="userWorkPlace"
            class="vue-parents-sending-textarea"
            :tooltip="false"
            :label="$t('parents.forms.placeholderWorkPlace')"
            :rowsAutoExpand="true"
            :rows="1"
            :rowsAutoExpandMinRows="1"
            :rowsAutoExpandMaxRows="5"
          />
        </frm1035-form-group>

        <div class="vue-parents-date">
          <div class="vue-parents-dates-label">
            <p>
              {{ $t('parents.forms.labels.preliminaryEstimateDate') }}
            </p>
          </div>

          <div class="vue-b-blocks">
            <div class="vue-parents-date-block">
              <frm1035-form-group>
                <frm1001-input-field
                  v-model="userWorkingDateIn"
                  :label="$t('parents.forms.placeholderDateIn')"
                  :tooltip="false"
                  :disabled="true"
                />

                <frm1006-button
                  :type="'internal'"
                  :icon-left="true"
                  class="vue-is-calendar"
                  @buttonClickEvent="openCalendar('in')"
                />
              </frm1035-form-group>
            </div>

            <div class="vue-parents-date-block">
              <frm1035-form-group>
                <frm1001-input-field
                  v-model="userWorkingDateOut"
                  :label="$t('parents.forms.placeholderDateOut')"
                  :tooltip="false"
                  :disabled="true"
                />

                <frm1006-button
                  :type="'internal'"
                  :icon-left="true"
                  class="vue-is-calendar"
                  @buttonClickEvent="openCalendar('out')"
                />
              </frm1035-form-group>
            </div>
          </div>
        </div>

        <frm1035-form-group>
          <frm1013-textarea
            id="idea-text"
            v-model="userAdditionalText"
            class="vue-parents-sending-textarea"
            :tooltip="false"
            :label="$t('parents.forms.placeholderAdditionalText')"
            :rowsAutoExpand="true"
            :rows="1"
            :rowsAutoExpandMinRows="1"
            :rowsAutoExpandMaxRows="5"
          />
        </frm1035-form-group>
      </template>

      <!--======= SUCCESS PAGE ====================-->
      <div
        v-if="completedForm"
        class="vue-b-form-success"
      >
        <prj1017-heading
          :header="$i18n.t('parents.forms.thankYou.headingPool')"
          :subheader="$i18n.t('parents.forms.thankYou.subHeadingPool')"
        />
      </div>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <!--======= LOGIN BUTTON ====================-->
      <template v-if="isLogin && !completedForm">
        <frm1006-button
          v-if="formStep === 1"
          class="vue-button-register"
          :disabled="!isContinueActive"
          @buttonClickEvent="nextStep"
        >
          {{ $t('general.continue') }}
        </frm1006-button>

        <frm1006-button
          v-if="formStep === 2"
          class="vue-button-register"
          :disabled="!isSubmitActive"
          @buttonClickEvent="submitForm"
        >
          {{ $t('parents.forms.buttonSubmit') }}
        </frm1006-button>
      </template>

      <!--======= LOGOUT BUTTON ===================-->
      <frm1006-button
        v-if="!isLogin && !completedForm"
        type="primary"
        :disabled="!isLogoutActive"
        @buttonClickEvent="submitForm"
      >
        {{ $t('parents.forms.buttonLogout') }}
      </frm1006-button>

      <!--======= BACK TO DASH ====================-->
      <frm1006-button
        v-if="completedForm"
        type="primary"
        @buttonClickEvent="goToDashboard"
      >
        {{ $t('parents.forms.thankYou.backToDash') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        ref="overlay"
        class="vue-is-date-picker"
        :active.sync="datePickerOpened"
        :buttonClose="true"
        :prevent-closing="'clickOutside'"
      >
        <v-date-picker
          v-model="datePickerDate"
          :min-date="datePickerMinDate"
          :update-on-input="false"
          is-expanded
          locale="cs-CZ"
        />
      </gen1009-overlay>

      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import {PARENTS_DASHBOARD_ID} from '@/constants/app-constants';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';

import utilsGeneral from '@/utils/utils-general';
import router from '@/router';

import Prj1002Navbar from '../../components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import Prj1040Stepper from '@/components/prj1040-stepper/prj1040-stepper';
import Frm1025OptionGroup from '../../../frameworks/vue/components/frm/frm1025-option-group/frm1025-option-group';
import Prj1045FileUpload from '@/components/prj1045-file-upload/prj1045-file-upload';

const POOL_FORM_TYPE_LOGIN = 'login';
const POOL_FORM_TYPE_LOGOUT = 'logout';

export default {
  name: 'ParentsPoolLogin',
  components: {
    Prj1045FileUpload,
    Frm1025OptionGroup,
    Prj1040Stepper,
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      formStep: 1,
      formIsLogin: null,
      formType: POOL_FORM_TYPE_LOGIN,
      completedForm: false,
      isLoading: false,
      hasError: false,
      userName: '',
      userEmail: '',
      userPhone: '',
      userPrivacyPolicy: false,
      userPersonalNumber: '',
      userWorkSpecialization: '',
      userWorkPlace: '',
      userAdditionalText: '',
      userWorkingDateIn: '',
      userWorkingDateInRawDate: '',
      userWorkingDateOut: '',
      userWorkingDateOutRawDate: '',
      userUploadedFile: null,
      parentsCategoryData: [],
      datePickerDate: false,
      datePickerDateUpdated: false,
      datePickerOpened: false,
      datePickerOpenedType: ''
    }
  },

  computed: {
    isLogin() {
      return this.formType === POOL_FORM_TYPE_LOGIN;
    },

    isContinueActive() {
      return !!(this.userName && this.userPersonalNumber && this.formEmailInvalidState(this.userEmail) && this.userPhone && this.userPrivacyPolicy);
    },

    isSubmitActive() {
      return this.userUploadedFile && this.userWorkSpecialization && this.userWorkPlace && this.userWorkingDateIn && this.userWorkingDateOut
    },

    isLogoutActive() {
      return !!(this.userName && this.userPersonalNumber);
    },

    optionsType() {
      return [
        {
          'id': 'option-type-login',
          'caption': this.$i18n.t('parents.login'),
          'value': POOL_FORM_TYPE_LOGIN,
          'state': 'info',
        },
        {
          'id': 'option-type-logout',
          'caption': this.$i18n.t('parents.logout'),
          'value': POOL_FORM_TYPE_LOGOUT,
          'state': 'info',
        }
      ]
    },

    datePickerMinDate() {
      return this.userWorkingDateInRawDate || new Date();
    }
  },

  watch: {
    formType() {
      this.formIsLogin = this.isLogin
    },

    datePickerDate(value) {
      if (value && this.datePickerOpened) {
        let tmpDate = new Date(value);
        let tmpDateFormatted = tmpDate.getDate() + '.' + (tmpDate.getMonth() + 1) + '.' + tmpDate.getFullYear();

        if (this.datePickerOpenedType === 'in') {
          this.userWorkingDateIn = tmpDateFormatted;
          this.userWorkingDateInRawDate = tmpDate;
        }

        else if (this.datePickerOpenedType === 'out') {
          this.userWorkingDateOut = tmpDateFormatted;
          this.userWorkingDateOutRawDate = tmpDate;
        }

        this.datePickerOpened = false;
      }
    },

    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.formIsLogin = this.isLogin;
    this.getParentsCategories();
    this.initialized = true;
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getParentsCategories() {
      this.isLoading = true;

      return dataLoader.fetchApiDataList(PARENTS_DASHBOARD_ID)
        .then(data => {
          this.parentsCategoryData = data.parents;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    formEmailInvalidState(value) {
      return utilsGeneral.isValidEmail(value);
    },

    formPhoneInvalidState(value) {
      return utilsGeneral.isValidPhoneNumber(value);
    },

    nextStep() {
      ++this.formStep
    },

    previousStep() {
      if (this.formStep > 1) {
        --this.formStep
      }
    },

    navigateBack() {
      if (this.formStep === 1 || this.completedForm) {
        router.push( { name: 'parents-dashboard' } );
      } else {
        this.previousStep();
      }
    },

    goToDashboard() {
      router.push( { name: 'parents-dashboard' } );
    },

    submitForm() {
      this.setLoadingState(true);

      if (this.isLogin) {
        let sendData = {
          userName: this.userName,
          userEmail: this.userEmail,
          userPhone: this.userPhone,
          userPersonalNumber: this.userPersonalNumber,
          userPrivacyPolicy: this.userPrivacyPolicy,
          userWorkSpecialization: this.userWorkSpecialization,
          userWorkPlace: this.userWorkPlace,
          userAdditionalText: this.userAdditionalText,
          userUploadedFile: this.userUploadedFile,
          userWorkingDateIn: this.userWorkingDateIn,
          userWorkingDateOut: this.userWorkingDateOut,
        }

        return dataLoader.postParentPool(
          sendData
        )
          .catch(error => {
            logger.error(error);
            this.hasError = true;
          })
          .finally(() => {
            this.setLoadingState(false);
            this.completedForm = true;
          });
      } else {
        let sendData = {
          userName: this.userName,
          userPersonalNumber: this.userPersonalNumber,
        }

        return dataLoader.deleteParentPool(
          sendData
        )
          .catch(error => {
            logger.error(error);
            this.hasError = true;
          })
          .finally(() => {
            this.setLoadingState(false);
            this.completedForm = true;
          });
      }
    },

    optionGroupChangeEvent(value) {
      this.formType = value;
    },

    openCalendar(type) {
      this.datePickerOpenedType = type;
      this.datePickerDate = this.datePickerOpenedType === 'in' ? this.userWorkingDateInRawDate : this.userWorkingDateOutRawDate; // TODO this solution shows date from selected input in opened datepicker. Maybe is better inputs replace for one input with date ranges

      this.$nextTick(() => {
        this.datePickerOpened = true;
      });
    },

    processedFile(file) {
      this.userUploadedFile = file.fileBinary
    }
  }
}
</script>
