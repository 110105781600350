<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    class="relative flex flex-col bg-white rounded-xl"
  >
    <!--========== CONTROL ===============================-->
    <!--==================================================-->
    <button
      class="flex py-4 px-3.5 bg-sky-100 rounded-xl"
      @click="onBtnClick"
    >
      <span
        class="text-base font-semibold mr-auto"
        v-html="title"
      ></span>
      <span class="ml-4">
        <svg
          class="h-6 w-6 transition ease-out transition-transform duration-300"
          :class="isCardExpanded ? 'rotate-180' : 'rotate-0'"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 9L12 16L5 9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </button>

    <!--========== CONTENT ===============================-->
    <!--===================================================-->
    <transition
      appear
      name="expand-box-content"
      @after-enter="onAfterEnter"
    >
      <div
        v-if="isCardExpanded"
        class="overflow-hidden transition-all opacity-1 max-h-px-920 duration-200"
      >
        <section
          class="pt-4"
        >
          <p
            v-if="description"
            v-html="description"
          ></p>
        </section>
      </div>
    </transition>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ EXPORT ==================================//
//======================================================//
export const OPEN_DEFAULT = 'open'

//============ IMPORT ==================================//
//======================================================//

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1025CollapsibleCard',
  components: {},
  props: {
    description: {
      type: String,
      default: null,
    },
    isCardExpanded: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      disabledActions: false,
    }
  },
  computed: {},
  methods: {
    onBtnClick() {
      this.$emit('toggleCard')
    },

    onAfterEnter() {
      this.$emit('transitionDone')
    },
  },
}
</script>
