//============ GENERAL =================================//
//======================================================//
export const SUCCESS = 'SUCCESS'

//============ APP CONFIG ==============================//
//======================================================//
export const GET_APP_CONFIG_SUCCESS = 'GET_APP_CONFIG_SUCCESS'
export const GET_APP_CONFIG_FAIL = 'GET_APP_CONFIG_FAIL'

//============ REGISTRATION ============================//
//======================================================//
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED'
export const TOKEN_REFRESH_FAIL = 'TOKEN_REFRESH_FAIL'

//============ STORIES =================================//
//======================================================//
export const USER_LIKE_EXISTS = 'USER_LIKE_EXISTS'
