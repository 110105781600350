<template>
  <div class="vue-popup-onboarding">
    <!-- Department-->
    <frm1035-form-group>
      <frm1028-advanced-combo
        v-model="filterForm.department"
        :options="[]"
        :placeholder="$i18n.t('onboarding.person.department')"
        :tooltip="false"
        :label="$i18n.t('onboarding.person.department')"
        :searchable="true"
        :searchInExternalListFunction="dataLoaderAuthorizedMode.fetchOnboardingDepartmentsLists"
      />
    </frm1035-form-group>

    <!-- Start date-->
    <frm1035-form-group>
      <frm1001-input-field
        v-model="localDate"
        :options="null"
        :label="$i18n.t('onboarding.person.startDateFrom')"
        :tooltip="false"
        :disabled="true"
        :placeholder="'DD.MM.YYYY'"
      />

      <frm1006-button
        :type="'internal'"
        :icon-left="true"
        class="vue-is-calendar"
        @buttonClickEvent="toggleCalendar()"
      />
    </frm1035-form-group>
    <v-date-picker
      v-if="isCalendarVisible"
      v-model="filterForm.startDate"
      :model-config="modelConfig"
      class="vue-date-picker"
      locale="cs-CZ"
    />

    <!-- Status-->
    <frm1035-form-group>
      <frm1028-advanced-combo
        v-model="filterForm.status"
        :options="statusesListOptions"
        :placeholder="$i18n.t('onboarding.person.status')"
        :tooltip="false"
        :label="$i18n.t('onboarding.person.status')"
      />
    </frm1035-form-group>

    <!-- HR Manager-->
    <frm1035-form-group v-if="isUserPerka">
      <frm1028-advanced-combo
        v-model="filterForm.perka"
        :options="[]"
        :placeholder="$i18n.t('onboarding.perka')"
        :tooltip="false"
        :label="$i18n.t('onboarding.perka')"
        :searchable="true"
        :searchInExternalListFunction="dataLoaderAuthorizedMode.fetchOnboardingEmployeesLists"
      />
    </frm1035-form-group>

    <!-- Contract type-->
    <frm1035-form-group>
      <frm1028-advanced-combo
        v-model="filterForm.contractType"
        :options="contractTypeListOptions"
        :placeholder="$i18n.t('onboarding.person.contractType')"
        :tooltip="false"
        :label="$i18n.t('onboarding.person.contractType')"
      />
    </frm1035-form-group>

    <div
      class="vue-bottom-content"
    >
      <frm1006-button
        :disabled="untouchedFilter"
        @buttonClickEvent="storeFilterData"
      >
        {{ $t('advancedFilter.filterList') }}
      </frm1006-button>
    </div>

    <gen1006-info-panel
      v-if="hasError"
      type="error"
    >
      {{ $t('error.generic') }}
    </gen1006-info-panel>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import {ONBOARDING_APPLICANT_STATUSES, ONBOARDING_APPLICANT_CONTRACT_TYPE_OPTIONS, USER_ROLE} from 'ext-authorized-mode-constants';
import dataLoaderAuthorizedMode from '@/utils/data-loader/authorized-mode';

export default {
  name: 'Prj1011PopupOnboardingApplicantsFilter',
  data() {
    return {
      hasError: false,
      departmentList: [],
      statusList: [],
      localDate: '',
      filterForm: {},
      isCalendarVisible: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      dataLoaderAuthorizedMode
    }
  },

  computed: {
    ...mapState('onboarding', [
      'onboardingApplicants',
      'onboardingApplicantsFilter',
      'onboardingSelectedUserRole'
    ]),

    untouchedFilter() {
      return  Object.values(this.filterForm).every(value => value === '')
    },

    statusesListOptions() {
      const statuses = [...new Set(this.onboardingApplicants.map(applicant => applicant.status))];

      return statuses.map(item => {
        return {
          value: ONBOARDING_APPLICANT_STATUSES.indexOf(item).toString(),
          caption: this.$t(`onboarding.statuses.${item}`)
        }
      })
    },

    contractTypeListOptions() {
      const ONBOARDING_APPLICANT_CONTRACT_TYPES = Object.values(ONBOARDING_APPLICANT_CONTRACT_TYPE_OPTIONS);
      return ONBOARDING_APPLICANT_CONTRACT_TYPES.map(item => {
        return {
          value: item,
          caption: this.$t(`onboarding.contractTypes.${item}`)
        }
      })
    },

    pickerDate() {
      return this.filterForm.startDate
    },

    isUserPerka() {
      return this.onboardingSelectedUserRole === USER_ROLE.perka
    }
  },

  watch: {
    pickerDate(value) {
      let tmpDate = new Date(value);
      if (value) {
        this.localDate = tmpDate.getDate() + '. ' + (tmpDate.getMonth() + 1) + '. ' + tmpDate.getFullYear();
      }
      this.isCalendarVisible = false
    },
  },

  mounted() {
    this.filterForm = {...this.onboardingApplicantsFilter};
  },

  methods: {
    storeFilterData() {
      this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_APPLICANTS_FILTER, this.filterForm);
      this.$store.commit(STORE_MODULES.POPUP + '/' + MUTATIONS_CONSTANTS.TOGGLE_POPUP, false);
    },

    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible
    },
  }
}
</script>
