<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template v-if="initialized && !isLoading">
        <!--======= FORM INPUT ======================-->
        <div
          v-if="!isReport"
          class="vue-b-form-input"
        >
          <prj1017-heading
            :header="$i18n.t('recruitment.headlineListingForm')"
          />

          <div
            v-if="locationsList.length > 0 && specializationsList.length > 0 && contractTypeList.length > 0 && interestList.length > 0"
            class="vue-b-form"
          >
            <!-- INTEREST -->
            <frm1035-form-group>
              <frm1025-option-group
                :name="'frm-interest'"
                :label="$i18n.t('recruitment.interestedIn')"
                :multiselect="false"
                :options="interestList"
                :value="recruitmentFormValues.interest"
                :tooltip="false"
                @change="optionGroupChangeEventInterest"
              />
            </frm1035-form-group>

            <!-- NAME INPUT -->
            <frm1035-form-group>
              <frm1001-input-field
                id="recruitment-listing-form-name"
                v-model="recruitmentFormValues.name"
                :tooltip="false"
                name="applicant-name"
                :required="true"
                :state="formNameInvalidState(recruitmentFormValues.name)"
                :label="$i18n.t('forms.name')"
              />
            </frm1035-form-group>

            <!-- EMAIL INPUT -->
            <frm1035-form-group>
              <frm1001-input-field
                id="recruitment-response-applicant-email"
                v-model="recruitmentFormValues.email"
                :tooltip="false"
                name="applicant-email"
                inputType="email"
                :required="true"
                :state="formEmailInvalidState(recruitmentFormValues.email) ? 'info' : 'invalid'"
                :label="$i18n.t('forms.email')"
              />
            </frm1035-form-group>

            <!-- PHONE INPUT -->
            <frm1035-form-group>
              <frm1001-input-field
                v-model="recruitmentFormValues.tel"
                :tooltip="false"
                inputType="tel"
                :required="true"
                :state="formTelInvalidState(recruitmentFormValues.tel)"
                :label="$i18n.t('forms.phoneNumber')"
              />
            </frm1035-form-group>

            <!-- SPECIALIZATION -->
            <frm1035-form-group>
              <frm1028-advanced-combo
                id="recruitment-specialization-search"
                v-model="recruitmentFormValues.specialization"
                :options="specializationsList"
                :tooltip="false"
                :label="$i18n.t('recruitment.specialization')"
                :searchable="true"
              />
            </frm1035-form-group>

            <!-- LOCATION -->
            <frm1035-form-group>
              <frm1025-option-group
                :name="'frm-regions'"
                :label="$i18n.t('recruitment.place')"
                :multiselect="false"
                :options="locations"
                :value="recruitmentFormValues.regionSelect"
                :tooltip="false"
                @change="optionGroupChangeEventRegionSelect"
              />

              <frm1025-option-group
                v-show="recruitmentFormValues.regionSelect === 'yes'"
                :name="'frm-regions-list'"
                :multiselect="true"
                :options="locationsList"
                :value="recruitmentFormValues.regions"
                :tooltip="false"
                @change="optionGroupChangeEventRegions"
              />
            </frm1035-form-group>

            <!-- CONTRACT TYPE -->
            <frm1035-form-group>
              <frm1025-option-group
                :name="'frm-types'"
                :label="$i18n.t('recruitment.obligation')"
                :multiselect="false"
                :options="contractTypes"
                :value="recruitmentFormValues.typeSelect"
                :tooltip="false"
                @change="optionGroupChangeEventTypeSelect"
              />

              <frm1025-option-group
                v-show="recruitmentFormValues.typeSelect === 'yes'"
                :name="'frm-types-list'"
                :multiselect="true"
                :options="contractTypeList"
                :value="recruitmentFormValues.types"
                :tooltip="false"
                @change="optionGroupChangeEventTypes"
              />
            </frm1035-form-group>

            <!-- INTEREST MESSAGE -->
            <frm1035-form-group>
              <frm1013-textarea
                v-model="recruitmentFormValues.message"
                :tooltip="false"
                :label="$i18n.t('recruitment.interest')"
                :required="false"
                :rowsAutoExpand="true"
                :rows="1"
                :rowsAutoExpandMinRows="1"
                :rowsAutoExpandMaxRows="5"
              />
            </frm1035-form-group>
          </div>
        </div>

        <!--======= FORM SUCCESS ====================-->
        <div
          v-if="isReport"
          class="vue-b-form-success"
        >
          <div v-if="reportSent">
            <prj1017-heading
              :header="$i18n.t('recruitment.reportSentHeader')"
              :subheader="$i18n.t('recruitment.reportSentSubHeader')"
            />
          </div>
        </div>
      </template>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        v-if="!isReport"
        type="primary"
        :disabled="!isActive"
        @buttonClickEvent="validateRecruitmentInterestForm"
      >
        {{ $t('forms.submit') }}
      </frm1006-button>

      <frm1006-button
        v-if="reportSent"
        type="primary"
        @buttonClickEvent="redirectToBack"
      >
        {{ $t('general.back') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        :active.sync="reportFailed"
        :closeAfter="5000"
        :buttonClose="true"
        class="vue-is-info-panel"
      >
        <gen1006-info-panel type="error">
          {{ $t('forms.failReportGeneral') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1016-loading-indicator
        :active="sending"
        :fullScreen="true"
        :overlay="true"
      />
    </template>
  </page-layout>
</template>

<script type="application/javascript">

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import logger from '@/utils/logger';
import Scroller from 'vue-scrollto'
import utilsGeneral from '@/utils/utils-general';
import dataLoader from '@/utils/data-loader';
import {mapState} from 'vuex';
import { RECRUITMENT_ROTATION_FORM } from '@/store/store-modules';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import Frm1025OptionGroup from '../../../frameworks/vue/components/frm/frm1025-option-group/frm1025-option-group.vue';

export default {
  name: 'RecruitmentListingForm',
  components: {
    Frm1025OptionGroup,
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      locations: [{
        'id': 'frm-radio-location-select-no',
        'caption': this.$i18n.t('recruitment.doNotDecide'),
        'value': 'no'
      }, {
        'id': 'frm-radio-location-select-yes',
        'caption': this.$i18n.t('recruitment.selectLocation'),
        'value': 'yes'
      }],
      locationsList: [],
      specializationsList: [],
      contractTypes: [{
        'id': 'frm-radio-contract-select-no',
        'caption': this.$i18n.t('recruitment.doNotDecide'),
        'value': 'no'
      }, {
        'id': 'frm-radio-contract-select-you',
        'caption': this.$i18n.t('recruitment.selectType'),
        'value': 'yes'
      }],
      contractTypeList: [],
      interestList: [],
      recruitmentFormValues: {
        name: '',
        email: '',
        tel: '',
        regionSelect: 'no',
        regions: [],
        specialization: '',
        typeSelect: 'no',
        types: [],
        interest: '',
        message: ''
      },
      sending: false,
      isReport: false,
      reportSent: false,
      reportFailed: false,
      validationErrors: [],
    }
  },

  computed: {
    ...mapState('user', [
      'deviceId',
      'profile'
    ]),

    ...mapState('recruitment', [
      'recruitment'
    ]),


    isActive() {
      let formValue = this.recruitmentFormValues;
      return !!(formValue.name && formValue.email && this.formEmailInvalidState(formValue.email) && formValue.tel && formValue.interest);
    },

    isSelectedRegion() {
      return this.recruitmentFormValues.regions.length > 0;
    },

    selectedRegionValue() {
      if (this.isSelectedRegion) {
        let retValue = '';
        this.recruitmentFormValues.regions.forEach((region) => {
          retValue += (retValue.length > 0 ? ', ' : '') + this.locationsList.find(({ value }) => value === region).caption;
        });
        return retValue;
      } else {
        return this.$i18n.t('recruitment.doNotDecide');
      }
    },

    isSelectedSpecialization() {
      return this.recruitmentFormValues.specialization !== '' && this.recruitmentFormValues.specialization !== '0';
    },

    selectedSpecializationValue() {
      if (this.isSelectedSpecialization) {
        return this.specializationsList.find(({ value }) => value === this.recruitmentFormValues.specialization).caption;
      } else {
        return this.$i18n.t('recruitment.doNotDecide');
      }
    },

    isSelectedType() {
      return this.recruitmentFormValues.types.length > 0;
    },

    selectedTypeValue() {
      if (this.isSelectedType) {
        let retValue = '';
        this.recruitmentFormValues.types.forEach((type) => {
          retValue += (retValue.length > 0 ? ', ' : '') + this.contractTypeList.find(({ value }) => value === type).caption;
        });
        return retValue;
      } else {
        return this.$i18n.t('recruitment.doNotDecide');
      }
    },

    isSelectedInterest() {
      return this.recruitmentFormValues.interest !== '';
    },

    selectedInterestValue() {
      if (this.isSelectedInterest) {
        return this.interestList.find(({ value }) => this.recruitmentFormValues.interest.includes(value)).caption;
      } else {
        return '';
      }
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value && Object.keys(this.locationsList).length) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.getRegistries();
    this.recruitmentFormValues.name = this.profile?.name ?? '';
    this.recruitmentFormValues.email = this.profile?.workEmail ?? (this.profile?.otherEmail ?? '');
    this.recruitmentFormValues.tel = this.profile?.phone ?? '';
    this.sendStatistics(RECRUITMENT_ROTATION_FORM);
  },

  methods: {
    formNameInvalidState(value) {
      if (!value && this.validationErrors.length) {
        return 'invalid'
      } else {
        return 'info'
      }
    },

    formEmailInvalidState(value) {
      if (!value) {
        return true;
      }
      return utilsGeneral.isValidEmail(value);
    },

    formTelInvalidState(value) {
      if (!value && this.validationErrors.length) {
        return 'invalid'
      } else {
        return 'info'
      }
    },

    validateRecruitmentInterestForm() {
      this.validationErrors = [];
      this.reportSent = false;
      this.reportFailed = false;

      if (this.validationErrors.length === 0) {
        this.sendRecruitmentInterestForm();
      } else {
        this.scrollToTop();
      }
    },

    sendRecruitmentInterestForm() {
      this.sending = true;

      let component = this;
      let values = this.recruitmentFormValues;
      let filter = [];

      filter.push('Lokalita (' + this.selectedRegionValue + ')');
      filter.push('Obor (' + this.selectedSpecializationValue + ')');
      filter.push('Úvazek (' + this.selectedTypeValue + ')');

      let source = {...this.recruitmentFormValues};
      delete source.name;
      delete source.email;
      delete source.tel;

      dataLoader.sendJobInterest(values.name, values.email, values.tel, this.selectedInterestValue, values.message, filter.toString(), JSON.stringify(source))
        .then(() => {
          this.sending = false;
          this.isReport = true;
          this.reportSent = true;
          this.returnFormToInitialState();
          component.scrollToTop();
          logger.info('Job interest form has been sent.');
        }, (error) => {
          this.sending = false;
          this.isReport = false;
          this.reportFailed = true;
          logger.error('Sending interest form has failed.', error);
        });
    },

    scrollToTop() {
      let pageTop = document.getElementById('vue-page-default');
      let scrollContainer = document.getElementById('vue-b-page-content');

      Scroller.scrollTo(pageTop, 500, {container: scrollContainer});
    },

    returnFormToInitialState() {
      this.recruitmentFormValues.name = '';
      this.recruitmentFormValues.email = '';
      this.recruitmentFormValues.tel = '';
      this.recruitmentFormValues.message = '';
      this.recruitmentFormValues.regions = [];
      this.recruitmentFormValues.types = [];
      this.recruitmentFormValues.regionSelect = 'no';
      this.recruitmentFormValues.typeSelect = 'no';
      this.recruitmentFormValues.specialization = '';
      this.recruitmentFormValues.interest = '';
      this.validationErrors = [];
    },

    redirectToBack(){
      this.isReport = false;
      this.reportSent = false;
      this.reportFailed = false;
      this.$router.replace({ name: 'recruitment-dashboard' });
    },

    async getRegistries() {
      this.isLoading = true;
      // this.searchRequestSending = true;
      // this.searchRequestFailed = false;

      await dataLoader
        .fetchRecruitmentRegistries()
        .then((registriesData) => {
          if (typeof registriesData === 'undefined') {
            throw Error('Wrong registries response format!');
          }

          this.$store.commit(STORE_MODULES.RECRUITMENT + '/' + MUTATIONS_CONSTANTS.SET_RECRUITMENT_REGISTRIES, registriesData);
          let tmpRegions = [];
          let registriesRegions = registriesData.regions;
          Object.keys(registriesRegions).forEach(function (value) {
            let tmpRegion = {'value': registriesRegions[value].id, 'caption': registriesRegions[value].name, 'id': 'region_' + registriesRegions[value].id};
            tmpRegions.push(tmpRegion);
          });
          this.locationsList = tmpRegions;

          let tmpSpecializations = [];
          let registriesSpecializations = registriesData.specializations;
          tmpSpecializations.push({'value':'0', 'caption': this.$i18n.t('recruitment.doNotDecide'), 'id':'specialization_0'});
          Object.keys(registriesSpecializations).forEach(function (value) {
            let tmpSpecialization = {'value': registriesSpecializations[value].id, 'caption': registriesSpecializations[value].name, 'id': 'specialization_' + registriesSpecializations[value].id};
            tmpSpecializations.push(tmpSpecialization);
          });
          this.specializationsList = tmpSpecializations;

          let tmpTypes = [];
          let registriesTypes = registriesData.types;
          Object.keys(registriesTypes).forEach(function (value) {
            let tmpType = {'value': registriesTypes[value].id, 'caption': registriesTypes[value].name, 'id': 'type_' + registriesTypes[value].id};
            tmpTypes.push(tmpType);
          });
          this.contractTypeList = tmpTypes;

          let tmpInterests = [];
          let registriesInterests = registriesData.interests;
          Object.keys(registriesInterests).forEach(function (value) {
            let tmpInterest = {'value': registriesInterests[value].id, 'caption': registriesInterests[value].name, 'id': 'interest_' + registriesInterests[value].id};
            tmpInterests.push(tmpInterest);
          });
          this.interestList = tmpInterests;

          // this.searchRequestSending = false;
        }, () => {

          // this.searchRequestSending = false;
          // this.searchRequestFailed = true;

        }).then(() => {

          this.initialized = true;
          this.isLoading = false;
          this.recruitmentFormValues.regions = this.recruitment.filterLocation === null ? [] : this.recruitment.filterLocation;
          this.recruitmentFormValues.specialization = this.recruitment.filterSpecialization === null ? '' : this.recruitment.filterSpecialization;
          this.recruitmentFormValues.types = this.recruitment.filterType === null ? [] : this.recruitment.filterType;

        });
    },

    optionGroupChangeEventInterest(value) {
      this.recruitmentFormValues.interest = value;
    },

    optionGroupChangeEventRegions(value) {
      let regions = [];
      Object.entries(value).forEach(([, val]) => {
        regions.push(val);
      });
      this.recruitmentFormValues.regions = regions;
    },

    optionGroupChangeEventTypes(value) {
      let types = [];
      Object.entries(value).forEach(([, val]) => {
        types.push(val);
      });
      this.recruitmentFormValues.types = types;
    },

    optionGroupChangeEventRegionSelect(value) {
      this.recruitmentFormValues.regionSelect = value;
      if (value === 'no') {
        this.recruitmentFormValues.regions = [];
      }
    },

    optionGroupChangeEventTypeSelect(value) {
      this.recruitmentFormValues.typeSelect = value;
      if (value === 'no') {
        this.recruitmentFormValues.types = [];
      }
    }
  },
}
</script>
