<template>
  <div class="vue-popup-benefits-filter">
    <frm1035-form-group>
      <frm1028-advanced-combo
        v-model="benefitLocation"
        class="vue-popup-benefits-city-selector"
        :options="convertedCategoryObject"
        :placeholder="$i18n.t('advancedFilter.cityPlaceholder')"
        :tooltip="false"
        :label="$i18n.t('advancedFilter.city')"
        @change="fetchItemsNumber"
      />

      <gen1015-section
        v-if="benefitsFilterCategories.categories.length > 1"
        :collapsible="true"
        :collapsed="true"
      >
        <template v-slot:header>
          <h2>{{ $t('advancedFilter.categories') }}</h2>
        </template>

        <!-- TODO MBU: use frm1025 option group -->
        <template v-slot:content>
          <frm1003-checkbox
            v-for="category in benefitsFilterCategories.categories"
            :key="'category-' + category.id"
            v-model="benefitCategories"
            :valueNative="category.id"
            :tooltip="false"
            @change="fetchItemsNumber"
          >
            {{ category.name }}
          </frm1003-checkbox>
        </template>
      </gen1015-section>
    </frm1035-form-group>

    <div
      v-if="!clearFilters"
      class="vue-popup-benefits-filter-submit"
    >
      <!-- FIXME: JNO: Parsing integer from foundItems string/number mismatch for strict type comparison -->
      <frm1006-button
        :disabled="clearFilters || parseInt(foundItems) < 1"
        @buttonClickEvent="filterEvents"
      >
        {{ $t('advancedFilter.filterList') }}
        <span
          v-if="showFilterNumbers"
        >
          ({{ foundItems }})
        </span>
      </frm1006-button>
    </div>

    <gen1006-info-panel
      v-if="hasError"
      type="error"
    >
      {{ $t('error.generic') }}
    </gen1006-info-panel>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'Prj1011PopupBenefitsFilter',
  data() {
    return {
      touchedFilters: false,
      hasError: false,
      benefitLocation: '',
      searchQuery: '',
      benefitCategories: [],
      loadingItemsNumber: false,
      foundItems: 0
    }
  },

  computed: {
    ...mapState('benefits', [
      'benefitsFilterCategories',
      'benefitsSelectedCategories',
      'benefitsActiveCategory'
    ]),

    convertedCategoryObject() {
      let locations = [];

      Object.values(this.benefitsFilterCategories.locations).forEach(categoryItem => {
        const categoryObject = {
          value: categoryItem.id.toString(),
          caption: categoryItem.name
        }

        locations.push(categoryObject);
      });

      return locations;
    },

    clearFilters() {
      return !this.benefitLocation && !this.benefitCategories.length;
    },

    showFilterNumbers() {
      return !this.clearFilters && !this.loadingItemsNumber;
    },
  },

  created() {
    this.synchronizeFiltersData();
  },

  mounted() {
    this.assignCategory();
  },

  methods: {
    synchronizeFiltersData() {
      if (this.benefitsActiveCategory === 0) {
        return;
      }

      this.benefitCategories.push(parseInt(this.benefitsActiveCategory));
    },

    fetchItemsNumber() {
      const benefitsItemsNumberOptions = [
        this.benefitCategories,
        [this.benefitLocation],
        this.searchQuery
      ]

      this.loadingItemsNumber = true;
      this.touchedFilters = true;

      dataLoader.fetchBenefitsListCount(benefitsItemsNumberOptions)
        .then(response => {
          this.foundItems = response.resultsCount;
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          } else {
            this.loadingItemsNumber = false;
          }
        })
    },

    assignCategory() {
      if (typeof this.benefitsSelectedCategories.categories !== 'undefined') {
        this.benefitCategories = this.benefitsSelectedCategories.categories;
      }
    },

    filterEvents() {
      const filteredCategories = {
        categories: this.benefitCategories.filter(item => parseInt(item) !== 0).filter(item => item.constructor !== Array),
        locations: [this.benefitLocation],
      }

      this.$store.commit(STORE_MODULES.BENEFITS + '/' + MUTATIONS_CONSTANTS.SET_BENEFITS_SELECTED_CATEGORIES, filteredCategories);
      this.$store.commit(STORE_MODULES.BENEFITS + '/' + MUTATIONS_CONSTANTS.SET_BENEFITS_FILTER_ACTIVE, true);
      this.$store.commit(STORE_MODULES.POPUP + '/' + MUTATIONS_CONSTANTS.TOGGLE_POPUP, false);
    }
  }
}
</script>
