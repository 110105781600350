//============ CONFIG ==================================//
//======================================================//
export const GET_APP_CONFIG = 'GET_APP_CONFIG';

//============ THEMES ===================================//
//======================================================//
export const GET_THEMES_INFO = 'GET_THEMES_INFO'

//============ CHALLENGES ==============================//
//======================================================//
export const GET_CHALLENGES_INFO = 'GET_CHALLENGES_INFO'

//============ COMPETITIONS ============================//
//======================================================//
export const GET_COMPETITIONS_INFO = 'GET_COMPETITIONS_INFO'

//============ SOCIAL ==================================//
//======================================================//
export const GET_SOCIAL_LINKS = 'GET_SOCIAL_LINKS'

