import store from '../../store';
import * as STORE_MODULES from '../../store/store-modules';
import * as MUTATIONS_CONSTANTS from '../../store/constants/esg/mutations';

let utilsGeneral = {
  createTimedId() {
    return this.randomChars(8) + '-' + new Date().getTime();
  },
  randomChars(length) {
    let result = '';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  },
  startAsyncProcess() {
    let processId = this.createTimedId();
    store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.START_PROCESS, processId);
    return processId;
  },
  stopAsyncProcess(processId) {
    store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.STOP_PROCESS, processId);
  },
  /**
   * The most basic validator for email address structure
   *
   * @param email String email address
   * @returns {boolean} true, if structure matches email format
   */
  isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  },
};

export default utilsGeneral;
