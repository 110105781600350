<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-advanced-combo" :class="classObjectComputed">
        <div class="vue-b-form-field">
            <label
                v-if="label"
                :id="idComputed + ID_EXTENSIONS.LABEL"
                :for="idComputed + ID_EXTENSIONS.INPUT"
                class="vue-label"
                >{{ label }}</label
            >
            <div
                :id="selectId"
                ref="select"
                class="vue-select"
                :tabindex="selectTabIndex"
                :aria-labelledby="labeledByComputed"
                :aria-describedby="describedByComputed"
                @click="selectClick(valueData, $event)"
                @keydown="selectKeyDown(valueData, $event)"
                @keyup="selectKeyUp(valueData, $event)"
                @focus="selectFocus(valueData, $event)"
                @blur="selectBlur(valueData, $event)"
            >
                <!--=== PLACEHOLDER ===-->
                <div
                    v-if="(placeholder && valueData === '') || (placeholderSelectable && valueData === '')"
                    key="placeholder"
                    class="vue-content"
                >
                    {{ placeholder }}
                </div>
                <!--=== SCOPED SLOT ===-->
                <div
                    v-if="itemSelected && $scopedSlots.itemSelected"
                    :key="itemSelected.value"
                    class="vue-content"
                    :class="{
                        [itemSelected.customCss]: itemSelected.customCss
                    }"
                >
                    <slot name="itemSelected" :item="itemSelected" />
                </div>
                <!--=== SIMPLE ===-->
                <div
                    v-else-if="itemSelected && !$scopedSlots.itemSelected"
                    :key="itemSelected.value"
                    class="vue-content"
                    :class="{
                        [itemSelected.customCss]: itemSelected.customCss
                    }"
                    v-html="itemSelected.caption"
                />
            </div>

            <div v-if="decorator" class="vue-decorator"></div>
            <frm1006-button
                v-if="hasButtonClear"
                ref="buttonClear"
                type="internal"
                class="vue-ci-button-clear"
                :title="i18n('globalInputButtonClear')"
                :tabindex="-1"
                :preventLosingFocus="true"
                @buttonClickEvent="buttonClearClick"
            >
                {{ i18n('globalInputButtonClear') }}
            </frm1006-button>

            <!--========== CONTEXT CONTAINER ======================-->
            <!--===================================================-->
            <gen1011-context-container
                ref="contextContainer"
                class="vue-ci-dropdown vue-is-menu"
                :class="contextContainerClassObject"
                :active.sync="expandedData"
                :whiteList="contextContainerWhiteListComputed"
                @update:active="contextContainerActiveChange"
                @removedEvent="contextContainerRemovedEvent"
            >
                <div v-if="searchable" class="vue-b-search">
                    <frm1001-input-field
                        ref="search"
                        v-model="searchStringComputed"
                        :liveUpdate="true"
                        :placeholder="searchPlaceholderComputed"
                        :tooltip="false"
                        class="vue-ci-search"
                    />
                </div>
              <template v-if="!searchInExternalListFunction">
                <gen1011-context-container-menu
                  v-if="!filterResultEmpty"
                  ref="contextContainerMenu"
                  :items="optionsData"
                  :active="expandedData"
                  :itemSelectedValue.sync="valueData"
                  :itemSelect="true"
                  :searchable="searchable"
                  :searchString="searchStringData"
                  :placeholder="placeholder"
                  :placeholderSelectable="placeholderSelectable"
                  @menuMountedEvent="contextContainerMenuMounted"
                  @itemSelectEvent="contextContainerMenuItemSelect"
                  @closeEvent="contextContainerClose"
                >
                  <template v-if="$scopedSlots.item" v-slot:contextContainerMenu="props">
                    <slot name="item" :item="props.item" :index="props.index" />
                  </template>
                </gen1011-context-container-menu>
                <div v-else class="vue-b-search-empty">
                  <slot name="searchEmptyResult">
                    <span class="vue-caption">
                        {{ i18n('advancedComboSearchEmptyResult') }}
                    </span>
                  </slot>
                </div>
              </template>
              <template v-else>
                <gen1011-context-container-menu
                  v-if="!filterResultEmpty"
                  ref="contextContainerMenu"
                  :items="optionsData"
                  :active="expandedData"
                  :itemSelectedValue.sync="valueData"
                  :itemSelect="true"
                  :searchable="searchable"
                  :searchString="searchStringData"
                  :placeholder="placeholder"
                  :placeholderSelectable="placeholderSelectable"
                  @menuMountedEvent="contextContainerMenuMounted"
                  @itemSelectEvent="contextContainerMenuItemSelect"
                  @closeEvent="contextContainerClose"
                >
                  <template v-if="$scopedSlots.item" v-slot:contextContainerMenu="props">
                    <slot name="item" :item="props.item" :index="props.index" />
                  </template>
                </gen1011-context-container-menu>
                <div v-else class="vue-b-search-empty">
                  <slot name="searchEmptyResult">
                    <template v-if="!loading">
                      <span v-if="!searchStringIsLongEnough" class="vue-caption">
                          {{ i18n('minimumNumberOfCharsIs') }}
                      </span>
                      <span v-else class="vue-caption">
                          {{ i18n('advancedComboSearchEmptyResult') }}
                      </span>
                    </template>
                    <gen1016-loading-indicator
                      :active="loading"
                      :overlay="false"
                      :fullScreen="false"
                    />
                  </slot>
                </div>
              </template>
            </gen1011-context-container>
        </div>

        <!--========== TOOLTIP ================================-->
        <!--===================================================-->
        <gen1010-information-tooltip
            v-if="tooltipComputed"
            ref="tooltip"
            :expanded.sync="tooltipExpandedData"
            :state="state"
            :disabled="tooltipDisabled"
            :content="tooltipContent"
            :contentId="idComputed + ID_EXTENSIONS.TOOLTIP_CONTENT"
            :whiteList="tooltipWhiteListComputed"
            :boundComponentActive="componentIsActive"
            :boundComponentPreventLosingFocus="tooltipPreventLosingFocus"
            class="vue-ci-tooltip"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltip from '../../gen/gen1010-information-tooltip/gen1010-information-tooltip';
import Gen1011ContextContainer from '../../gen/gen1011-context-container/gen1011-context-container';
import Frm1001InputField from '../frm1001-input-field/frm1001-input-field';
import Frm1006Button from '../frm1006-button/frm1006-button';

//=== HELPERS
import Gen1011ContextContainerMenu from '../../helpers/gen1011-context-container/gen1011-context-container-menu';

//=== MIXINS
import Component from '../../mixins/component';
import ButtonClearCombo from '../../mixins/buttonClearCombo';
import Tooltip from '../../mixins/tooltip';
import Localization from '../../mixins/localization';
import Position from '../../mixins/position';
import Filter from '../../mixins/filter';

//=== MISC
import config from '../../../config';
import utilsComponents from '../../../utils/utils-components';
import Lodash from 'lodash';
import utilsEval from '../../../utils/eval/utils-eval';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import logger from '@/utils/logger';
import router from '@/router';

//============ OPTIONS =================================//
//======================================================//
let options = {
    scrollDebounceTimeout: 150
};

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1028';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1028AdvancedCombo',
    components: {
        Gen1010InformationTooltip,
        Gen1011ContextContainer,
        Gen1011ContextContainerMenu,
        Frm1001InputField,
        Frm1006Button
    },
    mixins: [Component, ButtonClearCombo, Tooltip, Localization, Position, Filter],
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        name: String,
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        required: Boolean,
        disabled: Boolean,
        expanded: Boolean,
        label: String,
        labeledBy: String,
        describedBy: String,
        placeholder: String,
        placeholderSelectable: Boolean,
        value: {
            default: '',
            type: String
        },
        excludeSelectedOption: Boolean,
        //=== ADDITIONAL ELEMENTS
        decorator: Boolean,
        //=== SEARCH
        searchable: Boolean,
        searchPlaceholder: String,
        searchValuePaths: {
            type: Array,
            default: () => ['caption']
        },
        searchString: String,
        searchEmptyResultCaption: String,
        searchFilteringExternal: Boolean,
        //=== FILTERS
        filters: {
            type: Array,
            default: () => []
        },
        // TODO MBU: implement driving by watchers?
        filtersDrivenByWatcher: {
            default: false,
            type: Boolean
        },
        //=== CONTEXT CONTAINER
        contextContainerCustomCss: String,
        contextContainerPositionDefault: {
            default: () => {
                return {
                    x: 'right',
                    y: 'bottom'
                };
            },
            type: Object
        },
        contextContainerWhiteList: {
            default: () => {
                return [];
            },
            type: Array
        },
        //=== TOOLTIP
        focusOnTooltipOpen: {
            default: true,
            type: Boolean
        },
        tooltipPreventLosingFocus: {
            default: true,
            type: Boolean
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        },
        searchInExternalListFunction: {
          default: false,
          type: [Function, Boolean],
        },
    },
    data() {
        return {
            valueData: this.value,
            optionsData: [],
            componentIsActive: false,
            focused: false,
            expandedData: this.expanded,
            searchStringData: '',
            filtersData: [...this.filters],
            filtersWatcherUnwatch: null,
            filtersDataWatcherUnwatch: null,
            contextContainerWhiteListInitial: [],
            contextContainerPosition: {
                x: this.contextContainerPositionDefault.x,
                y: this.contextContainerPositionDefault.y
            },
            menuMounted: false,
            externalList: [],
            loading: false
        };
    },
    computed: {
        classObject() {
            return [
                'vue-is-' + this.state,
                'vue-has-dropdown-position-' + this.contextContainerPosition.x,
                'vue-has-dropdown-position-' + this.contextContainerPosition.y,
                {
                    [`vue-has-placeholder-selected`]:
                        (this.placeholder && this.valueData === '') ||
                        (this.placeholderSelectable && this.valueData === ''),
                    'vue-is-expanded': this.expandedData,
                    'vue-is-required': this.required,
                    'vue-is-readonly': this.readonly,
                    'vue-is-disabled': this.disabled,
                    'vue-is-set': !this.notSet,
                    'vue-is-not-set': this.notSet,
                    'vue-has-label': this.label,
                    'vue-has-decorator': this.decorator,
                    'vue-is-searchable': this.searchable,
                    'vue-is-component-active': this.componentIsActive,
                    'vue-is-focused': this.focused,
                    'vue-has-filter-results-empty': this.filterResultEmpty
                }
            ];
        },
        classObjectComputed() {
            return [...this.classObject, ...this.classObjectMixinTooltip, ...this.classObjectMixinButtonClear];
        },
        optionsComputed() {
            // TODO MBU: do deep copy correctly - prepare methods in utilsGeneral
            let optionsStringified = JSON.stringify(this.options);
            let optionsComputed = JSON.parse(optionsStringified);

            if ( this.searchInExternalListFunction && (this.externalList.length !== 0)) return this.externalList

            for (let i = 0; i < optionsComputed.length; i++) {
                let option = optionsComputed[i];

                if (!option.action) {
                    optionsComputed[i].action = 'default';
                }

                if (option.component) {
                    optionsComputed[i].component = this.options[i].component;
                }

                if (this.excludeSelectedOption && option.value === this.valueData) {
                    optionsComputed[i].excluded = true;
                    optionsComputed[i].disabled = true;
                }
            }

            //=== WITH PLACEHOLDER
            if (
                (this.placeholder && this.valueData === '' && this.placeholderSelectable) ||
                this.placeholderSelectable
            ) {
                let placeholderOption = {
                    caption: this.placeholder,
                    value: '',
                    customCss: 'vue-combo-option-placeholder'
                };

                optionsComputed.unshift(placeholderOption);
            }

            return optionsComputed;
        },
        selectTabIndex() {
            return this.disabled ? -1 : 0;
        },
        itemSelectedIndex() {
            return this.getItemIndex(this.valueData, this.optionsComputed);
        },
        itemSelected() {
            if (this.itemSelectedIndex !== null && this.itemSelectedIndex >= 0) {
                if (this.valueData !== '') {
                    return this.optionsComputed[this.itemSelectedIndex];
                } else {
                    return null;
                }
            }

            return null;
        },
        filterResultEmpty() {
            return this.filtersData.length > 0 && this.optionsData.length < 1;
        },
        //========= SEARCH ===========================//
        //============================================//
        searchStringComputed: {
            get() {
                return this.searchStringData;
            },
            set(value) {
                this.searchStringData = value;
                this.$emit('update:searchString', value);
            }
        },
        searchPlaceholderComputed() {
            if (!this.searchPlaceholder) {
                return this.i18n('advancedComboSearchPlaceholder');
            }

            return this.searchPlaceholder;
        },
        //========= CONTEXT CONTAINER ================//
        //============================================//
        contextContainerClassObject() {
            return [this.contextContainerCustomCss, { 'vue-is-searchable': this.searchable }];
        },
        contextContainerWhiteListComputed() {
            let whiteList = [];

            // initial internal white list setting
            if (this.contextContainerWhiteListInitial.length > 0) {
                for (let i = 0; i < this.contextContainerWhiteListInitial.length; i++) {
                    let element = this.contextContainerWhiteListInitial[i];
                    whiteList.push(element);
                }
            }

            // add passed items via whiteList prop
            if (this.contextContainerWhiteList.length > 0) {
                for (let i = 0; i < this.contextContainerWhiteList.length; i++) {
                    let element = this.contextContainerWhiteList[i];
                    whiteList.push(element);
                }
            }

            return whiteList;
        },
        //========= ID & ACCESSIBILITY ===============//
        //============================================//
        generateAutoId() {
            return !!this.label || this.tooltipHasContent;
        },
        selectId() {
            if (this.generateAutoId) {
                return this.idComputed + this.ID_EXTENSIONS.INPUT;
            }

            return null;
        },
        labeledByComputed() {
            if (this.label && !this.labeledBy) {
                return this.idComputed + this.ID_EXTENSIONS.LABEL;
            }

            return this.labeledBy;
        },
        describedByComputed() {
            if (!this.describedBy && this.tooltipHasContent) {
                return this.idComputed + this.ID_EXTENSIONS.TOOLTIP_CONTENT;
            }

            return this.describedBy;
        },
        //============ OTHER ===================================//
        //======================================================//
        notSet() {
            return this.valueData === '';
        },
        searchStringIsLongEnough() {
          return this.searchStringData.length >= 3
        },

    },
    watch: {
        value(value) {
            this.valueData = value;
        },
        valueData(value) {
            this.$emit('change', value); // event for v-model
        },
        optionsComputed(value) {
            this.optionsData = [...value];
        },
        expanded(value) {
            this.expandedData = value;
        },
        expandedData(value) {
            if (value) {
                // close tooltip on context container open
                this.tooltipExpandedData = false;
            } else {
                this.searchStringData = '';
                this.menuMounted = false;
            }
        },
        searchString(value) {
            this.searchStringData = value;
        },
        searchStringData(value) {
            if (!this.searchFilteringExternal) {
                // TODO MBU: make more general
                this.setSearchFilter('caption', value, 'caption');
                this.filterOptions();
            }
            if (this.searchStringIsLongEnough) {
              this.fetchExternalListData(value);
            }
        },
        tooltipExpandedData() {
            this.setComponentActiveState();
            if (this.focusOnTooltipOpen && !this.componentIsActive && this.tooltipExpandedData) {
                this.$refs.select.focus();
            }
        },
        componentIsActive(value) {
            this.$emit('componentIsActiveEvent', value);
        },
        focused(value) {
            this.$emit('selectFocusStateEvent', value);
        },
        tooltipWhiteListInitial(value) {
            this.$emit('tooltipWhiteListInitial', value);
        }
    },
    created() {
        // TODO MBU: create from options or we need options computed for additional placeholder?
      if ( !this.searchInExternalListFunction ) this.optionsData = [...this.options];
    },
    mounted() {
        this.setContextContainerWhiteListInitial();

        // tooltip
        this.tooltipExpandedData = this.tooltipExpanded;
        if (this.tooltipWhiteListInitialInit) {
            this.setTooltipWhiteListInitial();
        }

        // set initial select value if none is set and there is no placeholder defined
        if (this.value === '' && !this.placeholder) {
            this.valueData = this.optionsData[0].value;
        }

        // set initial search data if it is preset via value
        if (this.searchString) {
            this.searchStringData = this.searchString;
        }

        // TODO MBU: keep prop driven by?
        // filters
        if (this.filtersDrivenByWatcher) {
            this.filtersWatcherUnwatch = this.$watch('filters', this.filtersWatcherCallback, { deep: true });
            this.filtersDataWatcherUnwatch = this.$watch('filtersData', this.filtersDataWatcherCallback, {
                deep: true
            });
        }
    },
    methods: {
        //========= GENERAL ==========================//
        //============================================//
        // TODO MBU: move to utils (used on repeater)
        getValueByPath(obj, path) {
            let pathSplit = path.split('.');
            let value;

            if (!pathSplit.length > 1) {
                value = obj[path];
            } else {
                value = pathSplit.reduce((o, i) => o[i], obj);
            }
            return value;
        },
        getItemIndex(itemValue, object) {
            for (let i = 0; i < object.length; i++) {
                let item = object[i];

                if (item.value === itemValue) {
                    return i;
                }
            }

            if (itemValue !== '') {
                console.warn('Value {{' + itemValue + '}} not matching any item value!');
            }
            return null;
        },
        setComponentActiveState() {
            if (!this.readonly) {
                this.componentIsActive = document.activeElement === this.$refs.select || this.expandedData;
            }
        },
        setSelectFocusState() {
            this.focused = document.activeElement === this.$refs.select;
        },
        //========= EVENTS ===========================//
        //============================================//

        //=== SELECT
        selectClick(value, event) {
            if (!this.disabled) {
                this.$emit('selectClickEvent', this.valueData, event);
                this.contextContainerToggle('click');
            }
        },
        selectKeyDown(value, event) {
            this.$emit('selectKeyDownEvent', this.valueData, event);

            if (event.key === 'Enter') {
                if (!this.expandedData) {
                    this.contextContainerOpen('enter');

                    // set customProp for event, so we can identify opening the context container via enter
                    // so this enter event could be ignored inside contextContainerMenu
                    // otherwise this event would be registered on document triggering item selection instantly inside contextContainerMenu
                    utilsComponents.eventSetCustomProp(event, 'contextContainerMenuOpenEvent', true);
                }
            }

            if (!this.expandedData) {
                if (event.key === 'ArrowDown') {
                    // TODO MBU: duplicated method in GEN1011: Context container menu, move to utils?
                    //========= SELECT ITEM NEXT =================//
                    //============================================//
                    event.preventDefault();
                    let itemSelectedIndex;
                    let itemNextIndex;
                    let itemLastIndex = this.optionsData.length - 1;

                    if (this.itemSelectedIndex !== null) {
                        itemSelectedIndex = this.itemSelectedIndex;
                    } else {
                        // TODO MBU: not very clear even with comment - refactor?
                        // set to -1 if itemSelectedIndex is null, so next index index is calculated as 0 - first item
                        itemSelectedIndex = -1;
                    }

                    // if next item is not disabled, select next item straight away
                    // else find next not disabled item through cycle
                    if (itemSelectedIndex <= itemLastIndex - 1 && !this.optionsData[itemSelectedIndex + 1].disabled) {
                        itemNextIndex = itemSelectedIndex + 1;

                        if (itemNextIndex <= itemLastIndex) {
                            this.valueData = this.optionsData[itemNextIndex].value;
                        }
                    } else if (itemSelectedIndex <= itemLastIndex - 1) {
                        itemNextIndex = this.findNextNotDisabledItem(itemSelectedIndex, true);

                        if (itemNextIndex > -1) {
                            this.valueData = this.optionsData[itemNextIndex].value;
                        }
                    }
                } else if (event.key === 'ArrowUp') {
                    // TODO MBU: duplicated method in GEN1011: Context container menu, move to utils?
                    //========= SELECT ITEM PREV =================//
                    //============================================//
                    event.preventDefault();
                    let itemSelectedIndex;
                    let itemPrevIndex;

                    if (this.itemSelectedIndex !== null) {
                        itemSelectedIndex = this.itemSelectedIndex;
                    } else {
                        // if itemSelectedIndex is null, start from the first item
                        itemSelectedIndex = 0;
                    }

                    // if previous item is not disabled, select previous item straight away
                    // else find previous not disabled item through cycle
                    if (itemSelectedIndex > 0 && !this.optionsData[itemSelectedIndex - 1].disabled) {
                        itemPrevIndex = itemSelectedIndex - 1;

                        if (itemSelectedIndex > 0) {
                            this.valueData = this.optionsData[itemPrevIndex].value;
                        }
                    } else if (itemSelectedIndex > 0) {
                        itemPrevIndex = this.findPreviousNotDisabledItem(itemSelectedIndex, true);

                        if (itemPrevIndex > -1) {
                            this.valueData = this.optionsData[itemPrevIndex].value;
                        }
                    }
                }
            }
        },
        selectKeyUp(value, event) {
            this.$emit('selectKeyUpEvent', value, event);
        },
        selectFocus(value, event) {
            this.$emit('selectFocusEvent', value, event);
            // states
            this.setComponentActiveState();
            this.setSelectFocusState();
            // tooltip
            if (
                this.tooltipComputed &&
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
        },
        selectBlur(value, event) {
            this.$emit('selectBlurEvent', value, event);
            this.setComponentActiveState();
            this.setSelectFocusState();
        },

        //========= SELECT ===========================//
        //============================================//
        selectSetFocus() {
            this.$refs.select.focus();
        },
        selectSetBlur() {
            this.$refs.select.blur();
        },
        //========= TOOLTIP ==========================//
        //============================================//
        setTooltipWhiteListInitial() {
            this.tooltipWhiteListInitial = [];

            // select
            this.tooltipWhiteListInitial.push(this.$refs.select);

            // button clear
            if (this.$refs.buttonClear !== undefined) {
                let buttonClearElements = this.$refs.buttonClear.$el.querySelectorAll('*');
                for (let element of buttonClearElements) {
                    this.tooltipWhiteListInitial.push(element);
                }
            }
        },
        //========= FILTERING ========================//
        //============================================//
        filterOptions() {
            if (this.filtersData.length > 0) {
                // filter options from optionsData
                this.optionsData = this.optionsComputed.filter(option => this.isOptionFiltered(option, 'id'));
            } else {
                // if no filtersData are set, set optionsData from options prop
                this.optionsData = [...this.optionsComputed];
            }
        },
        isOptionFiltered(option) {
            let fnResult = false; // result of filtering function - false means option is filtered out

            // iterate over multiple filtering conditions
            for (let i = 0; i < this.filtersData.length; i++) {
                let filterOption = this.filtersData[i];

                let value = this.getValueByPath(option, filterOption.valuePath);

                if (Lodash.isFunction(filterOption.fn)) {
                    fnResult = filterOption.fn(value);
                } else if (Lodash.isString(filterOption.fn)) {
                    // TODO MBU: implement eval functions if they will be part of the framework
                    let rule = filterOption.fn;
                    fnResult = utilsEval.evalDefinedFunction(value, rule);
                }

                if (!fnResult) {
                    break;
                }
            }

            return fnResult;
        },

        // TODO MBU: make more general via searchValuePaths prop
        setSearchFilter(valuePath, searchString, id) {
            let component = this;

            let filterItemData = {
                id: id,
                type: 'search',
                valuePath: valuePath,
                searchString: searchString,
                fn(value) {
                    return component._containsCaseInsensitive(searchString, value, false, false);
                }
                // fn: 'search:' + searchString + ',' + false + ',' + false // TODO MBU: variant for using eval function
            };

            let filterExisting = Lodash.filter(this.filtersData, { id: id });

            if (filterExisting.length < 1) {
                // if filter exists, push filterItem data
                this.filtersData.push(filterItemData);
            } else {
                // if it is already defined, replace the existing data
                for (let i = 0; i < this.filtersData.length; i++) {
                    let filterItem = this.filtersData[i];

                    if (filterItem.id === id) {
                        this.filtersData.splice(i, 1, filterItemData);
                    }
                }
            }
        },

        //========= CONTEXT CONTAINER ================//
        //============================================//
        contextContainerToggle(trigger) {
            this.expandedData = !this.expandedData;
            this.$emit('contextContainerToggleEvent', trigger, this.expandedData);
        },
        contextContainerOpen(trigger) {
            this.expandedData = true;
            this.$emit('contextContainerOpenEvent', trigger);
        },
        contextContainerClose(trigger) {
            this.expandedData = false;
            this.$emit('contextContainerCloseEvent', trigger);
        },
        contextContainerActiveChange(value) {
            if (value && this.searchable) {
                this.$nextTick().then(() => {
                    this.$refs.search.inputSetFocus();
                });
            } else if (!value && this.searchable) {
                this.selectSetFocus();
            }

            this.$emit('update:expanded', value);
            this.$emit('contextContainerExpandedChangeEvent', value);
        },
        contextContainerRemovedEvent() {
            this.resetContextContainerPosition();
            this.removeViewportChangeListeners();
        },
        contextContainerMenuMounted() {
            // wait until contextContainer is really rendered
            this.updateContextContainerPosition();
            this.createViewportChangeListeners();
            // context container white list
            this.setContextContainerWhiteListInitial();
            this.menuMounted = true;

            // close tooltip on context container open
            this.tooltipExpandedData = false;
        },
        contextContainerMenuItemSelect(item, itemIndex) {
            if (this.menuMounted) {
                this.$emit('contextContainerMenuItemSelectEvent', item, itemIndex);
                this.contextContainerClose('itemSelected');
                // update whiteList after DOM is redrawn
                this.$nextTick(() => {
                    this.setContextContainerWhiteListInitial();
                });
            }
        },
        setContextContainerWhiteListInitial() {
            this.contextContainerWhiteListInitial = [];

            // select
            if (this.$refs.select !== undefined) {
                this.contextContainerWhiteListInitial.push(this.$refs.select);

                let selectChildren = this.$refs.select.querySelectorAll('*');
                if (selectChildren !== undefined) {
                    for (let child of selectChildren) {
                        this.contextContainerWhiteListInitial.push(child);
                    }
                }
            }
        },

        //============ POSITION ================================//
        //======================================================//
        updateContextContainerPosition() {
            // once the context container is closed and scroll is performed
            // contextContainer.$el was just empty comment and getPositionRelativeTo function failed
            // this is safeguard to prevent it
            if (this.$refs.contextContainer.$el.nodeName !== '#comment') {
                // set current position for calculation
                let positionData = this.getPositionRelativeTo(
                    this.$refs.contextContainer.$el,
                    this.contextContainerPosition
                );
                // update only when needed
                this.contextContainerPosition = positionData.position;
            }
        },
        resetContextContainerPosition() {
            this.contextContainerPosition.x = this.contextContainerPositionDefault.x;
            this.contextContainerPosition.y = this.contextContainerPositionDefault.y;
        },
        windowScrolled() {
            if (this.scrollDebounce) {
                clearTimeout(this.scrollDebounce);
            }
            this.scrollDebounce = setTimeout(() => {
                this.updateContextContainerPosition();
                this.scrollDebounce = null;
            }, options.scrollDebounceTimeout);
        },
        windowResized() {
            if (this.expandedData) {
                this.updateContextContainerPosition();
            }
        },
        createViewportChangeListeners() {
            window.addEventListener('scroll', this.windowScrolled);
            window.addEventListener('resize', this.windowResized);
        },
        removeViewportChangeListeners() {
            window.removeEventListener('scroll', this.windowScrolled);
            window.removeEventListener('scroll', this.windowResized);
            if (this.scrollDebounce) {
                clearTimeout(this.scrollDebounce);
                this.scrollDebounce = null;
            }
        },
        //============ MISC ====================================//
        //======================================================//
        // TODO MBU: duplicated method in GEN1011: Context container menu, move to utils?
        findPreviousNotDisabledItem(index, skipEvaluatingPrevious) {
            let itemPrevIndex;

            // if we already know that previous item is disabled when can skip evaluating it
            if (skipEvaluatingPrevious) {
                itemPrevIndex = index - 2;
            } else {
                itemPrevIndex = index - 1;
            }

            for (let i = itemPrevIndex; i > -1; i--) {
                if (!this.optionsData[i].disabled) {
                    return i;
                }
            }

            return -1;
        },
        // TODO MBU: duplicated method in GEN1028: Advanced combo, move to utils?
        findNextNotDisabledItem(index, skipEvaluatingNext) {
            let itemNextIndex;
            let itemLastIndex = this.optionsData.length - 1;

            // if we already know that previous item is disabled when can skip evaluating it
            if (skipEvaluatingNext) {
                itemNextIndex = index + 2;
            } else {
                itemNextIndex = index + 1;
            }

            for (let i = itemNextIndex; i <= itemLastIndex; i++) {
                if (!this.optionsData[i].disabled) {
                    return i;
                }
            }

            return -1;
        },
        // TODO MBU: duplicated method in GEN1028: Advanced combo, move to utils?
        findFirstNotDisabledItem() {
            return this.findNextNotDisabledItem(-1);
        },

      fetchExternalListData(fullText) {
        this.loading = true;
        let externalListFetchFunction = this.searchInExternalListFunction;
        return externalListFetchFunction({fullText})
          .then(data => {
            this.externalList = data.map(item => {
              if (item.hasOwnProperty('title')) {
                return {
                  value: `${item.id}-${item.name}`,
                  caption: `${item.id} - ${item.name}<br><small>${item.title}</small>`,
                }
              } else {
                return {
                  value: `${item.id}-${item.name}`,
                  caption: `${item.id} - ${item.name}`,
                }
              }
            })
          })
          .catch(error => {
            logger.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
        },
    }
};
</script>
