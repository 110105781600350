<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <router-link
    :to="{ name: pathName, params: { id } }"
    class="flex"
  >
    <!--========== IMAGE =================-->
    <div class="flex items-center justify-center w-20 h-20 md:w-24 md:h-24 bg-gray-100 overflow-hidden rounded-lg">
      <img
        v-if="thumbnail"
        :src="thumbnail"
        :alt="thumbnailAlt"
        class="w-full h-full object-cover"
      />
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        class="w-10 h-10 text-gray-300"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
        />
      </svg>
    </div>

    <div class="flex-1 pl-4">
      <!--========== CONTENT ===============-->
      <t-tag class="mb-2 pt-1 text-sm md:text-base leading-4 font-medium">
        {{ title }}
      </t-tag>

      <!--========== DATE + CATEGORY =======-->
      <div class="flex flex-wrap">
        <span class="text-gray-400 text-xs md:text-sm mr-2 whitespace-nowrap">{{ date }}</span>
        <prj1018-fest-badge
          v-if="categoryId"
          :categoryId="categoryId"
          :themeTitle="themeTitle"
          iconClasses="h-4 w-4"
          hasCategoryIcon
          hasCategoryHash
        />
      </div>
    </div>
  </router-link>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import '@/utils/dayjs'

import Prj1018FestBadge from '@/components/fest/prj1018-fest-badge/prj1018-fest-badge'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1004ArticleItem',
  components: { Prj1018FestBadge },
  props: {
    article: {
      type: Object,
      required: true,
    },
    pathName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      id: this.article.id,
    }
  },
  computed: {
    categoryId() {
      return this.article.categoryId
    },
    themeId() {
      return this.article.themeId
    },
    themeTitle() {
      return this.article.themeTitle
    },
    title() {
      return this.article.title?.rendered || this.article.title
    },
    featuredWPMedia() {
      return this.article._embedded && this.article._embedded['wp:featuredmedia'] ? this.article._embedded['wp:featuredmedia'][0] : null
    },
    featuredMediaDetails() {
      return this.featuredWPMedia?.media_details
    },
    thumbnailAlt() {
      return this.featuredWPMedia ? this.featuredWPMedia.alt_text : this.title
    },
    thumbnail() {
      return this.featuredMediaDetails?.sizes?.thumbnail?.source_url || this.article.image || null
    },
    date() {
      return this.$date(this.article.date || this.article.created).format('L')
    }
  },
}
</script>
