<template>
  <page-layout>
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <template v-slot:contentMain>
      <div class="vue-survey-thank-you">
        <img
          v-if="surveyLoaded && typeof surveyContent.surveyLogoUrl.value !== 'undefined'"
          class="vue-survey-logo"
          :src="surveyContent.surveyLogoUrl.value"
          alt="Survey logo"
        >

        <div class="vue-survey-success" />

        <div
          v-html="surveyContent.surveyThankYouContent.value"
        />
      </div>
    </template>

    <template v-slot:buttonFixed>
      <frm1006-button
        class="vue-button-page-layout-fixed"
        type="primary"
        @buttonClickEvent="goToDashboard"
      >
        {{ $t('survey.backToDashboard') }}
      </frm1006-button>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import router from '@/router';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

export default {
  name: 'SurveyThankYou',
  components: {
    PageLayout,
    Prj1002Navbar
  },

  mixins: [
    mxNavigationDefault
  ],

  computed: {
    ...mapState('survey', [
      'surveyLoaded',
      'surveyHasError',
      'surveyContent'
    ]),
  },

  methods: {
    goToDashboard() {
      router.push({ name: 'dashboard' });
    }
  }
}
</script>
