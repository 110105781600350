<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar :route-name="ROUTE_COMPETITIONS" />
        </template>
        <template v-slot:content>
          <div
            v-if="competitionResultsPage"
            class="prj-layout-default h-full"
          >
            <header class="mb-8 xs:mt-6 md:mb-10">
              <t-tag
                variant="sectionTitle"
                class="mb-8"
                tag-name="h1"
              >
                {{ title }}
              </t-tag>
              <p class="inline-block px-4 py-2 bg-gray-100 rounded-lg">{{ competitionDuration() }}</p>
            </header>

            <!--========== PAGE BODY ==============================-->
            <!--===================================================-->
            <section
              class="prj-article prj-article-extend-fest mb-8"
              v-html="content"
            ></section>
          </div>
        </template>

        <template v-slot:footer>
          <div
            v-if="storiesOfWinners"
            class="prj-controls w-full"
          >
            <t-button
              class="w-full bg-fest-home"
              @click="toWinningStories"
            >
              {{ $t('fest.competitionResults.buttonPrimary') }}
            </t-button>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<script>
//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex'
import { FEST_COMPETITIONS } from '@/store/store-modules'
import { GET_COMPETITIONS_INFO } from '@/store/constants/fest/actions'

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import utilsGeneral from '@/utils/fest/utils-general'
import apiCmsContent from '@/fest-api/cms-content'
import logger from '@/utils/logger'

import { ROUTE_COMPETITIONS } from '@/constants/fest/route-names'

import PageLayout from '@/templates/fest/page-layout'
import Prj1005NavigationBar from '@/components/fest/prj1005-navigation-bar/prj1005-navigation-bar'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTCompetitionResult',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout,
    Prj1005NavigationBar,
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      ROUTE_COMPETITIONS,
      competitionId: null,
      competitionDetail: null,
      competitionResultsPage: null,
      storiesOfWinners: false,
    }
  },
  computed: {
    ...mapState('festCompetitions', ['competitionsInfo']),
    title() {
      return this.competitionResultsPage?.title.rendered
    },
    content() {
      return this.competitionResultsPage?.content.rendered
    },
  },
  async created() {
    this.competitionId = parseInt(this.$route.params.competitionId)
    if (!this.competitions?.length) await this.$store.dispatch(`${FEST_COMPETITIONS}/${GET_COMPETITIONS_INFO}`)
    this.competitionDetail = this.competitionsInfo?.find((competition) => competition.id === this.competitionId)
    this.getAfterPage()
  },
  methods: {
    getAfterPage() {
      const pageId = this.competitionDetail?.afterPages?.ID

      if (!pageId) return

      const processId = utilsGeneral.startAsyncProcess()
      apiCmsContent
        .getPageById(pageId)
        .then((response) => {
          this.competitionResultsPage = response
        })
        .catch((error) => {
          logger.error(error)
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId)
        })
    },
    /*
    TODO JBa winning stories logic:
      1. push new object with winning stories to store OR
      2. include param for filter in url
   */
    toWinningStories() {
      logger.info('winning stories', this.competitionId);
    },
    competitionDuration() {
      const start = this.competitionDetail.startDate
      const end = this.competitionDetail.endDate

      const startDate = this.$date(start).format('L')
      const endDate = this.$date(end).format('L')

      return `${this.$t('fest.competitionResults.competitionRun.from')} ${startDate} ${this.$t('fest.competitionResults.competitionRun.to')} ${endDate}`
    },
  },
}
</script>
