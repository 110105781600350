import axiosConfig from '@/utils/data-loader/axiosConfig'
import * as envConfig from 'env-config'

export default {
  getAllThemes() {
    return axiosConfig.get(envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_THEMES).then(response => response.data);
  },
  getThemeById(id) {
    return axiosConfig.get(`${envConfig.default.webServices.FEST_WEB_SERVICES.CMS_CONTENT_THEMES}/${id}`).then(response => response.data);
  },
}
