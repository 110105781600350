import Gen1004TagLabel from './gen1004-tag-label';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1004TagLabel);
    }
};

use(Plugin);

export default Plugin;

export { Gen1004TagLabel };
