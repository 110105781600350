<template>
  <!-- TODO MBU: remove vue-heading class - refactor needed - styling in various modules relies on this style -->
  <div class="vue-c-heading vue-heading">
    <div class="vue-heading-container">
      <h1
        v-if="header && !detailheader"
        class="vue-header"
        v-html="header"
      >
      </h1>
      <h1
        v-if="detailheader"
        class="vue-detail-header"
        v-html="detailheader"
      >
      </h1>
      <h2
        v-if="subheader"
        class="vue-subheader"
        v-html="subheader"
      >
      </h2>
      <div
        v-if="description"
        class="vue-header-description"
        v-html="description"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prj1017Heading',
  components: {
  },

  props: {
    header: {
      type: String,
      required: false,
    },
    subheader: {
      type: String,
      required: false,
    },
    detailheader: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    }
  },
}
</script>
