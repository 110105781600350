<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :button-back-type="'none'"
        :building="false"
        @activeView="buttonBackEvent"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        id="vue-m-strategy-competition-intro"
        ref="strategyContainer"
        :class="classObject"
        class="vue-strategy-page-layout"
      >
        <gen1009-overlay
          :active="isIntroModalOpen"
          :buttonClose="false"
        >
          <div v-html="introDescription" />

          <frm1006-button
            type="primary"
            @buttonClickEvent="introModalClose"
          >
            {{ $t('strategy.buttonQuizConfirm') }}
          </frm1006-button>
        </gen1009-overlay>

        <div class="vue-intro-content vue-competition-intro">
          <transition
            name="vue-anim-strategy-move-from-top"
            appear
          >
            <div class="vue-intro-illustration vue-intro-illustration-quiz" />
          </transition>

          <transition
            name="vue-anim-strategy-move-from-right"
            appear
          >
            <gen1006-info-panel
              v-transition-delay-enter="300"
              class="vue-competition-result-counter"
              type="neutral"
            >
              <div class="vue-caption">
                {{ $t('strategy.quizStatus') }}
              </div>
              <div class="vue-filled-counter">
                {{ competitionResultsCount }}
              </div>
            </gen1006-info-panel>
          </transition>

          <transition
            name="vue-anim-strategy-move-from-right"
            appear
          >
            <div class="vue-strategy-buttons">
              <frm1006-button
                class="vue-button"
                type="primary"
                :disabled="buttonDisabled"
                @buttonClickEvent="buttonCompetitionStart"
              >
                {{ buttonText }}
              </frm1006-button>
            </div>
          </transition>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import PageLayout from '@/templates/partials/page-layout';
import * as STRATEGY_COMPETITION from '@/assets/mocks/strategyCompetitionData';
import { mapState } from 'vuex';
import router from '@/router';
import { STRATEGY_COMPETITION_ACTIVE, STRATEGY_DEFAULT_LANG } from '@/constants/app-constants';
import dataLoader from '@/utils/data-loader';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'StrategyCompetitionIntro',
  components: {
    Prj1002Navbar,
    PageLayout
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      competitionResultsCount: 0,
      competitionResultsCountLoaded: false,
      competitionData: STRATEGY_COMPETITION.DATA_ARRAY.competition,
      isIntroModalOpen: true
    }
  },

  computed: {
    ...mapState('competition', [
      'competitions'
    ]),

    buttonDisabled() {
      if(this.quizCompleted && this.quizResult && this.quizSent) {
        return true;
      } else {
        return false;
      }
    },

    buttonText() {
      if(!this.quizCompleted) {
        return this.$i18n.t('strategy.enter');
      } else {
        if(this.quizResult && this.quizSent) {
          return this.$i18n.t('strategy.quizSent');
        } else if(this.quizResult && !this.quizSent) {
          return this.$i18n.t('strategy.sendResult');
        } else if(!this.quizResult && !this.quizSent) {
          return this.$i18n.t('strategy.tryAgain');
        } else {
          return this.$i18n.t('strategy.enter');
        }
      }
    },

    classObject() {
      return [
        'vue-is-' + this.getSelectedLang,
      ];
    },

    getSelectedLang() {
      return this.$i18n.locale;
    },

    introTitle() {
      let lang = this.getSelectedLang;
      if( Object.prototype.hasOwnProperty.call(this.competitionData, lang) ) {
        return this.competitionData[lang]['title'];
      }
      return this.competitionData[STRATEGY_DEFAULT_LANG]['title'];
    },

    introDescription() {
      let lang = this.getSelectedLang;
      if( Object.prototype.hasOwnProperty.call(this.competitionData, lang) ) {
        return this.competitionData[lang]['description'];
      }
      return this.competitionData[STRATEGY_DEFAULT_LANG]['description'];
    },

    quizCompleted() {
      if (typeof this.competitions.data[STRATEGY_COMPETITION_ACTIVE] === 'undefined') {
        return false;
      }
      return this.competitions.data[STRATEGY_COMPETITION_ACTIVE].summaryTimestamp !== null;
    },

    quizSent() {
      if (typeof this.competitions.data[STRATEGY_COMPETITION_ACTIVE] === 'undefined') {
        return false;
      }
      return this.competitions.data[STRATEGY_COMPETITION_ACTIVE].sentResult === 2;
    },

    quizResult() {
      if (typeof this.competitions.data[STRATEGY_COMPETITION_ACTIVE] === 'undefined') {
        return false;
      }
      if (this.competitions.data[STRATEGY_COMPETITION_ACTIVE].result === null) {
        return false;
      }
      return this.competitions.data[STRATEGY_COMPETITION_ACTIVE].result;
    }
  },

  mounted() {
    if (!this.competitionResultsCountLoaded) {
      this.getCompetitionResults();
    }
    this.sendStatistics('STRATEGY-QUIZ-INTRO');
  },

  methods: {
    buttonCompetitionStart() {
      if(!this.quizCompleted) {
        router.push({ name: 'strategy-competition'});
      } else {
        if(this.quizResult && !this.quizSent) {
          router.push({ name: 'strategy-competition-registration'});
        } else if(!this.quizResult && !this.quizSent) {
          this.resetQuizResults();
          this.$nextTick(() => {
            router.push({ name: 'strategy-competition'});
          });
        }
      }
    },

    buttonBackEvent() {
      router.push({ name: 'strategy'});
    },

    introModalClose() {
      this.isIntroModalOpen = !this.isIntroModalOpen
    },

    getCompetitionResults() {
      dataLoader.fetchCompetitionResultsCount()
        .then(data => {
          this.competitionResultsCount = data.count;
          this.competitionResultsCountLoaded = true;
        });
    },

    resetQuizResults() {
      this.$store.commit(STORE_MODULES.COMPETITION + '/' + MUTATIONS_CONSTANTS.SET_COMPETITION_RESET);
    },
  },
}
</script>
