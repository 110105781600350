//============ GENERAL =================================//
//======================================================//
import { SET_COMPETITIONS_INFO, SORT_COMPETITIONS_INFO } from '@/store/constants/esg/mutations'
import { GET_COMPETITIONS_INFO } from '@/store/constants/esg/actions'
import { EXPIRED } from '@/constants/esg/status'
import { sortingEvents } from '@/utils/esg/sorting'

//============ API =====================================//
//======================================================//
import apiCmsCompetitions from '@/esg-api/cms-competitions'
import logger from '@/utils/logger'

//============ STATE ===================================//
//======================================================//
const state = {
  competitionsInfo: [],
  sortCompetitionsInfo: [],
  competitionsCount: null,
  activeCompetitionsCount: null,
}

//============ MUTATIONS ===============================//
//======================================================//
const mutations = {
  [SET_COMPETITIONS_INFO](state, payload) {
    state.competitionsInfo = [...payload] // FIXME missing linked challenges from BE, is it possible to deliver?
    state.competitionsCount = payload.length
    state.activeCompetitionsCount = payload.filter((item) => item.status !== EXPIRED).length
  },
  [SORT_COMPETITIONS_INFO](state, payload) {
    state.sortCompetitionsInfo = sortingEvents(payload)
  },
}

//============ ACTIONS =================================//
//======================================================//
const actions = {
  async [GET_COMPETITIONS_INFO]({ commit }) {
    try {
      const response = await apiCmsCompetitions.getAllCompetitions()
      commit(SET_COMPETITIONS_INFO, response)
      commit(SORT_COMPETITIONS_INFO, response)
    } catch (error) {
      logger.error(error)
    }
  },
}

//============ GETTERS =================================//
//======================================================//
const getters = {}

//============ EXPORT ==================================//
//======================================================//
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
