//============ GENERAL =================================//
//======================================================//
import {
  SET_VIEWPORT_SIZE,
  START_PROCESS,
  STOP_PROCESS,
  SET_SW_REGISTRATION,
  SET_SW_INSTANCE,
  SET_APP_UPDATE_READY,
  SET_APP_UPDATE_FAILED,
  SET_NETWORK_ERROR
} from '@/store/constants/fest/mutations';
import logger from '@/utils/logger';

//============ STATE ===================================//
//======================================================//
const state = {
  viewportSize: {
    windowHeight: 0,
    windowWidth: 0,
    height: 0,
    width: 0,
    correctionConstant: 0
  },
  processesRunning: [],
  swRegistration: null,
  swInstance: null,
  swInstanceWaiting: null,
  appUpdateReady: false,
  appUpdateFailed: false,
  networkErrorLastDismiss: 0,
  networkError: false,
};

//============ MUTATIONS ===============================//
//======================================================//
const mutations = {
  [SET_VIEWPORT_SIZE](state, payload) {
    state.viewportSize.windowHeight = payload.windowHeight;
    state.viewportSize.windowWidth = payload.windowWidth;
    state.viewportSize.height = payload.height;
    state.viewportSize.width = payload.width;
    state.viewportSize.correctionConstant = payload.correctionConstant;
  },
  [START_PROCESS](state, processId) {
    state.processesRunning.push(processId);
  },
  [STOP_PROCESS](state, processId) {
    const loadingIdPosition = state.processesRunning.indexOf(processId);
    if (loadingIdPosition > -1) {
      state.processesRunning.splice(loadingIdPosition, 1);
    } else {
      logger.error(`Loading ID ${processId} does not exist!`);
    }
  },
  [SET_SW_REGISTRATION](state, registration) {
    state.swRegistration = registration;
  },
  [SET_SW_INSTANCE](state, instance) {
    state.swInstanceWaiting = null;
    state.swInstance = instance;
  },
  [SET_APP_UPDATE_READY](state, swInstanceWaiting) {
    state.appUpdateReady = true;
    state.swInstanceWaiting = swInstanceWaiting;
  },
  [SET_APP_UPDATE_FAILED](state) {
    state.appUpdateFailed = true;
  },
  [SET_NETWORK_ERROR](state, status) {
    state.networkError = status;
    if (!status) {
      state.networkErrorLastDismiss = new Date().getTime() / 1000;
    }
  },
};

//============ ACTIONS =================================//
//======================================================//

const actions = {}

//============ GETTERS =================================//
//======================================================//

const getters = {}

//============ EXPORT ==================================//
//======================================================//
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
