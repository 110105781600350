<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <li class="vue-calendar-item vue-is-month" :class="classObject" @click="calendarMonthClick(month, $event)">
        <span>
            <slot></slot>
        </span>
    </li>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ EXPORT ==================================//
//======================================================//

export default {
    name: 'Gen1010Day',
    props: {
        month: Number,
        todayMonthIndex: Number,
        todayYear: Number,
        selectedMonthIndex: Number,
        selectedYear: Number,
        currentMonthIndex: Number,
        currentYear: Number
    },
    computed: {
        classObject() {
            return {
                'vue-is-selected': this.selected,
                'vue-is-today': this.today
            };
        },
        selected() {
            return this.month === this.selectedMonthIndex && this.currentYear === this.selectedYear;
        },
        today() {
            return this.month === this.todayMonthIndex && this.currentYear === this.todayYear;
        }
    },
    methods: {
        calendarMonthClick(month, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('calendarMonthClickEvent', month, event);
        }
    }
};
</script>
