<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <!-- NOTE: click prevent default used - all actions must be handled manually -->
    <!-- here click would work with anchor - but developers could potentially forget to handle keyboard confirmation -->
    <li class="vue-menu-item" :class="classObject" role="option" aria-selected="false" @click.prevent="click(value)">
        <!--========== SIMPLE =================================-->
        <!--===================================================-->
        <template v-if="$scopedSlots.contextContainerMenuItem">
            <slot name="contextContainerMenuItem" :item="item" :index="index" />
        </template>
        <template v-else>
          <div v-html="caption" />
        </template>
    </li>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1011ContextContainerMenuItem',
    props: {
        item: Object,
        active: Boolean,
        selected: Boolean,
        disabled: Boolean,
        excluded: Boolean,
        index: {
            default: null,
            type: Number
        },
        value: String,
        caption: String,
        action: String,
        actionProps: {
            default: () => {
                return null;
            },
            type: Object
        },
        customCss: String,
        id: String
    },
    computed: {
        classObject() {
            return [
                this.customCss,
                {
                    'vue-is-active': this.active,
                    'vue-is-selected': this.selected,
                    'vue-is-disabled': this.disabled,
                    'vue-is-excluded': this.excluded
                }
            ];
        }
    },
    mounted() {
        this.$emit('itemMounted');
    },
    methods: {
        click(value) {
            if (!this.disabled) {
                this.$emit('clickEvent', value);
            }
        }
    }
};
</script>
