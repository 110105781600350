<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <prj1007-event-card
    :class="classObject"
    :image="image"
    :isSingle="isSingle"
    :status="status"
    :participants="participants"
    :statusTag="statusTag"
    :isClickable="isClickable"
    :isModeOverlay="isModeOverlay"
    :isHighlighted="isHighlighted"
    v-bind="getDynamicProps()"
    @cardClick="onCardClick"
  >
    <!--========== STAMPS =================================-->
    <!--===================================================-->
    <template v-slot:stamps>
      <prj1018-esg-badge
        v-if="categoryId"
        :categoryId="categoryId"
        :themeTitle="themeTitle"
        iconClasses="h-4 w-4"
        hasBg
        hasCategoryIcon
      />
    </template>

    <!--========== HEADER =================================-->
    <!--===================================================-->
    <template v-slot:header>
      <t-tag
        :variant="isModeOverlay ? 'blockTitle' : 'sectionTitle'"
        class="flex text-white line-clamp-2 my-3 xs:my-7"
        tag-name="h2"
      >
        <svg
          viewBox="0 0 43 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="inline-block relative mr-2 text-white"
          :class="[isModeOverlay ? '-top-0.5 h-7 w-7' : '-top-1 h-8 w-11']"
        >
          <path
            d="M14.1009 0.590907H8.76989L0.332386 31.9375H5.66335L14.1009 0.590907ZM28.5501 0.590907H23.2191L14.7816 31.9375H20.1126L28.5501 0.590907ZM42.9993 0.590907H37.6683L29.2308 31.9375H34.5618L42.9993 0.590907Z"
            fill="currentColor"
          />
        </svg>
        <span v-html="title"></span>
      </t-tag>
    </template>

    <!--========== CONTENT ================================-->
    <!--===================================================-->
    <template v-slot:content>
      <p
        v-if="description"
        class="line-clamp-5 xs:line-clamp-8"
      >
        {{ description }}
      </p>

      <a
        v-if="hasLink"
        class="relative flex items-center mb-4"
        target="_blank"
        :href="linkUrl"
      >
        <span
          class="mr-2"
          v-html="getEventIcon()"
        ></span>

        <span class="font-semibold">{{ link }}</span>
      </a>
    </template>

    <!--========== CONTROLS ===============================-->
    <!--===================================================-->
    <template
      v-if="!isModeOverlay"
      v-slot:controls
    >
      <t-button
        :disabled="isPrimaryBtnDisabled"
        class="mb-2 w-full"
        @click="clickedPrimary"
      >
        {{ $t('esg.themes.details') }}
      </t-button>
    </template>
  </prj1007-event-card>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
import { DEFAULT, WAITING, RUNNING, EXPIRED } from '@/constants/esg/status'
import eventIcons from '@/assets/esg/events/eventIcons'

import Prj1018EsgBadge from '@/components/esg/prj1018-esg-badge/prj1018-esg-badge'
import Prj1007EventCard from '@/components/esg/prj1007-event-card/prj1007-event-card'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1007ThemeCard',
  components: { Prj1007EventCard, Prj1018EsgBadge },
  props: {
    themeTitle: {
      type: String,
      default: null,
    },
    categoryId: {
      type: Number,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    isFullPage: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    isPrimaryBtnDisabled: {
      type: Boolean,
      default: false,
    },
    isSecondaryBtnDisabled: {
      type: Boolean,
      default: false,
    },
    isResultReady: {
      type: Boolean,
      default: false,
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
    linkUrl: {
      type: String,
      default: null,
    },
    isModeOverlay: {
      type: Boolean,
      default: false
    },
    participants: {
      type: Number,
      default: null,
    },
    status: {
      type: String,
      default: DEFAULT,
      validator: (value) => [DEFAULT, WAITING, RUNNING, EXPIRED].includes(value),
    },
    statusTag: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      eventIcons,
    }
  },
  computed: {
    classObject() {
      return {
        'h-11/12 my-auto': this.isFullPage,
        'h-vh-30': this.isModeOverlay,
      }
    },
    hasLink() {
      return this.link && this.linkUrl
    },
    isDefault() {
      return this.status === DEFAULT
    },
    isWaiting() {
      return this.status === WAITING
    },
    isRunning() {
      return this.status === RUNNING
    },
    isExpired() {
      return this.status === EXPIRED
    },
  },
  methods: {
    getDynamicProps() {
      const result = {}
      if (this.isModeOverlay) result.widthClass = 'w-3/5'
      return result
    },
    clickedPrimary() {
      this.$emit('primaryButton')
    },
    clickedSecondary() {
      this.$emit('secondaryButton')
    },
    onResultClick() {
      this.$emit('resultClick')
    },
    onCardClick() {
      this.$emit('cardClick')
    },
    getEventIcon() {
      return this.eventIcons.find((icon) => icon.name === this.icon)?.icon
    },
  },
}
</script>
