<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <span :id="id" class="vue-component vue-c-icon" :title="title" :aria-hidden="ariaHidden" :class="classObject">
        <slot></slot>
    </span>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
export default {
    name: 'Gen1023Icon',
    props: {
        name: String,
        iconSet: String,
        title: String,
        ariaHidden: Boolean,
        id: String
    },
    computed: {
        classObject() {
            return {
                [this.name]: this.name,
                [this.iconSet]: this.iconSet
            };
        }
    }
};
</script>
