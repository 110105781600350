<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <template v-if="hasIdentity">
          <div class="vue-b-profile-photo">
            <div class="vue-photo-image-wrap">
              <img
                v-if="profilePhotoUrl || uploadedPhoto"
                class="vue-photo-image"
                :src="uploadedPhoto ? uploadedPhoto : profilePhotoUrl"
              />

              <prj1010-image-upload
                :cropper="true"
                :acceptFiles="['image/jpeg', 'image/png']"
                @uploadedPhoto="uploadPhoto"
              />
            </div>
          </div>

          <!-- List items -->
          <div class="vue-b-profile-details">
            <!-- Item -->
            <div class="vue-b-profile-detail">
              <span class="vue-profile-detail-title">{{ $t('profile.workEmail') }}</span>
              <div class="vue-profile-detail-content">
                <span class="vue-profile-detail-content-text">{{ profileWorkEmail }}</span>
              </div>
            </div>

            <!-- Item -->
            <div class="vue-b-profile-detail">
              <span class="vue-profile-detail-title">{{ $t('profile.fullName') }}</span>
              <div class="vue-profile-detail-content">
                <span class="vue-profile-detail-content-text">{{ profileName }}</span>
                <a
                  class="vue-profile-detail-content-link"
                  @click="goToView('profile-edit-name')"
                ></a>
              </div>
            </div>

            <!-- Item -->
            <div class="vue-b-profile-detail">
              <span class="vue-profile-detail-title">{{ $t('profile.phone') }}</span>
              <div class="vue-profile-detail-content">
                <span class="vue-profile-detail-content-text">{{ profilePhone }}</span>
                <a
                  class="vue-profile-detail-content-link"
                  @click="goToView('profile-edit-phone')"
                ></a>
              </div>
            </div>

            <!-- Item -->
            <div class="vue-b-profile-detail">
              <span class="vue-profile-detail-title">{{ $t('profile.locality') }}</span>
              <div class="vue-profile-detail-content">
                <frm1028-advanced-combo
                  v-if="applicationConfigLoaded"
                  v-model="selectedBuilding"
                  class="vue-navbar-dropdown vus-custom-style"
                  importance="normal"
                  :options="convertedLocalities"
                  type="internal"
                  :tooltip="false"
                  contextContainerCustomCss="vue-is-simple vue-is-position-top"
                  @change="updateBuilding"
                />
              </div>
            </div>
          </div>
        </template>


        <gen1006-info-panel
          v-else
          type="error"
        >
          {{ $t('profile.profileNonexistent') }}
        </gen1006-info-panel>

        <gen1006-info-panel
          v-if="!isLoading && !initialized"
          type="neutral"
        >
          {{ $t('general.loadingFailed') }}
        </gen1006-info-panel>
      </template>
    </template>

    <template
      v-if="hasIdentity"
      v-slot:contentRest
    >
      <prj1020-separator />
      <div class="vue-b-profile-links">
        <frm1006-button
          class="vue-navigation-info-btn"
          type="app-button"
          @buttonClickEvent="goToView('profile-cancel-account')"
        >
          {{ $t('profile.buttonCancelAccount') }}
        </frm1006-button>
      </div>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import router from '@/router';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';

import PageLayout from '../../templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1020Separator from '@/components/prj1020-separator/prj1020-separator';
import Prj1010ImageUpload from '@/components/prj1010-image-upload/prj1010-image-upload';

import mxNavigationDefault from '../../mixins/mx-navigation-default';

export default {
  name: 'Profile',

  components: {
    Prj1010ImageUpload,
    Prj1020Separator,
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      uploadedPhoto: null
    }
  },

  computed: {
    ...mapState('user', ['profile']),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),
    ...mapState('dashboard', {
      storedSelectedBuilding: state => state.selectedBuilding
    }),
    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY
    }),

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },

    profileWorkEmail() {
      return this.profile.workEmail;
    },

    profilePhone() {
      return this.profile.phone || '-';
    },

    profileName() {
      return this.profile.name;
    },

    profilePhotoUrl() {
      return this.profile.photoUrl;
    },

    selectedBuilding: {
      get() {
        return this.storedSelectedBuilding;
      },
      set(value) {
        // this.selectedBuilding must be a string
        this.$store.commit(STORE_MODULES.DASHBOARD + '/' + MUTATIONS_CONSTANTS.SET_SELECTED_BUILDING, value.toString());
      }
    },

    convertedLocalities() {
      let localitiesSettings = [];

      this.applicationConfig.localities.forEach(item => {
        let itemObject = {};

        itemObject.caption = item.name;
        itemObject.alias = item.alias;
        itemObject.value = item.id;
        itemObject.navigationId = item.navigationId;
        itemObject.customCss = item.cssClass;

        localitiesSettings.push(itemObject);
      });

      return localitiesSettings;
    },

    isBuildingSet() {
      return typeof this.selectedBuilding !== 'undefined';
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.initializePage();
  },

  methods: {
    initializePage() {
      this.initialized = true;
    },

    refreshPhoto(photo) {
      this.uploadedPhoto = photo;
    },

    uploadPhoto(photo) {
      let formData = new FormData();
      formData.append('photo', photo);

      this.sending = true
      dataLoader
        .postProfilePhoto(formData)
        .then(() => {
          logger.info('Form has been sent.')
          this.sending = false

          this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.IDENTITY_PROFILE, {force: true});
          this.refreshPhoto(photo);
        })
        .catch((error) => {
          this.sending = false
          this.hasError = true
          logger.error('Sending form has failed.', error.response)
        });
    },

    goToView(name) {
      router.push({ name: name });
    },

    updateBuilding() {
      const options = {
        selectedBuilding: (this.isBuildingSet ? this.selectedBuilding : '2'),
        force: true
      };

      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.GET_APPLICATION_SETTINGS, options);
      this.submitBuilding(options.selectedBuilding);
    },

    submitBuilding(locality) {
      let formData = new FormData();
      formData.append('locality', locality);

      this.sending = true
      dataLoader
        .putIdentityProfile(formData)
        .then(() => {
          logger.info('Form has been sent.')
          this.sending = false
        })
        .catch((error) => {
          this.sending = false
          this.sendFailed = true
          logger.error('Sending form has failed.', error.response)
        });
    },
  }
}
</script>
