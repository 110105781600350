import Gen1017Controls from './gen1017-controls';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1017Controls);
    }
};

use(Plugin);

export default Plugin;

export { Gen1017Controls };
