<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :button-back-type="'none'"
        @activeView="buttonBackEvent"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-strategy-page-layout">
        <div
          v-if="strategyTargets"
          class="vue-c-strategy-target vue-is-target-illustration"
          :class="`vue-is-target-${strategyTargets.illustration}`"
        >
          <transition
            name="vue-anim-strategy-move-from-right"
            appear
          >
            <div class="vue-b-header vue-strategy-container">
              <h2
                class="vue-title"
                :class="`vue-navigation-text-${strategyTargets.color}`"
              >
                {{ strategyTargets.title }}
              </h2>
              <p class="vue-subtitle">
                {{ strategyTargets.subtitle }}
              </p>
            </div>
          </transition>

          <transition
            name="vue-anim-strategy-move-from-right"
            appear
          >
            <div
              class="vue-b-content vue-strategy-container"
              :set="counter = 0"
            >
              <div
                v-for="(value) in strategyTargets.contentItems"
                :key="value.id"
                class="vue-value"
                :class="excludedValueClassObject(value.bulletExcluded)"
              >
                <template v-if="!value.bulletExcluded">
                  <div
                    class="vue-target-header-number"
                    :class="`vue-navigation-bg-${strategyTargets.color}`"
                    :set="counter = counter + 1"
                  >
                    {{ counter }}
                  </div>
                </template>
                <template v-else>
                  <div :set="counter = 0"></div>
                </template>
                <div>
                  <div
                    class="vue-value-header"
                    v-html="firstLetterEscape(value.header, value.lettersEscape)"
                  />
                  <div
                    v-for="valueItem in value.items"
                    :key="valueItem.id"
                    class="vue-value-body"
                    v-html="valueItem.body"
                  />
                  <template v-if="value.pearls">
                    <div
                      v-if="value.subheader"
                      class="vue-value-body"
                      v-html="value.subheader"
                    />
                    <div class="vue-value-body">
                      <div
                        v-for="pearl in value.pearls"
                        :key="pearl.id"
                        class="vue-value"
                        :class="excludedValueClassObject(pearl.bulletExcluded)"
                      >
                        <div
                          v-if="!pearl.bulletExcluded"
                          class="vue-value-bullet"
                        >
                          {{ bulletContent(pearl.header) }}
                        </div>
                        <div>
                          <div
                            class="vue-value-header"
                            v-html="firstLetterEscape(pearl.header, pearl.lettersEscape)"
                          />
                          <div
                            v-for="valueItem in pearl.items"
                            :key="valueItem.id"
                            class="vue-value-body"
                            v-html="valueItem.body"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div class="vue-strategy-navigation">
                <frm1006-button
                  class="vue-navigation-icon vue-navigation-icon-prev"
                  type="internal"
                  :disabled="!hasPrevStep"
                  @buttonClickEvent="goBackward"
                />
                <frm1006-button
                  class="vue-navigation-icon vue-navigation-icon-next"
                  type="internal"
                  :disabled="!hasNextStep"
                  @buttonClickEvent="goForvard"
                />
              </div>
            </div>
          </transition>

          <transition
            :name="transitionDetailName"
            appear
          >
            <div
              v-transition-delay-enter="200"
              class="vue-detail-illustration"
            />
          </transition>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script type="application/javascript">

//=== PRJ
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

//=== CONSTANTS
import * as STRATEGY from '@/assets/mocks/strategyData';
import {STRATEGY_DEFAULT_LANG} from '@/constants/app-constants';
import PageLayout from '@/templates/partials/page-layout';
import router from '@/router';

export default {
  name: 'StrategyDetail',
  components: {
    Prj1002Navbar,
    PageLayout
  },

  data() {
    return {
      strategyData: STRATEGY.DATA_ARRAY.slides,
      strategyId: 0,
      strategyTargets: null,
      strategyTargetsLenght: 0,
      isLoading: true,
      initialized: false,
    }
  },

  computed: {
    transitionDetailName() {
      return 'vue-anim-strategy-detail-' + this.strategyTargets.illustration
    },

    hasPrevStep() {
      return this.strategyId > 1
    },

    hasNextStep() {
      return this.strategyId < this.strategyTargetsLenght
    },

    getSelectedLang() {
      return this.$i18n.locale;
    },

    routeLang() {
      return this.$route.params?.lang
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },

    strategyId() {
      this.loadTargetsData(this.strategyId);
      //router.push({ name: 'strategy-detail', params: { id:this.strategyId}});
    }
  },

  mounted() {
    this.strategyId = parseInt(this.$route.params.id);
    this.loadTargetsData(this.strategyId);
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    loadTargetsData(strategyId) {
      if (strategyId > 0 && this.strategyData.length === 1) {
        let strategyDataJSON = this.strategyData[0];
        let tmpLangApp = this.$i18n.locale;
        if (typeof this.routeLang !== 'undefined') {
          tmpLangApp = this.routeLang;
        }
        if (!Object.prototype.hasOwnProperty.call(strategyDataJSON, tmpLangApp)) {
          tmpLangApp = STRATEGY_DEFAULT_LANG;
        }
        this.strategyTargetsLenght = Object.keys(strategyDataJSON[tmpLangApp]['items']).length;
        for (let key of Object.keys(strategyDataJSON[tmpLangApp]['items'])) {
          key = parseInt(key);
          if (key === strategyId) {
            this.strategyTargets = (key === strategyId ? strategyDataJSON[tmpLangApp]['items'][key] : false);
            this.initialized = true;
          }
        }
      }
    },
    bulletContent(text) {
      return text.charAt(0)
    },

    excludedValueClassObject(excluded) {
      return [
        {
          'vue-is-bullet-excluded': excluded,
        }
      ]
    },

    firstLetterEscape(value, escape) {
      return escape ? value.substring(escape).trim() : value;
    },

    buttonBackEvent() {
      if (window.history.length <= 1) {
        router.push({ name: 'strategy', params: { nav: 'current-objectives' } });
      } else {
        router.go(-1);
      }
    },

    bulletNumbers(items) {
      let number = 0;
      return items.map(item => item.bulletExcluded ? null : ++number)
    },

    goBackward() {
      this.strategyId--;
    },

    goForvard() {
      this.strategyId++;
    },
  },
}
</script>
