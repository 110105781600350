<template>
  <div
    class="vue-b-tutorial-item vue-is-tutorial-init"
    :class="'vue-step-' + slide"
  >
    <gen1009-overlay :active="true">
      <h1 class="vue-overlay-heading">
        {{ $t('tutorial.dashboard.' + slide + '.title') }}
      </h1>
      <p>{{ $t('tutorial.dashboard.' + slide + '.content') }}</p>

      <a
        v-if="slide === 5"
        ref="tutorialSmile"
        class="vue-tutorial-smile"
        :class="classObject"
        @click="switchAnimation"
      />
      <slot />
    </gen1009-overlay>

    <div class="vue-highlighted-element">
      <span class="vue-highlighted-element-behavior" />
      <span class="vue-highlighted-element-behavior" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prj1034TutorialDashboardInit',

  props: {
    slide: Number
  },
    
  data() {
    return {
      smileFirstView: true,
      smileClicked: false
    }
  },

  computed: {
    classObject() {
      return {
        'vue-has-first-animation': this.smileFirstView,
        'vue-has-animation': this.smileClicked
      };
    },
  },

  methods: {
    switchAnimation() {
      this.smileClicked = true;
      this.smileFirstView = false;

      this.$refs.tutorialSmile.addEventListener('animationend', () => {
        this.smileClicked = false;
      })
    }
  }
}
</script>