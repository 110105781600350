<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div
          class="vue-b-crisis-content"
        >
          <div class="vue-numbered-expandible-list">
            <gen1015-section
              v-for="(item, index) in listItems"
              :key="item.id"
              :collapsible="item.content !== ''"
              :collapsed="true"
              class="is-animated"
            >
              <template v-slot:header>
                <h3 :data-order="index + 1">{{ item.title }}</h3>
              </template>
              <template v-slot:content>
                <div class="vue-b-content-animated">
                  <div
                    class="vue-c-article-body vue-wcm-content"
                    v-html="item.content"
                  />
                </div>
              </template>
            </gen1015-section>
          </div>
        </div>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

export default {
  name: 'EmergencyCrisisSituationDetail',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      navbarCaptionData: null,
      ERROR_MODAL_TIMEOUT,
      pageSlug: 'krizove-situace/',
      listItems: null,
    };
  },

  computed: {
    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },

    routeId() {
      return this.$route.params.id
    },

    routeSlug() {
      return this.$route.params.slug;
    },

    routeData() {
      return this.$route.params.data
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value && this.listItems) {
        this.setLoadingState(false);
      }
    },
  },

  mounted() {
    if(!this.listItems) {
      this.getPageContent();
    }

    this.initialized = true;
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getPageContent() {
      this.setLoadingState(true);

      return dataLoader.fetchEmergencyPage(this.pageSlug + this.routeSlug, this.selectedBuilding)
        .then(data => {
          if (data.hasOwnProperty('id')) {
            this.fetchListPages(data.id);
          }
        })
        .catch(error => {
          this.setLoadingState(false);
          logger.error(error);
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        });
    },

    fetchListPages(parentPageId) {
      dataLoader.fetchSubPages(parentPageId, false)
        .then(data => {
          this.listItems = data.items;
        })
        .catch(error => {
          logger.error(error);
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },
  },
}
</script>

