<template>
  <div
    :id="id"
    class="vue-component vue-c-map"
    :class="classObject"
  >
    <div class="vue-b-buildings">
      <div class="vue-b-building">
        <div class="vue-building-tag">
          {{ buildingActiveId }}
        </div>
      </div>
      <div
        v-if="floorActiveId"
        class="vue-floor-name"
      >
        {{ floorActiveId | convertStock }}. {{ $t("maps.stock") }}
      </div>
      <template v-for="(building, key) in mapData">
        <prj1009-map-building
          :key="key"
          :map-data="mapData"
          :building="building"
          :building-active-id="buildingActiveId"
          :floor-active-id="floorActiveId"
          :item-active-id="itemActiveId"
          :buildings-selected-id="buildingsSelectedId"
          :floors-selected-id="floorsSelectedId"
          :rooms-selected-id="roomsSelectedId"
          :center-map="centerMap"
          @buildingFloorClickEvent="mapBuildingFloorClickEmit"
        />
      </template>
      <div class="vue-b-control-position">
        <Frm1006Button
          class="vue-map-control-button vue-map-button-printer"
          @buttonClickEvent="showPrinters"
        />
        <Frm1006Button
          class="vue-map-control-button vue-map-button-report"
          @buttonClickEvent="report"
        />
        <Frm1006Button
          class="vue-map-control-button vue-map-button-zoom-in"
          @buttonClickEvent="zoomIn"
        />
        <Frm1006Button
          class="vue-map-control-button vue-map-button-zoom-out"
          @buttonClickEvent="zoomOut"
        />
        <Frm1006Button
          class="vue-map-control-button vue-map-button-center"
          @buttonClickEvent="resetPosition"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EVENT_BUS from '@/event-bus';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';

import Prj1009MapBuilding from './prj1009-map-building';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

const COMPONENT_EVENTS = Object.freeze({
  MAP_BUILDING_FLOOR_CLICK_EVENT: 'mapBuildingFloorClickEvent',
})

export default {
  name: 'Prj1009Map',
  components: {
    Prj1009MapBuilding,
  },
  props: {
    'mapData': {
      default: null,
      type: Object
    },
    'buildingActiveId': {
      default: null,
      type: String
    },
    'floorActiveId': {
      default: null,
      type: String
    },
    'itemActiveId': {
      default: null,
      type: String
    },
    'buildingsSelectedId': {
      default: null,
      type: Array
    },
    'floorsSelectedId': {
      default: null,
      type: Array
    },
    'roomsSelectedId': {
      default: null,
      type: Array
    },
    'id': {
      default: null,
      type: String
    },
    'centerMap': {
      default: false,
      required: false,
      type: Boolean
    }
  },
  data() {
    return {}
  },
  computed: {
    classObject() {
      return {
        [`vue-is-building-${this.buildingActiveId}`]: this.buildingActiveId,
      }
    },
  },
  methods: {
    mapBuildingFloorClickEmit(buildingId, floorId) {
      this.$emit(COMPONENT_EVENTS.MAP_BUILDING_FLOOR_CLICK_EVENT, buildingId, floorId);
    },
    resetPosition() {
      EVENT_BUS.$emit(GLOBAL_EVENTS.MAP_RESET_POSITION_EVENT);
    },
    zoomIn() {
      EVENT_BUS.$emit(GLOBAL_EVENTS.MAP_ZOOM_IN_EVENT);
    },
    zoomOut() {
      EVENT_BUS.$emit(GLOBAL_EVENTS.MAP_ZOOM_OUT_EVENT);
    },
    showPrinters() {
      EVENT_BUS.$emit(GLOBAL_EVENTS.MAP_SHOW_PRINTERS_EVENT);
    },
    report() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.$store.commit(STORE_MODULES.MAPS + '/' + MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_MODAL, true);
    }
  }
}
</script>
