<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:nav>
          <prj1005-navigation-bar route-name="sustainability-stories" />
        </template>
        <template v-slot:content>
          <div class="prj-layout-form">
            <!--========== HEADER =================================-->
            <!--===================================================-->
            <div class="">
              <t-tag
                variant="sectionTitle"
                class="xs:mt-6"
              >
                <!-- TODO replace from rest API -->
                {{ isCompetition ? $t('esg.competitions.forms.newStory') : $t('esg.stories.forms.newStory') }}
              </t-tag>

              <!-- TODO replace from rest API -->
              <p v-if="isCompetition">{{ $t('esg.competitions.forms.newStoryDescription') }}</p>

              <p v-else>{{ $t('esg.stories.forms.newStoryDescription') }}</p>
            </div>

            <!--========== FORM ===================================-->
            <!--===================================================-->
            <form>
              <prj1019-visual-modal-select
                containerClasses="w-full mb-4"
                :btnClasses="selectBtnClasses"
                :disabled="!!competitionThemeId"
                :resetBtnCaption="$t('esg.stories.forms.fieldThemeSelectionReset')"
                :activeSelection="story.themeId ? story.themeId : 0"
                :options="themesInfo"
                :overlayBtnCaption="themeOptionsPlaceholder"
                @select="setSelectedThemeId"
                @selectionReset="onSelectionReset"
              >
                <template v-slot:icon>
                  <svg
                    viewBox="0 0 24 24"
                    stroke="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.35362 6H7.28125L4 18.1903H6.07362L9.35362 6Z" />
                    <path d="M14.6636 6H12.5859L9.30469 18.1903H11.3836L14.6636 6Z" />
                    <path d="M19.9636 6H17.8906L14.6094 18.1903H16.6836L19.9636 6Z" />
                  </svg>
                </template>
              </prj1019-visual-modal-select>

              <t-textarea
                v-model="story.content"
                :placeholder="$t('esg.stories.forms.fieldMyStory')"
                rows="6"
                class="mb-4"
              />

              <prj1011-image-upload
                v-model="story.photoBase64"
                :cropper-aspect-ratio="4 / 5"
                :cropped-image-height="768"
                :placeholder="$t('esg.stories.forms.fieldStoryPhoto')"
              />

              <label class="flex items-center mb-4">
                <t-checkbox
                  v-model="consentsChecked"
                  name="options"
                />
                <span class="ml-2 text-sm">
                  {{ $t('esg.stories.forms.consents.policyData') }}
                  <button
                    type="submit"
                    class="prj-text-link"
                    @click.prevent="openPrivacyPolicy"
                  >
                    {{ $t('esg.stories.forms.consents.policyDataLink') }}
                  </button>
                </span>
              </label>
            </form>

            <!--========== CONTROLS ===============================-->
            <!--===================================================-->
            <div class="prj-controls">
              <t-button
                class="w-full"
                :disabled="!isActive"
                @click="sendStory()"
              >
                {{ $t('esg.forms.buttons.buttonSend') }}
              </t-button>
            </div>

            <prj1009-modal
              :active="policyModalShow"
              @overlayCloseEvent="closePolicyOverlay"
            >
              <!--========== TITLE ==================================-->
              <!--===================================================-->
              <template v-slot:title>
                {{ policyTitle }}
              </template>

              <!--========== CONTENT ================================-->
              <!--===================================================-->
              <template v-slot:content>
                <div class="flex flex-col">
                  <div v-html="policyContent" />
                </div>
              </template>

              <!--========== CONTROLS ===============================-->
              <!--===================================================-->
              <template v-slot:controls>
                <t-button
                  class="w-full"
                  @click="closePolicyOverlay"
                >
                  {{ $t('esg.forms.buttons.buttonClose') }}
                </t-button>
              </template>
            </prj1009-modal>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapActions, mapState } from 'vuex'
import utilsGeneral from '@/utils/esg/utils-general'
import { ESG_THEMES } from '@/store/store-modules'
import { GET_THEMES_INFO } from '@/store/constants/esg/actions'
import { GET_APP_CONFIG } from '@/store/constants/esg/actions';

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import apiCmsStories from '@/esg-api/cms-stories'
import logger from '@/utils/logger'

import PageLayout from '@/templates/esg/page-layout'
import Prj1005NavigationBar from '@/components/esg/prj1005-navigation-bar/prj1005-navigation-bar'
import Prj1011ImageUpload from '@/components/esg/prj1011-image-upload/prj1011-image-upload'
import Prj1009Modal from '@/components/esg/prj1009-modal/prj1009-modal'
import Prj1019VisualModalSelect from '@/components/esg/prj1019-visual-modal-select/prj1019-visual-modal-select'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'ESGNewStory',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout,
    Prj1005NavigationBar,
    Prj1009Modal,
    Prj1011ImageUpload,
    Prj1019VisualModalSelect,
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      competitionThemeId: this.$route.params.competitionThemeId,
      story: {
        content: '',
        photoBase64: '',
        themeId: this.$route.params.competitionThemeId || null,
        storyOwner: {
          name: '',
          position: '',
          photoBase64: '',
        },
      },
      consentsChecked: false,
      sending: false,
      sendFailed: false,
      loginOverlay: false,
      policyPage: null,
      policyModalShow: false,
      policyTitle: null,
      policyContent: null
    }
  },
  computed: {
    ...mapState('esgConfig', ['personalPrivacyPolicyPage']),
    ...mapState('esgThemes', ['themesInfo']),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),
    isActive() {
      const storyHasContent = this.story.content.length > 1

      return storyHasContent && this.consentsChecked
    },
    computedStoryThemeId() {
      return this.story.themeId
    },
    competitionStoryCategoryId() {
      return this.$route.params.competitionCategoryId
    },
    competitionId() {
      return this.$route.params?.competitionId
    },
    isCompetition() {
      const params = this.$route.params
      const key = 'competitionId'

      return Object.prototype.hasOwnProperty.call(params, key)
    },
    themeOptionsPlaceholder() {
      return this.computedStoryThemeId
        ? this.themesInfo?.find((theme) => theme.id === this.computedStoryThemeId).title
        : this.$t('esg.stories.forms.fieldThemePlaceholder')
    },
    selectBtnClasses() {
      return [
        'prj-form-control-w-icon',
        this.computedStoryThemeId ? 'text-black' : '!text-gray-500'
      ]
    }
  },
  created() {
    // temp condition disabled
    //if (!this.personalPrivacyPolicyPage) this.GET_APP_CONFIG()
    this.GET_APP_CONFIG()
    if (!this.themesInfo.length) this.$store.dispatch(`${ESG_THEMES}/${GET_THEMES_INFO}`)
  },
  methods: {
    ...mapActions('esgConfig', [GET_APP_CONFIG]),
    sendStory() {
      let processId = utilsGeneral.startAsyncProcess()
      let postData = this.story
      postData.categoryId = this.competitionStoryCategoryId || null
      postData.competitionId = this.competitionId
      this.sending = true
      this.sendFailed = false

      // story consents
      postData.consents = this.consentsChecked ? 1 : 0

      apiCmsStories
        .sendStory(postData)
        .then(() => {
          logger.info('Form has been sent.')

          this.sending = false
          utilsGeneral.stopAsyncProcess(processId)

          this.$router.push({ name: 'sustainability-new-story-success' })
        })
        .catch((error) => {
          this.sending = false
          this.sendFailed = true
          utilsGeneral.stopAsyncProcess(processId)
          logger.error('Sending form has failed.', error.response)
        })
    },
    openPrivacyPolicy() {
      this.policyTitle = this.personalPrivacyPolicyPage?.page?.post_title
      this.policyContent = this.personalPrivacyPolicyPage?.page?.post_content
      this.policyModalShow = true
    },
    closePolicyOverlay() {
      this.policyModalShow = false
      this.policyTitle = null
      this.policyContent = null
    },
    setSelectedThemeId(themeId) {
      this.story.themeId = themeId
    },
    onSelectionReset() {
      this.story.themeId = null
    }
  },
}
</script>
