<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
    :watchScroll="true"
    @articleListContainerScrolledBottom="onScrolledBottom"
    @articleListContainerScrollbarVisible="onScrollbarVisible"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div
          class="vue-c-article-body vue-wcm-content"
          v-html="replacer(dashboardContent)"
        />
      </template>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
      <frm1006-button
        v-if="initialized && !isLoading && loaded && covidFormUrl !== ''"
        mode="anchor"
        type="primary"
        class="vue-button-confirm vue-button-page-layout-fixed"
        :link="covidFormUrl"
        :disabled="!applicationConfigLoaded || (scrolledToBottom === false && scrollbarVisible === true)"
        :linkNewWindow="true"
      >
        {{ $t('covid19.fillForm') }}
      </frm1006-button>
    </template>


    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import { mapState } from 'vuex';
import { COVID19_KEY_PREFIX, ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import router from '@/router';
import utilsGeneral from '@/utils/utils-general';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

export default {
  name: 'Covid19FormInfo',
  components: {
    Prj1002Navbar,
    PageLayout
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      loaded: false,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      dashboardContent: null,
      scrolledToBottom: false,
      scrollbarVisible: false
    }
  },

  computed: {
    ...mapState('dataStore', [
      'contentData'
    ]),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),

    covidConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(COVID19_KEY_PREFIX)) {
            const itemNameWithoutPrefix = item.key.replace('COVID19_', '');
            const itemName = utilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },

    covidFormUrl() {
      if (typeof this.covidConfig === 'undefined') {
        return '';
      }

      if (this.covidConfig?.formUrl === '#') {
        return '';
      }

      return this.covidConfig?.formUrl;
    }
  },

  watch: {
    applicationConfigLoaded() {
      this.fetchDashboardData();
    },
  },

  mounted() {
    this.initialized = true;

    if (this.applicationConfigLoaded) {
      this.fetchDashboardData();
    }
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    goToForm() {
      router.push({ name: 'covid-19-form' });
    },

    fetchDashboardData() {
      this.setLoadingState(true);

      dataLoader.fetchSubPages(this.covidConfig.formInfoPageId)
        .then(data => {
          this.dashboardContent = data.content;
          this.loaded = true;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
          this.loaded = false;
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    replacer(content) {
      if(typeof content !== 'string') {
        return null;
      }

      return content;
    },

    onScrolledBottom(event) {
      this.scrolledToBottom = event;
    },

    onScrollbarVisible(event) {
      this.scrollbarVisible = event;
    }
  }
}
</script>
