<script>
// FIXME: JNO - Temporary mixin for infinity scrolling of random content, sure to be replaced with awesome VueJS 3 features
/*
 * REQUIRED
 * this.$refs.infinityScrollTrigger -> trigger for intersection observer
 * this.isInfinityScrollEnabled -> boolean toggle for enable infinity scrolling feature
 * this.loadNewContent -> function for loading new content (standardized name)
 *
 * feel free for setting your own content loading logic in component, it's independent on this mixin
 */

export default {
  data() {
    return {
      intersectionObserver: null,
      intersectionObserverOptions: {
        root: null,
        threshold: 0
      }
    }
  },

  methods: {
    initInfinityScroll() {
      const options = this.intersectionObserverOptions;
      const target = this.$refs.infinityScrollTrigger;
      this.intersectionObserver = new IntersectionObserver(this.onIntersection, options);
      if (typeof target === 'object') {
        this.intersectionObserver.observe(target);
      }
    },

    onIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting && this.isInfinityScrollEnabled && !this.isLoading) {
          this.loadNewContent(true);
        }
      });

      if (!this.isInfinityScrollEnabled) {
        this.intersectionObserver.unobserve(entries[0].target);
      }
    },
  }
}
</script>
