<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div
          class="vue-b-crisis-content"
        >
          <div class="vue-header">
            <img
              class="vue-header-logo"
              :src="headerLogoPath"
            >
          </div>

          <div class="vue-controls">
            <frm1006-button
              v-for="(item, index) in listItems"
              :key="index"
              :icon-right="true"
              class="vue-control-item"
              type="internal"
              @buttonClickEvent="goToView(item)"
            >
              {{ item.title }}
            </frm1006-button>
          </div>
        </div>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import router from '@/router';

export default {
  name: 'EmergencyCrisisSituation',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      navbarCaptionData: null,
      ERROR_MODAL_TIMEOUT,
      pageSlug: 'krizove-situace',
      listItems: null,
      headerLogoPath: '/img/emergency/crisis-situation-header.png',
    };
  },

  computed: {
    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value && this.listItems) {
        this.setLoadingState(false);
      }
    },
  },

  mounted() {
    if(!this.listItems) {
      this.getPageContent();
    }

    this.initialized = true;
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getPageContent() {
      this.setLoadingState(true);

      return dataLoader.fetchEmergencyPage(this.pageSlug, this.selectedBuilding)
        .then(data => {
          if (data.hasOwnProperty('id')) {
            this.fetchListPages(data.id);
          }
        })
        .catch(error => {
          this.setLoadingState(false);
          logger.error(error);
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        });
    },

    fetchListPages(parentPageId) {
      dataLoader.fetchSubPages(parentPageId, false)
        .then(data => {
          this.listItems = data.items;
        })
        .catch(error => {
          logger.error(error);
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    goToView(item) {
      router.push({name: 'emergency-crisis-situation-detail', params: {
        id: item.id,
        slug: item.slug,
        data: item
      }});
    }
  },
}
</script>

