<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <!--Overview-->
      <template
        v-if="covid19DashboardActiveCategory === categoriesConstants.OVERVIEW"
      >
        <h2 class="vue-headline">
          {{ $t('covid19.overview') }}
        </h2>
        <div
          v-html="covidPostsContent[0]"
        />
      </template>

      <!--Info-->
      <template
        v-if="covid19DashboardActiveCategory === categoriesConstants.INFO"
      >
        <div
          class="vue-intro-content"
          v-html="covidFaqIntroContent"
        />
        <div class="vue-b-faq-controls">
          <frm1006-button
            v-for="(item, index) in covidFaqItems"
            :key="index"
            :icon-right="true"
            :disabled="!applicationConfigLoaded"
            class="vue-faq-item"
            type="internal"
            @buttonClickEvent="goToView(item.id)"
          >
            {{ item.title }}
          </frm1006-button>
        </div>
      </template>

      <!--News-->
      <template
        v-if="covid19DashboardActiveCategory === categoriesConstants.NEWS"
      >
        <prj1032-news-list
          v-if="covidDashboardConfig.catId > 0"
          ref="newsList"
          :categoryIdInitial="covidDashboardConfig.catId"
          :categoryFilter="false"
          @filteredArticlesChangeEvent="filteredArticlesChange"
          @filteredCategoryNameUpdatedEvent="setNavbarCaption"
          @initializedEvent="initializeNewsListEvent"
        />
      </template>
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <prj1026-category-filter
        v-if="covidCategoriesLoaded"
        :filterCategories="covidDashboardCategories"
        :activeCategory="covid19DashboardActiveCategory"
        :showItems="covidDashboardCategories.length"
        :showAllButtons="true"
        :showFilterButtonMore="true"
        :showResetButton="false"
        filterType="covid"
        popupType="covidCategory"
        @filterItems="changeCategory"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import {
  COVID_DASHBOARD_CATEGORIES_LIST,
  COVID19_KEY_PREFIX,
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';
import router from '@/router';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';
import utilsGeneral from '@/utils/utils-general';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter';
import Prj1032NewsList from '@/components/prj1032-news-list/prj1032-news-list';
import EVENT_BUS from '@/event-bus';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';

export default {
  name: 'Covid19Dashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1026CategoryFilter,
    Prj1032NewsList
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      categoriesConstants: {
        OVERVIEW: 1,
        INFO: 2,
        NEWS: 3
      },
      isLoading: true,
      hasError: false,
      navbarCaptionData: null,
      newsListInitialized: false,
      covidFaqItems: {},
      covidFaqIntroContent: '',
      covidPostsContent: [],
      covidDashboardCategories: [],
      covidCategoriesLoaded: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    };
  },

  computed: {
    ...mapState('dataStore', [
      'contentData'
    ]),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
      'covid19DashboardActiveCategory'
    ]),

    covidDashboardConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(COVID19_KEY_PREFIX)) {
            // FIXME MWR: Replace the string with a constant
            const itemNameWithoutPrefix = item.key.replace('COVID19_', '');
            const itemName = utilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },

    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    }
  },

  watch: {
    applicationConfigLoaded() {
      this.fetchDashboardData();
    },

    covid19HasError(newValue) {
      this.handleHasError(newValue);
    }
  },

  created() {
    this.getCovidDashboardCategories();

    if (this.applicationConfigLoaded) {
      this.fetchDashboardData();
    }
  },

  mounted() {
    this.sendStatistics('COVID19');

    EVENT_BUS.$on(GLOBAL_EVENTS.FILTER_COVID_ITEM_LIST, selectedCategoryNumber => {
      this.changeCategory(parseInt(selectedCategoryNumber));
    });
  },

  beforeDestroy() {
    EVENT_BUS.$off(GLOBAL_EVENTS.FILTER_COVID_ITEM_LIST);
    this.resetCategory();
  },

  methods: {
    getCovidDashboardCategories() {
      dataLoader.fetchApiDataList(COVID_DASHBOARD_CATEGORIES_LIST)
        .then(data => {
          this.covidDashboardCategories = data.covidCategories;
          this.covidCategoriesLoaded = true;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
    },

    // FIXME MBU: reports only if legth of articles is > 0, not safe
    // API with categories should return only categories, that have items
    filteredArticlesChange(value) {
      if (value.length > 0) {
        this.isLoading = false;
      }
    },

    // TODO MBU: not ideal solution
    setNavbarCaption(value) {
      this.navbarCaptionData = value;
    },

    initializeNewsListEvent() {
      this.newsListInitialized = true;
    },

    fetchDashboardData() {
      this.isLoading = true;

      const postsIdsArray = [
        this.covidDashboardConfig.quickOverview,
        this.covidDashboardConfig.linkId
      ];

      dataLoader.fetchMultiplePostsById(postsIdsArray)
        .then(data => {
          data.forEach(posts => {
            this.covidPostsContent.push(posts.data[0].content);
          })

          return dataLoader.fetchSubPages(this.covidDashboardConfig.pageId);
        })
        .then(faqContent => {
          this.covidFaqItems = faqContent.items;
          this.covidFaqIntroContent = faqContent.content;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    goToView(articleId) {
      router.push({ name: 'covid-19-faq', params: { id: articleId }});
    },

    handleHasError(hasError) {
      this.hasError = hasError;
    },

    changeCategory(categoryId) {
      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_COVID19_ACTIVE_CATEGORY, categoryId);
    },

    resetCategory() {
      this.changeCategory(this.categoriesConstants.OVERVIEW);
    },
  }
}
</script>
