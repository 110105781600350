<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        ref="navbar"
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="clickEventBackButton()"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        v-if="initialized && !isLoading"
        class="vue-b-manager"
      >
        <h2 class="vue-forvardino-lessons-heading">{{ courseData?.name }}</h2>

        <div
          v-if="view === FORVARDINO_MANAGER_VIEW_LESSONS"
          class="vue-b-lessons"
        >
          <div class="vue-b-course-caption">
            <div class="vue-info">
              <small>Stav kurzu:</small>
              <div
                class="vue-state-text"
                :class="{'vue-is-draft': courseData?.state === '0', 'vue-is-active': courseData?.state === '1', 'vue-is-deleted': courseData?.state === '-1'}"
              >
                {{ getStateText(courseData?.state) }}
              </div>
            </div>
            <div class="vue-actions">
              <frm1006-button
                v-if="enabledEditCourseButton && releaseEnabled"
                type="app-button"
                :icon-left="true"
                class="vue-edit-button"
                @buttonClickEvent="clickEventEditCourse()"
              />
              <frm1006-button
                v-if="enabledDeleteCourseButton && releaseEnabled"
                type="app-button"
                :icon-left="true"
                class="vue-delete-button"
                @buttonClickEvent="clickEventCourseDelete()"
              />
            </div>
          </div>

          <prj1020-separator />

          <div class="vue-b-course-content">
            <div class="vue-info">
              <span class="vue-forvardino-lessons-subheading">
                {{ $t('forvardino.lessonDates') }}
              </span>
            </div>
            <div class="vue-actions">
              <frm1006-button
                v-if="enabledNewLessonButton && releaseEnabled"
                type="app-button"
                :icon-left="true"
                class="vue-create-button"
                @buttonClickEvent="clickEventNewLesson()"
              />
            </div>
          </div>

          <div
            v-if="lessonsData.length > 0"
            ref="lessonsList"
            class="vue-b-info-controls"
            @loadNewContent="loadNewContent"
          >
            <frm1006-button
              v-for="(item, index) in lessonsData"
              :key="index"
              :icon-right="true"
              class="vue-control-item"
              type="internal"
              @buttonClickEvent="clickEventLessonSelect(item)"
            >
              <div>{{ convertToTextDate(item.activityFrom) }}</div>
              <small>{{ convertToTextDayNameTime(item.activityFrom) }}</small>
              <div
                class="vue-state-text"
                :class="{'vue-is-draft': item.state === '0', 'vue-is-active': item.state === '1', 'vue-is-deleted': item.state === '-1'}"
              >
                [{{ getStateText(item.state) }}]
              </div>
            </frm1006-button>
          </div>

          <div
            v-if="isInfinityScrollEnabled"
            ref="infinityScrollTrigger"
          >
            <gen1016-loading-indicator
              :active="isInfinityScrollEnabled"
            />
          </div>

          <gen1006-info-panel
            v-if="!lessonsData.length"
            type="neutral"
            class="vue-no-data"
          >
            {{ $t('forvardino.forvardinoNoAvailableLessons') }}
          </gen1006-info-panel>
        </div>

        <div
          v-if="view === FORVARDINO_MANAGER_VIEW_ATTENDANCE"
          class="vue-b-attendance-list"
        >
          <div class="vue-b-lesson-caption">
            <div class="vue-info">
              <div>{{ selectedLesson ? convertToTextDate(selectedLesson.activityFrom) : '' }}</div>
              <small>{{ selectedLesson ? convertToTextDayNameTime(selectedLesson.activityFrom) : '' }}</small><br>
            </div>
            <div class="vue-actions">
              <frm1006-button
                v-if="enabledEditLessonButton && releaseEnabled"
                type="app-button"
                :icon-left="true"
                class="vue-edit-button"
                @buttonClickEvent="clickEventLessonEdit()"
              />
              <frm1006-button
                v-if="enabledDeleteLessonButton && releaseEnabled"
                type="app-button"
                :icon-left="true"
                class="vue-delete-button"
                @buttonClickEvent="clickEventLessonDelete()"
              />
            </div>
          </div>

          <prj1020-separator />

          <div class="vue-b-lesson-content">
            <div class="vue-info">
              <span class="vue-forvardino-lessons-subheading">
                {{ $t('forvardino.lessonsRegistered') }}
              </span>
            </div>
            <div class="vue-actions">
              <frm1006-button
                type="app-button"
                :icon-left="true"
                class="vue-save-button"
                @buttonClickEvent="clickEventAttendanceExport()"
              />
            </div>
          </div>

          <div
            v-if="attendanceList.length > 0"
            ref="attendanceList"
            class="vue-b-info-controls"
          >
            <frm1006-button
              v-for="(item, index) in attendanceList"
              :key="index"
              :icon-right="true"
              class="vue-control-item vue-attendance-list-item"
              :class="{'vue-is-attended' : item.attended, 'vue-is-loading': item.loading}"
              type="internal"
              @buttonClickEvent="clickEventAttendanceDialog(item)"
            >
              {{ item.fullName }}<br>
              <small>{{ item.email }}</small><br>
              <span class="vue-attended-timestamp">{{ item.attended ? $t('forvardino.attended') : $t('forvardino.noAttended') }}</span>
            </frm1006-button>
          </div>

          <gen1006-info-panel
            v-else
            type="neutral"
            class="vue-no-data"
          >
            {{ $t('forvardino.noRegistered') }}
          </gen1006-info-panel>
        </div>
      </div>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="deleteSuccessfull"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="success"
        >
          {{ $t('forvardino.forvardinodeleteSuccessfull') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="newLessonFormSent"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="success"
        >
          {{ $t('forvardino.customCourseSuccessfull') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-forvardino-new-lesson-form-modal"
        :active.sync="isNewLessonFormModalActive"
        :buttonClose="true"
        preventClosing="all"
      >
        <div class="vue-b-data">
          <h2>{{ $t('forvardino.newLessonDate') }}</h2>

          <frm1035-form-group :required="true">
            <label>{{ $t('forvardino.forms.labelNewLessonDateStart') }}</label>
            <datetime
              v-model="newLessonFormDateStart"
              :type="'datetime'"
              :use12-hour="false"
              :min-datetime="datePickerMinDate"
              :phrases="datetimePhrases"
              class="vue-is-calendar"
            ></datetime>
          </frm1035-form-group>

          <frm1035-form-group :required="true">
            <label>{{ $t('forvardino.forms.labelNewLessonDateEnd') }}</label>
            <datetime
              v-model="newLessonFormDateEnd"
              :type="'datetime'"
              :use12-hour="false"
              :min-datetime="datePickerMinDate"
              :phrases="datetimePhrases"
              class="vue-is-calendar"
            ></datetime>
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1028-advanced-combo
              v-model="newLessonFormLocation"
              :options="newLessonFormLocations"
              :required="false"
              :tooltip="false"
              :label="$i18n.t('forvardino.forms.labelNewLessonLocation')"
              :placeholder="$i18n.t('forvardino.forms.labelNewLessonLocation')"
              @change="newLessonFormValidate"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1001-input-field
              v-model="newLessonFormPlace"
              :required="false"
              :tooltip="false"
              :label="$i18n.t('forvardino.forms.labelNewLessonPlace')"
              @inputChangeEvent="newLessonFormValidate"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1003-checkbox
              v-model="newLessonFormOnline"
              :tooltip="false"
              @change="newLessonFormValidate"
            >
              {{ $t('forvardino.forms.labelNewLessonOnline') }}
            </frm1003-checkbox>
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1001-input-field
              v-show="newLessonFormOnline"
              v-model="newLessonFormURL"
              :required="false"
              :tooltip="false"
              :label="$i18n.t('forvardino.forms.labelNewLessonUrl')"
              @inputChangeEvent="newLessonFormValidate"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1001-input-field
              v-model="newLessonFormCapacity"
              :inputType="'number'"
              :required="true"
              :tooltip="false"
              :label="$i18n.t('forvardino.forms.labelNewLessonCapacity')"
              @inputChangeEvent="newLessonFormValidate"
            />
          </frm1035-form-group>

          <frm1006-button
            type="secondary"
            class="vue-lesson-form-cancel"
            @buttonClickEvent="clickEventCancelNewLessonDialog()"
          >
            {{ $t('forvardino.forms.cancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            :class="classObject"
            :disabled="!isNewLessonFormValid || newLessonFormSending || newLessonFormSent"
            @buttonClickEvent="submitNewLessonDialog()"
          >
            {{ $t('forvardino.forms.save') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-forvardino-edit-course-form-modal"
        :active.sync="isEditCourseFormModalActive"
        :buttonClose="true"
        preventClosing="all"
      >
        <div class="vue-b-data">
          <h2>{{ $t('forvardino.editCourseData') }}</h2>

          <frm1035-form-group>
            <frm1003-checkbox
              v-model="draftFormState"
              :tooltip="false"
              @change="draftFormValidate"
            >
              {{ $t('forvardino.forms.labelState') }}
            </frm1003-checkbox>
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1001-input-field
              v-model="draftFormName"
              :disabled="draftFormCompleted"
              :required="true"
              :tooltip="false"
              :label="$i18n.t('forvardino.forms.labelName')"
              @inputChangeEvent="draftFormValidate"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <wysiwyg v-model="draftFormDescription" />
          </frm1035-form-group>

          <frm1035-form-group :required="true">
            <label>{{ $i18n.t('forvardino.forms.labelCategory') }}</label>
            <frm1025-option-group
              :name="'frm-categories-list'"
              :multiselect="true"
              :options="draftFormCategoriesOptions"
              :value="draftFormCategories"
              :tooltip="false"
              @change="changeEventCategories"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1028-advanced-combo
              v-model="draftFormTarget"
              :options="draftFormTargets"
              :required="true"
              :tooltip="false"
              :label="$i18n.t('forvardino.forms.labelTarget')"
              :placeholder="$i18n.t('forvardino.forms.labelTarget')"
              @change="draftFormValidate"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <div class="vue-photo-image-wrap">
              <label>{{ $i18n.t('forvardino.forms.labelImage') }}</label>

              <prj1010-image-upload
                v-if="!draftFormImage"
                class="vue-app-upload-icon"
                :cropper="true"
                :acceptFiles="['image/jpeg', 'image/png']"
                :cropper-aspect-ratio="16 / 9"
                :caption="'png/jpeg'"
                @uploadedPhoto="processedPhoto"
              />

              <div class="vue-camera-canvas">
                <div
                  v-if="draftFormImage || draftFormImageOld"
                  class="vue-app-uploaded-file"
                >
                  <img
                    :src="draftFormImage ?? draftFormImageOld"
                    :style="styleObjectImg"
                  />

                  <frm1006-button
                    v-if="!draftFormCompleted"
                    class="vue-button-remove-icon"
                    type="internal"
                    :disabled="!!draftFormImageOld"
                    :iconLeft="true"
                    :captionHidden="true"
                    @buttonClickEvent="removeTakenPicture"
                  >
                    {{ $t('forvardino.forms.buttonRemoveImage') }}
                  </frm1006-button>
                </div>
              </div>
            </div>
          </frm1035-form-group>

          <frm1006-button
            type="secondary"
            class="vue-draft-form-cancel"
            @buttonClickEvent="clickEventCancelDraftForm()"
          >
            {{ $t('forvardino.forms.cancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            class="vue-draft-form-submit"
            :class="classObject"
            :disabled="!isDraftFormValid || draftFormSending || draftFormSent"
            @buttonClickEvent="clickEventSubmitDraftForm()"
          >
            {{ $t('forvardino.forms.save') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>

      <gen1009-overlay
        :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
        :active.sync="showLessonDeleteDialogModal"
      >
        <div class="vue-forvardino-lesson-delete-dialog">
          <h3>{{ $t('forvardino.forvardinoLessonDelete') }}</h3>

          <frm1006-button
            type="secondary"
            @buttonClickEvent="cancelLessonDeleteDialog()"
          >
            {{ $t('forvardino.forms.cancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            @buttonClickEvent="submitLessonDeleteDialog()"
          >
            {{ $t('forvardino.forms.confirm') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>

      <gen1009-overlay
        :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
        :active.sync="showCourseDeleteDialogModal"
      >
        <div class="vue-forvardino-course-delete-dialog">
          <h3>{{ $t('forvardino.forvardinoCourseDelete', { 'name': courseData?.name }) }}</h3>

          <frm1006-button
            type="secondary"
            @buttonClickEvent="cancelCourseDeleteDialog()"
          >
            {{ $t('forvardino.forms.cancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            @buttonClickEvent="submitCourseDeleteDialog()"
          >
            {{ $t('forvardino.forms.confirm') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import {
  ERROR_MODAL_TIMEOUT,
} from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1020Separator from '@/components/prj1020-separator/prj1020-separator.vue';
import router from '@/router';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import '@/utils/dayjs';
import dayjs from 'dayjs';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import mxInfinityScrolling from '@/mixins/mx-infinity-scrolling';

import { Datetime } from 'vue-datetime';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import utilsGeneral from '@/utils/utils-general';
import Prj1010ImageUpload from '@/components/prj1010-image-upload/prj1010-image-upload.vue';
import Frm1025OptionGroup from '../../../frameworks/vue/components/frm/frm1025-option-group/frm1025-option-group.vue';

const FORVARDINO_MANAGER_VIEW_LESSONS = 'lessons';
const FORVARDINO_MANAGER_VIEW_ATTENDANCE = 'attendance';
const LESSONS_LIST_PAGINATION = 50;
const FORVARDINO_DATE_COURSE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'ForvardinoCourseManager',
  components: {
    Frm1025OptionGroup,
    Prj1010ImageUpload,
    Prj1020Separator,
    PageLayout,
    Prj1002Navbar,
    Datetime
  },

  mixins: [
    mxNavigationDefault,
    mxDetectDesktop,
    mxInfinityScrolling
  ],

  data() {
    return {
      locale: 'cs-CZ',
      view: FORVARDINO_MANAGER_VIEW_LESSONS,
      courseData: null,
      lessonsData: [],
      attendanceList: [],
      selectedLesson: null,
      selectedAttendance: null,
      formDataLessonStart: null,
      formDataLessonEnd: null,
      noMoreLessons: false,
      currentPosition: 0,
      componentKey: 0,
      initialized: false,
      isLoading: false,
      hasError: false,
      enabledNewLessonButton: true,
      enabledEditLessonButton: true,
      enabledDeleteLessonButton: true,
      enabledEditCourseButton: true,
      enabledDeleteCourseButton: true,
      showNewLessonDialogModal: false,
      showLessonDeleteDialogModal: false,
      showCourseDeleteDialogModal: false,
      deleteSuccessfull: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      FORVARDINO_MANAGER_VIEW_LESSONS,
      FORVARDINO_MANAGER_VIEW_ATTENDANCE,
      datetimePhrases: {ok: 'Ok', cancel: 'Storno'},
      isNewLessonFormValid: false,
      newLessonFormSending: false,
      newLessonFormSent: false,
      isDraftFormValid: false,
      draftFormSending: false,
      draftFormSent: false,
      releaseEnabled: true
    }
  },

  computed: {
    ...mapState('user', [
      'profile'
    ]),
    ...mapState('forvardino', [
      'forvardinoFilterCategories',
      'newLessonFormData',
      'draftFormData',
      'modalNewLessonFormActive',
      'modalEditCourseFormActive',
    ]),

    paramId() {
      return this.$route.params.id;
    },

    isInfinityScrollEnabled() {
      return false;
    },

    endpointListOptions() {
      return [
        this.paramId,
        this.currentPosition,
        LESSONS_LIST_PAGINATION
      ]
    },

    datePickerMinDate() {
      return dayjs().locale(this.locale).format(FORVARDINO_DATE_COURSE_FORMAT);
    },

    newLessonFormLocations() {
      let convertedFilterLocations = [];

      convertedFilterLocations.push({ 'value': '0', 'caption': '-- Nevyplněno --' });
      Object.values(this.forvardinoFilterCategories.locations).forEach(item => {
        convertedFilterLocations.push({ 'value': item.id, 'caption': item.name });
      })

      return convertedFilterLocations;
    },

    isNewLessonFormModalActive: {
      get() {
        return this.modalNewLessonFormActive;
      },
      set(newValue) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_MODAL, newValue);
      }
    },

    isEditCourseFormModalActive: {
      get() {
        return this.modalEditCourseFormActive;
      },
      set(newValue) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_EDIT_COURSE_FORM_MODAL, newValue);
      }
    },

    newLessonFormId: {
      get () {
        return this.newLessonFormData.id;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_ID, value);
      }
    },

    newLessonFormDateStart: {
      get () {
        return this.newLessonFormData.dateStart;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_DATE_START, value);
      }
    },

    newLessonFormDateEnd: {
      get () {
        return this.newLessonFormData.dateEnd;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_DATE_END, value);
      }
    },

    newLessonFormLocation: {
      get () {
        return this.newLessonFormData.location;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_LOCATION, value);
      }
    },

    newLessonFormPlace: {
      get () {
        return this.newLessonFormData.place;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_PLACE, value);
      }
    },

    newLessonFormOnline: {
      get () {
        return this.newLessonFormData.online;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_ONLINE, value);
      }
    },

    newLessonFormURL: {
      get () {
        return this.newLessonFormData.url;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_URL, value);
      }
    },

    newLessonFormCapacity: {
      get () {
        return this.newLessonFormData.capacity;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_CAPACITY, value);
      }
    },

    newLessonFormCompleted: {
      get () {
        return this.newLessonFormData.completed;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_COMPLETED, value);
      }
    },

    classObject() {
      return {
        'vue-is-active': this.isNewLessonFormValid,
      }
    },

    styleObjectImg() {
      return {
        maxWidth: '200px',
        maxHeight: '200px',
      }
    },

    draftFormCategoriesOptions() {
      let convertedFilterCategories = [];

      Object.values(this.forvardinoFilterCategories.categories).forEach(item => {
        convertedFilterCategories.push({ 'value': item.id, 'caption': item.name });
      })

      return convertedFilterCategories;
    },

    draftFormTargets() {
      let convertedFilterCategories = [];

      Object.values(this.forvardinoFilterCategories.targets).forEach(item => {
        convertedFilterCategories.push({ 'value': item.id, 'caption': item.name });
      })

      return convertedFilterCategories;
    },

    draftFormId: {
      get () {
        return this.draftFormData.id;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_ID, value);
      }
    },

    draftFormName: {
      get () {
        return this.draftFormData.name;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_NAME, value);
      }
    },

    draftFormDescription: {
      get () {
        return this.draftFormData.description;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_DESCRIPTION, value);
      }
    },

    draftFormCategories: {
      get () {
        return this.draftFormData.categories;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_CATEGORY, value);
      }
    },

    draftFormTarget: {
      get () {
        return this.draftFormData.target;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_TARGET, value);
      }
    },

    draftFormImage: {
      get () {
        return this.draftFormData.image;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_IMAGE, value);
      }
    },

    draftFormImageOld: {
      get () {
        return this.draftFormData.imageOld;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_IMAGE_OLD, value);
      }
    },

    draftFormState: {
      get () {
        return this.draftFormData.state;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_STATE, value);
      }
    },

    draftFormCompleted: {
      get () {
        return this.draftFormData.completed;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_COMPLETED, value);
      }
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },

    newLessonFormDateStart(value) {
      this.newLessonFormDateEnd = dayjs(value).add(1, 'hour').locale(this.locale).format();
    },

    newLessonFormDateEnd() {
      this.newLessonFormValidate();
    },

    newLessonFormOnline(value) {
      if (!value) {
        this.newLessonFormURL = '';
      }
    },

    modalNewLessonFormActive(value) {
      if (!value) {
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_RESET);
      }
    },

    draftFormDescription() {
      this.draftFormValidate();
    },

    view(value) {
      if (value === FORVARDINO_MANAGER_VIEW_ATTENDANCE) {
        this.enabledEditLessonButton = this.selectedLesson.state !== '-1';
        this.enabledDeleteLessonButton = this.selectedLesson.state !== '-1';
      }
    }
  },

  created() {},

  mounted() {
    if (!this.paramId) {
      router.push({ name: 'forvardino-list' });
    } else {
      this.fetchLessons();
    }
  },

  methods: {
    viewLessons() {
      this.view = FORVARDINO_MANAGER_VIEW_LESSONS;
    },

    viewAttendanceList() {
      this.view = FORVARDINO_MANAGER_VIEW_ATTENDANCE;
    },

    fetchLessons() {
      this.isLoading = true;
      this.componentKey++;
      this.currentPosition = 0;

      dataLoader.getForvardinoCourseLessons([...this.endpointListOptions])
        .then(response => {
          this.courseData = response.course;
          this.lessonsData = response.lessons;
          this.initialized = true;
          if (response.lessons.length >= LESSONS_LIST_PAGINATION) {
            this.noMoreLessons = false;
            this.$refs.lessonsList?.initInfinityScroll();
          } else {
            this.noMoreLessons = true;
          }
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async loadNewContent() {
      logger.log('load new content');
      if (this.noMoreLessons) return;
      this.currentPosition += LESSONS_LIST_PAGINATION;

      await dataLoader.getForvardinoCourseLessons([...this.endpointListOptions])
        .then(response => {
          this.courseData = response.course;
          let tempArray = this.lessonsData;
          this.lessonsData = [...tempArray, ...response.lessons];
          if (response.lessons.length < LESSONS_LIST_PAGINATION) {
            this.noMoreLessons = true;
          }
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        })
    },

    clickEventLessonEdit() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      if (this.selectedLesson) {
        this.newLessonFormDateStart = dayjs(this.selectedLesson.activityFrom).locale(this.locale).format();
        this.newLessonFormDateEnd = dayjs(this.selectedLesson.activityTo).locale(this.locale).format();
        this.newLessonFormOnline = !!this.selectedLesson.isOnline;
        this.newLessonFormLocation = this.selectedLesson.locationId;
        this.newLessonFormCapacity = this.selectedLesson.capacity;
        this.newLessonFormPlace = this.selectedLesson.locationValue;
        this.newLessonFormURL = this.selectedLesson.onlineLink;
        this.newLessonFormValidate();

        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_MODAL, true);
      }
    },

    clickEventLessonDelete() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.showLessonDeleteDialogModal = true;
    },

    submitLessonDeleteDialog() {
      this.isLoading = true;
      dataLoader.deleteForvardinoCourseLesson(this.selectedLesson.id).then(() => {
        logger.info('Lesson has been deleted!');
        this.deleteSuccessfull = true;
        this.view = FORVARDINO_MANAGER_VIEW_LESSONS;
        this.fetchLessons();
      }).catch(error => {
        logger.error('Lesson delete has failed:', error);
        this.hasError = true;
      }).finally(() => {
        this.isLoading = false;
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.showLessonDeleteDialogModal = false;
      })
    },

    submitCourseDeleteDialog() {
      this.isLoading = true;
      dataLoader.deleteForvardinoCourse(this.courseData.id).then(() => {
        logger.info('Course has been deleted!');
        this.deleteSuccessfull = true;
        router.push({ name: 'forvardino-list' });
      }).catch(error => {
        logger.error('Course delete has failed:', error);
        this.hasError = true;
      }).finally(() => {
        this.isLoading = false;
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.showCourseDeleteDialogModal = false;
      })
    },

    cancelLessonDeleteDialog() {
      this.showLessonDeleteDialogModal = false;
    },

    cancelCourseDeleteDialog() {
      this.showCourseDeleteDialogModal = false;
    },

    clickEventNewLesson() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_MODAL, true);
    },

    clickEventEditCourse() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      if (this.courseData) {
        this.draftFormId = this.courseData.id;
        this.draftFormName = this.courseData.name;
        this.draftFormDescription = this.courseData.description;
        this.draftFormImageOld = this.courseData.image;
        this.draftFormTarget = this.courseData.targetId.toString();
        this.draftFormState = !!this.courseData.state;
        let tmpCategories = [];
        this.courseData.categories.forEach(item => {
          tmpCategories.push(item.id);
        });
        this.draftFormCategories = tmpCategories;
        this.draftFormValidate();

        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_EDIT_COURSE_FORM_MODAL, true);
      }
    },

    clickEventCourseDelete() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.showCourseDeleteDialogModal = true;
    },

    clickEventBackButton() {
      if (this.view === FORVARDINO_MANAGER_VIEW_ATTENDANCE) {
        this.goToLessons();
      } else {
        router.push({ name: 'forvardino-detail',
          params: {
            id: this.paramId,
          }
        });
      }
    },

    clickEventCancelDraftForm() {
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_EDIT_COURSE_FORM_MODAL, false);
    },

    clickEventCancelNewLessonDialog() {
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_MODAL, false);
    },

    clickEventSubmitDraftForm() {
      let formData = new FormData();
      formData.append('id', this.draftFormId);
      formData.append('name', this.draftFormName);
      formData.append('description', this.draftFormDescription);
      formData.append('category', this.draftFormCategories.join(','));
      formData.append('target', this.draftFormTarget);
      formData.append('image', this.draftFormImage);
      formData.append('imageOld', this.draftFormImageOld);
      formData.append('state', this.draftFormState);
      this.draftFormSending = true;

      dataLoader.sendForvardinoCourse(
        formData
      ).then(() => {
        logger.info('Form has been sent!');
        this.draftFormSent = true;
        this.newLessonFormSent = true;
        router.push({ name: 'forvardino-detail',
          params: {
            id: this.paramId,
          }
        });
      }).catch(error => {
        logger.error('Sending form has failed:', error);
        //this.formFailed = true;
        this.draftFormSent = false;
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.draftFormSending = false;
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_EDIT_COURSE_FORM_MODAL, false);
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_DRAFT_FORM_RESET);
      })
    },

    submitNewLessonDialog() {
      let formData = new FormData();
      formData.append('courseId', this.paramId);
      formData.append('lessonId', this.newLessonFormId);
      formData.append('dateStart', dayjs(this.newLessonFormDateStart).locale(this.locale).format('YYYY-MM-DD HH:mm'));
      formData.append('dateEnd', dayjs(this.newLessonFormDateEnd).locale(this.locale).format('YYYY-MM-DD HH:mm'));
      formData.append('location', this.newLessonFormLocation);
      formData.append('place', this.newLessonFormPlace);
      formData.append('online', this.newLessonFormOnline | 0);
      formData.append('url', this.newLessonFormURL);
      formData.append('capacity', this.newLessonFormCapacity);
      this.newLessonFormSending = true;

      dataLoader.sendForvardinoCourseLesson(
        formData
      ).then(() => {
        logger.info('Form has been sent!');
        this.newLessonFormSent = true;
      }).catch(error => {
        logger.error('Sending form has failed:', error);
        //this.formFailed = true;
        this.newLessonFormSent = false;
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.newLessonFormSending = false;
        this.fetchLessons();
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_MODAL, false);
        this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_NEW_LESSON_FORM_RESET);
      })
    },

    submitAttendanceDialog() {
      if (!this.selectedAttendance.attended) {
        this.clickEventAttendanceAction('sign-in');
      } else {
        this.clickEventAttendanceAction('sign-out');
      }
    },

    clickEventAttendanceDialog(item) {
      item.loading = true;
      this.selectedAttendance = item;
      this.submitAttendanceDialog();
    },

    clickEventAttendanceAction(action) {
      if (!this.selectedAttendance) {
        return false;
      }

      //this.isLoading = true;
      const attendancePayload = {
        action: action,
        id: this.selectedAttendance.id,
      };

      dataLoader.sendForvardinoLessonAttendance(attendancePayload)
        .then(() => {
          this.attendanceList.forEach(attendanceItem => {
            if (attendanceItem.id === this.selectedAttendance.id && action === 'sign-in') {
              attendanceItem.attended = dayjs().locale(this.locale).format('YYYY-MM-DD HH:mm:ss');
            }
            if (attendanceItem.id === this.selectedAttendance.id && action === 'sign-out') {
              attendanceItem.attended = null;
            }
            attendanceItem.loading = false;
          });
        })
        .catch(error => {
          logger.error('error -> ', error);
          this.$nextTick(() => {
            this.hasError = true;
          });
        })
        .finally(() => {
          //this.isLoading = false;
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          }
        });
    },

    goToLessons() {
      this.selectedLesson = null;
      this.attendanceList = [];
      this.viewLessons();
    },

    clickEventLessonSelect(item) {
      this.selectedLesson = item;
      this.attendanceList = item.attendanceList;
      this.viewAttendanceList();
    },

    convertToTextDate(date) {
      return dayjs(date).locale(this.locale).format('D. MMMM YYYY');
    },

    convertToTextDayNameTime(date) {
      return dayjs(date).locale(this.locale).format('dddd, HH:mm');
    },

    convertToTextDateTime(date) {
      return dayjs(date).locale(this.locale).format('D. MMMM, HH:mm');
    },

    clickEventAttendanceExport() {
      const attendanceExportPayload = {
        id: this.selectedLesson.id
      };

      dataLoader.sendForvardinoLessonAttendanceExport(attendanceExportPayload)
        .then((fileContent) => {
          const url = URL.createObjectURL(
            new Blob([fileContent], {
              type: 'application/vnd.ms-excel'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'forvardino_'+ dayjs().locale(this.locale).format('YYYY-MM-DD_HHmmss') +'.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          logger.error('error -> ', error);
          this.$nextTick(() => {
            this.hasError = true;
          });
        })
        .finally(() => {
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          }
        });
    },

    newLessonFormValidate() {
      let decision = false;

      if (this.newLessonFormData.dateStart.length > 0 &&
        this.newLessonFormData.dateEnd.length > 0 &&
        utilsGeneral.isPositiveNumeric(this.newLessonFormData.capacity) &&
        ((this.newLessonFormData.online && utilsGeneral.isValidURL(this.newLessonFormData.url)) || (this.newLessonFormData.location.length > 0 && this.newLessonFormData.location !== '0' && this.newLessonFormData.place.length > 0))) {
        decision = true;
      }

      this.isNewLessonFormValid = decision;
    },

    getStateText(state) {
      if (state === '0') {
        return 'Koncept'
      } else if (state === '1') {
        return 'Publikováno'
      } else if (state === '-1') {
        return 'Smazáno'
      }
    },

    draftFormDescriptionInvalidState() {
      if (this.isDraftFormValid) {
        return 'info'
      } else {
        return 'invalid'
      }
    },

    draftFormValidate() {
      let decision = false;

      if (this.draftFormData.name.length > 0 &&
        this.draftFormData.description.length > 0 &&
        this.draftFormData.categories.length > 0 &&
        this.draftFormData.target.length > 0) {
        decision = true;
      }

      this.isDraftFormValid = decision;
    },

    processedPhoto(photoObject) {
      this.draftFormImage = photoObject;
    },

    removeTakenPicture() {
      this.draftFormImage = null;
    },

    changeEventCategories(value) {
      let categories = [];
      Object.entries(value).forEach(([, val]) => {
        categories.push(val);
      });
      this.draftFormCategories = categories;
    },
  }
}
</script>
