//============ GENERAL =================================//
//======================================================//
import { SET_LIKE_STORY, REMOVE_LIKE_STORY } from '@/store/constants/esg/mutations';

//============ STATE ===================================//
//======================================================//
const state = {
  currentUserLikedStories: []
};

//============ MUTATIONS ===============================//
//======================================================//
const mutations = {
  [SET_LIKE_STORY](state, payload) {
    state.currentUserLikedStories.push(payload);
  },
  [REMOVE_LIKE_STORY](state, payload) {
    for (let index in state.currentUserLikedStories) {
      if ( state.currentUserLikedStories[index] === payload) {
        state.currentUserLikedStories.splice(index, 1);
      }
    }
  }
};

//============ ACTIONS =================================//
//======================================================//

const actions = {}

//============ GETTERS =================================//
//======================================================//

const getters = {}

//============ EXPORT ==================================//
//======================================================//
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
