import {
  TInput,
  TTextarea,
  TSelect,
  TRadio,
  TCheckbox,
  TButton,
  TTag,
  TAlert,
  TModal,
} from 'vue-tailwind/dist/components';

export default {
  't-input': {
    component: TInput,
    props: {
      fixedClasses:
        'block w-full px-3 py-2 leading-6 border placeholder-gray-500 bg-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-0',
      classes: 'text-black border-gray-300 focus:border-black',
      variants: {
        danger: 'border-red-700 text-red-700 focus:border-red-900',
      },
    },
  },
  't-textarea': {
    component: TTextarea,
    props: {
      fixedClasses:
        'block w-full px-3 py-2 leading-6 placeholder-gray-500 bg-white border rounded-md disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-0',
      classes: 'text-black border-gray-300 focus:border-black',
      variants: {
        danger: 'border-red-700 text-red-700 focus:border-red-900',
      },
    },
  },
  't-select': {
    component: TSelect,
    props: {
      fixedClasses:
        'block w-full pl-3 pr-6 py-2 leading-6 placeholder-gray-500 bg-white border rounded-md disabled:opacity-50 disabled:bg-gray-300 disabled:cursor-not-allowed focus:outline-none focus:ring-0',
      classes: 'text-black border-gray-300 focus:border-black',
      variants: {
        danger: 'border-red-700 text-red-700 focus:border-red-900',
        themeSelection: 'border-gray-300 disabled:opacity-100 disabled:bg-gray-100 disabled:border-gray-300',
      },
    },
  },
  't-radio': {
    component: TRadio,
    props: {
      fixedClasses:
        'transition duration-100 ease-in-out focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-0',
      classes: 'text-green-500',
    },
  },
  't-checkbox': {
    component: TCheckbox,
    props: {
      fixedClasses:
        'transition duration-100 ease-in-out rounded border-gray-300 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-0',
      classes: 'text-green-500',
    },
  },
  't-button': {
    component: TButton,
    props: {
      fixedClasses:
        'block px-5 rounded-lg text-lg leading-6 font-semibold shadow-lg disabled:text-gray-400 disabled:pointer-events-none',
      classes: 'py-3.5 text-white bg-emerald-500 disabled:bg-gray-100',
      variants: {
        small: 'py-2 text-white bg-emerald-500 disabled:bg-gray-100',
        primaryAlt: 'py-3.5 bg-yellow-200 disabled:bg-gray-100',
        primaryAltSmall: 'py-2 bg-yellow-200 disabled:bg-gray-100',
        secondary: 'py-3.5 text-black bg-white',
        secondarySmall: 'py-2 text-black bg-white',
        link: 'text-black-500 bg-transparent leading-none shadow-none disabled:bg-transparent',
      },
    },
  },
  't-icon-button': {
    component: TButton,
    props: {
      fixedClasses:
        'flex items-center h-8 rounded-lg text-xs leading-4 font-semibold disabled:text-gray-400 disabled:pointer-events-none',
      classes: 'px-2 text-black bg-gray-100',
      variants: {
        link: 'bg-transparent px-0',
      },
    },
  },
  't-nav-button': {
    component: TButton,
    props: {
      fixedClasses:
        'flex items-center justify-center h-8 w-8 rounded-lg disabled:pointer-events-none',
      classes: 'text-black bg-transparent',
      variants: {
        inverse: 'text-white',
        through: 'bg-white shadow-lg',
      },
    },
  },
  't-tag': {
    component: TTag,
    props: {
      fixedClasses: '',
      classes: '',
      variants: {
        sectionTitle: 'text-4xl leading-10 font-extrabold mb-4',
        blockTitle: 'text-2xl leading-7 font-bold mb-4',
        subTitle: 'text-3xl leading-8 font-extrabold mb-6',
        highlight: 'text-teal-600',
        highlightInverse: 'text-teal-100',
        badge: 'text-xs leading-4 font-medium tracking-wider py-1 px-2.5 text-white bg-emerald-500 rounded-md',
      },
    },
  },
  't-alert': {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: 'relative flex items-center p-4 rounded-lg',
        body: 'flex-grow',
        close:
          'absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded',
        closeIcon: 'fill-current h-4 w-4',
      },
      classes: {
        wrapper: 'bg-cyan-500',
        body: 'text-white',
        close: 'text-white bg-cyan-600',
      },
      variants: {
        danger: {
          wrapper: 'bg-red-700',
          body: 'text-white',
          close: 'text-white bg-red-800',
        },
        success: {
          wrapper: 'bg-green-500',
          body: 'text-white',
          close: 'text-white bg-green-600',
        },
      },
    },
  },
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'z-40 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
        wrapper: 'absolute left-0 bottom-0 z-50 w-full',
        modal: 'overflow-visible relative  rounded-t-2xl',
        body: 'mb-4',
        header: 'mb-4',
        footer: 'mb-4',
        close: 'flex items-center justify-center rounded-full absolute right-0 top-0 h-8 w-8',
        closeIcon: 'fill-current h-4 w-4',
      },
      classes: {
        overlay: 'bg-black',
        wrapper: '',
        modal: 'bg-white p-6 max-w-screen-md mx-auto',
        body: '',
        header: '',
        footer: '',
        close: '',
        closeIcon: '',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-300',
        overlayEnterToClass: 'opacity-1',
        overlayLeaveClass: 'opacity-1',
        overlayLeaveActiveClass: 'transition ease-in duration-300',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
    },
  },
};
