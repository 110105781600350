// === GENERAL ==============================//
export const PUSH_BROWSER_SUPPORT = 'PUSH_BROWSER_SUPPORT';

// === PERSISTENT STORAGE ===================//

// === DATA STORE ===========================//

// === USER ================================//
export const GET_USER_HAS_IDENTITY = 'GET_USER_HAS_IDENTITY';
export const GET_USER_NOTICES = 'GET_USER_NOTICES';
export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES';

// === DASHBOARD ===========================//

// === POPUP ===============================//

// === EVENTS ==============================//

// === RECRUITMENT =========================//

// === RESTAURANTS =========================//

// === ONBOARDING =========================//
export const GET_USER_ROLE = 'GET_USER_ROLE'

// === PROMOTED =========================//
export const GET_FILTER_CATEGORIES_MODULE_ITEM = 'GET_FILTER_CATEGORIES_MODULE_ITEM'
