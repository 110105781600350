<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <prj1004-article-body
          v-if="buildingIntroPost"
          :articleData="buildingIntroPost"
        />
        <gen1006-info-panel
          v-else
          type="info"
        >
          {{ $t('advent.postNotPublish') }}
        </gen1006-info-panel>
        <gen1006-info-panel
          v-if="!isLoading && !initialized"
          type="neutral"
        >
          {{ $t('general.loadingFailed') }}
        </gen1006-info-panel>
      </template>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
      <frm1006-button
        class="vue-button-register vue-button-page-layout-fixed"
        @buttonClickEvent="goToBuildingDashboard"
      >
        {{ $t('buildingHHQ.continue') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import router from '@/router';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1004ArticleBody from '@/components/prj1004-article-detail/prj1004-article-body';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'BuildingHHQStart',
  components: {
    Prj1002Navbar,
    PageLayout,
    Prj1004ArticleBody
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      buildingIntroPost: {}
    }
  },

  computed: {
    ...mapState('persistentStorage', [
      'isBuildingHHQIntroVisited',
    ]),

    pageId() {
      return this.$route.params.pageId
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  created() {},

  mounted() {
    if (!this.pageId) router.push({ name: 'hhq-building' });
    this.initialized = true;
    this.fetchPageDetail();
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    fetchPageDetail() {
      this.setLoadingState(true);

      dataLoader.fetchPostById(this.pageId)
        .then(data => {
          this.buildingIntroPost = data[0];
        })
        .catch(error => {
          logger.error(error);
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.setLoadingState(false);
        })
    },

    goToBuildingDashboard() {
      this.$store.commit(STORE_MODULES.PERSISTENT_STORAGE + '/' + MUTATIONS_CONSTANTS.SET_BUILDING_HHQ_INTRO_VISITED, true);
      router.push({ name: 'hhq-building' });
    },

    controlClassObject(status) {
      return {
        'vue-is-disabled': status === 'future'
      }
    },
  }
}
</script>
