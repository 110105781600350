//============ GENERAL =================================//
//======================================================//
import { SET_CHALLENGE_INVOLVE, SET_CHALLENGES_INFO, SORT_CHALLENGES_INFO } from '@/store/constants/fest/mutations'
import { GET_CHALLENGES_INFO } from '@/store/constants/fest/actions'
import { EXPIRED } from '@/constants/fest/status'
import { sortingEvents } from '@/utils/fest/sorting'

//============ API =====================================//
//======================================================//
import apiCmsChallenges from '@/fest-api/cms-challenges'
import logger from '@/utils/logger'

//============ STATE ===================================//
//======================================================//
const state = {
  challengesInfo: [],
  sortedChallengesInfo: [],
  challengesCount: null,
  activeChallengesCount: null,
  challengesUserInvolved: [],
}

//============ MUTATIONS ===============================//
//======================================================//
const mutations = {
  [SET_CHALLENGES_INFO](state, payload) {
    state.challengesInfo = [...payload]
    state.challengesCount = payload.length
    state.activeChallengesCount = payload.filter((item) => item.status !== EXPIRED).length
  },
  [SORT_CHALLENGES_INFO](state, payload) {
    state.sortedChallengesInfo = sortingEvents(payload)
  },
  [SET_CHALLENGE_INVOLVE](state, payload) {
    state.challengesUserInvolved.push(payload)
  },
}

//============ ACTIONS =================================//
//======================================================//

const actions = {
  async [GET_CHALLENGES_INFO]({ commit }) {
    try {
      const response = await apiCmsChallenges.getAllChallenges()
      commit(SET_CHALLENGES_INFO, response)
      commit(SORT_CHALLENGES_INFO, response)
    } catch (error) {
      logger.error(error)
    }
  },
}

//============ GETTERS =================================//
//======================================================//

const getters = {}

//============ EXPORT ==================================//
//======================================================//
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
