<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        :searchButton="true"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1032-news-list
        ref="newsList"
        :categoryIdInitial="categoryId"
        :categoryFilter="false"
        @filteredArticlesChangeEvent="filteredArticlesChange"
        @filteredCategoryNameUpdatedEvent="setNavbarCaption"
        @initializedEvent="initializeNewsListEvent"
      />

      <gen1016-loading-indicator
        v-if="1 === 0"
        :active="!loadingComplete"
        :overlay="true"
        :fullScreen="true"
      />
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <!-- FIXME MBU: category filter torn out of news-list component into this slot -->
      <!-- call its functions via refs -->
      <prj1026-category-filter
        v-if="newsListInitialized && $refs.newsList.articlesCategories.length > 0"
        :filterCategories="$refs.newsList.articlesCategories"
        :activeCategory="parseInt(newsActiveCategory)"
        filterType="articles"
        @filterItems="filterItems"
        @resetFilteredItems="$refs.newsList.resetFilteredArticles()"
      />
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';
import { ARTICLES, NEWS } from '@/store/store-modules';

import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1032NewsList from '@/components/prj1032-news-list/prj1032-news-list';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

export default {
  name: 'NewsList',

  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1026CategoryFilter,
    Prj1032NewsList,
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics,
    mxDetectDesktop
  ],

  data() {
    return {
      loadingInProgress: true,
      navbarCaptionData: null,
      newsListInitialized: false,
    };
  },

  computed: {
    ...mapState('articles', [
      'newsActiveCategory',
    ]),
    ...mapState('general', [
      'applicationConfig',
    ]),
    ...mapState('dashboard', {
      storedSelectedBuilding: state => state.selectedBuilding
    }),

    categoryId() {
      let categoryId = this.$route.query.categoryId;

      if (categoryId) {
        return parseInt(categoryId);
      } else {
        let retValue = 0;
        if (typeof this.applicationConfig.localities !== 'undefined') {
          this.applicationConfig.localities.forEach(e =>
            Object.entries(e).forEach(([key, value]) => {
              if (key === 'id' && this.storedSelectedBuilding === value && e.hasOwnProperty('articleTagId')) {
                retValue = e.articleTagId;
              }
            })
          );
        }
        return parseInt(retValue); // 0 returns all categories
      }
    },

    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },

    loadingComplete() {
      return !this.loadingInProgress;
    },
  },

  mounted() {
    this.sendStatistics(NEWS);
  },

  methods: {
    filterItems(selectedCategoryNumber) {
      this.$store.commit(ARTICLES + '/' + MUTATIONS_CONSTANTS.SET_NEWS_ACTIVE_CATEGORY, selectedCategoryNumber);
      this.$refs.newsList.loadNewContent(false);
    },

    // FIXME MBU: reports only if legth of articles is > 0, not safe
    // API with categories should return only categories, that have items
    filteredArticlesChange(value) {
      if (value.length > 0) {
        this.loadingInProgress = false;
      }
    },

    // TODO MBU: not ideal solution
    setNavbarCaption(value) {
      this.navbarCaptionData = value;
    },

    initializeNewsListEvent() {
      this.newsListInitialized = true;
    },
  }
}
</script>
