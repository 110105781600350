<template>
  <page-layout>
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>
    <template v-slot:contentMain>
      <h1 class="vue-page-title">
        {{ $t('general.pageNotFound') }}
      </h1>
    </template>
    <template v-slot:contentRest>
      <frm1006-button @buttonClickEvent="$router.replace({name: 'dashboard'})">
        {{ $t('general.home') }}
      </frm1006-button>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

export default {
  name: 'NotFound404',
  components: {
    PageLayout,
    Prj1002Navbar
  },

  mixins: [mxNavigationDefault],

  methods: {
  }
}
</script>
