<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        buttonMode="custom"
        @buttonCustomEvent="buttonBackPresentationList"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        class="vue-fullpage-wrapper"
        :class="classObject"
      >
        <div class="vue-b-presentation vue-b-presentation-list">
          <prj1009-map-header
            :button-back="false"
            class="vue-page-header"
          >
            <template v-slot:main>
              <div class="vue-search-container">
                <div class="vue-b-search">
                  <frm1035-form-group>
                    <frm1001-input-field
                      v-model="mapSearchString"
                      name="map-search"
                      autocomplete="off"
                      :placeholder="$i18n.t('maps.searchPlaceholder')"
                      :tooltip="false"
                      class="vue-ci-search-list"
                      @inputFieldFocusEvent="mapSearchFocus"
                      @inputFieldBlurEvent="mapSearchBlur"
                    />
                  </frm1035-form-group>
                </div>

                <div
                  v-if="floorFilterActive"
                  class="vue-b-filter"
                >
                  <h3>{{ $t('maps.floorFilter') }}</h3>

                  <frm1035-form-group>
                    <frm1028-advanced-combo
                      v-model="selectedMapBuilding"
                      :options="mapBuildings"
                      :required="false"
                      :tooltip="false"
                      :label="$i18n.t('maps.labelBuilding')"
                      :placeholder="$i18n.t('maps.labelBuilding')"
                      @change="onChangeBuildingEvent"
                    />
                  </frm1035-form-group>

                  <frm1035-form-group>
                    <frm1028-advanced-combo
                      v-show="mapFloorsOptions.length > 0"
                      v-model="selectedMapFloor"
                      :options="mapFloorsOptions"
                      :required="false"
                      :tooltip="false"
                      :label="$i18n.t('maps.labelFloor')"
                      :placeholder="$i18n.t('maps.labelFloor')"
                    />
                  </frm1035-form-group>

                  <frm1035-form-group>
                    <frm1006-button
                      v-show="selectedMapBuilding && selectedMapFloor"
                      type="primary2"
                      class="vue-draft-form-cancel"
                      @buttonClickEvent="onClickEventViewMap()"
                    >
                      {{ $t('maps.display') }}
                    </frm1006-button>
                  </frm1035-form-group>
                </div>
              </div>
            </template>
          </prj1009-map-header>

          <div class="vue-page-content">
            <div class="vue-b-list">
              <tbl1001-repeater
                :items="mapRepeaterItems"
                itemKey="id"
                :header="false"
                :footer="false"
                :paginated="false"
                class="vue-ci-map-list"
              >
                <template v-slot:item="props">
                  <prj1009-map-repeater-item
                    :mapData="mapData"
                    :item="props.item"
                    :itemActiveId="itemActiveId"
                    :itemActiveType="itemActiveType"
                    class="vue-repeater-item"
                    @mapRepeaterItemClickEvent="showRoomOnMap"
                  />
                </template>
              </tbl1001-repeater>
            </div>
          </div>
        </div>

        <!--=== MAP =========================================-->
        <!--=================================================-->
        <div class="vue-b-presentation vue-b-presentation-map">
          <!--==== CONTENT ========================-->
          <div class="vue-page-content">
            <div class="vue-b-map">
              <div id="vue-ci-output" />

              <prj1009-map
                mode="map"
                :map-data="mapData"
                :building-active-id="buildingActiveId"
                :floor-active-id="floorActiveId"
                :item-active-id="itemActiveId"
                :buildings-selected-id="buildingsSelectedId"
                :floors-selected-id="floorsSelectedId"
                :rooms-selected-id="roomsSelectedId"
                :center-map="centerMap"
                class="vue-ci-map-main"
              />

              <!-- TODO MBU: remove this testing code / or use v-if="devMode" condition or something similar -->
              <div id="vue-ci-preview">
                <button @click="selectSpecificRooms">
                  Select specific rooms
                </button>
                <button @click="deselectRooms">
                  Deselect rooms
                </button>
                <button @click="selectAllRooms">
                  Select all rooms
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <gen1016-loading-indicator
        :active="reportFormSending"
        :overlay="true"
        :fullScreen="true"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-maps-report-form-modal"
        :active.sync="isReportFormModalActive"
        :buttonClose="true"
        preventClosing="all"
      >
        <div class="vue-b-data">
          <h2>{{ $t('maps.reportFormTitle') }}</h2>

          <gen1006-info-panel
            type="neutral"
          >
            {{ $t('maps.reportFormInfoMessage') }}
          </gen1006-info-panel>

          <frm1035-form-group>
            <frm1001-input-field
              v-model="reportFormLocation"
              :required="false"
              :disabled="true"
              :tooltip="false"
              :label="$i18n.t('maps.reportFormLocation')"
              @inputChangeEvent="reportFormValidate"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1013-textarea
              v-model="reportFormDescription"
              :required="true"
              :state="reportFormDescriptionInvalidState()"
              :label="$i18n.t('maps.reportFormDescription')"
              :rowsAutoExpand="true"
              :rowsAutoExpandMinRows="1"
              :rowsAutoExpandMaxRows="8"
              :tooltip="false"
            />
          </frm1035-form-group>

          <frm1035-form-group>
            <frm1006-button
              type="primary"
              class="vue-report-form-submit"
              :disabled="!isReportFormValid || reportFormSending || reportFormSent"
              @buttonClickEvent="clickEventSubmitReportForm()"
            >
              {{ $t('maps.reportFormButton') }}
            </frm1006-button>
          </frm1035-form-group>
        </div>
      </gen1009-overlay>

      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="reportSent"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="success"
        >
          {{ $t('maps.reportSentSuccessfull') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>

    <template v-slot:footer>
      <prj1026-category-filter
        v-if="presentationMode === 'overview' && mapRepeaterItems.length > 0"
        :filterCategories="filterCategories"
        :activeCategory="parseInt(filterSelected)"
        :showItems="filterCategories.length"
        :showFilterButtonMore="false"
        filterType="building"
        @filterItems="filterCategoryItems"
        @resetFilteredItems="resetItems"
      />
    </template>
  </page-layout>
</template>

<script>

//=== IMPORT ======================================//
//=================================================//

//=== PROJECT COMPONENTS
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1009Map from '@/components/prj1009-map/prj1009-map';
import Prj1009MapMixinsSearch from '@/components/prj1009-map/prj1009-map-mixin-search';
import Prj1009MapRepeaterItem from '@/components/prj1009-map/prj1009-map-repeater-item';
import Prj1009MapHeader from '@/components/prj1009-map/prj1009-map-header';
import PageLayout from '../../templates/partials/page-layout';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter';

// import Prj1009MapSearchResult from '@/components/prj1009-map/prj1009-map-search-result';

//=== CONSTANTS
import * as MAP from '@/static/mapData';
import {WHERE_TO_FIND} from '@/store/store-modules';
import {ERROR_MODAL_TIMEOUT} from '@/constants/app-constants';

//=== MIXINS
import mxSearch from '@/mixins/mx-search.vue';
import moduleStatistics from '../../mixins/mx-module-statistics';

//=== UTILITY
import Scroller from 'vue-scrollto';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import {mapState} from 'vuex';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import Gen1006InfoPanel from '../../../frameworks/vue/components/gen/gen1006-info-panel/gen1006-info-panel.vue';

//=== OPTIONS
let options = {
  // TODO MBU use options
  cssSelectors: {

  },
  // TODO MBU use options
  cssClasses: {

  },
  cssVariables: {
    globalHeaderHeight: 60,
    whereToFindAnimDurationTransform: 1000
  },
};

//=== EXPORT ======================================//
//=================================================//
export default {
  name: 'RoomSearch',
  components: {
    Gen1006InfoPanel,
    Prj1002Navbar,
    Prj1009Map,
    Prj1009MapRepeaterItem,
    Prj1009MapHeader,
    PageLayout,
    Prj1026CategoryFilter
    //Prj1009MapSearchResult
  },

  mixins: [
    Prj1009MapMixinsSearch,
    mxSearch,
    moduleStatistics,
    mxNavigationDefault,
    mxDetectDesktop
  ],

  data() {
    return {
      devMode: false,
      presentationMode: 'overview',
      mapSearchString: '',
      mapSearchInputFocus: false,
      mapRepeaterItems: [],
      mapData: MAP.DATA,
      mapMode: 'overview',
      buildingActiveId: null,
      floorActiveId: null,
      itemActiveId: null,
      itemActiveType: 'room', // could be teams, departments etc., necessary to fill them to separate data / computed like mapDataRooms and search through it etc.
      buildingsSelectedId: [],
      floorsSelectedId: [],
      roomsSelectedId: [],
      floorFilterActive: true,
      animationFromOverviewToList: null,
      backButtonRoute: 'dashboard',
      filterCategories: [
        {
          termId: 1,
          termName: 'NHQ'
        },
        {
          termId: 2,
          termName: 'SHQ'
        },
        {
          termId: 3,
          termName: 'HHQ'
        },
      ],
      filterSelected: 0,
      selectedMapBuilding: '',
      selectedMapFloor: '',
      mapBuildings: [
        {
          value: 'all',
          caption: 'Vše'
        },
        {
          value: 'NHQ',
          caption: 'NHQ'
        },
        {
          value: 'SHQ',
          caption: 'SHQ'
        },
        {
          value: 'HHQ',
          caption: 'HHQ'
        }
      ],
      reportSent: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      isReportFormValid: false,
      reportFormSending: false,
      reportFormSent: false,
      centerMap: false
    }
  },

  computed: {
    ...mapState('maps', [
      'modalReportFormActive',
      'reportFormData'
    ]),

    classObject() {
      return {
        'vue-is-dev-mode': this.devMode,
        [`vue-is-presentation-mode-${this.presentationMode}`]: this.presentationMode,
        'vue-has-item-active': this.itemActive,
        'vue-has-search-input-focused': this.mapSearchInputFocus,
        'vue-animation-from-overview-to-list': this.animationFromOverviewToList
      }
    },

    headerClassObject() {
      return {
        // [`vue-is-building-${this.mapData[this.itemActive.buildingId].code}`]: this.itemActive && this.itemActiveType === 'room', TODO MBU: error in evaluation rewrite manually below
        'vue-is-building-SHQ': this.itemActive && this.itemActiveType === 'room' && this.itemActive.buildingId === 'SHQ',
        'vue-is-building-NHQ': this.itemActive && this.itemActiveType === 'room' && this.itemActive.buildingId === 'NHQ'
      }
    },

    headerCaptionPresentationMap() {
      let headerCaption;

      if (this.itemActive === null) {
        headerCaption = 'Kde najdu'
      } else if (this.itemActiveType === 'room') {
        headerCaption = this.itemActive.code;
      }

      return headerCaption;
    },

    itemActive() {
      if (this.itemActiveId && this.itemActiveType === 'room') {
        let roomActive = this.mapData[this.buildingActiveId].floors[this.floorActiveId].rooms[this.itemActiveId];
        return roomActive;
      } else {
        return null;
      }
    },

    buildingName() {
      if(this.mapData[this.buildingActiveId]) {
        return this.mapData[this.buildingActiveId].name;
      } else {
        return '';
      }
    },

    floorName() {
      if(this.mapData[this.buildingActiveId]) {
        return this.mapData[this.buildingActiveId].floors[this.floorActiveId].name
      } else {
        return '';
      }
    },

    mapDataRooms() {
      let mapDataRooms = [];
      // Search buildings
      for (let buildingId in this.mapData) {
        let building = this.mapData[buildingId];

        // Search floors
        for (let floorId in building.floors) {
          let floor = this.mapData[buildingId].floors[floorId];

          // Search rooms
          for (let roomId in floor.rooms) {
            let room = this.mapData[buildingId].floors[floorId].rooms[roomId];

            mapDataRooms.push(room);
          }
        }
      }

      return mapDataRooms;
    },

    mapFloorsOptions() {
      let retValues = [];
      let building = this.mapData[this.selectedMapBuilding];

      if (building) {
        for (let floorId in building.floors) {
          let floor = this.mapData[this.selectedMapBuilding].floors[floorId];

          retValues.push({
            value: floor['code'],
            caption: floor['name']
          });
        }
      }

      return retValues;
    },

    isReportFormModalActive: {
      get() {
        return this.modalReportFormActive;
      },
      set(newValue) {
        this.$store.commit(STORE_MODULES.MAPS + '/' + MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_MODAL, newValue);
      }
    },

    reportFormLocation: {
      get () {
        return this.reportFormData.location;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MAPS + '/' + MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_DATA_LOCATION, value);
      }
    },

    reportFormDescription: {
      get () {
        return this.reportFormData.description;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MAPS + '/' + MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_DATA_DESCRIPTION, value);
      }
    },

    reportFormCompleted: {
      get () {
        return this.reportFormData.completed;
      },
      set (value) {
        this.$store.commit(STORE_MODULES.MAPS + '/' + MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_DATA_COMPLETED, value);
      }
    }
  },
  watch: {
    '$route.params.mode'(newMode) {
      if(newMode === 'overview') {
        this.setViewOverview();
      }
    },

    mapSearchInputFocus() {
      let pageHeaderContentBottom = document.querySelector('.vue-b-presentation-list .vue-page-header .vue-b-content-bottom');

      if ((this.mapSearchInputFocus === true) && (this.$el.scrollTop === 0)) {
        this.$nextTick(function () {
          Scroller.scrollTo(pageHeaderContentBottom, 500, {container: this.$el});
        })
      }
    },

    mapSearchString(value) {
      this.floorFilterActive = value.length === 0;
      if (!this.floorFilterActive) {
        this.selectedMapBuilding = '';
        this.selectedMapFloor = '';
      }
    },

    selectedMapBuilding(value) {
      if (value === 'all') {
        this.selectedMapFloor = '';
      }
    },

    reportFormDescription() {
      this.reportFormValidate();
    },

    isReportFormModalActive(value) {
      if (value) {
        this.reportFormSent = false;
        this.reportSent = false;
        if (this.selectedMapBuilding && this.selectedMapFloor) {
          this.reportFormLocation = this.selectedMapBuilding + '-' + this.selectedMapFloor;
        } else {
          this.reportFormLocation = this.floorActiveId;
        }
      }
    }
  },

  beforeCreate() {
    if (typeof this.$route.params.mode === 'undefined') {
      this.$route.params['mode'] = 'overview';
    }
  },

  mounted() {
    this.watchElementScroll(this.$el);
    this.sendStatistics(WHERE_TO_FIND);
    if(this.$route.params.room) {
      this.transformRoom(this.$route.params.room);
    }
  },

  methods: {
    watchElementScroll(element) {
      let component = this;
      let scrollPosition;
      let ticking = false;

      element.addEventListener('scroll', () => {
        scrollPosition = element.scrollTop;

        if (!ticking) {
          window.requestAnimationFrame(() => {
            component.fixHeaderContentBottom(scrollPosition);
            ticking = false;
          });

          ticking = true;
        }
      });
    },

    fixHeaderContentBottom(scrollPosition) {
      let pageHeader = document.querySelector('.vue-b-presentation-list .vue-page-header');

      if (scrollPosition > options.cssVariables.globalHeaderHeight) {
        pageHeader.classList.add('vue-has-content-bottom-fixed');
      } else {
        pageHeader.classList.remove('vue-has-content-bottom-fixed');
      }
    },

    setViewOverview() {
      this.buildingActiveId = null;
      this.floorActiveId = null;
      this.itemActiveId = null;
      this.buildingsSelectedId = [];
      this.floorsSelectedId = [];
      this.roomsSelectedId = [];
      // WORKAROUND MBU: show header from presentation list on presentation overview
      this.presentationMode = 'overview';
    },

    // TODO MBU: remove this test code?
    selectAllRooms() {
      let component = this;

      this.deselectRooms();

      for (let roomId in this.mapDataRooms) {
        let room = this.mapDataRooms[roomId];
        component.addRoomToSelection(room.buildingId, room.floorId, room.id);
      }
    },

    // TODO MBU: remove this test code?
    selectSpecificRooms() {
      this.buildingsSelectedId = ['SHQ'];
      this.floorsSelectedId = ['SHQ-1NP'];
      this.roomsSelectedId = ['SHQ-1NP-1S01', 'SHQ-1NP-1S02', 'SHQ-1NP-0Z07'];
    },

    mapSearchFocus() {
      this.mapSearchInputFocus = true;
      this.presentationMode = 'list';
    },

    mapSearchBlur() {
      this.mapSearchInputFocus = false;
    },

    selectRoom(buildingId, floorId, roomId) {
      this.deselectRooms();
      this.addRoomToSelection(buildingId, floorId, roomId);
      this.setActiveItem('room', buildingId, floorId, roomId)
    },

    setActiveItem(itemType, buildingId, floorId, roomId) {
      if (itemType === 'room') {
        this.buildingActiveId = buildingId;
        this.floorActiveId = floorId;
        this.itemActiveId = roomId;
      }
    },

    buttonBackPresentationList() {
      if( this.presentationMode === 'list' ) {
        this.buildingActiveId = null;
        this.floorActiveId = null;
        this.itemActiveId = null;
        this.buildingsSelectedId = [];
        this.floorsSelectedId = [];
        this.roomsSelectedId = [];
      } else if( this.presentationMode === 'overview' ) {
        this.$router.replace({ name: 'dashboard' })
      } else if( this.$route.params.room ) {
        this.$router.push({ name: 'services-detail', params: { id: this.$route.params.serviceId }});
      }

      this.presentationMode = 'overview';
      this.animationFromOverviewToList = true;
    },

    buttonBackPresentationMap() {
      if (this.itemActive === null) {
        this.presentationMode = 'overview';
        this.buildingActiveId = null;
        this.floorActiveId = null;
      } else {
        this.presentationMode = 'list'
      }
    },

    deselectRooms() {
      this.buildingsSelectedId = [];
      this.floorsSelectedId = [];
      this.roomsSelectedId = [];
    },

    addRoomToSelection(buildingId, floorId, roomId) {
      if (this.buildingsSelectedId.indexOf(buildingId) === -1) {
        this.buildingsSelectedId.push(buildingId);
      }

      if (this.floorsSelectedId.indexOf(floorId) === -1) {
        this.floorsSelectedId.push(floorId);
      }

      if (this.roomsSelectedId.indexOf(roomId) === -1) {
        this.roomsSelectedId.push(roomId);
      }
    },

    showFloorOnMap(buildingId, floorId, roomId = null) {
      this.buildingActiveId = buildingId;
      this.floorActiveId = floorId;
      this.mapMode = 'map';
      this.presentationMode = 'map';
      this.centerMap = roomId === null;
      this.$router.push({ path: 'search' }, () => {});
    },

    showRoomOnMap(buildingId, floorId, roomId) {
      this.selectRoom(buildingId, floorId, roomId);
      this.showFloorOnMap(buildingId, floorId, roomId);
      this.presentationMode = 'map';
      this.$router.push({ path: 'search' }, () => {});
    },

    setActiveFloor(buildingId, floorId) {
      this.showFloorOnMap(buildingId, floorId);
    },

    /**
     * @param categoryNumber number, $emitted from Prj1026CategoryFilter
     */
    filterCategoryItems(categoryNumber) {
      this.filterSelected = categoryNumber;
    },

    resetItems() {
      this.filterSelected = 0
    },

    transformRoom(roomString) {
      const roomArray = roomString.split('-');
      if (roomArray.length === 3) {
        this.showRoomOnMap(
          roomArray[0],
          roomArray[0] + '-' + roomArray[1],
          roomArray[0] + '-' + roomArray[1] + '-' + roomArray[2]
        );
      }
    },

    onClickEventViewMap() {
      this.showFloorOnMap(this.selectedMapBuilding, this.selectedMapBuilding + '-' + this.selectedMapFloor);
    },

    reportFormValidate() {
      let decision = false;

      if (this.reportFormData.location.length > 0 &&
        this.reportFormData.description.length > 0) {
        decision = true;
      }

      this.isReportFormValid = decision;
    },

    reportFormDescriptionInvalidState() {
      if (!this.reportFormData.description) {
        return 'invalid'
      } else {
        return 'info'
      }
    },

    onChangeBuildingEvent() {
      this.selectedMapFloor = '';
    },

    clickEventSubmitReportForm() {
      let formData = new FormData();
      formData.append('location', this.reportFormData.location);
      formData.append('description', this.reportFormData.description);
      this.reportFormSending = true;

      dataLoader.sendMapsReport(
        formData
      ).then(() => {
        logger.info('Form has been sent!');
        this.reportFormSent = true;
        this.reportSent = true;
      }).catch(error => {
        logger.error('Sending form has failed:', error);
        //this.formFailed = true;
        this.reportFormSent = false;
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.reportFormSending = false;
        this.$store.commit(STORE_MODULES.MAPS + '/' + MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_MODAL, false);
        this.$store.commit(STORE_MODULES.MAPS + '/' + MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_DATA_RESET);
      })
    },
  }
}
</script>
