<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    :class="{ 'w-6/7': !single }"
    class="flex-shrink-0 flex w-full max-h-px-680 first:pl-7 last:pr-7"
  >
    <div
      class="relative flex flex-col w-full rounded-2xl text-white"
      :class="[isCorporate ? 'bg-emerald-600' : 'bg-emerald-500']"
    >
      <!-- Scrollable content -->
      <div
        class="flex-1 overflow-y-auto mt-4 px-4 prj-scrollbar-inverse"
        :class="{ 'mb-14 pb-6': author }"
      >
        <!-- Photo -->
        <div
          v-if="storyImg"
          class="h-20 md-portrait:h-40 lg:h-32 2xl:h-40 mb-4"
        >
          <div
            class="overflow-hidden h-full rounded-lg cursor-pointer"
            @click="expandImage"
          >
            <img
              :src="storyImg"
              :alt="title"
              loading="lazy"
              class="w-full h-full object-cover"
            />
          </div>

          <transition name="expand-story-card-image">
            <div
              v-if="expandedImage"
              class="
                absolute
                z-10
                transition-all
                duration-300
                overflow-hidden
                cursor-pointer
                top-0
                left-0
                right-0
                h-full
                rounded-2xl
              "
              @click="expandImage"
            >
              <img
                :src="storyImg"
                :alt="title"
                loading="lazy"
                class="w-full h-full object-cover"
              />
            </div>
          </transition>
        </div>

        <!-- Story -->
        <div class="mb-4">
          <div
            class="prj-last-paragraph whitespace-pre-wrap"
            v-html="content"
          ></div>
          <span
            v-if="themeTitle"
            class="flex items-center relative -left-0.5 font-bold text-yellow-200"
          ><svg
             class="h-6 w-6"
             viewBox="0 0 24 24"
             fill="currentColor"
             xmlns="http://www.w3.org/2000/svg"
           >
             <path d="M9.35362 6H7.28125L4 18.1903H6.07362L9.35362 6Z" />
             <path d="M14.6636 6H12.5859L9.30469 18.1903H11.3836L14.6636 6Z" />
             <path d="M19.9636 6H17.8906L14.6094 18.1903H16.6836L19.9636 6Z" />
           </svg>
            <span>{{ themeTitle }}</span></span>
        </div>
      </div>

      <!-- Author -->
      <div
        v-if="author"
        class="absolute right-4 left-4 bottom-4 pt-4 flex bg-gradient-to-t scale-100"
        :class="[isCorporate ? 'from-emerald-600 via-emerald-600' : 'from-emerald-500 via-emerald-500']"
      >
        <!-- Author photo -->
        <div
          class="flex-shrink-0 w-15 h-15 mr-3.5 rounded-full"
          :class="{'relative -left-1 top-1 box-content border-yellow-300 border-4': isWinner}"
        >
          <img
            v-if="author.image"
            :src="author.image"
            alt
            class="w-full h-full rounded-full object-cover"
          />
          <div
            v-else
            class="flex items-center justify-center w-full h-full rounded-full bg-white overflow-hidden"
          >
            <svg
              class="h-20 w-20 relative top-6"
              viewBox="0 0 22 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8001 15.6002C15.108 15.6002 18.6002 12.108 18.6002 7.80011C18.6002 3.49223 15.108 0 10.8001 0C6.49223 0 3 3.49223 3 7.80011C3 12.108 6.49223 15.6002 10.8001 15.6002Z"
                fill="#FAD4BE"
              />
              <path
                d="M11.0001 16C4.9319 16 0 19.4438 0 23.687V46.748C0 47.9917 1.44288 49 3.22262 49H18.7774C20.5571 49 22 47.9917 22 46.748V23.687C22 19.4438 17.0685 16 11.0001 16V16Z"
                fill="#FF5078"
              />
              <path
                d="M10.6017 0C14.9048 0 18.4017 3.49444 18.4017 7.80011C18.4017 12.1044 14.9048 15.6002 10.6016 15.6002V0H10.6017Z"
                fill="#FAD4BE"
              />
              <path
                d="M11 16V49H18.7773C20.5571 49 22 47.9917 22 46.748V23.687C22 19.4438 17.0685 16 11 16V16Z"
                fill="#DC3C60"
              />
            </svg>
          </div>
          <span
            v-if="isWinner"
            class="absolute -bottom-2 -right-2 flex items-center justify-center w-9 h-9 bg-yellow-300 text-black rounded-full"
          >
            <svg
              class="w-6 h-6"
              viewBox="0 0 24 25"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 15.4167C15.3137 15.4167 18 12.805 18 9.58333C18 6.36167 15.3137 3.75 12 3.75C8.68629 3.75 6 6.36167 6 9.58333C6 12.805 8.68629 15.4167 12 15.4167Z"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.002 15.4199L15.402 21.1453L17 18.003L20.5981 18.2286L17.1981 12.5033"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.80188 12.5033L3.40188 18.2287L6.99996 18.0031L8.59803 21.1454L11.998 15.42"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

          </span>
        </div>
        <!-- Author name, position & date -->
        <div class="flex flex-col justify-center">
          <span class="text-sm leading-5 font-semibold"> {{ author.name }}</span>
          <span
            v-if="author.position"
            class="text-xs leading-4"
          > {{ author.position }}</span>
          <span class="text-xs leading-4 opacity-70 mt-1">{{ publishedDate }}</span>
        </div>
      </div>

      <div class="absolute right-3 -bottom-3 grid grid-flow-col gap-4 z-10">
        <!-- Share -->
        <prj1015-share-button
          :title="author.name"
          :text="content"
          :url="`${locationOrigin}/sustainability/stories/${story.id}`"
        />

        <!-- Likes -->
        <prj1014-like-button
          :likes="likes"
          :liked="isLiked"
          :likesEnabled="true"
          :disabled="disabledActions"
          @clickLike="clickedLikeButton"
        />
      </div>
    </div>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import { mapState } from 'vuex'
import { ESG_STORIES } from '@/store/store-modules'
import { SET_LIKE_STORY, REMOVE_LIKE_STORY } from '@/store/constants/esg/mutations'
import { SUCCESS, USER_LIKE_EXISTS } from '@/store/constants/esg/response-types'

import apiCmsStories from '@/esg-api/cms-stories'

import '@/utils/dayjs'

import Prj1014LikeButton from '@/components/esg/prj1014-like-button/prj1014-like-button'
import Prj1015ShareButton from '@/components/esg/prj1015-share-button/prj1015-share-button'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1008StoryCard',
  components: { Prj1014LikeButton, Prj1015ShareButton },
  props: {
    story: {
      type: Object,
      required: true,
    },
    single: {
      type: Boolean,
      default: false,
    },
    isWinner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedImage: false,
      hiddenButton: true,
      storyId: this.story.id,
      storyImg: this.story.image,
      title: this.story.title,
      content: this.story.content,
      themeTitle: this.story.themeName,
      locationOrigin: window.location.origin,
      disabledActions: false,
    }
  },
  computed: {
    ...mapState('esgStories', {
      currentUserLikedStories: (state) => state.currentUserLikedStories,
    }),
    isCorporate() {
      return !!this.story.corporate
    },
    author() {
      return this.isCorporate ? this.getCorporateData() : this.story.story_owner
    },
    publishedDate() {
      const date = this.story.created
      const today = this.$date.utc().utcOffset(0, true)
      const diff = today.diff(date, 'hour')

      if (diff > 47) {
        return this.$date(date).format('L')
      } else if (diff > 23 && diff < 48) {
        return this.$t('esg.stories.dateYesterday')
      } else {
        return this.$date(date).fromNow()
      }
    },
    likes() {
      return this.story.likes
    },
    isLiked() {
      return this.currentUserLikedStories.includes(this.storyId)
    },
  },
  methods: {
    expandImage() {
      this.expandedImage = !this.expandedImage
    },

    clickedLikeButton(value) {
      return value ? this.sendLike(this.story.id) : this.removeLike(this.story.id)
    },

    async sendLike(id) {
      const { type } = await apiCmsStories.likeStory({ id })
      if (type === SUCCESS) {
        this.$store.commit(`${ESG_STORIES}/${SET_LIKE_STORY}`, id)
      }
      if (type === USER_LIKE_EXISTS) {
        this.$store.commit(`${ESG_STORIES}/${SET_LIKE_STORY}`, id)
      }
      this.disabledActions = false
    },

    async removeLike(id) {
      const { type } = await apiCmsStories.dislikeStory({ id })
      if (type === SUCCESS) {
        this.$store.commit(`${ESG_STORIES}/${REMOVE_LIKE_STORY}`, id)
      }
      this.disabledActions = false
    },

    getCorporateData() {
      return {
        name: this.story.corporateAuthor,
        image: this.story.corporateLogo,
      }
    },
  },
}
</script>
