<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        :infoButton="true"
        :infoButtonDestination="infoButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-marketplace-dashboard">
        <div>
          <prj1017-heading
            :description="$t('marketplace.intro')"
          />
        </div>

        <div class="vue-dashboard-tiles">
          <frm1006-button
            class="vue-dashboard-item-tile vue-tile-ads"
            type="tile"
            @buttonClickEvent="goToView('ads')"
          >
            <template v-slot:iconLeft>
              <div
                class="vue-tile-icon"
              />
            </template>
            {{ $t('marketplace.categories.ads.name') }}
          </frm1006-button>

          <frm1006-button
            class="vue-dashboard-item-tile vue-tile-saved-ads"
            type="tile"
            @buttonClickEvent="goToView('saved-ads')"
          >
            <template v-slot:iconLeft>
              <div
                class="vue-tile-icon"
              />
            </template>
            {{ $t('marketplace.categories.savedAds.name') }}
          </frm1006-button>

          <frm1006-button
            class="vue-dashboard-item-tile vue-tile-my-ads"
            type="tile"
            @buttonClickEvent="goToView('my-ads')"
          >
            <template v-slot:iconLeft>
              <div
                class="vue-tile-icon"
              />
            </template>
            {{ $t('marketplace.categories.myAds.name') }}
          </frm1006-button>
        </div>
      </div>
    </template>
  </page-layout>
</template>

<script>
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import router from '@/router';

export default {
  name: 'MarketplaceDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      navbarCaptionData: null,
      infoButtonDestination: 'marketplace-info'
    };
  },

  computed: {
    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },
  },

  methods: {
    goToView(categoryId) {
      if (categoryId === 'saved-ads') {
        router.push({name: 'marketplace-list-saved'});
      } else if (categoryId === 'my-ads') {
        router.push({name: 'marketplace-list-my'});
      } else {
        router.push({ name: 'marketplace-list' });
      }
    },
  },
}
</script>

