export const GENERAL = 'general';
export const PERSISTENT_STORAGE = 'persistentStorage';
export const DATA_STORE = 'dataStore';
export const ARTICLES = 'articles';
export const USER = 'user';
export const DASHBOARD = 'dashboard';
export const POPUP = 'popup';
export const EVENTS = 'events';
export const RECRUITMENT = 'recruitment';
export const RECRUITMENT_ROTATION_FORM = 'recruitment-rotation-form';
export const RESTAURANTS = 'restaurants';
export const FORVARDINO = 'forvardino';
export const WORKOUTS = 'workouts';
export const COMPETITION = 'competition';
export const CLIENT_SATISFACTION = 'clientSatisfaction';
export const FAULT_REPORTING = 'fault_reporting';
export const SERVICES = 'services';
export const KNOWLEDGE = 'knowledge';
export const PARENTS = 'parents';
export const WHERE_TO_FIND = 'where_to_find';
export const NEWS = 'news';
export const ARTICLE = 'article';
export const TUTORIAL = 'tutorial';
export const PAGE = 'page';
export const TRIO = 'trio';
export const WEEK_ACTIONS = 'weekActions';
export const SURVEY = 'survey';
export const PARKING = 'parking';
export const BENEFITS = 'benefits';
export const IDEAS_SENDING = 'ideas_sending';
export const ONBOARDING = 'onboarding';
export const PROMOTED = 'promoted';
export const COMING_ACTIONS = 'comingActions';
export const MARKETPLACE = 'marketplace';
export const DIRECTORY = 'directory';
export const MAPS = 'maps';


// ============================================================== //
// //////////////////////////// ESG ///////////////////////////// //
// ============================================================== //

export const ESG_GENERAL = 'esgGeneral';
export const ESG_INTRO = 'esgIntro';
export const ESG_CHALLENGES = 'esgChallenges';
export const ESG_COMPETITIONS = 'esgCompetitions';
export const ESG_STORIES = 'esgStories';
export const ESG_THEMES = 'esgThemes';
export const ESG_TOPICS = 'esgTopics';
export const ESG_SOCIAL = 'esgSocial'


// ============================================================== //
// //////////////////////////// FEST //////////////////////////// //
// ============================================================== //

export const FEST_GENERAL = 'festGeneral';
export const FEST_INTRO = 'festIntro';
export const FEST_USER = 'festUser';
export const FEST_CHALLENGES = 'festChallenges';
export const FEST_COMPETITIONS = 'festCompetitions';
export const FEST_STORIES = 'festStories';
export const FEST_THEMES = 'festThemes';
export const FEST_TOPICS = 'festTopics';
export const FEST_SOCIAL = 'festSocial'

