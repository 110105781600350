<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <prj1004-article-body
          v-if="articleData"
          :articleData="articleData"
        />
        <gen1006-info-panel
          v-else
          type="error"
        >
          {{ $t('general.articleNonexistent') }}
        </gen1006-info-panel>

        <gen1006-info-panel
          v-if="!isLoading && !initialized"
          type="neutral"
        >
          {{ $t('general.loadingFailed') }}
        </gen1006-info-panel>
      </template>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
      <frm1006-button
        v-if="pageButton"
        class="vue-button-page-layout-fixed"
        mode="anchor"
        :link="pageButton.buttonLink"
        :linkNewWindow="true"
      >
        {{ pageButton.buttonText }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1004ArticleBody from '@/components/prj1004-article-detail/prj1004-article-body';

import PageLayout from '../../templates/partials/page-layout';

import mxNavigationDefault from '../../mixins/mx-navigation-default';
import moduleStatistics from '../../mixins/mx-module-statistics';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import {mapState} from 'vuex';
import {ERROR_MODAL_TIMEOUT} from '@/constants/app-constants';
import {PAGE} from '@/store/store-modules';

export default {
  name: 'SurfStudioVoiceContent',

  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1004ArticleBody,
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      articleFetched: {},
      visibleArticleComments: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    ...mapState('dataStore', [
      'contentData'
    ]),

    routeId() {
      return this.$route.params.id
    },

    articleCached() {
      return (typeof this.contentData.pages.data[this.routeId] !== 'undefined' ? this.contentData.pages.data[this.routeId] : false);
    },

    articleData() {
      return this.articleCached || this.articleFetched;
    },

    navbarCaption() {
      if (typeof this.articleData !== 'undefined') {
        return this.articleData.title;
      }
      return '';
    },

    pageButton() {
      return this.$route.params.button
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value && this.articleData) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  mounted() {
    if (!this.articleCached) {
      return this.fetchPageDetail();
    }

    this.initializePage();
  },

  methods: {
    fetchPageDetail() {
      dataLoader.fetchPostById(this.routeId)
        .then(data => {
          this.articleFetched = data[0];
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.initializePage();
        })
    },

    initializePage() {
      this.initialized = true;
      this.sendStatistics(PAGE, this.routeId, true);
    },

    goToButtonLink(url) {
      window.location = url
    },
  }
}
</script>
