<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <a
    :href="url"
    target="_blank"
    class="block"
  >
    <span
      class="flex items-center mb-1"
    >
      <span
        class="inline-block h-4 w-4 mr-1"
        :class="txtCol"
        v-html="icon"
      ></span>
      <span
        class="text-xs font-semibold"
        :class="txtCol"
      >{{ platformName }}</span>
    </span>
    <span class="block text-xl leading-7 font-bold">{{ title }}</span>
  </a>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import socialConfig from '@/assets/esg/social/config'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1021SocialLinksItem',
  props: {
    url: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      socialConfig,
    }
  },
  computed: {
    itemConfig() {
      return this.socialConfig.find((item) => item.iconType === this.iconType)
    },
    icon() {
      return this.itemConfig?.icon
    },
    platformName() {
      return this.itemConfig?.title
    },
    txtCol() {
      return this.itemConfig?.txtCol
    },
  },
}
</script>
