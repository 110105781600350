<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    class="vue-component vue-c-building"
    :class="classObject"
  >
    <div class="vue-building-floors">
      <template v-for="(floor, key) in building.floors">
        <Prj1009-map-floor
          v-if="floor.id === floorActiveId"
          :key="key"
          :map-data="mapData"
          :floor="floor"
          :building-active-id="buildingActiveId"
          :floor-active-id="floorActiveId"
          :item-active-id="itemActiveId"
          :buildings-selected-id="buildingsSelectedId"
          :floors-selected-id="floorsSelectedId"
          :rooms-selected-id="roomsSelectedId"
          :center-map="centerMap"
          @floorClickEvent="buildingFloorClickEmit"
        />
      </template>
    </div>
  </div>
</template>

<script type="application/javascript">
import Prj1009MapFloor from './prj1009-map-floor';

const COMPONENT_EVENTS = Object.freeze({
  BUILDING_FLOOR_CLICK_EVENT: 'buildingFloorClickEvent',
});

export default {
  name: 'Prj1009MapBuilding',
  components: {
    Prj1009MapFloor
  },
  props: {
    'mapData': {
      default: null,
      type: Object
    },
    'building': {
      default: null,
      type: Object
    },
    'buildingActiveId': {
      default: null,
      type: String
    },
    'floorActiveId': {
      default: null,
      type: String
    },
    'itemActiveId': {
      default: null,
      type: String
    },
    'buildingsSelectedId': {
      default: null,
      type: Array
    },
    'floorsSelectedId': {
      default: null,
      type: Array
    },
    'roomsSelectedId': {
      default: null,
      type: Array
    },
    'id': {
      default: null,
      type: String
    },
    'centerMap': {
      default: false,
      required: false,
      type: Boolean
    }
  },
  data() {
    return {

    }
  },
  computed: {
    classObject() {
      return {
        'vue-is-active': this.active,
        'vue-is-selected': this.selected
      }
    },
    active() {
      return this.building.id === this.buildingActiveId;
    },
    selected() {
      let selected = false;

      for (let i = 0; i < this.buildingsSelectedId.length; i++) {
        if (this.building.id === this.buildingsSelectedId[i]) {
          selected = true;
          break;
        }
      }

      return selected;
    },
  },
  methods: {
    buildingFloorClickEmit(buildingId, floorId) {
      this.$emit(COMPONENT_EVENTS.BUILDING_FLOOR_CLICK_EVENT, buildingId, floorId);
    }
  }
}
</script>
