import Tbl1002Pagination from './tbl1002-pagination';
import Tbl1002PaginationItem from './tbl1002-pagination-item';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Tbl1002Pagination);
        registerComponent(Vue, Tbl1002PaginationItem);
    }
};

use(Plugin);

export default Plugin;

export { Tbl1002Pagination, Tbl1002PaginationItem };
