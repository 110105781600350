// === GENERAL ==============================//
export const TOGGLE_DASHBOARD_MENU = 'TOGGLE_DASHBOARD_MENU';
export const TOGGLE_DASHBOARD_SUBMENU = 'TOGGLE_DASHBOARD_SUBMENU';
export const GENERATE_YEAR_AND_WEEK = 'GENERATE_YEAR_AND_WEEK';
export const GET_APPLICATION_SETTINGS = 'GET_APPLICATION_SETTINGS';
export const TOGGLE_PUSH_NOTIFICATIONS = 'TOGGLE_PUSH_NOTIFICATIONS';
export const SUBSCRIBE_PUSH_NOTIFICATIONS = 'SUBSCRIBE_PUSH_NOTIFICATIONS';
export const UNSUBSCRIBE_PUSH_NOTIFICATIONS = 'UNSUBSCRIBE_PUSH_NOTIFICATIONS';
export const LOAD_PUSH_NOTIFICATIONS_STATUS = 'LOAD_PUSH_NOTIFICATIONS_STATUS';
export const OPEN_DASHBOARD_NAVIGATION = 'OPEN_DASHBOARD_NAVIGATION';
export const CLOSE_DASHBOARD_NAVIGATION = 'CLOSE_DASHBOARD_NAVIGATION';
export const FETCH_NEW_VERSION_INFO = 'FETCH_NEW_VERSION_INFO';
export const MAKE_SURVEY_CONFIG = 'MAKE_SURVEY_CONFIG';
export const SET_NEW_VERSION_INSTALLED = 'SET_NEW_VERSION_INSTALLED';

// === PERSISTENT STORAGE ===================//
export const REMOVE_FORVARDINO_EVENT = 'REMOVE_FORVARDINO_EVENT';

// === USER =================================//
export const MAKE_INSTALL_REMINDER_TIMESTAMP = 'MAKE_INSTALL_REMINDER_TIMESTAMP';
export const LOGOUT_USER = 'LOGOUT_USER';
export const IDENTITY_PROFILE = 'IDENTITY_PROFILE';
export const DELETE_IDENTITY_PROFILE = 'DELETE_IDENTITY_PROFILE';
export const TOGGLE_PROFILE_MENU = 'TOGGLE_PROFILE_MENU';
export const IDENTITY_PREFERENCES = 'IDENTITY_PREFERENCES';
export const IDENTITY_PUSH_NOTIFICATION_SUB = 'IDENTITY_PUSH_NOTIFICATION_SUB';
// authorized mode actions
export const AM_LOAD_AUTHORIZED_MODE_PROFILE = 'AM_LOAD_AUTHORIZED_MODE_PROFILE'

// === APP NOTIFICATIONS ========================//
export const TOGGLE_APP_NOTIFICATIONS_MENU = 'TOGGLE_APP_NOTIFICATIONS_MENU';
export const FETCH_APP_NOTIFICATIONS = 'FETCH_APP_NOTIFICATIONS';
export const APP_NOTIFICATIONS_READ_ALL = 'FETCH_APP_NOTIFICATIONS_READ_ALL';
export const APP_NOTIFICATION_READ = 'FETCH_APP_NOTIFICATION_READ';

// === MESSAGES ================================//
export const FETCH_USER_MESSAGES = 'FETCH_USER_MESSAGES';
export const RESET_ALL_MESSAGES_FILTER = 'RESET_ALL_MESSAGES_FILTER';

// === DATA STORE ==============================//
export const GET_PREFETCH_CATEGORIES = 'GET_PREFETCH_CATEGORIES';
export const HANDLE_ARTICLES_IDS = 'HANDLE_ARTICLES_IDS';
export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const FETCH_ITEMS_DATA = 'FETCH_ITEMS_DATA';
export const FETCH_RESTAURANTS = 'FETCH_RESTAURANTS';
export const FETCH_RECOMMENDED_POSTS = 'FETCH_RECOMMENDED_POSTS';
export const FETCH_CAFES = 'FETCH_CAFES';

// === POPUP ===============================//
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const POPUP_POSITION = 'POPUP_POSITION';

// === EVENTS ==============================//
export const FETCH_EVENTS_CATEGORIES = 'FETCH_EVENTS_CATEGORIES';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FILTER_EVENTS = 'FILTER_EVENTS';
export const RESET_ALL_EVENTS = 'RESET_ALL_EVENTS';

// === DASHBOARD ==========================//
export const FETCH_DASHBOARD_PROMOTED = 'FETCH_DASHBOARD_PROMOTED';
export const FETCH_DASHBOARD_COMING = 'FETCH_DASHBOARD_COMING';
export const FETCH_DASHBOARD_NEWS = 'FETCH_DASHBOARD_NEWS';

// === FORVARDINO =========================//
export const FETCH_FORVARDINO_FILTER_DATA = 'FETCH_FORVARDINO_DATA';
export const RESET_ALL_FORVARDINO_FILTERS = 'RESET_ALL_FORVARDINO_FILTERS';

// === MARKETPLACE ========================//
export const FETCH_MARKETPLACE_FILTER_DATA = 'FETCH_MARKETPLACE_FILTER_DATA';
export const RESET_ALL_MARKETPLACE_FILTERS = 'RESET_ALL_MARKETPLACE_FILTERS';

// === DIRECTORY ========================//
export const FETCH_DIRECTORY_CATEGORIES = 'FETCH_DIRECTORY_CATEGORIES';
export const FETCH_DIRECTORY_ENTITIES = 'FETCH_DIRECTORY_ENTITIES';
export const FETCH_DIRECTORY_ITEMS = 'FETCH_DIRECTORY_ITEMS';
export const SET_DIRECTORY_ITEM_FAVORITE = 'SET_DIRECTORY_ITEM_FAVORITE';
export const SET_DIRECTORY_ITEM_FAVORITE_OFF = 'SET_DIRECTORY_ITEM_FAVORITE_OFF';
export const SET_DIRECTORY_ITEM_DELETE = 'SET_DIRECTORY_ITEM_DELETE';

// === WORKOUTS ============================//
export const FETCH_WORKOUTS = 'FETCH_WORKOUTS';
export const FETCH_WORKOUTS_QUARTERS = 'FETCH_WORKOUTS_QUARTERS';

// === CLIENT SATISFACTION =================//
export const FETCH_NPS_DATA = 'FETCH_NPS_DATA';
export const FETCH_NPS_FILTER_DATA = 'FETCH_NPS_FILTER_DATA';
export const RESET_ALL_NPS_FILTER = 'RESET_ALL_NPS_FILTER';

// === COVID 19 ============================//
export const FETCH_COVID19_FAQ_DATA = 'FETCH_COVID19_FAQ_DATA';

// === TRIO ================================//
export const FETCH_TRIO_DATA = 'FETCH_TRIO_DATA';

// === SURVEY ==============================//
export const MAKE_SURVEY_CONTENT = 'MAKE_SURVEY_CONTENT';

// === ARTICLES ============================//
export const RESET_SELECTED_CATEGORIES = 'RESET_SELECTED_CATEGORIES';

// === BENEFITS ============================//
export const FETCH_BENEFITS_FILTER_DATA = 'FETCH_BENEFITS_FILTER_DATA';
export const RESET_ALL_BENEFITS_FILTER = 'RESET_ALL_BENEFITS_FILTER';

// === PROMOTED ============================//
export const RESET_ALL_PROMOTED_FILTER = 'RESET_ALL_PROMOTED_FILTER';

// === COMING ============================//
export const RESET_ALL_COMING_FILTER = 'RESET_ALL_COMING_FILTER';
