export default {
  title: 'Zelená výzva',
  subTitle: '',
  content: 'Každý kilometr se počítá!',
  detail: {
    title: 'Zelená výzva',
    content: 'Jakmile zvolíte ekologicky šetrnější způsob dopravy, než jízdu sami autem se spalovacím motorem, zadejte, kolik kilometrů a jakým způsobem jste ušli nebo ujeli.',
    pages: [
      {
        name: 'Jak to funguje?',
        articleId: 235
      }
    ]
  },
  settings: {
    title: 'Zelené kilometry',
    content: 'Napište, kolik kilometrů jste ujeli nebo jinak překonali ekologicky šetrnějším způsobem. Každou cestu zadejte zvlášť, protože do práce můžete např. běžet a z práce jet metrem. Pokud obě cesty absolvujete stejným způsobem (MHD, home office...), můžete kilometry zadat najednou za cestu tam i zpět.',
    savingsUnits: 'CO2',
    savingPanelDetail: {
      title: '',
      text: ''
    },
    savingPanelHistory: {
      title: '',
      text: ''
    },
    savingPanelHistoryTypes: [
      '', '', ''
    ],
    savingPanelHistoryResult: {
      title: '',
      text: ''
    },
  },
  settingsExtended: true,
  settingsValueDefault: 15,
  daysWithMaxValue: 5,
  highestValue: 0.129, // CO2 kg per km
  units: ['km', 'm'],
  secondaryUnits: ['kg', 'g'],
  transportItems: [
    {
      id: 1,
      name: 'Sdílený automobil',
      icon: '<svg class="w-full h-full" viewBox="0 0 96 58" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M75 21.5H19.5L15 15.5L17.8912 9.71937C19.7962 5.9075 23.6925 3.5 27.9525 3.5H53.9681C59.6119 3.5 64.8806 6.32 68.01 11.015L75 21.5Z" fill="#D8E6EB"/>\n' +
        '<path d="M75 21.5H48V3.5H53.9681C59.6119 3.5 64.8806 6.32 68.01 11.015L75 21.5Z" fill="#A9D3EB"/>\n' +
        '<path d="M96 29.1875C95.9587 25.0756 93.0019 21.6181 88.935 20.9487L76.6631 18.9237L70.35 9.455C66.69 3.965 60.5662 0.6875 53.9681 0.6875H27.9525C22.5919 0.6875 17.7731 3.66688 15.375 8.46125L13.065 13.0794L2.14125 15.7681C0.883125 16.0775 0 17.2062 0 18.5V24.6875C0 24.6875 1.91438 28.1131 0 30.3125V39.5C0 40.2612 0.3075 40.9887 0.855 41.5194L7.0425 47.5194C7.5675 48.0275 8.26875 48.3125 9 48.3125H87.5625C92.2144 48.3125 96 44.5269 96 39.875V34.8125C93.4238 32.3544 96 29.1875 96 29.1875ZM27.1875 18.6875H20.9062L18.2925 15.2037L20.4056 10.9775C21.7294 8.33 24.2831 6.61062 27.1875 6.35V18.6875ZM32.8125 18.6875V6.3125H53.9681C58.6819 6.3125 63.0562 8.65437 65.67 12.575L69.7444 18.6875H32.8125Z" fill="#C2C2C2"/>\n' +
        '<path d="M96 34.8125V39.875C96 44.5269 92.2144 48.3125 87.5625 48.3125H48V18.6875H69.7444L65.67 12.575C63.0562 8.65437 58.6819 6.3125 53.9681 6.3125H48V0.6875H53.9681C60.5662 0.6875 66.69 3.965 70.35 9.455L76.6631 18.9237L88.935 20.9487C93.0019 21.6181 95.9588 25.0756 96 29.1875C96 29.1875 93.4238 32.3544 96 34.8125Z" fill="#B3B3B3"/>\n' +
        '<path d="M25.5 54.5C30.4706 54.5 34.5 50.4706 34.5 45.5C34.5 40.5294 30.4706 36.5 25.5 36.5C20.5294 36.5 16.5 40.5294 16.5 45.5C16.5 50.4706 20.5294 54.5 25.5 54.5Z" fill="#424242"/>\n' +
        '<path d="M25.5 57.3125C18.9866 57.3125 13.6875 52.0134 13.6875 45.5C13.6875 38.9866 18.9866 33.6875 25.5 33.6875C32.0134 33.6875 37.3125 38.9866 37.3125 45.5C37.3125 52.0134 32.0134 57.3125 25.5 57.3125ZM25.5 39.3125C22.0883 39.3125 19.3125 42.0883 19.3125 45.5C19.3125 48.9117 22.0883 51.6875 25.5 51.6875C28.9117 51.6875 31.6875 48.9117 31.6875 45.5C31.6875 42.0883 28.9117 39.3125 25.5 39.3125Z" fill="#373737"/>\n' +
        '<path d="M72 54.5C76.9706 54.5 81 50.4706 81 45.5C81 40.5294 76.9706 36.5 72 36.5C67.0294 36.5 63 40.5294 63 45.5C63 50.4706 67.0294 54.5 72 54.5Z" fill="#373737"/>\n' +
        '<path d="M72 57.3125C65.4866 57.3125 60.1875 52.0134 60.1875 45.5C60.1875 38.9866 65.4866 33.6875 72 33.6875C78.5134 33.6875 83.8125 38.9866 83.8125 45.5C83.8125 52.0134 78.5134 57.3125 72 57.3125ZM72 39.3125C68.5883 39.3125 65.8125 42.0883 65.8125 45.5C65.8125 48.9117 68.5883 51.6875 72 51.6875C75.4117 51.6875 78.1875 48.9117 78.1875 45.5C78.1875 42.0883 75.4117 39.3125 72 39.3125Z" fill="#2B2B2B"/>\n' +
        '<path d="M7.5 27.5C7.5 29.0525 6.24 30.3125 4.6875 30.3125H0V24.6875H4.6875C6.24 24.6875 7.5 25.9475 7.5 27.5Z" fill="#F16B6B"/>\n' +
        '<path d="M53.8125 32C53.8125 33.5525 52.5525 34.8125 51 34.8125H45C43.4475 34.8125 42.1875 33.5525 42.1875 32C42.1875 30.4475 43.4475 29.1875 45 29.1875H51C52.5525 29.1875 53.8125 30.4475 53.8125 32Z" fill="#B3B3B3"/>\n' +
        '<path d="M53.8125 32C53.8125 33.5525 52.5525 34.8125 51 34.8125H48V29.1875H51C52.5525 29.1875 53.8125 30.4475 53.8125 32Z" fill="#9C9C9C"/>\n' +
        '<path d="M96 29.1875V34.8125H92.8125C91.26 34.8125 90 33.5525 90 32C90 30.4475 91.26 29.1875 92.8125 29.1875H96Z" fill="#FBA400"/>\n' +
        '</svg>\n',
      cssClass: 'bg-yellow-100',
      value: '0.0645' // CO2 kg per km
    },
    {
      id: 2,
      name: 'Hybrid',
      icon: '<svg class="w-full h-full" viewBox="0 0 103 95" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M41 18.8447H38.3333V24.1781C38.3333 25.6493 37.138 26.8447 35.6667 26.8447H9.00001C4.58863 26.8447 1.00001 30.4333 1.00001 34.8447C1.00001 35.0653 0.982398 54.2839 1 54.5C1 57.5 3.5 58.8447 7.00001 58.8447H12.2518V53.5H9.00001C7.70118 53.5 6.39939 52.6083 6.38114 51.3493C6.34977 49.1859 6.33334 35.0172 6.33334 34.8447C6.33334 33.3734 7.52872 32.1781 9.00001 32.1781H35.6667C37.723 32.1781 39.5813 31.3766 41 30.0974C42.6247 28.6319 43.6667 26.5333 43.6667 24.1781V18.8447H41Z" fill="#766E6E"/>\n' +
        '<path d="M57 0C50.1751 0 44.2558 3.72267 41 9.20533C37.7442 3.72267 31.8249 0 25 0H17V2.66667C17 12.9584 25.3749 21.5111 35.6667 21.5111H41H46.3333C56.6251 21.5111 65 12.9584 65 2.66667V0H57Z" fill="#8ADA53"/>\n' +
        '<path d="M65 2.66667V0H57C50.1751 0 44.2558 3.72267 41 9.20533V21.5111H46.3333C56.6251 21.5111 65 12.9584 65 2.66667Z" fill="#5DC278"/>\n' +
        '<path d="M82 58.8125H26.5L22 52.8125L24.8912 47.0319C26.7962 43.22 30.6925 40.8125 34.9525 40.8125H60.9681C66.6119 40.8125 71.8806 43.6325 75.01 48.3275L82 58.8125Z" fill="#D8E6EB"/>\n' +
        '<path d="M82 58.8125H55V40.8125H60.9681C66.6119 40.8125 71.8806 43.6325 75.01 48.3275L82 58.8125Z" fill="#A9D3EB"/>\n' +
        '<path d="M103 66.5C102.959 62.3881 100.002 58.9306 95.935 58.2612L83.6631 56.2362L77.35 46.7675C73.69 41.2775 67.5662 38 60.9681 38H34.9525C29.5919 38 24.7731 40.9794 22.375 45.7738L20.065 50.3919L9.14125 53.0806C7.88313 53.39 7 54.5187 7 55.8125V62C7 62 8.91438 65.4256 7 67.625V76.8125C7 77.5737 7.3075 78.3012 7.855 78.8319L14.0425 84.8319C14.5675 85.34 15.2688 85.625 16 85.625H94.5625C99.2144 85.625 103 81.8394 103 77.1875V72.125C100.424 69.6669 103 66.5 103 66.5ZM34.1875 56H27.9062L25.2925 52.5162L27.4056 48.29C28.7294 45.6425 31.2831 43.9231 34.1875 43.6625V56ZM39.8125 56V43.625H60.9681C65.6819 43.625 70.0562 45.9669 72.67 49.8875L76.7444 56H39.8125Z" fill="#64DA55"/>\n' +
        '<path d="M103 72.125V77.1875C103 81.8394 99.2144 85.625 94.5625 85.625H55V56H76.7444L72.67 49.8875C70.0562 45.9669 65.6819 43.625 60.9681 43.625H55V38H60.9681C67.5662 38 73.69 41.2775 77.35 46.7675L83.6631 56.2362L95.935 58.2612C100.002 58.9306 102.959 62.3881 103 66.5C103 66.5 100.424 69.6669 103 72.125Z" fill="#3EC65B"/>\n' +
        '<path d="M32.5 91.8125C37.4706 91.8125 41.5 87.7831 41.5 82.8125C41.5 77.8419 37.4706 73.8125 32.5 73.8125C27.5294 73.8125 23.5 77.8419 23.5 82.8125C23.5 87.7831 27.5294 91.8125 32.5 91.8125Z" fill="#424242"/>\n' +
        '<path d="M32.5 94.625C25.9866 94.625 20.6875 89.3259 20.6875 82.8125C20.6875 76.2991 25.9866 71 32.5 71C39.0134 71 44.3125 76.2991 44.3125 82.8125C44.3125 89.3259 39.0134 94.625 32.5 94.625ZM32.5 76.625C29.0883 76.625 26.3125 79.4008 26.3125 82.8125C26.3125 86.2242 29.0883 89 32.5 89C35.9117 89 38.6875 86.2242 38.6875 82.8125C38.6875 79.4008 35.9117 76.625 32.5 76.625Z" fill="#373737"/>\n' +
        '<path d="M79 91.8125C83.9706 91.8125 88 87.7831 88 82.8125C88 77.8419 83.9706 73.8125 79 73.8125C74.0294 73.8125 70 77.8419 70 82.8125C70 87.7831 74.0294 91.8125 79 91.8125Z" fill="#373737"/>\n' +
        '<path d="M79 94.625C72.4866 94.625 67.1875 89.3259 67.1875 82.8125C67.1875 76.2991 72.4866 71 79 71C85.5134 71 90.8125 76.2991 90.8125 82.8125C90.8125 89.3259 85.5134 94.625 79 94.625ZM79 76.625C75.5883 76.625 72.8125 79.4008 72.8125 82.8125C72.8125 86.2242 75.5883 89 79 89C82.4117 89 85.1875 86.2242 85.1875 82.8125C85.1875 79.4008 82.4117 76.625 79 76.625Z" fill="#2B2B2B"/>\n' +
        '<path d="M14.5 64.8125C14.5 66.365 13.24 67.625 11.6875 67.625H7V62H11.6875C13.24 62 14.5 63.26 14.5 64.8125Z" fill="#F16B6B"/>\n' +
        '<path d="M60.8125 69.3125C60.8125 70.865 59.5525 72.125 58 72.125H52C50.4475 72.125 49.1875 70.865 49.1875 69.3125C49.1875 67.76 50.4475 66.5 52 66.5H58C59.5525 66.5 60.8125 67.76 60.8125 69.3125Z" fill="#3EC65B"/>\n' +
        '<path d="M60.8125 69.3125C60.8125 70.865 59.5525 72.125 58 72.125H55V66.5H58C59.5525 66.5 60.8125 67.76 60.8125 69.3125Z" fill="#2FB74D"/>\n' +
        '<path d="M103 66.5V72.125H99.8125C98.26 72.125 97 70.865 97 69.3125C97 67.76 98.26 66.5 99.8125 66.5H103Z" fill="#FBA400"/>\n' +
        '</svg>\n',
      cssClass: 'bg-emerald-100',
      value: '0.035'
    },
    {
      id: 3,
      name: 'Elektro',
      icon: '<svg class="w-full h-full" viewBox="0 0 96 85" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M48.7963 75.6452H45.6601C41.3671 75.6452 37.8739 72.152 37.8739 67.8584V65.9952C37.8739 64.2843 36.482 62.8924 34.7711 62.8924C33.0608 62.8924 31.6689 64.2843 31.6689 65.9952V67.8584C31.6689 72.152 28.1757 75.6452 23.8821 75.6452H18.6887C12.5277 75.6452 7.51587 70.6328 7.51587 64.4718V46.6553H12.1992V64.4718C12.1992 68.0501 15.1104 70.9612 18.6887 70.9612H23.8821C25.593 70.9612 26.9849 69.5693 26.9849 67.8584V65.9952C26.9849 61.7016 30.4781 58.2084 34.7711 58.2084C39.0647 58.2084 42.5579 61.7016 42.5579 65.9952V67.8584C42.5579 69.5693 43.9498 70.9612 45.6601 70.9612H48.7963V75.6452Z" fill="#66828D"/>\n' +
        '<path d="M42.5578 67.8586V65.9954C42.5578 62.8039 40.6273 60.057 37.8738 58.8555V67.8586C37.8738 72.1522 41.367 75.6454 45.66 75.6454H48.7961V70.9614H45.66C43.9497 70.9614 42.5578 69.5695 42.5578 67.8586Z" fill="#4E6873"/>\n' +
        '<path d="M71.4449 70.8031H64.3877C63.094 70.8031 62.0454 69.7546 62.0454 68.4608C62.0454 67.1677 63.094 66.1191 64.3877 66.1191H71.4449C72.738 66.1191 73.7866 67.1677 73.7866 68.4608C73.7866 69.7546 72.738 70.8031 71.4449 70.8031Z" fill="#66828D"/>\n' +
        '<path d="M71.4449 80.4867H64.3877C63.094 80.4867 62.0454 79.4382 62.0454 78.1444C62.0454 76.8513 63.094 75.8027 64.3877 75.8027H71.4449C72.738 75.8027 73.7866 76.8513 73.7866 78.1444C73.7866 79.4382 72.738 80.4867 71.4449 80.4867Z" fill="#66828D"/>\n' +
        '<path d="M64.3871 85.0001H58.1511C51.7015 85.0001 46.4546 79.7532 46.4546 73.303C46.4546 66.8534 51.7015 61.6064 58.1511 61.6064H64.3871C65.6809 61.6064 66.7294 62.655 66.7294 63.9481V82.6584C66.7294 83.9516 65.6809 85.0001 64.3871 85.0001Z" fill="#5BE06B"/>\n' +
        '<path d="M75 20.8125H19.5L15 14.8125L17.8912 9.03187C19.7962 5.22 23.6925 2.8125 27.9525 2.8125H53.9681C59.6119 2.8125 64.8806 5.6325 68.01 10.3275L75 20.8125Z" fill="#D8E6EB"/>\n' +
        '<path d="M75 20.8125H48V2.8125H53.9681C59.6119 2.8125 64.8806 5.6325 68.01 10.3275L75 20.8125Z" fill="#A9D3EB"/>\n' +
        '<path d="M96 28.5C95.9587 24.3881 93.0019 20.9306 88.935 20.2612L76.6631 18.2362L70.35 8.7675C66.69 3.2775 60.5662 0 53.9681 0H27.9525C22.5919 0 17.7731 2.97938 15.375 7.77375L13.065 12.3919L2.14125 15.0806C0.883125 15.39 0 16.5187 0 17.8125V24C0 24 1.91438 27.4256 0 29.625V38.8125C0 39.5737 0.3075 40.3012 0.855 40.8319L7.0425 46.8319C7.5675 47.34 8.26875 47.625 9 47.625H87.5625C92.2144 47.625 96 43.8394 96 39.1875V34.125C93.4238 31.6669 96 28.5 96 28.5ZM27.1875 18H20.9062L18.2925 14.5162L20.4056 10.29C21.7294 7.6425 24.2831 5.92312 27.1875 5.6625V18ZM32.8125 18V5.625H53.9681C58.6819 5.625 63.0562 7.96687 65.67 11.8875L69.7444 18H32.8125Z" fill="#4AA4D7"/>\n' +
        '<path d="M96 34.125V39.1875C96 43.8394 92.2144 47.625 87.5625 47.625H48V18H69.7444L65.67 11.8875C63.0562 7.96687 58.6819 5.625 53.9681 5.625H48V0H53.9681C60.5662 0 66.69 3.2775 70.35 8.7675L76.6631 18.2362L88.935 20.2612C93.0019 20.9306 95.9588 24.3881 96 28.5C96 28.5 93.4238 31.6669 96 34.125Z" fill="#3E95C6"/>\n' +
        '<path d="M25.5 53.8125C30.4706 53.8125 34.5 49.7831 34.5 44.8125C34.5 39.8419 30.4706 35.8125 25.5 35.8125C20.5294 35.8125 16.5 39.8419 16.5 44.8125C16.5 49.7831 20.5294 53.8125 25.5 53.8125Z" fill="#424242"/>\n' +
        '<path d="M25.5 56.625C18.9866 56.625 13.6875 51.3259 13.6875 44.8125C13.6875 38.2991 18.9866 33 25.5 33C32.0134 33 37.3125 38.2991 37.3125 44.8125C37.3125 51.3259 32.0134 56.625 25.5 56.625ZM25.5 38.625C22.0883 38.625 19.3125 41.4008 19.3125 44.8125C19.3125 48.2242 22.0883 51 25.5 51C28.9117 51 31.6875 48.2242 31.6875 44.8125C31.6875 41.4008 28.9117 38.625 25.5 38.625Z" fill="#373737"/>\n' +
        '<path d="M72 53.8125C76.9706 53.8125 81 49.7831 81 44.8125C81 39.8419 76.9706 35.8125 72 35.8125C67.0294 35.8125 63 39.8419 63 44.8125C63 49.7831 67.0294 53.8125 72 53.8125Z" fill="#373737"/>\n' +
        '<path d="M72 56.625C65.4866 56.625 60.1875 51.3259 60.1875 44.8125C60.1875 38.2991 65.4866 33 72 33C78.5134 33 83.8125 38.2991 83.8125 44.8125C83.8125 51.3259 78.5134 56.625 72 56.625ZM72 38.625C68.5883 38.625 65.8125 41.4008 65.8125 44.8125C65.8125 48.2242 68.5883 51 72 51C75.4117 51 78.1875 48.2242 78.1875 44.8125C78.1875 41.4008 75.4117 38.625 72 38.625Z" fill="#2B2B2B"/>\n' +
        '<path d="M7.5 26.8125C7.5 28.365 6.24 29.625 4.6875 29.625H0V24H4.6875C6.24 24 7.5 25.26 7.5 26.8125Z" fill="#F16B6B"/>\n' +
        '<path d="M53.8125 31.3125C53.8125 32.865 52.5525 34.125 51 34.125H45C43.4475 34.125 42.1875 32.865 42.1875 31.3125C42.1875 29.76 43.4475 28.5 45 28.5H51C52.5525 28.5 53.8125 29.76 53.8125 31.3125Z" fill="#3E95C6"/>\n' +
        '<path d="M53.8125 31.3125C53.8125 32.865 52.5525 34.125 51 34.125H48V28.5H51C52.5525 28.5 53.8125 29.76 53.8125 31.3125Z" fill="#257EB0"/>\n' +
        '<path d="M96 28.5V34.125H92.8125C91.26 34.125 90 32.865 90 31.3125C90 29.76 91.26 28.5 92.8125 28.5H96Z" fill="#FBA400"/>\n' +
        '</svg>\n',
      cssClass: 'bg-cyan-100',
      value: '0'
    },
    {
      id: 4,
      name: 'Chůze / běh',
      icon: '<svg class="w-full h-11/12" viewBox="0 0 58 78" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M41.9138 21.1143C42.7696 21.97 55.6809 34.8789 55.6809 34.8789C57.7109 36.9089 57.7109 40.2003 55.6809 42.2304C53.651 44.2605 50.3596 44.2605 48.3295 42.2304C48.3295 42.2304 37.0849 30.9884 33.4897 27.3922C30.3566 24.2643 28.6018 20.0223 28.6018 15.5996C33.5938 15.5996 38.3908 17.5834 41.9138 21.1143Z" fill="#DC3C60"/>\n' +
        '<path d="M28.6016 15.6002C32.9095 15.6002 36.4017 12.108 36.4017 7.80011C36.4017 3.49223 32.9095 0 28.6016 0C24.2937 0 20.8015 3.49223 20.8015 7.80011C20.8015 12.108 24.2937 15.6002 28.6016 15.6002Z" fill="#FAD4BE"/>\n' +
        '<path d="M28.6016 15.5996C24.2987 15.5996 20.8015 19.0941 20.8015 23.3997V46.7999C20.8015 48.0619 21.8246 49.0851 23.0867 49.0851H34.1164C35.3785 49.0851 36.4016 48.0619 36.4016 46.7999V23.3997C36.4016 19.0941 32.9047 15.5996 28.6016 15.5996Z" fill="#FF5078"/>\n' +
        '<path d="M15.2896 21.1143C14.4339 21.97 1.52248 34.8789 1.52248 34.8789C-0.507493 36.9089 -0.507493 40.2003 1.52248 42.2304C3.55245 44.2605 6.84382 44.2605 8.87394 42.2304C8.87394 42.2304 20.1185 30.9884 23.7137 27.3922C26.8468 24.2643 28.6018 20.0223 28.6018 15.5996C23.6098 15.5996 18.8128 17.5834 15.2896 21.1143Z" fill="#FF5078"/>\n' +
        '<path d="M28.6017 0C32.9048 0 36.4017 3.49444 36.4017 7.80011C36.4017 12.1044 32.9048 15.6002 28.6016 15.6002V0H28.6017Z" fill="#FAD4BE"/>\n' +
        '<path d="M28.6018 15.5996V49.0851H34.1166C35.3786 49.0851 36.4018 48.0619 36.4018 46.7999V23.3997C36.4018 19.0941 32.9049 15.5996 28.6018 15.5996Z" fill="#DC3C60"/>\n' +
        '<path d="M55.6811 69.1259L38.5598 51.9995C37.169 50.6214 36.4018 48.7508 36.4018 46.8008L29.1425 54.0601C28.25 54.9525 28.25 56.3995 29.1425 57.2919L48.3295 76.4777C50.3594 78.5078 53.6508 78.5078 55.6809 76.4777C57.711 74.4472 57.711 71.156 55.6811 69.1259Z" fill="#5485BF"/>\n' +
        '<path d="M20.8016 46.8008C20.8016 50.1717 20.2556 53.6297 19.1765 56.8265L18.1884 59.801L4.87641 68.3186C2.45797 69.8655 1.7514 73.0801 3.29829 75.4987C4.84518 77.9171 8.03494 78.7096 10.4535 77.1627L25.4075 67.8575C25.6792 67.6884 25.9122 67.4643 26.0919 67.1994L33.4767 56.3039C35.3878 53.4998 36.4017 50.1887 36.4017 46.8009H20.8016V46.8008Z" fill="#71A0D8"/>\n' +
        '<path d="M28.6018 63.4964L33.4768 56.3039C35.3879 53.4998 36.4018 50.1887 36.4018 46.8008H28.6018V63.4964Z" fill="#71A0D8"/>\n' +
        '</svg>\n',
      cssClass: 'bg-lime-100',
      value: '0'
    },
    {
      id: 5,
      name: 'Kolo',
      icon: '<svg class="w-full h-full" viewBox="0 0 96 61" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M17.8749 24.6758C8.01863 24.6758 0 32.6944 0 42.5507C0 52.407 8.01863 60.4257 17.8749 60.4257C27.7313 60.4257 35.7499 52.4068 35.7499 42.5507C35.7499 32.6946 27.7313 24.6758 17.8749 24.6758ZM17.8749 54.8008C11.1202 54.8008 5.625 49.3054 5.625 42.5507C5.625 35.796 11.1202 30.3008 17.8749 30.3008C24.6296 30.3008 30.1249 35.7962 30.1249 42.5507C30.1249 49.3052 24.6296 54.8008 17.8749 54.8008Z" fill="#41495A"/>\n' +
        '<path d="M17.875 24.6758V30.3008C24.6297 30.3008 30.1249 35.7962 30.1249 42.5507C30.1249 49.3052 24.6295 54.8007 17.875 54.8007V60.4257C27.7313 60.4257 35.7499 52.4068 35.7499 42.5507C35.7499 32.6946 27.7313 24.6758 17.875 24.6758Z" fill="#141E32"/>\n' +
        '<path d="M35.9514 24.2761C34.8603 24.2761 33.8221 23.6373 33.365 22.571L28.2001 10.5209C27.5881 9.09311 28.2494 7.43974 29.6773 6.82774C31.1047 6.21593 32.7583 6.87705 33.3703 8.30468L38.5351 20.3547C39.1471 21.7826 38.4858 23.4359 37.0582 24.0479C36.6969 24.2028 36.3211 24.2761 35.9514 24.2761Z" fill="#006E8C"/>\n' +
        '<path d="M78.1252 24.6758C68.2689 24.6758 60.2502 32.6946 60.2502 42.5507C60.2502 52.4068 68.2689 60.4257 78.1252 60.4257C87.9815 60.4257 96.0001 52.407 96.0001 42.5507C96.0001 32.6944 87.9813 24.6758 78.1252 24.6758ZM78.1252 54.8008C71.3705 54.8008 65.8752 49.3054 65.8752 42.5509C65.8752 35.7964 71.3705 30.301 78.1252 30.301C84.8799 30.301 90.3751 35.796 90.3751 42.5507C90.3751 49.3054 84.8797 54.8008 78.1252 54.8008Z" fill="#41495A"/>\n' +
        '<path d="M68.4 12.0487C67.7313 11.0662 66.5169 10.6099 65.3671 10.9091L35.2421 18.7415C34.6863 18.8861 34.188 19.1972 33.8145 19.6331L15.7395 40.7207C15.0245 41.5547 14.8606 42.7284 15.3196 43.7263C15.7786 44.7242 16.7765 45.3635 17.8749 45.3635H44.9874C45.8859 45.3635 46.7304 44.9342 47.2599 44.208L68.3475 15.288C69.0476 14.328 69.0688 13.0312 68.4 12.0487ZM23.99 39.7387L35.1448 26.7247L40.7221 39.7387H23.99ZM45.626 36.9017L39.8236 23.3625L59.1652 18.3335L45.626 36.9017Z" fill="#4BB4D2"/>\n' +
        '<path d="M38.9627 12.2266H26.9126C25.3593 12.2266 24.1001 10.9673 24.1001 9.41406C24.1001 7.86081 25.3593 6.60156 26.9126 6.60156H38.9627C40.5159 6.60156 41.7752 7.86081 41.7752 9.41406C41.7752 10.9673 40.5159 12.2266 38.9627 12.2266Z" fill="#F7785A"/>\n' +
        '<path d="M65.3671 10.9092L45.626 16.042V21.854L59.165 18.3338L45.626 36.9018V45.2892C46.2757 45.1375 46.8584 44.7588 47.2598 44.2085L68.3474 15.2885C69.0475 14.3283 69.0687 13.0315 68.3999 12.049C67.7313 11.0661 66.5168 10.6098 65.3671 10.9092Z" fill="#2691AF"/>\n' +
        '<path d="M80.7213 41.4694L65.6588 5.31938C65.0614 3.88557 63.4146 3.20757 61.981 3.80495C60.5472 4.40251 59.8692 6.04895 60.4666 7.48276L75.529 43.6328C76.1264 45.0666 77.7732 45.7446 79.2068 45.1472C80.6406 44.5498 81.3186 42.9032 80.7213 41.4694Z" fill="#006E8C"/>\n' +
        '<path d="M74.7679 2.49914C74.2766 1.02577 72.6848 0.228706 71.2103 0.720519L62.1728 3.73271C60.6992 4.22396 59.9029 5.81677 60.3941 7.29033C60.8854 8.76371 62.4773 9.56077 63.9518 9.06896L72.9893 6.05677C74.4628 5.56552 75.2591 3.97271 74.7679 2.49914Z" fill="#2691AF"/>\n' +
        '<path d="M78.125 24.6758V30.3008C84.8797 30.3008 90.3749 35.7962 90.3749 42.5507C90.3749 49.3052 84.8795 54.8007 78.125 54.8007V60.4257C87.9813 60.4257 95.9999 52.4068 95.9999 42.5507C95.9999 32.6946 87.9811 24.6758 78.125 24.6758Z" fill="#141E32"/>\n' +
        '</svg>\n',
      cssClass: 'bg-lime-100',
      value: '0'
    },
    {
      id: 6,
      name: 'Autobus',
      icon: '<svg class="w-full h-full" viewBox="0 0 96 62" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M96 39.4375L90.375 42.25L96 45.0625V47.875C96 52.5436 92.2314 56.3125 87.5625 56.3125H8.4375C3.76856 56.3125 0 52.5436 0 47.875V45.0625V42V39.4375V14.125C0 6.3625 6.3 0.0625 14.0625 0.0625H78.45C81.6562 0.0625 84.5811 1.8625 85.9873 4.73106L89.3062 11.3125L84.75 14.125V31L95.7752 33.8125C95.8875 34.9936 96 36.1748 96 37.3V39.4375Z" fill="white"/>\n' +
        '<path d="M87.5625 56.3125C92.2314 56.3125 96 52.5436 96 47.875H0C0 52.5436 3.76856 56.3125 8.4375 56.3125H87.5625Z" fill="#FF7474"/>\n' +
        '<path d="M96 39.4375L90.375 42.25L96 45.0625V47.875C96 52.5436 92.2314 56.3125 87.5625 56.3125H48V0.0625H78.45C81.6562 0.0625 84.5811 1.8625 85.9873 4.73106L89.3062 11.3125L84.75 14.125V31L95.7752 33.8125C95.8875 34.9936 96 36.1748 96 37.3V39.4375Z" fill="#F3F3F3"/>\n' +
        '<path d="M87.5625 56.3125C92.2314 56.3125 96 52.5436 96 47.875H48V56.3125H87.5625Z" fill="#FF0909"/>\n' +
        '<path d="M59.4375 11.3125H42.1875C40.6123 11.3125 39.375 12.5498 39.375 14.125V31C39.375 32.5748 40.6123 33.8125 42.1875 33.8125H59.4375C61.0127 33.8125 62.25 32.5748 62.25 31V14.125C62.25 12.5498 61.0127 11.3125 59.4375 11.3125Z" fill="#57555C"/>\n' +
        '<path d="M30.9375 33.8125H14.0625C12.5079 33.8125 11.25 32.5546 11.25 31V14.125C11.25 12.5704 12.5079 11.3125 14.0625 11.3125H30.9375C32.4921 11.3125 33.75 12.5704 33.75 14.125V31C33.75 32.5546 32.4921 33.8125 30.9375 33.8125Z" fill="#57555C"/>\n' +
        '<path d="M89.301 11.3125H70.6875C69.1329 11.3125 67.875 12.5704 67.875 14.125V31C67.875 32.5546 69.1329 33.8125 70.6875 33.8125H95.7939C95.0154 22.1451 89.5146 11.8799 89.301 11.3125Z" fill="#3C3A41"/>\n' +
        '<path d="M84.75 42.25C84.75 43.8046 86.0079 45.0625 87.5625 45.0625H96V39.4375H87.5625C86.0079 39.4375 84.75 40.6954 84.75 42.25Z" fill="#B9B9B9"/>\n' +
        '<path d="M22.5 39.4375C16.3127 39.4375 11.25 44.4998 11.25 50.6875C11.25 56.8748 16.3127 61.9375 22.5 61.9375C28.6873 61.9375 33.75 56.8748 33.75 50.6875C33.75 44.4998 28.6873 39.4375 22.5 39.4375Z" fill="#57555C"/>\n' +
        '<path d="M22.5 45.0625C19.4062 45.0625 16.875 47.5938 16.875 50.6875C16.875 53.7812 19.4062 56.3125 22.5 56.3125C25.5938 56.3125 28.125 53.7812 28.125 50.6875C28.125 47.5938 25.5938 45.0625 22.5 45.0625Z" fill="#E5E5E5"/>\n' +
        '<path d="M73.5 39.4375C67.3127 39.4375 62.25 44.4998 62.25 50.6875C62.25 56.8748 67.3127 61.9375 73.5 61.9375C79.6873 61.9375 84.75 56.8748 84.75 50.6875C84.75 44.4998 79.6873 39.4375 73.5 39.4375Z" fill="#3C3A41"/>\n' +
        '<path d="M73.5 45.0625C70.4062 45.0625 67.875 47.5938 67.875 50.6875C67.875 53.7812 70.4062 56.3125 73.5 56.3125C76.5938 56.3125 79.125 53.7812 79.125 50.6875C79.125 47.5938 76.5938 45.0625 73.5 45.0625Z" fill="#D9D9D9"/>\n' +
        '<path d="M62.25 14.125V31C62.25 32.5748 61.0127 33.8125 59.4375 33.8125H48V11.3125H59.4375C61.0127 11.3125 62.25 12.5498 62.25 14.125Z" fill="#3C3A41"/>\n' +
        '</svg>\n',
      cssClass: 'bg-yellow-100',
      value: '0.103'
    },
    {
      id: 7,
      name: 'Tramvaj',
      icon: '<svg class="w-full h-full" viewBox="0 0 96 70" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M57.2737 12.0091L51.3821 3.03838C50.5295 1.74053 48.7864 1.37872 47.4878 2.23125C46.1892 3.08379 45.8281 4.82769 46.6807 6.12627L52.5723 15.097C53.1121 15.9195 54.01 16.3655 54.9255 16.3655C55.4551 16.3655 55.9905 16.2161 56.4665 15.9034C57.7644 15.0508 58.1262 13.3077 57.2737 12.0091Z" fill="#8A8A8A"/>\n' +
        '<path d="M53.8506 0.875H40.2136C38.6602 0.875 37.4011 2.13403 37.4011 3.6875C37.4011 5.24097 38.6602 6.5 40.2136 6.5H53.8506C55.4041 6.5 56.6631 5.24097 56.6631 3.6875C56.6631 2.13403 55.4041 0.875 53.8506 0.875Z" fill="#FF7474"/>\n' +
        '<path d="M53.8506 0.875H47.488V6.5H53.8506C55.4041 6.5 56.6631 5.24097 56.6631 3.6875C56.6631 2.13403 55.4041 0.875 53.8506 0.875Z" fill="#FF0909"/>\n' +
        '<path d="M93.1692 63.446H78.6826L75.1296 59.0479H67.8992L71.4514 63.446H63.8511L60.2981 59.0479H53.0676L56.6199 63.446H49.0195L45.4673 59.0479H38.2361L41.7891 63.446H34.1887L30.6357 59.0479H23.4045L26.9575 63.446H19.3572L15.8042 59.0479H9.0293C8.87329 59.0479 8.71802 59.0442 8.56348 59.0361L12.126 63.446H2.8125C1.25903 63.446 0 64.7051 0 66.2578C0 67.8113 1.25903 69.0703 2.8125 69.0703H93.1692C94.7227 69.0703 95.9817 67.8113 95.9817 66.2578C95.9817 64.7051 94.7227 63.446 93.1692 63.446Z" fill="#8A8A8A"/>\n' +
        '<path d="M93.1692 63.446H78.6826L75.1296 59.0479H67.8992L71.4514 63.446H63.8511L60.2981 59.0479H53.0676L56.6199 63.446H49.0195L47.9978 62.1804V69.0703H93.1692C94.7227 69.0703 95.9817 67.8113 95.9817 66.2578C95.9817 64.7051 94.7227 63.446 93.1692 63.446Z" fill="#6B6B6B"/>\n' +
        '<path d="M80.2925 59.2356H9.0293C4.05029 59.2356 0 55.1853 0 50.207V20.7922C0 15.814 4.05029 11.7637 9.0293 11.7637H75.3362C78.7156 11.7637 81.9382 12.8242 84.657 14.8311C87.3757 16.8379 89.3386 19.6057 90.334 22.835L95.291 38.9204C96.7734 43.731 95.9084 48.8132 92.9187 52.8628C89.929 56.9131 85.3271 59.2356 80.2925 59.2356Z" fill="#FFFFED"/>\n' +
        '<path d="M95.2903 38.9204L90.334 22.835C89.3386 19.6057 87.3757 16.8379 84.657 14.8311C81.9382 12.8242 78.7148 11.7637 75.3362 11.7637H47.9978V59.2356H80.2925C85.3271 59.2356 89.929 56.9131 92.9187 52.8628C95.9084 48.8132 96.7727 43.731 95.2903 38.9204Z" fill="#F2F2DA"/>\n' +
        '<path d="M71.4961 34.5588H93.9463L90.334 22.835C89.3386 19.6057 87.3757 16.8379 84.657 14.8311C81.9382 12.8242 78.7156 11.7637 75.3362 11.7637H64.6223V27.6851C64.6223 31.4812 67.7 34.5588 71.4961 34.5588Z" fill="#67C7F0"/>\n' +
        '<path d="M90.6804 47.0215H88.9922C87.4387 47.0215 86.1797 45.7625 86.1797 44.209C86.1797 42.6562 87.4387 41.3965 88.9922 41.3965H90.6804C92.2332 41.3965 93.4922 42.6562 93.4922 44.209C93.4922 45.7625 92.2332 47.0215 90.6804 47.0215Z" fill="#FF0909"/>\n' +
        '<path d="M26.803 37.3713H12.397C10.7373 37.3713 9.38745 36.0215 9.38745 34.3618V24.1255C9.38745 22.4651 10.7373 21.1152 12.397 21.1152H26.803C28.4626 21.1152 29.8132 22.4651 29.8132 24.1255V34.3618C29.8132 36.0215 28.4626 37.3713 26.803 37.3713Z" fill="#8CECFF"/>\n' +
        '<path d="M52.0889 37.3713H37.6829C36.0232 37.3713 34.6726 36.0215 34.6726 34.3618V24.1255C34.6726 22.4651 36.0232 21.1152 37.6829 21.1152H52.0889C53.7485 21.1152 55.0991 22.4651 55.0991 24.1255V34.3618C55.0991 36.0215 53.7485 37.3713 52.0889 37.3713Z" fill="#8CECFF"/>\n' +
        '<path d="M79.1572 47.0215H2.8125C1.25903 47.0215 0 45.7625 0 44.209C0 42.6562 1.25903 41.3965 2.8125 41.3965H79.1572C80.71 41.3965 81.969 42.6562 81.969 44.209C81.969 45.7625 80.71 47.0215 79.1572 47.0215Z" fill="#FF7474"/>\n' +
        '<path d="M79.1572 41.3965H47.9978V47.0215H79.1572C80.71 47.0215 81.969 45.7625 81.969 44.209C81.969 42.6562 80.71 41.3965 79.1572 41.3965Z" fill="#FF0909"/>\n' +
        '<path d="M52.0891 21.1152H47.9978V37.3713H52.0891C53.7488 37.3713 55.0994 36.0215 55.0994 34.3618V24.1255C55.0994 22.4658 53.7488 21.1152 52.0891 21.1152Z" fill="#67C7F0"/>\n' +
        '</svg>\n',
      cssClass: 'bg-cyan-100',
      value: '0.021'
    },
    {
      id: 8,
      name: 'Metro',
      icon: '<svg class="w-full h-full" viewBox="0 0 100 82" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M100 45C100 55.1324 96.2791 64.4827 90 72.0044C80.8777 82.9319 66.356 81 50 81C33.644 81 19.1223 82.9319 10 72.0044C3.72089 64.4827 0 55.1324 0 45C0 20.1472 22.3858 0 50 0C77.6142 0 100 20.1472 100 45Z" fill="#535353"/>\n' +
        '<path d="M100 45C100 20.1472 77.6142 0 50 0V81C66.356 81 80.8777 82.9319 90 72.0044C96.2791 64.4827 100 55.1324 100 45Z" fill="#424242"/>\n' +
        '<path d="M78.7086 25.7036C78.7086 21.9377 75.6556 18.8848 71.8897 18.8848H28.1106C24.3447 18.8848 21.2917 21.9377 21.2917 25.7036V54.2961H78.7086V25.7036Z" fill="#8CECFF"/>\n' +
        '<path d="M71.9673 18.8848H51.4062V54.2963H78.7086V25.626C78.7086 21.9029 75.6904 18.8848 71.9673 18.8848V18.8848Z" fill="#67C7F0"/>\n' +
        '<path d="M72.4875 16H27.5124C22.861 16 19.0767 19.7843 19.0767 24.4358V65.2131C19.0767 69.8646 22.861 73.6488 27.5124 73.6488H72.4875C77.1391 73.6488 80.9233 69.8645 80.9233 65.2131V24.4358C80.9233 19.7843 77.1391 16 72.4875 16ZM76.7046 24.4358V50.9066H52.1093V20.2187H72.4875C74.8129 20.2187 76.7046 22.1106 76.7046 24.4358ZM27.5124 20.2187H47.8906V50.9068H23.2954V24.4358C23.2954 22.1106 25.1872 20.2187 27.5124 20.2187Z" fill="white"/>\n' +
        '<path d="M72.4875 16H50V73.6488H72.4875C77.1391 73.6488 80.9233 69.8645 80.9233 65.2131V24.4358C80.9233 19.7843 77.1391 16 72.4875 16ZM76.7046 50.9068H52.1094V20.2187H72.4875C74.8129 20.2187 76.7046 22.1106 76.7046 24.4358V50.9068Z" fill="#ECECEC"/>\n' +
        '<path d="M29.27 60.2285H27.0591C25.8941 60.2285 24.9497 61.173 24.9497 62.3379C24.9497 63.5028 25.8941 64.4473 27.0591 64.4473H29.27C30.435 64.4473 31.3794 63.5028 31.3794 62.3379C31.3794 61.173 30.4351 60.2285 29.27 60.2285Z" fill="#FED100"/>\n' +
        '<path d="M72.9409 60.2285H70.73C69.565 60.2285 68.6206 61.173 68.6206 62.3379C68.6206 63.5028 69.565 64.4473 70.73 64.4473H72.9409C74.1059 64.4473 75.0503 63.5028 75.0503 62.3379C75.0503 61.173 74.1059 60.2285 72.9409 60.2285Z" fill="#FFB500"/>\n' +
        '<path d="M43 57.2725L45 66.9997H47.3182L46.3182 61.9202H46.3864L49.3295 66.9316H50.7273L53.6705 61.9543H53.7386L52.7386 66.9997H55.0568L57.0568 57.2725H54.108L50.0795 63.2156H49.9773L45.9489 57.2725H43Z" fill="#C60D0D"/>\n' +
        '<path d="M53.6705 61.9543L50.7273 66.9316H50.0284L50 63.2156H50.0796L54.108 57.2725H57.0568L55.0568 66.9997H52.7387L53.7387 61.9543H53.6705Z" fill="#AB1414"/>\n' +
        '</svg>\n',
      cssClass: 'bg-cyan-100',
      value: '0.021'
    },
    {
      id: 9,
      name: 'Motorka',
      icon: '<svg class="w-full h-full" viewBox="0 0 96 68" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M79.125 34C69.8436 34 62.25 41.5936 62.25 50.875C62.25 60.1564 69.8436 67.75 79.125 67.75C88.4064 67.75 96 60.1564 96 50.875C96 41.5936 88.4064 34 79.125 34ZM79.125 62.125C72.9377 62.125 67.875 57.0623 67.875 50.875C67.875 44.6877 72.9377 39.625 79.125 39.625C85.3123 39.625 90.375 44.6877 90.375 50.875C90.375 57.0623 85.3123 62.125 79.125 62.125Z" fill="#495A79"/>\n' +
        '<path d="M96 50.875C96 60.1564 88.4064 67.75 79.125 67.75V62.125C85.3123 62.125 90.375 57.0623 90.375 50.875C90.375 44.6877 85.3123 39.625 79.125 39.625V34C88.4064 34 96 41.5936 96 50.875Z" fill="#42516D"/>\n' +
        '<path d="M16.875 34C7.59356 34 0 41.5936 0 50.875C0 60.1564 7.59356 67.75 16.875 67.75C26.1564 67.75 33.75 60.1564 33.75 50.875C33.75 41.5936 26.1564 34 16.875 34ZM16.875 62.125C10.6877 62.125 5.625 57.0623 5.625 50.875C5.625 44.6877 10.6877 39.625 16.875 39.625C23.0623 39.625 28.125 44.6877 28.125 50.875C28.125 57.0623 23.0623 62.125 16.875 62.125Z" fill="#495A79"/>\n' +
        '<path d="M33.75 50.875C33.75 60.1564 26.1564 67.75 16.875 67.75V62.125C23.0623 62.125 28.125 57.0623 28.125 50.875C28.125 44.6877 23.0623 39.625 16.875 39.625V34C26.1564 34 33.75 41.5936 33.75 50.875Z" fill="#42516D"/>\n' +
        '<path d="M51 17.125H22.5V34H51V17.125Z" fill="#495A79"/>\n' +
        '<path d="M65.0625 5.875H59.4375C57.8829 5.875 56.625 4.61706 56.625 3.0625C56.625 1.50794 57.8829 0.25 59.4375 0.25H65.0625C66.6171 0.25 67.875 1.50794 67.875 3.0625C67.875 4.61706 66.6171 5.875 65.0625 5.875Z" fill="#495A79"/>\n' +
        '<path d="M80.7001 53.2374C80.1937 53.5186 79.6873 53.6876 79.1249 53.6876C78.2251 53.6876 77.3249 53.2374 76.7626 52.4503L63.6 32.7628C62.7001 31.4688 63.0939 29.7251 64.3312 28.8253C65.6248 27.9813 67.3685 28.3188 68.2687 29.6126L79.1249 45.7567L81.4872 49.3001C82.3312 50.5938 81.9937 52.3376 80.7001 53.2374Z" fill="#949FB3"/>\n' +
        '<path d="M48.1875 45.25V56.5H16.875C13.7812 56.5 11.25 53.9688 11.25 50.875C11.25 47.7812 13.7812 45.25 16.875 45.25H48.1875Z" fill="#949FB3"/>\n' +
        '<path d="M48.1875 45.25H36.75V56.5H48.1875V45.25Z" fill="#8A94A8"/>\n' +
        '<path d="M51 17.125H36.75V34H51V17.125Z" fill="#42516D"/>\n' +
        '<path d="M65.0625 0.25C63.4873 0.25 62.25 1.48731 62.25 3.0625V11.5H53.8125C52.8564 11.5 52.0125 11.9502 51.4502 12.7373L40.8561 28.375H32.4563L23.5125 14.9875C22.05 12.7938 19.6312 11.5 16.9877 11.5H2.8125C1.23731 11.5 0 12.7373 0 14.3125C0 21.5127 5.45606 27.5311 12.4873 28.2623L21.4875 41.7625C22.95 43.9562 25.3688 45.25 28.0123 45.25H34.8187L36.75 48.7937L38.4375 52.1686C39.7873 54.8125 42.4877 56.5 45.525 56.5H49.65C53.4186 56.5 56.625 53.8564 57.3562 50.1438L57.8064 48.1752C60.0562 36.6998 70.2373 28.375 81.9375 28.375C83.5127 28.375 84.75 27.1377 84.75 25.5625V19.9375C84.75 9.08125 75.9188 0.25 65.0625 0.25Z" fill="#FF9567"/>\n' +
        '<path d="M84.75 19.9375V25.5625C84.75 27.1377 83.5127 28.375 81.9375 28.375C70.2373 28.375 60.0562 36.6998 57.8064 48.1752L57.3562 50.1438C56.625 53.8564 53.4187 56.5 49.65 56.5H45.525C42.4877 56.5 39.7873 54.8125 38.4375 52.1686L36.75 48.7937V28.375H40.8561L51.45 12.7373C52.0125 11.9502 52.8564 11.5 53.8125 11.5H62.25V3.0625C62.25 1.48731 63.4873 0.25 65.0625 0.25C75.9188 0.25 84.75 9.08125 84.75 19.9375Z" fill="#FF7235"/>\n' +
        '<path d="M80.7002 53.2367C80.1938 53.518 79.6873 53.6869 79.125 53.6869V45.7559L81.4873 49.2992C82.3313 50.5932 81.9938 52.3369 80.7002 53.2367Z" fill="#8A94A8"/>\n' +
        '</svg>\n',
      cssClass: 'bg-yellow-100',
      value: '0.0645'
    },
    {
      id: 10,
      name: 'Skútr',
      icon: '<svg class="w-full h-full" viewBox="0 0 90 66" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M37.6084 23.1967C37.4256 25.3289 36.784 28.1994 34.8047 30.1787H20.7422V20.335H34.9822C36.5273 20.335 37.742 21.6568 37.6084 23.1967Z" fill="#28485E"/>\n' +
        '<path d="M20.7422 20.335V30.1787H12.3047C10.3254 28.1994 9.68383 25.3289 9.50102 23.1967C9.36743 21.6568 10.5821 20.335 12.1272 20.335H20.7422Z" fill="#36607D"/>\n' +
        '<path d="M12.4805 62.5225C17.917 62.5225 22.3242 58.1153 22.3242 52.6787C22.3242 47.2422 17.917 42.835 12.4805 42.835C7.04392 42.835 2.63672 47.2422 2.63672 52.6787C2.63672 58.1153 7.04392 62.5225 12.4805 62.5225Z" fill="#36607D"/>\n' +
        '<path d="M12.4805 65.1592C5.59881 65.1592 0 59.5604 0 52.6787C0 45.797 5.59881 40.1982 12.4805 40.1982C19.3621 40.1982 24.9609 45.797 24.9609 52.6787C24.9609 59.5604 19.3621 65.1592 12.4805 65.1592ZM12.4805 45.4717C8.50658 45.4717 5.27344 48.7048 5.27344 52.6787C5.27344 56.6526 8.50658 59.8857 12.4805 59.8857C16.4544 59.8857 19.6875 56.6526 19.6875 52.6787C19.6875 48.7048 16.4544 45.4717 12.4805 45.4717Z" fill="#28485E"/>\n' +
        '<path d="M87.3633 52.6787C87.3633 58.1153 82.7803 62.5225 77.3438 62.5225C71.9072 62.5225 67.6758 58.1153 67.6758 52.6787C67.6758 47.2421 72.083 42.835 77.5195 42.835C82.9561 42.835 87.3633 47.2421 87.3633 52.6787Z" fill="#28485E"/>\n' +
        '<path d="M77.3438 65.1592C70.4668 65.1592 65.0391 59.5946 65.0391 52.6787C65.0391 45.797 70.6379 40.1982 77.5195 40.1982C84.4012 40.1982 90 45.797 90 52.6787C90 59.5461 84.2727 65.1592 77.3438 65.1592ZM77.5195 45.4717C73.5456 45.4717 70.3125 48.7048 70.3125 52.6787C70.3125 56.7199 73.401 59.8857 77.3438 59.8857C81.3458 59.8857 84.7266 56.5854 84.7266 52.6787C84.7266 48.7048 81.4934 45.4717 77.5195 45.4717Z" fill="#1B303E"/>\n' +
        '<path d="M79.756 51.2822C79.2812 50.4556 73.9864 40.8914 70.0084 33.4938C69.3188 32.211 67.7192 31.7311 66.4376 32.4203C65.1549 33.1099 64.6743 34.7088 65.3641 35.9911C68.432 41.6966 73.2504 50.4611 74.9611 53.5144C75.3386 54.1882 75.9704 55.316 77.5197 55.316C79.602 55.3158 80.8427 53.0187 79.756 51.2822V51.2822Z" fill="#36607D"/>\n' +
        '<path d="M72.2706 34.8543L63.7505 40.5355L58.1273 48.567C55.1706 52.7927 50.3244 55.3152 45.1669 55.3152H17.5447C13.5246 55.3152 9.9105 53.081 8.11225 49.4863L1.85972 36.983C1.48882 36.2394 1.48882 35.3658 1.85972 34.624C2.00562 34.3357 5.48433 27.5418 12.6562 27.5418H36.5624C37.7718 27.5418 38.8265 28.3644 39.1201 29.5386C39.3957 30.6411 44.0082 49.0911 44.2458 50.0418H45.1669C48.6052 50.0418 51.8361 48.3595 53.8083 45.5435L59.0009 38.1255C52.2528 13.8526 54.5438 21.9568 48.8794 2.24333L57.3538 0.939029C59.9818 0.534732 62.6835 1.38727 64.9581 3.33844C66.6369 4.77985 69.3544 8.7279 70.0154 10.716C70.5005 12.175 69.6304 13.7377 68.1363 14.1033L66.3626 14.5375C75.4528 25.2415 74.9042 24.5247 75.1236 25.0193C76.6986 28.5648 75.4997 32.7009 72.2706 34.8543V34.8543Z" fill="#FAAEE4"/>\n' +
        '<path d="M42.4002 42.6592H34.4531C32.9977 42.6592 31.8164 41.4779 31.8164 40.0225C31.8164 38.567 32.9977 37.3857 34.4531 37.3857H41.0818L42.4002 42.6592Z" fill="#EE8DD3"/>\n' +
        '<path d="M72.2707 34.8543L63.7506 40.5355L58.1273 48.567C55.1707 52.7927 50.3244 55.3152 45.167 55.3152H44.209V49.8941L44.2459 50.0418H45.167C48.6053 50.0418 51.8361 48.3595 53.8084 45.5435L59.001 38.1255C52.2529 13.8526 54.5439 21.9568 48.8795 2.24333L57.3539 0.939029C59.9818 0.534732 62.6836 1.38727 64.9582 3.33844C66.6369 4.77985 69.3545 8.7279 70.0154 10.716C70.5006 12.175 69.6305 13.7377 68.1363 14.1033L66.3627 14.5375C75.4529 25.2415 74.9042 24.5247 75.1236 25.0193C76.6986 28.5648 75.4998 32.7009 72.2707 34.8543V34.8543Z" fill="#EE8DD3"/>\n' +
        '<path d="M46.1404 7.96834C44.8609 7.96834 43.738 7.03547 43.5376 5.7317C43.3163 4.2924 44.3038 2.94627 45.7431 2.72514L51.838 1.7884C53.278 1.56885 54.6234 2.55463 54.8445 3.9941C55.0657 5.4334 54.0783 6.77953 52.6388 7.00067C46.046 8.01404 46.4302 7.96834 46.1404 7.96834Z" fill="#28485E"/>\n' +
        '<path d="M68.1362 14.104C65.6266 14.7184 68.2485 14.05 53.3776 17.8991L48.8794 2.24333L57.3538 0.939029C59.9817 0.534732 62.6835 1.38727 64.9581 3.33844C66.6368 4.77985 69.3544 8.7286 70.0153 10.7167C70.5005 12.1757 69.6304 13.7384 68.1362 14.104V14.104Z" fill="#D576BA"/>\n' +
        '<path d="M63.3991 9.94043L57.027 11.533L55.5979 6.54434L58.1555 6.15059C60.3319 5.81573 62.5508 7.85197 63.3991 9.94043Z" fill="#F7E378"/>\n' +
        '</svg>\n',
      cssClass: 'bg-yellow-100',
      value: '0.0645'
    },
    {
      id: 11,
      name: 'Vlak',
      icon: '<svg class="w-full h-11/12" viewBox="0 0 86 87" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M28.0436 86.4996H3.23827C1.44991 86.4996 0 85.0497 0 83.2615V75.2821C0 73.2169 1.67398 71.543 3.73915 71.543H28.0436V86.4996Z" fill="#5C4E66"/>\n' +
        '<path d="M28.0434 71.5439H14.0217V86.5004H28.0434V71.5439Z" fill="#533E5C"/>\n' +
        '<path d="M83.1948 80.8906H0V83.695C0 85.2437 1.25557 86.4993 2.80441 86.4993H83.1948C84.7429 86.4993 85.9992 85.244 85.9992 83.6949C85.9992 82.1457 84.7429 80.8906 83.1948 80.8906Z" fill="#58838C"/>\n' +
        '<path d="M83.1948 80.8906H14.0217V86.4993H83.1946C84.7428 86.4993 85.999 85.244 85.999 83.6949C85.999 82.1457 84.743 80.8906 83.1948 80.8906Z" fill="#5B7982"/>\n' +
        '<path d="M25.2392 24.8044C26.7884 24.8044 28.0436 23.5492 28.0436 22V14.5217C28.0436 6.78976 21.7537 0.5 14.0217 0.5V24.8044H25.2392Z" fill="#533E5C"/>\n' +
        '<path d="M25.2392 24.8044H5.60864C2.51096 24.8044 0 22.2933 0 19.1956V14.7004C0 6.98039 6.16496 0.574902 13.8845 0.500659C21.6792 0.425745 28.0434 6.74389 28.0434 14.5217V22C28.0436 23.5487 26.7878 24.8044 25.2392 24.8044Z" fill="#5C4E66"/>\n' +
        '<path d="M25.2392 24.8044C26.7884 24.8044 28.0436 23.5492 28.0436 22V14.5217C28.0436 6.78976 21.7537 0.5 14.0217 0.5V24.8044H25.2392Z" fill="#533E5C"/>\n' +
        '<path d="M68.2392 50.978C60.4953 50.978 54.2175 44.7004 54.2175 36.9563V19.1953H0V58.4562L5.60864 64.0648H78.5217L86 58.4562V50.9779H68.2392V50.978Z" fill="#5BA6DC"/>\n' +
        '<path d="M68.2392 50.978C60.4953 50.978 54.2175 44.7004 54.2175 36.9563V19.1953H43V64.0648H78.5217L86 58.4562V50.9779H68.2392V50.978Z" fill="#348BC9"/>\n' +
        '<path d="M0 58.4561V75.2822H77.5859C82.2326 75.2822 85.999 71.5156 85.999 66.8691V58.4561H0Z" fill="white"/>\n' +
        '<path d="M43 75.2822H77.5859C82.2326 75.2822 85.999 71.5156 85.999 66.8691V58.4561H43V75.2822Z" fill="#ECECEC"/>\n' +
        '<path d="M85.9998 50.0441V52.8485H66.3693C58.6293 52.8485 52.3477 46.5668 52.3477 38.8268V19.1963H55.1521C62.6115 19.1963 69.4543 21.851 74.7826 26.2446C76.2971 27.4971 77.6993 28.8993 78.9517 30.4137C83.3453 35.7419 85.9998 42.5844 85.9998 50.0441Z" fill="#5EE1ED"/>\n' +
        '<path d="M22.4347 52.8478H0V30.4131H22.4347V52.8478Z" fill="#58838C"/>\n' +
        '<path d="M43.0001 31.8339C41.654 30.9365 40.046 30.4131 38.3262 30.4131H16.8262V52.8478H38.3262C40.046 52.8478 41.654 52.3243 43.0001 51.427C45.2623 49.9314 46.7392 47.3514 46.7392 44.4348V38.8261C46.7392 35.9095 45.2623 33.3295 43.0001 31.8339Z" fill="#58838C"/>\n' +
        '<path d="M65.4348 86.4999C60.7956 86.4999 57.0217 82.726 57.0217 78.0869C57.0217 73.4478 60.7956 69.6738 65.4348 69.6738C70.0739 69.6738 73.8478 73.4478 73.8478 78.0869C73.8478 82.726 70.0739 86.4999 65.4348 86.4999Z" fill="#5C4E66"/>\n' +
        '<path d="M73.8479 78.0869C73.8479 73.4478 70.0739 69.6738 65.4348 69.6738V86.4999C70.0739 86.4999 73.8479 82.726 73.8479 78.0869Z" fill="#533E5C"/>\n' +
        '<path d="M43 86.4999C38.3608 86.4999 34.5869 82.726 34.5869 78.0869C34.5869 73.4478 38.3608 69.6738 43 69.6738C47.6391 69.6738 51.413 73.4478 51.413 78.0869C51.413 82.726 47.6391 86.4999 43 86.4999Z" fill="#5C4E66"/>\n' +
        '<path d="M51.4131 78.0869C51.4131 73.4478 47.6391 69.6738 43 69.6738V86.4999C47.6391 86.4999 51.4131 82.726 51.4131 78.0869Z" fill="#533E5C"/>\n' +
        '<path d="M46.7392 38.8262V44.4348C46.7392 47.3513 45.2622 49.9313 43 51.427V31.834C45.2622 33.3296 46.7392 35.9096 46.7392 38.8262Z" fill="#5B7982"/>\n' +
        '<path d="M41.1303 36.0225H5.60864V47.2399H41.1303V36.0225Z" fill="#5AF1FF"/>\n' +
        '<path d="M18.6956 30.4131H13.0869V52.8478H18.6956V30.4131Z" fill="#58838C"/>\n' +
        '<path d="M43 75.2822C41.4536 75.2822 40.1956 76.5403 40.1956 78.0866C40.1956 79.6329 41.4536 80.891 43 80.891C44.5463 80.891 45.8044 79.6329 45.8044 78.0866C45.8044 76.5403 44.5463 75.2822 43 75.2822Z" fill="#E6EEFF"/>\n' +
        '<path d="M45.8044 78.0866C45.8044 76.5403 44.5463 75.2822 43 75.2822V80.8909C44.5463 80.891 45.8044 79.633 45.8044 78.0866Z" fill="#DAE2F2"/>\n' +
        '<path d="M65.4348 75.2822C63.8885 75.2822 62.6304 76.5403 62.6304 78.0866C62.6304 79.6329 63.8885 80.891 65.4348 80.891C66.9811 80.891 68.2392 79.6329 68.2392 78.0866C68.2392 76.5403 66.9811 75.2822 65.4348 75.2822Z" fill="#E6EEFF"/>\n' +
        '<path d="M68.2392 78.0866C68.2392 76.5403 66.9811 75.2822 65.4348 75.2822V80.8909C66.9811 80.891 68.2392 79.633 68.2392 78.0866Z" fill="#DAE2F2"/>\n' +
        '</svg>\n',
      cssClass: 'bg-cyan-100',
      value: '0.021'
    },
    {
      id: 12,
      name: 'Home office',
      icon: '<svg class="w-full h-11/12" viewBox="0 0 82 85" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M71.9788 5.12402H63.5562L65.1418 5.91267L65.5562 6.36335V18.27L71.9506 23.1284H72.8389V5.66789L71.9788 5.12402Z" fill="#7B6C79"/>\n' +
        '<path d="M65.7436 5.12402H60.9634L60.0325 5.66789V13.4349L60.7112 14.5888L65.7436 18.4124V5.12402Z" fill="#665E68"/>\n' +
        '<path d="M71.4999 28.315L40.9999 5.66797L16.5692 23.7833V78.6451L14.7957 79.8283V80.1945H69.9858L72.147 79.3328V30.0237L71.4999 28.315Z" fill="white"/>\n' +
        '<path d="M17.5426 23.0615L10.4576 28.3151L9.85254 30.0238V79.333L11.5844 80.1946H17.5426V23.0615Z" fill="#F9F9F9"/>\n' +
        '<path d="M75.0924 79.333H15.5384L14.7957 79.8285V83.8285L15.6772 84.5006H75.0922C75.8789 84.5006 76.5168 83.8628 76.5168 83.076V80.7576C76.517 79.9707 75.8791 79.333 75.0924 79.333V79.333Z" fill="#4BCE5B"/>\n' +
        '<path d="M15.1446 83.076V80.7576C15.1446 79.9707 15.7825 79.333 16.5692 79.333H6.90722C6.12054 79.333 5.48267 79.9707 5.48267 80.7576V83.076C5.48267 83.8628 6.12054 84.5006 6.90722 84.5006H16.5692C15.7825 84.5006 15.1446 83.8628 15.1446 83.076Z" fill="#00C63A"/>\n' +
        '<path d="M74.4478 0.5H64.5419L64.1198 0.71525L63.5564 1.36313V5.12377L64.6497 5.66764H74.4478C75.2345 5.66764 75.8724 5.02993 75.8724 4.24309V1.92455C75.8724 1.13771 75.2345 0.5 74.4478 0.5V0.5Z" fill="#938493"/>\n' +
        '<path d="M63.7174 4.24292V1.92455C63.7174 1.13771 64.3552 0.5 65.1419 0.5H58.4236C57.6369 0.5 56.999 1.13771 56.999 1.92455V4.24292C56.999 5.02977 57.6369 5.66748 58.4236 5.66748H65.1419C64.3551 5.66764 63.7174 5.02977 63.7174 4.24292Z" fill="#7B6C79"/>\n' +
        '<path d="M59.043 49.4229L56.7614 51.7468H33.7434V67.5808L33.9859 67.8437H48.0141L48.4516 67.3101H52.8178L53.3098 67.8437H56.3238C58.245 67.8437 59.8167 66.2718 59.8167 64.3508V49.4229H59.043Z" fill="#B06C6C"/>\n' +
        '<path d="M28.9324 51.7467H24.9444L22.7894 48.958H22.1829V64.3507C22.1829 66.2718 23.7547 67.8436 25.6757 67.8436H28.6897L28.9322 67.5957V51.7467H28.9324Z" fill="#9D5E5E"/>\n' +
        '<path d="M28.6899 52.2402H33.9857V67.8441H28.6899V52.2402Z" fill="#865151"/>\n' +
        '<path d="M48.0139 52.2402H53.3097V67.8441H48.0139V52.2402Z" fill="#865151"/>\n' +
        '<path d="M56.3238 37.6465H30.216C29.0087 38.2494 28.1741 39.4981 28.1741 40.9328V49.4588C28.1741 50.8936 29.0087 52.1421 30.216 52.745H56.3238C58.2449 52.745 59.8166 51.1733 59.8166 49.2521V41.1394C59.8166 39.2184 58.2449 37.6465 56.3238 37.6465V37.6465Z" fill="#BC7F7F"/>\n' +
        '<path d="M28.3519 49.2521V41.1394C28.3519 39.2182 29.9238 37.6465 31.8448 37.6465H25.6758C23.7546 37.6465 22.1829 39.2182 22.1829 41.1394V49.2521C22.1829 51.1731 23.7547 52.745 25.6758 52.745H31.8448C29.9238 52.745 28.3519 51.1731 28.3519 49.2521Z" fill="#B06C6C"/>\n' +
        '<path d="M42.7535 48.2382H39.2465C38.5465 48.2382 37.979 47.6707 37.979 46.9707C37.979 46.2706 38.5465 45.7031 39.2465 45.7031H42.7535C43.4536 45.7031 44.0211 46.2706 44.0211 46.9707C44.0211 47.6707 43.4536 48.2382 42.7535 48.2382Z" fill="#B06C6C"/>\n' +
        '<path d="M50.6142 39.1237C50.0165 39.1237 49.4845 38.6991 49.3698 38.0903C48.5801 33.8947 45.0599 30.8495 40.9996 30.8495C36.9394 30.8495 33.4193 33.8947 32.6295 38.0903C32.4999 38.7782 31.8377 39.2313 31.1493 39.1015C30.4613 38.9721 30.0086 38.3093 30.1381 37.6214C31.1533 32.2285 35.7213 28.3145 40.9996 28.3145C46.2782 28.3145 50.8462 32.2287 51.8612 37.6214C51.9907 38.3093 51.538 38.9721 50.8499 39.1015C50.7709 39.1165 50.692 39.1237 50.6142 39.1237V39.1237Z" fill="#B06C6C"/>\n' +
        '<path d="M80.6008 29.0033L46.0141 2.82422H45.0777L41.6973 5.66791L41.0637 6.70085C41.2493 6.71299 41.4322 6.77517 41.5884 6.89346L77.0308 33.7203C78.3332 34.706 80.1882 34.4496 81.1742 33.1471C82.1599 31.8444 81.9033 29.9894 80.6008 29.0033V29.0033Z" fill="#FF7235"/>\n' +
        '<path d="M40.8872 6.70445L46.014 2.82388L44.481 1.66347C42.4313 0.112095 39.5684 0.112259 37.5188 1.66347L1.39913 29.0032C0.0966389 29.989 -0.159955 31.8441 0.825897 33.1466C1.81208 34.4497 3.6673 34.7053 4.9693 33.7198L40.4112 6.89312C40.5536 6.78534 40.7185 6.72414 40.8872 6.70445V6.70445Z" fill="#FF7235"/>\n' +
        '</svg>\n',
      cssClass: 'bg-purple-100',
      value: '0'
    }
  ]
}
