<template>
  <page-layout class="vue-m-intro">
    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <frm1006-button
        class="vue-navbar-button vue-is-back vue-content-back-button"
        :iconLeft="true"
        :captionHidden="true"
        type="app-button"
        @buttonClickEvent="navigateBack"
      >
        <template v-slot:iconLeft>
          <span class="vue-icon-inner" />
        </template>
      </frm1006-button>

      <template v-if="registrationSuccess">
        <prj1017-heading
          :header="$i18n.t('general.registrationSuccess')"
        />

        <p v-html="$t('intro.registrationDoneDescription')"></p>

        <gen1006-info-panel
          type="neutral"
        >
          {{ $t('intro.registrationDoneTip') }}
        </gen1006-info-panel>
      </template>

      <template v-else>
        <prj1017-heading
          :header="$i18n.t('general.completingProfile')"
        />

        <frm1035-form-group>
          <frm1001-input-field
            id="name-input"
            ref="nameInput"
            v-model="name"
            name="name"
            :maskToggle="maskToggle"
            :tooltip="false"
            :label="$i18n.t('general.namePlaceholder')"
            :disabled="isAuthorizing"
            @inputKeyDownEvent="loginFromInput"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1003-checkbox
            v-model="userPrivacyPolicy"
            :tooltip="false"
          >
            {{ $t('general.consents.personalData') }}

            <a
              class="prj-text-link cursor-pointer"
              href="https://zapkacms.csob.cz/wp-content/uploads/festival/souhlas%20se%20zpracovanim%20udaju.pdf"
              target="_blank"
            >{{ $t("general.consents.personalDataLink") }}</a>
          </frm1003-checkbox>
        </frm1035-form-group>
      </template>
    </template>
    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <!-- CONFIRMATION BUTTON -->
      <Frm1006Button
        v-if="!registrationSuccess"
        type="primary"
        :disabled="name.length < 1 || !userPrivacyPolicy"
        @buttonClickEvent="registrationProcess()"
      >
        {{ $t('general.continue') }}
      </Frm1006Button>

      <Frm1006Button
        v-if="registrationSuccess"
        type="primary"
        @buttonClickEvent="goToRefererProcess()"
      >
        {{ $t('general.registrationFinishButton') }}
      </Frm1006Button>
    </template>
    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isAuthorizing"
        :fullScreen="true"
        :overlay="true"
      />
      <gen1009-overlay
        class="vue-intro-error-overlay vue-is-info-panel"
        :buttonClose="true"
        :active.sync="hasError"
      >
        <gen1006-info-panel
          v-if="formError.length > 0"
          :type="'error'"
        >
          {{ $t('intro.error.' + formError) }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import logger from '@/utils/logger';
import client from '@/utils/data-loader';
import PageLayout from '@/templates/partials/page-layout';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import { APP_DESTINATIONS } from '@/constants/general';
import router from '@/router';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

export default {
  name: 'Registration',
  components: {
    PageLayout,
    Prj1017Heading
  },

  mixins: [
    mxDetectDesktop
  ],

  data() {
    return {
      name: '',
      userPrivacyPolicy: false,
      maskToggle: false,
      isAuthorizing: false,
      hasError: false,
      formError: '',
      registrationSuccess: false
    }
  },

  computed: {
    ...mapState('general', [
      'appDestination'
    ]),
    ...mapState('user', [
      'isStandalone',
      'platform',
      'browser',
      'deviceId',
      'profile',
    ]),

    paramEmail() {
      if(typeof this.$route.params?.email === 'undefined') {
        return '';
      }
      return this.$route.params.email
    },

    paramAuthType() {
      return this.$route.params.authType
    },

    paramAuthReferer() {
      if(typeof this.$route.params?.authReferer === 'undefined') {
        return '';
      }
      return this.$route.params.authReferer
    },

    paramAuthRefererAction() {
      if(typeof this.$route.params?.authRefererAction === 'undefined') {
        return '';
      }
      return this.$route.params.authRefererAction
    }
  },

  created() {
    if (this.profile.anonymous) {
      this.$router.push({ name: 'dashboard'});
    }
  },

  mounted() {
    if (this.isDesktopLayout) {
      this.$nextTick(() => {
        this.$refs.nameInput.inputSetFocus();
      });
    }
  },

  methods: {
    registrationProcess() {
      if (this.name !== '' && this.userPrivacyPolicy) {
        this.isAuthorizing = true;

        let formData = new FormData();
        formData.append('name', this.name);
        formData.append('consentPersonalData', this.userPrivacyPolicy);

        this.registrationProcessFetch(formData);
      }
    },

    registrationProcessFetch(formData) {
      client.putIdentityProfile(formData)
        .then(result => {
          logger.info(result);

          this.isAuthorizing = false;
          this.registrationSuccess = true;

          this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.IDENTITY_PROFILE, {force: true});
        }, error => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
          this.isAuthorizing = false;
          this.hasError = true;
          // desktop PC in CSOB has internet access limited to Chrome browser only, while default browser is Edge.
          // we customize the error message to advise user to switch to Chrome when CMS is not available
          this.formError = (this.appDestination === APP_DESTINATIONS.PRIVATE && this.browser !== 'Chrome') ? 'communicationErrorPrivate' : 'communicationError';
          logger.error('Error processing login', error);
        })
    },

    loginFromInput(value, event) {
      if (event.key === 'Enter') {
        this.registrationProcess();
      }
    },

    navigateBack() {
      router.go(-1);
    },

    goToRefererProcess() {
      if (this.paramAuthReferer !== '') {
        if (this.paramAuthRefererAction === '') {
          this.$router.push({ name: this.paramAuthReferer});
        } else {
          this.$router.push({ name: this.paramAuthReferer, params: {
            action: this.paramAuthRefererAction
          }});
        }
      } else {
        this.$router.push({ name: 'dashboard'});
      }
    }
  }
}
</script>
