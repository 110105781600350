<template>
  <page-layout class="vue-m-intro vue-m-parents-form vue-m-registration-parents">
    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <frm1006-button
        class="vue-navbar-button vue-is-back vue-content-back-button"
        :iconLeft="true"
        :captionHidden="true"
        type="app-button"
        @buttonClickEvent="navigateBack"
      >
        <template v-slot:iconLeft>
          <span class="vue-icon-inner" />
        </template>
      </frm1006-button>

      <template
        v-if="registrationSuccess"
        class="vue-b-form-success"
      >
        <prj1017-heading
          :header="$i18n.t('general.registrationSuccess')"
          :subheader="$i18n.t('intro.registrationParentSuccess')"
        />

        <gen1006-info-panel
          type="neutral"
        >
          {{ $t('intro.registrationDoneTip') }}
        </gen1006-info-panel>
      </template>

      <template v-else>
        <prj1017-heading
          :header="$t('general.completingProfile')"
        />

        <frm1035-form-group>
          <frm1001-input-field
            v-model="parentData.userName"
            :label="$t('general.namePlaceholder')"
            :tooltip="false"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            v-model="parentData.userPersonalNumber"
            :label="$t('general.personalNumberPlaceholder')"
            :tooltip="false"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            v-model="parentData.userPhone"
            :label="$t('general.phoneOnlyPlaceholder')"
            :tooltip="false"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1001-input-field
            v-model="parentData.userEmail"
            :label="$t('parents.forms.labels.emailPlaceholder')"
            :tooltip="false"
            :disabled="isDisabledEmail"
          />
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1028-advanced-combo
            id="idea-category"
            v-model="parentData.userWorkingPlace"
            :options="branches"
            :tooltip="false"
            :label="$t('parents.forms.placeholderWorkingPlace')"
            :placeholder="$t('parents.forms.placeholderWorkingPlace')"
          />
        </frm1035-form-group>

        <div class="vue-parents-consents-description">
          <p>
            {{ $t('parents.forms.consents.newsletterGroup') }}
          </p>
        </div>

        <frm1035-form-group>
          <frm1003-checkbox
            v-model="parentData.userNewsletterTypeEmail"
            :tooltip="false"
          >
            {{ $t('parents.forms.consents.newsletterEmail') }}
          </frm1003-checkbox>

          <frm1003-checkbox
            v-model="parentData.userNewsletterTypeFacebook"
            :tooltip="false"
          >
            {{ $t('parents.forms.consents.newsletterFacebook') }}
          </frm1003-checkbox>
        </frm1035-form-group>

        <frm1035-form-group>
          <frm1003-checkbox
            v-model="parentData.userPrivacyPolicy"
            :tooltip="false"
          >
            {{ $t('parents.forms.consents.personalData') }}

            <a
              class="prj-text-link cursor-pointer"
              :href="$t('parents.forms.consents.personalDataLinkUrl')"
              target="_blank"
            >{{ $t("parents.forms.consents.personalDataLink") }}</a>
            {{ $t('parents.forms.consents.personalDataEnd') }}
          </frm1003-checkbox>
        </frm1035-form-group>
      </template>
    </template>
    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <!-- CONFIRMATION BUTTON -->
      <frm1006-button
        v-if="!registrationSuccess"
        class="vue-button-register"
        :disabled="!isSubmitActive"
        @buttonClickEvent="submitRegistration"
      >
        {{ $t('parents.forms.buttonSubmit') }}
      </frm1006-button>
    </template>
    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :fullScreen="true"
        :overlay="true"
      />
      <gen1009-overlay
        class="vue-intro-error-overlay vue-is-info-panel"
        :buttonClose="true"
        :active.sync="hasError"
      >
        <gen1006-info-panel
          v-if="formError.length > 0"
          :type="'error'"
        >
          {{ $t('intro.error.' + formError) }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/templates/partials/page-layout';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import logger from '@/utils/logger';
import client from '@/utils/data-loader';
import router from '@/router';
import dataLoader from '@/utils/data-loader';
import utilsGeneral from '@/utils/utils-general';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import {APP_DESTINATIONS, APP_ENTRY_PATH_STORAGE_KEY, ERROR_CODES_LOGIN} from '@/constants/general';
import {PARENTS_DASHBOARD_ID} from '@/constants/app-constants';
import {mapState} from 'vuex';

export default {
  name: 'RegistrationParent',
  components: {
    PageLayout,
    Prj1017Heading
  },

  mixins: [
    mxDetectDesktop
  ],

  data() {
    return {
      code: '',
      maskToggle: false,
      isLoading: false,
      hasError: false,
      formError: '',
      registrationSuccess: false,
      parentData: {
        userName: '',
        userEmail: '',
        userPhone: '',
        userPrivacyPolicy: false,
        userPersonalNumber: '',
        userNewsletterTypeEmail: false,
        userNewsletterTypeFacebook: false,
        userWorkingPlace: '',
        parentsCategoryData: [],
      }
    }
  },

  computed: {
    ...mapState('general', [
      'appDestination'
    ]),
    ...mapState('user', [
      'isStandalone',
      'platform',
      'browser',
      'deviceId',
      'profile',
    ]),

    paramEmail() {
      if(typeof this.$route.params?.email === 'undefined') {
        return '';
      }
      return this.$route.params.email
    },

    paramPersonalEmailFlag() {
      if(typeof this.$route.params?.personalEmailFlag === 'undefined') {
        return false;
      }
      return this.$route.params.personalEmailFlag
    },

    paramDeviceId() {
      if(typeof this.$route.params?.deviceId === 'undefined') {
        return false;
      }
      return this.$route.params.deviceId
    },

    branches() {
      return this.parentData.parentsCategoryData.map(item => {
        return {
          value: item.branchId,
          caption: item.branchCaption
        }
      })
    },

    isContinueActive() {
      return !!(this.parentData.userName && this.parentData.userPersonalNumber && this.formEmailInvalidState(this.parentData.userEmail) && this.parentData.userPhone && this.parentData.userWorkingPlace && this.parentData.userPrivacyPolicy);
    },

    isSubmitActive() {
      return this.isContinueActive;
    },

    isDisabledEmail() {
      return !!this.paramEmail
    }
  },

  created() {},

  mounted() {
    this.getParentsCategories();
    if (this.paramEmail) {
      this.parentData.userEmail = this.paramEmail
      this.getUserInProgramExist()
    }
  },

  methods: {
    confirmationProcess() {
      if (this.code !== '') {
        this.isLoading = true;

        let formData = new FormData();
        formData.append('deviceId', this.deviceId);
        formData.append('code', this.code);
        formData.append('email', this.paramEmail);
        formData.append('personalEmailFlag', this.paramPersonalEmail);

        this.confirmationProcessFetch(formData);
      }
    },

    confirmationCodeSendAgainProcess() {
      this.isLoading = true;

      let formData = new FormData();
      formData.append('deviceId', this.deviceId);
      formData.append('email', this.paramEmail);
      formData.append('personalEmailFlag', this.paramPersonalEmail);

      this.confirmationCodeSendAgainProcessFetch(formData);
    },

    confirmationProcessFetch(formData) {
      client.fetchAuthorization(formData)
        .then(result => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_ANONYMOUS, result.anonymous);
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_REFRESH_TOKEN, result.refreshToken);
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PUBLIC_KEY, result.publicKey);
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_LAST_LOGGED, new Date().getTime());
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, true);

          const entryPath = localStorage.getItem(APP_ENTRY_PATH_STORAGE_KEY);

          this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.IDENTITY_PROFILE, {force: true}).finally(() => {
            this.isLoading = false;
            if (this.profile.name !== '' && this.profile.consentPersonalData === true) {
              // Login finished and go back to referrer
              if (this.paramAuthReferer !== '') {
                if (this.paramAuthRefererAction === '') {
                  this.$router.push({ name: this.paramAuthReferer, params: this.paramAuthRefererParams});
                } else {
                  this.$router.push({
                    name: this.paramAuthReferer,
                    params: {
                      ...this.paramAuthRefererParams,
                      action: this.paramAuthRefererAction
                    }
                  });
                }
              } else {
                // Go to Dashboard
                this.$router.push({ name: 'dashboard'});
              }
            } else {
              // Continue to registration
              if (this.paramAuthType === 'registration') {
                this.$router.push(
                  { name: 'registration',
                    params: {
                      email: this.email,
                      authType: this.paramAuthType,
                      authReferer: this.paramAuthReferer,
                      authRefererAction: this.paramAuthRefererAction,
                    }
                  }
                );
                return;
              }

              if (entryPath) {
                // clear path
                localStorage.setItem(APP_ENTRY_PATH_STORAGE_KEY, '');
                this.$router.push(entryPath);
                return;
              }

              // Go to Dashboard
              this.$router.push({ name: 'dashboard'});
            }
          });
        }, error => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
          this.isLoading = false;
          this.hasError = true;

          if (error.response.data.hasOwnProperty('code') && ERROR_CODES_LOGIN.includes(error.response.data.code)) {
            this.formError = error.response.data.code;
          } else if (this.appDestination === APP_DESTINATIONS.PRIVATE && this.browser !== 'Chrome') {
            // desktop PC in CSOB has internet access limited to Chrome browser only, while default browser is Edge.
            // we customize the error message to advise user to switch to Chrome when CMS is not available
            this.formError = 'communicationErrorPrivate';
          } else {
            this.formError = 'communicationError';
          }

          logger.error('Error processing login', error);
        })
    },

    confirmationCodeSendAgainProcessFetch(formData) {
      client.fetchAuthorization(formData)
        .then(() => {
          this.isLoading = false;
        }, error => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
          this.isLoading = false;
          this.hasError = true;

          if (error.response.data.hasOwnProperty('code') && ERROR_CODES_LOGIN.includes(error.response.data.code)) {
            this.formError = error.response.data.code;
          } else if (this.appDestination === APP_DESTINATIONS.PRIVATE && this.browser !== 'Chrome') {
            // desktop PC in CSOB has internet access limited to Chrome browser only, while default browser is Edge.
            // we customize the error message to advise user to switch to Chrome when CMS is not available
            this.formError = 'communicationErrorPrivate';
          } else {
            this.formError = 'communicationError';
          }

          logger.error('Error processing login', error);
        })
    },

    loginFromInput(value, event) {
      if (event.key === 'Enter') {
        this.confirmationProcess();
      }
    },

    navigateBack() {
      router.go(-1);
    },

    getParentsCategories() {
      this.isLoading = true;

      return dataLoader.fetchApiDataList(PARENTS_DASHBOARD_ID)
        .then(data => {
          this.parentData.parentsCategoryData = data.parents;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    formEmailInvalidState(value) {
      return utilsGeneral.isValidEmail(value);
    },

    submitRegistration() {
      this.isLoading = true;

      const sendData = {
        userName: this.parentData.userName,
        userEmail: this.parentData.userEmail,
        userPhone: this.parentData.userPhone,
        userPrivacyPolicy: this.parentData.userPrivacyPolicy,
        userPersonalNumber: this.parentData.userPersonalNumber,
        userNewsletterTypeEmail: this.parentData.userNewsletterTypeEmail,
        userNewsletterTypeFacebook: this.parentData.userNewsletterTypeFacebook,
        userWorkingPlace: this.parentData.userWorkingPlace
      }

      dataLoader.postParentProgram(
        sendData
      )
        .then(() => {
          this.registrationSuccess = true;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
          this.registrationSuccess = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getUserInProgramExist() {
      this.isLoading = true;

      return dataLoader.getParentProgram(this.paramEmail)
        .then(data => {
          this.registrationSuccess = data.data.program;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
</script>
