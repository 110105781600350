<template>
  <div
    class="vue-c-search"
    :class="{'vue-is-focused': isFocused}"
  >
    <template>
      <div
        v-if="!isDesktopLayout && !disabledCompact"
        class="vue-b-toggle"
      >
        <frm1006-button
          v-if="!isSearchActive"
          type="internal"
          :iconLeft="true"
          :captionHidden="true"
          class="vue-search-icon"
          @buttonClickEvent="searchToggle"
        />

        <frm1006-button
          v-if="isSearchActive"
          type="internal"
          :iconLeft="true"
          :captionHidden="true"
          class="vue-search-close-icon"
          @buttonClickEvent="searchToggle"
        />
      </div>

      <div
        v-if="isSearchActive || isSearchToggleActive || isDesktopLayout || disabledCompact"
        class="vue-b-controls"
        :class="{'vue-is-focused': isFocused}"
      >
        <frm1001-input-field
          ref="search"
          v-model="searchModel"
          :tooltip="false"
          :placeholder="placeholderDefault"
          class="vue-has-search-icon"
          @inputKeyUpEvent="inputKeyUp"
          @inputFocusEvent="inputFocusSet"
          @inputBlurEvent="inputBlurSet"
        />

        <frm1006-button
          v-if="searchModel.length > 0"
          type="internal"
          :iconLeft="true"
          :captionHidden="true"
          class="vue-search-clear-icon"
          @buttonClickEvent="clearSearch"
        />

        <frm1006-button
          v-if="!submitButtonDisable"
          :title="$i18n.t('general.search')"
          :disabled="isSubmitDisabled"
          class="vue-search-submit-icon"
          @buttonClickEvent="submitSearch"
        >
          {{ $t('general.search') }}
        </frm1006-button>
      </div>

      <transition
        name="vue-anim-move-from-top"
        appear
      >
        <div
          v-if="isFocused && showHistory && historyData != null && historyData.length > 0 && isSearchHistoryActive"
          class="vue-b-history"
        >
          <h1>{{ $t('general.searchHistory') }}</h1>
          <div
            v-for="(itemHistory, indexHistory) in historyData"
            :key="indexHistory"
            class="vue-history-item"
          >
            <frm1006-button
              type="internal"
              class="vue-history-submit-icon"
              @buttonClickEvent="submitHistoryItem(itemHistory.term)"
            >
              {{ itemHistory.term }}
            </frm1006-button>

            <frm1006-button
              type="internal"
              :iconRight="true"
              :captionHidden="true"
              class="vue-history-delete-icon"
              @buttonClickEvent="clearHistoryItem(itemHistory.id)"
            />
          </div>
        </div>
      </transition>

      <transition
        name="vue-anim-move-from-top"
        appear
      >
        <div
          v-if="showResults && isSearchActive"
          class="vue-b-results"
        >
          <div
            v-if="!hasResults && !isLoading && isLoaded"
            class="vue-empty-notice"
          >
            {{ $t('general.widgetNoContent') }}
          </div>

          <template
            v-for="item in getMockDataItems"
          >
            <prj1024-item-list-item
              :key="item.actionId"
              :list-item-config="item"
              :item-data="item"
              :promoted="false"
              :promoted-only-icon="false"
              :is-widget="false"
              :tag-disable="true"
              :module="'general'"
              :line-clamp="2"
              :class="dataLoadingClassObject"
            />
          </template>

          <template
            v-for="(resultItem, resultIndex) in renderedData"
          >
            <h1
              v-if="resultItem.items.length > 0 && ((searchMode === 'full' && resultItem.items.length > 0) || (searchMode === 'summary'))"
              :key="resultIndex + getRandomInt(1000)"
              class="vue-search-result-caption"
              :class="resultCaptionClassObject"
              @click="switchModeClickEvent(resultItem.module)"
              v-html="$t('general.searchResults.' + resultItem.module, {resultsCount: resultsDataCount[resultItem.module][0].count})"
            />

            <prj1024-item-list-item
              v-for="(subItem, subIndex) in resultItem.items"
              :key="resultItem.module + subIndex + getRandomInt(1000)"
              :itemData="subItem"
              :listItemConfig="subItem"
              :promoted="false"
              :promoted-only-icon="false"
              :is-widget="false"
              :tag-disable="true"
              :module="resultItem.module ? resultItem.module : module"
              :line-clamp="2"
              :class="dataLoadingClassObject"
              @goToDetailPrev="goToDetailClickEvent()"
            />
          </template>

          <gen1006-info-panel
            v-if="!(renderedData && renderedData.length > 0) && !isInfinityScrollEnabled && nothingToShow && !isLoading && isLoaded"
            type="neutral"
          >
            {{ $t('articles.emptyCategory') }}
          </gen1006-info-panel>

          <div
            v-if="isInfinityScrollEnabled"
            ref="infinityScrollTrigger"
            class="vue-b-loader"
          >
            <gen1016-loading-indicator
              :active="isInfinityScrollEnabled"
            />
          </div>
        </div>
      </transition>

      <transition
        name="vue-anim-fade"
        appear
      >
        <div
          v-if="(isFocused && showHistory && historyData != null && historyData.length > 0) || (showResults && isSearchActive)"
          class="vue-search-overlay"
          @click="clearSearch()"
        />
      </transition>
    </template>
  </div>
</template>

<script>

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import dataLoader from '@/utils/data-loader';
import { mapState } from 'vuex';
import logger from '@/utils/logger';
import Prj1024ItemListItem from '@/components/prj1024-item-list/prj1024-item-list-item.vue';
import debounce from 'lodash/debounce';
import {WIDGET_EMPTY_DATA_ITEMS} from '@/constants/app-constants';
import mxInfinityScrolling from '@/mixins/mx-infinity-scrolling';

export default {
  name: 'Prj1048Search',
  components: {Prj1024ItemListItem},

  mixins: [
    mxDetectDesktop,
    mxInfinityScrolling
  ],

  props: {
    module: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    setFocusInput: {
      type: Boolean,
      required: false,
      default: false
    },
    submitButtonDisable: {
      type: Boolean,
      required: false,
      default: false
    },
    showHistory: {
      type: Boolean,
      required: false,
      default: false
    },
    showResults: {
      type: Boolean,
      required: false,
      default: false
    },
    autoFocus: {
      type: Boolean,
      required: false,
      default: true
    },
    disabledCompact: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      searchModel: '',
      searchMode: 'summary',
      searchResult: {
        offset: 0,
        limit: 20
      },
      isFocused: false,
      isLoading: false,
      isLoaded: false,
      debounceFn: null,
      emptyDataItems: WIDGET_EMPTY_DATA_ITEMS,
      noMoreArticles: false,
      nothingToShow: false,
      filteredArticlesOffset: 0,
      selectedModule: null
    }
  },

  computed: {
    ...mapState('user', [
      'deviceId',
      'profile'
    ]),

    dataLoadingClassObject() {
      return {
        'vue-is-loading': this.isLoading,
        'vue-is-loaded': this.isLoaded,
      }
    },

    resultCaptionClassObject() {
      return {
        'vue-is-full-mode': this.searchMode === 'full'
      }
    },

    searchQuery() {
      return this.$store.state[this.module].searchQuery
    },

    isSearchActive() {
      return this.$store.state[this.module].isSearchActive
    },

    isSearchToggleActive() {
      return this.$store.state[this.module].isSearchToggleActive
    },

    isSearchHistoryActive() {
      return this.$store.state[this.module].isSearchHistoryActive
    },

    resultsDataCount() {
      return this.$store.state[this.module].searchResultCount;
    },

    hasResults() {
      let retValue = false;
      this.renderedData?.forEach(item => {
        if (item.items.length > 0) {
          retValue = true;
        }
      });
      return retValue;
    },

    resultsData() {
      return this.$store.state[this.module].searchResultData;
    },

    resultsFullData() {
      return this.$store.state[this.module].searchResultFullData;
    },

    renderedData() {
      return this.searchMode === 'full' ? this.resultsFullData : this.resultsData;
    },

    isSubmitDisabled() {
      return this.searchModel.length < 3
    },

    historyData() {
      return this.profile.searchHistory ?? [];
    },

    getMockDataItems() {
      return this.isLoading && !this.isLoaded ? this.emptyDataItems.slice(0, 2) : null;
    },

    isInfinityScrollEnabled() {
      let retValue = false;
      const ONE_PAGE = 19;
      this.renderedData?.forEach(parent => {
        if (parent.module === this.selectedModule) {
          retValue = parent.items.length > ONE_PAGE && !this.noMoreArticles;
        }
      });

      return retValue;
    },

    placeholderDefault() {
      return this.placeholder === '' ? this.$i18n.t('general.searchPlaceholder') : this.placeholder;
    }
  },

  watch: {
    setFocusInput(value) {
      if (value) {
        this.inputSetFocus();
      }
    },

    isSearchActive(value) {
      if (value) {
        this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS}`, false);
      }
    },

    isFocused(value) {
      if (value && !this.isSearchActive && !this.isSearchHistoryActive) {
        this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS}`, true);
      }
    },
  },

  mounted() {
    if (this.autoFocus) {
      this.inputSetFocus();
    }

    if (this.isFocused && !this.isSearchActive && !this.isSearchHistoryActive) {
      this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS}`, true);
    }

    this.debounceFn = debounce(() => this.submitLiveSearch(), 800);
  },

  methods: {
    inputSetFocus() {
      if (this.isSearchActive || this.isSearchToggleActive || this.isSearchHistoryActive || this.isDesktopLayout) {
        this.$nextTick(() => {
          this.searchModel = this.searchQuery;
          this.$refs.search.inputSetFocus();
        });
      }
    },

    inputKeyUp(value, event) {
      if (value.length >= 3 && this.module === 'general') {
        this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_STATUS}`, true);
        this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_QUERY}`, this.searchModel);
        this.isLoading = true;
        this.isLoaded = false;
        this.debounceFn();
      } else {
        this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_STATUS}`, false);
        this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_QUERY}`, this.searchModel);
      }
      if (event.keyCode === 13) this.submitSearch()
    },

    submitSearch() {
      if (!this.isSubmitDisabled) {
        dataLoader.logSearchQuery(this.deviceId, this.module, this.searchModel);
        this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_QUERY}`, this.searchModel);
        this.$emit('buttonSearchEvent', this.searchModel);
      }
    },

    async submitLiveSearch() {
      this.searchMode = 'summary';
      let formData = new FormData();
      formData.append('term', this.searchModel);
      await dataLoader.postGlobalSearch(formData)
        .then(response => {
          if (response.data.message?.history) {
            this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_GLOBAL_SEARCH_HISTORY, response.data.message?.history);
          }
          if (response.data.message?.result) {
            let convertedDataProperty = this.convertDataProperty(response.data.message?.result);
            this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_SEARCH_RESULTS_DATA, this.convertDataToGroups(convertedDataProperty));
          }
          if (response.data.message?.resultCount) {
            this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_SEARCH_RESULTS_COUNT, this.convertCountToGroups(response.data.message?.resultCount));
          }
        })
        .catch(error => {
          logger.error(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.isLoaded = true;
        });
    },

    async loadNewContent(scrolled) {
      await this.submitSearchFull(this.selectedModule, scrolled);
    },

    async submitSearchFull(module, scrolled) {
      if (!scrolled) {
        this.noMoreArticles = false;
        this.nothingToShow = false;
        this.searchResult.offset = 0;
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_SEARCH_RESULTS_FULL_DATA, []);
      }

      let tempArray = [];
      this.selectedModule = module;
      let formData = new FormData();
      formData.append('term', this.searchModel);
      formData.append('module', module);
      formData.append('offset', this.searchResult.offset);
      formData.append('amount', this.searchResult.limit);
      await dataLoader.postGlobalSearch(formData)
        .then(response => {
          if (response.data.message?.history) {
            this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_GLOBAL_SEARCH_HISTORY, response.data.message?.history);
          }
          if (response.data.message?.result) {
            let convertedDataProperty = this.convertDataProperty(response.data.message?.result);
            let convertedDataToGroups = this.convertDataToGroups(convertedDataProperty);

            if (this.renderedData) {
              tempArray = this.renderedData;
            }
            let resultSplitArray = this.splitData(tempArray, convertedDataToGroups);

            this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_SEARCH_RESULTS_FULL_DATA, resultSplitArray);

            if (convertedDataProperty.length < this.searchResult.limit) {
              this.noMoreArticles = true;
            }

            if (!convertedDataProperty.length) {
              this.nothingToShow = true;
            }
          }
        })
        .catch(error => {
          logger.error(error);
        })
        .finally(() => {
          if (!this.noMoreArticles && !scrolled) {
            this.initInfinityScroll();
          }
          this.isLoading = false;
          this.isLoaded = true;
          this.searchResult.offset += this.searchResult.limit;
        });
    },

    inputFocusSet() {
      this.isFocused = true
    },

    inputBlurSet() {
      //this.isFocused = false
    },

    searchToggle() {
      this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_TOGGLE_STATUS}`, !this.isSearchToggleActive);
      this.$emit('buttonSearchActiveEvent', this.isSearchToggleActive);
      if (this.isSearchToggleActive) {
        this.inputSetFocus();
      } else {
        this.clearSearch();
      }
    },

    clearSearch() {
      this.isFocused = false;
      this.searchModel = '';
      this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_QUERY}`, this.searchModel);
      this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_STATUS}`, false);
      this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_TOGGLE_STATUS}`, false);
      this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS}`, false);
    },

    async clearHistoryItem(termId) {
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_GLOBAL_SEARCH_HISTORY_DELETE, termId);
      await dataLoader.deleteGlobalSearchHistory(termId);
      //this.isFocused = false;
    },

    submitHistoryItem(term) {
      this.searchModel = term;
      this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_QUERY}`, term);
      this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_STATUS}`, true);
      this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_TOGGLE_STATUS}`, true);
      this.$store.commit(`${STORE_MODULES[this.module.toUpperCase()]}/${MUTATIONS_CONSTANTS.SET_SEARCH_HISTORY_STATUS}`, false);
      this.submitLiveSearch();
    },

    convertDataProperty(data) {
      let tempObject = [];
      let counter = 0;

      data.forEach(item => {
        tempObject[counter] = {
          name: item.title,
          id: item.id,
          actionId: item.id,
          image: item.featuredImage,
          displayDate: true,
          target: '',
          module: item.module,
          categories: item.module === 'forvardino' ? [] : item.categories,
          modifiedTimestamp: item.modifiedTimestamp / 1000,
          createdTimestamp: item.createdTimestamp / 1000,
          modified: item.modifiedTimestamp,
          created: item.createdTimestamp,
        }
        counter++;
      });

      return tempObject;
    },

    convertDataToGroups(data) {
      let groupedData = [];

      groupedData.push({
        module: 'news',
        items: data.filter(element => element.module === 'news')
      });

      groupedData.push({
        module: 'benefit',
        items: data.filter(element => element.module === 'benefit')
      });

      groupedData.push({
        module: 'forvardino',
        items: data.filter(element => element.module === 'forvardino')
      });

      groupedData.push({
        module: 'event',
        items: data.filter(element => element.module === 'event')
      });

      groupedData.push({
        module: 'service',
        items: data.filter(element => element.module === 'service')
      });

      groupedData.push({
        module: 'knowledge',
        items: data.filter(element => element.module === 'knowledge')
      });

      groupedData.push({
        module: 'parent',
        items: data.filter(element => element.module === 'parent')
      });

      return groupedData;
    },

    convertCountToGroups(data) {
      let groupedData = [];
      groupedData['news'] = data.filter(element => element.module === 'news');
      groupedData['forvardino'] = data.filter(element => element.module === 'forvardino');
      groupedData['event'] = data.filter(element => element.module === 'event');
      groupedData['benefit'] = data.filter(element => element.module === 'benefit');
      groupedData['service'] = data.filter(element => element.module === 'service');
      groupedData['knowledge'] = data.filter(element => element.module === 'knowledge');
      groupedData['parent'] = data.filter(element => element.module === 'parent');
      return groupedData;
    },

    goToDetailClickEvent() {
      this.clearSearch();
    },

    switchModeClickEvent(module) {
      if (this.searchMode === 'summary') {
        this.searchMode = 'full';
        this.isLoading = true;
        this.isLoaded = false;
        this.submitSearchFull(module, false);
      } else {
        this.searchMode = 'summary';
      }
    },

    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },

    splitData(oldArray, newArray) {
      newArray?.forEach(newItem => {
        let existModuleData = false;

        oldArray.forEach(oldItem => {
          if (oldItem.module === newItem.module) {
            existModuleData = true;
            oldItem.items = [...oldItem.items, ...newItem.items];
          }
        });

        if (!existModuleData) {
          oldArray.push({
            module: newItem.module,
            items: newItem.items
          });
        }
      });

      return oldArray;
    }
  }
}
</script>
