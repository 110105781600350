<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="navigateBack"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1021-profile-card
        v-if="lectorLoaded"
        :profileView="true"
        :fullName="lector.name"
        :email="lector.email"
        :avatar="lector.avatar"
        :profession="lector.profession"
        :phoneNumber="lector.phone"
        :isClickable="false"
      />

      <div
        v-if="lector.description"
        class="vue-lector-text-block"
        v-html="lector.description"
      />

      <gen1006-info-panel
        v-if="!lectorLoaded && !isLoading"
        type="neutral"
      >
        {{ $t('general.loadingFailed') }}
      </gen1006-info-panel>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import router from '@/router';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '../../components/prj1002-navbar/prj1002-navbar';
import Prj1021ProfileCard from '@/components/prj1021-profile-card/prj1021-profile-card';

export default {
  name: 'ForvardinoLector',
  components: {
    PageLayout,
    Prj1021ProfileCard,
    Prj1002Navbar
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      lectorLoaded: false,
      isLoading: true,
      hasError: false,
      lector: {}
    }
  },

  computed: {
    currentLectorId() {
      return this.$route.params.id;
    }
  },

  created() {
    this.getForvardinoLectorData();
  },

  methods: {
    getForvardinoLectorData() {
      dataLoader.getForvardinoLectorDetail(this.currentLectorId)
        .then(data => {
          this.lector = data;
          this.lectorLoaded = true;
          this.isLoading = false;
        })
        .catch(error => {
          this.hasError = true;
          this.isLoading = false;
          logger.error(error);
        })
        .finally(() => {
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          }
        })
    },

    navigateBack() {
      if (this.$route.params.eventId) {
        router.push({
          name: 'forvardino-detail', params: {
            id: this.$route.params.eventId,
            origin: this.$route.params.origin
          }
        });
      } else {
        router.push({name: 'forvardino-list'});
      }
    }
  }
}
</script>
