<template>
  <div
    ref="dashboardButtons"
    class="vue-c-dashboard-buttons"
    :class="classObject"
    :style="styleObject"
  >
    <div
      v-for="(group, index) in dashboardGroupButtons"
      :key="index"
      class="vue-tile-group"
      @click="groupButtonClickEvent(group, $event)"
    >
      <div
        v-for="groupItem in group.items"
        :key="groupItem.id"
        class="vue-tile-button"
      >
        <frm1006-button
          type="tile"
          importance="normal"
        >
          <template v-slot:iconLeft>
            <div
              class="vue-tile-icon"
              :class="generateTileIcon(groupItem)"
              :style="generateTileIconBackground(groupItem)"
            />
          </template>
        </frm1006-button>

        <prj1044-dashboard-tile-sticker
          v-if="groupItem.sticker"
          :sticker="groupItem.sticker"
          :compact="true"
        />
      </div>
    </div>

    <div
      v-for="(item, index2) in getDashboardButtons"
      :key="index2"
      class="vue-tile"
      :class="[setFloatedTile(index2), tileLoadingClassObject]"
    >
      <div class="vue-tile-button">
        <frm1006-button
          type="tile"
          importance="normal"
          @buttonClickEvent="goToRoute(item.routeName)"
        >
          <template v-slot:iconLeft>
            <div
              class="vue-tile-icon"
              :class="generateTileIcon(item)"
              :style="generateTileIconBackground(item)"
            />
          </template>
          {{ item.nameShort }}
        </frm1006-button>

        <prj1044-dashboard-tile-sticker
          v-if="item.sticker"
          :sticker="item.sticker"
        />
      </div>
    </div>

    <frm1006-button
      v-if="expandButtonVisible"
      class="vue-expand-button"
      type="tile"
      importance="normal"
      @buttonClickEvent="toggleButton()"
    >
      <template v-slot:iconLeft>
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.62573 11.1812L6.37404 6.43285L1.62573 1.68455"
            stroke="black"
            stroke-width="2"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
        </svg>
      </template>
    </frm1006-button>

    <gen1009-overlay
      :active.sync="showGroupMenu"
      :positionCustom="contextContainerPositions"
    >
      <div
        class="vue-b-group-menu-items"
      >
        <div
          v-for="groupItem in groupMenu.items"
          :key="groupItem.id"
          class="vue-tile"
        >
          <div class="vue-tile-button">
            <frm1006-button
              type="tile"
              importance="normal"
              @buttonClickEvent="goToRoute(groupItem.routeName)"
            >
              <template v-slot:iconLeft>
                <div
                  class="vue-tile-icon"
                  :class="generateTileIcon(groupItem)"
                  :style="generateTileIconBackground(groupItem)"
                />
              </template>
              {{ groupItem.nameShort }}
            </frm1006-button>

            <prj1044-dashboard-tile-sticker
              v-if="groupItem.sticker"
              :sticker="groupItem.sticker"
            />
          </div>
        </div>
      </div>
    </gen1009-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { APP_DESTINATIONS } from '@/constants/general';
import { DASHBOARD_EMPTY_BUTTONS } from '@/constants/app-constants';
import * as envConfig from 'env-config';
import router from '@/router';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import Prj1044DashboardTileSticker from '@/components/prj1044-dashboard-tile-sticker/prj1044-dashboard-tile-sticker';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'Prj1042DashboardButtons',
  components: {
    Prj1044DashboardTileSticker
  },
  mixins: [
    mxDetectDesktop
  ],

  data() {
    return {
      APP_DESTINATIONS,
      dashboardEmptyButtons: DASHBOARD_EMPTY_BUTTONS,
      showGroupMenu: false,
      groupMenu: false,
      contextContainerPositions: false,
      expandedTiles: false,
      heightExpandedBoxTiles: 0,
      widthExpandedBoxTiles: 0,
      leftBackButtonExpandedBoxTiles: 0,
      groupTileCount: 0,
      tileCount: 0,
      tileVisibleLimitDesktopDefault: 6,
      tileVisibleLimitMobileDefault: 3,
      navbarHeight: 70,
      tileSpace: 20,
      tileHeight: 100,
      visibleCounter: 0
    }
  },

  computed: {
    ...mapState('general', [
      'appDestination',
      'dashboardButtons',
      'dashboardGroupButtons',
      'dashboardButtonsExpanded',
      'dashboardButtonsExpandedWidth',
      'viewportSize',
      'navigationDataLoaded',
    ]),

    tileLoadingClassObject() {
      return {
        'vue-is-loading': !this.navigationDataLoaded,
      }
    },

    getDashboardButtons() {
      return this.navigationDataLoaded ? this.orderedDashboardButtons : this.dashboardEmptyButtons;
    },

    orderedDashboardButtons() {
      const buttonsArray = [...this.dashboardButtons];
      const uniqueButtonsArray = buttonsArray.filter((thing, index) => {
        if (thing.privateOnly === '0' || (thing.privateOnly === '1' && this.appDestination === APP_DESTINATIONS.PRIVATE)) {
          const _thing = JSON.stringify(thing);
          return index === buttonsArray.findIndex(obj => {
            return JSON.stringify(obj) === _thing;
          });
        }
      });

      return uniqueButtonsArray.sort((a, b) => parseInt(a.orderingDashboard) - parseInt(b.orderingDashboard));
    },

    classObject() {
      return {
        'vue-is-expanded': this.expandedTiles,
        'vue-is-desktop': this.isDesktopLayout,
        'vue-is-mobile': !this.isDesktopLayout,
      }
    },

    styleObject() {
      return {
        'height': this.isDesktopLayout ? '100%' : (this.expandedTiles ? this.heightExpandedBoxTiles + 'px' : '160px'),
        'width': !this.isDesktopLayout ? '100%' : (this.expandedTiles ? this.widthExpandedBoxTiles + 'px' : '170px'),
      };
    },

    appHeight() {
      return this.viewportSize.height;
    },

    tileVisibleLimitDefault() {
      return this.isDesktopLayout ? this.tileVisibleLimitDesktopDefault : this.tileVisibleLimitMobileDefault;
    },

    expandButtonVisible() {
      return this.tileCount + this.groupTileCount > this.tileVisibleLimitDefault;
    }
  },

  watch: {
    getDashboardButtons(value) {
      this.tileCount = Object.keys(value).length;
      this.counterTiles();
    },
    dashboardGroupButtons(value) {
      this.groupTileCount = Object.keys(value).length;
      this.counterTiles();
    },
    appHeight() {
      this.counterTiles();
    }
  },

  mounted() {
    this.counterTiles();

    this.expandedTiles = this.dashboardButtonsExpanded;
    this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_BUTTONS_EXPANDED, this.expandedTiles);
    this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_BUTTONS_EXPANDED_WIDTH, this.expandedTiles ? this.leftBackButtonExpandedBoxTiles : 170);
  },

  methods: {
    generateTileIcon(item) {
      if (item.cssClass.length > 0 && !item.iconCustom.length > 0) {
        return item.cssClass;
      }

      else if (item.iconCustom.length > 0) {
        return 'vue-icon-custom';
      }

      return 'vue-icon-' + item.iconDashboard;
    },

    generateTileIconBackground(item) {
      if (item.iconCustom.length > 0) {
        return {
          'background-image': `url(${envConfig.default.webServices.BASE_CMS_URL}${item.iconCustom})`
        }
      }
    },

    goToRoute(routeName) {
      const routeToGo = {};

      if (routeName.startsWith('/')) {
        routeToGo.path = routeName;
      } else {
        routeToGo.name = routeName;
      }

      if (!this.categoryId && this.postId) {
        routeToGo.params = { id: this.postId };
      }

      else if(this.categoryId) {
        routeToGo.query = { categoryId: this.categoryId };
      }

      router.push(routeToGo);
    },

    groupButtonClickEvent(group, event) {
      this.groupMenu = group;
      this.showGroupMenu = true;

      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;

      if (this.isDesktopLayout) {
        this.contextContainerPositions = {
          x: event.pageX,
          y: event.pageY,
          mode: 'top-left'
        }
      } else {
        this.contextContainerPositions = false
      }
    },

    toggleButton() {
      this.expandedTiles = !this.expandedTiles;
      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_BUTTONS_EXPANDED, this.expandedTiles);
      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_BUTTONS_EXPANDED_WIDTH, this.expandedTiles ? this.leftBackButtonExpandedBoxTiles : 170);
    },

    counterTiles() {
      this.tileCount = Object.keys(this.getDashboardButtons).length;
      this.groupTileCount = Object.keys(this.dashboardGroupButtons).length;

      if (this.isDesktopLayout) {
        const containerHeight = parseInt(this.$refs.dashboardButtons.clientHeight) + this.tileSpace;
        this.tileVisibleLimitDesktopDefault =  Math.floor(containerHeight / (this.tileHeight + this.tileSpace));

        let division = parseInt((this.tileCount + this.groupTileCount) / this.tileVisibleLimitDesktopDefault);
        let modulo = (this.tileCount + this.groupTileCount) % this.tileVisibleLimitDesktopDefault;
        if (modulo > 0) {
          division++;
        }
        this.widthExpandedBoxTiles = division * 120 + 50;
        this.leftBackButtonExpandedBoxTiles = division * 120 + 65;
      } else {
        let division = parseInt((this.tileCount + this.groupTileCount) / this.tileVisibleLimitMobileDefault);
        let modulo = (this.tileCount + this.groupTileCount) % this.tileVisibleLimitMobileDefault;
        if (modulo > 0) {
          division++;
        }
        this.heightExpandedBoxTiles = division * 120 + 35;
      }
    },

    setFloatedTile(value) {
      return {
        'vue-is-floated': (value + 1 + this.groupTileCount) > this.tileVisibleLimitDefault
      }
    }
  }
}
</script>
