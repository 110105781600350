//============ GENERAL =================================//
//======================================================//
import { SET_INTRO_APP_SEEN } from '@/store/constants/fest/mutations';

//============ STATE ===================================//
//======================================================//
const state = {
  introApp: {
    timestamp: null,
    active: true,
    seen: false
  }
};

//============ MUTATIONS ===============================//
//======================================================//
const mutations = {
  [SET_INTRO_APP_SEEN](state, payload) {
    let date = new Date();
    let timestamp = Number(date);

    state.introApp.seen = payload;
    state.introApp.timestamp = timestamp;
  }
};

//============ ACTIONS =================================//
//======================================================//

const actions = {}

//============ GETTERS =================================//
//======================================================//

const getters = {}

//============ EXPORT ==================================//
//======================================================//
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
