<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout
        classNames="bg-emerald-500"
        :inverse-scrollbar="true"
      >
        <template v-slot:content>
          <div class="prj-layout-default h-full">
            <t-tag
              variant="sectionTitle"
              class="text-white mt-8 xs:mt-12 lg:mt-20"
            >
              {{ $t("esg.about.heroStart") }}
              <t-tag
                variant="highlightInverse"
              >
                {{ $t("esg.about.heroEnd") }}
              </t-tag>
            </t-tag>

            <!--========== ARTICLE BODY ===========================-->
            <!--===================================================-->
            <div
              v-if="aboutPage"
              class="text-white prj-article prj-article-inverse"
            >
              <div
                class="mb-4"
                v-html="aboutContent"
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="prj-controls">
            <t-button
              variant="primaryAlt"
              class="m-auto"
              @click="toDashboard"
            >
              {{ $t("esg.about.buttonNext") }}
            </t-button>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as MUTATIONS_CONSTANTS from '@/store/constants/esg/mutations';
import * as STORE_MODULES from '@/store/store-modules';
import { GET_APP_CONFIG } from '@/store/constants/esg/actions';

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import { ROUTE_DASHBOARD } from '@/constants/esg/route-names'

import PageLayout from '@/templates/esg/page-layout';
import utilsGeneral from '@/utils/esg/utils-general';
import apiCmsContent from '@/esg-api/cms-content';
import logger from '@/utils/logger';

export default {
  name: 'ESGAbout',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {};
  },
  computed: {
    ...mapState('esgConfig', ['aboutPage']),

    aboutTitle() {
      return this.aboutPage?.page?.post_title
    },

    aboutContent() {
      return this.aboutPage?.page?.post_content
    },
  },
  created() {
    // temp condition disabled
    //if (!this.aboutPage) this.GET_APP_CONFIG()
    this.GET_APP_CONFIG()
  },
  methods: {
    ...mapActions('esgConfig', [GET_APP_CONFIG]),

    getPageById(id) {
      let processId = utilsGeneral.startAsyncProcess();

      apiCmsContent
        .getPageById(id)
        .then(response => {
          this.article = response;
        })
        .catch(error => {
          logger.error(error);
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId);
        });
    },
    introPageSeen() {
      this.$store.commit(STORE_MODULES.ESG_INTRO + '/' + MUTATIONS_CONSTANTS.SET_INTRO_APP_SEEN, true);
    },
    toDashboard() {
      this.introPageSeen();
      this.$router.push({name: ROUTE_DASHBOARD});
    }
  }
};
</script>

