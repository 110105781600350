<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div class="prj-c-esg-badge flex text-xs md:text-sm leading-4 font-medium">
    <!--  Icon and Category  -->
    <span
      v-if="hasCategoryIcon"
      class="flex items-center mr-1"
      :class="getItemClasses(hasCategoryTitle)"
    >
      <!-- Icon -->
      <span
        :class="iconClasses"
        v-html="categoryBadge"
      ></span>
      <span
        class="whitespace-nowrap"
        :class="hasCategoryTitle ? 'ml-2' : 'sr-only'"
      >{{ categoryTitle }}</span>
    </span>

    <!--  Hash and Theme  -->
    <span
      v-if="hasCategoryHash && themeTitle"
      class="flex items-center ml-1"
      :class="getItemClasses(themeTitle)"
    >
      <!-- Icon -->
      <span
        class="mr-2"
        :class="iconClasses"
        v-html="categoryHash"
      ></span>
      <span
        v-if="themeTitle"
        class="whitespace-nowrap"
      >{{ themeTitle }} </span>
    </span>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
/**
 * ESG Badge
 * UI component describes Category and Theme of its parent
 *
 * @description
 * Two main visual modes: 1. With coloured background
 *                        2. Plain text - transparent background
 *
 * Three main contextual modes: 1. Icon with/without category title
 *                              2. Hash tag with/without theme title
 *                              3. Icon with category title followed by hash ant theme title
 */
//============ IMPORTS =================================//
//======================================================//
import categoryConfig from '@/assets/esg/categories/config'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1018EsgBadge',
  props: {
    categoryId: {
      type: Number,
      default: null,
    },
    hasCategoryTitle: {
      type: Boolean,
      default: false,
    },
    hasCategoryIcon: {
      type: Boolean,
      default: false,
    },
    hasCategoryHash: {
      type: Boolean,
      default: false,
    },
    themeTitle: {
      type: String,
      default: null,
    },
    hasBg: {
      type: Boolean,
      default: false,
    },
    iconClasses: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      categoryConfig,
    }
  },
  computed: {
    categorySettings() {
      return this.categoryConfig?.find((item) => item.id === this.categoryId)
    },
    categoryBadge() {
      return this.categorySettings?.svg.icon
    },
    categoryHash() {
      return this.hasBg ? this.categorySettings?.svg.transparentHash : this.categorySettings?.svg.hash
    },
    categoryTitle() {
      return this.categorySettings.title
    },
  },
  methods: {
    getItemClasses(caption) {
      return this.hasBg
        ? `${this.categorySettings?.bgCol} text-white tracking-wider py-1 rounded-full ${caption ? 'px-2.5' : 'px-1'}`
        : `${this.categorySettings?.txtCol}`
    },
  },
}
</script>
