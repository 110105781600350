//============ GENERAL =================================//
//======================================================//
import { SET_THEMES_INFO } from '@/store/constants/fest/mutations'
import { GET_THEMES_INFO } from '@/store/constants/fest/actions'

//============ API =====================================//
//======================================================//
import apiCmsThemes from '@/fest-api/cms-themes'
import logger from '@/utils/logger'

//============ STATE ===================================//
//======================================================//
const state = {
  themesInfo: [],
  currentTheme: null,
}

//============ MUTATIONS ===============================//
//======================================================//
const mutations = {
  [SET_THEMES_INFO](state, payload) {
    state.themesInfo = [...payload]
    state.currentTheme = payload.find(theme => theme.isActual)
  },
}

//============ ACTIONS =================================//
//======================================================//

const actions = {
  async [GET_THEMES_INFO]({ commit }) {
    try {
      const response = await apiCmsThemes.getAllThemes()
      commit(SET_THEMES_INFO, response)
    } catch (error) {
      logger.error(error)
    }
  }
}

//============ GETTERS =================================//
//======================================================//

const getters = {}

//============ EXPORT ==================================//
//======================================================//
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
