<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :button-back-type="'none'"
        @activeView="buttonBackEvent"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        id="vue-m-strategy"
        ref="strategyContainer"
        :class="classObject"
        class="vue-strategy-page-layout"
      >
        <button
          v-if="isIntroOpen"
          class="vue-lang"
          :class="flagClassObject"
          @click="langClick()"
        />

        <div class="vue-intro-content">
          <transition
            name="vue-anim-strategy-expand-point"
            appear
          >
            <div
              v-if="isIntroOpen"
              class="vue-intro-illustration vue-intro-illustration-home-point"
            />
          </transition>


          <transition
            name="vue-anim-strategy-move-from-right"
            appear
          >
            <prj1017-heading
              v-if="isIntroOpen"
              v-transition-delay-enter="150"
              :header="$t('strategy.headline')"
              :subheader="$t('strategy.subHeader') + '\n' + $t('strategy.subHeaderSecond')"
              class="vue-strategy-container"
            />
          </transition>

          <transition
            name="vue-anim-strategy-move-from-right"
            appear
          >
            <frm1031-button-container
              v-if="isIntroOpen"
              class="vue-strategy-buttons vue-strategy-container"
            >
              <frm1006-button
                class="vue-button"
                type="primary"
                @buttonClickEvent="currentObjectivesClick"
              >
                {{ $t('strategy.buttonCurrentObjectives') }}
              </frm1006-button>

              <frm1006-button
                class="vue-button"
                type="primary"
                @buttonClickEvent="toMenuClick"
              >
                {{ $t('strategy.buttonGoals') }}
              </frm1006-button>

              <frm1006-button
                v-if="strategyQuizActive"
                class="vue-button"
                type="primary"
                @buttonClickEvent="toQuizClick"
              >
                {{ $t('strategy.buttonQuiz') }}
              </frm1006-button>

              <frm1006-button
                class="vue-button"
                type="primary"
                @buttonClickEvent="groupStrategyClick"
              >
                {{ $t('strategy.buttonGroupStrategy') }}
              </frm1006-button>
            </frm1031-button-container>
          </transition>
        </div>

        <template v-if="!isMenuActive">
          <nav
            class="vue-navigation"
          >
            <transition
              name="vue-anim-strategy-expand-point"
              appear
            >
              <div class="vue-navigation-illustration vue-navigation-illustration-home-point" />
            </transition>

            <transition
              name="vue-anim-strategy-move-from-right"
              appear
            >
              <prj1017-heading
                :header="strategyNav.title"
              />
            </transition>

            <div class="vue-navigation-container">
              <transition
                name="vue-anim-strategy-move-from-right"
                appear
              >
                <div class="vue-navigation-wrap">
                  <frm1006-button
                    v-for="(navItem, index) in strategyNav['items']"
                    :key="index"
                    :icon-right="true"
                    class="vue-control-item"
                    :class="`vue-control-item-bg-${navItem.color}`"
                    type="internal"
                    @buttonClickEvent="tileClick(index)"
                  >
                    <span>{{ navItem.title }}</span>
                  </frm1006-button>
                </div>
              </transition>
            </div>
          </nav>
        </template>

        <template v-if="audienceOpen && !currentObjectivesOpen && !strategyGroupOpen">
          <div class="vue-audience">
            <transition
              name="vue-anim-strategy-move-from-right"
              appear
            >
              <prj1017-heading
                :header="$t('strategy.audienceHeader')"
              />
            </transition>

            <transition
              name="vue-anim-strategy-audience-total"
              appear
            >
              <div
                class="vue-audience-total"
              >
                <span class="vue-audience-total-number">{{ audienceTotalNumber }}</span>
                <span class="vue-audience-total-text">{{ audienceContent.subtitle }}</span>
              </div>
            </transition>

            <transition
              name="vue-anim-strategy-move-from-top"
              appear
            >
              <div
                v-transition-delay-enter="300"
                class="vue-audience-list"
              >
                <div
                  v-for="(item, index) in audienceContent['items']"
                  :key="index"
                  class="vue-audience-list-item"
                >
                  <span class="vue-audience-list-value">{{ item.value }}</span>
                  <span>{{ item.description }}</span>
                </div>
              </div>
            </transition>
          </div>
        </template>

        <template v-if="currentObjectivesOpen && !audienceOpen && !strategyGroupOpen">
          <div class="vue-audience">
            <transition
              name="vue-anim-strategy-move-from-right"
              appear
            >
              <prj1017-heading
                :header="currentObjectives.title"
              />
            </transition>
            <transition
              name="vue-anim-strategy-move-from-top"
              appear
            >
              <div
                v-transition-delay-enter="300"
                class="vue-audience-list"
              >
                <div
                  v-for="(item, index) in currentObjectives['items']"
                  :key="index"
                  class="vue-audience-list-item"
                >
                  <frm1006-button
                    :type="'internal'"
                    class="vue-audience-list-value"
                    @buttonClickEvent="routerDetailLink(item.detailId, index)"
                  >
                    {{ item.value }}
                  </frm1006-button>
                  <span
                    v-html="item.description"
                  />

                  <!--======= OBJECTIVE - DIGITAL =============-->
                  <!--=========================================-->
                  <div
                    v-if="index == 1"
                    class="vue-objective-visualisation vue-objective-illustration vue-is-fill-mobile"
                  >
                    <div class="vue-mobile vue-is-10">
                      <span class="vue-control">
                        <span class="vue-control-text">{{ item.attachmentData.current }}</span>
                      </span>
                      <div class="vue-mobile-item" />
                      <span class="vue-legend-from">{{ item.attachmentData.start }}</span>
                      <span class="vue-legend-to">{{ item.attachmentData.end }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </template>

        <template v-if="strategyGroupOpen && !audienceOpen && !currentObjectivesOpen">
          <div class="vue-audience vue-strategy-group">
            <div class="vue-strategy-group-wrap">
              <transition
                name="vue-anim-strategy-move-from-right"
                appear
              >
                <prj1017-heading
                  :header="strategyGroup.title"
                  :subheader="strategyGroup.subTitle"
                />
              </transition>

              <transition
                name="vue-anim-strategy-move-from-top"
                appear
              >
                <div
                  v-transition-delay-enter="300"
                  class="vue-audience-list"
                >
                  <div class="vue-strategy-group-intro">
                    <div class="vue-strategy-group-intro-image"></div>
                  </div>
                  <div
                    v-for="(item, index) in strategyGroup['items']"
                    :key="index"
                    class="vue-audience-list-item"
                  >
                    <div class="vue-audience-list-value">{{ item.value }}</div>
                    <div>{{ item.description }}</div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </template>
      </div>
    </template>
  </page-layout>
</template>

<script>

import * as STRATEGY from '@/assets/mocks/strategyData';
import * as STRATEGY_AUDIENCE from '@/assets/mocks/strategyAudienceData';
import * as STRATEGY_CURRENTt_OBECTIVES from '@/assets/mocks/strategyCurrentObjectivesData';
import * as STRATEGY_GROUP from '@/assets/mocks/strategyGroupData';
import router from '@/router';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import { mapState } from 'vuex';
import { STRATEGY_KEY_PREFIX } from '@/constants/app-constants';
import utilsGeneral from '@/utils/utils-general';

export default {
  name: 'Strategy',
  components: {
    Prj1002Navbar,
    Prj1017Heading,
    PageLayout
  },

  mixins: [
    mxNavigationDefault,
  ],

  data() {
    return {
      contentClass: 'vue-page-content',
      strategyData: STRATEGY.DATA_ARRAY.slides,
      navigationOpened: false,
      menuActive: false,
      audienceOpen: false,
      audienceData: STRATEGY_AUDIENCE.DATA_ARRAY.audience,
      currentObjectivesData: STRATEGY_CURRENTt_OBECTIVES.DATA_ARRAY.currentObjectives,
      strategyGroupData: STRATEGY_GROUP.DATA_ARRAY.strategyGroup,
      currentObjectivesOpen: false,
      strategyGroupOpen: false,
      audienceTotalNumber: 0
    }
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),

    isEnglish(){
      if(this.$i18n.locale === 'en'){
        return true;
      } else {
        return false;
      }
    },

    classObject() {
      return [
        'vue-is-' + this.$i18n.locale,
        {
          'vue-is-nav-opened': !this.isMenuActive,
          'vue-is-audience': this.audienceOpen,
          'vue-is-strategy-group': this.strategyGroupOpen
        }
      ];
    },

    flagClassObject() {
      return {
        'vue-is-cs': this.isEnglish,
        'vue-is-en': !this.isEnglish,
      }
    },

    isMenuActive() {
      if (this.menuActive) {
        return false;
      } else {
        return true;
      }
    },

    isIntroOpen() {
      return !this.audienceOpen && !this.menuActive && !this.currentObjectivesOpen && !this.strategyGroupOpen;
    },

    strategyNav() {
      let lang = this.$i18n.locale;
      if( Object.prototype.hasOwnProperty.call(this.strategyData[0], lang) ) {
        return this.strategyData[0][lang];
      }
      return this.strategyData[0]['en'];
    },

    audienceContent() {
      let lang = this.$i18n.locale;
      if( Object.prototype.hasOwnProperty.call(this.audienceData[0], lang) ) {
        return this.audienceData[0][lang];
      }
      return this.audienceData[0]['en'];
    },

    currentObjectives() {
      let lang = this.$i18n.locale;
      if( Object.prototype.hasOwnProperty.call(this.currentObjectivesData[0], lang) ) {
        return this.currentObjectivesData[0][lang];
      }
      return this.currentObjectivesData[0]['en'];
    },

    strategyGroup() {
      let lang = this.$i18n.locale;
      if( Object.prototype.hasOwnProperty.call(this.strategyGroupData[0], lang) ) {
        return this.strategyGroupData[0][lang];
      }
      return this.strategyGroupData[0]['en'];
    },

    strategyConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(STRATEGY_KEY_PREFIX)) {
            const itemNameWithoutPrefix = item.key.replace(STRATEGY_KEY_PREFIX, '');
            const itemName = utilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },

    strategyQuizActive() {
      return (typeof this.strategyConfig.quizActive !== 'undefined' ? this.strategyConfig.quizActive : false);
    },
  },

  watch: {
    audienceOpen(value) {
      if (value) {
        let audienceTitle = this.audienceContent.title.replace(',', '.');
        let audienceNumber = Number(audienceTitle) * 10;

        this.numberAnimateCounter(audienceNumber);
      }
    }
  },

  mounted() {
    this.checkUrl();
  },

  methods: {
    checkUrl() {
      if(this.$route.params.nav === undefined) {
        this.$route.params.nav = 'closed';
      }
      else {
        if (this.$route.params.nav === 'ambition') {
          this.menuActive = true;
        }
        else if (this.$route.params.nav === 'audience') {
          this.audienceOpen = true;
        }
        else if (this.$route.params.nav === 'current-objectives') {
          this.currentObjectivesOpen = true;
        }
        else {
          this.menuActive = false;
          this.audienceOpen = false;
          this.currentObjectivesOpen = false;
        }
      }
    },

    tileClick(tileId) {
      router.push({ name: 'strategy-detail', params: { id: tileId }});
    },

    toMenuClick() {
      if (this.menuActive) {
        this.menuActive = false;
        this.routerLink('closed');
      } else {
        this.menuActive = true;
        this.routerLink('ambition');
      }
    },

    currentObjectivesClick() {
      if (this.currentObjectivesOpen) {
        this.currentObjectivesOpen = false;
        this.routerLink('closed');
      } else {
        this.currentObjectivesOpen = true;
        this.routerLink('current-objectives');
      }
    },

    groupStrategyClick() {
      if (this.strategyGroupOpen) {
        this.currentObjectivesOpen = false;
        this.strategyGroupOpen = false;
        this.audienceOpen = false;
        this.routerLink('closed');
      } else {
        this.strategyGroupOpen = true;
        this.audienceOpen = false;
        this.currentObjectivesOpen = false;
        this.routerLink('group-strategy');
      }
    },

    toQuizClick() {
      router.push({ name: 'strategy-competition-intro'});
    },

    routerLink(param) {
      router.push({ name: 'strategy', params: { nav: param }});
    },

    routerDetailLink(param, index) {
      if (!param) return;
      if (index == 1) return;
      router.push({ name: 'strategy-detail', params: { id: param }});
    },

    langClick() {
      if(this.isEnglish) {
        this.$i18n.locale = 'cs'
      } else {
        this.$i18n.locale = 'en'
      }
    },

    buttonCompetitionStart() {
      router.push({ name: 'strategy-competition'});
    },

    buttonBackEvent() {
      if(this.$route.params.nav === 'ambition' || this.$route.params.nav === 'quiz' || this.$route.params.nav === 'audience' || this.$route.params.nav === 'current-objectives' || this.$route.params.nav === 'group-strategy') {

        this.menuActive = false;
        this.audienceOpen = false;
        this.currentObjectivesOpen = false;
        this.strategyGroupOpen = false;
        this.routerLink('closed');

      } else {
        router.push({ name: 'dashboard'});
      }
    },

    numberAnimateCounter(number) {
      const duration = 2000;
      const start = 0;
      const endAudienceTotal = number;

      let numberTemp = 0;

      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);

        numberTemp = Math.floor(progress * (endAudienceTotal - start) + start) / 10;

        if (progress < 1) {
          window.requestAnimationFrame(step);
        }

        this.audienceTotalNumber = this.convertAudienceNumberToString(numberTemp);
      };

      window.requestAnimationFrame(step);
    },

    convertAudienceNumberToString(number) {
      let numberToDecimal = number.toFixed(1);
      let numberStringed = numberToDecimal.toString()

      return numberStringed.replace('.', ',')
    }
  }
}
</script>
