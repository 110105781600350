import utilsGeneral from '../../../utils/utils-general';
import client from '../../../utils/data-loader/index';
import logger from '../../../utils/logger';
import utils from './utils';

import * as ACTIONS_CONSTANTS from '../../constants/actions';
import * as MUTATIONS_CONSTANTS from '../../constants/mutations';
import RestaurantConverter from '@/utils/data-loader/restaurant-converter';

const generalCategories = ['news', 'importantNews', 'services', 'pages'];
const generalCategoriesWithTips = ['news', 'importantNews', 'services', 'pages', 'tips'];
let restaurantsCategories = ['cafeteriaNHQ', 'cafeteriaSHQ', 'cafeteriaHHQ', 'cafeteriaPHQ'];
let categoryTips = 'tips';
let generalCategoriesArray = [];
let restaurantCategoriesArray = [];
let allItemIdsToFetch = [];
let allItemIdsToDelete = [];

export default {
  [ACTIONS_CONSTANTS.GET_PREFETCH_CATEGORIES]({ state, commit, dispatch }, data) {
    if (typeof this.state?.user?.publicKey === 'undefined') {
      return false;
    }
    if (this.state?.user?.publicKey === '') {
      return false;
    }
    if (this.state?.dashboard?.selectedBuilding === '') {
      return false;
    }
    return client
      .fetchDataPrefetchInfo(data, this.state.dashboard.selectedBuilding, this.state.articles.newsActiveCategory)
      .then(dataPrefetchInfo => {
        logger.info('BEGIN GLOBAL DATA FETCH');
        logger.info('1. PREFETCH INFO');
        logger.info(['PREFETCHED INFO:', dataPrefetchInfo]);

        if (typeof dataPrefetchInfo !== 'object') {
          if (dataPrefetchInfo === 'ok') {
            throw Error('Wrong data prefetch info format - not authenticated.');
          } else {
            throw Error('Wrong data prefetch info format.');
          }
        }

        if ((Object.keys(dataPrefetchInfo).length === 0)) {
          throw Error('Data prefetch info empty response.')
        }

        let categories = [];

        generalCategoriesArray = utils.processSlugs(generalCategories, state, 'general');
        restaurantCategoriesArray = utils.processSlugs(restaurantsCategories, state, 'restaurants');

        for (let categoryId in dataPrefetchInfo) {
          if (generalCategoriesArray.indexOf(categoryId) > -1) {
            let processedCategory = utils.processCategories(generalCategories, generalCategoriesArray, categoryId, dataPrefetchInfo, state, 'general');

            if (processedCategory != null) {
              categories.push(processedCategory);
            }
          }

          if (restaurantCategoriesArray.indexOf(categoryId) > -1) {
            let processedRestaurantCategory = utils.processCategories(restaurantsCategories, restaurantCategoriesArray, categoryId, dataPrefetchInfo, state, 'restaurants');

            if (processedRestaurantCategory != null) {
              categories.push(processedRestaurantCategory);
            }
          }

          if (categoryId === categoryTips) {
            let categoryIdData = categoryTips;
            let categoryIdApi = state.contentData[categoryIdData].slug;

            if (dataPrefetchInfo[categoryIdApi].modifiedTimestamp !== state.contentData[categoryIdData].lastUpdate.timestamp) {
              if (state.contentData[categoryIdData].shownLast.timestampCreated) {
                let tipsSince = state.contentData[categoryIdData].slug + '.' + (state.contentData[categoryIdData].shownLast.timestampCreated + 1);
                categories.push(tipsSince);
              } else {
                categories.push(categoryIdApi);
              }
            }
          }
        }

        if (categories.length > 0) {
          commit(MUTATIONS_CONSTANTS.SET_CONTENT_DATA_UPDATE, {inProgress: true, stage: 'prefetch-item-ids', dataPrefetchInfo: dataPrefetchInfo});

          let categoriesParam = categories.join(';');
          let slugModeParam = 'cafeteria_nhq;cafeteria_shq;cafeteria_hhq';
          logger.info(['Categories param', categoriesParam]);

          return dispatch(ACTIONS_CONSTANTS.HANDLE_ARTICLES_IDS, [categoriesParam, slugModeParam, this.state.dashboard.selectedBuilding, this.state.articles.newsActiveCategory]);
        } else {
          commit(MUTATIONS_CONSTANTS.SET_CONTENT_DATA_UPDATE, {inProgress: false, stage: 'idle'});
        }

        logger.info(['List of categories changed manifest:', categories]);
      }, error => {throw Error('Fetch failed - data prefetch info: ' + error)});
  },

  [ACTIONS_CONSTANTS.HANDLE_ARTICLES_IDS]({state, dispatch}, data) {
    return client
      .fetchDataPrefetchItemIdsByCategories(data[0], data[1], data[2], data[3])
      .then(dataPrefetchItemIdsByCategories => {
        logger.info('2. PREFETCH ITEM IDS');
        logger.info(['Item IDs', dataPrefetchItemIdsByCategories]);

        if (typeof dataPrefetchItemIdsByCategories !== 'object') {
          if (dataPrefetchItemIdsByCategories === 'ok') {
            throw Error('Wrong data prefetch item IDs by categories format - not authenticated.');
          } else {
            throw Error('Wrong data prefetch item IDs by categories format.');
          }
        }

        if ((Object.keys(dataPrefetchItemIdsByCategories).length === 0)) {
          throw Error('Data prefetch item IDs by categories info empty response.');
        }

        if (Object.keys(dataPrefetchItemIdsByCategories).length > 0) {
          generalCategoriesArray = utils.processSlugs(generalCategoriesWithTips, state, 'general');
          restaurantCategoriesArray = utils.processSlugs(restaurantsCategories, state, 'restaurants');

          let yearAndWeekCurrent = utilsGeneral.yearAndWeekCurrent();

          for (let categoryId in dataPrefetchItemIdsByCategories) {
            if(!state.contentData.restaurants || !state.contentData.restaurants.facilities) {
              // missing data, nothing to do
              break;
            }

            if (generalCategoriesArray.indexOf(categoryId) > -1) {
              let categoryIdData = generalCategoriesWithTips[generalCategoriesArray.indexOf(categoryId)];
              let categoryIdApi = state.contentData[categoryIdData].slug;

              for (let itemId in dataPrefetchItemIdsByCategories[categoryIdApi]) {

                if (state.contentData[categoryIdData].data[itemId]) {
                  let dateModifiedPrefetch = dataPrefetchItemIdsByCategories[categoryIdApi][itemId].modifiedTimestamp;
                  let dateModifiedData = state.contentData[categoryIdData].data[itemId].modifiedTimestamp;

                  if (dateModifiedPrefetch !== dateModifiedData) {
                    allItemIdsToFetch.push(itemId);
                  }
                } else {
                  allItemIdsToFetch.push(itemId);
                }
              }

              for (let itemId in state.contentData[categoryIdData].data) {
                if (dataPrefetchItemIdsByCategories[categoryIdApi][itemId] === undefined) {
                  allItemIdsToDelete.push(itemId);
                }
              }
            }

            if (restaurantCategoriesArray.indexOf(categoryId) > -1) {
              let categoryIdData = restaurantsCategories[restaurantCategoriesArray.indexOf(categoryId)];
              let categoryIdApi = state.contentData.restaurants.facilities[categoryIdData].slug;

              for (let itemId in dataPrefetchItemIdsByCategories[categoryIdApi]) {
                let itemYearAndWeek = utilsGeneral.yearAndWeekSplit(itemId);
                let itemYearAndWeekIsEqualOrHigherThanCurrent = (itemYearAndWeek.year > yearAndWeekCurrent.year) || ((itemYearAndWeek.year === yearAndWeekCurrent.year) && (itemYearAndWeek.week >= yearAndWeekCurrent.week));

                if (state.contentData.restaurants.facilities[categoryIdData].itemId) {
                  let dateModifiedPrefetch = dataPrefetchItemIdsByCategories[categoryIdApi][itemId].modifiedTimestamp;
                  let dateModifiedData = state.contentData.restaurants.facilities[categoryIdData].data[itemId].modifiedTimestamp;

                  if (dateModifiedPrefetch !== dateModifiedData) {
                    if (state.contentData.restaurants.facilities[categoryIdData].menuPeriod === 'week' && itemYearAndWeekIsEqualOrHigherThanCurrent) {
                      allItemIdsToFetch.push(dataPrefetchItemIdsByCategories[categoryIdApi][itemId].id);
                    } else {
                      allItemIdsToFetch.push(dataPrefetchItemIdsByCategories[categoryIdApi][itemId].id);
                    }
                  }
                } else {
                  if (state.contentData.restaurants.facilities[categoryIdData].menuPeriod === 'week' && itemYearAndWeekIsEqualOrHigherThanCurrent) {
                    allItemIdsToFetch.push(dataPrefetchItemIdsByCategories[categoryIdApi][itemId].id);
                  } else {
                    allItemIdsToFetch.push(dataPrefetchItemIdsByCategories[categoryIdApi][itemId].id);
                  }
                }
              }

              for (let itemId in state.contentData.restaurants.facilities[categoryIdData].data) {
                if (dataPrefetchItemIdsByCategories[categoryIdApi][itemId] === undefined) {
                  allItemIdsToDelete.push(itemId);
                }
              }
            }
          }
        }

        return dispatch(ACTIONS_CONSTANTS.FETCH_ARTICLES, dataPrefetchItemIdsByCategories);
      }, error => {throw Error('Fetch failed - data prefetch item IDs by categories: ' + error)});
  },

  [ACTIONS_CONSTANTS.FETCH_ARTICLES]({commit, state, dispatch}, dataPrefetchItemIdsByCategories) {
    logger.info('3. GET ITEMS DATA');

    if (allItemIdsToFetch.length > 0 || allItemIdsToDelete.length > 0) {
      commit(MUTATIONS_CONSTANTS.SET_CONTENT_DATA_UPDATE, {inProgress: true, stage: 'get-items-data'});

      logger.info(['allItemIdsToFetch:', allItemIdsToFetch]);
      logger.info(['allItemIdsToDelete:', allItemIdsToDelete]);

      if (allItemIdsToFetch.length === 0) {
        let categoriesGeneralMutations = {
          news: 'news',
          importantNews: 'importantNews',
          services: 'services',
          pages: 'pages',
          tips: 'tips'
        };

        generalCategoriesArray = utils.processSlugs(generalCategoriesWithTips, state, 'general');
        restaurantCategoriesArray = utils.processSlugs(restaurantsCategories, state, 'restaurants');

        for (let categoryId in dataPrefetchItemIdsByCategories) {
          let itemsToDelete = [];

          if (generalCategoriesArray.indexOf(categoryId) > -1) {
            let categoryIdData = generalCategories[generalCategoriesArray.indexOf(categoryId)];
            let mutation = categoriesGeneralMutations[categoryIdData];

            for (let itemId in state.contentData[categoryIdData].data) {
              if (allItemIdsToDelete.indexOf(itemId) > -1) {
                itemsToDelete.push(itemId);
              }
            }

            commit(MUTATIONS_CONSTANTS.UPDATE_ARTICLES, {items: {itemsToDelete: itemsToDelete}, type: mutation});
          }

          if (restaurantCategoriesArray.indexOf(categoryId) > -1) {
            let categoryIdData = restaurantsCategories[restaurantCategoriesArray.indexOf(categoryId)];

            for (let itemId in itemsToDelete) {
              let itemSlug = itemsToDelete[itemId].slug;

              itemsToDelete[itemSlug] = itemsToDelete[itemId];
              delete itemsToDelete[itemId];
            }

            for (let itemId in state.contentData.restaurants.facilities[categoryIdData].data) {
              if (allItemIdsToDelete.indexOf(itemId) > -1) {
                itemsToDelete.push(itemId);
              }
            }

            commit(MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_EXTERNAL, {items: {itemsToDelete: itemsToDelete}, restaurantId: categoryIdData});
          }
        }

        commit(MUTATIONS_CONSTANTS.SET_CONTENT_DATA_UPDATE, {inProgress: false, stage: 'idle'});

      } else {
        return dispatch(ACTIONS_CONSTANTS.FETCH_ITEMS_DATA, [allItemIdsToFetch, allItemIdsToDelete]);
      }
    }

    else {
      commit(MUTATIONS_CONSTANTS.SET_CONTENT_DATA_UPDATE, {inProgress: false, stage: 'idle'});
    }
  },

  [ACTIONS_CONSTANTS.FETCH_ITEMS_DATA]({state, commit}, data) {
    return client
      .fetchItemIdsData(data[0].join(','))
      .then(itemIdsDataByCategories => {
        logger.info('4. FETCH ITEMS DATA');
        logger.info(['Items IDs by categories fetched:', itemIdsDataByCategories]);

        if (typeof itemIdsDataByCategories !== 'object') {
          if (itemIdsDataByCategories === 'ok') {
            throw Error('Wrong fetch item IDs data format - not authenticated.');
          } else {
            throw Error('Wrong fetch item IDs data format.');
          }
        }

        if ((Object.keys(itemIdsDataByCategories).length === 0)) {
          throw Error('Fetch item IDs empty response.')
        }

        let categoriesGeneralMutations = {
          news: 'news',
          importantNews: 'importantNews',
          services: 'services',
          pages: 'pages',
          tips: 'tips'
        };

        generalCategoriesArray = utils.processSlugs(generalCategoriesWithTips, state, 'general');
        restaurantCategoriesArray = utils.processSlugs(restaurantsCategories, state, 'restaurants');

        allItemIdsToDelete = data[1];

        for (let categoryId in itemIdsDataByCategories) {
          let itemsToUpdate = itemIdsDataByCategories[categoryId];
          let itemsToDelete = [];

          if (generalCategoriesArray.includes(categoryId) || generalCategoriesArray.includes(categoryId) && allItemIdsToDelete.length > 0) {
            let categoryIdData = generalCategoriesWithTips[generalCategoriesArray.indexOf(categoryId)];
            let mutation = categoriesGeneralMutations[categoryIdData];

            for (let itemId in state.contentData[categoryIdData].data) {
              if (allItemIdsToDelete.indexOf(itemId) > -1) {
                itemsToDelete.push(itemId);
              }
            }

            commit(MUTATIONS_CONSTANTS.UPDATE_ARTICLES, {items: {itemsToUpdate: itemsToUpdate, itemsToDelete: itemsToDelete}, type: mutation});
          }

          if (restaurantCategoriesArray.includes(categoryId) || restaurantCategoriesArray.includes(categoryId) && allItemIdsToDelete.length > 0) {
            let categoryIdData = restaurantsCategories[restaurantCategoriesArray.indexOf(categoryId)];

            for (let itemId in itemsToUpdate) {
              let itemSlug = itemsToUpdate[itemId].slug;

              itemsToUpdate[itemSlug] = itemsToUpdate[itemId];
              delete itemsToUpdate[itemId];
            }

            for (let itemId in itemsToDelete) {
              let itemSlug = itemsToDelete[itemId].slug;

              itemsToDelete[itemSlug] = itemsToDelete[itemId];
              delete itemsToDelete[itemId];
            }

            for (let itemId in state.contentData.restaurants.facilities[categoryIdData].data) {
              if (allItemIdsToDelete.indexOf(itemId) > -1) {
                itemsToDelete.push(itemId);
              }
            }

            commit(MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS, {items: {itemsToUpdate: itemsToUpdate, itemsToDelete: itemsToDelete}, restaurantId: categoryIdData});
          }
        }

        commit(MUTATIONS_CONSTANTS.SET_CONTENT_DATA_UPDATE, {inProgress: false, stage: 'idle'});
      });
  },

  [ACTIONS_CONSTANTS.FETCH_CAFES]({commit}) {
    return client
      .fetchCafes()
      .then(cafeData => {
        // NHQ CAFE
        if (cafeData.nhqCafe) {
          commit(MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_CAFE, {
            items: cafeData.nhqCafe,
            restaurantId: 'cafeteriaNHQ'
          });
        }

        // SHQ CAFE
        if (cafeData.shqCafe) {
          commit(MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_CAFE, {
            items: cafeData.shqCafe,
            restaurantId: 'cafeteriaSHQ'
          });
        }

        // HHQ CAFE
        if (cafeData.hhqCafe) {
          commit(MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_CAFE, {
            items: cafeData.hhqCafe,
            restaurantId: 'cafeteriaHHQ'
          });
        }

        // PHQ CAFE
        if (cafeData.phqCafe) {
          commit(MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_CAFE, {
            items: cafeData.phqCafe,
            restaurantId: 'cafeteriaPHQ'
          });
        }
      })
  },

  [ACTIONS_CONSTANTS.FETCH_RESTAURANTS]({commit}) {
    return client
      .fetchRestaurants()
      .then(restaurantData => {
        if (typeof restaurantData !== 'object') {
          throw Error('Wrong restaurant data format')
        }

        // NHQ
        const nhqRC = new RestaurantConverter(restaurantData.nhq);
        commit(MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_EXTERNAL, {
          items: {itemsToUpdate: nhqRC.getConvertedData()},
          restaurantId: 'restaurantNHQ'
        });

        // SHQ
        const shqRC = new RestaurantConverter(restaurantData.shq);
        commit(MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_EXTERNAL, {
          items: {itemsToUpdate: shqRC.getConvertedData()},
          restaurantId: 'restaurantSHQ'
        });

        // HHQ
        const hhqRC = new RestaurantConverter(restaurantData.hhq);
        commit(MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_EXTERNAL, {
          items: {itemsToUpdate: hhqRC.getConvertedData()},
          restaurantId: 'restaurantHHQ'
        });

        // PHQ
        const phqRC = new RestaurantConverter(restaurantData.phq);
        commit(MUTATIONS_CONSTANTS.UPDATE_RESTAURANTS_EXTERNAL, {
          items: {itemsToUpdate: phqRC.getConvertedData()},
          restaurantId: 'restaurantPHQ'
        });
      });
  },

  [ACTIONS_CONSTANTS.FETCH_RECOMMENDED_POSTS]({state, commit}) {
    let currentDateTime = new Date();
    let checkInterval = globalConfig.CONTENT_DATA_UPDATE_CHECK_INTERVAL;
    if ((currentDateTime - state.contentData.recommendedNews.lastUpdate.timestamp) < checkInterval) {
      return false;
    }

    return client
      .fetchRecommendedPosts()
      .then(data => {
        commit(MUTATIONS_CONSTANTS.UPDATE_RECOMMENDED_POSTS, {
          items: data,
        });
      });
  },
}
