import Gen1020Image from './gen1020-image';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1020Image);
    }
};

use(Plugin);

export default Plugin;

export { Gen1020Image };
