import * as MUTATIONS_CONSTANTS from '../constants/mutations';

const state = {
  recruitment: {
    filterLocation: null,
    filterSpecialization: null,
    filterType: null,
    filterKeyword: null,
    data: {},
    registries: {},
    detailData: {}
  }
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_RECRUITMENT_FILTER_LOCATION](state, location) {
    state.recruitment.filterLocation = location;
  },

  [MUTATIONS_CONSTANTS.SET_RECRUITMENT_FILTER_SPECIALIZATION](state, specialization) {
    state.recruitment.filterSpecialization = specialization;
  },

  [MUTATIONS_CONSTANTS.SET_RECRUITMENT_FILTER_TYPE](state, type) {
    state.recruitment.filterType = type;
  },

  [MUTATIONS_CONSTANTS.SET_RECRUITMENT_FILTER_KEYWORD](state, keyword) {
    state.recruitment.filterKeyword = keyword;
  },

  [MUTATIONS_CONSTANTS.SET_RECRUITMENT_RESULT_DATA](state, results) {
    state.recruitment.data = results;
  },

  [MUTATIONS_CONSTANTS.SET_RECRUITMENT_REGISTRIES](state, registries) {
    state.recruitment.registries = registries;
  },
  
  [MUTATIONS_CONSTANTS.SET_RECRUITMENT_DETAIL_DATA](state, detailData) {
    state.recruitment.detailData = detailData;
  }
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
