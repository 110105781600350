<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        v-if="initialized && !isLoading"
      >
        <div class="vue-b-education-dashboard">
          <div class="vue-education-header">
            <h1
              class="vue-education-title"
              v-html="dashboardTitle"
            />

            <div class="vue-custom-dashboard-tiles">
              <div
                class="vue-custom-item-tile vue-tile-edukate"
                :class="{'vue-is-disabled': !isPrivateApp}"
              >
                <div class="vue-tile-icon" />
                <div class="vue-b-content">
                  <h2 class="vue-tile-headline">{{ eduKatePage?.title }}</h2>

                  <div class="vue-tile-description">
                    <p v-html="eduKatePage?.content"></p>
                  </div>

                  <a
                    v-if="isPrivateApp"
                    href="https://apps.powerapps.com/play/e/c9d94845-6976-4f01-963d-2fa1f3325890/a/e7571f86-9d1b-4edb-808c-e4c9062c3423?tenantId=64af2aee-7d6c-49ac-a409-192d3fee73b8"
                    target="_blank"
                    class="vue-tile-link-external"
                  >{{ $t('education.edukateButtonText') }}</a>
                  <gen1006-info-panel
                    v-else
                    type="neutral"
                  >
                    <span
                      v-html="$i18n.t('education.privateMessage', { pageId: installationPageId})"
                    />
                  </gen1006-info-panel>
                </div>
              </div>

              <div
                class="vue-custom-item-tile vue-tile-edoceo"
                :class="{'vue-is-disabled': !isPrivateApp}"
              >
                <div class="vue-tile-icon" />
                <div class="vue-b-content">
                  <h2 class="vue-tile-headline">{{ edoceoPage?.title }}</h2>

                  <div class="vue-tile-description">
                    <p v-html="edoceoPage?.content"></p>
                  </div>

                  <a
                    v-if="isPrivateApp"
                    href="https://csob.edoceo.cz/"
                    target="_blank"
                    class="vue-tile-link-external"
                  >{{ $t('education.edoceoButtonText') }}</a>
                  <gen1006-info-panel
                    v-else
                    type="neutral"
                  >
                    <span
                      v-html="$i18n.t('education.privateMessage', { pageId: installationPageId})"
                    />
                  </gen1006-info-panel>
                </div>
              </div>

              <div
                class="vue-custom-item-tile vue-tile-forvardino"
                @buttonClickEvent="goToForvardino()"
              >
                <div class="vue-tile-icon" />
                <div class="vue-b-content">
                  <h2 class="vue-tile-headline">{{ forvardinoPage?.title }}</h2>

                  <div class="vue-tile-description">
                    <p v-html="forvardinoPage?.content"></p>
                  </div>

                  <frm1006-button
                    class="vue-tile-button-internal"
                    type="primary2"
                    :icon-right="false"
                    @buttonClickEvent="goToForvardino()"
                  >
                    {{ $t('education.forvardinoButtonText') }}
                  </frm1006-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import { EDUCATION_KEY_PREFIX, ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import utilsGeneral from '@/utils/utils-general';

export default {
  name: 'EducationDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT,
      dashboardTitle: null,
      dashboardContent: null,
      eduKatePage: null,
      edoceoPage: null,
      forvardinoPage: null
    }
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },

    educationConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(EDUCATION_KEY_PREFIX)) {
            const itemNameWithoutPrefix = item.key.replace(EDUCATION_KEY_PREFIX, '');
            const itemName = utilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },

    educationDashboardPageId() {
      return (typeof this.educationConfig.dashboardPageId !== 'undefined' ? this.educationConfig.dashboardPageId : 0);
    },

    educationEduKatePageId() {
      return (typeof this.educationConfig.edukatePageId !== 'undefined' ? this.educationConfig.edukatePageId : 0);
    },

    educationEdoceoPageId() {
      return (typeof this.educationConfig.edoceoPageId !== 'undefined' ? this.educationConfig.edoceoPageId : 0);
    },

    educationForvardinoPageId() {
      return (typeof this.educationConfig.forvardinoPageId !== 'undefined' ? this.educationConfig.forvardinoPageId : 0);
    },

    installationPageId() {
      let pageId = -1;

      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'HELP_INSTALLATION_PAGE_ID') {
            pageId = parseInt(item.value);
          }
        });
      }

      return pageId;
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },

    applicationConfigLoaded() {
      this.fetchDashboardData();
    },
  },

  mounted() {
    this.fetchDashboardData();
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    fetchDashboardData() {
      if(this.educationDashboardPageId > 0) {
        this.setLoadingState(true);

        dataLoader.fetchSubPages(this.educationDashboardPageId)
          .then(data => {
            this.dashboardTitle = data.title;
            this.dashboardContent = data.content;
            this.eduKatePage = data.items.find(item => parseInt(item.id) === parseInt(this.educationEduKatePageId));
            this.edoceoPage = data.items.find(item => parseInt(item.id) === parseInt(this.educationEdoceoPageId));
            this.forvardinoPage = data.items.find(item => parseInt(item.id) === parseInt(this.educationForvardinoPageId));
          })
          .catch(error => {
            logger.error(error);
            this.hasError = true;
          })
          .finally(() => {
            this.initialized = true;
            this.setLoadingState(false);
          });
      }
    },

    goToForvardino() {
      this.$router.push({ name: 'forvardino-list'});
    },
  }
}
</script>
