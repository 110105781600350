<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-layout-slot" :class="classObject">
        <slot></slot>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1026LayoutSlot',
    props: {
        type: String,
        id: {
            default: null,
            type: String
        }
    },
    computed: {
        classObject() {
            return {
                [`vue-layout-slot-${this.type}`]: this.type
            };
        }
    }
};
</script>
