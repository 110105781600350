<template>
  <div class="vue-popup-forvardino-filter">
    <frm1035-form-group>
      <frm1028-advanced-combo
        v-model="eventLocation"
        class="vue-popup-forvardino-city-selector"
        :options="convertedCategoryObject"
        :placeholder="$i18n.t('advancedFilter.cityPlaceholder')"
        :tooltip="false"
        :label="$i18n.t('advancedFilter.city')"
        @change="fetchItemsNumber"
      />

      <frm1003-checkbox
        v-model="onlyOnline"
        class="vue-popup-forvardino-online-checkbox"
        :valueTrue="1"
        :valueFalse="0"
        :tooltip="false"
        @change="fetchItemsNumber"
      >
        {{ $t('advancedFilter.onlyOnline') }}
      </frm1003-checkbox>

      <gen1015-section
        v-if="forvardinoFilterCategories.targets.length > 1"
        :collapsible="true"
        :collapsed="true"
      >
        <template v-slot:header>
          <h2>{{ $t('advancedFilter.forWhomItsIntended') }}</h2>
        </template>

        <template v-slot:content>
          <frm1003-checkbox
            v-for="target in forvardinoFilterCategories.targets"
            :key="'target-' + target.id"
            v-model="eventTargets"
            :valueNative="target.id"
            :tooltip="false"
            @change="fetchItemsNumber"
          >
            {{ target.name }}
          </frm1003-checkbox>
        </template>
      </gen1015-section>

      <gen1015-section
        v-if="forvardinoFilterCategories.categories.length > 1"
        :collapsible="true"
        :collapsed="true"
      >
        <template v-slot:header>
          <h2>{{ $t('advancedFilter.categories') }}</h2>
        </template>

        <!-- TODO MBU: use frm1025 option group -->
        <template v-slot:content>
          <frm1003-checkbox
            v-for="category in forvardinoFilterCategories.categories"
            :key="'category-' + category.id"
            v-model="eventCategories"
            :valueNative="category.id"
            :tooltip="false"
            @change="fetchItemsNumber"
          >
            {{ category.name }}
          </frm1003-checkbox>
        </template>
      </gen1015-section>
    </frm1035-form-group>

    <div
      v-if="!clearFilters"
      class="vue-popup-forvardino-filter-submit"
    >
      <!-- FIXME: JNO: Parsing integer from foundItems string/number mismatch for strict type comparison -->
      <frm1006-button
        :disabled="clearFilters || parseInt(foundItems) < 1"
        @buttonClickEvent="filterEvents"
      >
        {{ $t('advancedFilter.filterList') }}
        <span
          v-if="showFilterNumbers"
        >
          ({{ foundItems }})
        </span>
      </frm1006-button>
    </div>

    <gen1006-info-panel
      v-if="hasError"
      type="error"
    >
      {{ $t('error.generic') }}
    </gen1006-info-panel>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'Prj1011PopupForvardinoFilter',
  data() {
    return {
      touchedFilters: false,
      hasError: false,
      eventLocation: '',
      eventTargets: [],
      eventCategories: [],
      onlyOnline: 0,
      loadingItemsNumber: false,
      foundItems: 0
    }
  },

  computed: {
    ...mapState('forvardino', [
      'forvardinoFilterCategories',
      'forvardinoSelectedCategories',
      'forvardinoActiveCategory'
    ]),

    convertedCategoryObject() {
      let locations = [];

      Object.values(this.forvardinoFilterCategories.locations).forEach(categoryItem => {
        const categoryObject = {
          value: categoryItem.id.toString(),
          caption: categoryItem.name
        }

        locations.push(categoryObject);
      });

      return locations;
    },

    clearFilters() {
      return !this.eventLocation && !this.onlyOnline && !this.eventTargets.length && !this.eventCategories.length;
    },

    showFilterNumbers() {
      return !this.clearFilters && !this.loadingItemsNumber;
    },
  },

  created() {
    this.synchronizeFiltersData();
  },

  mounted() {
    this.assignCategory();
  },

  methods: {
    synchronizeFiltersData() {
      this.eventCategories.push(this.forvardinoActiveCategory);
    },

    fetchItemsNumber() {
      this.loadingItemsNumber = true;
      this.touchedFilters = true;

      if (this.onlyOnline) {
        this.eventLocation = '';
      }

      dataLoader.getForvardinoFilteredItemsNumber(this.eventCategories, this.eventLocation, this.eventTargets, this.onlyOnline, '')
        .then(response => {
          this.foundItems = response.resultsCount;
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          } else {
            this.loadingItemsNumber = false;
          }
        })
    },

    assignCategory() {
      if (typeof this.forvardinoSelectedCategories.eventCategories !== 'undefined') {
        this.eventCategories.push(this.forvardinoSelectedCategories.eventCategories);
      }
    },

    filterEvents() {
      const filteredCategories = {
        categories: this.eventCategories.filter(item => parseInt(item) !== 0).filter(item => item.constructor !== Array),
        locations: this.eventLocation,
        targets: this.eventTargets,
        onlyOnline: this.onlyOnline,
      }

      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_FORVARDINO_SELECTED_CATEGORIES, filteredCategories);
      this.$store.commit(STORE_MODULES.FORVARDINO + '/' + MUTATIONS_CONSTANTS.SET_ARE_FILTERS_ACTIVE, true);
      this.$store.commit(STORE_MODULES.POPUP + '/' + MUTATIONS_CONSTANTS.TOGGLE_POPUP, false);
    }
  }
}
</script>
