<template>
  <div
    class="vue-c-dashboard-tile-sticker"
    :class="classObject"
  >
    <span class="vue-sticker-short">{{ stickerShort }}</span>
    <span class="vue-sticker-full">{{ stickerFull }}</span>
  </div>
</template>

<script>
const STICKER_TYPE_UPDATED = 'updated';
const STICKER_TYPE_NEW = 'new';
const STICKER_TYPE_IMPORTANT = 'important';

export default {
  name: 'Prj1044DashboardTileSticker',
  props: {
    sticker: {
      type: String,
      required: true
    },
    compact: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showGroupMenu: false,
      groupMenu: false,
      contextContainerPositions: false
    }
  },
  
  computed: {
    classObject() {
      return {
        ['vue-is-' + this.sticker]: this.sticker,
        'vue-is-compact': this.compact
      }
    },
    stickerFull() {
      if (this.sticker === STICKER_TYPE_UPDATED) {
        return this.$i18n.t('dashboardSticker.updated');
      }
      if (this.sticker === STICKER_TYPE_NEW) {
        return this.$i18n.t('dashboardSticker.new');
      }
      if (this.sticker === STICKER_TYPE_IMPORTANT) {
        return this.$i18n.t('dashboardSticker.important');
      }

      return '';
    },
    stickerShort() {
      if (this.sticker === STICKER_TYPE_UPDATED) {
        return this.$i18n.t('dashboardSticker.updatedShort');
      }
      if (this.sticker === STICKER_TYPE_NEW) {
        return this.$i18n.t('dashboardSticker.newShort');
      }
      if (this.sticker === STICKER_TYPE_IMPORTANT) {
        return this.$i18n.t('dashboardSticker.importantShort');
      }

      return '';
    }
  }
}
</script>