<template>
  <page-layout
    :class="classObject"
  >
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        @buttonCustomEvent="buttonCustomEvent"
      />
    </template>

    <template v-slot:contentMain>
      <prj1042-dashboard-buttons v-if="!isDesktopLayout" />

      <div class="vue-b-widgets">
        <prj1050-widget
          :title="$t(widgets.WIDGET_PROMOTED.title)"
          promoted
          :destination="widgets.WIDGET_PROMOTED.destination"
          :data-items="widgetPromotedData"
          :icon-color="widgets.WIDGET_PROMOTED.iconColor"
          :bg-color="widgets.WIDGET_PROMOTED.bgColor"
          :icon-svg="widgets.WIDGET_PROMOTED.iconSVG"
          :dataLoaded="widgetPromotedDataInitialized"
        />

        <prj1050-widget
          :title="$t(widgets.WIDGET_NEWS.title)"
          :destination="widgets.WIDGET_NEWS.destination"
          :data-items="widgetNewsData"
          :icon-color="widgets.WIDGET_NEWS.iconColor"
          :bg-color="widgets.WIDGET_NEWS.bgColor"
          :icon-svg="widgets.WIDGET_NEWS.iconSVG"
          :module="'news'"
          :dataLoaded="widgetNewsDataInitialized"
          class="vue-is-main1"
        />

        <prj1050-widget
          :title="$t(widgets.WIDGET_EVENTS.title)"
          :destination="widgets.WIDGET_EVENTS.destination"
          :data-items="widgetAwaitData"
          :icon-color="widgets.WIDGET_EVENTS.iconColor"
          :bg-color="widgets.WIDGET_EVENTS.bgColor"
          :icon-svg="widgets.WIDGET_EVENTS.iconSVG"
          :module="'events'"
          :dataLoaded="widgetAwaitDataInitialized"
          class="vue-is-main2"
        />

        <prj1050-widget
          v-if="!appDirectoryDisabled"
          :title="$t(widgets.WIDGET_DIRECTORY.title)"
          :destination="widgets.WIDGET_DIRECTORY.destination"
          :data-items="[]"
          :icon-color="widgets.WIDGET_DIRECTORY.iconColor"
          :bg-color="widgets.WIDGET_DIRECTORY.bgColor"
          :icon-svg="widgets.WIDGET_DIRECTORY.iconSVG"
          :module="'directory'"
          :dataLoaded="true"
          :custom-content="true"
          class="vue-is-sidebar"
        >
          <template v-slot:customContent>
            <prj1052-directory-list />
          </template>
        </prj1050-widget>
      </div>

      <prj1034-tutorial
        :tutorial="tutorials.dashboardInit"
      />
    </template>
  </page-layout>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapState} from 'vuex';
import router from '@/router';
import utilsGeneral from '@/utils/utils-general';

import * as envConfig from 'env-config';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import {
  ERROR_MODAL_TIMEOUT,
  MOBILE_BREAKPOINT_SIZE,
  DESKTOP_APP_BORDER_SIZE, APP_DIRECTORY_KEY_PREFIX
} from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';
import mxDetectDesktop from '../../mixins/mx-detect-desktop';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1034Tutorial from '@/components/prj1034-tutorial/prj1034-tutorial';
import Prj1042DashboardButtons from '@/components/prj1042-dashboard-buttons/prj1042-dashboard-buttons';
import Prj1052DirectoryList from '@/components/prj1052-directory-list/prj1052-directory-list.vue';
import Prj1050Widget from '@/components/prj1050-widget/prj1050-widget';
import { APP_DESTINATIONS } from '@/constants/general';

import * as WIDGETS from '@/assets/widgets';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import zapUtilsGeneral from '@/utils/utils-general';

export default {
  name: 'Dashboard',

  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1034Tutorial,
    Prj1042DashboardButtons,
    Prj1050Widget,
    Prj1052DirectoryList
  },

  mixins: [
    mxNavigationDefault,
    mxDetectDesktop
  ],

  data() {
    return {
      currentDateTime: new Date(),
      verticalHeight: 0,
      firstRun: false,
      drawerTopAboveThreshold: null,
      showInstallReminderModal: false,
      events: {},
      hasError: false,
      drawerTopDistance: 0,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      showGroupMenu: false,
      groupMenu: false,
      contextContainerPositions: false,
      focusInput: false,
      widgets: WIDGETS,
    }
  },

  computed: {
    ...mapState('articles', [
      'newsActiveCategory'
    ]),
    ...mapState('dataStore', [
      'contentDataUpdate'
    ]),
    ...mapState('general', [
      'swRegistration',
      'applicationConfig',
      'applicationConfigLoaded',
      'isMenuOpened',
      'isSubmenuOpened',
      'dashboardButtons',
      'dashboardGroupButtons',
      'backToNavigation',
      'viewportSize',
      'appDestination'
    ]),
    ...mapState('user', [
      'platform'
    ]),
    ...mapState('dashboard', [
      'selectedBuilding',
      'promoted',
      'promotedInitialized',
      'coming',
      'comingInitialized',
      'news',
      'newsInitialized',
    ]),
    ...mapState('tutorial', [
      'tutorials'
    ]),
    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY
    }),

    classObject() {
      return {
        'vue-has-content-drawer-above-threshold': this.drawerTopAboveThreshold
      }
    },

    widgetPromotedData() {
      return this.promoted;
    },

    widgetPromotedDataInitialized() {
      return this.promotedInitialized;
    },

    widgetAwaitData() {
      return this.coming;
    },

    widgetAwaitDataInitialized() {
      return this.comingInitialized;
    },

    widgetNewsData() {
      return this.news;
    },

    widgetNewsDataInitialized() {
      return this.newsInitialized;
    },

    navbarButtonType() {
      if (this.dashboardMenuClosed) {
        return 'hamburger'
      } else if (this.dashboardMenuTopLevelOpen) {
        return 'close'
      } else if (this.dashboardSubmenuOpen) {
        return 'back'
      }

      return '';
    },

    dashboardMenuClosed() {
      return !this.isMenuOpened && !this.isSubmenuOpened;
    },

    dashboardMenuTopLevelOpen() {
      return this.isMenuOpened && !this.isSubmenuOpened;
    },

    dashboardSubmenuOpen() {
      return this.isMenuOpened && this.isSubmenuOpened
    },

    orderedDashboardButtons() {
      const buttonsArray = this.dashboardButtons;
      const uniqueButtonsArray = buttonsArray.filter((thing, index) => {
        if (thing.privateOnly === '0' || (thing.privateOnly === '1' && this.appDestination === APP_DESTINATIONS.PRIVATE)) {
          const _thing = JSON.stringify(thing);
          return index === buttonsArray.findIndex(obj => {
            return JSON.stringify(obj) === _thing;
          });
        }
      });

      return uniqueButtonsArray.sort((a, b) => parseInt(a.orderingDashboard) - parseInt(b.orderingDashboard));
    },

    categoryId() {
      let retValue = 0;
      if (typeof this.applicationConfig.localities !== 'undefined') {
        this.applicationConfig.localities.forEach(e =>
          Object.entries(e).forEach(([key, value]) => {
            if (key === 'id' && this.storedSelectedBuilding === value && e.hasOwnProperty('articleTagId')) {
              retValue = e.articleTagId;
            }
          })
        );
      }
      return parseInt(retValue);
    },

    appDirectoryConfig() {
      let configObject = {};

      if (this.applicationConfigLoaded) {
        let customFields = this.applicationConfig.customFields;

        customFields.forEach(item => {
          if (item.key.includes(APP_DIRECTORY_KEY_PREFIX)) {
            const itemNameWithoutPrefix = item.key.replace(APP_DIRECTORY_KEY_PREFIX, '');
            const itemName = zapUtilsGeneral.convertToCamelCase(itemNameWithoutPrefix);

            configObject[itemName] = item.value;
          }
        })
      }

      return configObject;
    },

    appDirectoryDisabled() {
      return (typeof this.appDirectoryConfig?.disabled !== 'undefined' ) ? this.appDirectoryConfig.disabled : true;
    },
  },

  watch: {
    applicationConfigLoaded() {
      if (this.applicationConfigLoaded) {
        this.setDashboardBackground();
      }
    },

    selectedBuilding() {
      this.setDashboardBackground();
    },

    isMenuOpened(value) {
      this.focusInput = !value;
    },
  },

  created() {},

  mounted() {
    if (this.backToNavigation) {
      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.OPEN_DASHBOARD_NAVIGATION)
        .then(() => {
          this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_BACK_TO_NAVIGATION, false);
        });
    }

    if (this.swRegistration) {
      this.swRegistration.update();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateDrawerDistance);
  },

  methods: {
    generateTileIcon(item) {
      if (item.cssClass.length > 0 && !item.iconCustom.length > 0) {
        return item.cssClass;
      } else if (item.iconCustom.length > 0) {
        return 'vue-icon-custom';
      }

      return 'vue-icon-' + item.iconDashboard;
    },

    generateTileIconBackground(item) {
      if (item.iconCustom.length > 0) {
        return {
          'background-image': `url(${envConfig.default.webServices.BASE_CMS_URL}${item.iconCustom})`
        }
      }
    },

    buttonCustomEvent() {
      // menu top level open - 2 conditions to prevent open & close with same click
      if (!this.dashboardMenuTopLevelOpen) {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU, true);
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU_CATEGORY, null);
      } else if (this.dashboardMenuTopLevelOpen) {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU, false);
      }        // submenuopen
      if (this.dashboardSubmenuOpen) {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_BACK_TO_NAVIGATION, false);
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU, false);
      }
    },

    setDashboardBackground() {
      const localities = this.applicationConfig.localities;
      // FIXME: We need to standardize API number values + try to standardize store values. Now we must use non-strict value comparing
      const selectedBuildingIndex = localities.findIndex(item => item.id == this.selectedBuilding);
      const backgroundAddress = selectedBuildingIndex > -1 ? localities[selectedBuildingIndex].imageBackground : null;

      this.$store.commit(STORE_MODULES.DASHBOARD + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_BACKGROUND, backgroundAddress);
    },

    setContentDrawerTopAboveThreshold(value) {
      this.drawerTopAboveThreshold = value;
    },

    endpointListOptions() {
      return [0, 0, 50]
    },

    filterHourFromDate(date) {
      return Vue.filter('convertDate')(date, 'hour') + ':' + Vue.filter('convertDate')(date, 'minutes');
    },

    goToWeekActions() {
      router.push({name: 'week-actions'});
    },

    goToActionDetail(id, parentId, type) {
      if (type === 'event') {
        router.push({name: 'event-detail', params: {id: id}});
      } else if (type === 'forvardino') {
        router.push({name: 'forvardino-detail', params: {id: parentId}});
      }
    },

    convertDate(date) {
      let dateObject = utilsGeneral.sqlDateTimeToDate(date);
      let convertedTime = this.filterHourFromDate(date);
      let dateDay = (dateObject.getDay() === 0 ? 6 : dateObject.getDay() - 1);
      let result = '';

      if (this.isToday(dateObject)) {
        result += '<span class="vue-short-dayname">dnes</span> ';
      } else if (this.isTomorrow(dateObject)) {
        result += '<span class="vue-short-dayname">zítra</span> ';
      } else {
        result += '<span class="vue-short-dayname">' + this.$i18n.t('abbreviation.days.' + dateDay) + '</span> ';
      }
      result += '<span class="vue-short-time">' + this.$i18n.t('time.hourShortStrong', {hours: convertedTime}) + '</span>';

      return result;
    },

    calculateDrawerDistance() {
      this.$nextTick(() => {
        if (!this.isDesktopLayout) {
          const actionSliderRect = this.$refs.actionsSlider.getBoundingClientRect();
          const contentDrawerRect = this.$refs.contentDrawer.$el.getBoundingClientRect();
          const sliderTopDistanceFromViewport = actionSliderRect.top;
          const drawerTopDistanceFromViewport = contentDrawerRect.top;
          const sliderHeight = actionSliderRect.height;
          const desktopTopBorder = this.viewportSize.windowWidth > MOBILE_BREAKPOINT_SIZE ? DESKTOP_APP_BORDER_SIZE : 0;

          this.drawerTopDistance = (drawerTopDistanceFromViewport + desktopTopBorder) - (sliderTopDistanceFromViewport + sliderHeight + desktopTopBorder);
          document.documentElement.style.setProperty('--drawerDistance', `${this.drawerTopDistance}px`);
        }
      });
    },

    isToday(compareDate) {
      const now = new Date();
      return now.getFullYear() === compareDate.getFullYear()
        && now.getMonth() === compareDate.getMonth()
        && now.getDate() === compareDate.getDate();
    },

    isTomorrow(compareDate) {
      const now = new Date();
      return now.getFullYear() === compareDate.getFullYear()
        && now.getMonth() === compareDate.getMonth()
        && (now.getDate() + 1) === compareDate.getDate();
    },

    goToRoute(routeName) {
      const routeToGo = {};

      if (routeName.startsWith('/')) {
        routeToGo.path = routeName;
      } else {
        routeToGo.name = routeName;
      }

      if (!this.categoryId && this.postId) {
        routeToGo.params = {id: this.postId};
      } else if (this.categoryId) {
        routeToGo.query = {categoryId: this.categoryId};
      }

      router.push(routeToGo);
    },

    classObjectGroupButtons(group) {
      return [
        {
          'vue-has-2': group.length === 2,
          'vue-has-4': group.length > 2,
        }
      ];
    },

    groupButtonClickEvent(group, event) {
      this.groupMenu = group;
      this.showGroupMenu = true;

      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;

      if (this.isDesktopLayout) {
        this.contextContainerPositions = {
          x: event.pageX,
          y: event.pageY,
          mode: 'top-left'
        }
      } else {
        this.contextContainerPositions = false
      }
    },

    plural(n) {
      return (n > 1)
    },

    eventsWithUnit(event) {
      return `${this.plural(event) ? this.$i18n.t('weekActions.events') : this.$i18n.t('weekActions.event')}`
    },

    async fetchPromotedData() {
      if (!this.hasIdentity) {
        return false;
      }

      this.widgetPromotedDataInitialized = false;
      const endpointPromotedListOptions = [
        this.selectedBuilding,
        'any',
        0,
        20
      ];

      await dataLoader.fetchPromotedFilter([...Object.values(endpointPromotedListOptions)])
        .then(response => {
          this.widgetPromotedData = this.convertPromotedData(response);
          this.widgetPromotedDataInitialized = true;
        })
        .catch(error => {
          this.widgetPromotedDataInitialized = false;
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          this.widgetPromotedDataInitialized = true;
        });
    },

    async fetchNewsData() {
      if (!this.hasIdentity) {
        return false;
      }

      const filteredArticlesAmount = 10;
      this.widgetNewsDataInitialized = false;

      if (this.selectedBuilding === '') {
        return false;
      }

      await dataLoader.fetchPostsByCategories(this.newsActiveCategory, 0, filteredArticlesAmount, this.selectedBuilding, '')
        .then(response => {
          this.widgetNewsData = this.convertNewsData(response);
          this.widgetNewsDataInitialized = true;
          this.$store.commit(STORE_MODULES.ARTICLES + '/' + MUTATIONS_CONSTANTS.SHOW_FILTERED_ARTICLES, true);
          this.$store.commit(STORE_MODULES.ARTICLES + '/' + MUTATIONS_CONSTANTS.ADD_FILTERED_ARTICLES, response);
        })
        .catch(error => {
          this.widgetNewsDataInitialized = false;
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          this.widgetNewsDataInitialized = true;
        });
    },

    async fetchComingData() {
      if (!this.hasIdentity) {
        return false;
      }

      this.widgetAwaitDataInitialized = false;

      await dataLoader.fetchComingFilter([...Object.values(this.endpointListOptions)])
        .then(response => {
          this.widgetAwaitData = this.convertComingData(response);
          this.widgetAwaitDataInitialized = true;
        })
        .catch(error => {
          this.widgetAwaitDataInitialized = false;
          this.hasError = true;
          logger.error(error);
        })
        .finally(() => {
          this.widgetAwaitDataInitialized = true;
        });
    },

    convertPromotedData(data) {
      let tempObject = [];
      let counter = 0;

      data.forEach(item => {
        let itemObject = {
          name: item.title,
          id: item.id,
          actionId: item.id,
          image: item.featuredImage,
          module: item.module,
          target: item.module
        };

        tempObject[counter] = itemObject
        counter++;
      });

      return tempObject;
    },

    convertNewsData(data) {
      let tempObject = [];
      let counter = 0;

      data.forEach(article => {
        let articleObject = {
          categories: article.categories,
          commentsCount: article.commentsCount,
          commentsEnable: article.commentsEnable,
          createdTimestamp: article.created / 1000,
          content: article.content,
          excerpt: article.excerpt,
          image: article.featuredImage,
          id: article.id,
          actionId: article.id,
          important: article.important,
          localities: article.localities,
          recommended: article.recommended,
          modifiedTimestamp: article.modified / 1000,
          name: article.title,
          target: '',
          created: article.created / 1000,
          displayDate: true
        };

        tempObject[counter] = articleObject
        counter++;
      });

      return tempObject;
    },

    convertComingData(data) {
      let tempObject = [];
      let counter = 0;

      data.forEach(item => {
        let itemObject = {
          name: item.name,
          id: item.id,
          actionId: item.actionId,
          image: item.featuredImage,
          module: item.module,
          score: item.score,
          target: item.target,
          categories: item.module === 'forvardino' ? [] : item.categories,
          displayDate: true,
          nearestDate: item.nearestDate
        };

        tempObject[counter] = itemObject
        counter++;
      });

      return tempObject;
    },
  }
}
</script>
