<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div :class="containerClasses">
    <button
      type="button"
      class="flex items-center"
      :class="[{ 'text-teal-600': btnTxtHighlighted && computedActiveFilter}, btnClasses ]"
      :disabled="disabled"
      @click="openOverlay"
    >
      <!-- Icon -->
      <slot name="icon" />

      {{ overlayBtnCaption }}
    </button>

    <!-- Overlay -->
    <prj1009-modal
      v-if="options && !!options.length"
      :active="selectModal"
      @overlayCloseEvent="closeOverlay"
    >
      <template v-slot:content>
        <div class="flex flex-col pb-3">
          <prj1006-cards-wrapper
            class="mb-4"
            :hasNavigation="false"
            :itemsLength="options.length"
          >
            <template v-for="{ id, categoryId, image, timeSpanInfo, title } in options">
              <prj1007-theme-card
                :key="`visual-filter-${id}`"
                :statusTag="timeSpanInfo"
                :title="title"
                :image="image"
                :categoryId="categoryId"
                :isSingle="isSingleCard"
                :isHighlighted="id === computedActiveFilter"
                isClickable
                isModeOverlay
                @cardClick="onSelect(id)"
              />
            </template>
          </prj1006-cards-wrapper>
          <label class="prj-filter-radio flex items-center">
            <span class="pr-2 font-semibold">{{ resetBtnCaption }}</span>
            <t-radio
              name="visual-filter-radio"
              value="default"
              :checked="!computedActiveFilter"
              @click="onSelectionReset"
            />
          </label>
        </div>
      </template>
    </prj1009-modal>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import Prj1006CardsWrapper from '@/components/esg/prj1006-cards-wrapper/prj1006-cards-wrapper'
import Prj1007ThemeCard from '@/components/esg/prj1007-event-card/prj1007-theme-card'
import Prj1009Modal from '@/components/esg/prj1009-modal/prj1009-modal'

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1019VisualModalSelect',
  components: { Prj1006CardsWrapper, Prj1007ThemeCard, Prj1009Modal },
  props: {
    containerClasses: {
      type: String,
      default: null,
    },
    btnClasses: {
      type: [String, Array],
      default: null,
    },
    btnTxtHighlighted: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    activeSelection: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    overlayBtnCaption: {
      type: String,
      default: null,
    },
    resetBtnCaption: {
      type: [String, global.VueI18n],
      default: null,
    },
  },
  data() {
    return {
      selectModal: false,
    }
  },
  computed: {
    computedActiveFilter() {
      return this.options.find((filter) => filter.id === this.activeSelection) ? this.activeSelection : 0
    },
    isSingleCard() {
      return this.options.length === 1
    },
  },
  methods: {
    onSelect(value) {
      this.$emit('select', value)
      this.closeOverlay()
    },

    onSelectionReset() {
      this.$emit('selectionReset')
      this.closeOverlay()
    },

    openOverlay() {
      this.selectModal = true
    },

    closeOverlay() {
      this.selectModal = false
    },
  },
}
</script>
