<template>
  <div
    class="vue-c-category-filter"
    :class="classObject"
  >
    <div class="vue-category-filter-scroll-wrapper">
      <frm1006-button
        v-if="showResetButton"
        class="vue-category-filter-button"
        :class="isButtonActive(0)"
        type="app-button"
        @buttonClickEvent="resetFilteredArticles"
      >
        {{ $t('general.all') }}
      </frm1006-button>
      <template
        v-if="!showAllButtons && activeCategory !== 0 && !showFilterButton"
      >
        <frm1006-button
          class="vue-category-filter-button"
          :class="isButtonActive(selectedButton.termId)"
          type="app-button"
        >
          {{ selectedButton.termName }}
        </frm1006-button>
      </template>

      <template
        v-else-if="!showAllButtons && showFilterButton"
      >
        <frm1006-button
          class="vue-category-filter-button vue-is-active"
          type="app-button"
        >
          {{ $t('general.filter') }}
        </frm1006-button>
      </template>

      <template
        v-else
      >
        <template
          v-for="(category, index) in filterCategories"
        >
          <frm1006-button
            v-if="index < showItems"
            :key="category.termId"
            class="vue-category-filter-button"
            :class="isButtonActive(category.termId)"
            type="app-button"
            @buttonClickEvent="setArticleCategory(category.termId, 'button')"
          >
            {{ category.termName }}
          </frm1006-button>
        </template>
      </template>
    </div>

    <frm1006-button
      v-show="showFilterButtonMore"
      class="vue-category-filter-button-more"
      type="app-button"
      @buttonClickEvent="showCategoriesMenu"
    />
  </div>
</template>

<script>
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

export default {
  name: 'Prj1026CategoryFilter',
  mixins: [mxDetectDesktop],
  props: {
    filterCategories: {
      type: [Array, Object],
      required: true
    },
    filterType: {
      type: String,
      required: true
    },
    // FIXME: CSOBZ-543 - make dynamic filter length default
    showItems: {
      type: Number,
      required: false,
      default: 2
    },
    showFilterButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFilterButtonMore: {
      type: Boolean,
      required: false,
      default: true,
    },
    showResetButton: {
      type: Boolean,
      required: false,
      default: true
    },
    showAllButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    activeCategory: {
      type: Number,
      required: true
    },
    popupType: {
      type: String,
      required: false,
      default: 'articleMenu'
    },
  },

  computed: {
    classObject() {
      return [
        `vue-category-filter-${this.filterType}`
      ]
    },

    selectedButton() {
      const selectedButtonIndex = this.filterCategories.findIndex(category => parseInt(category.termId) === parseInt(this.activeCategory));

      if (selectedButtonIndex < 0) {
        return 0;
      }

      return this.filterCategories[selectedButtonIndex];
    }
  },

  created() {
    this.$store.commit(STORE_MODULES.POPUP + '/' + MUTATIONS_CONSTANTS.SET_CATEGORIES, this.filterCategories);
  },

  methods: {
    isButtonActive(categoryId) {
      const categorySelection = parseInt(categoryId);

      return {
        'vue-is-active': categorySelection === this.activeCategory,
      }
    },

    setArticleCategory(categoryNumber, origin) {
      const selectedCategoryNumber = origin === 'button' ? parseInt(categoryNumber) : parseInt(categoryNumber.value);

      this.$emit('filterItems', selectedCategoryNumber);
    },

    resetFilteredArticles() {
      this.$emit('resetFilteredItems');
    },

    showCategoriesMenu(event) {
      let contextContainerPositions = false;
      if (this.isDesktopLayout) {
        contextContainerPositions = {
          x: event.pageX,
          y: false,
          mode: 'bottom-right'
        }
      }
      this.$store.dispatch(STORE_MODULES.POPUP + '/' + ACTIONS_CONSTANTS.POPUP_POSITION, contextContainerPositions);
      this.$store.dispatch(STORE_MODULES.POPUP + '/' + ACTIONS_CONSTANTS.TOGGLE_POPUP, this.popupType);
    }
  }
}
</script>
