<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div class="vue-b-contacts">
          <frm1035-form-group>
            <frm1028-advanced-combo
              id="building-selector"
              v-model="selectedBuilding"
              :options="buildings"
              :label="$i18n.t('emergency.buildings')"
              :tooltip="false"
              :searchable="false"
              @change="getSelectedBuildingData()"
            />
          </frm1035-form-group>

          <prj1004-article-body
            v-if="pageContent"
            :articleHeading="false"
            :articleData="pageContent"
          />

          <gen1006-info-panel
            v-if="!pageContent && !isLoading"
            type="error"
          >
            {{ $t('general.articleNonexistent') }}
          </gen1006-info-panel>
        </div>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1004ArticleBody from '@/components/prj1004-article-detail/prj1004-article-body';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

export default {
  name: 'EmergencyContacts',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1004ArticleBody
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      navbarCaptionData: null,
      ERROR_MODAL_TIMEOUT,
      selectedBuilding: 'nhq',
      pageContent: null,
      buildings: [
        {
          'caption': 'NHQ',
          'value': 'nhq'
        },
        {
          'caption': 'SHQ',
          'value': 'shq'
        },
        {
          'caption': 'HHQ',
          'value': 'hhq'
        },
        {
          'caption': 'PHQ',
          'value': 'phq'
        },
      ],
      pageSlug: 'important-phone-numbers'
    };
  },

  computed: {
    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value && this.pageContent) {
        this.setLoadingState(false);
      }
    },
  },

  mounted() {
    this.initialized = true;
    this.getPageContent();
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getPageContent() {
      this.setLoadingState(true);

      return dataLoader.fetchEmergencyPage(this.pageSlug, this.selectedBuilding)
        .then(data => {
          this.pageContent = data;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    getSelectedBuildingData() {
      this.getPageContent();
    }
  },
}
</script>

