<template>
  <div class="vue-c-article-body">
    <div
      v-if="articleHeading"
      class="vue-b-article-heading"
    >
      <h1
        v-if="articleData.title"
        class="vue-article-heading"
        v-html="articleData.title"
      >
      </h1>

      <div
        class="vue-article-date"
      >
        <template v-if="!isDateDisabled">
          <prj1038-item-age
            v-if="articleData.createdTimestamp"
            :date="articleData.createdTimestamp"
            :showFullDate="isModified"
          />

          <span
            v-if="isModified && !hasSameDate"
            class="vue-article-date-extended"
          >
            <span
              v-html="$t('articles.articleUpdated')"
            />

            <prj1038-item-age
              v-if="articleData.modifiedTimestamp"
              :date="articleData.modifiedTimestamp"
              :showFullDate="isModified"
            />
          </span>
        </template>
      </div>
    </div>
    <div
      class="vue-b-article-content vue-wcm-content"
      v-html="articleData.content"
    />
  </div>
</template>

<script>
import utilsGeneral from '@/utils/utils-general';

import Prj1038ItemAge from '@/components/prj1038-item-age/prj1038-item-age';

export default {
  name: 'Prj1004ArticleBody',
  components: {
    Prj1038ItemAge
  },

  props: {
    articleHeading: {
      default: true,
      type: Boolean
    },
    articleData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      pluralizedDays: {
        0: this.$i18n.t('inflection.days.0'),
        1: this.$i18n.t('inflection.days.1'),
        2: this.$i18n.t('inflection.days.2'),
        5: this.$i18n.t('inflection.days.5')
      },
    }
  },

  computed: {
    isModified() {
      return this.articleData.createdTimestamp !== this.articleData.modifiedTimestamp;
    },

    isDateDisabled() {
      return typeof this.articleData.disabledDate === 'undefined' ? false : this.articleData?.disabledDate;
    },

    hasSameDate() {
      const createdDate = utilsGeneral.timestampToFullDate(this.articleData.createdTimestamp);
      const modifiedDate = utilsGeneral.timestampToFullDate(this.articleData.modifiedTimestamp);

      return createdDate === modifiedDate;
    }
  }
}
</script>
