<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-c-filter-group" :class="classObject">
        <!--========== HEADER =================================-->
        <!--===================================================-->

        <!--======= CUSTOM ==========================-->
        <div v-if="hasHeaderCustom" class="vue-b-filter-group-header">
            <slot name="header"></slot>
        </div>

        <!--======= DEFAULT =========================-->
        <div v-else-if="hasHeaderDefault" class="vue-b-filter-group-header">
            <div class="vue-b-filter-group-caption">
                <template v-if="!!$slots.caption">
                    <slot name="caption">{{ caption }}</slot>
                </template>
                <span v-else-if="!labelFor" class="vue-caption">
                    <slot name="caption">{{ caption }}</slot>
                </span>
                <label v-else class="vue-label" :for="labelFor">{{ caption }}</label>
            </div>
            <div v-if="collapsible" class="vue-b-filter-group-controls">
                <frm1006-button
                    v-if="collapsible"
                    type="internal"
                    class="vue-ci-button-toggle-visibility"
                    :title="buttonToggleVisibilityCaption"
                    @buttonClickEvent="buttonToggleVisibilityClick"
                >
                    {{ buttonToggleVisibilityCaption }}
                </frm1006-button>
            </div>
        </div>

        <!--========== CONTENT ================================-->
        <!--===================================================-->
        <div class="vue-b-filter-group-content">
            <slot></slot>
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//=== MIXINS
import Localization from '../../mixins/localization';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1028FilterGroup',
    components: {
        Frm1006Button
    },
    mixins: [Localization],
    props: {
        active: Boolean,
        collapsible: Boolean,
        collapsed: Boolean,
        buttonToggleVisibilityExpandCaption: String,
        buttonToggleVisibilityCollapseCaption: String,
        caption: String,
        labelFor: String,
        id: String
    },
    data() {
        return {
            collapsedData: this.collapsed
        };
    },
    computed: {
        classObject() {
            return {
                'vue-is-collapsible': this.collapsible,
                'vue-is-collapsed': this.collapsible && this.collapsedData,
                'vue-is-expanded': this.collapsible && !this.collapsedData,
                'vue-has-header': this.hasHeader
            };
        },
        hasHeaderDefault() {
            return !!this.$slots.caption || this.caption;
        },
        hasHeaderCustom() {
            return !!this.$slots.header;
        },
        hasHeader() {
            return this.hasHeaderDefault || this.hasHeaderCustom;
        },
        buttonToggleVisibilityCaption() {
            if (this.collapsedData) {
                return this.buttonToggleVisibilityExpandCaption
                    ? this.buttonToggleVisibilityExpandCaption
                    : this.i18n('sectionToggleVisibilityExpand');
            }

            return this.buttonToggleVisibilityCollapseCaption
                ? this.buttonToggleVisibilityCollapseCaption
                : this.i18n('sectionToggleVisibilityCollapse');
        }
    },
    watch: {
        collapsed(value) {
            this.collapsedData = value;
        },
        collapsedData(value) {
            this.$emit('collapsedChangeEvent', value);
            this.$emit('update:collapsed', value);
        }
    },
    methods: {
        buttonToggleVisibilityClick() {
            this.collapsedData = !this.collapsedData;
            this.$emit('buttonToggleVisibilityClickEvent');
        }
    }
};
</script>
