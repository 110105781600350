<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        :infoButton="true"
        :infoButtonDestination="'surf-studio-surf-kemp-info'"
        :infoButtonDestinationParams="infoButtonDestinationParams"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div
          v-for="(period, periodIndex) in ['future', 'history']"
          :key="periodIndex"
          class="vue-b-actions"
        >
          <div class="vue-action-title">{{ $t('surfStudio.surfKemp')[period] }}</div>
          <div
            v-for="(year, index) in Object.keys(events[period]).sort((a, b) => (a.activityFrom > b.activityFrom) ? 1 : -1)"
            :key="'year-' + index"
          >
            <div class="vue-action-title">{{ year }}</div>
            <div
              v-for="(event, yearIndex) in Object.values(events[period][year])"
              :key="'week-action-' + yearIndex"
              class="vue-action-item"
              :class="classObject(event)"
              @click="goToActionDetail(event.id)"
            >
              <div class="vue-b-date">
                <span
                  class="vue-date"
                  v-html="convertToActionDate(event.activityFrom)"
                />
                <span
                  class="vue-day-name"
                  v-html="convertToActionDayName(event.activityFrom)"
                />
              </div>
              <div class="vue-b-name">
                <span class="vue-name">{{ event.name }}</span>
              </div>
            </div>
          </div>
        </div>

        <gen1006-info-panel
          v-if="isObjectEmpty(events)"
          type="neutral"
          class="vue-no-data"
        >
          {{ $t('weekActions.noActionsAvailable') }}
        </gen1006-info-panel>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '../../mixins/mx-module-statistics';

import {ERROR_MODAL_TIMEOUT, SURF_STUDIO_DASHBOARD_ID} from '@/constants/app-constants';
import { WEEK_ACTIONS } from '@/store/store-modules';
import {mapState} from 'vuex';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import router from '@/router';
import Vue from 'vue';
import utilsGeneral from '@/utils/utils-general';

export default {
  name: 'SurfStudioSurfKemp',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics
  ],

  data() {
    return {
      events: {},
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      infoPageId: null
    }
  },

  computed: {
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded',
    ]),

    eventsCategoryId() {
      let categoryId = 0;
      if (this.applicationConfigLoaded) {
        let tmpCustomFields = this.applicationConfig.customFields;
        tmpCustomFields.forEach(item => {
          if(item.key === 'SURFSTUDIO_EVENTS_CAT_ID') {
            categoryId = parseInt(item.value);
          }
        });
      }
      return categoryId;
    },

    infoButtonDestinationParams() {
      return {id: this.infoPageId}
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.getSurfStudioKempInfo();
    this.getSurfKempEvents().then(() => {
      this.initialized = true;
    });

    this.sendStatistics(WEEK_ACTIONS);
  },

  methods: {
    handleLoader(loaderState) {
      this.isLoading = loaderState;
    },

    getSurfStudioKempInfo() {
      return dataLoader.fetchApiDataList(SURF_STUDIO_DASHBOARD_ID)
        .then(data => {
          this.infoPageId = data.surfStudioKempPageId;
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          this.hasError = true;
        })
    },

    getSurfKempEvents() {
      return dataLoader.fetchSurfKempEvents()
        .then(data => {
          this.events = data;
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        });
    },

    convertToActionDate(date) {
      return Vue.filter('convertDate')(date, 'day') + '.' +
        Vue.filter('convertDate')(date, 'month') + '.';
    },

    convertToActionDayName(date) {
      date = new Date(utilsGeneral.normalizeDateString(date));
      let dateDay = (date.getDay() === 0 ? 6 : date.getDay() - 1);
      let weekDayName = this.$i18n.t('abbreviation.days.' + dateDay);
      return weekDayName;
    },

    goToActionDetail(id) {
      router.push({ name: 'event-detail', params: { id: id, origin: this.$route.name } });
    },

    classObject(event) {
      return [
        'vue-is-event',
        {
          'vue-is-my-action': event.isRegistered
        }
      ];
    },

    isObjectEmpty(obj) {
      return obj && Object.keys(obj).length === 0
    },
  },
}
</script>
