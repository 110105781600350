import { render, staticRenderFns } from "./MarketplaceRules.vue?vue&type=template&id=243ee626&"
import script from "./MarketplaceRules.vue?vue&type=script&lang=js&"
export * from "./MarketplaceRules.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports