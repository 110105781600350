<template>
  <div class="vue-event-list-filter">
    <frm1006-button
      v-if="displayFilterButton"
      type="app-button"
      class="vue-event-list-filter-button"
      :icon-left="true"
      @buttonClickEvent="openFilterDialog"
    >
      {{ $t('actions.filter') }}
    </frm1006-button>

    <frm1006-button
      v-else
      class="vue-event-list-filter-remove"
      type="app-button"
      :icon-left="true"
      @buttonClickEvent="resetFilter"
    >
      {{ $t('actions.removeFilter') }}
    </frm1006-button>

    <frm1006-button
      v-if="releaseEnabled"
      type="app-button"
      :icon-left="true"
      class="vue-event-list-action-button"
      @buttonClickEvent="openCustomEventDialog"
    >
      {{ $t('actions.newActions') }}
    </frm1006-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Prj1027EventFilter',
  props: {
    displayFilterButton: {
      type: Boolean,
      required: true
    },
    displayActionButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      selectedBuilding: '',
      releaseEnabled: true
    }
  },

  computed: {
    ...mapState('events', [
      'eventsSelectedCategories'
    ]),
    ...mapState('forvardino', [
      'forvardinoAreFiltersActive'
    ]),
  },

  methods: {
    openFilterDialog(event) {
      this.$emit('openFilterDialog', event);
    },

    resetFilter() {
      this.$emit('resetFilteredItems');
    },

    openCustomEventDialog() {
      this.$emit('openCustomEventDialog');
    }
  }
}
</script>
