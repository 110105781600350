<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    class="flex flex-col items-center h-28 xs:h-32 px-4 justify-evenly rounded-xl"
    :class="{'cursor-pointer': routeName}"
    @click="goToPage"
  >
    <span
      v-if="hasIcon"
      class="relative flex items-end top-3 h-2/6 text-black"
    >
      <slot name="icon" />
    </span>

    <span
      class="flex items-center h-4/6 text-center text-base leading-6 font-semibold text-black"
    >
      <slot name="text" />
    </span>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1002Tile',
  components: {},
  props: {
    routeName: String
  },
  computed: {
    hasIcon() {
      return !!this.$slots.icon;
    }
  },
  methods: {
    goToPage() {
      if (this.routeName) {
        this.$router.push({ name: this.routeName });
      }
    }
  }
}
</script>
