<template>
  <div class="vue-c-star-rating">
    <div class="vue-star-rating-star" />
    {{ parseFloat(itemRating).toFixed(digitsNumber) }}
  </div>
</template>

<script>
export default {
  name: 'Prj1019StarRating',
  props: {
    itemRating: {
      type: [Number, String],
      required: true
    },
    digitsNumber: {
      type: Number,
      required: false,
      default: 1
    }
  }
}
</script>
