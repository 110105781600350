<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    v-if="active"
    class="absolute"
  >
    <prj1009-modal
      :active="active"
      @overlayCloseEvent="closeLoginOverlay"
    >
      <template v-slot:title>
        <!--========== TITLE ==================================-->
        <!--===================================================-->
        <template v-if="fullIdentity">
          {{ $t("fest.loginModal.full.headline") }}
        </template>
        <template v-else>
          {{ $t("fest.loginModal.email.headline") }}
        </template>
      </template>

      <template v-slot:content>
        <div class="flex flex-col">
          <!--========== DESCRIPTION ============================-->
          <!--===================================================-->
          <p
            v-if="fullIdentity"
            class="mb-4 xs:mb-8"
          >
            {{ $t("fest.loginModal.full.description") }}
          </p>
          <p
            v-else
            class="mb-4 xs:mb-8"
          >
            {{ $t("fest.loginModal.email.description") }}
          </p>

          <!--========== FIELDS =================================-->
          <!--===================================================-->
          <t-input
            v-model="fields.email"
            :placeholder="$t('fest.forms.fields.email')"
            class="mb-4"
            :disabled="hasUserEmail"
          ></t-input>
          <t-input
            v-if="fullIdentity"
            v-model="fields.fullName"
            :placeholder="$t('fest.forms.fields.fullName')"
            class="mb-4"
          ></t-input>
          <t-input
            v-if="fullIdentity"
            v-model="fields.workPosition"
            :placeholder="$t('fest.forms.fields.workPosition') + $t('fest.forms.fields.notRequire')"
            class="mb-4"
          ></t-input>
          <prj1011-image-upload
            v-if="fullIdentity"
            v-model="fields.photoBase64"
            :cropped-image-height="128"
            :placeholder="$t('fest.forms.fields.userPhoto')"
          />
        </div>

        <!--========== CONSENT ================================-->
        <!--===================================================-->
        <div
          v-if="fullIdentity"
          class="mb-6"
        >
          <a
            class="prj-text-link cursor-pointer"
            @click="openPrivacyPolicy"
          >
            {{ $t("fest.forms.consents.privacyPolicy") }}
          </a>
        </div>
      </template>

      <!--========== CONTROLS ===============================-->
      <!--===================================================-->
      <template v-slot:controls>
        <t-button
          class="w-full"
          :disabled="!filledRequiredFields"
          @click="submitForm"
        >
          {{ $t("fest.forms.buttons.buttonSend") }}
        </t-button>
      </template>
    </prj1009-modal>

    <prj1009-modal
      :active="policyModalShow"
      @overlayCloseEvent="closePolicyOverlay"
    >
      <!--========== TITLE ==================================-->
      <!--===================================================-->
      <template v-slot:title>
        {{ policyTitle }}
      </template>

      <!--========== CONTENT ================================-->
      <!--===================================================-->
      <template v-slot:content>
        <div class="flex flex-col">
          <div v-html="policyContent" />
        </div>
      </template>

      <!--========== CONTROLS ===============================-->
      <!--===================================================-->
      <template v-slot:controls>
        <t-button
          class="w-full"
          @click="closePolicyOverlay"
        >
          {{ $t("fest.forms.buttons.buttonClose") }}
        </t-button>
      </template>
    </prj1009-modal>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import {mapActions, mapGetters, mapState} from 'vuex';
import utilsGeneral from '@/utils/fest/utils-general';

import Prj1009Modal from '@/components/fest/prj1009-modal/prj1009-modal';
import Prj1011ImageUpload from '@/components/fest/prj1011-image-upload/prj1011-image-upload';
import apiCmsUser from '@/fest-api/cms-user';
import logger from '@/utils/logger';

import * as MUTATIONS_CONSTANTS from '@/store/constants/fest/mutations';
import * as STORE_MODULES from '@/store/store-modules';
import { GET_APP_CONFIG } from '@/store/constants/fest/actions';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1010LoginModal',
  components: {Prj1011ImageUpload, Prj1009Modal},
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fullIdentity: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      fields: {
        email: '',
        fullName: '',
        workPosition: '',
        photoBase64: ''
      },
      sending: false,
      sendFailed: false,
      policyPage: null,
      policyModalShow: false
    }
  },
  computed: {
    ...mapState('festConfig', ['personalPrivacyPolicyPage']),
    ...mapState('festUser', {
      userName: state => state.name,
      userEmail: state => state.email,
      userPosition: state => state.position,
      userImage: state => state.image,
      userToken: state => state.token,
      userTokenExpiration: state => state.tokenExpiration
    }),
    ...mapGetters('festUser', ['isUserIdentityFull', 'isUserIdentityPartial']),
    filledRequiredFields() {
      if (this.fullIdentity) {
        return utilsGeneral.isValidEmail(this.fields.email) && this.fields.fullName.length > 0;
      }

      return utilsGeneral.isValidEmail(this.fields.email);
    },
    hasUserEmail() {
      return utilsGeneral.isValidEmail(this.userEmail);
    },
    isUserExists() {
      return this.fullIdentity ? this.isUserIdentityFull : this.isUserIdentityPartial
    },
    policyTitle() {
      return this.personalPrivacyPolicyPage?.page?.post_title
    },
    policyContent() {
      return this.personalPrivacyPolicyPage?.page?.post_content
    },
  },
  created() {
    // temp condition disabled
    //if (!this.personalPrivacyPolicyPage) this.GET_APP_CONFIG()
    this.GET_APP_CONFIG()
  },
  mounted() {
    if (this.hasUserEmail) {
      this.fields.email = this.userEmail;
    }
  },
  methods: {
    ...mapActions('festConfig', [GET_APP_CONFIG]),
    closeLoginOverlay() {
      this.$emit('loginClose', 'clickOutside');
    },
    closePolicyOverlay() {
      this.policyModalShow = false;
    },
    submitForm() {
      let processId = utilsGeneral.startAsyncProcess();
      this.sendFailed = false;
      this.sending = true;

      if (!this.isUserExists) {
        this.$store.commit(STORE_MODULES.FEST_USER + '/' + MUTATIONS_CONSTANTS.SET_USER_EMAIL, this.fields.email);
        this.$store.commit(STORE_MODULES.FEST_USER + '/' + MUTATIONS_CONSTANTS.SET_USER_NAME, this.fields.fullName);
        this.$store.commit(STORE_MODULES.FEST_USER + '/' + MUTATIONS_CONSTANTS.SET_USER_POSITION, this.fields.workPosition);
        this.$store.commit(STORE_MODULES.FEST_USER + '/' + MUTATIONS_CONSTANTS.SET_USER_IMAGE, this.fields.photoBase64);
      }

      this.sendingForm({
        email: this.userEmail,
        name: this.userName,
        position: this.userPosition,
        image: this.userImage,
        processId: processId
      });
    },
    sendingForm(userData) {
      const { processId, ...rest } = userData

      apiCmsUser
        .registration(rest)
        .then((result) => {
          this.$store.commit(STORE_MODULES.FEST_USER + '/' + MUTATIONS_CONSTANTS.SET_USER_TOKEN, result.data.token);
          this.$store.commit(STORE_MODULES.FEST_USER + '/' + MUTATIONS_CONSTANTS.SET_USER_TOKEN_EXPIRATION, result.data.exp);
          logger.info('result: ', result);
          logger.info('Form has been sent.');
        })
        .catch(error => {
          this.sendFailed = true;
          logger.error('Sending form has failed.', error);
        })
        .finally(() => {
          this.sending = false;
          utilsGeneral.stopAsyncProcess(processId);
          this.closeLoginOverlay();
          this.$emit('loginDone');
        });
    },
    openPrivacyPolicy() {
      this.policyModalShow = true;
    },
  }
}
</script>
