<template>
  <page-layout
    ref="pageLayout"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-plan-controls">
        <frm1035-form-group>
          <frm1028-advanced-combo
            id="branch-selector"
            v-model="buildingId"
            :options="buildings"
            :label="$i18n.t('emergency.buildingPlanPlaceholder')"
            :tooltip="false"
            :searchable="false"
          />
        </frm1035-form-group>

        <gen1006-info-panel
          type="neutral"
          class="vue-no-data"
        >
          {{ $t('emergency.buildingPlansDescription') }}
        </gen1006-info-panel>

        <a
          class="vue-plan-item-link"
          :href="emergencyBuildingPlans.fileUrl"
          target="_blank"
          download
        >
          <span class="vue-plan-item-icon" />
          {{ $t('emergency.buildingPlanDownloadLink') }}
        </a>
      </div>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        class="vue-button-register"
        :disabled="!buildingId"
        @buttonClickEvent="goToDetail"
      >
        {{ $t('general.continue') }}
      </frm1006-button>
    </template>
  </page-layout>
</template>

<script>
import router from '@/router';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import mxNavigationDefault from '@/mixins/mx-navigation-default';

import evacuationPlans from '@/assets/mocks/emergency/evacuationPlans';

export default {
  name: 'EmergencyBuildingPlans',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      navbarCaptionData: null,
      emergencyBuildingPlans: evacuationPlans,
      buildingId: ''
    };
  },

  computed: {
    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },

    buildings() {
      let buildings = [];

      for (const [i, plan] of this.emergencyBuildingPlans.plans.entries()) {
        const value = {
          value: (i + 1).toString(),
          caption: plan.name
        }
        buildings.push(value);
      }

      return buildings;
    },

    isPrivateApp() {
      return process.env.VUE_APP_DESTINATION === 'private'
    },
  },

  watch: {},

  mounted() {},

  methods: {
    goToDetail() {
      router.push({ name: 'emergency-building-plan-detail', params: { id: this.buildingId } });
    }
  },
}
</script>

