<template>
  <div class="vue-c-competition-item">
    <div
      v-if="questionImage"
      class="vue-question-icon"
    >
      <img :src="questionImage">
    </div>
    <div
      v-else
      class="vue-question-caption"
    >
      <span class="vue-number">{{ questionCaption }}</span>
    </div>
    <h3 class="vue-question">
      {{ question }}
    </h3>
    <frm1031-button-container class="vue-answers-container">
      <frm1006-button
        v-for="(item, index) in answers"
        :key="index"
        type="answer"
        class="vue-is-answer"
        :class="classObject(index)"
        :data-value="index"
        @buttonClickEvent="checkAnswer(index)"
      >
        {{ item.answer }}
      </frm1006-button>
    </frm1031-button-container>
  </div>
</template>

<script type="application/javascript">

import {mapState} from 'vuex';
import {
  STRATEGY_DEFAULT_LANG,
  STRATEGY_COMPETITION_ACTIVE
} from '@/constants/app-constants';

import * as STORE_MODULES from '../../store/store-modules';
import * as MUTATIONS_CONSTANTS from '../../store/constants/mutations';

export default {
  name: 'Prj1022CompetitionItem',
  props: {
    questionId: {
      type: Number,
      required: true,
      default: 0
    },
    question: {
      type: String,
      required: true,
      default: ''
    },
    questionCaption: {
      type: String,
      default: ''
    },
    questionImage: {
      type: String,
      default: ''
    },
    answerSelected: {
      type: Boolean,
      required: true,
      default: false
    },
    answers: {
      type: Object,
      default: () => {
        return {}
      }
    },
    competitionData: {
      type: Object,
      required: true
    },
    lang: {
      type: String,
      default: STRATEGY_DEFAULT_LANG
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState('competition', [
      'competitions'
    ])
  },

  methods: {
    classObject(index) {
      return [
        'vue-is-' + this.state,
        {
          'vue-is-selected': this.getAnswerSelected(index),
          'vue-is-correct': this.getAnswerCorrect(index),
          'vue-is-incorrect': this.getAnswerIncorrect(index),
          'vue-is-indeterminate': this.indeterminate,
          'vue-has-decorator': this.decorator,
          'vue-is-checked': this.checked,
          'vue-is-component-active': this.componentIsActive
        }
      ];
    },

    getAnswerSelected(index) {
      if(typeof this.competitions.data[STRATEGY_COMPETITION_ACTIVE] === 'undefined') {
        return false;
      }
      if(!Object.prototype.hasOwnProperty.call(this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results, this.questionId)) {
        return false;
      }
      return this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results[this.questionId] === parseInt(index);
    },

    getAnswerTruth(index) {
      return this.competitionData[this.lang]['items'][this.questionId].answers[index].truth;
    },

    getAnswerCorrect(index) {
      return ( this.getAnswerTruth(index) && this.answerSelected );
    },

    getAnswerIncorrect(index) {
      return ( !this.getAnswerTruth(index) && this.getAnswerSelected(index) );
    },

    checkAnswer(answerId) {
      if(this.answerSelected) {
        return false;
      }

      if (typeof this.competitions.data[STRATEGY_COMPETITION_ACTIVE] !== 'undefined') {
        let tmpResults = this.competitions.data[STRATEGY_COMPETITION_ACTIVE].results;
        this.$store.commit(STORE_MODULES.COMPETITION + '/' + MUTATIONS_CONSTANTS.SET_COMPETITION_ANSWER, Object.assign(tmpResults,{
          [this.questionId]: parseInt(answerId)
        }));

      } else {
        this.$store.commit(STORE_MODULES.COMPETITION + '/' + MUTATIONS_CONSTANTS.SET_COMPETITION_ANSWER, {
          [this.questionId]: parseInt(answerId)
        });
      }

      this.$emit('quizAnswerSelected');
      return true;
    },
  }
}
</script>
