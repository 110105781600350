<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1017-heading
        :header="$i18n.t('recruitment.searchResult')"
      />
      <div class="vue-b-list">
        <div
          v-for="(item, index) in itemsData"
          :key="index"
          :ref="'job' + index"
          class="vue-listing-list-item"
          @click="redirectToArticle(index,item)"
        >
          <template v-if="item.imagePath">
            <div
              class="vue-list-item-image"
              :style="styleObject(item.imagePath)"
            />
          </template>

          <template v-else>
            <div
              class="vue-list-item-image"
            />
          </template>

          <div class="vue-list-item-info">
            <h3
              class="vue-list-item-name"
              v-html="item.name"
            >
            </h3>

            <p class="vue-list-item-metainfo">
              <span
                class="vue-list-item-tag"
              >
                {{ item.regions[0] }}
              </span>
            </p>
          </div>
        </div>

        <div
          v-if="isInfinityScrollEnabled"
          ref="infinityScrollTrigger"
        >
          <gen1016-loading-indicator
            :active="isInfinityScrollEnabled"
          />
        </div>
      </div>

      <gen1006-info-panel
        type="neutral"
        class="vue-additional-info"
      >
        {{ $t('recruitment.noteRecruitmentListing') }}
      </gen1006-info-panel>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        type="primary"
        :title="$i18n.t('recruitment.submitNotFound')"
        @buttonClickEvent="redirectToInterestForm"
      >
        {{ $t('recruitment.submitNotFound') }}
      </frm1006-button>
    </template>
  </page-layout>
</template>

<script type="application/javascript">
import {mapState} from 'vuex';

import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import router from '@/router';
import dataLoader from '@/utils/data-loader';

import * as envConfig from 'env-config';
import * as STORE_MODULES from '../../store/store-modules';
import * as MUTATIONS_CONSTANTS from '../../store/constants/mutations';
import mxInfinityScrollingVue from '../../mixins/mx-infinity-scrolling.vue';

export default {
  name: 'RecruitmentListing',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
    mxInfinityScrollingVue
  ],

  data() {
    return {
      recruitmentInterestFormValues: {
        name: '',
        email: '',
        tel: '',
        message: '',
      },
      reportFailed: false,
      validationErrors: [],
      itemsData: [],
      recordCount: 0
    }
  },

  computed: {
    ...mapState('user', [
      'deviceId'
    ]),
    ...mapState('recruitment', [
      'recruitment'
    ]),

    lastViewedIndex() {
      return this.$route.params.index;
    },

    renderCmsUrl() {
      return envConfig.default.webServices.BASE_CMS_URL;
    },

    itemsLoaded() {
      return Object.keys(this.recruitment.data).length;
    },

    recruitmentFormValues() {
      return {
        region: this.recruitment.filterLocation,
        specialization: this.recruitment.filterSpecialization,
        type: this.recruitment.filterType,
        search: this.recruitment.filterKeyword
      }
    },

    isInfinityScrollEnabled() {
      return this.itemsData.length < this.recordCount;
    },
  },

  mounted() {
    this.getRecordCount()  // set count of jobs found
      .then(() => {
        this.getData().then(() => {   // get data - either from store, or network
          // init infinity scroll
          if (this.isInfinityScrollEnabled) {
            // wait for ref to be initialized
            this.$nextTick( () => {
              this.initInfinityScroll();
            });
          }
          // scroll to last viewed item
          if (this.lastViewedIndex && this.lastViewedIndex > 0) {
            this.$nextTick( () => {
              this.$refs['job' + (this.lastViewedIndex - 1)][0].scrollIntoView({ behavior: 'smooth' });
            });
          }
        })
      })
  },

  methods: {
    getData() {
      if (this.itemsLoaded === 0) { // no data in store, get intial batch from network
        return this.getMoreItems();
      } else {
        this.itemsData = this.convertDataToRepeater(this.recruitment.data);
        return Promise.resolve();
      }
    },

    parseFormToUrl() {
      let formValues = this.recruitmentFormValues;
      return Object.keys(formValues).map(function(key) {
        if(formValues[key].length > 0) {
          return key + '=' + formValues[key];
        } else {
          return key + '=0';
        }
      }).join('&');
    },

    getRecordCount() {
      return dataLoader
        .fetchRecruitmentResultsCount(this.parseFormToUrl())
        .then((countData) => {
          this.recordCount = parseInt(countData.count);
        });
    },

    getMoreItems() {
      return dataLoader
        .fetchRecruitmentResults(this.parseFormToUrl() + '&offset=' + this.itemsLoaded + '&limit=10')
        .then((resultsData) => {
          if(resultsData) {
            const mergedData = {...this.recruitment.data, ...resultsData};
            this.$store.commit(STORE_MODULES.RECRUITMENT + '/' + MUTATIONS_CONSTANTS.SET_RECRUITMENT_RESULT_DATA, mergedData);
            this.itemsData = this.convertDataToRepeater(mergedData);
          }
        });
    },

    renderRegionName(regId) {
      let result = '';
      if (regId !== null) {
        let regionsData = this.recruitment.registries.regions;

        Object.keys(regionsData).forEach(function (value) {
          if (regionsData[value].id === regId) {
            result = regionsData[value].name;
          }
        });
      }
      return result;
    },

    redirectToArticle(index, data) {
      this.index = index;
      router.push({name: 'recruitment-detail', params: {id: data.id, index: index}});
      // save registries to store
      this.$store.commit(STORE_MODULES.RECRUITMENT + '/' + MUTATIONS_CONSTANTS.SET_RECRUITMENT_DETAIL_DATA, data);
    },

    convertDataToRepeater(data) {
      let result = [];
      Object.keys(data).reverse().forEach(function (index) {
        result.push(data[index]);
      });
      return result;
    },

    loadNewContent() {
      this.getMoreItems();
    },

    redirectToInterestForm() {
      router.push({name: 'recruitment-listing-form'});
    },

    styleObject(imagePath) {
      if (imagePath) {
        return 'background-image: url("' + this.renderCmsUrl + imagePath + '");'
      } else {
        return ''
      }
    },
  },
}
</script>
