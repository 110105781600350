<template>
  <div>
    <prj1009-modal
      :active="showLoginModal"
      @overlayCloseEvent="showLoginModal=false"
    >
      <template v-slot:title>
        {{ $t('intro.loginRedirectModal.headline') }}
      </template>

      <template v-slot:content>
        <div
          class="mb-10"
        >
          <p>{{ $t('intro.loginRedirectModal.description') }}</p>
        </div>
      </template>

      <template v-slot:controls>
        <t-button
          class="bg-fest-home"
          @click="redirectButtonClick()"
        >
          {{ $t("intro.loginRedirectModal.buttonSubmit") }}
        </t-button>
      </template>
    </prj1009-modal>

    <frm1006-button
      :title="title"
      :type="type"
      :disabled="disabled"
      @buttonClickEvent="buttonClick($event)"
    >
      <slot></slot>
    </frm1006-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Prj1009Modal from '@/components/fest/prj1009-modal/prj1009-modal';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';

export default {
  name: 'Prj1049LoginRedirectButton',
  components: {
    Prj1009Modal
  },
  mixins: [],
  props: {
    buttonId: {
      type: String,
      required: false,
      default: ''
    },
    type: String,
    title: String,
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      showLoginModal: false
    }
  },

  computed: {
    ...mapGetters('user', {
      'hasIdentity': GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY,
    }),
    paramAction() {
      if(typeof this.$route.params?.action === 'undefined') {
        return '';
      }
      return this.$route.params.action
    },
  },

  created() {
    if (this.paramAction === ('afterLoginEvent' + this.buttonId)) {
      this.$emit('afterLoginEvent');
    }
  },

  methods: {
    buttonClick(event) {
      if (this.hasIdentity) {
        this.$emit('afterLoginEvent', event);
      } else {
        this.showLoginModal = true;
      }
    },

    redirectButtonClick() {
      this.$router.push({ name: 'login', params: {
        authType: 'registration',
        authReferer: this.$route.name,
        authRefererParams: this.$route.params,
        authRefererAction: 'afterLoginEvent' + this.buttonId,
        forward: true,
        introDescription: this.$i18n.t('intro.message2'),
      }});
    }
  }
}
</script>
