import axiosConfig from '@/utils/data-loader/axiosConfig'
import * as envConfig from 'env-config'
import utilsGeneral from '@/utils/esg/utils-general'
import logger from '@/utils/logger'

export default {
  async getAllSocialLinks() {
    const processId = utilsGeneral.startAsyncProcess()

    try {
      const res = await axiosConfig.get(envConfig.default.webServices.ESG_WEB_SERVICES.CMS_CONTENT_SOCIAL)

      const {data, status} = res
      if (status === 200) return {data, status}
    } catch (error) {
      // TODO JBa Provide proper error handling
      logger.error(error.response)
      return error.response.status
    } finally {
      utilsGeneral.stopAsyncProcess(processId)
    }
  },
}
