import axios from 'axios';
import axiosConfig from './axiosConfig';
import * as envConfig from 'env-config';
import utilsGeneral from '@/utils/utils-general';
import logger from '../logger';
import authorizedModeDataLoaders from './authorized-mode';
import store from '@/store/index.js';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

// Workaround iOS - make sure styles always reload
const VERSION_CHECK = envConfig.default.webServices.VERSION_CHECK + '?v=' + Math.random().toString(8);

// FIXME MSE: this is not utility, this is in fact API client - should be somewhere in /api/ and split into relevant modules
export default {
  ...authorizedModeDataLoaders,
  fetchAuthorization(formData) {
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return axiosConfig
      .post(envConfig.default.webServices.CMS_IDENTITY_AUTHORIZATION, formData, options)
      .then(response => response.data);
  },

  fetchUnauthorization() {
    return axiosConfig
      .post(envConfig.default.webServices.CMS_IDENTITY_UNAUTHORIZATION)
      .then(response => response.data);
  },

  updateModuleUsageStatistics(module, contentId) {
    let paramsObj = {'module': module, 'measure': 'visited'};
    if(contentId) {
      paramsObj.contentId = contentId;
    }
    return axiosConfig
      .get(envConfig.default.webServices.CMS_UPDATE_STATISTICS, {params: paramsObj})
      .then(() => {
        logger.info('Visited stats sent ok.');
      }, (error) => {
        logger.error('Visited stats failed.', error);
      });
  },

  fetchDataPrefetchInfo(data, loc, cats) {
    let formData = new FormData();
    formData.append('loc', loc);
    formData.append('cats', cats);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_CHECK_LAST_MODIFICATION, formData, options)
      .then(response => response.data);
  },

  fetchDataPrefetchItemIdsByCategories(categories, slugMode, loc, cats) {
    let formData = new FormData();
    formData.append('types', categories);
    formData.append('slugMode', slugMode);
    formData.append('loc', loc);
    formData.append('cats', cats);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_GET_UPDATED_AGGREGATE_SINCE, formData,  options)
      .then(response => response.data);
  },

  fetchItemIdsData(itemsIds) {
    let formData = new FormData();
    formData.append('ids', itemsIds);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_GET_ARTICLE_CONTENT, formData, options)
      .then(response => response.data);
  },

  fetchRestaurants() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_RESTAURANTS)
      .then(response => response.data)
      .then(responseData => {
        if (envConfig.default.webServices.PROXY_REQUIRED) {  // modify image URLs
          var respStr = JSON.stringify(responseData);
          respStr = respStr.replaceAll('https://digitalnimenu.cz/','/zapka-be/proxy/lunch/');
          responseData = JSON.parse(respStr);
        }
        return responseData;
      });
  },

  fetchCafes() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_CAFES)
      .then(response => response.data);
  },

  sendFoodRating(ratingData) {
    let formData = new FormData();
    formData.append('key', ratingData.foodIndex + 1);
    formData.append('score', ratingData.score);
    formData.append('deviceId', ratingData.deviceId);
    formData.append('restaurant', ratingData.restaurant);
    formData.append('name', ratingData.name);
    formData.append('category', ratingData.category);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_FOOD_RATING_STATS, formData, options)
      .then(response => response.data);
  },

  subscribeOnServer(subscription) {
    // convert subscription
    let subscriptionData = JSON.parse(JSON.stringify(subscription));
    // Note usage of URLSearchParams - needed for communication with backend
    // Do not use standard axiosConfig POST JSON - it won't work!
    let params = new URLSearchParams();
    params.append('mode', 'subscribe');
    params.append('endpoint', subscriptionData.endpoint);
    params.append('publicKey', subscriptionData.keys.p256dh);
    params.append('authToken', subscriptionData.keys.auth);
    params.append('destination', window.location.hostname);
    return axiosConfig
      .post(envConfig.default.webServices.PUSH_SERVER_API, params)
      .then(response => response.data);
  },

  unsubscribeOnServer(endpoint) {
    // Note usage of URLSearchParams - needed for communication with backend -
    // Do not use standard axiosConfig POST JSON - it won't work!
    let params = new URLSearchParams();
    params.append('mode', 'unsubscribe');
    params.append('endpoint', endpoint);
    return axiosConfig
      .post(envConfig.default.webServices.PUSH_SERVER_API, params)
      .then(response => response.data);
  },

  sendFaultReport(building, branch, category, room, phone, description, imageBase64, imageBinary) {
    // Note usage of FormData - needed for communication with backend -
    // Do not use standard axiosConfig POST JSON - it won't work!
    let formData = new FormData();
    formData.append('building', building);
    formData.append('branch', branch);
    formData.append('category', category);
    formData.append('room', room);
    formData.append('phone', phone);
    formData.append('description', description);
    if(imageBase64) {
      formData.append('imageBase64', imageBase64);
    }
    if(imageBinary) {
      formData.append('imageBinary', imageBinary);
    }
    // TODO: send also auth key and secure on BE also - create same solution as recruitment module
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.FAULT_REPORT_API, formData, options)
      .then(response => response.data);
  },

  checkVersions() {
    return axiosConfig
      .get(VERSION_CHECK)
      .then((response) => response.data);
  },

  fetchSurveyState() {
    return axiosConfig
      .get(envConfig.default.webServices.SURVEY_STATE)
      .then(response => response.data);
  },

  fetchSurveyResultsCount(surveyId) {
    return axiosConfig
      .get(envConfig.default.webServices.SURVEY_RESULT + '?id=' + surveyId)
      .then(response => response.data);
  },

  async fetchRecruitmentRegistries() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_RECRUITMENT_REGISTRIES)
      .then(response => response.data);
  },

  fetchRecruitmentResults(urlParams) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_RECRUITMENT_RESULTS + '?' + urlParams)
      .then(response => response.data);
  },

  fetchRecruitmentResultsCount(urlParams) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_RECRUITMENT_RESULTS_COUNT + '?' + urlParams)
      .then(response => response.data);
  },

  sendJobApplication(name, email, phone, deviceId, jobId) {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('deviceId', deviceId);
    formData.append('jobId', jobId);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_RECRUITMENT_SEND_JOB_APPLICATION, formData, options)
      .then(response => response.data);
  },

  sendJobApplicationSF(data) {
    let formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('startDate', data.startDate);
    formData.append('startingSalary', data.startingSalary);
    formData.append('cvFile', data.cvFile);
    formData.append('id', data.id);
    formData.append('regId', data.regId);
    formData.append('regStatus', data.regStatus);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_RECRUITMENT_SEND_JOB_APPLICATION_SF, formData, options)
      .then(response => response.data);
  },

  sendJobRecommendation(nameReferrer, emailReferrer, nameCandidate, emailCandidate, message, deviceId, jobId) {
    let formData = new FormData();
    formData.append('nameReferrer', nameReferrer);
    formData.append('emailReferrer', emailReferrer);
    formData.append('nameCandidate', nameCandidate);
    formData.append('emailCandidate', emailCandidate);
    formData.append('message', message);
    formData.append('deviceId', deviceId);
    formData.append('jobId', jobId);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_RECRUITMENT_SEND_JOB_RECOMMENDATION, formData, options)
      .then(response => response.data);
  },

  sendJobInterest(name, email, phone, interest, message, filter, source) {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('interest', interest);
    formData.append('source', source);
    formData.append('message', message);
    formData.append('filter', filter);

    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };

    return axiosConfig
      .post(envConfig.default.webServices.CMS_RECRUITMENT_SEND_JOB_ROTATION_INTEREST, formData, options)
      .then(response => response.data);
  },

  sendJobWatchdog(name, region, specialization, type, search) {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('region', region);
    formData.append('specialization', specialization);
    formData.append('type', type);
    formData.append('search', search);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_RECRUITMENT_JOB_WATCHDOG, formData, options)
      .then(response => response.data);
  },

  fetchJobWatchdogs() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_RECRUITMENT_JOB_WATCHDOG)
      .then(response => response.data);
  },

  async deleteJobWatchdog(id) {
    return await axiosConfig
      .delete(envConfig.default.webServices.CMS_RECRUITMENT_JOB_WATCHDOG + '/' + id)
      .then(response => response.data);
  },

  fetchRecruitmentDetail(urlParams) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_RECRUITMENT_DETAIL_RESULT + '?' + urlParams)
      .then(response => response.data);
  },

  fetchWorkoutsQuarters() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_WORKOUTS_QUARTERS)
      .then(response => response.data);
  },

  fetchWorkouts() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_WORKOUTS)
      .then(response => response.data);
  },

  fetchWorkout(id) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_WORKOUT, {params: {'id': id}})
      .then(response => response.data);
  },

  sendWorkoutApplication(workoutData) {
    let formData = new FormData();
    formData.append('name', workoutData.name);
    formData.append('email', workoutData.email);
    formData.append('phone', workoutData.tel);
    formData.append('deviceId', workoutData.deviceId);
    formData.append('trainingId', workoutData.trainingId);
    formData.append('lessonId', workoutData.lessonId);
    formData.append('seasonApplication', workoutData.seasonApplication);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_WORKOUT_SEND_REGISTRATION, formData, options)
      .then(response => response.data);
  },

  fetchPostsFilterCategories() {
    let formData = new FormData();
    formData.append('fill', 0);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_POSTS_FILTER_CATEGORIES, formData, options)
      .then(response => response.data);
  },

  fetchPostsByCategories(options) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        'cats': options.categories,
        'offset': options.offset,
        'limit': options.amount,
        'loc': options.loc,
        'search': options.search
      }
    };
    return axiosConfig
      .get(envConfig.default.webServices.CMS_POSTS_BY_CATEGORIES, config)
      .then(response => response.data);
  },

  fetchRecommendedPosts() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_RECOMMENDED_POSTS)
      .then(response => response.data);
  },

  fetchPostById(postId, priv) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_POST_DETAIL + '?post_id=' + postId + (priv ? '&private=1' : ''))
      .then(response => response.data);
  },

  fetchMultiplePostsById(postIdsArray) {
    let requestsArray = [];

    postIdsArray.forEach(postId => {
      requestsArray.push(axiosConfig.get(envConfig.default.webServices.CMS_POST_DETAIL + '?post_id=' + postId));
    });

    return Promise.all(requestsArray);
  },

  fetchEvent(id) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_EVENT, {params: {'id': id}})
      .then(response => response.data);
  },

  fetchEventsFilterCategories() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_EVENTS_FILTER_CATEGORIES)
      .then(response => response.data);
  },

  fetchEventsFilter(categories, search, offset, amount) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_EVENTS_FILTER, {
        params: {
          'cats': utilsGeneral.parseNumbersFromArray(categories),
          'search': search,
          'offset': offset,
          'amount': amount
        }
      })
      .then(response => response.data);
  },

  fetchMyEvents() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_EVENTS_FETCH_MY_EVENTS)
      .then(response => response.data);
  },

  fetchFilteredServices(location, page, limit, term) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_GET_SERVICES, {params: {'loc': location, 'page': page, 'limit': limit, 'search': term}})
      .then(response => response.data);
  },


  fetchMyServiceDetail(serviceId) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_GET_SERVICE_CONTENT + '/' + serviceId)
      .then(response => response.data);
  },

  sendEventApplication(actionId) {
    let formData = new FormData();
    formData.append('actionId', actionId);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_EVENT_SEND_REGISTRATION, formData, options)
      .then(response => response.data);
  },

  cancelEventApplication(actionId) {
    return axiosConfig
      .delete(envConfig.default.webServices.CMS_EVENT_SEND_REGISTRATION + '/' + actionId)
      .then(response => response.data);
  },

  getMenuNavigationData(locationId) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_MENU_ITEMS + '/' + locationId)
      .then(response => response.data);
  },

  getApiAppSettings() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_API_APP_SETTINGS)
      .then(response => response.data);
  },

  getMarketplaceFilters() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_MARKETPLACE_FILTERS)
      .then(response => response.data);
  },

  getMarketplaceFilteredList(options) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_MARKETPLACE_ITEMS, {
        params: {
          'filter': options[0],
          'sort': options[1],
          'offset': options[2],
          'limit': options[3]
        }
      })
      .then(response => response.data);
  },

  getMarketplaceDetail(id) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_MARKETPLACE_ITEMS + '/' + id)
      .then(response => response.data);
  },

  sendMarketplaceAd(formData) {
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_MARKETPLACE_ITEMS, formData, options)
      .then(response => response.data);
  },

  deleteMarketplaceAd(adId) {
    return axiosConfig
      .delete(envConfig.default.webServices.CMS_MARKETPLACE_ITEMS + '/' + adId)
      .then(response => response.data);
  },

  putMarketplaceAdBookmark(adId) {
    let formData = new FormData();
    formData.append('id', adId);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_MARKETPLACE_ITEMS_BOOKMARK, formData, options)
      .then(response => response.data);
  },

  getForvardinoFilters() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_FORVARDINO_FILTERS)
      .then(response => response.data);
  },

  getForvardinoFilteredList(options) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_FORVARDINO_COURSES, {
        params: {
          'cats': utilsGeneral.parseNumbersFromArray(options[0]),
          'locs': options[1],
          'trgs': utilsGeneral.parseNumbersFromArray(options[2]),
          'online': options[3],
          'search': options[4],
          'offset': options[5],
          'limit': options[6]
        }
      })
      .then(response => response.data);
  },

  getForvardinoEventDetail(id) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_FORVARDINO_DETAIL + '?id=' + id)
      .then(response => response.data);
  },

  getForvardinoFilteredItemsNumber(categories, locations, targets, online, search) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_FORVARDINO_FILTERED_ITEMS_NUMBER + '?cats=' + categories + '&locs=' + locations + '&trgs=' + targets + '&online=' + online + '&search=' + search)
      .then(response => response.data);
  },

  getForvardinoLectorDetail(id) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_FORVARDINO_LECTOR_DETAIL + '?id=' + id)
      .then(response => response.data);
  },

  getForvardinoMyLessons() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_FORVARDINO_MY_LESSONS)
      .then(response => response.data);
  },

  getForvardinoCourseScores(id, offset) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_FORVARDINO_COURSE_SCORES + '?id=' + id + '&offset=' + offset + '&limit=20')
      .then(response => response.data);
  },

  sendForvardinoLessonSignIn(lessonId) {
    let formData = new FormData();
    formData.append('lessonId', lessonId);

    return axiosConfig
      .post(envConfig.default.webServices.CMS_FORVARDINO_LESSON_SIGN, formData)
      .then(response => response.data);
  },

  sendForvardinoLessonSignOut(lessonId) {
    return axiosConfig
      .delete(envConfig.default.webServices.CMS_FORVARDINO_LESSON_SIGN + '/' + lessonId)
      .then(response => response.data);
  },

  sendForvardinoNewsletterSignIn() {
    return axiosConfig
      .post(envConfig.default.webServices.CMS_FORVARDINO_NEWSLETTER)
      .then(response => response.data);
  },

  sendForvardinoCourseInterest(postData) {
    let formData = new FormData();
    formData.append('courseId', postData.courseId);

    return axiosConfig
      .post(envConfig.default.webServices.CMS_FORVARDINO_COURSE_INTEREST, formData)
      .then(response => response.data);
  },

  sendForvardinoCourseRating(ratingData) {
    let formData = new FormData();
    formData.append('comment', ratingData.comment);
    formData.append('score', ratingData.score);
    formData.append('courseId', ratingData.courseId);
    formData.append('lessonId', ratingData.lessonId);

    return axiosConfig
      .post(envConfig.default.webServices.CMS_FORVARDINO_COURSE_RATING, formData)
      .then(response => response.data)
  },

  sendForvardinoLessonAttendance(postData) {
    let formData = new FormData();
    formData.append('id', postData.id);
    formData.append('action', postData.action);

    return axiosConfig
      .post(envConfig.default.webServices.CMS_FORVARDINO_LESSON_ATTENDANCE, formData)
      .then(response => response.data);
  },

  sendForvardinoLessonAttendanceExport(postData) {
    let formData = new FormData();
    formData.append('id', postData.id);

    return axiosConfig
      .post(envConfig.default.webServices.CMS_FORVARDINO_LESSON_ATTENDANCE_EXPORT, postData, {responseType:'blob'})
      .then(response => response.data);
  },

  getForvardinoCourseLessons(options) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_FORVARDINO_COURSE_LESSONS, {params: {id: options[0], offset: options[1], limit: options[2]}})
      .then(response => response.data);
  },

  sendForvardinoCourse(postData) {
    let formData = new FormData();
    formData.append('name', postData.name);
    formData.append('description', postData.description);
    formData.append('category', postData.category);
    formData.append('target', postData.target);

    return axiosConfig
      .post(envConfig.default.webServices.CMS_FORVARDINO_COURSE, postData)
      .then(response => response.data);
  },

  sendForvardinoCourseLesson(postData) {
    let formData = new FormData();
    formData.append('id', postData.id);

    return axiosConfig
      .post(envConfig.default.webServices.CMS_FORVARDINO_COURSE_LESSON, postData)
      .then(response => response.data);
  },

  deleteForvardinoCourse(courseId) {
    return axiosConfig
      .delete(envConfig.default.webServices.CMS_FORVARDINO_COURSE, {params: {id: courseId}})
      .then(response => response.data);
  },

  deleteForvardinoCourseLesson(lessonId) {
    return axiosConfig
      .delete(envConfig.default.webServices.CMS_FORVARDINO_COURSE_LESSON, {params: {id: lessonId}})
      .then(response => response.data);
  },

  sendMapsReport(postData) {
    let formData = new FormData();
    formData.append('location', postData.location);
    formData.append('description', postData.description);

    return axiosConfig
      .post(envConfig.default.webServices.CMS_MAPS_REPORT, postData)
      .then(response => response.data);
  },

  fetchNpsData(options) {
    return axiosConfig
      .get(envConfig.default.webServices.GET_NPS_DATA, {params: {filter: options.filter}})
      .then(response => response.data);
  },

  fetchNpsFiltersData() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_NPS_FILTERS_DATA)
      .then(response => response.data);
  },

  sendForumPost(postData) {
    let formData = new FormData();
    formData.append('name', postData.name);
    formData.append('email', postData.email);
    formData.append('content', postData.content);
    formData.append('topicId', postData.topicId);
    formData.append('parentId', postData.parentId);
    formData.append('deviceId', postData.deviceId);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_ARTICLE_COMMENT_SEND, formData, options)
      .then(response => response.data);
  },

  fetchArticleComments(articleId, offset, amount) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_ARTICLE_COMMENTS + '?articleId=' + articleId + '&offset=' + offset + '&amount=' + amount)
      .then(response => response.data);
  },

  fetchVersionFromServer() {
    return axiosConfig
      .get(envConfig.default.webServices.VERSION_CHECK)
      .then(response => response.data)
  },

  fetchChangelog(version) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_CHANGELOG + '/' + version)
      .then(response => response.data)
  },

  postAppRelease(version) {
    let formData = new FormData();
    formData.append('version', version);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_APP_RELEASE, formData, options)
      .then(response => response.data)
  },

  fetchSubPages(pageId, priv) {
    let formData = new FormData();
    formData.append('pageId', pageId);
    formData.append('private', priv ? '1' : '0');
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_SUB_PAGES, formData, options)
      .then(response => response.data);
  },

  fetchWeekActions(options) {
    let formData = new FormData();
    formData.append('type', options[0]);
    formData.append('offset', options[1]);
    formData.append('amount', options[2]);
    formData.append('next', options[3]);
    return axiosConfig
      .post(envConfig.default.webServices.CMS_WEEK_ACTIONS, formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => response.data);
  },

  fetchApiDataList(id) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_API_DATA_LIST + '/'+ id)
      .then(response => response.data);
  },

  fetchMultipleDataListsById(dataListIds) {
    let requestsArray = [];

    dataListIds.forEach(dataListId => {
      requestsArray.push(axiosConfig.get(envConfig.default.webServices.CMS_API_DATA_LIST + '/' + dataListId));
    });

    return Promise.all(requestsArray);
  },

  fetchAuctionData(options) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_AUCTION_DATA, {
        params: {
          'id': options[0]
        }
      })
      .then(response => response.data);
  },

  sendPlaceBid(name, email, price, id) {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('price', price);
    formData.append('auctionId', id);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_AUCTION_PLACE_BID, formData, options)
      .then(response => response.data);
  },

  fetchAdventPosts() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_ADVENT_POSTS)
      .then(response => response.data);
  },

  fetchBenefitsFiltersData() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_BENEFITS_FILTERS_DATA)
      .then(response => response.data);
  },

  fetchBenefitsList(options) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_BENEFITS_LIST, {
        params: {
          'cats': utilsGeneral.parseNumbersFromArray(options[0]),
          'locs': utilsGeneral.parseNumbersFromArray(options[1]),
          'search': options[2],
          'offset': options[3],
          'limit': options[4]
        }
      })
      .then(response => response.data);
  },

  fetchBenefitsListCount(options) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_BENEFITS_COUNT, {
        params: {
          'cats': utilsGeneral.parseNumbersFromArray(options[0]),
          'locs': utilsGeneral.parseNumbersFromArray(options[1]),
          'search': options[2]
        }
      })
      .then(response => response.data);
  },

  fetchBenefitsItemDetail(benefitId) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_BENEFITS_DETAIL + '/' + benefitId)
      .then(response => response.data);
  },

  sendCompetitionApplicationResult(competitionData) {
    let formData = new FormData();
    formData.append('answers', competitionData.answers);
    formData.append('result', competitionData.result);
    formData.append('email', competitionData.email);
    formData.append('deviceId', competitionData.deviceId);
    formData.append('lang', competitionData.lang);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_COMPETITION_RESULT, formData, options)
      .then(response => response.data);
  },

  fetchCompetitionResultsCount() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_COMPETITION_RESULT_COUNT)
      .then(response => response.data);
  },

  sendIdeaReport(name, email, category, title, description, imageBase64, imageBinary) {
    // Note usage of FormData - needed for communication with backend -
    // Do not use standard axiosConfig POST JSON - it won't work!
    let formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('category', category);
    formData.append('title', title);
    formData.append('description', description);
    if(imageBase64) {
      formData.append('imageBase64', imageBase64);
    }
    if(imageBinary) {
      formData.append('imageBinary', imageBinary);
    }
    // TODO: send also auth key and secure on BE also - create same solution as recruitment module
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.IDEAS_REPORT_API, formData, options)
      .then(response => response.data);
  },

  postParentProgram(sendData) {
    // Note usage of FormData - needed for communication with backend -
    // Do not use standard axiosConfig POST JSON - it won't work!
    let formData = new FormData();
    formData.append('type', 'program');
    formData.append('full_name', sendData.userName);
    formData.append('personal_number', sendData.userPersonalNumber);
    formData.append('email', sendData.userEmail);
    formData.append('phone', sendData.userPhone);
    formData.append('company', sendData.userWorkingPlace);
    formData.append('source_info', JSON.stringify({email: sendData.userNewsletterTypeEmail, fb: sendData.userNewsletterTypeFacebook}));
    formData.append('consent', sendData.userPrivacyPolicy);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_PARENTS_FORM, formData, options)
      .then(response => response.data);
  },

  postParentPool(sendData) {
    // Note usage of FormData - needed for communication with backend -
    // Do not use standard axiosConfig POST JSON - it won't work!
    let formData = new FormData();
    formData.append('type', 'pool');
    formData.append('full_name', sendData.userName);
    formData.append('personal_number', sendData.userPersonalNumber);
    formData.append('email', sendData.userEmail);
    formData.append('phone', sendData.userPhone);
    formData.append('consent', sendData.userPrivacyPolicy);
    formData.append('file', sendData.userUploadedFile);
    formData.append('preferred_work_name', sendData.userWorkSpecialization);
    formData.append('preferred_work_place', sendData.userWorkPlace);
    formData.append('additional_comment', sendData.userAdditionalText);
    formData.append('date_from', sendData.userWorkingDateIn);
    formData.append('date_to', sendData.userWorkingDateOut);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_PARENTS_FORM, formData, options)
      .then(response => response.data);
  },

  getParentProgram(email) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_PARENTS_FORM, {
        params: {
          'email': email,
        }
      })
      .then(response => response.data);
  },

  deleteParentPool(sendData) {
    // Note usage of FormData - needed for communication with backend -
    // Do not use standard axiosConfig POST JSON - it won't work!
    let formData = new FormData();
    formData.append('type', 'pool');
    formData.append('full_name', sendData.userName);
    formData.append('personal_number', sendData.userPersonalNumber);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_PARENTS_FORM_LOG_OUT, formData, options)
      .then(response => response.data);
  },

  fetchParentPages(search, company) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_PARENTS_PAGES, {
        params: {
          'search': search,
          'company': company,
        }
      })
      .then(response => response.data);
  },

  fetchKnowledgeBaseList(categoryId, search) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_GET_KNOWLEDGE_BASE_LIST + '/' + categoryId, {
        params: {
          'search': search,
        }
      })
      .then(response => response.data);
  },


  fetchKnowledgeBaseDetail(pageId) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_GET_KNOWLEDGE_BASE_CONTENT + '/detail/' + pageId)
      .then(response => response.data);
  },

  fetchSurfKempEvents() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_GET_SURF_KEMP_EVENTS)
      .then(response => response.data);
  },

  fetchMap(floor) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_MAPS, {
        params: {
          'floor': floor
        }
      })
      .then(response => response.data);
  },

  fetchEmergencyPage(slug, location) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_EMERGENCY_PAGE, {
        params: {
          'slug': slug,
          'loc': location,
        }
      })
      .then(response => response.data);
  },

  fetchIdentityProfile() {
    const userStore = store.state.user;
    function customHeaders() {
      return {
        withCredentials: true,
        headers: {
          token: userStore.refreshToken,
          axiosType: 'custom',
          deviceId: userStore.deviceId
        }
      }
    }
    return axiosConfig
      .get(envConfig.default.webServices.CMS_IDENTITY_PROFILE)
      .then((response) => {
        if (response.data?.anonymous && userStore.refreshToken) {  // try refresh login with stored token
          this.logCustomInfo(userStore.deviceId, 'fetchIdentityProfile', 'debug1', userStore.refreshToken);
          let formData = new FormData();
          formData.append('deviceId', userStore.deviceId);
          formData.append('refreshToken', userStore.refreshToken);
          return this.fetchAuthorization(formData)
            .then((authResponse) => {
              this.logCustomInfo(userStore.deviceId, 'fetchIdentityProfile', 'debug1.5', JSON.stringify(authResponse));
              if (authResponse.refreshToken) {
                store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_REFRESH_TOKEN, authResponse.refreshToken);
                if (!authResponse.anonymous) {  // non-anonymous session found
                  this.logCustomInfo(userStore.deviceId, 'fetchIdentityProfile', 'debug2', authResponse.refreshToken);
                  return axios.get(envConfig.default.webServices.CMS_IDENTITY_PROFILE, customHeaders())
                    .then((resp) => {
                      this.logCustomInfo(userStore.deviceId, 'fetchIdentityProfile', 'debug3', JSON.stringify(resp.data));
                      return resp.data;
                    })
                }
              }
              return response.data;
            })
            .catch((error) => {
              this.logCustomInfo(userStore.deviceId, 'fetchIdentityProfile', 'catch1', error);
              if (error.request.status === 401 && error.response.data.code === 'rest_invalid_token') {
                // remove token from localstore and continue as anonymous
                store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_REFRESH_TOKEN, null);
              }
              return response.data;
            })
        } else {
          return response.data;
        }
      });
  },

  putIdentityProfile(sendData) {
    // Note usage of FormData - needed for communication with backend -
    // Do not use standard axiosConfig POST JSON - it won't work!
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_IDENTITY_PROFILE, sendData, options)
      .then(response => response.data);
  },

  postIdentityProfilePhoneVerify(sendData) {
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_IDENTITY_PROFILE_PHONE_VERIFY, sendData, options)
      .then(response => response.data);
  },

  postProfilePhoto(sendData) {
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_IDENTITY_PROFILE_PHOTO, sendData, options)
      .then(response => response.data);
  },

  deleteProfile() {
    return axiosConfig
      .delete(envConfig.default.webServices.CMS_IDENTITY_PROFILE)
      .then(response => response.data);
  },

  postProfilePreference(sendData) {
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_IDENTITY_PROFILE_PREFERENCE, sendData, options)
      .then(response => response.data);
  },

  postProfilePushNotificationSub(sendData) {
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_IDENTITY_PROFILE_NOTIFICATION, sendData, options)
      .then(response => response.data);
  },

  fetchProfilePreference(options) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_IDENTITY_PROFILE_PREFERENCE, {
        params: {
          'module': options?.module,
          'param': options?.param
        }
      })
      .then(response => response.data);
  },

  async logSearchQuery(deviceId, module, query) {
    fetch(envConfig.default.webServices.CMS_LOG_SEARCH_QUERY + '?id=' + deviceId + '&module=' + module + '&value=' + query,
      {
        mode: 'no-cors',
        cache: 'no-store'
      }
    )
  },

  fetchPromotedFilter(options) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_PROMOTED_FILTER, {
        params: {
          'locality': options.locality,
          'module': options.module,
          'offset': options.offset,
          'limit': options.limit
        }
      })
      .then(response => response.data);
  },

  fetchComingFilter(options) {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_COMING_ACTIONS, {
        params: {
          'type': options.type,
          'offset': options.offset,
          'amount': options.amount
        }
      })
      .then(response => response.data);
  },

  fetchAppNotifications() {
    return axiosConfig
      .get(envConfig.default.webServices.CMS_APP_NOTIFICATIONS, {
      })
      .then(response => response.data);
  },

  postAppNotificationsReadAll() {
    return axiosConfig
      .post(envConfig.default.webServices.CMS_APP_NOTIFICATIONS_READ_ALL, {
      })
      .then(response => response.data);
  },

  postAppNotificationRead(id) {
    let formData = new FormData();
    formData.append('id', id);
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return axiosConfig
      .post(envConfig.default.webServices.CMS_APP_NOTIFICATIONS_READ, formData, options)
      .then(response => response.data);
  },

  async postGlobalSearch(data) {
    return await axiosConfig
      .post(envConfig.default.webServices.CMS_GLOBAL_SEARCH, data)
      .then(response => response.data);
  },

  async deleteGlobalSearchHistory(termId) {
    return await axiosConfig
      .delete(envConfig.default.webServices.CMS_GLOBAL_SEARCH_HISTORY + '/' + termId)
      .then(response => response.data);
  },

  fetchMessages(type) {
    let typeUrl = '';
    if (type !== 'none') {
      typeUrl = '?type=' + type;
    }
    return axiosConfig
      .get(envConfig.default.webServices.CMS_IDENTITY_MESSAGES + typeUrl, {
      })
      .then(response => response.data);
  },

  async deleteMessage(id) {
    return await axiosConfig
      .delete(envConfig.default.webServices.CMS_IDENTITY_MESSAGE + '/' + id)
      .then(response => response.data);
  },

  postJobInterestSF(data) {
    return axios
      .post(envConfig.default.webServices.SEND_JOB_INTEREST_SF, data)
      .then(response => response.data);
  },

  async fetchDirectoryCategories() {
    return await axiosConfig
      .get(envConfig.default.webServices.CMS_DIRECTORY_CATEGORIES)
      .then(response => response.data);
  },

  async fetchDirectoryEntities() {
    return await axiosConfig
      .get(envConfig.default.webServices.CMS_DIRECTORY_ENTITIES)
      .then(response => response.data);
  },

  async fetchDirectoryApplications(options) {
    return await axiosConfig
      .get(envConfig.default.webServices.CMS_DIRECTORY_APPLICATIONS, {
        params: {
          'filter': options[0],
          'sort': options[1],
          'offset': options[2],
          'limit': options[3]
        }
      })
      .then(response => response.data);
  },

  async fetchDirectoryApplicationDetail(id) {
    return await axiosConfig
      .get(envConfig.default.webServices.CMS_DIRECTORY_APPLICATIONS + '/' + id)
      .then(response => response.data);
  },

  async postDirectoryApplication(data) {
    return await axiosConfig
      .post(envConfig.default.webServices.CMS_DIRECTORY_APPLICATIONS, data)
      .then(response => response.data);
  },

  async putDirectoryApplication(id, data) {
    return await axiosConfig
      .post(envConfig.default.webServices.CMS_DIRECTORY_APPLICATIONS + '/' + id, data)
      .then(response => response.data);
  },

  async deleteDirectoryApplication(id) {
    return await axiosConfig
      .delete(envConfig.default.webServices.CMS_DIRECTORY_APPLICATIONS + '/' + id)
      .then(response => response.data);
  },

  async postDirectoryApplicationFavorite(id) {
    return await axiosConfig
      .post(envConfig.default.webServices.CMS_DIRECTORY_APPLICATIONS + '/' + id + '/favorites')
      .then(response => response.data);
  },

  async deleteDirectoryApplicationFavorite(id) {
    return await axiosConfig
      .delete(envConfig.default.webServices.CMS_DIRECTORY_APPLICATIONS + '/' + id + '/favorites')
      .then(response => response.data);
  },

  async postDirectoryApplicationWish(data) {
    return await axiosConfig
      .post(envConfig.default.webServices.CMS_DIRECTORY_APPLICATIONS_WISH, data)
      .then(response => response.data);
  },
}
