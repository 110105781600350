<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        v-if="!isLoading"
        class="vue-b-directory-list"
      >
        <prj1052-directory-list
          :use="'module'"
        />
      </div>
    </template>

    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <frm1006-button
        type="primary"
        class="vue-button-new-app vue-button-page-layout-fixed"
        @buttonClickEvent="showAppForm()"
      >
        <span>
          {{ $t('directory.createCustom') }}
        </span>
      </frm1006-button>
    </template>
  </page-layout>
</template>

<script>
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1052DirectoryList from '@/components/prj1052-directory-list/prj1052-directory-list.vue';
import { mapState } from 'vuex';

export default {
  name: 'DirectoryList',
  components: {
    Prj1052DirectoryList,
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
    };
  },

  computed: {
    ...mapState('directory', [
      'isLoadingItems',
    ]),
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.initialized = true;
  },

  methods: {
    showAppForm() {
      this.$store.commit(STORE_MODULES.DIRECTORY + '/' + MUTATIONS_CONSTANTS.SET_DIRECTORY_MODAL_FORM, true);
    },
  },
}
</script>

