import Gen1026Layout from './gen1026-layout';
import Gen1026LayoutSlot from './gen1026-layout-slot';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1026Layout);
        registerComponent(Vue, Gen1026LayoutSlot);
    }
};

use(Plugin);

export default Plugin;

export { Gen1026Layout, Gen1026LayoutSlot };
