<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <!-- Container -->
  <section class="relative">
    <!-- Cards -->
    <div
      ref="carousel"
      class="flex h-full gap-x-4 overflow-x-scroll prj-scroll-snap-x xs:py-3"
      :class="{
        'prj-layout-offset-padding-x': containerFullWidth,
      }"
      @scroll="scrollEvent"
    >
      <slot />
    </div>

    <!-- Controls -->
    <nav v-if="hasNavigation">
      <t-nav-button
        v-if="!isStartOfList"
        class="absolute -left-24 h-32 w-8 text-gray-500 bg-gray-100 top-1/2 transform -translate-y-1/2 hidden lg:block"
        @click="onCarouselButtonClick('backward')"
      >
        <svg
          class="h-7 w-7"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 19L8 12L15 5"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </t-nav-button>
      <t-nav-button
        v-if="!isEndOfList"
        class="absolute -right-24 h-32 w-8 text-gray-500 bg-gray-100 top-1/2 transform -translate-y-1/2 hidden lg:block"
        @click="onCarouselButtonClick('forward')"
      >
        <svg
          class="h-7 w-7"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 5L16 12L9 19"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </t-nav-button>
    </nav>
  </section>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1006CardsWrapper',
  props: {
    fireScrollEnd: {
      type: Boolean,
      default: false,
    },
    hasNavigation: {
      type: Boolean,
      default: false,
    },
    itemsLength: {
      type: Number,
      // required: true, TODO https://jpower8.atlassian.net/browse/ESG-273
      default: 0,
    },
    containerFullWidth: {
      type: Boolean,
      default: true,
    },
    startPosition: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentOffset: 0,
      paginationFactor: 0,
      isObserverFullSupport: true,
      observer: null,
      carouselWidth: 0,
    }
  },
  computed: {
    isStartOfList() {
      return this.currentOffset === 0
    },
    isEndOfList() {
      return this.currentOffset + this.paginationFactor >= this.carouselWidth
    },
    isContinuousFetching() {
      return this.fireScrollEnd && this.itemsLength > 1
    },
  },
  watch: {
    itemsLength(value) {
      if (value && this.fireScrollEnd && this.isObserverFullSupport)
        this.$nextTick(() => {
          this.observeLoadMoreTriggerItem()
        })
    },
  },
  mounted() {
    if (
      !('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window) ||
      !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
    ) {
      this.isObserverFullSupport = false
    }

    // Set initial dimensions
    this.paginationFactor = this.$refs.carousel.clientWidth
    this.carouselWidth = this.$refs.carousel.scrollWidth

    if (this.isContinuousFetching && this.isObserverFullSupport) this.observeLoadMoreTriggerItem()
    if (this.startPosition)
      this.$refs.carousel.scrollTo({
        top: 0,
        left: this.$refs.carousel.clientWidth * this.startPosition,
        behavior: 'smooth',
      })
  },
  beforeDestroy() {
    this.observer?.disconnect()
  },
  methods: {
    onElementObserved(entries) {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.observer.disconnect()
          this.$emit('scrollEnd')
        }
      })
    },

    observeLoadMoreTriggerItem() {
      this.carouselWidth = this.$refs.carousel.scrollWidth
      const carouselNodes = this.$refs.carousel.childNodes
      const observedNode = carouselNodes[carouselNodes.length - 2]

      this.observer = new IntersectionObserver(this.onElementObserved, {
        root: this.$refs.carousel,
        threshold: 0,
      })

      // FIXME: temp fix IO error in Zapka
      if (typeof observedNode !== 'undefined') {
        this.observer.observe(observedNode)
      }
    },

    scrollEvent(e) {
      this.currentOffset = e.target.scrollLeft

      // Edge case for no Intersection Observer support
      if (!this.isObserverFullSupport && this.isContinuousFetching) {
        if (e.target.offsetWidth + e.target.scrollLeft >= e.target.scrollWidth) {
          this.$emit('scrollEnd')
        }
      }
    },

    onCarouselButtonClick(direction) {
      const { carousel } = this.$refs

      if (direction === 'forward' && !this.isEndOfList) {
        this.currentOffset += this.paginationFactor
      } else if (direction === 'backward' && !this.isStartOfList) {
        this.currentOffset -= this.paginationFactor
      }

      if (carousel) {
        carousel.scrollTo({
          top: 0,
          left: this.currentOffset,
          behavior: 'smooth',
        })
      }
    },
  },
}
</script>
