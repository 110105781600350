<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-surf-studio-dashboard">
        <div class="vue-dashboard-header">
          <img
            v-if="dashboardLogoURL"
            class="vue-surf-studio-logo"
            :src="dashboardLogoURL"
            alt="Surf Studio logo"
          >
          <p
            class="vue-dashboard-title"
          >
            {{ dashboardTitle }}
          </p>
        </div>
        <div class="vue-dashboard-tiles">
          <frm1006-button
            v-for="item in tileButtons"
            :key="item.id"
            class="vue-dashboard-item-tile"
            :class="generateTile(item)"
            type="tile"
            @buttonClickEvent="goToView(item)"
          >
            <template v-slot:iconLeft>
              <div
                class="vue-tile-icon"
              />
            </template>
            {{ item.title }}
          </frm1006-button>
        </div>
      </div>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />

      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  SURF_STUDIO_DASHBOARD_ID,
  ERROR_MODAL_TIMEOUT
} from '@/constants/app-constants';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';

import mxNavigationDefault from '@/mixins/mx-navigation-default';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import router from '@/router';

export default {
  name: 'SurfStudioDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      ERROR_MODAL_TIMEOUT,
      surfStudioData: [],
      surfStudioDataLoaded: false,
      dashboardLogoURL: '',
      dashboardTitle: ''
    };
  },

  computed: {
    navbarCaption() {
      return this.$route.meta.navbarCaption;
    },

    tileButtons() {
      return this.surfStudioData
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.getSurfStudioDashboardCategories().then(() => {
      this.initialized = true;
    });
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    getSurfStudioDashboardCategories() {
      this.setLoadingState(true);

      return dataLoader.fetchApiDataList(SURF_STUDIO_DASHBOARD_ID)
        .then(data => {
          this.surfStudioData = data.surfStudioCategories;
          this.surfStudioDataLoaded = true;
          this.dashboardLogoURL = data.surfStudioLogoURL;
          this.dashboardTitle = data.surfStudioTitle;
        })
        .catch(error => {
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    generateTile(item) {
      if (item.icon.length > 0) {
        return 'vue-tile-' + item.icon;
      }
    },

    goToView(item) {
      if ( item.name ) {
        router.push({ name: item.name, params: {isSurfStudio: true} })
      }
    },
  },
}
</script>

