<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
    :watchScroll="true"
    :class="classObject"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        @buttonCustomEvent="returnToArticle"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <gen1006-info-panel
        v-if="loadFailed"
        type="error"
      >
        {{ $t('articles.commentsShowTryAgain') }} <a @click="reloadContent">{{ $t('articles.commentsShowTryAgainReload') }}</a>
      </gen1006-info-panel>

      <div class="vue-b-comments">
        <!--=== MESSAGES ===-->
        <div class="vue-b-messages">
          <gen1006-info-panel
            v-if="discussionPostsCount === 0"
            type="neutral"
          >
            {{ $t('articles.commentsEmpty') }}
          </gen1006-info-panel>

          <div class="vue-c-messages">
            <template
              v-for="(post, index) in discussion.posts"
            >
              <!--======= ITEMS FIRST LEVEL ===============-->
              <div
                :key="post.comment_ID + '-' + index"
                class="vue-b-message"
                :class="{ 'vue-is-newest': commentNewest(post.comment_date) }"
              >
                <div class="vue-b-message-container">
                  <div class="vue-b-message-content">
                    <div class="vue-message-author">
                      {{ post.comment_author }}
                    </div>
                    <div class="vue-message-date">
                      {{ commentDateTime(post.comment_date) }}
                    </div>
                    <div class="vue-message-content">
                      {{ post.comment_content }}
                    </div>
                  </div>
                  <div class="vue-b-button-reply">
                    <frm1006-button
                      type="internal"
                      class="vue-button-reply"
                      @buttonClickEvent="event => (postReply(event, post.comment_ID))"
                    >
                      {{ $t('articles.commentReply') }}
                    </frm1006-button>
                  </div>
                </div>
                <div class="vue-b-replies">
                  <!--==== ITEMS SECOND LEVEL =======-->
                  <template v-for="reply in post.comment_replies">
                    <div
                      :key="reply.comment_ID"
                      class="vue-b-message vue-is-reply"
                      :class="{ 'vue-is-newest': commentNewest(reply.comment_date) }"
                    >
                      <div class="vue-b-message-container">
                        <div class="vue-b-message-content">
                          <div class="vue-message-author">
                            {{ reply.comment_author }}
                          </div>
                          <div class="vue-message-date">
                            {{ commentDateTime(reply.comment_date) }}
                          </div>
                          <div class="vue-message-content">
                            {{ reply.comment_content }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>

          <div
            v-show="isInfinityScrollEnabled"
            ref="infinityScrollTrigger"
          >
            <gen1016-loading-indicator
              :active="isInfinityScrollEnabled"
            />
          </div>

          <frm1006-button
            type="primary"
            class="vue-button-add-comment"
            :iconLeft="true"
            @buttonClickEvent="postReply"
          >
            {{ $t('articles.commentNew') }}
          </frm1006-button>
        </div>
      </div>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        :active.sync="postSuccess"
        :closeAfter="5000"
        :buttonClose="true"
        class="vue-is-info-panel"
      >
        <gen1006-info-panel
          type="success"
        >
          {{ $t('articles.commentSendSuccess') }}
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { ARTICLE } from '@/store/store-modules';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

import mxInfinityScrolling from '@/mixins/mx-infinity-scrolling';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';

import PageLayout from '../../templates/partials/page-layout';
import Prj1002Navbar from '../../components/prj1002-navbar/prj1002-navbar';

export default {
  name: 'NewsDetailComments',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics,
    mxInfinityScrolling
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      discussion: {
        posts: [],
        offset: 0,
        amount: 10,
        count: 0
      },
      isInfinityScrollEnabled: false,
      loadFailed: false,
      postSuccess: false,
      showButtonLoadPostsMore: false
    }
  },

  computed: {
    classObject() {
      return [
        {
          'vue-has-infinity-scroll-finished': !this.isInfinityScrollEnabled,
          'vue-has-infinity-scroll-unfinished': this.isInfinityScrollEnabled
        }
      ];
    },

    articleId() {
      return this.$route.params.id;
    },

    discussionPostsCount() {
      return this.discussion.posts.length;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },
  },

  mounted() {
    this.loadNewContent(false);

    this.initialized = true;
    this.sendStatistics(ARTICLE, this.articleId, true);

    setTimeout(() => {
      if (this.$route.params.success) {
        this.postSuccess = true;
      }
    }, 1000);
  },

  methods: {
    returnToArticle() {
      this.$router.push({ name: 'news', params: { id: this.articleId }});
    },

    postReply(event, parentId) {
      let parentIdComputed = 0;

      if (parentId) {
        parentIdComputed = parentId;
      }

      this.$router.push({ name: 'news-comment-add', params: { id: this.articleId }, query: { parentId: parentIdComputed }});
    },

    clearDataList() {
      this.discussion.posts = [];
      this.discussion.offset = 0;
      this.discussion.count = 0;
    },

    reloadContent() {
      // clear data to prevent duplicities
      this.clearDataList();
      this.loadNewContent(false)
    },

    async loadNewContent(scrolled) {
      let tempArray = [];
      let component = this;

      if (!scrolled) {
        this.discussion.offset = 0;
        this.discussion.posts = [];
      }

      await dataLoader
        .fetchArticleComments(this.articleId, this.discussion.offset, this.discussion.amount)
        .then((result) => {
          this.isLoading = false;

          tempArray = this.discussion.posts;
          this.discussion.posts = [...tempArray, ...result];

          if (result.length < this.discussion.amount) {
            this.isInfinityScrollEnabled = false;
          }
        })
        .catch(error => {
          this.loadFailed = true;
          logger.error(error);
        })
        .finally(() => {
          if (!scrolled) {
            if (this.discussion.posts.length < this.discussion.amount) {
              this.isInfinityScrollEnabled = false;
            } else {
              this.isInfinityScrollEnabled = true;

              this.initInfinityScroll();
            }
          }

          this.discussion.offset += this.discussion.amount;

          // TODO MBU: solve via global event
          // update scroll heights in page layout after loading more content
          component.$refs.pageLayout.updateScrollVariables();
        })
    },

    commentDateTime(datetime) {
      let tmpDatetime = datetime.replace(' ', 'T');
      let dateTimeObject = new Date(tmpDatetime);
      return dateTimeObject.getDate() +
              '.' + (dateTimeObject.getMonth() + 1) +
              '.' + dateTimeObject.getFullYear() +
              ' ' + (dateTimeObject.getHours() < 10 ? '0' + dateTimeObject.getHours() : dateTimeObject.getHours()) +
              ':' + (dateTimeObject.getMinutes() < 10 ? '0' + dateTimeObject.getMinutes() : dateTimeObject.getMinutes());
    },

    commentNewest(datetime) {
      let tmpDatetime = datetime.replace(' ', 'T');
      let oneHour = 60 * 60 * 1000;
      let dateTimeObject = new Date(tmpDatetime);
      return ((new Date) - dateTimeObject) < oneHour;
    },
  }
}
</script>
