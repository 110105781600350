<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//
import localization from '../../localization';

//============ EXPORT ==================================//
//======================================================//
export default {
    data() {
        return {
            localization: localization
        };
    },
    methods: {
        i18n(stringKey) {
            return localization.strings[localization.language][stringKey];
        }
    }
};
</script>
