<template>
  <!-- TODO MBU: unify markup -->
  <div
    class="vue-b-news-list-item"
    :class="classObject"
    @click="redirectToArticle(articleId, article)"
  >
    <div class="vue-article-list-item-left">
      <div
        v-if="article.featuredImage"
        class="vue-article-list-item-thumbnail"
        :style="handleArticleThumbnail(article)"
      />
      <div
        v-else
        class="vue-article-list-item-thumbnail-placeholder"
      />
    </div>
    <div class="vue-article-list-item-right">
      <p
        class="vue-article-list-item-link"
        v-html="article.title"
      >
      </p>
      <div class="vue-article-list-item-metadata">
        <span
          v-if="(article.categories && article.categories.length > 0) || filteredArticlesCategoryName"
          class="vue-article-list-item-category"
        >
          <template v-if="filteredArticlesCategoryName">
            {{ filteredArticlesCategoryName }}
          </template>
          <template v-else-if="article.categories && article.categories.length > 0">
            {{ article.categories[0].name }}
          </template>
        </span>

        <span class="vue-article-list-item-date">
          <prj1038-item-age
            :date="dateCreated"
          />
        </span>

        <frm1006-button
          v-if="hasComments"
          type="internal"
          class="vue-button-comments"
          :iconLeft="true"
          :captionHidden="true"
          @buttonClickEvent="event => (redirectToArticleComments(event, articleId))"
        >
          {{ $t('articles.commentsShow') }}
        </frm1006-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Prj1038ItemAge from '@/components/prj1038-item-age/prj1038-item-age';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'Prj1031NewsListItem',
  components: {
    Prj1038ItemAge
  },

  mixins: [mxDetectDesktop],

  props: {
    index: Number,
    last: Boolean,
    article: Object,
    isCategoryFilterActive: Boolean,
    filteredArticlesCategoryName: String, // TODO MBU: remove this prop when categoryActive is used
  },

  computed: {
    ...mapState('articles', [
      'newsActiveCategory'
    ]),
    ...mapState('general', [
      'applicationConfig',
      'applicationConfigLoaded'
    ]),

    classObject() {
      return [
        {
          // TODO MBU: remove prop - requires structure change in list, to use css selector
          'vue-is-last': this.last,
          'vue-is-important': this.important,
          'vue-has-comments': this.hasComments,
          'vue-has-categories': this.hasCategories,
          'vue-is-promoted': this.isPromoted,
        }
      ];
    },

    articleId() {
      return this.article.id;
    },

    hasCategories() {
      if (this.article.categories) {
        return this.article.categories.length > 0;
      }

      return false;
    },

    hasComments() {
      return this.article.commentsEnable;
    },

    isPromoted() {
      return this.article.promoted;
    },

    newsImportantCategoryId() {
      if (this.applicationConfigLoaded) {
        this.applicationConfig.customFields.forEach(item => {
          if (item.key === 'NEWS_IMPORTANT_CATEGORY_ID') {
            return item.value;
          }
        });
      }
      return -1;
    },

    important() {
      let categories = this.article.categories;

      if (categories && categories.length > 0) {
        if (categories.filter(category => category.id === this.newsImportantCategoryId).length > 0) {
          return true;
        }
      }

      return false;
    },

    // TODO MBU: workaround for inconsistent API
    dateCreated() {
      let createdTimestamp = null;

      if (this.article.createdTimestamp) {
        return this.article.createdTimestamp;
      } else if (this.article.created) {
        return this.article.created / 1000;
      }

      return createdTimestamp;
    }
  },

  methods: {
    generateBackgroundUrl(article) {
      if (this.isCategoryFilterActive) {
        return typeof article.featuredImage !== 'undefined' || article.featuredImage !== false ? article.featuredImage : false;
      } else {
        return article.featuredImage && article.featuredImage.mediumLarge && article.featuredImage.mediumLarge.src ? article.featuredImage.mediumLarge.src : false;
      }
    },

    handleArticleThumbnail(article) {
      let imageUrl;

      if (this.isCategoryFilterActive) {
        imageUrl = typeof article.featuredImage !== 'undefined' || article.featuredImage !== false ? article.featuredImage : '';
      } else {
        imageUrl = article.featuredImage && article.featuredImage.thumbnail && article.featuredImage.thumbnail.src ? article.featuredImage.thumbnail.src : '';
      }

      return `background-image: url("${imageUrl}");`;
    },

    handleBackground(article) {
      return {
        'vue-article-list-item-headline-placeholder': typeof article.featuredImage === 'undefined' || article.featuredImage === false
      }
    },

    redirectToArticle(articleId, data) {
      this.$router.push({
        name: 'news',
        params: {
          id: articleId,
          origin: this.$route.name,
          newslistActiveCategory: this.newsActiveCategory
        }
      });
      // save registries to store
      this.$store.commit(STORE_MODULES.ARTICLES + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, data);
    },

    redirectToArticleComments(event, articleId) {
      event.stopPropagation();
      this.$router.push({
        name: 'news-comments',
        params: { id: articleId }
      });
    }
  }
}
</script>
