import Frm1034InputNumber from './frm1034-input-number';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1034InputNumber);
    }
};

use(Plugin);

export default Plugin;

export { Frm1034InputNumber };
