<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div class="bg-gray-200 px-14 py-12 rounded-2xl">
    <span class="text-gray-500 text-4xl leading-10 font-extrabold">{{ text }}</span>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1023NoContentPanel',
  props: {
    text: {
      type: [String, global.VueI18n],
      required: true,
    },
  },
}
</script>
