export const PLATFORM_OTHER = 'other';
export const PLATFORM_ANDROID = 'android';
export const PLATFORM_IOS = 'ios';
export const ORIENTATION_LANDSCAPE = 'landscape';
export const ORIENTATION_PORTRAIT = 'portrait';
export const SYSTEM_VERSION_UNKNOWN = 'unknown';

export const FILTER_THEME = 'themeId'
export const FILTER_CORP = 'corporate'
export const FILTER_PROM = 'promoted'
export const FILTER_LIKED = 'likes'
export const ORDER_NEW = 'created'
