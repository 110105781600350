<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        ref="navbar"
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj-1024-item-list
        ref="servicesList"
        :listData="sortedServices"
        :listItemsCount="servicesNumber"
        :isFilterActive="servicesFilterIsActive"
        :noMoreItems="servicesNoMore"
        :componentKey="componentKey"
        :listItemConfig="listItemConfigObject"
        @loadingBenefitsList="startLoadingServicesList"
        @loadedBenefitsList="finishLoadingServicesList"
        @loadNewContent="loadNewContent"
        @openFilterDialog="openFilterDialog"
        @resetFilteredItems="clearFilter"
        @scrollToTop="scrollToTop"
        @goToDetail="goToDetail"
      />
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <prj1026-category-filter
        v-if="servicesCategories.length > 0"
        :filterCategories="servicesCategories"
        :showItems="showItemsInFilter"
        :showFilterButton="false"
        :showFilterButtonMore="false"
        :showAllButtons="true"
        :showResetButton="false"
        :activeCategory="servicesSelectedCategoryId"
        filterType="services"
        @filterItems="changeCategory"
      />
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="!servicesListInitialized || isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-has-no-padding"
        :active.sync="hasError"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          <span>
            {{ $t('error.generic') }}
          </span>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT,
  SERVICES_CATEGORIES,
  SERVICES_PAGE_SIZE
} from '@/constants/app-constants';

import PageLayout from '@/templates/partials/page-layout';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1026CategoryFilter from '@/components/prj1026-category-filter/prj1026-category-filter';
import Prj1024ItemList from '@/components/prj1024-item-list/prj1024-item-list';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxInfinityScrolling from '@/mixins/mx-infinity-scrolling';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

import {mapState} from 'vuex';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import router from '@/router';

export default {
  name: 'ServicesList',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1026CategoryFilter,
    Prj1024ItemList,
  },

  mixins: [
    mxNavigationDefault,
    mxInfinityScrolling,
    mxDetectDesktop
  ],

  data() {
    return {
      isLoading: false,
      services: [],
      servicesNumber: 0,
      servicesNoMore: false,
      servicesListInitialized: false,
      hasError: false,
      currentPage: 1,
      servicesPageSize: SERVICES_PAGE_SIZE,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      searchTerm: '',
      componentKey: 0,
      listItemConfigObject: {
        displayDate: false,
        displayRating: false,
      },
    }
  },

  computed: {
    ...mapState('services', [
      'servicesSelectedCategoryId',
      'servicesSearchTerm',
      'searchQuery'
    ]),

    showItemsInFilter() {
      return this.servicesCategories ? this.servicesCategories.length : 0;
    },

    servicesCategories() {
      return SERVICES_CATEGORIES.map((item, index) => {
        return {
          termId: index,
          termName: this.$t('services.categoryName')[item]
        }
      })
    },

    sortedServices() {
      const services = [...this.services];
      return services.sort(function(a, b) {
        return (new Date(b.modified)) - (new Date(a.modified));
      });
    },

    selectedCategory() {
      return SERVICES_CATEGORIES[this.servicesSelectedCategoryId];
    },

    servicesFilterIsActive() {
      return this.servicesSearchTerm !== '';
    },
  },

  watch: {
    servicesSearchTerm(newValue) {
      this.searchTerm = newValue;
      this.getServices();
    },

    servicesSelectedCategoryId() {
      this.getServices();
    },

    searchQuery() {
      this.getServices();
    }
  },

  mounted() {
    if (!this.services.length) {
      this.getServices();
    }
  },

  methods: {
    changeCategory(categoryNumber) {
      const categoryId = typeof categoryNumber !== 'undefined' ? categoryNumber : 0;
      this.$store.commit(STORE_MODULES.SERVICES + '/' + MUTATIONS_CONSTANTS.SET_SERVICES_SELECTED_CATEGORY, categoryId)
    },

    getServices() {
      this.currentPage = 1;
      this.servicesListInitialized = false;
      this.componentKey++;

      dataLoader.fetchFilteredServices(this.selectedCategory, this.currentPage, this.servicesPageSize, this.searchQuery)
        .then(services => {
          this.services = services;
          this.servicesListInitialized = true;
        })
        .catch(error => {
          this.servicesListInitialized = true;
          logger.error(error);
          this.hasError = true;
        })
        .finally(() => {
          if (this.benefitsNumber > 20) {
            this.servicesNoMore = false;
            this.$refs.servicesList.initInfinityScroll();
          }
        })
    },

    async loadNewContent() {
      if (this.servicesNoMore) return;
      this.currentPage += 1;

      await dataLoader.fetchFilteredServices(this.selectedCategory, this.currentPage, this.servicesPageSize, this.searchTerm)
        .then(newServices => {
          let tempArray = this.services;
          this.services = [...tempArray, ...newServices];

          if (newServices.length < this.servicesPageSize) {
            this.servicesNoMore = true;
          }
        })
        .catch(error => {
          this.hasError = true;
          logger.error(error);
        })
    },

    startLoadingServicesList() {
      this.servicesListInitialized = false;
    },

    finishLoadingServicesList() {
      this.servicesListInitialized = true;
    },

    openFilterDialog() {
      this.$store.dispatch(STORE_MODULES.POPUP + '/' + ACTIONS_CONSTANTS.TOGGLE_POPUP, 'servicesFilter');
    },

    clearFilter() {
      this.$store.commit(STORE_MODULES.SERVICES + '/' + MUTATIONS_CONSTANTS.SET_SERVICES_LIST_FILTER, '');
    },

    scrollToTop() {
      this.$nextTick(() => {
        this.scrollToElement();
      })
    },

    scrollToElement() {
      const ref = this.$refs.navbar.$el;

      if (ref) {
        ref.scrollIntoView(true);
      }
    },

    goToDetail(data) {
      // save to store
      this.$store.commit(STORE_MODULES.SERVICES + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, data);
      router.push({ name: 'services-detail', params: { id: data.id }});
    },
  },
}
</script>
