import Vue from 'vue';
import VueFramework from '../frameworks/vue';

//========= VUE FRAMEWORK INIT ===============//
//=== Import whole framework

// EXAMPLE: import config if needed for expanding it and not overriding
// import { default as VueFrameworkConfig } from '../../frameworks/vue/config';

Vue.use(VueFramework, {
  config: {
    // components: ['Frm1001InputField', 'Frm1008SplitButton'], // EXAMPLE: Load only specific components (default: all components)
    // globalInputButtonClear: true,
    components: [
      'Frm1001InputField',
      'Frm1002Combo',
      'Frm1003Checkbox',
      'Frm1004Radio',
      'Frm1006Button',
      'Frm1009Password',
      'Frm1013Textarea',
      'Frm1023FormRow',
      'Frm1023FormRowSlot',
      'Frm1028AdvancedCombo',
      'Frm1031ButtonContainer',
      'Frm1033DropdownButton',
      'Frm1035FormGroup',
      'Gen1004TagLabel',
      'Gen1005TagContainer',
      'Gen1006InfoPanel',
      'Gen1009Overlay',
      'Gen1011ContextContainer',
      'Gen1015Section',
      'Gen1016LoadingIndicator',
      'Gen1021NumericValue',
      'Tbl1001Repeater',
      'Tbl1001RepeaterMixin'
    ],
    buttonTypes: [
      'primary',
      'primary2',
      'secondary',
      'tertiary',
      'link',
      'internal',
      'info',
      'success',
      'warning',
      'danger',
      'neutral',
      'tile',
      'app-button',
      'navigation',
      'orange',
      'answer',
      'shadow'
    ],
    infoStates: ['info', 'success', 'warning', 'error', 'neutral'],
    testConfigProp: 'propValueOverridden'
    // EXAMPLE: object will be completely overwritten, necessary to provide full config
    // frm1027slider: {
    //     options: {
    //         tooltip: 'active'
    //     }
    // }
    // EXAMPLE: or expand the default options
    // frm1027slider: {
    //     options: { ...VueFrameworkConfig.frm1027slider.options, tooltip: 'none' }
    // }
  }
  // EXAMPLE: localization override on Vue framework init
  // localization: {
  //     language: 'en'
  // }
});
