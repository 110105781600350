import {TWO_WEEKS_IN_MS} from '@/constants/app-constants';
import * as STRING_VALUES from '../string-values';
import * as MUTATIONS_CONSTANTS from '../constants/mutations'
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import dataLoader from '@/utils/data-loader';
import router from '@/router';
import logger from '@/utils/logger';

const state = {
  publicKey: '',
  refreshToken: '',
  tokenStatus: '',
  platform: STRING_VALUES.PLATFORM_OTHER,
  systemVersion: STRING_VALUES.SYSTEM_VERSION_UNKNOWN,
  isLogged: false,
  lastLogged: null,
  profile: {
    id: null,
    name: null,
    workEmail: null,
    otherEmail: null,
    phone: null,
    photoUrl: null,
    consentPersonalData: null,
    locality: null,
    gaId: null,
    anonymous: null,
    lastCheck: {
      timestamp: '',
      date: ''
    },
    searchHistory: null
  },
  preferences: {
    lastCheck: {
      timestamp: '',
      date: ''
    },
    data: []
  },
  notifications: {
    lastCheck: {
      timestamp: '',
      date: ''
    },
  },
  isStandalone: false,
  browser: '',
  deviceId: null,
  entryPath: '',
  installReminderTimestamp: null,
  forvardinoNewsletterState: null,
  // authorized mode only
  amUserProfile: null,
  isProfileMenuOpened: false,
  // App notifications
  isAppNotificationsMenuOpened: false,
  appNotificationsList: [],
  messagesList: [],
  messagesSelectedCategories: {
    modules: []
  },
  messagesActiveModule: 0,
  messagesAreFiltersActive: false,
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_PUBLIC_KEY](state, token) {
    state.publicKey = token;
  },
  [MUTATIONS_CONSTANTS.SET_REFRESH_TOKEN](state, token) {
    state.refreshToken = token;
  },
  [MUTATIONS_CONSTANTS.SET_TOKEN_STATUS](state, status) {
    state.tokenStatus = status;
  },
  [MUTATIONS_CONSTANTS.SET_IS_LOGGED](state, loginState) {
    state.isLogged = loginState;
  },
  [MUTATIONS_CONSTANTS.SET_LAST_LOGGED](state, lastLogged) {
    state.lastLogged = lastLogged;
  },
  [MUTATIONS_CONSTANTS.SET_DEVICE_ID](state, deviceid) {
    state.deviceId = deviceid;
  },
  [MUTATIONS_CONSTANTS.SET_PLATFORM](state, platform) {
    state.platform = platform;
  },
  [MUTATIONS_CONSTANTS.SET_BROWSER](state, browser) {
    state.browser = browser;
  },
  [MUTATIONS_CONSTANTS.SET_IS_STANDALONE](state, isStandalone) {
    state.isStandalone = isStandalone;
  },
  [MUTATIONS_CONSTANTS.SET_SYSTEM_VERSION](state, systemVersion) {
    state.systemVersion = systemVersion;
  },
  [MUTATIONS_CONSTANTS.SET_INSTALL_REMINDER_TIMESTAMP](state, reminderTimestamp) {
    state.installReminderTimestamp = reminderTimestamp;
  },
  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEWSLETTER_STATE](state, forvardinoNewsletterState) {
    state.forvardinoNewsletterState = forvardinoNewsletterState;
  },
  // PROFILE
  [MUTATIONS_CONSTANTS.SET_PROFILE_ID](state, id) {
    state.profile.id = id;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_NAME](state, name) {
    state.profile.name = name;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_WORK_EMAIL](state, workEmail) {
    state.profile.workEmail = workEmail;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_OTHER_EMAIL](state, otherEmail) {
    state.profile.otherEmail = otherEmail;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_PHONE](state, phone) {
    state.profile.phone = phone;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_PHOTO](state, photoUrl) {
    state.profile.photoUrl = photoUrl;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_CONSENT_PERSONAL_DATA](state, consent) {
    state.profile.consentPersonalData = consent;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_LOCALITY](state, locality) {
    state.profile.locality = locality;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_GAID](state, gaId) {
    state.profile.gaId = gaId;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_ANONYMOUS](state, otherEmail) {
    state.profile.anonymous = otherEmail;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_LAST_CHECK](state) {
    let date = new Date();
    let timestamp = Number(date);
    state.profile.lastCheck.date = date;
    state.profile.lastCheck.timestamp = timestamp;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_MENU](state, menuState) {
    state.isProfileMenuOpened = menuState;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_GLOBAL_SEARCH_HISTORY](state, history) {
    state.profile.searchHistory = history;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_GLOBAL_SEARCH_HISTORY_DELETE](state, historyItemId) {
    //console.log(historyItemId);
    Object.entries(state.profile.searchHistory).forEach(([subIndex, subVal]) => {
      if (parseInt(subVal.id) === parseInt(historyItemId)) {
        state.profile.searchHistory.splice(subIndex, 1);
      }
      //console.log(subVal);
      //state.preferences.data[key].push(subVal)
    });
    //state.profile.searchHistory = history;
  },
  [MUTATIONS_CONSTANTS.SET_PREFERENCES_LAST_CHECK](state) {
    let date = new Date();
    let timestamp = Number(date);
    state.preferences.lastCheck.date = date;
    state.preferences.lastCheck.timestamp = timestamp;
  },
  [MUTATIONS_CONSTANTS.SET_PREFERENCES_DATA](state, preferencesData) {
    if (state.preferences.data === null) {
      state.preferences.data = [];
    }
    Object.entries(preferencesData).forEach(([key, val]) => {
      state.preferences.data[key] = [];
      Object.entries(val).forEach(([, subVal]) => {
        state.preferences.data[key].push(subVal)
      });
    });
  },
  [MUTATIONS_CONSTANTS.SET_PREFERENCES_RESET](state) {
    state.preferences.lastCheck.date = '';
    state.preferences.lastCheck.timestamp = '';
    state.preferences.data.parking = [];
    state.preferences.data.restaurants = [];
  },
  [MUTATIONS_CONSTANTS.SET_NOTIFICATIONS_LAST_CHECK](state) {
    let date = new Date();
    let timestamp = Number(date);
    state.notifications.lastCheck.date = date;
    state.notifications.lastCheck.timestamp = timestamp;
  },
  [MUTATIONS_CONSTANTS.SET_NOTIFICATIONS_RESET](state) {
    state.notifications.lastCheck.date = '';
    state.notifications.lastCheck.timestamp = '';
  },
  // APP NOTIFICATIONS MUTATIONS
  // ===================================================
  [MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_MENU](state, notificationState) {
    state.isAppNotificationsMenuOpened = notificationState;
  },
  [MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS](state, notificationsData) {
    state.appNotificationsList = notificationsData
  },
  [MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_RESET](state) {
    state.appNotificationsList = [];
  },
  // MESSAGES
  // ===================================================
  [MUTATIONS_CONSTANTS.SET_USER_MESSAGES](state, messagesData) {
    state.messagesList = messagesData
  },

  [MUTATIONS_CONSTANTS.SET_USER_MESSAGES_RESET](state) {
    state.messagesList = [];
  },

  [MUTATIONS_CONSTANTS.SET_USER_MESSAGES_ACTIVE_MODULE](state, activeCategory) {
    state.messagesActiveModule = activeCategory;
  },

  [MUTATIONS_CONSTANTS.SET_USER_MESSAGES_SELECTED_CATEGORIES](state, selectedCategories) {
    state.messagesSelectedCategories.modules = selectedCategories.modules;
  },

  [MUTATIONS_CONSTANTS.SET_USER_MESSAGES_FILTER_ACTIVE](state, filterState) {
    state.messagesAreFiltersActive = filterState;
  },
  // AUTHORIZED MODE ONLY MUTATIONS
  // ===================================================
  [MUTATIONS_CONSTANTS.AM_SET_USER_PROFILE](state, userProfile) {
    state.amUserProfile = userProfile;
  }
};

const actions = {
  [ACTIONS_CONSTANTS.TOGGLE_PROFILE_MENU]({state, commit}) {
    let menuState = state.isProfileMenuOpened;

    commit(MUTATIONS_CONSTANTS.SET_PROFILE_MENU, menuState = !menuState);

    // close notifications menu if it's opened
    if (state.isAppNotificationsMenuOpened) {
      commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_MENU, false);
    }
  },
  [ACTIONS_CONSTANTS.MAKE_INSTALL_REMINDER_TIMESTAMP]({ commit }) {
    const reminderTimestamp = Date.now() + TWO_WEEKS_IN_MS;

    commit(MUTATIONS_CONSTANTS.SET_INSTALL_REMINDER_TIMESTAMP, reminderTimestamp);
  },
  [ACTIONS_CONSTANTS.LOGOUT_USER]({ commit }) {
    return dataLoader.fetchUnauthorization()
      .finally(() => {
        commit(MUTATIONS_CONSTANTS.SET_PUBLIC_KEY, '');
        commit(MUTATIONS_CONSTANTS.SET_REFRESH_TOKEN, null);
        commit(MUTATIONS_CONSTANTS.SET_TOKEN_STATUS, null);
        commit(MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_ID, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_NAME, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_WORK_EMAIL, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_OTHER_EMAIL, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_PHONE, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_PHOTO, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_CONSENT_PERSONAL_DATA, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_LOCALITY, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_GAID, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_ANONYMOUS, null);
        commit(MUTATIONS_CONSTANTS.AM_SET_USER_PROFILE, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
        commit(MUTATIONS_CONSTANTS.SET_PREFERENCES_RESET);
      });
  },
  [ACTIONS_CONSTANTS.IDENTITY_PROFILE]({ state, commit }, options) {
    let currentDateTime = new Date();
    let checkInterval = globalConfig.CONTENT_DATA_UPDATE_CHECK_INTERVAL;
    if ((currentDateTime - state.profile.lastCheck.timestamp) < checkInterval && !options.force) {
      return false;
    }

    return dataLoader.fetchIdentityProfile()
      .then(data => {
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
        commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_MENU, false);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_LAST_CHECK);
        if (data.hasOwnProperty('id')) {
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_ID, data.id);
        }
        if (data.hasOwnProperty('name')) {
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_NAME, data.name);
        }
        if (data.hasOwnProperty('workEmail')) {
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_WORK_EMAIL, data.workEmail);
        }
        if (data.hasOwnProperty('otherEmail')) {
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_OTHER_EMAIL, data.otherEmail);
        }
        if (data.hasOwnProperty('phone')) {
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_PHONE, data.phone);
        }
        if (data.hasOwnProperty('photoUrl')) {
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_PHOTO, data.photoUrl);
        }
        if (data.hasOwnProperty('consentPersonalData')) {
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_CONSENT_PERSONAL_DATA, data.consentPersonalData);
        }
        if (data.hasOwnProperty('locality')) {
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_LOCALITY, data.locality);
        }
        if (data.hasOwnProperty('gaId')) {
          const oldId = state.profile?.gaId;
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_GAID, data.gaId);

          // set GA userId
          if (!oldId || oldId !== data.gaId) {
            logger.info('[Profile] userId: ' + data.gaId);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'login',
              'user_id': data.gaId
            });
          }
        }
        if (data.hasOwnProperty('anonymous')) {
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_ANONYMOUS, data.anonymous);

          if (data.anonymous) {
            // redirect to login
            router.push({ name: 'login' });
          }
        }
        if (data.hasOwnProperty('searchHistory')) {
          commit(MUTATIONS_CONSTANTS.SET_PROFILE_GLOBAL_SEARCH_HISTORY, data.searchHistory);
        }
      })
  },
  [ACTIONS_CONSTANTS.DELETE_IDENTITY_PROFILE]({ commit }) {
    return dataLoader.deleteProfile()
      .then(() => {
        commit(MUTATIONS_CONSTANTS.SET_PUBLIC_KEY, '');
        commit(MUTATIONS_CONSTANTS.SET_REFRESH_TOKEN, null);
        commit(MUTATIONS_CONSTANTS.SET_TOKEN_STATUS, null);
        commit(MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_ID, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_NAME, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_WORK_EMAIL, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_OTHER_EMAIL, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_PHONE, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_PHOTO, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_CONSENT_PERSONAL_DATA, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_LOCALITY, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_GAID, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_ANONYMOUS, null);
        commit(MUTATIONS_CONSTANTS.AM_SET_USER_PROFILE, null);
        commit(MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
        commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_MENU, false);
        commit(MUTATIONS_CONSTANTS.SET_PREFERENCES_RESET);
      });
  },
  [ACTIONS_CONSTANTS.IDENTITY_PREFERENCES]({ state, commit }, options) {
    let currentDateTime = new Date();
    let checkInterval = globalConfig.CONTENT_DATA_UPDATE_CHECK_INTERVAL;
    if ((currentDateTime - state.preferences.lastCheck.timestamp) < checkInterval && !options.force) {
      return false;
    }

    return dataLoader.fetchProfilePreference(options)
      .then(data => {
        commit(MUTATIONS_CONSTANTS.SET_PREFERENCES_LAST_CHECK);
        commit(MUTATIONS_CONSTANTS.SET_PREFERENCES_DATA, data);
      })
  },
  [ACTIONS_CONSTANTS.IDENTITY_PUSH_NOTIFICATION_SUB]({ state, commit }, options) {
    let currentDateTime = new Date();
    let checkInterval = 600000;
    if ((currentDateTime - state.notifications.lastCheck.timestamp) < checkInterval && !options.force) {
      return false;
    }

    let formData = new FormData();
    formData.append('endpoint', options[0]);

    return dataLoader.postProfilePushNotificationSub(formData)
      .then(() => {
        commit(MUTATIONS_CONSTANTS.SET_NOTIFICATIONS_LAST_CHECK);
      })
  },
  // APP NOTIFICATIONS ACTIONS
  // ===================================================
  [ACTIONS_CONSTANTS.TOGGLE_APP_NOTIFICATIONS_MENU]({state, commit}) {
    let notificationState = state.isAppNotificationsMenuOpened;

    commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_MENU, notificationState = !notificationState);

    // close profile menu if it's opened
    if (state.isProfileMenuOpened) {
      commit(MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
    }
  },
  [ACTIONS_CONSTANTS.FETCH_APP_NOTIFICATIONS]({ commit }) {
    return dataLoader.fetchAppNotifications()
      .then(data => {
        const appNotifications = data.data.notices;
        const notificationsSortedByDate = appNotifications.sort((a, b) => {
          return new Date(b.created.date) - new Date(a.created.date);
        });

        commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS, notificationsSortedByDate);
      })
  },
  [ACTIONS_CONSTANTS.APP_NOTIFICATIONS_READ_ALL]({ commit }) {
    return dataLoader.postAppNotificationsReadAll()
      .then(() => {
        commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_RESET);
      })
  },
  [ACTIONS_CONSTANTS.APP_NOTIFICATION_READ]({ state, commit }, id) {
    return dataLoader.postAppNotificationRead(id)
      .then(() => {
        const filteredAppNotifications = state.appNotificationsList.filter((item) => item.id !== id);

        commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS, filteredAppNotifications);
      })
  },
  // MESSAGES ACTIONS
  // ===================================================
  [ACTIONS_CONSTANTS.FETCH_USER_MESSAGES]({ commit }) {
    return dataLoader.fetchMessages()
      .then(data => {
        commit(MUTATIONS_CONSTANTS.SET_USER_MESSAGES, data);
      })
  },
  [ACTIONS_CONSTANTS.RESET_ALL_MESSAGES_FILTER]({ commit }) {
    const resettedMessagesObject = {
      modules: []
    }

    commit(MUTATIONS_CONSTANTS.SET_USER_MESSAGES_SELECTED_CATEGORIES, resettedMessagesObject);
    commit(MUTATIONS_CONSTANTS.SET_USER_MESSAGES_ACTIVE_MODULE, 0);
    commit(MUTATIONS_CONSTANTS.SET_USER_MESSAGES_FILTER_ACTIVE, false);
  },
  // AUTHORIZED MODE ONLY ACTIONS
  // ===================================================
  [ACTIONS_CONSTANTS.AM_LOAD_AUTHORIZED_MODE_PROFILE]({ commit }) {
    return dataLoader.fetchUserProfile()
      .then(data => {
        commit(MUTATIONS_CONSTANTS.AM_SET_USER_PROFILE, data);
      })
  }
};

const getters = {
  [GETTERS_CONSTANTS.GET_USER_ROLE]: state => role => {
    return state.amUserProfile.roles.includes(role)
  },
  [GETTERS_CONSTANTS.GET_USER_HAS_IDENTITY]: state => {
    return state.profile.anonymous === false;
  },
  [GETTERS_CONSTANTS.GET_USER_NOTICES]: state => {
    return state.appNotificationsList;
  },
  [GETTERS_CONSTANTS.GET_USER_PREFERENCES]: state => {
    return state.preferences.data;
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
