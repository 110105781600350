<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div>
    <t-icon-button
      :disabled="disabled"
      @click="openShareDialog"
    >
      <!-- Icon -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
        />
      </svg>
      <span class="sr-only">{{ $t('esg.stories.shareButton') }}</span>
    </t-icon-button>

    <!-- Overlay -->
    <prj1009-modal
      :active="shareDialog"
      @overlayCloseEvent="closeOverlay"
    >
      <template
        v-if="imageRefName"
        v-slot:content
      >
        <div class="w-full mb-6">
          <img
            :src="generatedImageUrl"
            :alt="title"
          />
        </div>
      </template>

      <template v-slot:controls>
        <div class="flex justify-center">
          <transition
            name="fade"
            mode="out-in"
            @after-enter="closeOverlay"
          >
            <t-button
              :key="indicatorCopied"
              class="flex transition-opacity duration-300"
              @click="copyToClipboard"
            >
              <span class="stroke-current text-white">
                <svg
                  v-if="indicatorCopied"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                  />
                </svg>
              </span>
              <span class="text-white px-3">{{ indicatorCopied ? $t('esg.stories.copySuccess') : $t('esg.stories.copyButton') }}</span>
            </t-button>
          </transition>
        </div>
      </template>
    </prj1009-modal>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import * as clipboardy from 'clipboardy';
import * as htmlToImage from 'html-to-image';
import logger from '@/utils/logger';

import Prj1009Modal from '@/components/esg/prj1009-modal/prj1009-modal';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1015ShareButton',
  components: {Prj1009Modal},
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    imageRefName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      shareDialog: false,
      indicatorCopied: false,
      generatedImageUrl: ''
    }
  },
  computed: {
    imageRef() {
      const parentRefs = this.$parent.$refs;
      return parentRefs[this.imageRefName];
    },
    imageFileName() {
      return this.title.replace(/\s/g, '-') + '.png';
    }
  },
  methods: {
    openShareDialog(event) {
      if (this.imageRefName) {
        this.$emit('generatingImage', true);
        this.imageShare(event);

        return;
      }

      if (navigator.share) {
        navigator.share({
          title: this.title,
          text: this.text || this.title,
          url: this.url
        })
          .catch(error => {
            logger.error(error)
          })
      } else {
        this.toggleShareDialog(event);
      }
    },

    imageShare(event) {
      htmlToImage.toBlob(this.imageRef)
        .then(blob => {
          const filesArray = [
            new File(
              [blob],
              this.imageFileName, // Name of file
              {
                type: blob.type,
                lastModified: new Date().getTime()
              }
            )
          ];

          if (navigator.share && navigator.canShare({ files: filesArray })) {
            navigator.share({
              files: filesArray
            })
              .catch(error => {
                logger.error(error)
              })
          } else {
            let targetImage;
            const reader = new FileReader();
            reader.onload = event => {
              targetImage = event.target.result;

              this.generatedImageUrl = targetImage;
            };
            reader.readAsDataURL(blob);
            this.toggleShareDialog(event);
          }

          this.$emit('generatingImage', false);
        });
    },

    toggleShareDialog(event) {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.shareDialog = !this.shareDialog;
    },

    copyToClipboard() {
      const writeUrl = this.imageRefName ? this.generatedImageUrl : this.url;

      clipboardy.write(writeUrl).then(() => {
        this.indicatorCopied = true;
      })
    },

    closeOverlay() {
      this.shareDialog = false;
      this.indicatorCopied = false;
    },
  }
}
</script>
