/**
 * =======================================================================
 * General multi-purpose utils for all projects in need
 *
 * @name    conceptsUtilsGeneral
 * @version 0.1.0
 * =======================================================================
 * =======================================================================
 */

/**
 * Rounds to integer or decimals, based on decimals param
 * @param value
 * @param decimals [Number] – round to number position relative to decimal separator.
 * +2 means rounding to 2 decimals, +3 means rounding to 3 decimals.
 * -2 means rounding to tens, -3 means rounding to hundreds.
 * 0 or undefined means don't round.
 * @returns {number}
 */
export const round = (value, decimals) => {
    if (decimals >= 0) {
        // rounding decimals in JS
        // https://www.jacklmoore.com/notes/rounding-in-javascript/
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    } else {
        let roundToIntDecimals = Math.abs(decimals);
        let roundToGrade = 1;

        for (let i = 0; i < roundToIntDecimals - 1; i++) {
            roundToGrade = roundToGrade * 10;
        }

        return Math.round(value / roundToGrade) * roundToGrade;
    }
};
/**
 * Returns unit - either fixed unit or inflected unit
 * @param value Number
 * @param unit  String|Object
 * Object type example:
 * {
 *   0: 'let',
 *   1: 'rok',
 *   2: 'roky',
 *   5: 'let',
 *   decimal: 'roku'
 * }
 * Number values are evaluated from current key, to next key
 * example: { 2: 'roky', 5: 'let' } - 'roky' is returned for values 2,3,4
 *
 * decimal key is optional, its value is returned for all float values
 *
 * value is converted to absolute, so only positive keys are allowed
 * @returns [String|null]
 */
export const getUnit = (value, unit) => {
    let isUnitObject = unit !== null && typeof unit === 'object';

    if (isUnitObject) {
        return getInflectedUnit(value, unit);
    } else if (typeof unit === 'string') {
        return unit;
    }

    return null;
};

/**
 * This function returns specific locale decimal and thousands separators
 * EXAMPLE: for cs-CZ, return value is
 * {
 *    thousandsSeparator: ' ',
 *    decimalSeparator: ','
 * }
 *
 * default browser language can be passed param locale set to navigator.language
 *
 * @param locale [String] - valid locale string for function toLocaleString, example cs-CZ, en-GB etc.
 * @returns [Object] - [{decimalSeparator: [String], thousandsSeparator: [String]}]
 */
export const getLocalizedNumberSeparators = (locale) => {
    let testNumber = 1000.99;

    let testNumberArray = testNumber
        .toLocaleString(locale, {minimumDecimalDigits: 2, maximumDecimalDigits: 2})
        .toString()
        .split('');

    return {
        thousandsSeparator: testNumberArray[1],
        decimalSeparator: testNumberArray[5]
    };
};
/**
 * Gets inflected unit based on value
 * @param value          [Number]
 * @param inflectedUnits [Object]
 * example:
 * {
 *   0: 'let',
 *   1: 'rok',
 *   2: 'roky',
 *   5: 'let',
 *   decimal: 'roku'
 * }
 *
 * Number values are evaluated from current key, to next key
 * example: { 2: 'roky', 5: 'let' } - 'roky' is returned for values 2,3,4
 *
 * decimal key is optional, its value is returned for all float values
 *
 * value is converted to absolute, so only positive keys are allowed
 *
 * @returns [String]
 */
export const getInflectedUnit = (value, inflectedUnits) => {
    let unit;
    let foundKey;

    let decimalRest = parseInt(value) - value;
    let valueIsDecimal = decimalRest !== 0;

    if (!valueIsDecimal) {
        for (let prop in inflectedUnits) {
            let valueAbsolute = Math.abs(value);

            if (prop !== 'decimal') {
                if (!inflectedUnits.hasOwnProperty(prop)) {
                    continue;
                }
                if (prop > valueAbsolute) {
                    break;
                }
                foundKey = prop;
            }
        }
        if (foundKey) {
            unit = inflectedUnits[foundKey];
        }
    } else {
        if (inflectedUnits.hasOwnProperty('decimal')) {
            unit = inflectedUnits.decimal;
        }
    }
    return unit;
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait, immediate) => {
    var timeout;
    return () => {
        const context = this,
            args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const capitalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};


export const includes = (collection, item) => {
    return collection.indexOf(item) !== -1;
};

export const createError = (message) => {
    new Error(`ERROR: ${message}`);
};

export const formatFileSize = (size) => {
    const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const threshold = 1024;
    size = Number(size) * threshold;
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold));
    return `${(size / Math.pow(threshold, i)).toFixed(2) * 1} ${units[i]}`;
};

export const merge = (target, source) => {
    if (!(isObject(target) && isObject(source))) {
        return target;
    }

    Object.keys(source).forEach(key => {
        if (isObject(source[key])) {
            if (!target[key]) {
                assign(target, {[key]: {}});
            }

            merge(target[key], source[key]);
            return;
        }

        assign(target, {[key]: source[key]});
    });

    return target;
};
/**
 * Assign polyfill from the mdn.
 */
export const assign = (target, ...others) => {
    /* istanbul ignore else */
    if (isCallable(Object.assign)) {
        return Object.assign(target, ...others);
    }

    /* istanbul ignore next */
    if (target == null) {
        throw new TypeError('Cannot convert undefined or null to object');
    }

    /* istanbul ignore next */
    const to = Object(target);
    /* istanbul ignore next */
    others.forEach(arg => {
        // Skip over if undefined or null
        if (arg != null) {
            Object.keys(arg).forEach(key => {
                to[key] = arg[key];
            });
        }
    });
    /* istanbul ignore next */
    return to;
};
/**
 * Checks if a function is callable.
 */
export const isCallable = (func) => {
    return typeof func === 'function';
};

/**
 * Checks if the value is an object.
 */
export const isObject = (obj) => {
    return obj !== null && obj && typeof obj === 'object' && !Array.isArray(obj);
};

const utilsGeneral = Object.freeze({
    round,
    getUnit,
    getLocalizedNumberSeparators,
    getInflectedUnit,
    debounce,
    capitalizeFirst,
    includes,
    createError,
    formatFileSize,
    merge,
    assign,
    isCallable,
    isObject
});

export { utilsGeneral };
