import utilsGeneral from '../utils-general';

let RestaurantConverter = function(supplierData) {
  this.supplierData = supplierData;
  this.convertedData = [];
  this.categories = [];
  this.categoriesIndex = {};
  this.categoriesSupplierLocalization = {};
  this._runConverter();
};

RestaurantConverter.prototype = {
  constructor: RestaurantConverter,
  getConvertedData() {
    let today = new Date();
    let currentWeekYear = utilsGeneral.getWeekNumber(today);
    let currentWeek = currentWeekYear[1];
    let currentYear = currentWeekYear[0];
    let weekStamp = currentYear + '-' + currentWeek;

    return {
      [weekStamp]: {
        slug: weekStamp,
        title: weekStamp,
        content: this.convertedData
      }
    }
  },
  /* =================================================== */
  /* === PRIVATE METHODS                             === */
  /* =================================================== */
  _runConverter() {
    let dates = this._detectDates();
    if(!dates) {
      return false;
    }
    this._prepareDateBasedStructures(dates);
    this._convertMeals();
  },
  _prepareDateBasedStructures(datesArray) {
    let dayNames = ['Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek'];

    for(let i = 0; i < datesArray.length; i++) {
      let day = datesArray[i].getDate();
      let month = datesArray[i].getMonth() + 1;
      let dayStr =  day < 10 ? '0' + day : '' + day;
      let monthsStr = (month < 10) ? '0' + month : '' + month;
      let dayObj = {
        dateUTC: datesArray[i],
        date: datesArray[i].getFullYear() + '-' + monthsStr + '-' + dayStr,
        dayName: dayNames[i],
        categories: []
      };

      this.convertedData.push(dayObj);
    }
  },
  _detectDates() {
    let dates = null;
    if(this.supplierData && this.supplierData.week && this.supplierData.week.value) {
      let weekValue = this.supplierData.week.value;
      // structure from supplier: 10.6.-14.6.2019
      if(weekValue.indexOf('-') === -1) {
        return null;
      }
      let weekArr = weekValue.split('-');
      let fridayInISOArr = weekArr[1].split('.').reverse();
      // check proper ISO day format - leading zero
      if(fridayInISOArr[0].length < 2) {
        fridayInISOArr[0] = '0' + fridayInISOArr[0];
      }
      // check proper ISO day format - leading zero
      if(fridayInISOArr[1].length < 2) {
        fridayInISOArr[1] = '0' + fridayInISOArr[1];
      }
      let fridayInISO = fridayInISOArr.join('-');
      // set all dates to
      let friDateUTC = new Date(fridayInISO + 'T00:00:00');
      let thuDateUTC = new Date(fridayInISO + 'T00:00:00');
      let wedDateUTC = new Date(fridayInISO + 'T00:00:00');
      let tueDateUTC = new Date(fridayInISO + 'T00:00:00');
      let monDateUTC = new Date(fridayInISO + 'T00:00:00');
      thuDateUTC.setDate(friDateUTC.getDate() - 1);
      wedDateUTC.setDate(friDateUTC.getDate() - 2);
      tueDateUTC.setDate(friDateUTC.getDate() - 3);
      monDateUTC.setDate(friDateUTC.getDate() - 4);
      dates = [
        monDateUTC, tueDateUTC, wedDateUTC, thuDateUTC, friDateUTC
      ]
    }
    return dates;
  },
  _convertMeals() {
    let meals = this.supplierData.meals;
    for(let mealKey in meals) {
      if(!Object.prototype.hasOwnProperty.call(meals, mealKey)) {
        continue;
      }

      let meal = meals[mealKey];
      let categoryName = meal['name'];
      if (!this.categoriesSupplierLocalization[meal['name']]) {
        this.categoriesSupplierLocalization[meal['name']] = meal['value'];
      }

      for (let i = 0; i < meal['items'].length; i++) {
        let singleMeal = meal['items'][i];
        this._setMealToCategoryAndDay(categoryName, singleMeal.day - 1, singleMeal.value, singleMeal.img, singleMeal.allergens, singleMeal.nutrition_facts, singleMeal.pictograms, singleMeal.price);
      }
    }
  },
  _setMealToCategoryAndDay(categoryName, dayIndex, mealName, image, allergens, nutritionFacts, pictograms, price) {
    let categoryIndex = this._getOrCreateCategoryIndex(categoryName);
    if (!this.convertedData[dayIndex].categories[categoryIndex]) {
      this._createCategoryInDay(dayIndex, categoryIndex, categoryName);
    }

    let originalObjectCopy = this.convertedData[dayIndex].categories[categoryIndex];

    let mealConverted = {
      name: mealName,
      category_id: categoryName,
      alergens: allergens,
      attributes: nutritionFacts,
      price: price,
      pictures: {
        small: image,
        large: image,
        pictograms: pictograms
      }
    };

    this.convertedData[dayIndex].categories[categoryIndex] = {...originalObjectCopy, ...mealConverted};
  },
  _createCategoryInDay(dayIndex, categoryIndex) {
    this.convertedData[dayIndex].categories[categoryIndex] = {
      category_name: this._localizeCategory(this.categories[categoryIndex])
    };
  },
  _convertCategoryName(nameFromSupplier, technicalName) {
    return technicalName;
  },
  _localizeCategory(technicalName) {
    if(this.categoriesSupplierLocalization[technicalName]) {
      return this.categoriesSupplierLocalization[technicalName];
    } else {
      return 'Ostatní';
    }
  },
  /**
     * We must convert names of categories from supplier to indexes of categories used by our app structure.
     *
     * @param categoryName
     * @return {*}
     */
  _getOrCreateCategoryIndex(categoryName) {
    if(this.categoriesIndex[categoryName] === undefined) {
      this.categories.push(categoryName);
      this.categoriesIndex[categoryName] = this.categories.length - 1;
    }
    return this.categoriesIndex[categoryName];
  }
};

export default RestaurantConverter;
