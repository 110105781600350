// === GENERAL ==============================//
export const SET_VIEWPORT_SIZE = 'SET_VIEWPORT_SIZE';
export const SET_DASHBOARD_MENU = 'SET_DASHBOARD_MENU';
export const SET_DASHBOARD_SUBMENU = 'SET_DASHBOARD_SUBMENU';
export const SET_DASHBOARD_SUBMENU_CATEGORY = 'SET_DASHBOARD_SUBMENU_CATEGORY';
export const SET_BACK_TO_NAVIGATION = 'SET_BACK_TO_NAVIGATION';
export const SET_DRAWER_IS_OPENED = 'SET_DRAWER_IS_OPENED';
export const SET_SW_REGISTRATION = 'SET_SW_REGISTRATION';
export const SET_SW_INSTANCE = 'SET_SW_INSTANCE';
export const SET_APP_UPDATE_READY = 'SET_APP_UPDATE_READY';
export const SET_APP_UPDATE_FAILED = 'SET_APP_UPDATE_FAILED';
export const SET_APP_VERSION = 'SET_APP_VERSION';
export const SET_APPLICATION_CONFIG = 'SET_APPLICATION_CONFIG';
export const SET_APPLICATION_CONFIG_LAST_CHECK = 'SET_APPLICATION_CONFIG_LAST_CHECK';
export const SET_SURVEY_CONFIG = 'SET_SURVEY_CONFIG';
export const SET_NAVIGATION_DATA = 'SET_NAVIGATION_DATA';
export const SET_DASHBOARD_BUTTONS = 'SET_DASHBOARD_BUTTONS';
export const SET_DASHBOARD_GROUP_BUTTONS = 'SET_DASHBOARD_GROUP_BUTTONS';
export const SET_PUSH_NOTIFICATIONS_STATUS = 'SET_PUSH_NOTIFICATIONS_STATUS';
export const SET_PUSH_NOTIFICATIONS_ENDPOINT = 'SET_PUSH_NOTIFICATIONS_ENDPOINT';
export const SET_PUSH_NOTIFICATIONS_PERMISSION = 'SET_PUSH_NOTIFICATIONS_PERMISSION';
export const SET_NETWORK_ERROR = 'SET_NETWORK_ERROR';
export const SET_STATISTICS_TIMESTAMP = 'SET_STATISTICS_TIMESTAMP';
export const SET_VERSION_ON_SERVER = 'SET_VERSION_ON_SERVER';
export const SET_APP_CHANGELOG = 'SET_APP_CHANGELOG';
export const SET_COVID19_ACTIVE_CATEGORY = 'SET_COVID19_ACTIVE_CATEGORY';
export const START_PROCESS = 'START_PROCESS';
export const STOP_PROCESS = 'STOP_PROCESS';
export const SET_DASHBOARD_BUTTONS_EXPANDED = 'SET_DASHBOARD_BUTTONS_EXPANDED';
export const SET_DASHBOARD_BUTTONS_EXPANDED_WIDTH = 'SET_DASHBOARD_BUTTONS_EXPANDED_WIDTH';

// === PERSISTENT STORAGE ===================//
export const SET_YEAR_AND_WEEK = 'SET_YEAR_AND_WEEK';
export const SET_REGISTERED_EVENT = 'SET_REGISTERED_EVENT';
export const REMOVE_REGISTERED_EVENT = 'REMOVE_REGISTERED_EVENT';
export const SET_REGISTERED_FORVARDINO_EVENT = 'SET_REGISTERED_FORVARDINO_EVENT';
export const REMOVE_REGISTERED_FORVARDINO_EVENT = 'REMOVE_REGISTERED_FORVARDINO_EVENT';
export const SET_RATED_FORVARDINO_EVENT = 'SET_RATED_FORVARDINO_EVENT';
export const REMOVE_RATED_FORVARDINO_EVENT = 'REMOVE_RATED_FORVARDINO_EVENT';
export const SET_REGISTERED_WORKOUT = 'SET_REGISTERED_WORKOUT';
export const REMOVE_REGISTERED_WORKOUT = 'REMOVE_REGISTERED_WORKOUT';
export const POSTPONE_PUSH_NOTIFICATIONS = 'POSTPONE_PUSH_NOTIFICATIONS';
export const SET_LAST_FOOD_RATING_TIMESTAMP = 'SET_LAST_FOOD_RATING_TIMESTAMP';
export const SET_FILLED_SURVEY_ID = 'SET_FILLED_SURVEY_ID';
export const SET_HAS_FILLED_SURVEY = 'SET_HAS_FILLED_SURVEY';
export const SET_BUILDING_HHQ_INTRO_VISITED = 'SET_BUILDING_HHQ_INTRO_VISITED';
export const SET_PREVIOUS_SELECTED_RESTAURANT = 'SET_PREVIOUS_SELECTED_RESTAURANT';
export const SET_PREVIOUS_SELECTED_BRANCH = 'SET_PREVIOUS_SELECTED_BRANCH';

// === DATA STORE ===========================//
export const SET_CONTENT_DATA_UPDATE = 'SET_CONTENT_DATA_UPDATE';
export const UPDATE_ARTICLES = 'UPDATE_ARTICLES';
export const UPDATE_RESTAURANTS = 'UPDATE_RESTAURANTS';
export const UPDATE_RESTAURANTS_CAFE = 'UPDATE_RESTAURANTS_CAFE';
export const UPDATE_RESTAURANTS_EXTERNAL = 'UPDATE_RESTAURANTS_EXTERNAL';
export const UPDATE_RECOMMENDED_POSTS = 'UPDATE_RECOMMENDED_POSTS';

// === ARTICLES ============================//
export const SET_NEWS_ACTIVE_CATEGORY = 'SET_NEWS_ACTIVE_CATEGORY';
export const SHOW_FILTERED_ARTICLES = 'SHOW_FILTERED_ARTICLES';
export const ADD_FILTERED_ARTICLES = 'ADD_FILTERED_ARTICLES';
export const SET_DETAIL_DATA = 'SET_DETAIL_DATA';

// === USER ================================//
export const SET_PUBLIC_KEY = 'SET_PUBLIC_KEY';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_TOKEN_STATUS = 'SET_TOKEN_STATUS';
export const SET_IS_LOGGED = 'SET_IS_LOGGED';
export const SET_LAST_LOGGED = 'SET_LAST_LOGGED';
export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const SET_PLATFORM = 'SET_PLATFORM';
export const SET_BROWSER = 'SET_BROWSER';
export const SET_IS_STANDALONE = 'SET_IS_STANDALONE';
export const SET_SYSTEM_VERSION = 'SET_SYSTEM_VERSION';
export const SET_INSTALL_REMINDER_TIMESTAMP = 'SET_INSTALL_REMINDER_TIMESTAMP';
export const SET_FORVARDINO_NEWSLETTER_STATE = 'SET_FORVARDINO_NEWSLETTER_STATE';
export const SET_PREFERENCES_LAST_CHECK = 'SET_PREFERENCES_LAST_CHECK';
export const SET_PREFERENCES_DATA = 'SET_PREFERENCES_DATA';
export const SET_PREFERENCES_RESET = 'SET_PREFERENCES_RESET';
export const SET_NOTIFICATIONS_LAST_CHECK = 'SET_NOTIFICATIONS_LAST_CHECK';
export const SET_NOTIFICATIONS_RESET = 'SET_NOTIFICATIONS_RESET';
// profile
export const SET_PROFILE_ID = 'SET_PROFILE_ID';
export const SET_PROFILE_NAME = 'SET_PROFILE_NAME';
export const SET_PROFILE_WORK_EMAIL = 'SET_PROFILE_WORK_EMAIL';
export const SET_PROFILE_OTHER_EMAIL = 'SET_PROFILE_OTHER_EMAIL';
export const SET_PROFILE_PHONE = 'SET_PROFILE_PHONE';
export const SET_PROFILE_PHOTO = 'SET_PROFILE_PHOTO';
export const SET_PROFILE_CONSENT_PERSONAL_DATA = 'SET_PROFILE_CONSENT_PERSONAL_DATA';
export const SET_PROFILE_LOCALITY = 'SET_PROFILE_LOCALITY';
export const SET_PROFILE_GAID = 'SET_PROFILE_GAID';
export const SET_PROFILE_ANONYMOUS = 'SET_PROFILE_ANONYMOUS';
export const SET_PROFILE_LAST_CHECK = 'SET_PROFILE_LAST_CHECK';
export const SET_PROFILE_MENU = 'SET_PROFILE_MENU';
export const SET_PROFILE_GLOBAL_SEARCH_HISTORY = 'SET_PROFILE_GLOBAL_SEARCH_HISTORY';
export const SET_PROFILE_GLOBAL_SEARCH_HISTORY_DELETE = 'SET_PROFILE_GLOBAL_SEARCH_HISTORY_DELETE';
// authorized mode mutations
export const AM_SET_USER_PROFILE = 'AM_SET_USER_PROFILE';
export const AM_SET_USER_PROFILE_PHOTO = 'AM_SET_USER_PROFILE_PHOTO';

// === APP NOTIFICATIONS ========================//
export const SET_APP_NOTIFICATIONS_MENU = 'SET_APP_NOTIFICATIONS_MENU';
export const SET_APP_NOTIFICATIONS = 'SET_APP_NOTIFICATIONS';
export const SET_APP_NOTIFICATIONS_RESET = 'SET_APP_NOTIFICATIONS_RESET';

// === MESSAGES ================================//
export const SET_USER_MESSAGES = 'SET_USER_MESSAGES';
export const SET_USER_MESSAGES_RESET = 'SET_USER_MESSAGES_RESET';
export const SET_USER_MESSAGES_SELECTED_CATEGORIES = 'SET_USER_MESSAGES_SELECTED_CATEGORIES';
export const SET_USER_MESSAGES_ACTIVE_MODULE = 'SET_USER_MESSAGES_ACTIVE_MODULE';
export const SET_USER_MESSAGES_FILTER_ACTIVE = 'SET_USER_MESSAGES_FILTER_ACTIVE';

// === DASHBOARD ===============================//
export const SET_SELECTED_BUILDING = 'SET_SELECTED_BUILDING';
export const SET_DASHBOARD_BACKGROUND = 'SET_DASHBOARD_BACKGROUND';
export const SET_DASHBOARD_PROMOTED = 'SET_DASHBOARD_PROMOTED';
export const SET_DASHBOARD_PROMOTED_INITIALIZED = 'SET_DASHBOARD_PROMOTED_INITIALIZED';
export const SET_DASHBOARD_PROMOTED_LAST_CHECK = 'SET_DASHBOARD_PROMOTED_LAST_CHECK';
export const SET_DASHBOARD_COMING = 'SET_DASHBOARD_COMING';
export const SET_DASHBOARD_COMING_INITIALIZED = 'SET_DASHBOARD_COMING_INITIALIZED';
export const SET_DASHBOARD_COMING_LAST_CHECK = 'SET_DASHBOARD_COMING_LAST_CHECK';
export const SET_DASHBOARD_NEWS = 'SET_DASHBOARD_NEWS';
export const SET_DASHBOARD_NEWS_INITIALIZED = 'SET_DASHBOARD_NEWS_INITIALIZED';
export const SET_DASHBOARD_NEWS_LAST_CHECK = 'SET_DASHBOARD_NEWS_LAST_CHECK';

// === POPUP ===============================//
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const SET_ACTIVE_POPUP_MODULE = 'SET_ACTIVE_POPUP_MODULE';
export const SET_POPUP_POSITION = 'SET_POPUP_POSITION';

// === EVENTS ==============================//
export const SET_ALL_EVENTS = 'SET_ALL_EVENTS';
export const SET_EVENTS_CATEGORIES = 'SET_EVENTS_CATEGORIES';
export const SET_EVENTS_IS_LOADED = 'SET_EVENTS_IS_LOADED';
export const SET_FILTERED_CATEGORIES = 'SET_FILTERED_CATEGORIES';
export const SET_FILTERED_EVENTS = 'SET_FILTERED_EVENTS';
export const RESET_EVENTS = 'RESET_EVENTS';
export const SET_EVENT_REGISTRATION_ID = 'SET_EVENT_REGISTRATION_ID';

// === RECRUITMENT ==============================//
export const SET_RECRUITMENT_FILTER_LOCATION = 'SET_RECRUITMENT_FILTER_LOCATION';
export const SET_RECRUITMENT_FILTER_SPECIALIZATION = 'SET_RECRUITMENT_FILTER_SPECIALIZATION';
export const SET_RECRUITMENT_FILTER_TYPE = 'SET_RECRUITMENT_FILTER_TYPE';
export const SET_RECRUITMENT_FILTER_KEYWORD = 'SET_RECRUITMENT_FILTER_KEYWORD';
export const SET_RECRUITMENT_RESULT_DATA = 'SET_RECRUITMENT_RESULT_DATA';
export const SET_RECRUITMENT_REGISTRIES = 'SET_RECRUITMENT_REGISTRIES';
export const SET_RECRUITMENT_DETAIL_DATA = 'SET_RECRUITMENT_DETAIL_DATA';

// === RESTAURANTS =========================//
export const SET_SELECTED_RESTAURANT = 'SET_SELECTED_RESTAURANT';
export const SET_RESTAURANT_DAY = 'SET_RESTAURANT_DAY';
export const SET_CAFE_VISIBILITY = 'SET_CAFE_VISIBILITY';

// === FORVARDINO ==========================//
export const SET_FORVARDINO_FILTER_CATEGORIES = 'SET_FORVARDINO_FILTER_CATEGORIES';
export const SET_FORVARDINO_SELECTED_CATEGORIES = 'SET_FORVARDINO_SELECTED_CATEGORIES';
export const SET_ARE_FILTERS_ACTIVE = 'SET_ARE_FILTERS_ACTIVE';
export const SET_FORVARDINO_ACTIVE_CATEGORY = 'SET_FORVARDINO_ACTIVE_CATEGORY';
export const SET_FORVARDINO_PAGES = 'SET_FORVARDINO_PAGES';
export const SET_FORVARDINO_CUSTOM_COURSE_MODAL = 'SET_FORVARDINO_CUSTOM_COURSE_MODAL';
export const SET_FORVARDINO_CUSTOM_COURSE_FORM_MODAL = 'SET_FORVARDINO_CUSTOM_COURSE_FORM_MODAL';
export const SET_FORVARDINO_NEW_LESSON_FORM_MODAL = 'SET_FORVARDINO_NEW_LESSON_FORM_MODAL';
export const SET_FORVARDINO_EDIT_COURSE_FORM_MODAL = 'SET_FORVARDINO_EDIT_COURSE_FORM_MODAL';
export const SET_FORVARDINO_DRAFT_FORM_ID = 'SET_FORVARDINO_DRAFT_FORM_ID';
export const SET_FORVARDINO_DRAFT_FORM_NAME = 'SET_FORVARDINO_DRAFT_FORM_NAME';
export const SET_FORVARDINO_DRAFT_FORM_DESCRIPTION = 'SET_FORVARDINO_DRAFT_FORM_DESCRIPTION';
export const SET_FORVARDINO_DRAFT_FORM_CATEGORY = 'SET_FORVARDINO_DRAFT_FORM_CATEGORY';
export const SET_FORVARDINO_DRAFT_FORM_TARGET = 'SET_FORVARDINO_DRAFT_FORM_TARGET';
export const SET_FORVARDINO_DRAFT_FORM_IMAGE = 'SET_FORVARDINO_DRAFT_FORM_IMAGE';
export const SET_FORVARDINO_DRAFT_FORM_IMAGE_OLD = 'SET_FORVARDINO_DRAFT_FORM_IMAGE_OLD';
export const SET_FORVARDINO_DRAFT_FORM_STATE = 'SET_FORVARDINO_DRAFT_FORM_STATE';
export const SET_FORVARDINO_DRAFT_FORM_COMPLETED = 'SET_FORVARDINO_DRAFT_FORM_COMPLETED';
export const SET_FORVARDINO_DRAFT_FORM_RESET = 'SET_FORVARDINO_DRAFT_FORM_RESET';
export const SET_FORVARDINO_NEW_LESSON_FORM_ID = 'SET_FORVARDINO_NEW_LESSON_FORM_ID';
export const SET_FORVARDINO_NEW_LESSON_FORM_DATE_START = 'SET_FORVARDINO_NEW_LESSON_FORM_DATE_START';
export const SET_FORVARDINO_NEW_LESSON_FORM_DATE_END = 'SET_FORVARDINO_NEW_LESSON_FORM_DATE_END';
export const SET_FORVARDINO_NEW_LESSON_FORM_LOCATION = 'SET_FORVARDINO_NEW_LESSON_FORM_LOCATION';
export const SET_FORVARDINO_NEW_LESSON_FORM_PLACE = 'SET_FORVARDINO_NEW_LESSON_FORM_PLACE';
export const SET_FORVARDINO_NEW_LESSON_FORM_ONLINE = 'SET_FORVARDINO_NEW_LESSON_FORM_ONLINE';
export const SET_FORVARDINO_NEW_LESSON_FORM_URL = 'SET_FORVARDINO_NEW_LESSON_FORM_URL';
export const SET_FORVARDINO_NEW_LESSON_FORM_CAPACITY = 'SET_FORVARDINO_NEW_LESSON_FORM_CAPACITY';
export const SET_FORVARDINO_NEW_LESSON_FORM_COMPLETED = 'SET_FORVARDINO_NEW_LESSON_FORM_COMPLETED';
export const SET_FORVARDINO_NEW_LESSON_FORM_RESET = 'SET_FORVARDINO_NEW_LESSON_FORM_RESET';

// === MARKETPLACE ==========================//
export const SET_MARKETPLACE_FORM_ID = 'SET_MARKETPLACE_FORM_ID';
export const SET_MARKETPLACE_FORM_NAME = 'SET_MARKETPLACE_FORM_NAME';
export const SET_MARKETPLACE_FORM_DESCRIPTION = 'SET_MARKETPLACE_FORM_DESCRIPTION';
export const SET_MARKETPLACE_FORM_PRICE = 'SET_MARKETPLACE_FORM_PRICE';
export const SET_MARKETPLACE_FORM_CATEGORY = 'SET_MARKETPLACE_FORM_CATEGORY';
export const SET_MARKETPLACE_FORM_CONDITION = 'SET_MARKETPLACE_FORM_CONDITION';
export const SET_MARKETPLACE_FORM_LOCATION = 'SET_MARKETPLACE_FORM_LOCATION';
export const SET_MARKETPLACE_FORM_DURATION = 'SET_MARKETPLACE_FORM_DURATION';
export const SET_MARKETPLACE_FORM_EXPIRED = 'SET_MARKETPLACE_FORM_EXPIRED';
export const SET_MARKETPLACE_FORM_IMAGE = 'SET_MARKETPLACE_FORM_IMAGE';
export const SET_MARKETPLACE_FORM_IMAGE_OLD = 'SET_MARKETPLACE_FORM_IMAGE_OLD';
export const SET_MARKETPLACE_FORM_CONSENT = 'SET_MARKETPLACE_FORM_CONSENT';
export const SET_MARKETPLACE_FORM_COMPLETED = 'SET_MARKETPLACE_FORM_COMPLETED';
export const SET_MARKETPLACE_FORM_RESET = 'SET_MARKETPLACE_FORM_RESET';
export const SET_MARKETPLACE_FILTER_CATEGORIES = 'SET_MARKETPLACE_FILTER_CATEGORIES';
export const SET_MARKETPLACE_SELECTED_CATEGORIES = 'SET_MARKETPLACE_SELECTED_CATEGORIES';
export const SET_MARKETPLACE_ARE_FILTERS_ACTIVE = 'SET_MARKETPLACE_ARE_FILTERS_ACTIVE';
export const SET_MARKETPLACE_ACTIVE_CATEGORY = 'SET_MARKETPLACE_ACTIVE_CATEGORY';

// === DIRECTORY ==========================//
export const SET_DIRECTORY_CATEGORIES = 'SET_DIRECTORY_CATEGORIES';
export const SET_DIRECTORY_CATEGORIES_LAST_CHECK = 'SET_DIRECTORY_CATEGORIES_LAST_CHECK';
export const SET_DIRECTORY_ENTITIES = 'SET_DIRECTORY_ENTITIES';
export const SET_DIRECTORY_ENTITIES_LAST_CHECK = 'SET_DIRECTORY_ENTITIES_LAST_CHECK';
export const SET_DIRECTORY_ITEMS = 'SET_DIRECTORY_ITEMS';
export const SET_DIRECTORY_ITEMS_LAST_CHECK = 'SET_DIRECTORY_ITEMS_LAST_CHECK';
export const SET_DIRECTORY_MODAL_FORM = 'SET_DIRECTORY_MODAL_FORM';
export const SET_DIRECTORY_MODAL_DETAIL = 'SET_DIRECTORY_MODAL_DETAIL';
export const SET_DIRECTORY_MODAL_WISH = 'SET_DIRECTORY_MODAL_WISH';
export const SET_DIRECTORY_FORM_ID = 'SET_DIRECTORY_FORM_ID';
export const SET_DIRECTORY_FORM_NAME = 'SET_DIRECTORY_FORM_NAME';
export const SET_DIRECTORY_FORM_DESCRIPTION = 'SET_DIRECTORY_FORM_DESCRIPTION';
export const SET_DIRECTORY_FORM_WISH_DESCRIPTION = 'SET_DIRECTORY_FORM_WISH_DESCRIPTION';
export const SET_DIRECTORY_FORM_LINK = 'SET_DIRECTORY_FORM_LINK';
export const SET_DIRECTORY_FORM_LINK_WIKI = 'SET_DIRECTORY_FORM_LINK_WIKI';
export const SET_DIRECTORY_FORM_ICON = 'SET_DIRECTORY_FORM_ICON';
export const SET_DIRECTORY_FORM_ICON_OLD = 'SET_DIRECTORY_FORM_ICON_OLD';
export const SET_DIRECTORY_FORM_ICON_COLOR = 'SET_DIRECTORY_FORM_ICON_COLOR';
export const SET_DIRECTORY_FORM_SUPPORT_EMAIL = 'SET_DIRECTORY_FORM_SUPPORT_EMAIL';
export const SET_DIRECTORY_FORM_SUPPORT_PHONE = 'SET_DIRECTORY_FORM_SUPPORT_PHONE';
export const SET_DIRECTORY_FORM_SUPPORT_SNOW_GROUP = 'SET_DIRECTORY_FORM_SUPPORT_SNOW_GROUP';
export const SET_DIRECTORY_FORM_PRIVATE_ONLY = 'SET_DIRECTORY_FORM_PRIVATE_ONLY';
export const SET_DIRECTORY_FORM_DESKTOP_ONLY = 'SET_DIRECTORY_FORM_DESKTOP_ONLY';
export const SET_DIRECTORY_FORM_COMPLETED = 'SET_DIRECTORY_FORM_COMPLETED';
export const SET_DIRECTORY_FORM_RESET = 'SET_DIRECTORY_FORM_RESET';
export const SET_DIRECTORY_DETAIL_APP = 'SET_DIRECTORY_DETAIL_APP';
export const SET_DIRECTORY_ITEM_FAVORITE = 'SET_DIRECTORY_ITEM_FAVORITE';
export const SET_DIRECTORY_ITEM_FAVORITE_OFF = 'SET_DIRECTORY_ITEM_FAVORITE_OFF';
export const SET_DIRECTORY_ITEM_DELETE = 'SET_DIRECTORY_ITEM_DELETE';
export const SET_DIRECTORY_ITEMS_LOADING = 'SET_DIRECTORY_ITEMS_LOADING';

// === COMPETITITON ========================//
export const SET_COMPETITION_ANSWER = 'SET_COMPETITION_ANSWER';
export const SET_COMPETITION_RESULT = 'SET_COMPETITION_RESULT';
export const SET_COMPETITION_SENT_RESULT = 'SET_COMPETITION_SENT_RESULT';
export const SET_COMPETITION_SENT_TIMESTAMP = 'SET_COMPETITION_SENT_TIMESTAMP';
export const SET_COMPETITION_SUMMARY_TIMESTAMP = 'SET_COMPETITION_SUMMARY_TIMESTAMP';
export const SET_COMPETITION_RESET = 'SET_COMPETITION_RESET';

// === WORKOUTS ============================//
export const SET_ALL_WORKOUTS = 'SET_ALL_WORKOUTS';
export const SET_WORKOUTS_IS_LOADED = 'SET_WORKOUTS_IS_LOADED';
export const SET_WORKOUT_BUILDING = 'SET_WORKOUT_BUILDING';
export const SET_WORKOUT_QUARTAL = 'SET_WORKOUT_QUARTAL';
export const SET_WORKOUTS_OPTIONS = 'SET_WORKOUTS_OPTIONS';
export const SET_WORKOUTS_HAS_ERROR = 'SET_WORKOUTS_HAS_ERROR';

// === CLIENT SATISFACTION =================//
export const SET_NPS_GRAPH_VALUES = 'SET_NPS_GRAPH_VALUES';
export const SET_NPS_IS_LOADED = 'SET_NPS_IS_LOADED';
export const SET_NPS_FILTERS_LOADED = 'SET_NPS_FILTERS_LOADED';
export const SET_NPS_FILTER_CATEGORIES = 'SET_NPS_FILTER_CATEGORIES';
export const SET_NPS_FILTER_ACTIVE = 'SET_NPS_FILTER_ACTIVE';
export const SET_NPS_ACTIVE_CATEGORY = 'SET_NPS_ACTIVE_CATEGORY';
export const SET_NPS_SELECTED_FILTERS = 'SET_NPS_SELECTED_FILTERS';

// === TUTORIAL ============================//
export const TOGGLE_TUTORIAL = 'TOGGLE_TUTORIAL';
export const SET_TUTORIAL_IS_COMPLETED = 'SET_TUTORIAL_IS_COMPLETED';
export const SET_TUTORIAL_IS_NOT_COMPLETED = 'SET_TUTORIAL_IS_NOT_COMPLETED';

// === COVID-19 ============================//
export const SET_COVID19_FAQ_ITEMS = 'SET_COVID19_FAQ_ITEMS';
export const SET_COVID19_FAQ_IS_LOADED = 'SET_COVID19_FAQ_IS_LOADED';
export const SET_COVID19_HAS_ERROR = 'SET_COVID19_HAS_ERROR';

// === TRIO ================================//
export const SET_TRIO_PAGES = 'SET_TRIO_PAGES';
export const SET_TRIO_IS_LOADED = 'SET_TRIO_IS_LOADED';
export const SET_TRIO_HAS_ERROR = 'SET_TRIO_HAS_ERROR';

// === WEEK ACTIONS ========================//
export const SET_WEEK_ACTIONS_SELECTED_CATEGORIES = 'SET_WEEK_ACTIONS_SELECTED_CATEGORIES';

// === SURVEY ==============================//
export const SET_SURVEY_IS_LOADED = 'SET_SURVEY_IS_LOADED';
export const SET_SURVEY_HAS_ERROR = 'SET_SURVEY_HAS_ERROR';
export const SET_SURVEY_CONTENT = 'SET_SURVEY_CONTENT';
export const SET_SURVEY_ORGANIZATION_STRUCTURE = 'SET_SURVEY_ORGANIZATION_STRUCTURE';

// === PARKING =============================//
export const SET_PARKING_CAPACITY = 'SET_PARKING_CAPACITY';
export const SET_MY_PARKING_RESERVATIONS = 'SET_MY_PARKING_RESERVATIONS';
export const SET_PARKING_LAST_RESERVE = 'SET_PARKING_LAST_RESERVE';

// === AUCTION =============================//
export const SET_AUCTION_MEMBER = 'SET_AUCTION_MEMBER';

// === BENEFITS ============================//
export const SET_BENEFITS_FILTER_CATEGORIES = 'SET_BENEFITS_FILTER_CATEGORIES';
export const SET_BENEFITS_FILTERS_LOADED = 'SET_BENEFITS_FILTERS_LOADED';
export const SET_BENEFITS_ACTIVE_CATEGORY = 'SET_BENEFITS_ACTIVE_CATEGORY';
export const SET_BENEFITS_FILTER_ACTIVE = 'SET_BENEFITS_FILTER_ACTIVE';
export const SET_BENEFITS_SELECTED_CATEGORIES = 'SET_BENEFITS_SELECTED_CATEGORIES';

// === ONBOARDING =============================//
export const SET_ONBOARDING_EXTERNAL_CANDIDATES = 'SET_ONBOARDING_EXTERNAL_CANDIDATES';
export const SET_ONBOARDING_EXTERNAL_CANDIDATES_LOADED = 'SET_ONBOARDING_EXTERNAL_CANDIDATES_LOADED';
export const SET_ONBOARDING_APPLICANTS = 'SET_ONBOARDING_APPLICANTS';
export const SET_ONBOARDING_APPLICANTS_LOADED = 'SET_ONBOARDING_APPLICANTS_LOADED';
export const SET_ONBOARDING_FILTERED_INTERVAL = 'SET_ONBOARDING_FILTERED_INTERVAL';
export const SET_ONBOARDING_APPLICANTS_FILTER = 'SET_ONBOARDING_APPLICANTS_FILTER';
export const SET_ONBOARDING_USER_ROLE = 'SET_ONBOARDING_USER_ROLE';
export const SET_ONBOARDING_USER_MODE = 'SET_ONBOARDING_USER_MODE';

// === SERVICES ========================//
export const SET_SERVICES_SELECTED_CATEGORY = 'SET_SERVICES_SELECTED_CATEGORY';
export const SET_SERVICES_LIST_FILTER = 'SET_SERVICES_LIST_FILTER';
export const SET_SERVICES_FILTER_ACTIVE = 'SET_SERVICES_FILTER_ACTIVE';

// === SEARCH ========================//
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_SEARCH_TOGGLE_STATUS = 'SET_SEARCH_TOGGLE_STATUS';
export const SET_SEARCH_STATUS = 'SET_SEARCH_STATUS';
export const SET_SEARCH_HISTORY_STATUS = 'SET_SEARCH_HISTORY_STATUS';
export const SET_SEARCH_RESULTS_DATA = 'SET_SEARCH_RESULTS_DATA';
export const SET_SEARCH_RESULTS_COUNT = 'SET_SEARCH_RESULTS_COUNT';
export const SET_SEARCH_RESULTS_FULL_DATA = 'SET_SEARCH_RESULTS_FULL_DATA';

// === PROMOTED ========================//
export const SET_PROMOTED_MODULES = 'SET_PROMOTED_MODULES';
export const SET_PROMOTED_ACTIVE_MODULE = 'SET_PROMOTED_ACTIVE_MODULE';
export const SET_PROMOTED_SELECTED_CATEGORIES = 'SET_PROMOTED_SELECTED_CATEGORIES';
export const SET_PROMOTED_FILTER_ACTIVE = 'SET_PROMOTED_FILTER_ACTIVE';

// === COMING ACTIONS ========================//
export const SET_COMING_MODULES = 'SET_COMING_MODULES';
export const SET_COMING_ACTIVE_MODULE = 'SET_COMING_ACTIVE_MODULE';
export const SET_COMING_SELECTED_CATEGORIES = 'SET_COMING_SELECTED_CATEGORIES';
export const SET_COMING_FILTER_ACTIVE = 'SET_COMING_FILTER_ACTIVE';

// === MAPS ========================//
export const SET_MAPS_REPORT_FORM_MODAL = 'SET_MAPS_REPORT_FORM_MODAL';
export const SET_MAPS_REPORT_FORM_DATA_LOCATION = 'SET_MAPS_REPORT_FORM_DATA_LOCATION';
export const SET_MAPS_REPORT_FORM_DATA_DESCRIPTION = 'SET_MAPS_REPORT_FORM_DATA_DESCRIPTION';
export const SET_MAPS_REPORT_FORM_DATA_COMPLETED = 'SET_MAPS_REPORT_FORM_DATA_COMPLETED';
export const SET_MAPS_REPORT_FORM_DATA_RESET = 'SET_MAPS_REPORT_FORM_DATA_RESET';
