import * as MUTATIONS_CONSTANTS from '../constants/mutations'

const state = {
  modalReportFormActive: false,
  reportFormData: {
    location: '',
    description: '',
    completed: false
  }
}

const mutations = {
  [MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_MODAL](state, active) {
    state.modalReportFormActive = active;
  },

  [MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_DATA_LOCATION](state, location) {
    state.reportFormData.location = location;
  },

  [MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_DATA_DESCRIPTION](state, description) {
    state.reportFormData.description = description;
  },

  [MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_DATA_COMPLETED](state, completed) {
    state.reportFormData.completed = completed;
  },

  [MUTATIONS_CONSTANTS.SET_MAPS_REPORT_FORM_DATA_RESET](state) {
    state.reportFormData.location = '';
    state.reportFormData.description = '';
    state.reportFormData.completed = false;
  },
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
