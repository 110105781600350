<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    ref="savingPanel"
    class="relative rounded-2xl p-4 xs:p-6"
    :class="classObject"
  >
    <!--========== BASIC ==================================-->
    <!--===================================================-->
    <div
      v-if="!extended"
      class="flex justify-between"
    >
      <!-- Title + description -->
      <div class="flex flex-col w-1/2">
        <span class="mb-2 text-2xl font-bold leading-8 text-white">
          {{ headline }}
        </span>

        <span class="text-sm text-white leading-5">
          {{ description }}
        </span>
      </div>

      <!-- Calculated savings -->
      <div class="flex flex-col w-1/2 flex-1">
        <div class="text-white text-right flex flex-col items-end">
          <span class="text-5xl font-extrabold">{{ roundingValue }}</span>
          <span class="text-xl font-bold text-lime-200">{{ unit }}</span>
        </div>
      </div>
    </div>

    <!--========== EXTENDED ===============================-->
    <!--===================================================-->
    <template v-else>
      <div class="flex justify-between">
        <!-- Title -->
        <div class="flex flex-col w-2/4 pr-2">
          <span class="mb-2 text-base xs:text-xl sm:text-2xl font-bold xs:leading-7 sm:leading-8 text-white">

            <span class="block">
              {{ $t("esg.savingPanel.extended.headlineStart") }}

              <a
                class="relative cursor-pointer"
                :class="classDateLink"
                @click="clickSelectedOption"
              >
                {{ selectedOption }}

                <svg
                  v-if="!generatingImage"
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute -right-4.5 top-1/2 transform  -translate-y-1/2 h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="4"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </a>
            </span>

            {{ $t("esg.savingPanel.extended.headlineEnd") }}
          </span>
        </div>

        <!-- Calculated savings -->
        <div class="flex flex-col w-2/4 flex-1">
          <div class="text-white text-right pt-0.5">
            <template v-if="extendedSettings">
              <span
                class="font-extrabold pr-2"
                :class="classRoundingExtendedValue"
              >{{ roundingValue }}</span>
              <span
                class="font-extrabold"
                :class="classRoundingExtendedUnit"
              >{{ unit }}</span>
            </template>
            <template v-else>
              <span
                class="font-extrabold"
                :class="classRoundingExtendedValue"
              >{{ extendedListCount }}</span>
              <span
                class="font-extrabold"
                :class="classRoundingExtendedUnit"
              >x</span>
            </template>
          </div>
        </div>
      </div>

      <!-- Description -->
      <div
        v-if="extendedSettings"
        class="flex flex-col"
      >
        <span class="text-sm text-white leading-5">
          {{ $t("esg.savingPanel.description", { saved: secondaryRoundingValue, unit: secondaryUnit }) }}
        </span>
      </div>

      <!-- Top 3 types -->
      <div
        v-if="hasExtendedList"
        class="flex gap-4 justify-between mt-4 xs:mt-6"
      >
        <div
          v-for="(item, index) of extendedListCropped"
          :key="index"
          class="flex flex-col justify-between w-full m-auto max-w-24"
        >
          <div
            class="flex flex-col justify-evenly items-center p2 font-semibold h-20 rounded-xl px-3 mb-2.5"
            :class="item.cssClass"
          >
            <span
              class="w-5/6 h-4/6"
              v-html="item.icon"
            ></span>
          </div>

          <span class="font-semibold text-lime-200 text-center">{{ item.percentageValue }} %</span>
        </div>
      </div>
    </template>

    <div
      v-if="sharing"
      class="absolute right-3 -bottom-3 z-10"
    >
      <!-- Share -->
      <prj1015-share-button
        :title="shareImageName"
        :disabled="false"
        :imageRefName="shareImage"
        :class="{'opacity-0': generatingImage}"
        @generatingImage="imageIsShared"
      />
    </div>
  </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
const TOP_TYPES_COUNT = 3;

import Prj1015ShareButton from '@/components/esg/prj1015-share-button/prj1015-share-button';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'Prj1012SavingPanel',
  components: {Prj1015ShareButton},
  props: {
    type: {
      type: String,
      default: 'transport'
    },
    value: {
      type: Number,
      default: 0
    },
    secondaryValue: {
      type: Number,
      default: 0
    },
    minPositiveValue: {
      type: Number,
      default: 1
    },
    maxRoundedValue: {
      type: Number,
      default: 0.9
    },
    units: {
      type: Array,
      default: () => ['km', 'm']
    },
    secondaryUnits: {
      type: Array,
      default: () => ['kg', 'g']
    },
    unitsMultiple: {
      type: Array,
      default: () => [1, 1000]
    },
    extended: {
      type: Boolean,
      default: false
    },
    extendedSettings: {
      type: Boolean,
      default: false
    },
    extendedList: {
      type: Array,
      default: () => {}
    },
    selectedOption: {
      type: String,
      default: ''
    },
    sharing: {
      type: Boolean,
      default: false
    },
    shareImageName: {
      type: String,
      default: ''
    },
    headline: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      generatingImage: false
    }
  },
  computed: {
    positive() {
      return this.value > this.minPositiveValue;
    },
    classObject() {
      return [
        this.positive || !this.extendedSettings ? 'bg-esg-green' : 'bg-red-600'
      ];
    },
    classDateLink() {
      return [
        this.value > 0 || !this.extendedSettings ? 'text-lime-200' : 'text-red-300'
      ]
    },
    valueHasDecimalPlace() {
      return this.value % 1 !== 0;
    },
    classRoundingExtendedValue() {
      const decimal = this.valueHasDecimalPlace;

      return [
        this.value > 999 && decimal ?
          'text-2xl sm:text-5xl' :
          this.value > 999 || this.value > 99 && decimal ? 'text-3xl sm:text-5xl' :
            this.value > 99 || this.value > 9 && decimal ? 'text-4xl sm:text-5xl' : 'text-5xl'
      ]
    },
    classRoundingExtendedUnit() {
      const decimal = this.valueHasDecimalPlace;

      return [
        this.value > 999 || this.value > 99 && decimal ? 'text-base xs:text-xl sm:text-3xl' :
          this.value > 99 || this.value > 9 && decimal ? 'text-xl xs:text-2xl sm:text-3xl' : 'text-3xl'
      ]
    },
    unitsCount() {
      return this.units.length;
    },
    roundingValue() {
      if (this.unitsCount > 1) {
        if (this.value < this.maxRoundedValue) {
          const newValue = this.value * this.unitsMultiple[1];

          return Math.floor(newValue);
        }
      }

      return ((this.value * 10) / 10).toLocaleString('cs-CZ', { maximumFractionDigits: 1 });
    },
    unit() {
      if (this.unitsCount > 1 && this.value < this.maxRoundedValue) {
        return this.units[1];
      }

      return this.units[0];
    },

    secondaryUnitsCount() {
      return this.secondaryUnits.length;
    },
    secondaryRoundingValue() {
      if (this.secondaryUnitsCount > 1) {
        if (this.secondaryValue < this.maxRoundedValue) {
          const newValue = this.secondaryValue * this.unitsMultiple[1];

          return Math.floor(newValue);
        }
      }

      return ((this.secondaryValue * 10) / 10).toLocaleString('cs-CZ', { maximumFractionDigits: 1 });
    },
    secondaryUnit() {
      if (this.secondaryUnitsCount > 1 && this.secondaryValue < this.maxRoundedValue) {
        return this.secondaryUnits[1];
      }

      return this.secondaryUnits[0];
    },
    extendedListCropped() {
      const length = this.extendedList.length;

      if (length > TOP_TYPES_COUNT) {
        return this.extendedList.slice(0, TOP_TYPES_COUNT);
      }

      return this.extendedList;
    },
    extendedListCount() {
      const list = this.extendedListCropped;
      let count = 0;

      for (let item of list) {
        count += item.count;
      }

      return count;
    },
    hasExtendedList() {
      return this.extendedList?.length;
    },
    shareImage() {
      return 'savingPanel';
    }
  },
  methods: {
    clickSelectedOption() {
      this.$emit('selectOption');
    },
    imageIsShared(state) {
      this.generatingImage = state;
    }
  }
}
</script>
