<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <div
    :id="id"
    class="vue-component vue-c-header"
    :class="classObject"
  >
    <div class="vue-b-content-main">
      <div
        v-if="hasSlotLeft"
        class="vue-b-slot-left"
      >
        <slot name="left" />
      </div>
      <div
        v-if="hasSlotMain"
        class="vue-b-slot-main"
      >
        <slot name="main" />
      </div>
      <div
        v-if="hasSlotRight"
        class="vue-b-slot-right"
      >
        <slot name="right" />
      </div>
    </div>
    <div
      v-if="hasSlotBottom"
      class="vue-b-content-bottom"
    >
      <div class="vue-b-slot-bottom">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prj1009MapHeader',
  props: {
    'caption': {
      default: null,
      type: String
    },
    'buttonBack': {
      default: true,
      type: Boolean
    },
    'buttonBackType': {
      default: 'back',
      type: String
    },
    'id': {
      default: null,
      type: String
    },
  },
  data() {
    return {}
  },
  computed: {
    classObject() {
      return {
        'vue-has-slot-left': this.hasSlotLeft,
        'vue-has-slot-main': this.hasSlotMain,
        'vue-has-slot-right': this.hasSlotRight,
        'vue-has-slot-bottom': this.hasSlotBottom,
        'vue-has-button-back': this.buttonBack
      }
    },
    hasSlotLeft() {
      return !!this.$slots.left || this.buttonBack;
    },
    hasSlotMain() {
      return !!this.$slots.main || this.caption;
    },
    hasSlotRight() {
      return !!this.$slots.right;
    },
    hasSlotBottom() {
      return !!this.$slots.bottom;
    },
  }
}
</script>
