<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-combo" :class="classObjectComputed">
        <div class="vue-b-form-field">
            <label
                v-if="label"
                :id="idComputed + ID_EXTENSIONS.LABEL"
                :for="idComputed + ID_EXTENSIONS.SELECT"
                class="vue-label"
                >{{ label }}</label
            >
            <select
                :id="selectId"
                ref="select"
                v-model="valueComputed"
                class="vue-select"
                :name="name"
                :disabled="disabled"
                :multiple="multiple"
                :size="size"
                :aria-labelledby="labeledByComputed"
                :aria-describedby="describedByComputed"
                @click="selectClick($event.target.value, $event)"
                @keydown="selectKeyDown($event.target.value, $event)"
                @keyup="selectKeyUp($event.target.value, $event)"
                @change="selectChange($event.target.value, $event)"
                @focus="selectFocus($event.target.value, $event)"
                @blur="selectBlur($event.target.value, $event)"
            >
                <!-- This option is rendered only if placeholder is set AND value is not defined as it is non reselectable -->
                <option
                    v-if="(placeholder && value === '') || placeholderSelectable"
                    class="vue-combo-option-placeholder"
                    value=""
                    :disabled="!placeholderSelectable"
                    :hidden="!placeholderSelectable"
                    selected="value === ''"
                    >{{ placeholder }}
                </option>

                <option
                    v-for="(option, index) in options"
                    :key="index"
                    class="vue-combo-option"
                    :class="{
                        'vue-is-disabled': option.disabled,
                        'vue-is-placeholder': option.placeholder
                    }"
                    :selected="option.value === value"
                    :disabled="option.disabled"
                    :value="option.value"
                    :label="option.caption"
                    >{{ option.caption }}
                </option>
            </select>
            <div v-if="decorator" class="vue-decorator"></div>
            <frm1006-button
                v-if="hasButtonClear"
                ref="buttonClear"
                type="internal"
                class="vue-ci-button-clear"
                :title="i18n('globalInputButtonClear')"
                :tabindex="-1"
                @buttonClickEvent="buttonClearClick"
            >
                {{ i18n('globalInputButtonClear') }}
            </frm1006-button>
        </div>

        <gen1010-information-tooltip
            v-if="tooltipComputed"
            ref="tooltip"
            :idPrefix="null"
            :expanded.sync="tooltipExpandedData"
            :state="state"
            :disabled="tooltipDisabled"
            :content="tooltipContent"
            :contentId="idComputed + ID_EXTENSIONS.TOOLTIP_CONTENT"
            :whiteList="tooltipWhiteListComputed"
            :boundComponentActive="componentIsActive"
            :boundComponentPreventLosingFocus="tooltipPreventLosingFocus"
            class="vue-ci-tooltip"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltip from '../../gen/gen1010-information-tooltip/gen1010-information-tooltip';
import Frm1006Button from '../frm1006-button/frm1006-button';

//=== MIXINS
import Component from '../../mixins/component';
import ButtonClearCombo from '../../mixins/buttonClearCombo';
import Tooltip from '../../mixins/tooltip';
import Localization from '../../mixins/localization';

//=== MISC
import config from '../../../config';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1002';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1002Combo',
    components: {
        Gen1010InformationTooltip,
        Frm1006Button
    },
    mixins: [Component, ButtonClearCombo, Tooltip, Localization],
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        name: String,
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        required: Boolean,
        disabled: Boolean,
        label: String,
        labeledBy: String,
        describedBy: String,
        options: {
            default: null,
            type: Array,
            required: true
        },
        value: {
            default: '',
            type: [String, Array]
        },
        placeholder: String,
        placeholderSelectable: Boolean,
        multiple: Boolean, // TODO MBU: implement multiple param
        size: String,
        //=== ADDITIONAL ELEMENTS
        decorator: Boolean,
        //=== TOOLTIP
        focusOnTooltipOpen: {
            default: true,
            type: Boolean
        },
        tooltipPreventLosingFocus: {
            default: true,
            type: Boolean
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    data() {
        return {
            valueData: this.value,
            componentIsActive: false,
            focused: false
        };
    },
    computed: {
        classObject() {
            return [
                'vue-is-' + this.state,
                {
                    [`vue-has-placeholder-selected`]:
                        (this.placeholder && this.valueData === '') ||
                        (this.placeholderSelectable && this.valueData === ''),
                    'vue-is-required': this.required,
                    'vue-is-readonly': this.readonly,
                    'vue-is-disabled': this.disabled,
                    'vue-is-set': !this.notSet,
                    'vue-is-not-set': this.notSet,
                    'vue-has-label': this.label,
                    'vue-has-decorator': this.decorator,
                    'vue-is-component-active': this.componentIsActive,
                    'vue-is-focused': this.focused
                }
            ];
        },
        classObjectComputed() {
            return [...this.classObject, ...this.classObjectMixinTooltip, ...this.classObjectMixinButtonClear];
        },
        valueComputed: {
            get() {
                return this.valueData;
            },
            set(value) {
                this.valueData = value;
                this.$emit('change', value);
            }
        },
        //========= ID & ACCESSIBILITY ===============//
        //============================================//
        generateAutoId() {
            return !!this.label || this.tooltipHasContent;
        },
        selectId() {
            if (this.generateAutoId) {
                return this.idComputed + this.ID_EXTENSIONS.SELECT;
            }

            return null;
        },
        labeledByComputed() {
            if (this.label && !this.labeledBy) {
                return this.idComputed + this.ID_EXTENSIONS.LABEL;
            }

            return this.labeledBy;
        },
        describedByComputed() {
            if (!this.describedBy && this.tooltipHasContent) {
                return this.idComputed + this.ID_EXTENSIONS.TOOLTIP_CONTENT;
            }

            return this.describedBy;
        },
        //============ OTHER ===================================//
        //======================================================//
        notSet() {
            return this.valueData === '';
        }
    },
    watch: {
        value(value) {
            if (value !== '') {
                let valueIndex = this.checkValue(value);

                if (valueIndex > -1) {
                    this.valueData = value;
                } else if (value !== '') {
                    this.$emit('change', '');
                    console.error('Value {{' + value + '}} not matching any item value! Value set to empty string!');
                }
            } else {
                this.valueData = value;
            }
        },
        tooltipExpandedData() {
            this.setComponentActiveState();
            if (this.focusOnTooltipOpen && !this.componentIsActive && this.tooltipExpandedData) {
                this.$refs.select.focus();
            }
        },
        componentIsActive(value) {
            this.$emit('componentIsActiveEvent', value);
        },
        focused(value) {
            this.$emit('selectFocusStateEvent', value);
        },
        tooltipWhiteListInitial(value) {
            this.$emit('tooltipWhiteListInitial', value);
        }
    },
    mounted() {
        this.tooltipExpandedData = this.tooltipExpanded;
        if (this.tooltipWhiteListInitialInit) {
            this.setTooltipWhiteListInitial();
        }

        // set initial select value if none is set
        if (this.value === '' && !this.placeholder) {
            this.valueData = this.options[0].value;
        }
    },
    methods: {
        //=== GENERAL
        checkValue(value) {
            for (let i = 0; i < this.options.length; i++) {
                let item = this.options[i];

                if (item.value === value) {
                    return i;
                }
            }

            if (value !== '') {
                console.warn('Value {{' + value + '}} not matching any item value!');
            }
            return -1;
        },
        selectSetFocus() {
            this.$refs.select.focus();
        },
        selectSetBlur() {
            this.$refs.select.blur();
        },
        setComponentActiveState() {
            this.componentIsActive = document.activeElement === this.$refs.select;
        },
        setSelectFocusState() {
            this.focused = document.activeElement === this.$refs.select;
        },
        //=== EVENTS
        selectClick(value, event) {
            this.$emit('selectClickEvent', value, event);
        },
        selectKeyDown(value, event) {
            this.$emit('selectKeyDownEvent', value, event);
        },
        selectKeyUp(value, event) {
            this.$emit('selectKeyUpEvent', value, event);
        },
        selectChange(value, event) {
            this.$emit('selectChangeEvent', value, event);
        },
        selectFocus(value, event) {
            this.$emit('selectFocusEvent', value, event);
            // states
            this.setComponentActiveState();
            this.setSelectFocusState();
            // tooltip
            if (
                this.tooltipComputed &&
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
        },
        selectBlur(value) {
            this.$emit('selectBlurEvent', value, event);
            this.setComponentActiveState();
            this.setSelectFocusState();
        },
        //=== TOOLTIP
        setTooltipWhiteListInitial() {
            this.tooltipWhiteListInitial = [];

            // select
            this.tooltipWhiteListInitial.push(this.$refs.select);

            // button clear
            if (this.$refs.buttonClear !== undefined) {
                let buttonClearElements = this.$refs.buttonClear.$el.querySelectorAll('*');
                for (let element of buttonClearElements) {
                    this.tooltipWhiteListInitial.push(element);
                }
            }
        }
    }
};
</script>
