<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        @buttonCustomEvent="buttonCustomEvent"
      />
    </template>

    <!--========== HEADER =================================-->
    <!--===================================================-->
    <template v-slot:header>
      <prj1004-article-head
        v-if="articleData"
        :articleData="articleData"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <prj1004-article-body
        v-if="articleData"
        :articleData="articleData"
      />
      <gen1006-info-panel
        v-else
        type="error"
      >
        {{ $t('general.articleNonexistent') }}
      </gen1006-info-panel>

      <template v-if="articleData && articleData.commentsEnable">
        <!-- TODO MBU: type internal? -->
        <frm1006-button
          type="internal"
          class="vue-button-comments"
          :iconLeft="true"
          @buttonClickEvent="goToComments"
        >
          {{ $t('articles.commentsShow') }}
        </frm1006-button>
      </template>

      <gen1006-info-panel
        v-if="!isLoading && !initialized"
        type="neutral"
      >
        {{ $t('general.loadingFailed') }}
      </gen1006-info-panel>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
      <!-- TODO MBU: type internal? -->
      <frm1006-button
        class="vue-button-page-layout-fixed vue-is-share"
        type="internal"
        :iconLeft="true"
        iconLeftName="vue-icon-share"
        :captionHidden="true"
        @buttonClickEvent="openShareDialog"
      >
        {{ $t('general.share') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
        :active.sync="shareDialog"
        :positionCustom="contextContainerPositions"
      >
        <div class="vue-share-links">
          <frm1006-button
            class="vue-button-share vue-is-minor"
            type="primary"
            :iconLeft="true"
            iconLeftName="vue-icon-share"
            @buttonClickEvent="copyToClipboard"
          >
            {{ $t('articles.copyLink') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>

      <prj1041-status-indicator
        :text="$t('articles.copied')"
        :active="indicatorCopied"
        @transitionEnd="indicatorCopied = false"
      />
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import { ARTICLE } from '@/store/store-modules';
import dataLoader from '@/utils/data-loader';
import router from '@/router';
import logger from '@/utils/logger';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import moduleStatistics from '@/mixins/mx-module-statistics';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';

import PageLayout from '../../templates/partials/page-layout';
import Prj1002Navbar from '../../components/prj1002-navbar/prj1002-navbar';
import Prj1004ArticleHead from '@/components/prj1004-article-detail/prj1004-article-head';
import Prj1004ArticleBody from '@/components/prj1004-article-detail/prj1004-article-body';
import Gen1009Overlay from '../../../frameworks/vue/components/gen/gen1009-overlay/gen1009-overlay';
import Prj1041StatusIndicator from '@/components/prj1041-status-indicator/prj1041-status-indicator';
import * as clipboardy from 'clipboardy';

export default {
  name: 'NewsDetail',

  components: {
    Prj1041StatusIndicator,
    Gen1009Overlay,
    PageLayout,
    Prj1002Navbar,
    Prj1004ArticleHead,
    Prj1004ArticleBody,
  },

  mixins: [
    mxNavigationDefault,
    moduleStatistics,
    mxDetectDesktop
  ],

  data() {
    return {
      isLoading: true,
      hasError: false,
      initialized: false,
      articleFetched: null,
      visibleArticleComments: false,
      shareDialog: false,
      indicatorCopied: false,
      ERROR_MODAL_TIMEOUT,
      contextContainerPositions: false
    }
  },

  computed: {
    ...mapState('articles', [
      'showFilteredArticles',
      'filteredArticles',
      'newsActiveCategory',
      'detailData'
    ]),
    ...mapState('dataStore', [
      'contentData'
    ]),

    articleCached() {
      return this.filteredArticles[this.articleId] || this.detailData;
    },

    articleData() {
      if (Object.keys(this.articleCached).length) {
        return this.articleCached;
      }
      return this.articleFetched;
    },

    articleId() {
      return this.$route.params.id;
    },
    articleUrl() {
      return window.location.href;
    }
  },

  // TODO MBU: this approach might be helpful in future implementation
  // Updated lifecycle hook is solves the RW/FF issues in the browser history between articles
  // updated() {
  //   this.getArticleIdFromUrl();
  // },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        if (this.articleData) {
          this.$refs.pageLayout.pageAsyncDataLoadedDone();
        }
      }
    },
  },

  mounted() {
    if (this.articleCached?.id !== this.articleId) {
      this.fetchArticle();
    } else {
      this.initialized = true;
    }

    this.sendStatistics(ARTICLE, this.articleId, true);
  },

  methods: {
    fetchArticle() {
      dataLoader.fetchPostById(this.articleId)
        .then(data => {
          this.articleFetched = data[0];
          this.$store.commit(STORE_MODULES.ARTICLES + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, this.articleFetched);
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // TODO MBU: overlay delay - when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.initialized = true;
        });
    },

    navigateBack() {
      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DRAWER_IS_OPENED, true);
      this.$router.push({
        name: 'dashboard',
        query: {
          categoryId: this.newsActiveCategory
        }
      });
    },

    openShareDialog(event) {
      let title = this.articleData.title;
      let url = this.articleUrl;

      if (this.isDesktopLayout) {
        this.contextContainerPositions = {
          x: event.pageX,
          y: event.pageY,
          mode: 'bottom-center'
        }
      } else {
        this.contextContainerPositions = false
      }

      if (navigator.share) {
        navigator.share({
          title: title,
          text: title,
          url: url
        })
          .catch(error => {
            logger.error(error)
          })
      } else {
        this.toggleShareDialog(event);
      }
    },

    toggleShareDialog(event) {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.shareDialog = !this.shareDialog;
    },

    copyToClipboard() {
      clipboardy.write(this.articleUrl).then(() => {
        this.shareDialog = false;
        this.indicatorCopied = true;
      })
    },

    goToComments() {
      this.$router.push({ name: 'news-comments', params: { id: this.articleId }});
    },

    buttonCustomEvent() {
      if (this.newsActiveCategory !== 0) {

        router.push({
          name: this.$route.params.origin,
          params: {
            hasActiveCategory: true
          },
          query: {
            categoryId: this.newsActiveCategory
          }
        });
      } else if (this.$route.params.navbarButtonMode === 'history') {
        router.go(-1);
      } else if (typeof this.$route.params.origin === 'undefined') {
        router.push({ name: 'dashboard' });
      } else {
        router.push({ name: this.$route.params.origin });
      }
    }
  }
}
</script>
