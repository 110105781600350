import Tbl1007TableResponsive from './tbl1007-table-responsive';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Tbl1007TableResponsive);
    }
};

use(Plugin);

export default Plugin;

export { Tbl1007TableResponsive };
