<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-tag-container" :class="classObject">
        <slot></slot>
        <template v-if="tags.length > 0">
            <frm1004-tag-label
                v-for="(tag, i) in tags"
                :id="tag.id"
                :key="i"
                :value="tag.value"
                :class="tag.class"
                :buttonDelete="tag.buttonDelete"
                :buttonDeleteCaption="tag.buttonDeleteCaption"
                @buttonDeleteClickEvent="tagLabelButtonDeleteClick"
            >
                {{ tag.caption }}
            </frm1004-tag-label>
        </template>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GENERIC COMPONENTS
import Frm1004TagLabel from '../gen1004-tag-label/gen1004-tag-label';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1005TagContainer',
    components: {
        Frm1004TagLabel
    },
    model: {
        prop: 'tags',
        event: 'tagsChangeEvent'
    },
    props: {
        tags: {
            default() {
                return [];
            },
            type: Array
        },
        id: {
            default: null,
            type: String
        }
    },
    computed: {
        classObject() {
            return {
                'vue-is-empty': !this.hasTags
            };
        },
        hasTags() {
            return Object.keys(this.$slots).length > 0 || this.tags.length > 0;
        }
    },
    methods: {
        tagLabelButtonDeleteClick(id, value) {
            //=== V-MODEL
            let tagsModified = [...this.tags];

            for (let i = 0; i < tagsModified.length; i++) {
                if (tagsModified[i].id === id) {
                    tagsModified.splice(i, 1);
                    break;
                }
            }
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('tagsChangeEvent', tagsModified); // event for v-model

            //=== CUSTOM
            this.$emit('tagLabelButtonDeleteClickEvent', id, value);
        }
    }
};
</script>
