import * as MUTATIONS_CONSTANTS from '../constants/mutations';
import { STRATEGY_COMPETITION_ACTIVE } from '@/constants/app-constants';

const state = {
  competitions: {
    data: {}
  }
};

//=== COMPETITION =======================================//
//=======================================================//

const mutations = {
  [MUTATIONS_CONSTANTS.SET_COMPETITION_ANSWER](state, results) {
    if (typeof state.competitions.data[STRATEGY_COMPETITION_ACTIVE] === 'undefined') {
      state.competitions.data[STRATEGY_COMPETITION_ACTIVE] = {
        'results': null,
        'result': null,
        'sentResult': false,
        'sentTimestamp': null,
        'summaryTimestamp': null
      }
      state.competitions.data[STRATEGY_COMPETITION_ACTIVE].results = results;
    } else {
      state.competitions.data[STRATEGY_COMPETITION_ACTIVE].results = results;
    }
  },
  [MUTATIONS_CONSTANTS.SET_COMPETITION_RESULT](state, value) {
    state.competitions.data[STRATEGY_COMPETITION_ACTIVE].result = value;
  },
  [MUTATIONS_CONSTANTS.SET_COMPETITION_SENT_RESULT](state, value) {
    state.competitions.data[STRATEGY_COMPETITION_ACTIVE].sentResult = value;
  },
  [MUTATIONS_CONSTANTS.SET_COMPETITION_SENT_TIMESTAMP](state) {
    let date = new Date();
    state.competitions.data[STRATEGY_COMPETITION_ACTIVE].sentTimestamp = Number(date);
  },
  [MUTATIONS_CONSTANTS.SET_COMPETITION_SUMMARY_TIMESTAMP](state) {
    let date = new Date();
    state.competitions.data[STRATEGY_COMPETITION_ACTIVE].summaryTimestamp = Number(date);
  },
  [MUTATIONS_CONSTANTS.SET_COMPETITION_RESET](state) {
    state.competitions.data = {};
  },
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
