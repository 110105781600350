import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as APP_CONSTANTS from '@/constants/app-constants';

const state = {
  selectedRestaurant: APP_CONSTANTS.RESTAURANT_NHQ,
  selectedDay: 0,
  cafeMenuVisibility: false
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_SELECTED_RESTAURANT](state, restaurant) {
    state.selectedRestaurant = restaurant;
  },

  [MUTATIONS_CONSTANTS.SET_RESTAURANT_DAY](state, restaurantSelectedDay) {
    state.selectedDay = restaurantSelectedDay;
  },

  [MUTATIONS_CONSTANTS.SET_CAFE_VISIBILITY](state) {
    state.cafeMenuVisibility = !state.cafeMenuVisibility;
  }
};

const actions = {

};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
