<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <transition :name="transitionName" @afterLeave="afterLeave">
        <div v-if="active" :id="idComponent" class="vue-component vue-c-context-container" :class="classObject">
            <div
                ref="contentContainer"
                class="vue-b-content-container"
                :style="styleObject"
            >
                <div ref="content" class="vue-b-content">
                    <slot></slot>
                </div>
                <div v-if="buttonClose || !!$slots.controls" ref="controls" class="vue-b-controls">
                    <slot name="controls"></slot>
                    <frm1006-button
                        type="internal"
                        :title="buttonCloseCaptionComputed"
                        :preventLosingFocus="true"
                        class="vue-ci-button-close"
                        @buttonClickEvent="buttonCloseClick"
                    >
                        {{ buttonCloseCaptionComputed }}
                    </frm1006-button>
                </div>
            </div>
        </div>
    </transition>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//=== MIXINS
import Component from '../../mixins/component';
import Localization from '../../mixins/localization';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'gen1011';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1011ContextContainer',
    components: {
        Frm1006Button
    },
    mixins: [Component, Localization],
    props: {
        active: Boolean,
        buttonClose: {
            default: true,
            type: Boolean
        },
        buttonCloseCaption: String,
        whiteList: {
            default: () => {
                return [];
            },
            type: Array
        },
        whiteListContentContainerIncluded: {
            default: true,
            type: Boolean
        },
        preventLosingFocus: Boolean,
        transitionName: {
            default: 'vue-default',
            type: String
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        },
        positionCustom: {
            default: false,
            type: [Boolean, Object]
        }
    },
    data() {
        return {
            activeData: this.active,
            contextContainerWidth: 0,
            contextContainerHeight: 0,
        };
    },
    computed: {
        classObject: function() {
            return {
                'vue-has-controls': this.buttonClose || !!this.$slots.controls,
                'vue-has-button-close': this.buttonClose
            };
        },
        styleObject() {
            return this.positionCustom ? {
                position: 'absolute',
                top: this.getTopValue(this.positionCustom.y, this.positionCustom.mode),
                left: this.getLeftValue(this.positionCustom.x, this.positionCustom.mode),
            } : {};
        },
        buttonCloseCaptionComputed() {
            if (!this.buttonCloseCaption) {
                return this.i18n('buttonClose');
            }

            return this.buttonCloseCaption;
        }
    },
    watch: {
        active(value) {
            this.activeData = value;
            if (value) {
              setTimeout(() => {
                this.contextContainerWidth = typeof this.$refs.contentContainer === 'undefined' ? 0 : this.$refs.contentContainer.clientWidth;
                this.contextContainerHeight = typeof this.$refs.contentContainer === 'undefined' ? 0 : this.$refs.contentContainer.clientHeight;
                this.$forceUpdate();
              }, 0);
            }
        },
        activeData(value) {
            this.$emit('update:active', value);

            if (value) {
                document.addEventListener('click', this.click);
                document.addEventListener('touchend', this.click);
                document.addEventListener('keyup', this.keyUp);

                this.$nextTick().then(() => {
                    this.$refs.contentContainer.addEventListener('mousedown', this.contentContainerMouseDown);
                });
            } else {
                document.removeEventListener('click', this.click);
                document.removeEventListener('touchend', this.click);
                document.removeEventListener('keyup', this.keyUp);
                this.$refs.contentContainer.removeEventListener('mousedown', this.contentContainerMouseDown);
            }
        },
    },
    beforeDestroy() {
        document.removeEventListener('click', this.click);
        document.removeEventListener('touchend', this.click);
        document.removeEventListener('keyup', this.keyUp);

        if (this.$refs.contentContainer) {
            this.$refs.contentContainer.removeEventListener('mousedown', this.contentContainerMouseDown);
        }
    },
    methods: {
        //=== GENERAL
        toggle(trigger) {
            this.activeData = !this.activeData;
            this.$emit('toggleEvent', trigger, this.activeData);

            if (!this.activeData) {
                this.$emit('closeEvent', 'toggle');
            }
        },
        open(trigger) {
            this.activeData = true;
            this.$emit('openEvent', trigger);
        },
        close(trigger) {
            this.activeData = false;
            this.$emit('closeEvent', trigger);
        },
        //=== EVENTS
        afterLeave() {
            this.$emit('removedEvent');
        },
        click(event) {
            if (!this.whiteListCheck(event)) {
                this.close('clickOutside');
            }
        },
        keyUp(event) {
            if (event.key === 'Escape') {
                this.close('esc');
            }
        },
        buttonCloseClick() {
            this.close('button');
        },
        contentContainerMouseDown(event) {
            if (this.preventLosingFocus && this.activeData) {
                event.preventDefault(); // warning: prevents drag select / double click select etc.
            }
            this.$emit('contentContainerMouseDownEvent', event);
        },
        //=== MISC
        // checks if clicked item is on white list, if it is, it won't trigger clickOutside
        whiteListCheck(event) {
            let element = event.target;

            // click inside context container content
            // TODO MBU: manual solution with path, instead of this.$refs.contentContainer.contains(element) recommended here https://stackoverflow.com/a/28432139
            // EDIT: the solution with contains might be better in the end, validate
            // solution via path had problems with iOS
            if (this.whiteListContentContainerIncluded && this.$refs.contentContainer.contains(element)) {
                return true;
            }

            // TODO MBU: if above solution works ok, delete the old one
            // if (this.whiteListContentContainerIncluded) {
            //     for (let element of event.path) {
            //         if (element === this.$refs.contentContainer) {
            //             return true;
            //         }
            //     }
            // }

            // manual override
            if (event.hasOwnProperty('customProps') && event.customProps.contextContainerPreventClickOutside) {
                return true;
            }

            // passed white list
            if (this.whiteList.length > 0 && this.whiteList.indexOf(element) > -1) {
                return true;
            }

            return false;
        },
        getTopValue(value, mode) {
            if (typeof value !== 'number') {
                return false;
            }
            if (mode === 'top-left') {
                return (value + 20) + 'px';
            } else if(mode === 'bottom-right') {
                return (value - 20 - this.contextContainerHeight) + 'px';
            }
        },
        getLeftValue(value, mode) {
            if (typeof value !== 'number') {
                return false;
            }
            if (mode === 'top-left') {
                return (value + 20) + 'px';
            } else if(mode === 'bottom-right') {
                return (value - 20 - this.contextContainerWidth) + 'px';
            }
        },
    }
};
</script>
