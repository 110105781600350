export default {
  fileUrl: 'https://zapkacms.csob.cz/wp-content/uploads/emergency/emergency-evac-plan-nhq-shq-hhq.pdf',
  plans: [
    {
      id: 1,
      name: 'NHQ',
      fileUrl: '/img/emergency/evacuation-plan-nhq.png'
    },
    {
      id: 2,
      name: 'SHQ',
      fileUrl: '/img/emergency/evacuation-plan-shq.png'
    },
    {
      id: 3,
      name: 'HHQ',
      fileUrl: '/img/emergency/evacuation-plan-hhq.png'
    }
  ],
}
