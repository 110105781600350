<template>
  <main
    class="grid grid-rows-layout h-full"
    :class="classNames"
  >
    <nav class="relative mx-auto prj-layout-padding-x w-full max-w-screen-lg">
      <prj1005-navigation-bar
        v-if="isBackNavEnabled"
        :class="navClassNames"
        :route-name="computedBackRoute"
        :nav-button-variant="navBtnVariant"
      />
    </nav>

    <div
      v-if="contentHasContent"
      class="overflow-y-auto h-full"
      :class="{'prj-scrollbar-inverse': inverseScrollbar}"
    >
      <div
        class="mx-auto px-7 w-full sm:w-144 h-full text-left prj-wallpaper"
      >
        <slot name="content" />
      </div>
    </div>

    <footer class="mx-auto px-7 w-full sm:w-144">
      <slot name="footer" />
    </footer>

    <section v-if="fixedHasContent">
      <slot name="fixed" />
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex';
import { ROUTE_DASHBOARD } from '@/constants/esg/route-names'

import Prj1005NavigationBar from '@/components/esg/prj1005-navigation-bar/prj1005-navigation-bar'

export default {
  name: 'PageLayout',
  components: {
    Prj1005NavigationBar,
  },
  props: {
    inverseScrollbar: {
      type: Boolean,
      default: false
    },
    classNames: {
      type: String,
      default: null
    },
    isBackNavEnabled: {
      type: Boolean,
      default: true
    },
    navBtnVariant: {
      type: String,
      default: null
    },
    navClassNames: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState('esgGeneral', ['windowHistoryCount']),
    appHistoryCount() {
      return window.history.length - this.windowHistoryCount
    },
    computedBackRoute() {
      return !this.appHistoryCount ? ROUTE_DASHBOARD : null
    },
    contentHasContent() {
      return !!this.$slots.content;
    },
    fixedHasContent() {
      return !!this.$slots.fixed;
    }
  }
};
</script>
