<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="goToBenefitsList"
      />
    </template>

    <!--========== HEADER =================================-->
    <!--===================================================-->
    <template v-slot:header>
      <prj1001-detail-header
        v-if="initialized && !isLoading"
        :headerImage="benefitData.image"
        :headerTag="benefitData.categories[0].name"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div
          v-if="benefitData"
          class="vue-benefit-detail-content"
        >
          <prj1017-heading
            :detailheader="benefitData.name"
          />

          <gen1006-info-panel
            class="vue-benefit-duration"
            type="neutral"
          >
            {{ convertDateToTranslation(benefitData.activityTo) }}
          </gen1006-info-panel>

          <div
            class="vue-benefit-detail-text"
            v-html="benefitData.description"
          />

          <div
            v-if="benefitData.attachment"
            class="vue-b-docs"
          >
            <div class="vue-doc">
              <span class="vue-name">{{ $i18n.t('general.attachment') }} - </span>
              <a
                :href="attachmentUrl(benefitData)"
                class="vue-external-link-blue"
                target="_blank"
              >
                {{ $i18n.t('general.externalAttachment') }}
              </a>
            </div>
          </div>

          <div class="vue-benefit-details">
            <h2
              v-if="benefitData.contactName.length > 0"
            >
              {{ benefitData.contactName }}
            </h2>
            <p
              v-if="benefitData.contactAddress.length > 0"
              class="vue-address"
            >
              {{ benefitData.contactAddress }}
            </p>
            <a
              v-if="benefitData.contactAddress.length > 0"
              class="vue-icon vue-map"
              :href="generateGoogleMapsUrl(benefitData.contactAddress)"
              target="_blank"
            >
              {{ $t('general.map') }}
            </a>
            <a
              v-if="benefitData.contactPhone.length > 0"
              class="vue-icon vue-phone"
              :href="`tel:${benefitData.contactPhone}`"
            >
              {{ benefitData.contactPhone }}
            </a>
            <a
              v-if="benefitData.contactEmail.length > 0"
              class="vue-icon vue-email"
              :href="`mailto:${benefitData.contactEmail}`"
            >
              {{ benefitData.contactEmail }}
            </a>
            <a
              v-if="benefitData.contactUrl.length > 0"
              class="vue-icon vue-website"
              :href="benefitData.contactUrl"
              target="_blank"
            >
              {{ $t('benefits.websiteLink') }}
            </a>
          </div>
        </div>

        <gen1006-info-panel
          v-if="!benefitData"
          type="neutral"
        >
          {{ $t('general.loadingFailed') }}
        </gen1006-info-panel>

        <gen1016-loading-indicator
          :active="isLoading"
          :overlay="true"
          :fullScreen="true"
        />
      </template>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <!-- TODO MBU: add button already registered - already done on events -->
    <template v-slot:buttonFixed>
      <frm1006-button
        class="vue-button-register vue-button-page-layout-fixed"
        @buttonClickEvent="applyDiscount"
      >
        <span>
          {{ $t('benefits.applyDiscount') }}
        </span>
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
        </gen1006-info-panel>
      </gen1009-overlay>

      <gen1009-overlay
        v-if="initialized && !isLoading"
        class="vue-benefits-overlay"
        :active.sync="showBenefitsModal"
        :buttonClose="true"
      >
        <div class="vue-benefits-apply-discount-modal">
          <p
            v-if="benefitData.discountDescription.length > 0"
            v-html="benefitData.discountDescription"
          />
          <gen1006-info-panel
            v-if="benefitData.discountCode.length > 0"
            type="neutral"
          >
            <p class="vue-code-content">
              {{ benefitData.discountCode }}
            </p>
          </gen1006-info-panel>
          <div
            v-if="benefitData.discountDisclaimer.length > 0"
            class="vue-subnote"
          >
            <p>
              {{ benefitData.discountDisclaimer }}
            </p>
          </div>
        </div>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import utilsGeneral from '@/utils/utils-general';

import mxNavigationDefault from '../../mixins/mx-navigation-default';
import mxEventDate from '@/mixins/mx-event-date';
import PageLayout from '@/templates/partials/page-layout';

import Prj1001DetailHeader from '@/components/prj1001-detail-header/prj1001-detail-header';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'BenefitsDetail',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading,
    Prj1001DetailHeader,
  },

  mixins: [
    mxNavigationDefault,
    mxEventDate
  ],

  data() {
    return {
      today: new Date().getTime(),
      isLoading: true,
      hasError: false,
      initialized: false,
      benefitFetched: null,
      alreadyRegistered: false,
      eventDateModalVisibility: false,
      showRatingDialogModal: false,
      showBenefitsModal: false,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    ...mapState('benefits', [
      'detailData'
    ]),

    pageId() {
      return this.$route.params.id;
    },

    historyBack() {
      return !!this.$route.params.historyBack;
    },

    benefitCached() {
      return this.detailData;
    },

    benefitData() {
      if (Object.keys(this.benefitCached).length) {
        return this.benefitCached;
      }
      return this.benefitFetched;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value && Object.keys(this.benefitData).length) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  mounted() {
    if (this.benefitCached?.id !== this.pageId) {
      this.getBenefitDetailData();
    } else {
      this.initialized = true;
    }
  },

  methods: {
    getBenefitDetailData() {
      dataLoader.fetchBenefitsItemDetail(this.pageId)
        .then(response => {
          this.benefitFetched = response;
          this.$store.commit(STORE_MODULES.BENEFITS + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, this.benefitFetched);
          this.isLoading = false;
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.initialized = true;
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          }
        });
    },

    applyDiscount() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;

      this.showBenefitsModal = true;
    },

    generateGoogleMapsUrl(address) {
      const googleAddressBase = 'https://www.google.com/maps/search/?api=1&query=';
      const escapedAddressQuery = encodeURIComponent(address);

      return googleAddressBase + escapedAddressQuery;
    },

    convertDateToTranslation(date) {
      const defaultDate = new Date(utilsGeneral.normalizeDateString(date));
      const dayNumber = defaultDate.getDate();
      const monthNumber = defaultDate.getMonth() + 1;
      const yearNumber = defaultDate.getFullYear();
      const defaultMonthName = this.$i18n.t(`time.months.${monthNumber}`);
      const inflectedMonthName = this.$i18n.t(`inflection.months.2.${defaultMonthName}`);

      return this.$i18n.t('benefits.activityTo', { day: dayNumber, month: inflectedMonthName, year: yearNumber });
    },

    attachmentUrl(benefitDetail) {
      return globalConfig.BASE_CMS_URL + benefitDetail.attachment;
    },

    goToBenefitsList() {
      if (this.historyBack) {
        this.$router.go(-1);
      }

      if (!this.$route.params.origin) {
        this.$router.push({ name: 'benefits' })
      } else {
        this.$router.push({ name: this.$route.params.origin})
      }
    }
  }
}
</script>
