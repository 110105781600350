<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
  <zap-page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <zap-prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <page-layout>
        <template v-slot:content>
          <!--========== PAGE BODY ==============================-->
          <!--===================================================-->
          <div
            v-if="challengeDetail"
            class="prj-article prj-article-extend-fest prj-promo-page"
          >
            <t-tag
              variant="sectionTitle"
              class="xs:mb-8 md:mb-10 xs:mt-6 md:mt-10"
            >
              {{ challengeDetail.title }}
              <t-tag
                v-if="challengeDetail.subTitle"
                variant="highlight"
              >
                {{ challengeDetail.subTitle }}
              </t-tag>
            </t-tag>
          </div>

          <!--========== SUMMARY (ALL) ==========================-->
          <!--===================================================-->
          <div
            v-if="isHistory && challengeDetail"
            class="pb-8"
          >
            <h2 class="mb-8 font-bold text-xl">{{ $t('fest.vote.result.subHeadline') }}</h2>

            <div class="mb-4">
              <div class="flex flex-col justify-between">
                <div
                  v-for="(item, index) in barChartStats"
                  :key="index"
                  class="w-full flex justify-start items-center mb-1"
                >
                  <div
                    class="text-sky-600 text-2xl w-8"
                    :class="{'font-bold': index <= 2}"
                  >
                    {{ index + 1 }}.
                  </div>

                  <div
                    class="text-base flex-1 text-left"
                    :class="{'font-bold': index <= 2}"
                  >
                    {{ item.name }}
                  </div>

                  <div class="w-1/2 flex-1 flex justify-end">
                    <div
                      class="h-5 w-full rounded-lg"
                      :class="item.cssClassBarLine"
                      :style="'width:' + item.percentageCss"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="w-2 h-2 rounded bg-sky-500 flex-shrink-0 mr-2 mt-0.5"></div>
              <p class="text-xs text-gray-500">{{ $t('fest.vote.result.legend') }}</p>
            </div>
          </div>
        </template>
      </page-layout>
    </template>
  </zap-page-layout>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script>
//============ IMPORT ==================================//
//======================================================//
import '@/utils/dayjs';
import {mapState} from 'vuex';
import utilsGeneral from '@/utils/fest/utils-general';
import logger from '@/utils/logger';

import ZapPageLayout from '@/templates/partials/page-layout'
import ZapPrj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar'
import mxNavigationDefault from '@/mixins/mx-navigation-default'

import musicBandsChallengeType from '@/assets/fest/vote/types/musicBands';

import PageLayout from '@/templates/fest/page-layout';
import apiCmsChallenges from '@/fest-api/cms-challenges';

import {FEST_CHALLENGES} from '@/store/store-modules';
import {GET_CHALLENGES_INFO} from '@/store/constants/fest/actions';

//============ EXPORT ==================================//
//======================================================//
export default {
  name: 'FESTVoteResult',
  components: {
    ZapPageLayout,
    ZapPrj1002Navbar,
    PageLayout
  },
  mixins: [
    mxNavigationDefault
  ],
  data() {
    return {
      historyData: [],
      historyDataGroup: [],
      challengeDetail: null,
      challengeItems: musicBandsChallengeType.items
    }
  },
  computed: {
    ...mapState('festUser', {
      userName: state => state.name,
      userEmail: state => state.email
    }),
    ...mapState('festChallenges', ['challengesInfo']),
    challengeId() {
      return parseInt(this.$route.params.voteId);
    },
    historyList() {
      return this.historyData.list;
    },
    isHistory() {
      return this.historyList;
    },
    summaryValue() {
      let summary = 0;

      for (let item of this.historyData.list) {
        if (item.challenge_id === this.challengeId.toString()) {
          summary = summary + Number(item.distance);
        }
      }

      return summary;
    },
    stats() {
      return this.historyData.stats;
    },
    statsList() {
      return this.historyData.list;
    },
    getListSummaryValues() {
      const listData = this.statsList;
      const challengeId = this.challengeId.toString();
      let summaryValues = {
        types: this.challengeItems,
        maxValue: 0
      };

      for (const challengeItem of listData) {
        if (challengeItem.challenge_id === challengeId) {
          const itemsIds = challengeItem.transport_id.split(',');

          for (const id of itemsIds) {
            const findValuesIndex = summaryValues.types.findIndex(item => item.id === parseFloat(id));

            if (findValuesIndex) {
              if (summaryValues.types[findValuesIndex].count) {
                summaryValues.types[findValuesIndex].count += 1;
              }
              else {
                summaryValues.types[findValuesIndex].count = 1;
              }

              summaryValues.maxValue = summaryValues.types[findValuesIndex].count > summaryValues.maxValue ? summaryValues.types[findValuesIndex].count : summaryValues.maxValue;
            }
          }
        }
      }

      return summaryValues;
    },
    barChartStats() {
      const types = this.getListSummaryValues.types;
      const sortedTypes = types.sort((a, b) => a.count - b.count).slice().reverse();
      const maxValue = this.getListSummaryValues.maxValue;

      for (const [index, value] of sortedTypes.entries()) {
        sortedTypes[index].percentageCss = maxValue && value.count ? Math.round((value.count / maxValue) * 100) + '%' : '0';
        sortedTypes[index].cssClassBarLine = 'bg-sky-500';
      }

      return sortedTypes;
    }
  },
  async created() {
    if (!this.challengesInfo.length) await this.$store.dispatch(`${FEST_CHALLENGES}/${GET_CHALLENGES_INFO}`)
    this.getChallengeById()
    this.getChallengeUserHistory();
  },
  methods: {
    getChallengeById() {
      this.challengeDetail = this.challengesInfo.find((challenge) => challenge.id === this.challengeId);
    },
    challengeTypeObject(id) {
      return this.challengeItems.find(transportItem => transportItem.id === parseFloat(id))
    },
    percentageFromMaxValue(max, value) {
      const maxValue = max ? max : 1;
      const valueWithMax = value ? value : max ? value : 1;

      return Math.round( (valueWithMax / maxValue) * 100);
    },
    getChallengeUserHistory() {
      let processId = utilsGeneral.startAsyncProcess();

      apiCmsChallenges
        .getChallengeUserHistory()
        .then(response => {
          this.historyData = response;
        })
        .catch(error => {
          utilsGeneral.stopAsyncProcess(processId);
          this.refreshToken = false;
          logger.error('Sending form has failed.', error.response);
        })
        .finally(() => {
          utilsGeneral.stopAsyncProcess(processId);
        });
    }
  }
}
</script>
