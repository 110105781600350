<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        @buttonCustomEvent="navigateBack()"
      />
    </template>

    <!--========== HEADER =================================-->
    <!--===================================================-->
    <template v-slot:header>
      <prj1001-detail-header
        v-if="adData"
        ref="adDetailHeader"
        :headerImage="adData.image"
        :header-image-click="true"
        :headerTag="adData.categoryName"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        v-if="adData && !isLoading"
        class="vue-marketplace-detail-content"
      >
        <!-- TODO: Heading props are in lowercase, change it to camelCase globally -->
        <prj1017-heading
          ref="adTop"
          :detailheader="adData.name"
        />

        <div
          v-if="!adData.expired"
          class="vue-b-row"
        >
          <div
            v-if="adData.priceFormatted"
            class="vue-marketplace-detail-price"
          >
            <span
              v-if="adData.priceOld && adData?.priceOld !== '0.00' && adData.priceOld !== adData.price"
              class="vue-price-old"
            >{{ adData.priceOld }}
            </span>
            <span class="vue-price">{{ adData.price === '0.00' ? $t('marketplace.priceNone') : adData.priceFormatted }}</span>
          </div>

          <div class="vue-marketplace-detail-bookmark">
            <frm1006-button
              type="internal"
              :icon-left="true"
              :class="{ 'vue-is-bookmark': !adData.bookmarked, 'vue-is-bookmarked': adData.bookmarked }"
              @buttonClickEvent="clickButtonBookmark()"
            >
              {{ $t(adData.bookmarked ? 'marketplace.bookmarked' : 'marketplace.bookmark') }}
            </frm1006-button>
          </div>
        </div>

        <gen1006-info-panel
          v-if="adData.expired"
          type="neutral"
        >
          <p v-html="$i18n.t('marketplace.durationExpired')" />
        </gen1006-info-panel>

        <div
          class="vue-marketplace-detail-text"
          v-html="adData.description"
        />

        <ul>
          <li>
            <label><strong>{{ $i18n.t('marketplace.state') }}:</strong></label>
            {{ adData.conditionName }}
          </li>
          <li v-if="!adData.expired">
            <label><strong>{{ $i18n.t('marketplace.duration', { days: adData.durationLeftFormatted }) }}</strong></label>
          </li>
        </ul>

        <div class="vue-icon vue-marketplace-detail-map">
          {{ adData.locationName }}
        </div>

        <div
          v-if="!adData.owner"
          class="vue-b-seller-contact"
        >
          <prj1020-separator />

          <h3>{{ $i18n.t('marketplace.seller.caption') }}</h3>

          <prj1021-profile-card
            :fullName="adData.contact?.name"
            :avatar="adData.contact?.image"
            :profession="''"
            :isClickable="false"
          />

          <a
            v-if="adData.contact?.phone"
            class="vue-icon vue-marketplace-detail-phone"
            :href="`tel:${adData.contact?.phone}`"
          >
            {{ adData.contact?.phone }}
          </a>

          <a
            v-if="adData.contact?.email"
            class="vue-icon vue-marketplace-detail-email"
            :href="`mailto:${adData.contact?.email}`"
          >
            {{ adData.contact?.email }}
          </a>
        </div>
      </div>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
      <frm1006-button
        v-if="adData?.owner && !adData.expired"
        class="vue-button-page-layout-fixed vue-is-second"
        @buttonClickEvent="clickButtonEdit()"
      >
        <span>
          {{ $t('marketplace.forms.editAd') }}
        </span>
      </frm1006-button>

      <frm1006-button
        v-if="adData?.owner && adData.expired"
        class="vue-button-page-layout-fixed vue-is-second"
        @buttonClickEvent="clickButtonEdit()"
      >
        <span>
          {{ $t('marketplace.forms.editExpiredAd') }}
        </span>
      </frm1006-button>

      <frm1006-button
        v-if="adData?.owner"
        type="secondary"
        class="vue-button-page-layout-fixed"
        @buttonClickEvent="clickButtonDelete()"
      >
        <span>
          {{ $t('marketplace.forms.deleteAd') }}
        </span>
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        :class="{ 'vue-is-rounded-bottom vue-is-position-bottom':!isDesktopLayout }"
        :active.sync="showDeleteModal"
      >
        <div
          class="vue-b-modal-delete"
        >
          <h3 v-html="$t('marketplace.modals.delete.caption')"></h3>
          <p v-html="$t('marketplace.modals.delete.description')"></p>

          <frm1006-button
            type="secondary"
            @buttonClickEvent="clickButtonModalCancel()"
          >
            {{ $t('marketplace.modals.delete.cancel') }}
          </frm1006-button>

          <frm1006-button
            type="primary"
            @buttonClickEvent="clickButtonModalConfirm()"
          >
            {{ $t('marketplace.modals.delete.confirm') }}
          </frm1006-button>
        </div>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import router from '@/router';
import mxNavigationDefault from '@/mixins/mx-navigation-default';
import { mapState } from 'vuex';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import dataLoader from '@/utils/data-loader';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import logger from '@/utils/logger';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import mxDetectDesktop from '@/mixins/mx-detect-desktop.vue';

import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import Prj1001DetailHeader from '@/components/prj1001-detail-header/prj1001-detail-header.vue';
import Prj1020Separator from '@/components/prj1020-separator/prj1020-separator';
import Prj1021ProfileCard from '@/components/prj1021-profile-card/prj1021-profile-card.vue';

export default {
  name: 'MarketplaceDetail',
  components: {
    Prj1021ProfileCard,
    Prj1001DetailHeader,
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading,
    Prj1020Separator
  },

  mixins: [
    mxNavigationDefault,
    mxDetectDesktop
  ],

  data() {
    return {
      navbarCaptionData: null,
      today: new Date().getTime(),
      isLoading: true,
      hasError: false,
      initialized: false,
      adFetched: null,
      ERROR_MODAL_TIMEOUT: ERROR_MODAL_TIMEOUT,
      showDeleteModal: false,
    };
  },

  computed: {
    ...mapState('user', [
      'deviceId'
    ]),
    ...mapState('marketplace', [
      'marketplaceFilterCategories',
      'marketplaceSelectedCategories',
      'marketplaceActiveCategory',
      'marketplaceAreFiltersActive',
      'detailData'
    ]),


    pageId() {
      return this.$route.params.id;
    },

    listMode() {
      return this.$route.params.listMode;
    },

    historyBack() {
      return !!this.$route.params.historyBack;
    },

    navbarCaption() {
      if (!this.$route.query.categoryId) {
        return this.$route.meta.navbarCaption;
      } else {
        return this.navbarCaptionData;
      }
    },

    adCached() {
      return this.detailData;
    },

    adData() {
      if (Object.keys(this.adCached).length) {
        return this.adCached;
      }
      return this.adFetched;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value && Object.keys(this.adData).length) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    await this.$store.dispatch(STORE_MODULES.MARKETPLACE + '/' + ACTIONS_CONSTANTS.FETCH_MARKETPLACE_FILTER_DATA);

    if (this.adCached?.id !== this.pageId) {
      this.getAdDetailData();
    } else {
      this.initialized = true;
    }
  },

  methods: {
    getAdDetailData() {
      dataLoader.getMarketplaceDetail(this.pageId)
        .then(response => {
          this.adFetched = response.data;
          this.$store.commit(STORE_MODULES.MARKETPLACE + '/' + MUTATIONS_CONSTANTS.SET_DETAIL_DATA, this.adFetched);
          if (Object.keys(response.data).length > 0) {
            this.isLoading = false;
          }
        })
        .catch(error => {
          logger.error(error);
          this.isLoading = false;
          // FIXME: CSOBZ-521 - overlay delay, when async load is done (isLoading set to false, hasError must wait to trigger watcher in gen1009)
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.initialized = true;
          if (this.hasError) {
            setTimeout(() => {
              this.hasError = false;
            }, ERROR_MODAL_TIMEOUT);
          }
        });
    },

    goToView(categoryId) {
      router.push({
        name: 'marketplace-list',
        params: { categoryId }
      })
    },

    getCondition(conditionId) {
      const conditions = this.marketplaceFilterCategories?.conditions;
      return conditions.find(condition => condition.id === conditionId).value || '';
    },

    getLocation(locationId) {
      const locations = this.marketplaceFilterCategories?.locations;
      return locations.find(location => location.id === locationId).value || '';
    },

    navigateBack() {
      let routeName = 'marketplace';
      switch (this.listMode) {
      case 'all':
        routeName = 'marketplace-list';
        break;
      case 'my':
        routeName = 'marketplace-list-my';
        break;
      case 'saved':
        routeName = 'marketplace-list-saved';
        break;
      }
      router.push({
        name: routeName,
      })
    },

    clickButtonEdit() {
      router.push({
        name: 'marketplace-form',
        params: { id: this.pageId }
      })
    },

    clickButtonDelete() {
      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;
      this.showDeleteModal = true;
    },

    clickButtonModalConfirm() {
      this.isLoading = true;
      dataLoader.deleteMarketplaceAd(
        this.pageId
      ).then(() => {
        logger.info('Ad has been deleted!');
        this.isLoading = false;
      }).catch(error => {
        logger.error('Deleting ad has failed:', error);
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.isLoading = false;
        this.showDeleteModal = false;
        router.push({
          name: 'marketplace-list-my',
          params: {}
        })
      })
    },

    clickButtonModalCancel() {
      this.showDeleteModal = false;
    },

    clickButtonBookmark() {
      this.isLoading = true;
      dataLoader.putMarketplaceAdBookmark(
        this.pageId
      ).then(() => {
        logger.info('Save ad bookmark success');
        this.isLoading = false;
      }).catch(error => {
        logger.error('Save ad bookmark has failed:', error);
        this.hasError = true;
      }).finally(() => {
        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, ERROR_MODAL_TIMEOUT);
        }
        this.isLoading = false;
        this.getAdDetailData();
      })
    }
  },
}
</script>

